export const minMaxDates = (
  dates: string[]
): { min_date: string; max_date: string } => {
  try {
    const convertedDates = dates?.map((date) => new Date(date)?.getTime());

    const minDate = new Date(Math.min(...convertedDates));
    const maxDate = new Date(Math.max(...convertedDates));

    return {
      max_date: `${maxDate}`,
      min_date: `${minDate}`,
    };
  } catch {
    return {
      max_date: "",
      min_date: "",
    };
  }
};
