import { Tabs } from "antd";
import { LeftTabPaneStyled, LeftTabsStyled } from "./lefttabs.styles";
import { LeftTabPaneProps, LeftTabsProps } from "./lefttabs.types";
import SectionTitleWithIcon from "../../sectiontitlewithicon/sectiontitlewithicon";

const LeftTabs = (props: LeftTabsProps): JSX.Element => {
  const { children, className, header, height } = props;

  return (
    <LeftTabsStyled className="dvsum-tabs-left-container" height={height}>
      {header && (
        <SectionTitleWithIcon
          className="left-tabs-header"
          title={header}
          isShowIcon={false}
          showBorderBottom={false}
        />
      )}
      <Tabs
        className={`${className || ""} dvsum-tabs-left`}
        defaultActiveKey="1"
        tabPosition="left"
        tabBarGutter={0}
        {...props}
      >
        {children}
      </Tabs>
    </LeftTabsStyled>
  );
};

LeftTabs.LeftTabPane = ({
  className,
  ...props
}: LeftTabPaneProps): JSX.Element => {
  return (
    <LeftTabPaneStyled
      className={`${className || ""} dvsum-tabs-tabpane`}
      {...props}
    />
  );
};

export default LeftTabs;
