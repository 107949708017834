// <--Library Imports Start-->
import { AxiosResponse } from "axios";
// <--Library Imports End-->

// <--Types Start-->
import {
  DvSumHelpSearchResultsResponse,
  OnboardingTutorialResponse,
  ReturnTypeOfDvSumHelpSearchResultItem,
  ReturnTypeOfDvSumHelpSearchResultsParser,
  ReturnTypeOfParsedOnboardingTutorial,
  ReturntypeOfReleaseOnboardingHelpParser,
} from "./helpandonboardingparser.types";
// <--Types End-->

// <--Utils Start-->
import {
  checkIsChatPage,
  getEnvVariables,
  getPostLoginData,
  jsonParse,
  momentUtc,
} from "../../utils";

import {
  ELEMENT_IDS,
  LOCAL_STORAGE_CONSTANTS,
  MOMENT_DATE_FORMATS,
} from "../../constants";

import { checkIsChatLandingPage } from "../../utils/checkischatlandingpage";
// <--Utils End-->

const COMMON_STEP: ReturnTypeOfParsedOnboardingTutorial[number] = {
  step_num: 8,
  step_title: "Self-service Help",
  step_desc:
    "You can access help-related materials such as tutorials, quick tips, videos, and articles in this help section.",
  step_action: "button",
  step_action_text: "Click ",
  step_element_id: "userjrn-help-hlp-top-nav",
  visited: false,
  required_field: false,
  show_next_btn: false,
  step_action_bold_text: "highlighted",
  step_action_suffix_text: " area\n\n",
  tooltip_placement: "right-start",
  remove_next_element_id_from_dom: false,
  close_drawer_on_next: false,
  element_to_click_on_next: "",
  set_next_elem_visibility_false: false,
  step_element_padding: "",
  step_element_margin: "",
  is_place_left_arrow_top: false,
  is_allow_dropdown_menu: false,
  is_open_new_tutorial_in_new_tab: false,
  next_tutorial_asset_id: "",
  next_tutorial_id: "",
  next_tutorial_start_url_from_id: "",
  next_tutorial_total_steps_from_id: 0,
  scroll_to_element: false,
};

export const getParsedOnboardingTutorial = ({
  data,
}: AxiosResponse<OnboardingTutorialResponse>): ReturnTypeOfParsedOnboardingTutorial => {
  const { records = [] } = data || {};

  try {
    const parsedSteps = records
      ?.sort((a, b) => a?.fields?.["step-num"] - b?.fields?.["step-num"])
      ?.map((step) => {
        const { fields } = step || {};
        const splittedActionText = fields?.["step-action-text"]?.split("*");

        const prefixActionText = splittedActionText?.[0] || "";
        const boldActionText = splittedActionText?.[1] || "";
        const suffixActionText = splittedActionText?.[2] || "";

        return {
          step_num: fields?.["step-num"] || 0,
          step_title:
            fields?.["upd-step-title"] || fields?.["step-title"] || "",
          step_desc: fields?.["upd-step-desc"] || fields?.["step-desc"] || "",
          step_action: fields?.["step-action"],
          step_action_text: prefixActionText,
          step_element_id:
            fields?.["step-element-id"] === "dummy-noelement"
              ? ""
              : fields?.["step-element-id"] || "",
          visited: false,
          required_field: !!fields?.options?.includes("is-required-field"),
          show_next_btn: !!fields?.options?.includes("show-next-btn"),
          step_action_bold_text: boldActionText,
          step_action_suffix_text: suffixActionText,
          tooltip_offset: fields?.["tooltip-offset"]
            ? jsonParse(fields?.["tooltip-offset"], false)
            : undefined,
          tooltip_placement: fields?.["tooltip-placement"] || "right-start",
          remove_next_element_id_from_dom: !!fields?.options?.includes(
            "remove-next-element-id-from-dom"
          ),
          close_drawer_on_next: !!fields?.options?.includes(
            "close-drawer-on-next"
          ),
          element_to_click_on_next: fields?.["element-to-click-on-next"] || "",
          set_next_elem_visibility_false: !!fields?.options?.includes(
            "set-next-elem-visibility-false"
          ),
          step_element_padding: fields?.["step-element-padding"] || "",
          step_element_margin: fields?.["step-element-margin"] || "",
          is_place_left_arrow_top: !!fields?.options?.includes(
            "is-place-left-arrow-top"
          ),
          is_allow_dropdown_menu: !!fields?.options?.includes(
            "allow-dropdown-menu"
          ),
          is_open_new_tutorial_in_new_tab: !!fields?.options?.includes(
            "is-open-new-tutorial-in-new-tab"
          ),
          next_tutorial_asset_id: fields?.["next-tutorial-asset-id"] || "",
          next_tutorial_id: fields?.["next-tutorial-id"]?.[0] || "",
          next_tutorial_start_url_from_id:
            fields?.["next-tutorial-start-url-from-id"]?.[0] || "",
          next_tutorial_total_steps_from_id:
            fields?.["next-tutorial-total-steps-from-id"]?.[0] || 0,
          scroll_to_element: !!fields?.options?.includes?.("scroll-to-element"),
        };
      });

    const isStepsIncludesHelpStep = parsedSteps?.some(
      (step) => step?.step_element_id === ELEMENT_IDS.userjrn_help_hlp_top_nav
    );

    const shouldAddHelpStep =
      !isStepsIncludesHelpStep &&
      !checkIsChatLandingPage() &&
      !checkIsChatPage();

    const lastStep = parsedSteps?.[parsedSteps?.length - 1];
    const lastStepNum = lastStep?.step_num || 0;

    const finalsteps = shouldAddHelpStep
      ? [
          ...parsedSteps?.slice(0, parsedSteps?.length - 1),
          { ...COMMON_STEP, step_num: lastStepNum },
          { ...lastStep, step_num: lastStepNum + 1 },
        ]
      : parsedSteps;

    return finalsteps;
  } catch {
    return [];
  }
};

function mapDvSumSearchResults(
  records: DvSumHelpSearchResultsResponse["records"]
): ReturnTypeOfDvSumHelpSearchResultItem[] {
  return records?.map((record) => {
    const { id = "", fields } = record || {};
    const {
      asset_id: assetId = "",
      title = "",
      description = "",
      url = "",
      article_type: articleType = "Feature Sets",
    } = fields || {};

    return {
      id,
      asset_id: assetId,
      article_type: articleType,
      title,
      description,
      url,
      thumbnail_image_url:
        fields?.["thumbnail-image-attachment"]?.[0]?.thumbnails?.full?.url ||
        "",
      tutuorial_start_url: fields?.["tutuorial-start-url"] || "",
      gif_attachment: fields?.["gif-attachment"] || [],
      video_duration: fields?.["video-duration"] || "",
      is_show_only_on_dev: !!fields?.["is-show-only-on-dev"],
      total_steps: fields?.["total-steps"] || 0,
      feature_set_order: fields?.feature_set_order || 0,
      is_recent_update_feaure: !!fields?.is_recent_update_feaure,
    };
  });
}

const gatewayForEnvChecker = (
  items: DvSumHelpSearchResultsResponse["records"] = []
): DvSumHelpSearchResultsResponse["records"] => {
  const { REACT_APP_ENV } = getEnvVariables();
  const isDevEnv = REACT_APP_ENV === "dev";

  return isDevEnv
    ? items
    : items?.filter((item) => !item?.fields?.["is-show-only-on-dev"]);
};

export function getParsedDvSumSearchResults(
  res: AxiosResponse<DvSumHelpSearchResultsResponse>,
  isRecentUpdateFeaure?: boolean
): ReturnTypeOfDvSumHelpSearchResultsParser {
  const { records = [] } = res?.data || {};

  let updatedRecords = records;

  if (isRecentUpdateFeaure) {
    const mostRecentTime = Math.max(
      ...records?.map((item) => Date.parse(item?.fields?.released_on) || 0)
    );

    updatedRecords = records?.filter(
      (record) => Date.parse(record?.fields?.released_on) === mostRecentTime
    );
  }

  return {
    articles: mapDvSumSearchResults(
      gatewayForEnvChecker(
        updatedRecords?.filter(
          (item) => item?.fields?.article_type === "Help Center"
        )
      )
    ),
    quick_tips: mapDvSumSearchResults(
      gatewayForEnvChecker(
        updatedRecords?.filter(
          (item) => item?.fields?.article_type === "Quick Tip"
        )
      )
    ),
    videos: mapDvSumSearchResults(
      gatewayForEnvChecker(
        updatedRecords?.filter((item) => item?.fields?.article_type === "Video")
      )
    ),
    tutorial: mapDvSumSearchResults(
      gatewayForEnvChecker(
        updatedRecords?.filter(
          (item) => item?.fields?.article_type === "Tutorial"
        )
      )
    ),
    feature_sets: mapDvSumSearchResults(
      gatewayForEnvChecker(
        updatedRecords?.filter(
          (item) => item?.fields?.article_type === "Feature Sets"
        )
      )
    ),
  };
}

export const releaseOnboardingParser = ({
  data,
}: AxiosResponse<DvSumHelpSearchResultsResponse>): ReturntypeOfReleaseOnboardingHelpParser => {
  const { records = [] } = data || {};

  const { user_info: userInfo } = getPostLoginData();

  const {
    user_last_skip_onb_date: skippedOnbDate = "",
    user_last_dnrm_onb_date: doNotRemindMeOnbDate = "",
    is_user_firstlogin: isFirstLogin = false,
    user_last_rev_onb_date: userLastRevOnbDate = "",
  } = userInfo || {};

  const userClickedOnSkipOnb = Boolean(
    localStorage.getItem(LOCAL_STORAGE_CONSTANTS.user_clicked_on_skip_onb)
  );

  const formattedUserlastRevOnbDate = momentUtc(
    userLastRevOnbDate,
    MOMENT_DATE_FORMATS["YYYY-MM-DD HH:mm:ss"]
  );

  const formattedSkippedDate = momentUtc(
    skippedOnbDate,
    MOMENT_DATE_FORMATS["YYYY-MM-DD HH:mm:ss"]
  );

  const formattedDoNotRemindMeDate = momentUtc(
    doNotRemindMeOnbDate,
    MOMENT_DATE_FORMATS["YYYY-MM-DD HH:mm:ss"]
  );

  const lastReleasedData =
    formattedDoNotRemindMeDate ||
    formattedSkippedDate ||
    formattedUserlastRevOnbDate ||
    // if last released date is not available then we are setting it to 2024-03-01 10:00:00
    // if user first time is viewing this feature i that case there will be not lastReleasedDate
    "2024-03-01 10:00:00";

  const filteredObjects = gatewayForEnvChecker(
    records?.filter((obj) => obj?.fields?.article_type === "Tutorial")
  );

  //get the most recently released object
  const mostRecentDate = Math.max(
    ...filteredObjects?.map((obj) => Date.parse(obj?.fields?.released_on))
  );

  const formattedMostRecentDate = momentUtc(
    mostRecentDate,
    MOMENT_DATE_FORMATS["YYYY-MM-DD HH:mm:ss"]
  );

  //filter the most recently released object
  const mostRecentObjects = filteredObjects?.filter(
    (obj) => Date.parse(obj?.fields?.released_on) === mostRecentDate
  );

  const isAnyUpdateThatUserHasNotSeen =
    formattedMostRecentDate !== formattedUserlastRevOnbDate &&
    formattedMostRecentDate !== formattedDoNotRemindMeDate &&
    formattedMostRecentDate !== formattedSkippedDate &&
    Date.parse(formattedMostRecentDate) > Date.parse(lastReleasedData);

  const showSplashOnFirstVisitAfterRelease =
    !isFirstLogin && isAnyUpdateThatUserHasNotSeen && !!formattedMostRecentDate;

  const showSplashScreenOnNextLoginIfSkip =
    !!formattedSkippedDate &&
    !formattedDoNotRemindMeDate &&
    !formattedUserlastRevOnbDate &&
    !userClickedOnSkipOnb &&
    !!formattedMostRecentDate;

  const releasetitle =
    mostRecentObjects?.find((obj) => obj?.fields?.release_title)?.fields
      ?.release_title || "";

  return {
    show_splash_on_first_login: showSplashOnFirstVisitAfterRelease,
    show_splash_in_case_of_skip: showSplashScreenOnNextLoginIfSkip,
    release_description: "",
    release_heading: releasetitle,
    most_recent_updates_date: formattedMostRecentDate,
    features: mostRecentObjects?.map((obj) => {
      return {
        id: obj?.id || "",
        asset_id: obj?.fields?.asset_id,
        title: obj?.fields?.feature_title || "",
        article_link: obj?.fields?.url || "",
        tutorial_start_url: obj?.fields?.["tutuorial-start-url"] || "",
      };
    }),
  };
};
