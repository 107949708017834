// <--- Library Imports Start --->
import styled from "styled-components";
// <--- Library Imports End --->

// <---  Types Start --->
import { StaticTextRendrerStyledProps } from "./statictextrendrer.types";
// <---  Types End --->

export const StaticTextRendrerStyled = styled.div<StaticTextRendrerStyledProps>`
  color: ${({ theme, color }): string => color || theme.textColors.GREY_101};
  font-style: italic;
  font-size: ${({ fontSize }): string => fontSize || "12px"};
  margin: ${({ margin }): string => margin || ""};
  font-family: ${({ fontFamily }): string => fontFamily || ""};
`;
