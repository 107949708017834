import styled from "styled-components";

export const ModalWrapperStyled = styled.div`
  padding-left: 64px;
  padding-right: 64px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  .form-actions-sec {
    background-color: ${(props): string => props.theme.bgColors.LIGHT_BLUE_25};
    border-top: 1px solid
      ${(props): string => props.theme.borderColors.LIGHT_BLUE_45};
    padding: 8px 18px 8px 18px;

    .ant-form-item-control-input-content {
      display: flex;
      justify-content: flex-end;
    }

    position: relative;
    width: 750px;
    margin-top: 47px;
    margin-bottom: 0px;
  }

  .error {
    max-width: 390px;
  }
`;

export const HeaderWrapper = styled.div`
  svg {
    vertical-align: middle;
    width: 10px;
    height: 10px;
  }
`;

export const FooterWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  .delete-button {
    color: ${({ theme }): string => theme.textColors.RED_100};
  }

  .save-cancel-action {
    display: flex;
    align-items: center;
    column-gap: 5px;
    justify-content: flex-end;
  }
`;
