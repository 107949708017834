import { APP_ROUTES } from "../constants";
import { RuleDetailPageTabType } from "../contexts/ruledetailpagecontext/ruledetailpagecontext.types";
import { ruleDetailPageTabsValues } from "../pages/ruledetailpage/ruledetailpage.views/ruledetailpagecontent/ruledetailpagecontent.types";

export const getRuleDetailPageUrl = (
  id: string,
  tab?: RuleDetailPageTabType
): string => {
  const newTab = tab || ruleDetailPageTabsValues?.definition;

  return `${APP_ROUTES.private_routes.rule_detail}/${id}/${newTab}`;
};
