import styled from "styled-components";

export const GatewayStatusWrapperStyled = styled.div`
  width: 496px;
  height: 268px;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  .heading-sec {
    margin: 16px 0 10px 0;
    font-size: 20px;
    font-family: OpenSansSemiBold;
  }

  .desc-sec {
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme }): string => theme?.textColors?.GREY_24};
    margin-bottom: 20px;
  }
`;
