import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useCancelModal, useGetAppState } from "../../../../customhooks";
import {
  ssaSaveAnalysisFormSchema,
  ssaSaveAnalysisRequiredFormSchema,
} from "../../../../utils/schemas/ssasaveanalysisformschema";
import { Button } from "../../../button/button.component";
import { FormItemStyled, FormStyled } from "../../../form";
import { InputField, TextAreaField } from "../../../formfields";
import {
  NoteStyled,
  SSAHeaderShareModalStyled,
} from "./ssasaveanalysismodal.styles";
import {
  SSASaveAnalysisFormType,
  SSASaveAnalysisModalProps,
} from "./ssasaveanalysismodal.types";
import { checkIsChatPage } from "../../../../utils/ischatpage";
import IconWithText from "../../../iconwithtext/iconwithtext";
import { infoIcon } from "../../../../svgs";
import { ELEMENT_IDS } from "../../../../constants";

const {
  caddi_obj_fld: CADDI_OBJ_FLD,
  caddi_obj_save_btn: CADDI_OBJ_SAVE_BTN,
} = ELEMENT_IDS;

const SSASaveAnalysisModal = (): JSX.Element => {
  const isChatPage = checkIsChatPage();

  const onCancel = useCancelModal();
  const { modal } = useGetAppState();

  const {
    onSaveDoneClick,
    title,
    desc,
    isFromGuideMeFlow = false,
  } = (modal?.modalProps || {}) as SSASaveAnalysisModalProps;

  const onSubmit = useCallback(
    (formValues: SSASaveAnalysisFormType) => {
      onSaveDoneClick(formValues);
      onCancel();
    },
    [onSaveDoneClick]
  );

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<SSASaveAnalysisFormType>({
    defaultValues: {
      title,
      desc,
    },
    mode: "onChange",
    resolver: yupResolver(
      isFromGuideMeFlow
        ? ssaSaveAnalysisRequiredFormSchema
        : ssaSaveAnalysisFormSchema
    ),
  });

  return (
    <SSAHeaderShareModalStyled>
      <FormStyled
        isItemColumnLayout
        onFinish={handleSubmit(onSubmit) as any}
        paddingLeft="112px"
        paddingRight="112px"
      >
        <FormItemStyled label="Name" required marginTop="30px">
          <InputField
            name="title"
            control={control}
            width="530px"
            placeholder="Name"
          />
        </FormItemStyled>
        <FormItemStyled
          label={isChatPage ? "Objective" : "Description"}
          required={isFromGuideMeFlow}
        >
          <TextAreaField
            name="desc"
            control={control}
            height="250"
            width="530px"
            placeholder={isChatPage ? "Objective" : "Description"}
            id={CADDI_OBJ_FLD}
          />
        </FormItemStyled>
        {isChatPage && (
          <NoteStyled>
            <IconWithText
              icon={infoIcon}
              text="The objective will be incorporated into the prompt to generate questions that align with your specific chat goal"
              textFlexDirection="column"
            />
          </NoteStyled>
        )}

        <FormItemStyled
          label=""
          className="form-actions-sec"
          marginBottom="0px"
          marginTop="41px"
        >
          <Button id="cancel" width="74px" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            id="primary"
            width="74px"
            marginLeft="8px"
            htmlType="submit"
            disabled={!isValid}
            tooltipProps={{
              title: !isValid ? "Please fill the required field" : "",
              placement: "right",
            }}
            elementId={CADDI_OBJ_SAVE_BTN}
          >
            Save
          </Button>
        </FormItemStyled>
      </FormStyled>
    </SSAHeaderShareModalStyled>
  );
};

export default SSASaveAnalysisModal;
