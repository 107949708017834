import { useCallback, useMemo, useState, useRef, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import { UnprivilegedEditor } from "react-quill";

import { useDebounce } from "../../customhooks";

import { addInfoIcon, crossIconInCircle } from "../../svgs";

import FilePreviewer from "../filepreviewer";

import LinkButton from "../linkbutton";
import AddedItem from "./addeditem";

import { AdditionalInfoStyled } from "./additionalinfo.styles";

import { AdditionalInfoTypeProps } from "./additionalinfo.types";
import TextEditor from "../texteditor";
import Flex from "../flex";

const EDITOR_TOOLBAR_OPTIONS = [
  [{ header: "1" }, { header: "2" }, { font: [] }],
  [{ size: [] }],
  [{ color: [] }, { background: [] }],
  ["bold", "italic", "underline", "strike", "blockquote"],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ indent: "-1" }, { indent: "+1" }],
  ["link"],
];

function AdditionalInfo(props: AdditionalInfoTypeProps): JSX.Element {
  const { setState, isEditable, state, setIsSaveDisabled } = props;

  const [hasError, setError] = useState<boolean>(false);

  const actionElmRef = useRef<HTMLDivElement>(null);

  const validUrlDebounce = useDebounce(state?.currentValue, 500);

  const [isTextEditorEmpty, setIsTextEditorEmpty] = useState<boolean>(true);
  const [editModeItemId, setEditModeItemId] = useState<string>("");

  const isActionDisabled =
    isTextEditorEmpty || !state?.currentValue || hasError;

  useEffect(() => {
    if (setIsSaveDisabled) {
      setIsSaveDisabled(!isActionDisabled);
    }
  }, [isActionDisabled]);

  const onAddItem = useCallback(() => {
    if (editModeItemId) {
      const addedItems = state?.addedItems?.map((item) => {
        if (item?.id === editModeItemId) {
          return {
            ...item,
            value: state?.currentValue,
          };
        }
        return item;
      });

      setState((st) => ({
        ...st,
        addedItems,
        currentValue: undefined,
      }));

      setEditModeItemId("");
    } else if (!isActionDisabled) {
      const addedItems = [
        ...state?.addedItems,
        {
          actionId: state?.currentAction,
          value: state?.currentValue,
          id: uuidv4(),
        },
      ];

      setState((st) => ({
        ...st,
        addedItems,
        currentValue: undefined,
        id: uuidv4(),
      }));
    }
  }, [state, isActionDisabled, editModeItemId]);

  const onCancelEdit = useCallback(() => {
    setEditModeItemId("");
    setState((st) => ({
      ...st,
      currentValue: undefined,
    }));
  }, []);

  const onChangeEditor = useCallback(
    (
      editorValue: string,
      _delta: any,
      _source: any,
      editor: UnprivilegedEditor
    ) => {
      if (editor?.getLength() - 1 > 5000) {
        setError(true);
      } else {
        setIsTextEditorEmpty(
          !editor?.getHTML()?.includes("<img") &&
            !editor?.getText()?.replaceAll("\n", "")?.trim()?.length
        );

        setState((st) => ({
          ...st,
          currentValue: editorValue,
        }));

        setError(false);
      }
    },
    []
  );

  const currentElement = useMemo(() => {
    switch (state?.currentAction) {
      case "edit":
        return (
          <TextEditor
            value={state?.currentValue || ""}
            onChange={onChangeEditor}
            modules={{
              toolbar: {
                container: EDITOR_TOOLBAR_OPTIONS,
              },
            }}
          />
        );
      default:
        return (
          <FilePreviewer
            onChange={(file): boolean => {
              setState((st) => ({ ...st, currentValue: file }));
              return false;
            }}
          />
        );
    }
  }, [state.currentAction, state?.currentValue]);

  const onRemoveItem = useCallback(
    (id) => {
      id
        ? setState((st) => ({
            ...st,
            addedItems: st?.addedItems?.filter((item) => item?.id !== id),
          }))
        : setState((st) => ({
            ...st,
            currentValue: "",
          }));
    },
    [state]
  );

  const onEditItem = useCallback(
    (id: string) => {
      setState((st) => ({
        ...st,
        currentValue:
          st?.addedItems?.find((item) => item?.id === id)?.value || "",
      }));

      setEditModeItemId(id);

      actionElmRef?.current?.scrollIntoView({ behavior: "smooth" });
    },
    [actionElmRef]
  );

  const onKeyDown = useCallback(
    (e) => {
      if (e?.ctrlKey && e?.key === "Enter") {
        onAddItem();
      }
    },
    [onAddItem]
  );

  const previewElement = useMemo(() => {
    switch (state?.currentAction) {
      case "edit":
        return (
          <TextEditor
            value={state?.currentValue || ""}
            onChange={onChangeEditor}
            onKeyDown={onKeyDown}
            modules={{
              toolbar: {
                container: EDITOR_TOOLBAR_OPTIONS,
              },
            }}
          />
        );
      default:
        return (
          <FilePreviewer
            file={state?.currentValue}
            isAdded={false}
            id={undefined}
            onRemove={onRemoveItem}
          />
        );
    }
  }, [state.currentAction, state.currentValue, validUrlDebounce]);

  return (
    <AdditionalInfoStyled isEditable={isEditable}>
      {state?.addedItems?.map((item) => (
        <AddedItem
          key={`additional-info-added-item-${item?.id}`}
          item={item}
          onRemove={isEditable ? onRemoveItem : undefined}
          onEdit={onEditItem}
          isEditable
          isInEditMode={item?.id === editModeItemId}
        />
      ))}
      <div className="action-elm" ref={actionElmRef}>
        {state?.currentValue ? previewElement : currentElement}
        {hasError && (
          <div className="error-message">
            Field reached the 5000 characters limit
          </div>
        )}
      </div>
      <div className="actions-sec">
        <div />
        <Flex columnGap={12}>
          {editModeItemId && (
            <LinkButton onClick={onCancelEdit}>
              {crossIconInCircle("37px", "37px")}
            </LinkButton>
          )}

          <LinkButton
            type="link"
            onClick={onAddItem}
            disabled={isActionDisabled}
          >
            {addInfoIcon("37px", "37px")}
          </LinkButton>
        </Flex>
      </div>
    </AdditionalInfoStyled>
  );
}

export default AdditionalInfo;
