import { useQueries } from "react-query";
import { useMemo } from "react";

import { appendQueryParamInUrl, fetchRequestWithoutHeaders } from "../../api";

import { API_CONFIG } from "../../constants/apiconfig";
import { SAWSParserReturnType } from "../../parsers";

import {
  getParsedDownloadLink,
  getParsedSAWS,
} from "../../parsers/accountsettings/accountsettingsparser";

import { SAWSDownloadLinkParserReturnType } from "../../parsers/accountsettings/accountsettingsparser.types";
import { useGetAll } from "../baseservice";

import { CustomReactQueryHookReturnType } from "../services.types";
import { NodeType } from "../../app.types";

const {
  get_all_saws: getAllSAWS,
  download_saws: getSAWSDownloadLink,
  download_saws_config: getSAWSDownloadInstallerLink,
  ping_check: pingCheck,
} = API_CONFIG;

export function useGetAllSAWS(
  enabled = true,
  refetchOnMount = true
): CustomReactQueryHookReturnType<SAWSParserReturnType[]> {
  return useGetAll({
    apiConfig: getAllSAWS,
    parser: getParsedSAWS,
    options: { enabled, refetchOnMount },
  });
}

export const useGetSAWSDownloadLink = (): CustomReactQueryHookReturnType => {
  return useGetAll({
    apiConfig: getSAWSDownloadLink,
    parser: getParsedDownloadLink,
  });
};

export const usePingCheck = (
  enabled: boolean,
  allGateways: { host_name: string; port: string; id: string }[],
  shouldRefetch = true
): {
  [key: string]: {
    isLoading: boolean;
    isGatewayRuning: boolean;
    id: string;
  };
} => {
  const fetcher = fetchRequestWithoutHeaders("POST");
  const apiRequest = allGateways?.map((item) => {
    return {
      queryKey: appendQueryParamInUrl(API_CONFIG.ping_check.url, [
        item?.host_name,
        item?.port,
        item?.id,
      ]),
      queryFn: fetcher,
      ...(shouldRefetch && { refetchInterval: 60000 }),
      retry: false,
    };
  });

  const config = useQueries(enabled ? apiRequest : []);

  return config?.reduce((prev, next) => {
    const id = (next?.data as any)?.config?.url?.split("q=")?.[1] || "";
    return {
      ...prev,
      ...(id && {
        [id]: {
          isLoading: next?.isLoading,
          isGatewayRuning: next?.status === "success",
          id,
        },
      }),
    };
  }, {});
};

export const usePingCheckRetry = (
  hostName: string,
  port: string,
  gatewayId: string,
  onSuccess: () => void,
  onError: () => void
): CustomReactQueryHookReturnType<any> => {
  return useGetAll({
    apiConfig: API_CONFIG.ping_check,
    params: [hostName, port, gatewayId],
    options: { enabled: false, retry: 1, onSuccess, onError },
    isReqWithoutHeader: true,
    fetchRequestWithoutHeadersMethod: "POST",
  });
};

export const useCheckGatewayConnectionStatus = (args?: {
  host: string;
  port: string;
  isOnPremSaws: boolean;
  tableId?: string;
}): CustomReactQueryHookReturnType<boolean> => {
  const { host = "", isOnPremSaws = "", port = "", tableId = "" } = args || {};

  const config = useGetAll({
    apiConfig: pingCheck,
    params: isOnPremSaws ? [host, port, tableId] : [host, "", tableId],
    isReqWithoutHeader: true,
    fetchRequestWithoutHeadersMethod: "POST",
    options: { enabled: !!args, refetchOnMount: false },
  });

  const response = useMemo(() => {
    return {
      ...config,
      parsedData: config?.data?.status === 200,
    };
  }, [config]);

  return response;
};

export const useGetSAWSInstallerDownloadLink = (): CustomReactQueryHookReturnType<SAWSDownloadLinkParserReturnType> => {
  return useGetAll({
    apiConfig: getSAWSDownloadInstallerLink,
    parser: getParsedDownloadLink,
  });
};

export const useGetSourceGatewayInfo = (
  id: string, //Id could be source id and bot id
  type: NodeType,
  enabled: boolean,
  onSuccess?: () => void
): CustomReactQueryHookReturnType<SAWSParserReturnType[]> => {
  return useGetAll({
    apiConfig: API_CONFIG.get_source_gateway_info,
    params: ["", id, type],
    options: { enabled: !!Number(id) && enabled, onSuccess },
    parser: getParsedSAWS,
  });
};
