import { ELEMENT_IDS } from "../../constants";
import { isJsonString, jsonParse, sortObjectsArrayByKey } from "../../utils";

import {
  DqRuleAccumaltorType,
  DqRuleType,
  DqRulesResponseType,
  RuleDetailsObjType,
} from "./glossarypageheader.types";

const {
  datdict_detpg_dq_ad_rul_piplin_check_btn: DATDICT_DETPG_DQ_AD_RUL_PIPLIN_CHECK_BTN,
  datdict_detpg_dq_ad_rul_cnt_btn: DATDICT_DETPG_DQ_AD_RUL_CNT_BTN,
} = ELEMENT_IDS;

export const getParsedDqRulesCategoriesAndTypes = (
  dqRules: DqRulesResponseType
): DqRuleAccumaltorType => {
  const sortedDqRules = sortObjectsArrayByKey(dqRules, "displayOrder");

  const getParsedRuleItem = (item: DqRuleType): RuleDetailsObjType => {
    return {
      name: item?.name,
      category: item?.id,
      disabled: item?.disabled,
      desc: item?.description,
      elementId: item?.id === "CNT" ? DATDICT_DETPG_DQ_AD_RUL_CNT_BTN : "",
    };
  };

  const rules = sortedDqRules?.reduce(
    (acc: DqRuleAccumaltorType, item: DqRuleType) => {
      const rulesCategory = isJsonString(item?.adhocInfo || "")
        ? jsonParse(item?.adhocInfo)
        : {};

      const dqRuleCategoryExists = acc?.find(
        (entry) => rulesCategory?.cat_id === entry?.category
      );

      if (dqRuleCategoryExists) {
        dqRuleCategoryExists?.children?.push(getParsedRuleItem(item));
      } else {
        acc?.push({
          category: rulesCategory?.cat_id,
          name: rulesCategory?.cat_name,
          children: [getParsedRuleItem(item)],
          elementId:
            rulesCategory?.cat_id === "PLC"
              ? DATDICT_DETPG_DQ_AD_RUL_PIPLIN_CHECK_BTN
              : "",
        });
      }

      return acc;
    },
    []
  );

  return rules;
};
