export const isDateInRange = (
  startDate: string | Date | number,
  endDate: string | Date | number,
  dateToCheck: string | Date | number,
  includeEndDate?: boolean
): boolean => {
  try {
    const dateToCheckDateType = new Date(dateToCheck);
    return (
      dateToCheckDateType >= new Date(startDate) &&
      (includeEndDate
        ? dateToCheckDateType <= new Date(endDate)
        : dateToCheckDateType <= new Date(endDate))
    );
  } catch {
    return false;
  }
};
