import { useCallback, useState } from "react";

// <--- Components Start --->
import SectionWithBody from "../../../../../sectionwithbody";

import {
  DQRuleDetailsSummarySec,
  DQRuleDetailsGridSection,
  DQRuleDetailsModalRightSec,
} from "./dqruledetailsmodaldetailssec.components";
// <--- Components End --->

// <--- Services Start --->
import { useGetDQRuleDetailsBarChartData } from "../../../../../../api/dataqualityservice";
// <--- Services End --->

// <--- Styles Start --->
import { DQRuleDetailsModalDetailsSecStyled } from "./dqruledetailsmodaldetailssec.styles";
// <--- Styles End --->

// <--- SVGs Start --->
import {
  databaseTableIcon,
  horizentalBarChartIcon,
} from "../../../../../../svgs";
// <--- SVGs End --->

// <--- Types Start --->
import { DQRuleDetailsModalDetailsSecProps } from "./dqruledetailsmodaldetailssec.types";
// <--- Types End --->

export function DQRuleDetailsModalDetailsSec(
  props: DQRuleDetailsModalDetailsSecProps
): JSX.Element {
  const {
    dqScore = { score: "", status: "", statusId: "" },
    nodeId = "",
    nodeName = "",
    ruleHistoryId = "",
    ruleTypeId,
    ruleAlert = "",
    isLoadingDQRuleDetails = false,
  } = props;

  const isTableDrift = ruleTypeId === "TDD" || ruleTypeId === "TSD";

  const [view, setView] = useState("summary");

  const [paginatedRuleHistory, setPaginatedRuleHistory] = useState<string>(
    ruleHistoryId
  );

  const {
    parsedData: parsedDataBarChart,
    isLoading: isLoadingBarChart,
    error: errorBarChart,
  } = useGetDQRuleDetailsBarChartData(nodeId, paginatedRuleHistory);

  const {
    date = "",
    next_id: nextId = "",
    prev_id: prevId = "",
    rule_details: ruleDetails = { download_csv_url: "", sample_data_url: "" },
  } = parsedDataBarChart || {};

  const onNextClick = useCallback(() => {
    setPaginatedRuleHistory(nextId);
  }, [nextId]);

  const onPrevClick = useCallback(() => {
    setPaginatedRuleHistory(prevId);
  }, [prevId]);

  const onChangeView = useCallback((selected: string) => {
    setView(selected);
  }, []);

  return (
    <DQRuleDetailsModalDetailsSecStyled
      data-testid={`rule-type-detail-modal-details-sec-component-${nodeId}`}
    >
      <SectionWithBody
        headingProps={{
          title: view === "summary" ? "Summary" : "Details",
          fontFamily: "OpenSansSemiBold",
          fontSize: "17.6px",
          fontWeight: 600,
          isShowIcon: false,
          rightSec: (
            <DQRuleDetailsModalRightSec
              toggleButtonProps={{
                content: [
                  {
                    id: "summary",
                    tooltip: "View Summary",
                    icon: horizentalBarChartIcon("21", "21"),
                  },
                  {
                    id: "details",
                    tooltip: isTableDrift
                      ? "Details view not available for tables"
                      : "View Exceptions Detail",
                    icon: databaseTableIcon("14", "14"),
                    disabled: isTableDrift,
                  },
                ],
                selectedItem: view,
                onChangeView,
                isDisableButtons: isLoadingDQRuleDetails || isLoadingBarChart,
              }}
              downloadLink={ruleDetails?.download_csv_url}
            />
          ),
        }}
      >
        {view === "summary" ? (
          <DQRuleDetailsSummarySec
            nodeId={nodeId}
            dqScore={dqScore}
            ruleAlert={ruleAlert}
            ruleTypeId={ruleTypeId}
            ruleHistoryId={ruleHistoryId}
            onNextClick={onNextClick}
            onPrevClick={onPrevClick}
            parsedDataBarChart={parsedDataBarChart}
            isLoadingBarChart={isLoadingBarChart}
            errorBarChart={errorBarChart}
          />
        ) : (
          <DQRuleDetailsGridSection
            timeTravelDate={date}
            csvUrl={ruleDetails?.sample_data_url}
            nodeName={nodeName}
          />
        )}
      </SectionWithBody>
    </DQRuleDetailsModalDetailsSecStyled>
  );
}

export default DQRuleDetailsModalDetailsSec;
