// <--- Constants Start --->
import { tooltipItems } from "./ruledefinitiontooltip.constants";
// <--- Constants End --->

// <--- Styles Start --->
import { RuleDefinitionTooltipStyled } from "./ruledefinitiontooltip.styles";
// <--- Styles End --->

const RuleDefinitionTooltip = (): JSX.Element => {
  return (
    <RuleDefinitionTooltipStyled rowGap={5} direction="column">
      {tooltipItems?.map((item) => (
        <div className="tooltip-item" key={item?.key}>
          <div className="tooltip-item-heading">{item?.heading}:</div>
          <div className="tooltip-item-description">{item?.description}</div>
        </div>
      ))}
    </RuleDefinitionTooltipStyled>
  );
};

export default RuleDefinitionTooltip;
