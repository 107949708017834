import { DataSourceDetailPageParamViewKeys } from "./datasourcedetailpage.types";

export const dataSourceDetailPageTabsValues: {
  [key in DataSourceDetailPageParamViewKeys]: DataSourceDetailPageParamViewKeys;
} = {
  overview: "overview",
  data: "data",
  pages: "pages",
};

export const dataSoruceDetailPageTabs = [
  { key: dataSourceDetailPageTabsValues.overview, value: "Overview" },
  { key: dataSourceDetailPageTabsValues.data, value: "Data" },
  { key: dataSourceDetailPageTabsValues.pages, value: "Pages" },
];
