import { useParams } from "react-router";
import { useEffect } from "react";

import { useCancelModal, useGetAppState } from "../../../../customhooks";
import { useGetRefByType } from "../../../../api/refservice";

import { useGetGovernanceViewFields } from "../../../../api/listingsservice";
import { WidgetCreationWizardStyled } from "./widgetcreationwizard.styles";

import { WidgetCreationWizardPropsType } from "./widgetcreationwizard.types";
import {
  AddWidgetCreateOrPick,
  AddWidgetForm,
  AddWidgetPickFromGallery,
} from "./widgetcreationwizard.comp";

import {
  useWidgetCreationPageContext,
  WidgetCreationPageProvider,
} from "../../../../contexts/widgetcreationcontext/widgetcreationcontext";
import {
  jsonParse,
  jsonStringify,
  sortListOnSpecificKeyValue,
} from "../../../../utils";
import { All_GOV_CATEGORY } from "../../../../contexts/widgetcreationcontext/widgetcreationcontext.config";
import { getGovViewListWithWidgets } from "./widgetcreationwizard.util";

const WidgetCreationWizardContent = (): JSX.Element => {
  const onCancel = useCancelModal();

  const { modal: { modalProps = {} } = {} } = useGetAppState();

  const {
    selectedViewId = "ALL",
    isEditMode = false,
    isCloneMode = false,
    propsOnSuccess,
    orderOfWidget,
    selectedWidgetId,
    selectedGovernanceId,
    selectedWidgetConfig,
    dashboardId,
  } = modalProps as WidgetCreationWizardPropsType;

  const { state, updateState } = useWidgetCreationPageContext();
  const { currentViewId } = state;

  const {
    parsedData: widgets,
    isLoading: isLoadingTemplatesTypeRefs,
    error: errorTemplatesTypeRefs,
  } = useGetRefByType("WIDGET_TEMPLATES");

  const {
    parsedData: govCategoryTypeRefs,
    isLoading: isLoadingGovCategoryTypeRefs,
    error: errorGovCategoryTypeRefs,
  } = useGetRefByType("GOV_VIEW_CATEGORY");

  const sortedGovViewsCat = sortListOnSpecificKeyValue({
    key: "displayOrder",
    list: govCategoryTypeRefs,
    type: "number",
    sortAscending: false,
  });

  const pageLoading =
    isLoadingTemplatesTypeRefs || isLoadingGovCategoryTypeRefs;

  const pageError = errorTemplatesTypeRefs || errorGovCategoryTypeRefs;

  // for clone and edit
  const selectedWidget = widgets?.filter(
    (widData) => Number(widData?.id) === Number(selectedWidgetId)
  )?.[0];

  const selectedWidgetInfo = { ...selectedWidget, order: orderOfWidget };

  useEffect(() => {
    const groupedWidByCat = getGovViewListWithWidgets(
      sortedGovViewsCat,
      widgets
    );

    const selectedGovernanceCategory = groupedWidByCat?.find(
      (govData) =>
        Number(govData?.id) === Number(selectedWidgetConfig?.govViewCategory)
    );
    const alreadyWidgetExists = isCloneMode || isEditMode;

    const selectedGovCategory = alreadyWidgetExists
      ? selectedGovernanceCategory
      : All_GOV_CATEGORY;

    updateState({
      ...state,
      currentViewId: alreadyWidgetExists ? "WF" : state?.currentViewId,
      selectedGovernanceCategory: selectedGovCategory,
      governanceViewCategories: [...groupedWidByCat],
      governanceViewCategoriesLoading: pageLoading,
      governanceViewCategoriesError: pageError,
      selectedWidgetConfig,
      isEditMode,
      isCloneMode,
      dashboardId: String(dashboardId),
    });
  }, [sortedGovViewsCat?.length, widgets?.length, modalProps]);

  const pageContent = (): JSX.Element => {
    switch (currentViewId) {
      case "COR":
        return <AddWidgetCreateOrPick />;

      case "PFG":
        return <AddWidgetPickFromGallery />;

      case "WF":
        return <AddWidgetForm propsOnSuccess={propsOnSuccess} />;

      default:
        return <AddWidgetCreateOrPick />;
    }
  };

  return (
    <WidgetCreationWizardStyled>{pageContent()}</WidgetCreationWizardStyled>
  );
};

const WidgetCreationWizard = (): JSX.Element => {
  return (
    <WidgetCreationPageProvider>
      <WidgetCreationWizardContent />
    </WidgetCreationPageProvider>
  );
};

export default WidgetCreationWizard;
