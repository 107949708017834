import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

import { Button } from "../../components";
import { SelectField } from "../../components/formfields";
import StateHandler from "../../components/statehandler/statehandler";

import { API_CONFIG } from "../../constants/apiconfig";
import { useRequestWithMethod } from "../../api";
import { useGetAllGlossaryCategoriesForSelect } from "../../api/glossarycategoriesservice/glossarycategoriesservice";
import { deleteGlossaryCategorySchema } from "../../utils/schemas/glossarycategorieschemas";

import { useCancelModal, useGetAppState } from "../../customhooks";

import { DeleteGlossaryCategoryType } from "./deleteglossarycategoryform.types";
import { GlossaryCategoriesGridColumnType } from "../../pages/glossarycategoriespage/glossarycategoriespage.types";

import { FormItemStyled, FormStyled } from "../../components/form";
import { DeleteGlossaryCategoryFormStyled } from "./deleteglossarycategoryform.styles";

const DeleteGlossaryCategoryForm = (): JSX.Element => {
  const {
    parsedData: parsedGlossaryCategories = [],
    isLoading: isLoadingCategories,
    error: errorCategories,
  } = useGetAllGlossaryCategoriesForSelect();

  const {
    modal: { modalProps = {} },
  } = useGetAppState();
  const queryClient = useQueryClient();
  const onCancel = useCancelModal();

  const { id = 0, name = "" } = modalProps as GlossaryCategoriesGridColumnType;

  const parsedCategory = useMemo(() => {
    return parsedGlossaryCategories?.filter(
      (category) => !category.label.includes(`${name} >`)
    );
  }, [parsedGlossaryCategories, name]);

  const {
    control,
    setValue,
    handleSubmit,
    formState: { isValid },
  } = useForm<DeleteGlossaryCategoryType>({
    defaultValues: {
      assign_terms_to: undefined,
    },
    resolver: yupResolver(deleteGlossaryCategorySchema),
    mode: "onChange",
  });

  // APIs Integration
  const onSuccess = useCallback(() => {
    const api = API_CONFIG?.get_all_glossary_categories?.url;
    queryClient.invalidateQueries(api, { fetching: false });
    onCancel();
  }, []);

  const {
    onExecuteRequest,
    isLoading: isLoadingDeleteCategory,
    error: errorDeleteCategory,
  } = useRequestWithMethod("delete_glossary_category", [id], true, onSuccess);

  // Callbacks
  const onSubmit = useCallback((values) => {
    onExecuteRequest({
      new_category_id: Number.parseInt(values?.assign_terms_to) || 0,
    });
  }, []);

  return (
    <StateHandler
      error={errorCategories || errorDeleteCategory}
      isFetching={isLoadingCategories || isLoadingDeleteCategory}
      isModal
    >
      <DeleteGlossaryCategoryFormStyled>
        <FormStyled
          isItemColumnLayout
          paddingLeft="110.5px"
          onFinish={handleSubmit(onSubmit) as any}
        >
          <div className="content-sec">
            <div className="static-text">
              You must assign terms of
              <span className="bold"> {name}</span> to another sub-domain.
            </div>
            <FormItemStyled label="Assign Terms To" required>
              <SelectField
                control={control}
                name="assign_terms_to"
                setValue={setValue}
                options={parsedCategory}
                width="396px"
                height="45.36px"
                placeholder="Select Sub-domain"
                isAllowClear={false}
              />
            </FormItemStyled>
          </div>

          <FormItemStyled
            label=""
            className="form-actions-sec"
            marginBottom="0px"
          >
            <Button id="cancel" width="74px" height="39px" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              id="primary"
              width="74px"
              height="39px"
              marginLeft="8px"
              htmlType="submit"
              disabled={!isValid}
            >
              Re-Assign & Delete
            </Button>
          </FormItemStyled>
        </FormStyled>
      </DeleteGlossaryCategoryFormStyled>
    </StateHandler>
  );
};

export default DeleteGlossaryCategoryForm;
