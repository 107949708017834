import styled from "styled-components";
import { Alert } from "antd";

export const AlertStyled = styled(Alert)<{
  width?: string;
  type: any;
  height?: string;
  padding?: string;
  margin?: string;
  useFlexStart?: boolean;
}>`
  &.ant-alert {
    ${({ padding }): any => padding && `padding: ${padding};`};
    ${({ useFlexStart }): any => useFlexStart && `align-items: flex-start`};
  }

  &.ant-alert {
    ${({ margin }): any => margin && `margin: ${margin};`}
  }

  border: ${({ type, theme }): string =>
    type === "info"
      ? `1px solid ${theme.borderColors.LIGHT_BLUE_103}`
      : type === "warning"
      ? `1px solid ${theme.borderColors.LIGHT_YELLOW_COLOR}`
      : type === "message"
      ? `1px solid ${theme.borderColors.GREY_19}`
      : ""};

  background-color: ${({ type, theme }): string =>
    type === "info"
      ? theme.borderColors.LIGHT_BLUE_104
      : type === "warning"
      ? theme.borderColors.YELLOW_12
      : type === "message"
      ? theme.borderColors.GREY_19
      : ""};

  ${({ width }): any => width && `width: ${width};`};

  ${({ height }): any => height && `height: ${height};`}

  &.no-border {
    border: none;
  }

  &.bg-transparent {
    background: transparent;
  }

  .ant-alert-content {
    .ant-alert-message {
      color: ${({ theme }): string => theme.textColors.GREY_90};
      font-family: OpenSans;
      font-size: 14px;
    }
  }
`;
