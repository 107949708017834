import { useGetAll } from "../baseservice";

import {
  getParsedAPIKey,
  getParsedFirewallEnforced,
  getParsedIpAddresses,
} from "../../parsers/accountsettings/accountsettingsparser";

import { API_CONFIG } from "../../constants/apiconfig";

import { CustomReactQueryHookReturnType } from "../services.types";
import { FirewallEnforcedParserReturnType } from "../../parsers/accountsettings/accountsettingsparser.types";

const {
  get_current_ip_address: getCurrentIPAddress,
  get_api_key: getApiKey,
  get_ip_addresses: getIpAddresses,
  get_firewall_enforced: getFirewallEnforced,
} = API_CONFIG;

export function useGetApiKey(): CustomReactQueryHookReturnType {
  return useGetAll({ apiConfig: getApiKey, parser: getParsedAPIKey });
}

export function useGetAllIPAddresses(): CustomReactQueryHookReturnType {
  return useGetAll({
    apiConfig: getIpAddresses,
    parser: getParsedIpAddresses,
  });
}

export function useGetCurrentIPAddress(): CustomReactQueryHookReturnType {
  return useGetAll({
    apiConfig: getCurrentIPAddress,
    options: {
      enabled: false,
      keepPreviousData: false,
    },
    isReqWithoutHeader: true,
  });
}

export const useGetFirewallEnforced = (): CustomReactQueryHookReturnType<FirewallEnforcedParserReturnType> => {
  return useGetAll({
    apiConfig: getFirewallEnforced,
    parser: getParsedFirewallEnforced,
  });
};
