import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Button } from "../../components";
import { FormStyled, FormItemStyled } from "../../components/form";
import {
  InputField,
  SelectField,
  TextAreaField,
} from "../../components/formfields";
import { setModal } from "../../reducers/appreducer/appreducer";
import { CreateListFormStyled } from "./createlistform.styles";
import { CreateListFormType } from "./createlistform.types";

function CreateListForm(): JSX.Element {
  const { control, setValue } = useForm<CreateListFormType>({
    defaultValues: {
      desc: "",
      list_name: "",
    },
  });
  const dispatch = useDispatch();

  const onCancel = useCallback(() => {
    dispatch(setModal({}));
  }, []);

  return (
    <CreateListFormStyled>
      <FormStyled isItemColumnLayout>
        <FormItemStyled
          label="List Name"
          required
          marginBottom="22px"
          paddingLeft="137px"
        >
          <InputField
            control={control}
            name="list_name"
            width="495px"
            height="52.7px"
          />
        </FormItemStyled>
        <FormItemStyled
          label="List Description"
          marginBottom="22px"
          paddingLeft="137px"
        >
          <TextAreaField
            control={control}
            name="list_description"
            width="495px"
            height="175px"
          />
        </FormItemStyled>
        <FormItemStyled label="Alert Frequncy" required paddingLeft="137px">
          <SelectField
            control={control}
            name="list_name"
            width="495px"
            height="56.7px"
            options={[
              { label: "Relevance", value: "Relevance" },
              { label: "Option1", value: "Option1" },
              { label: "Option2", value: "Option2" },
            ]}
            setValue={setValue}
            placeholder="Select"
          />
        </FormItemStyled>
        <FormItemStyled
          className="form-actions-sec"
          label=""
          marginTop="61.6px"
        >
          <Button fontSize="18px" width="74px" height="39px">
            Save
          </Button>
          <Button
            fontSize="18px"
            width="74px"
            height="39px"
            onClick={onCancel}
            marginLeft="5px"
          >
            Cancel
          </Button>
        </FormItemStyled>
      </FormStyled>
    </CreateListFormStyled>
  );
}

export default CreateListForm;
