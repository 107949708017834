import { Spin } from "antd";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";

import {
  FilterInputWrapperStyled,
  FilterRowAndRemoveButtonWrapperStyled,
  FilterRowWithLogicalOperStyled,
} from "./filterrow.styles";
import { FilterRowProps } from "./filterrow.types";

import {
  AddCriteria,
  SpecifyFilterCriteriaType,
  TypeOfInputField,
} from "../../filtercriteria.types";
import {
  DatePickerField,
  InputField,
  SelectField,
  TextAreaField,
} from "../../../formfields";

import LinkButton from "../../../linkbutton/linkbutton";
import { deleteIcon } from "../../../../svgs";

import StateHandler from "../../../statehandler/statehandler";
import { accountSettingsInputBlankSlate } from "../../../../blankslates/accountsettingsblankslates";

import { SelectFieldOption } from "../../../formfields/selectfield/selectfield.types";
import { selectFilterOption } from "../../../../utils";

import { LoadingSpinerStyled } from "../../../../styles/app.styles";
import { AndOrToggleButton } from "../../../andortogglebutton/andortogglebutton";

const removeRowBtn = deleteIcon;
export const DATE_FORMAT = "MM/DD/YYYY";

export const ROW_FILTER_TYPE_OPTIONS = [
  {
    label: "Filter Condition",
    value: "CND",
  },
  {
    label: "Custom Expression",
    value: "EXP",
  },
];

const FilterRow = (props: FilterRowProps): JSX.Element => {
  const { control, setValue, setError } = useFormContext<AddCriteria>();

  const {
    filterRowIndex,
    onRemoveFilterRow,
    criteriaRowIndex,
    filterRow,
    name,
    isReadMode,
    onHandleFilterRowValues,
    onResetFilterRowValues,

    columns,
    filters,
    inputValues,
    isInputValuesFetching,
    isErrorInFetchingInputValues,
    isInputValuesFetchingInEditMode,
    onFilterConditionLogicalOperatorChange,
  } = props;

  const onFilterRowInputValueChange = useCallback(
    (params: {
      updatedObj: SpecifyFilterCriteriaType;
      finalSelectOption?: SelectFieldOption[];
      typeOfInputField?: TypeOfInputField;
      isSingleSelect?: boolean;
      shouldReset?: boolean;
    }) => {
      const {
        updatedObj,
        finalSelectOption,
        typeOfInputField,
        isSingleSelect,
        shouldReset,
      } = params;

      onHandleFilterRowValues(
        criteriaRowIndex,
        filterRowIndex,
        updatedObj,
        typeOfInputField,
        finalSelectOption,
        isSingleSelect
      );

      shouldReset &&
        onResetFilterRowValues({
          criteriaRowIndex,
          filterRowIndex,
          typeOfInputField,
        });
    },
    [
      onHandleFilterRowValues,
      onResetFilterRowValues,
      filterRowIndex,
      criteriaRowIndex,
    ]
  );

  return (
    <FilterRowAndRemoveButtonWrapperStyled>
      <FilterRowWithLogicalOperStyled>
        <>
          {filterRowIndex === 0 ? (
            <div className="logical-condition-renderer">
              <span className="text">WHERE</span>
            </div>
          ) : filterRowIndex === 1 ? (
            <div className="logical-condition-renderer">
              <AndOrToggleButton
                disabled={isReadMode}
                selectedOption={filterRow?.next_operation || "AND"}
                options={["AND", "OR"]}
                onClick={(option): void => {
                  onFilterConditionLogicalOperatorChange(
                    criteriaRowIndex,
                    option
                  );
                }}
              />
            </div>
          ) : (
            <div className="logical-condition-renderer">
              <span className="text">{filterRow?.next_operation || "AND"}</span>
            </div>
          )}

          <FilterInputWrapperStyled>
            {filterRow?.filter_type === "EXP" ? (
              <TextAreaField
                name={`${name}.${criteriaRowIndex}.condition.${filterRowIndex}.sql_expression`}
                control={control}
                autoSize
                autoFocus
                placeholder="Enter Expression"
                height="40px"
                width="500px"
                propOnChange={(event): void => {
                  const updatedObj = {
                    ...filterRow,
                    sql_expression: event?.target?.value,
                  };
                  onFilterRowInputValueChange({
                    updatedObj,
                  });
                }}
                disabled={isReadMode}
              />
            ) : (
              <>
                <SelectField
                  isAllowClear={false}
                  control={control}
                  name={`${name}.${criteriaRowIndex}.condition.${filterRowIndex}.column`}
                  placeholder="Select Column"
                  options={columns || []}
                  setValue={setValue}
                  showSearch
                  filterOption={selectFilterOption}
                  className="column-select-field"
                  propOnChange={(option, selectOptionObj): void => {
                    const finalSelectOption = Array.isArray(selectOptionObj)
                      ? selectOptionObj
                      : [selectOptionObj];

                    const updatedObj = {
                      ...filterRow,
                      column: option,
                    };

                    onFilterRowInputValueChange({
                      updatedObj,
                      finalSelectOption,
                      typeOfInputField: "field",
                      isSingleSelect: true,
                      shouldReset: true,
                    });
                  }}
                  disabled={isReadMode}
                />

                <SelectField
                  isAllowClear={false}
                  control={control}
                  name={`${name}.${criteriaRowIndex}.condition.${filterRowIndex}.filter`}
                  placeholder="Select Operator"
                  options={filters || []}
                  setValue={setValue}
                  className="filter-select-field"
                  disabled={!filterRow?.column || isReadMode}
                  showSearch
                  filterOption={selectFilterOption}
                  propOnChange={(option): void => {
                    const updatedObj = {
                      ...filterRow,
                      filter: option,
                    };

                    onFilterRowInputValueChange({
                      updatedObj,
                      typeOfInputField: "filter",
                      shouldReset: true,
                    });
                  }}
                />

                {!["DTE", "DTM", "DT"]?.includes(filterRow?.data_type || "") ? (
                  <>
                    {filterRow?.filter && (
                      <>
                        {!["BLK", "NBL", "INC", "NIN"].includes(
                          filterRow?.filter || ""
                        ) && (
                          <InputField
                            control={control}
                            name={`${name}.${criteriaRowIndex}.condition.${filterRowIndex}.value`}
                            placeholder={`Enter ${
                              filterRow?.data_type === "NUM"
                                ? "Numeric"
                                : "String"
                            } Value`}
                            propOnChange={(event): void => {
                              onFilterRowInputValueChange({
                                updatedObj: {
                                  ...filterRow,
                                  value: event?.target?.value || "",
                                },
                              });
                            }}
                            disabled={isReadMode}
                          />
                        )}

                        {["BTW", "NBT"].includes(filterRow?.filter || "") && (
                          <InputField
                            control={control}
                            name={`${name}.${criteriaRowIndex}.condition.${filterRowIndex}.second_value`}
                            placeholder={`Enter ${
                              filterRow?.data_type === "NUM"
                                ? "Numeric"
                                : "String"
                            } Value`}
                            propOnChange={(event): void => {
                              onFilterRowInputValueChange({
                                updatedObj: {
                                  ...filterRow,
                                  second_value: event?.target?.value || "",
                                },
                              });
                            }}
                            disabled={isReadMode}
                          />
                        )}

                        {["INC", "NIN"].includes(filterRow?.filter || "") && (
                          <StateHandler
                            error={undefined}
                            isFetching={isInputValuesFetchingInEditMode}
                            blankSlate={accountSettingsInputBlankSlate}
                          >
                            <SelectField
                              className="multi-select-values"
                              control={control}
                              name={`${name}.${criteriaRowIndex}.condition.${filterRowIndex}.values_list`}
                              mode="multiple"
                              setValue={setValue}
                              options={inputValues || []}
                              showSearch
                              filterOption={selectFilterOption}
                              notFoundContent={
                                isInputValuesFetching ? (
                                  <LoadingSpinerStyled>
                                    Data is being fetched <Spin size="small" />
                                  </LoadingSpinerStyled>
                                ) : isErrorInFetchingInputValues ? (
                                  <LoadingSpinerStyled>
                                    No results found
                                  </LoadingSpinerStyled>
                                ) : null
                              }
                              propOnChange={(
                                option,
                                multipleSelectOption
                              ): void => {
                                onFilterRowInputValueChange({
                                  updatedObj: {
                                    ...filterRow,
                                    values_list: option,
                                  },
                                  isSingleSelect: false,
                                  finalSelectOption: multipleSelectOption,
                                });
                              }}
                              disabled={isReadMode}
                            />
                          </StateHandler>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {filterRow?.filter &&
                      !["BLK", "NBL", "INC", "NIN"].includes(
                        filterRow?.filter || ""
                      ) && (
                        <>
                          <SelectField
                            control={control}
                            name={`${name}.${criteriaRowIndex}.condition.${filterRowIndex}.date_type`}
                            options={[
                              {
                                value: "ABS",
                                label: "Absolute Date",
                              },
                              {
                                value: "REL",
                                label: "Relative Date",
                              },
                            ]}
                            className="date-type-select-field"
                            defaultValue="REL"
                            setValue={setValue}
                            setError={setError}
                            isAllowClear={false}
                            propOnChange={(option): void => {
                              onFilterRowInputValueChange({
                                updatedObj: {
                                  ...filterRow,
                                  date_type: option,
                                },
                              });
                            }}
                            disabled={isReadMode}
                          />

                          {filterRow?.date_type === "REL" ? (
                            <>
                              <InputField
                                control={control}
                                name={`${name}.${criteriaRowIndex}.condition.${filterRowIndex}.value`}
                                placeholder="Enter Number of Days"
                                propOnChange={(event): void => {
                                  onFilterRowInputValueChange({
                                    updatedObj: {
                                      ...filterRow,
                                      value: event?.target?.value || "",
                                    },
                                  });
                                }}
                                disabled={isReadMode}
                              />

                              {["BTW", "NBT"].includes(
                                filterRow?.filter || ""
                              ) && (
                                <InputField
                                  control={control}
                                  name={`${name}.${criteriaRowIndex}.condition.${filterRowIndex}.second_value`}
                                  placeholder="Enter Number of Days"
                                  propOnChange={(event): void => {
                                    onFilterRowInputValueChange({
                                      updatedObj: {
                                        ...filterRow,
                                        second_value:
                                          event?.target?.value || "",
                                      },
                                    });
                                  }}
                                  disabled={isReadMode}
                                />
                              )}
                            </>
                          ) : (
                            <>
                              <DatePickerField
                                control={control}
                                format={DATE_FORMAT}
                                name={`${name}.${criteriaRowIndex}.condition.${filterRowIndex}.date_value`}
                                placeholder="Enter Date Value"
                                propOnChange={(option): void => {
                                  onFilterRowInputValueChange({
                                    updatedObj: {
                                      ...filterRow,
                                      date_value: option,
                                    },
                                  });
                                }}
                                width="95%"
                                disabled={isReadMode}
                              />

                              {["BTW", "NBT"].includes(
                                filterRow?.filter || ""
                              ) && (
                                <DatePickerField
                                  control={control}
                                  format={DATE_FORMAT}
                                  name={`${name}.${criteriaRowIndex}.condition.${filterRowIndex}.second_date_value`}
                                  placeholder="Enter Date Value"
                                  propOnChange={(option): void => {
                                    onFilterRowInputValueChange({
                                      updatedObj: {
                                        ...filterRow,
                                        second_date_value: option,
                                      },
                                    });
                                  }}
                                  width="95%"
                                  disabled={isReadMode}
                                />
                              )}
                            </>
                          )}
                        </>
                      )}
                  </>
                )}
              </>
            )}
          </FilterInputWrapperStyled>
        </>
        {!isReadMode && (
          <LinkButton
            className="remove-btn"
            onClick={onRemoveFilterRow(criteriaRowIndex, filterRowIndex)}
            data-testid={`remove-filter-row-criteria-${criteriaRowIndex}-${filterRowIndex}`}
          >
            {removeRowBtn}
          </LinkButton>
        )}
      </FilterRowWithLogicalOperStyled>
    </FilterRowAndRemoveButtonWrapperStyled>
  );
};

export default FilterRow;
