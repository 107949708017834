export function moveUP(
  allSelectedKeys: string[],
  positionChangeAbleKeys: string[]
): string[] {
  const tempArr = [...allSelectedKeys];
  const indices = positionChangeAbleKeys
    ?.map((key) => tempArr?.indexOf(key))
    ?.sort((a, b) => a - b); // Sort indices in ascending order

  if (indices?.length === 0 || indices?.[0] === 0) {
    return tempArr; // No change if the first selected key is already at the top
  }

  // Move each selected key up by one position
  indices?.forEach((index) => {
    if (index > 0) {
      const fromElement = tempArr[index];
      tempArr[index] = tempArr[index - 1];
      tempArr[index - 1] = fromElement;
    }
  });

  return tempArr;
}

export function moveDown(
  allSelectedKeys: string[],
  positionChangeAbleKeys: string[]
): string[] {
  const tempArr = [...allSelectedKeys];
  const indices = positionChangeAbleKeys
    ?.map((key) => tempArr?.indexOf(key))
    ?.sort((a, b) => b - a); // Sort indices in descending order

  if (
    indices?.length === 0 ||
    indices?.[indices?.length - 1] === tempArr?.length - 1
  ) {
    return tempArr; // No change if the last selected key is already at the bottom
  }

  // Move each selected key down by one position
  indices?.forEach((index) => {
    if (index < tempArr.length - 1) {
      const fromElement = tempArr[index];
      tempArr[index] = tempArr[index + 1];
      tempArr[index + 1] = fromElement;
    }
  });

  return tempArr;
}
