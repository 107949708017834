import * as yup from "yup";
import {
  CHARACTERS_LIMIT_EXCEEDED,
  INVALID_LINK,
  REQUIRED_MESSAGE,
} from "../../constants/formconstants";
import { is_policy_linked } from "../../pages/tagsetsdetailpage/tagsetsdetailpage.constants";

export const addTagsetSchema = yup.object().shape({
  name: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .max(100, CHARACTERS_LIMIT_EXCEEDED(100)),
  description: yup.string().required(REQUIRED_MESSAGE),
});

export const informationSectionSchema = yup.object().shape({
  name: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .max(100, CHARACTERS_LIMIT_EXCEEDED(100)),
  description: yup.string(),
  allow_suggestions: yup.boolean(),
  single_value: yup.boolean(),
  datasets: yup.boolean(),
  fields: yup.boolean(),
  terms: yup.boolean(),
  is_policy_linked: yup.boolean(),
  tagset_policy_link: yup.string().when(is_policy_linked, {
    is: true,
    then: yup
      .string()
      .matches(/^(ftp|http|https):\/\/[^ "]+$/, INVALID_LINK)
      .required(REQUIRED_MESSAGE),
  }),
});

export const tagsSectionSchema = yup.object().shape({
  allTags: yup.array().of(
    yup.object().shape({
      tag_id: yup.number(),
      tag: yup
        .string()
        .required(REQUIRED_MESSAGE)
        .max(100, CHARACTERS_LIMIT_EXCEEDED(100)),
      description: yup.string(),
      usage_count: yup.number(),
    })
  ),
});

export const mergeTagsSchema = yup.object().shape({
  target_tag: yup.number().required(REQUIRED_MESSAGE),
});
