import { format } from "date-fns";

export function utcTOLocalTimeZone(
  value: string,
  dateFormat?: string,
  dontDoFormat?: boolean
): string {
  try {
    const convertedDate = new Date(`${value}Z`);
    return dontDoFormat
      ? `${convertedDate}`
      : format(convertedDate, dateFormat || "PPpp");
  } catch {
    return "";
  }
}
