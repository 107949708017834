export const maskString = (str: string, isDefnStr?: boolean): string => {
  try {
    const regex = /[a-zA-Z0-9]/g;

    const maskVal = (valToMasked: string): string => {
      return valToMasked?.toString()?.replace(regex, "X");
    };

    if (isDefnStr) {
      const strToBeMasked = str?.includes("e.g.")
        ? str?.split("e.g.")?.slice(-1)?.[0]
        : "";

      const maskedStr = maskVal(strToBeMasked);
      const finalMaskedString = str?.replace(strToBeMasked, maskedStr);

      return finalMaskedString;
    }

    return maskVal(str);
  } catch {
    return "";
  }
};
