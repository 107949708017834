import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback } from "react";
import { useForm } from "react-hook-form";

import { useRequestWithMethod } from "../../api";
import { Button } from "../../components";
import { FormItemStyled, FormStyled } from "../../components/form";
import { TextAreaField } from "../../components/formfields";
import StateHandler from "../../components/statehandler/statehandler";

import {
  useCancelModal,
  useGetAppState,
  useGetAppType,
} from "../../customhooks";
import { openNotification } from "../../utils";
import { suggestAnDeprecateSchema } from "../../utils/schemas/createsuggestionschema";
import { MarkAsDeprecateSuggestionFormStyled } from "./markasdeprecatesuggestionform.styles";

function MarkAsDeprecateSuggestionForm({
  isRefPage = false,
  onCancel: onCancelView,
  ...props
}: {
  isRefPage?: boolean;
  nodeId?: string;
  nodeType?: string;
  onCancel?: () => void;
}): JSX.Element {
  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(suggestAnDeprecateSchema),
  });

  const { isExtOrDesktop } = useGetAppType();
  const onCancelModal = useCancelModal();

  const onCancel = onCancelView || onCancelModal;

  const { modal } = useGetAppState();
  const { modalProps = {} } = isRefPage ? { modalProps: { ...props } } : modal;

  const onSuccess = useCallback(() => {
    openNotification(
      <span>
        <span className="action-text">
          Thank you for contributing to
          {modalProps?.nodeType === "TBL"
            ? " Dataset"
            : modalProps?.nodeType === "DSR"
            ? modalProps?.nodeSubType === "RPT"
              ? " Report"
              : " Data Source"
            : " Term"}
          !
        </span>
        DvSum will email you about the status of your suggestions.
      </span>,
      0,
      {
        ...((isExtOrDesktop || isRefPage) && {
          top: isExtOrDesktop ? 0 : 44,
          className: isExtOrDesktop
            ? "desktop-app-center-notification"
            : "ref-page-notification",
          ...(isRefPage && {
            getContainer: (): any => {
              const elements =
                document.getElementsByClassName("ant-drawer-content");
              return (elements?.length && elements[0]) || document.body;
            },
          }),
        }),
      }
    );
    onCancel?.();
  }, [onCancel]);

  const { onExecuteRequest, isLoading, error } = useRequestWithMethod(
    "create_suggestion",
    [modalProps?.nodeId, modalProps?.nodeType],
    undefined,
    onSuccess
  );

  const onSubmit = useCallback((values) => {
    onExecuteRequest({
      status: "deprecate",
      comment: values?.comment,
    });
  }, []);

  return (
    <>
      <StateHandler isModal isFetching={isLoading} error={error}>
        <MarkAsDeprecateSuggestionFormStyled
          isRefPage={isRefPage}
          isExtOrDesktop={isExtOrDesktop}
        >
          {isRefPage && <div className="suggest-header">Suggest an Edit</div>}
          <FormStyled
            isItemColumnLayout
            paddingLeft={isRefPage ? "38px" : "118px"}
            paddingRight={isRefPage ? "38px" : "115px"}
            onFinish={handleSubmit(onSubmit) as any}
          >
            <div className="scroll-sec">
              <FormItemStyled label="Comment" required>
                <TextAreaField control={control} name="comment" width="558px" />
              </FormItemStyled>
            </div>
            <FormItemStyled
              {...(isRefPage && { marginBottom: "0px" })}
              label=""
              className="form-actions-sec"
              marginTop="30px"
            >
              <Button width="82px" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                width="93px"
                marginLeft="8px"
                htmlType="submit"
                disabled={!isValid}
              >
                Submit
              </Button>
            </FormItemStyled>
          </FormStyled>
        </MarkAsDeprecateSuggestionFormStyled>
      </StateHandler>
    </>
  );
}

export default MarkAsDeprecateSuggestionForm;
