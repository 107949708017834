import styled from "styled-components";

export const AddReferenceDictionaryModalStyled = styled.div`
  padding-top: 38px;
  padding-bottom: 38px;

  padding-left: 123px;
  padding-right: 123px;

  .input-field-container {
    width: max-content;
  }
`;
