export const REQUIRED_MESSAGE = "This is a required field.";
export const ONLY_INTEGERS = "Only integer value allowed";
export const INVALID_EMAIL = "Invalid email address";
export const INVALID_IP_ADDRESS = "Invalid IP address";
export const INVALID_DOMAIN_NAME = "Invalid domain name";
export const INVALID_PORT = "Invalid port number";
export const INVALID_PROVIDER_NAME = "Invalid provider name";
export const IP_ALREADY_EXISTS = "IP Address already exist";
export const DOMAIN_ALREADY_EXISTS = "Domain already exist";
export const USER_ALREADY_EXISTS = "User already exists";
export const DESC_MAX_RANGE_MSG = "Field reached the 500 characters limit";
export const COLUMN_NAME_MAX_RANGE = "Field reached the 50 characters limit";
export const INVALID_SAWS_NAME = "Invalid Gateway name";
export const TITLE_MAX_RANGE = "Field reached the 100 characters limit";
export const INVALID_HOSTNAME = "Invalid hostname";
export const INVALID_IDENTIFIER = "Invalid identifier";
export const INVALID_METADATA_URL = "Invalid metadata url";
export const INVALID_LINK = "Enter a valid link";
export const INVALID_FIRST_NAME = "Invlaid first name";
export const INVALID_LAST_NAME = "Invlaid last name";
export const INVALID_PASSWORD = "Please choose a strong password";
export const MIN_CHARACTERS_LIMIT = (limit: number): string =>
  `There should atleast be ${limit} characters.`;
export const CHARACTERS_LIMIT_EXCEEDED = (limit: number): string =>
  `Character limit reached. ${limit} characters are allowed.`;
export const INVALID_NUMERIC_VALUE = "Invalid numeric value";
export const MAX_USER_SELECTION_MESSAGE = "Only one user can be selected";

export const ENABLE_WORKFLOW_TOOLTIP =
  "Once workflow is enabled, then associated objects will require approval before they can be published.";

export const DOMAIN_GOVERNANCE_NOT_ENABLED =
  "Governance is not enabled on this domain. You can enable governance on this domain at Module Settings Level";

export const GOVERNANCE_NOT_ENABLED_ON_NEW_DOMAINS =
  "Governance cannot be enabled for newly created domains. You can enable this feature at Module Settings Level";

export const DATA_QUALITY_NOT_ENABLED =
  "Data Quality is not enabled . You can enable this feature at Module Settings Level";

export const CAT_GOVERNANCE_NOT_ENABLED =
  "Governance is not enabled on this sub-domain. You can enable governance on this sub-domain at Module Settings Level";

export const TITLE_MAX_CHA_LIMIT = 100;

export const getLessThanMessage = (
  firstField: string,
  secondField: string
): string => `${firstField} must be less than ${secondField}`;

export const getGreaterThanMessage = (
  firstField: string,
  secondField: string
): string => `${firstField} must be greater ${secondField}`;

export const IP_ADDRESS_REGEX = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/;
export const URL_REGEX = /^[-a-zA-Z0-9:%._+~#=//]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9(),'@:%_+.~#?&//=]*)$/;
export const PORT_NUMBER_REGEX = /^((6553[0-5])|(655[0-2][0-9])|(65[0-4][0-9]{2})|(6[0-4][0-9]{3})|([1-5][0-9]{4})|([0-5]{0,5})|([0-9]{1,4}))$/;
export const ALPHANUMERIC_REGEX = /^[A-Za-z0-9]+$/;
export const HOSTNAME_REGEX = new RegExp(
  `${IP_ADDRESS_REGEX.source}|${URL_REGEX.source}|(localhost)`
);
export const ALPHABET_REGEX = /^[A-Za-z]*$/;
export const NAME_REGEX = new RegExp(`${ALPHABET_REGEX.source}|[ ]*`);
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
export const NUMBERS_REGEX = /^-?[0-9]\d*$/;
