import { API_CONFIG } from "../../constants/apiconfig";

import {
  getParsedEnabledObjects,
  getParsedUserRoleInfo,
} from "../../parsers/userroles/userrolesparser";

import {
  ReturnTypeOfEnabledObjects,
  ReturnTypeOfUserRoleInfo,
  UserRolesParserReturnType,
} from "../../parsers/userroles/userrolesparser.types";

import { useGetAll } from "../baseservice";
import { CustomReactQueryHookReturnType } from "../services.types";

const {
  get_user_role: getUserRole,
  get_all_user_roles_for_role_page: allUserRoles,
  get_all_user_groups_for_role_page: allUserGroups,
  get_all_access_types_for_role_page: allAccessTokens,
  get_user_role_enabled_objects: getUserRoleEnabledObjects,
} = API_CONFIG;

export function useGetUserRoleInfo(
  roleId: string
): CustomReactQueryHookReturnType<ReturnTypeOfUserRoleInfo> {
  return useGetAll({
    apiConfig: getUserRole,
    parser: getParsedUserRoleInfo,
    params: [roleId],
    options: { enabled: !!roleId },
  });
}

export function useGetAllUserRoles(): CustomReactQueryHookReturnType<
  UserRolesParserReturnType[]
> {
  return useGetAll({ apiConfig: allUserRoles });
}

export function useGetAllUserGroupsForRoles(): CustomReactQueryHookReturnType {
  return useGetAll({ apiConfig: allUserGroups });
}

export function useGetAllAccessTypes(): CustomReactQueryHookReturnType {
  return useGetAll({ apiConfig: allAccessTokens });
}

export function useGetEnabledObjects(
  roleId: string,
  moduleType: "CTG" | "GLS"
): CustomReactQueryHookReturnType<ReturnTypeOfEnabledObjects> {
  return useGetAll({
    apiConfig: getUserRoleEnabledObjects,
    parser: getParsedEnabledObjects,
    params: [roleId, moduleType],
    options: { enabled: !!roleId },
  });
}
