import { Key } from "react";

//It exactly does like excel percentage format
export const formatPercentage = (
  number: Key,
  isAlreadyformatted?: boolean
): number => {
  try {
    if (isAlreadyformatted) return +number;

    return +number * 100;
  } catch (error) {
    return +number;
  }
};
