import styled from "styled-components";
import LinkButton from "../../../components/linkbutton/linkbutton";

export const SectionContainer = styled.div<{ isFromRuleSet?: boolean }>`
  /* padding-left: ${({ isFromRuleSet }): string =>
    isFromRuleSet ? "20%" : "268px"}; */

  max-height: ${({ isFromRuleSet }): string => (isFromRuleSet ? "650px" : "")};
  min-height: ${({ isFromRuleSet }): string => (isFromRuleSet ? "400px" : "")};
  overflow-y: auto;

  .title {
    margin-top: 40px;
    color: ${({ theme }): string => theme.textColors.BLUE_116};
    font-size: 16px;
    margin-left: 20px;
    margin-bottom: -10px !important;
    font-weight: 600;
  }

  .description {
    color: ${({ theme }): string => theme.textColors.GREY_26};
    font-size: 12px;
    margin-left: 20px;
    margin-top: 15px;
    margin-bottom: 0;
    max-width: 50%;
  }

  .ant-select-selection-item {
    .pattern-desc {
      display: none;
    }
    :hover {
      .pattern-edit-icon {
        display: none;
      }
    }
  }

  .ant-select-item {
    &.ant-select-item-group {
      padding: 5px 15px;
    }
  }

  .ant-select-selection-item-content {
    .icon {
      display: none;
    }
  }

  .ant-row .ant-form-item:not(.form-actions-sec) {
    margin-bottom: 5px;
    margin-top: 2px;
  }

  .label-hierarchy {
    display: flex;
    align-items: center;
    column-gap: 4px;

    .hierarchy {
      color: ${({ theme }): string => theme.textColors.GREY_210};
      font-size: 13.6px;
    }
  }
`;

export const FilterWrapperStyled = styled(LinkButton)`
  display: flex;
  column-gap: 8px;
  align-items: center;

  svg {
    path {
      fill: ${({ theme }): any => theme.bgColors.BLUE_116};
    }
  }

  .filter-label-value {
    display: flex;
    row-gap: 5px;
    flex-direction: column;
    align-items: flex-start;

    p {
      margin: 0;
    }

    .label {
      font-style: italic;
      color: ${({ theme }): string => theme.textColors.GREY_210};
      font-size: 12px;
      font-weight: 400;
      margin-top: 2px;
    }

    .value {
      color: ${({ theme }): string => theme.textColors.GREY_24};
      font-size: 12px;
    }
  }
`;

export const EndIconStyled = styled.div`
  margin-left: 8px;
  margin-top: 3px;

  .cls-1 {
    fill: #949494;
  }
`;

export const ChoiceBoxFieldWrapperStyled = styled.div`
  padding-left: 20px;
  padding-bottom: 20px;

  .label {
    font-family: OpenSansSemiBold;
    margin-bottom: 8px;
  }
`;

export const ComparisionWrapperStyled = styled.div`
  width: 520px;

  .label {
    display: flex;
    font-size: 12px;
    color: #4f4f4f;
    font-weight: 400;
  }
`;

export const DynamicFormLoadingSpinerStyled = styled.div`
  padding: 5px 5px 5px 10px;
  opacity: unset;
  color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
`;

export const ColumnNameAndTypeStyled = styled.div`
  display: flex;
  column-gap: 5px;
  align-items: center;

  .icon {
    display: flex;
    align-items: center;
    svg {
      height: 25px;
      path {
        fill: ${({ theme }): string => theme.textColors.GREY_18};
      }
    }
  }
`;

export const DynamicformStyled = styled.div`
  .ant-form {
    .section-container {
      .form-item-label-wrapper {
        .custom-query-rule-text-area {
          .ant-form-item-control {
            width: 100%;
            .ant-form-item-control-input {
              .ant-form-item-control-input-content {
                .textarea-field-container > textarea {
                  height: 400px !important;
                }
              }
            }
          }
        }
      }
    }
  }
`;
