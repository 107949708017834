import { useCallback } from "react";

import { NodeType } from "../../app.types";
import { useGetAppType, useOpenModal } from "../../customhooks";

import { editPencilIcon } from "../../svgs";
import { SuggestAnEditButtonStyled } from "./suggestaneditbutton.styles";

type NodeProps = {
  nodeType: NodeType;
  nodeId: string;
  nodeTitle: string;
  nodeDesc: string;
};

const SuggestAnEditButton = (
  props: NodeProps & {
    isDrawer?: boolean;
    onClick?: (
      _nodeType: NodeProps["nodeType"],
      _nodeId: string,
      _nodeTitle: string,
      _nodeDesc: string
    ) => void;
    marginLeft?: string;
  }
): JSX.Element => {
  const {
    nodeType,
    nodeId,
    isDrawer = false,
    onClick,
    nodeDesc,
    nodeTitle,
    marginLeft,
  } = props;

  const onOpenModal = useOpenModal();
  const { isExtOrDesktop } = useGetAppType();

  const onClickSuggest = useCallback(() => {
    if (onClick) {
      onClick(nodeType, nodeId, nodeTitle, nodeDesc);
    } else {
      onOpenModal({
        modalId: "suggest_an_edit_form",
        visible: true,
        modalProps: {
          nodeType,
          nodeId,
          animateModal: !isDrawer && !isExtOrDesktop,
        },
      });
    }
  }, [nodeType, nodeId]);

  return (
    <SuggestAnEditButtonStyled
      id="primary"
      fontSize="15px"
      icon={editPencilIcon("16", "16")}
      className="suggest-edit-btn"
      onClick={onClickSuggest}
      marginLeft={marginLeft}
    >
      Suggest an Edit
    </SuggestAnEditButtonStyled>
  );
};

export default SuggestAnEditButton;
