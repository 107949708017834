export const POPULARITY_SCORE_CRITERIA_TOOLTIP = [
  {
    title: "Heavily Used",
    description: "More than one query per day",
  },
  {
    title: "Moderately Used",
    description: "More than one query per week",
  },
  {
    title: "Lightly Used",
    description: "Less than one query per week",
  },
];
