import styled from "styled-components";

export const BannerStyled = styled.div<{ bgColor?: string; color?: string }>`
  height: 44.8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme, color }): string =>
    color || theme?.textColors?.WHITE_COLOR
  };
  background: ${({ theme, bgColor }): string =>
    bgColor || theme?.bgColors?.GREY_40
  };
`;
