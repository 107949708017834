//   <--Library Imports Start-->
import styled from "styled-components";
//   <--Library Imports End-->

export const NodeRefQuickEditCompStyled = styled.div<{
  right: string;
  top: string;
}>`
  position: relative;

  .edit-pencil {
    position: absolute;
    right: ${({ right }): string => right};
    top: ${({ top }): string => top};
  }
`;
