import { ProgressBarStyled } from "./customprogresbar.styles";
import { ProgressProps } from "./customprogresbar.types";

function CustomProgressBar(props: ProgressProps): JSX.Element {
  const { value } = props;

  return (
    <ProgressBarStyled {...props}>
      <div className="progress-inner">{`${value}%`}</div>
    </ProgressBarStyled>
  );
}

export default CustomProgressBar;
