import { NodeType } from "../app.types";
import { ELEMENT_IDS } from "./elementids";

const {
  anldict_detpg_ovw_end_btn: ANLDICT_DETPG_OVW_END_BTN,
  anldict_detpg_ovw_fol_btn: ANLDICT_DETPG_OVW_FOL_BTN,
  anldict_detpg_ovw_edi_btn: ANLDICT_DETPG_OVW_EDI_BTN,
  datdict_detpg_info_cert_btn: DATDICT_DETPG_INFO_CERT_BTN,
  datdict_detpg_info_edt_btn: DATDICT_DETPG_INFO_EDT_BTN,
  datdict_detpg_info_endrs_btn: DATDICT_DETPG_INFO_ENDRS_BTN,
  datdict_detpg_info_flw_btn: DATDICT_DETPG_INFO_FLW_BTN,
  datdict_detpg_info_getsugg_btn: DATDICT_DETPG_INFO_GETSUGG_BTN,
  datdict_detpg_info_sugedi_btn: DATDICT_DETPG_INFO_SUGEDI_BTN,
  busgls_detpg_edit_btn: BUSGLS_DETPG_EDIT_BTN,
  busgls_detpg_like_btn: BUSGLS_DETPG_LIKE_BTN,
  busgls_detpg_follow_btn: BUSGLS_DETPG_FOLLOW_BTN,
  busgls_detpg_sugs_btn: BUSGLS_DETPG_SUGS_BTN,
  busgls_detpg_sugedit_btn: BUSGLS_DETPG_SUGEDIT_BTN,
  busgls_detpg_done_btn: BUSGLS_DETPG_DONE_BTN,
  datdict_detpg_info_done_btn: DATDICT_DETPG_INFO_DONE_BTN,
  anldict_detpg_ovw_done_btn: ANLDICT_DETPG_OVW_DONE_BTN,
  busgls_detpg_sbmt_appr_btn: BUSGLS_DETPG_SBMT_APPR_BTN,
} = ELEMENT_IDS;

export const ENDORSE_ELEM_ID_MAPPING: { [key in NodeType]?: string } = {
  DSR: ANLDICT_DETPG_OVW_END_BTN,
  TBL: DATDICT_DETPG_INFO_ENDRS_BTN,
  TRM: BUSGLS_DETPG_LIKE_BTN,
};

export const FOLLOW_ELEM_ID_MAPPING: { [key in NodeType]?: string } = {
  DSR: ANLDICT_DETPG_OVW_FOL_BTN,
  TBL: DATDICT_DETPG_INFO_FLW_BTN,
  TRM: BUSGLS_DETPG_FOLLOW_BTN,
};

export const EDIT_ELEM_ID_MAPPPING: { [key in NodeType]?: string } = {
  DSR: ANLDICT_DETPG_OVW_EDI_BTN,
  TBL: DATDICT_DETPG_INFO_EDT_BTN,
  TRM: BUSGLS_DETPG_EDIT_BTN,
};

export const CERTIFY_ELEM_ID_MAPPPING: { [key in NodeType]?: string } = {
  TBL: DATDICT_DETPG_INFO_CERT_BTN,
};

export const VIEW_SUGGESTIONS_BTN: { [key in NodeType]?: string } = {
  TBL: DATDICT_DETPG_INFO_GETSUGG_BTN,
  TRM: BUSGLS_DETPG_SUGS_BTN,
};

export const CREATE_SUGGESTION_BTN: { [key in NodeType]?: string } = {
  TBL: DATDICT_DETPG_INFO_SUGEDI_BTN,
  TRM: BUSGLS_DETPG_SUGEDIT_BTN,
};

export const DONE_ELEM_ID_MAPPPING: { [key in NodeType]?: string } = {
  DSR: ANLDICT_DETPG_OVW_DONE_BTN,
  TBL: DATDICT_DETPG_INFO_DONE_BTN,
  TRM: BUSGLS_DETPG_DONE_BTN,
};

export const SUBMIT_APPROVAL_ELEM_ID_MAPPPING: {
  [key in NodeType]?: string;
} = {
  TRM: BUSGLS_DETPG_SBMT_APPR_BTN,
  TBL: BUSGLS_DETPG_SBMT_APPR_BTN,
  DSR: BUSGLS_DETPG_SBMT_APPR_BTN,
};
