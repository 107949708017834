import { Button } from "../../../../components";

import { LoadingScreenProps } from "./loadingscreen.types";

import { LoadingScreenStyled } from "./loadingscreen.styles";
import { getEnvVariables } from "../../../../utils";

const LoadingScreen = (props: LoadingScreenProps): JSX.Element => {
  const { icon, text, displayButton } = props;

  const { REACT_APP_DVSUM_GET_STARTED_URL } = getEnvVariables();

  return (
    <LoadingScreenStyled>
      {icon}
      <h1 className="screen-text">{text}</h1>
      {displayButton && (
        <Button
          id="primary_2"
          width="197.6px"
          height="68px"
          fontSize="16px"
          href={REACT_APP_DVSUM_GET_STARTED_URL}
        >
          Register
        </Button>
      )}
    </LoadingScreenStyled>
  );
};

export default LoadingScreen;
