import { useGetAppState } from "../../../../customhooks";
import DvsumHelpAndOnboardingFeedbackSec from "../../../dvsumhelpandonboardingfeedbacksec";

import { DvsumHelpDrawerDetailModalStyled } from "./dvsumhelpdrawerdetailmodal.styles";
import { DvsumHelpDrawerDetailModalProps } from "./dvsumhelpdrawerdetailmodal.types";

const DvsumHelpDrawerDetailModal = (): JSX.Element => {
  const { modal } = useGetAppState();

  const { type, item } = (modal?.modalProps ||
    {}) as DvsumHelpDrawerDetailModalProps;

  const {
    description = "",
    url: videoUrl = "",
    gif_attachment: gifAttachment = [],
    thumbnail_image_url: thumbnailImageUrl = "",
    id = "",
  } = item || {};

  const { url: gifURL } = gifAttachment?.[0] || {};

  return (
    <DvsumHelpDrawerDetailModalStyled>
      <div className="scroll-sec">
        <div className="item-detailed-view-sec">
          {type === "Quick Tip" && <img src={gifURL} alt="item" />}
          {type === "Video" && <iframe title="video" src={videoUrl} />}
          {type === "Tutorial" && <img src={thumbnailImageUrl} alt="item" />}
        </div>

        <div className="desc-sec">{description}</div>

        <DvsumHelpAndOnboardingFeedbackSec id={id} type={type} />
      </div>
    </DvsumHelpDrawerDetailModalStyled>
  );
};

export default DvsumHelpDrawerDetailModal;
