import styled from "styled-components";
import { ellipsis } from "../../../../../../../../styles/app.styles";

export const ContextTagStyled = styled.div<{ isContextInUse: boolean }>`
  display: flex;
  align-items: center;
  column-gap: 8px;

  .tag {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: ${({ theme }): string => theme?.bgColors?.LIGHT_GREY_22};
    padding: 9.6px 12px;
    border-radius: 25.6px;
    font-size: 12px;
    align-items: center;
    color: ${({ theme }): string => theme?.textColors?.GREY_24};
    max-width: ${({ isContextInUse }): string =>
      !isContextInUse ? "288px" : ""};

    .content {
      ${ellipsis}
    }

    .add-btn {
      svg {
        path {
          fill: ${({ theme }): string => theme?.textColors?.GREY_210};
        }
      }
    }
  }
`;
