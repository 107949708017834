import { useCallback } from "react";
import { ELEMENT_IDS } from "../../../../constants";
import { plusIconInFilledCircle } from "../../../../svgs";
import DropdownButton from "../../../dropdownbutton/dropdownbutton";
import { AddNewButtonWrapperStyled } from "./addnewbutton.styles";
import { AddNewButtonPropsType } from "./addnewbutton.types";

const addNewCriteriaBtnIcon = plusIconInFilledCircle("12", "12");

const {
  add_new_filter_condition: ADD_NEW_FILTER_CONDITION,
  add_new_custom_expression: ADD_NEW_CUSTOM_EXPRESSION,
  add_new_criteria: ADD_NEW_CRITERIA,
} = ELEMENT_IDS;

const AddNewButton = (props: AddNewButtonPropsType): JSX.Element => {
  const { buttonTitle, handleAddButtonClick } = props;

  const onAddButtonClick = useCallback(
    (type: "CND" | "EXP") => (): void => {
      handleAddButtonClick?.(type);
    },
    [handleAddButtonClick]
  );

  const addNewCriteriaConditionItems = [
    {
      key: "add-filter-expression",
      value: "Custom Expression",
      onClick: onAddButtonClick("EXP"),
      id: ADD_NEW_CUSTOM_EXPRESSION,
      customClass: "add-filter-expression-btn",
    },
    {
      key: "add-filter-condition",
      value: "Filter Condition",
      onClick: onAddButtonClick("CND"),
      id: ADD_NEW_FILTER_CONDITION,
      customClass: "add-filter-condition-btn",
    },
  ];

  return (
    <AddNewButtonWrapperStyled className="add-new-button">
      <DropdownButton
        type="text"
        width="150px"
        items={addNewCriteriaConditionItems}
        overlay={<div />}
        drpdnBtnElementId={ADD_NEW_CRITERIA}
        dropdownLabelClassName="add-new-criteria-btn"
      >
        {addNewCriteriaBtnIcon} {buttonTitle}
      </DropdownButton>
    </AddNewButtonWrapperStyled>
  );
};

export default AddNewButton;
