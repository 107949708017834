import { Key } from "react";
import { ONBOARDING_TUTORIAL_PARAMS } from "../components/onboardingtutorial/onboardingtutorial.constant";
import { APP_ROUTES } from "../constants";

const {
  id: ID,
  start_url: START_URL,
  asset_id: ASSET_ID,
  total_steps: TOTAL_STEPS,
} = ONBOARDING_TUTORIAL_PARAMS;

export const openOnboardingTutorial = ({
  id = "",
  startUrl = "",
  assetId = "",
  totalSteps = "",
}: {
  id?: string;
  startUrl: string;
  assetId?: string;
  totalSteps?: Key;
}): void => {
  try {
    window.open(
      `${APP_ROUTES.private_routes.onboarding_tutorial}?${ID}=${id}&${START_URL}=${startUrl}&${ASSET_ID}=${assetId}&${TOTAL_STEPS}=${totalSteps}`
    );
  } catch {
    console.log("");
  }
};
