import styled from "styled-components";

export const MergeTagsFormStyled = styled.div`
  .ant-form {
    .form-content {
      padding: 24.8px 51.36px;

      .ant-form-item {
        padding-left: 0px;

        .ant-form-item-control {
          width: 495px;

          .form-item-with-inline-info {
            .ant-form-item-control-input-content {
              display: flex;
              align-items: center;
              column-gap: 8px;
            }
          }
        }
      }
    }
  }
`;
