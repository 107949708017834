import { Tooltip } from "antd";
import { DVSUM_TOOLTIP_CLASS_NAME } from "../../constants";
import { ConfidenceScoreStyled } from "./confidencescore.styles";
import { ConfidenceScoreProps } from "./confidencescore.types";

function ConfidenceScore(props: ConfidenceScoreProps): JSX.Element {
  const { score, marginTop } = props;
  return (
    <Tooltip
      overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
      title="Confidence Score"
      placement="right"
    >
      <ConfidenceScoreStyled score={score} marginTop={marginTop}>
        {score}%
      </ConfidenceScoreStyled>
    </Tooltip>
  );
}

export default ConfidenceScore;
