// <--- Types Start --->
import { DQExecutionStatus } from "../app.types";
import { DQScoreVariant } from "../components/dqscore/dqscore.types";

import { TrendDirectionType } from "../components/trend/trend.types";
// <--- Types End --->

// <--- Utils Start --->
import { getParsedDQScore } from "./getparseddqscore";
// <--- Utils End --->

export const dqScoreMapping = (
  id: DQExecutionStatus,
  status: string,
  score: string,
  trend: string
): {
  score: string;
  scoreTooltip: string;
  trend: TrendDirectionType;
  trendTooltip: string;
  variant: DQScoreVariant;
} => {
  const availableVariants: {
    [key in DQExecutionStatus]: DQScoreVariant;
  } = {
    PSS: "GRN",
    WNG: "ORG",
    FAL: "RED",
    WIG: "GRN",
    "": "",
  };

  const roundedTrend =
    trend !== "null" && trend !== "undefined"
      ? Math.round(Number.parseFloat(trend || "0"))
      : 0;
  const roundedScore = getParsedDQScore(score);

  return {
    score: roundedScore,
    scoreTooltip: status,
    trend: roundedTrend > 0 ? "UP" : roundedTrend < 0 ? "DOWN" : "CENTER",
    trendTooltip: roundedTrend !== 0 ? `Change: ${roundedTrend}%` : "No change",
    variant: availableVariants?.[id],
  };
};
