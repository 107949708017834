import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRequestWithMethod } from "../../api";

import { Button } from "../../components";
import { FormItemStyled, FormStyled } from "../../components/form";
import { SelectField } from "../../components/formfields";
import StateHandler from "../../components/statehandler/statehandler";
import { API_CONFIG } from "../../constants/apiconfig";
import { UserRolesGridColumnsType } from "../../pages/userrolespage/userrolespage.types";
import { DeleteUserRoleFormStyled } from "./deleteuserroleform.styles";
import { deleteUserRoleSchema } from "../../utils/schemas/userroleschemas";
import { useCancelModal, useGetAppState } from "../../customhooks";

const api = API_CONFIG?.get_all_user_roles?.url;

function DeleteUserRoleForm(): JSX.Element {
  const {
    control,
    setValue,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    defaultValues: { assign_user_role_to: undefined },
    mode: "onChange",
    resolver: yupResolver(deleteUserRoleSchema),
  });
  const {
    modal: { modalProps = {} },
  } = useGetAppState();

  const { id = "", name = "" } = modalProps as UserRolesGridColumnsType;

  const queryClient = useQueryClient();
  const data = queryClient?.getQueryData(api) as any;

  const onCancel = useCancelModal();

  const onSuccess = useCallback(() => {
    queryClient.invalidateQueries(api, { fetching: false });
    onCancel();
  }, [modalProps]);

  const { isLoading, error, onExecuteRequest } = useRequestWithMethod(
    "delete_user_role",
    [id],
    true,
    onSuccess
  );

  const onSubmit = useCallback((values) => {
    onExecuteRequest({ new_role_id: values?.assign_user_role_to });
  }, []);

  return (
    <StateHandler isModal isFetching={isLoading} error={error}>
      <DeleteUserRoleFormStyled>
        <FormStyled
          isItemColumnLayout
          paddingLeft="110px"
          onFinish={handleSubmit(onSubmit) as any}
        >
          <div className="content-sec">
            <div className="static-text">
              You must assign a role to the groups having
              <span className="bold"> {name}</span>.
            </div>
            <FormItemStyled
              label="Reassign role to group"
              required
              marginBottom="60px"
            >
              <SelectField
                control={control}
                setValue={setValue}
                options={data?.data
                  ?.filter((item: any) => item?.ROLE_ID !== id)
                  ?.map((item: any) => ({
                    label: item?.ROLE_NAME,
                    value: item?.ROLE_ID,
                  }))}
                name="assign_user_role_to"
                width="396px"
                height="45.36px"
                placeholder="Select"
                isAllowClear={false}
              />
            </FormItemStyled>
          </div>
          <FormItemStyled
            label=""
            className="form-actions-sec"
            marginBottom="0px"
          >
            <Button
              id="primary"
              width="78px"
              height="39px"
              htmlType="submit"
              disabled={!isValid}
            >
              Delete
            </Button>
            <Button
              id="primary"
              width="78px"
              height="39px"
              marginLeft="5px"
              onClick={onCancel}
            >
              Cancel
            </Button>
          </FormItemStyled>
        </FormStyled>
      </DeleteUserRoleFormStyled>
    </StateHandler>
  );
}

export default DeleteUserRoleForm;
