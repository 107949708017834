import styled from "styled-components";

export const DvsumHelpAndOnboardingFeedbackSecStyled = styled.div<{
  isDetailsModal: boolean;
}>`
  width: ${({ isDetailsModal }): string => (isDetailsModal ? "100%" : "510px")};

  .feedback-sec {
    display: flex;
    gap: 12.8px;
    padding: 14px;
    background: ${({ theme }): string => theme?.bgColors?.LIGHT_GREY_22};
    flex-direction: ${({ isDetailsModal }): string =>
      isDetailsModal ? "row" : "column"};
    justify-content: ${({ isDetailsModal }): string =>
      isDetailsModal ? "start" : "center"};
    align-items: ${({ isDetailsModal }): string =>
      isDetailsModal ? "start" : "center"};

    .feedback-text {
      color: ${({ theme }): string => theme?.textColors?.GREEN_11};
      width: 100%;
      text-align: center;
    }

    .feedback-label {
      margin-top: 4px;
      font-family: OpenSansSemiBold;
    }

    .feedback-vote-sec {
      display: flex;
      flex-direction: column;
      row-gap: 9.6px;
      align-items: ${({ isDetailsModal }): string =>
        isDetailsModal ? "start" : "center"};
    }

    .vote-btns {
      display: flex;
      column-gap: 9.6px;

      .ant-btn {
        display: flex;
        align-items: center;
        column-gap: 2.8px;
        font-size: 13.6px;
        font-weight: normal;
        color: ${({ theme }): string => theme?.textColors?.GREY_24};
        pointer-events: all;
        border-radius: 28.8px;
      }

      .selected {
        background: ${({ theme }): string => theme?.bgColors?.LIGHT_BLUE_1};
        color: ${({ theme }): string => theme?.textColors?.BLUE_116};
        border: 1px solid
          ${({ theme }): string => theme?.borderColors?.BLUE_116};

        svg {
          path {
            fill: ${({ theme }): string => theme?.textColors?.BLUE_116};
          }
        }
      }
    }

    .comment-field {
      width: ${({ isDetailsModal }): string =>
        isDetailsModal ? "389.6px" : "420px"};
      padding: 8px;
      resize: none;
      font-size: 13.6px;
      pointer-events: all;
    }
  }

  .submit-btn {
    margin: auto;
    margin-top: 25.6px;
    pointer-events: all;
  }
`;
