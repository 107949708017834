//   <--Library Imports Start-->
import { useCallback, useEffect } from "react";
import { Tooltip } from "antd";
//   <--Library Imports Start-->

//   <--Custom Hooks Start-->
import {
  useCancelSecondaryModal,
  useOpenDrawer,
  useOpenModal,
} from "../../customhooks";
//   <--Custom Hooks End-->

//   <--SVGS Start-->
import { editPencilIcon } from "../../svgs";
//   <--SVGS End-->

//   <--Styles Start-->
import { NodeRefQuickEditCompStyled } from "./noderefquickedit.styles";
//   <--Styles End-->

//   <--Types Start-->
import { TermRefDrawerProps } from "../../drawerviews/termrefdrawer/termrefdrawer.types";
import { QuickEditForColConfirmationModalProps } from "../modalwrapper/modals/quickeditforcolconfirmationmodal/quickeditforcolconfirmationmodal.types";

import { NodeRefQuickEditCompProps } from "./noderefquickedit.types";
import { DVSUM_TOOLTIP_CLASS_NAME, ELEMENT_IDS } from "../../constants";
import LinkButton from "../linkbutton";
//   <--Types End-->

const {
  datdict_detpg_info_ref_edit_btn: DATDICT_DETPG_INFO_REF_EDIT_BTN,
} = ELEMENT_IDS;

const NodeRefQuickEditComp = (
  props: NodeRefQuickEditCompProps
): JSX.Element => {
  const {
    nodeDesc = "",
    nodeId = "",
    nodeTitle = "",
    nodeType,
    onClickEdit,
    right = "",
    top = "",
    nodeSubType,
    parentId = "",
    entityId = "",
    isColDescInherited,
    isTriggerEditOnMount,
    onChangeEditView,
    nodeName = "",
    isDisabled = false,
    drawerStartFromTop = false,
  } = props;

  const openModal = useOpenModal();
  const onCancel = useCancelSecondaryModal();

  const openDrawer = useOpenDrawer();

  const onClickEditWithProps = useCallback(() => {
    if (nodeType === "COL" && isColDescInherited) {
      openModal(
        {
          modalId: "quick_edit_for_col_confirmation",
          modalTitle: "Edit Column Details",
          visible: true,
          modalProps: {
            onEditEntity: () => {
              openDrawer({
                drawerId: "term_ref",
                drawerProps: {
                  id: entityId,
                  isEdit: true,
                } as TermRefDrawerProps,
                visible: true,
                isStartFromTop: drawerStartFromTop,
                zIndex: 100001,
              });
              onCancel();
            },
            onEditField: () => {
              onClickEdit?.({
                nodeType,
                nodeId,
                nodeTitle,
                nodeDesc,
                nodeSubType,
                parentId,
                isUpdatingInheritedDesc: true,
                nodeName,
              });
              onCancel();
            },
          } as QuickEditForColConfirmationModalProps,
        },
        true
      );
    } else {
      onClickEdit?.({
        nodeType,
        nodeId,
        nodeTitle,
        nodeDesc,
        nodeSubType,
        parentId,
        nodeName,
      });
    }
  }, [props]);

  useEffect(() => {
    isTriggerEditOnMount && onClickEditWithProps();
    onChangeEditView?.();
  }, []);

  return (
    <NodeRefQuickEditCompStyled right={right} top={top}>
      <LinkButton
        className="edit-pencil"
        disabled={isDisabled}
        onClick={(): void => (!isDisabled ? onClickEditWithProps() : undefined)}
        id={DATDICT_DETPG_INFO_REF_EDIT_BTN}
        tooltipProps={{
          title: isDisabled
            ? "The workflow is enabled. To make changes, navigate to the detail page and submit your edits for approval."
            : "",
          placement: "left",
          overlayStyle: { maxWidth: "400px" },
        }}
      >
        {editPencilIcon("13", "13")}
      </LinkButton>
    </NodeRefQuickEditCompStyled>
  );
};

export default NodeRefQuickEditComp;
