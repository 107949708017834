import { LOCAL_STORAGE_CONSTANTS } from "../constants";
import { GatewayStatusContextState } from "../contexts";
import { jsonParse } from "./jsonparse";

export const getGatewaysStatusesListFromLocalStorage = ():
  | GatewayStatusContextState["listOfGateways"]
  | undefined => {
  const gatewaysList = localStorage.getItem(
    LOCAL_STORAGE_CONSTANTS.list_of_gateways
  );
  if (!gatewaysList) {
    return undefined;
  }
  return jsonParse(gatewaysList);
};
