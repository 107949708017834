import { useHistory } from "react-router";
import { useCallback } from "react";
import { Button } from "../../../../components";

import { useCancelModal, useGetAppState } from "../../../../customhooks";
import { DiscardConfirmationWrapperStyled } from "./discardconfirmation.styles";

import { DiscardConfirmationPropsType } from "./discardconfirmation.types";

const DiscardConfirmation = (): JSX.Element => {
  const {
    modal: { modalProps = {} },
  } = useGetAppState();

  const { propsOnDiscard } = (modalProps as DiscardConfirmationPropsType) || {};

  const handleCancel = useCancelModal();
  const history = useHistory();

  const onDiscard = useCallback(() => {
    handleCancel();
    propsOnDiscard?.();
  }, [propsOnDiscard]);

  return (
    <DiscardConfirmationWrapperStyled>
      <div className="message-content">
        Are you sure you want to discard these changes. The changes you made
        will not be reflected.
      </div>

      <div className="modal-actions-sec">
        <Button id="cancel" width="78px" onClick={handleCancel}>
          Cancel
        </Button>

        <Button id="primary" width="78px" onClick={onDiscard}>
          Discard
        </Button>
      </div>
    </DiscardConfirmationWrapperStyled>
  );
};

export default DiscardConfirmation;
