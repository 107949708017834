import styled from "styled-components";

export const AddNewButtonWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;

  .add-new-criteria-btn {
    display: flex;
    column-gap: 5px;
    align-items: center;
  }

  .add-filter-condition-btn,
  .add-filter-expression-btn {
    color: ${({ theme }): string => theme.textColors.GREY_40};
    border-bottom: none;

    :hover {
      color: ${({ theme }): string => theme.textColors.WHITE_COLOR};
    }
  }

  .dropdown-icon {
    svg {
      path {
        fill: ${({ theme }): string => theme.textColors.DARK_BLUE};
      }
    }
  }
`;
