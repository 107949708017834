import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { v4 as uuidv4 } from "uuid";

// <--- Library Imports Start --->
import { useState, useCallback, useMemo, useEffect } from "react";
// <--- Library Imports End --->

// <--- Components Start--->
import StateHandler from "../../components/statehandler/statehandler";
// <--- Components End --->

// <--- Styles Start --->
import {
  DescriptionStyled,
  DrawerTitleStyled,
  FilterWrapperStyled,
  LabelAndInputWrapper,
  LabelStyled,
  RulesListingEditRefDrawerStyled,
  RulesInputWrapperStyled,
  RulesFooterStyled,
  DrawerContentWrapperStyled,
  FilterConditionModalFooterStyled,
  FilterConditionModalStyled,
  FilterConditionWrapperStyled,
  ColumnNameAndTypeWrapper,
  ColumnSequenceWrapperStyled,
} from "./ruleslistingeditdrawer.styles";
// <--- Styles End --->

// <--- Types Start --->
import {
  DrawerContentProps,
  EditRulesListingSettingsFormTypes,
} from "./ruleslistingeditdrawer.types";

import { InputField, SelectField } from "../../components/formfields";
import {
  getDvSumInformativeText,
  isEqual,
  jsonStringify,
  openNotification,
  selectFilterOption,
} from "../../utils";

import { dateIcon, filledFilterIcon, filterIconFlipped } from "../../svgs";
import { Button, Sequence } from "../../components";

import { useCancelDrawer, useGetAppState, useSetData } from "../../customhooks";
import LinkButton from "../../components/linkbutton/linkbutton";

import SuccessNotificationMessage from "../../components/successnotificationmessagerendrer/successnotificationmessagerendrer";
import {
  useGetEditTableConfig,
  useGetColumnsWithDistribution,
} from "../../api/tablesservice";

import { ModalStyled } from "../../components/modalwrapper/modalwrapper.styles";
import FilterCriteria from "../../components/filtercriteria/filtercriteria";

import { parsedFilters } from "../../components/filtercriteria/filtercriteria.config";
import { useRequestWithMethod } from "../../api";

import { filterCriteriaApiData } from "../../forms/addgovernanceviewform/addgovernanceviewform.utils";
import { FiltersColumnParsedConfigurationType } from "../../parsers/tablepage/tablepageparser.types";

import { API_CONFIG } from "../../constants/apiconfig";
import FormItemLabel from "../../components/form/formitemlabel";
import { FormStyled } from "../../components/form";

import { rulesListingEditDrawerFormSchema } from "../../utils/schemas/ruleslistingeditdrawerschema";
import { HorizontalDividerStyled } from "../../components/dividers/dividers.styles";

// <--- Types End --->

const filledFunnelIcon = filledFilterIcon("13", "13");
const unFilledFunnelIcon = filterIconFlipped("13", "13");

function DrawerContent(props: DrawerContentProps): JSX.Element {
  const { drawer } = useGetAppState();
  const onSetData = useSetData();

  const { tableId = "" } = drawer?.drawerProps || {};

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFilterCriteriaChanged, setIsFilterCriteriaChanged] = useState(false);
  const [isUpdatedFiltersSaved, setIsUpdatedFiltersSaved] = useState(false);

  const onChangeFilterCriteria = useCallback(() => {
    setIsFilterCriteriaChanged(true);
  }, []);

  const onUpdatedFiltersSaved = useCallback(() => {
    setIsUpdatedFiltersSaved(true);
  }, []);

  const { drawerData, isLoading, columnWithDist } = props;

  const { load_profile, metric_time, exception_record_limit, columns = [] } =
    drawerData || {};

  const {
    isLoading: tableConfigSaving,
    error: errorInSavingTableConfig,
    onExecuteRequest,
  } = useRequestWithMethod(
    "save_node_edit_config",
    [tableId, "TBL"],
    undefined
  );

  const {
    datdict_datqual_edtrle_lodprof: datdictDatqualEdtrleLodprof,
    datdict_datqual_edtrle_mtrctim: datdictDatqualEdtrleMtrctim,
    datdict_datqual_edtrle_rcrdlmt: datdictDatqualEdtrleRcrdlmt,
  } = getDvSumInformativeText();

  const closeDrawer = useCancelDrawer();

  const lengthOfExistsingFilters = useMemo(
    () => drawerData?.filterCriteria?.length,
    []
  );

  const filtersExists = useMemo(() => lengthOfExistsingFilters > 0, [
    lengthOfExistsingFilters,
  ]);

  const rulesListingEditForm = useForm<EditRulesListingSettingsFormTypes>({
    defaultValues: isLoading
      ? {}
      : {
          load_profile: load_profile || "ALL",
          metric_time: metric_time ? `${metric_time}` : undefined,
          exceptions_record_count: Number(exception_record_limit) || 10000,
          is_filter_criteria_active: false,
          filter_criteria: [],
        },
    resolver: yupResolver(rulesListingEditDrawerFormSchema),
    mode: "onChange",
  });

  const {
    control,
    setValue,
    watch,
    handleSubmit,
    formState: { isDirty, isValid, errors },
  } = rulesListingEditForm;

  const onChangeFilterCriteriaStateToActive = useCallback(() => {
    setValue("is_filter_criteria_active", true);
    setIsModalVisible(true);
  }, []);

  const onChangeFilterCriteriaStateToInActive = useCallback(
    (isSavedFilters?: boolean) => (): void => {
      isSavedFilters && onUpdatedFiltersSaved();
      setValue("is_filter_criteria_active", false);
      setIsModalVisible(false);

      if (!isSavedFilters)
        setValue("filter_criteria", drawerData?.filterCriteria);
    },
    [drawerData?.filterCriteria]
  );

  const { load_profile: loadProfile } = watch();

  const onSaveTableConfigSuccess = useCallback(
    (response) => {
      onSetData(API_CONFIG?.get_node_edit_config, response?.data, [tableId]);

      openNotification(
        <SuccessNotificationMessage
          message="The setting has been successfully updated"
          showSuccess
          showSuccessText={false}
        />
      );

      closeDrawer();
    },
    [tableId]
  );

  const metricTimeFields = useMemo(() => {
    const dateColumns: FiltersColumnParsedConfigurationType[] = columns?.filter(
      (column) =>
        column?.field_datatype === "DTE" || column?.field_datatype === "DTM"
    );

    return (
      dateColumns?.map((item) => {
        return {
          labelText: item?.field_name,
          value: `${item?.field_id}`,
          label: (
            <ColumnNameAndTypeWrapper>
              <span className="icon"> {dateIcon}</span>
              <span className="label">{item?.field_name}</span>
            </ColumnNameAndTypeWrapper>
          ),
        };
      }) || []
    );
  }, [columns]);

  const initialColumnOrder = columns?.map((item) => `${item?.field_id}`);

  const [columnsOrder, setColumnsOrder] = useState<string[]>(
    initialColumnOrder
  );

  const sortedColumns = useMemo(() => {
    const columnMap = new Map(
      columns?.map((col, index) => [col?.field_id, index])
    );

    const sortedColumnOrder = columnsOrder?.map((key, index) => {
      return {
        ...columns?.[columnMap?.get(key) as number],
        col_seq: index + 1,
      };
    });

    return sortedColumnOrder;
  }, [columns, columnsOrder]);

  const onSetColumnsOrder = useCallback((updatedColumnOrder: string[]) => {
    setColumnsOrder([...updatedColumnOrder]);
  }, []);

  // const isColumnSequenceChanges = !isEqual(columns, sortedColumns);
  const isColumnSequenceChanges = !(
    jsonStringify(columns) === jsonStringify(sortedColumns)
  );

  const onSubmit = useCallback(
    (values: EditRulesListingSettingsFormTypes) => {
      const criteria = isUpdatedFiltersSaved
        ? values?.filter_criteria
        : drawerData?.filterCriteria;

      const formFilterCondition = criteria || [];

      const parsedfilterCriteria = filterCriteriaApiData(
        formFilterCondition,
        columns
      );

      const apiColumns = sortedColumns?.map((column) => ({
        COL_ID: Number(column?.field_id),
        COL_NAME: column?.field_name,
        COL_DISPLAY_ORDER: column?.col_seq,
        COL_TYPE: column?.field_datatype,
      }));

      const parsedData = {
        additional_info: {
          load_profile: values?.load_profile,
          metric_time:
            values?.load_profile === "ALL"
              ? undefined
              : Number(values?.metric_time),
          exception_record_limit: values?.exceptions_record_count,
        },
        filter: parsedfilterCriteria,
        col_seq: isColumnSequenceChanges ? apiColumns : undefined,
      };

      onExecuteRequest(
        { ...parsedData },
        undefined,
        onSaveTableConfigSuccess,
        "save_node_edit_config"
      );
    },
    [columns, isUpdatedFiltersSaved, sortedColumns, isColumnSequenceChanges]
  );

  const {
    dat_dict_dat_qual_edtrle_load_prfl_val_al_dta: {
      description: datDictDatQualEdtrleLoadPrflValAlDta = "",
    } = {},
    dat_dict_dat_qual_edtrle_load_prfl_val_incr: {
      description: datDictDatQualEdtrleLoadPrflValIncr = "",
    } = {},
  } = getDvSumInformativeText();

  return (
    <StateHandler
      isFetching={tableConfigSaving}
      error={errorInSavingTableConfig}
      isModal
    >
      <FormStyled layout="vertical" height="100%">
        <FormProvider {...rulesListingEditForm}>
          <RulesListingEditRefDrawerStyled>
            <DrawerTitleStyled className="DrawerTitleStyled">
              Settings
            </DrawerTitleStyled>

            <RulesInputWrapperStyled>
              <LabelAndInputWrapper>
                <FormItemLabel
                  label="Load Profile"
                  description={datdictDatqualEdtrleLodprof?.description || ""}
                  paddingLeft="0"
                  marginBottom="0"
                >
                  <SelectField
                    setValue={setValue}
                    isAllowClear={false}
                    control={control}
                    name="load_profile"
                    allowClear={false}
                    options={[
                      {
                        label: "Select",
                        value: "",
                        options: [
                          {
                            label: "All Data",
                            value: "ALL",
                            labelDesc: datDictDatQualEdtrleLoadPrflValAlDta,
                          },
                          {
                            label: "Incremental Data",
                            value: "INC",
                            labelDesc: datDictDatQualEdtrleLoadPrflValIncr,
                          },
                        ],
                      },
                    ]}
                    showSearch
                    filterOption={selectFilterOption}
                    shouldAddDescInLabel
                    isGroupedOpt
                    width="552px"
                  />
                </FormItemLabel>
              </LabelAndInputWrapper>
              {loadProfile === "INC" && (
                <LabelAndInputWrapper>
                  <FormItemLabel
                    label="Metric Time"
                    description={datdictDatqualEdtrleMtrctim?.description || ""}
                    paddingLeft="0"
                    marginBottom="0"
                  >
                    <SelectField
                      setValue={setValue}
                      isAllowClear={false}
                      control={control}
                      name="metric_time"
                      allowClear={false}
                      options={[
                        {
                          label: "Select",
                          value: "",
                          options: metricTimeFields || [],
                        },
                      ]}
                      showSearch
                      placeholder="Select"
                      filterOption={selectFilterOption}
                      width="100%"
                    />
                  </FormItemLabel>
                </LabelAndInputWrapper>
              )}

              <LabelAndInputWrapper>
                <FormItemLabel
                  label="Exceptions Record Limit"
                  description={datdictDatqualEdtrleRcrdlmt?.description || ""}
                  paddingLeft="0"
                  marginBottom="0"
                >
                  <InputField
                    control={control}
                    name="exceptions_record_count"
                    type="number"
                  />
                </FormItemLabel>
              </LabelAndInputWrapper>

              <LabelAndInputWrapper>
                <LabelStyled>Filter Condition</LabelStyled>
                <DescriptionStyled>
                  Use to apply rules on filtered data from the table.
                </DescriptionStyled>
              </LabelAndInputWrapper>

              <FilterWrapperStyled
                onClick={onChangeFilterCriteriaStateToActive}
              >
                <div className="filter-icon">
                  {filtersExists ? filledFunnelIcon : unFilledFunnelIcon}
                </div>
                <div className="filter-label-value">
                  <LinkButton className="label">
                    {filtersExists
                      ? `${lengthOfExistsingFilters} condition(s) added`
                      : " Add Filter"}
                  </LinkButton>

                  {filtersExists && (
                    <p className="value">{drawerData?.filter_text}</p>
                  )}
                </div>
              </FilterWrapperStyled>

              <ModalStyled
                width="65vw"
                visible={isModalVisible}
                onCancel={onChangeFilterCriteriaStateToInActive()}
                title="Filter Condition"
              >
                <FilterConditionModalStyled>
                  <FilterConditionWrapperStyled>
                    <FilterCriteria
                      name="filter_criteria"
                      parsedFields={columnWithDist}
                      parsedFilters={parsedFilters}
                      isEditMode={filtersExists}
                      existingFilters={drawerData?.filterCriteria || []}
                      onChangeFilterCriteria={onChangeFilterCriteria}
                      nodeType="RLS"
                    />
                  </FilterConditionWrapperStyled>
                  <FilterConditionModalFooterStyled className="form-actions-sec">
                    <Button
                      id="cancel"
                      width="74px"
                      onClick={onChangeFilterCriteriaStateToInActive()}
                    >
                      Cancel
                    </Button>
                    <Button
                      id="primary"
                      width="74px"
                      marginLeft="8px"
                      htmlType="submit"
                      onClick={onChangeFilterCriteriaStateToInActive(true)}
                      disabled={!isFilterCriteriaChanged || !isValid}
                    >
                      Save
                    </Button>
                  </FilterConditionModalFooterStyled>
                </FilterConditionModalStyled>
              </ModalStyled>

              <ColumnSequenceWrapperStyled>
                <HorizontalDividerStyled
                  width="100%"
                  className="col-seq-divider"
                />
                <span className="text-heading">Columns Sequence</span>

                <Sequence
                  onColumnSeqChange={(): void => {}}
                  sortedColumns={sortedColumns}
                  columnsOrder={columnsOrder}
                  onSetColumnsOrder={onSetColumnsOrder}
                />
              </ColumnSequenceWrapperStyled>
            </RulesInputWrapperStyled>

            <RulesFooterStyled>
              <Button id="cancel" width="74px" onClick={closeDrawer}>
                Cancel
              </Button>
              <Button
                width="74px"
                marginLeft="8px"
                htmlType="submit"
                onClick={handleSubmit(onSubmit)}
                disabled={
                  !isDirty &&
                  !isFilterCriteriaChanged &&
                  !isColumnSequenceChanges
                }
              >
                Save
              </Button>
            </RulesFooterStyled>
          </RulesListingEditRefDrawerStyled>
        </FormProvider>
      </FormStyled>
    </StateHandler>
  );
}

const RulesListingEditRefDrawer = (): JSX.Element => {
  const { drawer } = useGetAppState();
  const { tableId = "" } = drawer?.drawerProps || {};
  const { parsedData, isLoading, error, isFetching } = useGetEditTableConfig(
    tableId,
    "TBL"
  );

  const {
    parsedData: columnWithDist,
    isLoading: columnsLoading,
    error: columnsError,
  } = useGetColumnsWithDistribution(tableId);

  const isDataFetching = isFetching || columnsLoading;

  return (
    <DrawerContentWrapperStyled>
      <StateHandler
        isFetching={isDataFetching}
        error={error || columnsError}
        isModal
      >
        <DrawerContent
          drawerData={parsedData}
          columnWithDist={columnWithDist}
          isLoading={isDataFetching}
          key={isDataFetching ? "empty-form" : "parsed-data-form"}
        />
      </StateHandler>
    </DrawerContentWrapperStyled>
  );
};

export default RulesListingEditRefDrawer;
