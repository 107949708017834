import { NodeType } from "../../app.types";
import { SectionRowProps } from "../../components/sectionrow";
import { TagsData } from "../../components/tagseditor/tagseditor.types";

import { API_CONFIG } from "../../constants/apiconfig";
import { GetClassificationCustomTagsAddInfo } from "../../parsers/parserutils/getclassificationcustomtags";

import {
  getParsedColSeq,
  getParsedRecommendRulesData,
  getParsedRefDictionariesList,
  getParsedRuleDocForDetailPage,
  getParsedRuleDocForRefPage,
  getParsedRuleFilters,
  getParsedRuleSpecificColSeq,
  getRuleExecutionHistoryParsedData,
  getrulesListFromZendeskArticles,
  useGetNodeTagsParsedData,
  useGetPatternsParsedData,
} from "../../parsers/ruleparser/ruleparser";

import {
  DictionaryListParsedType,
  RecommendedRulesParsedType,
  ReturnTypeOfRuleDocParser,
  ReturnTypeOfRuleFilterParser,
  ReturnTypeOfRuleRefDocParser,
  RuleExecutionHistoryDataParsedType,
  RulePatterGroupedData,
  RuleTypeId,
  ZendeskArticleRuleParsedType,
} from "../../parsers/ruleparser/ruleparser.types";
import { SearchedNodeTypeCatParsed } from "../../parsers/tablepage/tablepageparser.types";
import {
  getNodeTypeTAGContextParser,
  getSearchedNodeTypeCat,
} from "../../parsers/tablepage/tablepageparser";
import { getEnvVariables, getFiltersfromLocalStorage } from "../../utils";

import { useGetAll } from "../baseservice";

import { CustomReactQueryHookReturnType } from "../services.types";

const {
  get_recommended_rules: getRecommendedRules,
  get_all_reference_dictionaries: getRefDictionariesList,
  get_rules_info_from_zendesk_article: getrulesInfoFromZendeskArticles,
  get_rule_execution_history: getRuleExecutionHistory,
  get_node: getNodeData,
  get_filter: getFilterData,
  get_rule_specific_col_seq: getRuleSpecificColSeq,
  get_search_node: getSearchedNode,
  get_node_tags: getTags,
  get_pattern: getPatterns,
} = API_CONFIG;

const { REACT_APP_ZENDESK_ARTICLE_TOKEN } = getEnvVariables();

const headers = {
  Authorization: `Basic ${window.btoa(REACT_APP_ZENDESK_ARTICLE_TOKEN)}`,
};

export function useGetColSeq(
  node_id: string,
  table_id: string,
  seq_type: string,
  cols_id: string[],
  rule_type: RuleTypeId
): CustomReactQueryHookReturnType<{
  columns: string[];
  seqType: string;
  customColumns: string[];
}> {
  return useGetAll({
    apiConfig: getRuleSpecificColSeq,
    parser: getParsedColSeq,
    params: [node_id, table_id, seq_type, `${cols_id}`],
    options: {
      enabled:
        rule_type === "CUQ" ? true : !!(node_id && table_id && cols_id?.length),
    },
  });
}

export function useGetRecommendedRules(
  table_id: string
): CustomReactQueryHookReturnType<RecommendedRulesParsedType[]> {
  return useGetAll({
    apiConfig: getRecommendedRules,
    parser: getParsedRecommendRulesData,
    params: [table_id],
  });
}

export function useGetRefDictionariesList(): CustomReactQueryHookReturnType<
  DictionaryListParsedType[]
> {
  return useGetAll({
    apiConfig: getRefDictionariesList,
    parser: getParsedRefDictionariesList,
  });
}

export const useGetZendeskArticles = (): CustomReactQueryHookReturnType<ZendeskArticleRuleParsedType> => {
  return useGetAll({
    apiConfig: getrulesInfoFromZendeskArticles,
    parser: getrulesListFromZendeskArticles,
    headers: { ...headers },
    isReqWithCustomHeader: true,
  });
};

export function useGetRuleDocForRefPage(
  nodeID: string,
  nodeType: string,
  nodeStatus: string
): CustomReactQueryHookReturnType<ReturnTypeOfRuleRefDocParser> {
  const parsedNodeFilter = getFiltersfromLocalStorage("RLS");

  return useGetAll({
    apiConfig: getNodeData,
    params: [nodeID, nodeType, nodeStatus, "true", "", parsedNodeFilter],
    parser: getParsedRuleDocForRefPage,
  });
}

export function useGetRuleDocForDetailPage(
  nodeID: string,
  nodeType: string,
  nodeStatus: string
): CustomReactQueryHookReturnType<ReturnTypeOfRuleDocParser> {
  const parsedNodeFilter = getFiltersfromLocalStorage("RLS");

  return useGetAll({
    apiConfig: getNodeData,
    params: [nodeID, nodeType, nodeStatus, "false", "", parsedNodeFilter],
    parser: getParsedRuleDocForDetailPage,
  });
}

export function useGetRuleFilters(
  nodeID: string,
  filterType: string,
  filtersOnly: string
): CustomReactQueryHookReturnType<ReturnTypeOfRuleFilterParser> {
  return useGetAll({
    apiConfig: getFilterData,
    params: [nodeID, "RLS", filterType, filtersOnly],
    parser: getParsedRuleFilters,
  });
}

export function useGetRuleExecutionHistory(
  nodeId: string,
  interval: string
): CustomReactQueryHookReturnType<RuleExecutionHistoryDataParsedType[]> {
  return useGetAll({
    apiConfig: getRuleExecutionHistory,
    params: [nodeId, interval],
    parser: getRuleExecutionHistoryParsedData,
    options: { enabled: !!nodeId },
  });
}

export function useGetClassificationDataForRule(
  id: string,
  enabled: boolean
): CustomReactQueryHookReturnType<TagsData> {
  const { parsedData } = useGetRuleDocForDetailPage(id, "RLS", "PUB");

  const getClassificationRow = (tagsetId: string): SectionRowProps =>
    parsedData?.classifications?.data?.find(
      (item) =>
        (item?.additionalInfo as GetClassificationCustomTagsAddInfo)
          ?.tagsetId === tagsetId
    ) || { title: "" };

  const tagsConfig = useGetAll<TagsData>({
    apiConfig: API_CONFIG.get_node_tags,
    params: ["RLS"],
    options: { enabled },
    parser: getNodeTypeTAGContextParser,
  });

  const { parsedData: tagsData = {} } = tagsConfig;

  return {
    ...tagsConfig,
    parsedData: {
      ...Object.keys(tagsData)?.reduce((prev, item) => {
        const row = getClassificationRow(item);

        const { tagsetConfig = {} } = (row?.additionalInfo ||
          {}) as GetClassificationCustomTagsAddInfo;

        const {
          tagset_name: tagsetName,
          is_comment_required: isCommentRequired,
          is_multi_value_allowed: isMultiValueAllowed,
          is_new_value_allowed: isNewValueAllowed,
          tagset_type: tagsetType,
        } = tagsetConfig?.[item] || {};

        const isSelected = (val: string): boolean => {
          const combinedTags = [
            ...(row?.tags || []),
            ...(row?.prominentTags?.tags?.map((promTag) => promTag?.name) ||
              []),
          ];

          return combinedTags?.length
            ? combinedTags?.includes(val)
            : val === row?.value;
        };
        return {
          ...prev,
          [tagsetName]: {
            ...tagsData?.[item],
            isMultiple: !!isMultiValueAllowed,
            isNewValueAllowed: !!isNewValueAllowed,
            isNoteRequired: !!isCommentRequired,
            tagsetType,
            tagsData: tagsData?.[item]?.tagsData?.map((nestItem) => ({
              ...nestItem,
              selected: isSelected(nestItem?.label),
              isAdded: isSelected(nestItem?.label),
              comment: row?.prominentTags?.tags?.find(
                (item) => item?.id === nestItem?.value
              )?.tagDetails?.tagNote,
            })),
          },
        };
      }, {}),
    },
  };
}

export function useGetSearchedNodeCat(
  node_type: string,
  search_value: string,
  enabled: boolean,
  searchByParent?: boolean
): CustomReactQueryHookReturnType<SearchedNodeTypeCatParsed[]> {
  return useGetAll({
    apiConfig: getSearchedNode,
    params: [node_type, search_value, String(!!searchByParent)],
    options: { enabled },
    parser: getSearchedNodeTypeCat,
  });
}

export function useGetNodeTags(
  nodeType: NodeType
): CustomReactQueryHookReturnType<TagsData> {
  return useGetAll({
    apiConfig: getTags,
    params: [nodeType],
    options: { enabled: !!nodeType },
    parser: useGetNodeTagsParsedData,
  });
}

export function useGetAllPatterns(
  enabled?: boolean
): CustomReactQueryHookReturnType<RulePatterGroupedData> {
  return useGetAll({
    apiConfig: getPatterns,
    parser: useGetPatternsParsedData,
    options: { enabled },
  });
}
