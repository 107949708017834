import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import { TextAreaField } from "../../../formfields";
import Statehandler from "../../../statehandler";

import { FormItemStyled, FormStyled } from "../../../form";
import { Button } from "../../..";

import { useCancelModal, useGetAppState } from "../../../../customhooks";

import { EditPromptModalStyled } from "./editprompt.styles";

import { EditPromptModalProps } from "./editprompt.types";

import { useRequestWithMethod } from "../../../../api";

import { editPromptSchema } from "../../../../utils/schemas";
import { jsonParse, jsonStringify } from "../../../../utils";

const EditPromptModal = (): JSX.Element => {
  const [updatePromptLoading, setUpdatePromptLoading] = useState(false);

  const {
    control,
    setValue,
    handleSubmit,
    formState: { isValid },
  } = useForm<{ prompt: string; prompt_config: string }>({
    defaultValues: {
      prompt: "",
      prompt_config: "",
    },
    mode: "onChange",
    resolver: yupResolver(editPromptSchema),
  });

  const onCancel = useCancelModal();

  const { modal } = useGetAppState();

  const modalProps = (modal?.modalProps as EditPromptModalProps) || {};

  const {
    id = "",
    pinnedTables = [],
    analysisTitle = "",
    onSaveDoneClick,
  } = modalProps;

  const promptOnSuccess = useCallback((res) => {
    if (typeof res?.data === "string") {
      const obj = jsonParse(res?.data || "", true);
      const prompt = obj?.TOPIC_PROMPT;
      delete obj?.TOPIC_PROMPT;
      const prettyString = JSON.stringify(obj, undefined, 4);
      setValue("prompt", prompt);
      setValue("prompt_config", prettyString);
    } else {
      const obj = jsonParse(res?.data?.[0]?.DA_PROMPT || "", true);
      const prompt = obj?.TOPIC_PROMPT;
      delete obj?.TOPIC_PROMPT;
      const prettyString = JSON.stringify(obj, undefined, 4);
      setValue("prompt", prompt);
      setValue("prompt_config", prettyString);
    }
  }, []);

  const {
    onExecuteRequest: createPrompt,
    isLoading: createPromptLoading,
    error: createPromptError,
  } = useRequestWithMethod(
    "create_prompt",
    [id || "null"],
    true,
    promptOnSuccess
  );

  const getPromptOnSuccess = useCallback((res) => {
    if (res?.data === "") {
      createPrompt({
        pin_tables: jsonStringify(
          pinnedTables?.map((table) => ({
            pin_tbl_id: table?.id,
            pin_tbl_name: table?.title,
            pin_tbl_type: "TBL",
          }))
        ),
      });
    } else {
      promptOnSuccess(res);
    }
  }, []);

  const {
    onExecuteRequest: getPrompt,
    isLoading: getPromptLoading,
    error: getPromptError,
  } = useRequestWithMethod(
    id ? "get_prompt" : "create_prompt",
    [id || "null"],
    true,
    getPromptOnSuccess
  );

  const {
    onExecuteRequest: updatePrompt,
    error: updatePromptError,
  } = useRequestWithMethod(
    "update_prompt",
    [id || "null"],
    true,
    () => {
      setUpdatePromptLoading(false);
      onCancel();
    },
    () => {
      setUpdatePromptLoading(false);
    }
  );

  useEffect(() => {
    getPrompt({
      pin_tables: jsonStringify(
        pinnedTables?.map((table) => ({
          pin_tbl_id: table?.id,
          pin_tbl_name: table?.title,
          pin_tbl_type: "TBL",
        }))
      ),
    });
  }, []);

  const onSubmit = useCallback(
    (values) => {
      setUpdatePromptLoading(true);

      const combinedValue = {
        TOPIC_PROMPT: values?.prompt,
        ...jsonParse(values?.prompt_config, true),
      };

      const func = (id?: string): void =>
        updatePrompt(
          {
            pin_tables: jsonStringify(
              pinnedTables?.map((table) => ({
                pin_tbl_id: table?.id,
                pin_tbl_name: table?.title,
                pin_tbl_type: "TBL",
              }))
            ),
            is_custom_prompt: true,
            custom_prompt: jsonStringify(combinedValue),
            da_name: analysisTitle,
          },
          id ? [id] : undefined
        );

      if (id) {
        func();
      } else {
        onSaveDoneClick?.({ updatePromptAfterSave: func });
      }
    },
    [pinnedTables, id]
  );

  return (
    <EditPromptModalStyled>
      <Statehandler
        isFetching={
          getPromptLoading || updatePromptLoading || createPromptLoading
        }
        error={getPromptError || updatePromptError || createPromptError}
        isModal
      >
        <FormStyled onFinish={handleSubmit(onSubmit) as any} isItemColumnLayout>
          <div className="content-sec">
            <FormItemStyled label="Prompt">
              <TextAreaField control={control} name="prompt" />
            </FormItemStyled>
            <FormItemStyled label="GPT3 Code Config">
              <TextAreaField control={control} name="prompt_config" />
            </FormItemStyled>
          </div>
          <FormItemStyled className="form-actions-sec">
            <Button id="cancel" width="74px" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              id="primary"
              width="74px"
              marginLeft="8px"
              htmlType="submit"
              disabled={!isValid}
            >
              Save
            </Button>
          </FormItemStyled>
        </FormStyled>
      </Statehandler>
    </EditPromptModalStyled>
  );
};

export default EditPromptModal;
