import styled from "styled-components";

export const PosterStyled = styled.div`
  // USE COLOR FROM THEME PROVIDER
  background-color: #f6f6f6;
  padding: 30px;
  margin-right: 64px;
  width: 429px;
  margin-top: 26px;

  position: absolute;
  right: 0px;
  top: 40px;

  .buttetPointContent {
    color: #4f4f4f;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
    margin-left: 8px;
  }

  .buttetPoint {
    margin-left: 13px;
  }

  .rule-link {
    margin-top: 20px;
    a {
      color: ${({ theme }): string => theme.textColors.BLUE_116};
      font-family: OpenSansSemiBold;
      font-size: 13.6px;
    }
    svg {
      margin-left: 5px;
    }
  }
`;
// POSTER STYLING IN PROGRESS
export const PosterContentWrapperStyled = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 20px;
  flex-direction: column;
  overflow: hidden;
  max-height: 330px;

  .example {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .name {
    font-family: OpenSansSemiBold;
    font-size: 16px;
  }

  .text {
    font-family: OpenSansSemiBold;
    font-size: 13.6px;
  }
`;
