import { Breadcrumb } from "antd";
import styled from "styled-components";

export const ExtBreadCrumbStyled = styled(Breadcrumb)`
  min-height: 33px;
  background: ${(props): string => props.theme.bgColors.BLUE_10};
  display: flex;
  align-items: center;
  padding-left: 23px;

  span {
    .ant-breadcrumb-link {
      .link-button {
        color: ${({ theme }): string => theme.textColors.BLUE_56};
        font-family: OpenSansSemiBold;
        display: flex;
        align-items: center;
        column-gap: 8px;

        svg {
          margin-top: 2px;

          path {
            fill: ${({ theme }): string => theme.textColors.BLUE_56};
          }
        }
      }
    }
  }
`;
