import styled from "styled-components";

import { hexToRgbA } from "../../utils";

export const TableWithFilterTilesStyled = styled.div`
  .filters {
    display: flex;
  }

  .ag-root-wrapper {
    .ag-row {
      font-size: 13px;
      background-color: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
    }
    .ag-row-hover {
      :hover {
        background: ${({ theme }): string => theme.bgColors.BLUE_23};
      }
    }
    .ag-row-selected {
      background: ${({ theme }): string => theme.bgColors.BLUE_23};
      :hover {
        background: ${({ theme }): string => theme.bgColors.BLUE_23};
      }
    }
  }
`;

export const FilterStyled = styled.div<{ isSelected: boolean }>`
  &.filter {
    flex: 1 1 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 59px;
    border-top: solid 1px #dde0e2;
    border-left: solid 1px #dde0e2;
    padding: 0px 19px;

    div {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: 6.4px;

      .value {
        font-family: ${({ isSelected }): string =>
          isSelected ? "OpenSansSemiBold" : "OpenSansBold"};
        font-size: 22px;
        justify-content: flex-end;
        flex: 0;
      }

      .title {
        opacity: ${({ isSelected }): any => !isSelected && "0.8"};
        font-size: 14px;
        justify-content: flex-start;
      }

      .icon {
        flex: 0;

        &.total-results,
        &.with-suggestions,
        &.users,
        &.approved,
        &.rejected,
        &.to-review,
        &.saved {
          svg {
            path {
              stroke: ${({ theme, isSelected }): string =>
                isSelected
                  ? theme.textColors.WHITE_COLOR
                  : hexToRgbA(theme.textColors.BLACK_20, 0.8)} !important;
            }
          }
        }

        &.newly-added,
        &.incomplete,
        &.duplicate,
        &.recently-refreshed,
        &.with-user-activity,
        &.recently-executed,
        &.with-alerts,
        &.daily-jobs,
        &.weekly-jobs,
        &.aborted-jobs,
        &.monthly-jobs,
        &.with-alerts,
        &.total-chats,
        &.with-feedback,
        &.all-feedback {
          svg {
            path {
              fill: ${({ theme, isSelected }): string =>
                isSelected
                  ? theme.textColors.WHITE_COLOR
                  : hexToRgbA(theme.textColors.BLACK_20, 0.8)} !important;
            }
          }
        }
        &.assigned-to,
        &.to-review {
          svg {
            path {
              fill: ${({ theme, isSelected }): string =>
                isSelected
                  ? theme.textColors.WHITE_COLOR
                  : hexToRgbA(theme.textColors.BLACK_20, 0.8)} !important;
              stroke: ${({ theme, isSelected }): string =>
                isSelected
                  ? theme.textColors.WHITE_COLOR
                  : hexToRgbA(theme.textColors.BLACK_20, 0.8)} !important;
            }
          }
        }

        &.questions {
          svg {
            path {
              fill: ${({ theme, isSelected }): string =>
                isSelected ? theme.textColors.WHITE_COLOR : ""} !important;
              stroke: ${({ theme, isSelected }): string =>
                isSelected ? theme.textColors.WHITE_COLOR : ""} !important;
            }
          }
        }

        &.newly-added {
          svg {
            .plus-circle-cls-1 {
              stroke: ${({ theme, isSelected }): string =>
                isSelected
                  ? theme.textColors.WHITE_COLOR
                  : hexToRgbA(theme.textColors.BLACK_20, 0.8)};
            }
          }
        }

        &.incomplete {
          svg {
            g {
              g {
                g {
                  stroke: ${({ theme, isSelected }): string =>
                    isSelected
                      ? theme.textColors.WHITE_COLOR
                      : hexToRgbA(theme.textColors.BLACK_20, 0.8)} !important;
                  stroke-width: 2.6px;

                  circle {
                    &:last-child {
                      stroke: ${({ theme, isSelected }): string =>
                        isSelected
                          ? theme.textColors.WHITE_COLOR
                          : hexToRgbA(
                              theme.textColors.BLACK_20,
                              0.8
                            )} !important;
                    }
                  }
                }
              }
            }
          }
        }

        &.duplicate {
          svg {
            g {
              path {
                &:first-child {
                  fill: none !important;
                }
              }
            }
          }
        }

        &.running-jobs {
          svg {
            path {
              stroke: ${({ theme, isSelected }): string =>
                isSelected ? theme.textColors.WHITE_COLOR : ""};
              fill: ${({ theme, isSelected }): string =>
                isSelected ? theme.textColors.WHITE_COLOR : ""};
            }
          }
        }

        &.completed-jobs {
          path {
            fill: ${({ theme, isSelected }): string =>
              isSelected ? theme.textColors.WHITE_COLOR : ""};
          }
        }

        &.aborted-jobs,
        &.scheduled-jobs {
          path {
            fill: ${({ theme, isSelected }): string =>
              isSelected ? theme.textColors.WHITE_COLOR : ""};
          }
        }

        &.with-suggestions {
          svg {
            circle {
              fill: ${({ theme, isSelected }): string =>
                isSelected
                  ? theme.textColors.WHITE_COLOR
                  : hexToRgbA(theme.textColors.BLACK_20, 0.8)} !important;
            }
          }
        }

        &.with-alerts {
          svg {
            g {
              g {
                &:last-child {
                  stroke: ${({ theme, isSelected }): string =>
                    isSelected
                      ? theme.textColors.WHITE_COLOR
                      : hexToRgbA(theme.textColors.BLACK_20, 0.8)} !important;
                }
              }
            }
          }
        }

        &.approved,
        &.rejected {
          svg {
            path {
              fill: none;
            }
          }
        }
      }
    }

    :last-of-type {
      border-right: solid 1px #dde0e2;
    }

    cursor: pointer;
    color: ${({ theme, isSelected }): string =>
      isSelected ? theme.textColors.WHITE_COLOR : theme.textColors.BLACK_20};
    background: ${({ theme, isSelected }): string =>
      isSelected ? theme.bgColors.BLUE_116 : theme.bgColors.WHITE_COLOR};
  }
`;
