export function calculatePercentageFromTotal(
  obtainedValue: number = 0,
  total: number = 1
): number {
  try {
    if (total) {
      return (obtainedValue / total) * 100;
    }
    return 0;
  } catch {
    return 0;
  }
}
