import { useCallback, useState } from "react";
import { Menu, Tooltip } from "antd";
import { useDispatch } from "react-redux";

import {
  DropDownButtonStyled,
  DropDownWrapperStyled,
  IconTextStyled,
  RuleTypeTextStyled,
  RuleTypesWrapper,
  SubmenuItemStyled,
  SubmenuStyled,
} from "./multileveldropdownmenu.styles";

import { chevronDown1, plusIconInFilledCircle } from "../../svgs";
import { setModal } from "../../reducers/appreducer/appreducer";

import { DVSUM_TOOLTIP_CLASS_NAME, ELEMENT_IDS } from "../../constants";

import { AddRuleFormModalProps } from "../../forms/addruleform/addruleform.types";
import { DqRuleAccumaltorType } from "../glossarypageheader/glossarypageheader.types";

import { SourceTypes } from "../../app.types";
import Linkbutton from "../linkbutton";
import { useGetAppState } from "../../customhooks";

const addNewRuleBtn = plusIconInFilledCircle("12", "12");
const dropdownIcon = chevronDown1("8", "8");

const {
  datdict_detpg_dq_ad_rul_btn: DATDICT_DETPG_DQ_AD_RUL_BTN,
} = ELEMENT_IDS;
// MAKE THE CRUX OF THE COMPONENT REUSABLE

const MultiLevelDropDownMenu = ({
  tableId,
  sourceId,
  srcTypeId,
  isOnlineProfilingInProgress,
  data,
  freshnessRuleColumnId,
}: {
  tableId: string;
  sourceId: number;
  srcTypeId?: SourceTypes;
  isOnlineProfilingInProgress: boolean;
  data: DqRuleAccumaltorType;
  freshnessRuleColumnId?: number;
}): JSX.Element => {
  const { isOnboardingMode } = useGetAppState();

  const MULTILEVEL_MENU_ITEMS = data?.map((item) => {
    return {
      submenuTitle: item?.name,
      subMenuElementId: item?.elementId || "",
      submenuItems:
        item?.children?.map((subItem) => {
          return {
            categoryId: item?.category,
            title: subItem?.name,
            value: subItem?.category,
            disabled: subItem?.disabled,
            description: subItem?.desc,
            elementId: subItem?.elementId || "",
          };
        }) || [],
    };
  });

  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const dispatch = useDispatch();

  const onVisibleChange = useCallback(
    (visibility) => {
      setIsMenuVisible(isOnlineProfilingInProgress ? false : visibility);
    },
    [isOnlineProfilingInProgress]
  );

  const onToggleMenuVisiblility = useCallback(() => {
    setIsMenuVisible((st) => !st);
  }, []);

  const onClickSubMenuItem = useCallback(
    (formId: string, ruleCategoryId: string) => (): void => {
      setIsMenuVisible(false);

      dispatch(
        setModal({
          modalId: "add_rule",
          modalTitle: "Add Rule",
          visible: true,
          modalProps: {
            formId,
            tableId,
            sourceId,
            srcTypeId,
            ruleCategoryId,
            // ONLY IN CASE OF FRESHNESS CHECK
            columnId: freshnessRuleColumnId,
          } as AddRuleFormModalProps,
        })
      );
    },
    [tableId, sourceId, srcTypeId, freshnessRuleColumnId]
  );

  return (
    <DropDownWrapperStyled>
      <DropDownButtonStyled
        overlay={
          <RuleTypesWrapper>
            {MULTILEVEL_MENU_ITEMS?.map((selectOption) => {
              return (
                <RuleTypeTextStyled
                  key={`rule-type-${selectOption?.submenuTitle}`}
                >
                  <Menu
                    mode="vertical"
                    getPopupContainer={(trigger): HTMLElement =>
                      trigger.parentNode as HTMLElement
                    }
                    id={selectOption?.subMenuElementId}
                    {...(isOnboardingMode && {
                      openKeys: ["rule-type-Pipeline Checks"],
                    })}
                  >
                    <SubmenuStyled
                      key={`rule-type-${selectOption?.submenuTitle}`}
                      title={selectOption?.submenuTitle}
                    >
                      {selectOption?.submenuItems?.map((item) => {
                        const {
                          description = "",
                          title = "",
                          disabled = false,
                          value = "",
                          categoryId,
                          elementId = "",
                        } = item || {};

                        return (
                          <Tooltip
                            overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
                            title={
                              value === "FRC" && disabled
                                ? 'Please add the Metric Time by changing the Load Profile to "Incremental" to enable this Rule.'
                                : disabled
                                ? "This Rule is not supported by this Data Source."
                                : description
                            }
                            placement="right"
                            key={`rule-subtype-${title}`}
                          >
                            <SubmenuItemStyled
                              key={`rule-subtype-${title}`}
                              className="submenu-item"
                              isDisabled={disabled}
                              onClick={
                                disabled
                                  ? undefined
                                  : onClickSubMenuItem(value, categoryId)
                              }
                              id={elementId}
                            >
                              {title}
                            </SubmenuItemStyled>
                          </Tooltip>
                        );
                      })}
                    </SubmenuStyled>
                  </Menu>
                </RuleTypeTextStyled>
              );
            })}
          </RuleTypesWrapper>
        }
        overlayStyle={{ top: "90px" }}
        trigger={["click"]}
        placement="bottomLeft"
        onVisibleChange={onVisibleChange}
        visible={isMenuVisible}
        {...(isOnboardingMode &&
          !isMenuVisible && {
            onVisibleChange,
          })}
        getPopupContainer={(trigger): HTMLElement =>
          trigger?.parentNode as HTMLElement
        }
      >
        <IconTextStyled
          className="actions-container"
          onClick={onToggleMenuVisiblility}
          hasCommonStyle={false}
        >
          <Linkbutton
            className="action-icon"
            tooltipProps={{
              title: isOnlineProfilingInProgress
                ? "Profiling is in progress"
                : "",
              placement: "right",
            }}
            disabled={isOnlineProfilingInProgress}
            elementId={DATDICT_DETPG_DQ_AD_RUL_BTN}
          >
            {addNewRuleBtn} Add Rule {dropdownIcon}
          </Linkbutton>
        </IconTextStyled>
      </DropDownButtonStyled>
    </DropDownWrapperStyled>
  );
};

export default MultiLevelDropDownMenu;
