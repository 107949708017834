import { useCallback, useMemo } from "react";
import { useHistory } from "react-router";
import { useRequestWithMethod } from "../../../../api";
import { useCancelModal, useGetAppState } from "../../../../customhooks";

import { getSourceDetailPageUrl, openNotification } from "../../../../utils";
import { Button } from "../../../button/button.component";

import { FormItemStyled, FormStyled } from "../../../form";
import StateHandler from "../../../statehandler";
import SuccessNotificationMessage from "../../../successnotificationmessagerendrer/successnotificationmessagerendrer";

import { CancelScanModalStyled } from "./cancelscanmodal.styles";
import {
  CancelScanModalProps,
  ScanCancellationType,
} from "./cancelscanmodal.types";

const CancelScanModal = (): JSX.Element => {
  const cancelModal = useCancelModal();
  const history = useHistory();

  const { modal } = useGetAppState();

  const {
    scanId = "",
    sourceId = "",
    scanStatusId = "",
    isScanOnDemand = false,
    isScanScheduled = false,
    scanName = "",
    srcTypeId,
  } = (modal?.modalProps || {}) as CancelScanModalProps;

  const isScanRunning = scanStatusId === "RUN";
  const isPowerBiORTableau = srcTypeId === "TBU" || srcTypeId === "PBI";

  const onCancelScanSuccess = (type: ScanCancellationType): (() => void) =>
    useCallback(() => {
      cancelModal();
      openNotification(
        <SuccessNotificationMessage
          message={
            type === "delete_schedule" ? (
              "Scheduled scan deleted"
            ) : (
              <div>
                Scan <b>{scanName}</b>{" "}
                {isScanRunning ? "aborted." : "cancelled."}
              </div>
            )
          }
          showSuccessText={false}
        />
      );
      history.push(getSourceDetailPageUrl(sourceId, "scan_history"));
    }, [sourceId, scanName, isScanRunning]);

  const {
    onExecuteRequest: cancelScanExecuteRequest,
    isLoading: cancelScanIsLoading,
    error: cancelScanError,
  } = useRequestWithMethod(
    "cancel_scan",
    [scanId, false],
    true,
    onCancelScanSuccess("cancel_scan")
  );

  const {
    onExecuteRequest: deleteScheduleExecuteRequest,
    isLoading: deleteScheduleIsLoading,
    error: deleteScheduleError,
  } = useRequestWithMethod(
    "cancel_scan",
    [scanId, true],
    true,
    onCancelScanSuccess("delete_schedule")
  );

  const modalDesc = useMemo(() => {
    if (isScanRunning) {
      return (
        <FormItemStyled marginTop="32px">
          This scan is already running. If you abort the scan, it will be
          terminated. {isPowerBiORTableau ? "Datasets/Reports" : "Tables"} that
          have already been scanned will still show as refreshed, however
          remaining {isPowerBiORTableau ? "datasets/reports" : "tables"} will
          not be refreshed. What do you want to do?
        </FormItemStyled>
      );
    }

    if (isScanScheduled) {
      return (
        <FormItemStyled marginTop="32px">
          This scan is part of a schedule.
        </FormItemStyled>
      );
    }

    if (isScanOnDemand) {
      return (
        <FormItemStyled marginTop="32px">
          Cancel scan <b>{scanName}</b>?
        </FormItemStyled>
      );
    }

    return <div />;
  }, [
    isScanRunning,
    isScanScheduled,
    isScanOnDemand,
    scanName,
    isPowerBiORTableau,
  ]);

  const onSubmit = useCallback(
    (type: ScanCancellationType) => (): void => {
      if (type === "delete_schedule") {
        deleteScheduleExecuteRequest();
        return;
      }

      cancelScanExecuteRequest();
    },
    []
  );
  return (
    <StateHandler
      isModal
      isFetching={cancelScanIsLoading || deleteScheduleIsLoading}
      error={cancelScanError || deleteScheduleError}
    >
      <CancelScanModalStyled>
        <FormStyled>
          <div className="form-wrapper">{modalDesc}</div>

          <FormItemStyled
            label=""
            className="form-actions-sec"
            marginBottom="0px"
          >
            <Button id="cancel" width="74px" onClick={cancelModal}>
              Never Mind
            </Button>

            <div className="cancel-btns-container">
              {isScanScheduled && (
                <Button
                  id="primary"
                  width="74px"
                  marginLeft="8px"
                  onClick={onSubmit("delete_schedule")}
                >
                  Delete Schedule
                </Button>
              )}
              <Button
                id="primary"
                width="74px"
                marginLeft="8px"
                onClick={onSubmit("cancel_scan")}
              >
                {isScanRunning ? "Abort" : "Cancel"} Scan
              </Button>
            </div>
          </FormItemStyled>
        </FormStyled>
      </CancelScanModalStyled>
    </StateHandler>
  );
};

export default CancelScanModal;
