import * as yup from "yup";
import { REQUIRED_MESSAGE } from "../../constants/formconstants";

export const addNewJobSchema = yup.object().shape({
  jobCategory: yup.string().required(REQUIRED_MESSAGE),
  scheduleType: yup.string(),

  repeatAfterXMinutes: yup
    .string()
    .matches(/^(\s*|\d+)$/, "Must be a number.")
    .test("is-valid-number", "Must be a number between 1 and 60", (value) => {
      if (!value || value.trim() === "") {
        return true; // Allow empty values, as the matches method already validates this part
      }
      const number = Number(value);
      return number >= 1 && number <= 60;
    }),
  repeatForXHours: yup
    .string()
    .matches(/^(\s*|\d+)$/, "Must be a number.")
    .test("is-valid-number", "Must be a number between 1 and 24", (value) => {
      if (!value || value.trim() === "") {
        return true; // Allow empty values, as the matches method already validates this part
      }
      const number = Number(value);
      return number >= 1 && number <= 24;
    }),
  endAfterRepeatingXTimes: yup.lazy((_p, p1) => {
    if (p1?.parent?.scheduleType === "SCH") {
      return yup
        ?.string()
        ?.test(
          "no-more-than-3-chars",
          "Value should be less than 3 characters.",
          (currentField) => {
            const currentFieldLength = String(currentField)?.length || 0;
            if (currentFieldLength > 3) return false;
            return true;
          }
        );
    }
    return yup?.string();
  }),
});
