// <--- Library Imports Start --->
import { yupResolver } from "@hookform/resolvers/yup";
import { Tooltip } from "antd";

import {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { v4 as uuidv4 } from "uuid";

import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router";
// <--- Library Imports End --->

// <--- Components Start --->
import { Button } from "../../components";
import { InputField } from "../../components/formfields";

import StateHandler from "../../components/statehandler/statehandler";

import Transfer from "../../components/transfer";
import { AddViewActionButtons } from "./addgovernanceviewform.components";
// <--- Components End --->

// <--- Constants Start --->
import { API_CONFIG } from "../../constants/apiconfig";

import { QUERY_PARAMS } from "../../pages/listingpage/listingpage.constants";
// <--- Constants End --->

// <--- Custom Hooks Start --->
import {
  useCancelModal,
  useGetAppState,
  useQueryParams,
  useSetData,
} from "../../customhooks";
// <--- Custom Hooks End --->

// <--- Services Start --->
import { useRequestWithMethod } from "../../api";
import { getParsedCurrentNode } from "../../parsers";

import {
  useGetGovernanceViewFields,
  useGetGovernanceViewFilters,
  useGetIncExcFieldData,
} from "../../api/listingsservice";
// <--- Services End --->

// <--- Styles Start --->
import { LoadingSpinerStyled } from "../../styles/app.styles";
import { FormItemStyled, FormStyled } from "../../components/form";

import { AddGovernanceViewFormStyled } from "./addgovernanceviewform.styles";
// <--- Styles End --->

// <--- SVGs Start --->
import { infoIcon, minusIconFilled, plusIconInFilledCircle } from "../../svgs";
// <--- SVGs End --->

// <--- Types Start --->
import {
  TranferStateType,
  TransferRecordType,
} from "../../components/transfer/transfer.types";
import { PopulateDataParams } from "../../pages/listingpage/listingpage.types";

import {
  AddGovernanceViewFormProps,
  AddGovernanceViewType,
  SpecifyCriteriaType,
} from "./addgovernanceviewform.types";
// <--- Types End --->

// <--- Utils Start --->
import {
  filterCriteriaApiData,
  getSecondValue,
  getValue,
} from "./addgovernanceviewform.utils";
import { addGovernanceViewSchema } from "../../utils/schemas/governanceviewschemas";
import { NodeType } from "../../app.types";
import { ELEMENT_IDS } from "../../constants";
import FilterCriteria from "../../components/filtercriteria/filtercriteria";
import {
  DUMMY_FILTER_CRITERIA,
  LogiacalOperatorType,
} from "../../components/filtercriteria/filtercriteria.types";
// <--- Utils End --->

const dateFormat = "MM/DD/YYYY";

const {
  allcat_lst_edi_view_name_formfield: ALLCAT_LST_EDI_VIEW_NAME_FORMFIELD,
  allcat_lst_edi_view_crit_section: ALLCAT_LST_EDI_VIEW_CRIT_SECTION,
  allcat_lst_edi_view_lyt_section: ALLCAT_LST_EDI_VIEW_LYT_SECTION,
  allcat_lst_edi_view_save_btn: ALLCAT_LST_EDI_VIEW_SAVE_BTN,
} = ELEMENT_IDS;

const AddGovernanceViewForm = (
  props: AddGovernanceViewFormProps
): JSX.Element => {
  const { isEditMode = false } = props;

  const history = useHistory();
  const params = useQueryParams();

  const onCancel = useCancelModal();
  const onSetData = useSetData();
  const {
    modal: { modalProps = {} },
  } = useGetAppState();

  const {
    get_current_node: getCurrentNode,
    get_governance_views: getGovernanceViews,
  } = API_CONFIG;

  const filterRowId = uuidv4();

  // Form data population
  const {
    id = "0",
    view_name = "",
    filter_criteria: existingFilterCriteria = [],
    selected_fields = [],
    type = "TBL",
    actionButtons = {
      clone: { onClick: (): void => {} },
      delete: { onClick: (): void => {} },
    },
    populateData,
    resetGridStates,
    setIsPopulateDataCalled,
    parentNodeId = "",
  } = modalProps as AddGovernanceViewType & {
    type: NodeType;
    actionButtons: {
      clone: { onClick: () => void; disabled?: boolean };
      delete: { onClick: () => void; disabled?: boolean };
    };
    populateData: (_params: PopulateDataParams) => void;
    resetGridStates: () => void;
    checkIsFavourite: (_nodeId: number) => void;
    setIsPopulateDataCalled: (_value: SetStateAction<boolean>) => void;
    parentNodeId: string;
  };

  const governanceViewForm = useForm<AddGovernanceViewType>({
    defaultValues: {
      view_name,
      filter_criteria: [],
    },
    resolver: yupResolver(addGovernanceViewSchema),
    mode: "onChange",
  });

  const {
    control,
    handleSubmit,
    trigger,
    watch,
    formState: { isValid },
  } = governanceViewForm;

  const { filter_criteria: filterCondition } = watch();

  const [state, setState] = useState<TranferStateType>({
    data: [],
    targetKeys: selected_fields,
  });

  const {
    parsedData: parsedFilters,
    isLoading: isLoadingFilters,
    error: errorFilters,
  } = useGetGovernanceViewFilters("");

  const {
    parsedData: parsedFields,
    isLoading: isLoadingFields,
    error: errorFields,
  } = useGetGovernanceViewFields(type, "", true);

  // APIs Integration
  const onSuccess = useCallback(
    (response) => {
      const data = response?.data;
      onSetData(getCurrentNode, data?.current, [type, parentNodeId]);
      onSetData(getGovernanceViews, data?.listing, [type, parentNodeId]);

      const parsedData = getParsedCurrentNode({
        ...response,
        data: data?.current,
      });

      const { SELECTED_FILTER, NODE_ID } = QUERY_PARAMS;
      const currentPath = history?.location;

      resetGridStates();
      setIsPopulateDataCalled(true);

      populateData({
        nodeId: parsedData?.node_id,
        columns: parsedData?.columns,
        ...(isEditMode && { triggeredFrom: "edit_governance_view" }),
      });

      history?.push({
        ...currentPath,
        search: `?${SELECTED_FILTER}=${params?.get(
          SELECTED_FILTER
        )}&${NODE_ID}=${parsedData?.node_id || ""}`,
      });

      onCancel();
    },
    [params, parentNodeId, isEditMode]
  );

  const { isLoading, error, onExecuteRequest } = useRequestWithMethod(
    isEditMode ? "update_governance_view" : "create_governance_view",
    isEditMode ? [id, type, parentNodeId] : [type, parentNodeId],
    true,
    onSuccess
  );

  const onSubmit = useCallback(
    (values) => {
      const selectedFields = state?.targetKeys?.map((selectedField, index) => {
        const field = parsedFields?.find(
          (parsedField) => `${parsedField.field_id}` === selectedField
        );

        return {
          field_id: field?.field_id,
          display_order: `${index + 1}`,
          field_display_name: field?.field_display_name,
        };
      });

      const hiddenFields = parsedFields
        ?.filter((item) => item?.is_field_hidden)
        ?.map((item, index: number) => ({
          field_id: `${item?.field_id || 0}`,
          display_order: `${index + 1 + selectedFields?.length}`,
          field_display_name: item?.field_display_name || "",
        }));

      const criteria = values?.filter_criteria;

      const formFilterCondition = criteria || [];

      const parsedfilterCriteria = filterCriteriaApiData(
        formFilterCondition,
        parsedFields
      );

      const filteredSelectedFields = selectedFields?.filter(
        (field) => field?.field_id
      );

      onExecuteRequest(
        {
          search_name: values?.view_name,
          search_aurora_filter_json: parsedfilterCriteria,
          layout_fields: [...filteredSelectedFields, ...hiddenFields],
        },
        isEditMode ? [id, type, parentNodeId] : [type, parentNodeId],
        undefined,
        isEditMode ? "update_governance_view" : "create_governance_view"
      );
    },
    [parsedFields, state, isEditMode, id, type, parentNodeId]
  );

  const transferData: TransferRecordType[] = useMemo(() => {
    return (
      parsedFields
        ?.filter((item) => !item?.is_field_hidden)
        ?.map((item) => ({
          key: `${item?.field_id}`,
          name: item?.field_display_name,
          description: "",
        })) || []
    );
  }, [parsedFields]);

  const isRendered = useRef<boolean>(false);

  useEffect(() => {
    trigger("filter_criteria");
  }, [filterCondition]);

  return (
    <StateHandler
      error={error || errorFilters || errorFields}
      isFetching={isLoading || isLoadingFilters || isLoadingFields}
      isModal
    >
      <AddGovernanceViewFormStyled>
        {isEditMode && (
          <AddViewActionButtons {...actionButtons} nodeType={type} />
        )}

        <FormStyled
          paddingLeft="204px"
          onFinish={handleSubmit(onSubmit) as any}
          isItemColumnLayout
        >
          <FormProvider {...governanceViewForm}>
            <div className="scroll-sec">
              <FormItemStyled label="Name" required>
                <InputField
                  control={control}
                  name="view_name"
                  placeholder="Enter View Name"
                  className="view-name"
                  width="512px"
                  id={ALLCAT_LST_EDI_VIEW_NAME_FORMFIELD}
                />
              </FormItemStyled>

              <FormItemStyled
                label={
                  <div id={ALLCAT_LST_EDI_VIEW_CRIT_SECTION}>
                    Specify Criteria
                  </div>
                }
              >
                <div className="specify-criteria-fields-wrapper">
                  {!(isLoadingFields || isLoadingFilters) && (
                    <FilterCriteria
                      name="filter_criteria"
                      parsedFields={parsedFields}
                      parsedFilters={parsedFilters}
                      isEditMode={isEditMode}
                      existingFilters={existingFilterCriteria || []}
                      nodeType={type}
                      isFromGovViews
                      parentNodeId={parentNodeId}
                    />
                  )}
                </div>
              </FormItemStyled>

              <FormItemStyled
                label={
                  <div id={ALLCAT_LST_EDI_VIEW_LYT_SECTION}>Choose Columns</div>
                }
                required
              >
                <Transfer
                  state={{ ...state, data: transferData }}
                  setState={setState}
                  errorMessage="Please select at least one column"
                  leftTile={
                    <div className="transfer-left-tilte">
                      Available
                      <Tooltip
                        title="Select the columns that you want to add to your view."
                        placement="right"
                        getPopupContainer={(trigger): HTMLElement =>
                          trigger.parentNode as HTMLElement
                        }
                        overlayClassName="available-filters-tooltip"
                      >
                        {infoIcon}
                      </Tooltip>
                    </div>
                  }
                  rightTile="Assigned"
                  showUpDownComp
                />
              </FormItemStyled>
            </div>
          </FormProvider>

          <FormItemStyled
            label=""
            className="form-actions-sec"
            marginBottom="0px"
          >
            <Button id="cancel" width="74px" height="39px" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              id="primary"
              width="74px"
              height="39px"
              marginLeft="8px"
              htmlType="submit"
              disabled={!isValid || state?.targetKeys?.length <= 0}
              elementId={ALLCAT_LST_EDI_VIEW_SAVE_BTN}
            >
              Save
            </Button>
          </FormItemStyled>
        </FormStyled>
      </AddGovernanceViewFormStyled>
    </StateHandler>
  );
};

export default AddGovernanceViewForm;
