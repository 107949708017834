import { Collapse } from "antd";
import styled from "styled-components";

export const LinkCollapseStyled = styled(Collapse)`
  background: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
  border: none;
  width: 100%;
  .ant-collapse-item {
    border: none;
    .ant-collapse-header {
      padding: 0px;
      display: flex;
    }

    .ant-collapse-content {
      border: none;
      .ant-collapse-content-box {
        padding: 0px;
      }
    }
  }

  .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
    padding-left: 0px;
  }
`;
