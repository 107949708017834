export const tableRecordCountHistoryChartBlankSlate = (
  width?: string
): JSX.Element => (
  <svg width={width || "100%"} height="100%" viewBox="0 0 1761 597" fill="none">
    <rect
      x="0.5"
      y="0.5"
      width="1760"
      height="596"
      fill="white"
      stroke="#E6E6E6"
    />
    <rect
      x="75"
      y="489.178"
      width="47"
      height="11.8353"
      rx="5.91767"
      fill="#F0F0F0"
    />
    <rect
      x="75"
      y="408.342"
      width="47"
      height="11.8353"
      rx="5.91767"
      fill="#F0F0F0"
    />
    <rect
      x="75"
      y="327.507"
      width="47"
      height="11.8353"
      rx="5.91767"
      fill="#F0F0F0"
    />
    <rect
      x="75"
      y="246.671"
      width="47"
      height="11.8353"
      rx="5.91767"
      fill="#F0F0F0"
    />
    <rect
      x="75"
      y="165.836"
      width="47"
      height="11.8353"
      rx="5.91767"
      fill="#F0F0F0"
    />
    <rect
      x="75"
      y="85.0005"
      width="47"
      height="11.8353"
      rx="5.91767"
      fill="#F0F0F0"
    />
    <line
      opacity="0.6"
      x1="981.328"
      y1="504.818"
      x2="981.328"
      y2="90.9999"
      stroke="#E6E6E6"
      strokeDasharray="2 2"
    />
    <line
      opacity="0.6"
      x1="796.5"
      y1="504.818"
      x2="796.5"
      y2="90.9999"
      stroke="#E6E6E6"
      strokeDasharray="2 2"
    />
    <line
      opacity="0.6"
      x1="888.5"
      y1="504.818"
      x2="888.5"
      y2="90.9999"
      stroke="#E6E6E6"
      strokeDasharray="2 2"
    />
    <line
      opacity="0.6"
      x1="1077.5"
      y1="504.818"
      x2="1077.5"
      y2="90.9999"
      stroke="#E6E6E6"
      strokeDasharray="2 2"
    />
    <line
      opacity="0.6"
      x1="702.5"
      y1="504.818"
      x2="702.5"
      y2="90.9999"
      stroke="#E6E6E6"
      strokeDasharray="2 2"
    />
    <line
      opacity="0.6"
      x1="416.5"
      y1="504.818"
      x2="416.5"
      y2="90.9999"
      stroke="#E6E6E6"
      strokeDasharray="2 2"
    />
    <line
      opacity="0.6"
      x1="608.5"
      y1="504.818"
      x2="608.5"
      y2="90.9999"
      stroke="#E6E6E6"
      strokeDasharray="2 2"
    />
    <line
      opacity="0.6"
      x1="325.5"
      y1="504.818"
      x2="325.5"
      y2="90.9999"
      stroke="#E6E6E6"
      strokeDasharray="2 2"
    />
    <line
      opacity="0.6"
      x1="507.5"
      y1="504.818"
      x2="507.5"
      y2="90.9999"
      stroke="#E6E6E6"
      strokeDasharray="2 2"
    />
    <line
      opacity="0.6"
      x1="234.5"
      y1="504.818"
      x2="234.5"
      y2="90.9999"
      stroke="#E6E6E6"
      strokeDasharray="2 2"
    />
    <line
      opacity="0.6"
      x1="1357.5"
      y1="504.818"
      x2="1357.5"
      y2="90.9999"
      stroke="#E6E6E6"
      strokeDasharray="2 2"
    />
    <line
      opacity="0.6"
      x1="1173.5"
      y1="504.818"
      x2="1173.5"
      y2="90.9999"
      stroke="#E6E6E6"
      strokeDasharray="2 2"
    />
    <line
      opacity="0.6"
      x1="1265.5"
      y1="504.818"
      x2="1265.5"
      y2="90.9999"
      stroke="#E6E6E6"
      strokeDasharray="2 2"
    />
    <line
      opacity="0.6"
      x1="1450.5"
      y1="504.818"
      x2="1450.5"
      y2="90.9999"
      stroke="#E6E6E6"
      strokeDasharray="2 2"
    />
    <line
      opacity="0.6"
      x1="1543.5"
      y1="505"
      x2="1543.5"
      y2="92.7843"
      stroke="#E6E6E6"
      strokeDasharray="2 2"
    />
    <line
      opacity="0.6"
      x1="1651.5"
      y1="505"
      x2="1651.5"
      y2="92.7843"
      stroke="#E6E6E6"
      strokeDasharray="2 2"
    />
    <line x1="146" y1="505.5" x2="1665" y2="505.5" stroke="#E6E6E6" />
    <line x1="145.5" y1="506.001" x2="145.5" y2="65.001" stroke="#E6E6E6" />
    <rect
      x="208.813"
      y="530"
      width="53.1489"
      height="14"
      rx="7"
      fill="#F0F0F0"
    />
    <rect
      x="1143.84"
      y="530"
      width="53.1489"
      height="14"
      rx="7"
      fill="#F0F0F0"
    />
    <rect
      x="395.818"
      y="530"
      width="53.1489"
      height="14"
      rx="7"
      fill="#F0F0F0"
    />
    <rect
      x="1330.85"
      y="530"
      width="53.1489"
      height="14"
      rx="7"
      fill="#F0F0F0"
    />
    <rect
      x="582.824"
      y="530"
      width="53.1489"
      height="14"
      rx="7"
      fill="#F0F0F0"
    />
    <rect
      x="1517.85"
      y="530"
      width="53.1489"
      height="14"
      rx="7"
      fill="#F0F0F0"
    />
    <rect
      x="769.829"
      y="530"
      width="53.1489"
      height="14"
      rx="7"
      fill="#F0F0F0"
    />
    <rect
      x="956.834"
      y="530"
      width="53.1489"
      height="14"
      rx="7"
      fill="#F0F0F0"
    />
  </svg>
);
