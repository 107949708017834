// <--- Library Imports Start --->
import { AxiosResponse } from "axios";
// <--- Library Imports End --->

// <--- SVGs Start --->
import {
  adminsStatsIcon,
  categoriesStatsIcon,
  datasetsStatsIcon,
  domainsStatsIcon,
  editorsStatsIcon,
  modelsStatsIcon,
  fieldsStatsIcon,
  reportStatsIcon,
  sourcesStatsIcon,
  termStatsIcon,
  userGroupsStatsIcon,
} from "../../svgs";
// <--- SVGs End --->

// <--- Types Start --->
import {
  AnalyticsStatsResponse,
  DataDictionaryAnalyticsResponseType,
  GlossaryAnalyticsResponseType,
  SampleLinkedColType,
  RetunTypeOfSingleGlossaryAnalytics,
  RetunTypeOfSingleSourceAnalytics,
  ReturnTypeOfDataDictionartAlalytics,
  ReturnTypeOfGetAnalyticsStats,
  ReturnTypeOfGlossaryAnalytics,
  ReturnTypeOfSingleTagAnalytics,
  ReturnTypeOfTagsAnalytics,
  ReturnTypeOfUserCatalogUsageAnalytics,
  ReturnTypeOfUserStewardActivitiesAnalytics,
  SingleGlossaryAnalyticsResponseType,
  SingleSourceAnalyticsResponseType,
  SingleTagAnalyticsResponseType,
  TagsAnalyticsResponse,
  UsersAnalyticsResponse,
  ReturnTypeOfUsersAnalytics,
  ReturnTypeOfAnalyticsDictionartAlalytics,
  ReturnTypeOfSinglAnalyticsSourceAnalytics,
  AnalyticsDictionaryAnalyticsResponseType,
  AnalyticsSourceSummaryResponseType,
} from "./analyticsparser.types";
// <--- Types End --->

// <--- Utils Start --->
import {
  calculatePercentageFromTotal,
  getNodeIcon,
  jsonParse,
  toFixed,
} from "../../utils";
// <--- Utils End --->

export function getAnalyticsStats({
  data,
}: AxiosResponse<AnalyticsStatsResponse>): ReturnTypeOfGetAnalyticsStats {
  const { data: analytics } = data || {};

  const dataSources = analytics?.find((item) => item?.NODE_TYPE === "CSR");
  const tables = analytics?.find((item) => item?.NODE_TYPE === "TBL");
  const columns = analytics?.find((item) => item?.NODE_TYPE === "COL");

  const analyticsSources = analytics?.find((item) => item?.NODE_TYPE === "ASR");
  const models = analytics?.find((item) => item?.NODE_TYPE === "DSR");
  const reports = analytics?.find((item) => item?.NODE_TYPE === "RPT");

  const domains = analytics?.find((item) => item?.NODE_TYPE === "DMN");
  const subCategories = analytics?.find((item) => item?.NODE_TYPE === "CAT");
  const terms = analytics?.find((item) => item?.NODE_TYPE === "TRM");

  const admins = analytics?.find((item) => item?.NODE_TYPE === "ADM");
  const editors = analytics?.find((item) => item?.NODE_TYPE === "EDT");
  const viewers = analytics?.find((item) => item?.NODE_TYPE === "VWR");

  return {
    data_catalog: {
      title: dataSources?.GROUP_SECTION || "",
      data: [
        {
          count: dataSources?.NODE_COUNT || 0,
          node_type: dataSources?.NODE_TYPE,
          name: dataSources?.NODE_NAME || "",
          icon: sourcesStatsIcon(),
        },
        {
          count: tables?.NODE_COUNT || 0,
          node_type: tables?.NODE_TYPE,
          name: tables?.NODE_NAME || "",
          icon: datasetsStatsIcon(),
        },
        {
          count: columns?.NODE_COUNT || 0,
          node_type: columns?.NODE_TYPE,
          name: columns?.NODE_NAME || "",
          icon: fieldsStatsIcon(),
        },
      ],
    },
    analytics_catalog: {
      title: analyticsSources?.GROUP_SECTION || "",
      data: [
        {
          count: analyticsSources?.NODE_COUNT || 0,
          node_type: analyticsSources?.NODE_TYPE,
          name: analyticsSources?.NODE_NAME || "",
          icon: sourcesStatsIcon(),
        },
        {
          count: models?.NODE_COUNT || 0,
          node_type: models?.NODE_TYPE,
          name: models?.NODE_NAME || "",
          icon: modelsStatsIcon(),
        },
        {
          count: reports?.NODE_COUNT || 0,
          node_type: reports?.NODE_TYPE,
          name: reports?.NODE_NAME || "",
          icon: reportStatsIcon(),
        },
      ],
    },
    glossary: {
      title: domains?.GROUP_SECTION || "",
      data: [
        {
          count: domains?.NODE_COUNT || 0,
          node_type: domains?.NODE_TYPE,
          name: domains?.NODE_NAME || "",
          icon: domainsStatsIcon(),
        },
        {
          count: subCategories?.NODE_COUNT || 0,
          node_type: subCategories?.NODE_TYPE,
          name: subCategories?.NODE_NAME || "",
          icon: categoriesStatsIcon(),
        },
        {
          count: terms?.NODE_COUNT || 0,
          node_type: terms?.NODE_TYPE,
          name: terms?.NODE_NAME || "",
          icon: termStatsIcon(),
        },
      ],
    },
    users: {
      title: admins?.GROUP_SECTION || "",
      data: [
        {
          count: admins?.NODE_COUNT || 0,
          node_type: admins?.NODE_TYPE,
          name: admins?.NODE_NAME || "",
          icon: adminsStatsIcon(),
        },
        {
          count: editors?.NODE_COUNT || 0,
          node_type: editors?.NODE_TYPE,
          name: editors?.NODE_NAME || "",
          icon: editorsStatsIcon(),
        },
        {
          count: viewers?.NODE_COUNT || 0,
          node_type: viewers?.NODE_TYPE,
          name: viewers?.NODE_NAME || "",
          icon: userGroupsStatsIcon(),
        },
      ],
    },
  };
}

export function getDataDictionaryAnalytics({
  data,
}: AxiosResponse<DataDictionaryAnalyticsResponseType>): ReturnTypeOfDataDictionartAlalytics[] {
  const { data: sources = [] } = data || {};

  return sources?.map((item) => ({
    id: `${item?.SRC_ID}`,
    dataset_count: item?.TBL_COUNT,
    dataset_usability: Number(toFixed(item?.TBL_USB || "")),
    fields_count: Number(item?.COL_COUNT || ""),
    fields_data_quality: Number(toFixed(item?.COL_DQ_SCORE || "")),
    fields_usability: Number(toFixed(item?.COL_USB || "")),
    quality: Number(toFixed(item?.SRC_DQ_SCORE || "")),
    source: item?.SRC_NAME || "",
    title: item?.SRC_NAME || "",
    usability: Number(toFixed(item?.SRC_USB || "")),
    datasets: "",
    fields: "",
    src_type: item?.SRC_TYPE,
  }));
}

export function getAnalyticsDictionaryAnalytics({
  data,
}: AxiosResponse<AnalyticsDictionaryAnalyticsResponseType>): ReturnTypeOfAnalyticsDictionartAlalytics[] {
  const { data: sources = [] } = data || {};
  return sources?.map((item) => ({
    id: `${item?.SRC_ID}`,
    dataset_count: Number(item?.DSR_COUNT || ""),
    dataset_usability: Number(toFixed(item?.DSR_USB || "")),
    metrics_count: Number(item?.DSF_COUNT || ""),
    metrics_usability: Number(toFixed(item?.DSF_USB || "")),
    source: item?.SRC_NAME || "",
    title: item?.SRC_NAME || "",
    source_usability: Number(toFixed(item?.SRC_USB || "")),
    reports_count: Number(item?.RPT_COUNT || ""),
    reports_usability: Number(toFixed(item?.RPT_USB || "")),
    datasets: "",
    fields: "",
    src_type: item?.SRC_TYPE,
    metrics: "",
    reports: "",
  }));
}

export function getGlossaryAnalytics({
  data,
}: AxiosResponse<GlossaryAnalyticsResponseType>): ReturnTypeOfGlossaryAnalytics[] {
  const { data: glosseries } = data || {};

  return glosseries?.map((glossary) => ({
    domain_name: glossary?.DMN_NAME || "",
    categories: glossary?.CATEGORY_CNT,
    entities: {
      count: glossary?.TRM_COUNT,
      percVal: Number(toFixed(glossary?.TRM_PERCENT || "")),
    },
    id: `${glossary?.DMN_ID}`,
    linked_to_data_assets: glossary?.LINKED_COLS_CNT,
    title: glossary?.DMN_NAME || "",
    map_entities_count: glossary?.COL_MAP_CNT,
    entities_count: glossary?.TRM_COUNT,
    mapped_entities_percentage: toFixed(glossary?.TRM_PERCENT || ""),
  }));
}

export function getTagsAnalytics({
  data,
}: AxiosResponse<TagsAnalyticsResponse>): ReturnTypeOfTagsAnalytics[] {
  const { data: tagsets = [] } = data || {};

  return tagsets?.map((item) => ({
    id: `${item?.TAGSET_ID}`,
    taged_assets: Number(item?.TAG_USAGE_CNT || ""),
    tags: {
      count: Number(toFixed(item?.TAG_COUNT)),
      percVal: Number(toFixed(item?.TAG_PRCT)),
    },
    tagset_name: item?.TAGSET_NAME || "",
    title: item?.TAGSET_NAME || "",
    desc: item?.TAGSET_DESC || "",
    tags_count: Number(toFixed(item?.TAG_COUNT)),
    mapped_tags_count_prect: `${Number(toFixed(item?.TAG_PRCT))}`,
  }));
}

export function getUsersAnalytics({
  data,
}: AxiosResponse<UsersAnalyticsResponse>): ReturnTypeOfUsersAnalytics {
  const {
    admin_count = 0,
    editor_count = 0,
    viewer_count = 0,
    stewards_data: stewardsDataRes = [],
    users_data: usersDataRes = [],
  } = data || {};

  const usersData: ReturnTypeOfUserCatalogUsageAnalytics[] = usersDataRes?.map(
    (item) => {
      const {
        USER_FIRST_NAME = "",
        USER_LAST_NAME = "",
        SEARCH_COUNT = 0,
        CONTRIB_COUNT = 0,
        USER_STREAK = 0,
        USER_ID,
      } = item;

      const streak = USER_STREAK ? `${USER_STREAK} day streak` : "";
      const contrib = CONTRIB_COUNT
        ? `${CONTRIB_COUNT} contributions ${streak ? "," : ""}`
        : "";
      const searches = SEARCH_COUNT
        ? `${SEARCH_COUNT} searches ${contrib || streak ? "," : ""}`
        : "";
      const noActivityperformed =
        !SEARCH_COUNT && !USER_STREAK && !CONTRIB_COUNT;

      const activityCount = SEARCH_COUNT + CONTRIB_COUNT;

      return {
        activity_count: activityCount,
        steak_count: USER_STREAK,
        id: `${USER_ID}`,
        name: `${USER_FIRST_NAME} ${USER_LAST_NAME}`,
        notes: `${searches} ${contrib} ${streak}`,
        rank:
          activityCount > 0 && activityCount <= 5
            ? "bronze"
            : activityCount > 5 && activityCount < 15
            ? "silver"
            : activityCount >= 15
            ? "gold"
            : "NA",
        is_no_activity_performed: noActivityperformed,
      };
    }
  );

  const stewardsData: ReturnTypeOfUserStewardActivitiesAnalytics[] = stewardsDataRes?.map(
    (item) => {
      const {
        ASSIGNED_ASSETS = "",
        CONTRIB_COUNT = 0,
        SEARCH_COUNT = 0,
        USER_STREAK = 0,
        USER_FIRST_NAME = "",
        USER_LAST_NAME = "",
        AVG_USB = 0,
        ROLE_NAME = "",
        USER_ID,
      } = item;
      return {
        assigned_assets: Number(ASSIGNED_ASSETS),
        curation_activity_count: SEARCH_COUNT + CONTRIB_COUNT,
        steak_count: USER_STREAK,
        id: `${USER_ID}`,
        name: `${USER_FIRST_NAME} ${USER_LAST_NAME}`,
        usability_score: Number(toFixed(AVG_USB)),
        role: ROLE_NAME,
      };
    }
  );

  return {
    catalog_usage: usersData,
    steward_data: stewardsData,
    admin_count,
    editor_count,
    viewer_count,
  };
}

export function getSingleSourceAnalytics({
  data,
}: AxiosResponse<SingleSourceAnalyticsResponseType>): RetunTypeOfSingleSourceAnalytics[] {
  const { data: summaryAnalysis } = data || {};
  const {
    COL_CNT: colCount = 0,
    COL_DESC_CNT: colDescCount = 0,
    COL_ENTITY_CNT: colEntityCount = 0,
    TBL_CNT: tblCount = 0,
    TBL_DESC_CNT: tblDescCount = 0,
    TBL_FUNC_AREA_CNT: tblEntityCount = 0,
    TBL_OWNER_CNT: tblOwnerCount = 0,
    COL_PRF_CNT: colProfillingCount = 0,
  } = summaryAnalysis?.[0] || {};

  const totalCount = colCount + tblCount;
  const totalDescCount = tblDescCount + colDescCount;
  const totalEntityCount = tblEntityCount + colEntityCount;
  const totalOwnerShipCount = tblOwnerCount;
  const totalProfilingCount = colProfillingCount;

  return [
    {
      name: "All Items",
      profiling: {
        count: totalProfilingCount,
        percVal: calculatePercentageFromTotal(totalProfilingCount, colCount),
      },
      total: {
        count: totalCount,
        percVal: calculatePercentageFromTotal(
          tblDescCount +
            tblEntityCount +
            tblOwnerCount +
            colDescCount +
            colEntityCount +
            colProfillingCount,
          (tblCount + colCount) * 3
        ),
      },
      description: {
        count: totalDescCount,
        percVal: calculatePercentageFromTotal(totalDescCount, totalCount),
      },
      linked_to_entity: {
        count: totalEntityCount,
        percVal: calculatePercentageFromTotal(totalEntityCount, totalCount),
      },
      ownership: {
        count: totalOwnerShipCount,
        percVal: calculatePercentageFromTotal(totalOwnerShipCount, tblCount),
      },
      id: "All Items",
    },
    {
      name: "Datasets",
      profiling: {
        count: 0,
        percVal: 0,
        showText: false,
      },
      total: {
        count: tblCount,
        percVal: calculatePercentageFromTotal(
          tblDescCount + tblOwnerCount + tblEntityCount,
          3 * tblCount
        ),
      },
      description: {
        count: tblDescCount,
        percVal: calculatePercentageFromTotal(tblDescCount, tblCount),
      },
      linked_to_entity: {
        count: tblEntityCount,
        percVal: calculatePercentageFromTotal(tblEntityCount, tblCount),
      },
      ownership: {
        count: tblOwnerCount,
        percVal: calculatePercentageFromTotal(tblOwnerCount, tblCount),
      },
      id: "Datasets",
    },
    {
      name: "Fields",
      profiling: {
        count: colProfillingCount,
        percVal: calculatePercentageFromTotal(colProfillingCount, colCount),
      },
      total: {
        count: colCount,
        percVal: calculatePercentageFromTotal(
          colDescCount + colEntityCount + colProfillingCount,
          3 * colCount
        ),
      },
      description: {
        count: colDescCount,
        percVal: calculatePercentageFromTotal(colDescCount, colCount),
      },
      linked_to_entity: {
        count: colEntityCount,
        percVal: calculatePercentageFromTotal(colEntityCount, colCount),
      },
      ownership: {
        count: 0,
        percVal: 0,
        showText: false,
      },
      id: "Fields",
    },
  ];
}

export function getSinglAnalyticsSourceAnalytics({
  data,
}: AxiosResponse<AnalyticsSourceSummaryResponseType>): ReturnTypeOfSinglAnalyticsSourceAnalytics[] {
  const { data: summary = [] } = data || {};

  const {
    DSF_CNT: datasourceFieldCount = 0,
    DSF_DESC_CNT: datasourceFieldDescCount = 0,
    DSF_DUPLICATE_CNT: datasourceFieldDuplicateCount = 0,
    DSF_LINEAGE_CNT: datasourceFieldLineageCount = 0,
    DSR_CNT: datasourceCount = 0,
    DSR_DESC_CNT: datasourceDescCount = 0,
    DSR_LINEAGE_CNT: datasourceLineageCount = 0,
    RPT_CNT: reportCount = 0,
    RPT_DESC_CNT: reportDescCount = 0,
    RPT_DUPLICATE_CNT: reportDuplicateCount = 0,
    RPT_LINEAGE_CNT: reportLineageCount = 0,
  } = summary?.[0] || {};

  const totalCount = datasourceCount + reportCount;

  const totalDescCount = datasourceDescCount + reportDescCount;

  const totalLineageCount = datasourceLineageCount + reportLineageCount;

  const totalDuplicateCount = reportDuplicateCount;

  return [
    {
      id: "Total",
      name: "Total",
      duplicate: {
        count: totalDuplicateCount,
        percVal: calculatePercentageFromTotal(
          totalDuplicateCount,
          datasourceCount + reportCount
        ),
        isEmpty: true,
      },
      lineage: {
        count: totalLineageCount,
        percVal: calculatePercentageFromTotal(totalLineageCount, totalCount),
      },
      description: {
        count: totalDescCount,
        percVal: calculatePercentageFromTotal(totalDescCount, totalCount),
      },
    },
    {
      id: "Datasets",
      name: "Datasets",
      duplicate: {
        count: 0,
        percVal: 0,
        showText: false,
      },
      lineage: {
        count: datasourceLineageCount,
        percVal: calculatePercentageFromTotal(
          datasourceLineageCount,
          datasourceCount
        ),
      },
      description: {
        count: datasourceDescCount,
        percVal: calculatePercentageFromTotal(
          datasourceDescCount,
          datasourceCount
        ),
      },
    },
    {
      id: "Reports",
      name: "Reports",
      duplicate: {
        count: reportDuplicateCount,
        percVal: calculatePercentageFromTotal(
          reportDuplicateCount,
          reportCount
        ),
        isEmpty: true,
      },
      lineage: {
        count: reportLineageCount,
        percVal: calculatePercentageFromTotal(reportLineageCount, reportCount),
      },
      description: {
        count: reportDescCount,
        percVal: calculatePercentageFromTotal(reportDescCount, reportCount),
      },
    },
    {
      id: "Metrics",
      name: "Metrics",
      duplicate: {
        count: datasourceFieldDuplicateCount,
        percVal: calculatePercentageFromTotal(
          datasourceFieldDuplicateCount,
          datasourceFieldCount
        ),
        isEmpty: true,
      },
      lineage: {
        count: datasourceFieldLineageCount,
        percVal: calculatePercentageFromTotal(
          datasourceFieldLineageCount,
          datasourceFieldCount
        ),
      },
      description: {
        count: datasourceFieldDescCount,
        percVal: calculatePercentageFromTotal(
          datasourceFieldDescCount,
          datasourceFieldCount
        ),
      },
    },
  ];
}

export function getSingleGlossaryAnalytics({
  data,
}: AxiosResponse<SingleGlossaryAnalyticsResponseType>): RetunTypeOfSingleGlossaryAnalytics[] {
  const { data: singleeGlossarySummary } = data || {};

  return singleeGlossarySummary?.map((item) => {
    const parsedSampleColum = jsonParse(
      item?.SMPL_LINKED_COLUMNS,
      false
    ) as SampleLinkedColType[];

    return {
      category_name: item?.CAT_NAME || "",
      domain_name: item?.DMN_NAME || "",
      desc: item?.TRM_DESC || "",
      entity: item?.TRM_NAME || "",
      id: `${item?.TRM_ID}`,
      linked_data_dictionary_items: item?.LINKED_COLS_CNT,
      samples: parsedSampleColum?.slice(0, 2)?.map((col) => ({
        nodeType: col?.node_type,
        items: [
          {
            id: `${col?.node_id}`,
            name: col?.node_name || "",
            nodeType: col?.node_type,
            nodeSubType: col?.node_sub_type,
            openRefView: true,
            isClickable: true,
          },
        ],
      })),
    };
  });
}

export function getSingleTagsetAnalytics({
  data,
}: AxiosResponse<SingleTagAnalyticsResponseType>): ReturnTypeOfSingleTagAnalytics[] {
  const { data: tags } = data || {};

  return tags?.map((item) => {
    const parsedSampleColum = jsonParse(
      item?.TAG_COL_NAMES,
      false
    ) as SampleLinkedColType[];

    return {
      desc: item?.TAG_DESC || "",
      id: `${item?.TAG_ID}`,
      linked_count: item?.TAG_USAGE_CNT,
      sample_assets: parsedSampleColum?.slice(0, 2)?.map((col) => ({
        nodeType: col?.node_type,
        items: [
          {
            id: `${col?.node_id}`,
            name: col?.node_name || "",
            icon: getNodeIcon(col?.node_type),
            nodeType: col?.node_type,
            openRefView: true,
            isClickable: true,
            nodeSubType: col?.node_sub_type,
          },
        ],
      })),
      tag_name: item?.TAG_NAME || "",
      tagset_name: item?.TAGSET_NAME || "",
      tagset_id: `${item?.TAGSET_ID}`,
    };
  });
}
