import { Tag, Tooltip } from "antd";
import { useMemo } from "react";

import { DVSUM_TOOLTIP_CLASS_NAME } from "../../constants";

import {
  RemainingTagListItem,
  TagsBasedGridFiltersStyled,
} from "./tagsbasedgridfilters.styles";

import { TagsBasedGridFiltersProps } from "./tagsbasedgridfilters.types";

const TagsBasedGridFilters = (
  props: TagsBasedGridFiltersProps
): JSX.Element => {
  const {
    filters = [],
    label = "",
    onClearAll,
    showLabel = true,
    mode = "primary",
  } = props;

  const isFilterExists = useMemo(() => !!filters?.length, [filters?.length]);

  return isFilterExists ? (
    <TagsBasedGridFiltersStyled
      mode={mode}
      data-testid="tags-based-grid-filters-main-wrapper"
    >
      {showLabel && (
        <div
          className="filters-list-label"
          data-testid="tags-based-grid-filters-label-text"
        >
          {label || "Filtered by:"}{" "}
        </div>
      )}
      <div
        className="filters-list-wrapper"
        data-testid="tags-based-grid-filters-list-main-wrapper"
      >
        {filters?.map((filter, index) => {
          const {
            filter_name: filterName = "",
            val = [],
            onClear,
            count = 0,
            oprator = "",
          } = filter;

          const upfrontTags = val?.slice(0, 2) || [];
          const remainingTags = val?.slice(2, val?.length) || [];

          return (
            <Tag
              key={filterName + val + index}
              closable={!!onClear}
              onClose={(): void => onClear?.(filter)}
              className="filter-tag"
              data-testid="tags-based-grid-filters-filter-tag"
            >
              {!!count && (
                <span
                  className="filter-count"
                  data-testid="tags-based-grid-filters-filter-count"
                >
                  {count}
                </span>
              )}
              {!!filterName && (
                <span data-testid="tags-based-grid-filters-filter-name">
                  {filterName}:
                </span>
              )}{" "}
              {!!oprator && (
                <span
                  className="filter-operator"
                  data-testid="tags-based-grid-filters-filter-operator"
                >
                  {" "}
                  {`${oprator}`}{" "}
                </span>
              )}
              {!!val && (
                <span
                  className="filter-val"
                  data-testid="tags-based-grid-filters-filter-value"
                >
                  {upfrontTags?.join(", ")}
                </span>
              )}
              {!!remainingTags?.length && (
                <Tooltip
                  overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
                  placement="bottomRight"
                  title={
                    <>
                      {remainingTags?.map((remTag, index) => (
                        <RemainingTagListItem
                          key={`tags-based-grid-filters-${index}`}
                          style={{ paddingRight: 50 }}
                          data-testid="tags-based-grid-filters-remaining-tag-list-item"
                        >
                          {remTag}
                        </RemainingTagListItem>
                      ))}
                    </>
                  }
                >
                  <div
                    className="remaining-tags"
                    data-testid="tags-based-grid-filters-remaining-tags-count"
                  >
                    +{remainingTags?.length}
                  </div>
                </Tooltip>
              )}
            </Tag>
          );
        })}
        {!!onClearAll && (
          <div
            className="clear-all-btn"
            role="button"
            onClick={(): void => onClearAll?.()}
            data-testid="tags-based-grid-filters-clear-all-btn"
          >
            Clear All
          </div>
        )}
      </div>
    </TagsBasedGridFiltersStyled>
  ) : (
    <div />
  );
};

export default TagsBasedGridFilters;
