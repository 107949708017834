export const convertMillisecondsToValidDate = (val: number): string => {
  try {
    const date = new Date(val);
    const iso = date
      .toISOString()
      .match(/(\d{4}\-\d{2}\-\d{2})T(\d{2}:\d{2}:\d{2})/);

    return `${iso?.[1]} ${iso?.[2]}`;
  } catch (err) {
    return "";
  }
};
