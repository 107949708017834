import styled from "styled-components";

export const MessageInputFieldStyled = styled.div<{
  marginBottom?: string;
  marginTop?: string;
  iconPlacement?: string;
}>`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  margin-bottom: ${({ marginBottom }): string => marginBottom || "0"};
  margin-top: ${({ marginTop }): string => marginTop || "0"};

  .form-style {
    width: 100%;

    .ant-input {
      font-size: 14.4px;
      padding: 19px 14.4px;
      padding-right: 90px;
      min-height: 60.8px;
      max-height: 110px !important;
      box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
    }

    .ant-btn-link {
      position: absolute;
      right: 28px;
      top: ${({ iconPlacement }): string =>
        iconPlacement === "top" ? "23px" : ""};
      bottom: ${({ iconPlacement }): string =>
        iconPlacement === "bottom" ? " 21.5px" : ""};

      display: flex;
      align-items: flex-end;
      cursor: pointer;
    }
  }
`;
