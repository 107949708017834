import styled from "styled-components";
import { ellipsis } from "../../../styles/app.styles";
import { hexToRgbA } from "../../../utils";
import { SimpleTagProps } from "./simpletag.types";

export const TagStyled = styled.div<SimpleTagProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${(props): string => props?.minWidth || "50px"};
  max-width: ${(props): string => props?.width || "90px"};
  min-height: ${(props): string => props?.minHeight || "28px"};
  ${(props): any => props?.height && `height:${props?.height};`}
  border-radius: 4px;
  border: solid 1px #d5d5d5;
  background-color: ${({ theme }): string => theme.bgColors.LIGHT_GREY_7};
  color: ${({ theme }): string => theme.textColors.DARK_GREY};
  font-size: 13px;
  margin-right: ${(props): string => props?.marginRight || "5px"};
  .content {
    padding: 0 6px;
    color: ${({ theme }): string => hexToRgbA(theme.textColors.DARK_GREY, 0.8)};
    max-width: ${(props): string => props?.width || "90px"};
    ${ellipsis}
  }
`;
