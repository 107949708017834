import styled from "styled-components";

export const AdditionalResourcesSecStyled = styled.div`
  .resources-sec {
    display: flex;
    flex-direction: column;

    .resource-item {
      color: ${({ theme }): string => theme?.textColors?.WHITE_COLOR};
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      padding: 0 10px;

      .resource-content {
        display: flex;
        align-items: center;
        column-gap: 8px;
      }

      svg {
        path {
          stroke: ${({ theme }): string =>
            theme.textColors.WHITE_COLOR} !important;
        }
      }
    }

    .resource-item-disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
`;
