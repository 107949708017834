import { Button } from "antd";
import styled from "styled-components";
import { ButtonStyledProps } from "./button.interfaces";
import { StyledMapping } from "./button.util";

export const ButtonStyled = styled(Button)<ButtonStyledProps>`
  margin-left: ${({ marginLeft }): any => marginLeft};
  ${(props): string =>
    props.btnType === "link" || props.btnType === "text"
      ? `
      color: ${StyledMapping(props.btnType, props.theme).textColor || ""};
      :hover,
      :focus {
        background: ${StyledMapping(props.btnType, props.theme).bgColor || ""};
        border-color: ${
          StyledMapping(props.btnType, props.theme).borderColor || ""
        };
        color: ${StyledMapping(props.btnType, props.theme).textColor || ""};
      }
      `
      : `
        border: 3px solid;
        box-shadow: none;
        outline: none;
        color: ${props.theme.textColors.WHITE_COLOR};
        display: flex;
        align-items: center;
        padding: 4px 10px;
        border-radius: 0px;
        min-width: ${props?.width};
        min-height: ${props?.height || "32px"};
        display: flex;
        align-items: center;
        justify-content: center;
        font-size:${props?.fontSize || "14px"};
        `}
  background: ${(props): string =>
    StyledMapping(props.btnType, props.theme).bgColor || ""};
  border-color: ${(props): string =>
    StyledMapping(props.btnType, props.theme).borderColor || ""};
  color: ${(props): string =>
    StyledMapping(props.btnType, props.theme).textColor || ""};
  font-weight: ${(props): string =>
    StyledMapping(props.btnType, props.theme).fontWeight || ""};
  :hover,
  :focus {
    background: ${(props): string =>
      StyledMapping(props.btnType, props.theme).focusBgColor || ""};
    border-color: ${(props): string =>
      StyledMapping(props.btnType, props.theme).focusBorderColor || ""};
    color: ${(props): string =>
      StyledMapping(props.btnType, props.theme).focusTextColor || ""};
  }

  :hover {
    opacity: 0.8;
  }

  :disabled {
    background: ${(props): string =>
      StyledMapping(props.btnType, props.theme).disabledBgColor || ""};
    border-color: ${(props): string =>
      StyledMapping(props.btnType, props.theme).disabledBorderColor || ""};
    color: ${(props): string =>
      StyledMapping(props.btnType, props.theme).disabledTextColor || ""};
    opacity: ${(props): string =>
      StyledMapping(props.btnType, props.theme).disabledOpacity || ""};
    :hover,
    :focus {
      background: ${(props): string =>
        StyledMapping(props.btnType, props.theme).disabledFocusBgColor || ""};
      border-color: ${(props): string =>
        StyledMapping(props.btnType, props.theme).disabledFocusBorderColor ||
        ""};
      color: ${(props): string =>
        StyledMapping(props.btnType, props.theme).disabledFocusTextColor || ""};
    }
  }
`;
