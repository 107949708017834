// <--- Constants Start --->
import { APP_ROUTES } from "../constants";
import {
  ChatbotAnalyticsViewKeys,
  ChatbotPageParamViewKeys,
  chatbotPageTabValue,
} from "../pages/analysisdetailpage/analysisdetailpage.types";

// <--- Constants End --->

export function getAnalysisDetailPageUrl(
  analysisId: string,
  tab?: ChatbotPageParamViewKeys,
  view?: ChatbotAnalyticsViewKeys
): string {
  const finalTab =
    tab ||
    (analysisId
      ? chatbotPageTabValue?.["train-bot"]
      : chatbotPageTabValue?.definition);

  const finalView = view ? `/${view}` : "";

  return analysisId
    ? `${APP_ROUTES.private_routes.chat_bot}/${analysisId}/${finalTab}${finalView}`
    : `${APP_ROUTES.private_routes.chat_bot}/${finalTab}${finalView}`;
}
