// <--- Library Imports Start --->
import styled from "styled-components";
// <--- Library Imports End --->

// <--- Utils Start --->
import { hexToRgbA } from "../../../../utils";
// <--- Utils End --->

export const DQRuleDetailsModalStyled = styled.div<{ isLoading: boolean }>`
  ${({ isLoading }): string =>
    isLoading
      ? `
        max-height: calc(100vh - 57px);
        overflow: auto;
`
      : ""}

  .ant-form {
    .form-actions-sec {
      padding-right: 51px;
    }
  }
`;

export const DQRuleDetailsModalChildStyled = styled.div`
  > .tabs-with-right-sec {
    background-color: ${({ theme }): string => theme?.bgColors?.WHITE_COLOR};
    border-bottom: 1px solid
      ${({ theme }): string =>
        hexToRgbA(theme?.borderColors?.LIGHT_GREY_11, 0.1)};
    padding-left: 20px;
    padding-right: 35px;
    margin-bottom: 0px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.08);

    .tabs {
      padding-top: 14px;
      padding-bottom: 11px;

      .ant-btn-link::after {
        top: 41px;
      }

      .ant-btn-link:not(:last-of-type) {
        margin-right: 33px;
      }
    }
  }

  .dq-history-chart {
    padding: 35px 57px 35px 35px;
    min-height: calc(100vh - 164px);
    max-height: calc(100vh - 164px);
    overflow-y: auto;
  }

  .other-content-sec {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 45px;
    min-height: calc(100vh - 164px);
    max-height: calc(100vh - 164px);
    overflow-y: auto;
    padding-top: 34px;
  }
`;
