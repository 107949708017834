import { getObjectKeys, numberFormatter } from "../../../utils";

export const calculateInterval = (
  chartWidth: number,
  dataLength: number
): number => {
  // const dataLength = data.length;
  const minSpaceBetweenLabels = 40; // Minimum pixels between labels
  const calculatedInterval =
    Math.ceil(dataLength / (chartWidth / minSpaceBetweenLabels)) - 1;

  return Math.max(1, calculatedInterval);
};

export const calculateHeight = (
  entry: any,
  dataKey: string,
  chartHeight: number
): number => {
  const total = getObjectKeys(entry)
    ?.filter((val) => val !== "name")
    .reduce((sum, key) => sum + entry[key], 0);
  return (entry[dataKey] / total) * chartHeight;
};

export const formatWhenFloat = (num: number): string => {
  // Check if the number is a float
  if (num % 1 !== 0) {
    return num.toFixed(1); // Apply .toFixed(1) if it's a float
  }
  return `${num}`; // Return as is if it's an integer
};
