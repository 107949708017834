import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

import { Button } from "../../components";
import { SelectField } from "../../components/formfields";
import StateHandler from "../../components/statehandler/statehandler";

import { useGetGlossaryDomainsForSelect } from "../../api/glossarydomainsservice";
import { API_CONFIG } from "../../constants/apiconfig";
import { useRequestWithMethod } from "../../api";

import { useCancelModal, useGetAppState } from "../../customhooks";

import { deleteGlossaryDomainSchema } from "../../utils/schemas/glossarydomainschemas";

import { GlossaryDomainsGridColumnType } from "../../pages/glossarydomainspage/glossarydomainspage.types";
import { DeleteGlossaryDomainType } from "./deleteglossarydomainform.types";

import { FormItemStyled, FormStyled } from "../../components/form";
import { DeleteGlossaryDomainFormStyled } from "./deleteglossarydomainform.styles";
import { getGlobalFiltersData } from "../../utils/getglobalfiltersdata";
import { GlobalFiltersData } from "../../parsers/globalfiltersparser/globalfiltersparser.types";
import { LOCAL_STORAGE_CONSTANTS } from "../../constants";

const DeleteGlossaryDomainForm = (): JSX.Element => {
  const {
    parsedData: parsedGlossaryDomains = [],
    isLoading: isLoadingDomains,
    error: errorDomains,
  } = useGetGlossaryDomainsForSelect();

  const {
    modal: { modalProps = {} },
  } = useGetAppState();
  const queryClient = useQueryClient();
  const onCancel = useCancelModal();

  const { id = 0, name = "" } = modalProps as GlossaryDomainsGridColumnType;

  const globalFiltersData = getGlobalFiltersData();
  const { selected_filters: selectedFilters } = globalFiltersData || {};
  const { domain: domainGlobalFilters } = selectedFilters || {};
  const { filters_list: domainsGlobalFiltersList = [] } =
    domainGlobalFilters || {};

  const parsedDomains = useMemo(() => {
    return parsedGlossaryDomains?.filter((domain) => domain.label !== name);
  }, [name, parsedGlossaryDomains]);

  const {
    control,
    setValue,
    handleSubmit,
    formState: { isValid },
  } = useForm<DeleteGlossaryDomainType>({
    defaultValues: {
      assign_categories_to: undefined,
    },
    resolver: yupResolver(deleteGlossaryDomainSchema),
    mode: "onChange",
  });

  // APIs Integration
  const onSuccess = useCallback(() => {
    const api = API_CONFIG?.get_all_glossary_domains?.url;
    queryClient.invalidateQueries(api, { fetching: false });

    const updatedDomainGlobalFiltersList = domainsGlobalFiltersList?.filter(
      (filter) => filter?.id !== id
    );

    const updatedGlobalFilersData: GlobalFiltersData = {
      ...globalFiltersData,
      selected_filters: {
        ...selectedFilters,
        domain: {
          ...domainGlobalFilters,
          filters_list: updatedDomainGlobalFiltersList,
        },
      },
    };

    localStorage.setItem(
      LOCAL_STORAGE_CONSTANTS?.global_filters,
      JSON.stringify(updatedGlobalFilersData || {})
    );

    onCancel();
  }, [globalFiltersData, id]);

  const {
    onExecuteRequest,
    isLoading: isLoadingDeleteDomain,
    error: errorDeleteDomain,
  } = useRequestWithMethod("delete_glossary_domain", [id], true, onSuccess);

  // Callbacks
  const onSubmit = useCallback((values) => {
    onExecuteRequest({
      new_domain_id: Number.parseInt(values?.assign_categories_to) || 0,
      in_node_type: "DMN",
    });
  }, []);

  return (
    <StateHandler
      error={errorDomains || errorDeleteDomain}
      isFetching={isLoadingDomains || isLoadingDeleteDomain}
      isModal
    >
      <DeleteGlossaryDomainFormStyled>
        <FormStyled
          isItemColumnLayout
          paddingLeft="110.5px"
          onFinish={handleSubmit(onSubmit) as any}
        >
          <div className="content-sec">
            <div className="static-text">
              You must assign Sub-Domains of
              <span className="bold"> {name}</span> to another domain.
            </div>
            <FormItemStyled label="Assign Sub-Domains To" required>
              <SelectField
                control={control}
                name="assign_categories_to"
                setValue={setValue}
                options={parsedDomains}
                width="396px"
                height="45.36px"
                isAllowClear={false}
                placeholder="Select Domain"
              />
            </FormItemStyled>
          </div>

          <FormItemStyled
            label=""
            className="form-actions-sec"
            marginBottom="0px"
          >
            <Button id="cancel" width="74px" height="39px" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              id="primary"
              width="74px"
              height="39px"
              marginLeft="8px"
              htmlType="submit"
              disabled={!isValid}
            >
              Re-Assign & Delete
            </Button>
          </FormItemStyled>
        </FormStyled>
      </DeleteGlossaryDomainFormStyled>
    </StateHandler>
  );
};

export default DeleteGlossaryDomainForm;
