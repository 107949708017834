import { useMemo } from "react";
import { AddRuleFromRulesetGridModalProps } from "./addrulefromrulesetgridmodal.types";

import { ModalStyled } from "../../../../../../components/modalwrapper/modalwrapper.styles";

import { ModalWrapperStyled } from "./addrulefromrulesetgridmodal.styles";
import AddValueRangeForm from "../../../addvaluerangeform/addvaluerangeform";

import { useGetColumnsWithDistribution } from "../../../../../../api/tablesservice";
import Statehandler from "../../../../../../components/statehandler";

import AddDataFormatForm from "../../../adddataformatform/adddataformatform";

import { useGetRefByType } from "../../../../../../api/refservice";
import {
  useGetAllPatterns,
  useGetRefDictionariesList,
} from "../../../../../../api/ruleservice";
import { sortObjectsArrayByKey } from "../../../../../../utils";

const AddRuleFromRulesetGridModal = (
  props: AddRuleFromRulesetGridModalProps
): JSX.Element => {
  const {
    isModalVisible = false,
    onHandleCancel,
    title = "",
    propsOnValueRangeFormSubmit,
    propsOnDataFormFormSubmit,
    formId,
    tableId = "",
    selectedColumnId = "",
    currentColumnDefinitionConfig,
  } = props;

  const {
    parsedData: columnsData,
    isLoading: columnsDataLoading,
    error: columnsDataError,
  } = useGetColumnsWithDistribution(tableId);

  const {
    parsedData: patternList,
    isLoading: patternListLoading,
    error: patternListError,
  } = useGetAllPatterns();

  const {
    parsedData: parsedDataFormatTypes,
    isLoading: isLoadingDataFormatTypes,
    error: errorInGettingDataFormatTypes,
  } = useGetRefByType("DATAFORMAT_TYPES");

  const columnSelectOptions = useMemo(() => {
    return (
      columnsData?.map((column) => ({
        value: `${column?.field_id}`,
        label: column?.field_display_name || "",
        type: column?.field_datatype,
      })) || []
    );
  }, [columnsData]);

  const {
    parsedData: refDictionariesList,
    isLoading: refDictListLoading,
    error: refDictListError,
  } = useGetRefDictionariesList();

  return (
    <ModalStyled
      width="750px"
      visible={isModalVisible}
      onCancel={onHandleCancel}
      title={title}
    >
      <Statehandler
        isFetching={
          columnsDataLoading ||
          isLoadingDataFormatTypes ||
          patternListLoading ||
          refDictListLoading
        }
        error={
          columnsDataError ||
          errorInGettingDataFormatTypes ||
          patternListError ||
          refDictListError
        }
        isModal
      >
        {/* TODO:   DO IT USING ADD RULE FORM COMP */}
        <ModalWrapperStyled>
          {formId === "VLR" ? (
            <AddValueRangeForm
              columnSelectOptions={columnSelectOptions}
              formId={formId}
              columnsData={columnsData}
              isFromRuleSet
              propsOnSubmit={propsOnValueRangeFormSubmit}
              propsOnCancel={onHandleCancel}
              ruleConfig={currentColumnDefinitionConfig}
              onAddRule={(): void => {}}
              ruleCategoryId="FND"
              refDictionariesList={refDictionariesList}
            />
          ) : (
            <AddDataFormatForm
              columnSelectOptions={columnSelectOptions}
              formId={formId}
              columnsData={columnsData}
              isFromRuleSet
              selectedColumnId={selectedColumnId}
              propsOnSubmit={propsOnDataFormFormSubmit}
              propsOnCancel={onHandleCancel}
              onAddRule={(): void => {}}
              ruleCategoryId="FND"
              dataFormatTypes={parsedDataFormatTypes}
              ruleConfig={currentColumnDefinitionConfig}
              patternList={patternList}
            />
          )}
        </ModalWrapperStyled>
      </Statehandler>
    </ModalStyled>
  );
};

export default AddRuleFromRulesetGridModal;
