import { calculatePercentageFromTotal, numberFormatter } from "../../utils";
import { BarWithProgressStyled } from "./barwithprogress.styles";
import { BarWithProgressProps } from "./barwithprogress.types";

const BarWithProgress = (props: BarWithProgressProps): JSX.Element => {
  const {
    showValue = false,
    value = 0,
    maxValue = 0,
    checkForSelected = false,
    isSelected = false,
  } = props;

  const width = calculatePercentageFromTotal(value, maxValue);

  return (
    <BarWithProgressStyled
      width={width > 3 ? width : 3}
      checkForSelected={checkForSelected}
      isSelected={isSelected}
    >
      <div
        className="val-perc"
        data-testid="visual-dist-chart-for-distribution-chart-val-perc"
      />
      {showValue && (
        <div
          className="val-occur"
          data-testid="visual-dist-chart-for-distribution-chart-val-occur"
        >
          {numberFormatter(`${value}`)}
        </div>
      )}
    </BarWithProgressStyled>
  );
};

export default BarWithProgress;
