import { AppState } from "../../reducers/appreducer/appreducer.types";

export function getDrawerWidth(
  id: AppState["drawer"]["drawerId"],
  isMaximized?: boolean
): number | string {
  switch (id) {
    case "column_ref":
      return 602;
    case "term_ref":
      return 602;
    case "table_ref":
      return 602;
    case "dvsum_help":
      return 425;
    case "analysis_ref":
      return 602;
    case "rule_edit_setting":
      return 438;
    case "table_view": {
      if (isMaximized) return "100vw";
      return "45vw";
    }

    default:
      return 602;
  }
}

export function getMaskBackground(id: AppState["drawer"]["drawerId"]): string {
  switch (id) {
    case "table_view":
      return "transparent";

    default:
      return "rgba(0, 0, 0, 0.45)";
  }
}

export function getBoxShadow(id: AppState["drawer"]["drawerId"]): string {
  switch (id) {
    case "table_view":
      return "0 3px 8px 0 rgba(0, 0, 0, 0.25)";

    default:
      return "none";
  }
}

export function enableMasking(id: AppState["drawer"]["drawerId"]): boolean {
  switch (id) {
    case "discovery_drawer":
    case "dvsum_help":
      return false;

    default:
      return true;
  }
}
