import { StaticTextRendrerStyled } from "./statictextrendrer.styles";
import { StaticTextRendrerProps } from "./statictextrendrer.types";

function StaticTextRendrer(props: StaticTextRendrerProps): JSX.Element {
  const { text, ...resProps } = props;
  return (
    <StaticTextRendrerStyled {...resProps} className="static-text-rendrer">
      {text}
    </StaticTextRendrerStyled>
  );
}

export default StaticTextRendrer;
