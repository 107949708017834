import styled from "styled-components";

export const SelectWithLabelAndDescStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 15px;

  .select-field-container {
    width: 460px;

    .ant-select-selector {
      min-height: 38px;
    }
  }

  .label {
    font-family: OpenSansSemiBold;
    font-size: 13.6px;
    margin-bottom: 0;
  }

  .desc {
    font-size: 12px;
    text-align: left;
    color: ${({ theme }): string => theme.textColors.GREY_24};
    max-width: 60%;
    margin-bottom: 5px;
  }
`;
