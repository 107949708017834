export const checkIfDateExpired = (expiryTime: string): boolean => {
  try {
    const currentTime = new Date().getTime();
    const givenTime = new Date(expiryTime).getTime();

    return givenTime < currentTime;
  } catch (error) {
    return false;
  }
};
