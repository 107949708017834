import { useGetAll } from "../baseservice";

import { API_CONFIG } from "../../constants/apiconfig";
import { CustomReactQueryHookReturnType } from "../services.types";

import {
  GlobalFiltersReturnType,
  SelectedGlobalFiltersReturnType,
} from "../../parsers/globalfiltersparser/globalfiltersparser.types";
import {
  getParsedDomainsGlobalFilter,
  getParsedSelectedGlobalFilter,
  getParsedSourcesGlobalFilter,
} from "../../parsers/globalfiltersparser/globalfiltersparser";
import { getFiltersfromLocalStorage } from "../../utils";

export function useGetGlobalFiltersService(
  type: "SRC" | "DMN"
): CustomReactQueryHookReturnType<GlobalFiltersReturnType> {
  const parsedNodeFilters = getFiltersfromLocalStorage(
    type === "DMN" ? "TRM" : "TBL"
  );

  return useGetAll({
    apiConfig: API_CONFIG?.get_all_global_filters,
    parser:
      type === "SRC"
        ? getParsedSourcesGlobalFilter
        : getParsedDomainsGlobalFilter,
    params: [type, parsedNodeFilters],
  });
}

export function useGetSelectedGlobalFiltersService(
  onSuccess?: (_response: unknown) => void
): CustomReactQueryHookReturnType<SelectedGlobalFiltersReturnType> {
  return useGetAll({
    apiConfig: API_CONFIG?.get_selected_global_filters,
    parser: getParsedSelectedGlobalFilter,
    options: {
      onSuccess,
      enabled: false,
    },
  });
}
