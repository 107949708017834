import { Fragment } from "react";
import { Link } from "react-router-dom";

//               <--Constants-->
import { APP_ROUTES } from "../../constants";
//               <--Constants-->

//               <--Styles-->
import { BreadcrumStyled } from "./breadcrum.styles";
//               <--Styles-->

//               <--SVGs-->
import { chevronRight, homeIcon } from "../../svgs";
//               <--SVGs-->

//               <--Types-->
import { BreadcrumProps } from "./breadcrum.types";
//               <--Types-->

function BreadCrum({ currentPage, currentPages }: BreadcrumProps): JSX.Element {
  return (
    <BreadcrumStyled>
      <div className="breadcrum-content">
        <div className="breadcrum-item">
          {homeIcon()}
          <Link
            to={APP_ROUTES.private_routes.home}
            data-testid="breadcrum-home-link"
          >
            Home
          </Link>
        </div>
        {currentPages?.length
          ? currentPages.map((path) => (
              <Fragment key={`key-bread-crumb-header-${path?.label}`}>
                <span className="sep-sec">{chevronRight}</span>
                <div className="paths-container">
                  <Link
                    className={`${path.link ? "path-link" : "breadcrum-item"}`}
                    to={path.link ? path.link : "#"}
                    data-testid={`breadcrum-${path?.label}-link`}
                  >
                    {path.label}
                  </Link>
                </div>
              </Fragment>
            ))
          : currentPage && (
              <>
                <div className="sep-sec">{chevronRight}</div>
                <div className="breadcrum-item">
                  {currentPage?.label || "path not exists"}
                </div>
              </>
            )}
      </div>
      {/* <div>{settingsIcon}</div> */}
    </BreadcrumStyled>
  );
}

export default BreadCrum;
