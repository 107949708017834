import styled from "styled-components";
import LinkButton from "../linkbutton/linkbutton";

export const ToggleButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 15px;
  padding: 8px 0;
`;

export const ToggleButtonStyled = styled(LinkButton)<{
  selected: boolean;
}>`
  min-width: 95px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  &,
  &:hover,
  &:focus {
    border: solid 1px
      ${({ theme, selected }): string =>
        selected
          ? theme?.borderColors?.BLUE_116
          : theme?.borderColors?.GREY_18};
    background-color: ${({ theme, selected }): string =>
      selected ? theme?.bgColors?.BLUE_116 : theme?.bgColors?.WHITE_COLOR};
    color: ${({ theme, selected }): string =>
      selected ? theme?.textColors?.WHITE_COLOR : theme?.textColors?.BLUE_116};
  }
`;
