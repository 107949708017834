import { LinkTabsProps } from "./linktabs.types";

import { LinkTabsStyled, TabButton } from "./linktabs.styles";

function LinkTabs(props: LinkTabsProps): JSX.Element {
  const { onChange, selectedTab, tabs, id = "default" } = props;
  return (
    <LinkTabsStyled data-testid="link-tabs-wrapper" className="tabs">
      {tabs.map((link) => (
        <TabButton
          data-testid={`link-tab-btn-${link?.key}`}
          className={`custom-tab-btn custom-btn-${link?.key} ${
            link?.key === selectedTab ? "active-tab" : "inactive-tab"
          }`}
          key={link?.key}
          value={link?.key}
          selected={link?.key === selectedTab}
          btnType={id}
          type="link"
          onClick={(): void => onChange(link?.key)}
          id={link?.id || ""}
          disabled={link?.disabled}
        >
          {link.icon && <span className="custom-tab-icon">{link.icon}</span>}
          {link?.value}
        </TabButton>
      ))}
    </LinkTabsStyled>
  );
}

export default LinkTabs;
