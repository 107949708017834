import * as yup from "yup";
import { REQUIRED_MESSAGE } from "../../constants/formconstants";

export const addGlossaryCategorySchema = yup.object().shape({
  domain: yup.string().required(REQUIRED_MESSAGE),
  name: yup.string().required(REQUIRED_MESSAGE),
  description: yup.string().required(REQUIRED_MESSAGE),
  priority: yup.string().required(REQUIRED_MESSAGE),
  enable_workflow: yup.boolean(),
  approvers: yup
    .array()
    .of(yup.string())
    .when("enable_workflow", {
      is: true,
      then: yup.array().of(yup.string()).min(1, "Select atleast 1 User"),
    }),
  wt_id: yup.mixed().when("enable_workflow", {
    is: true,
    then: yup.number().required(REQUIRED_MESSAGE),
  }),
});

export const deleteGlossaryCategorySchema = yup.object().shape({
  assign_terms_to: yup.string().required(REQUIRED_MESSAGE),
});
