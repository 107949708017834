import { useCallback, useMemo } from "react";

import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Dynamicform from "../../dynamicform";

import { AddCompareSchemaFormType } from "./addcompareschemaform.types";
import { addCompareTableFormConfig } from "./addcompareschemaform.config";

import { GetAddRuleFormContentProps } from "../../addruleform.types";

import {
  sortListOnSpecificKeyValue,
  sortObjectsArrayByKey,
} from "../../../../utils";

import { SourceSchemaOptionStyled } from "./addcompareschemaform.styles";
import { addCompareDbSchema } from "../../../../utils/schemas/ruleschemas/compareschema";

const AddCompareSchemaForm = (
  props: GetAddRuleFormContentProps
): JSX.Element => {
  const {
    formId,
    tableId,
    sourceId,
    ruleConfig,
    columnsData,
    ruleCategoryId,
    isEdit = false,
    compareSchemaTypes,
    parsedSrcSchemaList = [],
    onAddRule,
    onUpdateRule,
    propsOnCancel,
  } = props;

  const {
    src_id: srcId = "",
    src_schema: srcSchema = "",
    ref_src_id: refSrcId = "",
    ref_src_schema: refSrcSchema = "",
    tbl_in_src_not_in_tgt: tblInSrcNotInTgt = true,
    tbl_in_tgt_not_in_src: tblInTgtNotInSrc = true,
    col_in_src_not_in_tgt: colInSrcNotInTgt = true,
    col_in_tgt_not_in_src: colInTgtNotInSrc = true,
    col_data_type_check: colDataTypeCheckConfig,
  } = ruleConfig || {};

  const { is_enabled: isEnabled = true, type_of_check: typeOfCheck = "basic" } =
    colDataTypeCheckConfig || {};

  const sortedSrcSchemaList = sortListOnSpecificKeyValue({
    list: parsedSrcSchemaList,
    key: "parentName",
    type: "caseInsensitive",
  });

  const currentSourceSchemas = sortedSrcSchemaList?.filter(
    (source) => Number(source?.parentId) === sourceId
  );

  const schemasLength = currentSourceSchemas?.length || 0;

  const isOneSourceSchema = schemasLength === 1;

  const onlySchema = `${currentSourceSchemas?.[0]?.parentId}-${currentSourceSchemas?.[0]?.name}`;

  const srcSchemaConfig = ruleConfig
    ? `${srcId}-${srcSchema}`
    : isOneSourceSchema
    ? onlySchema
    : "";

  const targetSchemaConfig = ruleConfig ? `${refSrcId}-${refSrcSchema}` : "";

  const addCompareSchemaForm = useForm<AddCompareSchemaFormType>({
    defaultValues: {
      id: "compare_schema",
      rule_type: "Compare Schema",
      src_schema: srcSchemaConfig,
      target_schema: targetSchemaConfig,
      tbl_in_src_not_in_tgt: tblInSrcNotInTgt,
      tbl_in_tgt_not_in_src: tblInTgtNotInSrc,
      col_in_src_not_in_tgt: colInSrcNotInTgt,
      col_in_tgt_not_in_src: colInTgtNotInSrc,
      col_data_type_check: isEnabled,
      type_check: typeOfCheck,
    },
    resolver: yupResolver(addCompareDbSchema),
    mode: "onChange",
  });
  const { setValue, watch, getValues } = addCompareSchemaForm;

  const {
    src_schema: sourceSchema,
    target_schema: targetSchema,
    col_data_type_check: colDataTypeCheck,
  } = watch();

  const sourceSchemaSelect = useMemo(() => {
    const srcSchemaOptions =
      currentSourceSchemas?.map((item) => {
        const srcSchemaId = `${item?.parentId}-${item?.name}`;
        const srcSchemaName = `${item?.parentName}-${item?.name}`;

        return {
          textForSearch: srcSchemaName,
          value: srcSchemaId,
          labelText: srcSchemaName,
          label: (
            <SourceSchemaOptionStyled>
              {item?.parentName} <span className="svg">{">"} </span>{" "}
              {item?.name}
            </SourceSchemaOptionStyled>
          ),
        };
      }) || [];

    return srcSchemaOptions?.filter((item) => item?.value !== targetSchema);
  }, [currentSourceSchemas, targetSchema]);

  const targetSchemaSelect = useMemo(() => {
    const tgtSchemaOptions =
      sortedSrcSchemaList?.map((item) => {
        const srcSchemaId = `${item?.parentId}-${item?.name}`;
        const srcSchemaName = `${item?.parentName}-${item?.name}`;

        return {
          textForSearch: srcSchemaName,
          value: srcSchemaId,
          labelText: srcSchemaName,
          label: (
            <SourceSchemaOptionStyled>
              {item?.parentName} <span className="svg">{">"} </span>{" "}
              {item?.name}
            </SourceSchemaOptionStyled>
          ),
        };
      }) || [];

    return tgtSchemaOptions?.filter((item) => item?.value !== sourceSchema);
  }, [sortedSrcSchemaList, sourceSchema]);

  const addCompareTableFormSchema = addCompareTableFormConfig({
    sourceSchemaSelect,
    targetSchemaSelect,
    colDataTypeCheck,
    compareSchemaTypes,
    isSourceDisabled: isEdit,
    isEdit,
  });

  const onSubmit = useCallback(
    (values: AddCompareSchemaFormType) => {
      const desc = values?.rule_description;

      const target = values?.target_schema;
      const source = values?.src_schema;
      const splittedTrgValues = target?.split("-");
      const splittedSrcValues = source?.split("-");

      const sourceId = splittedSrcValues?.[0]
        ? Number(splittedSrcValues?.[0])
        : undefined;

      const isColDataTypeCheckEnabled = values?.col_data_type_check;

      const rule_config = {
        rule_type: formId,
        rule_desc: desc,
        src_schema: splittedSrcValues?.[1],
        src_id: sourceId,
        ref_src_id: splittedTrgValues?.[0]
          ? Number(splittedTrgValues?.[0])
          : undefined,
        ref_src_schema: splittedTrgValues?.[1],
        tbl_in_src_not_in_tgt: values?.tbl_in_src_not_in_tgt,
        tbl_in_tgt_not_in_src: values?.tbl_in_tgt_not_in_src,
        col_in_src_not_in_tgt: values?.col_in_src_not_in_tgt,
        col_in_tgt_not_in_src: values?.col_in_tgt_not_in_src,
        col_data_type_check: {
          is_enabled: isColDataTypeCheckEnabled,
          type_of_check: isColDataTypeCheckEnabled
            ? values?.type_check
            : undefined,
        },
      };

      const addNewRulePayload = {
        rule_cat: ruleCategoryId,
        rule_type: formId,
        rule_desc: desc,
        src_id: sourceId,
        tbl_id: Number(tableId),
        rule_priority: "NRM",
        rule_def_config: rule_config,
      };

      isEdit ? onUpdateRule?.(rule_config) : onAddRule(addNewRulePayload);
    },
    [tableId, sourceId, columnsData]
  );

  return (
    <FormProvider {...addCompareSchemaForm}>
      <Dynamicform
        schema={addCompareTableFormSchema}
        onSubmit={onSubmit}
        columnsData={columnsData}
        propsOnCancel={propsOnCancel}
        isEdit={isEdit}
      />
    </FormProvider>
  );
};

export default AddCompareSchemaForm;
