import styled from "styled-components";
import { SectionHeaderProps } from "./sectionheader.types";
import { RulePriorityIdType } from "../../parsers/ruleparser/ruleparser.types";

export const SectionHeaderStyled = styled.div<SectionHeaderProps>`
  display: flex;
  align-items: center;
  margin-bottom: ${({ marginBottom }): any => marginBottom};
  position: relative;

  .letter-icon-mapping {
    font-size: 12px;
  }

  .info {
    margin: 0px 13px 0px 10px;
    .info-title {
      color: ${({ theme }): string => theme.textColors.BLUE_116};
      font-size: 19px;
      font-weight: 600;
      font-family: OpenSansSemiBold;
      cursor: ${({ isClickable }): any => !isClickable && "default"};
      line-height: 1;
      word-break: break-all;
    }

    .info-subtitle {
      color: ${({ theme }): string => theme.textColors.DARK_GREY};
      font-size: 12px;
      opacity: 0.8;
      line-height: 1;
    }
  }
`;

export const RulePriorityIconDivStyled = styled.div<{
  priority?: RulePriorityIdType;
}>`
  position: absolute;
  width: 12px;
  height: 12px;
  border: 2px solid ${({ theme }): string => theme.borderColors.WHITE_COLOR};
  background-color: ${({ theme, priority }): string =>
    priority === "HIG" ? theme.bgColors.RED_16 : theme.bgColors.ORANGE_10};
  border-radius: 50%;
  top: 18px;
  left: 18px;
`;
