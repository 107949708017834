import styled from "styled-components";
import { ellipsis } from "../../styles/app.styles";

export const SearchWithDropdownContainer = styled.div`
  display: flex;

  .autocomplete-wrapper {
    position: relative;

    .search-icon {
      position: absolute;
      left: 0;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2.5em;
      height: 100%;
    }
    .ant-select-auto-complete {
      .ant-select-selector {
        padding: 0px;
        border-radius: 4px;
        .ant-select-selection-search {
          padding: 0px;
          left: 0;

          .ant-select-selection-search-input {
            padding-left: 2.5em;
            width: 616px;
          }
        }

        .ant-select-selection-placeholder {
          padding-left: 2.5em;
        }
      }

      .ant-select-clear {
        right: 65px;
        opacity: unset;
      }
    }

    .suffix-info {
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: flex-end;
      z-index: 1;
      gap: 0.5em;
    }

    .go-btn {
      border-radius: 0 4px 4px 0;
      background-color: #222a2d;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 55px;

      span {
        color: white;
      }
    }
  }

  .ant-select-auto-complete {
    height: 34px;

    .ant-select-selector {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .ant-select-selection-search,
      .ant-select-selection-placeholder,
      .ant-select-selection-item {
        //padding-left: 2em;
      }

      .ant-select-selection-item {
        color: #000;
      }

      .ant-select-selection-search {
        height: 100%;

        .ant-select-selection-search-input {
          height: 100%;
          border-radius: 4px;

          input {
            color: #969696;
            width: 85%;
          }
        }
      }
    }
  }

  .certain-category-search-dropdown .ant-select-item-option,
  .ant-select-item-option-grouped {
    padding-left: 15px;
  }

  .ant-select-item-group:not(:first-of-type) {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .ant-select-item-group span.dropdown-wrapper {
    margin-top: 1em;
  }

  .ant-select-item-group:not(:first-of-type)::before {
    position: absolute;
    left: 0;
    content: "";
    display: block;
    background: #707070;
    width: 100%;
    height: 1px;
    margin: 5px 0;
    opacity: 0.2;
  }
`;

export const DropdownCategoryWrapper = styled.span`
  font-family: OpenSansSemiBold;
  color: #000;
  font-size: 14px;
  .ant-select-item-group {
    font-size: 14px;
    color: #000;
  }

  .ant-select-item-group {
    :not(:first-child) {
      :before {
        content: "";
        display: block;
        background: #707070;
        width: 100%;
        height: 1px;
        margin: 5px 0;
      }
    }
  }
`;

export const HeaderDropdownItemStyled = styled.span`
  color: #2c2c2c;
  display: flex;
  align-items: center;

  .search-node-icon {
    display: flex;
    margin-right: 0.875em;
  }

  .search-node-info {
    ${ellipsis}
    display: flex;
    align-items: center;
    > div {
      ${ellipsis}
    }
  }

  .dropdown-item-subtext {
    color: #828282;
  }

  .search-text {
    font-family: OpenSansSemiBold;
  }
`;
