import styled from "styled-components";
import LinkButton from "../linkbutton/linkbutton";

export const SequenceStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  margin-bottom: 50px;

  .listing-container {
    padding: 20px;
    border-radius: 8px;
    min-width: 450px;
    min-height: 500px;
    max-height: 500px;
    overflow-y: auto;

    border: 1px solid ${({ theme }): string => theme.borderColors.GREY_56};
    background: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
  }

  .up-down-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    column-gap: 10px;
    row-gap: 10px;
  }
`;

export const ColumnItemStyled = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background: ${({ theme }): string => theme.bgColors.GREY_23};
  }

  background: ${({ theme, isSelected }): string =>
    isSelected ? theme.bgColors.GREY_23 : ""};

  .column-title {
    color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
  }

  svg {
    path {
      fill: ${({ theme }): string => theme.textColors.GREY_210};
    }
  }
`;

export const UpDownButtonStyled = styled(LinkButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 5px;
  background: ${({ theme }): string => theme.bgColors.WHITE_COLOR};

  &,
  &:focus,
  &:hover {
    border: 1px solid ${({ theme }): string => theme.borderColors.GREY_56};
  }

  svg {
    width: 12px;
    height: 12px;

    path {
      fill: ${({ theme }): string => theme.textColors.GREY_22};
    }
  }
`;
