import { useState } from "react";
import { Input } from "../../../../../../../../components/inputs";
import LinkButton from "../../../../../../../../components/linkbutton";
import { addInfoIcon } from "../../../../../../../../svgs";
import { AddCommentSecStyled } from "./addcommentsec.styles";
import { AddCommentSecProps } from "./addcommentsec.types";

const AddCommentSec = (props: AddCommentSecProps): JSX.Element => {
  const { id = "", placeholder = "", tag = "", onAddClick } = props;

  const [commentText, setCommentText] = useState(tag ? `@${tag} ` : "");

  return (
    <AddCommentSecStyled>
      <Input
        height="41.6px"
        value={commentText}
        placeholder={placeholder || "Add a comment"}
        onChange={(e): void => setCommentText(e.target.value)}
      />
      <LinkButton
        className="add-comment-icon"
        onClick={(): void => onAddClick(commentText, id)}
      >
        {addInfoIcon("40.8px", "40.8px")}
      </LinkButton>
    </AddCommentSecStyled>
  );
};

export default AddCommentSec;
