export const THEME_COLORS = {
  light: {
    textColors: {
      ICE_BLUE: "#c3e0fa",
      BLUE_181: "#7CADD6",
      ORANGE_505: "#f85900",
      RED_202: "#e5bab3",
      GREEEN_202: "#cde8cc",
      YELLOW_ORANGISH: "#ff6915",
      YELLOW_223: "#F1C911",
      BLUE_150: "#a3c6e5",
      YELLOW_116: "#fdf9e2",
      GREY_COLOR: "#333",
      LIGHT_YELLOW_COLOR: "#fee188",
      WHITE_COLOR: "#fff",
      BLUE_100: "#2e6588",
      BLUE_200: "#2c5976",
      PURPLE_100: "#892e65",
      PURPLE_200: "#762c59",
      RED_100: "#d15b47",
      BLUE_50: "#62a8d1",
      BLUE_75: "#579ec8",
      BLUE_30: " #85c0ec",
      GREY_50: "#e5e5e5",
      GREY_40: "#222a2d",
      GREY_30: "#414b51",
      GREY_150: "#141a1b",
      GREY_175: "#3f4e54",
      GREY_80: "#b1bac1",
      BLUE_20: "#e1eaf1",
      GREY_20: "#5a6364",
      LIGHT_GREY_101: "#bdbdbd",
      LIGHT_GREY_20: "#afbdc9",
      LIGHT_GREY_30: "#d1dae1",
      LIGHT_BLUE_20: "#85c0ec",
      GREY_45: "#aaa",
      GREY_55: "#bbb",
      GREY_60: "#929ba3",
      GRYEY_65: "#3a4344",
      GREY_65: "#606060",
      BLUE_25: "#2679b5",
      GRAY_70: "#aaaaaa",
      GREY_75: "#ddd",
      BLACK_50: "#444",
      BLACK_60: "#999",
      GREY_90: "#393939",
      LIGHT_GREY_24: "#777777",
      LIGHT_GREY_25: "#ccc",
      DARK_GREY_100: "#858585",
      DARK_GREY_105: "#696969",
      DARK_GREY_120: "#8a8c8c",
      BLUE_105: "#478fca",
      BLUE_110: "#4d6883",
      BLUE_115: "#0A73FA",
      BLUE_116: "#428bca",
      GRAY: "gray",
      BLUE_2B: "#2b7dbc",
      LIGHT_GREY_5: "#bdbec4",
      DARK_BLUE: "#328db5",
      DARK_BLUE_1: "#3b7296",
      DARK_BLUE_20: "#2e6589",
      DARK_GREY: "#4d4d4d",
      DARK_GREY_10: "#4c4c4c",
      BLACK_COLOR: "#000000",
      LIGHT_GREY_15: "#d6d5d5",
      LIGHT_GREY_45: "#969696",
      GREY_25: "#515151",
      GREY_35: "#e1e1e1",
      GREEN_COLOR: "#72aa69",
      GREEN_1: "#59945a",
      YELLOW_COLOR: "#f1c911",
      YELLOW_COLOR_1: "#fefae8",
      LIGHT_GREY_50: "#959596",
      GREY_70: "#a5a5a5",
      GREY_110: "#dfdfdf",
      GREY_95: "#f7f7f7",
      LIGHT_BLUE_50: "#4698bc",
      BLUE_117: "#2c78c0",
      BLUE_26: "#2c6aa0",
      LIGHT_BLUE_22: "#fbfbfb",
      BLACK_10: "#383838",
      BLACK_15: "#343434",
      GREEN_35: "#468847",
      GREEN_40: "#dff0d8",
      BLACK_20: "#272727",
      BLUE_11: "#f8f8f8",
      GREY_56: "#e8e8e8",
      LIGHT_GREY_35: "#eee",
      BLUE_51: "#488bc3",
      GREEN_10: "#87b87f",
      GREY_11: "#c2c2c2",
      BLUE_21: "#4595df",
      LIGHT_BLUE_10: " #f5f7f8",
      BLACK_11: "#303030",
      GREY_12: "#929292",
      LIGHT_GREY_11: "#707070",
      LIGHT_GREY_12: "#737373",
      LIGHT_BLUE_13: "#5398d3",
      GREY_13: "#585858",
      BLUE_14: "#4c9bcf",
      GREY_34: "#b0b0b0",
      GREY_36: "#bcbdc3",
      BLUE_56: "#4b90cc",
      LIGHT_GREY_16: "#a8a8a8",
      BLACK_12: "#3b3b3b",
      YELLOW_RD: "#f59942",
      YELLOW_10: "#ffb049",
      YELLOW_11: "#f9f5e7",
      YELLOW_12: "#fefae7",
      YELLOW_13: "#53430a",
      BLACK_13: "#2c2c2c",
      BLUE_23: "#eff4f7",
      BLACK_14: "#111111",
      GREY_14: "#d8d8d8",
      GREY_18: "#c9c9c9",
      RED_11: "#ff1111",
      BLACK_16: "#1d1d1d",
      LIGHT_GREY_6: "#cfcfcf",
      GREY_10: "#dde0e2",
      LIGHT_GREY_7: "#f2f2f2",
      GREY_17: "#cbcbcb",
      GREY_21: "#dde0e2",
      GREY_22: "#434954",
      GREY_23: "#d4d4d4",
      GREY_24: "#4f4f4f",
      BLACK_17: "#939192",
      GREY_26: "#757575",
      RED_12: "#d93f3c",
      RED_13: "#f7ebeb",
      LIGHT_BLUE_6: "#6B83B0",
      BLUE_10: "#f0f0f0",
      DARK_GREY_1: "#808080",
      LIGHT_GREY_13: "#dddddd",
      GREY_16: "#c9d4db",
      GREY_19: "#f3f3f3",
      BLACK_22: " #161616",
      GREY_27: "#dcdcdc",
      GREY_28: "#656565",
      LIGHT_GREY_17: " #fcfcfc",
      GREY_29: "#dadada",
      GREY_31: "#5c5c5c",
      BLUE_27: "#64a2cb",
      BLACK_25: "#636363",
      BLUE_118: "#4f92be",
      RED: "red",
      BLUE_31: "#2283c5",
      LIGHT_RED_1: "#f7e7e7",
      DARK_RED_1: "#d33532",
      BLUE_36: "#ebf3fa",
      LIGHT_GREY_22: "#f6f6f6",
      GREEN_45: "#59a84b",
      RED_18: "#d33532",
      DARK_GREY_20: "#8b9aa3",
      BLUE_41: "#e2ebf4",
      BLUE_42: "#e8f0f8",
      DARK_GREY_110: "#5e5959",
      CYAN_BLUE_1: "#d9e6f1",
      DARK_GREY_115: "#2f2f2f",
      GREY_COLOR_12: "#d2d2d2",
      LIGHT_GREY_27: "#eaeaea",
      BLUE_119: "#e3f2f9",
      BLUE_22: "#b3cfdb",
      GREY_101: "#bfbfbf",
      ORANGE_1: "#efad62",
      ORANGE_2: "#faf2e9",
      ORANGE_3: "#F85900",
      LIGHT_GREY_40: "#d5d5d5",
      DARK_GREY_101: "#b2a89e",
      LIGHT_BLUE_15: "#d9edf7",
      BLUE_46: "#3a87ad",
      LIGHT_BLUE_101: "#c7ddec",
      LIGHT_BLUE_102: "#f9fcff",
      LIGHT_BLUE_103: "#c7ddef",
      LIGHT_BLUE_104: "#f8fcff",
      LIGHT_BLUE_105: "#bce8f1",
      GREY_111: "#9a9a9a",
      GREY_41: "#b4b4b4",
      LIGHT_GREY_55: "#c4cfde",
      DARK_GREY_106: "#535353",
      LIGHT_GREY_32: "#c7ddec",
      RED_20: "#de4b3c",
      ORANGE_5: "#ff9300",
      LIGHT_GREY_36: "#f1f5f9",
      GREY_201: "#AFAFAF",
      DARK_GREY_201: "#555",
      LIGHT_BLUE_106: "#3fc3cc",
      LIGHT_ORANGE_1: "#ffa87f",
      LIGHT_ORANGE_2: "#c99132",
      ORANGE_6: "#ff5200",
      GREY_205: "#818181",
      GREY_210: "#949494",
      LIGHT_BROWN_1: "#fdf2e4",
      BROWN_1: "#f29423",
      BLUE_121: "#2c6aa0",
      BLUE_122: "#3b85c6",
      BLUE_123: "#3c9af0",
      BLUE_124: "#0e487b",
      BLUE_126: "#28679c;",
      RED_16: "#d3212c",
      ORANGE_10: "#ff980e",
      GREEN_111: "#069c56",
      RED_19: "#dd5a43",
      GREEN_11: "#73aa6a",
      LIGHT_GREY_41: "#ececec",
      LIGHT_BLUE_112: "#b8bbc3",
      LIGHT_GREY_46: "#f1f1f1",
      GREY_206: "#717171",
      GREY_211: "#8e8c92",
      GREEN_12: "#e2f7df",
      GREEN_13: "#c4edbe",
      RED_101: "#fbecea",
      RED_102: "#f4dcdc",
      BLUE_111: "#eff5fb",
      BLUE_112: "#e4f2ff",
      BLUE_113: "#c2e7f2",
      GREY_32: "#727373",
      GREY_51: "#98a3ac",
      BLACK_70: "#3d3d3d",
      GREY_33: "#999999",
      LIGHT_GREY_51: "#e6e6e6",
      CYAN_BLUE_6: "#4894ca",
      LIGHT_GREY_56: "#b7b7b7",
      LIGHT_GREY_61: "#cfcece",
      LIGHT_GREY_10: "#d9d9d9",
      LIGHT_BLUE_25: "#eff3f8",
      LIGHT_BLUE_27: "#d8e6f3",
      LIGHT_BLUE_28: "#2f97c1b3",
    },
    bgColors: {
      ICE_BLUE: "#c3e0fa",
      BLUE_181: "#7CADD6",
      ORANGE_505: "#f85900",
      RED_202: "#e5bab3",
      GREEEN_202: "#cde8cc",
      YELLOW_ORANGISH: "#ff6915",
      DARK_GREY_120: "#8a8c8c",
      YELLOW_223: "#F1C911",
      BLUE_150: "#a3c6e5",
      YELLOW_116: "#fdf9e2",
      LIGHT_BLUE_125: "8cc8ff",
      PRIMARY_BG_COLOR: "#2c6aa0",
      GREY_COLOR: "#333",
      LIGHT_YELLOW_COLOR: "#fee188",
      WHITE_COLOR: "#fff",
      BLUE_100: "#2e6588",
      BLUE_200: "#2c5976",
      PURPLE_100: "#892e65",
      PURPLE_200: "#762c59",
      RED_100: "#d15b47",
      BLUE_50: "#62a8d1",
      BLUE_75: "#579ec8",
      BLUE_30: " #85c0ec",
      GREY_50: "#e5e5e5",
      GREY_40: "#222a2d",
      GREY_30: "#414b51",
      GREY_150: "#141a1b",
      GREY_175: "#3f4e54",
      GREY_80: "#b1bac1",
      BLUE_20: "#e1eaf1",
      GREY_20: "#5a6364",
      LIGHT_GREY_20: "#afbdc9",
      LIGHT_GREY_30: "#d1dae1",
      LIGHT_BLUE_20: "#85c0ec",
      GREY_45: "#aaa",
      GREY_55: "#bbb",
      GREY_60: "#929ba3",
      GRYEY_65: "#3a4344",
      GREY_65: "#606060",
      BLUE_25: "#2679b5",
      GREY_75: "#ddd",
      DISABLED_BLUE_BACKGROUND: "#428bca",
      GREY_120: "#888888",
      BLUE_110: "#3875d7",
      GREY_115: "#f4f4f4",
      GREY_15: "#ebebeb",
      GREY_125: "#888",
      DARK_BLUE_10: "#1b6aaa",
      BLUE_10: "#f0f0f0",
      BLUE_115: "#0A73FA",
      BLUE_116: "#428bca",
      BLUE_120: "#005CC8",
      LIGHT_BLUE_25: "#eff3f8",
      LIGHT_BLUE_26: "#e3effa",
      DARK_GREY_20: "#8b9aa3",
      BLUE_15: "#6fb3e0",
      DARK_BLUE_15: "#4f99c6",
      GREY_BLUE: "#f9f9f9",
      LIGHT_BLUE: "#f5f5f5",
      LIGHT_BLUE_1: "#e8f1f9",
      LIGHT_GREY_35: "#eee",
      LIGHT_BLUE_5: "#fafafa",
      LIGHT_BLUE_30: "#f5f8fc",
      BLUE_55: "#32a3ce",
      BLUE_105: "#478fca",
      BlUE_BACKGROUND: "#438eb9",
      HONEY_BACKGROUND: "#D8F6CE",
      BLUE_125: "#A3CCFC",
      LIGHT_GREY: "#c5c5c5",
      LIGHT_GREY_15: "#d6d5d5",
      LIGHT_GREY_45: "#969696",
      GREY_25: "#515151",
      GREY_35: "#e1e1e1",
      LIGHT_BLUE_24: "#eff5f8",
      DARK_BLUE: "#328db5",
      DARK_BLUE_1: "#3b7296",
      DARK_BLUE_20: "#2e6589",
      GREEN_COLOR: "#72aa69",
      GREEN_1: "#59945a",
      YELLOW_COLOR: "#f1c911",
      YELLOW_COLOR_1: "#fefae8",
      LIGHT_GREY_50: "#959596",
      GREY_70: "#a5a5a5",
      GREY_110: "#dfdfdf",
      GREY_95: "#f7f7f7",
      LIGHT_BLUE_50: "#4698bc",
      BLUE_117: "#2c78c0",
      BLUE_26: "#2c6aa0",
      LIGHT_BLUE_22: "#fbfbfb",
      BLACK_10: "#383838",
      BLACK_15: "#343434",
      GREEN_35: "#468847",
      GREEN_40: "#dff0d8",
      BLACK_20: "#272727",
      BLUE_11: "#f8f8f8",
      GREY_56: "#e8e8e8",
      BLUE_51: "#488bc3",
      GREEN_10: "#87b87f",
      GREY_11: "#c2c2c2",
      BLUE_21: "#4595df",
      LIGHT_BLUE_10: " #f5f7f8",
      BLACK_11: "#303030",
      GREY_12: "#929292",
      LIGHT_GREY_11: "#707070",
      LIGHT_GREY_12: "#737373",
      LIGHT_BLUE_13: "#5398d3",
      GREY_13: "#585858",
      BLUE_14: "#4c9bcf",
      GREY_34: "#b0b0b0",
      GREY_36: "#bcbdc3",
      BLUE_56: "#4b90cc",
      LIGHT_GREY_16: "#a8a8a8",
      BLACK_12: "#3b3b3b",
      YELLOW_10: "#ffb049",
      YELLOW_11: "#f9f5e7",
      YELLOW_12: "#fefae7",
      YELLOW_13: "#53430a",
      BLACK_13: "#2c2c2c",
      BLUE_23: "#eff4f7",
      BLACK_14: "#111111",
      GREY_14: "#d8d8d8",
      GREY_18: "#c9c9c9",
      RED_11: "#ff1111",
      BLACK_16: "#1d1d1d",
      LIGHT_GREY_6: "#cfcfcf",
      GREY_10: "#dde0e2",
      LIGHT_GREY_7: "#f2f2f2",
      GREY_17: "#cbcbcb",
      GREY_21: "#dde0e2",
      GREY_22: "#434954",
      GREY_23: "#d4d4d4",
      GREY_24: "#4f4f4f",
      BLACK_17: "#939192",
      GREY_26: "#757575",
      RED_12: "#d93f3c",
      RED_13: "#f7ebeb",
      LIGHT_BLUE_6: "#6B83B0",
      DARK_GREY_1: "#808080",
      LIGHT_GREY_13: "#dddddd",
      GREY_16: "#c9d4db",
      GREY_19: "#f3f3f3",
      BLACK_22: " #161616",
      GREY_27: "#dcdcdc",
      GREY_28: "#656565",
      LIGHT_GREY_17: " #fcfcfc",
      GREY_29: "#dadada",
      GREY_31: "#5c5c5c",
      BLUE_27: "#64a2cb",
      DARK_GREY_10: "#4c4c4c",
      BLACK_25: "#636363",
      BLUE_118: "#4f92be",
      RED: "red",
      BLUE_31: "#2283c5",
      LIGHT_RED_1: "#f7e7e7",
      DARK_RED_1: "#d33532",
      LIGHT_GREY_5: "#bdbec4",
      BLUE_36: "#ebf3fa",
      LIGHT_GREY_22: "#f6f6f6",
      GREEN_45: "#59a84b",
      RED_18: "#d33532",
      BLUE_41: "#e2ebf4",
      BLUE_42: "#e8f0f8",
      DARK_GREY_110: "#5e5959",
      CYAN_BLUE_1: "#d9e6f1",
      DARK_GREY_115: "#2f2f2f",
      GREY_COLOR_12: "#d2d2d2",
      LIGHT_GREY_27: "#eaeaea",
      BLUE_119: "#e3f2f9",
      BLUE_22: "#b3cfdb",
      GREY_101: "#bfbfbf",
      ORANGE_1: "#efad62",
      ORANGE_2: "#faf2e9",
      ORANGE_3: "#F85900",
      DARK_GREY_101: "#b2a89e",
      LIGHT_BLUE_15: "#d9edf7",
      BLUE_46: "#3a87ad",
      LIGHT_BLUE_101: "#c7ddec",
      LIGHT_BLUE_102: "#f9fcff",
      LIGHT_BLUE_103: "#c7ddef",
      LIGHT_BLUE_104: "#f8fcff",
      LIGHT_BLUE_105: "#bce8f1",
      GREY_111: "#9a9a9a",
      GREY_41: "#b4b4b4",
      LIGHT_GREY_55: "#c4cfde",
      DARK_GREY_106: "#535353",
      LIGHT_GREY_32: "#c7ddec",
      RED_20: "#de4b3c",
      ORANGE_5: "#ff9300",
      LIGHT_GREY_36: "#f1f5f9",
      GREY_201: "#AFAFAF",
      DARK_GREY_201: "#555",
      LIGHT_BLUE_106: "#3fc3cc",
      LIGHT_ORANGE_1: "#ffa87f",
      LIGHT_ORANGE_2: "#c99132",
      ORANGE_6: "#ff5200",
      GREY_205: "#818181",
      GREY_210: "#949494",
      LIGHT_BROWN_1: "#fdf2e4",
      BROWN_1: "#f29423",
      BLUE_121: "#2c6aa0",
      BLUE_122: "#3b85c6",
      BLUE_123: "#3c9af0",
      BLUE_124: "#0e487b",
      BLUE_126: "#28679c;",
      RED_16: "#d3212c",
      ORANGE_10: "#ff980e",
      GREEN_111: "#069c56",
      RED_19: "#dd5a43",
      GREEN_11: "#73aa6a",
      LIGHT_GREY_41: "#ececec",
      LIGHT_BLUE_112: "#b8bbc3",
      LIGHT_GREY_46: "#f1f1f1",
      GREY_206: "#717171",
      GREY_211: "#8e8c92",
      BLACK_COLOR: "#000000",
      GREEN_12: "#e2f7df",
      GREEN_13: "#c4edbe",
      RED_101: "#fbecea",
      RED_102: "#f4dcdc",
      BLUE_111: "#eff5fb",
      BLUE_112: "#e4f2ff",
      BLUE_113: "#c2e7f2",
      GREY_32: "#727373",
      GREY_51: "#98a3ac",
      BLACK_70: "#3d3d3d",
      GREY_33: "#999999",
      LIGHT_GREY_51: "#e6e6e6",
      CYAN_BLUE_6: "#4894ca",
      LIGHT_GREY_56: "#b7b7b7",
      LIGHT_GREY_61: "#cfcece",
      LIGHT_GREY_10: "#d9d9d9",
      LIGHT_BLUE_27: "#d8e6f3",
      LIGHT_BLUE_28: "#2f97c1b3",
    },
    borderColors: {
      ICE_BLUE: "#c3e0fa",
      BLUE_181: "#7CADD6",
      ORANGE_505: "#f85900",
      RED_202: "#e5bab3",
      GREEEN_202: "#cde8cc",
      YELLOW_ORANGISH: "#ff6915",
      LIGHT_BLUE_25: "#eff3f8",
      DARK_GREY_120: "#8a8c8c",
      YELLOW_223: "#F1C911",
      BLUE_150: "#a3c6e5",
      YELLOW_116: "#fdf9e2",
      GREY_COLOR: "#333",
      LIGHT_YELLOW_COLOR: "#fee188",
      WHITE_COLOR: "#fff",
      BLUE_100: "#2e6588",
      BLUE_200: "#2c5976",
      PURPLE_100: "#892e65",
      PURPLE_200: "#762c59",
      RED_100: "#d15b47",
      BLUE_50: "#62a8d1",
      BLUE_75: "#579ec8",
      BLUE_30: " #85c0ec",
      GREY_50: "#e5e5e5",
      GREY_40: "#222a2d",
      GREY_30: "#414b51",
      GREY_150: "#141a1b",
      GREY_175: "#3f4e54",
      GREY_80: "#b1bac1",
      BLUE_20: "#e1eaf1",
      GREY_20: "#5a6364",
      LIGHT_GREY_20: "#afbdc9",
      LIGHT_GREY_30: "#d1dae1",
      LIGHT_BLUE_20: "#85c0ec",
      GREY_15: "#ebebeb",
      GREY_45: "#aaa",
      GREY_55: "#bbb",
      GREY_60: "#929ba3",
      GRYEY_65: "#3a4344",
      GREY_65: "#606060",
      BLUE_25: "#2679b5",
      GREY_75: "#ddd",
      YELLOW_RD: "#f59942",
      GRAY_70: "#aaaaaa",
      DISABLED_BLUE_BORDER_COLOR: "#428bca",
      DARK_GREY_15: "#b5b5b5",
      DARK_GREY_100: "#858585",
      LIGHT_BLUE_45: "#e4e9ee",
      LIGHT_GREY_10: "#d9d9d9",
      LIGHT_BLUE_35: "#d3e6f3",
      DARK_GREY_20: "#8b9aa3",
      DARK_GREY_25: "#abbac3",
      BLUE_15: "#6fb3e0",
      BLUE_35: "#709fc0",
      LIGHT_GREY_25: "#ccc",
      LIGHT_GREY_40: "#d5d5d5",
      GREY_100: "#c8c8c8",
      BLUE_55: "#32a3ce",
      BLUE_115: "#0A73FA",
      BLUE_116: "#428bca",
      LIGHT_BLUE_40: "#d5e3ef",
      BLUE_105: "#478fca",
      BLACK_21: "#212121",
      GREY_COLOR_12: "#d2d2d2",
      BLUE_125: "#A3CCFC",
      LIGHT_GREY_15: "#d6d5d5",
      LIGHT_GREY_45: "#969696",
      GREY_25: "#515151",
      GREY_35: "#e1e1e1",
      DARK_BLUE: "#328db5",
      DARK_BLUE_1: "#3b7296",
      DARK_BLUE_20: "#2e6589",
      GREEN_COLOR: "#72aa69",
      GREEN_1: "#59945a",
      YELLOW_COLOR: "#f1c911",
      YELLOW_COLOR_1: "#fefae8",
      LIGHT_GREY_50: "#959596",
      GREY_70: "#a5a5a5",
      GREY_110: "#dfdfdf",
      GREY_95: "#f7f7f7",
      LIGHT_BLUE_50: "#4698bc",
      BLUE_117: "#2c78c0",
      BLUE_26: "#2c6aa0",
      LIGHT_BLUE_22: "#fbfbfb",
      BLACK_10: "#383838",
      BLACK_15: "#343434",
      GREEN_35: "#468847",
      GREEN_40: "#dff0d8",
      BLACK_20: "#272727",
      BLUE_11: "#f8f8f8",
      GREY_56: "#e8e8e8",
      LIGHT_GREY_35: "#eee",
      BLUE_51: "#488bc3",
      GREEN_10: "#87b87f",
      GREY_11: "#c2c2c2",
      BLUE_21: "#4595df",
      LIGHT_BLUE_10: " #f5f7f8",
      BLACK_11: "#303030",
      GREY_12: "#929292",
      LIGHT_GREY_11: "#707070",
      LIGHT_GREY_12: "#737373",
      LIGHT_BLUE_13: "#5398d3",
      GREY_13: "#585858",
      BLUE_14: "#4c9bcf",
      GREY_34: "#b0b0b0",
      GREY_36: "#bcbdc3",
      BLUE_56: "#4b90cc",
      LIGHT_GREY_16: "#a8a8a8",
      BLACK_12: "#3b3b3b",
      YELLOW_10: "#ffb049",
      YELLOW_11: "#f9f5e7",
      YELLOW_12: "#fefae7",
      YELLOW_13: "#53430a",
      BLACK_13: "#2c2c2c",
      BLUE_23: "#eff4f7",
      BLACK_14: "#111111",
      GREY_14: "#d8d8d8",
      GREY_18: "#c9c9c9",
      RED_11: "#ff1111",
      BLACK_16: "#1d1d1d",
      LIGHT_GREY_6: "#cfcfcf",
      GREY_10: "#dde0e2",
      LIGHT_GREY_7: "#f2f2f2",
      GREY_17: "#cbcbcb",
      GREY_21: "#dde0e2",
      GREY_22: "#434954",
      GREY_23: "#d4d4d4",
      GREY_24: "#4f4f4f",
      BLACK_17: "#939192",
      GREY_26: "#757575",
      RED_12: "#d93f3c",
      RED_13: "#f7ebeb",
      LIGHT_BLUE_6: "#6B83B0",
      DARK_GREY_1: "#808080",
      LIGHT_GREY_13: "#dddddd",
      BLUE_10: "#f0f0f0",
      GREY_16: "#c9d4db",
      GREY_19: "#f3f3f3",
      BLACK_22: " #161616",
      GREY_27: "#dcdcdc",
      GREY_28: "#656565",
      LIGHT_GREY_17: " #fcfcfc",
      GREY_29: "#dadada",
      GREY_31: "#5c5c5c",
      BLUE_27: "#64a2cb",
      DARK_GREY_10: "#4c4c4c",
      BLACK_25: "#636363",
      BLUE_118: "#4f92be",
      RED: "red",
      BLUE_31: "#2283c5",
      LIGHT_RED_1: "#f7e7e7",
      DARK_RED_1: "#d33532",
      LIGHT_GREY_5: "#bdbec4",
      BLUE_36: "#ebf3fa",
      LIGHT_GREY_22: "#f6f6f6",
      GREEN_45: "#59a84b",
      RED_18: "#d33532",
      BLUE_41: "#e2ebf4",
      BLUE_42: "#e8f0f8",
      DARK_GREY_110: "#5e5959",
      CYAN_BLUE_1: "#d9e6f1",
      DARK_GREY_115: "#2f2f2f",
      LIGHT_GREY_27: "#eaeaea",
      BLUE_119: "#e3f2f9",
      BLUE_22: "#b3cfdb",
      GREY_101: "#bfbfbf",
      ORANGE_1: "#efad62",
      ORANGE_2: "#faf2e9",
      ORANGE_3: "#F85900",
      DARK_GREY_101: "#b2a89e",
      LIGHT_BLUE_15: "#d9edf7",
      BLUE_46: "#3a87ad",
      LIGHT_BLUE_101: "#c7ddec",
      LIGHT_BLUE_102: "#f9fcff",
      LIGHT_BLUE_103: "#c7ddef",
      LIGHT_BLUE_104: "#f8fcff",
      LIGHT_BLUE_105: "#bce8f1",
      GREY_111: "#9a9a9a",
      GREY_41: "#b4b4b4",
      LIGHT_GREY_55: "#c4cfde",
      DARK_GREY_106: "#535353",
      LIGHT_GREY_32: "#c7ddec",
      RED_20: "#de4b3c",
      ORANGE_5: "#ff9300",
      LIGHT_GREY_36: "#f1f5f9",
      GREY_201: "#AFAFAF",
      DARK_GREY_201: "#555",
      LIGHT_BLUE_106: "#3fc3cc",
      LIGHT_ORANGE_1: "#ffa87f",
      LIGHT_ORANGE_2: "#c99132",
      ORANGE_6: "#ff5200",
      GREY_205: "#818181",
      GREY_210: "#949494",
      LIGHT_BROWN_1: "#fdf2e4",
      BROWN_1: "#f29423",
      BLUE_121: "#2c6aa0",
      BLUE_122: "#3b85c6",
      BLUE_123: "#3c9af0",
      BLUE_124: "#0e487b",
      BLUE_126: "#28679c;",
      RED_16: "#d3212c",
      ORANGE_10: "#ff980e",
      GREEN_111: "#069c56",
      RED_19: "#dd5a43",
      GREEN_11: "#73aa6a",
      LIGHT_GREY_41: "#ececec",
      LIGHT_BLUE_112: "#b8bbc3",
      LIGHT_GREY_46: "#f1f1f1",
      GREY_206: "#717171",
      GREY_211: "#8e8c92",
      BLACK_COLOR: "#000000",
      GREEN_12: "#e2f7df",
      GREEN_13: "#c4edbe",
      RED_101: "#fbecea",
      RED_102: "#f4dcdc",
      BLUE_111: "#eff5fb",
      BLUE_112: "#e4f2ff",
      BLUE_113: "#c2e7f2",
      GREY_32: "#727373",
      GREY_51: "#98a3ac",
      BLACK_70: "#3d3d3d",
      GREY_33: "#999999",
      LIGHT_GREY_51: "#e6e6e6",
      CYAN_BLUE_6: "#4894ca",
      LIGHT_GREY_56: "#b7b7b7",
      LIGHT_GREY_61: "#cfcece",
      LIGHT_BLUE_27: "#d8e6f3",
      LIGHT_BLUE_28: "#2f97c1b3",
    },
  },
  dark: {
    textColors: {
      ICE_BLUE: "#c3e0fa",
      BLUE_181: "#7CADD6",
      ORANGE_505: "#f85900",
      RED_202: "#e5bab3",
      GREEEN_202: "#cde8cc",
      YELLOW_ORANGISH: "#ff6915",
      DARK_GREY_120: "#8a8c8c",
      YELLOW_223: "#F1C911",
      BLUE_150: "#a3c6e5",
      YELLOW_116: "#fdf9e2",
      GREY_COLOR: "#333",
      LIGHT_YELLOW_COLOR: "#fee188",
      WHITE_COLOR: "#fff",
      BLUE_100: "#2e6588",
      BLUE_200: "#2c5976",
      PURPLE_100: "#892e65",
      PURPLE_200: "#762c59",
      RED_100: "#d15b47",
      BLUE_50: "#62a8d1",
      BLUE_75: "#579ec8",
      BLUE_30: " #85c0ec",
      GREY_50: "#e5e5e5",
      GREY_40: "#222a2d",
      GREY_30: "#414b51",
      GREY_150: "#141a1b",
      GREY_175: "#3f4e54",
      GREY_80: "#b1bac1",
      BLUE_20: "#e1eaf1",
      GREY_20: "#5a6364",
      LIGHT_GREY_20: "#afbdc9",
      LIGHT_GREY_30: "#d1dae1",
      LIGHT_BLUE_20: "#85c0ec",
      GREY_45: "#aaa",
      GREY_55: "#bbb",
      GREY_60: "#929ba3",
      GRYEY_65: "#3a4344",
      GREY_65: "#606060",
      BLUE_25: "#2679b5",
      GREY_75: "#ddd",
      GRAY_70: "#aaaaaa",
      BLACK_50: "#444",
      BLACK_60: "#999",
      GREY_90: "#393939",
      LIGHT_GREY_24: "#777777",
      LIGHT_GREY_25: "#ccc",
      DARK_GREY_100: "#858585",
      DARK_GREY_105: "#696969",
      BLUE_105: "#478fca",
      BLUE_110: "#4d6883",
      BLUE_115: "#0A73FA",
      BLUE_116: "#428bca",
      GRAY: "gray",
      BLUE_2B: "#2b7dbc",
      LIGHT_GREY_5: "#bdbec4",
      DARK_BLUE: "#328db5",
      DARK_BLUE_1: "#3b7296",
      DARK_BLUE_20: "#2e6589",
      DARK_GREY: "#4d4d4d",
      BLACK_COLOR: "#000000",
      DARK_GREY_10: "#4c4c4c",
      LIGHT_GREY_15: "#d6d5d5",
      LIGHT_GREY_45: "#969696",
      GREY_25: "#515151",
      GREY_35: "#e1e1e1",
      GREEN_COLOR: "#72aa69",
      GREEN_1: "#59945a",
      YELLOW_COLOR: "#f1c911",
      YELLOW_COLOR_1: "#fefae8",
      LIGHT_GREY_50: "#959596",
      GREY_70: "#a5a5a5",
      GREY_110: "#dfdfdf",
      GREY_95: "#f7f7f7",
      LIGHT_BLUE_50: "#4698bc",
      BLUE_117: "#2c78c0",
      BLUE_26: "#2c6aa0",
      LIGHT_BLUE_22: "#fbfbfb",
      BLACK_10: "#383838",
      BLACK_15: "#343434",
      GREEN_35: "#468847",
      GREEN_40: "#dff0d8",
      BLACK_20: "#272727",
      BLUE_11: "#f8f8f8",
      GREY_56: "#e8e8e8",
      LIGHT_GREY_35: "#eee",
      BLUE_51: "#488bc3",
      GREEN_10: "#87b87f",
      GREY_11: "#c2c2c2",
      BLUE_21: "#4595df",
      LIGHT_BLUE_10: " #f5f7f8",
      BLACK_11: "#303030",
      GREY_12: "#929292",
      LIGHT_GREY_11: "#707070",
      LIGHT_GREY_12: "#737373",
      LIGHT_BLUE_13: "#5398d3",
      GREY_13: "#585858",
      BLUE_14: "#4c9bcf",
      GREY_34: "#b0b0b0",
      GREY_36: "#bcbdc3",
      BLUE_56: "#4b90cc",
      LIGHT_GREY_16: "#a8a8a8",
      BLACK_12: "#3b3b3b",
      YELLOW_RD: "#f59942",
      YELLOW_10: "#ffb049",
      YELLOW_11: "#f9f5e7",
      YELLOW_12: "#fefae7",
      YELLOW_13: "#53430a",
      BLACK_13: "#2c2c2c",
      BLUE_23: "#eff4f7",
      BLACK_14: "#111111",
      GREY_14: "#d8d8d8",
      GREY_18: "#c9c9c9",
      RED_11: "#ff1111",
      BLACK_16: "#1d1d1d",
      LIGHT_GREY_6: "#cfcfcf",
      GREY_10: "#dde0e2",
      LIGHT_GREY_7: "#f2f2f2",
      GREY_17: "#cbcbcb",
      GREY_21: "#dde0e2",
      GREY_22: "#434954",
      GREY_23: "#d4d4d4",
      GREY_24: "#4f4f4f",
      BLACK_17: "#939192",
      GREY_26: "#757575",
      RED_12: "#d93f3c",
      RED_13: "#f7ebeb",
      LIGHT_BLUE_6: "#6B83B0",
      DARK_GREY_1: "#808080",
      LIGHT_GREY_13: "#dddddd",
      BLUE_10: "#f0f0f0",
      GREY_16: "#c9d4db",
      GREY_19: "#f3f3f3",
      BLACK_22: " #161616",
      GREY_27: "#dcdcdc",
      GREY_28: "#656565",
      LIGHT_GREY_17: " #fcfcfc",
      GREY_29: "#dadada",
      GREY_31: "#5c5c5c",
      BLUE_27: "#64a2cb",
      BLACK_25: "#636363",
      BLUE_118: "#4f92be",
      RED: "red",
      BLUE_31: "#2283c5",
      LIGHT_RED_1: "#f7e7e7",
      DARK_RED_1: "#d33532",
      BLUE_36: "#ebf3fa",
      LIGHT_GREY_22: "#f6f6f6",
      GREEN_45: "#59a84b",
      RED_18: "#d33532",
      DARK_GREY_20: "#8b9aa3",
      BLUE_41: "#e2ebf4",
      BLUE_42: "#e8f0f8",
      DARK_GREY_110: "#5e5959",
      CYAN_BLUE_1: "#d9e6f1",
      DARK_GREY_115: "#2f2f2f",
      GREY_COLOR_12: "#d2d2d2",
      LIGHT_GREY_27: "#eaeaea",
      BLUE_119: "#e3f2f9",
      BLUE_22: "#b3cfdb",
      GREY_101: "#bfbfbf",
      ORANGE_1: "#efad62",
      ORANGE_2: "#faf2e9",
      ORANGE_3: "#F85900",
      LIGHT_GREY_40: "#d5d5d5",
      DARK_GREY_101: "#b2a89e",
      LIGHT_BLUE_15: "#d9edf7",
      BLUE_46: "#3a87ad",
      LIGHT_BLUE_101: "#c7ddec",
      LIGHT_BLUE_102: "#f9fcff",
      LIGHT_BLUE_103: "#c7ddef",
      LIGHT_BLUE_104: "#f8fcff",
      LIGHT_BLUE_105: "#bce8f1",
      GREY_111: "#9a9a9a",
      GREY_41: "#b4b4b4",
      LIGHT_GREY_55: "#c4cfde",
      DARK_GREY_106: "#535353",
      LIGHT_GREY_32: "#c7ddec",
      RED_20: "#de4b3c",
      ORANGE_5: "#ff9300",
      LIGHT_GREY_36: "#f1f5f9",
      GREY_201: "#AFAFAF",
      DARK_GREY_201: "#555",
      LIGHT_BLUE_106: "#3fc3cc",
      LIGHT_ORANGE_1: "#ffa87f",
      LIGHT_ORANGE_2: "#c99132",
      ORANGE_6: "#ff5200",
      GREY_205: "#818181",
      GREY_210: "#949494",
      LIGHT_BROWN_1: "#fdf2e4",
      BROWN_1: "#f29423",
      BLUE_121: "#2c6aa0",
      BLUE_122: "#3b85c6",
      BLUE_123: "#3c9af0",
      BLUE_124: "#0e487b",
      BLUE_126: "#28679c;",
      RED_16: "#d3212c",
      ORANGE_10: "#ff980e",
      GREEN_111: "#069c56",
      RED_19: "#dd5a43",
      GREEN_11: "#73aa6a",
      LIGHT_GREY_41: "#ececec",
      LIGHT_BLUE_112: "#b8bbc3",
      LIGHT_GREY_46: "#f1f1f1",
      GREY_206: "#717171",
      GREY_211: "#8e8c92",
      GREEN_12: "#e2f7df",
      GREEN_13: "#c4edbe",
      RED_101: "#fbecea",
      RED_102: "#f4dcdc",
      BLUE_111: "#eff5fb",
      BLUE_112: "#e4f2ff",
      BLUE_113: "#c2e7f2",
      GREY_32: "#727373",
      GREY_51: "#98a3ac",
      BLACK_70: "#3d3d3d",
      GREY_33: "#999999",
      LIGHT_GREY_51: "#e6e6e6",
      CYAN_BLUE_6: "#4894ca",
      LIGHT_GREY_56: "#b7b7b7",
      LIGHT_GREY_61: "#cfcece",
      LIGHT_GREY_10: "#d9d9d9",
      LIGHT_BLUE_25: "#eff3f8",
      LIGHT_BLUE_27: "#d8e6f3",
      LIGHT_BLUE_28: "#2f97c1b3",
    },
    bgColors: {
      ICE_BLUE: "#c3e0fa",
      BLUE_181: "#7CADD6",
      ORANGE_505: "#f85900",
      RED_202: "#e5bab3",
      GREEEN_202: "#cde8cc",
      YELLOW_ORANGISH: "#ff6915",
      BLUE_150: "#a3c6e5",
      YELLOW_116: "#fdf9e2",
      PRIMARY_BG_COLOR: "#000000",
      GREY_COLOR: "#333",
      LIGHT_YELLOW_COLOR: "#fee188",
      WHITE_COLOR: "#fff",
      BLUE_100: "#2e6588",
      BLUE_200: "#2c5976",
      PURPLE_100: "#892e65",
      PURPLE_200: "#762c59",
      RED_100: "#d15b47",
      BLUE_50: "#62a8d1",
      BLUE_75: "#579ec8",
      BLUE_30: " #85c0ec",
      GREY_50: "#e5e5e5",
      GREY_40: "#222a2d",
      GREY_30: "#414b51",
      GREY_150: "#141a1b",
      GREY_175: "#3f4e54",
      GREY_80: "#b1bac1",
      BLUE_20: "#e1eaf1",
      GREY_20: "#5a6364",
      LIGHT_GREY_20: "#afbdc9",
      LIGHT_GREY_30: "#d1dae1",
      LIGHT_BLUE_20: "#85c0ec",
      GREY_45: "#aaa",
      GREY_55: "#bbb",
      GREY_60: "#929ba3",
      GRYEY_65: "#3a4344",
      GREY_65: "#606060",
      BLUE_25: "#2679b5",
      GREY_75: "#ddd",
      DISABLED_BLUE_BACKGROUND: "#428bca",
      GREY_120: "#888888",
      BLUE_110: "#3875d7",
      GREY_115: "#f4f4f4",
      GREY_15: "#ebebeb",
      GREY_125: "#888",
      DARK_BLUE_10: "#1b6aaa",
      BLUE_10: "#f0f0f0",
      BLUE_115: "#0A73FA",
      BLUE_116: "#428bca",
      BLUE_120: "#005CC8",
      LIGHT_BLUE_25: "#eff3f8",
      LIGHT_BLUE_26: "#e3effa",
      DARK_GREY_20: "#8b9aa3",
      BLUE_15: "#6fb3e0",
      DARK_BLUE_15: "#4f99c6",
      GREY_BLUE: "#f9f9f9",
      LIGHT_BLUE: "#f5f5f5",
      LIGHT_BLUE_1: "#e8f1f9",
      LIGHT_GREY_35: "#eee",
      LIGHT_BLUE_5: "#fafafa",
      LIGHT_BLUE_30: "#f5f8fc",
      BLUE_55: "#32a3ce",
      BlUE_Background: "#438eb9",
      HONEY_BACKGROUND: "#D8F6CE",
      BLUE_105: "#478fca",
      BLUE_125: "#A3CCFC",
      LIGHT_GREY: "#c5c5c5",
      LIGHT_GREY_15: "#d6d5d5",
      LIGHT_GREY_45: "#969696",
      GREY_25: "#515151",
      GREY_35: "#e1e1e1",
      LIGHT_BLUE_24: "#eff5f8",
      DARK_BLUE: "#328db5",
      DARK_BLUE_1: "#3b7296",
      DARK_BLUE_20: "#2e6589",
      GREEN_COLOR: "#72aa69",
      GREEN_1: "#59945a",
      YELLOW_COLOR: "#f1c911",
      YELLOW_COLOR_1: "#fefae8",
      LIGHT_GREY_50: "#959596",
      GREY_70: "#a5a5a5",
      GREY_110: "#dfdfdf",
      GREY_95: "#f7f7f7",
      LIGHT_BLUE_50: "#4698bc",
      BLUE_26: "#2c6aa0",
      LIGHT_BLUE_22: "#fbfbfb",
      BLACK_10: "#383838",
      BLACK_15: "#343434",
      GREEN_35: "#468847",
      GREEN_40: "#dff0d8",
      BLACK_20: "#272727",
      BLUE_11: "#f8f8f8",
      GREY_56: "#e8e8e8",
      BLUE_51: "#488bc3",
      GREEN_10: "#87b87f",
      GREY_11: "#c2c2c2",
      BLUE_21: "#4595df",
      LIGHT_BLUE_10: " #f5f7f8",
      BLACK_11: "#303030",
      GREY_12: "#929292",
      LIGHT_GREY_11: "#707070",
      LIGHT_GREY_12: "#737373",
      LIGHT_BLUE_13: "#5398d3",
      GREY_13: "#585858",
      BLUE_14: "#4c9bcf",
      GREY_34: "#b0b0b0",
      GREY_36: "#bcbdc3",
      BLUE_56: "#4b90cc",
      LIGHT_GREY_16: "#a8a8a8",
      BLACK_12: "#3b3b3b",
      YELLOW_10: "#ffb049",
      YELLOW_11: "#f9f5e7",
      YELLOW_12: "#fefae7",
      YELLOW_13: "#53430a",
      BLACK_13: "#2c2c2c",
      BLUE_23: "#eff4f7",
      BLACK_14: "#111111",
      GREY_14: "#d8d8d8",
      GREY_18: "#c9c9c9",
      RED_11: "#ff1111",
      BLACK_16: "#1d1d1d",
      LIGHT_GREY_6: "#cfcfcf",
      GREY_10: "#dde0e2",
      LIGHT_GREY_7: "#f2f2f2",
      GREY_17: "#cbcbcb",
      GREY_21: "#dde0e2",
      GREY_22: "#434954",
      GREY_23: "#d4d4d4",
      GREY_24: "#4f4f4f",
      BLACK_17: "#939192",
      GREY_26: "#757575",
      RED_12: "#d93f3c",
      RED_13: "#f7ebeb",
      LIGHT_BLUE_6: "#6B83B0",
      DARK_GREY_1: "#808080",
      LIGHT_GREY_13: "#dddddd",
      GREY_16: "#c9d4db",
      GREY_19: "#f3f3f3",
      BLACK_22: " #161616",
      GREY_27: "#dcdcdc",
      GREY_28: "#656565",
      LIGHT_GREY_17: " #fcfcfc",
      GREY_29: "#dadada",
      GREY_31: "#5c5c5c",
      BLUE_27: "#64a2cb",
      DARK_GREY_10: "#4c4c4c",
      BLACK_25: "#636363",
      BLUE_118: "#4f92be",
      RED: "red",
      BLUE_31: "#2283c5",
      LIGHT_RED_1: "#f7e7e7",
      DARK_RED_1: "#d33532",
      LIGHT_GREY_5: "#bdbec4",
      BLUE_36: "#ebf3fa",
      LIGHT_GREY_22: "#f6f6f6",
      GREEN_45: "#59a84b",
      RED_18: "#d33532",
      BLUE_41: "#e2ebf4",
      BLUE_42: "#e8f0f8",
      DARK_GREY_110: "#5e5959",
      CYAN_BLUE_1: "#d9e6f1",
      DARK_GREY_115: "#2f2f2f",
      GREY_COLOR_12: "#d2d2d2",
      LIGHT_GREY_27: "#eaeaea",
      BLUE_119: "#e3f2f9",
      BLUE_22: "#b3cfdb",
      GREY_101: "#bfbfbf",
      ORANGE_1: "#efad62",
      ORANGE_2: "#faf2e9",
      ORANGE_3: "#F85900",
      DARK_GREY_101: "#b2a89e",
      LIGHT_BLUE_15: "#d9edf7",
      BLUE_46: "#3a87ad",
      LIGHT_BLUE_101: "#c7ddec",
      LIGHT_BLUE_102: "#f9fcff",
      LIGHT_BLUE_103: "#c7ddef",
      LIGHT_BLUE_104: "#f8fcff",
      LIGHT_BLUE_105: "#bce8f1",
      GREY_111: "#9a9a9a",
      GREY_41: "#b4b4b4",
      LIGHT_GREY_55: "#c4cfde",
      DARK_GREY_106: "#535353",
      LIGHT_GREY_32: "#c7ddec",
      RED_20: "#de4b3c",
      ORANGE_5: "#ff9300",
      LIGHT_GREY_36: "#f1f5f9",
      GREY_201: "#AFAFAF",
      DARK_GREY_201: "#555",
      LIGHT_BLUE_106: "#3fc3cc",
      LIGHT_ORANGE_1: "#ffa87f",
      LIGHT_ORANGE_2: "#c99132",
      ORANGE_6: "#ff5200",
      GREY_205: "#818181",
      GREY_210: "#949494",
      LIGHT_BROWN_1: "#fdf2e4",
      BROWN_1: "#f29423",
      BLUE_121: "#2c6aa0",
      BLUE_122: "#3b85c6",
      BLUE_123: "#3c9af0",
      BLUE_124: "#0e487b",
      BLUE_126: "#28679c;",
      RED_16: "#d3212c",
      ORANGE_10: "#ff980e",
      GREEN_111: "#069c56",
      RED_19: "#dd5a43",
      GREEN_11: "#73aa6a",
      LIGHT_GREY_41: "#ececec",
      LIGHT_BLUE_112: "#b8bbc3",
      LIGHT_GREY_46: "#f1f1f1",
      GREY_206: "#717171",
      GREY_211: "#8e8c92",
      BLACK_COLOR: "#000000",
      GREEN_12: "#e2f7df",
      GREEN_13: "#c4edbe",
      RED_101: "#fbecea",
      RED_102: "#f4dcdc",
      BLUE_111: "#eff5fb",
      BLUE_112: "#e4f2ff",
      BLUE_113: "#c2e7f2",
      GREY_32: "#727373",
      GREY_51: "#98a3ac",
      BLACK_70: "#3d3d3d",
      GREY_33: "#999999",
      LIGHT_GREY_51: "#e6e6e6",
      CYAN_BLUE_6: "#4894ca",
      LIGHT_GREY_56: "#b7b7b7",
      LIGHT_GREY_61: "#cfcece",
      LIGHT_GREY_10: "#d9d9d9",
      LIGHT_BLUE_27: "#d8e6f3",
      LIGHT_BLUE_28: "#2f97c1b3",
    },
    borderColors: {
      ICE_BLUE: "#c3e0fa",
      BLUE_181: "#7CADD6",
      ORANGE_505: "#f85900",
      RED_202: "#e5bab3",
      GREEEN_202: "#cde8cc",
      YELLOW_ORANGISH: "#ff6915",
      BLUE_150: "#a3c6e5",
      YELLOW_116: "#fdf9e2",
      GREY_COLOR: "#333",
      LIGHT_YELLOW_COLOR: "#fee188",
      WHITE_COLOR: "#fff",
      BLUE_100: "#2e6588",
      BLUE_200: "#2c5976",
      PURPLE_100: "#892e65",
      PURPLE_200: "#762c59",
      RED_100: "#d15b47",
      BLUE_50: "#62a8d1",
      BLUE_75: "#579ec8",
      BLUE_30: " #85c0ec",
      GREY_50: "#e5e5e5",
      GREY_40: "#222a2d",
      GREY_30: "#414b51",
      GREY_150: "#141a1b",
      GREY_175: "#3f4e54",
      GREY_80: "#b1bac1",
      BLUE_20: "#e1eaf1",
      GREY_20: "#5a6364",
      LIGHT_GREY_20: "#afbdc9",
      LIGHT_GREY_30: "#d1dae1",
      LIGHT_BLUE_20: "#85c0ec",
      GREY_45: "#aaa",
      GREY_55: "#bbb",
      GREY_60: "#929ba3",
      GRYEY_65: "#3a4344",
      GREY_65: "#606060",
      BLUE_25: "#2679b5",
      GREY_75: "#ddd",
      YELLOW_RD: "#f59942",
      GRAY_70: "#aaaaaa",
      DISABLED_BLUE_BORDER_COLOR: "#428bca",
      DARK_GREY_15: "#b5b5b5",
      DARK_GREY_100: "#858585",
      LIGHT_BLUE_45: "#e4e9ee",
      LIGHT_GREY_10: "#d9d9d9",
      LIGHT_BLUE_35: "#d3e6f3",
      DARK_GREY_20: "#8b9aa3",
      DARK_GREY_25: "#abbac3",
      BLUE_15: "#6fb3e0",
      BLUE_35: "#709fc0",
      LIGHT_GREY_25: "#ccc",
      LIGHT_GREY_40: "#d5d5d5",
      GREY_100: "#c8c8c8",
      BLUE_55: "#32a3ce",
      BLUE_115: "#0A73FA",
      BLUE_116: "#428bca",
      LIGHT_BLUE_40: "#d5e3ef",
      BLUE_105: "#478fca",
      BLACK_21: "#212121",
      GREY_COLOR_12: "#d2d2d2",
      BLUE_125: "#A3CCFC",
      LIGHT_GREY_15: "#d6d5d5",
      LIGHT_GREY_45: "#969696",
      GREY_25: "#515151",
      GREY_35: "#e1e1e1",
      DARK_BLUE: "#328db5",
      DARK_BLUE_1: "#3b7296",
      DARK_BLUE_20: "#2e6589",
      GREEN_COLOR: "#72aa69",
      GREEN_1: "#59945a",
      YELLOW_COLOR: "#f1c911",
      YELLOW_COLOR_1: "#fefae8",
      LIGHT_GREY_50: "#959596",
      GREY_70: "#a5a5a5",
      GREY_110: "#dfdfdf",
      GREY_95: "#f7f7f7",
      LIGHT_BLUE_50: "#4698bc",
      BLUE_117: "#2c78c0",
      BLUE_26: "#2c6aa0",
      LIGHT_BLUE_22: "#fbfbfb",
      BLACK_10: "#383838",
      BLACK_15: "#343434",
      GREEN_35: "#468847",
      GREEN_40: "#dff0d8",
      BLACK_20: "#272727",
      BLUE_11: "#f8f8f8",
      GREY_56: "#e8e8e8",
      LIGHT_GREY_35: "#eee",
      BLUE_51: "#488bc3",
      GREEN_10: "#87b87f",
      GREY_11: "#c2c2c2",
      BLUE_21: "#4595df",
      LIGHT_BLUE_10: " #f5f7f8",
      BLACK_11: "#303030",
      GREY_12: "#929292",
      LIGHT_GREY_11: "#707070",
      LIGHT_GREY_12: "#737373",
      LIGHT_BLUE_13: "#5398d3",
      GREY_13: "#585858",
      BLUE_14: "#4c9bcf",
      GREY_34: "#b0b0b0",
      GREY_36: "#bcbdc3",
      BLUE_56: "#4b90cc",
      LIGHT_GREY_16: "#a8a8a8",
      BLACK_12: "#3b3b3b",
      YELLOW_10: "#ffb049",
      YELLOW_11: "#f9f5e7",
      YELLOW_12: "#fefae7",
      YELLOW_13: "#53430a",
      BLACK_13: "#2c2c2c",
      BLUE_23: "#eff4f7",
      BLACK_14: "#111111",
      GREY_14: "#d8d8d8",
      GREY_18: "#c9c9c9",
      RED_11: "#ff1111",
      BLACK_16: "#1d1d1d",
      LIGHT_GREY_6: "#cfcfcf",
      GREY_10: "#dde0e2",
      LIGHT_GREY_7: "#f2f2f2",
      GREY_17: "#cbcbcb",
      GREY_21: "#dde0e2",
      GREY_22: "#434954",
      GREY_23: "#d4d4d4",
      GREY_24: "#4f4f4f",
      BLACK_17: "#939192",
      GREY_26: "#757575",
      RED_12: "#d93f3c",
      RED_13: "#f7ebeb",
      LIGHT_BLUE_6: "#6B83B0",
      BLUE_10: "#f0f0f0",
      DARK_GREY_1: "#808080",
      LIGHT_GREY_13: "#dddddd",
      GREY_16: "#c9d4db",
      GREY_19: "#f3f3f3",
      BLACK_22: " #161616",
      GREY_27: "#dcdcdc",
      GREY_28: "#656565",
      LIGHT_GREY_17: " #fcfcfc",
      GREY_29: "#dadada",
      GREY_31: "#5c5c5c",
      BLUE_27: "#64a2cb",
      DARK_GREY_10: "#4c4c4c",
      BLACK_25: "#636363",
      BLUE_118: "#4f92be",
      RED: "red",
      BLUE_31: "#2283c5",
      LIGHT_RED_1: "#f7e7e7",
      DARK_RED_1: "#d33532",
      LIGHT_GREY_5: "#bdbec4",
      BLUE_36: "#ebf3fa",
      LIGHT_GREY_22: "#f6f6f6",
      GREEN_45: "#59a84b",
      RED_18: "#d33532",
      BLUE_41: "#e2ebf4",
      BLUE_42: "#e8f0f8",
      DARK_GREY_110: "#5e5959",
      CYAN_BLUE_1: "#d9e6f1",
      DARK_GREY_115: "#2f2f2f",
      LIGHT_GREY_27: "#eaeaea",
      BLUE_119: "#e3f2f9",
      BLUE_22: "#b3cfdb",
      GREY_101: "#bfbfbf",
      ORANGE_1: "#efad62",
      ORANGE_2: "#faf2e9",
      ORANGE_3: "#F85900",
      DARK_GREY_101: "#b2a89e",
      LIGHT_BLUE_15: "#d9edf7",
      BLUE_46: "#3a87ad",
      LIGHT_BLUE_101: "#c7ddec",
      LIGHT_BLUE_102: "#f9fcff",
      LIGHT_BLUE_103: "#c7ddef",
      LIGHT_BLUE_104: "#f8fcff",
      LIGHT_BLUE_105: "#bce8f1",
      GREY_111: "#9a9a9a",
      GREY_41: "#b4b4b4",
      LIGHT_GREY_55: "#c4cfde",
      DARK_GREY_106: "#535353",
      LIGHT_GREY_32: "#c7ddec",
      RED_20: "#de4b3c",
      ORANGE_5: "#ff9300",
      LIGHT_GREY_36: "#f1f5f9",
      GREY_201: "#AFAFAF",
      DARK_GREY_201: "#555",
      LIGHT_BLUE_106: "#3fc3cc",
      LIGHT_ORANGE_1: "#ffa87f",
      LIGHT_ORANGE_2: "#c99132",
      ORANGE_6: "#ff5200",
      GREY_205: "#818181",
      GREY_210: "#949494",
      LIGHT_BROWN_1: "#fdf2e4",
      BROWN_1: "#f29423",
      BLUE_121: "#2c6aa0",
      BLUE_122: "#3b85c6",
      BLUE_123: "#3c9af0",
      BLUE_124: "#0e487b",
      BLUE_126: "#28679c;",
      RED_16: "#d3212c",
      ORANGE_10: "#ff980e",
      GREEN_111: "#069c56",
      RED_19: "#dd5a43",
      GREEN_11: "#73aa6a",
      LIGHT_GREY_41: "#ececec",
      LIGHT_BLUE_112: "#b8bbc3",
      LIGHT_GREY_46: "#f1f1f1",
      GREY_206: "#717171",
      GREY_211: "#8e8c92",
      BLACK_COLOR: "#000000",
      GREEN_12: "#e2f7df",
      GREEN_13: "#c4edbe",
      RED_101: "#fbecea",
      RED_102: "#f4dcdc",
      BLUE_111: "#eff5fb",
      BLUE_112: "#e4f2ff",
      BLUE_113: "#c2e7f2",
      GREY_32: "#727373",
      GREY_51: "#98a3ac",
      BLACK_70: "#3d3d3d",
      GREY_33: "#999999",
      LIGHT_GREY_51: "#e6e6e6",
      CYAN_BLUE_6: "#4894ca",
      LIGHT_GREY_56: "#b7b7b7",
      LIGHT_GREY_61: "#cfcece",
      LIGHT_BLUE_25: "#eff3f8",
      LIGHT_BLUE_27: "#d8e6f3",
      LIGHT_BLUE_28: "#2f97c1b3",
    },
  },
};
