import { Fragment, useCallback } from "react";
import { Card } from "antd";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  Label,
  CartesianGrid,
} from "recharts";

import {
  StepLineChartStyled,
  ToolTipContentWrapperStyled,
} from "./steplinechart.styles";

import { ChartDataItemType, StepLineChartProps } from "./steplinechart.types";

import {
  convertDateIntoUTC,
  numberFormatter,
  shortNumberFormater,
  utcTOLocalTimeZone,
} from "../../../utils";

import Flex from "../../flex";
import RechartHOC from "../recharthoc/recharthoc";

import { RechartHOCEmbedProps } from "../recharthoc/recharthoc.types";
import { MONTH_DAY_YEAR_HOUR_MINUTE } from "../../../constants";

//CHANGE ANY TO PROPPER TYPE
const CustomizedDot = (props: {
  cx?: number;
  cy?: number;
  payload?: ChartDataItemType;
  isActiveDot?: boolean;
}): JSX.Element => {
  const { cx, cy, payload, isActiveDot } = props;

  return (
    <circle
      cx={cx}
      cy={cy}
      r={isActiveDot ? 8 : 4}
      fill={payload?.isAlerting ? "red" : "black"}
      stroke="none"
    />
  );
};

const StepLineChartContent = (
  props: StepLineChartProps & RechartHOCEmbedProps
): JSX.Element => {
  const {
    width = 450,
    height = 500,
    isFromChartView = false,
    chartData,
    legends,
    lastRefreshedOn = "",
    chartKey,
  } = props;

  const renderTooltip = useCallback((props): JSX.Element => {
    const { payload } = props;

    const isAlerting = payload?.[0]?.payload?.isAlerting;
    const tooltipData = payload?.[0]?.payload?.metaData;

    const upperBound = tooltipData?.upperBound;
    const lowerBound = tooltipData?.lowerBound;
    const metric = tooltipData?.bucketResult;
    const executionTime = tooltipData?.bucketTime;

    return (
      <ToolTipContentWrapperStyled isAlerting={isAlerting}>
        <Flex columnGap={2}>
          <div>Time: </div>
          <div>
            {utcTOLocalTimeZone(executionTime, MONTH_DAY_YEAR_HOUR_MINUTE)}
          </div>
        </Flex>
        <Flex columnGap={2}>
          <div>Metric: </div>
          <div>{metric ? numberFormatter(metric) : ""}</div>
        </Flex>
        <Flex columnGap={2}>
          <div>Upper Bound: </div>
          <div>{upperBound ? numberFormatter(upperBound) : ""}</div>
        </Flex>
        <Flex columnGap={2}>
          <div>Lower Bound: </div>
          <div>{lowerBound ? numberFormatter(lowerBound) : ""}</div>
        </Flex>
      </ToolTipContentWrapperStyled>
    );
  }, []);

  const calculateMinMax = (): { min: number; max: number } => {
    const minMaxValue =
      chartData?.reduce(
        (acc: { min: number; max: number }, item: ChartDataItemType) => {
          const bucketResult = Number(item?.metaData?.bucketResult);

          const result = Number(bucketResult);

          if (result) {
            return {
              min: Math.min(acc?.min, result),
              max: Math.max(acc?.max, result),
            };
          }
          return acc;
        },
        { min: 0, max: 0 }
      ) || [];

    const { min, max } = minMaxValue || {};
    return { min, max };
  };

  const calculateYAxisDomain = (): [number, number] => {
    const { min, max } = calculateMinMax();

    let lower = min;
    let upper = max;

    const lowerUpperBound =
      chartData?.reduce(
        (
          acc: { lowerBound: number; upperBound: number },
          item: ChartDataItemType
        ) => {
          const { lowerBound, upperBound } = item?.metaData;
          if (lowerBound) {
            acc.lowerBound = Math.min(acc?.lowerBound, Number(lowerBound));
          }
          if (upperBound) {
            acc.upperBound = Math.max(acc?.upperBound, Number(upperBound));
          }
          return acc;
        },
        { lowerBound: min, upperBound: max }
      ) || [];

    const { lowerBound, upperBound } = lowerUpperBound || {};

    if (!Number.isNaN(lowerBound)) {
      lower = lowerBound;
    }

    if (!Number.isNaN(upperBound)) {
      upper = upperBound;
    }

    return [lower, upper];
  };

  const yaxisKeys = chartData?.map((item: ChartDataItemType) => item?.yaxisKey);

  // Check if all yaxisKey values are the same and alerting
  const allSameAndAlerting = yaxisKeys?.every(
    (value: ChartDataItemType, index: number, arr: ChartDataItemType[]) =>
      value === arr?.[0] && chartData?.[index]?.isAlerting
  );

  // Check if all yaxisKey values are the same and healthy
  const allSameAndHealthy = yaxisKeys?.every(
    (value: ChartDataItemType, index: number, arr: ChartDataItemType[]) =>
      value === arr?.[0] && !chartData?.[index]?.isAlerting
  );

  return (
    <StepLineChartStyled>
      <Card bordered={isFromChartView}>
        <ResponsiveContainer width={width} height={height}>
          <LineChart
            data={chartData}
            margin={{ top: 5, right: 20, bottom: 5, left: 10 }}
          >
            <CartesianGrid
              strokeDasharray="2"
              vertical={false}
              stroke="#e6e6e6"
            />
            <XAxis
              dataKey="xaxisKey"
              padding={{ left: 30, right: 30 }}
              tickLine={false}
              axisLine={{ stroke: "#e6e6e6" }}
              tickMargin={10}
              height={60}
            >
              {isFromChartView ? (
                <Label position="insideBottom" fill="#8a8c8c">
                  {/* MAKE DATE FORMAT A CONSTANT */}
                  Time axis: Update Time
                </Label>
              ) : (
                <div />
              )}
            </XAxis>

            <YAxis
              dataKey="yaxisKey"
              domain={calculateYAxisDomain()}
              tickFormatter={(val): string => {
                return `${shortNumberFormater(val)}`;
              }}
            >
              {isFromChartView ? (
                <Label
                  angle={270}
                  fill="#8a8c8c"
                  value="Metric Value"
                  position="left"
                />
              ) : (
                <div />
              )}
            </YAxis>
            {legends}
            <defs>
              <linearGradient
                id={`gradient${chartKey}`}
                x1="0"
                y1="0"
                x2="100%"
                y2="0"
              >
                {chartData?.map((item: ChartDataItemType, index: number) => {
                  const percentage =
                    index === 0 ? 0 : (index * 100) / (chartData?.length - 1);
                  const nextStepPercentage =
                    ((index + 1) * 100) / (chartData?.length - 1);

                  const nextPerc = Math.min(nextStepPercentage, 100);

                  return (
                    <Fragment
                      key={`line-${item?.metaData?.bucketTime}-${item?.metaData?.bucketResult}`}
                    >
                      <stop offset={`${percentage}%`} stopColor="black" />
                      <stop
                        offset={`${percentage}%`}
                        stopColor={item?.isAlerting ? "red" : "black"}
                      />
                      <stop
                        offset={`${nextPerc}%`}
                        stopColor={item?.isAlerting ? "red" : "black"}
                      />
                      <stop offset={`${nextPerc}%`} stopColor="black" />
                    </Fragment>
                  );
                })}
              </linearGradient>
            </defs>
            <Tooltip content={renderTooltip} />
            <Line
              type="step"
              dataKey="yaxisKey"
              data={chartData}
              fill={
                allSameAndAlerting
                  ? "red"
                  : allSameAndHealthy
                  ? "black"
                  : `url(#gradient${chartKey})`
              }
              stroke={
                allSameAndAlerting
                  ? "red"
                  : allSameAndHealthy
                  ? "black"
                  : `url(#gradient${chartKey})`
              }
              connectNulls
              dot={<CustomizedDot />}
              activeDot={<CustomizedDot isActiveDot />}
            />
          </LineChart>
        </ResponsiveContainer>
      </Card>
    </StepLineChartStyled>
  );
};

const StepLineChart = (props: StepLineChartProps): JSX.Element => {
  return (
    <RechartHOC dataKeys={props?.dataKeys} showLedgends>
      <StepLineChartContent {...props} key={props?.chartKey} />
    </RechartHOC>
  );
};

export default StepLineChart;
