import { ThemeProvider } from "styled-components";
import { useGetAppState } from "../../customhooks";
import { getTheme } from "./themeprovider.utils";

function AppThemeProvider({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const { selectedTheme } = useGetAppState();
  return (
    <ThemeProvider theme={getTheme(selectedTheme)}>{children}</ThemeProvider>
  );
}

export default AppThemeProvider;
