import { useCallback, useEffect, useState } from "react";
import { Input } from "../../components/inputs";

import { useCancelDrawer, useDebounce } from "../../customhooks";
import { lightCrossIcon, redirectIcon, searchIcon } from "../../svgs";

import { DvsumHelpDrawerStyled } from "./dvsumhelpdrawer.styles";

import {
  AdditionalResourcesSec,
  DetailedView,
  DvsumHelpDrawerBreadcrum,
  DvsumHelpItemCard,
} from "./dvsumhelpdrawer.components";

import LinkButton from "../../components/linkbutton";
import { useGetdvSumHelpSearchResults } from "../../api/helpandonboardingservice/helpandonboardingservice";

import { SelectedCategory } from "./dvsumhelpdrawer.types";
import ExploreSec from "./dvsumhelpdrawer.components/exploresec/exploresec";

import ResultsSec from "./dvsumhelpdrawer.components/resultssec";
import StateHandler from "../../components/statehandler";

const DvsumHelpDrawer = (): JSX.Element => {
  const cancelDrawer = useCancelDrawer();

  const [searchText, setSearchText] = useState("");
  const [selectedTopic, setSelectedTopic] = useState<{
    title: string;
    isRecentUpdateFeaure: boolean;
  }>({ title: "", isRecentUpdateFeaure: false });

  const [selectedCategory, setSelectedCategory] = useState<SelectedCategory>();

  const searchDebounced = useDebounce(searchText, 500);

  const isStep1 = !selectedTopic?.title && !searchText;
  const isShowDetailedView = !isStep1 && selectedCategory?.item?.id;

  const {
    parsedData: selectedTopicParsedData,
    isFetching: selectedTopicIsFetching,
    error: selectedTopicError,
  } = useGetdvSumHelpSearchResults(
    selectedTopic?.isRecentUpdateFeaure
      ? "AND(is_release_onboarding_help=1)"
      : `SEARCH(%22${selectedTopic?.title?.toLocaleLowerCase()}%22%2C+Use_for_Search)`,
    !!selectedTopic?.title,
    selectedTopic?.isRecentUpdateFeaure
  );

  const {
    parsedData: articleTypeParsedData,
    isLoading: articleTypeIsLoading,
    error: articleTypeError,
  } = useGetdvSumHelpSearchResults(
    `SEARCH(%22Feature Sets%22%2C+article_type)`,
    true
  );

  const { feature_sets: featureSets = [] } = articleTypeParsedData || {};

  const { parsedData, isFetching, error } = useGetdvSumHelpSearchResults(
    `SEARCH(%22${searchDebounced?.toLocaleLowerCase()}%22%2C+Use_for_Search)`,
    !!searchDebounced
  );

  const { articles = [], quick_tips: quickTips = [], videos = [] } =
    (selectedTopic?.title ? selectedTopicParsedData : parsedData) || {};

  const onItemClick = useCallback((obj: SelectedCategory) => {
    setSelectedCategory({ ...obj });
  }, []);

  const handleExploreBtnClick = useCallback(
    (topic: string, isRecentUpdateFeaure: boolean) => {
      setSelectedTopic({ title: topic, isRecentUpdateFeaure });
    },
    []
  );

  const resetState = useCallback(() => {
    setSearchText("");
    setSelectedTopic({ title: "", isRecentUpdateFeaure: false });
    setSelectedCategory({});
  }, []);

  useEffect(() => {
    return (): void => {
      const testScript = document?.querySelector(".LandbotPopup");
      testScript?.parentNode?.removeChild(testScript);
    };
  }, []);

  return (
    <StateHandler
      isFetching={articleTypeIsLoading}
      error={articleTypeError}
      isModal
    >
      <DvsumHelpDrawerStyled>
        <div className="header">
          <DvsumHelpDrawerBreadcrum
            searchText={searchText}
            selectedTopic={selectedTopic?.title}
            category={selectedCategory?.category}
            resetState={resetState}
            setSelectedCategory={setSelectedCategory}
          />
          <div className="cross-icon" role="button" onClick={cancelDrawer}>
            {lightCrossIcon()}
          </div>
        </div>

        <div className="content-sec">
          <div className="top-sec">
            {!selectedTopic?.title && !isShowDetailedView && (
              <div className="search-wrapper">
                <Input
                  placeholder="Find guides and resources"
                  className="search-input"
                  prefix={searchIcon()}
                  suffix={
                    <div
                      role="button"
                      onClick={(): void => setSearchText("")}
                      className="search-cross-icon"
                    >
                      {searchText && lightCrossIcon("9.6", "9.6")}
                    </div>
                  }
                  onChange={(e): void => {
                    setSearchText(e?.target?.value || "");
                  }}
                  value={searchText}
                />
              </div>
            )}

            {isStep1 && (
              <ExploreSec
                data={featureSets}
                handleItemClick={handleExploreBtnClick}
              />
            )}

            {!isStep1 && !selectedCategory?.item?.id && (
              <ResultsSec
                data={
                  selectedTopic?.title ? selectedTopicParsedData : parsedData
                }
                error={selectedTopic?.title ? selectedTopicError : error}
                isFetching={
                  selectedTopic?.title ? selectedTopicIsFetching : isFetching
                }
                onResultItemClick={onItemClick}
                isSearching={searchText !== searchDebounced}
              />
            )}

            {isShowDetailedView && (
              <DetailedView selectedCategory={selectedCategory} />
            )}
          </div>

          <div className="bottom-sec">
            {isStep1 && <AdditionalResourcesSec />}
            {isShowDetailedView && (
              <div className="more-content-sec">
                <div className="title">More Content Like This</div>
                <div className="thumbnails-sec-wrapper">
                  {!!quickTips?.length &&
                    !(
                      selectedCategory?.item?.id === quickTips?.[0]?.id &&
                      quickTips?.length === 1
                    ) && (
                      <DvsumHelpItemCard
                        type="gif"
                        item={
                          selectedCategory?.item?.id !== quickTips?.[0]?.id ||
                          quickTips?.length === 1
                            ? quickTips?.[0]
                            : quickTips?.[1]
                        }
                        onClick={onItemClick}
                      />
                    )}
                  {!!videos?.length &&
                    !(
                      selectedCategory?.item?.id === videos?.[0]?.id &&
                      videos?.length === 1
                    ) && (
                      <DvsumHelpItemCard
                        type="video"
                        item={
                          selectedCategory?.item?.id !== videos?.[0]?.id ||
                          videos?.length === 1
                            ? videos?.[0]
                            : videos?.[1]
                        }
                        onClick={onItemClick}
                      />
                    )}
                </div>
                {!!articles?.length && (
                  <div>
                    <div className="divider" />
                    <LinkButton
                      className="article-item"
                      onClick={(): void => {
                        window.open(articles?.[0]?.url);
                      }}
                    >
                      {articles?.[0]?.title}
                      {redirectIcon("10", "10")}
                    </LinkButton>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </DvsumHelpDrawerStyled>
    </StateHandler>
  );
};

export default DvsumHelpDrawer;
