import { APP_ROUTES } from "../constants";
import { JobDetailPageTabType } from "../pages/jobdetailpage/jobdetailpage.types";

export const checkIsJobDetailPageeDetailsTab = (): boolean => {
  const pathname = window?.location?.pathname || "";
  const detailsTabKey: JobDetailPageTabType = "details";

  try {
    return (
      pathname?.includes(APP_ROUTES.private_routes.jobs_dictionary) &&
      pathname?.includes(detailsTabKey)
    );
  } catch (e) {
    return false;
  }
};
