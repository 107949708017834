import { ChartType } from "../../utils/getcharticon";

export type DashboardPropsType = {};
export type WidgetType = {
  title: string;
  id: string;
  chartType: ChartType;
  order: number;
  width: number;
  xIndex?: number;
  yIndex?: number;
  isLoading?: boolean;
};

export const DASHBORD_QUERY_PARAMS = {
  NODE_ID: "node-id",
};

export type DashboardPageParams = {
  id: string;
};
