import { ColumnDataType } from "../parsers";

export const checkDataTypeOfColumn = (
  dataType: ColumnDataType
): {
  isNumberCol: boolean;
  isDateColumn: boolean;
  isStringColumn: boolean;
  isDecimalColumn: boolean;
} => {
  const isNum = dataType === "DEC" || dataType === "INT" || dataType === "NUM";
  const isDate =
    dataType === "DTE" ||
    dataType === "DTM" ||
    dataType === "TIM" ||
    dataType === "DT";

  return {
    isDateColumn: isDate,
    isNumberCol: isNum,
    isStringColumn: dataType === "STR",
    isDecimalColumn: dataType === "DEC",
  };
};
