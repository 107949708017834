import styled from "styled-components";
import { ellipsis } from "../../../../../../styles/app.styles";

export const AddWidgetPickFromGalleryStyled = styled.div`
  height: 90vh;

  .heading {
    padding-bottom: 19px;
  }

  .clear-search-text-icon {
    svg {
      margin-top: 8px;
    }
  }

  .content {
    padding-top: 15px;
    padding-left: 26px;
    padding-right: 26px;

    margin-bottom: 15px;
  }

  .widget-section-wrapper {
    width: 100%;
  }
`;

export const AddWidgetPickFromGalleryMainStyled = styled.div`
  border: 1px solid ${({ theme }): string => theme.bgColors.LIGHT_GREY_51};
`;

export const CatergoryTile = styled.div<{ selectedTile: boolean }>`
  color: ${({ theme, selectedTile }): string =>
    selectedTile && theme.textColors.BLUE_116};
  font-weight: ${({ selectedTile }): string => (selectedTile ? "600" : "400")};
  padding: 10px 13px;
  cursor: pointer;
  background-color: ${({ theme, selectedTile }): string =>
    selectedTile && theme.bgColors.LIGHT_BLUE_25};
  border-left: ${({ theme, selectedTile }): string =>
    selectedTile ? `3px solid ${theme.bgColors.BLUE_116}` : ""};

  .widget-total {
    color: ${({ theme, selectedTile }): string =>
      selectedTile ? theme.textColors.BLUE_116 : theme.textColors.GREY_210};
  }
`;

export const CatergoryTileSection = styled.div`
  width: 180px;
  padding-top: 10px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 8px;
  }
`;

export const WidgetsSections = styled.div`
  padding: 5px 20px;
  border-left: 1px solid ${({ theme }): string => theme.bgColors.LIGHT_GREY_51};
  min-height: calc(100vh - 190px);
  width: 100%;
  max-height: calc(100vh - 190px);
  overflow-y: auto;

  .widget-section-title {
    font-weight: 600;
    font-size: 20px;
    color: ${({ theme }): string => theme.textColors.BLUE_116};
    margin-bottom: 16px;
    margin-top: 10px;
  }
`;

export const WidgetTile = styled.div<{ createYourOwnWidget?: boolean }>`
  padding: 14px;
  border: 1px solid ${({ theme }): string => theme.bgColors.LIGHT_GREY_51};
  border-style: ${({ createYourOwnWidget }): string =>
    createYourOwnWidget ? "dashed" : ""};
  cursor: pointer;

  min-width: 403px;
  min-height: 260px;

  .add-widget-card-title {
    font-size: 16px;
    font-weight: 600;
    max-width: 375px;
    ${ellipsis}
  }

  .add-widget-card-desc {
    color: ${({ theme }): string => theme.textColors.GREY_24};
  }
`;

export const WidgetGroup = styled.div`
  padding-bottom: 45px;
`;

export const AddWidgetPickFromGalleryFooterStyled = styled.div`
  background-color: ${(props): string => props.theme.bgColors.LIGHT_BLUE_25};
  border-top: 1px solid
    ${(props): string => props.theme.borderColors.LIGHT_BLUE_45};
  padding: 8px 18px 8px 18px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  column-gap: 8px;
`;

export const EmptyWidgetContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72vh;
  width: 100%;
  flex-direction: column;

  .no-widget-desc {
    margin-top: 5px;
    color: ${({ theme }): string => theme.textColors.GREY_210};
  }

  svg {
    path {
      fill: ${({ theme }): string => theme.textColors.GREY_210};
    }
  }
`;

export const WidgetStyled = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  .add-widget-media {
    height: 190px;
    margin-bottom: 13px;
    min-height: 152px;
    background-color: ${({ theme }): string => theme.bgColors.LIGHT_BLUE_25};
  }

  .add-widget-card-svg {
    min-width: 372px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .add-widget-card-img {
    min-width: 375px;
    min-height: 190px;
    height: 190px;
    width: 375px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      min-height: 100%;
      min-width: 100%;
      padding: 20px;
    }

    :hover {
      img {
        opacity: 0.2;
      }

      .plus-icon-div {
        display: block;
      }
    }

    .plus-icon-div {
      position: absolute;
      top: 43%;
      left: 43%;
      display: none;
    }
  }
`;
