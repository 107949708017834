import { ConfigProvider } from "antd";
import { DatePickerProps } from "./datepicker.types";
import {
  DatePickerContainerStyled,
  DatePickerStyled,
} from "./datepikcer.styles";

const DatePicker = ({
  className = "dvsum-datepicker",
  dropdownClassName = "dvsum-datepicker-dropdown",
  width,
  height,
  suffixIcon = null,
  ref,
  ...props
}: DatePickerProps<Date>): JSX.Element => {
  return (
    <DatePickerContainerStyled>
      <ConfigProvider>
        <DatePickerStyled
          {...props}
          width={width}
          height={height}
          className={className}
          dropdownClassName={dropdownClassName}
          ref={ref}
          suffixIcon={suffixIcon}
          allowClear={false}
          // prevIcon={prevIcon()}
          // nextIcon={nextIcon()}
          getPopupContainer={(trigger: HTMLElement): HTMLElement =>
            trigger.parentNode as HTMLElement
          }
        />
      </ConfigProvider>
    </DatePickerContainerStyled>
  );
};

export default DatePicker;
