import { useState, useEffect } from "react";

import { TypingAnimationStyled } from "./typinganimation.styles";
import { MarkdownContainerStyled } from "../../styles/app.styles";
import { parseStringToHtml, replaceAll } from "../../utils";

const TypingAnimation = ({
  text = "",
  onComplete,
  nextToText,
  showNextToText,
  streamCompleted,
}: {
  text: string;
  onComplete?: () => void;
  nextToText?: JSX.Element;
  showNextToText?: boolean;
  streamCompleted: boolean;
}): JSX.Element => {
  const [typedText, setTypedText] = useState("");

  useEffect(() => {
    let index = typedText?.length;
    const streamAnimation = `<span className="typing-animation"/>`;

    const typingInterval = setInterval(() => {
      if (index < text?.length && text && !streamCompleted) {
        const nextChar = text?.[index] || "";

        // Handle broken tags
        if (nextChar === "<" && !streamCompleted) {
          const tagStart = index;
          const tagEnd = text?.indexOf(">", tagStart + 1);
          if (tagEnd !== -1) {
            setTypedText((prevText) => {
              const replPrevTxt = replaceAll(prevText, streamAnimation, "");
              return (
                replPrevTxt +
                text?.substring(tagStart, tagEnd + 1) +
                streamAnimation
              );
            });
            index = tagEnd + 1; // Skip broken tag and move to next character
            return;
          }
        }

        // Normal typing animation
        setTypedText((prevText) => {
          const replPrevTxt = replaceAll(prevText, streamAnimation, "");
          return replPrevTxt + nextChar + streamAnimation;
        });
        index++;
      } else if (text && !streamCompleted) {
        onComplete?.();
        clearInterval(typingInterval); // Stop the typing animation
      }
    }, 1); // Adjust the typing speed by changing the delay (milliseconds)

    return (): void => clearInterval(typingInterval);
  }, [text]);

  return (
    <TypingAnimationStyled>
      <MarkdownContainerStyled>
        {parseStringToHtml(streamCompleted ? typedText || text : typedText)}
      </MarkdownContainerStyled>
      {showNextToText && nextToText}
    </TypingAnimationStyled>
  );
};

export default TypingAnimation;
