import styled from "styled-components";

export const ShareFormStyled = styled.div<{ isShowShareForm: boolean }>`
  .share-with-section {
    .ant-form-item-control {
      .ant-form-item-control-input-content {
        display: flex;
        gap: 11.2px;
      }

      .ant-select-multiple {
        width: ${({ isShowShareForm }): string =>
          isShowShareForm ? "531.2px" : "656.8px"};
      }
    }
  }
`;
