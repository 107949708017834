import styled from "styled-components";

export const ChoiceBoxStyled = styled.div<{
  width?: string;
}>`
  width: ${(props): string => props.width || "512px"};
  /* min-height: 100%; */
  border-style: solid;
  border-width: 1px;
  border-color: #c9c9c9;
  border-radius: 8px;
  padding: 13px;

  .item {
    padding-left: 10px;
    font-size: 14px;
  }

  .content {
    overflow-y: auto;
    max-height: 496px;
    margin-top: 10px;
  }

  .item_content {
    margin-left: 30px;
  }

  .checkbox {
    display: flex;
    flex-direction: column;
  }
`;
