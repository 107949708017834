import { SyntheticEvent } from "react";
import { CoreNodesCircleIcon } from "../../../../../../components";

import Flex from "../../../../../../components/flex";
import { useOpenDrawer } from "../../../../../../customhooks";

import { ColumnInfoContentStyled } from "./columninfocontent.styles";
import { ColumnInfoContentPropsType } from "./columninfocontent.types";

import { redirectCircledIcon } from "../../../../../../svgs";
import { HorizontalDividerStyled } from "../../../../../../components/dividers/dividers.styles";
import { ColRefDrawerProps } from "../../../../../../drawerviews/colrefdrawer/colrefdrawer.types";

const redirectIcon = redirectCircledIcon("20px", "20px");

const ColumnInfoContent = (props: ColumnInfoContentPropsType): JSX.Element => {
  const { col } = props;
  const openDrawer = useOpenDrawer();

  const onNodeClick = (e: SyntheticEvent, id?: string): void => {
    openDrawer({
      drawerId: "column_ref",
      visible: true,
      drawerProps: { id, drawerStartFromTop: true } as ColRefDrawerProps,
      isStartFromTop: true,
      zIndex: 100001,
    });
    e?.stopPropagation();
  };

  return (
    <ColumnInfoContentStyled className="ColumnInfoContentStyled">
      <div className="main-content">
        <Flex
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Flex
            alignItems="flex-start"
            justifyContent="space-between"
            customClass="main-group"
          >
            <Flex
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              customClass="title-main"
            >
              <CoreNodesCircleIcon nodeType="COL" marginRight="12px" />
              <div>{col?.field_display_name}</div>
            </Flex>
            <div
              role="button"
              className="icon-datatype"
              onClick={(e): void => onNodeClick(e, col?.field_id)}
            >
              {redirectIcon}
            </div>
          </Flex>

          <Flex direction="row" customClass="group">
            <div className="title">Meta Score:</div>
            <div className="value">{col?.field_meta_score}</div>
          </Flex>

          <Flex direction="row" customClass="group" alignItems="flex-start">
            <div className="title">Description:</div>
            <div className="value">{col?.field_desc}</div>
          </Flex>

          <Flex direction="row" customClass="group">
            <div className="title">Data Type:</div>
            <div className="value">{col?.field_datatype}</div>
          </Flex>

          <HorizontalDividerStyled marginBottom="10px" />

          <Flex direction="row" customClass="group">
            <div className="title">Entity:</div>
            <div className="value">{col?.field_entity_name}</div>
          </Flex>

          <Flex direction="row" customClass="group">
            <div className="title">Column Type:</div>
            <div className="value">{col?.field_type}</div>
          </Flex>

          <Flex direction="row" customClass="group">
            <div className="title">Column Sub-type:</div>
            <div className="value">{col?.field_sub_type}</div>
          </Flex>

          <Flex direction="row" customClass="group">
            <div className="title">Min-Max:</div>
            <div className="value">{`${col?.col_min_value} - ${col?.col_max_value}`}</div>
          </Flex>
        </Flex>
      </div>
    </ColumnInfoContentStyled>
  );
};

export default ColumnInfoContent;
