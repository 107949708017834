import { useFormContext } from "react-hook-form";
import { useCallback, useState } from "react";
import { SelectWithSubOptionsType } from "./selectwithsuboptions.types";

import { SelectFieldOption } from "../formfields/selectfield/selectfield.types";

import { SelectWithSubOptionsWrapperStyled } from "./selectwithsuboptions.styles";

import { SelectField } from "../formfields";
import { getObjectKeys, selectFilterOption } from "../../utils";

// fix type, use indexes
import { SubPanelRenderer, TagRenderer } from "./comp";
import { getBaseValue } from "../modalwrapper/modals/widgetcreationwizard";

const SelectWithSubOptions = (props: SelectWithSubOptionsType): JSX.Element => {
  const [showSubPanel, setShowSubPanel] = useState(false);
  const [
    selectedOptionSubpanelOptions,
    setSelectedOptionSubpanelOptions,
  ] = useState<SelectFieldOption[]>();

  const { setValue, control, watch } = useFormContext();

  const {
    options = [],
    subOptionsMap,
    placeholder = "Select",
    name = "",
    noOfOptionsAllowed,
    width,
    subpanelHeading,
    propOnChange,
    fieldId,
  } = props;

  const isSingleSelect = noOfOptionsAllowed === 1;

  const currentValues: string[] = watch(name) || [];

  const onSelectOptionChange = useCallback(
    (option: string[], fullObjectOfOptions) => {
      const optionsLength = fullObjectOfOptions?.length || 0;
      const recentlyAddedOption = fullObjectOfOptions?.[optionsLength - 1];

      const isRecentlyAddedOptionHasSubMenu =
        recentlyAddedOption?.isSubMenuExist || false;

      const selectedMenuItemValue = recentlyAddedOption?.value || "";

      // this check is to open sub option panel for those who have
      if (isRecentlyAddedOptionHasSubMenu) {
        setShowSubPanel(true);

        const selectedMenuItemOptions =
          subOptionsMap?.[selectedMenuItemValue]?.options || [];

        setSelectedOptionSubpanelOptions(selectedMenuItemOptions);
        // remove this current selected option until user havent selected the sub panel option
        const filterCurrentValues = option?.filter(
          (value) => value !== selectedMenuItemValue
        );

        setValue(name, filterCurrentValues);
      } else {
        setValue(name, option);
        setShowSubPanel(false);
        setSelectedOptionSubpanelOptions([]);
      }

      propOnChange?.();
    },
    [name, subOptionsMap]
  );

  const onSubOptionSelect = useCallback(
    (option) => (): void => {
      // I am getting my option as 113-dly

      if (isSingleSelect) {
        // if single select store parent + submenuItemid,
        // why parent? because main option panel has to highlight select option
        // why submenuItemid? so that sub menu item is also highlighted of that parent
        const parentId = getBaseValue(option);
        setValue(name, [parentId, option]);
      } else {
        const selectedMenuSubOptionsKeys = selectedOptionSubpanelOptions?.map(
          (option) => option?.value
        );

        // this is to make sub panel menu items as single select
        // by filtering all parent-id values

        const filteredValues = currentValues?.filter(
          (value: string) => !selectedMenuSubOptionsKeys?.includes(value)
        );

        const parentId = getBaseValue(option);

        setValue(name, [...filteredValues, parentId, option]);
      }
    },
    [
      name,
      isSingleSelect,
      noOfOptionsAllowed,
      currentValues,
      selectedOptionSubpanelOptions,
    ]
  );

  const onOptionDeselected = useCallback(
    (option): void => {
      if (isSingleSelect) {
        // if it is single select and user deselect an option then value will be empty
        setValue(name, []);
      } else {
        // else remove the base identifier + parentid-submenuitemId
        const baseIdentifier = getBaseValue(option);

        const filterDeselectedOption = currentValues?.filter(
          (item: string) => item !== option
        );

        const filterBaseOption = filterDeselectedOption?.filter(
          (item: string) => item !== baseIdentifier
        );
        setValue(name, filterBaseOption);
      }
    },
    [name, isSingleSelect, currentValues]
  );

  const selectedOptionSubpanelOptionsLength =
    selectedOptionSubpanelOptions?.length || 0;

  return (
    <SelectWithSubOptionsWrapperStyled
      isSubPanelOpen={!!selectedOptionSubpanelOptionsLength}
    >
      <SelectField
        setValue={setValue}
        control={control}
        name={name}
        options={options}
        isAllowClear={false}
        filterOption={selectFilterOption}
        showSearch
        propOnChange={onSelectOptionChange}
        mode="multiple"
        isUseGlobalStyle={false}
        onDeselect={onOptionDeselected}
        width={width}
        placeholder={placeholder}
        tagRender={(tagRendererProps): JSX.Element => (
          <TagRenderer
            tagRendererProps={tagRendererProps as SelectFieldOption}
            subOptionsMap={subOptionsMap}
          />
        )}
        dropdownRender={(menu): JSX.Element => {
          return (
            <div className="custom-options-renderer">
              {menu}
              {showSubPanel && (
                <SubPanelRenderer
                  isSubPanelOpen={!!selectedOptionSubpanelOptionsLength}
                  subpanelHeading={subpanelHeading}
                  selectedOptionSubpanelOptions={selectedOptionSubpanelOptions}
                  onSubOptionSelect={onSubOptionSelect}
                  currentValues={currentValues}
                />
              )}
            </div>
          );
        }}
        id={fieldId}
      />
    </SelectWithSubOptionsWrapperStyled>
  );
};

export default SelectWithSubOptions;
