import { createContext, useContext, useReducer } from "react";

import {
  GatewayStatusContextActions,
  GatewayStatusContextProps,
  GatewayStatusContextState,
} from "./gatewaystatuscheckcontext.types";

import { LOCAL_STORAGE_CONSTANTS } from "../../constants";
import { jsonParse, jsonStringify } from "../../utils";

const defaultState: GatewayStatusContextState = {
  listOfGateways: jsonParse(
    localStorage.getItem(LOCAL_STORAGE_CONSTANTS.list_of_gateways)
  )?.map((item: GatewayStatusContextState["listOfGateways"][number]) => ({
    ...item,
    sawsCommunicationKey: atob(item?.sawsCommunicationKey || ""),
  })),
  isFetchingGatewaysList: false,
  showGatewayDownPrompt: false,
  currentGatewayId: "",
};

const GatewayStatusContext = createContext<GatewayStatusContextProps>({
  state: defaultState,
  dispatch: (value: GatewayStatusContextActions) => {
    console.error("Your dispatcher not recieved by reducer", value);
  },
});

export const useGatewayStatusContext = (): GatewayStatusContextProps => {
  return useContext(GatewayStatusContext);
};

const gatewayContextReducer = (
  state: GatewayStatusContextState,
  actions: GatewayStatusContextActions
): GatewayStatusContextState => {
  const updLocalStorageState = (updState: GatewayStatusContextState): void => {
    localStorage.setItem(
      LOCAL_STORAGE_CONSTANTS.list_of_gateways,
      jsonStringify(
        updState?.listOfGateways?.map((item) => ({
          ...item,
          sawsCommunicationKey: btoa(item?.sawsCommunicationKey || ""),
        }))
      )
    );
  };

  const commonPropertiesForCloudSaws = {
    isPingcheckInProgress: false,
    isGatewayRunning: true,
  };

  switch (actions.type) {
    case "START_FETCHING_DETAILS": {
      const updState: GatewayStatusContextState = {
        ...state,
        isFetchingGatewaysList: true,
        showGatewayDownPrompt: false,
        currentGatewayId: "",
      };
      updLocalStorageState(updState);

      return updState;
    }

    case "STOP_FETCHING_DETAILS": {
      const updState: GatewayStatusContextState = {
        ...state,
        isFetchingGatewaysList: false,
        showGatewayDownPrompt: false,
        currentGatewayId: "",
      };
      updLocalStorageState(updState);
      return updState;
    }
    case "UPDATE_GATEWAYS_LIST": {
      const updState: GatewayStatusContextState = {
        ...state,
        listOfGateways: actions?.listOfGateways?.map((item) => ({
          ...item,
          ...(item?.isCloudGateway && commonPropertiesForCloudSaws),
        })),
        showGatewayDownPrompt: false,
        currentGatewayId: "",
      };

      updLocalStorageState(updState);
      return updState;
    }
    case "UPDATE_GATEWAY_PING_CHECK_LOADING": {
      const updState: GatewayStatusContextState = {
        ...state,
        listOfGateways: state?.listOfGateways?.map((item) => ({
          ...item,
          isPingcheckInProgress:
            item?.id === actions?.id
              ? actions?.isLoading
              : item?.isPingcheckInProgress,
          ...(item?.isCloudGateway && commonPropertiesForCloudSaws),
        })),
        showGatewayDownPrompt: false,
        currentGatewayId: "",
      };

      updLocalStorageState(updState);
      return updState;
    }
    case "UPDATE_GATEWAY_PING_CHECK_STATUS": {
      const updState: GatewayStatusContextState = {
        ...state,
        listOfGateways: state?.listOfGateways?.map((item) => ({
          ...item,
          isGatewayRunning:
            item?.id === actions?.id ? actions?.status : item?.isGatewayRunning,
          isPingcheckInProgress:
            item?.id === actions?.id
              ? actions?.isLoading
              : item?.isPingcheckInProgress,
          ...(item?.isCloudGateway && commonPropertiesForCloudSaws),
        })),
        showGatewayDownPrompt: false,
        currentGatewayId: "",
      };
      updLocalStorageState(updState);
      return updState;
    }
    case "SHOW_GATEWAY_DOWN_PROMPT": {
      const updState: GatewayStatusContextState = {
        ...state,
        showGatewayDownPrompt: true,
        currentGatewayId: actions?.id,
      };

      updLocalStorageState(updState);
      return updState;
    }
    case "HIDE_GATEWAY_DOWN_PROMPT": {
      const updState: GatewayStatusContextState = {
        ...state,
        showGatewayDownPrompt: false,
        currentGatewayId: "",
        listOfGateways: state?.listOfGateways?.map((item) => ({
          ...item,
          ...(item?.id === state?.currentGatewayId && {
            isGatewayRunning: false,
            isPingcheckInProgress: false,
          }),
          ...(item?.isCloudGateway && commonPropertiesForCloudSaws),
        })),
      };

      updLocalStorageState(updState);
      return updState;
    }
    default: {
      const updState: GatewayStatusContextState = state;
      updLocalStorageState(updState);

      return updState;
    }
  }
};

export const GatewaysStatusesProvider = (props: any): JSX.Element => {
  const { children } = props;
  const [state, dispatch] = useReducer(gatewayContextReducer, defaultState);

  return (
    <GatewayStatusContext.Provider value={{ state, dispatch }}>
      {children}
    </GatewayStatusContext.Provider>
  );
};
