import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback } from "react";

import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

import { useHistory } from "react-router";
import { useRequestWithMethod } from "../../../api";

import { Button } from "../../../components";
import { FormItemStyled, FormStyled } from "../../../components/form";

import { InputField, TextAreaField } from "../../../components/formfields";
import StateHandler from "../../../components/statehandler/statehandler";

import { APP_ROUTES, ELEMENT_IDS } from "../../../constants";
import { API_CONFIG } from "../../../constants/apiconfig";

import { useCancelModal, useGetAppState } from "../../../customhooks";
import { SourceTypeParserReturnType } from "../../../parsers/sources/sourcesparser.types";

import { updatePostLoginUserInfoData } from "../../../utils";
import { addSourceSchema } from "../../../utils/schemas/sourceschemas";

import { AddSourceFormStyled } from "../addsourceform.styled";
import { AddSourceFormType } from "../addsourceform.types";

const api = API_CONFIG?.get_all_data_sources?.url;

const {
  datsrc_src_add_sav: DATSRC_SRC_ADD_SAV,
  datsrc_src_add_srcdesc: DATSRC_SRC_ADD_SRCDESC,
  datsrc_src_add_srcnam: DATSRC_SRC_ADD_SRCNAM,
} = ELEMENT_IDS;

const AddSourceFieldForm = (): JSX.Element => {
  const history = useHistory();
  const queryClient = useQueryClient();

  const {
    modal: { modalProps = {} },
  } = useGetAppState();

  const { selectedSource } = modalProps as {
    selectedSource: SourceTypeParserReturnType;
  };

  const onCancel = useCancelModal();

  const isExcelSourceSelected = selectedSource?.id === "EXL";

  const isRestDataSourceSelected = selectedSource?.id === "RST";

  const onSuccess = useCallback(
    (response) => {
      if (response?.data?.length) {
        queryClient?.setQueryData(api, (oldData: any) => {
          return {
            ...oldData,
            data: [...oldData?.data, ...response?.data],
          };
        });

        onCancel();

        updatePostLoginUserInfoData({ is_src_exists: true });

        if (isExcelSourceSelected) {
          history.push(
            `${APP_ROUTES.private_routes.sources}/${response?.data[0]?.SRC_ID}/files`
          );
        } else if (isRestDataSourceSelected) {
          history.push(
            `${APP_ROUTES.private_routes.sources}/${response?.data[0]?.SRC_ID}/chat-bots`
          );
        } else {
          history.push({
            pathname: `${APP_ROUTES.private_routes.sources}/${response?.data[0]?.SRC_ID}/settings/connection`,
            state: { isEdit: true },
          });
        }
      }
    },
    [selectedSource]
  );

  const { isLoading, error, onExecuteRequest } = useRequestWithMethod(
    "add_source",
    undefined,
    true,
    onSuccess
  );

  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<AddSourceFormType>({
    defaultValues: {
      source_type: selectedSource?.displayName || "",
      source_name: "",
      source_description: "",
    },
    resolver: yupResolver(addSourceSchema),
    mode: "onChange",
  });

  const onSubmit = useCallback((values) => {
    onExecuteRequest({
      type: selectedSource?.id,
      name: values?.source_name,
      description: values?.source_description,
    });
  }, []);

  return (
    <StateHandler isFetching={isLoading} error={error} isModal>
      <AddSourceFormStyled isFormContainsTwoItem>
        <div className="second-screen">
          <FormStyled
            paddingLeft="155px"
            onFinish={handleSubmit(onSubmit) as any}
            isItemColumnLayout
          >
            <div className="scroll-sec">
              {!isExcelSourceSelected && (
                <FormItemStyled label="Source Type" required>
                  <InputField
                    control={control}
                    name="source_type"
                    disabled
                    width="410px"
                  />
                </FormItemStyled>
              )}
              <FormItemStyled
                label={isExcelSourceSelected ? "Folder Name" : "Source Name"}
                required
              >
                <InputField
                  control={control}
                  name="source_name"
                  width="410px"
                  id={DATSRC_SRC_ADD_SRCNAM}
                />
              </FormItemStyled>
              <FormItemStyled
                label={
                  isExcelSourceSelected
                    ? "Folder Description"
                    : "Source Description"
                }
              >
                <TextAreaField
                  control={control}
                  name="source_description"
                  maxLength={1000}
                  width="410px"
                  id={DATSRC_SRC_ADD_SRCDESC}
                />
              </FormItemStyled>
            </div>
            <FormItemStyled
              label=""
              className="form-actions-sec"
              marginBottom="0px"
            >
              <Button id="cancel" width="74px" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                id="primary"
                width="74px"
                marginLeft="8px"
                htmlType="submit"
                disabled={!isValid}
                elementId={DATSRC_SRC_ADD_SAV}
              >
                Save
              </Button>
            </FormItemStyled>
          </FormStyled>
        </div>
      </AddSourceFormStyled>
    </StateHandler>
  );
};

export default AddSourceFieldForm;
