// <--- Library Imports Start --->
import styled from "styled-components";
// <--- Library Imports End --->

// <--- Components Start --->
import { FlexStyled } from "../flex";
import { LinkButtonStyled } from "../linkbutton/linkbutton.styles";
// <--- Components End --->

// <--- Constants Start --->
import { ellipsis } from "../../styles/app.styles";
// <--- Constants End --->

export const CardWrapperStyled = styled.div`
  row-gap: 10px;
  width: 420px;

  padding: 19px;
  border-radius: 7px;
  background: ${({ theme }): string => theme?.bgColors?.WHITE_COLOR};
  border: 1px solid ${({ theme }): string => theme?.borderColors?.DARK_BLUE};
`;

export const CardHeadingStyled = styled.div`
  font-size: 19px;
  margin-bottom: 16px;
  color: ${({ theme }): string => theme?.textColors?.BLUE_116};
`;

export const CardContentWrapperStyled = styled.div`
  max-height: 202px;
  overflow-y: auto;
`;

export const CardContentStyled = styled(FlexStyled)`
  font-size: 12px;
  column-gap: 11px;
  margin-bottom: 5px;
  color: ${({ theme }): string => theme?.textColors?.GREY_32};

  svg {
    height: 15px;
    width: 13px;
  }

  &.list-item {
    margin-left: 25px;
  }
`;

export const CardTextWrapperStyled = styled.div`
  width: 320px;
  ${ellipsis}
`;

export const CardLinkButtonStyled = styled(LinkButtonStyled)`
  cursor: auto;
  font-size: 12px;
`;

export const DQRuleActionStatusTagWrapperStyled = styled.div`
  cursor: pointer;
`;
