import { useCallback } from "react";

import { useOpenDrawer } from "../../customhooks";
import { getDrawerIdBasedOnNodeType } from "../../utils";

import LinkButton from "../linkbutton/linkbutton";
import { LinkButtonToOpenRefViewProps } from "./linkbuttontoopenrefview.types";

function LinkButtonToOpenRefView(
  props: LinkButtonToOpenRefViewProps
): JSX.Element {
  const { nodeId, nodeType, ...res } = props;
  const openDrawer = useOpenDrawer();

  const onClick = useCallback(
    (event: any) => {
      event?.stopPropagation();
      const drawerId = getDrawerIdBasedOnNodeType(nodeType);
      drawerId &&
        openDrawer({ drawerId, drawerProps: { id: nodeId }, visible: true });
    },
    [props]
  );

  return (
    <LinkButton {...res} onClick={onClick}>
      {props?.children}
    </LinkButton>
  );
}

export default LinkButtonToOpenRefView;
