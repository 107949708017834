import { Checkbox } from "antd";
import styled from "styled-components";
import { CheckboxGroupFieldStyledProps } from "./checkboxgroupfield.types";

export const CheckboxGroupFieldStyled = styled(
  Checkbox.Group
)<CheckboxGroupFieldStyledProps>`
  .ant-checkbox {
    &:hover {
      .ant-checkbox-inner {
        border-color: ${({ theme }): string =>
          theme.borderColors.LIGHT_GREY_10};
      }
    }
    &.ant-checkbox-checked {
      &:after {
        transition: none;
        animation: none;
        animation-fill-mode: none;
      }
      .ant-checkbox-inner {
        background: #428bca;
        border-color: #428bca;
      }
    }

    .ant-checkbox-inner,
    .ant-checkbox-inner:after {
      transition: none;
    }
  }
`;
