import { playVideoIcon } from "../../../../svgs";
import { DvsumHelpItemCardStyled } from "./dvsumhelpitemcard.styles";
import { DvsumHelpItemCardProps } from "./dvsumhelpitemcard.types";

const DvsumHelpItemCard = (props: DvsumHelpItemCardProps): JSX.Element => {
  const { type = "gif", item, onClick } = props;

  const {
    title,
    article_type: articleType = "Quick Tip",
    video_duration: videoDuration = "",
    thumbnail_image_url: thumbnailImageUrl = "",
  } = item || {};

  return (
    <DvsumHelpItemCardStyled
      role="button"
      onClick={(): void => onClick?.({ category: articleType, item })}
    >
      <div className="thumbnail-wrapper">
        <img src={thumbnailImageUrl} height={40} />
        {type === "gif" && <span className="gif-tag">GIF</span>}
        {type === "video" && (
          <span className="video-tag">{playVideoIcon("25.6", "25.6")}</span>
        )}
      </div>
      <div className="item-name">{title}</div>
      {type === "video" && (
        <div className="video-duration">{videoDuration}</div>
      )}
    </DvsumHelpItemCardStyled>
  );
};

export default DvsumHelpItemCard;
