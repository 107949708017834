import styled from "styled-components";

export const ColumnChartStyled = styled.div`
  width: 100%;
  .ant-card {
    .ant-card-body {
      padding: 0px;
    }
  }

  .custom-tooltip {
    z-index: 100000 !important;
  }
`;

export const CustomTooltipStyled = styled.div`
  background: white;
  padding: 10px;
  border: 1px solid rgb(204, 204, 204);
  max-height: 500px;
  overflow: auto;

  /* .label {
    min-width: 80px;
    padding-top: 10px;
  } */

  .label-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 5px;
  }

  .content {
    display: flex;
    flex-direction: column;
  }

  .content-row {
    display: flex;
    column-gap: 4px;
  }

  .tooltip-button {
    margin-top: -10px;
  }
`;
