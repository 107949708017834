import moment from "moment";
import { SelectFieldOption } from "../../../../components/formfields/selectfield/selectfield.types";
import { ColumnDataType } from "../../../../parsers";
import { checkDataTypeOfColumn } from "../../../../utils";
import {
  AddValueRangeFormApiDataType,
  AddValueRangeFormType,
} from "./addvaluerangeform.types";

export const formDataToApiData = (
  formData: AddValueRangeFormType
): AddValueRangeFormApiDataType => {
  const columnDataType = formData?.column_data_type || "STR";

  const { isDateColumn } = checkDataTypeOfColumn(columnDataType);

  return {
    col_id: Number(formData?.column),
    col_date_type: formData?.column_data_type,
    rule_type: "VLR",
    range_type: formData?.specify,
    is_single_value: formData?.specify === "SV",
    min_value:
      (formData?.specify === "RV" || formData?.specify === "SV") &&
      formData?.min_value_type === "ABS"
        ? isDateColumn
          ? moment.utc(formData?.min_value).local().format("YYYY-MM-DD")
          : formData?.min_value
        : undefined,
    max_value:
      formData?.specify === "RV" && formData?.max_value_type === "ABS"
        ? isDateColumn
          ? moment.utc(formData?.max_value).local().format("YYYY-MM-DD")
          : formData?.max_value
        : undefined,
    min_oper: formData?.min_oper,
    max_oper: formData?.max_oper,
    min_value_type: formData?.min_value_type,
    max_value_type: formData?.max_value_type,
    min_col_id: formData?.min_column_id || undefined,
    max_col_id: formData?.max_column_id || undefined,
    match_case: !formData?.ignore_case,
    is_selection_valid: formData?.define === "valid",
    is_blanks_included: formData?.include_blanks,
    min_expr: formData?.min_expr,
    max_expr: formData?.max_expr,
    ref_dict_id: formData?.dictionary
      ? Number(formData?.dictionary)
      : undefined,
    ref_dict_name: formData?.dictionary_name,
    value_list:
      formData?.specify === "FD" ? formData?.select_values : undefined,
    ref_dict_map:
      formData?.specify === "RD"
        ? formData?.specify_fields?.map((item) => ({
            col_id: Number(item?.source_field),
            rd_field_name: item?.reference_field_id,
            rd_field_id: item?.reference_field_name,
            order: item?.order,
          }))
        : undefined,
  };
};

export const getSpecifyComparisonTypeSelectFieldOptions = (
  fieldDictionarySelect: SelectFieldOption[],
  watchColumnType: ColumnDataType
): {
  label: string;
  value: string;
}[] => {
  const commonOptions = [
    { value: "SV", label: "Single Value" },
    { value: "RV", label: "Range Value" },
  ];

  const specifiTypeOptions = [
    { value: "RD", label: "Ref Dictionary" },
    ...((!!fieldDictionarySelect?.length && [
      { value: "FD", label: "Field Dictionary" },
    ]) ||
      []),
  ];

  const typeSpecificOptions: Record<
    string,
    { label: string; value: string }[]
  > = {
    INT: commonOptions,
    DEC: commonOptions,
    STR: [...commonOptions, ...specifiTypeOptions],
    GENERIC: [...commonOptions, ...specifiTypeOptions],
  };

  return typeSpecificOptions[watchColumnType] || typeSpecificOptions.GENERIC;
};

export const getMinMaxReferneceColumns = (
  columnSelectOptions: SelectFieldOption[],
  watchColumnType: ColumnDataType
): SelectFieldOption[] => {
  const typeMapping: Record<string, string[]> = {
    INT: ["INT", "DEC", "NUM"],
    DEC: ["INT", "DEC", "NUM"],
    NUM: ["INT", "DEC", "NUM"],
    DTE: ["DTE", "TIM", "DT", "DTM"],
    DTM: ["DTE", "TIM", "DT", "DTM"],
    DT: ["DTE", "TIM", "DT", "DTM"],
    TIM: ["DTE", "TIM", "DT", "DTM"],
    STR: ["STR"],
  };

  const allowedTypes = typeMapping[watchColumnType] || [];
  return (
    columnSelectOptions?.filter((column) =>
      allowedTypes?.includes(column?.type as string)
    ) || []
  );
};
