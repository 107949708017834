import styled from "styled-components";
import { ellipsis, multilineEllipsis } from "../../styles/app.styles";

export const TagDetailsCardStyled = styled.div<{ showDetailInfo?: boolean }>`
  width: 364px;
  padding: 20px 28px 20px 20px;
  border-radius: 8px;
  border: solid 1px ${({ theme }): string => theme?.borderColors?.DARK_BLUE};
  background-color: ${({ theme }): string => theme?.bgColors?.WHITE_COLOR};

  .tag-details-card-row-1,
  .tag-details-card-row-3 {
    display: flex;
    align-items: center;
  }

  .tag-details-card-row-4 {
    display: flex;
  }

  .tag-details-card-row-1,
  .tag-details-card-row-2 {
    margin-bottom: 16px;
  }

  .tag-details-card-row-1 {
    color: ${({ theme }): string => theme?.textColors?.BLUE_116};
    .tag-icon {
      display: flex;
      margin-right: 12px;
    }
    .tag-name {
      font-size: 19px;
      font-family: OpenSansSemiBold;
      margin-right: 4px;
      ${ellipsis}
    }

    .tagset-name {
      font-size: 14.4px;
      min-height: 23px;
      padding-top: 1px;
      ${ellipsis}
    }
  }

  .tag-details-card-row-2 {
    color: ${({ theme }): string => theme?.textColors?.BLACK_COLOR};
    ${multilineEllipsis}
  }

  .tag-details-card-row-3 {
    margin-bottom: 12px;
    color: ${({ theme }): string => theme?.textColors?.GREY_32};
    font-size: 12px;
    > svg {
      margin-right: 12px;
    }

    .updated-by {
      color: ${({ theme }): string => theme?.textColors?.BLUE_116};
      margin: 0 3px;
    }
  }

  .tag-details-card-row-4 {
    color: ${({ theme }): string => theme?.textColors?.GREY_32};
    font-size: 12px;
    .notes-icon {
      margin-top: 2px;
      svg {
        margin-right: 12px;
      }
    }

    .notes {
      ${multilineEllipsis}
    }
  }

  .tag-details-card-row-2 {
    margin-bottom: ${({ showDetailInfo }): string =>
      showDetailInfo ? "16px" : "0px"};
  }
`;
