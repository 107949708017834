import styled from "styled-components";
import { ellipsis } from "../../../../../../styles/app.styles";

export const ExcelSourceFilesGridViewStyled = styled.div`
  .ag-root-wrapper {
    border: none;
    .ag-header {
      border: none;
      background: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
      .ag-header-cell {
        font-size: 15px;
        color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
        font-family: OpenSansSemiBold;
      }
    }

    .ag-body-viewport {
      .ag-cell {
        font-size: 15px;
        color: ${({ theme }): string => theme.textColors.GREY_25};
      }

      .ag-center-cols-clipper {
        min-height: 50px;
      }
    }
  }
`;

export const ActionRendrerStyled = styled.div<{ isDisabled: boolean }>`
  .table-actions-sec {
    cursor: not-allowed;
    .actions-container {
      ${({ isDisabled }): string =>
        isDisabled
          ? `
        pointer-events: none;
        opacity: 0.5;
        `
          : ""}
    }
  }
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UpdatedOnRendrersStyled = styled.div<{
  progress: number;
  isNewlyAdded: boolean;
}>`
  line-height: ${({ isNewlyAdded }): string => (isNewlyAdded ? `initial` : "")};
  margin-top: ${({ isNewlyAdded }): string => (isNewlyAdded ? `2px` : "")};

  .newly-added-sec {
    font-size: 13px;
    color: ${({ theme }): string => theme.textColors.GREY_24};
    .progress {
      width: 288px;
      height: 9px;
      background: ${({ theme }): string => theme.bgColors.BLUE_10};
      .inner {
        width: ${({ progress }): string => (progress ? `${progress}%` : "0px")};
        height: 9px;
        font-size: 0px;
        background: ${({ theme }): string => theme.bgColors.BLUE_116};
      }
    }
  }
`;

export const FileNameRendrerStyled = styled.div<{ isNewlyAdded: boolean }>`
  opacity: ${({ isNewlyAdded }): string => (isNewlyAdded ? `0.5` : "")};
  ${ellipsis}
  width: 100%;
`;
