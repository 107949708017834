import styled from "styled-components";

export const TableRecordCountHistoryChartStyled = styled.div`
  .ant-card-body {
    background: rgba(245, 245, 245, 0.5);
  }

  .recharts-legend-wrapper {
    top: -10px !important;
    left: unset !important;

    .legend-wrapper {
      margin: 0;
      justify-content: flex-start;
      max-width: 100%;
      padding: 0;
    }
  }
`;
