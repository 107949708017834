import styled from "styled-components";
import { CheckboxStyled } from "../../checkbox/checkbox.styles";
import { CheckboxFieldStyledProps } from "./checkboxfield.types";

export const CheckboxFieldStyled = styled(
  CheckboxStyled
)<CheckboxFieldStyledProps>`
  ${({ width }): string => `width:${width}!important`}
`;

export const CheckboxFieldDescStyled = styled.div`
  padding-left: 25px;
`;
