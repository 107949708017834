import { useCallback } from "react";
import CoreNodesCircleIcon from "../../components/corenodetypescircleicon";

import { SectionRowProps } from "../../components/sectionrow/sectionrow.types";
import { APP_ROUTES } from "../../constants";
import { TERM_SECTION_TITLES } from "../../constants/labelconstants";

import { useOpenLinkInNewTab } from "../../customhooks/useopenlinlinnewtab";
import { termDetailPageTabsValues } from "../../pages/termspage/termspage.constants";

import { TermReplacesAdditionalIno } from "../../parsers/termsparser/termsparser.types";

const {
  MANAGED_BY: { AUDIT_TRAIL },
  OVERVIEW: { REPLACES, REPLACED_BY },
} = TERM_SECTION_TITLES;

function ReplacesRendrer(item: SectionRowProps): JSX.Element {
  const openLinkInNewTab = useOpenLinkInNewTab();

  const onClick = useCallback(
    (e: any, id: string) => {
      openLinkInNewTab(
        e,
        `${APP_ROUTES.private_routes.terms}/${id}/${termDetailPageTabsValues.overview}`
      );
    },
    [item]
  );

  const additionalInfo = item?.additionalInfo as TermReplacesAdditionalIno;
  return (
    <div>
      {additionalInfo?.replaces?.length ? (
        additionalInfo?.replaces?.map((item, index) => (
          <div
            className="replace-rendrer"
            key={`term-ref-drawer-replaces-rendrer-${item?.id}-${index}`}
          >
            <CoreNodesCircleIcon
              nodeType="TRM"
              width="15px"
              height="15px"
              fontSize="10px"
            />
            <span role="button" onClick={(e): void => onClick(e, item?.id)}>
              {item.name}
            </span>
          </div>
        ))
      ) : (
        <div>--</div>
      )}
    </div>
  );
}

export const termRefRendrer: {
  [key: string]: (_item: SectionRowProps) => JSX.Element;
} = {
  [AUDIT_TRAIL]: (item) => (
    <div>
      {item?.value?.split(":")?.map((item, index) => (
        <div key={`at-key-${item}-${index}`}>{item}</div>
      ))}
    </div>
  ),
  [REPLACES]: (item) => <ReplacesRendrer {...item} />,
  [REPLACED_BY]: (item) => <ReplacesRendrer {...item} />,
};
