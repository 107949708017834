import { useCallback } from "react";
import { useQueryClient } from "react-query";
import { useRequestWithMethod } from "../../../../api";
import { API_CONFIG } from "../../../../constants/apiconfig";
import { CustomException } from "../../../../customexception";

const api = API_CONFIG.get_data_source?.url;

type State = { isLoading?: boolean; error?: CustomException; data?: any };

export function useSaveSourceSettings(
  apiName: keyof typeof API_CONFIG,
  sourceId: string,
  onSaveSuccess?: (_response: any) => void
): State & {
  onExecuteRequest: (
    _body?: any,
    _updatedUrlParam?: any[],
    _updatedOnSuccess?: (_response?: any) => void,
    _updatedApiName?: keyof typeof API_CONFIG
  ) => void;
} {
  const queryClient = useQueryClient();

  const onSuccess = useCallback(
    (response) => {
      if (response?.data?.length) {
        queryClient?.setQueryData(
          api?.replace("url_param", sourceId),
          (oldData: any) => ({
            ...oldData,
            data: [{ ...oldData.data[0], ...response?.data[0] }],
          })
        );
      }

      onSaveSuccess && onSaveSuccess(response);
    },
    [sourceId]
  );

  return useRequestWithMethod(
    apiName,
    [sourceId],
    true,
    (onSaveSuccess && onSuccess) || undefined
  );
}
