import { NodeType } from "../../app.types";
import { GovernanceViewFiltersParserReturnType } from "../../parsers";

import {
  FilterCriteriaParsedType,
  FiltersColumnParsedConfigurationType,
} from "../../parsers/tablepage/tablepageparser.types";
import { SelectFieldOption } from "../formfields/selectfield/selectfield.types";

export type SpecifyFilterCriteriaType = {
  column?: string;
  filter?: string;
  value?: string;
  second_value?: string;
  data_type?: string;
  date_type?: string;
  date_value?: string | Date;
  second_date_value?: string | Date;
  values_list?: string[];
  columnName?: string;
  valuesList?: string[];

  filter_type?: string;
  sql_expression?: string;
  next_operation?: string;
  rowId?: string;
};

export type SpecifyCriteriaReadModeType = {
  columnName?: string;
  filter?: string;
  value1?: string;
  value2?: string;
  valuesList?: string[];
};

export type LogiacalOperatorType = "AND" | "OR";

export type FilterCriteriaType = Array<{
  condition: SpecifyFilterCriteriaType[];
  next_operator?: string;
}>;

export type AddCriteria = {
  [key: string]: FilterCriteriaType;
};

export type CriteriaSelectionProps = {
  desc?: string;
};

export type CriteriaProps = {
  name: string;
  parsedFields?: FiltersColumnParsedConfigurationType[];
  parsedFilters?: GovernanceViewFiltersParserReturnType[];
  isEditMode: boolean;
  existingFilters: FilterCriteriaParsedType;
  onChangeFilterCriteria?: () => void;
  nodeType: NodeType;
  isFromGovViews?: boolean;
  parentNodeId?: string;
  isReadMode?:boolean
};

export type TypeOfInputField = "filter" | "field" | "criteriaType";

export type HandleInputChangeValue = (
  criteriaRowIndex: number,
  filterRowIndex?: number,
  updatedObject?: SpecifyFilterCriteriaType,
  type?: TypeOfInputField,
  selectFieldOption?: SelectFieldOption[],
  isSingleSelect?: boolean
) => void;

export type onResetFilterRowValues = (params: {
  criteriaRowIndex: number;
  filterRowIndex: number;
  typeOfInputField?: TypeOfInputField;
}) => void;

// VERIFY THIS
export const DUMMY_FILTER_CRITERIA = {
  column: undefined,
  filter: undefined,
  value: undefined,
  second_value: undefined,
  data_type: undefined,
  date_type: undefined,
  date_value: "",
  second_date_value: undefined,
  values_list: undefined,
  columnName: undefined,
  valuesList: undefined,
  filter_type: "CND",
  next_operation: "AND",
  sql_expression: "",
  field_id: undefined,
  field_type: undefined,
};

export type HandleAddFilterConditionType = (
  conditionType: "CND" | "EXP",
  criteriaRowIndex?: number
) => void;

export type HandleAddFilterConditionGroupType = (
  conditionType: "CND" | "EXP"
) => void;
