import { Tag } from "antd";
import styled from "styled-components";
import LinkButton from "../linkbutton/linkbutton";

export const SelectWithSubOptionsWrapperStyled = styled.div<{
  isSubPanelOpen: boolean;
}>`
  .custom-options-renderer {
    display: flex;
    flex-direction: row;
    width: 100%;

    .rc-virtual-list {
      width: ${({ isSubPanelOpen }): string =>
        isSubPanelOpen ? "50%" : "100%"};
    }
  }

  .ant-select-selection-item {
    svg {
      display: none;
    }
  }
  .ant-select-selection-overflow {
    row-gap: 8px;
  }

  .dvsum-select-field .ant-select-selector {
    max-height: 100px;
    /* padding: 10px; */
  }
`;
