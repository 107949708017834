import { APP_ROUTES } from "../constants";
import { SourceDetailPageParamViewKeys } from "../pages/sourcedetailpage";

export function getSourceDetailPageUrl(
  id: string,
  tab?: SourceDetailPageParamViewKeys
): string {
  const typedTab = tab || "overview";
  return `${APP_ROUTES.private_routes.sources}/${id}/${typedTab}`;
}
