import { format } from "date-fns";

export function utcToLocalDate(dateStr: string): Date {
  return new Date(`${dateStr}Z`);
}

export function formatUtcToLocalDate(
  dateStr: string,
  dateFormat: string
): string {
  try {
    return format(utcToLocalDate(dateStr), dateFormat);
  } catch {
    return "";
  }
}
