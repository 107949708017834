import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Button } from "../../../../../../../components";

import {
  FormItemStyled,
  FormStyled,
} from "../../../../../../../components/form";

import {
  useCancelModal,
  useGetAppState,
} from "../../../../../../../customhooks";

import { DynamicModalProps } from "./connectionsettingsdynamicmodal.types";

import { DataSourceFormMetaDataProps } from "../connectionsettingstabform/connectionsettingstabform.types";

import { ConnectionSettingsDynamicModalStyled } from "./connectionsettingsdynamicmodal.styles";
import { getItemValidator } from "../../../../../sourcedetailpage.utils";

import ConnectionSettingsTabFormGroup from "../connectionsettingstabformgroup/connectionsettingstabformgroup";
import { DynamicFormGroupProps } from "../connectionsettingstabformgroup/connectionsettingstabformgroup.types";
import { DynamicFormGroupItemProps } from "../connectionsettingstabformitem/connectionsettingstabformitem.types";

import StateHandler from "../../../../../../../components/statehandler/statehandler";
import { DynamicActionButton } from "../connectionsettingstabform/renderers";

const ConnectionSettingsDynamicModal = (): JSX.Element => {
  const [dialogError, setDialogError] = useState<any>(null);
  const {
    modal: { modalProps = {} },
  } = useGetAppState();

  const {
    // primaryButtonText,
    secondaryButtonText,
    primaryButtonActionConfig,
    formData,
    defaultValues,
    parentFormValuesGetter,
    parentFormValuesSetter,
    setFormFieldKeys: setParentFormFieldKeys,
    setFieldLogs: setParentFieldLogs,
  } = modalProps as DynamicModalProps;

  const formResolver = useMemo(() => {
    const validators = {
      ...formData
        ?.flatMap((item) => item.fields)
        .reduce(
          (obj, item) => ({
            ...obj,
            ...getItemValidator(item),
          }),
          {}
        ),
    };

    return yup.object().shape(validators);
  }, [formData]);

  const defaultDynamicFormValues = useMemo(() => {
    return formData
      ?.flatMap((item) => item.fields)
      ?.reduce(
        (obj, item) => ({
          ...obj,
          [item.name]: defaultValues?.[`${item?.name}` || ""],
        }),
        {}
      );
  }, [formData]);

  const form = useForm<any>({
    defaultValues: defaultDynamicFormValues,
    resolver: formResolver && yupResolver(formResolver),
    mode: "onChange",
  });

  const {
    formState: { isValid },
  } = form;

  const onCancel = useCancelModal();

  const [groupVisibility, setGroupVisibility] = useState<
    DynamicFormGroupProps["groupVisibility"]
  >({});

  const [formFieldKeys, setFormFieldKeys] = useState<
    DynamicFormGroupItemProps["formFieldKeys"]
  >({});

  const [alertsContent, setAlertsContent] = useState<
    DynamicFormGroupProps["alertsContent"]
  >({}); // key: fieldName, value: message to show in alert

  const [fieldLogs, setFieldLogs] = useState<string[]>([]);

  const [enableDisableElements, setEnableDisableElements] = useState<
    DynamicFormGroupProps["enableDisableElements"]
  >({});

  const [disabledValues, setDisabledValues] = useState<
    DynamicFormGroupProps["disabledValues"]
  >({});

  const formDynamicFields = useMemo(() => {
    return formData?.map((item: DataSourceFormMetaDataProps, index) => (
      <ConnectionSettingsTabFormGroup
        {...item}
        form={form}
        groupVisibility={groupVisibility}
        setGroupVisibility={setGroupVisibility}
        formFieldKeys={formFieldKeys}
        setFormFieldKeys={setFormFieldKeys}
        fieldLogs={fieldLogs}
        setFieldLogs={setFieldLogs}
        alertsContent={alertsContent}
        setAlertsContent={setAlertsContent}
        enableDisableElements={enableDisableElements}
        setEnableDisableElements={setEnableDisableElements}
        setDialogError={setDialogError}
        disabledValues={disabledValues}
        setDisabledValues={setDisabledValues}
        key={`connection-settings-dynamic-modal-field-${index}`}
      />
    ));
  }, [
    formData,
    groupVisibility,
    formFieldKeys,
    fieldLogs,
    alertsContent,
    enableDisableElements,
    disabledValues,
  ]);

  // const { isLoading, error, onExecuteRequest } = useRequestWithMethod(
  //   primaryButtonActionConfig?.method === "POST"
  //     ? "dynamic_form_url_post"
  //     : "dynamic_form_url_get",
  //   undefined,
  //   true
  // );

  // const onClickPrimaryBtn = useCallback(() => {
  //   handleButtonXHRRequest(onExecuteRequest);
  //   primaryButtonActionConfig;
  // }, []);

  return (
    <StateHandler error={dialogError} isModal>
      <ConnectionSettingsDynamicModalStyled>
        <FormStyled paddingLeft="126px" paddingRight="126px" isItemColumnLayout>
          <div className="form-body">{formDynamicFields}</div>
          <FormItemStyled
            label=""
            className="form-actions-sec"
            marginBottom="0px"
          >
            <Button id="cancel" width="78px" onClick={onCancel}>
              {secondaryButtonText || "Cancel"}
            </Button>
            <DynamicActionButton
              parentFormValuesGetter={parentFormValuesGetter}
              form={form}
              actionData={primaryButtonActionConfig}
              setFormFieldKeys={setParentFormFieldKeys}
              successCallback={onCancel}
              parentFormValuesSetter={parentFormValuesSetter}
              setDialogError={setDialogError}
              setFieldLogs={setParentFieldLogs}
              disabled={!isValid}
            />
            {/* <Button
            id="primary"
            width="78px"
            disabled={!isValid}
            onClick={onClickPrimaryBtn}
          >
            {primaryButtonText || "Save"}
          </Button> */}
          </FormItemStyled>
        </FormStyled>
      </ConnectionSettingsDynamicModalStyled>
    </StateHandler>
  );
};

export default ConnectionSettingsDynamicModal;
