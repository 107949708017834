import { toFixed } from "./tofixed";

export function shortNumberFormater(
  value: number | string,
  decimal = 2
): string | number {
  try {
    const numberConvertedValue = Number(value);
    const sign = Math.sign(numberConvertedValue);
    return Math.abs(Number(numberConvertedValue)) >= 1.0e9
      ? `${toFixed(
          sign * (Math.abs(Number(numberConvertedValue)) / 1.0e9),
          decimal
        )}B`
      : Math.abs(Number(numberConvertedValue)) >= 1.0e6
      ? `${toFixed(
          sign * (Math.abs(Number(numberConvertedValue)) / 1.0e6),
          decimal
        )}M`
      : Math.abs(Number(numberConvertedValue)) >= 1.0e3
      ? `${toFixed(
          sign * (Math.abs(Number(numberConvertedValue)) / 1.0e3),
          decimal
        )}K`
      : Math.abs(Number(numberConvertedValue));
  } catch {
    return "";
  }
}
