export const sourceScanResultPageBlankSlate = (
  <svg width="100%" height="100%" viewBox="0 0 1794 573" fill="none">
    <circle cx="17.5" cy="17.5" r="17.5" fill="#F0F0F0" />
    <rect x="45" y="4" width="246" height="27" rx="13.5" fill="#F0F0F0" />
    <rect y="47" width="611" height="19" rx="9.5" fill="#F0F0F0" />
    <rect y="78" width="611" height="19" rx="9.5" fill="#F0F0F0" />
    <rect
      width="1794"
      height="60"
      transform="translate(0 129)"
      fill="#F6F6F6"
    />
    <rect y="229" width="593" height="24" rx="12" fill="#F0F0F0" />
    <rect y="269" width="593" height="24" rx="12" fill="#F0F0F0" />
    <rect y="309" width="593" height="24" rx="12" fill="#F0F0F0" />
    <rect y="349" width="593" height="24" rx="12" fill="#F0F0F0" />
    <rect y="389" width="593" height="24" rx="12" fill="#F0F0F0" />
    <rect y="429" width="593" height="24" rx="12" fill="#F0F0F0" />
    <rect y="469" width="593" height="24" rx="12" fill="#F0F0F0" />
    <rect y="509" width="593" height="24" rx="12" fill="#F0F0F0" />
    <rect y="549" width="593" height="24" rx="12" fill="#F0F0F0" />
  </svg>
);
