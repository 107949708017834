import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback } from "react";

import * as yup from "yup";
import { useForm } from "react-hook-form";

import { RadioGroupField, TextAreaField } from "../../components/formfields";
import LinkButton from "../../components/linkbutton";

import { useCancelModal, useGetAppState, useSetData } from "../../customhooks";

import { crossIcon } from "../../svgs";
import { TrainbotShareConceptFormStyled } from "./trainbotshareconceptform.styles";

import { Button } from "../../components";

import {
  TrainbotShareConceptFormProps,
  TrainbotShareConceptFormType,
} from "./trainbotshareconceptform.types";

import { FormItemStyled, FormStyled } from "../../components/form";
import SuccessNotificationMessage from "../../components/successnotificationmessagerendrer";

import {
  getChatPrefrencesData,
  getDvSumInformativeText,
  openNotification,
  removeSpaces,
} from "../../utils";
import { ConceptLevelType } from "../../parsers";

import { RadioFieldType } from "../../components/formfields/radiofield/radiofield.types";
import { useRequestWithMethod } from "../../api";

import StateHandler from "../../components/statehandler/statehandler";
import { API_CONFIG } from "../../constants/apiconfig";

import { REQUIRED_MESSAGE } from "../../constants/formconstants";

const conceptSchema = yup.object().shape({
  desc: yup
    ?.string()
    ?.required(REQUIRED_MESSAGE)
    ?.test(
      "non-space-length",
      "The concept must be at least 10 characters.",
      (value) => {
        const valueWithoutSpaces = removeSpaces(value || "");
        return valueWithoutSpaces?.length >= 10;
      }
    ),
});

const TrainbotShareConceptForm = (): JSX.Element => {
  const { modal } = useGetAppState();

  const {
    ssa_cnp_mdl_cnp: ssaCnpMdlCnp,
    ssa_cnp_mdl_cnp_scp: ssaCnpMdlCnpScp,
    ssa_cnp_mdl_bot_lvl: ssaCnpMdlBotLvl,
    ssa_cnp_mdl_src_lvl: ssaCnpMdlSrcLvl,
    ssa_cnp_mdl_acc_lvl: ssaCnpMdlAccLvl,
  } = getDvSumInformativeText();

  const {
    is_descriptive_mode_enabled: isDescriptiveModeEnabled,
  } = getChatPrefrencesData();

  const {
    description = "",
    conceptScope = "ANL",
    analysisId = "",
    sourceId = "",
    sourceName = "",
    conceptId = "",
    currentBotId = "",
    currentBotSrcId = "",
  } = (modal?.modalProps || {}) as TrainbotShareConceptFormProps;

  const {
    control,
    watch,
    handleSubmit,
    formState: { isValid },
  } = useForm<TrainbotShareConceptFormType>({
    defaultValues: { desc: description, conceptScope },
    resolver: yupResolver(conceptSchema),
    mode: "onChange",
  });

  const { desc: watchedDesc = "", conceptScope: watchedConceptScope } = watch();

  const isAnythingChanged = !(
    watchedDesc === description && conceptScope === watchedConceptScope
  );

  const disableSaveBtn = !watchedDesc || !isAnythingChanged || !isValid;

  const CONCEPT_SCOPE_OPTIONS: RadioFieldType["options"] = [
    {
      label: ssaCnpMdlBotLvl?.field_name || "",
      labelDesc: ssaCnpMdlBotLvl?.description || "",
      value: "ANL" as ConceptLevelType,
      key: "concept-scope-key-1",
    },
    {
      label: ssaCnpMdlSrcLvl?.field_name || "",
      value: "SRC" as ConceptLevelType,
      labelDesc: isDescriptiveModeEnabled
        ? `Only bots under this source (${sourceName}) can access the concept.`
        : "",
      key: "concept-scope-key-2",
    },
    {
      label: ssaCnpMdlAccLvl?.field_name || "",
      value: "ACT" as ConceptLevelType,
      labelDesc: ssaCnpMdlAccLvl?.description || "",
      key: "concept-scope-key-3",
    },
  ];

  const onCancelModal = useCancelModal();
  const onSetData = useSetData();

  const onSuccess = useCallback(
    (res) => {
      onSetData(
        API_CONFIG.get_bot_concepts,
        res.data?.[0],
        [currentBotId, currentBotSrcId],
        // [analysisId, sourceId],
        {
          action: conceptId ? "UPDATE" : "CREATE",
          key: "CNP_ID",
        }
      );

      openNotification(
        <SuccessNotificationMessage
          message={`Concept ${conceptId ? "updated" : "added"} successfully`}
          showSuccess
        />
      );

      onCancelModal?.();
    },
    [
      onCancelModal,
      conceptId,
      analysisId,
      sourceId,
      currentBotId,
      currentBotSrcId,
    ]
  );

  const {
    onExecuteRequest: onExecuteSaveConceptRequest,
    isLoading: saveConceptsIsLoading,
    error: saveConceptsError,
  } = useRequestWithMethod(
    "save_bot_concepts",
    [analysisId, sourceId],
    true,
    onSuccess
  );

  const {
    onExecuteRequest: onExecuteUpdateConceptRequest,
    isLoading: updateConceptsIsLoading,
    error: updateConceptsError,
  } = useRequestWithMethod(
    "update_bot_concepts",
    [analysisId, sourceId],
    true,
    onSuccess
  );

  const onSubmit = useCallback(
    (formData: TrainbotShareConceptFormType) => {
      const body = {
        src_id: parseInt(sourceId || ""),
        da_id: parseInt(analysisId || ""),
        concept_json: [
          {
            ...(conceptId && { cnp_id: conceptId }),
            cnp_text: formData?.desc || "",
            cnp_level: formData?.conceptScope || "ANL",
          },
        ],
      };

      if (conceptId) {
        onExecuteUpdateConceptRequest(body);
      } else {
        onExecuteSaveConceptRequest(body);
      }
    },
    [onCancelModal, conceptId]
  );

  return (
    <StateHandler
      isFetching={saveConceptsIsLoading || updateConceptsIsLoading}
      error={saveConceptsError || updateConceptsError}
      isModal
    >
      <TrainbotShareConceptFormStyled>
        <div className="sahre-concept-header">
          <LinkButton onClick={onCancelModal}>
            {crossIcon("19.6px", "19.6px")}
          </LinkButton>
        </div>
        <FormStyled layout="vertical">
          <div className="share-concept-content-wrapper">
            <div className="share-concept-form-sec">
              <div>
                <div className="share-concept-title">
                  {ssaCnpMdlCnp?.field_name || ""}
                </div>
                <div>
                  Use this mode to share the concept or objective that I can
                  learn from to answer the question.
                </div>
                <div>
                  For e.g. If I am a sales bot, you can tell me about key
                  objectives that a sales rep would use this bot for. The more
                  specific you are, the better.
                </div>
                <TextAreaField
                  name="desc"
                  control={control}
                  height="248px"
                  placeholder={ssaCnpMdlCnp?.placeholder || ""}
                />
                <FormItemStyled label={ssaCnpMdlCnpScp?.field_name || ""}>
                  <RadioGroupField
                    value={watchedConceptScope}
                    control={control}
                    name="conceptScope"
                    options={CONCEPT_SCOPE_OPTIONS}
                    direction="column"
                  />
                </FormItemStyled>
              </div>
            </div>

            <div className="share-concept-right-banner">
              <div className="header-sec">
                <div className="heading">How the concept will be used?</div>
                <div className="desc">
                  The system will use a maximum of 20 concepts to answer your
                  questions.
                </div>
              </div>

              <div className="search-priority-sec">
                <div className="sel-crit-heading">Selection Criteria:</div>
                <ul className="search-priority-steps-list">
                  <li className="search-priority-step">
                    Initially, the concepts will be sourced from the bot level.
                  </li>
                  <li className="search-priority-step">
                    Next, the concepts will be checked from the source level.
                  </li>
                  <li className="search-priority-step">
                    Finally, the concepts will be checked from the account
                    level.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <FormItemStyled
            label=""
            className="form-actions-sec"
            marginBottom="0px"
          >
            <Button id="cancel" width="74px" onClick={onCancelModal}>
              Cancel
            </Button>
            <Button
              id="primary"
              width="74px"
              marginLeft="8px"
              htmlType="submit"
              disabled={disableSaveBtn}
              onClick={handleSubmit(onSubmit)}
              tooltipProps={{
                title: !watchedDesc
                  ? "Please fill the concept field."
                  : !isAnythingChanged
                  ? "You haven't changed anything."
                  : "",
                placement: "topLeft",
              }}
            >
              Save
            </Button>
          </FormItemStyled>
        </FormStyled>
      </TrainbotShareConceptFormStyled>
    </StateHandler>
  );
};

export default TrainbotShareConceptForm;
