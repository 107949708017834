import { Button, Tooltip } from "antd";
import { useCallback, useMemo, useState } from "react";
import { useController } from "react-hook-form";

import { angleDown, angleUp } from "../../svgs";
import { Input } from "../inputs";

import {
  TimePickerInputStyled,
  TimePickerPanelStyled,
  TimePickerStyled,
} from "./timepicker.styles";

import {
  TimePickerProps,
  TimeState,
  TooltipContentProps,
} from "./timepicker.types";

function convertToDoubleDigit(num: number): string {
  return num < 10 ? `0${num}` : `${num}`;
}

function calculateDate(
  hours: number,
  minutes: number,
  period: string,
  dateRef: Date
): Date {
  const newHours =
    (period === "AM" || period === "PM") && hours === 12 ? 0 : hours;

  return new Date(
    dateRef.getFullYear(),
    dateRef.getMonth(),
    dateRef.getDate(),
    newHours + (period === "PM" ? 12 : 0),
    minutes
  );
}

function roundToMultipleOf(val: number, multipleOf = 5): number {
  if (!val) {
    return 0;
  }

  return Math.ceil(val / multipleOf) * multipleOf;
}

function TooltipContent({
  setState,
  state,
  updateValue,
}: TooltipContentProps & {
  updateValue: (_hours: number, _minutes: number, _period: string) => void;
}): JSX.Element {
  const onHoursChange = useCallback(
    (isIncrement?: boolean) => {
      const hours = isIncrement
        ? state.hours < 12
          ? state.hours + 1
          : 1
        : state.hours - 1 === 0
        ? 12
        : state.hours - 1;

      const period = isIncrement
        ? state.hours + 1 === 12
          ? state.period === "AM"
            ? "PM"
            : "AM"
          : state.period
        : state.hours - 1 === 11
        ? state.period === "AM"
          ? "PM"
          : "AM"
        : state.period;

      const minutes = roundToMultipleOf(state?.minutes);

      updateValue(hours, minutes, period);

      setState((st) => ({
        ...st,
        minutes,
        hours,
        period,
      }));
    },
    [state]
  );

  // const getMinutes = (minutes: number, increment: boolean):void => {
  //   if (increment) {
  //     if(minutes) {

  //     }
  //   }
  // };

  const onMinutesChange = useCallback(
    (isIncrement?: boolean) => {
      let minutes = roundToMultipleOf(
        isIncrement
          ? state.minutes < 60
            ? state.minutes + 5
            : 5
          : state.minutes !== 0
          ? state.minutes - 5
          : 55
      );

      const toggleMeridian = (): "AM" | "PM" => {
        return state?.period === "PM" ? "AM" : "PM";
      };

      const getPeriodOnMinuteChange = (
        currentPeriod: "AM" | "PM",
        hours: number,
        addition: number
      ): "AM" | "PM" => {
        return (addition === 1 && hours === 12) ||
          (addition === -1 && hours === 11)
          ? toggleMeridian()
          : currentPeriod;
      };

      const hoursAddition = isIncrement
        ? minutes > 59
          ? 1
          : 0
        : state?.minutes - 5 < 0
        ? -1
        : 0;
      let hours = state?.hours + hoursAddition;

      const period = getPeriodOnMinuteChange(
        state?.period,
        hours,
        hoursAddition
      );

      minutes = minutes > 59 ? 0 : minutes;
      hours = hours > 12 ? 1 : hours <= 0 ? 12 : hours;

      updateValue(hours, minutes, period);

      setState((st) => ({
        ...st,
        hours,
        minutes,
        period,
      }));
    },
    [state]
  );

  const onPeriodChange = useCallback(() => {
    const period = state.period === "PM" ? "AM" : "PM";
    const minutes = roundToMultipleOf(state?.minutes);

    updateValue(state?.hours, minutes, period);
    setState((st) => ({ ...st, minutes, period }));
  }, [state]);

  return (
    <TimePickerPanelStyled className="timepicker-content">
      <div className="timepicker-header">
        <div role="button" onClick={(): void => onHoursChange(true)}>
          {angleUp()}
        </div>
        <span className="seperator" />
        <div role="button" onClick={(): void => onMinutesChange(true)}>
          {angleUp()}
        </div>
        <span className="seperator" />
        <div role="button" onClick={onPeriodChange}>
          {angleUp()}
        </div>
      </div>
      <div className="timepicker-body">
        <div>
          <Input
            height="34px"
            value={convertToDoubleDigit(state.hours)}
            onChange={(e): void =>
              setState((st) => {
                const inputHours = Number(e?.target?.value);
                updateValue(
                  inputHours <= 12 ? inputHours : 1,
                  st.minutes,
                  st.period
                );
                return {
                  ...st,
                  hours: inputHours <= 12 ? inputHours : 1,
                };
              })
            }
          />
        </div>
        <span className="seperator">:</span>
        <div>
          <Input
            height="34px"
            value={convertToDoubleDigit(state.minutes)}
            onChange={(e): void =>
              setState((st) => {
                const inputMinutes = Number(e?.target?.value);
                updateValue(
                  st.hours,
                  inputMinutes <= 60 ? inputMinutes : 1,
                  st.period
                );

                return {
                  ...st,
                  minutes: inputMinutes <= 60 ? inputMinutes : 1,
                };
              })
            }
          />
        </div>
        <span className="seperator" />
        <div>
          <Input height="34px" value={state.period} />
        </div>
      </div>
      <div className="timepicker-footer">
        <div role="button" onClick={(): void => onHoursChange()}>
          {angleDown()}
        </div>
        <span className="seperator" />
        <div role="button" onClick={(): void => onMinutesChange()}>
          {angleDown()}
        </div>
        <span className="seperator" />
        <div role="button" onClick={onPeriodChange}>
          {angleDown()}
        </div>
      </div>
    </TimePickerPanelStyled>
  );
}

function getMeridiem(hours: number): TimeState["period"] {
  return hours >= 12 ? "PM" : "AM";
}

function TimePicker({
  name = "",
  defaultValue,
  value = defaultValue || new Date(),
  control,
  disabled,
  ...props
}: TimePickerProps): JSX.Element {
  const { showClearButton = true, suffixIcon, width, height } = props;
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);

  const {
    field: { onChange },
  } = useController({
    name,
    control,
  });

  const updateValue = useCallback(
    (hours, minutes, period) => {
      onChange(calculateDate(hours, minutes, period, value));
    },
    [value]
  );

  const [state, setState] = useState<TimeState>({
    hours: value?.getHours() % 12 || 12,
    minutes: value?.getMinutes(),
    period: getMeridiem(value?.getHours()),
    clear: false,
  });

  const onReset = useCallback(() => {
    setState((st) => ({ ...st, clear: true }));
  }, [state]);

  const onVisibleChange = useCallback(
    (visibility) => {
      !visibility && setState((st) => ({ ...st, clear: false }));
    },
    [state]
  );

  const time = useMemo(() => {
    const time = state?.clear
      ? ""
      : `${convertToDoubleDigit(state?.hours)}:${convertToDoubleDigit(
          state?.minutes
        )} ${state?.period}`;

    return time;
  }, [state]);

  const onInputFocused = useCallback(
    (focused) => {
      setIsInputFocused(focused);
    },
    [isInputFocused]
  );

  return (
    <TimePickerStyled width={width} height={height}>
      <Tooltip
        title={
          !disabled && (
            <TooltipContent
              state={state}
              setState={setState}
              updateValue={updateValue}
            />
          )
        }
        trigger={["click"]}
        getPopupContainer={(trigger): HTMLElement =>
          trigger.parentNode as HTMLElement
        }
        overlayClassName="timepicker-tooltip"
        placement="bottomLeft"
        onVisibleChange={onVisibleChange}
      >
        <div
          className={`ant-picker dvsum-timepicker${
            isInputFocused ? " ant-picker-focused" : ""
          }${disabled ? " ant-picker-disabled" : ""}`}
        >
          <div className="ant-picker-input dvsum-timepicker-input">
            <TimePickerInputStyled
              {...props}
              value={time}
              width={width}
              height={height}
              autoComplete="off"
              onFocus={(): void => onInputFocused(true)}
              onBlur={(): void => onInputFocused(false)}
              disabled={disabled}
            />
            {suffixIcon && (
              <span className="ant-picker-suffix">{suffixIcon}</span>
            )}
          </div>
        </div>
      </Tooltip>
      {showClearButton && (
        <Button type="link" className="clear-btn" onClick={onReset}>
          Clear
        </Button>
      )}
    </TimePickerStyled>
  );
}

export default TimePicker;
