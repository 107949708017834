import { NodeSubType, NodeType } from "../../app.types";
import { chatBotIcon } from "../../svgs";
import {
  CoreNodesCircleIconStyled,
  LetterIconMapping,
} from "./corenodetypescircleicon.styles";
import { CoreNodesCircleIconProps } from "./corenodetypescircleicon.types";

const nodeTypeToLetterMapping: {
  [key in NodeType]?: { letter: string | JSX.Element; title: string };
} = {
  TBL: { letter: "T", title: "Table" },
  DSR: { title: "Data Source", letter: "DS" },
  COL: { letter: "C", title: "Column" },
  TRM: { letter: "G", title: "Glossary Term" },
  DSF: { letter: "DSF", title: "Data Source Field" },
  ANL: { letter: chatBotIcon("16", "16"), title: "Chatbot" },
  RLS: { letter: "DQ", title: "Rules" },
  JOB: { letter: "J", title: "Job" },
};

const nodeSubTypeToLetterMapping: {
  [key in NodeSubType | string]?: { letter: string; title: string };
} = {
  RPT: { letter: "R", title: "Report" },
};

function CoreNodesCircleIcon(props: CoreNodesCircleIconProps): JSX.Element {
  const { nodeType, nodeSubType, isSelected } = props;
  const mappedInfo =
    nodeSubTypeToLetterMapping?.[nodeSubType || ""] ||
    nodeTypeToLetterMapping?.[nodeType];

  const isSvgIcon = nodeType === "ANL";

  return (
    <CoreNodesCircleIconStyled
      {...props}
      title={mappedInfo?.title || ""}
      className="core-nodes-circle-icon"
      data-testid="core-nodes-circle-icon-main-wrapper"
    >
      <LetterIconMapping
        isSvg={isSvgIcon}
        className="letter-icon-mapping"
        data-testid="core-nodes-circle-icon-letter-mapping"
        isSelected={isSelected}
      >
        {mappedInfo?.letter}
      </LetterIconMapping>
    </CoreNodesCircleIconStyled>
  );
}

export default CoreNodesCircleIcon;
