import {
  getAnalysisDetailPageUrl,
  getDataSourceDetailPageUrl,
  getReportDetailPageUrl,
  getSourceDetailPageUrl,
  getTableDetailPageUrl,
  getTermDetailPageUrl,
} from ".";

import { NodeSubType, NodeType } from "../app.types";

export function getDetailpageUrlBasedOnNodeType(
  id: string,
  nodeType: NodeType,
  nodeSubType?: NodeSubType
): string {
  const nodeTypeConfig: {
    [key in NodeType]?: string;
  } = {
    TBL: getTableDetailPageUrl(id),
    TRM: getTermDetailPageUrl(id),
    SRC: getSourceDetailPageUrl(id),
    DSR: getDataSourceDetailPageUrl(id),
    ANL: getAnalysisDetailPageUrl(id),
  };

  const nodeSubTypeConfig: {
    [key in NodeSubType | string]?: string;
  } = {
    RPT: getReportDetailPageUrl(id),
  };

  return (
    nodeSubTypeConfig?.[nodeSubType || ""] || nodeTypeConfig?.[nodeType] || ""
  );
}
