function isUpperCase(str?: string): boolean {
  return str === str?.toUpperCase();
}

function isLowerCase(str?: string): boolean {
  return str !== str?.toUpperCase();
}

export function sortListOnSpecificKeyValue<T>({
  list = [],
  key,
  sortAscending = true,
  type = "string",
}: {
  list: T[];
  key: keyof T;
  sortAscending?: boolean;
  type?: "string" | "number" | "date" | "caseInsensitive" | "agGridStrSorting";
}): T[] {
  switch (type) {
    case "number":
      return list?.sort((a: any, b: any) =>
        sortAscending ? b?.[key] - a?.[key] : a?.[key] - b?.[key]
      );
    case "date":
      return list?.sort((a: any, b: any) =>
        sortAscending
          ? new Date(a?.[key])?.valueOf() - new Date(b?.[key])?.valueOf()
          : new Date(b?.[key])?.valueOf() - new Date(a?.[key])?.valueOf()
      );
    case "caseInsensitive": {
      const upperCaseSorted = list
        ?.filter((item) => isUpperCase(`${item?.[key]}`))
        ?.sort((a: any, b: any) =>
          sortAscending
            ? a?.[key]?.toLowerCase()?.localeCompare(b?.[key]?.toLowerCase())
            : b?.[key]?.toLowerCase()?.localeCompare(a?.[key]?.toLowerCase())
        );

      const lowerCaseSorted = list
        ?.filter((item) => isLowerCase(`${item?.[key]}`))
        ?.sort((a: any, b: any) =>
          sortAscending
            ? a?.[key]?.toLowerCase()?.localeCompare(b?.[key]?.toLowerCase())
            : b?.[key]?.toLowerCase()?.localeCompare(a?.[key]?.toLowerCase())
        );
      return [...lowerCaseSorted, ...upperCaseSorted];
    }
    case "agGridStrSorting": {
      const upperCaseSorted = list
        ?.filter((item) => isUpperCase(`${item?.[key]}`))
        ?.sort((a: any, b: any) =>
          sortAscending
            ? a?.[key]?.toLowerCase()?.localeCompare(b?.[key]?.toLowerCase())
            : b?.[key]?.toLowerCase()?.localeCompare(a?.[key]?.toLowerCase())
        );

      const lowerCaseSorted = list
        ?.filter((item) => isLowerCase(`${item?.[key]}`))
        ?.sort((a: any, b: any) =>
          sortAscending
            ? a?.[key]?.toLowerCase()?.localeCompare(b?.[key]?.toLowerCase())
            : b?.[key]?.toLowerCase()?.localeCompare(a?.[key]?.toLowerCase())
        );
      return [...upperCaseSorted, ...lowerCaseSorted];
    }
    default:
      return list?.sort((a: any, b: any) =>
        sortAscending
          ? a?.[key]?.toLowerCase()?.localeCompare(b?.[key]?.toLowerCase())
          : b?.[key]?.toLowerCase()?.localeCompare(a?.[key]?.toLowerCase())
      );
  }
}
