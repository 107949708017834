import { useForm } from "react-hook-form";
import { useCallback, useEffect, useMemo, useState } from "react";

import moment from "moment";
import { useQueryClient } from "react-query";

import { yupResolver } from "@hookform/resolvers/yup";
import { addDays, endOfDay, isBefore, subDays, isAfter, add } from "date-fns";

import { AddUserFormStyled } from "./adduserform.styles";
import { AddUserFormProps, AddUserFormType } from "./adduserform.types";

import { FormStyled, FormItemStyled } from "../../components/form";
import { InputField, SelectField } from "../../components/formfields";

import StateHandler from "../../components/statehandler/statehandler";
import { Button } from "../../components";

import { useGetAllUserGroups } from "../../api/userservice";
import DatePickerField from "../../components/formfields/datepickerfield/datepickerfield";

import { useRequestWithMethod } from "../../api";
import { UsersGridColumnsType } from "../../pages/userspage/userspage.types";

import { API_CONFIG } from "../../constants/apiconfig";
import {
  formatUtcToLocalDate,
  getDvSumInformativeText,
  getUserPermissions,
  selectFilterOption,
  sortObjectsArrayByKey,
  utcToLocalDate,
} from "../../utils";

import { useCancelModal, useGetAppState } from "../../customhooks";
import { addUserSchema } from "../../utils/schemas/userschemas";

import { SelectFieldOption } from "../../components/formfields/selectfield/selectfield.types";
import { MONTH_DAY_YEAR_HOUR_MINUTE } from "../../constants";

import TooltipWithIcon from "../../components/tooltipwithicon";
import Flex from "../../components/flex";
import FormItemLabel from "../../components/form/formitemlabel";

const dateFormat = "MM/DD/YYYY";

const MAX_EXPIRY_DATE = new Date("2999-12-31");

const AddUserForm = (props: AddUserFormProps): JSX.Element => {
  const {
    add_usr_mdl_frst_nm: addUsrMdlFrstNm,
    add_usr_mdl_lst_nm: addUsrMdlLstNm,
    add_usr_mdl_email_adrs: addUsrMdlEmailAdrs,
    add_usr_mdl_usr_grp: addUsrMdlUsrGrp,
    add_usr_mdl_strt_dt: addUsrMdlStrtDt,
    add_usr_mdl_exp_dt: addUsrMdlExpDt,
    add_usr_mdl_lst_lgn: addUsrMdlLsdtLgn,
    add_usr_mdl_crt_dt: addUsrMdlCrtDt,
  } = getDvSumInformativeText();

  const { isEditMode } = props;
  const [scrollSecHeightDiff, setScrollSecHeightDiff] = useState<number>(142);
  const queryClient = useQueryClient();

  const { is_account_admin: isLoggedInUserAccountAdmin } = getUserPermissions();

  const {
    modal: { modalProps = {} },
  } = useGetAppState();

  const {
    id = "",
    firstName = "",
    lastName = "",
    email = "",
    userGroupId,
    userGroupName,
    firstLoginDate,
    expiryDate,
    createdOn,
    lastLoginDate,
    isDataSecurityAdmin,
    propsOnUpdateSuccess,
  } = modalProps as UsersGridColumnsType;

  const {
    control,
    setValue,
    handleSubmit,
    formState: { isValid, touchedFields },
    setError,
    watch,
    trigger,
  } = useForm<AddUserFormType>({
    defaultValues: {
      first_name: firstName,
      last_name: lastName,
      email,
      user_group: isEditMode ? userGroupName : undefined,
      start_date: isEditMode
        ? !!firstLoginDate?.length && utcToLocalDate(firstLoginDate)
        : new Date(),
      expiry_date: isEditMode
        ? !!expiryDate?.length && utcToLocalDate(expiryDate)
        : addDays(new Date().getTime(), 365),
      last_login: lastLoginDate?.length
        ? formatUtcToLocalDate(lastLoginDate, MONTH_DAY_YEAR_HOUR_MINUTE)
        : "",
      creation_date: createdOn?.length
        ? formatUtcToLocalDate(createdOn, "MM/dd/yyyy")
        : "",
    },
    resolver: yupResolver(addUserSchema),
    mode: "onChange",
  });

  const watchData = watch();

  const {
    parsedData: parsedUserGroups = [],
    isLoading: isLoadingUserGroup,
    error: errorGettingUserGroups,
  } = useGetAllUserGroups();

  const sortedParsedUserGroups = useMemo(() => {
    const filteredGroups = isLoggedInUserAccountAdmin
      ? parsedUserGroups
      : parsedUserGroups?.filter(
          (userGroup) => userGroup?.admin_access_type !== "ACT"
        );

    const selectOptions = filteredGroups?.map((userGroup) => ({
      label: userGroup?.group_name,
      value: `${userGroup?.id}`,
    }));
    return sortObjectsArrayByKey<SelectFieldOption>(selectOptions, "label");
  }, [parsedUserGroups, isLoggedInUserAccountAdmin]);

  const onCancel = useCancelModal();

  const onSuccess = useCallback(() => {
    const api = API_CONFIG?.get_all_users?.url;
    queryClient.invalidateQueries(api, { fetching: false });
    onCancel();
    propsOnUpdateSuccess?.();
  }, [modalProps]);

  const { isLoading, error, onExecuteRequest } = useRequestWithMethod(
    isEditMode ? "edit_user" : "add_user",
    isEditMode ? [id] : undefined,
    true,
    onSuccess
  );

  const onSubmit = useCallback(
    (values) => {
      onExecuteRequest({
        firstName: values?.first_name,
        lastName: values?.last_name,
        email: values?.email,
        userGroupId:
          isEditMode && userGroupName === values?.user_group
            ? userGroupId
            : values?.user_group?.length && Number(values?.user_group),
        startDate:
          values?.start_date &&
          moment(values?.start_date)
            .startOf("day")
            .utc()
            .format("YYYY-MM-DD HH:mm:ss"),
        endDate:
          (values?.expiry_date &&
            moment(values?.expiry_date)
              .endOf("day")
              .utc()
              .format("YYYY-MM-DD HH:mm:ss")) ||
          "",
        externalUserId: values?.externalUserId || "",
        isDataSecurityAdmin,
      });
    },
    [modalProps]
  );

  useEffect(() => {
    const alertDom = document.querySelector(".ant-modal-body .ant-alert-error");

    if (alertDom) {
      setScrollSecHeightDiff(
        (st) => st + alertDom?.getBoundingClientRect().height
      );
    }
  }, [errorGettingUserGroups]);

  useEffect(() => {
    touchedFields?.start_date && trigger("start_date");
    touchedFields?.expiry_date && trigger("expiry_date");
  }, [watchData?.start_date, watchData?.expiry_date]);

  return (
    <StateHandler
      isFetching={isLoading || isLoadingUserGroup}
      error={error || errorGettingUserGroups}
      isModal
    >
      <AddUserFormStyled maxHeight={`calc(100vh - ${scrollSecHeightDiff}px)`}>
        <FormStyled
          paddingLeft="334px"
          onFinish={handleSubmit(onSubmit) as any}
          isItemColumnLayout
        >
          <div className="scroll-sec">
            <FormItemLabel
              label={addUsrMdlFrstNm?.field_name || ""}
              description={addUsrMdlFrstNm?.description || ""}
              required
            >
              <InputField control={control} name="first_name" />
            </FormItemLabel>
            <FormItemLabel
              label={addUsrMdlLstNm?.field_name || ""}
              description={addUsrMdlLstNm?.description || ""}
              required
            >
              <InputField control={control} name="last_name" />
            </FormItemLabel>
            <FormItemLabel
              label={addUsrMdlEmailAdrs?.field_name || ""}
              description={addUsrMdlEmailAdrs?.description || ""}
              required
            >
              <InputField
                control={control}
                name="email"
                disabled={isEditMode}
              />
            </FormItemLabel>
            <FormItemLabel
              label={addUsrMdlUsrGrp?.field_name || ""}
              description={addUsrMdlUsrGrp?.description || ""}
              required
            >
              <SelectField
                control={control}
                name="user_group"
                setValue={setValue}
                setError={setError}
                options={sortedParsedUserGroups}
                width="512px"
                placeholder="Select"
                showSearch
                filterOption={selectFilterOption}
              />
            </FormItemLabel>
            <FormItemLabel
              label={addUsrMdlStrtDt?.field_name || ""}
              description={addUsrMdlStrtDt?.description || ""}
              required
            >
              <DatePickerField
                name="start_date"
                placeholder="Start Date"
                control={control}
                format={dateFormat}
                showToday={false}
                width="512px"
                disabledDate={(currentDate): any =>
                  isBefore(currentDate, endOfDay(subDays(new Date(), 1))) ||
                  isAfter(currentDate, MAX_EXPIRY_DATE)
                }
              />
            </FormItemLabel>
            <FormItemLabel
              label={addUsrMdlExpDt?.field_name || ""}
              description={addUsrMdlExpDt?.description || ""}
              required
            >
              <Flex>
                <DatePickerField
                  name="expiry_date"
                  placeholder="Expiration Date"
                  control={control}
                  format={dateFormat}
                  showToday={false}
                  width="512px"
                  disabledDate={(currentDate): any =>
                    isBefore(currentDate, watchData.start_date as Date) ||
                    isAfter(currentDate, MAX_EXPIRY_DATE)
                  }
                />
                {/* <TooltipWithIcon title="Maximum limit is 2999" /> */}
              </Flex>
            </FormItemLabel>
            {isEditMode && (
              <>
                <FormItemLabel
                  label={addUsrMdlLsdtLgn?.field_name || ""}
                  description={addUsrMdlLsdtLgn?.description || ""}
                >
                  <InputField
                    control={control}
                    name="last_login"
                    placeholder="Not logged-in yet"
                    disabled
                  />
                </FormItemLabel>
                <FormItemLabel
                  label={addUsrMdlCrtDt?.field_name || ""}
                  description={addUsrMdlCrtDt?.description || ""}
                >
                  <InputField
                    control={control}
                    name="creation_date"
                    placeholder="Creation Date"
                    disabled
                  />
                </FormItemLabel>
              </>
            )}
          </div>
          <FormItemStyled
            label=""
            className="form-actions-sec"
            marginBottom="0px"
          >
            <Button id="cancel" width="74px" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              id="primary"
              width="74px"
              marginLeft="8px"
              htmlType="submit"
              disabled={!isValid}
            >
              Save
            </Button>
          </FormItemStyled>
        </FormStyled>
      </AddUserFormStyled>
    </StateHandler>
  );
};

export default AddUserForm;
