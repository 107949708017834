import styled from "styled-components";

export const AddSourceFormStyled = styled.div<{
  isFormContainsTwoItem?: boolean;
  gritterNotificationExists?: boolean;
}>`
  .first-screen {
    padding: 0px 32px 34px 38px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    .section-modal-title-bar {
      display: flex;
      padding-top: 31px;

      .section-modal-title {
        font-size: 21px;
        font-family: OpenSans;
        color: ${({ theme }): string => theme.textColors.BLUE_116};
      }

      .section-modal-title-close {
        button {
          display: block;

          .ant-modal-close-x {
            height: 90px;
            width: 52px;
          }
        }
      }
    }

    .section-header {
      display: flex;
      align-items: center;
      position: relative;

      .gritter-notification {
        top: 40px;
      }

      .left-bar {
        flex: 1;
        font-size: 14px;
        font-family: OpenSans;
        color: ${({ theme }): string => theme.textColors.GREY_24};
      }

      .right-bar {
        .ant-input-prefix {
          margin-right: 8px;
        }
      }
    }

    .section-body {
      height: 354px;
      max-height: 354px;
      overflow: auto;
      margin-top: ${({ gritterNotificationExists }): string =>
        gritterNotificationExists ? "66px" : ""};

      .sources-container {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        padding-right: 10px;

        .source-tile {
          height: 124px;
          width: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
          margin: auto;
          transition: all 200ms linear;
          border: 1px solid transparent;
          border-radius: 10px;

          :disabled {
            opacity: 0.2;
            border: solid 1px transparent;
            :hover {
              box-shadow: unset;
              border: solid 1px transparent;
              cursor: not-allowed;
            }
          }

          &:hover {
            box-shadow: 0 2px 8px 0 rgba(124, 140, 240, 0.4);
            border: solid 1px rgba(66, 139, 202, 0.4);
            border-radius: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .second-screen {
    .scroll-sec {
      ${({ isFormContainsTwoItem }): string =>
        isFormContainsTwoItem
          ? `
            height: 312px;
            margin-top: 56px;
            margin-bottom: 20px;
          `
          : `
             height: 344px;
            margin-top: 32px;
            margin-bottom: 20px;
            `}
    }
  }
`;
