import { ColumnDataType } from "../parsers/columnparsers/columnparsers.types";
import {
  blobIcon,
  booleanIcon,
  clockIcon,
  dateIcon,
  dateTimeIcon,
  decimalIcon,
  integerIcon,
  stringIcon,
} from "../svgs";

export function getDataTypeIcon(dataType: ColumnDataType): JSX.Element {
  switch (dataType) {
    case "STR":
      return stringIcon;
    case "DEC":
      return decimalIcon;
    case "INT":
    case "NUM":
      return integerIcon("14", "14");
    case "DTE":
      return dateIcon;
    case "TIM":
      return clockIcon("18.4", "18.4");
    case "DTM":
    case "DT":
      return dateTimeIcon;
    case "BOL":
      return booleanIcon;
    case "BLB":
      return blobIcon("14", "18.7");
    default:
      return <div />;
  }
}
