import styled from "styled-components";

export const BarWithProgressStyled = styled.div<{
  width: number;
  isSelected: boolean;
  checkForSelected: boolean;
}>`
  display: flex;
  align-items: center;

  .val-perc {
    width: ${({ width }): string => `${width}%`};
    height: 16px;
    background: ${({ theme, isSelected, checkForSelected }): string =>
      checkForSelected
        ? isSelected
          ? theme?.bgColors?.BLUE_116
          : theme?.bgColors?.BLUE_150
        : theme?.bgColors?.BLUE_116};
  }

  .val-occur {
    color: ${({ theme, isSelected }): string =>
      isSelected ? theme?.textColors?.BLUE_116 : theme?.textColors?.GREY_24};
    font-family: OpenSansSemiBold;
    font-size: 12px;
    margin-left: 2px;
  }
`;
