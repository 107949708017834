import styled from "styled-components";

export const DeleteUserGroupFormStyled = styled.div`
  .ant-form {
    padding-top: 22px;

    .content-sec {
      .static-text {
        color: ${({ theme }): string => theme.textColors.BLACK_17};
        margin-bottom: 18px;
        padding-left: 110px;
        .bold {
          font-family: OpenSansSemiBold;
        }
      }
    }
  }
`;
