// <--- SVGs Start --->
import { starIcon, starIconOutlined } from "../../svgs";
// <--- SVGs End --->

// <--- Types Start --->
import { ScoreType } from "./popularityscore.types";
// <--- Types End --->

const filledStar = starIcon("14.4", "12.8");
const emptyStar = starIconOutlined("14.4", "12.8");

export const popularityStars: { [key in ScoreType]: JSX.Element[] } = {
  HVU: [filledStar, filledStar, filledStar],
  MDU: [filledStar, filledStar, emptyStar],
  LTU: [filledStar, emptyStar, emptyStar],
  NOU: [emptyStar, emptyStar, emptyStar],
};
