export const renderUIText = {
  gov: {
    label: "Data Governance",
    desc:
      "Governance mode allows you to orchestrate approval processes when updating documentation of Tables, Reports, and Glossary Terms. When this is turned on, workflow options will be available at domain level to govern approval of changes to metadata. When it is off, the workflow option will be disabled.",
    link: "//dvsum.ai/solutions/data-governance/",
  },
  dq: {
    label: "Data Quality",
    desc:
      "If DQ is turned on, Data Quality will be enabled on the Table detail page and users will have access to the different rules that can be applied on any dataset",
    link: "//dvsum.ai/solutions/data-quality/",
  },
  chat: {
    label: "Chat With Data",
    desc:
      "Natural Language BI allowing users to chat with data. When you enable this, a menu called Chat with your Data will show up for users. Additionally on the Table Dictionary inside Catalog Detail page, you will see an Analysis tab to define topics.",
    link: "//dvsum.ai/solutions/self-service-analytics/",
  },
};

export const fineGrainUIText = {
  gov: {
    text:
      "Fine-Grain settings allow you to control which domains/sub-domains to enable governance on automation criteria for newly created Terms.",
  },
  dq: {
    text:
      "By enabling this, you can limit enabling data quality to specific data sources and limited to certain status of assets.",
  },
  chat: {
    text:
      "Fine-Grain settings allow you to control which sources to enable Chat and automation criteria for newly created Sources.",
  },
};

export const settingsUIText = {
  gov: {
    text:
      "Governance will be enabled by default for new domains. You can update the settings here or in the domains settings page.",
  },
  dq: {
    text:
      "Data Quality will be enabled by default for new sources. You can update the settings here or in the source settings page.",
  },
  chat: {
    text:
      "Chat will be enabled by default for new sources. You can update the settings here or in the source settings page.",
  },
};

export const treeConfigUIText = {
  gov: {
    text: "Select Domains to apply Governance to",
  },
  dq: {
    text:
      "Data Quality will be enable by default for new sources. You can update the settings here or in the source settings page.",
  },
  chat: {
    text:
      "Chat will be enable by default for new sources. You can update the settings here or in the source settings page.",
  },
};

export const criteriaUIText = {
  gov: {
    term:
      "You can define a criteria to futher control which Terms are enabled for Governance. When you define this criteria, any terms that meet this criteria will be automatically excluded for Governance.",
  },
  dq: {
    column:
      "Optionally add a criteria to limit columns to check Data Quality on. If you enable this criteria, it is possible that new columns added to source may not be checked for data quality until they are updated.",
    table:
      "Optionally add a criteria to limit tables to check Data Quality on. If you enable this criteria, it is possible that new tables added to source may not be checked for data quality until they are updated.",
  },
  chat: {
    column:
      "You can define a criteria to further control which Columns are excluded for Chat. When you define this criteria, any column that meet this criteria will be automatically excluded for Chat.",
    table:
      "You can define a criteria to further control which Tables are excluded for Chat. When you define this criteria, any table that meet this criteria will be automatically excluded for Chat.",
  },
};

export const governanceLabelText = {
  gov: "Select Domains to apply Governance to",
  dq: "Select Sources to apply Data Quality to",
  chat: "Select Sources to enable Chat for",
};

export const FEATURE_NOT_AVAILABLE =
  "This Featue is not available with your subscription. Please contact DvSum support to discuss adding this feature";

export const PROVIDE_EXCLUSION_CRITERIA =
  "Please provide at-least one exclusion criteria";

export const CHAT_WITH_DATA = "chat-with-data";
export const DATA_QUALITY = "data-quality";
