//     <--Library Imports Start-->
import { useMemo } from "react";
//     <--Library Imports End-->

//     <---BlankSlates-->
import { columnRefBlankSlate } from "../../blankslates/columnsblankslate";
//     <---BlankSlates-->

//     <---Components-->
import { Section } from "../../components/section";
import SectionHeader from "../../components/sectionheader";

import StateHandler from "../../components/statehandler/statehandler";
import LinkButton from "../../components/linkbutton";

import NodeRefPageHOC from "../../components/noderefpagehoc/noderefpagehoc";
import NodeStatusRendrer from "../../components/nodestatusrendrer";

import ProminentTags from "../../components/prominenttagslist";
import CoreNodesCircleIcon from "../../components/corenodetypescircleicon";

import LineageSectionTitleWithIcon from "../../components/lineagesectitlewithicon";
import QualitySectionTitleWithIcon from "../../components/qualitysectitlewithicon";

import { NodeRefPageHOCEmbededProps } from "../../components/noderefpagehoc/noderefpagehoc.types";
import NodeRefQuickEditComp from "../../components/noderefquickedit";

//     <---Components-->

// <--- Constants Start --->
import {
  COLUMN_SECTIONS,
  COLUMN_SECTION_TITLES,
} from "../../constants/labelconstants";
// <--- Constants End --->

//     <---Custom hooks--->
import { useGetAppState, useGetAppType } from "../../customhooks";
//     <---Custom hooks--->

//     <---Rendrers--->
import {
  colRefCustomCompRendrer,
  colRefCustomRowRendrer,
} from "./colrefdrawer.rendrer";
//     <---Rendrers--->

//     <---Services--->
import { useGetColumnDoc } from "../../api/columnsservice";
//     <---Services--->

//     <---SVGS--->
import { chevronRight, databaseIcon, editInfoIconInCircle } from "../../svgs";
//     <---SVGS--->

//     <---Styles--->
import { ColRefDrawerStyled } from "./colrefdrawer.styles";
//     <---Styles--->

//     <---Types--->
import { ColRefDrawerProps, ColRefDrawerType } from "./colrefdrawer.types";
//     <---Types--->

//     <---Utils--->
import { getSourceDetailPageUrl, getTableDetailPageUrl } from "../../utils";
import { ELEMENT_IDS } from "../../constants";
import StyledLink from "../../components/styledlink/styledlink";
//     <---Utils--->

const titleSecProps = {
  className: "col-ref-sec-titile",
  showBorderBottom: false,
  isShowIcon: false,
};

const { flddict_detpag_ref_ttl: FLDDICT_DETPAG_REF_TTL } = ELEMENT_IDS;

function DrawerContent(
  props: ColRefDrawerType & NodeRefPageHOCEmbededProps
): JSX.Element {
  const { drawer } = useGetAppState();
  const {
    id = "",
    onEditColumnClick,
    showEditIcon = true,
    drawerStartFromTop = false,
  } = (drawer?.drawerProps || {}) as ColRefDrawerProps;

  const { editViewComp, onClickEdit } = props;

  const {
    information: { description: DESCRIPTION },
    profiling_information: { visual_distribution: VISUAL_DISTRIBUTION },
    data_quality_rules: { auto_set: AUTO_SET },
  } = COLUMN_SECTION_TITLES;

  const {
    classification: CLASSIFICATION,
    profiling_information: PROFILING_INFORMATION,
    data_quality_rules: DATA_QUALITY_RULES,
  } = COLUMN_SECTIONS;

  const { parsedData, isLoading, error } = useGetColumnDoc(id, "COL", "PUB");

  const {
    parent_id: parentId = "",
    parent_name: parentName = "",
    source_id: sourceId = "",
    source_name: sourceName = "",
    prominent_tags: prominentTags = { tags: [] },
    node_name: colName = "",
    publish_status: publishStatus = "",
    publish_status_id: publishStatusId,
    node_id: nodeId = "",
    node_title: colTitle = "",

    classification = [],
    profillingInfo = [],
    information = [],
    lineage = {
      lineageExists: false,
      data: { level1: [], level2: [], level3: [], level4: [], level5: [] },
    },
    quality = [],
    data_quality_rules: dataQualityRules = [],
    is_rule_auto_set: isRuleAutoSet,
    entity_id: entityId = "",
    is_editable: isEditable,
    is_col_desc_inherited: isColDescInherited,
    is_dq_enabled: isDqEnabled = false,
  } = parsedData || {};

  const { isExtOrDesktop } = useGetAppType();

  const { sourceUrl, tableUrl } = useMemo(() => {
    return {
      sourceUrl: getSourceDetailPageUrl(sourceId),
      tableUrl: getTableDetailPageUrl(parentId),
    };
  }, [parsedData, sourceId, parentId]);

  const source = useMemo(
    () => [
      {
        icon: databaseIcon,
        title: sourceName,
        link: sourceUrl,
      },
      {
        icon: (
          <CoreNodesCircleIcon
            nodeType="TBL"
            width="16px"
            height="16px"
            fontSize="12px"
          />
        ),
        title: parentName,
        link: tableUrl,
      },
    ],
    [sourceName, parentName, sourceUrl, tableUrl]
  );

  const updatedDesc = useMemo(() => {
    const resDesc =
      information?.find((item) => item?.title === DESCRIPTION)?.value || "";

    return resDesc;
  }, [information]);

  return (
    <StateHandler
      isFetching={isLoading}
      error={error}
      blankSlate={columnRefBlankSlate}
    >
      <ColRefDrawerStyled hasPromintntTags={!!prominentTags?.tags?.length}>
        <div className="col-ref-header">
          <div className="header">
            {source.map((item, index) => (
              <div key={`${item?.title}-${index}`} className="header-item">
                <div className="item-icon">{item?.icon}</div>

                <StyledLink
                  to={item.link}
                  target={isExtOrDesktop ? "_blank" : "_self"}
                  title={item?.title}
                >
                  <div title={item?.title} className="item-title">
                    {item?.title}
                  </div>
                </StyledLink>

                {index + 1 < source?.length && (
                  <div className="right-icon"> {chevronRight}</div>
                )}
              </div>
            ))}
          </div>
          <div className="header_row_2" id={FLDDICT_DETPAG_REF_TTL}>
            <SectionHeader
              title={colTitle}
              subTitle=""
              icon={<CoreNodesCircleIcon nodeType="COL" marginRight="11px" />}
              rightIcon={
                <>
                  <NodeStatusRendrer
                    status={publishStatusId}
                    className="deprecate-icon"
                    tooltipTitle={publishStatus}
                    nodeType="COL"
                  />
                  {onEditColumnClick && (
                    <LinkButton
                      marginLeft="7px"
                      height="33px"
                      tooltipProps={{ title: "Edit column info" }}
                      onClick={(): void => {
                        onEditColumnClick && onEditColumnClick(id);
                      }}
                    >
                      {editInfoIconInCircle("33px", "33px")}
                    </LinkButton>
                  )}
                </>
              }
            />
            <div className="node-title" title={colName}>
              {colName}
            </div>
            {!!prominentTags?.tags?.length && (
              <ProminentTags {...prominentTags} />
            )}
          </div>
        </div>
        {editViewComp || (
          <>
            {isEditable && showEditIcon && (
              <NodeRefQuickEditComp
                nodeDesc={updatedDesc}
                nodeId={nodeId}
                nodeTitle={colTitle}
                nodeType="COL"
                onClickEdit={onClickEdit}
                top="20px"
                right="29px"
                parentId={parentId}
                isColDescInherited={isColDescInherited}
                entityId={entityId}
                nodeName={colName}
                drawerStartFromTop={drawerStartFromTop}
              />
            )}
            <div className="col-ref-section">
              <Section
                secTitle={{
                  title: "",
                  ...titleSecProps,
                }}
                secRowData={information?.map((item) => ({
                  ...item,
                  value:
                    item?.title === DESCRIPTION ? updatedDesc : item?.value,
                  customJSX: colRefCustomRowRendrer?.[item?.title]?.(item),
                }))}
              />
            </div>
            <div className="col-ref-section">
              <Section
                secTitle={{
                  ...titleSecProps,
                  title: CLASSIFICATION,
                }}
                secRowData={classification?.map((item) => ({
                  ...item,
                  customJSX: colRefCustomRowRendrer?.[item?.title]?.(item),
                }))}
              />
            </div>
            <div className="col-ref-section profilling-info-sec">
              <Section
                secTitle={{
                  ...titleSecProps,
                  title: PROFILING_INFORMATION,
                }}
                secRowData={profillingInfo?.map((item) => ({
                  ...item,
                  customJSX: colRefCustomRowRendrer?.[item?.title]?.(item),
                  customRowComp:
                    item?.title === VISUAL_DISTRIBUTION
                      ? colRefCustomRowRendrer?.[item?.title]?.(item)
                      : undefined,
                }))}
              />
            </div>
            <div className="col-ref-section">
              <Section
                secTitle={{
                  ...titleSecProps,
                  title: (
                    <LineageSectionTitleWithIcon
                      isRefView
                      queryParams={{
                        nodeId,
                        nodeName: colTitle,
                        nodeType: "COL",
                        mode: "drill_down",
                        parentNodeId: parentId,
                        parentNodeName: parentName,
                        parentNodeType: "TBL",
                      }}
                      lineageExists={lineage?.lineageExists}
                    />
                  ),
                }}
                secRowData={[]}
                lineageData={{
                  ...lineage,
                  isRefView: true,
                  marginBottom: "15px",
                }}
                mode="lineage"
              />
            </div>
            {isDqEnabled && (
              <>
                <div className="col-ref-section">
                  <Section
                    secTitle={{
                      ...titleSecProps,
                      title: (
                        <QualitySectionTitleWithIcon
                          title={colTitle}
                          nodeId={nodeId}
                          isColRef
                          parentId={parentId}
                        />
                      ),
                      marginBottom: "15px",
                    }}
                    secRowData={quality?.map((item) => ({
                      ...item,
                      customJSX: colRefCustomRowRendrer?.[item?.title]?.(item),
                      customRowComp: colRefCustomCompRendrer?.[item?.title]?.(
                        item
                      ),
                    }))}
                  />
                </div>
                <div className="col-ref-section">
                  <Section
                    secTitle={{
                      ...titleSecProps,
                      title: DATA_QUALITY_RULES,
                    }}
                    secRowData={[
                      ...(isRuleAutoSet ? [{ title: AUTO_SET }] : []),
                      ...(dataQualityRules ?? []),
                    ]?.map((item) => ({
                      ...item,
                      customJSX: colRefCustomRowRendrer?.[item?.id || ""]?.(
                        item
                      ),
                      customRowComp: colRefCustomCompRendrer?.[item?.title]?.(
                        item
                      ),
                    }))}
                  />
                </div>
              </>
            )}
          </>
        )}
      </ColRefDrawerStyled>
    </StateHandler>
  );
}

const ColRefDrawer = (props: ColRefDrawerType): JSX.Element => {
  return (
    <NodeRefPageHOC
      isDrawer={props?.isDrawer}
      setShowParentGoBack={props?.setShowParentGoBack}
    >
      <DrawerContent {...props} />
    </NodeRefPageHOC>
  );
};

export default ColRefDrawer;
