// <--- Components Start --->
import DvSumPopover from "../dvsumpopover";

import GenericProminentTag from "../genericprominenttag";
import DQRuleActionStatusCard from "./dqruleactionstatus.components/dqruleactionstatuscard";
// <--- Components End --->

// <--- Styles Start --->
import { DQRuleActionStatusTagWrapperStyled } from "./dqruleactionstatus.styles";
// <--- Styles End --->

// <--- Types Start --->
import { DQRuleActionStatusProps } from "./dqruleactionstatus.types";
// <--- Types End --->

const DQRuleActionStatus = (props: DQRuleActionStatusProps): JSX.Element => {
  const { status = "", type = "GRY" } = props;

  return (
    <DvSumPopover
      content={
        <DQRuleActionStatusCard
          {...props}
          data-testid="dq-rule-action-status-card"
        />
      }
      placement="bottomRight"
      trigger="click"
      destroyTooltipOnHide
    >
      <DQRuleActionStatusTagWrapperStyled data-testid="dq-rule-action-status-tag">
        <GenericProminentTag
          tag={{ name: status, type }}
          type={type}
          isBoldView
        />
      </DQRuleActionStatusTagWrapperStyled>
    </DvSumPopover>
  );
};

export default DQRuleActionStatus;
