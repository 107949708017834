import generatePicker from "antd/lib/date-picker/generatePicker";
import styled from "styled-components";
import generateConfig from "rc-picker/lib/generate/dateFns";

export const DatePickerContainerStyled = styled.div<{
  width?: string;
}>`
  .dvsum-datepicker-dropdown {
    width: ${({ width }): string => width || "290px"};

    .ant-picker-panel {
      .ant-picker-date-panel {
        .ant-picker-header {
          border-bottom: none;
          .ant-picker-header-view {
            button {
              font-family: OpenSans;
              font-size: 13px;
              color: ${({ theme }): string => theme.textColors.GREY_24};
            }
          }
        }

        .ant-picker-body {
          table {
            thead th,
            tbody td {
              width: 36px;
              height: 35px;
              padding: 0px;

              .ant-picker-cell-inner {
                width: 100%;
                height: 100%;
                line-height: 35px;
              }
            }

            thead th {
              font-family: OpenSansSemiBold;
              font-size: 13px;
            }

            tbody {
              td {
                &.ant-picker-cell-today {
                  .ant-picker-cell-inner:before {
                    border: 1px solid
                      ${({ theme }): string => theme.borderColors.BLUE_31};
                  }
                }

                &.ant-picker-cell-selected {
                  .ant-picker-cell-inner {
                    background: ${({ theme }): string =>
                      theme.bgColors.BLUE_31};
                    color: ${({ theme }): string =>
                      theme.textColors.WHITE_COLOR};
                  }
                }

                font-family: OpenSans;
                font-size: 13px;

                &.ant-picker-cell,
                &.ant-picker-cell-end,
                &.ant-picker-cell-disabled {
                  color: #999999;
                }

                &.ant-picker-cell-in-view:not(.ant-picker-cell-disabled) {
                  color: ${({ theme }): string => theme.textColors.GREY_90};
                }

                &.ant-picker-cell-disabled:before {
                  background: transparent;
                }
              }
            }
          }
        }
      }
    }
  }
`;

const DatePickerDfns = generatePicker<Date>(generateConfig);

export const DatePickerStyled = styled(DatePickerDfns)<{
  width?: string;
  height?: string;
}>`
  &.dvsum-datepicker {
    &:not(.ant-picker-disabled):hover {
      border-color: ${({ theme }): string => theme.borderColors.DARK_GREY_15};
    }
    &.ant-picker-focused,
    &.ant-picker-focused:hover {
      box-shadow: none;
      border-color: ${({ theme }): string => theme.borderColors.YELLOW_RD};
    }

    width: ${({ width }): string => width || "100%"};
    height: ${({ height }): string => height || "34px"};
    border-radius: 0px;
    padding: 8px 12px;

    .ant-picker-input input {
      color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
      font-size: 13px;
    }
  }
`;
