import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";

import { chevronDown } from "../../svgs";
import Dvsumpopover from "../dvsumpopover";

import { MultiSelectionCheckboxesPopupStyled } from "./multiselectioncheckboxespopup.styles";
import { MultiSelectionCheckboxesPopupProps } from "./multiselectioncheckboxespopup.types";

import { MultiSelectioncheckBoxPopupContent } from "./components";

const MultiSelectionCheckboxesPopup = forwardRef(
  function MultiSelectionCheckboxesPopup(
    props: MultiSelectionCheckboxesPopupProps,
    ref
  ): JSX.Element {
    const { title = "", options = [], onChange } = props;

    const [isVisible, setIsVisible] = useState(false);

    useImperativeHandle(
      ref,
      () => ({
        isVisible,
        setIsVisible(visible: boolean): void {
          setIsVisible(visible);
        },
      }),
      [isVisible]
    );

    const {
      updatedTitle,
      isAnyItemSelected,
      isEveryItemselected,
    } = useMemo(() => {
      const selectedOptions = options?.filter((option) => option?.checked);

      const selectedOptWithOutAll = selectedOptions
        ?.filter((option) => !option?.isAllOption)
        ?.map((option) => option?.label);

      const isAnyItemSelected = selectedOptWithOutAll?.length > 0;
      const isEveryItemselected = selectedOptions?.length === options?.length;

      const firstSelectedItem = selectedOptWithOutAll?.[0] || "";
      const remainingItemsCount = selectedOptWithOutAll?.length - 1 || 0;

      const allOptLabel =
        options?.find((option) => option?.isAllOption)?.label || "";

      return {
        updatedTitle:
          selectedOptions?.length > 0 ? (
            <div className="multi-selection-checkbox-title">
              {title}:
              <div className="seleted-options-labels">
                {isEveryItemselected
                  ? allOptLabel
                  : `${firstSelectedItem} ${
                      remainingItemsCount > 0 ? `, +${remainingItemsCount}` : ""
                    }
            `}
              </div>
            </div>
          ) : (
            title
          ),
        isAnyItemSelected,
        isEveryItemselected,
      };
    }, [title, options]);

    const onVisibleChange = useCallback((visibility: boolean) => {
      setIsVisible(visibility);
    }, []);

    return (
      <Dvsumpopover
        content={
          <MultiSelectioncheckBoxPopupContent
            {...props}
            isEveryItemselected={isEveryItemselected}
            isAnyItemSelected={isAnyItemSelected}
            onChange={onChange}
          />
        }
        placement="bottomRight"
        trigger={["click"]}
        visible={isVisible}
        onVisibleChange={onVisibleChange}
        getPopupContainer={(trigger): HTMLElement =>
          trigger?.parentNode as HTMLElement
        }
        zIndex={1}
      >
        <MultiSelectionCheckboxesPopupStyled
          isVisible={isVisible}
          className="multi-selection-checkboxes-popup"
        >
          {updatedTitle} {chevronDown()}
        </MultiSelectionCheckboxesPopupStyled>
      </Dvsumpopover>
    );
  }
);

export default MultiSelectionCheckboxesPopup;
