import { APP_ROUTES } from "../constants";
import { checkIsChatPage } from "./ischatpage";

export const checkIsChatLandingPage = (): boolean => {
  const pathname = window?.location?.pathname || "";
  const isChatPage = checkIsChatPage();

  try {
    return isChatPage && pathname?.includes(APP_ROUTES.private_routes.new_chat);
  } catch (e) {
    return false;
  }
};
