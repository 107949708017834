import { useCallback, useEffect, useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";

import { FormProvider, useForm } from "react-hook-form";
import Dynamicform from "../../dynamicform";

import {
  AddValueRangeFormProps,
  AddValueRangeFormType,
} from "./addvaluerangeform.types";

import { ColumnDataType } from "../../../../parsers";
import { addValueRangeFormType } from "./addvaluerangeform.config";

import { GetAddRuleFormContentProps } from "../../addruleform.types";
import { useGetRefDictionariesList } from "../../../../api/ruleservice";

import {
  formDataToApiData,
  getMinMaxReferneceColumns,
  getSpecifyComparisonTypeSelectFieldOptions,
} from "./addvaluerangeformutil";
import { addValueRangeSchema } from "../../../../utils/schemas";

import { filterCriteriaApiData } from "../../../addgovernanceviewform/addgovernanceviewform.utils";
import { useRequestWithMethod } from "../../../../api";

import {
  checkDataTypeOfColumn,
  sortListOnSpecificKeyValue,
  sortObjectsArrayByKey,
} from "../../../../utils";

import { DictionaryMappingConfigValuesType } from "../../../../parsers/ruleparser/ruleparser.types";

import { useGetReferenceDictionaryValues } from "../../../../api/referencedictionaryservice";

const tranformDateString = (dateStr: string): string => {
  if (!/^\d{8}$/.test(dateStr)) {
    return dateStr;
  }
  const year = dateStr?.slice(0, 4);
  const month = dateStr?.slice(4, 6);
  const day = dateStr?.slice(6, 8);

  return `${month}/${day}/${year}`;
};

const AddValueRangeForm = (
  props: GetAddRuleFormContentProps & AddValueRangeFormProps
): JSX.Element => {
  const {
    columnsData,
    columnSelectOptions,
    isFromRuleSet = false,
    isFromRecommendedRules = false,
    propsOnSubmit,
    propsOnCancel,
    ruleConfig,
    tableId,
    sourceId,
    onAddRule,
    ruleCategoryId,
    onUpdateRule,
    formId,
    isEdit = false,
    refDictionariesList = [],
  } = props;

  const initialFilters = ruleConfig?.filter_cond;

  const {
    col_id: colId,
    min_expr: minExpr = "",
    max_expr: maxExpr = "",
    min_value: minValue = "",
    max_value: maxValue = "",
    min_oper: minOper = "GTE",
    max_oper: maxOper = "LTE",
    rule_desc: desc = "",
    value_list: valueList = [],
    max_value_type: maxValueType = "ABS",
    min_value_type: minValueType = "ABS",
    range_type: rangeType = "RV",
    min_col_id: minColId,
    max_col_id: maxColId,
    col_date_type: colDataType,
    ref_dict_id: refDictId = 0,
    ref_dict_name: refDictName = "",
    ref_dict_map: refDictMap = [],
  } = ruleConfig || {};

  const intialSpecifyFields =
    refDictMap?.map((refDict) => ({
      source_field: String(refDict?.col_id),
      reference_field_name: refDict?.rd_field_id,
      reference_field_id: refDict?.rd_field_name,
      order: refDict?.order,
    })) || [];

  const ignoreCase =
    "match_case" in (ruleConfig || {}) ? !ruleConfig?.match_case : undefined;

  const isBlanksIncluded =
    "is_blanks_included" in (ruleConfig || {})
      ? ruleConfig?.is_blanks_included
      : undefined;

  const isSelectionValid =
    "is_selection_valid" in (ruleConfig || {})
      ? ruleConfig?.is_selection_valid
        ? "valid"
        : "invalid"
      : "valid";

  const { isDateColumn: isRuleConfigColumnIsDateType } = checkDataTypeOfColumn(
    colDataType
  );

  // for the first time DB returns date as string, after saving it DB return a date string

  const defaultMinVlaue = minValue
    ? isRuleConfigColumnIsDateType
      ? typeof minValue === "string" && /^\d{8}$/?.test(minValue)
        ? new Date(tranformDateString(minValue))
        : new Date(minValue)
      : minValue
    : "";

  const defaultMaxVlaue = maxValue
    ? isRuleConfigColumnIsDateType
      ? typeof maxValue === "string" && /^\d{8}$/?.test(maxValue)
        ? new Date(tranformDateString(maxValue))
        : new Date(maxValue)
      : maxValue
    : "";

  const alreadySelectedRefDictionary = refDictionariesList?.find(
    (item) => item.dict_id === Number(ruleConfig?.ref_dict_id)
  );

  const addValueRangeForm = useForm<AddValueRangeFormType>({
    defaultValues: {
      id: "",
      rule_type: "Value Range",
      rule_description: desc || "",
      column: colId ? String(colId) : "",
      define: isSelectionValid,
      select_values: valueList || [],
      max_oper: maxOper || "LTE",
      min_oper: minOper || "GTE",
      max_value_type: maxValueType || "ABS",
      min_value_type: minValueType || "ABS",
      specify: rangeType || "RV",
      min_value: defaultMinVlaue || "",
      max_value: defaultMaxVlaue || "",
      min_column_id: minColId ? String(minColId) : "",
      max_column_id: maxColId ? String(maxColId) : "",
      min_expr: minExpr || "",
      max_expr: maxExpr || "",
      include_blanks: isBlanksIncluded,
      ignore_case: ignoreCase,
      specify_fields: intialSpecifyFields,
      is_filter_criteria_active: isEdit,
      filter_criteria: [],
      column_data_type: colDataType,
      dictionary: refDictId ? String(refDictId) : "",
      dictionary_name: refDictName || "",
      is_ref_dict_hierarchial: alreadySelectedRefDictionary?.is_hierarchical,
      is_dict_fields_in_edit_mode: !!intialSpecifyFields?.length,
    },
    resolver: yupResolver(addValueRangeSchema),
    mode: "onChange",
  });

  const {
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = addValueRangeForm;

  const {
    column: watchColumn,
    specify: watchSpecify,
    dictionary,
    specify_fields: watchSpecifyFields,
    is_dict_fields_in_edit_mode: isDictInEditMode,
  } = watch();

  const {
    parsedData: dictListValues,
    isLoading: selectedRefDictListValuesLoading = false,
    error: errorInGettingMetricTypes,
  } = useGetReferenceDictionaryValues(`${dictionary}`);

  const mappedValues = dictListValues?.gridData || [];

  const sortedDictListValues = mappedValues as DictionaryMappingConfigValuesType;

  const selectedColumn = useMemo(
    () =>
      columnsData?.find(
        (column) => Number(column?.field_id) === Number(watchColumn)
      ),
    [watchColumn, columnsData]
  );

  const fieldDictionarySelect = useMemo(() => {
    const columnDistribution = selectedColumn?.col_distribution_values || [];

    const sortedColumnDistribution =
      columnDistribution?.sort((a, b) =>
        a?.toLowerCase()?.localeCompare(b?.toLowerCase())
      ) || [];

    return (
      sortedColumnDistribution?.map((fieldDic) => {
        const selectedColumnDataType = selectedColumn?.field_datatype || "STR";

        const { isDateColumn: isDateFiledColumn } = checkDataTypeOfColumn(
          selectedColumnDataType
        );

        const value = isDateFiledColumn
          ? tranformDateString(fieldDic)
          : fieldDic || "";

        return {
          key: `col-distribution-value-${fieldDic}`,
          value: fieldDic || "",
          label: value || "",
        };
      }) || []
    );
  }, [selectedColumn]);

  const watchColumnType: ColumnDataType = useMemo(
    () => selectedColumn?.field_datatype || "STR",
    [selectedColumn]
  );

  const resetFormValues = useCallback(() => {
    setValue("min_oper", undefined);
    setValue("max_oper", undefined);
    setValue("min_column_id", undefined);
    setValue("max_column_id", undefined);
    setValue("min_expr", undefined);
    setValue("max_expr", undefined);
    setValue("dictionary", "");

    // u need to check, daniyal

    setValue(
      "specify_fields",
      watchSpecifyFields?.map((refDict) => ({
        ...refDict,
        source_field: undefined,
      }))
    );
  }, [watchSpecifyFields]);

  const onColumnValueChange = useCallback(
    (col_id) => {
      const selectedColumn = columnsData?.find(
        (column) => Number(column?.field_id) === Number(col_id)
      );

      const selectedColumnDataType = selectedColumn?.field_datatype || "STR";

      const { isDateColumn: isDateFiledColumn } = checkDataTypeOfColumn(
        selectedColumnDataType
      );

      const min = selectedColumn?.col_min_value || "";

      const max = selectedColumn?.col_max_value || "";

      const colMinValue =
        isDateFiledColumn && min ? new Date(tranformDateString(min)) : min;

      const colMaxValue =
        isDateFiledColumn && max ? new Date(tranformDateString(max)) : max;

      setValue("is_dict_fields_in_edit_mode", false);

      setValue("specify", "RV");
      setValue("column_data_type", selectedColumn?.field_datatype);

      setValue("min_value", colMinValue || undefined);
      setValue("max_value", colMaxValue || undefined);

      resetFormValues();
      clearErrors();
    },
    [columnsData, resetFormValues]
  );

  const refDictSelectOptions = useMemo(() => {
    const filteredRefDictList = isFromRuleSet
      ? refDictionariesList?.filter((dict) => !dict?.is_hierarchical)
      : refDictionariesList;

    const sortedRefDictList = sortListOnSpecificKeyValue({
      list: filteredRefDictList,
      key: "dict_name",
    });

    return (
      sortedRefDictList?.map((dictionary) => ({
        key: `dictionary-option-${dictionary?.dict_id}`,
        value: `${dictionary?.dict_id}`,
        label: (
          <div className="label-hierarchy">
            <span className="label">{dictionary?.dict_name} </span>
            {dictionary?.is_hierarchical && (
              <span className="hierarchy"> (hierarchical)</span>
            )}
          </div>
        ),
        textForSearch: dictionary?.dict_name,
      })) || []
    );
  }, [refDictionariesList, isFromRuleSet]);

  const onSpecifyComparisonTypeChange = useCallback(() => {
    setValue("min_value_type", "ABS");
    setValue("max_value_type", "ABS");
    clearErrors();
    resetFormValues();
  }, [resetFormValues]);

  const selectedRefDictionary = useMemo(() => {
    return refDictionariesList?.find(
      (dict) => Number(dict?.dict_id) === Number(dictionary)
    );
  }, [dictionary, refDictionariesList]);

  const onDictionaryChange = useCallback(
    (dictId) => {
      const selectedRefDict = refDictionariesList?.find(
        (dict) => Number(dict?.dict_id) === Number(dictId)
      );

      setValue("dictionary_name", selectedRefDict?.dict_name);

      const isSelectedRefDictHierarchial = selectedRefDict?.is_hierarchical;

      const selectedRefDictMapping =
        selectedRefDict?.dict_mapping_config?.mapping || [];

      setValue("is_dict_fields_in_edit_mode", false);

      if (isSelectedRefDictHierarchial) {
        const sortedRefDictMapping = sortObjectsArrayByKey(
          selectedRefDictMapping,
          "ORDER"
        );

        setValue(
          "specify_fields",
          sortedRefDictMapping?.map((refDict) => ({
            source_field: "",
            reference_field_name:
              refDict?.DATA_HEADER_CD ||
              refDict?.TBL_FIELD_CD ||
              refDict?.DATA_HEADER_VALUE,
            reference_field_id:
              refDict?.TBL_FIELD_CD || refDict?.TBL_FIELD_VALUE,
            order: refDict?.ORDER,
          }))
        );

        setValue("is_ref_dict_hierarchial", true);
      } else {
        setValue("is_ref_dict_hierarchial", false);
      }
    },
    [refDictionariesList]
  );

  const dictListKeys = useMemo(() => {
    return selectedRefDictionary?.dict_mapping_config?.mapping || [];
  }, [selectedRefDictionary]);

  const isSelectedDictionaryHierarchial = useMemo(() => {
    return selectedRefDictionary?.is_hierarchical || false;
  }, [selectedRefDictionary]);

  const specifySelectFieldOptions = useMemo(() => {
    const allAvailableOptions = getSpecifyComparisonTypeSelectFieldOptions(
      fieldDictionarySelect,
      watchColumnType
    );

    const allSortedAvailableOptions = sortListOnSpecificKeyValue({
      list: allAvailableOptions,
      key: "label",
    });

    return allSortedAvailableOptions;
  }, [fieldDictionarySelect, watchColumnType]);

  const minMaxReferenceColumSelectFieldOptions = useMemo(
    () =>
      getMinMaxReferneceColumns(
        columnSelectOptions?.filter((col) => col?.value !== watchColumn),
        watchColumnType
      ),
    [columnSelectOptions, watchColumnType, watchColumn]
  );

  const valueRangeFormConfig = addValueRangeFormType({
    watchColumnType,
    watchSpecify,
    columnSelectOptions,
    specifySelectFieldOptions,
    minMaxReferenceColumSelectFieldOptions,
    fieldDictionarySelect,
    refDictSelectOptions,
    isSelectedDictionaryHierarchial,
    dictListKeys,
    dictListValues: {
      isLoading: selectedRefDictListValuesLoading,
      values: sortedDictListValues,
    },
    isFromRuleSet,
    onColumnValueChange,
    onSpecifyComparisonTypeChange,
    onDictionaryChange,
    existingFilters: initialFilters,
    isEdit,
    intialSpecifyFields,
    isDictInEditMode,
  });

  const addValueRangeFormSchema = valueRangeFormConfig?.filter(
    (section) =>
      section?.id?.includes(watchSpecify) ||
      section?.id === "basic" ||
      section?.id === "filter"
  );

  const onSubmit = useCallback(
    (values: AddValueRangeFormType) => {
      const rule_config = formDataToApiData(values);

      const isReferenceDictionarySelected = values?.specify === "RD";
      const isReferenceDictionaryHierarchial = values?.is_ref_dict_hierarchial;

      const referenceDictionarySelectedColumns = values?.specify_fields?.map(
        (item) => Number(item?.source_field)
      );

      const formFilterCondition = values?.filter_criteria || [];

      const filterCondition = filterCriteriaApiData(
        formFilterCondition,
        columnsData
      );

      const selectedColumnId = Number(values?.column);

      const updatedDesc = values?.rule_description;

      const colIds =
        isReferenceDictionarySelected && isReferenceDictionaryHierarchial
          ? referenceDictionarySelectedColumns
          : [selectedColumnId];

      const ruleconfigWithType = {
        ...rule_config,
        rule_type: formId,
        rule_desc: updatedDesc,
        col_ids: colIds,
      };

      const addNewRulePayload = {
        rule_cat: ruleCategoryId,
        rule_type: formId,
        rule_desc: updatedDesc,
        src_id: sourceId,
        tbl_id: tableId,
        rule_priority: "NRM",
        rule_def_config: ruleconfigWithType,
        col_ids: colIds,
        rcm_rule_ids: isFromRecommendedRules ? [ruleConfig?.ruleId] : undefined,
        filter: filterCondition,
      };

      const modifyRulePayload = {
        ...ruleconfigWithType,
        filter_cond: filterCondition,
        // col_ids: [selectedColumnId],
        col_ids: colIds,
      };

      isFromRuleSet
        ? propsOnSubmit?.(values)
        : isEdit
        ? onUpdateRule?.(modifyRulePayload)
        : onAddRule(addNewRulePayload);
    },
    [
      isFromRuleSet,
      tableId,
      sourceId,
      columnsData,
      ruleConfig?.ruleId,
      isFromRecommendedRules,
    ]
  );

  return (
    <FormProvider {...addValueRangeForm}>
      <Dynamicform
        schema={addValueRangeFormSchema}
        onSubmit={onSubmit}
        isFromRuleSet={isFromRuleSet}
        propsOnCancel={propsOnCancel}
        columnsData={columnsData}
        isEdit={isEdit}
      />
    </FormProvider>
  );
};

export default AddValueRangeForm;
