import styled from "styled-components";

export const ContentPreviewerStyled = styled.div<{
  addScroll?: boolean;
  marginBottom?: string;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${({ marginBottom }): string => marginBottom || "1rem"};

  .title-copy-icon-wrapper {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${({ theme }): string => theme?.bgColors?.GREY_40};
    padding: 12px 18px;

    .sql-query-action-icons {
      display: flex;
      align-items: center;
      column-gap: 8px;
    }

    .copy-icon {
      font-size: 12px;
      color: ${({ theme }): string => theme?.textColors?.WHITE_COLOR};
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
    }

    .cross-icon-container {
      display: flex;
      align-items: center;
      height: 12px;
    }
  }

  .query-title {
    font-size: 17px;
    color: ${({ theme }): string => theme?.textColors?.WHITE_COLOR};
    font-family: OpenSansSemiBold;
  }

  .query-wrapper {
    padding: 20px;
    background-color: ${({ theme }): string => theme?.bgColors?.BLACK_COLOR};

    max-height: ${({ addScroll }): string => (addScroll ? "181px" : "")};
    overflow-y: auto;

    ::-webkit-scrollbar {
      width: 18px;
      background: ${({ theme }): string => theme?.bgColors?.BLACK_COLOR};
    }
    ::-webkit-scrollbar-thumb {
      background: ${({ theme }): string => theme?.bgColors?.WHITE_COLOR};
      background-clip: padding-box;
      border: 4px solid transparent;
      border-radius: 8px;
    }

    .query-line {
      color: ${({ theme }): string => theme?.textColors?.WHITE_COLOR};
      white-space: pre;
    }

    .query {
      word-break: break-word;
      margin: 0;
    }
  }

  .query-reserved-word {
    color: ${({ theme }): string => theme?.textColors?.BLUE_116};
    margin: 0;
  }

  .query-statement {
    padding-left: 15px;
    margin: 0;
  }

  .query-tables {
    padding-left: 15px;
    margin: 0;
  }

  .helping-word {
    color: ${({ theme }): string => theme?.textColors?.BLUE_116};
  }

  .operation-word {
    color: ${({ theme }): string => theme?.textColors?.ORANGE_10};
  }

  .value-statement {
    color: ${({ theme }): string => theme?.textColors?.GREEN_111};
  }
`;
