import { Card, Space } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "..";
import {
  checkInCircleIcon,
  downloadIcon,
  downloadInstallerIcon,
  exclamationCircledIcon,
} from "../../svgs";
import { GatewayInstallerModalBodyStyled } from "./gatewayinstallermodalbody.styles";
import LinkButton from "../../components/linkbutton/linkbutton";
import { useCancelModal, useGetAppState } from "../../customhooks";
import { useRequestWithMethod } from "../../api";
import { setModal } from "../../reducers/appreducer/appreducer";

const { Meta } = Card;

const GatewayInstallerModalBody = (): JSX.Element => {
  const [isWsInstalled, setIsWsInstalled] = useState(false);
  const [isDownloadBtnClicked, setIsDownloadBtnClicked] = useState(false);
  const [isGetInstalled, setIsGetInstalled] = useState(false);

  const {
    modal: { modalProps = {} },
  } = useGetAppState();

  const {
    isLoading: isLoadingSAWSConfig,
    onExecuteRequest: onExecuteSAWSConfigDownload,
  } = useRequestWithMethod("download_saws_config", undefined, true);

  const { sawsDownloadLink } = modalProps;

  const dispatch = useDispatch();

  const onSuccessGetSaws = useCallback(
    (response) => {
      const sawsCount =
        response?.data?.filter(
          (item: any) =>
            item?.SAWS_STATUS === "ACT" || item?.SAWS_STATUS === "CNF"
        )?.length || 0;

      if (sawsCount > 0) {
        setIsWsInstalled(true);
        setIsGetInstalled(false);
      } else {
        setIsWsInstalled(false);
        setIsGetInstalled(true);
      }
    },
    [isWsInstalled, isGetInstalled]
  );

  const { isLoading, onExecuteRequest } = useRequestWithMethod(
    "get_all_saws",
    undefined,
    true,
    onSuccessGetSaws
  );

  const onCancel = useCancelModal();

  const onDownloadGateway = useCallback(() => {
    onExecuteSAWSConfigDownload(undefined, undefined, (response) => {
      const win = window.open(response?.data?.download_link, "_blank");
      // window.open(sawsDownloadLink, "_parent");

      const winInterval = setInterval(() => {
        if (win?.closed) {
          window.open(sawsDownloadLink, "_parent");
          clearInterval(winInterval);
        }
      }, 100);

      // setTimeout(() => {
      //   window.open(sawsDownloadLink, "_parent");
      // }, 1000);

      setIsDownloadBtnClicked(true);
    });
  }, [isDownloadBtnClicked, sawsDownloadLink]);

  const onContinueToNext = useCallback(() => {
    onExecuteRequest({});
  }, [isWsInstalled, isGetInstalled]);

  useEffect(() => {
    if (isWsInstalled) {
      setTimeout(() => {
        dispatch(
          setModal({
            modalId: "add_source_fields",
            modalTitle: "Add Source",
            visible: true,
            modalProps,
          })
        );
      }, 3000);
    }
  }, [isWsInstalled]);

  return (
    <GatewayInstallerModalBodyStyled>
      {(isWsInstalled && (
        <Card
          cover={checkInCircleIcon("45", "45")}
          bordered={false}
          className="success-dialog"
        >
          <Meta
            title="I detected your newly installed DvSum service."
            description="Continuing to next step"
          />
        </Card>
      )) || (
        <>
          {(isGetInstalled && (
            <Card
              cover={exclamationCircledIcon("45", "45")}
              bordered={false}
              className="success-dialog"
            >
              <Meta
                title="Hmm... I am unable to detect DvSum Service running yet"
                description="Press continue after installation is complete"
              />
            </Card>
          )) || (
            <>
              <Card cover={downloadInstallerIcon()} bordered={false}>
                <Meta
                  title="Gateway Installer"
                  description="In order to connect to your Data Sources, Gateway needs to be
            installed."
                />
              </Card>
              <div className="download-btn-container">
                <Button
                  icon={downloadIcon}
                  height="34px"
                  onClick={onDownloadGateway}
                  loading={isLoadingSAWSConfig}
                >
                  Download Gateway
                </Button>
              </div>
            </>
          )}
          <div className="info">
            {isGetInstalled ? (
              <Space size={4}>
                Not Sure? Click
                <LinkButton
                  onClick={(): void => {
                    setIsWsInstalled(false);
                    setIsGetInstalled(false);
                  }}
                >
                  here
                </LinkButton>
                to go back to start again
              </Space>
            ) : (
              "After download is complete open the file to go through a quick 2 minutes installation. Once done, click to Continue."
            )}
          </div>
          <div className="action-buttons-container">
            {!isGetInstalled && (
              <Button id="cancel" width="90px" height="34px" onClick={onCancel}>
                Cancel
              </Button>
            )}
            <Button
              width="84px"
              height="34px"
              onClick={onContinueToNext}
              disabled={
                !isDownloadBtnClicked || isLoadingSAWSConfig || isLoading
              }
              loading={isLoading}
            >
              Continue
            </Button>
          </div>
        </>
      )}
    </GatewayInstallerModalBodyStyled>
  );
};

export default GatewayInstallerModalBody;
