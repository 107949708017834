import { useCallback } from "react";

import { useGatewayConRetry } from "../../customhooks/usegatewayretry";
import { GatewayRetryButtonStyled } from "./gatewatretrybtn.styles";

import { useGetCurrentGateway } from "../../customhooks";
import { retryIcon } from "../../svgs";

import LinkButton from "../linkbutton/linkbutton";

const GatewayRetryButton = ({ id = "" }: { id: string }): JSX.Element => {
  const { onRetryGatewayCon } = useGatewayConRetry(id);
  const { currentGateway } = useGetCurrentGateway(id);

  const { isPingcheckInProgress, isGatewayRunning } = currentGateway || {};

  const onRetryClick = useCallback(
    (event) => {
      event?.stopPropagation();
      onRetryGatewayCon();
    },
    [onRetryGatewayCon]
  );
  return (
    <GatewayRetryButtonStyled className="gateway-retry-btn">
      {!isGatewayRunning && !isPingcheckInProgress && (
        <LinkButton
          onClick={onRetryClick}
          tooltipProps={{ title: "Connect to the Gateway." }}
          disabled={isPingcheckInProgress}
        >
          {retryIcon()}
        </LinkButton>
      )}
    </GatewayRetryButtonStyled>
  );
};

export default GatewayRetryButton;
