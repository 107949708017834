import { useHistory } from "react-router";
import { useQueryClient } from "react-query";
import { useCallback } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useCancelModal } from "../../../../customhooks";
import Button from "../../../button";

import { FormItemStyled, FormStyled } from "../../../form";
import FormItemLabel from "../../../form/formitemlabel/formitemlabel";

import { CheckboxField, InputField, TextAreaField } from "../../../formfields";
import { AddReferenceDictionaryModalStyled } from "./addreferencedictionary.styles";

import { AddReferenceDictionarySchema } from "../../../../utils/schemas/addreferencedictionaryschema";
import { AddReferenceDictionaryFormType } from "./addreferencedictionary.types";

import { getDvSumInformativeText, jsonParse } from "../../../../utils";
import InformativeText from "../../../informativetext/informativetext";

import { CheckboxWrapperStyled } from "../../../form/form.styles";
import { getReferenceDictionaryDetailPageUrl } from "../../../../utils/getreferencedictionarydetailpageurl";

import { ReferenceDictionaryRouteStateType } from "../../../../pages/referencedictionarydetailpage/referencedictionarydetailpage.types";
import { useRequestWithMethod } from "../../../../api";

import StateHandler from "../../../statehandler/statehandler";
import { API_CONFIG } from "../../../../constants/apiconfig";
import { ELEMENT_IDS } from "../../../../constants";

const {
  ad_ref_dict_frm_desc_field: AD_REF_DICT_FRM_DESC_FIELD,
  ad_ref_dict_frm_name_field: AD_REF_DICT_FRM_NAME_FIELD,
  ad_ref_dict_frm_is_heir_field: AD_REF_DICT_FRM_IS_HEIR_FIELD,
  ad_ref_dict_frm_key_val_field: AD_REF_DICT_FRM_KEY_VAL_FIELD,
  ad_ref_dict_frm_save_btn: AD_REF_DICT_FRM_SAVE_BTN,
} = ELEMENT_IDS;

const AddReferenceDictionary = (): JSX.Element => {
  const onCancel = useCancelModal();
  const history = useHistory();

  const queryClient = useQueryClient();

  const addReferenceDictionaryForm = useForm<AddReferenceDictionaryFormType>({
    defaultValues: {
      name: "",
      description: "",
      isKeyValuePair: false,
      isHierarchical: false,
      hierarchicalLevel: 1,
    },
    resolver: yupResolver(AddReferenceDictionarySchema),
    mode: "onChange",
  });

  const {
    control,
    formState: { isValid, isDirty },
    handleSubmit,
    watch,
    setValue,
  } = addReferenceDictionaryForm;

  const { isHierarchical } = watch();

  const onReferenceDictionaryCreatedSuccess = useCallback((response) => {
    const parsedResId = jsonParse(response?.data?.FN_CREATE_NODE);

    //TODO: Will use onSetData when BE send back the added object in response
    queryClient?.invalidateQueries(
      API_CONFIG.get_all_reference_dictionaries?.url,
      {
        fetching: false,
      }
    );

    // just for now hard coded id, after integration it will be response id
    const refDictPageUrl = getReferenceDictionaryDetailPageUrl(parsedResId);

    history.push({
      pathname: refDictPageUrl,
      state: {
        isNewlyCreated: true,
      } as ReferenceDictionaryRouteStateType,
    });
  }, []);

  const { isLoading, error, onExecuteRequest } = useRequestWithMethod(
    "create_reference_dictionary",
    undefined,
    true
  );

  const onSubmit = useCallback((values: AddReferenceDictionaryFormType) => {
    onExecuteRequest(
      {
        dict_name: values?.name,
        dict_desc: values?.description,
        is_hierarchical: values?.isHierarchical,
        is_key_value: values?.isKeyValuePair,
        // dict_hier_level: values?.hierarchicalLevel,
        dict_hier_level: values?.isHierarchical
          ? values?.hierarchicalLevel
          : undefined,
      },
      undefined,
      onReferenceDictionaryCreatedSuccess
    );
  }, []);

  const {
    add_ref_dict_mdl_is_key_value_pair: {
      description: addRefDictMdlIsKeyValuePair = "",
    } = {},
    add_ref_dict_mdl_is_hierarchical: {
      description: addRefDictMdlIsHierarchical = "",
    } = {},
  } = getDvSumInformativeText();

  return (
    <StateHandler isFetching={isLoading} error={error} isModal>
      <FormStyled isItemColumnLayout>
        <AddReferenceDictionaryModalStyled>
          <FormItemLabel label="Name" key="name" width="450px" required>
            <InputField
              control={control}
              name="name"
              width="450px"
              placeholder="Name"
              type="text"
              id={AD_REF_DICT_FRM_NAME_FIELD}
            />
          </FormItemLabel>

          <FormItemStyled label="Description">
            <TextAreaField
              control={control}
              name="description"
              placeholder="Description"
              width="450px"
              id={AD_REF_DICT_FRM_DESC_FIELD}
            />
          </FormItemStyled>

          <CheckboxWrapperStyled
            marginBottom="15px"
            paddingLeft="20px"
            marginTop="20px"
            id={AD_REF_DICT_FRM_KEY_VAL_FIELD}
          >
            <CheckboxField
              control={control}
              name="isKeyValuePair"
              mode="secondary"
            >
              Is Key/Value Pair
              {addRefDictMdlIsKeyValuePair && (
                <InformativeText
                  desc={addRefDictMdlIsKeyValuePair}
                  marginBottom="8px"
                  width="450px"
                />
              )}
            </CheckboxField>
          </CheckboxWrapperStyled>

          <CheckboxWrapperStyled
            marginBottom="15px"
            paddingLeft="20px"
            marginTop="20px"
            id={AD_REF_DICT_FRM_IS_HEIR_FIELD}
          >
            <CheckboxField
              control={control}
              name="isHierarchical"
              mode="secondary"
            >
              Is Hierarchical
              <InformativeText
                desc={addRefDictMdlIsHierarchical}
                marginBottom="8px"
                width="450px"
              />
              {isHierarchical && (
                <InputField
                  control={control}
                  name="hierarchicalLevel"
                  width="430px"
                  type="number"
                  min={1}
                  max={10}
                  isMinMaxButtonHidden={false}
                />
              )}
            </CheckboxField>
          </CheckboxWrapperStyled>
        </AddReferenceDictionaryModalStyled>

        <FormItemStyled className="form-actions-sec">
          <Button id="cancel" width="74px" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            id="primary"
            width="74px"
            marginLeft="8px"
            htmlType="submit"
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid || !isDirty}
            elementId={AD_REF_DICT_FRM_SAVE_BTN}
          >
            Add
          </Button>
        </FormItemStyled>
      </FormStyled>
    </StateHandler>
  );
};

export default AddReferenceDictionary;
