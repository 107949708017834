export function sortObjectsArrayByKey<T>(
  objArray: T[],
  key: string,
  isSortingCaseInsensitive?: boolean
): T[] {
  try {
    if (isSortingCaseInsensitive) {
      return objArray?.sort((a: any, b: any) =>
        a[key]?.toLowerCase() > b[key]?.toLowerCase()
          ? 1
          : b[key]?.toLowerCase() > a[key]?.toLowerCase()
          ? -1
          : 0
      );
    }
    return objArray?.sort((a: any, b: any) =>
      a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0
    );
  } catch (e) {
    return objArray || [];
  }
}
