import { useGetAppState } from "../../../../customhooks";
import { filledSuccessTickInCircle } from "../../../../svgs";
import { Button } from "../../../button/button.component";
import { DataImportSubmittedModalStyled } from "./dataimportsubmittedmodal.styles";
import { DataImportSubmittedModalProps } from "./dataimportsubmittedmodal.types";

const DataImportSubmittedModal = (): JSX.Element => {
  const { modal: { modalProps = {} } = {} } = useGetAppState();

  const {
    showGotoButton = false,
    handleGoToDictionary,
    handleDone,
  } = modalProps as DataImportSubmittedModalProps;

  return (
    <DataImportSubmittedModalStyled>
      <div className="header">
        {filledSuccessTickInCircle("19.2", "19.2")}{" "}
        <span className="header-text">Import submitted successfully</span>
      </div>
      <div className="body">
        You will receive an email notification when import is completed. Usually
        the import will takes less than a minute but depending on volume may
        take up to 3-5 minutes.
      </div>
      <div className="footer">
        {showGotoButton && (
          <Button id="link" onClick={handleGoToDictionary}>
            Go to Dictionary
          </Button>
        )}
        <Button id="primary" onClick={handleDone}>
          I'm Done
        </Button>
      </div>
    </DataImportSubmittedModalStyled>
  );
};

export default DataImportSubmittedModal;
