import { useCallback } from "react";
import { useFormContext } from "react-hook-form";

import { TextAreaField } from "..";
import { sendMessageIcon } from "../../../svgs";

import LinkButton from "../../linkbutton";

import {
  MessageInputFieldFormType,
  MessageInputFieldProps,
} from "./messageinputfield.types";
import { MessageInputFieldStyled } from "./messageinputfield.styles";
import FormStyled from "../../form/form.styles";
import { ELEMENT_IDS } from "../../../constants";
import { useGetAppState } from "../../../customhooks";

const {
  caddi_qst_field: CADDI_QST_FIELD,
  caddi_qst_entr_btn: CADDI_QST_ENTR_BTN,
} = ELEMENT_IDS;

const MessageInputField = (props: MessageInputFieldProps): JSX.Element => {
  const {
    placeholder = "",
    isSubmitButtonDisabled = false,
    onSubmit,
    disabled = false,
    tooltipProps,
    name,
    propOnChange,
    sendIconPlacement = "bottom",
    marginBottom,
    marginTop,
  } = props;

  const { isOnboardingMode } = useGetAppState();

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useFormContext();

  const onSubmitWrapper = useCallback(
    (formData: MessageInputFieldFormType) => {
      onSubmit(formData);
    },
    [onSubmit]
  );

  return (
    <MessageInputFieldStyled
      data-testid="message-input-field-main-wrapper"
      iconPlacement={sendIconPlacement}
      marginBottom={marginBottom}
      marginTop={marginTop}
    >
      <FormStyled
        layout="vertical"
        onFinish={handleSubmit(onSubmitWrapper) as any}
        className="form-style"
        data-testid="message-input-field-form-wrapper"
      >
        <TextAreaField
          name={name}
          disabled={disabled}
          control={control}
          autoSize
          autoFocus
          height="60.8"
          placeholder={placeholder || "Type a message..."}
          propOnChange={propOnChange}
          onPressEnter={
            !isSubmitButtonDisabled &&
            isValid &&
            !isOnboardingMode &&
            (handleSubmit(onSubmitWrapper) as any)
          }
          tooltipProps={tooltipProps}
          data-testid="message-input-field-text-area-field"
          id={CADDI_QST_FIELD}
        />

        <LinkButton
          htmlType="submit"
          disabled={!isValid || isSubmitButtonDisabled}
          data-testid="message-input-field-send-btn"
          elementId={CADDI_QST_ENTR_BTN}
        >
          {sendMessageIcon("18", "18")}
        </LinkButton>
      </FormStyled>
    </MessageInputFieldStyled>
  );
};

export default MessageInputField;
