import styled from "styled-components";

export const MatchingStatusPopoverStyled = styled.div<{
  isSpaceBetween?: boolean;
  isContainingAdditionalInfo?: boolean;
  paddingRight?: string;
}>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: ${({ paddingRight }): string => paddingRight || ""};

  .plus-icon {
    font-size: 18px;
    margin-right: 4px;
  }

  .menu-sec {
    display: flex;
    align-items: center;
    height: 40px;
    width: 100%;
    justify-content: center;

    .additional-info {
      ${({ isSpaceBetween, isContainingAdditionalInfo }): string =>
        isSpaceBetween && isContainingAdditionalInfo
          ? "flex: 1"
          : "margin-right:10px"};
    }

    .menu-dots {
      font-size: 20px;
      font-weight: bold;
      cursor: pointer;
    }
  }
`;
