// <---  Library Imports Start --->
import { useCallback } from "react";
import { useHistory } from "react-router";
// <---  Library Imports End --->

// <---  Components Start --->
import CoreNodesCircleIcon from "../../components/corenodetypescircleicon";
import LinkButton from "../../components/linkbutton";
// <---  Components End --->

// <--- Custom Hooks Start --->
import { useGetAppType } from "../../customhooks";
// <--- Custom Hooks End --->

// <--- Parser Start --->
import { ColumnDataType } from "../../parsers";
import { dataSourceFieldNamesConfig } from "../../parsers/datasourcefieldparser/datasourcefieldparser.config";
// <--- Parser Start --->

// <---  Svgs Start --->
import { chevronRight } from "../../svgs";
// <---  Svgs End --->

// <--- Types Start --->
import { SectionRowProps } from "../../components/sectionrow/sectionrow.types";
import { LineangeParsedType } from "../../parsers/datasourcefieldparser/datasourcefieldparser.types";
// <--- Types End --->

// <--- Utils Start --->
import { getDataTypeIcon, getDetailpageUrlBasedOnNodeType } from "../../utils";
// <--- Utils End --->

export const dataSourceFieldRefViewRenderer: {
  [key: string]: (_item: SectionRowProps) => JSX.Element;
} = {
  [dataSourceFieldNamesConfig.overview.data_type]: (item) => (
    <span title={item?.additionalInfo?.data_type_title || ""}>
      {getDataTypeIcon(item?.value as ColumnDataType)}
    </span>
  ),
};

export function LineageItemRendrer({
  item,
}: {
  item: LineangeParsedType;
}): JSX.Element {
  const history = useHistory();
  const { isExtOrDesktop } = useGetAppType();

  const onLineageParentClick = useCallback((nodeType, nodeSubType, id) => {
    const url = getDetailpageUrlBasedOnNodeType(id, nodeType, nodeSubType);
    if (url) {
      isExtOrDesktop ? window.open(url) : history.push(url);
    }
  }, []);

  return (
    <>
      <CoreNodesCircleIcon
        nodeType={item?.node_type}
        nodeSubType={item?.node_sub_type}
        width="18px"
        height="18px"
        fontSize={item?.node_type === "DSF" ? "8px" : "10px"}
      />
      <LinkButton
        isTextUnderLine
        onClick={(): void =>
          onLineageParentClick(
            item?.node_id,
            item?.node_type,
            item?.node_sub_type
          )
        }
        className={
          item?.node_type === "COL" || item?.node_type === "DSF"
            ? "cursor-default"
            : ""
        }
        showEllipsis
        title={item?.node_name}
        maxWidth="180px"
        width="unset"
      >
        {item?.node_name}
      </LinkButton>
      {chevronRight}
    </>
  );
}
