import styled from "styled-components";
import { ellipsis } from "../../../../styles/app.styles";

export const MassUpdateOptionRendrerStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  .field-value-icon {
    margin-right: 5px;
    display: flex;
    svg {
      width: 12px;
      height: 12px;

      > path {
        fill: ${({ theme }): string => theme.bgColors.GREY_36};
      }

      g {
        path {
          fill: ${({ theme }): string => theme.bgColors.GREY_36};
        }
        .domain-icon-cls-2 {
          fill: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
        }
      }
    }
  }

  .right-icon {
    margin: 0 8px;
    display: flex;
    align-items: center;
  }

  .field-option-value {
    ${ellipsis}
  }
`;

export const MassUpdateFormItemStyled = styled.div`
  .dvsum-select-field {
    .multiselect-dropdown-dvsum .rc-virtual-list-holder {
      .ant-select-item-option-active,
      .ant-select-item-option-selected {
        background: ${({ theme }): string => theme.bgColors.BLUE_118};
        color: ${({ theme }): string => theme.textColors.WHITE_COLOR};
        font-weight: normal;
        font-family: OpenSansSemiBold;
        .ant-select-item-option-content {
          svg {
            path {
              fill: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
            }
          }

          .right-icon {
            svg {
              .chevron-right-icon {
                stroke: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
                fill: none;
              }
            }
          }
        }
      }
    }
  }
`;
