import { ParsedTagsetDetailTag } from "../parsers";

export const sortTagsetsDetailPageTags = (
  tags: Array<ParsedTagsetDetailTag>,
  isClassificationTagset: boolean
): Array<ParsedTagsetDetailTag> => {
  if (isClassificationTagset) {
    const restrictedTag = tags?.filter(
      (item) => item?.tag?.toLocaleLowerCase() === "restricted"
    );
    const sensitiveTag = tags?.filter(
      (item) => item?.tag?.toLocaleLowerCase() === "sensitive"
    );
    const internalTag = tags?.filter(
      (item) => item?.tag?.toLocaleLowerCase() === "internal"
    );
    const publicTag = tags?.filter(
      (item) => item?.tag?.toLocaleLowerCase() === "public"
    );
    const remainingTags = tags?.filter(
      (item) =>
        !["restricted", "sensitive", "internal", "public"]?.includes(
          item?.tag?.toLocaleLowerCase()
        )
    );

    return [
      ...restrictedTag,
      ...sensitiveTag,
      ...internalTag,
      ...publicTag,
      ...remainingTags,
    ];
  }

  return [...tags]?.sort((a, b) =>
    a?.tag > b?.tag ? 1 : b?.tag > a?.tag ? -1 : 0
  );
};
