import {
  checkIsViewOnlyChatQuestionPage,
  checkStringIncludesSubString,
  utcTOLocalTimeZone,
} from "../../../../utils";
import {
  AnalysisPageQueryBox,
  TrainBotTimeframeFilters,
  TrainbotFilters,
} from "../../analysisdetailpage.types";

const filterQueriesBasedOnStatus = (
  trainBotFilter: TrainbotFilters,
  queries: AnalysisPageQueryBox[]
): AnalysisPageQueryBox[] => {
  switch (trainBotFilter) {
    case "APR":
      return queries?.filter(
        (query) => query?.user_feedback?.response === "like"
      );
    case "REJ":
      return queries?.filter(
        (query) => query?.user_feedback?.response === "dislike"
      );
    case "CNP":
      return queries?.filter((query) => query?.sub_type === "CNP");
    case "PS":
      return queries?.filter(
        (query) =>
          ["PS", "FL"]?.includes(query?.qry_status) &&
          !query?.user_feedback?.response
      );
    case "INC":
      return queries?.filter(
        (query) => query?.qry_status === "FL" && !query?.qry_tmp_results_s3_url
      );
    case "SVD":
      return queries?.filter((query) => query?.header?.is_question_saved);
    default:
      return queries;
  }
};

const filterQueriesBasedOnAskedBy = (
  askedByFilter: string[],
  queries: AnalysisPageQueryBox[]
): AnalysisPageQueryBox[] => {
  const isAllAskedByFilterSelected = askedByFilter?.includes("all");
  return queries?.filter(
    (item) =>
      askedByFilter?.includes(item?.qry_created_by) ||
      isAllAskedByFilterSelected
  );
};

const filterQueriesBasedOnSearchText = (
  searchText: string,
  queries: AnalysisPageQueryBox[]
): AnalysisPageQueryBox[] => {
  if (!searchText) return queries;

  return queries?.filter((item) =>
    checkStringIncludesSubString(item?.header?.title, searchText)
  );
};

function filterQueriesBasedOnTimeFrameFilter(
  timeFrameFilter: TrainBotTimeframeFilters,
  queries: AnalysisPageQueryBox[]
): AnalysisPageQueryBox[] {
  if (timeFrameFilter === "all") return queries;

  const currentDate = new Date();

  // Calculate the difference in milliseconds for each date
  const filteredDates = queries?.filter((query) => {
    const timeDiff =
      currentDate?.getTime() -
      new Date(
        utcTOLocalTimeZone(query?.qry_exec_on, undefined, true)
      )?.getTime();
    const timeDiffDays = timeDiff / (1000 * 3600 * 24);

    // Determine if the date falls within the criteria
    switch (timeFrameFilter) {
      case "last_1_day":
        return timeDiffDays <= 1;
      case "last_7_day":
        return timeDiffDays <= 7;
      case "last_30_days":
        return timeDiffDays <= 30;
      case "last_6_months":
        return timeDiffDays <= 30 * 6;
      case "last_1_year":
        return timeDiffDays <= 365;
      default:
        return false;
    }
  });

  return filteredDates;
}

export const filterQueriesBasedOnAppliedCriteria = (
  trainBotFilter: TrainbotFilters,
  queries: AnalysisPageQueryBox[],
  askedBy: string[],
  filterSeq?: "status_to_asked_by" | "asked_by_to_status",
  searchText = "",
  timeFrameFilter: TrainBotTimeframeFilters = "all"
): AnalysisPageQueryBox[] => {
  const filteredQueries = queries?.filter((qry) => !qry?.parent_query_id);
  switch (filterSeq) {
    case "asked_by_to_status":
      return filterQueriesBasedOnTimeFrameFilter(
        timeFrameFilter,
        filterQueriesBasedOnSearchText(
          searchText,
          filterQueriesBasedOnStatus(
            trainBotFilter,
            filterQueriesBasedOnAskedBy(askedBy, filteredQueries)
          )
        )
      );
    default:
      return filterQueriesBasedOnTimeFrameFilter(
        timeFrameFilter,
        filterQueriesBasedOnSearchText(
          searchText,
          filterQueriesBasedOnAskedBy(
            askedBy,
            filterQueriesBasedOnStatus(trainBotFilter, filteredQueries)
          )
        )
      );
  }
};

export const queriesToFetchBeforePageLoad = (
  queries: AnalysisPageQueryBox[] = [],
  isRestAPISource?: boolean
): {
  queriesToFetchBeforePageLoad: AnalysisPageQueryBox[];
  isAnyOfThemLoadingPrevQueriedData: boolean;
} => {
  if (checkIsViewOnlyChatQuestionPage()) {
    return {
      isAnyOfThemLoadingPrevQueriedData: false,
      queriesToFetchBeforePageLoad: [],
    };
  }

  let qrysToFetchBeforeLoad = queries
    ?.slice(-3)
    ?.filter(
      (item) => item?.qry_tmp_results_s3_url && item?.qry_prf_results_s3_url
    );

  if (isRestAPISource) {
    const lastTwoParentQrysIds = queries
      ?.filter((query) => !query?.parent_query_id)
      ?.slice(-2)
      ?.map((qry) => qry?.id);

    qrysToFetchBeforeLoad = queries
      ?.filter((query) =>
        lastTwoParentQrysIds?.includes(query?.parent_query_id || "")
      )
      ?.filter((item) => item?.qry_tmp_results_s3_url);
  }

  const isAnyOfThemLoadingPrevQueriedData = qrysToFetchBeforeLoad?.some(
    (query) =>
      query?.is_loading && query?.loader_type === "reloading_prev_queried_data"
  );

  return {
    queriesToFetchBeforePageLoad: qrysToFetchBeforeLoad,
    isAnyOfThemLoadingPrevQueriedData,
  };
};
