import styled from "styled-components";

export const AddGroupFormStyled = styled.div`
  .ant-form {
    .scroll-sec {
      padding-top: 29px;
      max-height: calc(100vh - 104px);
      overflow: auto;
    }

    .ant-form-item {
      .ant-form-item-label {
        margin-bottom: 4px;
      }

      .transfer-left-tilte {
        display: flex;
        align-items: center;
        > svg {
          margin-left: 6px;
        }

        .available-filters-tooltip {
          .ant-tooltip-content {
            .ant-tooltip-arrow {
              .ant-tooltip-arrow-content {
                box-shadow: 0 0 7px 0 rgba(211, 211, 211, 0.85);
                background-color: ${({ theme }): string =>
                  theme.bgColors.WHITE_COLOR};
                width: 8px;
                height: 8px;
              }
            }
            .ant-tooltip-inner {
              width: 300px;
              height: 82px;
              padding: 16px 9.2px 18px 15px;
              box-shadow: 0 0 7px 0 rgba(211, 211, 211, 0.85);
              background-color: ${({ theme }): string =>
                theme.bgColors.WHITE_COLOR};
              font-size: 16px;
              color: ${({ theme }): string => theme.textColors.GREY_26};
            }
          }
        }
      }
    }
    .form-actions-sec {
      position: absolute;
      width: 100vw;
      bottom: 0px;
      margin-bottom: 0px;
      padding: 8px 24px;
    }
  }
`;
