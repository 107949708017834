// utils/fineGrainUtils.ts
import { getPostLoginData } from ".";
import { AdminAccessEnabledObjsType } from "../parsers/userroles/userrolesparser.types";

interface AdminAccessDataType {
  adminAccessType: "ACT" | "USR" | "NOA" | undefined;
  isFinegrainEnabled: boolean | undefined;
  enabledObjects: Array<AdminAccessEnabledObjsType> | undefined;
}

export function getAdminAccessData(): AdminAccessDataType {
  const { user_info: userInfo } = getPostLoginData();
  const { admin_access: adminAccess, admin_access_type: adminAccessType } =
    userInfo || {};

  const {
    is_finegrain_enabled: isFinegrainEnabled,
    enabled_objects: enabledObjects,
  } = adminAccess || {};

  return { adminAccessType, isFinegrainEnabled, enabledObjects };
}

// Check if the user has access to a page (NOA blocks access)
export function hasAnyAdministrationAccess(tab_id: string): boolean {
  const {
    adminAccessType,
    isFinegrainEnabled,
    enabledObjects,
  } = getAdminAccessData();
  if (adminAccessType !== "ACT") {
    return false;
  }

  if (!isFinegrainEnabled) {
    return true;
  }

  const pageAccess = enabledObjects?.find((item) => item?.tab_id === tab_id);
  return pageAccess?.access_type !== "NOA";
}

// New function to check if user has edit or view access on a page
export function hasAdministrationEditAccess(tab_id: string): boolean {
  const {
    adminAccessType,
    isFinegrainEnabled,
    enabledObjects,
  } = getAdminAccessData();
  if (adminAccessType !== "ACT") {
    return false;
  }

  if (!isFinegrainEnabled) {
    return true;
  }

  const pageAccess = enabledObjects?.find((item) => item?.tab_id === tab_id);
  return pageAccess?.access_type === "EDT";
}
