export function agGridSortDateComparator(date1: any, date2: any): any {
  const d1: Date = (date1 && new Date(date1)) || null;
  const d2: Date = (date2 && new Date(date2)) || null;

  if (d1 === null && d2 === null) {
    return 0;
  }

  if (d1 === null) {
    return -1;
  }

  if (d2 === null) {
    return 1;
  }

  return d1 === d2 ? 0 : d1 > d2 ? 1 : -1;
}
