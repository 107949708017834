import styled from "styled-components";

export const DQRuleDetailGridSectionStyled = styled.div`
  .time-travel-sec {
    margin-bottom: 12px;

    .time-travel-date {
      font-family: "OpenSansSemiBold";
      color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
      font-size: 14px;
    }

    .tooltip-with-icon {
      display: flex;
      align-items: center;
    }
  }

  .ag-theme-alpine {
    .ag-root-wrapper {
      border: 1px solid
        ${({ theme }): string => theme.borderColors.LIGHT_GREY_51};

      .ag-header {
        background: ${({ theme }): string => theme.bgColors.LIGHT_GREY_22};
        border-bottom: 1px solid
          ${({ theme }): string => theme.borderColors.LIGHT_GREY_51};

        .ag-header-cell {
          border-right: 1px solid
            ${({ theme }): string => theme.borderColors.LIGHT_GREY_51};

          &:last-child {
            border-right: none;
          }
        }
      }

      .ag-cell {
        border-right: 1px solid
          ${({ theme }): string => theme.borderColors.LIGHT_GREY_51};

        &:last-child {
          border-right: none;
        }
      }
    }
  }
`;
