import { SpecifyCriteriaReadModeType } from "../../../../../../../../components/criteriaselection/criteriaselection.types";
import { FILTERS_OBJECT } from "./exclusioncriteriasection.config";
import { ExclusionCriteriaFilterType } from "./exclusioncriteriasection.types";

export const getTransformedFilter = (
  inputFilterArray: SpecifyCriteriaReadModeType[]
): ExclusionCriteriaFilterType => {
  return inputFilterArray.map((criteriaItem) => {
    const {
      columnName = "",
      filter = "EQL",
      value1 = "",
      value2 = "",
      valuesList = [],
    } = criteriaItem;

    const getValues =
      value1 && value2
        ? `${value1} AND ${value2}`
        : value1 || value2
        ? value1 || value2
        : valuesList.length > 0
        ? valuesList.join(" , ")
        : "";

    return {
      column: columnName,
      filter: FILTERS_OBJECT[filter] || filter,
      value: getValues,
    };
  });
};
