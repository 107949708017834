import { useCallback, useMemo } from "react";

import { ContentPreviewerStyled } from "./contentpreviewer.styles";
import { ContentPreviewerProps } from "./contentpreviewer.types";

import { convertQueryToHtmlQuery, parseStringToHtml } from "../../utils";
import { copyTextIcon, crossIcon } from "../../svgs";

import LinkButton from "../linkbutton/linkbutton";
import { useCopyToClipboard } from "../../customhooks";

const ContentPreviewer = ({
  sqlQuery = "",
  addScroll = false,
  onClose,
  showCrossIcon = true,
  title = "",
  allowCopying = true,
  marginBottom = "",
  customJsx,
}: ContentPreviewerProps): JSX.Element => {
  const copyQuery = useCopyToClipboard();

  const queryHtml = useMemo(() => {
    return parseStringToHtml(convertQueryToHtmlQuery(sqlQuery));
  }, [sqlQuery]);

  const onCopy = useCallback(() => {
    copyQuery(sqlQuery);
  }, [sqlQuery, copyQuery]);

  return (
    <ContentPreviewerStyled
      data-testid="analysis-detail-page-query-block-query-results-sql-query-text-sec"
      addScroll={addScroll}
      marginBottom={marginBottom}
      className="sql-query-preview-container"
    >
      {title && (
        <div className="title-copy-icon-wrapper">
          <div className="query-title">{title}</div>
          <div className="sql-query-action-icons">
            {allowCopying && (
              <LinkButton className="copy-icon" onClick={onCopy}>
                {copyTextIcon("15", "15")} Copy
              </LinkButton>
            )}

            {showCrossIcon && (
              <LinkButton
                className="cross-icon-container"
                onClick={onClose}
                data-testid="analysis-detail-page-overview-sec-cross-btn"
              >
                {crossIcon("15", "15")}
              </LinkButton>
            )}
          </div>
        </div>
      )}
      <div
        className="query-wrapper"
        data-testid="analysis-detail-page-query-block-query-results-sql-query-text"
      >
        {customJsx || queryHtml}
      </div>
    </ContentPreviewerStyled>
  );
};

export default ContentPreviewer;
