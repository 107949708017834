import { Tooltip } from "antd";
import { useDebounce } from "usehooks-ts";
import { useCallback, useEffect, useMemo } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { VerticalDividerStyled } from "../../../../../../../../../../../../components/dividers/dividers.styles";

import {
  DateFormatInput,
  RangeInput,
  CoreNodesCircleIcon,
} from "../../../../../../../../../../../../components";
import { RadioGroupField } from "../../../../../../../../../../../../components/formfields";

import { AlertStyled } from "../../../../../../../../../../../../components/alert/alert.styles";
import {
  aiSuggestIcon,
  infoIcon,
} from "../../../../../../../../../../../../svgs";
import LinkButton from "../../../../../../../../../../../../components/linkbutton/linkbutton";
import { ColVisualDistributionSec } from "../../../queryblockprofiling/queryblockprofiling.comp/queryblockcolumnprofile/queryblockcolumnprofile.rendrer";

import { queryFieldSettingsSchema } from "../../../../../../../../../../../../utils/schemas/queryfieldsettingsschema";

import {
  FieldSettingsFormType,
  FieldSettingsMetricFormatType,
  QueryFieldSettingsProps,
} from "./queryfieldsettings.types";

import {
  FieldDetailsWrapperStyled,
  QueryFieldSettingsWrapperStyled,
  RadioGroupWrapper,
  VerticalSpacerStyled,
} from "./queryfieldsettings.styles";

import {
  DVSUM_TOOLTIP_CLASS_NAME,
  MOMENT_DATE_FORMATS,
} from "../../../../../../../../../../../../constants";

import {
  isDvsumPrefferedAmountMetricColumn,
  isUserPrefferedAmountMetricColumn,
  isDvsumPrefferedPercentageMetricColumn,
  isUserPrefferedPercentageMetricColumn,
  getChatPrefrencesData,
  updateChatPrefrencesData,
  checkDataTypeOfColumn,
  getDataTypeIcon,
  getUniqueList,
  isAmountMetricColumn,
  isPercentageMetricColumn,
} from "../../../../../../../../../../../../utils";

const AISuggestionSvg = aiSuggestIcon("15", "15");

const METRIC_OPTIONS = [
  {
    label: "Default",
    value: "default",
  },
  {
    label: "Percentage",
    value: "percentage",
  },
  {
    label: "Currency",
    value: "currency",
  },
];

const QueryFieldSettings = (props: QueryFieldSettingsProps): JSX.Element => {
  const chatPrefrences = getChatPrefrencesData();

  const { metric_format: metricFormat, date_format: dateFormat } =
    chatPrefrences || {};

  const {
    currency_cols_list: currencyColsList = [],
    percentage_cols_list: percentageColsList = [],
    cols_to_ignored_dvsum_defaut_logic: ignoredColsList = [],
  } = metricFormat || {};

  const { queryItem, handlers, selectedColumn } = props;

  const { onChangeQuerySettings } = handlers || {};

  const { id = "", results, settings } = queryItem || {};

  const { columns_profilig } = results || {};

  const { prefrences } = settings || {};

  const {
    data_type: colDataType = "STR",
    column_name: columnName = "",
    column_display_name: columnDisplayName = "",
    column_id: columnId,
    col_type_id: colTypeId,
  } = selectedColumn || {};

  const currentColumnDecimalPreference = useMemo(() => {
    return prefrences?.[columnName]?.decimals || 0;
  }, [columnName]);

  const isSelectedColInPercentageColsList = useMemo(
    () => isPercentageMetricColumn(columnDisplayName),
    [columnDisplayName, percentageColsList]
  );

  const isSelectedColInCurrencyColsList = useMemo(
    () => isAmountMetricColumn(columnDisplayName),
    [columnDisplayName, currencyColsList]
  );

  const fieldSettingsForm = useForm<FieldSettingsFormType>({
    defaultValues: {
      precision: currentColumnDecimalPreference || 2,
      date_format: dateFormat,
      metric_format: isSelectedColInPercentageColsList
        ? "percentage"
        : isSelectedColInCurrencyColsList
        ? "currency"
        : "default",
    },
    resolver: yupResolver(queryFieldSettingsSchema),
    mode: "onChange",
  });

  const {
    watch,
    formState: { isValid },
  } = fieldSettingsForm;

  const { precision } = watch();

  const { isDateColumn } = checkDataTypeOfColumn(colDataType);

  const isDecimalTypeColumn = colDataType === "DEC";
  const isMetricCol = colTypeId === "MET";

  const isAIRecommendedAmountFormat = useMemo(() => {
    return (
      isDvsumPrefferedAmountMetricColumn(columnDisplayName) &&
      !isUserPrefferedAmountMetricColumn(columnDisplayName)
    );
  }, [columnDisplayName]);

  const isAIRecommendedPercentageFormat = useMemo(() => {
    return (
      isDvsumPrefferedPercentageMetricColumn(columnDisplayName) &&
      !isUserPrefferedPercentageMetricColumn(columnDisplayName)
    );
  }, [columnDisplayName]);

  const selectedColumnProfilingData = useMemo(() => {
    return (
      columns_profilig?.filter((column) => column?.column_id === columnId) || []
    );
  }, [columns_profilig, columnId]);

  const showGridView = useCallback(() => {
    onChangeQuerySettings({ id, type: "view_change" });
  }, [id, onChangeQuerySettings]);

  const onPrecisionRangeChange = useCallback(
    (precision: number) => {
      onChangeQuerySettings?.({
        id,
        columnPrefrence: {
          name: columnName,
          decimals: precision,
        },
        callSaveApi: true,
      });
    },
    [id, columnName, onChangeQuerySettings]
  );

  const onDateFormateChange = useCallback(
    (selectedDateFormat: string) => {
      updateChatPrefrencesData({ date_format: selectedDateFormat });
      onChangeQuerySettings?.({
        id,
        dateFormat: selectedDateFormat,
      });
    },
    [id, onChangeQuerySettings]
  );

  const onMetricFormatChange = useCallback(
    (selectedMetricFormat: FieldSettingsMetricFormatType) => {
      const updatedPercentageColsList =
        selectedMetricFormat === "percentage"
          ? [...percentageColsList, columnDisplayName]
          : ["currency", "default"]?.includes(selectedMetricFormat) &&
            isSelectedColInPercentageColsList
          ? percentageColsList?.filter(
              (col) =>
                col?.toLocaleLowerCase()?.replaceAll(/_/g, " ") !==
                columnDisplayName?.toLocaleLowerCase()?.replaceAll(/_/g, " ")
            )
          : percentageColsList || [];

      const updatedCurrencyColsList =
        selectedMetricFormat === "currency"
          ? [...currencyColsList, columnDisplayName]
          : ["percentage", "default"]?.includes(selectedMetricFormat) &&
            isSelectedColInCurrencyColsList
          ? currencyColsList?.filter(
              (col) =>
                col?.toLocaleLowerCase()?.replaceAll(/_/g, " ") !==
                columnDisplayName?.toLocaleLowerCase()?.replaceAll(/_/g, " ")
            )
          : currencyColsList || [];

      const isUserRejectingDvSumProvidedMetricformat =
        isDvsumPrefferedAmountMetricColumn(columnDisplayName) ||
        isDvsumPrefferedPercentageMetricColumn(columnDisplayName);

      const updatedIgnoredColsList = isUserRejectingDvSumProvidedMetricformat
        ? getUniqueList([...ignoredColsList, columnDisplayName])
        : ignoredColsList;

      onChangeQuerySettings?.({
        id,
        metricFormat: {
          percentage_cols_list: updatedPercentageColsList,
          currency_cols_list: updatedCurrencyColsList,
          cols_to_ignored_dvsum_defaut_logic: updatedIgnoredColsList,
        },
      });
    },
    [
      id,
      columnDisplayName,
      percentageColsList,
      currencyColsList,
      isSelectedColInPercentageColsList,
      isSelectedColInCurrencyColsList,
      onChangeQuerySettings,
      ignoredColsList,
    ]
  );

  const precisionRangeValue = useDebounce(precision, 500);

  useEffect(() => {
    isValid && precision && onPrecisionRangeChange(precisionRangeValue);
  }, [precisionRangeValue]);

  return (
    <QueryFieldSettingsWrapperStyled>
      {selectedColumn ? (
        <>
          <div className="row-1">
            <CoreNodesCircleIcon
              width="19.2px"
              height="19.2px"
              nodeType="COL"
              fontSize="12px"
            />
            <span
              title={colDataType}
              className="col-profile-data-type-icon"
              data-testid="query-block-column-profile-data-type-icon"
            >
              {getDataTypeIcon(colDataType)}
            </span>
            <div
              title={columnName}
              className="column-name"
              data-testid="query-block-column-profile-column-name"
            >
              {columnName}
            </div>
          </div>
          <VerticalDividerStyled
            width="100%"
            height="1px"
            className="divider"
          />

          {/* {(isDateColumn || isDecimalTypeColumn || isMetricCol) && ( */}
          {true && (
            <FieldDetailsWrapperStyled>
              <div className="label">Display Format</div>
              <FormProvider {...fieldSettingsForm}>
                {isDateColumn ? (
                  <>
                    <div className="desc">
                      Select a relevant date format for your data. The default
                      preference for date format is{" "}
                      {MOMENT_DATE_FORMATS?.["DD/MM/YY"]}.
                    </div>
                    <DateFormatInput onChange={onDateFormateChange} />
                  </>
                ) : true ? (
                  <>
                    <div className="desc">
                      Increase or decrease the decimals for a more precise
                      value.
                    </div>
                    <RangeInput />
                  </>
                ) : (
                  <div />
                )}
                {isMetricCol && (
                  <>
                    <VerticalSpacerStyled />
                    <div className="metric-format">
                      <div className="label">Metric Format</div>
                    </div>
                    <RadioGroupWrapper>
                      <RadioGroupField
                        propOnChange={(e): void =>
                          onMetricFormatChange(e.target.value)
                        }
                        control={fieldSettingsForm.control}
                        name="metric_format"
                        options={METRIC_OPTIONS?.map((metricOption) => {
                          return {
                            label: (
                              <div
                                className="label-icon-wrapper"
                                key={metricOption?.value}
                              >
                                {metricOption?.label}
                                {((metricOption?.value === "percentage" &&
                                  isAIRecommendedPercentageFormat) ||
                                  (metricOption?.value === "currency" &&
                                    isAIRecommendedAmountFormat)) && (
                                  <span>
                                    <Tooltip
                                      overlayClassName={
                                        DVSUM_TOOLTIP_CLASS_NAME
                                      }
                                      title="Recommended by AI"
                                      placement="top"
                                    >
                                      {AISuggestionSvg}
                                    </Tooltip>
                                  </span>
                                )}
                              </div>
                            ),
                            value: metricOption?.value,
                          };
                        })}
                        innerCirclePrimaryBgColor
                        width="19px"
                        height="19px"
                      />
                    </RadioGroupWrapper>
                  </>
                )}
              </FormProvider>
            </FieldDetailsWrapperStyled>
          )}
        </>
      ) : (
        <div className="alert-container">
          <AlertStyled
            useFlexStart
            type="message"
            icon={infoIcon}
            showIcon
            message={
              <div className="alert-message">
                Click on a particular field from the{" "}
                <LinkButton className="view-name-span" onClick={showGridView}>
                  {" "}
                  grid view
                </LinkButton>{" "}
                to apply field level settings.
              </div>
            }
          />
        </div>
      )}

      {!!selectedColumnProfilingData?.length && (
        <ColVisualDistributionSec
          columnProfile={selectedColumnProfilingData?.[0]}
          queryItem={queryItem}
          handlers={handlers}
        />
      )}
    </QueryFieldSettingsWrapperStyled>
  );
};

export default QueryFieldSettings;
