import { removeIndex } from "../../../../utils";

import { TagType } from "../../tagsetsdetailpage.types";

export const allSelected = (
  isChecked: boolean,
  allTags: TagType[]
): number[] => {
  if (isChecked) {
    const selectedTags =
      allTags?.map((tag) => tag?.tag_id)?.filter((tagId) => tagId !== -1) || [];

    return selectedTags;
  }

  return [];
};

export const updateSelectedTagsIds = (
  isChecked: boolean,
  selectedTagId: number,
  selectedTagsIds: number[]
): number[] => {
  if (!isChecked) {
    const selectedTagIdIndex = selectedTagsIds?.findIndex(
      (tagId) => tagId === selectedTagId
    );

    return [...removeIndex([...selectedTagsIds], selectedTagIdIndex)];
  }

  return [...selectedTagsIds, selectedTagId];
};
