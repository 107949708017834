import styled from "styled-components";

export const MarkAsDeprecateSuggestionFormStyled = styled.div<{
  isRefPage: boolean;
  isExtOrDesktop: boolean;
}>`
  padding-top: ${({ isRefPage }): string => (isRefPage ? "0px" : "30px")};

  .suggest-header {
    font-family: OpenSansSemiBold;
    height: 70px;
    padding: 0 31px 0 27px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${({ theme }): string => theme.textColors.BLUE_116};
    font-size: 26px;
    svg {
      path {
        fill: ${({ theme }): string => theme.bgColors.RED_100};
        opacity: 0.5;
      }

      :hover {
        path {
          opacity: unset;
        }
      }
    }
  }

  .scroll-sec {
    ${({ isRefPage, isExtOrDesktop }): any =>
      isRefPage &&
      `height: calc(100vh - ${
        isExtOrDesktop ? "182px" : "227px"
      }); overflow: auto;`}
  }
`;
