import { useCallback } from "react";

import {
  SingleSelectionPopupContentStyled,
  SingleSelectionPopupItemStyled,
} from "./singleselectionpopupcontent.styles";

import { SingleSelectionPopupContentProps } from "./singleselectionpopupcontent.types";
import Flex from "../../../flex";

const SingleSelectionPopupContent = (
  props: SingleSelectionPopupContentProps
): JSX.Element => {
  const {
    options = [],
    selectedOption,
    onChange,
    onVisibleChange,
    contentId = "",
    width = "200px",
  } = props;

  const onClick = useCallback(
    (clickedOption) => (): void => {
      onChange?.(clickedOption);
      onVisibleChange?.(false);
    },
    [onChange, onVisibleChange]
  );

  return (
    <SingleSelectionPopupContentStyled id={contentId} width={width}>
      {options?.map((option, index) => {
        return (
          <>
            <SingleSelectionPopupItemStyled
              key={`${option?.value}-${index}`}
              onClick={onClick(option?.value)}
              isSelected={option?.value === selectedOption}
            >
              <Flex columnGap={8}>
                {option?.icon && option?.icon}
                <div>{option?.label}</div>
              </Flex>
              {(option?.count ?? -1) >= 0 && (
                <div className="count">{`(${option?.count})`}</div>
              )}
            </SingleSelectionPopupItemStyled>
            {option?.showSeparator && options?.length > 1 && (
              <div className="opt-separator" />
            )}
          </>
        );
      })}
    </SingleSelectionPopupContentStyled>
  );
};

export default SingleSelectionPopupContent;
