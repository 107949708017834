export const tagsetsBlankSlate = (
  <svg width="100%" viewBox="0 0 1558.428 284.5">
    <g id="Group_2665" data-name="Group 2665" transform="translate(-276 -267)">
      <rect
        id="Rectangle_1566"
        data-name="Rectangle 1566"
        width="169"
        height="11.5"
        rx="5.75"
        transform="translate(276 323)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1663"
        data-name="Rectangle 1663"
        width="169"
        height="11.5"
        rx="5.75"
        transform="translate(276 375)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1664"
        data-name="Rectangle 1664"
        width="169"
        height="11.5"
        rx="5.75"
        transform="translate(276 429)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1665"
        data-name="Rectangle 1665"
        width="169"
        height="11.5"
        rx="5.75"
        transform="translate(276 485)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1666"
        data-name="Rectangle 1666"
        width="169"
        height="11.5"
        rx="5.75"
        transform="translate(276 540)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1607"
        data-name="Rectangle 1607"
        width="230.979"
        height="11.5"
        rx="5.75"
        transform="translate(1076 323)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1680"
        data-name="Rectangle 1680"
        width="87.5"
        height="11.5"
        rx="5.75"
        transform="translate(1447 323)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1686"
        data-name="Rectangle 1686"
        width="169.428"
        height="11.5"
        rx="5.75"
        transform="translate(1665 323)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1683"
        data-name="Rectangle 1683"
        width="87.5"
        height="11.5"
        rx="5.75"
        transform="translate(1447 485)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1689"
        data-name="Rectangle 1689"
        width="169.428"
        height="11.5"
        rx="5.75"
        transform="translate(1665 485)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1676"
        data-name="Rectangle 1676"
        width="230.979"
        height="11.5"
        rx="5.75"
        transform="translate(1076 375)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1681"
        data-name="Rectangle 1681"
        width="87.5"
        height="11.5"
        rx="5.75"
        transform="translate(1447 375)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1687"
        data-name="Rectangle 1687"
        width="169.428"
        height="11.5"
        rx="5.75"
        transform="translate(1665 375)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1684"
        data-name="Rectangle 1684"
        width="87.5"
        height="11.5"
        rx="5.75"
        transform="translate(1447 540)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1690"
        data-name="Rectangle 1690"
        width="169.428"
        height="11.5"
        rx="5.75"
        transform="translate(1665 540)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1677"
        data-name="Rectangle 1677"
        width="230.979"
        height="11.5"
        rx="5.75"
        transform="translate(1076 429)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1682"
        data-name="Rectangle 1682"
        width="87.5"
        height="11.5"
        rx="5.75"
        transform="translate(1447 429)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1688"
        data-name="Rectangle 1688"
        width="169.428"
        height="11.5"
        rx="5.75"
        transform="translate(1665 429)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1678"
        data-name="Rectangle 1678"
        width="230.979"
        height="11.5"
        rx="5.75"
        transform="translate(1076 485)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1679"
        data-name="Rectangle 1679"
        width="230.979"
        height="11.5"
        rx="5.75"
        transform="translate(1076 540)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1593"
        data-name="Rectangle 1593"
        width="385.189"
        height="11.5"
        rx="5.75"
        transform="translate(576 323)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1672"
        data-name="Rectangle 1672"
        width="385.189"
        height="11.5"
        rx="5.75"
        transform="translate(576 375)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1673"
        data-name="Rectangle 1673"
        width="385.189"
        height="11.5"
        rx="5.75"
        transform="translate(576 429)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1674"
        data-name="Rectangle 1674"
        width="385.189"
        height="11.5"
        rx="5.75"
        transform="translate(576 485)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1675"
        data-name="Rectangle 1675"
        width="385.189"
        height="11.5"
        rx="5.75"
        transform="translate(576 540)"
        fill="#f0f0f0"
      />
      <text
        id="Role"
        transform="translate(276 286)"
        fontSize="14"
        fontFamily="OpenSansSemibold, OpenSans"
        fontWeight="600"
      >
        <tspan x="0" y="0">
          Tagset Name
        </tspan>
      </text>
      <text
        id="Modified_By"
        data-name="Modified By"
        transform="translate(1447 286)"
        fontSize="14"
        fontFamily="OpenSansSemibold, OpenSans"
        fontWeight="600"
      >
        <tspan x="0" y="0">
          Usage Count
        </tspan>
      </text>
      <text
        id="Created_By"
        data-name="Created By"
        transform="translate(1076 286)"
        fontSize="14"
        fontFamily="OpenSansSemibold, OpenSans"
        fontWeight="600"
      >
        <tspan x="0" y="0">
          Applicable To
        </tspan>
      </text>
      <g
        id="Group_2101"
        data-name="Group 2101"
        transform="translate(574.223 267)"
      >
        <text
          id="Description"
          transform="translate(-0.223 19)"
          fontSize="14"
          fontFamily="OpenSansSemibold, OpenSans"
          fontWeight="600"
        >
          <tspan x="0" y="0">
            Tags
          </tspan>
        </text>
      </g>
      <text
        id="Modified_By-2"
        data-name="Modified By"
        transform="translate(1664 286)"
        fontSize="14"
        fontFamily="OpenSansSemibold, OpenSans"
        fontWeight="600"
      >
        <tspan x="0" y="0">
          Modified On
        </tspan>
      </text>
    </g>
  </svg>
);

export const tagsetDetailsBlankSlate = (
  <svg width="100%" viewBox="0 0 1799.999 877">
    <g id="Group_2589" data-name="Group 2589" transform="translate(-95 -141)">
      <g
        id="Group_2523"
        data-name="Group 2523"
        transform="translate(163.266 -4.934)"
      >
        <path
          id="Path_2639"
          data-name="Path 2639"
          d="M0-2H88.2V38H0Z"
          transform="translate(1643.533 147.933)"
          fill="#f0f0f0"
        />
      </g>
      <text
        id="Created_by"
        data-name="Created by"
        transform="translate(98 203)"
        fill="#4c4c4c"
        fontSize="17"
        fontFamily="OpenSans, Open Sans"
        letterSpacing="-0.005em"
      >
        <tspan x="0" y="0">
          Created by
        </tspan>
      </text>
      <text
        id="Updated_by"
        data-name="Updated by"
        transform="translate(98 235)"
        fill="#4c4c4c"
        fontSize="17"
        fontFamily="OpenSans, Open Sans"
        letterSpacing="-0.005em"
      >
        <tspan x="0" y="0">
          Updated by
        </tspan>
      </text>
      <text
        id="Name:"
        transform="translate(96 361)"
        fill="#4c4c4c"
        fontSize="17"
        fontFamily="OpenSans, Open Sans"
      >
        <tspan x="0" y="0">
          Name:
        </tspan>
      </text>
      <text
        id="Information"
        transform="translate(95 299)"
        fill="#328db5"
        fontSize="22"
        fontFamily="OpenSansSemiBold, Open Sans"
        fontWeight="600"
      >
        <tspan x="0" y="0">
          Information
        </tspan>
      </text>
      <text
        id="Tags"
        transform="translate(95 683)"
        fill="#328db5"
        fontSize="22"
        fontFamily="OpenSansSemiBold, Open Sans"
        fontWeight="600"
      >
        <tspan x="0" y="0">
          Tags
        </tspan>
      </text>
      <path
        id="Path_2803"
        data-name="Path 2803"
        d="M0,0H1182"
        transform="translate(95.5 319)"
        fill="none"
        stroke="#707070"
        strokeWidth="1"
        opacity="0.1"
      />
      <path
        id="Path_2804"
        data-name="Path 2804"
        d="M0,0H1169"
        transform="translate(95.5 703)"
        fill="none"
        stroke="#707070"
        strokeWidth="1"
        opacity="0.1"
      />
      <text
        id="Desciption:_"
        data-name="Desciption: "
        transform="translate(96 401)"
        fill="#4c4c4c"
        fontSize="17"
        fontFamily="OpenSans, Open Sans"
        letterSpacing="-0.005em"
      >
        <tspan x="0" y="0">
          Desciption:{" "}
        </tspan>
      </text>
      <text
        id="Applicable_To:"
        data-name="Applicable To:"
        transform="translate(96 556)"
        fill="#4c4c4c"
        fontSize="17"
        fontFamily="OpenSans, Open Sans"
        letterSpacing="-0.005em"
      >
        <tspan x="0" y="0">
          Applicable To:
        </tspan>
      </text>
      <text
        id="Allow_New_Values_During_Tagging:"
        data-name="Allow New Values During Tagging:"
        transform="translate(96 470)"
        fill="#4c4c4c"
        fontSize="17"
        fontFamily="OpenSans, Open Sans"
        letterSpacing="-0.005em"
      >
        <tspan x="0" y="0">
          Allow New Values During Tagging:
        </tspan>
      </text>
      <text
        id="Single_Value:"
        data-name="Single Value:"
        transform="translate(96 513)"
        fill="#4c4c4c"
        fontSize="17"
        fontFamily="OpenSans, Open Sans"
        letterSpacing="-0.005em"
      >
        <tspan x="0" y="0">
          Single Value:
        </tspan>
      </text>
      <g
        id="Group_2526"
        data-name="Group 2526"
        transform="translate(-894.625 139)"
      >
        <text
          id="Role"
          transform="translate(989.625 615)"
          fontSize="18"
          fontFamily="OpenSansSemiBold, Open Sans"
          fontWeight="600"
        >
          <tspan x="0" y="0">
            Tag
          </tspan>
        </text>
        <text
          id="Description"
          transform="translate(1301.625 615)"
          fontSize="18"
          fontFamily="OpenSansSemiBold, Open Sans"
          fontWeight="600"
        >
          <tspan x="0" y="0">
            Description
          </tspan>
        </text>
        <text
          id="Description-2"
          data-name="Description"
          transform="translate(1926.625 615)"
          fontSize="18"
          fontFamily="OpenSansSemiBold, Open Sans"
          fontWeight="600"
        >
          <tspan x="0" y="0">
            Usage Count
          </tspan>
        </text>
        <line
          id="Line_397"
          data-name="Line 397"
          x2="1158.727"
          transform="translate(992.125 682.5)"
          fill="none"
          stroke="#707070"
          strokeWidth="1"
          opacity="0.1"
        />
        <line
          id="Line_397-2"
          data-name="Line 397"
          x2="1158.727"
          transform="translate(989.625 791.5)"
          fill="none"
          stroke="#707070"
          strokeWidth="1"
          opacity="0.1"
        />
        <line
          id="Line_397-3"
          data-name="Line 397"
          x2="1158.727"
          transform="translate(989.625 845.5)"
          fill="none"
          stroke="#707070"
          strokeWidth="1"
          opacity="0.1"
        />
        <line
          id="Line_414"
          data-name="Line 414"
          x2="1158.727"
          transform="translate(989.625 736.5)"
          fill="none"
          stroke="#707070"
          strokeWidth="1"
          opacity="0.1"
        />
      </g>
      <rect
        id="Rectangle_1537"
        data-name="Rectangle 1537"
        width="284"
        height="14"
        rx="7"
        transform="translate(98 150)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1545"
        data-name="Rectangle 1545"
        width="183"
        height="14"
        rx="7"
        transform="translate(195 189)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1546"
        data-name="Rectangle 1546"
        width="183"
        height="14"
        rx="7"
        transform="translate(195 223)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1538"
        data-name="Rectangle 1538"
        width="502"
        height="14"
        rx="7"
        transform="translate(406 388)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1541"
        data-name="Rectangle 1541"
        width="170"
        height="14"
        rx="7"
        transform="translate(406 348)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1563"
        data-name="Rectangle 1563"
        width="77"
        height="14"
        rx="7"
        transform="translate(1705 154)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1548"
        data-name="Rectangle 1548"
        width="170"
        height="14"
        rx="7"
        transform="translate(96 788)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1553"
        data-name="Rectangle 1553"
        width="501"
        height="14"
        rx="7"
        transform="translate(406 788)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1558"
        data-name="Rectangle 1558"
        width="41"
        height="14"
        rx="7"
        transform="translate(1032 788)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1549"
        data-name="Rectangle 1549"
        width="170"
        height="14"
        rx="7"
        transform="translate(96 840)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1554"
        data-name="Rectangle 1554"
        width="501"
        height="14"
        rx="7"
        transform="translate(406 840)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1559"
        data-name="Rectangle 1559"
        width="41"
        height="14"
        rx="7"
        transform="translate(1032 840)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1550"
        data-name="Rectangle 1550"
        width="170"
        height="14"
        rx="7"
        transform="translate(96 894)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1555"
        data-name="Rectangle 1555"
        width="501"
        height="14"
        rx="7"
        transform="translate(406 894)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1560"
        data-name="Rectangle 1560"
        width="41"
        height="14"
        rx="7"
        transform="translate(1032 894)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1551"
        data-name="Rectangle 1551"
        width="170"
        height="14"
        rx="7"
        transform="translate(96 952)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1556"
        data-name="Rectangle 1556"
        width="501"
        height="14"
        rx="7"
        transform="translate(406 952)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1561"
        data-name="Rectangle 1561"
        width="41"
        height="14"
        rx="7"
        transform="translate(1032 952)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1552"
        data-name="Rectangle 1552"
        width="170"
        height="14"
        rx="7"
        transform="translate(96 1004)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1557"
        data-name="Rectangle 1557"
        width="501"
        height="14"
        rx="7"
        transform="translate(406 1004)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1562"
        data-name="Rectangle 1562"
        width="41"
        height="14"
        rx="7"
        transform="translate(1032 1004)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1539"
        data-name="Rectangle 1539"
        width="502"
        height="14"
        rx="7"
        transform="translate(406 413)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1540"
        data-name="Rectangle 1540"
        width="97"
        height="14"
        rx="7"
        transform="translate(406 543)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1547"
        data-name="Rectangle 1547"
        width="22"
        height="22"
        transform="translate(406 453)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1565"
        data-name="Rectangle 1565"
        width="22"
        height="22"
        transform="translate(406 496)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1543"
        data-name="Rectangle 1543"
        width="97"
        height="14"
        rx="7"
        transform="translate(406 572)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1542"
        data-name="Rectangle 1542"
        width="216"
        height="14"
        rx="7"
        transform="translate(518 543)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1544"
        data-name="Rectangle 1544"
        width="216"
        height="14"
        rx="7"
        transform="translate(518 572)"
        fill="#f0f0f0"
      />
    </g>
  </svg>
);