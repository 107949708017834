import { AxiosResponse } from "axios";
import { SelectFieldOption } from "../../components/formfields/selectfield/selectfield.types";

import { UsersParserReturnType, UsersResponseType } from "./usersparser.types";
import { sortListOnSpecificKeyValue, sortObjectsArrayByKey } from "../../utils";

export function getParsedUsers({
  data,
}: AxiosResponse<UsersResponseType[]>): UsersParserReturnType[] {
  return data?.map((item) => ({
    id: item?.USER_ID,
    firstName: item?.USER_FIRST_NAME || "",
    lastName: item?.USER_LAST_NAME || "",
    email: item?.USER_EMAIL || "",
    timeZone: item?.USER_TIME_ZONE || "",
    lastLoginDate: item?.USER_LAST_LOGIN_DATE || "",
    expiryDate: item?.USER_EXPIRY_DATE || "",
    lastLogin: item?.USER_LAST_LOGIN_DATE || "",
    deletionDate: item?.DELETION_DATE || "",
    userGroupId: item?.USER_GROUP_ID,
    preferences: item?.USER_SETTINGS || "",
    createdOn: item?.CREATED_ON || "",
    createdBy: item?.CREATED_BY || "",
    updatedOn: item?.UPDATED_ON || "",
    updatedBy: item?.UPDATED_BY || "",
    userGroupName: item?.USER_GROUP_NAME || "",
    firstLoginDate: item?.USER_START_DATE || "",
    externalUserId: item?.USER_EXTERNAL_ID,
    status: item?.USER_STATUS_UI_NAME || "",
    statusId: item?.USER_STATUS_UI || "",
    action: "",
    adminAccessTypeId: item?.ADMIN_ACCESS_TYPE,
    isOwnerAccount: item?.IS_OWNER_ACCOUNT || false,
    isDataSecurityAdmin: item?.IS_DATA_SECURITY_ADMIN || false,
    catalogAccessType: item?.CATALOG_ACCESS_TYPE,
  }));
}

export function getParsedActiveUsers({
  data,
}: AxiosResponse<UsersResponseType[]>): UsersParserReturnType[] {
  const sortedUsers = sortListOnSpecificKeyValue({
    list: data,
    key: "USER_FIRST_NAME",
  });
  const activeUsers = sortedUsers?.filter(
    (user) => user?.USER_STATUS_UI === "ACT"
  );

  return activeUsers?.map((item) => ({
    id: item?.USER_ID,
    firstName: item?.USER_FIRST_NAME || "",
    lastName: item?.USER_LAST_NAME || "",
    email: item?.USER_EMAIL || "",
    timeZone: item?.USER_TIME_ZONE || "",
    lastLoginDate: item?.USER_LAST_LOGIN_DATE || "",
    expiryDate: item?.USER_EXPIRY_DATE || "",
    lastLogin: item?.USER_LAST_LOGIN_DATE || "",
    deletionDate: item?.DELETION_DATE || "",
    userGroupId: item?.USER_GROUP_ID,
    preferences: item?.USER_SETTINGS || "",
    createdOn: item?.CREATED_ON || "",
    createdBy: item?.CREATED_BY || "",
    updatedOn: item?.UPDATED_ON || "",
    updatedBy: item?.UPDATED_BY || "",
    userGroupName: item?.USER_GROUP_NAME || "",
    firstLoginDate: item?.USER_START_DATE || "",
    externalUserId: item?.USER_EXTERNAL_ID,
    status: item?.USER_STATUS_UI_NAME || "",
    statusId: item?.USER_STATUS_UI || "",
    action: "",
    adminAccessTypeId: item?.ADMIN_ACCESS_TYPE,
    isOwnerAccount: item?.IS_OWNER_ACCOUNT || false,
    isDataSecurityAdmin: item?.IS_DATA_SECURITY_ADMIN || false,
    catalogAccessType: item?.CATALOG_ACCESS_TYPE,
  }));
}

export function getParsedUsersForSelect({
  data,
}: AxiosResponse<UsersResponseType[]>): SelectFieldOption[] {
  return data?.map((item) => ({
    label: `${item?.USER_FIRST_NAME || ""} ${item?.USER_LAST_NAME || ""}`,
    value: `${item?.USER_ID}`,
  }));
}

export function getParsedDataSecurityAdminUsers({
  data,
}: AxiosResponse<UsersResponseType[]>): UsersParserReturnType[] {
  const securityAdmins =
    data?.filter((user: UsersResponseType) => user?.IS_DATA_SECURITY_ADMIN) ||
    [];

  const sortedSecurityAdmins: UsersResponseType[] = sortListOnSpecificKeyValue({
    list: securityAdmins,
    key: "USER_FIRST_NAME",
  });

  return sortedSecurityAdmins?.map((item) => ({
    id: item?.USER_ID,
    firstName: item?.USER_FIRST_NAME || "",
    lastName: item?.USER_LAST_NAME || "",
    email: item?.USER_EMAIL || "",
    timeZone: item?.USER_TIME_ZONE || "",
    lastLoginDate: item?.USER_LAST_LOGIN_DATE || "",
    expiryDate: item?.USER_EXPIRY_DATE || "",
    lastLogin: item?.USER_LAST_LOGIN_DATE || "",
    deletionDate: item?.DELETION_DATE || "",
    userGroupId: item?.USER_GROUP_ID,
    preferences: item?.USER_SETTINGS || "",
    createdOn: item?.CREATED_ON || "",
    createdBy: item?.CREATED_BY || "",
    updatedOn: item?.UPDATED_ON || "",
    updatedBy: item?.UPDATED_BY || "",
    userGroupName: item?.USER_GROUP_NAME || "",
    firstLoginDate: item?.USER_START_DATE || "",
    externalUserId: item?.USER_EXTERNAL_ID,
    status: item?.USER_STATUS_UI_NAME || "",
    statusId: item?.USER_STATUS_UI || "",
    action: "",
    adminAccessTypeId: item?.ADMIN_ACCESS_TYPE,
    isOwnerAccount: item?.IS_OWNER_ACCOUNT || false,
    isDataSecurityAdmin: item?.IS_DATA_SECURITY_ADMIN || false,
    catalogAccessType: item?.CATALOG_ACCESS_TYPE,
  }));
}
