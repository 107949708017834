import styled from "styled-components";

export const SSAHeaderShareModalStyled = styled.div`
  .ant-form {
    .ant-form-item-control-input {
      .ant-form-item-control-input-content {
        .ant-radio-group {
          .ant-radio-wrapper {
            width: 220px !important;
          }
        }
      }
    }
    .ant-form-item {
      .ant-form-item-control {
        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            .textarea-field-container > textarea {
              height: 250px !important;
            }
          }
        }
      }
    }
  }
`;

export const NoteStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 0 112px;

  .iwt-icon {
    svg {
      height: 11px;
    }
  }

  .iwt-icon-text {
    color: #4c4c4c;
    font-style: italic;
    font-size: 13px;
    opacity: 0.7;
  }
`;
