import { ColumnState } from "ag-grid-community";

import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "react-query";

import { ColTypeIds, ColumnDataType, ColumnProfilingInfo } from "../../parsers";

import {
  AnalysisConfigurationType,
  AnalysisQueriesResponseType,
  ChatMetricFormatType,
  GuideMeDataType,
  QuerySubType,
  QueryType,
  ReturnTypeOfAnalysisDetailPageParser,
  ReturnTypeOfAnalysisQueriesParser,
  ReturnTypeOfAnalysisTopicsList,
  UserAccessType,
} from "../../parsers/analysisparser/analysisparser.types";

import { NodePublishedStatusIds } from "../../parsers/parser.types";
import { ShareLevelId } from "../../components/sharelevelicon/sharelevelicon.types";

import { KeyValuePairType, NodeType, SourceTypes } from "../../app.types";
import { CustomReactQueryHookReturnType } from "../../api/services.types";
import { SpecifyCriteriaReadModeType } from "../../components/criteriaselection/criteriaselection.types";
import { QueryBlocksProps } from "./analysisdetailpage.views";

export type QueryBoxResultDataType = { [key: string]: string }[];

export type QueryStatus = "all" | "PS" | "FL" | "NR" | "" | "RT";

export type FilterModelType =
  | "nonBlanks"
  | "blanks"
  | "inRange"
  | "equals"
  | "set";

export type QueryBlockGridPagination = {
  current_page: number;
  page_size: number;
};

export type QueryBlockGridFilterModelType = {
  filter?: number;
  filterTo?: number;
  dateFrom?: string;
  dateTo?: string | null;
  filterType?: "date" | "number" | "set";
  type?: FilterModelType;
  values?: string[];
};

export type QueryBlockGridFilter = {
  col_display_name: string;
  colm_name: string;
  operator: string;
  col_data_type: ColumnDataType;
  filter_model?: QueryBlockGridFilterModelType;
};

export type QueryBoxFiltersType = {
  [key: string]: QueryBlockGridFilter;
};

export type AnalysisPageHeaderState = {
  title: string;
  desc: string;
  view: {
    is_collapse: boolean;
    is_expanded: boolean;
  };
  actions: {
    is_expanded: boolean;
  };
};

export type AnalysisPageQueryBoxResultsColumns = {
  column_id: string;
  column_name: string;
  column_display_name: string;
  description: string;
  data_type: ColumnDataType;
  data_type_info: string;
  col_status: string;
  col_status_id: NodePublishedStatusIds;
  is_selected: boolean;
  is_applied: boolean;
  col_type_id?: ColTypeIds;
  col_dist_count?: number;
  is_col_masked: boolean;
  is_qry_contains_perc_fmt?: boolean;
};

export type QueryBoxLoaderType =
  | "fetching_results"
  | "running_analysis"
  | "fetching_paginated_results"
  | "newly_added_question"
  | "fetching_results_after_refresh"
  | "reloading_prev_queried_data"
  | undefined;

export type QueryResultType = "chart" | "grid" | "pivot";

export type QueryChartType = "Line" | "Column" | "Tile" | "N/A";

export type UserFeedbackReason = "WQ" | "WD" | "WC" | "WV" | "OTH" | "ND" | "";

export type UserFeedackOnQuery = {
  response: "like" | "dislike" | "";
  comment?: string;
  reason?: UserFeedbackReason;
  train_me?: string;
  train_sql?: string;
};

export type AnalysisPageQueryBoxPivotStateType = { [key: string]: any };

export type AnalysisPageQueryBoxChartConfig = {
  // only in local state
  row_count?: number;
  column_count?: number;
  metric_count?: number;
  attribute_count?: number;
  datetime_count?: number;
  metric_columns?: AnalysisPageQueryBoxResultsColumns[];
  attribute_columns?: AnalysisPageQueryBoxResultsColumns[];
  datetime_columns?: AnalysisPageQueryBoxResultsColumns[];
  chart_type?: QueryChartType;
  data?: {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
    }[];
  };
  ist_metric_median?: number;
  second_metric_median?: number;
  ratio_of_ist_second_metric?: number;
  min_date?: string;
  max_date?: string;
  date_aggregated_by?:
    | "Aggregated to Hour"
    | "Aggregated to day"
    | "Aggregated to month"
    | "Aggregated to year";
  is_stacked_chart?: boolean;
  tile_data?: { count: number; title: string; data_type: ColumnDataType };
  chart_x2_axis_side_by_side?: string;
  // only in local state

  // will be removed in future
  chart_y_axis?: string;
  chart_y_axis_data_type?: ColumnDataType;
  chart_y_axis_orientation?: "right" | "left";
  is_hide_chart_y_axis?: boolean;
  chart_y2_axis?: string;
  chart_y2_axis_data_type?: ColumnDataType;
  chart_y2_axis_orientation?: "right" | "left";
  is_hide_chart_y2_axis?: boolean;
  // will be removed in future

  // will be saved in db
  chart_x_axis?: string;
  chart_x_Axis_data_type?: ColumnDataType;
  chart_y_axises?: {
    name: string;
    display_name: string;
    data_type: ColumnDataType;
    orientation?: "right" | "left";
    hide?: boolean;
    order: number;
  }[];
  sort_direction?: "asc" | "desc";
  sort_info_of_y_axis?: { name?: string; direction?: "asc" | "desc" };
  has_no_y_axes?: boolean;
  is_default_settings?: boolean;
  // will be saved in db
};

export type QuerySettingsTabType = "general" | "chart" | "field";

export type QuerySettingsType = {
  visible: boolean;
  is_data_formatted: boolean;
  tab: QuerySettingsTabType;
  date_format?: string;
  metric_format?: ChatMetricFormatType;
  // Te check what was the prev state of metric format
  prev_metric_format?: ChatMetricFormatType;
  prefrences: {
    [key: string]: {
      decimals: number;
    };
  };
};

export type SortModelType = {
  colId: string;
  sort?: "asc" | "desc";
}[];

export type TrainbotFilters =
  | QueryStatus
  | "CNP"
  | "APR"
  | "REJ"
  | "INC"
  | "SVD";

export type TrainBotTimeframeFilters =
  | "last_1_day"
  | "last_7_day"
  | "last_30_days"
  | "last_6_months"
  | "last_1_year"
  | "all";

export type ContextType = {
  id: string;
  title: string;
  selected: boolean;
  custom_context?: boolean;
  parent_id?: string;
};

export type QueryExplanationType =
  | Array<{ heading: string; desc: string }>
  | string;

export type AnalysisPageQueryBox = {
  qry_sec_id: string | null;
  type: QueryType;
  sub_type?: QuerySubType;
  qry_status: QueryStatus;
  qry_retry_count: number;
  src_id: string;
  src_name: string;
  table_id: string;
  table_name: string;
  selected_topic_id: number;
  qry_exec_by: string;
  qry_exec_on: string;
  qry_created_by: string;
  qry_created_by_first_name: string;
  qry_created_by_last_name: string;
  qry_created_on: string;
  qry_exec_by_id: number;
  qry_updated_by: string;
  qry_updated_on: string;
  qry_created_by_id: number;
  qry_updated_by_id: number;
  qry_prf_results_s3_url: string;
  qry_tmp_results_s3_url: string;
  qry_full_results_s3_url: string;
  verbose_s3_url: string;
  is_loading_verbose_logs: boolean;
  is_loading: boolean;
  error?: any;
  loader_type: QueryBoxLoaderType;
  loading_step?: 1 | 2 | 3;
  is_animation_completed?: boolean;
  id: string;
  is_expanded: boolean;
  is_expanded_prev_state?: boolean;
  is_focused: boolean;
  is_selected: boolean;
  user_feedback: UserFeedackOnQuery;
  use_sql_tool: boolean;
  use_du_tool: boolean;
  use_general_knowledge_tool: boolean;
  rephrased_question: string;
  header: {
    title: string;
    is_maximize_query_block: boolean;
    is_profiled: boolean;
    is_chart_settings_visible: boolean;
    is_editing_query_mode: boolean;
    edited_query_value: string;
    is_sql_query_visible: boolean;
    is_explanation_visible: boolean;
    is_show_contexts_list: boolean;
    is_use_context: boolean;
    query_context: ContextType[];
    result_type: QueryResultType;
    is_verbose_visible: boolean;
    is_question_saved: boolean;
  };
  query: {
    sql_query: string;
    is_filter_expanded: boolean;
    is_filter_edit_mode_expanded: boolean;
    filters: QueryBoxFiltersType;
  };
  filters: {
    transiant_filters: QueryBoxFiltersType;
    applied_filters: QueryBoxFiltersType;
    sortModel: SortModelType;
  };
  results: {
    selected_cell?: {
      index: number;
      value: string;
      col_id: string;
    };
    last_refreshed: string;
    columns: AnalysisPageQueryBoxResultsColumns[];
    columns_profilig: ColumnProfilingInfo[];
    is_server_side_filteration: boolean;
    data: QueryBoxResultDataType;
    pagination: QueryBlockGridPagination;
    tbl_record_count: number;
    is_sample_data: boolean;
    is_caddi_sample_data?: boolean;
    record_count: number;
    grid_record_count: number;
    summary?: string;
    summary_response_keys?: Array<string>;
    guide_me_data?: GuideMeDataType;
    prompt_summary?: {
      source: string;
      source_type: string;
      tables: { id: number; name: string; title: string }[];
      refresh_ts: {
        min: string;
        max: string;
      };
      stewards: { name: string; email: string }[];
      introduction: string;
      aboutText: string;
    };
    questiont_answer?: string;
    formatted_answer?: string;
    tables_used_in_answer?: { id: string; name: string }[];
    query_explanation?: QueryExplanationType;
    verbose_response?: string[];
    col_preprocess_info?: {
      sorted_data_by: string;
    };
    chart_config?: AnalysisPageQueryBoxChartConfig;
    pivot_state?: ColumnState[];
    sec_title: string;
  };
  settings: QuerySettingsType;
  is_newly_added_question?: boolean;
  parent_query_id?: string;
  sub_queries?: AnalysisPageQueryBox[];
};

export type AnalysisSchemasListType = Array<{
  title: string;
  tables: Array<{
    id: number;
    title: string;
    isPinned: boolean;
    name: string;
  }>;
}>;

export type PinnedTableType = {
  id: number;
  title: string;
  name: string;
};
export type SharedWithUserType = {
  name: string;
  type: NodeType;
  accessType: UserAccessType;
  email: string;
  id: string;
};

export type SharedWithType = Array<SharedWithUserType>;

export type AnalaysisPageState = {
  header: AnalysisPageHeaderState;
  queries: AnalysisPageQueryBox[];
  pinnedTables: PinnedTableType[];
  // isLoading?: boolean;
  analysis_info: {
    is_node_cloned: boolean;
    parent_analysis_id: number;
    analysis_id: number;
    created_by: { name: string; email: string };
    tables_list: Array<string>;
    share_type: ShareLevelId;
    shared_with: SharedWithType;
    datasource_used: {
      name: string;
      type: SourceTypes;
    };
    selected_contexts: ContextType[];
    selected_topic_id: number;
  };
  table_id: string;
  src_id: string;
  asked_by_filter?: string[];
  time_frame_filter?: TrainBotTimeframeFilters;
  train_bot_filter?: TrainbotFilters;
  is_scope_enabled: boolean;
  is_verbose: boolean;
  tableFilter: SpecifyCriteriaReadModeType[];
  columnFilter: SpecifyCriteriaReadModeType[];
  configuration: AnalysisConfigurationType;
  aiChatModel: string;
  aiChatModelName: string;
  gatewayId: string;
};

export type AnalysisDetailPageParamsState = {
  sourceId: string;
  sourceName: string;
  sourceTypeId: SourceTypes;
  isCloned?: boolean;
  isFromPage?: string;
  isFromRoute?: string;
};

export type MessageContent = {
  question: string;
  answer: string;
  query: string;
  tables: string[];
  table_id_map: { [key: string]: string };
  isSql: boolean;
  verbose_response: string;
  explanation: string;
  is_completion_msg: boolean;
  start_time: string;
  end_time: string;
  timestamp: number;
  is_last_response: boolean;
};

export type SocketResType = {
  query_id: string;
  query_text: string;
  total_records: number;
  records_per_page: number;
  s3_pre_signed_url_10k_file: string;
  s3_pre_signed_url_profile_json: string;
  sample_records_json: string;
  profile_json: string;
  first_page_results: string;
  tbl_record_count: number;
  summary?: string;
  summary_response_keys?: Array<string>;
  message_content: MessageContent;
  status_code: number;
  qry_status: QueryStatus;
  is_last_msg: boolean;
  is_term_response: boolean;
  verbose_s3_url: string;
  responses?: any;
  is_gateway_down: boolean;
};

export type AnalysisDetailPageWrapperProps = {
  onRefetchChatListing?: (
    _options?: (RefetchOptions & RefetchQueryFilters<unknown>) | undefined
  ) => Promise<QueryObserverResult<unknown, unknown>>;
  onNewChatInitiated?: () => void;
  onNewChatButtonClicked?: (_generateKey?: boolean) => void;
};

export type AnalysisDetailPageProps = CustomReactQueryHookReturnType<ReturnTypeOfAnalysisDetailPageParser> &
  AnalysisDetailPageWrapperProps & {
    isSavingNewAnalysis: boolean;
    onUpdateIsSavingNewAnalysis: (_val: boolean) => void;
    analysisQueriesListData: AnalysisQueriesResponseType;
    analysisQueriesListParsedData: ReturnTypeOfAnalysisQueriesParser;
    analysisTopicsListParsedData: ReturnTypeOfAnalysisTopicsList;
    analysisTopicsListIsLoading: boolean;
    analysisTopicsListError: any;
    propAnalysisNodeId?: string;
    qstIdToPreview?: string;
  };

export type GuideMeQuestionResponse = {
  data: string;
};
export type EditAnalysisBasicInfo = {
  title: string;
};

export type OnSaveAnalysisDoneParams = {
  updatedState?: AnalaysisPageState;
  analysisBasicInfo?: EditAnalysisBasicInfo;
  updatePromptAfterSave?: (_id: string) => void;
  isComingFromRun?: boolean;
  paramsOnSuccess?: (nodeId: string) => void;
};

export type OnSaveAnalysisDoneClick = (
  _params?: OnSaveAnalysisDoneParams
) => void;

export type onEditOverviewFormSubmit = (_formValues: {
  title: string;
  desc?: string;
}) => void;

export type UpdateConfiguration = (
  value: string,
  configurationType:
    | keyof AnalysisConfigurationType["prompt_inputs"]
    | keyof AnalysisConfigurationType
) => void;

export type ChatbotAnalyticsViewKeys = "chats-listing" | "feedback-listing";

export type ChatbotPageParamViewKeys =
  | "definition"
  | "train-bot"
  | "analytics"
  | "training";

export const chatbotPageTabValue: {
  [key in ChatbotPageParamViewKeys]: ChatbotPageParamViewKeys;
} = {
  definition: "definition",
  training: "training",
  "train-bot": "train-bot",
  analytics: "analytics",
};

export type ChatBotPageTabsType = {
  key: ChatbotPageParamViewKeys;
  value: string | JSX.Element;
  id: string;
};

export type ToggleSavedContextsViewType = "close" | "toggle";

export type UserQueriesPrefOfAnswerOutput = {
  qrys_pref_show_code?: KeyValuePairType<boolean>;
  qrys_pref_show_expl?: KeyValuePairType<boolean>;
  qrys_pref_show_logs?: KeyValuePairType<boolean>;
  ids_of_qrys_pref_show_code?: Array<string>;
  ids_of_qrys_pref_show_expl?: Array<string>;
  ids_of_qrys_pref_show_logs?: Array<string>;
};
