import styled from "styled-components";
import { ellipsis } from "../../../../../../../../styles/app.styles";

export const CommentStyled = styled.div<{ isShowReplies: boolean }>`
  margin-bottom: 25.6px;

  .comment-head {
    display: flex;
    align-items: center;
    column-gap: 6.4px;

    .user-name {
      ${ellipsis}
      width: 30ch;
      font-family: OpenSansSemiBold;
    }
  }
`;

export const CommentContentStyled = styled.div<{ isShowReplies: boolean }>`
  padding-left: 14.4px;
  margin-left: 12px;
  border-left: 1px solid
    ${({ theme, isShowReplies }): string =>
      isShowReplies ? theme?.borderColors?.BLUE_116 : "transparent"};
  margin-top: 5px;

  .comment-text {
    color: ${({ theme }): string => theme?.textColors?.GREY_24};
    margin-bottom: 9.6px;
  }

  .comment-info {
    color: ${({ theme }): string => theme?.textColors?.GREY_210};
    font-size: 12px;
    display: flex;
    align-items: center;
    column-gap: 10.8px;
    margin-bottom: 9.6px;

    .reply-btn {
      cursor: pointer;
    }
  }
`;
