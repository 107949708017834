import moment from "moment";
import { ValueGetterParams } from "ag-grid-community";

import { KeyValuePairType } from "../../app.types";
import { ColumnDataType } from "../columnparsers";

import { agGridSortDateComparator } from "../../utils";

export const getFilterByDataType = (dataType: ColumnDataType): string => {
  switch (dataType) {
    case "NUM":
    case "DEC":
    case "INT":
      return "agNumberColumnFilter";
    case "DTE":
    case "DT":
    case "DTM":
    case "TIM":
      return "agDateColumnFilter";
    case "STR":
    default:
      return "agSetColumnFilter";
  }
};

export const mapKeys = (
  data: KeyValuePairType<any>[]
): KeyValuePairType<any>[] => {
  return data?.map((item) => {
    const keys = Object.keys(item);
    const newKeys = keys.map((key) => key.toLowerCase().replaceAll(" ", "_"));
    const newItem: KeyValuePairType<any> = {};

    newKeys.forEach((key, index) => {
      newItem[key] = item[keys[index]];
    });

    return newItem;
  });
};

//REMOVE THIS
export const RULES_COLUMNS_DUMMY_LAYOUT_FIELDS = [
  {
    column_id: 1,
    display_order: "1",
    headerName: "Rule ID",
    width: 180,
    field: "rule_id",
    headerCheckboxSelection: true,
    checkboxSelection: true,
  },
  {
    column_id: 2,
    display_order: "2",
    headerName: "Rule Name",
    minWidth: 220,
    flex: 1,
    field: "rule_name",
  },
  {
    column_id: 3,
    display_order: "3",
    headerName: "Rule Description",
    minWidth: 220,
    flex: 1,
    field: "rule_description",
  },
  {
    column_id: 4,
    display_order: "4",
    headerName: "Alert Status",
    width: 180,
    field: "alert_status",
  },
  {
    column_id: 5,
    display_order: "5",
    headerName: "Run Status",
    width: 180,
    field: "run_status",
  },
  {
    column_id: 6,
    display_order: "6",
    headerName: "Run Date",
    width: 200,
    field: "run_date",
    valueGetter: (params: ValueGetterParams): any => {
      if (!params.data?.run_date) {
        return "";
      }
      return moment
        .utc(params.data?.run_date)
        .local()
        .format("MM/DD/YYYY hh:mm A");
    },
    comparator: agGridSortDateComparator,
  },
];

export const RULES_DICT_COLUMNS_DUMMY_LAYOUT_FIELDS = [
  {
    column_id: 1,
    display_order: "1",
    headerName: "Rule ID",
    width: 180,
    field: "rule_id",
    headerCheckboxSelection: true,
    checkboxSelection: true,
  },
  {
    column_id: 2,
    display_order: "2",
    headerName: "Rule Name",
    minWidth: 220,
    flex: 1,
    field: "rule_name",
  },
  {
    column_id: 3,
    display_order: "3",
    headerName: "Rule Description",
    minWidth: 220,
    flex: 1,
    field: "rule_description",
  },
  {
    column_id: 4,
    display_order: "4",
    headerName: "Alert Status",
    width: 180,
    field: "alert_status",
  },
  {
    column_id: 5,
    display_order: "5",
    headerName: "Result",
    width: 180,
    field: "result",
  },
  {
    column_id: 6,
    display_order: "6",
    headerName: "Run Status",
    width: 180,
    field: "run_status",
  },
  {
    column_id: 7,
    display_order: "7",
    headerName: "Run Date",
    width: 200,
    field: "run_date",
    valueGetter: (params: ValueGetterParams): any => {
      if (!params.data?.run_date) {
        return "";
      }
      return moment
        .utc(params.data?.run_date)
        .local()
        .format("MM/DD/YYYY hh:mm A");
    },
    comparator: agGridSortDateComparator,
  },
  {
    column_id: 7,
    display_order: "7",
    headerName: "Trend",
    width: 180,
    field: "trend",
  },

  {
    column_id: 8,
    display_order: "8",
    headerName: "Attributes",
    minWidth: 180,
    flex: 1,
    field: "attributes",
  },

  {
    column_id: 9,
    display_order: "9",
    headerName: "Workflow Status",
    width: 180,
    field: "workflow_status",
  },
];

export const RECOMMENDED_RULES_COLUMNS_DUMMY_LAYOUT_FIELDS = [
  {
    column_id: 1,
    display_order: "1",
    headerName: "Rule Name",
    minWidth: 220,
    flex: 1,
    field: "rule_name",
    headerCheckboxSelection: true,
    checkboxSelection: true,
  },
  {
    column_id: 2,
    display_order: "2",
    headerName: "Rule Description",
    minWidth: 220,
    flex: 1,
    field: "rule_desc",
  },
  {
    column_id: 3,
    display_order: "3",
    headerName: "Actions",
    width: 220,
    field: "actions",
  },
];
