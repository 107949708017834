export const jsonParse = <T = any>(
  json: string | null | undefined,
  isObject?: boolean
): T => {
  try {
    return JSON.parse(json || "");
  } catch {
    return (isObject ? {} : []) as T;
  }
};
