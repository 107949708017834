export const defaultSourceTargetFields = {
  source_field: undefined,
  target_field: undefined,
};

export const SOURCETARGETDUMMYCOLUMNS = [
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450884,
    COLUMNNAME: "ConvertedDate",
    COL_TITLE: "Converteddate",
    COL_DATA_TYPE_ID: "DTE",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450899,
    COLUMNNAME: "LastReferencedDate",
    COL_TITLE: "Lastreferenceddate",
    COL_DATA_TYPE_ID: "DTM",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450885,
    COLUMNNAME: "LastViewedDate",
    COL_TITLE: "Lastvieweddate",
    COL_DATA_TYPE_ID: "DTM",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450881,
    COLUMNNAME: "CreatedDate",
    COL_TITLE: "Createddate",
    COL_DATA_TYPE_ID: "DTM",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450894,
    COLUMNNAME: "LastModifiedDate",
    COL_TITLE: "Lastmodifieddate",
    COL_DATA_TYPE_ID: "DTM",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450891,
    COLUMNNAME: "EmailBouncedDate",
    COL_TITLE: "Emailbounceddate",
    COL_DATA_TYPE_ID: "DTM",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450898,
    COLUMNNAME: "EmailBouncedReason",
    COL_TITLE: "Emailbouncedreason",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450888,
    COLUMNNAME: "GeocodeAccuracy",
    COL_TITLE: "Geocodeaccuracy",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450890,
    COLUMNNAME: "JigsawContactId",
    COL_TITLE: "Jigsawcontactid",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450860,
    COLUMNNAME: "Jigsaw",
    COL_TITLE: "Jigsaw",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450872,
    COLUMNNAME: "PhotoUrl",
    COL_TITLE: "Photourl",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450877,
    COLUMNNAME: "MiddleName",
    COL_TITLE: "Middlename",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450871,
    COLUMNNAME: "Latitude",
    COL_TITLE: "Latitude",
    COL_DATA_TYPE_ID: "DEC",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450875,
    COLUMNNAME: "Longitude",
    COL_TITLE: "Longitude",
    COL_DATA_TYPE_ID: "DEC",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450864,
    COLUMNNAME: "Suffix",
    COL_TITLE: "Suffix",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450886,
    COLUMNNAME: "MasterRecordId",
    COL_TITLE: "Masterrecordid",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450896,
    COLUMNNAME: "ConvertedAccountId",
    COL_TITLE: "Convertedaccountid",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450900,
    COLUMNNAME: "ConvertedOpportunityId",
    COL_TITLE: "Convertedopportunityid",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450897,
    COLUMNNAME: "ConvertedContactId",
    COL_TITLE: "Convertedcontactid",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450892,
    COLUMNNAME: "LastActivityDate",
    COL_TITLE: "Lastactivitydate",
    COL_DATA_TYPE_ID: "DTE",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450853,
    COLUMNNAME: "Id",
    COL_TITLE: "Id",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450883,
    COLUMNNAME: "MobilePhone",
    COL_TITLE: "Mobilephone",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450895,
    COLUMNNAME: "NumberOfEmployees",
    COL_TITLE: "Numberofemployees",
    COL_DATA_TYPE_ID: "INT",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450854,
    COLUMNNAME: "City",
    COL_TITLE: "City",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450887,
    COLUMNNAME: "SystemModstamp",
    COL_TITLE: "Systemmodstamp",
    COL_DATA_TYPE_ID: "DTM",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450876,
    COLUMNNAME: "LeadSource",
    COL_TITLE: "Leadsource",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450869,
    COLUMNNAME: "Industry",
    COL_TITLE: "Industry",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450856,
    COLUMNNAME: "Email",
    COL_TITLE: "Email",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450859,
    COLUMNNAME: "Title",
    COL_TITLE: "Title",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450868,
    COLUMNNAME: "Website",
    COL_TITLE: "Website",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450862,
    COLUMNNAME: "Status",
    COL_TITLE: "Status",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450866,
    COLUMNNAME: "Country",
    COL_TITLE: "Country",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450857,
    COLUMNNAME: "Phone",
    COL_TITLE: "Phone",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450880,
    COLUMNNAME: "CreatedById",
    COL_TITLE: "Createdbyid",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450874,
    COLUMNNAME: "IsDeleted",
    COL_TITLE: "Isdeleted",
    COL_DATA_TYPE_ID: "BOL",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450865,
    COLUMNNAME: "Company",
    COL_TITLE: "Company",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450855,
    COLUMNNAME: "Name",
    COL_TITLE: "Name",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450873,
    COLUMNNAME: "FirstName",
    COL_TITLE: "Firstname",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450867,
    COLUMNNAME: "OwnerId",
    COL_TITLE: "Ownerid",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450870,
    COLUMNNAME: "LastName",
    COL_TITLE: "Lastname",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450863,
    COLUMNNAME: "Street",
    COL_TITLE: "Street",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450878,
    COLUMNNAME: "PostalCode",
    COL_TITLE: "Postalcode",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450858,
    COLUMNNAME: "State",
    COL_TITLE: "State",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450861,
    COLUMNNAME: "Rating",
    COL_TITLE: "Rating",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450882,
    COLUMNNAME: "IsConverted",
    COL_TITLE: "Isconverted",
    COL_DATA_TYPE_ID: "BOL",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450879,
    COLUMNNAME: "Salutation",
    COL_TITLE: "Salutation",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450893,
    COLUMNNAME: "LastModifiedById",
    COL_TITLE: "Lastmodifiedbyid",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36450848,
    TABLENAME: "Lead",
    TBL_DISPLAY_NAME: "Lead",
    COLUMNID: 36450889,
    COLUMNNAME: "IsUnreadByOwner",
    COL_TITLE: "Isunreadbyowner",
    COL_DATA_TYPE_ID: "BOL",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36456009,
    TABLENAME: "Asset",
    TBL_DISPLAY_NAME: "Asset",
    COLUMNID: 36456031,
    COLUMNNAME: "CreatedDate",
    COL_TITLE: "Createddate",
    COL_DATA_TYPE_ID: "DTM",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36456009,
    TABLENAME: "Asset",
    TBL_DISPLAY_NAME: "Asset",
    COLUMNID: 36456043,
    COLUMNNAME: "LastModifiedDate",
    COL_TITLE: "Lastmodifieddate",
    COL_DATA_TYPE_ID: "DTM",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36456009,
    TABLENAME: "Asset",
    TBL_DISPLAY_NAME: "Asset",
    COLUMNID: 36456048,
    COLUMNNAME: "IsCompetitorProduct",
    COL_TITLE: "Iscompetitorproduct",
    COL_DATA_TYPE_ID: "BOL",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36456009,
    TABLENAME: "Asset",
    TBL_DISPLAY_NAME: "Asset",
    COLUMNID: 36456040,
    COLUMNNAME: "SystemModstamp",
    COL_TITLE: "Systemmodstamp",
    COL_DATA_TYPE_ID: "DTM",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36456009,
    TABLENAME: "Asset",
    TBL_DISPLAY_NAME: "Asset",
    COLUMNID: 36456025,
    COLUMNNAME: "Longitude",
    COL_TITLE: "Longitude",
    COL_DATA_TYPE_ID: "DEC",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36456009,
    TABLENAME: "Asset",
    TBL_DISPLAY_NAME: "Asset",
    COLUMNID: 36456010,
    COLUMNNAME: "Id",
    COL_TITLE: "Id",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36456009,
    TABLENAME: "Asset",
    TBL_DISPLAY_NAME: "Asset",
    COLUMNID: 36456013,
    COLUMNNAME: "Price",
    COL_TITLE: "Price",
    COL_DATA_TYPE_ID: "DEC",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36456009,
    TABLENAME: "Asset",
    TBL_DISPLAY_NAME: "Asset",
    COLUMNID: 36456021,
    COLUMNNAME: "Quantity",
    COL_TITLE: "Quantity",
    COL_DATA_TYPE_ID: "DEC",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36456009,
    TABLENAME: "Asset",
    TBL_DISPLAY_NAME: "Asset",
    COLUMNID: 36456018,
    COLUMNNAME: "OwnerId",
    COL_TITLE: "Ownerid",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36456009,
    TABLENAME: "Asset",
    TBL_DISPLAY_NAME: "Asset",
    COLUMNID: 36456012,
    COLUMNNAME: "Name",
    COL_TITLE: "Name",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36456009,
    TABLENAME: "Asset",
    TBL_DISPLAY_NAME: "Asset",
    COLUMNID: 36456017,
    COLUMNNAME: "Country",
    COL_TITLE: "Country",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36456009,
    TABLENAME: "Asset",
    TBL_DISPLAY_NAME: "Asset",
    COLUMNID: 36456037,
    COLUMNNAME: "SerialNumber",
    COL_TITLE: "Serialnumber",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36456009,
    TABLENAME: "Asset",
    TBL_DISPLAY_NAME: "Asset",
    COLUMNID: 36456023,
    COLUMNNAME: "ContactId",
    COL_TITLE: "Contactid",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36456009,
    TABLENAME: "Asset",
    TBL_DISPLAY_NAME: "Asset",
    COLUMNID: 36456030,
    COLUMNNAME: "CreatedById",
    COL_TITLE: "Createdbyid",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36456009,
    TABLENAME: "Asset",
    TBL_DISPLAY_NAME: "Asset",
    COLUMNID: 36456011,
    COLUMNNAME: "City",
    COL_TITLE: "City",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36456009,
    TABLENAME: "Asset",
    TBL_DISPLAY_NAME: "Asset",
    COLUMNID: 36456014,
    COLUMNNAME: "State",
    COL_TITLE: "State",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36456009,
    TABLENAME: "Asset",
    TBL_DISPLAY_NAME: "Asset",
    COLUMNID: 36456045,
    COLUMNNAME: "AssetProvidedById",
    COL_TITLE: "Assetprovidedbyid",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36456009,
    TABLENAME: "Asset",
    TBL_DISPLAY_NAME: "Asset",
    COLUMNID: 36456044,
    COLUMNNAME: "StockKeepingUnit",
    COL_TITLE: "Stockkeepingunit",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36456009,
    TABLENAME: "Asset",
    TBL_DISPLAY_NAME: "Asset",
    COLUMNID: 36456029,
    COLUMNNAME: "Product2Id",
    COL_TITLE: "Product2id",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36456009,
    TABLENAME: "Asset",
    TBL_DISPLAY_NAME: "Asset",
    COLUMNID: 36456034,
    COLUMNNAME: "ProductCode",
    COL_TITLE: "Productcode",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36456009,
    TABLENAME: "Asset",
    TBL_DISPLAY_NAME: "Asset",
    COLUMNID: 36456016,
    COLUMNNAME: "Street",
    COL_TITLE: "Street",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36456009,
    TABLENAME: "Asset",
    TBL_DISPLAY_NAME: "Asset",
    COLUMNID: 36456028,
    COLUMNNAME: "PostalCode",
    COL_TITLE: "Postalcode",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36456009,
    TABLENAME: "Asset",
    TBL_DISPLAY_NAME: "Asset",
    COLUMNID: 36456032,
    COLUMNNAME: "Description",
    COL_TITLE: "Description",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36456009,
    TABLENAME: "Asset",
    TBL_DISPLAY_NAME: "Asset",
    COLUMNID: 36456015,
    COLUMNNAME: "Status",
    COL_TITLE: "Status",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36456009,
    TABLENAME: "Asset",
    TBL_DISPLAY_NAME: "Asset",
    COLUMNID: 36456046,
    COLUMNNAME: "AssetServicedById",
    COL_TITLE: "Assetservicedbyid",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36456009,
    TABLENAME: "Asset",
    TBL_DISPLAY_NAME: "Asset",
    COLUMNID: 36456020,
    COLUMNNAME: "ParentId",
    COL_TITLE: "Parentid",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36456009,
    TABLENAME: "Asset",
    TBL_DISPLAY_NAME: "Asset",
    COLUMNID: 36456022,
    COLUMNNAME: "AccountId",
    COL_TITLE: "Accountid",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36456009,
    TABLENAME: "Asset",
    TBL_DISPLAY_NAME: "Asset",
    COLUMNID: 36456026,
    COLUMNNAME: "AssetLevel",
    COL_TITLE: "Assetlevel",
    COL_DATA_TYPE_ID: "INT",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36456009,
    TABLENAME: "Asset",
    TBL_DISPLAY_NAME: "Asset",
    COLUMNID: 36456041,
    COLUMNNAME: "GeocodeAccuracy",
    COL_TITLE: "Geocodeaccuracy",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36456009,
    TABLENAME: "Asset",
    TBL_DISPLAY_NAME: "Asset",
    COLUMNID: 36456042,
    COLUMNNAME: "LastModifiedById",
    COL_TITLE: "Lastmodifiedbyid",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36456009,
    TABLENAME: "Asset",
    TBL_DISPLAY_NAME: "Asset",
    COLUMNID: 36456035,
    COLUMNNAME: "RootAssetId",
    COL_TITLE: "Rootassetid",
    COL_DATA_TYPE_ID: "STR",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36456009,
    TABLENAME: "Asset",
    TBL_DISPLAY_NAME: "Asset",
    COLUMNID: 36456039,
    COLUMNNAME: "LastViewedDate",
    COL_TITLE: "Lastvieweddate",
    COL_DATA_TYPE_ID: "DTM",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36456009,
    TABLENAME: "Asset",
    TBL_DISPLAY_NAME: "Asset",
    COLUMNID: 36456047,
    COLUMNNAME: "LastReferencedDate",
    COL_TITLE: "Lastreferenceddate",
    COL_DATA_TYPE_ID: "DTM",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36456009,
    TABLENAME: "Asset",
    TBL_DISPLAY_NAME: "Asset",
    COLUMNID: 36456033,
    COLUMNNAME: "InstallDate",
    COL_TITLE: "Installdate",
    COL_DATA_TYPE_ID: "DTE",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36456009,
    TABLENAME: "Asset",
    TBL_DISPLAY_NAME: "Asset",
    COLUMNID: 36456038,
    COLUMNNAME: "UsageEndDate",
    COL_TITLE: "Usageenddate",
    COL_DATA_TYPE_ID: "DTE",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36456009,
    TABLENAME: "Asset",
    TBL_DISPLAY_NAME: "Asset",
    COLUMNID: 36456036,
    COLUMNNAME: "PurchaseDate",
    COL_TITLE: "Purchasedate",
    COL_DATA_TYPE_ID: "DTE",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36456009,
    TABLENAME: "Asset",
    TBL_DISPLAY_NAME: "Asset",
    COLUMNID: 36456027,
    COLUMNNAME: "IsInternal",
    COL_TITLE: "Isinternal",
    COL_DATA_TYPE_ID: "BOL",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36456009,
    TABLENAME: "Asset",
    TBL_DISPLAY_NAME: "Asset",
    COLUMNID: 36456019,
    COLUMNNAME: "Latitude",
    COL_TITLE: "Latitude",
    COL_DATA_TYPE_ID: "DEC",
  },
  {
    SRC_ID: 36449834,
    SRC_NAME: "Release-20-9-2023",
    TABLEID: 36456009,
    TABLENAME: "Asset",
    TBL_DISPLAY_NAME: "Asset",
    COLUMNID: 36456024,
    COLUMNNAME: "IsDeleted",
    COL_TITLE: "Isdeleted",
    COL_DATA_TYPE_ID: "BOL",
  },
];
