// <--- Library Imports Start --->
import styled from "styled-components";
// <--- Library Imports End --->

// <--- Utils Start --->
import { hexToRgbA } from "../../../../../../../../utils";
// <--- Utils End --->

export const DQRuleActionsFormStyled = styled.div`
  padding: 13px;

  .modal-heading-sec {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;

    .heading {
      color: ${({ theme }): string => theme?.textColors?.BLUE_116};
      margin: 0px;
      font-size: 17px;
      width: 276px;
    }

    .cross-modal-icon {
      display: flex;
      align-items: center;

      svg {
        pointer-events: auto;
        cursor: pointer;

        path {
          fill: ${({ theme }): string => theme?.bgColors?.RED_100};
        }

        :hover {
          path {
            opacity: unset;
          }
        }
      }
    }
  }

  .ant-form {
    .ant-form-item {
      padding-left: unset;

      .dvsum-select-field {
        width: 360px;
      }

      #share-textarea {
        height: 160px !important;
      }

      &.save-btn-sec {
        margin-bottom: 0px;

        .ant-form-item-control-input-content {
          display: flex;
          justify-content: right;
        }
      }

      .select-field-container {
        .ant-select {
          cursor: default;

          .ant-select-item-option {
            cursor: pointer;
          }

          .ant-select-item-option:first-child {
            pointer-events: none;
            color: ${({ theme }): string =>
              hexToRgbA(theme?.textColors?.DARK_GREY, 0.8)};
          }
        }
      }
    }
  }

  .tooltip-wrapper {
    position: relative;

    &:hover {
      .tooltip {
        display: block;
      }
    }

    .tooltip {
      display: none;
      position: absolute;
      right: 70px;
      bottom: -6px;
      border-radius: 2px;
      padding: 10px 9.2px 10px 15px;
      width: max-content;

      box-shadow: 0 0 7px 0 rgb(211 211 211 / 85%);
      background-color: ${({ theme }): string => theme?.bgColors?.WHITE_COLOR};
      color: ${({ theme }): string => theme?.textColors?.GREY_26};

      &.tooltip::after {
        content: "";
        background: ${({ theme }): string => theme?.bgColors?.WHITE_COLOR};
        height: 10px;
        width: 10px;
        position: absolute;
        top: 16px;
        right: 2px;
        transform: translateX(6.53553391px) rotate(45deg);
        box-shadow: inset -2px 2px 0px -2px rgb(211 211 211 / 85%);
      }
    }
  }
`;
