import DvSumPopover from "../../../../../../components/dvsumpopover";
import { SelectField } from "../../../../../../components/formfields";
import { CheckboxFieldStyled } from "../../../../../../components/formfields/checkboxfield/checkboxfield.styles";
import GenericProminentTag from "../../../../../../components/genericprominenttag";
import LinkButton from "../../../../../../components/linkbutton";
import { ELEMENT_IDS } from "../../../../../../constants";
import { deleteIcon1 } from "../../../../../../svgs";
import {
  tagsetPrivacyOptions,
  tagTypesList,
} from "../../../../tagsetsdetailpage.constants";
import GridInputField from "../../../gridinputfield";
import {
  EditableGridRowStyled,
  SelectFieldGlobalStyle,
} from "./editablegridrow.styles";
import { EditableGridRowProps } from "./editablegridrow.types";

const {
  dataorg_tagset_dtl_pg_tag_edt_name_field_1: DATAORG_TAGSET_DTL_PG_TAG_EDT_NAME_FIELD_1,
  dataorg_tagset_dtl_pg_tag_edt_desc_field_1: DATAORG_TAGSET_DTL_PG_TAG_EDT_DESC_FIELD_1,
  dataorg_tagset_dtl_pg_tag_edt_prmt_tag_field_1: DATAORG_TAGSET_DTL_PG_TAG_EDT_PRMT_TAG_FIELD_1,
  dataorg_tagset_dtl_pg_tag_edt_pvc_field_1: DATAORG_TAGSET_DTL_PG_TAG_EDT_PVC_FIELD_1,
} = ELEMENT_IDS;

const EditableGridRow = (props: EditableGridRowProps): JSX.Element => {
  const {
    index = 0,
    isClassificationTagset = false,
    field,
    openedProminentTagsCard = -1,
    setOpenedProminentTagsCard,
    selectedTags = [],
    selectedProminentTag,
    control,
    setValue,
    handleSelectTag,
    handleDeleteRow,
    onProminentTagsCardItemClick,
  } = props;

  return (
    <EditableGridRowStyled isClassificationTagset={isClassificationTagset}>
      <div className="column editable-column first-column">
        <div>
          <CheckboxFieldStyled
            disabled={field?.tag_id === -1}
            defaultChecked={false}
            checked={selectedTags?.includes(field?.tag_id)}
            onChange={(e): void => handleSelectTag(e, field?.tag_id)}
          />
        </div>
        <div id={`${DATAORG_TAGSET_DTL_PG_TAG_EDT_NAME_FIELD_1}-${index + 1}`}>
          <GridInputField
            name={`allTags.${index}.tag`}
            control={control}
            placeholder="Tag Name"
          />
        </div>
      </div>
      <div
        className="column editable-column"
        id={`${DATAORG_TAGSET_DTL_PG_TAG_EDT_DESC_FIELD_1}-${index + 1}`}
      >
        <GridInputField
          name={`allTags.${index}.description`}
          control={control}
          placeholder="Description"
        />
      </div>
      <div
        className="column editable-column"
        id={`${DATAORG_TAGSET_DTL_PG_TAG_EDT_PRMT_TAG_FIELD_1}-${index + 1}`}
      >
        <DvSumPopover
          content={
            <div className="grid-prominent-tags-card">
              Select color for tag
              <div className="tags-wrapper">
                {tagTypesList?.map((prominentTag, tagTypes) => (
                  <LinkButton
                    key={`tagsets-editable-grid-tags-${tagTypes}`}
                    onClick={(e): void =>
                      onProminentTagsCardItemClick(e, index, prominentTag)
                    }
                  >
                    <GenericProminentTag
                      tag={{ name: "Tag", type: prominentTag }}
                      type={prominentTag}
                      isBoldView
                    />
                  </LinkButton>
                ))}
              </div>
            </div>
          }
          trigger={["click"]}
          zIndex={1080}
          getPopupContainer={(trigger): HTMLElement =>
            trigger.parentNode as HTMLElement
          }
          placement="bottomLeft"
          visible={openedProminentTagsCard === index}
          onVisibleChange={(visible): void => {
            setOpenedProminentTagsCard(visible ? index : -1);
          }}
        >
          <div className="prominent-tag-container">
            <div
              className="prominent-tag-wrapper"
              role="button"
              onClick={(e): void => e?.stopPropagation()}
            >
              <CheckboxFieldStyled
                checked={
                  openedProminentTagsCard === index ||
                  selectedProminentTag !== "GRY"
                }
                onChange={(e): void => {
                  e?.target?.checked
                    ? setOpenedProminentTagsCard(index)
                    : openedProminentTagsCard > -1
                    ? setOpenedProminentTagsCard(-1)
                    : setValue(`allTags.${index}.prominent_tag_id`, "GRY");
                }}
              />
              {selectedProminentTag !== "GRY" && (
                <LinkButton
                  onClick={(): void => setOpenedProminentTagsCard(index)}
                >
                  <GenericProminentTag
                    tag={{ name: "Tag", type: selectedProminentTag }}
                    type={selectedProminentTag}
                    isBoldView
                  />
                </LinkButton>
              )}
            </div>
          </div>
        </DvSumPopover>
      </div>
      {isClassificationTagset && (
        <div
          className="column editable-column"
          id={`${DATAORG_TAGSET_DTL_PG_TAG_EDT_PVC_FIELD_1}-${index + 1}`}
        >
          <SelectFieldGlobalStyle />
          <SelectField
            name={`allTags.${index}.privacy`}
            control={control}
            setValue={setValue}
            options={tagsetPrivacyOptions as any}
            width="100%"
            bordered={false}
            showArrow={false}
            isUseGlobalStyle
            isAllowClear={false}
          />
        </div>
      )}
      <div className="column action-column">
        <LinkButton onClick={(): void => handleDeleteRow(index)}>
          {deleteIcon1("11", "12")}
        </LinkButton>
      </div>
    </EditableGridRowStyled>
  );
};

export default EditableGridRow;
