import styled from "styled-components";

export const HelpingTextSectionStyled = styled.div`
  background: ${({ theme }): string => theme.bgColors.LIGHT_BLUE};
  width: 471px;
  height: 100%;
  padding: 30.9px 34px;

  .first-sec {
    border-bottom: 1px solid
      ${({ theme }): string => theme.borderColors.CYAN_BLUE_1};
    padding-bottom: 15.6px;

    .helping-item {
      display: flex;
      flex-direction: column;
      gap: 10px;

      &:not(:last-child) {
        margin-bottom: 27px;
      }

      .title {
        color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
        font-family: "OpenSansSemiBold";
        font-size: 15.2px;
      }

      .description,
      .points {
        color: ${({ theme }): string => theme.textColors.LIGHT_GREY_11};
        font-family: "OpenSans";
        font-size: 13.6px;
      }

      .points {
        margin: 0px;
        padding-left: 15px;
        margin-top: 10px;
      }
    }
  }

  .second-sec {
    padding-top: 15.6px;

    .helping-item {
      display: flex;
      flex-direction: column;
      gap: 10px;

      &:not(:last-child) {
        margin-bottom: 27px;
      }

      .title {
        color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
        font-family: "OpenSansSemiBold";
        font-size: 15.2px;
      }

      .description,
      .points {
        color: ${({ theme }): string => theme.textColors.LIGHT_GREY_11};
        font-family: "OpenSans";
        font-size: 13.6px;
      }

      .points {
        .point {
          &:not(:last-child) {
            margin-bottom: 16.5px;
          }

          .point-heading {
            font-family: "OpenSansSemiBold";
          }
        }
      }
    }
  }
`;
