import { useMemo } from "react";

import { IconWithTextStyled } from "./iconwithtext.styles";
import { IconWithTextProps } from "./iconwithtext.types";
import { parseStringToHtml } from "../../utils/parseStringToHtml";

const IconWithText = (props: IconWithTextProps): JSX.Element => {
  const {
    icon,
    text = "",
    title = "",
    iconAlignment = "left",
    textFlexDirection = "row",
  } = props;

  const domContent = useMemo(() => {
    const iconDom = <div className="iwt-icon">{icon}</div>;
    const textDom = text && (
      <div className="iwt-icon-text" title={title}>
        {parseStringToHtml(text)}
      </div>
    );

    if (iconAlignment === "left") {
      return (
        <>
          {iconDom}
          {textDom}
        </>
      );
    }

    return (
      <>
        {textDom}
        {iconDom}
      </>
    );
  }, [iconAlignment, text]);
  return (
    <IconWithTextStyled {...props} flexDirection={textFlexDirection}>
      {domContent}
    </IconWithTextStyled>
  );
};

export default IconWithText;
