import parse from "html-react-parser";

import SectionTitleWithIcon from "../../../../../../../components/sectiontitlewithicon/sectiontitlewithicon";

import { FieldMetaDataProps } from "../connectionsettingstabform/connectionsettingstabform.types";
import { DynamicFormGroupProps } from "./connectionsettingstabformgroup.types";

import ConnectionSettingsTabFormItem from "../connectionsettingstabformitem/connectionsettingstabformitem";

const ConnectionSettingsTabFormGroup = (
  props: DynamicFormGroupProps
): JSX.Element => {
  const {
    groupId,
    groupTitle,
    fields,
    isVisible,
    groupVisibility,
    groupDescription,
    // setGroupVisibility,
  } = props;

  // useEffect(() => {
  //   setGroupVisibility((st) => ({
  //     ...st,
  //     [`${groupId || groupTitle}`]: isVisible ?? true,
  //   }));
  // }, []);

  return (
    ((groupVisibility?.[`${groupId || groupTitle}`] ?? isVisible ?? true) && (
      <div id={groupId || groupTitle} key={groupTitle}>
        {groupTitle && (
          <SectionTitleWithIcon
            className="section-title"
            title={groupTitle}
            fontSize="16px"
            isShowIcon={false}
            showBorderBottom={false}
            minHeight="39px"
          />
        )}
        {groupDescription &&
          (groupDescription?.html || groupDescription?.text) &&
          !groupDescription?.hideInEditMode && (
            <p>
              {groupDescription?.html
                ? parse(groupDescription?.html)
                : groupDescription?.text}
            </p>
          )}
        {fields?.map(
          (fieldMetaData: FieldMetaDataProps): JSX.Element => (
            <ConnectionSettingsTabFormItem
              {...props}
              key={`dynamic-form-item-key-${fieldMetaData?.name}`}
              fieldMetaData={fieldMetaData}
            />
          )
        )}
      </div>
    )) || <></>
  );
};

export default ConnectionSettingsTabFormGroup;
