import { useCallback } from "react";
import { Button } from "../../../../components";

import { useCancelModal, useGetAppState } from "../../../../customhooks";
import { DeleteDictionaryStyled } from "./deletedictionary.styles";

import { openNotification } from "../../../../utils";
import SuccessNotificationMessage from "../../../../components/successnotificationmessagerendrer/successnotificationmessagerendrer";

import { DeleteDictionaryModalProps } from "./deletedictionary.types";
import { useRequestWithMethod } from "../../../../api";

import StateHandler from "../../../../components/statehandler/statehandler";

const DeleteDictionary = (): JSX.Element => {
  const {
    modal: { modalProps = {} },
  } = useGetAppState();
  const handleCancel = useCancelModal();

  const {
    selectedNodes = [],
    propsOnDeletionSuccess,
  } = modalProps as DeleteDictionaryModalProps;

  const onSuccessDelete = useCallback(
    (response) => {
      // remove after api integration
      console.log("response", response);
      openNotification(
        <SuccessNotificationMessage
          message={`${
            selectedNodes?.length > 1
              ? `${selectedNodes?.length} dictionaries have been deleted.`
              : `${selectedNodes?.length} dictionary have been deleted.`
          }`}
        />
      );
      handleCancel();
      propsOnDeletionSuccess?.(response?.data);
    },
    [selectedNodes, propsOnDeletionSuccess]
  );

  const {
    isLoading,
    error,
    onExecuteRequest: onDeleteDictionary,
  } = useRequestWithMethod(
    "delete_reference_dictionary",
    undefined,
    true,
    onSuccessDelete
  );

  const onDelete = useCallback(() => {
    onDeleteDictionary(selectedNodes);
  }, [selectedNodes]);

  return (
    <StateHandler isFetching={isLoading} error={error} isModal>
      <DeleteDictionaryStyled>
        <div className="message-content">
          This action cannot be reversed. Are you sure?
        </div>

        <div className="modal-actions-sec">
          <Button id="cancel" width="78px" onClick={handleCancel}>
            Cancel
          </Button>

          <Button id="primary" width="78px" onClick={onDelete}>
            Delete
          </Button>
        </div>
      </DeleteDictionaryStyled>
    </StateHandler>
  );
};

export default DeleteDictionary;
