import { GridReadyEvent } from "ag-grid-community";
import { useCallback, useEffect, useMemo, useRef } from "react";
import AgGridTable from "../../../../../../components/aggridtable/aggridtable";

import {
  AddRulesetFormGridStyled,
  AddRulesetFormGridWrapperStyled,
} from "./addrulesetformgrid.styles";

import {
  AddRulesetFormGridColumnsType,
  AddRulesetFormGridContentProps,
  AddRulesetFormGridProps,
  HeaderCompParamsType,
} from "./addrulesetformgrid.types";

import {
  ruleSetFormGridColumns,
  ColumnNameRendrer,
  BlankCheckRenderer,
  ValueRangeCheckRenderer,
  DataFormatCheckRenderer,
  BlanksNumberRenderer,
} from "./renderer";

import { TableWithSearchAndFiltersHOC } from "../../../../../../components/tablewithsearchandfiltershoc";
import { Input } from "../../../../../../components/inputs";

import { crossIcon, searchIconLight } from "../../../../../../svgs";
import { TableColumns } from "../../../../../../components/aggridtable/aggridtable.types";

const crossIconSvg = crossIcon("12", "12");
const serachIcon = searchIconLight();

const AddRulesetFormGridContent = (
  props: AddRulesetFormGridContentProps
): JSX.Element => {
  const {
    searchText,
    onChangeSearchText,
    onGridReady,
    onChangeMultipleRulesetChecks,
    rulesOnColumn,
    columnsData,
    tableId = "",
    isEdit = false,
    rulesRef,
    srcTypeId,
  } = props;

  const gridRef = useRef<GridReadyEvent>(null);

  const onGridReadyWrapper = useCallback(
    (params) => {
      onGridReady && onGridReady(params);
      params?.api?.setQuickFilter?.(searchText);
    },
    [props]
  );

  const gridColumns: TableColumns<AddRulesetFormGridColumnsType> = useMemo(() => {
    const headerCompParams: HeaderCompParamsType = {
      isEdit,
      tableId,
      rulesRef,
      srcTypeId,
      columnsData,
      rulesOnColumn,
      onChangeMultipleRulesetChecks,
    };

    return ruleSetFormGridColumns?.map((column) => ({
      ...column,
      headerComponentParams: headerCompParams,
    }));
  }, [
    isEdit,
    tableId,
    rulesRef,
    srcTypeId,
    columnsData,
    rulesOnColumn,
    onChangeMultipleRulesetChecks,
  ]);

  useEffect(() => {
    gridRef?.current?.api?.refreshCells({
      force: true,
      columns: ["blanksCheck", "valueRangeCheck", "dataFormatCheck"],
    });
  }, [rulesOnColumn]);

  const columnsGrid = useMemo(() => {
    return (
      columnsData?.map((item) => ({
        id: item?.field_id,
        columnId: item?.field_id,
        columnName: item?.field_display_name || "",
        columnType: item?.field_datatype,
        minMaxValue: `${item?.col_min_value} - ${item?.col_max_value}`,
        nullCount: item?.col_blank_count,
        blanksCheck: false,
        valueRangeCheck: false,
        dataFormatCheck: false,
      })) || []
    );
  }, [columnsData]);

  return (
    <AddRulesetFormGridWrapperStyled className="add-ruleset-form-grid-wrapper-styled">
      <AddRulesetFormGridStyled>
        <div className="grid-search">
          <Input
            suffix={
              searchText ? (
                <span
                  role="button"
                  onClick={onChangeSearchText}
                  className="clear-search-text-icon"
                >
                  {crossIconSvg}
                </span>
              ) : (
                <span />
              )
            }
            className="search-box"
            placeholder="Search"
            prefix={serachIcon}
            value={searchText}
            onChange={onChangeSearchText}
            width="200px"
            fontSize="14px"
          />
        </div>
        <div className="data-grid">
          <AgGridTable<AddRulesetFormGridColumnsType>
            onGridReady={onGridReadyWrapper}
            theme="ag-theme-alpine table-container"
            isTableWidthfitTowindowSize={false}
            rowData={columnsGrid}
            defaultColDef={{
              resizable: false,
              lockPosition: true,
            }}
            frameworkComponents={{
              ColumnNameRendrer,
              BlankCheckRenderer,
              ValueRangeCheckRenderer,
              DataFormatCheckRenderer,
              BlanksNumberRenderer,
            }}
            rowHeight={43}
            headerHeight={43}
            maxHeight="calc(100vh - 326px)"
            domLayout="autoHeight"
            pageSizes={["5", "10", "20", "50"]}
            tableColumns={gridColumns}
            suppressCellSelection
            suppressPaginationPanel
            cellRenderers={{
              columnName: {
                render: "ColumnNameRendrer",
              },
              blanksCheck: {
                render: "BlankCheckRenderer",
              },
              valueRangeCheck: {
                render: "ValueRangeCheckRenderer",
              },
              dataFormatCheck: {
                render: "DataFormatCheckRenderer",
              },
              nullCount: {
                render: "BlanksNumberRenderer",
              },
            }}
            gridRef={gridRef}
          />
        </div>
      </AddRulesetFormGridStyled>
    </AddRulesetFormGridWrapperStyled>
  );
};

const AddRulesetFormGrid = (props: AddRulesetFormGridProps): JSX.Element => {
  return (
    <TableWithSearchAndFiltersHOC appliedFilters={["all"]} filterColumnName="">
      <AddRulesetFormGridContent {...props} />
    </TableWithSearchAndFiltersHOC>
  );
};

export default AddRulesetFormGrid;
