import { IAppliedFiltersProps } from "../../api/enterprisesearchservice/enterprisesearchservice";
import { PaginationState } from "../../components/pagination/pagination.types";
import {
  IColumnFilters,
  ITableAnalysisFilters,
  ITableFilters,
  ITermFilters,
} from "./enterprisesearchpage.types";

export function mappFiltersWithEmptyFilterDataValues(
  filters: ITableFilters | IColumnFilters | ITermFilters | ITableAnalysisFilters
): { [key: string]: [] } {
  return Object.entries(filters || {})?.reduce((prev, next) => {
    const [filtKey] = next;
    return { ...prev, [filtKey]: [] };
  }, {});
}

export function removeFilterCount(val: string = ""): string {
  return val?.includes("$filterCount=")
    ? val?.split("$filterCount=")?.[0] || ""
    : val;
}

export function getFilterCount(val: string = ""): string {
  return val?.includes("$filterCount=")
    ? val?.split("$filterCount=")?.[1] || ""
    : val;
}

export function containFilterCount(val: string = ""): boolean {
  return val?.includes("$filterCount=");
}

export function formatUrlParamsPagination(
  decodedQueryParams: IAppliedFiltersProps
): PaginationState {
  return {
    pageSize: decodedQueryParams?.size || 10,
    currentPage:
      (decodedQueryParams?.from || 0) / (decodedQueryParams?.size || 10) + 1,
  };
}
