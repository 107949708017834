// <--Library Imports Start-->
import { DownOutlined } from "@ant-design/icons";
import { Spin, Tree } from "antd";
// <--Library Imports End-->

// <--Components Start-->
import { Input } from "../../../../../../../../components/inputs";
import LinkButton from "../../../../../../../../components/linkbutton";

import SectionBlankSlateText from "../../../../../../../../components/sectionblankslatetext";
// <--Components End-->

// <--Constants Start-->
import {
  NO_SCHEMAS,
  NO_SEARCH_RESULTS,
} from "../../analysisdetailpageexplorersec.constants";
// <--Constants End-->

// <--Styles Start-->
import { TableExplorerViewStyled } from "./tableexplorerview.styles";
import { lightCrossIcon, searchIcon } from "../../../../../../../../svgs";
// <--Styles End-->

// <--Types Start-->
import { TableExplorerViewProps } from "./tableexplorerview.types";
import ShowRelatedDatasetButton from "../showrelateddatasetbutton/showrelateddatasetbutton";
// <--Types End-->

const TableExplorerView = (props: TableExplorerViewProps): JSX.Element => {
  const {
    expandedKeys,
    isLoadingDatasets,
    onSearchTextChange,
    searchText,
    setExpandedKeys,
    transformedSchemasList,
    isSearchTextEmpty,
    isShowRelatedDatasets,
    onClickShowRelatedDatasets,
    hideRelatedDatasetButton,
    isLoadingRelatedDatasets,
  } = props;

  return (
    <TableExplorerViewStyled>
      <Spin
        spinning={
          !!(
            (isShowRelatedDatasets && isLoadingRelatedDatasets) ||
            isLoadingDatasets
          )
        }
      >
        <div
          className="explorer-collapse-item-content-container table-browser-sec"
          data-testid="analysis-detail-page-explorer-sec-table-browser-sec-main-wrapper"
        >
          <Input
            className="table-browser-search"
            prefix={searchIcon("12.48px")}
            suffix={
              searchText ? (
                <LinkButton onClick={onSearchTextChange}>
                  {lightCrossIcon("9.6px", "9.6px")}
                </LinkButton>
              ) : (
                <div />
              )
            }
            onChange={onSearchTextChange}
            value={searchText}
            placeholder="Search"
            data-testid="analysis-detail-page-explorer-sec-table-browser-sec-search-field"
          />
          {!hideRelatedDatasetButton && (
            <ShowRelatedDatasetButton
              isShowRelatedDatasets={isShowRelatedDatasets}
              onClickShowRelatedDatasets={onClickShowRelatedDatasets}
            />
          )}

          {!isLoadingDatasets ? (
            transformedSchemasList?.length ? (
              <Tree
                showLine
                showIcon
                blockNode
                switcherIcon={<DownOutlined />}
                treeData={transformedSchemasList}
                expandedKeys={expandedKeys}
                onExpand={(expanded): void => {
                  setExpandedKeys(expanded);
                }}
                data-testid="analysis-detail-page-explorer-sec-table-browser-sec-tables-tree"
              />
            ) : (
              <SectionBlankSlateText
                isEmpty
                text={isSearchTextEmpty ? NO_SCHEMAS : NO_SEARCH_RESULTS}
                className="no-schemas-state"
                data-testid="analysis-detail-page-explorer-sec-table-browser-sec-blankstate"
              >
                <>No data</>
              </SectionBlankSlateText>
            )
          ) : (
            <div />
          )}
        </div>
      </Spin>
    </TableExplorerViewStyled>
  );
};

export default TableExplorerView;
