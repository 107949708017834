// <--- Library Imports Start --->
import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
// <--- Library Imports End --->

// <--- Components Start --->
import StateHandler from "../../../../../../../statehandler";

import { FormItemStyled, FormStyled } from "../../../../../../../form";
import { SelectField, TextAreaField } from "../../../../../../../formfields";

import { Button } from "../../../../../../../button/button.component";
import MultiSelectUsersField from "../../../../../../../formfields/multiselectusersfield";
// <--- Components End --->

// <--- Schemas Start --->
import { dqRuldeActionsFormSchema } from "../../../../../../../../utils/schemas/dqruleactionsformschemas";
// <--- Schemas End --->

// <--- Services Start --->
import { useGetRefByType } from "../../../../../../../../api/refservice";
import { useGetUserAndGroups } from "../../../../../../../../api/tablesservice";
// <--- Services End --->

// <--- Styles Start --->
import { DQRuleActionsFormStyled } from "./dqruleactionsform.styles";
// <--- Styles End --->

// <--- SVGs Start --->
import { crossIcon } from "../../../../../../../../svgs";
// <--- SVGs End --->

// <--- Types Start --->
import {
  DQRuleActionsFormType,
  DQRuleActionsFormProps,
} from "./dqruleactionsform.types";

import { DQActionStatusType } from "../../../../../../../../app.types";
// <--- Types End --->

// <--- Utils Start --->
import {
  getPostLoginData,
  sortObjectsArrayByKey,
} from "../../../../../../../../utils";
// <--- Utils End --->

const assignFieldIds = ["ASN", "RSN"] as Array<DQActionStatusType>;
const resoltionFieldsIds = ["INP", "RSL"] as Array<DQActionStatusType>;

const DQRuleActionsForm = (props: DQRuleActionsFormProps): JSX.Element => {
  const {
    selectedAction = { id: "", name: "" },
    onCancel,
    onExecuteRequest,
    error,
    isLoading,
    defaultResolutionAction = "",
  } = props;

  const { id: selectedActionId = "" } = selectedAction;

  const isAssign = assignFieldIds.includes(selectedActionId);

  const {
    control,
    setValue,
    handleSubmit,
    formState: { isValid },
  } = useForm<DQRuleActionsFormType>({
    defaultValues: {
      assigned_to_name: undefined,
      resolution_action_id: defaultResolutionAction,
      comments: undefined,
    },
    resolver: yupResolver(dqRuldeActionsFormSchema({ isAssign })),
    mode: "onChange",
  });

  const {
    parsedData: parsedUsers,
    isLoading: isLoadingUsers,
    error: errorUsers,
  } = useGetUserAndGroups(isAssign);

  const {
    parsedData: resolutionTypeParsedData,
    isLoading: isLoadingRefType,
    error: refTypeError,
  } = useGetRefByType("DQ_RESOLUTION_TYPE");

  const sortedResolutionTypes = useMemo(
    () => sortObjectsArrayByKey(resolutionTypeParsedData, "displayOrder"),
    [resolutionTypeParsedData]
  );

  const resolutionActionOptions = useMemo(
    () =>
      sortedResolutionTypes?.map((resolutionType) => ({
        label: resolutionType?.name,
        value: resolutionType?.id,
      })),
    [sortedResolutionTypes]
  );

  const onSubmit = useCallback(
    (values: DQRuleActionsFormType) => {
      const {
        assigned_to_name: assignedToName,
        resolution_action_id: resolutionActionId,
        comments,
      } = values || {};

      const assignToId = parsedUsers?.find((user) =>
        values?.assigned_to_name?.includes(user?.value)
      )?.id;

      const resolutionActionName = resolutionActionOptions?.find(
        (item) => item?.value === resolutionActionId
      )?.label;

      const { user_info: userInfo } = getPostLoginData();
      onExecuteRequest({
        rule_action: {
          assigned_to_name: assignedToName?.[0],
          assigned_to_id: assignToId,
          comments,
          resolution_action_id: resolutionActionId,
          resolution_action_name: resolutionActionName,
          action_status: selectedAction?.id,
          action_on: new Date(),
          action_by: userInfo?.user_name,
          desc: "",
        },
      });
    },
    [parsedUsers, resolutionActionOptions, selectedAction]
  );

  return (
    <StateHandler
      isFetching={isLoadingUsers || isLoadingRefType || isLoading}
      error={errorUsers || refTypeError || error}
      isModal
    >
      <DQRuleActionsFormStyled
        data-testid={`dq-rule-actions-form-component-${selectedActionId}`}
      >
        <div className="modal-heading-sec">
          <div className="heading">Additional Information Required</div>
          <div
            className="cross-modal-icon"
            role="button"
            onClick={onCancel}
            data-testid={`dq-rule-actions-form-cross-btn-${selectedActionId}`}
          >
            {crossIcon("16px", "16px")}
          </div>
        </div>

        <FormStyled
          data-testid={`dq-rule-actions-form-content-sec-${selectedActionId}`}
          isItemColumnLayout
          onFinish={
            handleSubmit(onSubmit) as ((_values: unknown) => void) | undefined
          }
        >
          <FormItemStyled>
            {isAssign && (
              <MultiSelectUsersField
                data-testid={`dq-rule-actions-form-multiselect-user-field-${selectedActionId}`}
                control={control}
                setValue={setValue}
                name="assigned_to_name"
                mode="multiple"
                allowClear
                placeholder="Select user to assign"
              />
            )}
            {resoltionFieldsIds.includes(selectedActionId) && (
              <SelectField
                data-testid={`dq-rule-actions-form-resolution-action-select-field-${selectedActionId}`}
                options={resolutionActionOptions}
                allowClearLabel="Select the resolution action"
                showSearch
                control={control}
                setValue={setValue}
                name="resolution_action_id"
                placeholder="Select resolution action"
                filterOption={(input: string, option: any): boolean =>
                  option?.label
                    ?.toString()
                    ?.toLowerCase()
                    ?.includes(input?.toString()?.toLowerCase())
                }
              />
            )}
          </FormItemStyled>

          <FormItemStyled>
            <TextAreaField
              data-testid={`dq-rule-actions-form-comments-text-area-${selectedActionId}`}
              control={control}
              name="comments"
              placeholder="Add comments"
              width="360px"
              id="textarea"
            />
          </FormItemStyled>

          <FormItemStyled label="" className="save-btn-sec" marginBottom="0px">
            <div className="tooltip-wrapper">
              {!isValid && (
                <div className="tooltip">Required fields can't be empty</div>
              )}
              <Button
                id="primary"
                width="58px"
                height="33px"
                htmlType="submit"
                disabled={!isValid}
                data-testid={`dq-rule-actions-form-save-btn-${selectedActionId}`}
              >
                Save
              </Button>
            </div>
          </FormItemStyled>
        </FormStyled>
      </DQRuleActionsFormStyled>
    </StateHandler>
  );
};

export default DQRuleActionsForm;
