import { useCallback, useMemo } from "react";
import CoreNodesCircleIcon from "../../../../../../../../../../../../components/corenodetypescircleicon/corenodetypescircleicon";

import { thickTickIcon } from "../../../../../../../../../../../../svgs";
import { QueryBlockColumnProfileStyled } from "./queryblockcolumnprofile.styles";

import { QueryBlockColumnProfileProps } from "./queryblockcolumnprofile.types";
import { getDataTypeIcon } from "../../../../../../../../../../../../utils";

import {
  ColProfillingSection,
  ColVisualDistributionSec,
} from "./queryblockcolumnprofile.rendrer";
import { CustomDynamicProgressBar } from "../../../../../../../../../../../../components";

import { getProfilingDetails } from "../queryblockprofiling.comp.utils";
import { transformColumnToFilterModel } from "../../../queryblock.comp.utils";

const tickIcon = thickTickIcon();

const QueryBlockColumnProfile = (
  props: QueryBlockColumnProfileProps
): JSX.Element => {
  const { columnProfile, handlers, queryItem, isCellProfile } = props;

  const { id = "", filters } = queryItem || {};

  const {
    column_display_name: columnDisplayName = "",
    data_type: dataType,
    description = "",
    data_type_info: dataTypeInfo = "",
    profiling = [],
  } = columnProfile || {};

  const { onClearOrAddSingleTransientFilter } = handlers || {};

  const { non_blanks: nonBlanks = 0, blanks = 0 } = useMemo(
    () => getProfilingDetails(profiling),
    [profiling]
  );

  const { transiant_filters: transiantFilters = {} } = filters || {};

  const colTransFilter = useMemo(() => {
    return transiantFilters?.[columnDisplayName] || {};
  }, [transiantFilters?.[columnDisplayName]]);

  const { isBlankFilterApplied, isNonBlankFilterApplied } = useMemo(() => {
    return {
      isBlankFilterApplied: colTransFilter?.filter_model?.type === "blanks",
      isNonBlankFilterApplied:
        colTransFilter?.filter_model?.type === "nonBlanks",
    };
  }, [colTransFilter?.filter_model?.type]);

  const onBlankClick = useCallback(() => {
    onClearOrAddSingleTransientFilter(
      id,
      transformColumnToFilterModel(
        columnProfile,
        colTransFilter,
        "",
        "blanks",
        isBlankFilterApplied
      )
    );
  }, [
    onClearOrAddSingleTransientFilter,
    colTransFilter,
    columnProfile,
    isBlankFilterApplied,
  ]);

  const onNonBlankClick = useCallback(() => {
    onClearOrAddSingleTransientFilter(
      id,
      transformColumnToFilterModel(
        columnProfile,
        colTransFilter,
        "",
        "nonBlanks",
        isNonBlankFilterApplied
      )
    );
  }, [
    onClearOrAddSingleTransientFilter,
    colTransFilter,
    columnProfile,
    isNonBlankFilterApplied,
  ]);

  return (
    <QueryBlockColumnProfileStyled
      isCellProfile={isCellProfile}
      data-testid="query-block-column-profile-main-wrapper"
    >
      <ColProfillingSection {...props} />
      <div
        className="column-profile-header"
        data-testid="query-block-column-profile-header"
      >
        <div className="row-1">
          <CoreNodesCircleIcon
            width="19.2px"
            height="19.2px"
            nodeType="COL"
            fontSize="12px"
          />
          <span
            title={dataTypeInfo}
            className="col-profile-data-type-icon"
            data-testid="query-block-column-profile-data-type-icon"
          >
            {getDataTypeIcon(dataType)}
          </span>
          <div
            className="column-name"
            title={columnDisplayName}
            data-testid="query-block-column-profile-column-name"
          >
            {columnDisplayName}
          </div>
        </div>
        <div
          className="row-2"
          title={description}
          data-testid="query-block-column-profile-desc"
        >
          {description}
        </div>
      </div>
      {!isCellProfile && (
        <div
          className="column-count-wrapper"
          data-testid="query-block-column-profile-column-count-wrapper"
        >
          <div
            className="col-count-title"
            data-testid="query-block-column-profile-column-count-label-text"
          >
            Count
          </div>
          <CustomDynamicProgressBar
            value={nonBlanks}
            emptyText={
              <>
                {isBlankFilterApplied && tickIcon} {blanks}% blanks
              </>
            }
            fillText={
              <>
                {isNonBlankFilterApplied && tickIcon} {nonBlanks}% filled
              </>
            }
            width="100%"
            onEmptyBarClick={onBlankClick}
            titleEmptyText={`${blanks}`}
            titleFillText={`${nonBlanks}`}
            onFillBarClick={onNonBlankClick}
            minHeight="31"
          />
        </div>
      )}
      <ColVisualDistributionSec {...props} />
    </QueryBlockColumnProfileStyled>
  );
};

export default QueryBlockColumnProfile;
