export const analysisTabListingBlankSlate = (
  <svg width="100%" height="100%" viewBox="0 0 1794 527" fill="none">
    <line x1="-4.37114e-08" y1="340.5" x2="1794" y2="340.5" stroke="#E6E6E6" />
    <line x1="-4.37114e-08" y1="526.5" x2="1794" y2="526.5" stroke="#E6E6E6" />
    <rect x="60" y="1" width="197" height="22" rx="11" fill="#F6F6F6" />
    <circle cx="276" cy="12" r="11" fill="#F6F6F6" />
    <rect x="60" y="35" width="825" height="17" rx="8.5" fill="#F6F6F6" />
    <rect x="60" y="62" width="483" height="17" rx="8.5" fill="#F6F6F6" />
    <rect x="60" y="99" width="173" height="17" rx="8.5" fill="#F6F6F6" />
    <circle cx="246" cy="107.5" r="3" fill="#F6F6F6" />
    <rect x="259" y="99" width="173" height="17" rx="8.5" fill="#F6F6F6" />
    <rect x="20" width="24" height="24" rx="12" fill="#F6F6F6" />
    <rect x="60" y="187" width="197" height="22" rx="11" fill="#F6F6F6" />
    <circle cx="276" cy="198" r="11" fill="#F6F6F6" />
    <rect x="60" y="221" width="825" height="17" rx="8.5" fill="#F6F6F6" />
    <rect x="60" y="248" width="483" height="17" rx="8.5" fill="#F6F6F6" />
    <rect x="60" y="285" width="173" height="17" rx="8.5" fill="#F6F6F6" />
    <circle cx="246" cy="293.5" r="3" fill="#F6F6F6" />
    <rect x="259" y="285" width="173" height="17" rx="8.5" fill="#F6F6F6" />
    <rect x="20" y="186" width="24" height="24" rx="12" fill="#F6F6F6" />
    <rect x="60" y="373" width="197" height="22" rx="11" fill="#F6F6F6" />
    <circle cx="276" cy="384" r="11" fill="#F6F6F6" />
    <rect x="60" y="407" width="825" height="17" rx="8.5" fill="#F6F6F6" />
    <rect x="60" y="434" width="483" height="17" rx="8.5" fill="#F6F6F6" />
    <rect x="60" y="471" width="173" height="17" rx="8.5" fill="#F6F6F6" />
    <circle cx="246" cy="479.5" r="3" fill="#F6F6F6" />
    <rect x="259" y="471" width="173" height="17" rx="8.5" fill="#F6F6F6" />
    <rect x="20" y="372" width="24" height="24" rx="12" fill="#F6F6F6" />
    <line x1="-4.37114e-08" y1="154.5" x2="1794" y2="154.5" stroke="#E6E6E6" />
  </svg>
);

export const analysisRefBlankslate = (
  <svg
    width="100%"
    style={{ paddingTop: "44px" }}
    viewBox="0 0 602 332"
    fill="none"
  >
    <path d="M0 87H602" stroke="#E6E6E6" />
    <rect x="23" y="119" width="147" height="17" rx="8.5" fill="#F6F6F6" />
    <rect x="240" y="119" width="79" height="17" rx="8.5" fill="#F6F6F6" />
    <rect x="23" y="155" width="148" height="17" rx="8.5" fill="#F6F6F6" />
    <rect x="240" y="155" width="304" height="17" rx="8.5" fill="#F6F6F6" />
    <rect x="240" y="177" width="204" height="17" rx="8.5" fill="#F6F6F6" />
    <rect x="23" y="213" width="147" height="17" rx="8.5" fill="#F6F6F6" />
    <rect x="240" y="213" width="131" height="17" rx="8.5" fill="#F6F6F6" />
    <rect x="23" y="246" width="147" height="17" rx="8.5" fill="#F6F6F6" />
    <rect x="240" y="246" width="79" height="17" rx="8.5" fill="#F6F6F6" />
    <rect x="23" y="284.5" width="147" height="17" rx="8.5" fill="#F6F6F6" />
    <rect x="240.5" y="279.5" width="82" height="27" rx="3.5" fill="#F6F6F6" />
    <rect
      x="240.5"
      y="279.5"
      width="82"
      height="27"
      rx="3.5"
      stroke="#F6F6F6"
    />
    <rect x="331.5" y="279.5" width="172" height="27" rx="3.5" fill="#F6F6F6" />
    <rect
      x="331.5"
      y="279.5"
      width="172"
      height="27"
      rx="3.5"
      stroke="#F6F6F6"
    />
    <path d="M0 331H602" stroke="#E6E6E6" />
    <rect x="64" y="2" width="197" height="22" rx="11" fill="#F6F6F6" />
    <circle cx="279.5" cy="13" r="10.5" fill="#F6F6F6" />
    <circle cx="310.5" cy="13" r="12.5" fill="#F6F6F6" />
    <rect x="64" y="37" width="172" height="17" rx="8.5" fill="#F6F6F6" />
    <rect x="24" y="4" width="28" height="28" rx="14" fill="#F6F6F6" />
  </svg>
);