import {
  ProminentTagType,
  ProminentTagsType,
} from "../../components/genericprominenttag/genericprominenttag.types";

import { ProminentTagsProps } from "../../components/prominenttagslist/prominenttagslist.types";
import { SectionRowProps } from "../../components/sectionrow";

import { SectionWithEditableState } from "../../components/sectionwitheditables/sectionwitheditable/sectionwitheditable.types";
import { TagsData } from "../../components/tagseditor/tagseditor.types";

import {
  getObjectKeys,
  getPostLoginData,
  getTagsetConfigWithNameKey,
  localTimeZoneToUtc,
} from "../../utils";

import {
  ClassificationsType,
  CustomDataSetTag,
  NodeResponseTagsetConfig,
  NodeResponseTagsetConfigItem,
} from "../parser.types";

export type GetClassificationCustomTagsAddInfo = {
  tagsetConfig: NodeResponseTagsetConfig;
  tagsetId: string;
};

export function getClassificationCustomTags(
  classifications: ClassificationsType
): SectionWithEditableState["state"]["data"] {
  const tagsetConfigArray: NodeResponseTagsetConfigItem[] = getObjectKeys(
    classifications?.tagset_config
  )?.map((item) => classifications?.tagset_config?.[item]);

  const tagsetConfigWithNameKey = getTagsetConfigWithNameKey(
    classifications?.tagset_config
  );

  const multiValuesTag =
    tagsetConfigArray
      ?.filter((item) => item?.is_multi_value_allowed)
      ?.map((nestItem) => nestItem?.tagset_name) || [];

  const singleValueTag =
    tagsetConfigArray
      ?.filter((item) => !item?.is_multi_value_allowed)
      ?.map((nestItem) => nestItem?.tagset_name) || [];

  const findTag = (tagsetId: string): CustomDataSetTag["value"] => {
    const findedTag = (classifications?.tags?.find(
      (item) => `${item?.tagset_id}` === tagsetId
    ) || {}) as CustomDataSetTag;
    return findedTag?.value || [];
  };

  const getTagName = (tagAlongWithTagsetName = ""): string => {
    const splitedVal = tagAlongWithTagsetName?.split?.(".");
    return splitedVal?.slice(-1)?.[0] || "";
  };

  return (
    [...singleValueTag, ...multiValuesTag]?.map((tagsetName) => {
      const {
        tagset_id: tagsetId,
        is_multi_value_allowed: isMultiValue,
        is_policy_linked: isPolicyLinked,
        tagset_policy_info: tagsetPolicyInfo,
      } = tagsetConfigWithNameKey?.[tagsetName] || {};

      const tagsOfTagset = findTag(`${tagsetId}`) || [];

      const simpleTags =
        tagsOfTagset?.filter((item) => !item?.is_prominent) || [];

      const prominentTags =
        tagsOfTagset?.filter((item) => item?.is_prominent) || [];

      const firstProminentTag = prominentTags?.[0];
      const firstSimpleTag = simpleTags?.[0];

      const {
        tag_comments: firstProminentTagComment = "",
        tag_created_by: firstProminentTagCreatedBy = "",
        tag_created_on: firstProminentTagCreatedOn = "",
        tag_desc: firstProminentTagDesc = "",
        tag_name: firstProminentTagName = "",
        tag_id: firstProminentTagId = "",
        color_code: firstProminentColorCode,
      } = firstProminentTag || {};

      const {
        tag_name: firstSimpleTagName = "",
        tag_comments: firstSimpleTagComments = "",
        tag_id: firstSimpleTagId = "",
      } = firstSimpleTag || {};

      const formattedNameOfFirstProminentTag = getTagName(
        firstProminentTagName
      );

      const formattedNameOfFirstSimpleTag = getTagName(firstSimpleTagName);

      return {
        title: tagsetName,
        additionalInfo: {
          tagsetConfig: classifications?.tagset_config,
          tagsetId: `${tagsetId}`,
        } as GetClassificationCustomTagsAddInfo,
        tags: simpleTags?.length
          ? isMultiValue
            ? simpleTags?.map((item) => getTagName(item?.tag_name))
            : formattedNameOfFirstSimpleTag && !formattedNameOfFirstProminentTag
            ? [formattedNameOfFirstSimpleTag]
            : []
          : undefined,
        prominentTags: prominentTags?.length
          ? ((isMultiValue
              ? {
                  tags: prominentTags?.map((tag) => {
                    const {
                      tag_comments: tagComment = "",
                      tag_created_by: tagCreatedBy = "",
                      tag_created_on: tagCreatedOn = "",
                      tag_desc: tagDesc = "",
                      tag_name: tagName = "",
                      tag_id: tagId = "",
                    } = tag || {};

                    const formattedTagName = getTagName(tagName);
                    return {
                      name: formattedTagName,
                      type: tag?.color_code,
                      id: `${tagId}`,
                      tagDetails: {
                        tagName: formattedTagName,
                        tagsetId: `${tagsetId}`,
                        tagsetName,
                        tagNote: tagComment,
                        tagsetDesc: tagDesc,
                        updatedBy: tagCreatedBy,
                        updatedOn: tagCreatedOn,
                        tagsetPolicyInfo: isPolicyLinked
                          ? tagsetPolicyInfo
                          : "",
                      },
                    };
                  }),
                }
              : {
                  tags: firstProminentTag
                    ? [
                        {
                          name: formattedNameOfFirstProminentTag,
                          type: firstProminentColorCode,
                          id: `${firstProminentTagId}`,
                          tagDetails: {
                            tagName: formattedNameOfFirstProminentTag || "",
                            tagsetId: `${tagsetId}`,
                            tagsetName,
                            tagNote: firstProminentTagComment,
                            tagsetDesc: firstProminentTagDesc,
                            updatedBy: firstProminentTagCreatedBy,
                            updatedOn: firstProminentTagCreatedOn,
                            tagsetPolicyInfo: isPolicyLinked
                              ? tagsetPolicyInfo
                              : "",
                          },
                        },
                      ]
                    : [],
                }) as ProminentTagsProps)
          : undefined,
        selectedValueOfTags: isMultiValue
          ? tagsOfTagset?.map((item) => ({
              label: item?.tag_name || "",
              value: item?.tag_id || "",
              comment: item?.tag_comments || "",
              isProminent: !!item?.is_prominent,
              colorCode: item?.color_code,
            })) || []
          : formattedNameOfFirstSimpleTag && !formattedNameOfFirstProminentTag
          ? [
              {
                label: formattedNameOfFirstSimpleTag,
                value: firstSimpleTagId,
                comment: firstSimpleTagComments,
              },
            ]
          : formattedNameOfFirstProminentTag
          ? [
              {
                label: formattedNameOfFirstProminentTag,
                value: firstProminentTagId,
                comment: firstProminentTagComment,
                isProminent: true,
                colorCode: firstProminentColorCode,
              },
            ]
          : undefined,
      };
    }) || []
  );
}

export const getAppliedTagsForApi = (
  formClassificationData: SectionRowProps[],
  appliedTags: TagsData
): SectionRowProps[] => {
  const { user_info: { user_name: userName = "" } = {} } = getPostLoginData();

  const data = formClassificationData?.map((item) => {
    const findProminentTag = (id: string): ProminentTagType =>
      item?.prominentTags?.tags?.find(
        (prompTag) => `${prompTag?.id}` === id
      ) || { name: "", type: "RED" };

    const simpleTags = appliedTags?.[item?.title]?.tagsData?.filter(
      (tag) => !tag?.isProminent
    );
    const prominentTags = appliedTags?.[item?.title]?.tagsData?.filter(
      (tag) => tag?.isProminent
    );

    return {
      ...item,
      value: appliedTags?.[item?.title]
        ? appliedTags?.[item?.title]?.tagsData?.[0]?.label
        : item?.value,
      selectedValueOfTags: appliedTags?.[item?.title]?.isMultiple
        ? appliedTags?.[item?.title]?.tagsData
        : [appliedTags[item?.title]?.tagsData?.[0]],
      tags: simpleTags?.length
        ? simpleTags?.map((item: any) => item?.label)
        : undefined,
      prominentTags: prominentTags?.length
        ? {
            ...item?.prominentTags,
            tags: prominentTags?.map((applTag) => {
              const currPrompTag = findProminentTag(applTag?.value);

              return currPrompTag?.name
                ? {
                    ...currPrompTag,
                    tagDetails: currPrompTag?.tagDetails
                      ? {
                          ...currPrompTag?.tagDetails,
                          tagNote: applTag?.comment,
                        }
                      : undefined,
                  }
                : {
                    name: applTag?.label,
                    id: applTag?.value,
                    type: applTag?.colorCode as ProminentTagsType,
                    tagDetails: {
                      tagName: applTag?.label,
                      tagsetName: item?.title,
                      tagNote: applTag?.comment,
                      tagsetId: (item?.additionalInfo as GetClassificationCustomTagsAddInfo)
                        ?.tagsetId,
                      tagsetDesc: applTag?.desc,
                      updatedBy: userName,
                      updatedOn: localTimeZoneToUtc(),
                    },
                  };
            }),
          }
        : undefined,
    };
  });

  return data;
};
