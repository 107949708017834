import { Dropdown, Menu } from "antd";
import styled from "styled-components";

import { hexToRgbA } from "../../utils";

export const DropDownButtonStyled = styled(Dropdown)`
  .dropdown-icon {
    svg {
      width: 6px;
      height: 6px;
    }
  }
`;

export const MenuStyled = styled(Menu)<{
  width?: string;
}>`
  width: ${({ width }): string => width || "130px"};
  min-width: 130px;
  padding: 0px;
  top: "3px";
  color: ${({ theme }): string => theme.textColors.BLUE_116};
  font-family: OpenSans;
  font-size: 13px;
  font-weight: normal;
  border-radius: 2px;
  box-shadow: 0 0 4px 0
    ${({ theme }): string => hexToRgbA(theme.borderColors.BLACK_COLOR, 0.16)};

  svg,
  span {
    vertical-align: middle;
  }

  span {
    line-height: 20px;
  }

  .caution {
    color: ${({ theme }): string => theme.textColors.RED_100};
    svg {
      path {
        fill: ${({ theme }): string => theme.textColors.RED_100};
      }
    }
  }

  .disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const MenuItemStyled = styled.div<{
  isDisabled?: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 9px;
  border-bottom: 1px solid ${({ theme }): string => theme.borderColors.GREY_10};
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  svg {
    width: 12px;
    height: 12px;
  }

  opacity: ${({ isDisabled }): string => (isDisabled ? "0.5" : "1")};
  pointer-events: ${({ isDisabled }): string => (isDisabled ? "none" : "auto")};
  cursor: ${({ isDisabled }): string =>
    isDisabled ? "not-allowed" : "pointer"};

  &.delete-btn-icon,
  &.delete-btn-icon .ant-btn-link {
    align-items: center;
    color: ${({ theme }): string => theme.textColors.RED_100};
    svg {
      path {
        fill: ${({ theme }): string => theme.textColors.RED_100};
      }
    }
  }

  &:hover {
    background: ${({ theme }): string => theme.bgColors.BLUE_116};
    color: ${({ theme }): string => theme.textColors.WHITE_COLOR};

    svg {
      path {
        fill: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
      }
    }
  }
`;
