import styled from "styled-components";

export const EmailEditorWrapperStyled = styled.div`
  min-height: 150px;
  max-width: 650px;
  border: 1px solid ${({ theme }): string => theme.textColors.GREY_18};
  :focus {
    outline: none;
    border: 1px solid ${({ theme }): string => theme.textColors.YELLOW_RD};
  }
  :focus-within {
    border: 1px solid ${({ theme }): string => theme.textColors.YELLOW_RD};
  }
`;

export const EmailTagWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;
  padding: 8px;
  max-width: 650px;
  flex-wrap: wrap;
  row-gap: 10px;
  max-height: 250px;
  overflow-y: auto;
  /* border: 1px solid ${({ theme }): string => theme.textColors.GREY_18}; */
  /* min-height: 150px; */

  input {
    flex: 1;
    border: none;
    outline: none;
    /* min-width: auto; */
  }

  /* :focus {
    outline: none;
    border: 1px solid ${({ theme }): string => theme.textColors.YELLOW_RD};
  } */
  /* input:focus {
    border: 3px solid #555;
  } */
  /* :focus-within {
    border: 1px solid ${({ theme }): string => theme.textColors.YELLOW_RD};
  } */
`;

export const EmailTagStyled = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 3px 8px;
  border: 1px solid ${({ theme }): string => theme.textColors.GREY_18};
  background: ${({ theme }): string => theme.textColors.LIGHT_GREY_22};
  border-radius: 4px;
  pointer-events: none;

  .cross-icon {
    cursor: pointer;
    pointer-events: all;
  }
`;
