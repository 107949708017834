import styled from "styled-components";
import { InformativeTextStyledProps } from "./informativetext.types";

export const InformativeTextStyled = styled.div<InformativeTextStyledProps>`
  &.informative-text {
    font-size: 12px;
    color: ${({ theme }): string => theme?.textColors?.GREY_26};
    margin-bottom: ${({ marginBottom }): string => marginBottom || ""};
    margin-top: ${({ marginTop }): string => marginTop || ""};
    width: ${({ width }): string => width || "100%"};
  }
`;
