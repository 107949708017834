import { useMemo, useState } from "react";
import { Card } from "antd";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";

import {
  BarChart as Chart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label,
  TooltipProps,
} from "recharts";

import RechartHOC from "../recharthoc/recharthoc";
import { ColumnChartStyled, CustomTooltipStyled } from "./column.styles";

import { RechartHOCEmbedProps } from "../recharthoc/recharthoc.types";
import { ColumnChartProps } from "./columnchart.types";

import { numberFormatter } from "../../../utils";
import { X_AXIS_DATA_KEY } from "../../../constants";

import { DEFAULT_MARGIN } from "../defaultmargin";
import { DEFAULT_X_AXIS_LABEL } from "../defaultxaxislabel";

import { DEFAULT_Y_AXIS_LABEL } from "../defaultyaxislabel";
import CustomizedAxisTick from "../customizedaxistick";
import { getYAxisMaxValWithNearestRoundedVal } from "../rechart.utills";
import LinkButton from "../../linkbutton";
import { redirectCircledIcon } from "../../../svgs";
import { onBarClickRouteNames } from "../../widgetcard/widgetcard.util";
import CustomizedLabel from "../customizedlabel/customizedlabel";
import { calculateInterval } from "../customizedlabel/customizedlabel.utils";

const ColumnChartContent = (
  props: ColumnChartProps & RechartHOCEmbedProps
): JSX.Element => {
  const {
    width = "100%",
    height = 500,
    data = [],
    clonedDataKeys = [],
    xAxislabel,
    legends,
    margin,
    isStacked,
    updatedYAxis,
    isMultipleYAxis,
    isTiltedXAxisTicks = false,
    hideXAxis = false,
    showCartesianGrid = true,
    hideYAxis = false,
    keepStaicBarSize = true,
    onClick,
    onDomainLoad,
    chartYAxisLeftOffset = 0,
    chartInnerSpaceFromLeft = 30,
    tooltipFormatter,
    yAxisFormatter,
    showYAxisLabels = true,
    footerComponent,
    xAxisTickFormatter,
    tooltipLabelFormatter,
    onBarClick,
    isButtonInTooltip = false,
    govViewCategory,
    customLabel,
    listOfDataKeys = [],
  } = props;

  const [labelInterval, setLabelInterval] = useState(0);

  const cursorStyle = onBarClick ? "pointer" : "default";
  const [payload, setPayload] = useState<{ y: number; x: number } | undefined>(
    undefined
  );

  const [trigger, setTrigger] = useState(false);

  const CustomTooltip = ({
    active,
    payload,
    label,
  }: TooltipProps<ValueType, NameType>): JSX.Element => {
    if (active && payload && payload.length) {
      return (
        <CustomTooltipStyled
          onMouseEnter={(): void => setTrigger(true)}
          onMouseLeave={(): void => setTrigger(false)}
        >
          <div className="label-text">
            <p className="label">{`${label}`}</p>
            {onBarClick && (
              <LinkButton
                className="tooltip-button"
                onClick={(e): void => {
                  onBarClick?.(label);
                  e.stopPropagation();
                }}
                tooltipProps={{
                  title: `You will be redirected to the ${
                    onBarClickRouteNames[govViewCategory || ""]
                  } with this filter applied by default.`,
                  placement: "top",
                }}
              >
                {redirectCircledIcon("15px", "14px")}
              </LinkButton>
            )}
          </div>

          <div className="content">
            {payload?.map((pld, index) => (
              <div key={index} className="content-row">
                <div style={{ color: pld.color }}>{`${pld.dataKey}: `}</div>

                <div style={{ color: pld.color }}>
                  {numberFormatter(
                    String(Number.isNaN(pld?.value) ? 0 : Number(pld.value))
                  )}
                </div>
              </div>
            ))}
          </div>
        </CustomTooltipStyled>
      );
    }

    return <div />;
  };

  const lengths = data
    .map((obj) => {
      return Object.values(obj)
        .filter((value) => typeof value === "number")
        .map((num) => num.toString().length);
    })
    .flat();

  // Calculate average length
  const totalLength = lengths.reduce((acc, len) => acc + len, 0);
  const averageLength = Math.round(totalLength / lengths.length);

  return (
    <Card className="chart-card">
      <ColumnChartStyled>
        <ResponsiveContainer
          width={width}
          height={height}
          onResize={(width, height): void => {
            setLabelInterval(
              calculateInterval(
                width * (averageLength > 4 ? 0.5 : 1),
                data?.length
              )
            );
          }}
        >
          <Chart
            className="column-chart"
            data={data}
            margin={{
              ...DEFAULT_MARGIN,
              left: chartYAxisLeftOffset - chartInnerSpaceFromLeft,
              ...margin,
            }}
            {...(!keepStaicBarSize && { barCategoryGap: 0.1 })}
            onClick={onClick}
          >
            {showCartesianGrid && (
              <CartesianGrid
                strokeDasharray="1"
                vertical={false}
                stroke="#e6e6e6"
              />
            )}

            <XAxis
              dataKey={X_AXIS_DATA_KEY}
              stroke="#8a8c8c"
              axisLine={false}
              tickLine={false}
              height={60}
              hide={hideXAxis}
              tick={
                isTiltedXAxisTicks ? (
                  <CustomizedAxisTick
                    textAnchor="end"
                    fill="#8A8C8C"
                    transform="rotate(-20)"
                  />
                ) : (
                  { fill: "#8A8C8C" }
                )
              }
              tickFormatter={xAxisTickFormatter}
            >
              {xAxislabel && (
                <Label {...{ ...DEFAULT_X_AXIS_LABEL, ...xAxislabel }} />
              )}
            </XAxis>
            {updatedYAxis?.map((item) => (
              <YAxis
                key={`column-chart-y-axis-${item?.yAxisId}`}
                axisLine={false}
                tickLine={false}
                hide={hideYAxis || item?.hide}
                orientation={item?.orientation}
                stroke="#8a8c8c"
                tickFormatter={(val): string => {
                  onDomainLoad?.(val);
                  return `${item?.prefix || ""}${
                    yAxisFormatter?.(val, item?.yAxisId || "") ||
                    numberFormatter(val)
                  }${item?.suffix || ""}`;
                }}
                {...(isMultipleYAxis &&
                  !isStacked && {
                    ...item,
                    label: undefined,
                  })}
                {...(item?.hide
                  ? {
                      domain: ([min, max]): [number, number] => {
                        const minValue = item?.minYDomain || min || 0;
                        const maxVAlue = item?.maxYDomain || max || 0;

                        const finiteMinValue = Number.isFinite(minValue)
                          ? minValue
                          : 0;

                        const finiteMaxValue = Number.isFinite(maxVAlue)
                          ? maxVAlue
                          : 0;

                        const maxValueWithBuffer = getYAxisMaxValWithNearestRoundedVal(
                          finiteMaxValue
                        );

                        return [finiteMinValue, maxValueWithBuffer];
                      },
                    }
                  : /* ! operator is being used with minYDomain because it won't have
                    any impact on current implementation even if minYDomain is undefined
                    it can be changed in future. */
                    {
                      domain: [
                        item?.minYDomain! >= 0 ? 0 : item?.minYDomain || "auto",
                        item?.maxYDomain! <= 0 ? 0 : item?.maxYDomain || "auto",
                      ],
                    })}
              >
                {item?.label && (
                  <Label
                    {...{
                      ...DEFAULT_Y_AXIS_LABEL,
                      ...item?.label,
                      value: showYAxisLabels ? item?.label?.value : "",
                    }}
                  />
                )}
              </YAxis>
            ))}

            <Tooltip
              wrapperStyle={{
                pointerEvents: "auto",
                zIndex: 12,
              }}
              trigger={
                isButtonInTooltip ? (trigger ? "click" : "hover") : "hover"
              }
              {...(isButtonInTooltip && { content: <CustomTooltip /> })}
              formatter={(val, name): string => {
                const prefix =
                  updatedYAxis?.find((item) => item?.yAxisId === name)
                    ?.prefix || "";

                const suffix = `${
                  updatedYAxis?.find((item) => item?.yAxisId === name)
                    ?.suffix || ""
                }`;

                return `${prefix}${
                  tooltipFormatter?.(Number(val), `${name}`) ||
                  numberFormatter(`${val}`, true)
                }${suffix}`;
                // return `${val}`;
              }}
              position={
                // !isButtonInTooltip ? undefined : { y: 100, x: payload?.x - 185 }
                !isButtonInTooltip
                  ? undefined
                  : payload
                  ? { y: payload?.y, x: payload?.x }
                  : undefined
              }
              labelFormatter={tooltipLabelFormatter}
              separator=": "
            />
            {legends}
            {clonedDataKeys?.map((key) => (
              <Bar
                isAnimationActive={!customLabel}
                {...key}
                {...(keepStaicBarSize && { barSize: 60 })}
                key={`columnchart-datakey-${key?.dataKey}`}
                {...(isMultipleYAxis &&
                  !isStacked && { yAxisId: key?.dataKey?.toString() })}
                {...(isStacked && { stackId: "a" })}
                {...(!isStacked && { minPointSize: 2 })}
                onClick={(e): void =>
                  !isButtonInTooltip
                    ? onBarClick?.(e?.payload?.name)
                    : undefined
                }
                cursor={cursorStyle}
                onMouseEnter={(props): void => {
                  setPayload(props);
                }}
                onMouseLeave={(): void => {
                  setPayload(undefined);
                }}
                {...(customLabel && {
                  label: (props): JSX.Element => (
                    <CustomizedLabel
                      props={{
                        ...props,
                        data,
                        key,
                        isStacked,
                        chartType: "COL",
                        labelInterval,
                        listOfDataKeys,
                      }}
                    />
                  ),
                })}
              />
            ))}
          </Chart>
        </ResponsiveContainer>
        {footerComponent}
      </ColumnChartStyled>
    </Card>
  );
};

const ColumnChart = (props: ColumnChartProps): JSX.Element => {
  return (
    <RechartHOC
      dataKeys={props?.dataKeys}
      yAxis={props?.yAxis}
      showLedgends={props?.showLedgends}
      legendVerticalAlignment={props?.legendVerticalAlignment}
      legendHorizontalAlignment={props?.legendHorizontalAlignment}
    >
      <ColumnChartContent {...props} />
    </RechartHOC>
  );
};
export default ColumnChart;
