//   <--Library Imports Start-->
import styled from "styled-components";
//   <--Library Imports End-->

export const NodeRefQuickEditEditViewStyled = styled.div`
  border-top: 1px solid
    ${({ theme }): string => theme?.borderColors?.LIGHT_GREY_51};
  .content-sec {
    width: 554px;
    height: max-content;
    margin: 29.5px 24px 0;
    padding: 20px 20px 10px 0px;
    background-color: ${({ theme }): string => theme?.bgColors?.LIGHT_GREY_22};

    .edit-ref-form-footer {
      display: flex;
      align-items: center;

      .left {
        flex: 1;
      }
    }
  }
`;
