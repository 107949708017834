import styled from "styled-components";
import { hexToRgbA } from "../../../../utils";

export const EditableGridStyled = styled.div<{
  isClassificationTagset: boolean;
  isScrollExists: boolean;
}>`
  background: ${({ theme }): string => theme.bgColors.BLUE_11};
  padding: 13.6px 15.2px 15.2px 16.8px;

  .grid-wrapper {
    background: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
    padding: 20px 17.6px 17.6px 19.2px;

    .action-buttons-row {
      display: flex;
      gap: 21px;
      font-size: 15px;
      font-family: OpenSansSemiBold;
      margin-bottom: 11px;

      .ant-btn-link {
        display: flex;
        gap: 3px;
        align-items: center;
        height: 19.2px;

        svg {
          path {
            fill: ${({ theme }): string => theme.textColors.BLUE_105};
          }

          .plus-circle-cls-1 {
            stroke: ${({ theme }): string => theme.borderColors.BLUE_105};
          }
        }
      }

      .delete-btn {
        color: ${({ theme }): string => theme.textColors.RED_100};

        svg {
          path {
            fill: ${({ theme }): string => theme.textColors.RED_100};
          }
        }
      }
    }

    .grid {
      .grid-header {
        background: ${({ theme }): string => theme.bgColors.BLUE_11};
        display: grid;
        grid-template-columns: ${({ isClassificationTagset }): string =>
          isClassificationTagset
            ? "0.8fr 1fr 0.4fr 0.4fr 0.3fr"
            : "0.8fr 1fr 0.4fr 0.3fr"};
        border: 1px solid
          ${({ theme }): string => hexToRgbA(theme.bgColors.LIGHT_GREY_11, 0.1)};
        font-size: 13px;
        font-family: OpenSansSemiBold;
        overflow-y: auto;
        padding-right: ${({ isScrollExists }): string =>
          isScrollExists ? "12px" : "0"};

        .column:last-child {
          border: none;
        }
      }

      .grid-content {
        max-height: 230px;
        overflow-y: auto;
        border: 1px solid
          ${({ theme }): string => hexToRgbA(theme.bgColors.LIGHT_GREY_11, 0.1)};
        border-top: none;

        .column {
          height: 34px;
        }
      }
    }

    .column {
      padding: 9px 11px;
      border-right: 1px solid
        ${({ theme }): string => hexToRgbA(theme.bgColors.LIGHT_GREY_11, 0.1)};
      /* overflow: hidden; */

      .ant-input {
        padding: 9px 11px;
        font-size: 13px;
        border: 1px solid transparent;

        &:hover,
        :focus {
          border: 1px solid
            ${({ theme }): string => theme.borderColors.BLUE_105};
        }
      }
    }

    .editable-column {
      padding: 0px;

      .prominent-tag-container {
        display: flex;
        align-items: center;
        padding-left: 11px;
        height: 100%;
        column-gap: 8px;
        position: relative;
        cursor: pointer;

        .prominent-tag-wrapper {
          display: flex;
          align-items: center;
          column-gap: 8px;

          .generic-prominent-tag-container {
            height: 22px;
            width: 48px;
            padding: 0 6.4px;

            .generic-prominent-tag-name {
              font-size: 12px;
            }
          }
        }
      }

      .dvsum-popover {
        padding: 0;
        .grid-prominent-tags-card {
          background: white;
          padding: 12px;
          box-shadow: 0 4px 8px 0 rgb(0 0 0 / 15%);
          border: solid 1px #c9c9c9;
          color: #949494;
          width: 200.4px;

          .tags-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            row-gap: 12.8px;
            margin-top: 12px;
            height: 96px;

            .ant-btn-link {
              .generic-prominent-tag-container {
                height: 22px;
                min-width: 48px;

                .generic-prominent-tag-name {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }

    .first-column,
    .first-header-column {
      display: grid;
      grid-template-columns: 12px auto;
      align-items: center;
      gap: 19px;
      margin: 0px;
      margin-left: 14px;

      .ant-checkbox-wrapper {
        width: 12px;
        height: 12px;
        align-items: center;

        .ant-checkbox {
          top: 0.1em;

          .ant-checkbox-inner {
            width: 12px;
            height: 12px;

            &::after {
              transform: rotate(45deg) scale(0.8) translate(-90%, -63%);
            }
          }
        }
      }
    }

    .first-header-column {
      margin-left: 4px;
    }

    .first-column {
      gap: 14px;

      .ant-input {
        padding: 9px 5px;
      }
    }

    .action-column {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
