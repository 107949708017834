// <--- Types Start --->
import { SourceTypes } from "../app.types";
// <--- Types End --->

export function getDataSourceNameByType(sourceType: SourceTypes): string {
  switch (sourceType) {
    case "SNF":
      return "Snowflake";
    case "SLF":
      return "Salesforce";
    case "MSS":
      return "SQL Server";
    case "ORC":
      return "Oracle";
    case "MSQ":
      return "MySQL";
    case "DB2":
      return "DB2";
    case "EXL":
      return "File Upload";
    case "AS3":
      return "S3";
    case "ASQ":
      return "Azure SQL";
    case "SNP":
      return "Synapse";
    case "DBS":
      return "Databricks";
    case "PSQ":
      return "Postgress";

    default:
      return "";
  }
}
