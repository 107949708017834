import { AlertStyled } from "./alert.styles";
import { AppAlertProps } from "./alert.types";

const Alert = ({
  showBorder = true,
  showBackground = true,
  className,
  ...props
}: AppAlertProps): JSX.Element => {
  const myClassName = `${className || ""} dvsum-alert ${
    !showBorder ? "no-border" : ""
  }${!showBackground ? " bg-transparent" : ""}`;

  return <AlertStyled className={myClassName} {...props} />;
};

export default Alert;
