import * as yup from "yup";
import { REQUIRED_MESSAGE } from "../../constants/formconstants";

export const FieldsSchema = yup.object().shape({
  source_field: yup.string().required(REQUIRED_MESSAGE),
  target_field: yup.string().required(REQUIRED_MESSAGE),
});

export type CurrentObj = {
  [key: string]: string;
};

export const erdEdgeFormSchema = yup.object().shape({
  source_table: yup.string().required(REQUIRED_MESSAGE),
  target_table: yup.string().required(REQUIRED_MESSAGE),
  relationship_status: yup.string().required(REQUIRED_MESSAGE),
  specify_fields: yup.lazy(() => {
    return yup
      .array()
      .of(
        yup.object().shape({
          source_field: yup.string().required(REQUIRED_MESSAGE),
          target_field: yup.string().required(REQUIRED_MESSAGE),
        })
      )
      .test("no-duplicate-keys", "Duplicate keys found", (currentFields) => {
        const currentFieldsLength = currentFields?.length || 0;
        const uniqueValues =
          new Set(
            currentFields?.map((v) => `${v?.source_field}${v?.target_field}`)
          ) || [];

        if (uniqueValues?.size < currentFieldsLength) {
          return false;
        }
        return true;
      });
  }),
});
