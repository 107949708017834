import styled from "styled-components";

export const AddNewJobFormStyled = styled.div`
  padding-top: 38px;
  padding-left: 260px;

  max-height: calc(100vh - 180px);
  /* max-height: 100vh; */
  overflow-y: auto;

  .ant-select {
    .ant-select-selector {
      height: 40px;
    }
  }

  textarea {
    width: 550px;
  }
`;

export const SourceNameAndTypeWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;

  .icon {
    svg {
      max-width: 25px;
    }

    img {
      max-width: 28px;
    }
  }
`;

export const SectionContainerStyled = styled.div<{ marginTop?: string }>`
  margin: 35px 0 20px;
  margin-top: ${({ marginTop }): any => marginTop && marginTop};

  .section-title {
    display: block;
    margin-top: 10px;
    margin-bottom: 5px;
    color: ${({ theme }): string => theme.textColors.BLUE_116};
    font-size: 16px;
    font-weight: 600;
  }

  .section-desc {
    color: ${({ theme }): string => theme.textColors.GREY_26};
    font-size: 12px;
    line-height: normal;
    margin-bottom: 10px;
    display: block;
    width: 510px;
  }

  .error {
    color: ${({ theme }): string => theme.textColors.RED};
    max-width: 650px;
    display: flex;
    justify-content: end;
  }

  .transfer-box-container {
    .search-container {
      .ant-input-prefix {
        svg {
          path {
            fill: ${({ theme }): string => theme.bgColors.BLUE_116};
          }
        }
      }
    }
  }

  .transfer {
    .right-title {
      right: 90px;
      min-width: 250px;
    }

    .app-transfer {
      .ant-transfer {
        .ant-transfer-list {
          .ant-transfer-list-body {
            .ant-transfer-list-content {
              .ant-transfer-list-content-item {
                .ant-transfer-list-content-item-text .item svg path {
                  fill: ${({ theme }): string => theme.bgColors.BLUE_116};
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const ScheduleSummaryStyled = styled.div`
  color: ${({ theme }): string => theme.textColors.GREY_40};
  font-family: OpenSansSemiBold;
  font-size: 13.6px;
  margin-bottom: 20px;
`;

export const AddNewJobFormFooterStyled = styled.div`
  background-color: ${({ theme }): string => theme.bgColors.LIGHT_BLUE_25};
  border-top: 1px solid
    ${({ theme }): string => theme.borderColors.LIGHT_BLUE_45};
  padding: 8px 30px 8px 10px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  bottom: 0px;
  width: 100%;
`;

export const SourceNameAndTypeStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const ChooseViewDescriptionStyled = styled.div`
  color: ${({ theme }): string => theme.textColors.GREY_26};
  font-size: 12px;

  .edit-view-text {
    color: ${({ theme }): string => theme.textColors.BLUE_181};
    font-size: 12px;
  }

  .create-view-text {
    display: block;
    font-size: 12px;
    color: ${({ theme }): string => theme.textColors.BLUE_116};
  }
`;

export const LoadingSpinerStyled = styled.div`
  padding: 5px 5px 5px 10px;
  opacity: unset;
  color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
`;

export const AddNewJobTabStyled = styled.div`
  .link-tabs {
    padding-left: 14px;
    padding-top: 14px;
    padding-bottom: 14px;
  }

  .tags-editor {
    width: 550px;
    padding-left: 25px;
    padding-top: 5px;
    min-height: 0px;
  }

  .tag-category {
    font-size: 14px;
  }

  .tag-value {
    font-size: 14px;
  }

  .tag-label {
    font-size: 14px;
  }

  .remove-tag {
    margin-top: 8px;
  }

  .left-icon {
    margin-top: 4px;
  }

  .right-icon {
    margin-top: 4px;
  }

  .transfer {
    padding-left: 36px;
    padding-top: 5px;
  }

  .inactive-tab {
    color: ${({ theme }): string => theme.textColors.GREY_210};
  }
  /* 
  .ant-btn-link:not(:last-of-type) {
    margin-right: 19px;
  } */
`;
