import styled from "styled-components";

export const SearchFieldStyled = styled.div`
  .ant-input-affix-wrapper {
    padding: 7px 11px;

    .ant-input-prefix {
      margin-right: 7px;
      svg {
        width: 16px;
        height: 16px;
      }
    }

    .ant-input {
      font-size: 12px;
    }

    .ant-input-suffix {
      cursor: pointer;
      svg {
        width: 11px;
        height: 11px;
        opacity: 0.9;
      }
    }
  }
`;
