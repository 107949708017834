import styled from "styled-components";

export const AddGlossaryDomainFormStyled = styled.div<{ maxHeight?: string }>`
  .ant-form {
    .ant-form-item {
      .ant-form-item-control {
        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            .input-field-container > input,
            .textarea-field-container > textarea,
            .dvsum-select-field {
              width: 512px;
            }
          }
        }
      }

      .transfer-left-tilte {
        display: flex;
        align-items: center;
        > svg {
          margin-left: 6px;
        }

        .available-filters-tooltip {
          .ant-tooltip-content {
            .ant-tooltip-arrow {
              .ant-tooltip-arrow-content {
                box-shadow: 0 0 7px 0 rgba(211, 211, 211, 0.85);
                background-color: ${({ theme }): string =>
                  theme.bgColors.WHITE_COLOR};
                width: 8px;
                height: 8px;
              }
            }
            .ant-tooltip-inner {
              width: 300px;
              min-height: 82px;
              padding: 16px 9.2px 18px 15px;
              box-shadow: 0 0 7px 0 rgba(211, 211, 211, 0.85);
              background-color: ${({ theme }): string =>
                theme.bgColors.WHITE_COLOR};
              font-size: 13px;
              color: ${({ theme }): string => theme.textColors.GREY_26};
            }
          }
        }
      }
    }

    .scroll-sec {
      padding-top: 29px;
      max-height: ${({ maxHeight }): string =>
        maxHeight || "calc(100vh - 142px)"};
      overflow: auto;
      .extended-checkbox {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .ant-checkbox-wrapper {
          font-family: OpenSansSemiBold;
        }
        .checkbox-desc {
          padding-left: 24px;
          color: ${({ theme }): string => theme?.textColors?.GREY_24};
          font-size: 12px;
          width: 510px;
          margin-top: 4px;
        }
      }
    }

    .form-item-with-inline-info {
      .ant-form-item-control-input-content {
        display: flex;
        align-items: center;
        column-gap: 8px;
      }
    }

    .form-actions-sec {
      position: absolute;
      width: 100vw;
      bottom: 0px;
      margin-bottom: 0px;
    }
  }
`;

export const WorkflowFieldsWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 24px;
`;

export const CheckboxTooltipWrapperStyled = styled.div`
  width: max-content;

  .domain-checkbox-tooltip {
    .ant-tooltip-content {
      .ant-tooltip-arrow {
        .ant-tooltip-arrow-content {
          box-shadow: 0 0 7px 0 rgba(211, 211, 211, 0.85);
          background-color: ${({ theme }): string =>
            theme.bgColors.WHITE_COLOR};
          width: 8px;
          height: 8px;
        }
      }
      .ant-tooltip-inner {
        width: 400px;
        min-height: 82px;
        padding: 16px 9.2px 18px 15px;
        box-shadow: 0 0 7px 0 rgba(211, 211, 211, 0.85);
        background-color: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
        font-size: 13px;
        color: ${({ theme }): string => theme.textColors.GREY_26};
      }
    }
  }
`;
