import { useMemo } from "react";

import SectionRow from "../../../../components/sectionrow";

import { ReadOnlyInformationProps } from "./readonlyinformation.types";

const ReadOnlyInformation = (props: ReadOnlyInformationProps): JSX.Element => {
  const { config } = props;

  const renderSectionRows = useMemo(() => {
    return config?.map((item) => <SectionRow key={item?.title} {...item} />);
  }, [config]);

  return <>{renderSectionRows}</>;
};

export default ReadOnlyInformation;
