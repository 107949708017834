// <--- Constants Start --->
import { LOCAL_STORAGE_CONSTANTS } from "../constants/localstorageconstants";
// <--- Constants End --->

// <--- Types Start --->
import { PostLoginFormType } from "../app.types";
// <--- Types End --->

// <--- Utils Start --->
import { isJsonString } from ".";
// <--- Utils End --->

export function getPostLoginData(): PostLoginFormType {
  const data =
    localStorage.getItem(LOCAL_STORAGE_CONSTANTS?.post_login_response) || "";
  const parsedData = isJsonString(data)
    ? JSON.parse(data)
    : ({} as PostLoginFormType);
  return parsedData;
}
