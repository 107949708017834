import styled from "styled-components";

export const RegistrationFormStyled = styled.div`
  background: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
  width: 556px;
  height: 556px;
  border-radius: 20px;
  box-shadow: 0 2px 12px 0 rgba(148, 148, 148, 0.16);
  display: grid;
  place-items: center;
  padding: 50.4px 40.8px 44.8px;

  .already-have-account {
    display: flex;
    flex-direction: row;
    gap: 5px;
    color: ${({ theme }): string => theme.textColors.LIGHT_GREY_11};
    font-size: 13.6px;
    justify-content: right;
    width: 100%;

    a {
      line-height: unset;
    }
  }

  .ant-form {
    .form-content {
      .ant-row {
        padding: 0px !important;
      }

      .name-fields {
        display: flex;
        flex-direction: row;
        gap: 20px;

        .ant-input {
          width: 227.2px;
        }
      }
    }
  }

  .terms-and-conditions {
    .ant-form-item-control-input-content {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 13.6px;

      .ant-checkbox-wrapper {
        width: auto !important;
      }

      .terms-and-conditions-text {
        color: ${({ theme }): string => theme.textColors.LIGHT_GREY_11};
        font-size: 13.6px;
      }
    }
  }

  .submit-btn {
    .ant-form-item-control-input-content {
      display: flex;
      justify-content: center;

      button {
        margin: 0px;
      }
    }
  }
`;
