import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Tooltip } from "antd";

import { useOnClickOutside } from "usehooks-ts";

import { VerticalDividerStyled } from "../../../../../../components/dividers/dividers.styles";
import { TrainBotActionBarStyled } from "./trainbotactionbar.styles";

import {
  actionsIcon,
  bulbIcon,
  circleTickSvg,
  collapseIcon,
  exclamationIconInCircle,
  expnadIcon,
  magnifierIcon,
  refreshIconOutlined,
  saveIcon,
  thumbsDownSvg,
  thumbsUpSvg,
  toReviewSvg,
} from "../../../../../../svgs";

import DropdownMenu from "../../../../../../components/dropdownmenu";
import {
  TrainBotActionBarPopupRefType,
  TrainBotActionBarProps,
} from "./trainbotactionbar.types";

import MultiSelectionCheckboxesPopup from "../../../../../../components/multiselectioncheckboxespopup";
import {
  checkIsChatPage,
  getPostLoginData,
  getUniqueList,
} from "../../../../../../utils";

import SingleSelectionPopup, {
  SingleSelectionPopupProps,
} from "../../../../../../components/singleselectionpopup";

import { TrainBotTimeframeFilters } from "../../../../analysisdetailpage.types";

import { filterQueriesBasedOnAppliedCriteria } from "../../analysisdetailpagecontentsec.utils";

import ConfirmationPopover from "../../../../../../components/confirmationpopover/confirmationpopover";
import Flex from "../../../../../../components/flex";

import { ELEMENT_IDS } from "../../../../../../constants";
import {
  useGetCurrentGateway,
  useGetAppState,
  useScrollingUp,
} from "../../../../../../customhooks";

import { ANALYSIS_BOT_TABS } from "../../../../analysisdetalpage.constants";
import LinkButton from "../../../../../../components/linkbutton/linkbutton";

import {
  HighlightedCircleBackround,
  SearchField,
} from "../../../../../../components";

import { useSearchFieldContext } from "../../../../../../contexts";

const allStatusIcon = circleTickSvg("20", "20");
const toReviewIcon = toReviewSvg("21", "21");
const approvedIcon = thumbsUpSvg("18", "18");
const rejectedIcon = thumbsDownSvg("18", "18");
const conceptIcon = bulbIcon("14", "20");
const failedToGenerateIcon = exclamationIconInCircle("18", "18");
const refreshDataIcon = refreshIconOutlined("16", "14 ");
const collapseSvg = collapseIcon("13", "13");
const expandSvg = expnadIcon("13", "13");

const {
  chat_bot_train_bot_stats_fil: CHAT_BOT_TRAIN_BOT_STATS_FIL,
  chat_bot_train_bot_stats_fil_cnt: CHAT_BOT_TRAIN_BOT_STATS_FIL_CNT,
  chatbot_train_bot_tab_dots_menu: CHATBOT_TRAIN_BOT_TAB_DOTS_MENU,
  chat_bot_train_bot_refr_all_btn: CHAT_BOT_TRAIN_BOT_REFR_ALL_BTN,
  caddi_qst_srch_field: CADDI_QST_SRCH_FIELD,
  caddi_time_frame_fil: CADDI_TIME_FRAME_FIL,
  caddi_time_frame_fil_cnt: CADDI_TIME_FRAME_FIL_CNT,
} = ELEMENT_IDS;

const isChatPage = checkIsChatPage();

const TrainBotActionBar = (props: TrainBotActionBarProps): JSX.Element => {
  const { user_info: userInfo } = getPostLoginData();
  const { user_name: username } = userInfo || {};

  const askedByRef = useRef<TrainBotActionBarPopupRefType>();
  const statusRef = useRef<TrainBotActionBarPopupRefType>();
  const timeFrameRef = useRef<TrainBotActionBarPopupRefType>();

  const askedByIsVisible = askedByRef?.current?.isVisible || false;
  const statusIsVisible = statusRef?.current?.isVisible || false;
  const timeFrameIsVisible = timeFrameRef?.current?.isVisible || false;

  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const { debouncedSearchText } = useSearchFieldContext();
  const [isSearchFilterExpanded, setIsSearchFilterExpanded] = useState(
    !!debouncedSearchText
  );

  const { isOnboardingMode } = useGetAppState();
  const isScrolled = useScrollingUp(ANALYSIS_BOT_TABS);

  const searchfieldRef = useRef<any>();
  useOnClickOutside(searchfieldRef, (): void => {
    !debouncedSearchText && setIsSearchFilterExpanded(false);
  });

  const {
    onChangeTrainBotFilter,
    onChangeTrainBotAskedByFilter,
    onRefreshAllClick,
    onExpandCollapseClickForTrainBot,
    onChangeTrainBotTimeFrameFilter,
    queries = [],
    trainBotFilteredQueries = [],
    trainBotFilter = "all",
    askedByFilter = ["all"],
    timeFrameFilter = "all",
    gatewayId = "",
  } = props;

  const { isGatewayRunning } = useGetCurrentGateway(gatewayId);

  const showMenu = isGatewayRunning;

  const onVisibleChange = useCallback((visibility) => {
    setIsMenuVisible(visibility);
  }, []);

  const closeMenuItemIfExpanded = useCallback(() => {
    setIsMenuVisible(false);
  }, []);

  const onChangeSearchFilterExpand = useCallback(() => {
    setIsSearchFilterExpanded((prev) => !prev);
  }, []);

  useEffect(() => {
    askedByRef?.current?.setIsVisible(askedByIsVisible);
    statusRef?.current?.setIsVisible(statusIsVisible);
    timeFrameRef?.current?.setIsVisible(timeFrameIsVisible);
  }, [isScrolled]);

  const onChangeFilter = useCallback(
    (filter) => {
      onChangeTrainBotFilter?.(filter);
    },
    [onChangeTrainBotFilter]
  );

  const onChangeTimeFrameFilter = useCallback(
    (filter) => {
      onChangeTrainBotTimeFrameFilter?.(filter);
    },
    [onChangeTrainBotTimeFrameFilter]
  );

  const isRefreshDisabled = !trainBotFilteredQueries?.length;

  const isEveryQueryRefreshing = trainBotFilteredQueries?.every(
    (query) => query?.is_loading && query?.loader_type === "running_analysis"
  );

  const dropdownMenu = useMemo(() => {
    const isExpandCollapseDisabled = !trainBotFilteredQueries?.length;

    const isAllqueriesExpanded = trainBotFilteredQueries?.every(
      (query) => query?.is_expanded
    );

    const isAllqueriesCollapsed = trainBotFilteredQueries?.every(
      (query) => !query?.is_expanded
    );

    return [
      ...(isGatewayRunning
        ? [
            {
              key: "refresh-data",
              label: (
                <ConfirmationPopover
                  heading="Refresh Data"
                  desc="This will fetch fresh data for all questions from the source. Are you sure?"
                  placement="leftTop"
                  arrowPointAtCenter
                  okText="Refresh"
                  cancelText="Cancel"
                  onOk={(): void => {
                    onRefreshAllClick?.();
                    closeMenuItemIfExpanded();
                  }}
                >
                  <Flex
                    columnGap={7}
                    elementId={CHAT_BOT_TRAIN_BOT_REFR_ALL_BTN}
                  >
                    {refreshDataIcon} Refresh Data
                  </Flex>
                </ConfirmationPopover>
              ),
              isDisabled: isRefreshDisabled || isEveryQueryRefreshing,
            },
          ]
        : []),
      {
        label: isAllqueriesExpanded ? (
          <Flex columnGap={10}>{collapseSvg}Collapse All</Flex>
        ) : isAllqueriesCollapsed ? (
          <Flex columnGap={10}>{expandSvg}Expand All</Flex>
        ) : (
          <Flex columnGap={10}>{expandSvg}Collapse All</Flex>
        ),
        key: "collapse-expand-all",
        isDisabled: isExpandCollapseDisabled,
        onClick: (): void => {
          onExpandCollapseClickForTrainBot?.(
            !isAllqueriesExpanded && isAllqueriesCollapsed
          )();
          closeMenuItemIfExpanded();
        },
      },
    ];
  }, [
    onExpandCollapseClickForTrainBot,
    trainBotFilteredQueries,
    isGatewayRunning,
  ]);

  const timeFrameFilterOptions: Array<{
    label: string;
    value: TrainBotTimeframeFilters;
  }> = useMemo(() => {
    return [
      {
        label: "Any Time",
        value: "all",
      },
      {
        label: "1 day",
        value: "last_1_day",
      },
      {
        label: "7 days",
        value: "last_7_day",
      },
      {
        label: "30 days",
        value: "last_30_days",
      },
      {
        label: "6 months",
        value: "last_6_months",
      },
      {
        label: "1 year",
        value: "last_1_year",
      },
    ];
  }, []);

  const trainBotFilters = useMemo(() => {
    const options: SingleSelectionPopupProps["options"] = [
      {
        label: "All",
        value: "all",
        count: filterQueriesBasedOnAppliedCriteria(
          "all",
          queries,
          askedByFilter,
          "asked_by_to_status",
          debouncedSearchText,
          timeFrameFilter
        )?.length,
        icon: (
          <div className="status-filter-item-icon stroke">{allStatusIcon}</div>
        ),
      },
      {
        label: "Bookmarked",
        value: "SVD",
        count: filterQueriesBasedOnAppliedCriteria(
          "SVD",
          queries,
          askedByFilter,
          "asked_by_to_status",
          debouncedSearchText,
          timeFrameFilter
        )?.length,
        icon: (
          <div className="status-filter-item-icon stroke">
            {saveIcon("16", "17", "1.4px")}
          </div>
        ),
        showSeparator: true,
      },
      {
        label: "To Review",
        value: "PS",
        count: filterQueriesBasedOnAppliedCriteria(
          "PS",
          queries,
          askedByFilter,
          "asked_by_to_status",
          debouncedSearchText,
          timeFrameFilter
        )?.length,
        icon: (
          <div className="status-filter-item-icon fill">{toReviewIcon}</div>
        ),
      },
      {
        label: "Failed to Generate",
        value: "INC",
        count: filterQueriesBasedOnAppliedCriteria(
          "INC",
          queries,
          askedByFilter,
          "asked_by_to_status",
          debouncedSearchText,
          timeFrameFilter
        )?.length,
        icon: (
          <div className="status-filter-item-icon circle">
            {failedToGenerateIcon}
          </div>
        ),
      },
      {
        label: "Approved",
        value: "APR",
        count: filterQueriesBasedOnAppliedCriteria(
          "APR",
          queries,
          askedByFilter,
          "asked_by_to_status",
          debouncedSearchText,
          timeFrameFilter
        )?.length,
        icon: (
          <div className="status-filter-item-icon stroke">{approvedIcon}</div>
        ),
      },
      {
        label: "Rejected",
        value: "REJ",
        count: filterQueriesBasedOnAppliedCriteria(
          "REJ",
          queries,
          askedByFilter,
          "asked_by_to_status",
          debouncedSearchText,
          timeFrameFilter
        )?.length,
        icon: (
          <div className="status-filter-item-icon stroke">{rejectedIcon}</div>
        ),
      },
      {
        label: "Concepts",
        value: "CNP",
        count: filterQueriesBasedOnAppliedCriteria(
          "CNP",
          queries,
          askedByFilter,
          "asked_by_to_status",
          debouncedSearchText,
          timeFrameFilter
        )?.length,
        icon: (
          <div className="status-filter-item-icon stroke">{conceptIcon}</div>
        ),
      },
    ];
    return options?.filter(
      (item) =>
        (item?.count || 0) > 0 ||
        item?.value === trainBotFilter ||
        item?.value === "all"
    );
  }, [
    queries,
    askedByFilter,
    debouncedSearchText,
    trainBotFilter,
    timeFrameFilter,
    trainBotFilteredQueries,
  ]);

  const askedBylist = useMemo(() => {
    const allOption = {
      label: "Everyone",
      value: "all",
      checked: askedByFilter?.includes("all"),
      isAllOption: true,
      count: filterQueriesBasedOnAppliedCriteria(
        trainBotFilter,
        queries,
        ["all"],
        "asked_by_to_status",
        debouncedSearchText,
        timeFrameFilter
      )?.length,
    };

    const createdByList: string[] = getUniqueList(
      queries?.map((query) => query?.qry_created_by)
    );

    const options = createdByList
      ?.map((createdBy) => ({
        label: createdBy || "",
        labelSuffix: username === createdBy ? "(You)" : "",
        value: createdBy || "",
        checked:
          askedByFilter?.includes("all") || askedByFilter?.includes(createdBy),
        count: filterQueriesBasedOnAppliedCriteria(
          trainBotFilter,
          queries,
          [createdBy],
          "asked_by_to_status",
          debouncedSearchText,
          timeFrameFilter
        )?.length,
      }))
      ?.sort((a, b) => {
        if (a.labelSuffix === "(You)") return -1;
        if (b.labelSuffix === "(You)") return 1;
        return a.label.localeCompare(b.label);
      })
      ?.filter((item) => item?.label)
      ?.filter(
        (item) =>
          item?.count > 0 ||
          askedByFilter?.includes(item?.value) ||
          item?.value === "all"
      );

    return [allOption, ...options];
  }, [
    queries?.length,
    askedByFilter,
    trainBotFilter,
    username,
    debouncedSearchText,
    timeFrameFilter,
  ]);

  return (
    <TrainBotActionBarStyled>
      {!!queries?.length && (
        <>
          {askedBylist?.length > 0 && !isChatPage && (
            <MultiSelectionCheckboxesPopup
              ref={askedByRef}
              key={isScrolled ? "scrolled-askedby" : "not-scrolled-askedby"}
              title="Asked by"
              options={askedBylist}
              onChange={onChangeTrainBotAskedByFilter}
            />
          )}
          {!!trainBotFilters?.length && (
            <>
              <VerticalDividerStyled
                height="9px"
                width="0px"
                marginLeft="4.8px"
                marginRight="4.8px"
              />
              <SingleSelectionPopup
                ref={statusRef}
                key={isScrolled ? "scrolled-status" : "not-scrolled-status"}
                title="Status"
                options={trainBotFilters}
                selectedOption={trainBotFilter}
                onChange={onChangeFilter}
                titleId={CHAT_BOT_TRAIN_BOT_STATS_FIL}
                contentId={CHAT_BOT_TRAIN_BOT_STATS_FIL_CNT}
              />
            </>
          )}
        </>
      )}

      {!isChatPage && !!queries?.length && (
        <>
          <VerticalDividerStyled
            height="9px"
            width="0px"
            marginLeft="4.8px"
            marginRight="4.8px"
          />
          <SingleSelectionPopup
            ref={timeFrameRef}
            key={isScrolled ? "scrolled-timeframe" : "not-scrolled-timeframe"}
            title="Timeframe"
            options={timeFrameFilterOptions}
            selectedOption={timeFrameFilter}
            onChange={onChangeTimeFrameFilter}
            width="135px"
            titleId={CADDI_TIME_FRAME_FIL}
            contentId={CADDI_TIME_FRAME_FIL_CNT}
          />
        </>
      )}
      {/* will enable this option in future when we have the the link for it*/}
      {/* <Tooltip
        title={
          <Flex columnGap={9} customClass="open">
            How to train CADDI Bot? {redirectIconSvg}
          </Flex>
        }
        overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
        placement="bottomRight"
        getPopupContainer={(trigger): HTMLElement =>
          trigger?.parentNode as HTMLElement
        }
      >
        <div
          className="ques-mark-icon"
          role="button"
          onClick={onCaddiTrainngClick}
        >
          ?
        </div>
      </Tooltip> */}

      {!!queries?.length && (
        <div
          className="search-sec"
          ref={searchfieldRef}
          key={isScrolled ? "scrolled-searchbox" : "not-scrolled-searchbox"}
        >
          <VerticalDividerStyled
            height="9px"
            width="1px"
            marginLeft="13px"
            marginRight="6px"
          />
          <div id={CADDI_QST_SRCH_FIELD}>
            {isSearchFilterExpanded ? (
              <SearchField />
            ) : (
              <LinkButton
                tooltipProps={{
                  title: "Search Questions",
                  placement: "bottomRight",
                }}
                onClick={onChangeSearchFilterExpand}
              >
                <HighlightedCircleBackround className="caddi-search-icon">
                  {magnifierIcon()}
                </HighlightedCircleBackround>
              </LinkButton>
            )}
          </div>
        </div>
      )}
      {!!queries?.length && !isChatPage && (
        <>
          <VerticalDividerStyled
            height="9px"
            width="1px"
            marginLeft="6px"
            marginRight="6px"
          />
          <Tooltip
            title={<DropdownMenu menu={dropdownMenu} width="140px" />}
            trigger={["click"]}
            placement="bottomRight"
            {...(isOnboardingMode && !isMenuVisible && { onVisibleChange })}
            {...(!isOnboardingMode ? { onVisibleChange } : {})}
            visible={isMenuVisible}
            overlayClassName="analysis-right-section-actions-tooltip"
            getPopupContainer={(trigger): HTMLElement =>
              trigger.parentNode as HTMLElement
            }
            key={isScrolled ? "scrolled-dot-menu" : "not-scrolled-dot-menu"}
          >
            <div
              className="dot-menu-btn"
              data-testid="analysis-detail-page-header-right-sec-action-btns-edit-btn"
              id={CHATBOT_TRAIN_BOT_TAB_DOTS_MENU}
            >
              {actionsIcon()}
            </div>
          </Tooltip>
        </>
      )}
    </TrainBotActionBarStyled>
  );
};

export default TrainBotActionBar;
