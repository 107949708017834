import { VerticalDividerStyled } from "../../../../../../../../../../../components/dividers/dividers.styles";

import LinkButton from "../../../../../../../../../../../components/linkbutton/linkbutton";
import { checkIsChatPage } from "../../../../../../../../../../../utils/ischatpage";

import {
  maximizeIcon,
  minimizedIcon,
} from "../../../../../../../../../../../svgs";

import { checkQueryType } from "../../../../../../../../../analisisdetailpage.utils";

import { QueryActionSectionStyled } from "./queryactionsection.styles";
import { QueryActionSectionProps } from "./queryactionsection.types";
import { checkIsViewOnlyChatQuestionPage } from "../../../../../../../../../../../utils";

const maximizedIcon = maximizeIcon();
const minimizeIcon = minimizedIcon();

const QueryActionSection = (props: QueryActionSectionProps): JSX.Element => {
  const { queryItem, handlers, hasUserAccessToEdit = false } = props;

  const { onMinMax } = handlers || {};

  const { header, id = "", type, sub_type: subType, is_focused: isFocused } =
    queryItem || {};

  const { is_maximize_query_block: isMaximizeQueryBlock = false } =
    header || {};

  const isChatPage = checkIsChatPage();
  const isViewOnlyChatQuestionPage = checkIsViewOnlyChatQuestionPage();

  const {
    isSummary,
    isAboutPrompt,
    isConceptQuery,
    isGuideMeSec,
    isTermQuery,
  } = checkQueryType(type, subType);

  const showMenu =
    !isSummary && !isGuideMeSec && !isConceptQuery && !isAboutPrompt;

  return (
    <QueryActionSectionStyled>
      <div
        className="query-block-header-right-sec"
        data-testid="analysis-detail-page-query-block-header-right-sec"
      >
        {!isViewOnlyChatQuestionPage &&
        (isChatPage || hasUserAccessToEdit) &&
        showMenu &&
        !isTermQuery ? (
          <>
            <VerticalDividerStyled
              width="0"
              height="14px"
              marginLeft="16px"
              marginRight="16px"
            />
            <LinkButton
              tooltipProps={{
                title: isMaximizeQueryBlock
                  ? "Exit Full Screen"
                  : "Expand to full screen",
                placement: "left",
              }}
              onClick={(e): void => {
                onMinMax?.(id, e);
              }}
              data-testid={`analysis-detail-page-query-block-header-right-sec-${
                isMaximizeQueryBlock ? "minimize" : "maximize"
              }-btn`}
            >
              {isMaximizeQueryBlock ? minimizeIcon : maximizedIcon}
            </LinkButton>
          </>
        ) : (
          <div />
        )}
      </div>
    </QueryActionSectionStyled>
  );
};

export default QueryActionSection;
