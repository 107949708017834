import styled from "styled-components";
import { MatchingStatusPreviewerProps } from "./matchingstatuspreviewer.types";

export const MatchingStatusPreviewerStyled = styled.div<MatchingStatusPreviewerProps>`
  &.status-icon {
    display: flex;
    align-items: center;
    ${({ isSpaceBetween, isContainingAdditionalInfo }): string =>
      isSpaceBetween && !isContainingAdditionalInfo
        ? "flex: 1"
        : "margin-right:10px"};

    svg {
      g {
        stroke: ${({ theme }): string => theme.bgColors.GREEN_11};
      }
      path {
        fill: ${({ theme }): string => theme.bgColors.BLUE_116};
      }

      circle {
        fill: ${({ theme }): string => theme.bgColors.GREEN_11};
      }
    }

    .matching-status-approve {
      path {
        stroke: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
      }
    }

    .empty-icon {
      width: 13px;
    }
  }
`;
