import { useCallback } from "react";
import { useForm } from "react-hook-form";

import { useCancelModal, useGetAppState } from "../../../../customhooks";
import Button from "../../../button";

import { FormItemStyled, FormStyled } from "../../../form";
import {
  ResolveInputsWrapperStyled,
  ResolveModalStyled,
} from "./resolvemodal.styles";

import FormItemLabel from "../../../form/formitemlabel/formitemlabel";
import { SelectField, TextAreaField } from "../../../formfields";

import {
  ResolveModalFormType,
  ResolveModalPropsType,
} from "./resolvemodal.types";

import { useRequestWithMethod } from "../../../../api";
import StateHandler from "../../../statehandler/statehandler";

import { useGetRefByType } from "../../../../api/refservice";

const ResolveModal = (): JSX.Element => {
  const {
    modal: { modalProps = {} },
  } = useGetAppState();

  const {
    onExecuteRequest,
    isActionLoading,
  } = modalProps as ResolveModalPropsType;

  const {
    parsedData: parsedResolveReasons,
    isLoading: isLoadingResolveReasons,
    error: errorInResolveReasons,
  } = useGetRefByType("DQ_RULE_RSL_STATUS");

  const resolveReasonsSelectOptions =
    parsedResolveReasons?.map((item) => ({
      key: item?.id,
      label: item?.name,
      value: item?.id,
    })) || [];

  const resolveModalForm = useForm<ResolveModalFormType>({
    defaultValues: {
      reason_code: "PFX",
      comment: "",
    },
    mode: "onChange",
  });

  const { control, setValue, handleSubmit } = resolveModalForm;

  const onCancel = useCancelModal();

  const onSubmit = useCallback((values: ResolveModalFormType) => {
    const payload = {
      action: "RSL",
      rsl_type: values?.reason_code,
      rsl_desc: values?.comment,
    };
    onExecuteRequest(payload);
    onCancel();
  }, []);

  return (
    <StateHandler
      isFetching={isActionLoading || isLoadingResolveReasons}
      error={errorInResolveReasons}
      isModal
    >
      <ResolveModalStyled>
        <FormStyled isItemColumnLayout>
          <ResolveInputsWrapperStyled>
            <div
              className="description"
              // marginTop="26px"
              // width="500px"
              // desc="Resolve the current set of exceptions by selecting a reason code and adding a comment for the Root-Cause Analysis."
            >
              Resolve the current set of exceptions by selecting a reason code
              and adding a comment for the Root-Cause Analysis.
            </div>
            <FormItemLabel label="Reason Code" width="450px" required>
              <SelectField
                control={control}
                setValue={setValue}
                options={resolveReasonsSelectOptions}
                name="reason_code"
                isAllowClear={false}
                width="512px"
              />
            </FormItemLabel>

            <FormItemLabel label="Comment" width="450px">
              <TextAreaField
                control={control}
                name="comment"
                width="512px"
                placeholder="Description"
              />
            </FormItemLabel>
          </ResolveInputsWrapperStyled>

          <FormItemStyled className="form-actions-sec">
            <Button id="cancel" width="74px" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              id="primary"
              width="74px"
              marginLeft="8px"
              htmlType="submit"
              // disabled={!isDirty}
              onClick={handleSubmit(onSubmit)}
            >
              Resolve
            </Button>
          </FormItemStyled>
        </FormStyled>
      </ResolveModalStyled>
    </StateHandler>
  );
};

export default ResolveModal;
