import * as yup from "yup";
import {
  INVALID_NUMERIC_VALUE,
  NUMBERS_REGEX,
  REQUIRED_MESSAGE,
} from "../../constants/formconstants";

export const CriteriaSchema = yup.object().shape({
  column: yup.string().required(REQUIRED_MESSAGE),
  filter: yup.lazy((_p, p1) => {
    if (p1?.parent?.column) {
      return yup.string().required(REQUIRED_MESSAGE);
    }
    return yup.string();
  }),
  value: yup.lazy((p, p1) => {
    if (
      p1?.parent?.column &&
      !["BLK", "NBL", "INC", "NIN"].includes(p1?.parent?.filter) &&
      p1?.parent?.date_type !== "ABS"
    ) {
      if (p1?.parent?.data_type === "STR") {
        return yup.string().required(REQUIRED_MESSAGE);
      }
      if (
        p1?.parent?.data_type !== "STR" &&
        ["BTW", "NBT"].includes(p1?.parent?.filter)
      ) {
        return yup
          .string()
          .required(REQUIRED_MESSAGE)
          .matches(NUMBERS_REGEX, INVALID_NUMERIC_VALUE)
          .test(
            "check if less or not",
            "Min < Max",
            () => Number.parseInt(p1?.parent?.second_value) > Number.parseInt(p)
          );
      }
      return yup
        .string()
        .required(REQUIRED_MESSAGE)
        .matches(NUMBERS_REGEX, INVALID_NUMERIC_VALUE);
    }
    return yup.string();
  }),
  second_value: yup.lazy((p, p1) => {
    if (
      p1?.parent?.column &&
      ["BTW", "NBT"].includes(p1?.parent?.filter) &&
      p1?.parent?.date_type !== "ABS"
    ) {
      if (p1?.parent?.data_type === "STR") {
        return yup.string().required(REQUIRED_MESSAGE);
      }
      return yup
        .string()
        .required(REQUIRED_MESSAGE)
        .matches(NUMBERS_REGEX, INVALID_NUMERIC_VALUE)
        .test(
          "check if greater or less",
          "Max > Min",
          () => Number.parseInt(p) > Number.parseInt(p1?.parent?.value)
        );
    }
    return yup.string();
  }),
  date_type: yup.lazy((p, p1) => {
    if (p1?.parent?.column && ["REL", "ABS"].includes(p)) {
      return yup.string().required(REQUIRED_MESSAGE);
    }
    return yup.string();
  }),
  date_value: yup.lazy((p, p1) => {
    if (p1?.parent?.column && p1?.parent?.date_type === "ABS") {
      if (["BTW", "NBT"].includes(p1?.parent?.filter)) {
        const oldDate = new Date(p);
        const earlierDate = new Date(p1?.parent?.second_date_value);
        return yup
          .date()
          .required(REQUIRED_MESSAGE)
          .test("less than or not", "Oldest < Earliest", () => {
            return oldDate < earlierDate;
          });
      }
      return yup.date().required(REQUIRED_MESSAGE);
    }
    return yup.date().nullable();
  }),
  second_date_value: yup.lazy((p, p1) => {
    if (
      p1?.parent?.column &&
      p1?.parent?.date_type === "ABS" &&
      ["BTW", "NBT"].includes(p1?.parent?.filter)
    ) {
      return yup
        .date()
        .required(REQUIRED_MESSAGE)
        .test("greater than or not", "Earliest > Oldest", () => {
          const oldDate = new Date(p1?.parent?.date_value);
          const earlierDate = new Date(p);

          return oldDate < earlierDate;
        });
    }
    return yup.date().nullable();
  }),
  values_list: yup.lazy((_p, p1) => {
    if (p1?.parent?.column && ["INC", "NIN"].includes(p1?.parent?.filter)) {
      return yup.array().of(yup.string()).min(1, REQUIRED_MESSAGE);
    }
    return yup.mixed().nullable();
  }),
});

export const moduleSettingsFormSchema: any = yup.object().shape({
  is_enabled: yup.boolean(),
  fine_grain_control_enabled: yup.boolean(),
  default_flag: yup.lazy((_p, p1) => {
    if (p1?.parent?.fine_grain_control_enabled) {
      return yup.string().required(REQUIRED_MESSAGE);
    }
    return yup.string();
  }),
  term_criteria_enabled: yup.boolean(),
  specify_term_criteria: yup.lazy((_p, p1) => {
    if (p1?.parent?.term_criteria_enabled) {
      return yup.array().of(CriteriaSchema);
    }
    return yup.array();
  }),
  table_criteria_enabled: yup.boolean(),
  specify_table_criteria: yup.lazy((_p, p1) => {
    if (p1?.parent?.table_criteria_enabled) {
      return yup.array().of(CriteriaSchema);
    }
    return yup.array();
  }),
  column_criteria_enabled: yup.boolean(),
  specify_column_criteria: yup.lazy((_p, p1) => {
    if (p1?.parent?.column_criteria_enabled) {
      return yup.array().of(CriteriaSchema);
    }
    return yup.array();
  }),
});
