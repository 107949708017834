import styled from "styled-components";
import { ellipsis } from "../../../../styles/app.styles";

export const GlobalFiltersViewStyled = styled.div<{
  isSourcesActive?: boolean;
  isDomainsActive?: boolean;
}>`
  display: flex;
  justify-content: space-between;

  .content {
    width: 672px;

    .header {
      margin-bottom: 25.6px;

      .heading-sec {
        display: flex;
        align-items: center;
        column-gap: 9.6px;
        margin-bottom: 9.6px;

        .heading {
          font-size: 16px;
          color: ${({ theme }): string => theme.textColors.BLUE_116};
          font-weight: 600;
        }

        .selected-filters-tag {
          display: flex;
          align-items: center;
          font-size: 9.6px;
          font-weight: 600;
          padding: 4px 8px;
          color: ${({ theme }): string => theme.textColors.GREY_24};
          background: ${({ theme }): string => theme.bgColors.LIGHT_GREY_22};
          border-radius: 20px;
        }
      }

      .desc-sec {
        font-size: 13.6;
        color: ${({ theme }): string => theme.textColors.GREY_24};
      }
    }

    .filters-sec {
      display: flex;
      column-gap: 35px;

      .checkbox-container {
        display: flex;
        column-gap: 12px;
        align-items: center;
        margin-bottom: 13.6px;
        cursor: pointer;
        max-width: fit-content;

        img {
          max-width: 20px;
        }
      }

      .filter-sec-container {
        width: 50%;

        .filter-sec-header {
          .filter-sec-heading {
            font-size: 13.6px;
            font-weight: 600;
            margin-bottom: 8px;
          }
        }

        .filters-box {
          height: 402px;
          padding: 12.8px;
          border-radius: 8px;
          border: solid 1px
            ${({ theme }): string => theme.borderColors.LIGHT_GREY_51};

          .filters-wrapper {
            height: 320px;
            overflow: auto;

            .filter-name {
              width: 192px;
              ${ellipsis}
            }
          }

          .search-field {
            margin-bottom: 16px;

            svg {
              path {
                fill: ${({ theme }): string => theme.textColors.GREY_36};
              }
            }
          }
        }

        .sources-filters-box-wrapper {
          cursor: ${({ isSourcesActive }): string =>
            !isSourcesActive ? "not-allowed" : "default"};

          .sources-filters-box {
            pointer-events: ${({ isSourcesActive }): string =>
              !isSourcesActive ? "none" : ""};
            opacity: ${({ isSourcesActive }): string =>
              !isSourcesActive ? "0.7" : "1"};
          }
        }

        .domains-filters-box-wrapper {
          cursor: ${({ isDomainsActive }): string =>
            !isDomainsActive ? "not-allowed" : "default"};

          .domains-filters-box {
            pointer-events: ${({ isDomainsActive }): string =>
              !isDomainsActive ? "none" : ""};
            opacity: ${({ isDomainsActive }): string =>
              !isDomainsActive ? "0.7" : "1"};
          }
        }
      }
    }
  }

  .options-sec {
    display: flex;
    align-items: center;
    height: fit-content;
    column-gap: 8px;

    .edited-text {
      color: ${({ theme }): string => theme.textColors.GREY_24};
      font-style: italic;
      font-size: 13.6px;
    }
  }
`;
