import { HorizontalDividerStyled } from "../../../../components/dividers/dividers.styles";
import GlossaryPageHeader from "../../../../components/glossarypageheader";

import { GridActionButtons } from "../gridactionbuttons";
import { PageHeaderStyled } from "./pageheader.style";

import { PageHeaderProps } from "./pageheader.types";
import { DVSUM_TOOLTIP_CLASS_NAME } from "../../../../constants";

const PageHeader = (props: PageHeaderProps): JSX.Element => {
  const {
    isShowActionButtons,
    actionButtonsProps,
    glossaryHeaderProps,
    showDividerAfterGridActBtns,
    nodeType,
    tableId,
    sourceId,
    srcTypeId,
  } = props;

  const { isOnlineProfilingInProgress } = actionButtonsProps;

  return (
    <PageHeaderStyled isDisabled={isOnlineProfilingInProgress}>
      {isShowActionButtons ? (
        <>
          <GridActionButtons {...actionButtonsProps} nodeType={nodeType} />
          {showDividerAfterGridActBtns && (
            <HorizontalDividerStyled marginTop="15px" marginBottom="15px" />
          )}
        </>
      ) : (
        <GlossaryPageHeader
          {...glossaryHeaderProps}
          tableId={tableId}
          sourceId={sourceId}
          srcTypeId={srcTypeId}
        />
      )}
    </PageHeaderStyled>
  );
};

export default PageHeader;
