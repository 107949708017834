import { useCallback, useMemo } from "react";

import TableActionsSec from "../tableactionssec";
import { approveIcon, reviewIcon, unApproveIcon } from "../../svgs";

import { useOpenModal } from "../../customhooks";

import { ReviewRecEntityFormProps } from "../../forms/reviewrecomendedentitymodal/reviewrecomendedentitymodal.types";
import { MatchingStatusPopoverProps } from "./matchingstatus.types";

import { MatchingStatusPopoverStyled } from "./matchingstatus.styles";
import { MatchingStatusPreviewer } from "./matchingstatus.components";
import { ELEMENT_IDS } from "../../constants";

const {
  datdict_detpg_info_col_ent_rvw_btn: DATDICT_DETPG_INFO_COL_ENT_RVW_BTN,
  datdict_detpg_info_col_ent_opt_btn: DATDICT_DETPG_INFO_COL_ENT_OPT_BTN,
} = ELEMENT_IDS;

function MatchingStatusPopover(props: MatchingStatusPopoverProps): JSX.Element {
  const {
    matchingStatus,
    isSpaceBetween,
    additionnalInfo,
    onApprove,
    onUnApprove,
    onUnSelectRow,
    isEnableGetPopUpCont,
    recEntityModalProps,
    className,
    paddingRight = "",
    rowIndex = 0,
  } = props;
  const openModal = useOpenModal();

  const isApproved = matchingStatus === "APR";

  const onReview = useCallback(() => {
    onUnSelectRow && onUnSelectRow();

    openModal({
      modalId: "review_recomended_entity_modal",
      visible: true,
      modalTitle: "Review Recommended Term",
      modalProps: recEntityModalProps as ReviewRecEntityFormProps,
    });
  }, [recEntityModalProps]);

  const popoverMenu = useMemo(
    () => [
      isApproved
        ? { name: "Unapprove", icon: unApproveIcon(), onClick: onUnApprove }
        : { name: "Approve", icon: approveIcon(), onClick: onApprove },
      {
        name: "Review",
        icon: reviewIcon(),
        onClick: onReview,
        id: `${DATDICT_DETPG_INFO_COL_ENT_RVW_BTN}-${rowIndex}`,
      },
    ],
    [isApproved, onReview, onApprove, onUnApprove, rowIndex]
  );

  const menuSec = (
    <div className="menu-sec" role="button" onClick={onUnSelectRow}>
      <MatchingStatusPreviewer
        isApproved={isApproved}
        isContainingAdditionalInfo={!!additionnalInfo}
        isSpaceBetween={isSpaceBetween && !additionnalInfo}
      />
      {additionnalInfo && (
        <div className="additional-info">{additionnalInfo}</div>
      )}
      <span>
        <TableActionsSec
          data={popoverMenu}
          itemClassName="matching-status-popover-item"
          isEnableGetPopUpCont={isEnableGetPopUpCont}
          id={`${DATDICT_DETPG_INFO_COL_ENT_OPT_BTN}-${rowIndex}`}
        />
      </span>
    </div>
  );

  return (
    <MatchingStatusPopoverStyled
      isSpaceBetween={isSpaceBetween && !additionnalInfo}
      isContainingAdditionalInfo={!!additionnalInfo}
      className={className}
      paddingRight={paddingRight}
    >
      {matchingStatus !== "UNL" && matchingStatus !== "" && menuSec}
    </MatchingStatusPopoverStyled>
  );
}

export default MatchingStatusPopover;
