//   <--Library Imports Start-->
import { useMemo } from "react";
//   <--Library Imports End-->

//   <--Constants Start-->
import { API_CONFIG } from "../../constants/apiconfig";
//   <--Constants End-->

//   <--Parsers Start-->
import { getTableCountHistory } from "../../parsers/tablepage/tablepageparser";

import {
  getColumnParsedDoc,
  getTableColumsDetails,
  getDataSourceFieldsDetails,
} from "../../parsers/columnparsers/columnparsers";
//   <--Parsers End-->

//   <--Types Start-->
import { SectionRowProps } from "../../components/sectionrow/sectionrow.types";
import { NodeType } from "../../app.types";

import {
  ReturnTypeOfColumnDoc,
  ReturnTypeOfColumnsParser,
  ReturnTypeOfDataSourceFieldDetailsParser,
} from "../../parsers/columnparsers/columnparsers.types";

import {
  ProfillingInfoResponse,
  RecordCountChartType,
} from "../../parsers/tablepage/tablepageparser.types";
import { CustomReactQueryHookReturnType } from "../services.types";
//   <--Types End-->

//   <--Services Start-->
import { useGetAll } from "../baseservice";
//   <--Services End-->

//   <--UtilsStart-->
import { csvToJSON, getFiltersfromLocalStorage } from "../../utils";
import { CustomException } from "../../customexception";

import { useGetCurrentGateway, useGatewayDownPrompt } from "../../customhooks";
//   <--UtilsEnd-->

const {
  get_table_count_history: getTableColumnCountHistory,
  get_basic_profiling_info: getBasicProfilingInfo,
  get_table_columns: getTableColumns,
} = API_CONFIG;

export function useGetTableRecordCountHistory(
  tableId: string
): CustomReactQueryHookReturnType<RecordCountChartType> {
  return useGetAll<ReturnType<typeof getTableCountHistory>>({
    apiConfig: getTableColumnCountHistory,
    params: [tableId, ""],
    parser: getTableCountHistory,
  });
}

export function useGetColumnProfilingInfo(
  tableId: string,
  resource?: NodeType,
  shouldCheckGatewayStatus?: boolean,
  enabledSampleDataReq?: boolean
): CustomReactQueryHookReturnType<{
  isTableProfiled: boolean;
  profilingData: SectionRowProps[];
  sampleDataError: any;
  columnSampleData: any[];
  gatewayId: string;
  isSampleDataLoading: boolean;
  doesAvbRuleExists: boolean;
  doesRcmdRuleExists: boolean;
  refetchSampleData: () => void;
}> {
  const config = useGetAll<SectionRowProps[]>({
    apiConfig: getBasicProfilingInfo,
    params: [tableId, resource || ""],
  });

  const { refetch: refetchSampleData } = config;

  const profilingInfoResponse =
    (config?.data?.data as ProfillingInfoResponse)?.[0] || {};

  const sampleDataUrl = profilingInfoResponse?.TBL_SAMPLE_DATA || "";
  const gatewayId = `${profilingInfoResponse?.SAWS_ID}`;

  const { triggerPrompt } = useGatewayDownPrompt(gatewayId);

  const { isGatewayRunning, currentGateway } = useGetCurrentGateway(
    gatewayId || ""
  );

  const sawsCommunicationKey = currentGateway?.sawsCommunicationKey || "";

  const isOnPremSaws = !!currentGateway?.isOnpremiseGateway;

  //this is done for senario where we get a link for cloud saws instaed of file paths
  const checkDataUrl = sampleDataUrl?.includes("https");

  const checkDataApi = isOnPremSaws || checkDataUrl;

  const config1 = useGetAll<any>({
    apiConfig: checkDataApi
      ? { url: sampleDataUrl, method: "GET" }
      : API_CONFIG?.get_presigned_url,
    options: {
      enabled:
        !!sampleDataUrl &&
        (shouldCheckGatewayStatus ? !!isGatewayRunning : true) &&
        !!enabledSampleDataReq,
      refetchOnMount: false,
      onError: (err: any): void => {
        if (shouldCheckGatewayStatus) {
          triggerPrompt?.(err);
        }
      },
    },
    isReqWithoutHeader: isOnPremSaws ? false : checkDataUrl,
    params: checkDataApi ? [] : [sampleDataUrl],
    parser: checkDataApi ? csvToJSON : (data): void => data?.data,
    ...(isOnPremSaws && {
      isReqWithCustomHeader: isOnPremSaws,
      headers: {
        "X-Api-Key": btoa(sawsCommunicationKey),
      },
    }),
  });

  const { data = {} } = config1 || {};
  const sampleDataPreSignedUrl = data?.data?.url;

  const config2 = useGetAll<any>({
    apiConfig: { url: sampleDataPreSignedUrl, method: "GET" },
    options: {
      enabled: !!sampleDataPreSignedUrl,
    },
    isReqWithoutHeader: true,
    parser: csvToJSON,
  });

  return {
    ...config,
    isFetching: !!config?.isFetching,
    isLoading: !!config?.isLoading as any,
    parsedData: {
      isTableProfiled: config?.data?.data?.[0]?.IS_TBL_PROFILED || false,
      profilingData: config?.parsedData || [],
      columnSampleData: sampleDataPreSignedUrl
        ? config2?.data?.data || []
        : config1?.parsedData || [],
      sampleDataError:
        (config1?.error as any)?.status === 404 ||
        (config2?.error as any)?.status === 404
          ? new CustomException({
              message: `Please re-run the scan, It's possible that your sample data might have been removed due to data retention policies configured.`,
            })
          : config1?.error || config2?.error,
      gatewayId,
      isSampleDataLoading: !!config1?.isLoading || !!config2?.isLoading,
      doesAvbRuleExists: !!profilingInfoResponse?.TBL_RULE_CNT,
      doesRcmdRuleExists: !!profilingInfoResponse?.RCMD_RULE_CNT,
      refetchSampleData,
    },
  };
}

export function useGetTableColumnsDetails(
  tableId: string
): CustomReactQueryHookReturnType<ReturnTypeOfColumnsParser> {
  const config = useGetAll<ReturnTypeOfColumnsParser>({
    apiConfig: getTableColumns,
    params: [tableId, ""],
    // options: { enabled: !!tableId },
  });

  const memoizedParsedData = useMemo(() => {
    try {
      return (!config?.isLoading
        ? getTableColumsDetails(config?.data || {})
        : undefined) as any;
    } catch {
      return undefined;
    }
  }, [JSON.stringify(config?.data)]);

  return { ...config, parsedData: memoizedParsedData };
}

export function useGetDataSourceFieldDetails(
  tableId: string
): CustomReactQueryHookReturnType<ReturnTypeOfDataSourceFieldDetailsParser> {
  return useGetAll<ReturnTypeOfDataSourceFieldDetailsParser>({
    apiConfig: getTableColumns,
    params: [tableId, "DSR"],
    parser: getDataSourceFieldsDetails,
  });
}

export function useGetTableDataTabData(
  tableId: string,
  shouldCheckGatewayStatus?: boolean,
  enabledSampleDataReq?: boolean
): CustomReactQueryHookReturnType<
  {
    isTableProfiled: boolean;
    profilingData: SectionRowProps[];
    columnSampleData: any[];
    sampleDataError: CustomException;
    gatewayId: string;
    isSampleDataLoading: boolean;
    refetchSampleData: () => void;
  } & ReturnTypeOfColumnsParser
> {
  const config = useGetColumnProfilingInfo(
    tableId,
    undefined,
    shouldCheckGatewayStatus,
    enabledSampleDataReq
  );

  const config1 = useGetTableColumnsDetails(tableId);

  return {
    ...config,
    isLoading: (config?.isLoading || config1?.isLoading) as any,
    error: (config?.error || config1?.error) as any,
    isFetching: (config?.isFetching || config1?.isFetching) as any,
    parsedData: {
      isTableProfiled: config?.parsedData?.isTableProfiled,
      profilingData: config?.parsedData?.profilingData,
      columnSampleData: config?.parsedData?.columnSampleData,
      sampleDataError: config?.parsedData?.sampleDataError,
      gatewayId: config?.parsedData?.gatewayId,
      isSampleDataLoading: config?.parsedData?.isSampleDataLoading,
      refetchSampleData: config?.parsedData?.refetchSampleData,
      ...config1?.parsedData,
    },
  };
}

export function useGetDataSourceDataTabData(
  tableId: string
): CustomReactQueryHookReturnType<
  {
    profilingData: SectionRowProps[];
  } & ReturnTypeOfDataSourceFieldDetailsParser
> {
  const config = useGetColumnProfilingInfo(tableId, "DSR");
  const config1 = useGetDataSourceFieldDetails(tableId);

  return {
    ...config,
    isLoading: (config?.isLoading || config1?.isLoading) as any,
    error: (config?.error || config1?.error) as any,
    parsedData: {
      profilingData: config?.parsedData?.profilingData,
      ...config1?.parsedData,
    },
  };
}

export function useGetColumnDoc(
  nodeID: string,
  nodeType: NodeType,
  nodeStatus: string
): CustomReactQueryHookReturnType<ReturnTypeOfColumnDoc> {
  const parsedNodeFilter = getFiltersfromLocalStorage(nodeType);
  return useGetAll({
    apiConfig: API_CONFIG.get_node,
    params: [nodeID, nodeType, nodeStatus, "true", "", parsedNodeFilter],
    parser: getColumnParsedDoc,
  });
}
