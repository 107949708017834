import { useEffect } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 */
export function useOutsideClick(ref: any, outSideClickCallbak: () => void, dependencies: any[]): void {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event: any): void => {
      if (ref?.current && !ref?.current?.contains(event?.target)) {
        outSideClickCallbak();
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return (): void => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, ...dependencies]);
}
