import {
  MassEditableFieldValueTypeResponse,
  MassEditableParsedField,
} from "../../../../parsers/listingpagesparser/listingpagesparser.types";
import {
  categoryIcon,
  chevronRight,
  columnIcon,
  databaseIcon,
  databaseTableIcon,
  doomianIcon,
  termIcon,
} from "../../../../svgs";
import { MassUpdateOptionRendrerStyled } from "./massupdateformitem.styles";

export function massUpdateItemOptionRendrer(
  item: MassEditableParsedField["field_data"][number]
): JSX.Element {
  const parentIcon: {
    [key in MassEditableFieldValueTypeResponse]: JSX.Element;
  } = {
    CAT: doomianIcon(),
    COL: databaseTableIcon(),
    TBL: databaseIcon,
    TRM: categoryIcon(),
  };

  const rootIcon: {
    [key in MassEditableFieldValueTypeResponse]: JSX.Element;
  } = { CAT: <div />, COL: databaseIcon, TBL: <div />, TRM: doomianIcon() };

  const valueIcon: {
    [key in MassEditableFieldValueTypeResponse]: JSX.Element;
  } = {
    CAT: categoryIcon(),
    COL: columnIcon(),
    TBL: databaseTableIcon(),
    TRM: termIcon(),
  };

  return (
    <MassUpdateOptionRendrerStyled>
      {item?.root_name && (
        <>
          <div className="field-value-icon">{rootIcon?.[item?.value_type]}</div>
          <div className="field-option-value" title={item?.root_name}>
            {item?.root_name}
          </div>
          <div className="right-icon">{chevronRight}</div>
        </>
      )}
      {item?.parent_name && (
        <>
          <div className="field-value-icon">
            {parentIcon?.[item?.value_type]}
          </div>
          <div className="field-option-value" title={item?.parent_name}>
            {item?.parent_name}
          </div>
          <div className="right-icon">{chevronRight}</div>
        </>
      )}
      <>
        {item?.is_multi_level_option && (
          <div className="field-value-icon">
            {valueIcon?.[item?.value_type]}
          </div>
        )}
        <div className="field-option-value" title={item?.value_name}>
          {item?.value_name}
        </div>
      </>
    </MassUpdateOptionRendrerStyled>
  );
}
