import { awsAuth, awsConfig } from "../constants/awsuserpoolconfig";

export function getLogoutUrl(): string {
  const { domain, scope, responseType } = awsAuth;
  const {
    Auth: { userPoolWebClientId, redirectSignIn },
  } = awsConfig;

  return `https://${domain}/logout?client_id=${userPoolWebClientId}&redirect_uri=${redirectSignIn}&state=STATE&scope=${scope.join(
    "+"
  )}&response_type=${responseType}`;
}
