import { Input } from "antd";
import styled from "styled-components";

import { InputProps } from "../../inputs/inputs.types";

import { InputStyled } from "../../inputs/inputs.styles";

export const InputFieldStyled = styled(InputStyled)<{
  width?: string;
  height?: string;
  isMinMaxButtonHidden?: boolean;
}>`
  &.ant-input,
  &.ant-input-affix-wrapper {
    border-radius: 0px;
    padding: 8px 12px;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: ${({ isMinMaxButtonHidden }): string =>
      isMinMaxButtonHidden ? "none" : ""};
    appearance: ${({ isMinMaxButtonHidden }): string =>
      isMinMaxButtonHidden ? "none" : ""};
    margin: ${({ isMinMaxButtonHidden }): string =>
      isMinMaxButtonHidden ? "0" : ""};
  }
`;

export const PasswordInputFieldStyled = styled(Input.Password)<InputProps>`
  &.ant-input,
  &.ant-input-password {
    width: ${(props): string => props?.width || "100%"};
    height: ${(props): string => props?.height || "34px"};
    border: 1px solid
      ${(props): string => props.theme.borderColors.LIGHT_GREY_40};
    border-radius: 0px;
    padding: 8px 12px;
    color: ${(props): string => props.theme.textColors.BLACK_COLOR};
    font-size: 13px;
    :not(:disabled) {
      :hover {
        border-color: ${(props): string =>
          props.theme.borderColors.DARK_GREY_15};
        box-shadow: none;
      }

      :focus {
        border-color: ${(props): string => props.theme.borderColors.YELLOW_RD};
        color: ${(props): string => props.theme.textColors.BLACK_COLOR};
        box-shadow: none;
      }
    }

    input {
      font-size: ${(props): any => props?.fontSize || "13px"};

      ::placeholder {
        font-size: 13px;
      }
    }
  }

  &.ant-input-affix-wrapper-focused {
    border-color: ${(props): string =>
      props.theme.borderColors.YELLOW_RD} !important;
    color: ${(props): string => props.theme.textColors.BLACK_COLOR};
    box-shadow: none;
  }
`;

export const InputFieldContainerStyled = styled.div<{
  isMessagePositionAbsolute?: boolean;
}>`
  ${(props): any =>
    props.isMessagePositionAbsolute &&
    `
    position:relative;
    `}

  .error {
    text-align: right;
    color: red;

    ${(props): any =>
      props.isMessagePositionAbsolute &&
      `
    position:absolute;
    right:0;
    `}
  }
`;
