import styled from "styled-components";

export const DvsumHelpDrawerDetailModalStyled = styled.div`
  padding: 25px 0px;

  .scroll-sec {
    padding: 0px 25px;
    max-height: 482px;
    min-height: 482px;
    overflow: auto;
  }

  .item-detailed-view-sec {
    img {
      max-width: 668px;
    }

    iframe {
      width: 668px;
      min-height: 370px;
    }

    .desc {
      margin-top: 16px;
      color: ${({ theme }): string => theme?.textColors?.GREY_24};
      font-size: 13.6px;
    }
  }

  .desc-sec {
    margin: 12px 0px;
    color: ${({ theme }): string => theme?.textColors?.GREY_24};
  }
`;
