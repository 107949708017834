// <--- Styles Start --->
import { checkIsChatPage } from "../../utils/ischatpage";
import { UserNameInitialsStyled } from "./usernameinitials.styles";
// <--- Styles End --->

// <--- Types Start --->
import { UserNameInitialsProps } from "./usernameinitials.types";
// <--- Types End --->

const UserNameInitials = (props: UserNameInitialsProps): JSX.Element => {
  const { name = "", icon, userName = "" } = props;

  const isChatPage = checkIsChatPage();

  return (
    <UserNameInitialsStyled
      hasIcon={!!icon}
      isChatPage={isChatPage}
      data-testid="username-initials-wrapper"
      className="username-initials-wrapper"
    >
      {icon || (
        <span
          className="name-initials"
          data-testid="username-initials-text"
          title={userName}
        >
          {name}
        </span>
      )}
    </UserNameInitialsStyled>
  );
};

export default UserNameInitials;
