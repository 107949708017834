import parse from "html-react-parser";

import { FormItemStyled } from "../../../../../../../components/form";
import { infoIcon } from "../../../../../../../svgs";
import { ConnectionInfoTooltipContentStyled } from "../connectionsettingstab.styles";

import {
  GetFieldAction,
  GetFormField,
} from "../connectionsettingstabform/connectionsettingstabform.renderers";

import { ActionTypes } from "../connectionsettingstabform/connectionsettingstabform.types";
import TooltipWithIcon from "../../../../../../../components/tooltipwithicon/tooltipwithicon";
import { DynamicFormGroupItemProps } from "./connectionsettingstabformitem.types";

const ConnectionSettingsTabFormItem = (
  props: DynamicFormGroupItemProps
): JSX.Element => {
  const {
    form,
    fieldMetaData,
    setGroupVisibility,
    formFieldKeys,
    setFormFieldKeys,
    fieldLogs,
    setFieldLogs,
    alertsContent,
    setAlertsContent,
    setEnableDisableElements,
    enableDisableElements,
    setDialogError,
    disabledValues,
    setDisabledValues,
  } = props;
  const { control, setValue } = form;

  // useEffect(() => {
  //   setFormFieldKeys((st) => ({
  //     ...st,
  //     [`${fieldMetaData?.name}`]: `key-conn-form-${fieldMetaData?.name}`,
  //   }));
  // }, []);

  return fieldMetaData?.hideInEditMode ? (
    <></>
  ) : (
    <FormItemStyled
      className={fieldMetaData?.inputType === "alert" ? "form-alert-item" : ""}
      paddingLeft="0px"
      label={fieldMetaData?.showLabel && fieldMetaData?.label}
      required={fieldMetaData?.required}
      key={
        formFieldKeys?.[fieldMetaData?.name] ||
        `key-conn-form-${fieldMetaData?.name}`
      }
      data-field={
        (fieldMetaData?.inputType === "alert" && fieldMetaData?.name) ||
        undefined
      }
    >
      <GetFormField
        fieldMetaData={fieldMetaData}
        formControl={control}
        setValue={setValue}
        form={form}
        setFormFieldKeys={setFormFieldKeys}
        fieldLogs={fieldLogs}
        setFieldLogs={setFieldLogs}
        alertsContent={alertsContent}
        disabledValues={disabledValues}
        setDisabledValues={setDisabledValues}
        setAlertsContent={setAlertsContent}
      />
      {fieldMetaData?.tooltip && (
        <TooltipWithIcon
          className="tooltip-with-icon"
          marginLeft="0px"
          minWidth="330px"
          icon={infoIcon}
          title={
            (fieldMetaData?.tooltipMessage && (
              <ConnectionInfoTooltipContentStyled>
                {parse(fieldMetaData?.tooltipMessage)}
              </ConnectionInfoTooltipContentStyled>
            )) ||
            ""
          }
        />
      )}
      {(Object.keys(fieldMetaData?.actions || {}) as ActionTypes[])?.map(
        (actionType: ActionTypes) => (
          <GetFieldAction
            key={`dynamic-action-type-key-${fieldMetaData?.name}`}
            groupProps={{ ...props }}
            actionType={actionType}
            actionData={fieldMetaData?.actions?.[actionType]}
            form={form}
            setGroupVisibility={setGroupVisibility}
            setFormFieldKeys={setFormFieldKeys}
            setAlertsContent={setAlertsContent}
            setEnableDisableElements={setEnableDisableElements}
            enableDisableElements={enableDisableElements}
            setDialogError={setDialogError}
            setFieldLogs={setFieldLogs}
          />
        )
      )}
    </FormItemStyled>
  );
};

export default ConnectionSettingsTabFormItem;
