export const SCAN_TYPE_TYPES = [
  {
    label: "Catalog & Profile",
    value: "FUL",
    key: "scan-type-key-2",
  },
  {
    label: "Catalog",
    value: "CTG",
    key: "scan-type-key-1",
  },
];

export const SCAN_END_TYPES = [
  {
    label: "Never",
    value: "never",
    key: "ends-key-1",
  },
  {
    label: "After",
    value: "after",
    key: "ends-key-2",
  },
  {
    label: "On",
    value: "on",
    key: "ends-key-3",
  },
];

export const SCHEDULE_TYPE = [
  {
    label: "Schedule",
    value: "SCH",
    key: "ends-key-1",
    labelDesc:
      "Jobs can be schedule as recurring, ensuring they run automatically at specified intervals. This will helps streamline the workflow by automating repetitive tasks.",
  },
  {
    label: "Execute via API",
    value: "EXE",
    key: "ends-key-2",
    labelDesc:
      "Job can be initiated from an API and then can be monitored through another APIto check the job details.",
  },
];

export const SCAN_FREQUENCY_TYPES = [
  {
    type: "SCAN_FREQ",
    id: "DLY",
    name: "Daily",
    description: "",
    displayOrder: 2,
    adhocInfo: "",
  },
  {
    type: "SCAN_FREQ",
    id: "WLY",
    name: "Weekly",
    description: "",
    displayOrder: 3,
    adhocInfo: "",
  },
  {
    type: "SCAN_FREQ",
    id: "MLY",
    name: "Monthly",
    description: "",
    displayOrder: 4,
    adhocInfo: "",
  },
  {
    type: "SCAN_FREQ",
    id: "OTM",
    name: "One Time",
    description: "",
    displayOrder: 1,
    adhocInfo: "",
  },
];
