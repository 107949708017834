import styled from "styled-components";
import LinkButton from "../../../../../../components/linkbutton";

export const TrainbotFeedbackAddFeedbackFormStyled = styled.div<{
  isReasonSelected: boolean;
  isChatPage: boolean;
}>`
  margin-top: ${({ isChatPage }): string => (isChatPage ? "" : "15px")};
  margin-bottom: ${({ isChatPage }): string => (isChatPage ? "" : "25px")};

  .train-bot-add-feedback-title {
    font-size: 19px;
    font-family: OpenSansSemibold;
    color: ${({ theme }): string => theme?.textColors?.BLUE_116};
    margin-bottom: 12px;
  }
  border-bottom: ${({ theme, isReasonSelected, isChatPage }): string =>
    !isChatPage && isReasonSelected
      ? `1px solid ${theme?.borderColors?.GREY_56}`
      : ""};
`;

export const FeedbackButtonStyled = styled(LinkButton)<{
  isActive: boolean;
}>`
  height: 42px;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  padding: 10px 19px;
  border: solid 1px
    ${({ theme, isActive }): string =>
      isActive ? theme?.borderColors?.BLUE_116 : theme?.borderColors?.GREY_18};
  background-color: ${({ theme, isActive }): string =>
    isActive ? theme?.bgColors?.LIGHT_BLUE_25 : theme?.bgColors?.WHITE_COLOR};
  color: ${({ theme, isActive }): string =>
    isActive ? theme?.textColors?.BLUE_116 : theme?.textColors?.GREY_24};
  margin-right: 10px;
  margin-bottom: 16px;

  svg {
    path {
      fill: ${({ theme, isActive }): string =>
        isActive ? theme?.bgColors?.BLUE_116 : theme?.bgColors?.WHITE_COLOR};
      stroke: ${({ theme, isActive }): string =>
        isActive ? theme?.bgColors?.BLUE_116 : theme?.bgColors?.GREY_24};
    }
  }

  :hover,
  :focus {
    color: ${({ theme, isActive }): string =>
      isActive ? theme?.textColors?.BLUE_116 : theme?.textColors?.GREY_24};
    opacity: 0.9;
    border: solid 1px
      ${({ theme, isActive }): string =>
        isActive
          ? theme?.borderColors?.BLUE_116
          : theme?.borderColors?.GREY_18};
    background-color: ${({ theme, isActive }): string =>
      isActive ? theme?.bgColors?.LIGHT_BLUE_25 : theme?.bgColors?.WHITE_COLOR};
  }

  :disabled {
    &,
    &:hover {
      border: solid 1px
        ${({ theme, isActive }): string =>
          isActive
            ? theme?.borderColors?.BLUE_116
            : theme?.borderColors?.GREY_18};
      background-color: ${({ theme, isActive }): string =>
        isActive
          ? theme?.bgColors?.LIGHT_BLUE_25
          : theme?.bgColors?.WHITE_COLOR};
      color: ${({ theme, isActive }): string =>
        isActive ? theme?.textColors?.BLUE_116 : theme?.textColors?.GREY_24};
      opacity: 0.5;
    }
  }
`;
