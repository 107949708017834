import { checkSourceType, createUUID } from "../../utils";
import { getQueryCreatedByInfo } from "./analisisdetailpage.utils";

import {
  AnalaysisPageState,
  AnalysisDetailPageParamsState,
  AnalysisPageQueryBox,
  QueryBlockGridPagination,
} from "./analysisdetailpage.types";

export const DEFAULT_QUERY_BLOCK_GRID_PAGINATION: QueryBlockGridPagination = {
  current_page: 1,
  page_size: 100,
};

const queryCreatedByInfo = getQueryCreatedByInfo();

export const DEFAULT_QUERY: AnalysisPageQueryBox = {
  qry_sec_id: null,
  type: "CCQ",
  id: createUUID(),
  selected_topic_id: 0,
  qry_retry_count: 0,
  is_focused: true,
  is_expanded: true,
  is_loading: false,
  is_loading_verbose_logs: false,
  loader_type: "newly_added_question",
  src_id: "",
  src_name: "",
  table_id: "",
  table_name: "",
  is_selected: false,
  qry_prf_results_s3_url: "",
  qry_tmp_results_s3_url: "",
  qry_full_results_s3_url: "",
  verbose_s3_url: "",
  ...queryCreatedByInfo,
  qry_created_by_id: 0,
  qry_exec_by: "",
  qry_exec_by_id: 0,
  qry_exec_on: "",
  qry_updated_by: "",
  qry_updated_by_id: 0,
  qry_updated_on: "",
  use_du_tool: false,
  use_general_knowledge_tool: false,
  use_sql_tool: false,
  header: {
    title: "Untitled Query",
    is_profiled: false,
    is_chart_settings_visible: false,
    is_maximize_query_block: false,
    is_editing_query_mode: false,
    edited_query_value: "Untitled Query",
    is_sql_query_visible: false,
    is_show_contexts_list: false,
    is_use_context: false,
    query_context: [],
    result_type: "chart",
    is_verbose_visible: false,
    is_explanation_visible: false,
    is_question_saved: false,
  },
  query: {
    sql_query: "",
    filters: {},
    is_filter_expanded: false,
    is_filter_edit_mode_expanded: false,
  },
  filters: {
    applied_filters: {},
    transiant_filters: {},
    sortModel: [],
  },
  results: {
    data: [],
    columns: [],
    columns_profilig: [],
    last_refreshed: "",
    is_server_side_filteration: false,
    record_count: 0,
    grid_record_count: 0,
    pagination: DEFAULT_QUERY_BLOCK_GRID_PAGINATION,
    tbl_record_count: 0,
    is_sample_data: false,
    sec_title: "",
    chart_config: {
      is_default_settings: true,
    },
  },
  settings: {
    is_data_formatted: false,
    visible: false,
    tab: "general",
    prefrences: {},
  },
  qry_status: "",
  user_feedback: {
    response: "",
    reason: "",
    comment: "",
    train_me: "",
    train_sql: "",
  },
  is_newly_added_question: true,
};

export const DEFAULT_ANALYSIS_TITLE = "Untitled Chatbot";

const promptAIRole = `Tier-2 Support Agent for a Telecom Network Operations company`;

const promptGuidelines = `<GUIDELINES>
8. Co-relation of Modem with issues and outage
	- If Modem status is OFFLINE and OBSERVATION includes OUTAGE_OFFLINE, then category is OUTAGE.
	- If Modem status is UNKNOWN and OBSERVATION includes OUTAGE_UNKNOWN, then category is OUTAGE
	- If Modem status is ONLINE and Modem has MAJOR/CRITICAL issues and OBSERVATION includes OUTAGE_MAJORCRITICAL_THRESHOLDS, then category is OUTAGE.
	- If Modem status OFFLINE or UNKNOWN or has MAJOR/CRITICAL issues and there is NO OUTAGE, then category is ON_PREMISES
9. If modem is ONLINE and Modem has no issues then we don't know what the problem is so the category is UNKNOWN.
10. If the issue is ON_PREMISES and the modem is offline, then recommend several cable modem troubleshooting steps. And suggest that if these do not resolve it, then dispatching a technician may be needed.
11. If the issue is UNKNOWN, try to explain the inability to determine the source of the problem.
<END OF GUIDELINES>`;

const promptDatasetExplanation = `<DATASET EXPLANATION>
The section <DATASET> contains data for this customer and the structure of that data. Here is information about the datasets:
 "cerillion_and_sigma_dpm": Contains CRM and device provisioning information for the customer's modem, including bill status. Data is formatted as attribute=value,attribute=value.
 "telemetry_cm_level": Contains cable modem information at the customer's location. Data is formatted as attribute=value,attribute=value, and includes multiple records.
 "nxt_all_modems_threshold_crossing": Contains data about all modems on the fiber node serving this customer. It includes counts and percentages of modems at the fiber node level.
 "nxt_single_modem_threshold_crossing": Contains severity levels observed in different channels like downstream, upstream, OFDM, and OFDMA.
<END OF DATASET EXPLANATION>

<FIELDS EXPLANATION>
"last registered time": Use the parameter "lastRegTime". This differs from cm up time, cmts up time, and flap last time.
<END OF FIELDS EXPLANATION>

<DATA FORMAT GUIDELINES>
"lastRegTime" is in seconds. Convert it into days and hours, also list total seconds for reference.
Verify if IP addresses follow IPv4 format.
<END OF DATA FORMAT GUIDELINES>`;

const promptTroubleshootingGuide = `<LOGIC> * VERY IMPORTANT *
This describes the logic to apply to the data for making observations and recommendations.
If a condition is met and a <DECISION> is made, do not consider subsequent logic steps.

1. From cerillion_and_sigma_dpm, use Cerillion.status and SigmaDPM.status to determine if error in getting data
    - If either Cerillion.status is "error" or SigmaDPM.status is "error": <OBSERVATION>: Error pulling data from CRM and DPM. <DECISION>: ERROR_GETTING_DATA. Please contact System Administrator. Continue with manual troubleshooting. 
2. From cerillion_and_sigma_dpm, determine the package.
    - If package includes words like BlockedAccess or Suspend: <OBSERVATION>: Modem is blocked. <DECISION>: PROVISIONING. Review customer's billing and other details to determine why the modem is blocked. STOP.
    - If package includes words like VOID or INVALID: <OBSERVATION>: Package installed on modem is invalid. <DECISION>: PROVISIONING. Customer needs to wait for the correct package to be installed. STOP.
3. From cerillion_and_sigma_dpm, match sigma DPM's CM MAC address with Cerillion MAC address.
    - If different: <OBSERVATION>: New modem is not provisioned yet. <DECISION>: PROVISIONING. Route ticket to Provisioning team. STOP.
4. From telemetry_cm_level:
    - Check last registered time of modem.
        - If empty: <OBSERVATION>: Modem has not registered to the CMTS. <DECISION>: PROVISIONING. Route ticket to Provisioning team. STOP.
        - If recent: <OBSERVATION>: Modem is still booting. <DECISION>: UNKNOWN. Test again in a few minutes. STOP.
5. From telemetry_cm_level, check cmCollectStatus.
    - If DEVICE_UNAVAILABLE: <OBSERVATION>: Modem is offline or not connected to the CMTS. <DECISION>: Look at Step 9 below to determine if OUTAGE
    - If not SUCCESS or DEVICE_UNAVAILABLE: <OBSERVATION>: Data is not being collected by CMTS. <DECISION>: Look at Step 9 below to determine if OUTAGE
6. From telemetry_cm_level, check for any available error codes
    - If DEVICE_NOTFOUND: <OBSERVATION>: Modem status is definitely UNKNOWN and may be because Modem has been moved from the Cable Mac. <DECISION>: Look at Step 9 below to determine if OUTAGE
7. From nxt_single_modem_threshold_crossing, check severity_scores of all 4 channels: 'downstream_data', 'upstream_data', 'ofdm_data', 'ofdma_data'.
    - If any channel has severity as 'MAJOR' or 'CRITICAL': <OBSERVATION>: <channel> has <major/critical> severity. <DECISION>: Look at Step 9 below to determine if OUTAGE
8. From telemetry_cm_level, check inHomeScore severity level.
    - If CRITICAL or MAJOR: <OBSERVATION>: Modem alarming. <DECISION> Look at Step 9 below to determine if OUTAGE
9. <OUTAGE DETECTION> From nxt_all_modems_threshold_crossing, check total_modem_with_issues_pct, critical_major_pct, offline_modem_pct, and unknown_modem_pct. If any threshold is exceeded, then we have a possible OUTAGE.
    - If critical_major_pct > 60%: <OBSERVATION>: OUTAGE_MAJORCRITICAL_THRESHOLDS. <DECISION>: OUTAGE. Do NOT send truck. Send a team to fix Fiber Node problem. STOP.
    - If offline_modem_pct > 30%: <OBSERVATION>: OUTAGE_OFFLINE. <DECISION>: OUTAGE. Do NOT send truck. Send a team to fix Fiber Node problem. STOP.
    - If unknown_modem_pct > 50%: <OBSERVATION>: OUTAGE_UNKNOWN. <DECISION>: OUTAGE. Do NOT send truck. Send a team to fix Fiber Node problem. STOP.
    - If total_modem_flapped_simultaneously_count > 10: <OBSERVATION>: OUTAGE_OFFLINE. <DECISION>: OUTAGE. Do NOT send truck. Send a team to fix Fiber Node problem. STOP.
    - If total_modem_with_issues_pct > 60%: <OBSERVATION>: OUTAGE_MULTIPLE_REASONS. <DECISION>: OUTAGE. Do NOT send truck. Send a team to fix Fiber Node problem. STOP.
10. From telemetry_cm_level, check cmDocsisCapability version.
    - If version < 3.0: <RECOMMENDATION>: Legacy modem detected. Recommend upgrade. STOP.
11. If no decision is made: <OBSERVATION>: Problem not identified. <DECISION>: UNKNOWN. Review data to determine possible sources of the issue. STOP.
<END OF LOGIC>`;

const promptSummarizationOutputFormat = ``;

export const getDefaultAnalysisPageState = (
  paramsState: AnalysisDetailPageParamsState
): AnalaysisPageState => {
  const { sourceId = "", sourceName = "", sourceTypeId, isCloned = false } =
    paramsState || {};

  const { isRestAPISource } = checkSourceType(sourceTypeId);

  const defaultIntentLogic = `data_analysis_chain:
- Use when question is to analyze or re-analyze an entity of a dataset.  
- Whenever there are questions to check for a ID or any entity, use this flag.
- Even if a question contains only an entity like ID or an entity name use this flag.

qa_chain:
- Use when question does not contain anything related to a particular entity, but rather a generic domain specific question.
- For generic questions related to a domain or follow-up questions that reference previous conversations (chat history).
- In any other situation, if you do not know which flag to use, use this flag by default.`;

  return {
    header: {
      title: isCloned ? `${sourceName} (Clone)` : `${sourceName} Bot`,
      desc: "",
      view: {
        is_collapse: false,
        is_expanded: false,
      },
      actions: {
        is_expanded: false,
      },
    },
    queries: [],
    pinnedTables: [], //what will pe pinned tables list?
    analysis_info: {
      is_node_cloned: false,
      parent_analysis_id: 0,
      analysis_id: 0,
      created_by: { name: "", email: "" },
      share_type: "NON",
      shared_with: [],
      tables_list: [],
      datasource_used: {
        name: "",
        type: "ADL",
      },
      selected_contexts: [],
      selected_topic_id: 0,
    },
    table_id: "",
    src_id: sourceId,
    is_scope_enabled: false,
    is_verbose: false,
    train_bot_filter: "all",
    asked_by_filter: ["all"],
    tableFilter: [],
    columnFilter: [],
    configuration: {
      prompt_inputs: {
        ai_assistant_type: "",
        data_description: "",
        logic: "",
        guidelines: "",
        response_format: "",
        intent_logic: defaultIntentLogic,
      },
      workflow_json_s3_pre_signed_get_url: "",
    },
    aiChatModel: isRestAPISource ? "AWS" : "AWS",
    aiChatModelName: "",
    gatewayId: "",
  };
};

export const MAX_LIMIT_OF_CLIENT_SIDE_FILT = 10000;

export const DEFAULT_IS_FROM_PAGE = "home";

export const GENERIC_QUERY_BLOCK_ERROR_MESSAGE =
  "Sorry, ran into difficulty finding answer to this question.";

export const GENERIC_CCQ_QUERY_BLOCK_ERROR_MESSAGE =
  "Sorry, ran into difficulty finding answer to this question. Try asking the question a different way.";

export const NO_RESULTS_QUERY_BLOCK_ERROR_MESSAGE =
  "I didn't find any data. Try asking the question a different way.";

export const NO_RESULTS_ON_FILTERING_QUERY_BLOCK_MESSAGE =
  "No records were found that match your selected filter. Please adjust your filters and try again.";

export const CCQ_SQL_COMPILATION_ERROR =
  "Sorry, I could not generate the right SQL. Please give me some feedback so I can improve.";

export const CCQ_SQL_COMPILATION_ERROR_FOR_CADDI =
  "Sorry, I could not generate the right SQL. Try asking the question in a different way.";

export const CCQ_CHAT_GPT_FAILURE_ERROR =
  "Sorry, Error in Conversational AI API. Try asking the question in a different way";

export const PROMPT_SUMMARY_ERROR = `Sorry, not able to summarize the topic`;

export const GUIDE_ME_QUESTIONS_ERROR = `Sorry, not able to fetch guided set of Questions`;

export const CADDI_DATA_NOT_FOUND_MESSAGE = `Please re-run this question, It's possible that your query sample data might have been removed due to data retention policies configured.`;

export const LOADING_TEXT = "Data is being fetched";

export const ANALYSIS_BOT_TABS = "analysis-bot-tabs";

export const START_EXECUTION_ERROR = "Something went wrong. Please try again";

export const REST_SOURCE_ERROR_MESSAGE =
  "Something went wrong. I didn't find any data.";

export const GATEWAY_DOWN_ERROR = "Sorry, could not connect to Gateway.";
