import {
  Key,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { DownOutlined } from "@ant-design/icons";
import { RadioChangeEvent, Tree } from "antd";

import {
  chevronLeft,
  chevronRight,
  lightCrossIcon,
  searchIcon,
} from "../../svgs";

import { HorizontalDividerStyled } from "../dividers/dividers.styles";
import { RadioGroupFieldStyled } from "../formfields/radiofield/radiofield.styles";

import { Input } from "../inputs";
import LinkButton from "../linkbutton";

import {
  TransferContainerStyled,
  TreeTransferStyled,
} from "./treetransfer.styles";

import {
  DataNodeWithCustomProps,
  OnExpandType,
  OnSearchTextChangeType,
  OnSelectType,
  TransferContainerProps,
  TreeTransferProps,
} from "./treetransfer.types";

import {
  constructTreeAfterTransfer,
  constructTreeWithKeys,
  filterTreeWithSearchText,
  getAllTreeKeys,
  getLevelofAccess,
  getSelectableKeys,
  getUnselectableKeys,
} from "./treetransfer.utils";
import { useDebounce } from "../../customhooks";
import { NodeType } from "../../app.types";

const TransferContainer = (props: TransferContainerProps): JSX.Element => {
  const {
    type,
    title = "",
    enableLevelOfAccess = false,
    levelOfAccessOptions = [],
    selectedLevelOfAccess = "",
    onLevelOfAccessChange,
    data,
    selectedKeys,
    expandedKeys,
    onSelect,
    onExpand,
    searchText,
    onSearchTextChange,
    height = 274,
  } = props;

  const isShowLevelOfAccessOptions = type === "source" && enableLevelOfAccess;

  const titleRenderer = (node: DataNodeWithCustomProps): ReactNode => {
    const isShowLevelOfAccessTagWithItem =
      type === "destination" &&
      enableLevelOfAccess &&
      (["TBL", "CAT", "SRC", "DMN", "DSR"] as NodeType[])?.includes(
        node?.nodeType || "TBL"
      );

    // GET VERIFY FROM BADER
    const showChildCount =
      !(["TBL", "CAT", "DSR", "UGP", "USR"] as NodeType[])?.includes(
        node?.nodeType || "TBL"
      ) ||
      !node?.nodeType ||
      node?.children?.length !== 0;

    const levelOfAccessValue = getLevelofAccess(
      node?.levelOfAccess || "",
      levelOfAccessOptions
    );

    const isAddClass =
      (node?.nodeType === "SRC" || node?.nodeType === "DMN") &&
      !node?.children?.length;

    return (
      <span
        className={isAddClass ? "parent-title-container" : ""}
        title={`${node?.title} ${
          showChildCount ? `(${node?.children?.length || 0})` : ""
        } ${isShowLevelOfAccessTagWithItem ? levelOfAccessValue : ""}`}
      >
        <span className="parent-title">{node?.title}</span>
        {showChildCount && (
          <span className="children-count">{` (${
            node?.children?.length
              ? node?.children?.length || 0
              : (node as DataNodeWithCustomProps)?.childrenCount || 0
          })`}</span>
        )}
        {isShowLevelOfAccessTagWithItem && (
          <span className="level-of-access"> {levelOfAccessValue}</span>
        )}
      </span>
    );
  };

  return (
    <TransferContainerStyled
      className={`${type}-transfer-container`}
      enableLevelOfAccess={isShowLevelOfAccessOptions}
    >
      <div className="box-title">{title}</div>
      <div className="transfer-box-container">
        <div className="search-container">
          <Input
            placeholder="Search"
            className="search-input"
            prefix={searchIcon()}
            suffix={
              <LinkButton
                onClick={(): void => onSearchTextChange("", type)}
                className="search-cross-icon"
              >
                {searchText && lightCrossIcon("9.6", "9.6")}
              </LinkButton>
            }
            onChange={(e): void => onSearchTextChange(e?.target?.value, type)}
            value={searchText}
          />
        </div>

        {isShowLevelOfAccessOptions && (
          <div className="level-of-access-container">
            <div className="label">Level of access</div>
            <RadioGroupFieldStyled
              options={levelOfAccessOptions}
              direction="row"
              width=""
              height=""
              value={selectedLevelOfAccess}
              onChange={onLevelOfAccessChange}
            />

            <HorizontalDividerStyled marginBottom="8.8px" marginTop="8.8px" />
          </div>
        )}

        <Tree
          titleRender={titleRenderer}
          showLine
          showIcon
          blockNode
          multiple
          switcherIcon={<DownOutlined />}
          treeData={data}
          selectedKeys={selectedKeys}
          expandedKeys={expandedKeys}
          onExpand={(expandedKeys, info): void =>
            onExpand?.(expandedKeys, info, type)
          }
          onSelect={(selectedKeys, info): void =>
            onSelect?.(selectedKeys, info, type)
          }
          height={height}
        />
      </div>
    </TransferContainerStyled>
  );
};

const TreeTransfer = (props: TreeTransferProps): JSX.Element => {
  const {
    sourceTitle = "Source",
    destinationTitle = "Destination",
    enableLevelOfAccess = true,
    levelOfAccessOptions = [],
    treeData,
    sourceData,
    destinationData,
    updateTreeData,
    updateSourceData,
    updateDestinationData,
    leftSideHeight = 274,
    rightSideHeight = 274,
  } = props;

  const [selectedSourceKeys, setSelectedSourceKeys] = useState<Key[]>([]);
  const [selectedDestinationKeys, setSelectedDestinationKeys] = useState<Key[]>(
    []
  );

  const [expandedSourceKeys, setExpandedSourceKeys] = useState<Key[]>([]);
  const [expandedDestinationKeys, setExpandedDestinationKeys] = useState<Key[]>(
    []
  );

  const [sourceSearchText, setSourceSearchText] = useState<string>("");
  const [destinationSearchText, setDestinationSearchText] = useState<string>(
    ""
  );

  const sourceSearchDebounce = useDebounce(sourceSearchText, 500);
  const destSearchDebounce = useDebounce(destinationSearchText, 500);

  const [selectedLevelOfAccess, setSelectedLevelOfAccess] = useState<string>(
    levelOfAccessOptions?.[0]?.value || ""
  );

  const { data: updatedSourceTreeData } = useMemo(() => {
    return filterTreeWithSearchText(sourceSearchDebounce, sourceData || [], []);
  }, [sourceSearchDebounce, sourceData]);

  const { data: updatedDestinationTreeData } = useMemo(() => {
    return filterTreeWithSearchText(
      destSearchDebounce,
      destinationData || [],
      []
    );
  }, [destSearchDebounce, destinationData]);

  const updatedSourceTreeDataWithChildrenCount = useMemo(
    () => updatedSourceTreeData,
    [updatedSourceTreeData]
  );

  const updatedDestinationTreeDataWithChildrenCount = useMemo(
    () => updatedDestinationTreeData,
    [updatedDestinationTreeData]
  );

  const onSelect: OnSelectType = useCallback(
    (selectedKeys, info, type) => {
      const isSourceType = type === "source";

      if (info?.selected) {
        const selectableKeys = getSelectableKeys(
          info,
          type === "source" ? sourceData! : destinationData!,
          selectedKeys
        );

        if (isSourceType) {
          setSelectedSourceKeys(selectableKeys);
        } else {
          setSelectedDestinationKeys(selectableKeys);
        }
      } else {
        isSourceType
          ? setSelectedSourceKeys((keys) =>
              getUnselectableKeys(info, sourceData!, keys)
            )
          : setSelectedDestinationKeys((keys) =>
              getUnselectableKeys(info, destinationData!, keys)
            );
      }
    },
    [sourceData, destinationData]
  );

  const onExpand: OnExpandType = useCallback((expandedKeys, _info, type) => {
    const isSourceType = type === "source";

    isSourceType
      ? setExpandedSourceKeys(expandedKeys)
      : setExpandedDestinationKeys(expandedKeys);
  }, []);

  const onRightClick = useCallback(() => {
    if (sourceData && treeData && selectedSourceKeys?.length) {
      const destinationTreeKeys: Key[] = destinationData
        ? getAllTreeKeys(destinationData)
        : [];

      const destinationNewDataKeysList: Key[] = [
        ...new Set([...selectedSourceKeys, ...destinationTreeKeys]),
      ];

      const treeDataForDestination = constructTreeWithKeys(
        destinationNewDataKeysList,
        treeData,
        selectedSourceKeys,
        enableLevelOfAccess ? selectedLevelOfAccess : ""
      );

      updateDestinationData?.(treeDataForDestination);

      const updatedSourceData = constructTreeAfterTransfer(
        destinationNewDataKeysList,
        sourceData
      );

      updateSourceData?.(updatedSourceData);
      setSelectedSourceKeys([]);

      if (destinationNewDataKeysList?.length) {
        const allTreeKeys: Key[] = getAllTreeKeys(treeData);

        const updatedTreeDataWithLevelOfExcess = constructTreeWithKeys(
          allTreeKeys,
          treeData,
          selectedSourceKeys,
          enableLevelOfAccess ? selectedLevelOfAccess : ""
        );

        updateTreeData?.(updatedTreeDataWithLevelOfExcess);
      }

      if (expandedSourceKeys) {
        const expandedSourceSelectedKeys = selectedSourceKeys?.filter(
          (selectedKey) => expandedSourceKeys?.includes(selectedKey)
        );

        const updatedExpandedDestinationKeys = [
          ...new Set([
            ...expandedSourceSelectedKeys,
            ...expandedDestinationKeys,
          ]),
        ];

        setExpandedDestinationKeys(updatedExpandedDestinationKeys);
      }
    }
  }, [
    sourceData,
    treeData,
    destinationData,
    selectedSourceKeys,
    selectedLevelOfAccess,
  ]);

  const onLeftClick = useCallback(() => {
    if (destinationData && treeData && selectedDestinationKeys?.length) {
      const sourceTreeKeys: Key[] = sourceData
        ? getAllTreeKeys(sourceData)
        : [];

      const sourceNewDataKeysList: Key[] = [
        ...new Set([...selectedDestinationKeys, ...sourceTreeKeys]),
      ];

      const treeDataForSource = constructTreeWithKeys(
        sourceNewDataKeysList,
        treeData
      );

      updateSourceData?.(treeDataForSource);

      const updatedDestinationData = constructTreeAfterTransfer(
        sourceNewDataKeysList,
        destinationData
      );

      updateDestinationData?.(updatedDestinationData);
      setSelectedDestinationKeys([]);

      if (expandedDestinationKeys) {
        const expandedDestinationSelectedKeys = selectedDestinationKeys?.filter(
          (selectedKey) => expandedDestinationKeys?.includes(selectedKey)
        );

        const updatedExpandedDestinationKeys = [
          ...new Set([
            ...expandedDestinationSelectedKeys,
            ...expandedSourceKeys,
          ]),
        ];

        setExpandedSourceKeys(updatedExpandedDestinationKeys);
      }
    }
  }, [sourceData, treeData, destinationData, selectedDestinationKeys]);

  const onSearchTextChange: OnSearchTextChangeType = useCallback(
    (text, type) =>
      type === "source"
        ? setSourceSearchText(text)
        : setDestinationSearchText(text),
    []
  );

  const onLevelOfAccessChange = useCallback((e: RadioChangeEvent) => {
    setSelectedLevelOfAccess(e?.target?.value);
  }, []);

  useEffect(() => {
    const { keysToExpand } = filterTreeWithSearchText(
      sourceSearchDebounce,
      sourceData || [],
      expandedSourceKeys
    );
    setExpandedSourceKeys(keysToExpand);
  }, [sourceSearchDebounce]);

  useEffect(() => {
    const { keysToExpand } = filterTreeWithSearchText(
      destSearchDebounce,
      destinationData || [],
      expandedDestinationKeys
    );

    setExpandedDestinationKeys(keysToExpand);
  }, [destSearchDebounce]);

  return (
    <TreeTransferStyled>
      <TransferContainer
        type="source"
        title={sourceTitle}
        enableLevelOfAccess={enableLevelOfAccess}
        levelOfAccessOptions={levelOfAccessOptions}
        selectedLevelOfAccess={selectedLevelOfAccess}
        onLevelOfAccessChange={onLevelOfAccessChange}
        data={updatedSourceTreeDataWithChildrenCount}
        selectedKeys={selectedSourceKeys}
        onSelect={onSelect}
        expandedKeys={expandedSourceKeys}
        onExpand={onExpand}
        searchText={sourceSearchText}
        onSearchTextChange={onSearchTextChange}
        height={leftSideHeight}
      />

      <div className="transfer-btns-container">
        <LinkButton
          className="right-icon"
          onClick={onRightClick}
          disabled={!selectedSourceKeys?.length}
          tooltipProps={{
            title: !updatedSourceTreeDataWithChildrenCount?.length
              ? "No data available to move."
              : !selectedSourceKeys?.length
              ? "Select some data to move."
              : "",
            placement: "right",
          }}
        >
          {chevronRight}
        </LinkButton>
        <LinkButton
          className="left-icon"
          onClick={onLeftClick}
          disabled={!selectedDestinationKeys?.length}
          tooltipProps={{
            title: !updatedDestinationTreeDataWithChildrenCount?.length
              ? "No data available to move."
              : !selectedDestinationKeys?.length
              ? "Select some data to move."
              : "",
            placement: "right",
          }}
        >
          {chevronLeft}
        </LinkButton>
      </div>

      <TransferContainer
        type="destination"
        title={destinationTitle}
        enableLevelOfAccess={enableLevelOfAccess}
        levelOfAccessOptions={levelOfAccessOptions}
        data={updatedDestinationTreeDataWithChildrenCount}
        selectedKeys={selectedDestinationKeys}
        onSelect={onSelect}
        expandedKeys={expandedDestinationKeys}
        onExpand={onExpand}
        searchText={destinationSearchText}
        onSearchTextChange={onSearchTextChange}
        height={rightSideHeight}
      />
    </TreeTransferStyled>
  );
};

export default TreeTransfer;
