import { reduce, mergeAll, is, assoc, keys } from "ramda";

export const flattenObjects = (obj: Object, prefix = ""): Object => {
  try {
    return reduce(
      (acc, key) => {
        const value = obj[key];
        if (is(Object, value)) {
          return mergeAll([acc, flattenObjects(value, `${prefix}${key}.`)]);
        }
        return assoc(`${prefix}${key}`, value, acc);
      },
      {},
      keys(obj)
    );
  } catch {
    return {};
  }
};
