import { API_CONFIG } from "../../constants/apiconfig";

import {
  dataSourceScanResultParser,
  getParsedDataSources,
  getParsedDataSourceTypes,
  getParsedPaginatedScanHistory,
  getParsedSingleDataSource,
} from "../../parsers/sources/sourcesparser";

import { useGetAll } from "../baseservice";
import { CustomReactQueryHookReturnType } from "../services.types";

import { useGetRefByType } from "../refservice";

import {
  ExcelSourceScanJobStatusParsedType,
  GetScanResultQueryOptionsType,
  ParsedExcelSourceFilesList,
  ScanHistoryPaginatedParserReturnType,
  ScanReultParserdType,
  SourcesParserReturnType,
} from "../../parsers/sources/sourcesparser.types";

const {
  get_all_data_sources: allDataSources,
  get_data_source: singleDataSource,
  get_source_scan_history: sourceScanHistory,
  get_scan_result: getScanResult,
  get_excel_source_folder_files: getExcelSourceFolderFiles,
  get_excel_source_job_status: getExcelSourceJobStatus,
} = API_CONFIG;

export function useGetAllDataSources(
  enabled?: boolean
): CustomReactQueryHookReturnType<SourcesParserReturnType[]> {
  return useGetAll({
    apiConfig: allDataSources,
    parser: getParsedDataSources,
    options: { enabled },
  });
}

export function useGetAllDataSourceTypes(): CustomReactQueryHookReturnType {
  return useGetRefByType("SOURCE_TYPE", getParsedDataSourceTypes);
}

export function useGetDataSourceById(
  dataSourceId: string
): CustomReactQueryHookReturnType {
  return useGetAll({
    apiConfig: singleDataSource,
    params: [dataSourceId],
    parser: getParsedSingleDataSource,
  });
}

export function useGetSourceScanHistory(
  dataSourceId: string,
  pageNumber: number,
  pageSize: number,
  sortQuery: string,
  onSuccess?: (_response: any) => void,
  shouldRefetch?: boolean
): CustomReactQueryHookReturnType<ScanHistoryPaginatedParserReturnType> {
  return useGetAll({
    apiConfig: sourceScanHistory,
    params: [dataSourceId, `${pageNumber}`, `${pageSize}`, sortQuery],
    parser: getParsedPaginatedScanHistory,
    options: {
      ...(shouldRefetch && { refetchInterval: 10000 }),
      onSuccess,
    },
  });
}

export function useGetSourceScanResult(
  id: string,
  queryOptions: GetScanResultQueryOptionsType
): CustomReactQueryHookReturnType<ScanReultParserdType> {
  return useGetAll({
    apiConfig: getScanResult,
    params: [id],
    parser: dataSourceScanResultParser,
    options: {
      ...queryOptions,
    },
  });
}

export function useGetExcelSourceFolderFiles(
  folderName: string
): CustomReactQueryHookReturnType<ParsedExcelSourceFilesList> {
  return useGetAll({
    apiConfig: getExcelSourceFolderFiles,
    params: [folderName],
  });
}

export function useGetCurrentJobStatusOfExcelSource(
  jobId: string
): CustomReactQueryHookReturnType<ExcelSourceScanJobStatusParsedType> {
  return useGetAll({
    apiConfig: getExcelSourceJobStatus,
    params: [jobId],
    options: {
      enabled: !!jobId,
      refetchInterval: 10000,
    },
  });
}
