import { NodeType } from "../../app.types";
import { API_CONFIG } from "../../constants/apiconfig";
import {
  getAllWidgetsList,
  getDashboardFiltersList,
  getDashboardWidgetDetails,
  getParsedCurrentDashboard,
  getParsedDashboardGovernanceViews,
} from "../../parsers/dashboardparser/dashboardparser";

import {
  CurrentDashboardParserReturnType,
  DashboardFiltersParsedType,
  DashboardGovernanceViewsParserReturnType,
  WidgetAndLayoutType,
  WidgetsDataValuesType,
} from "../../parsers/dashboardparser/dashboardparser.types";

import { useGetAll } from "../baseservice";
import { CustomReactQueryHookReturnType } from "../services.types";

const {
  get_dashboard_widgets: getDashboardWidgets,
  get_dashboard_governance_views: getDashboardGovernanceViews,
  get_dashboard_current_node: getDashboardCurrentNode,
  get_dashboard_filters: getDashboardFilters,
  get_widget_details: getWidgetValues,
  get_dashboard_home_widgets: getDashboardHomeWidgets,
  get_dashboard_governance_view_by_id: getDashboardGovernanceViewById,
} = API_CONFIG;

export function useGetWidgetsList(
  dashboardId: string
): CustomReactQueryHookReturnType<WidgetAndLayoutType> {
  return useGetAll({
    apiConfig: getDashboardWidgets,
    params: [dashboardId],
    parser: getAllWidgetsList,
    options: { enabled: !!dashboardId },
  });
}

export function useGetHomeWidgetsList(): CustomReactQueryHookReturnType<WidgetAndLayoutType> {
  // dashboardId: string
  return useGetAll({
    apiConfig: getDashboardHomeWidgets,
    // params: [dashboardId],
    parser: getAllWidgetsList,
    // options: { enabled: !!dashboardId },
  });
}

export function useGetDashboardGovernanceViews(
  enabled: boolean
): CustomReactQueryHookReturnType<DashboardGovernanceViewsParserReturnType[]> {
  return useGetAll({
    apiConfig: getDashboardGovernanceViews,
    // params: [node_type],
    parser: getParsedDashboardGovernanceViews,
    options: { enabled },
  });
}

export function useGetDashboardGovernanceViewsById(
  dashboardId: string,
  enabled: boolean
): CustomReactQueryHookReturnType<DashboardGovernanceViewsParserReturnType[]> {
  return useGetAll({
    apiConfig: getDashboardGovernanceViewById,
    params: [dashboardId],
    parser: getParsedDashboardGovernanceViews,
    options: { enabled },
  });
}

export function useGetDashboardCurrentNode(
  onSuccess: (_response: any) => void,
  enabled: boolean
): CustomReactQueryHookReturnType<CurrentDashboardParserReturnType> {
  // onSuccess: (_response: any) => void
  return useGetAll({
    apiConfig: getDashboardCurrentNode,
    // params: [nodeType, nodeId],
    parser: getParsedCurrentDashboard,
    options: { onSuccess, enabled },
  });
}

export function useGetDashboardFilters(
  viewType: NodeType | "",
  widgetId: string
): CustomReactQueryHookReturnType<DashboardFiltersParsedType[]> {
  return useGetAll({
    apiConfig: getDashboardFilters,
    params: [viewType, widgetId],
    parser: getDashboardFiltersList,
    options: { enabled: !!viewType },
  });
}

export function useGetWidgetValues(
  dashboardId: string,
  widgetId: string
  // source_ids: string,
  // domain_ids: string
): CustomReactQueryHookReturnType<WidgetsDataValuesType> {
  const timzone = new Date().getTimezoneOffset() || "";
  return useGetAll({
    apiConfig: getWidgetValues,
    // params: [dashboardId, widgetId, String(timzone), source_ids, domain_ids],
    params: [dashboardId, widgetId, String(timzone)],
    parser: getDashboardWidgetDetails,
    options: { enabled: !!(dashboardId && widgetId) },
  });
}
