import * as yup from "yup";
import { REQUIRED_MESSAGE } from "../../../constants/formconstants";
import { FilterCriteriaSchema } from "../filtercriteriaschema";
import { descriptionSchema } from "./ruledescriptionschema";

export const addOrphanKeysSchema = yup.object().shape({
  rule_type: yup.string()?.trim().required(REQUIRED_MESSAGE),
  rule_description: descriptionSchema,
  columns: yup.array().of(yup.string()).min(1, REQUIRED_MESSAGE),
  reference_source: yup.string()?.trim().required(REQUIRED_MESSAGE),
  reference_table: yup.string()?.trim().required(REQUIRED_MESSAGE),
  reference_columns: yup.lazy((_, props2) => {
    const columns = props2?.parent?.columns;
    const refColumns = props2?.parent?.reference_columns;

    return yup
      .array()
      .test(
        "lengthCheck",
        "Number of fields should match with Target.",
        (): boolean => {
          return (
            !columns || !refColumns || columns.length === refColumns.length
          );
        }
      )
      .of(yup.string())
      .required(REQUIRED_MESSAGE);
  }),

  is_filter_criteria_active: yup.boolean(),

  filter_criteria: yup.lazy((_p, p1) => {
    if (p1?.parent?.is_filter_criteria_active) {
      return yup.array().of(
        yup.object().shape({
          condition: yup.array().of(FilterCriteriaSchema),
        })
      );
    }
    return yup.mixed().nullable();
  }),

  reference_filter_criteria: yup.lazy((_p, p1) => {
    if (p1?.parent?.is_filter_criteria_active) {
      return yup.array().of(
        yup.object().shape({
          condition: yup.array().of(FilterCriteriaSchema),
        })
      );
    }
    return yup.mixed().nullable();
  }),
});
