import styled from "styled-components";

export const QueryGeneralSettingsWrapperStyled = styled.div`
  display: flex;
  padding: 15px 0;
  row-gap: 15px;
  flex-direction: column;

  .format-btn-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 15px;
    flex-direction: row;

    .label-desc-container {
      display: flex;
      row-gap: 5px;
      flex-direction: column;

      .label {
        color: ${({ theme }): string => theme?.textColors?.GREY_40};
        font-family: OpenSansSemiBold;
        font-size: 14px;
      }

      .desc {
        color: ${({ theme }): string => theme?.textColors?.GREY_24};
        font-size: 12px;
      }
    }
  }
  .ant-switch {
    min-width: 35px;
  }

  .alert-container {
    svg {
      max-height: 14px;
      margin-right: 8px;
    }

    .ant-alert-message {
      color: ${({ theme }): string => theme?.textColors?.GREY_24};
      font-style: italic;
      font-size: 12px;
    }

    svg {
      path {
        fill: ${({ theme }): string => theme?.textColors?.GREY_210};
      }
    }
  }
`;
