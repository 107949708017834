import styled from "styled-components";
import { hexToRgbA } from "../../utils";

export const StyledCopyToClipboard = styled.div`
  display: flex;

  .text-to-copy {
    background: ${({ theme }): string =>
      hexToRgbA(theme.bgColors.LIGHT_GREY_22, 0.6)};
    color: ${({ theme }): string => theme.bgColors.DARK_GREY_10};
    width: 452px;
    padding: 4px 15px;
    line-height: 26px;
  }

  .copy-to-clipboard-button {
    background: ${({ theme }): string => theme.bgColors.BLUE_36};
    display: flex;
    width: 42px;
    padding: 8px 14px;
    align-items: center;
    justify-content: center;

    svg {
      width: 15px;
      height: 17px;
    }

    &:hover {
      cursor: pointer;
    }
  }
`;
