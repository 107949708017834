import styled from "styled-components";

export const AddSSOFormStyled = styled.div<{ maxHeight?: string }>`
  .ant-form {
    .ant-form-item {
      .ant-form-item-control {
        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            .input-field-container > input,
            .textarea-field-container > textarea {
              width: 512px;
            }
          }
        }
      }
    }

    .scroll-sec {
      display: flex;
      column-gap: 200px;
      padding: 29px;
      max-height: ${({ maxHeight }): string =>
        maxHeight || "calc(100vh - 142px)"};
      overflow: auto;

      .form-sec {
        .section-with-body {
          margin-left: 130px;
        }

        .ant-radio-group {
          gap: 10px;
        }

        .metadata-url {
          width: 476px !important;
          height: 34px !important;
        }

        .metadata-file {
          margin-top: 8px;
          width: 476px;
          height: 127px;

          .ant-upload-drag-container {
            .desc-sec {
              font-size: 11.2px;
              align-items: center;

              .action__text__1 {
                margin-left: 5px;
              }
              .action__text__2 {
                padding-left: 5px !important;
                margin-left: 0px !important;
              }

              .action__text__1,
              .action__text__2 {
                font-size: 11.2px !important;
              }

              svg {
                width: 6.4px;
              }
            }

            svg {
              width: 46px;
              height: 32px;
            }
          }

          .progress-sec {
            height: 100%;

            .left {
              font-size: 16px;

              .file-icon {
                width: 18px;
                height: 22px;

                svg {
                  width: 10px;
                }
              }

              .file-name {
                max-width: 82px;
              }
            }

            .right {
              .file-status {
                font-size: 14px;
              }

              div:nth-child(2) {
                width: 280px;
              }
            }
          }
        }

        .attributes-list {
          display: grid;
          grid-template-columns: 512px 214px;
          gap: 14px;
          margin-bottom: 10px;

          .ant-select {
            width: 100%;
          }
          .ant-select-selector {
            height: 34px;

            .ant-select-selection-placeholder {
              font-size: 14px;
              padding: 2px;
            }
          }
        }

        button.add-attributes-btn {
          font-family: OpenSansSemiBold;
          font-size: 14.4px;
          display: flex;
          align-items: baseline;

          &:disabled {
            color: ${({ theme }): string => theme.textColors.LIGHT_GREY_6};
            opacity: unset;

            svg path {
              fill: ${({ theme }): string => theme.textColors.LIGHT_GREY_6};
            }
          }

          svg {
            width: 12px;
            margin-right: 4.5px;
            padding-top: 3px;
          }
        }
      }
    }

    .form-item-with-inline-info {
      .ant-form-item-control-input-content {
        display: flex;
        align-items: center;
        column-gap: 8px;
      }
    }

    .form-actions-sec {
      position: absolute;
      width: 100vw;
      bottom: 0px;
      margin-bottom: 0px;
      padding: 16px 24px;
    }
  }
`;

export const UploadFileSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .file-exist-tag {
    background: ${({ theme }): string => theme.bgColors.LIGHT_BLUE_15};
    color: ${({ theme }): string => theme.textColors.BLUE_46};
    padding: 12px;

    svg {
      width: 12px;
    }
  }
`;