import { notification } from "antd";

export function openNotification(
  message: string | JSX.Element,
  duration?: number,
  opts?: {
    right?: number;
    top?: number;
    getContainer?: () => HTMLElement;
    className?: string;
    key?: string;
  },
  type?: "success" | "error" | "info"
): void {
  const args = {
    message: "Notification Title",
    description: message,
    duration: 0,
    className:
      type === "error"
        ? "error-ant-notification"
        : type === "info"
        ? "info-ant-notification"
        : "dvsum-notification",
    right: 76,
    top: 70,
  };
  notification.open({
    ...args,
    ...opts,
    ...(opts?.className && {
      className: `${args?.className} ${opts?.className}`,
    }),
  });
  setTimeout(() => notification.destroy(), duration || 5000);
}
