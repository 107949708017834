import { useCallback, useMemo, useState } from "react";
import { ColumnSequenceModalStyled } from "./columnsequencemodal.styles";

import { FormItemStyled, FormStyled } from "../../../form";
import Button from "../../../button";

import Sequence from "../../../sequence";
import {
  useCancelModal,
  useGetAppState,
  useSetData,
} from "../../../../customhooks";

import { useGetColumnConfig } from "../../../../api/tablesservice";
import StateHandler from "../../../statehandler/statehandler";

import { useRequestWithMethod } from "../../../../api";
import { columnSequenceBlankSlate } from "../../../../blankslates/columnsequenceblankslate";

import { openNotification } from "../../../../utils";
import SuccessNotificationMessage from "../../../successnotificationmessagerendrer/successnotificationmessagerendrer";

import { API_CONFIG } from "../../../../constants/apiconfig";

const ColumnSequenceModal = (): JSX.Element => {
  const onCancel = useCancelModal();

  const {
    modal: { modalProps = {} },
  } = useGetAppState();

  const onSetData = useSetData();

  const { tableId } = modalProps || {};

  const {
    parsedData: columnsData,
    isLoading: columnsDataLoading,
    error: columnsDataError,
  } = useGetColumnConfig(tableId);

  const onSaveColumnSequenceSuccess = useCallback(
    (response) => {
      onSetData(API_CONFIG?.get_column_config, response?.data, [tableId]);

      openNotification(
        <SuccessNotificationMessage
          message="Column sequence is updated successfully."
          showSuccess
        />
      );
      onCancel();
    },
    [tableId]
  );

  const {
    onExecuteRequest: onSaveColumnSequence,
    isLoading: isColumnSequenceSaving,
    error: errorInSavingColumnSequence,
  } = useRequestWithMethod(
    "save_column_order",
    [tableId],
    undefined,
    onSaveColumnSequenceSuccess
  );

  const [hasAnythingChanged, setHasAnythingChanged] = useState<boolean>(false);

  const onColumnSeqChange = useCallback(() => {
    setHasAnythingChanged(true);
  }, []);

  const [columnsOrder, setColumnsOrder] = useState<string[]>(
    columnsData?.map((item) => `${item?.field_id}`)
  );

  const onSetColumnsOrder = useCallback((updatedColumnOrder: string[]) => {
    setColumnsOrder([...updatedColumnOrder]);
  }, []);

  const sortedColumns = useMemo(() => {
    const columnMap = new Map(
      columnsData?.map((col, index) => [col?.field_id, index])
    );

    const initialColumnsOrder =
      columnsOrder || columnsData?.map((item) => `${item?.field_id}`);

    setColumnsOrder(initialColumnsOrder);

    const sortedColumns = initialColumnsOrder?.map((key, index) => {
      return {
        ...columnsData?.[columnMap?.get(key) as number],
        col_seq: index + 1,
      };
    });

    return sortedColumns;
  }, [columnsData, columnsOrder]);

  const onSave = useCallback(() => {
    const apiColumns = sortedColumns?.map((column) => ({
      COL_ID: Number(column?.field_id),
      COL_NAME: column?.field_name,
      COL_DISPLAY_ORDER: column?.col_seq,
      COL_TYPE: column?.field_datatype,
    }));

    onSaveColumnSequence(apiColumns);
  }, [sortedColumns]);

  return (
    <ColumnSequenceModalStyled>
      <p className="label">Columns</p>
      <FormStyled isItemColumnLayout paddingLeft="110px">
        <StateHandler
          isFetching={columnsDataLoading || isColumnSequenceSaving}
          error={columnsDataError || errorInSavingColumnSequence}
          blankSlate={columnsDataLoading ? columnSequenceBlankSlate : undefined}
          isModal={isColumnSequenceSaving}
        >
          <Sequence
            onColumnSeqChange={onColumnSeqChange}
            sortedColumns={sortedColumns}
            columnsOrder={columnsOrder}
            onSetColumnsOrder={onSetColumnsOrder}
            key={
              columnsDataLoading
                ? "column-sequence-modal"
                : `column-sequence-modal-${sortedColumns?.length}`
            }
          />
        </StateHandler>
        <FormItemStyled className="form-actions-sec">
          <Button id="cancel" width="74px" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            id="primary"
            width="74px"
            marginLeft="8px"
            htmlType="submit"
            onClick={onSave}
            disabled={!hasAnythingChanged}
          >
            Save
          </Button>
        </FormItemStyled>
      </FormStyled>
    </ColumnSequenceModalStyled>
  );
};

export default ColumnSequenceModal;
