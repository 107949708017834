// <--- Library Imports Start --->
import styled from "styled-components";
// <--- Library Imports End --->

export const CommentsSecStyled = styled.div`
  border: 1px solid ${({ theme }): string => theme?.borderColors?.GREY_56};
  position: relative;
  width: calc(25% - 26px);

  .header {
    padding: 12.8px 16px;
    display: flex;
    text-align: center;
    justify-content: space-between;
    border-bottom: 1px solid
      ${({ theme }): string => theme?.borderColors?.GREY_56};

    .heading {
      font-size: 14.4px;
      font-family: OpenSansSemiBold;
    }
  }

  .content-sec {
    padding: 19.2px 16px;

    .comments-sec {
      max-height: 460px;
      overflow-y: auto;
    }
  }

  .add-comment-wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 16px;
  }
`;
