import styled from "styled-components";

export const QueryBlockOuterWrapperStyled = styled.div<{
  isMaximizeQueryBlock: boolean;
  isChartSettingsVisible: boolean;
  isChatPage?: boolean;
  isProfiled?: boolean;
}>`
  z-index: 11;
  background: ${({ theme, isMaximizeQueryBlock }): string =>
    isMaximizeQueryBlock ? theme?.bgColors?.WHITE_COLOR : ""};
  overflow-y: auto;
  overflow-x: auto;

  .query-block-full-screen-header {
    padding: 19.2px 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${({ theme }): string => theme?.bgColors?.LIGHT_GREY_22};

    .title {
      font-family: OpenSansSemiBold;
    }

    .ant-btn-link {
      display: flex;
      align-items: center;

      svg {
        height: 19.2px;
        width: 19.2px;
      }
    }
  }

  .query-block-full-screen-body {
    .chat-query-block-header {
      width: ${({
        isMaximizeQueryBlock,
        isChatPage,
        isChartSettingsVisible,
      }): string =>
        isMaximizeQueryBlock && isChatPage
          ? isChartSettingsVisible
            ? "92%"
            : "75%"
          : ""};
    }
    .query-results-collapse {
      width: ${({
        isMaximizeQueryBlock,
        isChatPage,
        isProfiled,
        isChartSettingsVisible,
      }): string =>
        isMaximizeQueryBlock &&
        isChatPage &&
        (isProfiled || isChartSettingsVisible)
          ? "94%"
          : isMaximizeQueryBlock &&
            isChatPage &&
            (!isProfiled || !isChartSettingsVisible)
          ? "96%"
          : ""};
    }

    .query-block-header-collapse {
      justify-content: center;
    }
  }
`;

export const QueryBlockStyled = styled.div<{
  isFocused: boolean;
  isChatPage: boolean;
  isMaximizeQueryBlock: boolean;
  isProfiled: boolean;
  isExpanded: boolean;
  isViewOnlyChatQuestionPage: boolean;
  isQuerySettingsVisible: boolean;
}>`
  height: ${({ isChatPage, isMaximizeQueryBlock }): string =>
    !isChatPage && isMaximizeQueryBlock ? "max-content" : ""};
  min-height: ${({ isMaximizeQueryBlock }): string =>
    isMaximizeQueryBlock ? "100vh" : ""};
  width: ${({ isChatPage, isMaximizeQueryBlock }): string =>
    !isChatPage && isMaximizeQueryBlock ? "100vw" : ""};

  &.analysis-query-block-styled {
    margin-bottom: ${({ isMaximizeQueryBlock }): string =>
      isMaximizeQueryBlock ? "0" : "20px"};
    margin-right: 10px;
    display: flex;
    border: 1px solid
      ${({ isFocused, theme }): string =>
        isFocused
          ? theme?.borderColors?.BLUE_116
          : theme?.borderColors?.GREY_56};

    .query-block-left-sec {
      padding: 15px 6px 0px 0px;
      font-family: OpenSansSemiBold;
      color: ${({ isFocused, theme }): string =>
        isFocused
          ? theme?.textColors?.WHITE_COLOR
          : theme?.textColors?.GREY_24};
      background: ${({ isFocused, theme }): string =>
        isFocused ? theme?.bgColors?.BLUE_116 : theme?.bgColors?.GREY_56};
    }

    .query-block-right-sec {
      width: 100%;
      background: ${({ theme }): string => theme?.bgColors?.WHITE_COLOR};
    }

    .query-block-header-collapse {
      display: flex;
      align-items: center;
      cursor: auto;
      padding: 12px 17px 10px 10.2px;
      ${({ isMaximizeQueryBlock }): string =>
        isMaximizeQueryBlock ? "padding-right:34px" : ""};
      border-bottom: 1px solid
        ${({ theme }): string => theme?.borderColors?.GREY_56};

      .collapse-arrow {
        visibility: ${({ isMaximizeQueryBlock }): string =>
          isMaximizeQueryBlock ? "hidden" : ""};
        svg {
          path {
            fill: ${({ isFocused, theme }): string =>
              isFocused
                ? theme?.textColors?.BLUE_116
                : theme?.textColors?.GREY_11};
          }

          margin-top: 7px;
          margin-right: 5px;
        }
      }

      .collapse-arrow {
        cursor: pointer;
      }

      .concept-icon {
        margin-top: 7px;
      }
    }

    .query-block-header {
      &:hover {
        .edit-btn-form {
          display: block;
        }
      }
    }
  }

  &.chat-query-block-styled {
    padding-bottom: ${({ isExpanded }): string => (!isExpanded ? "15px" : "0")};
    background-color: ${({ theme }): string => theme?.bgColors?.GREY_95};
    border-bottom: ${({ theme }): string =>
      `1px solid ${theme?.borderColors?.GREY_56}`};

    .query-block-header-collapse {
      display: flex;
      align-items: center;
      justify-content: start;
      cursor: auto;
      padding: 10px 0 9px;
      background-color: ${({ theme }): string => theme?.bgColors?.WHITE_COLOR};
      border-bottom: 1px solid
        ${({ theme }): string => theme?.borderColors?.GREY_56};

      .collapse-arrow {
        margin-left: ${({
          isChatPage,
          isMaximizeQueryBlock,
          isQuerySettingsVisible,
        }): string =>
          isChatPage && !isMaximizeQueryBlock
            ? "calc(11rem - 23.6px)"
            : isChatPage && isMaximizeQueryBlock && isQuerySettingsVisible
            ? "calc(2rem - 23.6px)"
            : isChatPage && isMaximizeQueryBlock && !isQuerySettingsVisible
            ? "calc(11rem - 23.6px)"
            : ""};
        margin-right: 13.6px;
        cursor: pointer;
      }

      .chat-query-block-header {
        margin-left: ${({ isViewOnlyChatQuestionPage }): string =>
          isViewOnlyChatQuestionPage ? `18px` : "0"};
      }
    }

    height: ${({ isMaximizeQueryBlock }): string =>
      isMaximizeQueryBlock ? "100%" : ""};
    min-height: ${({ isMaximizeQueryBlock }): string =>
      isMaximizeQueryBlock ? "100vh" : ""};
  }
`;
