import { AxiosResponse } from "axios";
import { jsonParse } from "../../utils";

import {
  DomainsGlobalFiltersResponseType,
  GlobalFiltersReturnType,
  SelectedGlobalFiltersResponseType,
  SelectedGlobalFiltersReturnType,
  SourcesGlobalFiltersResponseType,
} from "./globalfiltersparser.types";

export function getParsedSourcesGlobalFilter({
  data,
}: AxiosResponse<SourcesGlobalFiltersResponseType>): GlobalFiltersReturnType {
  return (
    data?.map((filter) => ({
      id: filter?.NODE_ID || 0,
      name: filter?.NODE_NAME || "",
      source_type: filter?.SRC_TYPE || "",
    })) || []
  );
}

export function getParsedDomainsGlobalFilter({
  data,
}: AxiosResponse<DomainsGlobalFiltersResponseType>): GlobalFiltersReturnType {
  return (
    data?.map((filter) => ({
      id: filter?.NODE_ID || 0,
      name: filter?.NODE_NAME || "",
    })) || []
  );
}

export function getParsedSelectedGlobalFilter({
  data,
}: AxiosResponse<SelectedGlobalFiltersResponseType>): SelectedGlobalFiltersReturnType {
  const settings = jsonParse(data?.USER_SETTINGS || "");

  return {
    view_keys: settings?.gov_view_keys || {},
    selected_filters: settings?.filter || {},
  };
}
