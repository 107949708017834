export const APP_ROUTES = {
  private_routes: {
    dashboard: "/dashboard",
    terms: "/terms",
    table_dictionary: "/tables",
    field_dictionary: "/columns",
    rules_dictionary: "/rules",
    analytics_dictionary: "/analytics_dictionary",
    user_groups: "/user-groups",
    user_roles: "/user-roles",
    users: "/users",
    glossary_terms: "/glossary_terms",
    glossary_import: "/glossary_import",
    sub_domains: "/sub_domains",
    data_domains: "/data_domains",
    profiling: "/profiling",
    search: "/search",
    home: "/home",
    enterprise_search: "/search",
    sources: "/sources",
    account_settings: "/account-settings",
    // glossary_upload: "/glossary_upload",
    source_scan_result: "/sources/scan_result",
    product_tour: "/product-tour",
    ext_search_page: "/ext-enterprise-search-page",
    tagsets: "/tagsets",
    analytics: "/analytics",
    data_sources: "/data_sources",
    reports: "/reports",
    lineage: "/lineage",
    data_import: "/data-import",
    my_settings: "/my-settings",
    onboarding_tutorial: "/onboarding-tutorial",
    chat_bot: "/chat-bot",
    chat: "/chat",
    new_chat: "/chat/new",
    rule_detail: "/rules",
    job_schedule_detail: "/jobs",
    jobs_dictionary: "/jobs",
    jobexecutions_dictionary: "/executions",
    job_detail: "/jobs",
    view_only_chat: "/view-only-chat",
    reference_dictionary: "/reference-dictionary",
    read_only_chat: "/read-only-chat",
    view_only_chat_question: "/view-only-chat-question",
  },
  auth_routes: {
    login: "/login",
    ext_login: "/ext-login",
    auth_token: "/auth-token",
    register: "/signup",
    account_verification: "/account/verify",
    test_drive: "/testdrive",
  },
};
