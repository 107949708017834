import styled from "styled-components";

export const PageHeaderStyled = styled.div<{ isEditMode?: boolean }>`
  display: grid;
  grid-template-columns: 1fr auto;

  .left {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .page-name {
      display: flex;
      gap: 8px;
      align-items: center;
      color: ${({ theme }): string => theme.textColors.BLUE_116};
      font-size: 19.2px;
      font-family: OpenSansSemiBold, OpenSans;

      .static-text-rendrer {
        margin-top: 5px;
      }
    }

    .created-by,
    .updated-by {
      font-size: 14px;
      color: ${({ theme }): string => theme.textColors.DARK_GREY_10};
      letter-spacing: -0.09px;
    }
  }

  .right {
    display: flex;
    gap: ${(props): string => (props?.isEditMode ? "6px" : "20px")};
    height: fit-content;

    .delete-btn {
      display: flex;
      gap: 6px;
      align-items: center;
      color: ${({ theme }): string => theme.textColors.RED_100};
      font-size: 14px;
      font-family: OpenSansSemiBold;

      svg path {
        fill: ${({ theme }): string => theme.textColors.RED_100};
      }
    }

    .edit-btn {
      display: flex;

      svg {
        margin-right: 6px;
        path {
          fill: ${({ theme }): string => theme.textColors.WHITE_COLOR};
        }
      }
    }
  }
`;
