import { Tooltip } from "antd";
import { useCallback } from "react";

import { QueryChartSettingsTagStyled } from "./querychartsettingstag.styles";
import { QueryChartSettingsTagProps } from "./querychartsettingstag.types";
import {
  plusIcon,
  queryChartAscSortIcon,
  queryChartDescSortIcon,
  queryChartSortIcon,
} from "../../../../../../../../../../../../../../svgs";
import { DVSUM_TOOLTIP_CLASS_NAME } from "../../../../../../../../../../../../../../constants";
import { CheckboxStyled } from "../../../../../../../../../../../../../../components/checkbox/checkbox.styles";
import {
  getDataTypeIcon,
  transformDashedTextToCapitalizedText,
} from "../../../../../../../../../../../../../../utils";
import LinkButton from "../../../../../../../../../../../../../../components/linkbutton/linkbutton";
import TableActionsSec from "../../../../../../../../../../../../../../components/tableactionssec/tableactionssec";

const sortIcon = queryChartSortIcon();
const ascSortIcon = queryChartAscSortIcon();
const descSortIcon = queryChartDescSortIcon();
const addAxisIcon = plusIcon("12", "12", "#949494");

const QueryChartSettingsTag = (
  props: QueryChartSettingsTagProps
): JSX.Element => {
  const {
    columnName = "",
    dataType,
    variant = "GRY",
    sortDirection,
    isChecked,
    isShowCheckbox,
    checkboxTooltip,
    disabled = false,
    onCheckboxClick,
    onHideSortTooltip,
    onShowSortTooltip,
    columnIndex,
    onColumnNameClick,
    isShowSortIcon,
    axisName = "x",
    showAddAxisButton = false,
    disabledAddAxisButton = false,
    onAddOnXAxis,
    onAddOnYAxis,
    canAddOnBothAxes = false,
  } = props;

  const onMouseEnter = useCallback(() => {
    onHideSortTooltip?.()?.();
  }, []);

  const onMouseLeave = useCallback(() => {
    onShowSortTooltip?.(axisName, columnIndex)?.();
  }, [axisName, columnIndex]);

  const stopPropagation = useCallback((e) => {
    e?.stopPropagation();
  }, []);

  return (
    <QueryChartSettingsTagStyled
      variant={variant}
      disabled={disabled}
      showAddAxisButton={!!showAddAxisButton}
      className="tag-wrapper"
    >
      <div className="tag-left-sec">
        {dataType && (
          <div className="data-type-icon">{getDataTypeIcon(dataType)}</div>
        )}
        <LinkButton
          className="column-name"
          title={columnName}
          onClick={(e): void => onColumnNameClick?.(e, columnName)}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {transformDashedTextToCapitalizedText(columnName)}
        </LinkButton>

        {isShowSortIcon && (
          <div className="grid-sort-icon">
            {sortDirection === "asc"
              ? ascSortIcon
              : sortDirection === "desc"
              ? descSortIcon
              : sortIcon}
          </div>
        )}
      </div>

      <div className="tag-right-sec">
        {showAddAxisButton &&
          (canAddOnBothAxes ? (
            <TableActionsSec
              hideDots
              data={[
                {
                  name: "Add on X-axis",
                  onClick: onAddOnXAxis || ((): void => {}),
                  disabled: !onAddOnXAxis,
                },
                {
                  name: "Add on Y-axis",
                  onClick: onAddOnYAxis || ((): void => {}),
                  disabled: disabledAddAxisButton || !onAddOnYAxis,
                },
              ]}
            >
              {addAxisIcon}
            </TableActionsSec>
          ) : (
            <LinkButton
              className="add-axis-button"
              tooltipProps={{
                title: disabledAddAxisButton
                  ? undefined
                  : axisName === "x"
                  ? "Make it X Axis"
                  : "Add to Y Axis",
              }}
              onClick={axisName === "x" ? onAddOnXAxis : onAddOnYAxis}
              disabled={disabledAddAxisButton}
            >
              {addAxisIcon}
            </LinkButton>
          ))}

        {isChecked && <span className="axis-number-tag">2nd Axis</span>}
        {isShowCheckbox && (
          <div
            className="checkbox-wrapper"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            role="button"
            onClick={stopPropagation}
          >
            <Tooltip
              title={checkboxTooltip}
              overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
            >
              <CheckboxStyled onChange={onCheckboxClick} checked={isChecked} />
            </Tooltip>
          </div>
        )}
      </div>
    </QueryChartSettingsTagStyled>
  );
};

export default QueryChartSettingsTag;
