import { ProminentTagType } from "../components/genericprominenttag/genericprominenttag.types";
import { CaddiAnalyticsStatusIdType } from "../parsers";

const statusLabelMapping: { [key: string]: string } = {
  ACP: "Accepted",
  CLS: "Closed",
  FIX: "Fixed",
  IGN: "Ignored",
  INP: "In Progress",
  NEW: "New",
};

export const analyticsStatusTagMapping = (
  statusId: CaddiAnalyticsStatusIdType,
  status?: string
): ProminentTagType => {
  const alternateStatusLabel = statusLabelMapping?.[statusId];
  switch (statusId) {
    case "NEW":
      return {
        name: status || alternateStatusLabel,
        type: "LBL",
        showBorder: true,
      };
    case "ACP":
      return {
        name: status || alternateStatusLabel,
        type: "BLU",
        showBorder: true,
      };
    case "INP":
      return {
        name: status || alternateStatusLabel,
        type: "ORG",
        showBorder: true,
      };
    case "FIX":
      return {
        name: status || alternateStatusLabel,
        type: "LGR",
        showBorder: true,
      };
    case "CLS":
      return {
        name: status || alternateStatusLabel,
        type: "GRN",
        showBorder: true,
      };

    // Ignored
    default:
      return {
        name: status || alternateStatusLabel,
        type: "LGRY",
        showBorder: true,
      };
  }
};
