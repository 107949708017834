import { AxiosResponse } from "axios";
import {
  CurrentDashboardParserReturnType,
  CurrentDashboardResponse,
  DasboardGovernanceViewsResponse,
  DashboardGovernanceViewsParserReturnType,
  DashboardFiltersParsedType,
  DashboardFiltersResponseType,
  FiltersType,
  WidgetAndLayoutType,
  WidgetListResponseType,
  WidgetsDataValuesType,
  WidgetsDataValuesTypeResponse,
} from "./dashboardparser.types";

import { computeLayout, getSortedWidgets } from "./dashboardparser.util";
import { isJsonString, jsonParse } from "../../utils";

import { FilterResponseType } from "../tablepage";
import { apiToFormCriteria } from "../../forms/addgovernanceviewform/addgovernanceviewform.utils";

import { WidgetConfigResponseType } from "../../components/modalwrapper/modals/widgetcreationwizard";
import { convertApiFiltersToUIData } from "../../forms/addruleform/addfoundationaldqform/addrulesetform/addrulesetform.comp/addrulesetformgrid/addrulesetformgrid.util";

export function getAllWidgetsList({
  data = [],
}: AxiosResponse<WidgetListResponseType>): WidgetAndLayoutType {
  const parsedWidgets =
    data?.map((wid) => {
      const parsedSelectedWidgetAdhocInfo = isJsonString(
        wid?.WIDGET_CONFIG || ""
      )
        ? jsonParse(wid?.WIDGET_CONFIG || "")
        : {};

      const {
        enable_datalabels,
        slicer_fields,
        sort_type,
        metric_fields,
        sort_field_id,
        is_full_screen: isFullScreen,
      } = parsedSelectedWidgetAdhocInfo as WidgetConfigResponseType;

      const filters: FilterResponseType = isJsonString(
        wid?.WIDGET_FILTER_JSON || ""
      )
        ? JSON.parse(wid?.WIDGET_FILTER_JSON)
        : [];

      const parsedFilters = convertApiFiltersToUIData(filters?.filter);
      const parsedFiltersSqlText = filters?.filter_text;

      const sortFieldId = sort_field_id || (sort_type ? "CNT" : "");

      return {
        id: wid?.WIDGET_ID,
        dashboardId: wid?.DSH_ID,
        dashboardName: wid?.DSH_NAME,
        widgetName: wid?.WIDGET_NAME,
        desc: wid?.WIDGET_DESC,
        chartTypeId: wid?.WIDGET_CHART_TYPE_ID,
        chartType: wid?.WIDGET_CHART_TYPE,
        widgetChartType: wid?.WIDGET_CHART_TYPE_ID,
        govViewCategory: wid?.WIDGET_VIEW_CATEGORY_ID,
        config: wid?.WIDGET_CONFIG,
        templateId: wid?.WIDGET_TMPLT_ID,
        filterName: wid?.WIDGET_FILTER_NAME,
        filterJson: parsedFilters,
        filterSql: parsedFiltersSqlText || "",
        isTemplateFilter: wid?.IS_TEMPLATE_FILTER,
        widgetOrder: wid?.WIDGET_DISPLAY_ORDER,
        displayOrder: wid?.WIDGET_DISPLAY_ORDER,
        default: wid?.IS_WIDGET_DEFAULT,
        createdOn: wid?.CREATED_ON,
        createdBy: wid?.CREATED_BY,
        createdByName: wid?.CREATED_BY_NAME,
        updatedOn: wid?.UPDATED_ON,
        updatedBy: wid?.UPDATED_BY,
        updatedByName: wid?.UPDATED_BY_NAME,
        isExpanded: false,
        isFullWidthSize: isFullScreen,
        width: isFullScreen ? 12 : 6,
        height: 10,
        enableDataLabels: enable_datalabels,
        sortType: sort_type,
        metricFields: metric_fields,
        slicerFields: slicer_fields,
        category: "",
        sortFieldId,
        adhocInfo: "",
        isWidgetPinnedToHome: wid?.IS_WIDGET_PINNED || false,
      };
    }) || [];

  const sortedWidgets = getSortedWidgets(parsedWidgets);

  const widgetsLayout = computeLayout(sortedWidgets);

  return {
    allWidgets: parsedWidgets,
    layout: widgetsLayout,
  };
}

export const getParsedDashboardGovernanceViews = ({
  data,
}: AxiosResponse<
  DasboardGovernanceViewsResponse[]
>): DashboardGovernanceViewsParserReturnType[] => {
  return data?.map((item) => ({
    id: item?.DSH_ID,
    value: item?.DSH_NAME,
    default: item?.IS_DSH_DEFAULT,
    access: item?.DSH_ACCESS,
    desc: item?.DSH_DESC,
    accessOrder: item?.DSH_ACCESS_ORDER,
  }));
};

export const getParsedCurrentDashboard = ({
  data,
}: AxiosResponse<
  CurrentDashboardResponse[]
>): CurrentDashboardParserReturnType => {
  const item = data?.[0] || {};
  return {
    id: item?.DSH_ID,
    name: item?.DSH_NAME,
    createdOn: item?.CREATED_ON,
    updatedOn: item?.UPDATED_ON,
    createdBy: item?.CREATED_BY,
    updatedBy: item?.UPDATED_BY,
    default: item?.IS_DSH_DEFAULT,
    desc: item?.DSH_DESC,
  };
};
export function getDashboardFiltersList({
  data = [],
}: AxiosResponse<
  DashboardFiltersResponseType[]
>): DashboardFiltersParsedType[] {
  const parsedData =
    data?.map((dashboard) => {
      const filters: FiltersType = isJsonString(
        dashboard?.WIDGET_FILTER_JSON || ""
      )
        ? JSON.parse(dashboard?.WIDGET_FILTER_JSON)
        : [];

      const isFilterExists = filters?.filter?.[0]?.condition?.length || 0;

      const parsedFilters = isFilterExists
        ? (filters?.filter || [])?.flatMap((filterCriteriaRow) => {
            const conditionGrpLogicalOperator =
              filters?.filter?.[0]?.next_operator;

            const apiToFormParsedData = apiToFormCriteria(
              filterCriteriaRow?.condition,
              []
            );

            return {
              condition: apiToFormParsedData,
              next_operator: conditionGrpLogicalOperator || "AND",
            };
          })
        : [];

      return {
        filterType: dashboard?.FILTER_TYPE || "",
        filterName: dashboard?.WIDGET_FILTER_NAME || "",
        filterId: String(dashboard?.ID) || "",
        filterDesc: dashboard?.DESCRIPTION || "",
        filterCriteria: parsedFilters,
        filterSql: filters?.filter_text || "",
      };
    }) || [];

  return parsedData;
}

export function getDashboardWidgetDetails({
  data,
}: AxiosResponse<WidgetsDataValuesTypeResponse>): WidgetsDataValuesType {
  const unParsedChartData = data?.data;

  const parsedColorsCode =
    data?.color_codes?.map((clr) => {
      return {
        name: clr?.REF_NAME || "",
        code: clr?.COLOR_CODE || "",
      };
    }) || [];

  return {
    chartData: unParsedChartData,
    colorCodes: parsedColorsCode,
  };
}
