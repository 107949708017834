// <--- Constants Start --->
import { LOCAL_STORAGE_CONSTANTS } from "../constants/localstorageconstants";
// <--- Constants End --->

// <--- Types Start --->
import { PostLoginFormType } from "../app.types";
// <--- Types End --->

// <--- Utils Start --->
import { getPostLoginData } from "./getpostlogindata";
// <--- Utils End --->

export function updatePostLoginUserInfoData(
  updatedData: PostLoginFormType["user_info"]
): void {
  const postLoginData = getPostLoginData();
  localStorage.setItem(
    LOCAL_STORAGE_CONSTANTS?.post_login_response,
    JSON.stringify({
      ...postLoginData,
      user_info: { ...postLoginData?.user_info, ...updatedData },
    } as PostLoginFormType)
  );
}
