import { useCallback, useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";

import { useForm, FormProvider } from "react-hook-form";

import Dynamicform from "../../dynamicform";
import { addCustomQueryForm } from "./addcustomqueryform.config";

import { addCustomQuerySchema } from "../../../../utils/schemas";
import { AddCustomQueryFormType } from "./addcustomqueryform.types";

import { GetAddRuleFormContentProps } from "../../addruleform.types";
import { customQueryFormDataToApiData } from "./addcustomqueryform.utils";

import { isJsonString, jsonParse } from "../../../../utils";

const AddCustomQueryForm = (props: GetAddRuleFormContentProps): JSX.Element => {
  const {
    sourceId,
    tableId,
    onAddRule,
    onUpdateRule,
    columnsData,
    srcTypeId = "",
    ruleCategoryId,
    formId,
    ruleConfig,
    isEdit = false,
    propsOnCancel,
    rulesRef,
  } = props;

  const {
    rule_desc: desc = "",
    custom_query: customQuery = "",
    is_stored_proc: isStoredProc,
  } = ruleConfig || {};

  const form = useForm<AddCustomQueryFormType>({
    defaultValues: {
      id: "custom_query",
      rule_type: "Custom Query",
      rule_description: desc,
      custom_query: customQuery,
      stored_procedure: isStoredProc,
    },
    resolver: yupResolver(addCustomQuerySchema),
    mode: "onChange",
  });

  const customQueryRule = rulesRef?.find((rule) => rule?.id === formId);
  const customQueryRuleAdhocInfo = customQueryRule?.adhocInfo;
  const parsedAdhocInfo = isJsonString(customQueryRuleAdhocInfo || "")
    ? jsonParse(customQueryRuleAdhocInfo || "")
    : {};

  const supportingSources = parsedAdhocInfo?.sp_supported_sources || [];
  const isSupportedSrc = supportingSources?.includes(srcTypeId);

  const customQueryConfig = useMemo(() => {
    const customQueryFormConfig = addCustomQueryForm(isEdit, isSupportedSrc);

    const filteredSections = customQueryFormConfig?.filter((section) => {
      return section?.id === "basic_input" || section?.id === "basic";
    });

    return filteredSections;
  }, [srcTypeId, isEdit, isSupportedSrc]);

  const onSubmit = useCallback(
    (values: AddCustomQueryFormType) => {
      const rule_config = customQueryFormDataToApiData(values);

      const updatedDesc = values?.rule_description;

      const ruleConfig = {
        ...rule_config,
        rule_type: formId,
        rule_desc: updatedDesc,
      };

      const modifyRulePayload = ruleConfig;

      const dataToSubmit = {
        rule_cat: ruleCategoryId,
        rule_type: formId,
        rule_desc: updatedDesc,
        src_id: sourceId,
        tbl_id: tableId,
        rule_priority: "NRM",
        rule_def_config: ruleConfig,
      };

      isEdit ? onUpdateRule?.(modifyRulePayload) : onAddRule(dataToSubmit);
    },
    [tableId, sourceId, onAddRule, columnsData, isEdit]
  );

  return (
    <FormProvider {...form}>
      <Dynamicform
        schema={customQueryConfig}
        onSubmit={onSubmit}
        columnsData={columnsData}
        isEdit={isEdit}
        propsOnCancel={propsOnCancel}
      />
    </FormProvider>
  );
};

export default AddCustomQueryForm;
