import { PosterProps } from "../../components/poster/poster.types";

import AddCountForm from "./addpipelinechecksform/addcountform";
import AddDataFormatForm from "./addfoundationaldqform/adddataformatform";

import AddBlanksForm from "./addfoundationaldqform/addblanksform/addblanksform";
import AddFreshnessForm from "./addpipelinechecksform/addfreshnessform";

import AddMetricForm from "./addpipelinechecksform/addmetricform";
import AddOrphanRecordsForm from "./addcrosssystemdqform/addorphanrecordsform";

import AddUniquenessForm from "./addfoundationaldqform/adduniquenessform/adduniquenessform";
import AddUniqueValuesForm from "./addfoundationaldqform/adduniquevaluesform/adduniquevaluesform";

import AddCompareMetricForm from "./adddatadiffs/addcomparemetricform";
import AddCustomQueryForm from "./addfoundationaldqform/addcustomqueryform";

import AddValueRangeForm from "./addfoundationaldqform/addvaluerangeform/addvaluerangeform";
import AddCompareSchemaForm from "./adddatadiffs/addcompareschemaform/addcompareschemaform";

import AddRulesetForm from "./addfoundationaldqform/addrulesetform/addrulesetform";
import { RuleTypeId } from "../../parsers/ruleparser/ruleparser.types";

import AddOrphanKeysForm from "./addcrosssystemdqform/addorphankeysform";
import { GetAddRuleFormContentProps } from "./addruleform.types";

type RuleFormContent = {
  [key in RuleTypeId]: React.ComponentType<GetAddRuleFormContentProps>;
};

const addRuleFormContent: RuleFormContent = {
  DAF: (props: GetAddRuleFormContentProps) => <AddDataFormatForm {...props} />,
  BLK: (props: GetAddRuleFormContentProps) => <AddBlanksForm {...props} />,
  VLR: (props: GetAddRuleFormContentProps) => <AddValueRangeForm {...props} />,
  UNQ: (props: GetAddRuleFormContentProps) => <AddUniquenessForm {...props} />,
  UNV: (props: GetAddRuleFormContentProps) => (
    <AddUniqueValuesForm {...props} />
  ),
  CUQ: (props: GetAddRuleFormContentProps) => <AddCustomQueryForm {...props} />,
  CNT: (props: GetAddRuleFormContentProps) => <AddCountForm {...props} />,
  FRC: (props: GetAddRuleFormContentProps) => <AddFreshnessForm {...props} />,
  MET: (props: GetAddRuleFormContentProps) => <AddMetricForm {...props} />,
  ORR: (props: GetAddRuleFormContentProps) => (
    <AddOrphanRecordsForm {...props} />
  ),
  ORK: (props: GetAddRuleFormContentProps) => <AddOrphanKeysForm {...props} />,
  CMM: (props: GetAddRuleFormContentProps) => (
    <AddCompareMetricForm {...props} />
  ),
  CMS: (props: GetAddRuleFormContentProps) => (
    <AddCompareSchemaForm {...props} />
  ),
  MDR: (props: GetAddRuleFormContentProps) => <AddRulesetForm {...props} />,
  NONE: () => <div />,
};

export const getAddRuleFormContent = (
  props: GetAddRuleFormContentProps
): JSX.Element => {
  const { formId = "NONE", isPageLoading } = props;

  const FormComponent = addRuleFormContent[formId];
  return (
    <FormComponent
      {...props}
      key={isPageLoading ? "unmounted-form" : "create-rule-form"}
    />
  );
};
