import { useDebounce, useIsFirstRender } from "usehooks-ts";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
  IndexLocationWithAlign,
  ListRange,
  Virtuoso,
  VirtuosoHandle,
} from "react-virtuoso";
// <--- Components Start --->
import {
  QueryBlock,
  QueryBlockListingBlankSlate,
} from "./queryblocklisting.comp";
// <--- Components End --->

// <--- Styles Start --->
import { QueryBlockListingStyled } from "./queryblocklisting.styles";
// <--- Styles End --->

import {
  useGetResultsOfAnalysisQueryBlocks,
  useGetColumnsProfilingOfAnalysisQueryBlocks,
  useGetVerboseLogsOfQueries,
} from "../../../../../../api/analysisservice";

import { QueryBlockListingProps } from "./queryblocklisting.types";

import {
  checkIsViewOnlyChatQuestionPage,
  checkSourceType,
  extractUUIDFromString,
  uniqByKey,
} from "../../../../../../utils";

import { checkIsChatPage } from "../../../../../../utils/ischatpage";
import { queriesToFetchBeforePageLoad } from "../../analysisdetailpagecontentsec.utils";

import { ModalStyled } from "../../../../../../components/modalwrapper/modalwrapper.styles";
import { AnalysisPageQueryBox } from "../../../../analysisdetailpage.types";

import { useGetAppState } from "../../../../../../customhooks";
import { TrainBotFeedbackFormProps } from "../../../trainbotfeedbackform";

const QueryBlockListing = (props: QueryBlockListingProps): JSX.Element => {
  const [heightOfVirtualList, setHeightOfVirtualList] = useState<number>(188);
  const [listOfMountedQueries, setListOfMountedQueries] = useState<{
    [key: string]: boolean;
  }>({});

  const [listOfScrolledQrysIds, setListOfScrolledQrysIds] = useState<string[]>(
    []
  );

  const debouncedListOfScrolledQrysIds = useDebounce(
    listOfScrolledQrysIds,
    500
  );

  const virtuoso = useRef<VirtuosoHandle>(null);
  const isFirstRender = useIsFirstRender();

  const { modal } = useGetAppState();

  const modalProps: TrainBotFeedbackFormProps = (modal?.modalProps ||
    {}) as TrainBotFeedbackFormProps;

  const isChatPage = checkIsChatPage();
  const isViewOnlyChatQuestionPage = checkIsViewOnlyChatQuestionPage();

  const {
    queries = [],
    trainBotFilteredQueries = [],
    handlers,
    hasUserAccessToEdit = false,
    triggerScrollToBttom,
    parsedQueries,
    onSaveAnalysisIsLoading = false,
    onUseSampleQuestionClick,
    trainBotFilter,
    askedByFilter,
    onGuideMeClick,
    isSavedContextsSecVisible = false,
    gatewayId = "",
    analysisSrcTypeId,
    isHiddenMode,
    isAnyQueryMaximized,
    timeFrameFilter = "all",
    isAuthor = false,
  } = props;

  const { isRestAPISource } = checkSourceType(analysisSrcTypeId);

  const {
    onUpdateQueryColumns,
    onUpdateQueryResults,
    onErrorOfQueryBlock,
    onUpdateQueryVerboseLogs,
    onFetchingVerboseLogsError,
  } = handlers || {};

  const {
    queriesToFetchBeforePageLoad: queriesToFetchEarlier = [],
    isAnyOfThemLoadingPrevQueriedData,
  } = queriesToFetchBeforePageLoad(queries, isRestAPISource);

  const queriesToFetch = useMemo(() => {
    const childQryIdsIds = queries
      ?.filter((qry) =>
        debouncedListOfScrolledQrysIds?.includes(qry?.parent_query_id || "")
      )
      ?.flat();

    const negativeFeedbackQueries = queries?.filter(
      (qry) => qry?.user_feedback?.response === "dislike"
    );

    const negativeFeedbackQryIds = negativeFeedbackQueries?.map(
      (qry) => qry?.id
    );

    const childOfNegativeFeedbackQueries = queries
      ?.filter((qry) =>
        negativeFeedbackQryIds?.includes(qry?.parent_query_id || "")
      )
      ?.flat();

    const negativeFeedbackQueriesWithChild = [
      ...negativeFeedbackQueries,
      ...childOfNegativeFeedbackQueries,
    ];

    const queryOpenInFeedbackModal = queries?.filter(
      (qry) => qry?.id === modalProps?.queryItem?.id
    );
    const childQueries = queries?.filter(
      (qry) => qry?.parent_query_id === modalProps?.queryItem?.id
    );

    const queriesOpenInFeedbackModalWithChild = [
      ...queryOpenInFeedbackModal,
      ...childQueries,
    ];

    return uniqByKey(
      isHiddenMode
        ? [...queriesToFetchEarlier, ...negativeFeedbackQueriesWithChild]
        : [
            ...queriesToFetchEarlier,
            ...trainBotFilteredQueries?.slice(0, 8),
            ...trainBotFilteredQueries?.filter((qry) =>
              debouncedListOfScrolledQrysIds?.includes(qry?.id)
            ),
            ...childQryIdsIds,
            ...trainBotFilteredQueries?.slice(-8),
            ...negativeFeedbackQueriesWithChild,
            ...queriesOpenInFeedbackModalWithChild,
          ],
      "id"
    );
  }, [
    trainBotFilteredQueries,
    debouncedListOfScrolledQrysIds,
    queries,
    isRestAPISource,
    modalProps?.queryItem?.id,
    modal?.visible,
    queriesToFetchEarlier,
  ]);

  const urlsOfProfilingResults = useMemo(
    () =>
      queriesToFetch
        ?.filter((query) => query?.qry_prf_results_s3_url)
        ?.map((filQuery) => ({
          url: filQuery?.qry_prf_results_s3_url,
          id: filQuery?.id,
          isLoading: filQuery?.is_loading,
        })),
    [queriesToFetch]
  );

  const urlsOfVerboseS3Logs = useMemo(() => {
    return queriesToFetch
      ?.filter(
        (query) =>
          query?.verbose_s3_url && !query?.results?.verbose_response?.length
      )
      ?.map((filItem) => ({
        url: filItem?.verbose_s3_url,
        id: filItem?.id,
        isLoading: filItem?.is_loading,
      }));
  }, [queriesToFetch]);

  const onChangeIsQueryMounted = useCallback((id) => {
    setListOfMountedQueries((st) => ({
      ...st,
      [id]: true,
    }));
  }, []);

  const allColumnsQueries = useGetColumnsProfilingOfAnalysisQueryBlocks(
    urlsOfProfilingResults,
    onUpdateQueryColumns,
    onErrorOfQueryBlock,
    gatewayId
  );

  const urlsOfSampleDataResults = useMemo(
    () =>
      queriesToFetch
        ?.filter((query) => query?.qry_tmp_results_s3_url)
        ?.map((filQuery) => ({
          url: filQuery?.qry_tmp_results_s3_url,
          id: filQuery?.id,
          isLoading: filQuery?.is_loading,
        }))
        ?.filter((nestFilQuery) => {
          const findedQuery = allColumnsQueries?.find((allColQry) => {
            const id = extractUUIDFromString(
              (allColQry?.data as any)?.config?.url
            );

            return nestFilQuery?.id?.includes(id);
          });
          return (!findedQuery?.isFetching && findedQuery) || isRestAPISource;
        }),
    [queriesToFetch, allColumnsQueries]
  );

  useGetResultsOfAnalysisQueryBlocks(
    urlsOfSampleDataResults,
    onUpdateQueryResults,
    onErrorOfQueryBlock,
    gatewayId
  );

  useGetVerboseLogsOfQueries(
    urlsOfVerboseS3Logs,
    onUpdateQueryVerboseLogs,
    onFetchingVerboseLogsError
  );

  useEffect(() => {
    if (!isFirstRender && !isViewOnlyChatQuestionPage) {
      virtuoso?.current?.scrollToIndex({
        index: queries?.length,
        align: "center",
        behavior: "auto",
      });
    }
  }, [triggerScrollToBttom, isAnyOfThemLoadingPrevQueriedData]);

  const onRangeChanged = useCallback(
    (range: ListRange) => {
      const updRange = {
        startIndex: Math.max(range?.startIndex - 5, 0),
        endIndex: range?.endIndex + 5,
      };

      setListOfScrolledQrysIds((st) => [
        ...st,
        ...trainBotFilteredQueries
          ?.slice(updRange?.startIndex, updRange?.endIndex)
          ?.map((item) => item?.id),
      ]);
    },
    [trainBotFilteredQueries]
  );

  useEffect(() => {
    onRangeChanged({ startIndex: 0, endIndex: 0 });
  }, [queries?.length]);

  const maxQry = useMemo(
    () =>
      trainBotFilteredQueries?.find(
        (qry) => qry?.header?.is_maximize_query_block
      ),
    [trainBotFilteredQueries]
  );

  const queryItemRendrer = (
    queryItemProp: AnalysisPageQueryBox,
    index: number,
    isComingFromMaximize?: boolean
  ): JSX.Element => (
    <QueryBlock
      queryItem={
        //now max query opening in modal, so incase of listing passing is_maximize_query_block false
        isComingFromMaximize
          ? queryItemProp
          : {
              ...queryItemProp,
              header: {
                ...queryItemProp?.header,
                is_maximize_query_block: false,
              },
            }
      }
      handlers={handlers}
      key={`query-block-${queryItemProp?.id}`}
      hasUserAccessToEdit={hasUserAccessToEdit}
      parsedQueries={parsedQueries}
      isQueryMounted={listOfMountedQueries?.[queryItemProp?.id]}
      onChangeIsQueryMounted={onChangeIsQueryMounted}
      onSaveAnalysisIsLoading={onSaveAnalysisIsLoading}
      index={index}
      onUseSampleQuestionClick={onUseSampleQuestionClick}
      isSavedContextsSecVisible={isSavedContextsSecVisible}
      totalItems={trainBotFilteredQueries?.length}
      queries={queries}
      analysisSrcTypeId={analysisSrcTypeId}
      isAuthor={isAuthor}
      gatewayId={gatewayId}
    />
  );

  return (
    <>
      {!trainBotFilteredQueries?.length ? (
        <QueryBlockListingBlankSlate
          filter={trainBotFilter}
          onGuideMeClick={onGuideMeClick}
          askedByFilter={askedByFilter}
          analysisSrcTypeId={analysisSrcTypeId}
          timeFrameFilter={timeFrameFilter}
        />
      ) : (
        <QueryBlockListingStyled
          height={heightOfVirtualList}
          isChatPage={isChatPage}
        >
          <ModalStyled
            visible={
              isAnyQueryMaximized && !!maxQry && !isViewOnlyChatQuestionPage
            }
            isHideHeader
            width="100vw"
            isFullScreenModal
            destroyOnClose
            wrapClassName="caddi-maximized-qry-modal"
            mask={false}
          >
            {maxQry && queryItemRendrer(maxQry, 1, true)};
          </ModalStyled>
          <Virtuoso
            data={trainBotFilteredQueries}
            totalCount={trainBotFilteredQueries?.length}
            useWindowScroll
            ref={virtuoso}
            height={heightOfVirtualList}
            totalListHeightChanged={(listHeight): void => {
              setHeightOfVirtualList(listHeight + 210);
            }}
            itemContent={(index, query): JSX.Element => {
              return queryItemRendrer(query, index);
            }}
            initialTopMostItemIndex={{
              align: "end",
              index: trainBotFilteredQueries?.length - 1,
              offset: 5000,
            }}
            rangeChanged={onRangeChanged}
            className="virtuoso"
            data-testid="analysis-detail-page-query-blocks-listing-virtual-scrolling-main-outer-container"
            // increaseViewportBy={1000}
          />
        </QueryBlockListingStyled>
      )}
    </>
  );
};

export default QueryBlockListing;
