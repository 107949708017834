import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";

import { useCancelModal, useGetAppState } from "../../../../customhooks";
import { filterQueryFiltersWhereFilterModelsExists } from "../../../../pages/analysisdetailpage/analisisdetailpage.utils";

import {
  QueryBlockGridFilter,
  QueryBoxFiltersType,
} from "../../../../pages/analysisdetailpage/analysisdetailpage.types";

import {
  transformColumnToFilterModel,
  transformFilterModelToListOfFilters,
} from "../../../../pages/analysisdetailpage/analysisdetailpage.views/analysisdetailpagecontentsec/analysisdetailpagecontentsec.views/queryblocklisting/queryblocklisting.comp/queryblock/queryblock.comp/queryblock.comp.utils";

import { TransformedFilterFromFilterModelType } from "../../../../pages/analysisdetailpage/analysisdetailpage.views/analysisdetailpagecontentsec/analysisdetailpagecontentsec.views/queryblocklisting/queryblocklisting.comp/queryblock/queryblock.types";
import { ColumnProfilingInfo } from "../../../../parsers";

import { createUUID, getObjectKeys } from "../../../../utils";
import { Button } from "../../../button/button.component";

import { FormItemStyled, FormStyled } from "../../../form";
import { CheckboxField } from "../../../formfields";

import { SSAAddQueryModalStyled } from "./ssaaddquerymodal.styles";
import { SSAAddQueryModalProps } from "./ssaaddquerymodal.types";

const SSAAddQueryModal = (): JSX.Element => {
  const onCancel = useCancelModal();
  const { modal } = useGetAppState();

  const {
    table = "",
    columns = [],
    filters = [],
    onAddQueryClick,
  } = (modal?.modalProps || {}) as SSAAddQueryModalProps;

  const listOfTransformedFilters: Array<
    TransformedFilterFromFilterModelType & { id: string }
  > = useMemo(() => {
    const filtersListWhereFilterModelsExist =
      filters?.map((filter: QueryBoxFiltersType) =>
        filterQueryFiltersWhereFilterModelsExists(filter || {})
          ?.map((filterWithModel: QueryBlockGridFilter) =>
            transformFilterModelToListOfFilters(filterWithModel || {})
          )
          ?.flat()
      ) || [];

    return (
      filtersListWhereFilterModelsExist?.flat()?.map((filterWithModel) => ({
        ...filterWithModel,
        id: createUUID(),
      })) || []
    );
  }, [filters]);

  const onSubmit = useCallback((formValues: { [key: string]: boolean }) => {
    const selectedTransformedFilters =
      listOfTransformedFilters?.filter((filter) =>
        getObjectKeys(formValues)
          ?.filter((formValue) => formValues[formValue])
          ?.includes(filter?.id || "")
      ) || [];

    const selectedColumnsWithValues =
      selectedTransformedFilters?.map((filter) => ({
        value: filter?.val || "",
        column:
          columns?.filter(
            (column: ColumnProfilingInfo) =>
              column?.column_name === filter?.org_col_name
          )?.[0] || {},
      })) || [];

    const filtersWithModals: Array<QueryBlockGridFilter> =
      selectedColumnsWithValues?.map((item) =>
        transformColumnToFilterModel(
          item?.column,
          {
            col_data_type: "STR",
            col_display_name: "",
            colm_name: "",
            operator: "",
          },
          item?.value?.replace(" - ", ",")
        )
      ) || [];

    const setTypeFilters: Array<QueryBlockGridFilter> =
      filtersWithModals?.filter(
        (filter: QueryBlockGridFilter) =>
          filter?.filter_model?.filterType === "set"
      ) || [];

    const nonSetTypeFilters: Array<QueryBlockGridFilter> =
      filtersWithModals?.filter(
        (filter: QueryBlockGridFilter) =>
          filter?.filter_model?.filterType !== "set"
      ) || [];

    const combineSetTypeFiltersWithCol = [
      ...new Set(setTypeFilters?.map((filter) => filter?.colm_name) || []),
    ]?.map((colName: string) => {
      const filteredSetTypeFilters =
        setTypeFilters?.filter(
          (item: QueryBlockGridFilter) => item?.colm_name === colName
        ) || [];

      return {
        ...(filteredSetTypeFilters?.[0] || {}),
        filter_model: {
          ...(filteredSetTypeFilters?.[0]?.filter_model || {}),
          values: (
            filteredSetTypeFilters?.map(
              (item) => item?.filter_model?.values || []
            ) || []
          )?.flat(),
        },
      };
    });

    const mergedFilters: Array<QueryBlockGridFilter> = [
      ...nonSetTypeFilters,
      ...combineSetTypeFiltersWithCol,
    ];

    const transformedMergedFilters: QueryBoxFiltersType =
      mergedFilters?.reduce(
        (prev: QueryBoxFiltersType, next: QueryBlockGridFilter) => ({
          ...prev,
          [next?.colm_name || ""]: next || {},
        }),
        {}
      ) || {};

    onAddQueryClick(transformedMergedFilters);
    onCancel();
  }, []);

  const { control, handleSubmit, watch } = useForm<{
    [key: string]: string;
  }>({
    mode: "onChange",
  });

  const watchedFormValues = watch();

  const isAddQueryBtnDisabled = useMemo(() => {
    const selectedIdsList = getObjectKeys(watchedFormValues)?.filter(
      (id) => watchedFormValues?.[id]
    );
    const selectedTransformedFilters = listOfTransformedFilters?.filter(
      (transformedFilter) => selectedIdsList?.includes(transformedFilter?.id)
    );

    const nonSetTypeSelectedTransformedFilters = selectedTransformedFilters
      ?.filter((filter) => filter?.operator !== "Includes")
      .map((filter) => filter?.org_col_name);

    const isDuplicateFilterName = nonSetTypeSelectedTransformedFilters?.some(
      (item, index, arr) => arr?.indexOf(item) !== index
    );

    return isDuplicateFilterName;
  }, [watchedFormValues]);

  return (
    <SSAAddQueryModalStyled>
      <FormStyled
        isItemColumnLayout
        onFinish={handleSubmit(onSubmit) as any}
        paddingLeft="80px"
        paddingRight="80px"
      >
        <div className="form-wrapper">
          <FormItemStyled marginTop="32px">
            Your filters from existing <span className="bold">{table}</span> can
            be applied on this query as well. Select the filters below that you
            want to apply on this query
          </FormItemStyled>

          {listOfTransformedFilters?.map((filter, index) => (
            <FormItemStyled key={index} className="checkbox-wrapper">
              <CheckboxField name={filter?.id} control={control}>
                <div className="checkbox-label">
                  {filter?.name}: {filter?.operator}{" "}
                  <span className="bold">{filter?.val}</span>
                </div>
              </CheckboxField>
            </FormItemStyled>
          ))}
        </div>

        <FormItemStyled
          label=""
          className="form-actions-sec"
          marginBottom="0px"
        >
          <Button id="cancel" width="74px" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            id="primary"
            width="74px"
            marginLeft="8px"
            htmlType="submit"
            disabled={isAddQueryBtnDisabled}
            tooltipProps={{
              title: isAddQueryBtnDisabled
                ? "You can't apply multiple range filters on same column"
                : "",
              placement: "right",
            }}
          >
            Add Query
          </Button>
        </FormItemStyled>
      </FormStyled>
    </SSAAddQueryModalStyled>
  );
};

export default SSAAddQueryModal;
