import styled from "styled-components";

export const TrainBotFeedbackTrainMeSecStyled = styled.div`
  .train-me-detail-sec {
    display: flex;
    column-gap: 19px;
    min-height: 443px;

    .train-me-left {
      display: flex;
      flex-direction: column;
      flex: 1;

      .train-me-generated-query {
        flex: 1;
      }

      .train-me-sec-heading {
        font-size: 24px;
        font-size: 19.2px;
        color: ${({ theme }): string => theme?.textColors?.BLUE_116};
        font-family: OpenSansSemibold;
      }

      .train-me-sec-info {
        color: ${({ theme }): string => theme?.textColors?.GREY_26};
        margin-bottom: 19.2px;
      }
    }

    .train-me-right {
      background-color: ${({ theme }): string =>
        theme?.bgColors?.LIGHT_GREY_22};
      flex: 1;
      padding: 22.4px 19px;

      .train-me-info-text {
        color: ${({ theme }): string => theme?.textColors?.GREY_26};
        font-style: italic;
        margin-bottom: 19px;
      }
    }
  }
`;

export const FieldItemStyled = styled.div`
  width: 100%;

  .train-me-field-label {
    font-family: OpenSansSemibold;
    margin-bottom: 8px;
    color: ${({ theme }): string => theme?.textColors?.BLACK_COLOR};
  }

  .textarea-field-container {
    width: 100%;
  }
`;
