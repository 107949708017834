import styled, { createGlobalStyle } from "styled-components";
import { hexToRgbA } from "../../../../../../utils";

export const EditableGridRowStyled = styled.div<{
  isClassificationTagset: boolean;
}>`
  display: grid;
  grid-template-columns: ${({ isClassificationTagset }): string =>
    isClassificationTagset
      ? "0.8fr 1fr 0.4fr 0.4fr 0.3fr"
      : "0.8fr 1fr 0.4fr 0.3fr"};
  border-bottom: 1px solid
    ${({ theme }): string => hexToRgbA(theme.bgColors.LIGHT_GREY_11, 0.1)};

  &:last-child {
    border-bottom: none;
  }

  .column:last-child {
    border: none;
  }
`;

export const SelectFieldGlobalStyle = createGlobalStyle`
.multiselect-dropdown-dvsum {
      padding: 0px;
      .rc-virtual-list-holder {
        
        .ant-select-item-option {
          padding: 5px 15px;
          color: #2c2c2c;
          font-size: 13px;
        }

        .ant-select-item-group:not(:first-of-type)::before {
          margin: unset;
        }

        .ant-select-item-option-active,
        .ant-select-item-option-selected {
          background: #eff4f7;
        }
        .ant-select-item-option-selected {
          .ant-select-item-option-state {
            display: none;
          }
        }
        .ant-select-item-option-disabled {
          opacity: 0.5;
        }
      }
    }
`;
