import { NodeResponseTagsetConfig } from "../parsers/parser.types";
import { getObjectKeys } from "./getobjectkeys";

export function getTagsetConfigWithNameKey(
  tagsetConfig: NodeResponseTagsetConfig
): NodeResponseTagsetConfig {
  return getObjectKeys(tagsetConfig)?.reduce(
    (prev, next) => ({
      ...prev,
      [tagsetConfig?.[next]?.tagset_name]: tagsetConfig?.[next],
    }),
    {}
  );
}
