import { useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { ContentPreviewer } from "../../../../../../components";
import { TrainBotFeedbackFormProps } from "../../trainbotfeedbackform.types";

import { TrainbotFeedBackFormInputResponseStyled } from "../trainbotfeedbackforminputsec/trainbotfeedbackforminputsec.styles";
import { QueryResultsChart, QueryResultsGrid } from "../../..";

import { TrainbotFeedbackFormResponseForm } from "../../trainbotfeedbackform";
import Flex from "../../../../../../components/flex";

import Conditionalwrapper from "../../../../../../components/conditionalwrapper";
import { useGetCaddiNegFeedbackProps } from "../../../../useGetCaddiNegFeedbackProps";
import { GatewayStatusCheckingWrapper } from "../../../analysisdetailpagecontentsec/analysisdetailpagecontentsec.components";
import StateHandler from "../../../../../../components/statehandler";

const TrainbotFeedbackFormResponseSec = (
  props: TrainBotFeedbackFormProps
): JSX.Element => {
  const {
    handlers,
    itemToVisualize,
    queryItem,
    srcTypeId = "SNF",
    gatewayId = "",
  } = props;

  const {
    defSortKey = "",
    defSortdirection = "asc",
    shouldDoDefaultSorting = false,
    gridDefaultSortModel,
    alreadyPercFrmtedCols = [],
  } = useGetCaddiNegFeedbackProps(itemToVisualize || queryItem, handlers);

  const showLoading =
    itemToVisualize?.is_loading &&
    itemToVisualize?.loader_type === "reloading_prev_queried_data";

  const sqlQuery = queryItem?.query?.sql_query || "";
  const { results, settings } = itemToVisualize || {};

  const { chart_config: chartConfig } = results || {};

  const { chart_type: chartType } = chartConfig || {};
  const isChartExists = chartType && chartType !== "N/A";

  const { prefrences = {}, is_data_formatted: isDataFormatted } =
    settings || {};

  const { watch } = useFormContext<TrainbotFeedbackFormResponseForm>();
  const { reason: watchedReason } = watch();

  const previewSecHeading = useMemo(
    () =>
      watchedReason !== "WV"
        ? "Generated Response"
        : isChartExists
        ? "Generated Chart"
        : "Generated Grid",
    [watchedReason, isChartExists]
  );

  const previewSec = useMemo(() => {
    if (watchedReason === "WV") {
      if (chartConfig && isChartExists) {
        return (
          <GatewayStatusCheckingWrapper gatewayId={gatewayId} height="300px">
            <StateHandler
              isFetching={showLoading}
              showSpinner={showLoading}
              error={false}
              spinnerHeight="400px"
            >
              <Conditionalwrapper
                condition={!!itemToVisualize?.results?.sec_title}
                wrapper={(child): JSX.Element => (
                  <div className="visualize-sub-box">
                    <div className="sub-heading">
                      {itemToVisualize?.results?.sec_title}
                    </div>
                    {child}
                  </div>
                )}
              >
                <QueryResultsChart
                  isChartSettingsVisible={false}
                  isDataFormatted={!!isDataFormatted}
                  isChatPage={false}
                  chartConfig={chartConfig}
                  prefrences={prefrences}
                  defSortKey={defSortKey}
                  defSortdirection={defSortdirection}
                  shouldDoDefaultSorting={shouldDoDefaultSorting}
                  alreadyPercFrmtedCols={alreadyPercFrmtedCols}
                />
              </Conditionalwrapper>
            </StateHandler>
          </GatewayStatusCheckingWrapper>
        );
      }

      return (
        <GatewayStatusCheckingWrapper gatewayId={gatewayId} height="300px">
          <StateHandler
            isFetching={showLoading}
            showSpinner={showLoading}
            error={false}
            spinnerHeight="400px"
          >
            <Conditionalwrapper
              condition={!!itemToVisualize?.results?.sec_title}
              wrapper={(child): JSX.Element => (
                <div className="visualize-sub-box">
                  <div className="sub-heading">
                    {itemToVisualize?.results?.sec_title}
                  </div>
                  {child}
                </div>
              )}
            >
              <QueryResultsGrid
                queryItem={itemToVisualize || queryItem}
                handlers={{
                  ...handlers,
                  onChangeQuerySettings: (): void => {},
                  // onChangeQueryColumnSorting: (): void => {},
                }}
                isFiltersExist={false}
                defaultSortModel={gridDefaultSortModel}
                analysisSrcTypeId={srcTypeId}
              />
            </Conditionalwrapper>
          </StateHandler>
        </GatewayStatusCheckingWrapper>
      );
    }

    return sqlQuery ? (
      <ContentPreviewer
        sqlQuery={sqlQuery}
        showCrossIcon={false}
        title="Query"
      />
    ) : (
      <Flex height="100%" justifyContent="center" customClass="train-me-no-sql">
        No SQL response exists.
      </Flex>
    );
  }, [
    isDataFormatted,
    prefrences,
    sqlQuery,
    watchedReason,
    isChartExists,
    chartConfig,
    showLoading,
  ]);

  return (
    <TrainbotFeedBackFormInputResponseStyled className="train-me-generated-query">
      <div className="train-bot-feedback-form-input-res-title">
        {previewSecHeading}
      </div>

      {previewSec}
    </TrainbotFeedBackFormInputResponseStyled>
  );
};

export default TrainbotFeedbackFormResponseSec;
