import styled, { css } from "styled-components";
import { ellipsis } from "../../styles/app.styles";
import { hexToRgbA } from "../../utils";

const flexCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HeaderDropdownMenuStyled = styled.div`
  .my-settings-menu-item {
    display: flex;
    justify-content: space-between;

    .filter-icon-sec {
      display: flex;
      position: relative;
      align-items: center;

      .filter-count {
        position: absolute;
        top: 0;
        right: -7px;
        font-size: 6.4px;
        background: ${(props): string => props.theme.bgColors.BLUE_116};
        color: ${(props): string => props.theme.textColors.WHITE_COLOR};
        border-radius: 50%;
        height: 11px;
        width: 12px;
        text-align: center;
      }
    }
  }
`;

export const HeaderStyled = styled.div<{ isOnboardingMode: boolean }>`
  height: 45px;
  padding: 0 0px 0px 10px;
  ${flexCss};
  justify-content: space-between;
  color: white;
  background: ${(props): string => props.theme.bgColors.PRIMARY_BG_COLOR};
  position: fixed;
  width: 100%;
  z-index: ${({ isOnboardingMode }): number => (isOnboardingMode ? 11 : 10)};
  .left-sec {
    padding: 15px 12px;
    min-width: 363px;
    .logo-link {
      display: flex;
      align-items: center;
      color: ${(props): string => props.theme.textColors.WHITE_COLOR};
      font-size: 24px;
      img {
        width: 30px;
        height: 16.7px;
        margin-right: 5px;
      }
      div {
        font-size: 85%;
        padding-bottom: 4px;
      }
    }
  }

  .right-sec {
    display: flex;
    align-items: center;

    > button {
      color: ${(props): string => props.theme.WHITE_COLOR};
      height: 38px;
      border: none;
      border-left: 1px solid
        ${(props): string => props.theme.borderColors.LIGHT_BLUE_6};
      background: ${(props): string => props.theme.bgColors.PRIMARY_BG_COLOR};
      padding-top: 0px;
      padding-bottom: 0px;

      &:last-child {
        padding-right: 0px;
      }

      svg {
        vertical-align: middle;
      }
    }

    .help-center-icon {
      cursor: pointer;
      border-left: 1px solid
        ${(props): string => props.theme.borderColors.WHITE_COLOR};
      padding: 0px 15px;
      height: 45px;
      ${flexCss}
      column-gap: 4.8px;
      svg {
        width: 22px;
        height: 22px;
      }

      :hover {
        background: ${hexToRgbA("#2e6589", 0.5)};
      }
    }

    .more-options {
      min-width: 95px;
      cursor: pointer;
      border-left: 1px solid
        ${(props): string => props.theme.borderColors.WHITE_COLOR};
      padding: 9px 10px;
      background: #62a8d1;
      position: relative;
      font-size: 12px;
      height: 45px;

      .welcome-text {
        line-height: 1;
      }

      .first-name-sec {
        font-size: 13px;
        max-width: 150px;
        ${ellipsis}
      }

      .dropdown--direction-icon {
        position: absolute;
        right: 10px;
        top: 15px;

        svg {
          width: 8px;
          height: 13px;
        }
      }

      :hover {
        background: ${hexToRgbA("#62a8d1", 0.5)};
      }
    }
  }
`;

export const MenuStyled = styled.div`
  width: 158px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #fff;
  padding: 5px 0px;
  font-size: 16px;
  color: ${(props): string => props.theme.textColors.BLACK_13};
  > div:not(.divider) {
    line-height: unset;
    padding: 4px 12px;
    cursor: pointer;

    :hover {
      background: ${(props): string => props.theme.bgColors.BLUE_23};
    }
  }

  .divider {
    height: 1px;
    margin: 9px 0px;
    background: ${(props): string => props.theme.bgColors.GREY_50};
  }
`;
