import { calculatePercentageFromTotal } from ".";
import { ProgressBarItem } from "../components/multistatusprogressbar/multistatusprogressbar.types";

export function getProfilingInfo(
  totalCount: number,
  distinctCount: number,
  blankCount: number
): ProgressBarItem[] {
  return [
    {
      type: "error",
      value: calculatePercentageFromTotal(blankCount || 0, totalCount || 0),
      valueDetail: `${blankCount || 0}`,
    },
    {
      type: "warning",
      value: calculatePercentageFromTotal(distinctCount || 0, totalCount || 0),
      valueDetail: `${distinctCount || 0}`,
    },
    {
      type: "success",
      value: calculatePercentageFromTotal(
        (totalCount || 0) - (distinctCount || 0) - (blankCount || 0),
        totalCount
      ),
      valueDetail: `${
        (totalCount || 0) - (distinctCount || 0) - (blankCount || 0)
      }`,
    },
  ];
}
