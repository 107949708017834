import moment from "moment";
import { MOMENT_DATE_FORMATS } from "../../constants";

export type DateFormat = keyof typeof MOMENT_DATE_FORMATS;

export const getCurrentFormattedDate = (format: DateFormat): string => {
  const today = new Date();
  return moment?.(today)?.format(MOMENT_DATE_FORMATS?.[format]);
};

export const getFormattedDate = (date: string, format: DateFormat): string => {
  try {
    return moment?.(date)?.format(MOMENT_DATE_FORMATS?.[format]);
  } catch (e) {
    return "";
  }
};
