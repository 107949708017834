import { useMemo } from "react";
import { SingleSelectionPopup, Button } from "../../../../../../components";
import { GuidelinesActionBarStyled } from "./guidelinesactionbar.styles";
import { GuidelinesActionBarProps } from "./guidelinesactionbar.types";
import { circleTickSvg, toReviewSvg, bulbIcon } from "../../../../../../svgs";
import { ELEMENT_IDS } from "../../../../../../constants";
import { BotGuidelinesTypeFilterType } from "../chatbotsection/chatbotsection.types";
import { VerticalDividerStyled } from "../../../../../../components/dividers/dividers.styles";

const allStatusIcon = circleTickSvg("20", "20");
const feedbacksIcon = toReviewSvg("21", "21");
const conceptIcon = bulbIcon("14", "20");

const {
  chat_bot_guidelines_types_fil: CHAT_BOT_GUIDELINES_TYPES_FIL,
  chat_bot_guidelines_types_fil_cnt: CHAT_BOT_GUIDELINES_TYPES_FIL_CNT,
} = ELEMENT_IDS;

const GuidelinesActionBar = (props: GuidelinesActionBarProps): JSX.Element => {
  const {
    allGuidelinesLength = 0,
    conceptsLength = 0,
    feedbacksLength = 0,
    selectedTypeFilter = "all",
    onChangeTypeFilter,
    onAddGuidelineClick,
  } = props;

  const typeFilters = useMemo(() => {
    const options: Array<{
      label: string;
      count: number;
      value: BotGuidelinesTypeFilterType;
      icon?: JSX.Element;
    }> = [
      {
        label: "All",
        value: "all",
        count: allGuidelinesLength,
        icon: (
          <div className="status-filter-item-icon stroke">{allStatusIcon}</div>
        ),
      },
      {
        label: "Concepts",
        value: "concept",
        count: conceptsLength,
        icon: (
          <div className="status-filter-item-icon stroke">{conceptIcon}</div>
        ),
      },
      {
        label: "Feedback",
        value: "feedback",
        count: feedbacksLength,
        icon: (
          <div className="status-filter-item-icon fill">{feedbacksIcon}</div>
        ),
      },
    ];

    return options?.filter((item) => item?.count > 0);
  }, [allGuidelinesLength, conceptsLength, feedbacksLength]);

  return (
    <GuidelinesActionBarStyled>
      {!!allGuidelinesLength && (
        <>
          <SingleSelectionPopup
            title="Type"
            options={typeFilters}
            selectedOption={selectedTypeFilter}
            onChange={onChangeTypeFilter}
            titleId={CHAT_BOT_GUIDELINES_TYPES_FIL}
            contentId={CHAT_BOT_GUIDELINES_TYPES_FIL_CNT}
          />
          <VerticalDividerStyled
            height="9px"
            marginLeft="12px"
            marginRight="12px"
          />
          <Button
            onClick={onAddGuidelineClick}
            elementId={ELEMENT_IDS.cht_bot_trng_add_cnp_btn}
          >
            Add Concept
          </Button>
        </>
      )}
    </GuidelinesActionBarStyled>
  );
};

export default GuidelinesActionBar;
