export const tableViewBlankSlate = (
  <svg width="100%" viewBox="0 0 618 1783" fill="none">
    <rect x="0.5" y="0.5" width="291.667" height="96" fill="#F6F6F6" />
    <rect x="12" y="20" width="159" height="17" rx="8.5" fill="#E8E8E8" />
    <rect x="12" y="45" width="265" height="14" rx="7" fill="#E8E8E8" />
    <rect x="0.5" y="0.5" width="291.667" height="96" stroke="#E6E6E6" />
    <rect x="292.166" y="0.5" width="291.667" height="96" fill="#F6F6F6" />
    <rect x="303.666" y="20" width="159" height="17" rx="8.5" fill="#E8E8E8" />
    <rect x="303.666" y="45" width="265" height="14" rx="7" fill="#E8E8E8" />
    <rect x="292.166" y="0.5" width="291.667" height="96" stroke="#E6E6E6" />
    <rect x="583.834" y="0.5" width="291.667" height="96" fill="#F6F6F6" />
    <rect x="595.334" y="20" width="159" height="17" rx="8.5" fill="#E8E8E8" />
    <rect x="595.334" y="45" width="265" height="14" rx="7" fill="#E8E8E8" />
    <rect x="583.834" y="0.5" width="291.667" height="96" stroke="#E6E6E6" />
    <rect width="876" height="9" transform="translate(0 96)" fill="#F0F0F0" />
    <rect
      x="0.5"
      y="96.5"
      width="97.2222"
      height="8"
      fill="#E8E8E8"
      stroke="#E6E6E6"
    />
    <rect
      x="97.7227"
      y="96.5"
      width="97.2222"
      height="8"
      fill="#E8E8E8"
      stroke="#E6E6E6"
    />
    <rect
      x="194.945"
      y="96.5"
      width="97.2222"
      height="8"
      fill="#E8E8E8"
      stroke="#E6E6E6"
    />
    <rect
      x="292.166"
      y="96.5"
      width="97.2222"
      height="8"
      fill="#E8E8E8"
      stroke="#E6E6E6"
    />
    <rect
      x="389.389"
      y="96.5"
      width="97.2222"
      height="8"
      fill="#E8E8E8"
      stroke="#E6E6E6"
    />
    <rect
      x="486.611"
      y="96.5"
      width="97.2222"
      height="8"
      fill="#E8E8E8"
      stroke="#E6E6E6"
    />
    <rect
      x="583.834"
      y="96.5"
      width="97.2222"
      height="8"
      fill="#E8E8E8"
      stroke="#E6E6E6"
    />
    <rect
      x="681.057"
      y="96.5"
      width="97.2222"
      height="8"
      fill="#E8E8E8"
      stroke="#E6E6E6"
    />
    <rect
      x="778.279"
      y="96.5"
      width="97.2222"
      height="8"
      fill="#E8E8E8"
      stroke="#E6E6E6"
    />
    <rect x="0.5" y="104.5" width="291.667" height="108.5" fill="#F6F6F6" />
    <rect x="0.5" y="104.5" width="291.667" height="108.5" stroke="#E6E6E6" />
    <rect x="292.166" y="104.5" width="291.667" height="108.5" fill="#F6F6F6" />
    <rect
      x="292.166"
      y="104.5"
      width="291.667"
      height="108.5"
      stroke="#E6E6E6"
    />
    <rect x="583.834" y="104.5" width="291.667" height="108.5" fill="#F6F6F6" />
    <rect
      x="583.834"
      y="104.5"
      width="291.667"
      height="108.5"
      stroke="#E6E6E6"
    />
    <mask id="path-22-inside-1_1221_11762" fill="white">
      <path d="M0 212.5H292.667V261.5H0V212.5Z" />
    </mask>
    <path d="M0 212.5H292.667V261.5H0V212.5Z" fill="white" />
    <rect x="12" y="228.5" width="159" height="17" rx="8.5" fill="#F0F0F0" />
    <path
      d="M0 212.5V211.5H-1V212.5H0ZM0 261.5H-1V262.5H0V261.5ZM0 213.5H292.667V211.5H0V213.5ZM292.667 260.5H0V262.5H292.667V260.5ZM1 261.5V212.5H-1V261.5H1Z"
      fill="#E8E8E8"
      mask="url(#path-22-inside-1_1221_11762)"
    />
    <mask id="path-25-inside-2_1221_11762" fill="white">
      <path d="M291.666 212.5H584.333V261.5H291.666V212.5Z" />
    </mask>
    <path d="M291.666 212.5H584.333V261.5H291.666V212.5Z" fill="white" />
    <rect
      x="303.666"
      y="228.5"
      width="159"
      height="17"
      rx="8.5"
      fill="#F0F0F0"
    />
    <path
      d="M291.666 212.5V211.5H290.666V212.5H291.666ZM291.666 261.5H290.666V262.5H291.666V261.5ZM291.666 213.5H584.333V211.5H291.666V213.5ZM584.333 260.5H291.666V262.5H584.333V260.5ZM292.666 261.5V212.5H290.666V261.5H292.666Z"
      fill="#E8E8E8"
      mask="url(#path-25-inside-2_1221_11762)"
    />
    <mask id="path-28-inside-3_1221_11762" fill="white">
      <path d="M583.334 212.5H876.001V261.5H583.334V212.5Z" />
    </mask>
    <path d="M583.334 212.5H876.001V261.5H583.334V212.5Z" fill="white" />
    <rect
      x="595.334"
      y="228.5"
      width="159"
      height="17"
      rx="8.5"
      fill="#F0F0F0"
    />
    <path
      d="M583.334 212.5V211.5H582.334V212.5H583.334ZM876.001 212.5H877.001V211.5H876.001V212.5ZM876.001 261.5V262.5H877.001V261.5H876.001ZM583.334 261.5H582.334V262.5H583.334V261.5ZM583.334 213.5H876.001V211.5H583.334V213.5ZM875.001 212.5V261.5H877.001V212.5H875.001ZM876.001 260.5H583.334V262.5H876.001V260.5ZM584.334 261.5V212.5H582.334V261.5H584.334Z"
      fill="#E8E8E8"
      mask="url(#path-28-inside-3_1221_11762)"
    />
    <mask id="path-31-inside-4_1221_11762" fill="white">
      <path d="M0 260.5H292.667V309.5H0V260.5Z" />
    </mask>
    <path d="M0 260.5H292.667V309.5H0V260.5Z" fill="white" />
    <rect x="12" y="276.5" width="159" height="17" rx="8.5" fill="#F0F0F0" />
    <path
      d="M0 260.5V259.5H-1V260.5H0ZM0 309.5H-1V310.5H0V309.5ZM0 261.5H292.667V259.5H0V261.5ZM292.667 308.5H0V310.5H292.667V308.5ZM1 309.5V260.5H-1V309.5H1Z"
      fill="#E8E8E8"
      mask="url(#path-31-inside-4_1221_11762)"
    />
    <mask id="path-34-inside-5_1221_11762" fill="white">
      <path d="M291.666 260.5H584.333V309.5H291.666V260.5Z" />
    </mask>
    <path d="M291.666 260.5H584.333V309.5H291.666V260.5Z" fill="white" />
    <rect
      x="303.666"
      y="276.5"
      width="159"
      height="17"
      rx="8.5"
      fill="#F0F0F0"
    />
    <path
      d="M291.666 260.5V259.5H290.666V260.5H291.666ZM291.666 309.5H290.666V310.5H291.666V309.5ZM291.666 261.5H584.333V259.5H291.666V261.5ZM584.333 308.5H291.666V310.5H584.333V308.5ZM292.666 309.5V260.5H290.666V309.5H292.666Z"
      fill="#E8E8E8"
      mask="url(#path-34-inside-5_1221_11762)"
    />
    <mask id="path-37-inside-6_1221_11762" fill="white">
      <path d="M583.334 260.5H876.001V309.5H583.334V260.5Z" />
    </mask>
    <path d="M583.334 260.5H876.001V309.5H583.334V260.5Z" fill="white" />
    <rect
      x="595.334"
      y="276.5"
      width="159"
      height="17"
      rx="8.5"
      fill="#F0F0F0"
    />
    <path
      d="M583.334 260.5V259.5H582.334V260.5H583.334ZM876.001 260.5H877.001V259.5H876.001V260.5ZM876.001 309.5V310.5H877.001V309.5H876.001ZM583.334 309.5H582.334V310.5H583.334V309.5ZM583.334 261.5H876.001V259.5H583.334V261.5ZM875.001 260.5V309.5H877.001V260.5H875.001ZM876.001 308.5H583.334V310.5H876.001V308.5ZM584.334 309.5V260.5H582.334V309.5H584.334Z"
      fill="#E8E8E8"
      mask="url(#path-37-inside-6_1221_11762)"
    />
    <mask id="path-40-inside-7_1221_11762" fill="white">
      <path d="M0 308.5H292.667V357.5H0V308.5Z" />
    </mask>
    <path d="M0 308.5H292.667V357.5H0V308.5Z" fill="white" />
    <rect x="12" y="324.5" width="159" height="17" rx="8.5" fill="#F0F0F0" />
    <path
      d="M0 308.5V307.5H-1V308.5H0ZM0 357.5H-1V358.5H0V357.5ZM0 309.5H292.667V307.5H0V309.5ZM292.667 356.5H0V358.5H292.667V356.5ZM1 357.5V308.5H-1V357.5H1Z"
      fill="#E8E8E8"
      mask="url(#path-40-inside-7_1221_11762)"
    />
    <mask id="path-43-inside-8_1221_11762" fill="white">
      <path d="M291.666 308.5H584.333V357.5H291.666V308.5Z" />
    </mask>
    <path d="M291.666 308.5H584.333V357.5H291.666V308.5Z" fill="white" />
    <rect
      x="303.666"
      y="324.5"
      width="159"
      height="17"
      rx="8.5"
      fill="#F0F0F0"
    />
    <path
      d="M291.666 308.5V307.5H290.666V308.5H291.666ZM291.666 357.5H290.666V358.5H291.666V357.5ZM291.666 309.5H584.333V307.5H291.666V309.5ZM584.333 356.5H291.666V358.5H584.333V356.5ZM292.666 357.5V308.5H290.666V357.5H292.666Z"
      fill="#E8E8E8"
      mask="url(#path-43-inside-8_1221_11762)"
    />
    <mask id="path-46-inside-9_1221_11762" fill="white">
      <path d="M583.334 308.5H876.001V357.5H583.334V308.5Z" />
    </mask>
    <path d="M583.334 308.5H876.001V357.5H583.334V308.5Z" fill="white" />
    <rect
      x="595.334"
      y="324.5"
      width="159"
      height="17"
      rx="8.5"
      fill="#F0F0F0"
    />
    <path
      d="M583.334 308.5V307.5H582.334V308.5H583.334ZM876.001 308.5H877.001V307.5H876.001V308.5ZM876.001 357.5V358.5H877.001V357.5H876.001ZM583.334 357.5H582.334V358.5H583.334V357.5ZM583.334 309.5H876.001V307.5H583.334V309.5ZM875.001 308.5V357.5H877.001V308.5H875.001ZM876.001 356.5H583.334V358.5H876.001V356.5ZM584.334 357.5V308.5H582.334V357.5H584.334Z"
      fill="#E8E8E8"
      mask="url(#path-46-inside-9_1221_11762)"
    />
    <mask id="path-49-inside-10_1221_11762" fill="white">
      <path d="M0 356.5H292.667V405.5H0V356.5Z" />
    </mask>
    <path d="M0 356.5H292.667V405.5H0V356.5Z" fill="white" />
    <rect x="12" y="372.5" width="159" height="17" rx="8.5" fill="#F0F0F0" />
    <path
      d="M0 356.5V355.5H-1V356.5H0ZM0 405.5H-1V406.5H0V405.5ZM0 357.5H292.667V355.5H0V357.5ZM292.667 404.5H0V406.5H292.667V404.5ZM1 405.5V356.5H-1V405.5H1Z"
      fill="#E8E8E8"
      mask="url(#path-49-inside-10_1221_11762)"
    />
    <mask id="path-52-inside-11_1221_11762" fill="white">
      <path d="M291.666 356.5H584.333V405.5H291.666V356.5Z" />
    </mask>
    <path d="M291.666 356.5H584.333V405.5H291.666V356.5Z" fill="white" />
    <rect
      x="303.666"
      y="372.5"
      width="159"
      height="17"
      rx="8.5"
      fill="#F0F0F0"
    />
    <path
      d="M291.666 356.5V355.5H290.666V356.5H291.666ZM291.666 405.5H290.666V406.5H291.666V405.5ZM291.666 357.5H584.333V355.5H291.666V357.5ZM584.333 404.5H291.666V406.5H584.333V404.5ZM292.666 405.5V356.5H290.666V405.5H292.666Z"
      fill="#E8E8E8"
      mask="url(#path-52-inside-11_1221_11762)"
    />
    <mask id="path-55-inside-12_1221_11762" fill="white">
      <path d="M583.334 356.5H876.001V405.5H583.334V356.5Z" />
    </mask>
    <path d="M583.334 356.5H876.001V405.5H583.334V356.5Z" fill="white" />
    <rect
      x="595.334"
      y="372.5"
      width="159"
      height="17"
      rx="8.5"
      fill="#F0F0F0"
    />
    <path
      d="M583.334 356.5V355.5H582.334V356.5H583.334ZM876.001 356.5H877.001V355.5H876.001V356.5ZM876.001 405.5V406.5H877.001V405.5H876.001ZM583.334 405.5H582.334V406.5H583.334V405.5ZM583.334 357.5H876.001V355.5H583.334V357.5ZM875.001 356.5V405.5H877.001V356.5H875.001ZM876.001 404.5H583.334V406.5H876.001V404.5ZM584.334 405.5V356.5H582.334V405.5H584.334Z"
      fill="#E8E8E8"
      mask="url(#path-55-inside-12_1221_11762)"
    />
    <mask id="path-58-inside-13_1221_11762" fill="white">
      <path d="M0 404.5H292.667V453.5H0V404.5Z" />
    </mask>
    <path d="M0 404.5H292.667V453.5H0V404.5Z" fill="white" />
    <rect x="12" y="420.5" width="159" height="17" rx="8.5" fill="#F0F0F0" />
    <path
      d="M0 404.5V403.5H-1V404.5H0ZM0 453.5H-1V454.5H0V453.5ZM0 405.5H292.667V403.5H0V405.5ZM292.667 452.5H0V454.5H292.667V452.5ZM1 453.5V404.5H-1V453.5H1Z"
      fill="#E8E8E8"
      mask="url(#path-58-inside-13_1221_11762)"
    />
    <mask id="path-61-inside-14_1221_11762" fill="white">
      <path d="M291.666 404.5H584.333V453.5H291.666V404.5Z" />
    </mask>
    <path d="M291.666 404.5H584.333V453.5H291.666V404.5Z" fill="white" />
    <rect
      x="303.666"
      y="420.5"
      width="159"
      height="17"
      rx="8.5"
      fill="#F0F0F0"
    />
    <path
      d="M291.666 404.5V403.5H290.666V404.5H291.666ZM291.666 453.5H290.666V454.5H291.666V453.5ZM291.666 405.5H584.333V403.5H291.666V405.5ZM584.333 452.5H291.666V454.5H584.333V452.5ZM292.666 453.5V404.5H290.666V453.5H292.666Z"
      fill="#E8E8E8"
      mask="url(#path-61-inside-14_1221_11762)"
    />
    <mask id="path-64-inside-15_1221_11762" fill="white">
      <path d="M583.334 404.5H876.001V453.5H583.334V404.5Z" />
    </mask>
    <path d="M583.334 404.5H876.001V453.5H583.334V404.5Z" fill="white" />
    <rect
      x="595.334"
      y="420.5"
      width="159"
      height="17"
      rx="8.5"
      fill="#F0F0F0"
    />
    <path
      d="M583.334 404.5V403.5H582.334V404.5H583.334ZM876.001 404.5H877.001V403.5H876.001V404.5ZM876.001 453.5V454.5H877.001V453.5H876.001ZM583.334 453.5H582.334V454.5H583.334V453.5ZM583.334 405.5H876.001V403.5H583.334V405.5ZM875.001 404.5V453.5H877.001V404.5H875.001ZM876.001 452.5H583.334V454.5H876.001V452.5ZM584.334 453.5V404.5H582.334V453.5H584.334Z"
      fill="#E8E8E8"
      mask="url(#path-64-inside-15_1221_11762)"
    />
    <mask id="path-67-inside-16_1221_11762" fill="white">
      <path d="M0 452.5H292.667V501.5H0V452.5Z" />
    </mask>
    <path d="M0 452.5H292.667V501.5H0V452.5Z" fill="white" />
    <rect x="12" y="468.5" width="159" height="17" rx="8.5" fill="#F0F0F0" />
    <path
      d="M0 452.5V451.5H-1V452.5H0ZM0 501.5H-1V502.5H0V501.5ZM0 453.5H292.667V451.5H0V453.5ZM292.667 500.5H0V502.5H292.667V500.5ZM1 501.5V452.5H-1V501.5H1Z"
      fill="#E8E8E8"
      mask="url(#path-67-inside-16_1221_11762)"
    />
    <mask id="path-70-inside-17_1221_11762" fill="white">
      <path d="M291.666 452.5H584.333V501.5H291.666V452.5Z" />
    </mask>
    <path d="M291.666 452.5H584.333V501.5H291.666V452.5Z" fill="white" />
    <rect
      x="303.666"
      y="468.5"
      width="159"
      height="17"
      rx="8.5"
      fill="#F0F0F0"
    />
    <path
      d="M291.666 452.5V451.5H290.666V452.5H291.666ZM291.666 501.5H290.666V502.5H291.666V501.5ZM291.666 453.5H584.333V451.5H291.666V453.5ZM584.333 500.5H291.666V502.5H584.333V500.5ZM292.666 501.5V452.5H290.666V501.5H292.666Z"
      fill="#E8E8E8"
      mask="url(#path-70-inside-17_1221_11762)"
    />
    <mask id="path-73-inside-18_1221_11762" fill="white">
      <path d="M583.334 452.5H876.001V501.5H583.334V452.5Z" />
    </mask>
    <path d="M583.334 452.5H876.001V501.5H583.334V452.5Z" fill="white" />
    <rect
      x="595.334"
      y="468.5"
      width="159"
      height="17"
      rx="8.5"
      fill="#F0F0F0"
    />
    <path
      d="M583.334 452.5V451.5H582.334V452.5H583.334ZM876.001 452.5H877.001V451.5H876.001V452.5ZM876.001 501.5V502.5H877.001V501.5H876.001ZM583.334 501.5H582.334V502.5H583.334V501.5ZM583.334 453.5H876.001V451.5H583.334V453.5ZM875.001 452.5V501.5H877.001V452.5H875.001ZM876.001 500.5H583.334V502.5H876.001V500.5ZM584.334 501.5V452.5H582.334V501.5H584.334Z"
      fill="#E8E8E8"
      mask="url(#path-73-inside-18_1221_11762)"
    />
    <mask id="path-76-inside-19_1221_11762" fill="white">
      <path d="M0 500.5H292.667V549.5H0V500.5Z" />
    </mask>
    <path d="M0 500.5H292.667V549.5H0V500.5Z" fill="white" />
    <rect x="12" y="516.5" width="159" height="17" rx="8.5" fill="#F0F0F0" />
    <path
      d="M0 500.5V499.5H-1V500.5H0ZM0 549.5H-1V550.5H0V549.5ZM0 501.5H292.667V499.5H0V501.5ZM292.667 548.5H0V550.5H292.667V548.5ZM1 549.5V500.5H-1V549.5H1Z"
      fill="#E8E8E8"
      mask="url(#path-76-inside-19_1221_11762)"
    />
    <mask id="path-79-inside-20_1221_11762" fill="white">
      <path d="M291.666 500.5H584.333V549.5H291.666V500.5Z" />
    </mask>
    <path d="M291.666 500.5H584.333V549.5H291.666V500.5Z" fill="white" />
    <rect
      x="303.666"
      y="516.5"
      width="159"
      height="17"
      rx="8.5"
      fill="#F0F0F0"
    />
    <path
      d="M291.666 500.5V499.5H290.666V500.5H291.666ZM291.666 549.5H290.666V550.5H291.666V549.5ZM291.666 501.5H584.333V499.5H291.666V501.5ZM584.333 548.5H291.666V550.5H584.333V548.5ZM292.666 549.5V500.5H290.666V549.5H292.666Z"
      fill="#E8E8E8"
      mask="url(#path-79-inside-20_1221_11762)"
    />
    <mask id="path-82-inside-21_1221_11762" fill="white">
      <path d="M583.334 500.5H876.001V549.5H583.334V500.5Z" />
    </mask>
    <path d="M583.334 500.5H876.001V549.5H583.334V500.5Z" fill="white" />
    <rect
      x="595.334"
      y="516.5"
      width="159"
      height="17"
      rx="8.5"
      fill="#F0F0F0"
    />
    <path
      d="M583.334 500.5V499.5H582.334V500.5H583.334ZM876.001 500.5H877.001V499.5H876.001V500.5ZM876.001 549.5V550.5H877.001V549.5H876.001ZM583.334 549.5H582.334V550.5H583.334V549.5ZM583.334 501.5H876.001V499.5H583.334V501.5ZM875.001 500.5V549.5H877.001V500.5H875.001ZM876.001 548.5H583.334V550.5H876.001V548.5ZM584.334 549.5V500.5H582.334V549.5H584.334Z"
      fill="#E8E8E8"
      mask="url(#path-82-inside-21_1221_11762)"
    />
    <mask id="path-85-inside-22_1221_11762" fill="white">
      <path d="M0 548.5H292.667V597.5H0V548.5Z" />
    </mask>
    <path d="M0 548.5H292.667V597.5H0V548.5Z" fill="white" />
    <rect x="12" y="564.5" width="159" height="17" rx="8.5" fill="#F0F0F0" />
    <path
      d="M0 548.5V547.5H-1V548.5H0ZM0 597.5H-1V598.5H0V597.5ZM0 549.5H292.667V547.5H0V549.5ZM292.667 596.5H0V598.5H292.667V596.5ZM1 597.5V548.5H-1V597.5H1Z"
      fill="#E8E8E8"
      mask="url(#path-85-inside-22_1221_11762)"
    />
    <mask id="path-88-inside-23_1221_11762" fill="white">
      <path d="M291.666 548.5H584.333V597.5H291.666V548.5Z" />
    </mask>
    <path d="M291.666 548.5H584.333V597.5H291.666V548.5Z" fill="white" />
    <rect
      x="303.666"
      y="564.5"
      width="159"
      height="17"
      rx="8.5"
      fill="#F0F0F0"
    />
    <path
      d="M291.666 548.5V547.5H290.666V548.5H291.666ZM291.666 597.5H290.666V598.5H291.666V597.5ZM291.666 549.5H584.333V547.5H291.666V549.5ZM584.333 596.5H291.666V598.5H584.333V596.5ZM292.666 597.5V548.5H290.666V597.5H292.666Z"
      fill="#E8E8E8"
      mask="url(#path-88-inside-23_1221_11762)"
    />
    <mask id="path-91-inside-24_1221_11762" fill="white">
      <path d="M583.334 548.5H876.001V597.5H583.334V548.5Z" />
    </mask>
    <path d="M583.334 548.5H876.001V597.5H583.334V548.5Z" fill="white" />
    <rect
      x="595.334"
      y="564.5"
      width="159"
      height="17"
      rx="8.5"
      fill="#F0F0F0"
    />
    <path
      d="M583.334 548.5V547.5H582.334V548.5H583.334ZM876.001 548.5H877.001V547.5H876.001V548.5ZM876.001 597.5V598.5H877.001V597.5H876.001ZM583.334 597.5H582.334V598.5H583.334V597.5ZM583.334 549.5H876.001V547.5H583.334V549.5ZM875.001 548.5V597.5H877.001V548.5H875.001ZM876.001 596.5H583.334V598.5H876.001V596.5ZM584.334 597.5V548.5H582.334V597.5H584.334Z"
      fill="#E8E8E8"
      mask="url(#path-91-inside-24_1221_11762)"
    />
    <mask id="path-94-inside-25_1221_11762" fill="white">
      <path d="M0 596.5H292.667V645.5H0V596.5Z" />
    </mask>
    <path d="M0 596.5H292.667V645.5H0V596.5Z" fill="white" />
    <rect x="12" y="612.5" width="159" height="17" rx="8.5" fill="#F0F0F0" />
    <path
      d="M0 596.5V595.5H-1V596.5H0ZM0 645.5H-1V646.5H0V645.5ZM0 597.5H292.667V595.5H0V597.5ZM292.667 644.5H0V646.5H292.667V644.5ZM1 645.5V596.5H-1V645.5H1Z"
      fill="#E8E8E8"
      mask="url(#path-94-inside-25_1221_11762)"
    />
    <mask id="path-97-inside-26_1221_11762" fill="white">
      <path d="M291.666 596.5H584.333V645.5H291.666V596.5Z" />
    </mask>
    <path d="M291.666 596.5H584.333V645.5H291.666V596.5Z" fill="white" />
    <rect
      x="303.666"
      y="612.5"
      width="159"
      height="17"
      rx="8.5"
      fill="#F0F0F0"
    />
    <path
      d="M291.666 596.5V595.5H290.666V596.5H291.666ZM291.666 645.5H290.666V646.5H291.666V645.5ZM291.666 597.5H584.333V595.5H291.666V597.5ZM584.333 644.5H291.666V646.5H584.333V644.5ZM292.666 645.5V596.5H290.666V645.5H292.666Z"
      fill="#E8E8E8"
      mask="url(#path-97-inside-26_1221_11762)"
    />
    <mask id="path-100-inside-27_1221_11762" fill="white">
      <path d="M583.334 596.5H876.001V645.5H583.334V596.5Z" />
    </mask>
    <path d="M583.334 596.5H876.001V645.5H583.334V596.5Z" fill="white" />
    <rect
      x="595.334"
      y="612.5"
      width="159"
      height="17"
      rx="8.5"
      fill="#F0F0F0"
    />
    <path
      d="M583.334 596.5V595.5H582.334V596.5H583.334ZM876.001 596.5H877.001V595.5H876.001V596.5ZM876.001 645.5V646.5H877.001V645.5H876.001ZM583.334 645.5H582.334V646.5H583.334V645.5ZM583.334 597.5H876.001V595.5H583.334V597.5ZM875.001 596.5V645.5H877.001V596.5H875.001ZM876.001 644.5H583.334V646.5H876.001V644.5ZM584.334 645.5V596.5H582.334V645.5H584.334Z"
      fill="#E8E8E8"
      mask="url(#path-100-inside-27_1221_11762)"
    />
    <mask id="path-103-inside-28_1221_11762" fill="white">
      <path d="M0 644.5H292.667V693.5H0V644.5Z" />
    </mask>
    <path d="M0 644.5H292.667V693.5H0V644.5Z" fill="white" />
    <rect x="12" y="660.5" width="159" height="17" rx="8.5" fill="#F0F0F0" />
    <path
      d="M0 644.5V643.5H-1V644.5H0ZM0 693.5H-1V694.5H0V693.5ZM0 645.5H292.667V643.5H0V645.5ZM292.667 692.5H0V694.5H292.667V692.5ZM1 693.5V644.5H-1V693.5H1Z"
      fill="#E8E8E8"
      mask="url(#path-103-inside-28_1221_11762)"
    />
    <mask id="path-106-inside-29_1221_11762" fill="white">
      <path d="M291.666 644.5H584.333V693.5H291.666V644.5Z" />
    </mask>
    <path d="M291.666 644.5H584.333V693.5H291.666V644.5Z" fill="white" />
    <rect
      x="303.666"
      y="660.5"
      width="159"
      height="17"
      rx="8.5"
      fill="#F0F0F0"
    />
    <path
      d="M291.666 644.5V643.5H290.666V644.5H291.666ZM291.666 693.5H290.666V694.5H291.666V693.5ZM291.666 645.5H584.333V643.5H291.666V645.5ZM584.333 692.5H291.666V694.5H584.333V692.5ZM292.666 693.5V644.5H290.666V693.5H292.666Z"
      fill="#E8E8E8"
      mask="url(#path-106-inside-29_1221_11762)"
    />
    <mask id="path-109-inside-30_1221_11762" fill="white">
      <path d="M583.334 644.5H876.001V693.5H583.334V644.5Z" />
    </mask>
    <path d="M583.334 644.5H876.001V693.5H583.334V644.5Z" fill="white" />
    <rect
      x="595.334"
      y="660.5"
      width="159"
      height="17"
      rx="8.5"
      fill="#F0F0F0"
    />
    <path
      d="M583.334 644.5V643.5H582.334V644.5H583.334ZM876.001 644.5H877.001V643.5H876.001V644.5ZM876.001 693.5V694.5H877.001V693.5H876.001ZM583.334 693.5H582.334V694.5H583.334V693.5ZM583.334 645.5H876.001V643.5H583.334V645.5ZM875.001 644.5V693.5H877.001V644.5H875.001ZM876.001 692.5H583.334V694.5H876.001V692.5ZM584.334 693.5V644.5H582.334V693.5H584.334Z"
      fill="#E8E8E8"
      mask="url(#path-109-inside-30_1221_11762)"
    />
    <mask id="path-112-inside-31_1221_11762" fill="white">
      <path d="M0 692.5H292.667V741.5H0V692.5Z" />
    </mask>
    <path d="M0 692.5H292.667V741.5H0V692.5Z" fill="white" />
    <rect x="12" y="708.5" width="159" height="17" rx="8.5" fill="#F0F0F0" />
    <path
      d="M0 692.5V691.5H-1V692.5H0ZM0 741.5H-1V742.5H0V741.5ZM0 693.5H292.667V691.5H0V693.5ZM292.667 740.5H0V742.5H292.667V740.5ZM1 741.5V692.5H-1V741.5H1Z"
      fill="#E8E8E8"
      mask="url(#path-112-inside-31_1221_11762)"
    />
    <mask id="path-115-inside-32_1221_11762" fill="white">
      <path d="M291.666 692.5H584.333V741.5H291.666V692.5Z" />
    </mask>
    <path d="M291.666 692.5H584.333V741.5H291.666V692.5Z" fill="white" />
    <rect
      x="303.666"
      y="708.5"
      width="159"
      height="17"
      rx="8.5"
      fill="#F0F0F0"
    />
    <path
      d="M291.666 692.5V691.5H290.666V692.5H291.666ZM291.666 741.5H290.666V742.5H291.666V741.5ZM291.666 693.5H584.333V691.5H291.666V693.5ZM584.333 740.5H291.666V742.5H584.333V740.5ZM292.666 741.5V692.5H290.666V741.5H292.666Z"
      fill="#E8E8E8"
      mask="url(#path-115-inside-32_1221_11762)"
    />
    <mask id="path-118-inside-33_1221_11762" fill="white">
      <path d="M583.334 692.5H876.001V741.5H583.334V692.5Z" />
    </mask>
    <path d="M583.334 692.5H876.001V741.5H583.334V692.5Z" fill="white" />
    <rect
      x="595.334"
      y="708.5"
      width="159"
      height="17"
      rx="8.5"
      fill="#F0F0F0"
    />
    <path
      d="M583.334 692.5V691.5H582.334V692.5H583.334ZM876.001 692.5H877.001V691.5H876.001V692.5ZM876.001 741.5V742.5H877.001V741.5H876.001ZM583.334 741.5H582.334V742.5H583.334V741.5ZM583.334 693.5H876.001V691.5H583.334V693.5ZM875.001 692.5V741.5H877.001V692.5H875.001ZM876.001 740.5H583.334V742.5H876.001V740.5ZM584.334 741.5V692.5H582.334V741.5H584.334Z"
      fill="#E8E8E8"
      mask="url(#path-118-inside-33_1221_11762)"
    />
    <mask id="path-121-inside-34_1221_11762" fill="white">
      <path d="M0 740.5H292.667V789.5H0V740.5Z" />
    </mask>
    <path d="M0 740.5H292.667V789.5H0V740.5Z" fill="white" />
    <rect x="12" y="756.5" width="159" height="17" rx="8.5" fill="#F0F0F0" />
    <path
      d="M0 740.5V739.5H-1V740.5H0ZM0 789.5H-1V790.5H0V789.5ZM0 741.5H292.667V739.5H0V741.5ZM292.667 788.5H0V790.5H292.667V788.5ZM1 789.5V740.5H-1V789.5H1Z"
      fill="#E8E8E8"
      mask="url(#path-121-inside-34_1221_11762)"
    />
    <mask id="path-124-inside-35_1221_11762" fill="white">
      <path d="M291.666 740.5H584.333V789.5H291.666V740.5Z" />
    </mask>
    <path d="M291.666 740.5H584.333V789.5H291.666V740.5Z" fill="white" />
    <rect
      x="303.666"
      y="756.5"
      width="159"
      height="17"
      rx="8.5"
      fill="#F0F0F0"
    />
    <path
      d="M291.666 740.5V739.5H290.666V740.5H291.666ZM291.666 789.5H290.666V790.5H291.666V789.5ZM291.666 741.5H584.333V739.5H291.666V741.5ZM584.333 788.5H291.666V790.5H584.333V788.5ZM292.666 789.5V740.5H290.666V789.5H292.666Z"
      fill="#E8E8E8"
      mask="url(#path-124-inside-35_1221_11762)"
    />
    <mask id="path-127-inside-36_1221_11762" fill="white">
      <path d="M583.334 740.5H876.001V789.5H583.334V740.5Z" />
    </mask>
    <path d="M583.334 740.5H876.001V789.5H583.334V740.5Z" fill="white" />
    <rect
      x="595.334"
      y="756.5"
      width="159"
      height="17"
      rx="8.5"
      fill="#F0F0F0"
    />
    <path
      d="M583.334 740.5V739.5H582.334V740.5H583.334ZM876.001 740.5H877.001V739.5H876.001V740.5ZM876.001 789.5V790.5H877.001V789.5H876.001ZM583.334 789.5H582.334V790.5H583.334V789.5ZM583.334 741.5H876.001V739.5H583.334V741.5ZM875.001 740.5V789.5H877.001V740.5H875.001ZM876.001 788.5H583.334V790.5H876.001V788.5ZM584.334 789.5V740.5H582.334V789.5H584.334Z"
      fill="#E8E8E8"
      mask="url(#path-127-inside-36_1221_11762)"
    />
    <mask id="path-130-inside-37_1221_11762" fill="white">
      <path d="M0 788.5H292.667V837.5H0V788.5Z" />
    </mask>
    <path d="M0 788.5H292.667V837.5H0V788.5Z" fill="white" />
    <rect x="12" y="804.5" width="159" height="17" rx="8.5" fill="#F0F0F0" />
    <path
      d="M0 788.5V787.5H-1V788.5H0ZM0 837.5H-1V838.5H0V837.5ZM0 789.5H292.667V787.5H0V789.5ZM292.667 836.5H0V838.5H292.667V836.5ZM1 837.5V788.5H-1V837.5H1Z"
      fill="#E8E8E8"
      mask="url(#path-130-inside-37_1221_11762)"
    />
    <mask id="path-133-inside-38_1221_11762" fill="white">
      <path d="M291.666 788.5H584.333V837.5H291.666V788.5Z" />
    </mask>
    <path d="M291.666 788.5H584.333V837.5H291.666V788.5Z" fill="white" />
    <rect
      x="303.666"
      y="804.5"
      width="159"
      height="17"
      rx="8.5"
      fill="#F0F0F0"
    />
    <path
      d="M291.666 788.5V787.5H290.666V788.5H291.666ZM291.666 837.5H290.666V838.5H291.666V837.5ZM291.666 789.5H584.333V787.5H291.666V789.5ZM584.333 836.5H291.666V838.5H584.333V836.5ZM292.666 837.5V788.5H290.666V837.5H292.666Z"
      fill="#E8E8E8"
      mask="url(#path-133-inside-38_1221_11762)"
    />
    <mask id="path-136-inside-39_1221_11762" fill="white">
      <path d="M583.334 788.5H876.001V837.5H583.334V788.5Z" />
    </mask>
    <path d="M583.334 788.5H876.001V837.5H583.334V788.5Z" fill="white" />
    <rect
      x="595.334"
      y="804.5"
      width="159"
      height="17"
      rx="8.5"
      fill="#F0F0F0"
    />
    <path
      d="M583.334 788.5V787.5H582.334V788.5H583.334ZM876.001 788.5H877.001V787.5H876.001V788.5ZM876.001 837.5V838.5H877.001V837.5H876.001ZM583.334 837.5H582.334V838.5H583.334V837.5ZM583.334 789.5H876.001V787.5H583.334V789.5ZM875.001 788.5V837.5H877.001V788.5H875.001ZM876.001 836.5H583.334V838.5H876.001V836.5ZM584.334 837.5V788.5H582.334V837.5H584.334Z"
      fill="#E8E8E8"
      mask="url(#path-136-inside-39_1221_11762)"
    />
    <mask id="path-139-inside-40_1221_11762" fill="white">
      <path d="M0 836.5H292.667V885.5H0V836.5Z" />
    </mask>
    <path d="M0 836.5H292.667V885.5H0V836.5Z" fill="white" />
    <rect x="12" y="852.5" width="159" height="17" rx="8.5" fill="#F0F0F0" />
    <path
      d="M0 836.5V835.5H-1V836.5H0ZM0 885.5H-1V886.5H0V885.5ZM0 837.5H292.667V835.5H0V837.5ZM292.667 884.5H0V886.5H292.667V884.5ZM1 885.5V836.5H-1V885.5H1Z"
      fill="#E8E8E8"
      mask="url(#path-139-inside-40_1221_11762)"
    />
    <mask id="path-142-inside-41_1221_11762" fill="white">
      <path d="M291.666 836.5H584.333V885.5H291.666V836.5Z" />
    </mask>
    <path d="M291.666 836.5H584.333V885.5H291.666V836.5Z" fill="white" />
    <rect
      x="303.666"
      y="852.5"
      width="159"
      height="17"
      rx="8.5"
      fill="#F0F0F0"
    />
    <path
      d="M291.666 836.5V835.5H290.666V836.5H291.666ZM291.666 885.5H290.666V886.5H291.666V885.5ZM291.666 837.5H584.333V835.5H291.666V837.5ZM584.333 884.5H291.666V886.5H584.333V884.5ZM292.666 885.5V836.5H290.666V885.5H292.666Z"
      fill="#E8E8E8"
      mask="url(#path-142-inside-41_1221_11762)"
    />
    <mask id="path-145-inside-42_1221_11762" fill="white">
      <path d="M583.334 836.5H876.001V885.5H583.334V836.5Z" />
    </mask>
    <path d="M583.334 836.5H876.001V885.5H583.334V836.5Z" fill="white" />
    <rect
      x="595.334"
      y="852.5"
      width="159"
      height="17"
      rx="8.5"
      fill="#F0F0F0"
    />
    <path
      d="M583.334 836.5V835.5H582.334V836.5H583.334ZM876.001 836.5H877.001V835.5H876.001V836.5ZM876.001 885.5V886.5H877.001V885.5H876.001ZM583.334 885.5H582.334V886.5H583.334V885.5ZM583.334 837.5H876.001V835.5H583.334V837.5ZM875.001 836.5V885.5H877.001V836.5H875.001ZM876.001 884.5H583.334V886.5H876.001V884.5ZM584.334 885.5V836.5H582.334V885.5H584.334Z"
      fill="#E8E8E8"
      mask="url(#path-145-inside-42_1221_11762)"
    />
  </svg>
);
