// <--- Library Imports Start --->
import { useCallback, useState } from "react";

import AnalysisDetailPageheaderLeftSec from "../analysisdetailpageheaderleftsec";
import AnalysisDetailPageHeaderRightSec from "../analysisdetailpageheaderrightsec";

import AnalysisOverviewSec from "../analysisoverviewsec";
// <--- Library Imports End --->

// <--- Components Start --->

// <--- Components End --->

// <--- Styles Start --->
import { AnalysisDetailPageHeaderWithNoSelectionStyled } from "./analysisdetailpageheaderwithnoselection.styles";
// <--- Styles End --->

// <--- Types Start --->
import { AnalysisDetailPageHeaderWithNoSelectionProps } from "./analysisdetailpageheaderwithnoselection.types";
import {
  ExclusionCriteriaSection,
  ShareWithSection,
} from "./analysisdetailpageheaderwithnoselection.components";
// <--- Types End --->

const AnalysisDetailPageHeaderWithNoSelection = (
  props: AnalysisDetailPageHeaderWithNoSelectionProps
): JSX.Element => {
  const {
    handlers,
    analysisInfo = {
      analysisId: "",
      tableId: "",
      createdBy: { name: "", email: "" },
      tablesList: [],
      lastRefreshed: "",
      totalQueries: 0,
      shareType: "NON",
      sharedWith: [],
      datasourceUsed: { name: "", type: "ADL" },
      columnFilters: [],
      tableFilters: [],
    },
    analysisPageHeaderState,
    isEditMode = false,
    isAuthor = false,
    hasUserAccessToEdit = false,
    isAnalysisTitleExists = false,
    sourceId = "",
    onEditOverviewFormSubmit,
  } = props;

  const [showInfoSection, setShowInfoSection] = useState("");

  const [isShowDeleteConfirmation, setIsShowDeleteConfirmation] = useState(
    false
  );

  const handleOverviewOpenButtonClick = useCallback(
    (section) => {
      setShowInfoSection(section);
    },
    [showInfoSection]
  );

  const handleOverviewClose = useCallback(() => {
    setShowInfoSection("");
  }, []);

  const toggleDeleteConfirmation = useCallback(() => {
    setIsShowDeleteConfirmation((st) => !st);
  }, []);

  return (
    <AnalysisDetailPageHeaderWithNoSelectionStyled data-testid="analysis-detail-page-header-with-no-selection-wrapper">
      <div
        className="analysis-header-main-wrapper"
        data-testid="analysis-detail-page-header-with-no-selection-main-wrapper"
      >
        <>
          <AnalysisDetailPageheaderLeftSec
            isEditMode={isEditMode}
            analysisHeaderData={{
              ...analysisInfo,
              title: analysisPageHeaderState?.title,
              desc: analysisPageHeaderState?.desc,
              exclusionCriteriaExists:
                !!analysisInfo?.columnFilters?.length ||
                !!analysisInfo?.tableFilters?.length,
            }}
            onEditOverviewFormSubmit={onEditOverviewFormSubmit}
            handleOverviewOpenButtonClick={handleOverviewOpenButtonClick}
            showInfoSection={showInfoSection}
            isAnalysisTitleExists={isAnalysisTitleExists}
            showGradient={isShowDeleteConfirmation}
          />
          <AnalysisDetailPageHeaderRightSec
            analysisSourceId={sourceId}
            isEditMode={isEditMode}
            isAuthor={isAuthor}
            hasUserAccessToEdit={hasUserAccessToEdit}
            handlers={handlers}
            toggleDeleteConfirmation={toggleDeleteConfirmation}
            isShowDeleteConfirmation={isShowDeleteConfirmation}
          />
        </>
      </div>

      {showInfoSection && (
        <AnalysisOverviewSec handleClose={handleOverviewClose}>
          {showInfoSection === "overview" ? (
            <ShareWithSection
              shareType={analysisInfo?.shareType}
              sharedWith={analysisInfo?.sharedWith}
            />
          ) : (
            <ExclusionCriteriaSection
              columnFilters={analysisInfo?.columnFilters}
              tableFilters={analysisInfo?.tableFilters}
            />
          )}
        </AnalysisOverviewSec>
      )}
    </AnalysisDetailPageHeaderWithNoSelectionStyled>
  );
};

export default AnalysisDetailPageHeaderWithNoSelection;
