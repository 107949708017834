import styled from "styled-components";

export const InputFieldContainerStyled = styled.div<{
  isError: boolean;
}>`
  .ant-input {
    ${({ isError, theme }): string =>
      isError
        ? `border: 1px solid ${theme.borderColors.RED_11} !important;`
        : ""}
  }
`;
