import styled from "styled-components";

export const EntitiesSectionPaginationStyled = styled.div`
  color: ${({ theme }): string => theme.textColors.DARK_GREY_10};
  font-size: 14px;

  .pagination-btn-container {
    padding-left: 6px;

    .pagination-btn {
      border: 1px solid
        ${({ theme }): string => theme.borderColors.LIGHT_GREY_15};
      border-radius: 3px;
      width: 26.4px;
      height: 26.4px;
      margin-left: 3px;

      &:hover {
        border: 1px solid
          ${({ theme }): string => theme.borderColors.LIGHT_GREY_15};
      }
    }
  }

  svg {
    path {
      fill: ${({ theme }): string => theme.bgColors.LIGHT_BLUE_112} !important;
    }
  }
`;
