import styled from "styled-components";

export const AnalyticsTabSyled = styled.div`
  .page-content
    .ag-theme-alpine
    .ag-layout-auto-height
    .ag-center-cols-clipper {
    min-height: 280px;
  }
`;
