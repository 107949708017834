export const multiLevelSortArrayByKeys = <T>(
  array: Array<T>,
  keys: Array<keyof T>
): Array<T> => {
  try {
    return [...array]?.sort((a, b) => {
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (typeof a[key] === "string" && typeof b[key] === "string") {
          const lowerA = ((a[key] as unknown) as string).toLowerCase().trim();
          const lowerB = ((b[key] as unknown) as string).toLowerCase().trim();
          if (lowerA < lowerB) {
            return -1;
          }
          if (lowerA > lowerB) {
            return 1;
          }
        } else {
          if (a[key] < b[key]) {
            return -1;
          }
          if (a[key] > b[key]) {
            return 1;
          }
        }
      }
      return 0;
    });
  } catch (e) {
    return array || [];
  }
};
