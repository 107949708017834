import styled from "styled-components";

export const ModalWrapperStyled = styled.div`
  padding-left: 14%;

  .form-actions-sec {
    margin-left: -16%;
  }
`;

export const FilterConditionWrapperStyled = styled.div`
  padding: 0 45px;
  min-height: 350px;
  max-height: 600px;
  overflow-y: auto;
  margin: 45px 0;
`;

export const ModalFooterStyled = styled.div`
  background-color: ${({ theme }): string => theme.bgColors.LIGHT_BLUE_25};
  border-top: 1px solid
    ${({ theme }): string => theme.borderColors.LIGHT_BLUE_45};
  padding: 8px 18px 8px 18px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
