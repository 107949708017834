import { Drawer } from "antd";
import styled from "styled-components";

export const DrawerStyled = styled(Drawer)<{
  isChatPage: boolean;
  isStartFromTop?: boolean;
}>`
  &.ant-drawer {
    top: ${({ isChatPage, isStartFromTop }): string =>
      isChatPage || isStartFromTop ? "0" : "45px !important"};
    .ant-drawer-content-wrapper {
      box-shadow: none;
      .ant-drawer-content {
        .ant-drawer-wrapper-body {
          .ant-drawer-header {
            height: 63.5px;
            padding: 13px 22.7px 17.5px 17px;
            background-color: ${({ theme }): string =>
              theme.textColors.BLUE_26};
            color: ${({ theme }): string => theme.textColors.WHITE_COLOR};
            .ant-drawer-title {
              font-size: 24px;
              color: ${({ theme }): string => theme.textColors.WHITE_COLOR};
              font-family: OpenSansBold;
              line-height: unset;

              > svg {
                margin-right: 10px;
              }

              .custom-header {
                display: flex;
                align-items: center;
              }
            }
            .ant-drawer-close {
              padding-top: 22px;
              padding-right: 23px;
              svg {
                width: 17px;
                height: 17px;
                path {
                  fill: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
                }
              }
            }
          }

          .ant-drawer-header-no-title {
            display: none;
          }

          .ant-drawer-body {
            max-height: calc(100vh - 45px);
            overflow-y: auto;
            padding: 0px;
          }
        }
      }
    }
  }
`;
