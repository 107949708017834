import { useCallback, useMemo } from "react";
import { Tooltip } from "antd";

import { chatIcon, chevronDownCollapse } from "../../../../../../svgs";

import {
  analyticsStatusTagMapping,
  getViewOnlyChatDetailPageUrl,
} from "../../../../../../utils";

import { VerticalDividerStyled } from "../../../../../dividers/dividers.styles";
import LinkButton from "../../../../../linkbutton";

import { SingleTagRendrer } from "../../../../../prominenttagslist/prominenttagslist";
import {
  HeadingTooltipBlankState,
  ReviewCaddiFeedbackContentHeaderStyled,
} from "./reviewcaddifeedbackcontentheader.styles";

import { ReviewCaddiFeedbackContentHeaderProps } from "./reviewcaddifeedbackcontentheader.types";
import ButtonActionMenu from "../../../../../buttonactionmenu";

import { useOpenDrawer } from "../../../../../../customhooks";
import { WorkflowHOCEmbedProps } from "../../../../../workflowhoc/workflowhoc.types";

import { CommentHistoryDrawerTypes } from "../../../../../../drawerviews/activityhistorydrawer/activityhistorydrawer.types";
import { CaddiAnalyticsStatusIdType } from "../../../../../../parsers";
import {
  DVSUM_TOOLTIP_CLASS_NAME,
  LOCAL_STORAGE_CONSTANTS,
} from "../../../../../../constants";
import { getChatDetailPageUrl } from "../../../../../../utils/getchatdetailpageurl";

const chatIconSvg = chatIcon();
const chevronDownSvg = chevronDownCollapse();

const ReviewCaddiFeedbackContentHeader = (
  props: ReviewCaddiFeedbackContentHeaderProps & WorkflowHOCEmbedProps
): JSX.Element => {
  const openDrawer = useOpenDrawer();

  const {
    onNodeCommentOrRejectRequest,
    qstId = "",
    selectedQuestionData,
    sortedFeedbackStatuses = [],
    onUpdateStatus,
  } = props;

  const {
    chat_id: chatId = "",
    chat_name: chatName = "",
    status_id: statusId,
    status = "",
    user = "",
    feedbackText = "",
    feedbackType,
    chat_author: chatAuthor = "",
    qry_tain_text: qryTainText = "",
  } = selectedQuestionData;

  const modalHeadig = useMemo(() => {
    const text = `${
      feedbackType === "Approved" ? "Positive " : "Negative: "
    }${feedbackText}`;

    const tooltip =
      feedbackType === "Rejected"
        ? qryTainText || (
            <HeadingTooltipBlankState>
              No additional feedback has been provided.
            </HeadingTooltipBlankState>
          )
        : "";

    return { text, tooltip };
  }, [feedbackType, feedbackText, qryTainText]);

  const analyticsStatusTag = analyticsStatusTagMapping(statusId, status);

  const onChatNameClick = useCallback(
    (e) => {
      e?.stopPropagation();

      const isAuthor =
        chatAuthor?.toLocaleLowerCase() ===
        localStorage
          ?.getItem(LOCAL_STORAGE_CONSTANTS?.email)
          ?.toLocaleLowerCase();

      window.open(
        isAuthor
          ? getChatDetailPageUrl(chatId, qstId)
          : getViewOnlyChatDetailPageUrl(chatId, qstId)
      );
    },
    [chatId, qstId, chatAuthor]
  );

  const onCommnetClick = useCallback(() => {
    const drawerProps: CommentHistoryDrawerTypes = {
      nodeType: "QRY",
      nodeId: qstId,
      actionType: "CMT",
      activityRequest: onNodeCommentOrRejectRequest,
    };

    openDrawer({
      drawerId: "comment_history_ref",
      visible: true,
      drawerProps,
      zIndex: 10001,
      isStartFromTop: true,
    });
  }, [qstId, onNodeCommentOrRejectRequest]);

  const updateStatusOverlayItems = useMemo(
    () =>
      sortedFeedbackStatuses?.map((item) => ({
        name: item?.name,
        disable: item?.id === statusId,
        onClick: (): void =>
          onUpdateStatus(
            qstId,
            item?.id as CaddiAnalyticsStatusIdType,
            item?.name
          ),
      })),
    [statusId, qstId, sortedFeedbackStatuses, onUpdateStatus]
  );

  return (
    <ReviewCaddiFeedbackContentHeaderStyled>
      <div className="header-left-sec">
        <div className="heading-sec">
          <Tooltip
            title={modalHeadig?.tooltip}
            placement="bottomLeft"
            overlayClassName={`${DVSUM_TOOLTIP_CLASS_NAME} review-feedback-heading-tooltip`}
            getPopupContainer={(trigger): HTMLElement =>
              trigger.parentNode as HTMLElement
            }
          >
            <div className="heading">{modalHeadig?.text}</div>
          </Tooltip>
          <SingleTagRendrer tag={analyticsStatusTag} />
        </div>
        <div className="desc-sec">
          Question of {user} from{" "}
          <LinkButton onClick={onChatNameClick}>{chatName}</LinkButton>
        </div>
      </div>

      <div className="header-right-sec">
        <LinkButton
          onClick={onCommnetClick}
          tooltipProps={{
            title: "Activities",
          }}
        >
          {chatIconSvg}
        </LinkButton>
        <VerticalDividerStyled height="7.2px" />
        <ButtonActionMenu
          customButton={
            <div className="update-status-btn">
              Update Status {chevronDownSvg}
            </div>
          }
          overLayItems={updateStatusOverlayItems}
        />
      </div>
    </ReviewCaddiFeedbackContentHeaderStyled>
  );
};

export default ReviewCaddiFeedbackContentHeader;
