import * as yup from "yup";
import { REQUIRED_MESSAGE } from "../../constants/formconstants";
import { descriptionSchema } from "./ruleschemas/ruledescriptionschema";

export const AddReferenceDictionarySchema = yup.object().shape({
  name: yup
    ?.string()
    ?.trim()
    ?.test(
      "no-more-than-100-chars",
      "Name should be less than 100 words",
      (currentField) => {
        const currentFieldLength = currentField?.length || 0;
        if (currentFieldLength > 100) return false;
        return true;
      }
    )
    .required(REQUIRED_MESSAGE),
  description: descriptionSchema,
  isKeyValuePair: yup.boolean(),
  isHierarchical: yup.boolean(),
  hierarchicalLevel: yup.lazy((value, originalObject) => {
    const isHierarchicalChecked = originalObject.parent.isHierarchical;

    return isHierarchicalChecked
      ? yup
          .number()
          .typeError("Value entered must be a number.")
          .transform((originalValue, originalObject) => {
            // Return undefined if the value is not a valid integer
            return Number.isInteger(originalValue) ? originalValue : undefined;
          })
          .required("This is required.")
          .min(1, "Must be at least 1.")
          .max(10, "Must be at most 10.")
          .test("is-integer", "Value entered must be an integer.", (value) =>
            Number.isInteger(value)
          )
      : yup?.number();
  }),
});
