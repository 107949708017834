import styled from "styled-components";
import { ConfidenceScoreProps } from "./confidencescore.types";

export const ConfidenceScoreStyled = styled.div<ConfidenceScoreProps>`
  width: 47px;
  height: 17px;
  border-radius: 8px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({ marginTop }): string => marginTop ?? ""};
  background: ${({ theme, score }): string =>
    score >= 50 && score < 70
      ? theme.bgColors.RED_19
      : score >= 70 && score < 90
      ? theme.bgColors.YELLOW_COLOR
      : score >= 90
      ? theme.bgColors.GREEN_11
      : theme.bgColors.RED_19};
  color: ${({ theme }): string => theme.textColors.WHITE_COLOR};
`;
