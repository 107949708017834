import styled from "styled-components";

export const FilePreviewerStyled = styled.div<{
  isAdded?: boolean;
  file?: any;
}>`
  ${({ file, isAdded, theme }): any =>
    file &&
    `
     background: ${
       isAdded ? theme.bgColors.GREY_56 : theme.bgColors.WHITE_COLOR
     };
     padding: 12px 12px 12px 7px;
     border: 1px solid
       ${
         isAdded ? theme.borderColors.GREY_56 : theme.borderColors.LIGHT_GREY_40
       };
 `};

  .additional-info-uploader:not(.ant-upload-disabled) {
    border: 1px solid ${({ theme }): string => theme.borderColors.LIGHT_GREY_40};
    background: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
    height: 115px;

    :hover {
      border-color: ${({ theme }): string => theme.borderColors.LIGHT_GREY_40};
    }
  }

  .img-preview-container {
    position: relative;
    width: 162px;
    .preview-img {
      width: 162px;
      height: 95px;
    }

    .img-cross-icon {
      position: absolute;
      top: -8px;
      right: -7px;
      z-index: 10;
      cursor: pointer;

      svg {
        circle {
          fill: ${({ isAdded }): any => isAdded && "#bfbfbf"};
        }
      }
    }
  }
`;
