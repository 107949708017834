import { useMemo } from "react";
import { Spin } from "antd";
import { useRequestWithMethod } from "../../api";

import { useGetRefByType } from "../../api/refservice";
import { useGetAllDataSources } from "../../api/sourcesservice";

import { useGetColumnsWithDistribution } from "../../api/tablesservice";
import { sortListOnSpecificKeyValue } from "../../utils";

import StateHandler from "../statehandler/statehandler";
import { RuleWrapperProps } from "./rulewrapper.types";

import { getAddRuleFormContent } from "../../forms/addruleform/addruleform.utils";
import Flex from "../flex";

import { dvSumnLogo } from "../../svgs";
import { RuleWrapperLoader } from "./rulewrapper.styles";

import {
  useGetSearchedNodeCat,
  useGetAllPatterns,
  useGetRefDictionariesList,
} from "../../api/ruleservice";
import { useGetReferneceTablesList } from "../../api/erddiagramservice/erddiagramservice";

const RuleWrapper = ({
  tableId,
  ruleCategoryId,
  formId,
  sourceId = 0,
  srcTypeId,
  columnId,
  propsOnCancel,
  isModal = false,
  isEdit = false,
  ruleConfig,
  onUpdateRule,
  isUpdateNodeLoading,
  isFromRecommendedRules,
  onAddRuleSuccess,
  rulesRef = [],
}: RuleWrapperProps): JSX.Element => {
  const {
    rule_type: ruleType,
    ref_src_id: refSrcId = 0,
    ref_tbl_id: refTableId = 0,
  } = ruleConfig || {};

  const isCompareMetricRule = ruleType === "CMM";

  const {
    parsedData: columnsData,
    isLoading: columnsDataLoading,
    error: columnsDataError,
  } = useGetColumnsWithDistribution(tableId);

  const {
    parsedData: sourcesList = [],
    isLoading: sourcesListLoading,
    error: sourcesListError,
  } = useGetAllDataSources();

  const {
    parsedData: patternList,
    isLoading: patternListLoading,
    error: patternListError,
  } = useGetAllPatterns();

  const {
    parsedData: parsedMetricTypes,
    isLoading: isLoadingMetricTypes,
    error: errorInGettingMetricTypes,
  } = useGetRefByType("METRIC_TYPES");

  const {
    parsedData: parsedDataFormatTypes,
    isLoading: isLoadingDataFormatTypes,
    error: errorInGettingDataFormatTypes,
  } = useGetRefByType("DATAFORMAT_TYPES");

  const {
    parsedData: parsedCompareSchema,
    isLoading: isLoadingCompareSchemaTypes,
    error: errorInGettingCompareSchemaTypes,
  } = useGetRefByType("CMP_SCH_OPTIONS");

  const {
    isLoading: isAddRuleLoading,
    onExecuteRequest: onAddRule,
    error: errorInAddingRule,
  } = useRequestWithMethod("add_rules", undefined, true, onAddRuleSuccess);

  const isCompareSchemaRule = formId === "CMS";

  const {
    parsedData: parsedSrcSchemaList = [],
    isLoading: isLoadingSrcSchema,
    error: parsedSrcSchemaError,
  } = useGetSearchedNodeCat("SCH", "", isCompareSchemaRule);

  const {
    isLoading: isLoadingRefTables = false,
    error: refrenceTablesListError,
  } = useGetReferneceTablesList(String(refSrcId), !!isCompareMetricRule);

  const {
    isLoading: refColumnListLoading,
    error: refColumnListError,
  } = useGetColumnsWithDistribution(String(refTableId));

  const {
    parsedData: refDictionariesList,
    isLoading: refDictListLoading,
    error: refDictListError,
  } = useGetRefDictionariesList();

  const sortedSourcesList = useMemo(
    () =>
      sortListOnSpecificKeyValue({
        list: sourcesList,
        key: "name",
      }),
    [sourcesList]
  );

  const columnSelectOptions = useMemo(() => {
    return (
      columnsData?.map((column) => ({
        key: `column-option-${column?.field_id}`,
        value: `${column?.field_id}`,
        label: column?.field_display_name || "",
        type: column?.field_datatype,
      })) || []
    );
  }, [columnsData]);

  const isPageLoading =
    columnsDataLoading ||
    sourcesListLoading ||
    isLoadingMetricTypes ||
    isLoadingDataFormatTypes ||
    isLoadingSrcSchema ||
    patternListLoading ||
    isLoadingCompareSchemaTypes ||
    isLoadingRefTables ||
    refColumnListLoading ||
    refDictListLoading;

  return (
    <StateHandler
      isFetching={
        isPageLoading || (isEdit ? isUpdateNodeLoading : isAddRuleLoading)
      }
      error={
        columnsDataError ||
        sourcesListError ||
        errorInGettingMetricTypes ||
        errorInGettingDataFormatTypes ||
        errorInAddingRule ||
        parsedSrcSchemaError ||
        patternListError ||
        errorInGettingCompareSchemaTypes ||
        refrenceTablesListError ||
        refColumnListError ||
        refDictListError
      }
      isModal={isModal}
      blankSlate={
        isModal ? undefined : (
          <RuleWrapperLoader>
            <Flex rowGap={18} direction="column">
              {dvSumnLogo}
              Hang on! Loading...
              <Spin size="large" />
            </Flex>
          </RuleWrapperLoader>
        )
      }
    >
      {getAddRuleFormContent({
        isEdit,
        formId,
        tableId,
        sourceId,
        rulesRef,
        columnId,
        srcTypeId,
        ruleConfig,
        patternList,
        columnsData,
        ruleCategoryId,
        isPageLoading,
        columnSelectOptions,
        refDictionariesList,
        parsedSrcSchemaList,
        isFromRecommendedRules,
        sourcesList: sortedSourcesList,
        metricTypes: parsedMetricTypes,
        dataFormatTypes: parsedDataFormatTypes,
        compareSchemaTypes: parsedCompareSchema,
        onAddRule,
        onUpdateRule,
        propsOnCancel,
      })}
    </StateHandler>
  );
};

export default RuleWrapper;
