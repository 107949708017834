import * as yup from "yup";
import { REQUIRED_MESSAGE } from "../../constants/formconstants";
import { removeSpaces } from "../removespaces";

const MIN_LIMIT_MSG = "The explanation must be at least 10 characters.";

export const trainbotFeedbackFormSchema = (isChatPage: boolean): any => {
  return yup.object().shape({
    response: yup.string().required(),
    comment: yup.string(),
    reason: yup.string(),
    train_me: yup.lazy((_, p1) => {
      if (
        p1?.parent?.response === "dislike" &&
        !p1?.parent?.train_sql &&
        !isChatPage
      ) {
        return yup.string().required("").min(10, MIN_LIMIT_MSG);
      }

      return yup.string().matches(/.{10,}/, {
        excludeEmptyString: true,
        message: MIN_LIMIT_MSG,
      });
    }),
    train_sql: yup.lazy((_, p1) => {
      if (
        p1?.parent?.response === "dislike" &&
        !p1?.parent?.train_me &&
        !isChatPage
      ) {
        return yup.string().required(REQUIRED_MESSAGE);
        // ?.test("This is not a valid query.", "", (val = ""): boolean => {
        //   console.log("r", isValidQuery(val));
        //   return isValidQuery(val);
        // });
      }
      return yup.string();
    }),
  });
};
