import { memo } from "react";
import { getObjectKeys, numberFormatter } from "../../../utils";
import { CustomizedLabelProps } from "./customizedlabel.types";

const CustomizedLabel: React.FC<CustomizedLabelProps> = ({ props }) => {
  const {
    x,
    y,
    width,
    height,
    index,
    data,
    key,
    isStacked,
    chartType,
    value,
    labelInterval,
    lineIndex = 0,
    name = "",
    listOfDataKeys = [],
  } = props;

  // Only render label on certain intervals
  if (index % labelInterval !== 0) return null;

  let shouldRender = true;

  // Handle stacked charts
  if (isStacked) {
    const currentItemObjectKeys = getObjectKeys(
      data?.find((item) => item?.name === name)
    );

    const isLastItem =
      listOfDataKeys
        ?.filter((item) => {
          return currentItemObjectKeys?.includes(item);
        })
        ?.slice(-1)?.[0] === key?.dataKey;

    const size = chartType === "COL" ? height : width;
    if (isLastItem) {
      shouldRender = true;
    } else if (size < 10 && getObjectKeys(data?.[index] ?? {}).length <= 1) {
      shouldRender = true;
    } else if (size < 10) {
      shouldRender = false;
    }
  }

  if (!shouldRender) return null;

  // Common calculations for positioning
  const isBarChart = chartType === "BAR";
  const isColumnChart = chartType === "COL";
  const isLineChart = chartType === "LIN";

  let calculatedY = y;
  if (isLineChart) {
    const totalLines = getObjectKeys(data?.[0])?.filter(
      (val) => val !== "name" && val !== "fill"
    )?.length;
    if (totalLines === 2 || totalLines === 1) {
      calculatedY = lineIndex % 2 === 0 ? y - 10 : y + 10;
    } else if (totalLines === 3) {
      if (lineIndex === 0) calculatedY = y - 8;
      else if (lineIndex === 1) calculatedY = y - 20;
      else calculatedY = y + 12;
    }
  } else {
    calculatedY = isBarChart
      ? y + height / 2
      : isColumnChart
      ? isStacked && height > 10
        ? y + height / 2
        : y - 7.5
      : y;
  }

  const calculatedDx = isBarChart
    ? isStacked && width > 15
      ? width / 2
      : width + 8
    : isColumnChart
    ? width / 2
    : undefined;

  const fillColor = isStacked
    ? (isColumnChart && height > 10) || (isBarChart && width > 15)
      ? "#fff"
      : "#000"
    : "#000";

  const strokeColor =
    isStacked && ((isColumnChart && height > 10) || (isBarChart && width > 15))
      ? "black"
      : "white";

  // Format the value for display
  const formattedValue =
    isBarChart || isColumnChart
      ? data[index]?.[key?.dataKey] !== undefined
        ? numberFormatter(data[index]?.[key?.dataKey])
        : undefined
      : numberFormatter(String(value));

  // Determine if the label should be on the right or left
  const textAnchor =
    (chartType === "BAR" && !isStacked) || (isBarChart && width < 15)
      ? "start"
      : "middle";

  return (
    <text
      x={
        chartType === "COL" &&
        index === 0 &&
        String(data[index]?.[key?.dataKey])?.length > 3
          ? x
          : x
      }
      y={calculatedY}
      dx={calculatedDx}
      fill={fillColor}
      fontFamily="OpenSansSemiBold"
      textAnchor={textAnchor}
      alignmentBaseline="middle"
      fontSize="11px"
      stroke={strokeColor}
      strokeWidth="0.05px"
      style={
        isStacked
          ? isBarChart &&
            width > 15 &&
            getObjectKeys(data?.[index] ?? {})?.filter(
              (val) => val !== "name" && val !== "fill"
            ).length >= 1
            ? { textShadow: "1px 1px 2px #000" }
            : isColumnChart &&
              height > 10 &&
              getObjectKeys(data?.[index] ?? {})?.filter(
                (val) => val !== "name" && val !== "fill"
              ).length >= 1
            ? { textShadow: "1px 1px 2px #000" }
            : {}
          : {}
      }
    >
      {formattedValue}
    </text>
  );
};

export default memo(CustomizedLabel);
