import { getChatPrefrencesData } from "./getchatprefrencesdata";
import { getPostLoginData } from "./getpostlogindata";
import { isUserPrefferedPercentageMetricColumn } from "./isuserprefferedpercentagemetriccolumn";

export const isDvsumPrefferedAmountMetricColumn = (name: string): boolean => {
  const { user_info: userInfo } = getPostLoginData();
  const { metric_format: metricFormat } = getChatPrefrencesData();

  const {
    cols_to_ignored_dvsum_defaut_logic: colsToIgnoredDvsumDefautLogic = [],
  } = metricFormat || {};

  const { col_names_for_amount: dvsumProvidedColNamesForAmount = [] } =
    userInfo?.non_metric_keywords || {};

  const regexForAmount = new RegExp(
    `(${dvsumProvidedColNamesForAmount?.join("|")})`,
    "i"
  );

  try {
    const nameWithoutUnderscore = name
      ?.toLocaleLowerCase()
      ?.replaceAll(/_/g, " ");

    const listWithoutUnderScore = colsToIgnoredDvsumDefautLogic?.map((item) =>
      item?.toLocaleLowerCase()?.replaceAll(/_/g, " ")
    );

    return (
      regexForAmount?.test(nameWithoutUnderscore) &&
      !!dvsumProvidedColNamesForAmount?.length &&
      !listWithoutUnderScore?.includes(nameWithoutUnderscore) &&
      !isUserPrefferedPercentageMetricColumn(name)
    );
  } catch {
    return false;
  }
};
