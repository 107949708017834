import { useMemo } from "react";
import { SimpleTag } from "..";
import { range } from "../../../utils";
import { SimpleTagListStyled } from "./simpletaglist.styles";
import { SimpleTagListProps } from "./simpletaglist.types";

const SimpleTagList = ({
  tags = [],
  limit = 1,
  tagsDirection = "row",
  className,
  ...simpleTagProps
}: SimpleTagListProps): JSX.Element => {
  const renderTags = useMemo(
    () =>
      (tags?.length &&
        range(0, limit - 1).map((index) => (
          <SimpleTag key={`key-tag-tbl-${tags[index]}`} {...simpleTagProps}>
            {tags[index]}
          </SimpleTag>
        ))) ||
      "",
    [tags]
  );

  const moreTags = useMemo(
    () =>
      tags?.length > limit && (
        <SimpleTag
          {...simpleTagProps}
          title={tags.slice(limit, tags?.length).join(", ")}
          className="bold-text"
        >
          +{tags?.length - limit}
        </SimpleTag>
      ),
    [tags]
  );

  return (
    <SimpleTagListStyled className={className} tagsDirection={tagsDirection}>
      {renderTags}
      {moreTags}
    </SimpleTagListStyled>
  );
};

export default SimpleTagList;
