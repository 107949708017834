// <--- Library Imports Start --->
import ReactDOM from "react-dom";
import Amplify, { Hub, Auth } from "aws-amplify";

import { StrictMode } from "react";
import { LicenseManager } from "ag-grid-enterprise";
// <--- Library Imports End --->

// <--- Components Start --->
import App from "./App";
// <--- Components End --->

// <--- Constants Start --->
import { awsConfig, awsAuth } from "./constants/awsuserpoolconfig";
import { LOCAL_STORAGE_CONSTANTS } from "./constants/localstorageconstants";
// <--- Constants End --->

// <--- Utils Start --->
import reportWebVitals from "./reportWebVitals";
import { getEnvVariables } from "./utils";
// <--- Utils End --->

// import { worker } from "./mocks/browser";

// if (process.env.NODE_ENV === "development") {
//   worker.start();
// }

const { REACT_APP_AG_GRID_LICENCE_KEY } = getEnvVariables();

LicenseManager.setLicenseKey(REACT_APP_AG_GRID_LICENCE_KEY);

Amplify.configure(awsConfig);
Auth.configure({ oauth: awsAuth });

Hub.listen("auth", ({ payload: { event, data } }) => {
  const { accessToken = {}, refreshToken = {}, idToken = {} } =
    data?.signInUserSession || {};
  switch (event) {
    case "signIn":
      localStorage.setItem(
        LOCAL_STORAGE_CONSTANTS?.access_token,
        accessToken?.jwtToken
      );
      localStorage.setItem(
        LOCAL_STORAGE_CONSTANTS?.username,
        accessToken?.payload?.username
      );
      localStorage.setItem(
        LOCAL_STORAGE_CONSTANTS?.id_token,
        idToken?.jwtToken
      );
      localStorage.setItem(
        LOCAL_STORAGE_CONSTANTS?.email,
        idToken?.payload?.email
      );
      localStorage.setItem(
        LOCAL_STORAGE_CONSTANTS?.refresh_token,
        refreshToken?.token
      );
      localStorage.setItem(
        LOCAL_STORAGE_CONSTANTS?.exp_time_in_milli_seconds,
        `${new Date(0).setSeconds(accessToken?.payload?.exp || 0) - 60000}`
      );
      break;
    default:
    // console.log("event", event, data);
  }
});

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
