export const DVSUM_COLOR_PALLETE = [
  "#2196F3",
  "#FF5722",
  "#7E57C2",
  "#4AD0D9",
  "#4CAF50",
  "#E91E63",
  "#FFA726",
  "#78909C",
  "#CE93D8",
  "#D4E157",
  "#9D8DFA",
  "#2F97C1",
];

export const DVSUM_CHART_COLOR_PALLETE = ["#FF6915", "#428bca"];
export const DASHBOARD_LINE_CHART_COLOR_PALLETE = [
  "#1C4262",
  "#EDAA51",
  "#3FC3CC",
];
export const DASHBOARD_SINGLE_SLICER_COLOR = ["#428BCA"];

export const DASHBOARD_MULTIPLE_SLICER_COLOR = [
  "#1C4262",
  "#2C6290",
  "#428BCA",
  "#50A8F4",
  "#3FC3CC",
  "#2D9EB2",
  "#2283A1",
  "#18668A",
];
// export const DASHBOARD_MULTIPLE_SLICER_COLOR = [
//   "#428BCA",
//   "#73AA6A",
//   "#D15B47",
//   "#78909C",
//   "#EDAA51",
//   "#96EBE3",
//   "#D4E157",
//   "#B05D6F",
//   "#3380A6",
// ];
