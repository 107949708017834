import { getErrorMessage } from "./utils/errors";

export class CustomException extends Error {
  status: number;
  formattedMsg: string;
  errorTitle: string;
  errorResponse: any;
  errorMessage: any;

  constructor(error: any) {
    super(error);
    this.status = error?.response?.status;
    this.formattedMsg = getErrorMessage(error);
    this.errorTitle = error?.response?.data?.error;
    this.errorResponse = error?.response?.data?.account_admins;
    this.errorMessage = error?.response?.data?.message;
  }
}
