import { TabItemStyled, TabsWrapperStyled } from "./tabslayout.styles";
import { TabsLayoutProps } from "./tabslayout.types";

const TabsLayout = (props: TabsLayoutProps): JSX.Element => {
  const { tabsList, onChangeTab, selectedTab, renderPanel, title } = props;
  return (
    <TabsWrapperStyled>
      {title && <p className="module-settings-heading">{title}</p>}
      <div className="form-wrapper">
        <div className="tabs-ul">
          {tabsList?.map((item) => {
            return (
              <TabItemStyled
                isSelected={selectedTab === item?.key}
                className="tab-item"
                key={`tab-name-${item?.key}`}
                onClick={(): void => onChangeTab?.(item?.key)}
              >
                {item?.value}
              </TabItemStyled>
            );
          })}
        </div>
        <div className="tabs-panel">{renderPanel()}</div>
      </div>
    </TabsWrapperStyled>
  );
};

export default TabsLayout;
