import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import {
  Button,
  ContentPreviewer,
  JobApiInfoGetPreviewer,
  JobApiInfoPostPreviewer,
} from "../../../../components";

import {
  AddNewJobViaExecuteApiStyled,
  ApiInformationContentStyled,
  ApiInformationLeftContentStyled,
  ApiInformationRightContentStyled,
  ApiInformationStepContentStyled,
} from "./addnewjobviaexecuteapi.styles";

import { AddNewJobViaExecuteApiProps } from "./addnewjobviaexecuteapi.types";
import { getEnvVariables } from "../../../../utils";
import { getJobApiInformation } from "../../../../pages/jobdetailpage/jobdetailpage.views/jobdetailpagecontent/jobdetailpagecontent.util";

const AddNewJobViaExecuteApi = (
  props: AddNewJobViaExecuteApiProps
): JSX.Element => {
  const {
    onGenerateApiUrl,
    generatingApiUrl,
    jobId = 0,
    isEdit = false,
  } = props;

  const { REACT_APP_API_DOMAIN } = getEnvVariables();

  const { getReqWithId, postReqWithId } = getJobApiInformation(String(jobId));

  const stepOnePostReqCopycontent = `curl -X POST ${REACT_APP_API_DOMAIN}/integration/jobs/{job-id}/execute -H "x-api-key: API_KEY"  -H  "Content-Type: application/json" \\`;

  const stepTwoGetReqCopycontent = `curl -X GET ${REACT_APP_API_DOMAIN}/integration/jobs/{execution-id}/details -H "x-api-key: API_KEY"  -H "Content-Type: application/json"\\ `;

  const antIcon = <LoadingOutlined style={{ fontSize: 12 }} spin />;

  const postRequestStaticJsx = (
    <JobApiInfoPostPreviewer jobId={String(jobId)} isStatic />
  );
  const postRequestJsx = <JobApiInfoPostPreviewer jobId={String(jobId)} />;

  const getRequestStaticJsx = (
    <JobApiInfoGetPreviewer jobId={String(jobId)} isStatic />
  );
  const getRequestJsx = (
    <JobApiInfoGetPreviewer jobId={String(jobId)} isStatic />
  );

  const stepTowDummyResponseJSx = (
    <div className="response">
      <p>{" { "}</p>
      <p>"execution_id": 123456,</p>
      <p>"execution_status": "completed",</p>
      <p>"execution_details":</p>
      <p>{" { "}</p>
      <p>"remarks": "DQ-0001626 Passed.</p>
      <p>System Remarks: 0 exceptions",</p>
      <p>"status": "pass",</p>
      <p>"results": "0",</p>
      <p>{"}"},</p>
      <p>{"}"},</p>
    </div>
  );

  return (
    <AddNewJobViaExecuteApiStyled>
      <ApiInformationContentStyled>
        <ApiInformationLeftContentStyled>
          <div className="button-spninner-wrapper">
            <Button
              onClick={onGenerateApiUrl}
              disabled={generatingApiUrl || !!jobId}
            >
              {generatingApiUrl && (
                <Spin
                  spinning={generatingApiUrl}
                  indicator={antIcon}
                  size="small"
                />
              )}
              Generate API URL
            </Button>
          </div>

          {!!jobId && (
            <>
              <ContentPreviewer
                title="Execute Request"
                sqlQuery={postReqWithId}
                customJsx={postRequestJsx}
                showCrossIcon={false}
                marginBottom="0"
              />
              <ContentPreviewer
                title="Details Request"
                sqlQuery={getReqWithId}
                customJsx={getRequestJsx}
                showCrossIcon={false}
              />
            </>
          )}
        </ApiInformationLeftContentStyled>
        <ApiInformationRightContentStyled>
          <ApiInformationStepContentStyled>
            <div className="step-name">Step 1: Start Job</div>

            <div className="request-headers-info">
              <div className="request-type">POST:</div>
              <div className="request-url">
                {"apis.dvsum.ai/integration/jobs/{job_id}/execute"}
              </div>
            </div>

            <div className="request-body">
              <div className="request-body-left">Authentication:</div>
              <div className="request-body-right">x-api-key (string)</div>

              <div className="request-body-left">Path parameter:</div>
              <div className="request-body-right">job-id (number)</div>
              <div className="request-body-left">Response Format:</div>
              <div className="request-body-right">
                execution_id (number) <br />
                execution_status (string)
              </div>
            </div>

            <ContentPreviewer
              title="Execute Request"
              sqlQuery={stepOnePostReqCopycontent}
              showCrossIcon={false}
              marginBottom="0"
              customJsx={postRequestStaticJsx}
              allowCopying={false}
            />
            <ContentPreviewer
              title="Response"
              sqlQuery='{
                        "execution_id": 123456,
                        "execution_status": "running"
                        }'
              showCrossIcon={false}
              allowCopying={false}
            />
          </ApiInformationStepContentStyled>

          <ApiInformationStepContentStyled>
            <div className="step-name">Step 2: Check Job Status</div>

            <div className="request-headers-info">
              <div className="request-type">GET:</div>
              <div className="request-url">
                {"apis.dvsum.ai/integration/jobs/{execution_id}/details"}
              </div>
            </div>

            <div className="request-body">
              <div className="request-body-left">Authentication:</div>
              <div className="request-body-right">x-api-key (string)</div>

              <div className="request-body-left">Path parameter:</div>
              <div className="request-body-right">execution-id (number)</div>

              <div className="request-body-left">Response Format:</div>
              <div className="request-body-right">
                execution_id (number)
                <br />
                execution_status (string)
                <br />
                execution_details (object)
                <ul>
                  <li>remarks (string)</li>
                  <li>status (string)</li>
                  <li>results (integer)</li>
                </ul>
              </div>
            </div>

            <ContentPreviewer
              title="Details Request"
              sqlQuery={stepTwoGetReqCopycontent}
              customJsx={getRequestStaticJsx}
              showCrossIcon={false}
            />
            <ContentPreviewer
              title="Response"
              customJsx={stepTowDummyResponseJSx}
              showCrossIcon={false}
              sqlQuery=""
              allowCopying={false}
            />
          </ApiInformationStepContentStyled>
        </ApiInformationRightContentStyled>
      </ApiInformationContentStyled>
    </AddNewJobViaExecuteApiStyled>
  );
};

export default AddNewJobViaExecuteApi;
