import { format, isDate } from "date-fns";
import { SourcesParserReturnType } from "../../../../../../../parsers/sources/sourcesparser.types";
import { isNumber } from "../../../../../../../utils";

function getDayNumberFromDate(activationDate: Date): number {
  return activationDate.getDate();
}

const daysOfWeekKeys: { [key: string]: string } = {
  SUN: "sunday",
  MON: "monday",
  TUE: "tuesday",
  WED: "wednesday",
  THU: "thursday",
  FRI: "friday",
  SAT: "saturday",
};

function getDayNameFromDate(activationDate: Date): string {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const prefixes: string[] = ["First", "Second", "Third", "Fourth", "Fifth"];

  // $("#repeatByDayOfweek").val((0 | (date.getDate() / 7)) + 1);
  return `${prefixes[parseInt(`${activationDate.getDate() / 7}`)]} ${
    days[activationDate.getDay()]
  }`;
}

function returnWeeklyDays(selectedWeekDays: string[]): string {
  const weekDaysCount = selectedWeekDays?.length;

  if (!weekDaysCount) {
    return daysOfWeekKeys.MON;
  }

  return weekDaysCount > 6
    ? "all days"
    : selectedWeekDays
        ?.map((weekDay: string) => daysOfWeekKeys[weekDay])
        .join(", ");
}

function getWeeklySummary(
  interval: number,
  startOnTime: string,
  ends: string,
  selectedWeekDays: string[],
  endAfterRepeatingXTimes: string,
  endsOnDate: any
): string {
  const selectedWeekdays = returnWeeklyDays(selectedWeekDays);

  if (selectedWeekdays === "all days") {
    return `Weekly on all days at ${startOnTime}`;
  }

  if (interval === 1) {
    if (ends === "never") {
      return `Weekly on ${selectedWeekdays} at ${startOnTime}`;
    }

    if (ends === "after") {
      if (endAfterRepeatingXTimes !== "" && isNumber(endAfterRepeatingXTimes)) {
        return `Weekly on ${selectedWeekdays} at ${startOnTime}, ${endAfterRepeatingXTimes} times`;
      }
      return `Weekly on ${selectedWeekdays} at ${startOnTime}`;
    }

    if (ends === "on") {
      if (endsOnDate && isDate(endsOnDate)) {
        return `Weekly on ${selectedWeekdays} at ${startOnTime}, until ${format(
          endsOnDate,
          "MM/dd/yyyy"
        )}`;
      }

      return `Weekly on ${selectedWeekdays} at ${startOnTime}`;
    }

    return "";
  }

  if (interval > 1) {
    if (ends === "never") {
      return `Every ${interval} weeks on ${selectedWeekdays} at ${startOnTime}`;
    }

    if (ends === "after") {
      if (endAfterRepeatingXTimes !== "" && isNumber(endAfterRepeatingXTimes)) {
        return `Every ${interval} weeks on ${selectedWeekdays} at ${startOnTime}, ${endAfterRepeatingXTimes} times`;
      }

      return `Every ${interval} weeks on ${selectedWeekdays} at ${startOnTime}`;
    }

    if (ends === "on") {
      if (endsOnDate && isDate(endsOnDate)) {
        return `Every ${interval} weeks on ${selectedWeekdays} at ${startOnTime}, until ${format(
          endsOnDate,
          "MM/dd/yyyy"
        )}`;
      }

      return `Every ${interval} weeks ${selectedWeekdays} at ${startOnTime}`;
    }

    return "";
  }
  return "";
}

function getMonthlySummary(
  interval: number,
  startOnTime: string,
  activationDate: Date,
  daysOfMonth: string,
  ends: string,
  endAfterRepeatingXTimes: string,
  endsOnDate: any
): string {
  if (interval === 1) {
    if (ends === "never") {
      if (daysOfMonth === "DOM") {
        return `Monthly on day ${activationDate.getDate()} at ${startOnTime}`;
      }

      return `Monthly on ${getDayNameFromDate(
        activationDate
      )} at ${startOnTime}`;
    }

    if (ends === "after") {
      if (endAfterRepeatingXTimes !== "" && isNumber(endAfterRepeatingXTimes)) {
        if (daysOfMonth === "DOM") {
          return `Monthly on day ${getDayNumberFromDate(
            activationDate
          )} at ${startOnTime}, ${endAfterRepeatingXTimes} times`;
        }

        return `Monthly on ${getDayNameFromDate(
          activationDate
        )} at ${startOnTime}, ${endAfterRepeatingXTimes} times`;
      }

      if (daysOfMonth === "DOM") {
        return `Monthly on day ${getDayNumberFromDate(
          activationDate
        )} at ${startOnTime}`;
      }

      return `Monthly on ${getDayNameFromDate(
        activationDate
      )} at ${startOnTime}`;
    }

    if (ends === "on") {
      if (endsOnDate && isDate(endsOnDate)) {
        if (daysOfMonth === "DOM") {
          return `Monthly on day ${getDayNumberFromDate(
            activationDate
          )} at ${startOnTime}, until ${format(endsOnDate, "MM/dd/yyyy")}`;
        }

        return `Monthly on day ${getDayNameFromDate(
          activationDate
        )} at ${startOnTime}, until ${format(endsOnDate, "MM/dd/yyyy")}`;
      }

      if (daysOfMonth === "DOM") {
        return `Monthly on day ${getDayNumberFromDate(
          activationDate
        )} at ${startOnTime}`;
      }

      return `Monthly on day ${getDayNameFromDate(
        activationDate
      )} at ${startOnTime}`;
    }
  }

  if (interval > 1) {
    if (ends === "never") {
      if (daysOfMonth === "DOM") {
        return `Every ${interval} months on day ${activationDate.getDate()} at ${startOnTime}`;
      }

      return `Every ${interval} months on ${getDayNameFromDate(
        activationDate
      )} at ${startOnTime}`;
    }

    if (ends === "after") {
      if (endAfterRepeatingXTimes !== "" && isNumber(endAfterRepeatingXTimes)) {
        if (daysOfMonth === "DOM") {
          return `Every ${interval} months on day ${getDayNumberFromDate(
            activationDate
          )} at ${startOnTime}, ${endAfterRepeatingXTimes} times`;
        }

        return `Every ${interval} months on ${getDayNameFromDate(
          activationDate
        )} at ${startOnTime}, ${endAfterRepeatingXTimes} times`;
      }

      if (daysOfMonth === "DOM") {
        return `Every ${interval} months on day ${getDayNumberFromDate(
          activationDate
        )} at ${startOnTime}`;
      }

      return `Every ${interval} months on ${getDayNameFromDate(
        activationDate
      )} at ${startOnTime}`;
    }

    if (ends === "on") {
      if (endsOnDate && isDate(endsOnDate)) {
        if (daysOfMonth === "DOM") {
          return `Every ${interval} months on day ${getDayNumberFromDate(
            activationDate
          )} at ${startOnTime}, until ${format(endsOnDate, "MM/dd/yyyy")}`;
        }

        return `Every ${interval} months on ${getDayNameFromDate(
          activationDate
        )} at ${startOnTime}, until ${format(endsOnDate, "MM/dd/yyyy")}`;
      }

      if (daysOfMonth === "DOM") {
        return `Every ${interval} months on day ${getDayNumberFromDate(
          activationDate
        )} at ${startOnTime}`;
      }

      return `Every ${interval} months on ${getDayNameFromDate(
        activationDate
      )} at ${startOnTime}`;
    }
  }
  return "";
}

function getDailySummary(
  interval: number,
  startOnTime: string,
  ends: string,
  endAfterRepeatingXTimes: string,
  endsOnDate: any
): string {
  if (interval === 1 && ends === "never") {
    return `Daily at ${startOnTime}`;
  }

  if (interval === 1 && ends === "after") {
    if (endAfterRepeatingXTimes !== "" && isNumber(endAfterRepeatingXTimes)) {
      return `Daily at ${startOnTime}, ${endAfterRepeatingXTimes} times`;
    }

    return `Daily at ${startOnTime}`;
  }

  if (interval === 1 && ends === "on") {
    if (endsOnDate && isDate(endsOnDate)) {
      return `Daily  at ${startOnTime}, until ${format(
        endsOnDate,
        "MM/dd/yyyy"
      )}`;
    }

    return `Daily at ${startOnTime}`;
  }

  if (interval > 1 && ends === "never") {
    return `Every ${interval} days at ${startOnTime}`;
  }

  if (interval > 1 && ends === "after") {
    if (endAfterRepeatingXTimes !== "" && isNumber(endAfterRepeatingXTimes)) {
      return `Every ${interval} days at ${startOnTime}, ${endAfterRepeatingXTimes} times`;
    }

    return `Every ${interval} days at ${startOnTime}`;
  }

  if (interval > 1 && ends === "on") {
    if (endsOnDate && isDate(endsOnDate)) {
      return `Every ${interval} days at ${startOnTime}, until ${format(
        endsOnDate,
        "MM/dd/yyyy"
      )}`;
    }

    return `"Every ${interval} days at ${startOnTime}`;
  }

  return "";
}

function summaryText(
  formValues: SourcesParserReturnType["scanInfo"] & {
    ends: string;
    startOnTime: string | Date;
  }
): string {
  const {
    activationDate,
    startOnTime,
    frequencyType,
    ends,
    interval = "1",
    daysOfWeek,
    daysOfMonth,
    endAfterRepeatingXTimes,
    endsOnDate,
  } = formValues;

  const startOnTimeStr = isDate(startOnTime)
    ? format(startOnTime as Date, "hh:mm a")
    : startOnTime;

  if (frequencyType === "OTM") {
    return `On ${format(
      new Date(activationDate),
      "MM/dd/yyyy"
    )} at ${startOnTimeStr}`;
  }

  if (frequencyType === "DLY") {
    return getDailySummary(
      interval ? Number(interval) : 0,
      startOnTimeStr,
      ends,
      endAfterRepeatingXTimes,
      endsOnDate && new Date(endsOnDate)
    );
  }

  if (frequencyType === "WLY") {
    return getWeeklySummary(
      interval ? Number(interval) : 0,
      startOnTimeStr,
      ends,
      daysOfWeek,
      endAfterRepeatingXTimes,
      endsOnDate && new Date(endsOnDate)
    );
  }

  if (frequencyType === "MLY") {
    return getMonthlySummary(
      interval ? Number(interval) : 0,
      startOnTimeStr,
      new Date(activationDate),
      daysOfMonth,
      ends,
      endAfterRepeatingXTimes,
      endsOnDate && new Date(endsOnDate)
    );
  }

  return "";
}

export function getScanSummary(formValues: any): string {
  return (formValues && summaryText(formValues)) || "";
}
