import { Checkbox } from "antd";
import styled from "styled-components";
import { CheckboxProps, ReturnTypeOfStyleMapping } from "./checkbox.types";

const colorsMapping = (
  theme: any,
  id: CheckboxProps["mode"] = "primary"
): ReturnTypeOfStyleMapping => {
  const colorsMappingId: {
    [key in Exclude<CheckboxProps["mode"], undefined>]: any;
  } = {
    primary: {
      borderRadius: "2px",
      bgColor: "",
      checkedBgColor: theme.bgColors.BLUE_116,
      checkedHoverBgColor: theme.bgColors.BLUE_116,
      borderColor: theme.borderColors.GREY_100,
      checkedBorderColor: theme.borderColors.BLUE_116,
      checkedTickColor: theme.borderColors.WHITE_COLOR,
    },
    secondary: {
      borderRadius: "2px",
      bgColor: "",
      checkedBgColor: theme.bgColors.BLUE_116,
      checkedHoverBgColor: theme.bgColors.BLUE_125,
      borderColor: theme.borderColors.GREY_100,
      hoverBorderColor: theme.borderColors.GREY_100,
      checkedHoverBorderColor: theme.borderColors.GREY_100,
      checkedBorderColor: theme.borderColors.BLUE_116,
      checkedTickColor: theme.borderColors.WHITE_COLOR,
    },
    tertiary: {
      borderRadius: "0px",
      bgColor: theme.bgColors.LIGHT_BLUE_5,
      checkedBgColor: theme.bgColors.LIGHT_BLUE_45,
      checkedHoverBgColor: theme.bgColors.LIGHT_BLUE_45,
      borderColor: theme.borderColors.GREY_100,
      hoverBorderColor: theme.borderColors.YELLOW_RD,
      checkedHoverBorderColor: theme.borderColors.YELLOW_RD,
      checkedBorderColor: theme.borderColors.GREY_100,
      checkedTickColor: theme.borderColors.BLUE_55,
    },
  };
  return colorsMappingId[id];
};

export const CheckboxStyled = styled(Checkbox)<{
  width?: string;
  height?: string;
  mode?: CheckboxProps["mode"];
}>`
  &.ant-checkbox-wrapper {
    .ant-checkbox {
      .ant-checkbox-inner {
        width: ${(props): string => props?.width || "16px"};
        height: ${(props): string => props?.height || "16px"};
        border: 1px solid
          ${(props): string =>
            colorsMapping(props.theme, props?.mode).borderColor};
        transition: unset;
        background: ${(props): string =>
          colorsMapping(props.theme, props?.mode).bgColor};
        border-radius: ${(props): string =>
          colorsMapping(props.theme, props?.mode).borderRadius};

        ::after {
          transition: unset;
        }
      }

      :hover {
        .ant-checkbox-inner {
          border-color: ${(props): string =>
            colorsMapping(props.theme, props?.mode).hoverBorderColor};
        }
      }
    }

    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background: ${(props): string =>
          colorsMapping(props.theme, props?.mode).checkedBgColor};
        border-color: ${(props): string =>
          colorsMapping(props.theme, props?.mode).checkedBorderColor};

        ::after {
          border-color: ${(props): string =>
            colorsMapping(props.theme, props?.mode).checkedTickColor};
        }
      }

      :hover {
        .ant-checkbox-inner {
          background: ${(props): string =>
            colorsMapping(props.theme, props?.mode).checkedHoverBgColor};
          border-color: ${(props): string =>
            colorsMapping(props.theme, props?.mode).checkedHoverBorderColor};
        }
      }

      ::after {
        animation: unset;
        border: none;
      }
    }

    .ant-checkbox-indeterminate {
      .ant-checkbox-inner {
        ::after {
          background: ${({ theme }): string => theme?.textColors?.WHITE_COLOR};
          height: 2px;
        }
      }
    }
  }
  &.ant-checkbox-wrapper-disabled {
    .ant-checkbox {
      .ant-checkbox-inner {
        opacity: 0.5;
      }
    }
  }
`;
