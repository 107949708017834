import { Tooltip } from "antd";
import { useCallback, useState } from "react";

import {
  ActionItemStyled,
  DropDownButtonStyled,
  DropDownWrapperStyled,
  ThreeDotActionMenuStyled,
  ThreeDotMenuButton,
} from "./threedotactionmenu.styles";
import { actionsIcon } from "../../svgs";

import { ThreeDotActionMenuProps } from "./threedotactionmenu.types";
import { DVSUM_TOOLTIP_CLASS_NAME } from "../../constants";
import { DeleteViewTooltip } from "../../forms/addgovernanceviewform/addgovernanceviewform.components";
import { useGetAppState } from "../../customhooks";

const threeDotActionIcon = actionsIcon();

const ThreeDotActionMenu = (props: ThreeDotActionMenuProps): JSX.Element => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const { isOnboardingMode } = useGetAppState();

  const { overLayItems, overLayId = "", threeDotsMenuId = "" } = props;

  const onVisibleChange = useCallback(
    (visibility) => {
      setIsMenuVisible(isOnboardingMode ? true : visibility);
    },
    [isOnboardingMode]
  );

  return (
    <ThreeDotActionMenuStyled>
      <DropDownWrapperStyled isVisible={isMenuVisible}>
        <DropDownButtonStyled
          overlay={
            <div id={overLayId}>
              {overLayItems?.map((item) => {
                const {
                  name,
                  icon,
                  onClick,
                  customClass = "",
                  disable,
                  isDelete,
                  handleDelete,
                  tooltipTitle = "",
                  id,
                } = item;

                const onMenuItemClick = (): void => {
                  onClick?.();
                  setIsMenuVisible(false);
                };

                return (
                  <Tooltip
                    key={`overlay-item-${name}`}
                    getPopupContainer={(trigger): HTMLElement =>
                      trigger?.parentNode as HTMLElement
                    }
                    title={
                      isDelete ? (
                        <DeleteViewTooltip
                          handleCancel={(): void => setIsMenuVisible(false)}
                          handleDelete={(): void => {
                            handleDelete?.();
                            setIsMenuVisible(false);
                          }}
                          confirmationText="This action cannot be reversed. Are you sure?"
                          btnText="Delete"
                          title={tooltipTitle}
                        />
                      ) : (
                        item?.desc
                      )
                    }
                    placement="left"
                    overlayClassName={`${DVSUM_TOOLTIP_CLASS_NAME}`}
                  >
                    <ActionItemStyled
                      elementId={id}
                      onClick={onMenuItemClick}
                      key={`overlay-item-${name}`}
                      className={`action-item ${customClass}`}
                      disabled={disable}
                    >
                      {icon} {name}
                    </ActionItemStyled>
                  </Tooltip>
                );
              })}
            </div>
          }
          trigger={["click"]}
          placement="bottomRight"
          onVisibleChange={onVisibleChange}
          visible={isMenuVisible}
          getPopupContainer={(trigger): HTMLElement =>
            trigger.parentNode as HTMLElement
          }
        >
          <ThreeDotMenuButton
            className="actions-container"
            onClick={(e): void => {
              setIsMenuVisible(!isMenuVisible);
            }}
            hasCommonStyle={false}
            elementId={threeDotsMenuId}
          >
            <div className="action-icon"> {threeDotActionIcon}</div>
          </ThreeDotMenuButton>
        </DropDownButtonStyled>
      </DropDownWrapperStyled>
    </ThreeDotActionMenuStyled>
  );
};

export default ThreeDotActionMenu;
