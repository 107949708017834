import styled from "styled-components";

export const OverviewSuggestAnEditStyled = styled.div<{ isRefPage: boolean }>`
  .suggest-header {
    ${({ isRefPage }): any => isRefPage && `font-family: OpenSansSemiBold;`}
    height: 70px;
    padding: 0 31px 0 27px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${({ theme }): string => theme.textColors.BLUE_116};
    font-size: 26px;
    svg {
      path {
        fill: ${({ theme }): string => theme.bgColors.RED_100};
        opacity: 0.5;
      }

      :hover {
        path {
          opacity: unset;
        }
      }
    }
  }

  .suggest-item-1,
  .suggest-item-2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px #f0f0f0;
    padding: 25px 25px 34px 27px;
    cursor: pointer;
    ${({ isRefPage, theme }): any =>
      isRefPage &&
      `
      margin-right: 25px;
      margin-left: 27px;
      margin-bottom: 21px;
      padding-bottom: 27px;
      border-bottom: none;
      border-radius: 10px;
      background: ${theme.bgColors.GREY_95};
      `}

    .suggestion-right-arrow {
      margin-left: 6px;
    }

    .inner-box {
      display: flex;
      align-items: center;
    }

    .suggest-item-1-right,
    .suggest-item-2-right {
      padding-left: 21.5px;
      .suggest-item-1-right-title,
      .suggest-item-2-right-title {
        font-family: OpenSansSemiBold;
        font-size: 17px;
        margin-bottom: 3px;
      }
      .suggest-item-1-right-desc,
      .suggest-item-2-right-desc {
        font-size: 17px;
        color: ${({ theme }): string => theme.textColors.BLACK_25};
      }
    }

    :hover {
      background: ${({ theme }): string => theme.bgColors.BLUE_23};
    }
  }

  /* .suggest-item-2 {
    padding-top: 34px;
    padding-bottom: 39px;
  } */
`;
