import { APP_ROUTES } from "../constants";
import { LineagePageQueryParams } from "../pages/lineagepage/lineagepage.types";

export function getLineageDetailpageUrl(
  queryParams: LineagePageQueryParams
): string {
  const isInDrillDownMode = queryParams?.mode === "drill_down";
  const id = queryParams?.nodeId || "";

  const {
    mode = "none",
    nodeId,
    nodeName,
    nodeSubType = "",
    nodeType,
    direction = "",
    focusNodeId = isInDrillDownMode ? "" : id,
    expandedNodeId = isInDrillDownMode ? "" : id,
    randomNumber = "",
    drillDownDirection = "",
    drillDownExpandNodeId = isInDrillDownMode ? id : "",
    drillDownFocusNodeId = isInDrillDownMode ? id : "",
    parentNodeId = "",
    parentNodeName = "",
    parentNodeType = "",
    action = "",
  } = queryParams;
  return `${APP_ROUTES.private_routes.lineage}?nodeId=${nodeId}&nodeName=${nodeName}&nodeType=${nodeType}&nodeSubType=${nodeSubType}&mode=${mode}&direction=${direction}&focusNodeId=${focusNodeId}&expandedNodeId=${expandedNodeId}&randomNumber=${randomNumber}&drillDownDirection=${drillDownDirection}&drillDownExpandNodeId=${drillDownExpandNodeId}&drillDownFocusNodeId=${drillDownFocusNodeId}&parentNodeId=${parentNodeId}&parentNodeName=${parentNodeName}&parentNodeType=${parentNodeType}&action=${action}`;
}
