import { crossIcon, solidSuccessTickIcon } from "../../svgs";
import Flex from "../flex";
import { FlexProps } from "../flex/flex.types";
import { ActionTextStyled } from "./successnotificationmessagerendrer.styles";

const SuccessNotificationMessage = ({
  message,
  type = "success",
  showSuccess = true,
  showSuccessText = true,
  linkText = "",
  onLinkTextClick,
  alignItems,
  showErrorText = true,
}: {
  message: string | JSX.Element;
  type?: "success" | "error";
  showSuccess?: boolean;
  showSuccessText?: boolean;
  linkText?: string;
  onLinkTextClick?: () => void;
  alignItems?: FlexProps["alignItems"];
  showErrorText?: boolean;
}): JSX.Element => {
  return type === "success" ? (
    <Flex columnGap={5} alignItems={alignItems}>
      {showSuccess && (
        <span className="action-text">
          {solidSuccessTickIcon} {showSuccessText && "Success!"}
        </span>
      )}
      {message}
      {linkText && (
        <ActionTextStyled onClick={onLinkTextClick}>
          {linkText}
        </ActionTextStyled>
      )}
    </Flex>
  ) : (
    <span className="error-type">
      <span className="action-text">
        {crossIcon("16", "16")} {showErrorText && "Error!"}
      </span>
      {message}
    </span>
  );
};

export default SuccessNotificationMessage;
