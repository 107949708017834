import styled from "styled-components";

export const TrainbotShareConceptFormStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .sahre-concept-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .ant-btn-link {
      display: flex;
      margin-top: 24px;
      margin-right: 36px;
    }
  }

  .share-concept-content-wrapper {
    padding: 24px 36px;
    display: flex;
    justify-content: space-between;

    .share-concept-form-sec {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .share-concept-title {
        font-family: OpenSansSemibold;
        font-size: 20px;
        color: #428bca;
      }

      .textarea-field-container {
        margin-top: 25.6px;
        margin-bottom: 19.2px;
      }
    }

    .share-concept-right-banner {
      padding: 32px;
      width: 400px;
      height: fit-content;
      background: #f6f6f6;
      color: #4f4f4f;

      .header-sec {
        margin-bottom: 16px;

        .heading {
          font-size: 16px;
          font-family: OpenSansSemiBold;
        }
      }

      .sel-crit-heading {
        font-size: 14px;
        font-family: OpenSansSemiBold;
      }
    }
  }

  .ant-radio-group {
    width: 100%;

    .ant-radio-wrapper {
      width: 100%;
    }
  }

  .form-actions-sec {
    position: absolute;
    width: 100vw;
    bottom: 0px;
  }
`;
