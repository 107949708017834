import styled from "styled-components";

export const DialogBoxStyled = styled.div`
  padding-top: 20.8px;

  .content-sec {
    .static-text {
      color: ${({ theme }): string => theme.textColors.LIGHT_GREY_11};
      margin-bottom: 46px;
      padding-left: 20.8px;
      padding-right: 20.8px;

      .bold {
        color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
        font-family: OpenSansSemiBold;
        margin-top: 14px;
      }
    }
  }

  .modal-actions-sec {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    background-color: ${(props): string => props.theme.bgColors.LIGHT_BLUE_25};
    border-top: 1px solid
      ${(props): string => props.theme.borderColors.LIGHT_BLUE_45};
    padding: 8px 18px 8px 18px;
  }
`;
