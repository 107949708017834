import styled, { keyframes } from "styled-components";

const BounceDelayAnimation = keyframes`
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
`;

export const LoaderStyled = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10.4px;

  div {
    background-color: ${({ theme }): string => theme.bgColors.BLUE_56};
    width: 20.8px;
    height: 20.8px;
    border-radius: 100%;
    animation: ${BounceDelayAnimation} 1.4s infinite ease-in-out both;
  }

  .bounce1 {
    animation-delay: -0.32s;
  }

  .bounce2 {
    animation-delay: -0.16s;
  }
`;
