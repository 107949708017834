import styled from "styled-components";

export const ContactSupportButtonStyled = styled.div`
  display: inline-block;
  margin-left: 4px;
  a {
    color: ${({ theme }): string => theme?.bgColors?.BLUE_116};
    font-size: 14.4px;
  }
`;
