import styled from "styled-components";

export const TabsWithRightSecStyled = styled.div`
  background: ${({ theme }): string => theme.bgColors.GREY_19};
  display: flex;
  align-items: center;
  margin-bottom: 34px;
  padding-right: 13px;
  .tabs {
    padding-bottom: 8px;
    padding-top: 8px;
    border-bottom: none;
    flex: 1;

    .ant-btn-link {
      ::after {
        top: 35px;
      }
    }
  }
`;
