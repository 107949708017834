import { IdsType, StyledMappingReturnType } from "./button.interfaces";

export const StyledMapping = (
  key: IdsType,
  theme: any
): StyledMappingReturnType => {
  const mappingId = {
    cancel: {
      borderColor: theme.borderColors.DARK_GREY_20,
      textColor: theme.textColors.WHITE_COLOR,
      bgColor: theme.bgColors.DARK_GREY_20,
      focusTextColor: theme.textColors.WHITE_COLOR,
      focusBorderColor: theme.borderColors.DARK_GREY_20,
      focusBgColor: theme.bgColors.DARK_GREY_20,
      disabledFocusBgColor: theme.bgColors.DARK_GREY_20,
      disabledOpacity: "0.65",
      disabledFocusTextColor: theme.textColors.WHITE_COLOR,
      disabledFocusBorderColor: theme.borderColors.DARK_GREY_20,
      disabledTextColor: theme.textColors.WHITE_COLOR,
      disabledBorderColor: theme.borderColors.DARK_GREY_20,
      disabledBgColor: theme.bgColors.DARK_GREY_20,
    },
    primary: {
      borderColor: theme.borderColors.BLUE_116,
      textColor: theme.textColors.WHITE_COLOR,
      bgColor: theme.bgColors.BLUE_116,
      focusTextColor: theme.textColors.WHITE_COLOR,
      focusBorderColor: theme.borderColors.BLUE_116,
      focusBgColor: theme.bgColors.BLUE_116,
      disabledBorderColor: theme.borderColors.BLUE_116,
      disabledTextColor: theme.textColors.WHITE_COLOR,
      disabledBgColor: theme.bgColors.BLUE_116,
      disabledFocusBorderColor: theme.borderColors.BLUE_116,
      disabledFocusTextColor: theme.textColors.WHITE_COLOR,
      disabledFocusBgColor: theme.bgColors.BLUE_116,
      disabledOpacity: "0.65",
    },
    secondary: {
      borderColor: theme.borderColors.BLUE_15,
      textColor: theme.textColors.WHITE_COLOR,
      bgColor: theme.bgColors.BLUE_15,
      focusTextColor: theme.textColors.WHITE_COLOR,
      focusBorderColor: theme.borderColors.BLUE_15,
      focusBgColor: theme.bgColors.DARK_BLUE_15,
      disabledBorderColor: theme.borderColors.BLUE_15,
      disabledTextColor: theme.textColors.WHITE_COLOR,
      disabledBgColor: theme.bgColors.BLUE_15,
      disabledFocusBorderColor: theme.borderColors.BLUE_15,
      disabledFocusTextColor: theme.textColors.WHITE_COLOR,
      disabledFocusBgColor: theme.bgColors.BLUE_15,
      disabledOpacity: "0.7",
      notDisabledBorderColor: theme.borderColors.BLUE_15,
      notDisabledTextColor: theme.textColors.WHITE_COLOR,
      notDisabledBgColor: theme.borderColors.BLUE_15,
    },
    text: {
      textColor: theme.textColors.BLUE_105,
      focusTextColor: theme.textColors.BLUE_105,
      focusBgColor: "transparent",
      fontWeight: "lighter",
    },
    warning: {
      focusTextColor: theme.textColors.WHITE_COLOR,
      focusBorderColor: theme.borderColors.BLUE_116,
      focusBgColor: theme.bgColors.BLUE_116,
      disabledBorderColor: theme.borderColors.BLUE_116,
      disabledTextColor: theme.textColors.WHITE_COLOR,
      disabledBgColor: theme.borderColors.BLUE_116,
    },
    danger: {
      borderColor: theme.borderColors.RED_100,
      textColor: theme.textColors.WHITE_COLOR,
      bgColor: theme.bgColors.RED_100,
      focusTextColor: theme.textColors.WHITE_COLOR,
      focusBorderColor: theme.borderColors.RED_100,
      focusBgColor: theme.bgColors.RED_100,
      disabledBorderColor: theme.borderColors.RED_100,
      disabledTextColor: theme.textColors.WHITE_COLOR,
      disabledBgColor: theme.borderColors.RED_100,
      disabledFocusBorderColor: theme.borderColors.RED_100,
      disabledFocusTextColor: theme.textColors.WHITE_COLOR,
      disabledFocusBgColor: theme.bgColors.RED_100,
      disabledOpacity: "0.65",
    },
    link: {
      textColor: theme.textColors.BLUE_105,
      focusTextColor: theme.textColors.BLUE_105,
      fontWeight: "bold",
    },
    primary_1: {
      borderColor: theme.borderColors.BLUE_105,
      textColor: theme.textColors.WHITE_COLOR,
      bgColor: theme.bgColors.BLUE_105,
      focusTextColor: theme.textColors.WHITE_COLOR,
      focusBorderColor: theme.borderColors.BLUE_105,
      focusBgColor: theme.bgColors.BLUE_105,
      disabledBorderColor: theme.borderColors.BLUE_105,
      disabledTextColor: theme.textColors.WHITE_COLOR,
      disabledBgColor: theme.bgColors.BLUE_105,
      disabledFocusBorderColor: theme.borderColors.BLUE_105,
      disabledFocusTextColor: theme.textColors.WHITE_COLOR,
      disabledFocusBgColor: theme.bgColors.BLUE_105,
      disabledOpacity: "0.65",
    },
    primary_2: {
      borderColor: theme.borderColors.BLUE_56,
      textColor: theme.textColors.WHITE_COLOR,
      bgColor: theme.bgColors.BLUE_56,
      focusTextColor: theme.textColors.WHITE_COLOR,
      focusBorderColor: theme.borderColors.BLUE_56,
      focusBgColor: theme.bgColors.BLUE_56,
      disabledBorderColor: theme.borderColors.BLUE_56,
      disabledTextColor: theme.textColors.WHITE_COLOR,
      disabledBgColor: theme.bgColors.BLUE_56,
      disabledFocusBorderColor: theme.borderColors.BLUE_56,
      disabledFocusTextColor: theme.textColors.WHITE_COLOR,
      disabledFocusBgColor: theme.bgColors.BLUE_56,
      disabledOpacity: "0.65",
    },
    secondary_1: {
      borderColor: theme.borderColors.YELLOW_ORANGISH,
      textColor: theme.textColors.WHITE_COLOR,
      bgColor: theme.bgColors.YELLOW_ORANGISH,
      focusTextColor: theme.textColors.WHITE_COLOR,
      focusBorderColor: theme.borderColors.YELLOW_ORANGISH,
      focusBgColor: theme.bgColors.YELLOW_ORANGISH,
      disabledBorderColor: theme.borderColors.YELLOW_ORANGISH,
      disabledTextColor: theme.textColors.WHITE_COLOR,
      disabledBgColor: theme.bgColors.YELLOW_ORANGISH,
      disabledFocusBorderColor: theme.borderColors.YELLOW_ORANGISH,
      disabledFocusTextColor: theme.textColors.WHITE_COLOR,
      disabledFocusBgColor: theme.bgColors.YELLOW_ORANGISH,
      disabledOpacity: "0.65",
    },
  };
  return mappingId[key];
};
