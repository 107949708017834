import { SourceTypes } from "../app.types";

export function checkSourceType(
  id: SourceTypes
): {
  isExcelSource: boolean;
  isADLSSource: boolean;
  isTableauSource?: boolean;
  isRestAPISource?: boolean;
  isS3Source: boolean;
} {
  return {
    isADLSSource: id === "ADL",
    isExcelSource: id === "EXL",
    isTableauSource: id === "TBU",
    isRestAPISource: id === "RST",
    isS3Source: id === "AS3",
  };
}
