import styled from "styled-components";

export const SectionRowStyled = styled.div`
  margin-bottom: 17px;
  .main-row,
  .sublist-row {
    display: flex;
    .row-title,
    .sublist-title {
      color: ${({ theme }): string => theme.textColors.DARK_GREY_10};
      width: 33%;

      .required-row-title {
        color: ${({ theme }): string => theme.textColors.RED_11};
      }
    }

    .row-value {
      display: flex;
      align-items: center;
      column-gap: 8px;

      .name-icon-group {
        line-height: 0;
      }
    }

    .row-value,
    .sublist-value {
      color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
      width: 66%;
    }

    .multiline-text {
      word-break: break-word;
    }

    .tags {
      display: flex;
      flex-wrap: wrap;
      column-gap: 5px;
      > div {
        margin-bottom: 5px;
      }

      .more-tags-count {
        font-size: 600;
        font-family: OpenSansBold;
      }
    }
  }

  .sublist {
    position: relative;
    padding-top: 19px;
    .sublist-row-sec {
      .sublist-row {
        display: flex;
        margin-bottom: 7px;
        position: relative;
        padding-left: 26px;
        .sublist-title {
          width: 30.7%;
        }

        ::after {
          position: absolute;
          top: 11px;
          left: 7px;
          width: 12px;
          height: 1px;
          border: 0.5px dashed
            ${({ theme }): string => theme.borderColors.GREY_COLOR_12};
          content: "";
        }
      }

      ::before {
        height: 91%;
        width: 1px;
        border: 0.5px dashed
          ${({ theme }): string => theme.borderColors.GREY_COLOR_12};
        position: absolute;
        top: 0;
        left: 3px;
        content: "";
      }
    }
  }
`;
