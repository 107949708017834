import { CustomDynamicProgressBarStyled } from "./customdynamicprogressbar.styles";
import { CustomDynamicProgressBarProps } from "./customdynamicprogressbar.types";

const MIN_THRESHOLD_VAL = 3;
const MIN_THRESHOLD_EMPTY_VAL = 3;

const CustomDynamicProgressBar = (
  props: CustomDynamicProgressBarProps
): JSX.Element => {
  const {
    value,
    emptyText = "",
    fillText = "",
    height = "",
    width = "",
    onEmptyBarClick,
    onFillBarClick,
    titleEmptyText = "",
    titleFillText = "",
    minHeight = "",
  } = props;

  const valueToAdd = MIN_THRESHOLD_VAL - value;

  const updatedVal =
    value === 0
      ? 3
      : value > MIN_THRESHOLD_VAL
      ? value - MIN_THRESHOLD_VAL
      : valueToAdd + value;

  const emptyVal = 100 - updatedVal;
  const valueToAddEmptyVal = MIN_THRESHOLD_EMPTY_VAL - emptyVal;

  const updatedEmptyVal =
    value === 100
      ? 3
      : emptyVal < MIN_THRESHOLD_EMPTY_VAL
      ? emptyVal + valueToAddEmptyVal
      : emptyVal;

  return (
    <CustomDynamicProgressBarStyled
      value={updatedVal}
      width={width}
      height={height}
      emptyValue={updatedEmptyVal}
      isEmptyClickable={!!onEmptyBarClick}
      isFillClickable={!!onFillBarClick}
      minHeight={minHeight}
      data-testid="custom-dynamic-progressbar-main-wrapper"
    >
      <div
        className="progress"
        data-testid="custom-dynamic-progressbar-progress-wrapper"
      >
        <div
          className="progress-inner"
          data-testid="custom-dynamic-progressbar-progress-inner"
        >
          <div
            className="progress-inner-fill"
            onClick={onFillBarClick}
            role="button"
            title={titleFillText}
            data-testid="custom-dynamic-progressbar-progress-inner-fill"
          >
            d
          </div>
          <div
            className="progress-inner-empty"
            onClick={onEmptyBarClick}
            role="button"
            title={titleEmptyText}
            data-testid="custom-dynamic-progressbar-progress-inner-empty"
          >
            j
          </div>
        </div>
        <div
          className="progress-text"
          data-testid="custom-dynamic-progressbar-progress-text"
        >
          {fillText && (
            <div
              className="progress-text-fill"
              data-testid="custom-dynamic-progressbar-progress-text-fill"
            >
              {fillText}
            </div>
          )}
          {emptyText && (
            <div
              className="progress-text-empty"
              data-testid="custom-dynamic-progressbar-progress-text-empty"
            >
              {emptyText}
            </div>
          )}
        </div>
      </div>
    </CustomDynamicProgressBarStyled>
  );
};

export default CustomDynamicProgressBar;
