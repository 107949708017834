import InformativeText from "../../informativetext";
import { FormItemStyled } from "../form.styles";

import { FormItemLabelStyled } from "./formitemlabel.styles";
import { FormItemLabelProps } from "./formitemlabel.types";

const FormItemLabel = (props: FormItemLabelProps): JSX.Element => {
  const {
    children,
    description = "",
    width = "",
    id = "",
    isColumnLayout = false,
    ...resProps
  } = props;

  return (
    <FormItemLabelStyled
      width={width}
      id={id}
      isColumnLayout={isColumnLayout}
      className="form-item-label-wrapper"
    >
      <FormItemStyled {...resProps}>
        {description && (
          <InformativeText
            desc={description}
            marginBottom="8px"
            width={width}
          />
        )}
        {children}
      </FormItemStyled>
    </FormItemLabelStyled>
  );
};

export default FormItemLabel;
