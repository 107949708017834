//   <--Library Imports Start-->
import { Spin } from "antd";
//   <--Library Imports End-->

//   <--Components Start-->
import Flex from "../flex";
//   <--Components End-->

//   <--SVGS Start-->
import { dvSumnLogo } from "../../svgs";
//   <--SVGS END-->

//   <--Styles Start-->
import { DvSumFallBackSpinnerStyled } from "./dvsumfallbackspinner.styles";
//   <--Styles End-->

const DvSumFallBackSpinner = ({
  text = "Hang on! Loading...",
}: {
  text?: string;
}): JSX.Element => (
  <DvSumFallBackSpinnerStyled
    className="dvsum-fallback-spinner"
    data-testid="dvsum-fallback-spinner-main-wrapper"
  >
    <Flex rowGap={18} direction="column">
      {dvSumnLogo}
      {text}
      <Spin size="large" />
    </Flex>
  </DvSumFallBackSpinnerStyled>
);

export default DvSumFallBackSpinner;
