import * as yup from "yup";
import { REQUIRED_MESSAGE } from "../../constants/formconstants";

export const suggestAnEditSchema = yup.object().shape({
  title: yup.string().required(REQUIRED_MESSAGE),
  description: yup.string().required(REQUIRED_MESSAGE),
});

export const suggestAnDeprecateSchema = yup.object().shape({
  comment: yup.string().required(REQUIRED_MESSAGE),
});
