export const DATA_SOURCE_SECTION_TITLES = {
  overview: {
    description: "Definition",
    node_url: "URL",
    last_refreshed_on: "Last Refreshed On",
    last_updated_on: "Last Updated On",
    image_preview: "Preview",
    title: "Business Name",
    usability_score: "Usability Score",
  },
  managed_by: {
    data_owners: "Author",
    system_owners: "Admin",
    system_administrator: "System Administrator",
    data_stewards: "Data Steward",
    audit_trail: "Audit Trail",
  },
  classification: {
    format: "Format",
    sub_format: "Sub-Format",
    functional_area: "Data Domain",
    entity: "Entity",
  },
  audit_trail: {
    created: "Created",
    updated: "Updated",
  },
};

export const DATA_SOURCE_SECTIONS = {
  description: "Description",
  managed_by: "Managed By",
  lineage: "Lineage",
  usability_score: "Usability Score",
  classification: "Attributes",
  get_access_info: "Get Access Info",
  additional_info: "Additional Info",
  ownership: "Ownership",
};
