import { isDvsumPrefferedAmountMetricColumn } from "./isdvsumprefferedamountmetriccolumn";
import { isUserPrefferedAmountMetricColumn } from "./isuserprefferedamountmetriccolumn";

export const isAmountMetricColumn = (
  name: string,
  listOfKeywords?: string[]
): boolean => {
  try {
    return (
      isUserPrefferedAmountMetricColumn(name, listOfKeywords) ||
      isDvsumPrefferedAmountMetricColumn(name)
    );
  } catch {
    return false;
  }
};
