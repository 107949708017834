// <--- Library Imports Start --->
import { Tooltip } from "antd";
import { Fragment, useCallback, useState } from "react";
// <--- Library Imports End --->

// <--- Components Start --->
import Conditionalwrapper from "../conditionalwrapper";
import DvSumPopover from "../dvsumpopover";

import Flex from "../flex";
import PopularityScoreDetailCard from "../popularityscoredetailcard";
// <--- Components End --->

// <--- Constants Start --->
import { DVSUM_TOOLTIP_CLASS_NAME } from "../../constants";
// <--- Constants End --->

// <--- Renderers Start --->
import { popularityStars } from "./popularityscore.renders";
// <--- Renderers End --->

// <--- Styles Start --->
import { PopularityScoreStyled } from "./popularityscore.styles";
// <--- Styles End --->

// <--- Types Start --->
import {
  PopularityScorePopoverProps,
  PopularityScoreProps,
} from "./popularityscore.types";
// <--- Types End --->

const PopularityScorePopover = (
  props: PopularityScorePopoverProps
): JSX.Element => {
  const {
    children,
    wrapperCondition = false,
    popularityScoreDetails = {
      title: "",
      used: { usageCount: 0, usedByCount: 0, usedInCount: "" },
      topUsers: [],
    },
    isSelected = false,
    setIsSelected,
  } = props;

  const onchangeVisibility = useCallback(
    (visible: boolean) => {
      popularityScoreDetails && setIsSelected && setIsSelected(visible);
    },
    [isSelected, setIsSelected, popularityScoreDetails]
  );

  return (
    <Conditionalwrapper
      condition={wrapperCondition}
      wrapper={(child): JSX.Element => (
        <DvSumPopover
          content={
            popularityScoreDetails && (
              <PopularityScoreDetailCard {...popularityScoreDetails} />
            )
          }
          trigger={["click"]}
          placement="bottomLeft"
          onVisibleChange={onchangeVisibility}
          zIndex={1080}
        >
          {child}
        </DvSumPopover>
      )}
    >
      {children}
    </Conditionalwrapper>
  );
};

const PopularityScore = (props: PopularityScoreProps): JSX.Element => {
  const {
    score = "NOU",
    title = "",
    popularityScoreDetails = {
      topUsers: [],
      used: { usageCount: 0, usedByCount: 0, usedInCount: "" },
    },
    isShowBorderLeft = false,
    isShow = true,
  } = props;

  const [isSelected, setIsSelected] = useState<boolean>(false);

  return (
    <>
      {isShow && (
        <PopularityScoreStyled isSelected={isSelected}>
          <Flex direction="row" columnGap={2.4}>
            {isShowBorderLeft && <div className="separator" />}

            <PopularityScorePopover
              wrapperCondition={!!popularityScoreDetails}
              popularityScoreDetails={{
                ...popularityScoreDetails,
                title,
              }}
              isSelected={isSelected}
              setIsSelected={setIsSelected}
            >
              <Tooltip
                title={title}
                placement="right"
                overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
              >
                <Flex customClass="stars-container" columnGap={4}>
                  {popularityStars?.[score]?.map((star, index) => (
                    <Fragment key={index}>{star}</Fragment>
                  ))}
                </Flex>
              </Tooltip>
            </PopularityScorePopover>
          </Flex>
        </PopularityScoreStyled>
      )}
    </>
  );
};

export default PopularityScore;
