import { useCallback, useMemo, useState } from "react";
import { CloneRuleSummaryModalStyled } from "./clonerulesummarymodal.styles";

import { useGetAppState } from "../../../../customhooks";
import { LinkTabs } from "../../../tabs";

import { ELEMENT_IDS } from "../../../../constants";
import { CloneRuleSummaryModalGrid } from "./clonerulesummarymodal.comp";

import {
  CloneRuleSummaryModalPropsType,
  ModalTabType,
} from "./clonerulesummarymodal.types";

const {
  all_rule_tab: ALL_RULE_TAB,
  error_rule_tab: ERROR_RULE_TAB,
  success_rule_tab: SUCCESS_RULE_TAB,
} = ELEMENT_IDS;

const CloneRuleSummaryModal = (): JSX.Element => {
  const {
    modal: { modalProps = {} },
  } = useGetAppState();

  const {
    clonedRulesSummary = [],
  } = modalProps as CloneRuleSummaryModalPropsType;

  const cloneRuleSummaryGrid = useMemo(() => {
    return (
      clonedRulesSummary?.map((item) => ({
        id: item?.rule_id,
        ruleId: item?.rule_name,
        ruleName: item?.rule_name,
        clonedRuleId: item?.tgt_rule_id,
        clonedRuleName: item?.tgt_rule_name,
        fromSource: item?.src_name,
        toSource: item?.tgt_src_name,
        status: item?.status,
        remarks: item?.remarks,
        desc: item?.tgt_rule_desc,
      })) || []
    );
  }, [clonedRulesSummary]);

  const [tab, setTab] = useState<ModalTabType>("all");

  const onChangeTab = useCallback((tab: string): void => {
    setTab(tab as ModalTabType);
  }, []);

  const errorResults = useMemo(
    () => cloneRuleSummaryGrid?.filter((item) => item?.status === "FAL") || [],
    [cloneRuleSummaryGrid]
  );

  const successResults = useMemo(
    () => cloneRuleSummaryGrid?.filter((item) => item?.status === "PSS") || [],
    [cloneRuleSummaryGrid]
  );

  const gridData = useMemo(() => {
    return tab === "error"
      ? errorResults
      : tab === "success"
      ? successResults
      : cloneRuleSummaryGrid;
  }, [successResults, errorResults, tab, cloneRuleSummaryGrid]);

  return (
    <CloneRuleSummaryModalStyled>
      <div className="link-tabs-download-btn-wrapper">
        <LinkTabs
          id="primary"
          tabs={[
            {
              key: "all",
              value: `All (${cloneRuleSummaryGrid?.length})`,
              id: ALL_RULE_TAB,
            },
            {
              key: "error",
              value: `Error (${errorResults?.length})`,
              id: ERROR_RULE_TAB,
            },
            {
              key: "success",
              value: `Success (${successResults?.length})`,
              id: SUCCESS_RULE_TAB,
            },
          ]}
          selectedTab={tab}
          onChange={onChangeTab}
        />
      </div>

      <CloneRuleSummaryModalGrid data={gridData} />
    </CloneRuleSummaryModalStyled>
  );
};

export default CloneRuleSummaryModal;
