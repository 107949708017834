import styled from "styled-components";
import { ellipsis } from "../../../../../../../../../../../../styles/app.styles";

export const QueryBlockProfileStyled = styled.div<{
  isChatPage?: boolean;
  isFirstQueryProfiling?: boolean;
  isMaximized?: boolean;
}>`
  border-right: ${({ theme, isChatPage }): string =>
    isChatPage ? `1px solid ${theme?.borderColors?.GREY_56}` : ""};
  min-height: ${({ isChatPage }): string => (isChatPage ? `650px` : "")};
  .query-profilt-header {
    padding: 18px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid
      ${({ theme }): string => theme?.borderColors?.GREY_56};

    border-top: ${({
      theme,
      isChatPage,
      isFirstQueryProfiling,
      isMaximized,
    }): string =>
      isChatPage
        ? isMaximized
          ? `1px solid ${theme?.borderColors?.GREY_56}`
          : isFirstQueryProfiling
          ? ""
          : `1px solid ${theme?.borderColors?.GREY_56}`
        : ""};
    margin-bottom: 15.6px;

    .query-profilt-header-title {
      color: ${({ theme }): string => theme?.textColors?.BLACK_COLOR};
      font-family: OpenSansSemiBold;
    }
  }

  .query-profile-single-block {
    margin: 0 16px;
    margin-bottom: 16px;
    cursor: pointer;
    height: 230.5px;
    background-color: ${({ theme }): string => theme?.bgColors?.LIGHT_GREY_22};
    position: relative;

    .query-profile-single-block-header {
      padding: 12px;
      .col-name {
        display: flex;
        align-items: center;
        color: ${({ theme }): string => theme?.bgColors?.BLUE_116};
        font-size: 12px;
        font-family: OpenSansSemiBold;

        svg {
          margin-right: 4px;
          max-width: 13px;
          path {
            fill: ${({ theme }): string => theme?.bgColors?.GREY_25};
          }

          circle {
            fill: ${({ theme }): string => theme?.bgColors?.GREY_25};
          }
        }
      }

      .col-desc {
        color: ${({ theme }): string => theme?.bgColors?.GREY_24};
        font-size: 11px;
        ${ellipsis}
      }
    }

    .query-profile-single-block-chart {
      .ant-card {
        background-color: ${({ theme }): string =>
          theme?.bgColors?.LIGHT_GREY_22};

        .ant-card-body {
          padding: 0px;
          padding-left: 12px;
        }
      }
    }

    .progress-text {
      .progress-text-fill,
      .progress-text-empty {
        bottom: unset;
        top: 15px;
      }
      .progress-text-fill {
        color: ${({ theme }): string => theme?.bgColors?.GREY_24};
        left: 5px;
      }
      .progress-text-empty {
        color: ${({ theme }): string => theme?.bgColors?.GREY_24};
        right: 7px;
      }
    }
    .divider-text-wrapper {
      border-top: solid 1px #e8e8e8;
      margin-top: 24px;
      p {
        padding: 10px 15px 0 12px;
        font-family: OpenSansSemiBold;
        font-size: 12px;
        color: #4f4f4f;
        margin: 0;
      }
    }
  }
`;
