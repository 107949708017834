import * as yup from "yup";
import {
  CHARACTERS_LIMIT_EXCEEDED,
  REQUIRED_MESSAGE,
} from "../../constants/formconstants";

export const shareViewerSchema = yup.object().shape({
  share_with: yup
    .array()
    .of(yup.string())
    .required(REQUIRED_MESSAGE)
    .min(1, REQUIRED_MESSAGE),
  message: yup.string().max(1000, CHARACTERS_LIMIT_EXCEEDED(1000)),
});

export const shareOwnerSchema = yup.object().shape({
  share_with: yup.array().of(yup.string()),
  share_with_role: yup.string().required(REQUIRED_MESSAGE),
  message: yup.string().max(1000, CHARACTERS_LIMIT_EXCEEDED(1000)),
});
