import styled from "styled-components";

export const HighlightedCircleBackroundStyled = styled.div<{
  width?: string;
  height?: string;
  isFocused?: boolean;
}>`
  width: 32px;
  height: 32px;
  background: ${({ theme, isFocused }): string =>
    isFocused ? theme?.bgColors?.BLUE_42 : "transparent"};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    background: ${({ theme }): string => theme?.bgColors?.BLUE_42};
  }
`;
