import { Suspense, useCallback, useEffect } from "react";
import { RouteProps, Redirect, Route } from "react-router-dom";

import DvSumFallBackSpinner from "../../components/dvsumfallbackspinner";
import Layout from "../../components/layout";

import { APP_ROUTES } from "../../constants";
import { getEnvVariables, getLogoutUrl, getUserPermissions } from "../../utils";
import { getPostLoginData } from "../../utils/getpostlogindata";

import { PERMISSION_ROLES } from "./rolesandpermissions";
import { InformationModalProps } from "../../components/informationmodal/informationmodal.types";
import { useOpenModal } from "../../customhooks";
import { checkIfDateExpired } from "../../utils/checkIfDateExpired";
import LinkButton from "../../components/linkbutton/linkbutton";

const PrivateRoute = ({
  children,
  isLoggedIn,
  wrapLayout = true,
  id,
  ...rest
}: RouteProps & {
  isLoggedIn: boolean;
  wrapLayout?: boolean;
  id: keyof typeof APP_ROUTES["private_routes"];
}): JSX.Element => {
  const { user_info: userInfo = {} } = getPostLoginData();

  const {
    account_status: accountStatus,
    account_trial_expiry_date: trialExpiryDate = "",
  } = userInfo;
  const {
    is_account_admin: isAccountAdmin = false,
    is_glossary_viewer: isGlossaryViewer = false,
    is_catalog_viewer: isCatalogViewer = false,
  } = getUserPermissions();

  const isOnlyViewer = isGlossaryViewer && isCatalogViewer && !isAccountAdmin;

  const onOpenModal = useOpenModal();

  const logout = useCallback(() => {
    localStorage.clear();
    window.location.href = getLogoutUrl();
  }, []);

  const isTrialExpired =
    accountStatus === "TRL" && checkIfDateExpired(trialExpiryDate);

  const { REACT_APP_CONTACT_US_EMAIL } = getEnvVariables();

  const openTrialDeletedModal = useCallback(() => {
    onOpenModal({
      modalId: "subscription_deleted_modal",
      visible: true,
      modalProps: {
        textTitle: "Subscription Ended",
        textDescription:
          "Your subscription has been ended, but we'd love to have you back. Our support team is ready to assist you with any questions.",
        actionsButtonContent: (): JSX.Element => {
          return (
            <>
              <LinkButton className="contact" type="primary">
                <a
                  className="steward-email"
                  href={`mailto:${REACT_APP_CONTACT_US_EMAIL}`}
                >
                  Contact DvSum to Upgrade
                </a>
              </LinkButton>
              <LinkButton className="logout" type="primary" onClick={logout}>
                Logout
              </LinkButton>
            </>
          );
        },
      } as InformationModalProps,
    });
  }, [isTrialExpired]);

  useEffect(() => {
    isTrialExpired && openTrialDeletedModal();
  }, []);

  return (
    <Route
      {...rest}
      render={({ location }): JSX.Element | React.ReactNode =>
        isLoggedIn ? (
          PERMISSION_ROLES?.[id]?.includes(userInfo?.admin_access_type || "") ||
          PERMISSION_ROLES?.[id]?.includes(
            userInfo?.glossary_access_type || ""
          ) ||
          ((id === "tagsets" || id === "data_import") && !isOnlyViewer) ||
          !PERMISSION_ROLES?.[id]?.length ? (
            wrapLayout ? (
              <Layout>
                <Suspense fallback={<DvSumFallBackSpinner />}>
                  {children}
                </Suspense>
              </Layout>
            ) : (
              <div>
                <Suspense fallback={<DvSumFallBackSpinner />}>
                  {children}
                </Suspense>
              </div>
            )
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: location },
              }}
            />
          )
        ) : (
          <Redirect
            to={{
              pathname: APP_ROUTES.auth_routes.login,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
