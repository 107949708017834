export type JobDetailPageTabType = "details" | "api_information";

export type JobDetailPagePropsType = {
  jobId: string;
  paramsTab: JobDetailPageTabType;
};

export const jobDetailPageTabsValues: {
  [key in JobDetailPageTabType]: JobDetailPageTabType;
} = {
  details: "details",
  api_information: "api_information",
};

export type JobDetailPageParams = {
  id: string;
  tab: JobDetailPageTabType;
};
