import {
  differenceInDays,
  differenceInHours,
  differenceInMonths,
  differenceInYears,
  differenceInMinutes,
  differenceInSeconds,
} from "date-fns";

function appendTimeDifferenceText(
  number: number,
  appendedText: string
): string {
  const absNumber = Math.abs(number);

  if (absNumber !== 1) {
    appendedText += "s";
  }

  return `${absNumber} ${appendedText}${number < 0 ? "" : " ago"}`;
}

export function getTimeDifference(
  date: string,
  type?: "days" | "hours" | "minutes" | "years" | "month",
  endDate?: string
): string {
  try {
    const startDate = endDate ? new Date(endDate) : new Date();
    const differenceInDaysResult =
      differenceInDays(startDate, new Date(date)) || 0;
    const differenceInHoursResult =
      differenceInHours(startDate, new Date(date)) || 0;
    const differenceInMonthResult =
      differenceInMonths(startDate, new Date(date)) || 0;
    const differenceInYearsResult =
      differenceInYears(startDate, new Date(date)) || 0;
    const differenceInMinutesResult =
      differenceInMinutes(startDate, new Date(date)) || 0;
    const differenceInSecondesResult =
      differenceInSeconds(startDate, new Date(date)) || 0;

    // if (differenceInSecondesResult < 0) {
    //   console.log("differenceInSecondesResult", differenceInSecondesResult);
    //   return "a moment ago";
    // }

    switch (type) {
      case "days":
        return appendTimeDifferenceText(differenceInDaysResult, "day");
      case "hours":
        return appendTimeDifferenceText(differenceInHoursResult, "hour");
      case "minutes":
        return appendTimeDifferenceText(differenceInMinutesResult, "minute");
      case "month":
        return appendTimeDifferenceText(differenceInMonthResult, "month");
      case "years":
        return appendTimeDifferenceText(differenceInYearsResult, "year");
      default:
        return differenceInYearsResult
          ? appendTimeDifferenceText(differenceInYearsResult, "year")
          : differenceInMonthResult
          ? appendTimeDifferenceText(differenceInMonthResult, "month")
          : differenceInDaysResult
          ? appendTimeDifferenceText(differenceInDaysResult, "day")
          : differenceInHoursResult
          ? appendTimeDifferenceText(differenceInHoursResult, "hour")
          : differenceInMinutesResult
          ? appendTimeDifferenceText(differenceInMinutesResult, "minute")
          : differenceInSecondesResult
          ? appendTimeDifferenceText(differenceInSecondesResult, "second")
          : "a moment ago";
    }
  } catch {
    return "";
  }
}
