// <--- SVGs Start --->
import {
  userIcon,
  fileIcon,
  assignIcon,
  inProgressIcon,
  userWithTickIcon,
  greySuccessTickIcon,
  settingIconDynamicColor,
} from "../../../../../../svgs";
// <--- SVGs End --->

// <--- Types Start --->
import { DQActionStatusType } from "../../../../../../app.types";
// <--- Types End --->

export const dqRuleActionsMenuIconMapping = (
  action: DQActionStatusType
): JSX.Element | undefined => {
  const availableIcons: {
    [key in DQActionStatusType]?: JSX.Element;
  } = {
    OPN: fileIcon(),
    ASN: assignIcon(),
    RSN: assignIcon(),
    INP: inProgressIcon(),
    RSL: greySuccessTickIcon(),
  };

  return availableIcons[action];
};

export const dqRuleActionStatusCardIconMapping = (
  key: DQActionStatusType
): JSX.Element | undefined => {
  const statusIcons: {
    [key in DQActionStatusType]?: JSX.Element;
  } = {
    OPN: fileIcon(),
    ASN: userIcon,
    RSN: userIcon,
    INP: settingIconDynamicColor(),
    RSL: userWithTickIcon(),
  };

  return statusIcons[key];
};

export const dqRuleActionStatusCardTitleMapping = (
  key: DQActionStatusType
): string => {
  const statusIcons: {
    [key in DQActionStatusType]?: string;
  } = {
    OPN: "Opened by",
    ASN: "Assigned to",
    RSN: "Re-assigned to",
    INP: "In-progress by",
    RSL: "Resolved by",
  };

  return statusIcons[key] || "";
};
