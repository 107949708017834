import styled from "styled-components";
import { ellipsis } from "../../styles/app.styles";
import { hexToRgbA } from "../../utils";

export const TagsEditorStyled = styled.div<{
  isNewAllowed: boolean;
  isTagAlreadyExists: boolean;
}>`
  width: 100%;
  min-height: 341.7px;
  border: solid 1px
    ${({ theme }): string => hexToRgbA(theme.borderColors.GREY_27, 0.5)};
  background-color: ${({ theme }): string => theme.bgColors.WHITE_COLOR};

  .comment-screen-view {
    display: flex;
    flex-direction: column;
    min-height: 341.7px;

    .comment-screen-view-text {
      padding: 12px 15px 0px;
      font-family: OpenSansSemiBold;
      margin-bottom: 10px;
      color: ${({ theme }): string => theme.textColors.GREY_24};
    }

    .textarea-field-container {
      margin: 0px 15px 12px;
      flex: 1;
    }

    .comment-screen-view-footer {
      display: flex;
      justify-content: end;
      padding: 11.2px 15px;
      border-top: 1px solid
        ${({ theme }): string => hexToRgbA(theme.borderColors.GREY_27, 0.5)};

      button:first-of-type {
        color: ${({ theme }): string => theme.textColors.GREY_51};
      }
    }
  }

  .edit-tags-header {
    border-bottom: 1px solid
      ${({ theme }): string => hexToRgbA(theme.borderColors.GREY_27, 0.5)};
    font-size: 15px;
    color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
    font-family: OpenSansSemiBold;
    padding: 15px 15px 10px 15px;
  }

  .selected-tags-sec {
    padding: 12px 15px 12px;
    border-bottom: 1px solid
      ${({ theme }): string => hexToRgbA(theme.borderColors.GREY_27, 0.5)};
    .selected-tags-sec-heading {
      font-size: 13px;
      font-family: OpenSansSemiBold;
      margin-bottom: 6px;
    }
    .selected-tags {
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      .tag-rendrer {
        max-width: fit-content;
        background: ${({ theme }): string => theme.bgColors.LIGHT_GREY_22};
        border: 1px solid ${({ theme }): string => theme.borderColors.GREY_18};

        svg {
          margin: 0 0 0 5px;
        }
      }
    }
  }

  .edit-tags-search {
    height: 35px;
    border: none;
    padding-left: 16px;

    .ant-input-prefix {
      svg {
        path {
          fill: ${({ theme }): string => theme.bgColors.BLUE_116};
        }
      }
    }

    .ant-input-suffix {
      svg {
        path {
          fill: ${({ theme }): string => theme.bgColors.LIGHT_GREY_45};
        }
      }
    }
  }

  .tag-editor-body {
    display: flex;
    border-bottom: 1px solid
      ${({ theme }): string => hexToRgbA(theme.borderColors.GREY_27, 0.5)};
    border-top: 1px solid
      ${({ theme }): string => hexToRgbA(theme.borderColors.GREY_27, 0.5)};
    min-height: 193px;
    .tags-left-sec {
      width: 40%;
      border-right: 1px solid
        ${({ theme }): string => hexToRgbA(theme.borderColors.GREY_27, 0.5)};
    }
    .tags-right-sec {
      width: 60%;
      position: relative;

      .tags-scroll {
        overflow-y: auto;
        max-height: ${({ isNewAllowed, isTagAlreadyExists }): string =>
          isNewAllowed && isTagAlreadyExists
            ? "calc(100% - 65px)"
            : isNewAllowed
            ? "calc(100% - 42px)"
            : "calc(100% - 0px)"};
        position: absolute;
        top: 0;
        width: 100%;
        overflow-x: hidden;
      }

      .apply-tags {
        padding-left: 17px;
        opacity: 0.6;
        color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
        padding-top: 6px;
      }

      .add-custom-tags-input {
        position: absolute;
        bottom: ${({ isTagAlreadyExists }): string =>
          isTagAlreadyExists ? "22px" : "0px"};
        border: none;
        border-top: 1px solid
          ${({ theme }): string => hexToRgbA(theme.borderColors.GREY_27, 0.5)};
        border-bottom: ${({ theme, isTagAlreadyExists }): any =>
          isTagAlreadyExists &&
          `1px solid ${hexToRgbA(theme.borderColors.GREY_27, 0.5)}`};
        border-radius: 0px;
        width: 100%;
        height: 42px;
      }

      .already-exist-tag {
        position: absolute;
        bottom: 0px;
        right: 10px;
        font-size: 13px;
        color: red;
      }
    }
  }

  .footer {
    padding: 11.2px 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

export const TagCategoryStyled = styled.div<{ isSelected?: boolean }>`
  height: 46px;
  background: ${({ theme, isSelected }): string =>
    isSelected
      ? hexToRgbA(theme.bgColors.DARK_BLUE, 0.09)
      : theme.bgColors.WHITE_COLOR};
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${({ theme, isSelected }): string =>
    isSelected ? theme.textColors.BLUE_116 : theme.textColors.BLACK_COLOR};
  font-family: ${({ isSelected }): string =>
    isSelected ? "OpenSansSemiBold" : ""};
  padding-left: 11px;
  padding-right: 15px;
  border-left: 3px solid
    ${({ theme, isSelected }): string =>
      isSelected
        ? theme.borderColors.BLUE_116
        : theme.borderColors.WHITE_COLOR};

  .tag-category {
    flex: 1;
  }

  svg {
    path {
      stroke: ${({ theme, isSelected }): string =>
        isSelected
          ? theme.borderColors.BLUE_116
          : theme.borderColors.GREY_28} !important;
    }
  }
`;

export const TagsVlaueStyled = styled.div<{ isSelected?: boolean }>`
  height: 34px;
  background: ${({ theme, isSelected }): string =>
    isSelected
      ? hexToRgbA(theme.bgColors.BLUE_116, 0.09)
      : theme.bgColors.WHITE_COLOR};
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
  padding-left: 17px;
  padding-right: 11px;

  .tag-value {
    flex: 1;
    ${ellipsis}
  }
`;

export const TagValue = styled.div`
  max-width: 200px;
  height: 26px;
  padding: 0px 8.7px;
  border-radius: 3px;
  border: solid 1px ${({ theme }): string => theme.borderColors.LIGHT_GREY_40};
  background-color: #eaeaea;
  margin-right: 10px;
  .content {
    color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
    display: flex;

    .tag-label {
      ${ellipsis}
      color: ${({ theme }): string => theme.textColors.GREY_24};
    }

    .remove-tag {
      cursor: pointer;
      margin-left: 6px;
      svg {
        path {
          fill: ${({ theme }): string => theme.bgColors.LIGHT_GREY_45};
        }
      }
    }
  }
`;

export const TagsDropdownStyled = styled.div`
  background-color: ${({ theme }): string => theme?.bgColors?.WHITE_COLOR};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid ${({ theme }): string => theme.borderColors.GREY_18};
  padding: 8px 0;

  .tags-dropsown-scrollable-sec {
    max-height: 200px;
    overflow-y: auto;
  }
`;

export const TagsDropdownItemStyled = styled.div<{ isAdded: boolean }>`
  cursor: pointer;
  padding: 6px 12px;
  color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
  ${ellipsis}
  ${({ isAdded, theme }): string =>
    isAdded
      ? `
  font-family: OpenSansSemiBold;
  background: ${theme.bgColors.BLUE_23};
  `
      : ""}

  svg {
    margin: 0 6px;
  }
  :hover {
    background: ${({ theme }): string => theme.bgColors.BLUE_23};
  }
`;
