import styled from "styled-components";
import { BoldTextType } from "./boldtext.types";

export const BoldTextStyled = styled.div<BoldTextType>`
  font-size: ${({ fontSize }): string => fontSize || "16px"};
  font-family: ${({ text, isSemiBold }): any =>
    text && isSemiBold ? "OpenSansSemiBold" : "OpenSansBold"};
  color: ${({ theme, type }): string =>
    type === "secondary"
      ? theme.textColors.BLUE_116
      : theme.textColors.BLACK_COLOR};
`;
