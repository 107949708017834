import { API_CONFIG } from "../../constants/apiconfig";
import {
  GetAllUsersParserReturnType,
  UserGroupsParserReturnType,
} from "../../parsers";
import { getParsedUserGroupsForSelect } from "../../parsers/usergroups/usergroupsparser";
import { useGetAll } from "../baseservice";
import { CustomReactQueryHookReturnType } from "../services.types";

const {
  get_all_user_groups: allUserGroups,
  get_all_user_roles: allUserRoles,
  get_all_users: allUsers,
} = API_CONFIG;

export function useGetAllUserGroups(): CustomReactQueryHookReturnType<
  UserGroupsParserReturnType[]
> {
  return useGetAll({ apiConfig: allUserGroups });
}

export function useGetAllUserRolesForGroups(): CustomReactQueryHookReturnType {
  return useGetAll({ apiConfig: allUserRoles });
}

export function useGetAllUsersForGroups(): CustomReactQueryHookReturnType<
  GetAllUsersParserReturnType[]
> {
  return useGetAll({ apiConfig: allUsers });
}

export function useGetAllGroupsForSelect(): CustomReactQueryHookReturnType {
  return useGetAll({
    apiConfig: allUserGroups,
    parser: getParsedUserGroupsForSelect,
  });
}
