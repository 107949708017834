import { getEnvVariables } from "../../utils";

import {
  JobApiInfoPreviewerStyled,
  RequestBodyStyled,
} from "./jobapiinfopreviewer.styles";

import { JobApiInfoPreviewerPropsType } from "./jobapiinfopreviewer.types";

export const JobApiInfoPostPreviewer = (
  props: JobApiInfoPreviewerPropsType
): JSX.Element => {
  const { REACT_APP_API_DOMAIN } = getEnvVariables();
  const { isStatic, jobId } = props;

  return (
    <JobApiInfoPreviewerStyled>
      <RequestBodyStyled>
        curl -X POST
        <span className="url">
          {`"${REACT_APP_API_DOMAIN}/integration/jobs/${
            jobId && !isStatic ? `${jobId}` : "{job-id}"
          }/execute"`}
        </span>
        <br /> -H "x-api-key: API_KEY" \ <br />
        -H "Authorization: default"\
        <br />
        -H "Content-Type: application/json" \
      </RequestBodyStyled>
    </JobApiInfoPreviewerStyled>
  );
};

export const JobApiInfoGetPreviewer = (
  props: JobApiInfoPreviewerPropsType
): JSX.Element => {
  const { REACT_APP_API_DOMAIN } = getEnvVariables();
  const { isStatic, jobId } = props;

  return (
    <JobApiInfoPreviewerStyled>
      <RequestBodyStyled>
        curl -X GET{" "}
        <span className="url">
          {`"${REACT_APP_API_DOMAIN}/integration/jobs/${
            jobId && !isStatic ? `${jobId}` : "{execution-id}"
          }/details"`}
        </span>
        <br /> -H "x-api-key: API_KEY" \ <br />
        -H "Authorization: default"\ <br />
        -H "Content-Type: application/json" \
      </RequestBodyStyled>
    </JobApiInfoPreviewerStyled>
  );
};
