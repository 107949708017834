import { useCallback } from "react";
import { useClipboard } from "use-clipboard-copy";
import LinkButton from "../linkbutton";
import { copyIcon } from "../../svgs";
import { openNotification } from "../../utils";
import { StyledCopyToClipboard } from "./copytoclipboard.styles";
import { CopyToClipboardProps } from "./copytoclipboard.types";

const CopyToClipboard = (props: CopyToClipboardProps): JSX.Element => {
  const { text, isLoading } = props;
  const clipboard = useClipboard({
    copiedTimeout: 500,
    onSuccess() {
      openNotification("Copied to clipboard.");
    },
  });

  const handleCopy = useCallback(() => {
    clipboard.copy(text);
  }, [text, clipboard.copy]);

  return (
    <StyledCopyToClipboard>
      <div className="text-to-copy">{text}</div>
      <LinkButton
        className="copy-to-clipboard-button"
        onClick={handleCopy}
        role="button"
        disabled={isLoading}
      >
        {copyIcon}
      </LinkButton>
    </StyledCopyToClipboard>
  );
};

export default CopyToClipboard;
