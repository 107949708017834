import { useCallback } from "react";
import { Radio, RadioChangeEvent, Tooltip } from "antd";

import { useController } from "react-hook-form";

import { RadioGroupFieldStyled } from "./radiofield.styles";
import { RadioFieldType } from "./radiofield.types";

import { DVSUM_TOOLTIP_CLASS_NAME } from "../../../constants";
import ConditionalWrapper from "../../conditionalwrapper/conditionalwrapper";

import InformativeText from "../../informativetext/informativetext";

function RadioGroupField({
  name = "",
  control,
  width = "18px",
  height = "18px",
  direction = "column",
  gap = 0,
  options,
  propOnChange,
  labelDescWidth = "",
  ...props
}: RadioFieldType): JSX.Element {
  const {
    field: { ref, ...inputProps },
  } = useController({ name, control });

  const handleChange = useCallback(
    (event: RadioChangeEvent) => {
      inputProps?.onChange(event);
      propOnChange && propOnChange(event);
    },
    [inputProps]
  );

  return (
    <RadioGroupFieldStyled
      {...inputProps}
      onChange={handleChange}
      width={width}
      height={height}
      direction={direction}
      gap={gap}
      ref={ref}
      {...props}
    >
      {options?.map((option, index) => (
        <>
          <Radio
            value={option?.value}
            key={`radio-field-wrapper-${option?.value}-${index}`}
            disabled={option?.disabled}
            id={option?.id || ""}
          >
            <ConditionalWrapper
              condition={!!option?.tooltipProps}
              wrapper={(child): JSX.Element => (
                <Tooltip
                  title=""
                  {...option?.tooltipProps}
                  overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
                >
                  <span>{child}</span>
                </Tooltip>
              )}
            >
              <span>{option?.label}</span>
            </ConditionalWrapper>

            {option?.labelDesc && (
              <InformativeText
                desc={option?.labelDesc}
                width={labelDescWidth}
              />
            )}
          </Radio>
          {inputProps?.value === option?.value && option?.children}
        </>
      ))}
    </RadioGroupFieldStyled>
  );
}

export default RadioGroupField;
