import { useMemo } from "react";

//       <---BlankSlates--->
import { dataSourceRefBlankSlate } from "../../blankslates/datasourcerefblanlslate";
//       <---BlankSlates--->

//       <---Components--->
import CoreNodesCircleIcon from "../../components/corenodetypescircleicon";
import LineageSectionTitleWithIcon from "../../components/lineagesectitlewithicon";

import LinkButton from "../../components/linkbutton";
import NodeRefPageHOC from "../../components/noderefpagehoc";

import { NodeRefPageHOCEmbededProps } from "../../components/noderefpagehoc/noderefpagehoc.types";
import NodeStatusRendrer from "../../components/nodestatusrendrer";

import ProminentTags from "../../components/prominenttagslist";
import { Section } from "../../components/section";

import SectionHeader from "../../components/sectionheader";
import { SectionRowProps } from "../../components/sectionrow/sectionrow.types";

import StateHandler from "../../components/statehandler/statehandler";
import SuggestAnEditButton from "../../components/suggestaneditbutton";

import NodeRefQuickEditComp from "../../components/noderefquickedit";
//       <---Components--->

// <--- Constants Start --->
import { ELEMENT_IDS } from "../../constants";
import { DATA_SOURCE_SECTIONS } from "../../constants/labelconstants";
// <--- Constants End --->

//       <---Custom Hokks--->
import { useGetAppState, useGetAppType } from "../../customhooks";
//       <---Custom Hokks--->

//       <---Renderers--->
import { dataSourceRevViewRendrer } from "./datasourcerefview.rendrer";
//       <---Renderers--->

//       <---Services--->
import { useGetDataSourceRefDoc } from "../../api/datasourceservice/datasourceservice";
//       <---Services--->

//       <---SVGS--->
import { databaseIcon, redirectCircledIcon } from "../../svgs";
//       <---SVGS--->

//       <---Styles--->
import { DataSourceAndReportRefViewStyled } from "./datasourceandreportrefdrawer.styles";
//       <---Styles--->

//       <---Types--->
import { DataSourceAndReportRefViewProps } from "./datasourceandreportrefdrawer.types";
//       <---Types--->

//       <---Utils--->
import {
  getDetailpageUrlBasedOnNodeType,
  getSourceDetailPageUrl,
} from "../../utils";
import StyledLink from "../../components/styledlink/styledlink";
//       <---Utils--->

const titleSecProps = {
  className: "datasource-ref-sec-titile",
  showBorderBottom: false,
  isShowIcon: false,
};

const {
  anldict_detpg_ovw_ref_nav: ANLDICT_DETPG_OVW_REF_NAV,
  anldict_detpg_ovw_ref_ttl: ANLDICT_DETPG_OVW_REF_TTL,
} = ELEMENT_IDS;

function DataSourceAndReportRefViewContent(
  props: DataSourceAndReportRefViewProps & NodeRefPageHOCEmbededProps
): JSX.Element {
  const {
    nodeType,
    nodeSubType,
    onClickSuggest,
    isDrawer,
    id: propsId,
    isReportRefView,
    editViewComp,
    onClickEdit,
  } = props;

  const { drawer = {} } = useGetAppState();
  const { isExtOrDesktop } = useGetAppType();

  const id = drawer?.drawerProps?.id || "";

  const { parsedData, isLoading, error } = useGetDataSourceRefDoc(
    propsId || id,
    nodeType,
    "PUB"
  );

  const {
    node_id: nodeId = "",
    additional_info: additionalInfo = [],
    classification = [],
    is_editable: isEditable,
    lineage,
    managed_by: managedBy = [],
    node_desc: nodeDesc = "",
    node_name: nodeName = "",
    node_title: nodeTitle = "",
    overview = [],
    publish_status: publishStatus,
    publish_status_id: publishStatusId,
    source_id: sourceId = "",
    source_name: sourceName = "",
    prominentTags,
    is_wf_enabled: isWfEnabled = false,
  } = parsedData || {};

  const toUrl = useMemo(() => {
    const url = getDetailpageUrlBasedOnNodeType(
      propsId || id,
      nodeType,
      nodeSubType
    );

    return url;
  }, [nodeType, nodeSubType, id, propsId]);

  const breadCrubmUrl = useMemo(() => {
    return getSourceDetailPageUrl(sourceId);
  }, [sourceId]);

  const classificationSec = (
    <div className="datasource-ref-section">
      <Section
        secTitle={{
          title: DATA_SOURCE_SECTIONS?.classification,
          ...titleSecProps,
        }}
        secRowData={classification?.map((item: SectionRowProps) => ({
          ...item,
          customJSX: dataSourceRevViewRendrer?.[item?.title]?.(item),
        }))}
      />
    </div>
  );

  const lineageSec = (
    <div className="datasource-ref-section lineage-sec">
      <Section
        secTitle={{
          title: (
            <LineageSectionTitleWithIcon
              isRefView
              queryParams={{
                nodeId: propsId || nodeId,
                mode: "none",
                nodeName: nodeTitle,
                nodeSubType,
                nodeType,
              }}
              lineageExists={lineage?.lineageExists}
            />
          ),
          ...titleSecProps,
        }}
        mode="lineage"
        lineageData={{ ...lineage, isRefView: true }}
        secRowData={[]}
      />
    </div>
  );

  return (
    <StateHandler
      isFetching={isLoading}
      error={error}
      blankSlate={dataSourceRefBlankSlate}
    >
      <DataSourceAndReportRefViewStyled
        hasPromintntTags={!!prominentTags?.tags?.length}
      >
        <div className="datasource-ref-header">
          <div className="datasource-header-breadcrums">
            <div className="item-icon">{databaseIcon}</div>

            <StyledLink
              target={isExtOrDesktop ? "_blank" : "_self"}
              to={breadCrubmUrl}
              title={sourceName}
            >
              <div className="item-title" title={sourceName}>
                {sourceName}
              </div>
            </StyledLink>
          </div>
          <div className="header_row_2" id={ANLDICT_DETPG_OVW_REF_TTL}>
            <SectionHeader
              icon={
                <CoreNodesCircleIcon
                  nodeType={nodeType}
                  nodeSubType={nodeSubType}
                  marginRight="12px"
                />
              }
              title={nodeTitle}
              subTitle=""
              rightIcon={
                <>
                  <NodeStatusRendrer
                    status={publishStatusId}
                    className="deprecate-icon"
                    tooltipTitle={publishStatus}
                    nodeType={nodeType}
                  />

                  <LinkButton height="33px" disabled={!!editViewComp}>
                    <StyledLink
                      to={editViewComp ? "" : toUrl}
                      target={isExtOrDesktop ? "_blank" : "_self"}
                      cutomClassName="datasource-redirect-icon"
                      elementId={ANLDICT_DETPG_OVW_REF_NAV}
                    >
                      {redirectCircledIcon("28px", "28px")}
                    </StyledLink>
                  </LinkButton>
                </>
              }
            />
            {!isEditable && (
              <SuggestAnEditButton
                nodeType={nodeType}
                nodeId={propsId || nodeId}
                isDrawer={isDrawer}
                onClick={onClickSuggest}
                nodeDesc={nodeDesc}
                nodeTitle={nodeTitle}
                marginLeft="39px"
              />
            )}
            <div className="node-title" title={nodeName}>
              {nodeName}
            </div>
            {!!prominentTags?.tags?.length && (
              <ProminentTags {...prominentTags} />
            )}
          </div>
        </div>
        {editViewComp || (
          <>
            {parsedData?.is_editable && (
              <NodeRefQuickEditComp
                nodeDesc={nodeDesc}
                nodeId={nodeId}
                nodeTitle={nodeTitle}
                nodeType={nodeType}
                nodeSubType={nodeSubType}
                onClickEdit={onClickEdit}
                top="15px"
                right="29px"
                nodeName={nodeName}
                isDisabled={isWfEnabled}
              />
            )}
            <div className="datasource-ref-section first-type">
              <Section
                secTitle={{ title: "" }}
                secRowData={overview?.map((item: SectionRowProps) => ({
                  ...item,
                  customJSX: dataSourceRevViewRendrer?.[item?.title]?.(item),
                }))}
              />
            </div>
            <div className="datasource-ref-section managedby-section">
              <Section
                secTitle={{
                  title: DATA_SOURCE_SECTIONS?.managed_by,
                  ...titleSecProps,
                }}
                secRowData={managedBy?.map((item: SectionRowProps) => ({
                  ...item,
                  customJSX: dataSourceRevViewRendrer?.[item?.title]?.(item),
                }))}
                mode="section_column"
              />
            </div>
            {isReportRefView ? (
              <>
                {lineageSec}
                {classificationSec}
              </>
            ) : (
              <>
                {classificationSec}
                {lineageSec}
              </>
            )}
            <div className="datasource-ref-section">
              <Section
                secTitle={{
                  title: DATA_SOURCE_SECTIONS?.additional_info,
                  ...titleSecProps,
                }}
                additionalInfoData={additionalInfo}
                mode="additional_info"
                secRowData={[]}
              />
            </div>
          </>
        )}
      </DataSourceAndReportRefViewStyled>
    </StateHandler>
  );
}

const DataSourceAndReportRefView = (
  props: DataSourceAndReportRefViewProps
): JSX.Element => {
  return (
    <NodeRefPageHOC
      isDrawer={props?.isDrawer}
      setShowParentGoBack={props?.setShowParentGoBack}
    >
      <DataSourceAndReportRefViewContent {...props} />
    </NodeRefPageHOC>
  );
};

export default DataSourceAndReportRefView;
