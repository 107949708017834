import { useCallback, useEffect, useState } from "react";

import { useGetReleaseOnboardingDetails } from "../../api/helpandonboardingservice";

import {
  getPostLoginData,
  openOnboardingTutorial,
  updatePostLoginUserInfoData,
} from "../../utils";

import { useRequestWithMethod } from "../../api";

import { PostLoginFormType } from "../../app.types";
import { ReleaseOnboardingModalStyled } from "./releaseonboarding.styles";

import { bulletIcon, giftIconWithBgSvg } from "../../svgs";

import Linkbutton from "../linkbutton";
import { ModalStyled } from "../modalwrapper/modalwrapper.styles";

import Button from "../button";
import { LOCAL_STORAGE_CONSTANTS } from "../../constants";

import { VerticalDividerStyled } from "../dividers/dividers.styles";

const ReleaseOnboarding = (): JSX.Element => {
  const [showModal, setShowModal] = useState(false);
  const [isClickedOnReview, setIsclickedOnReview] = useState(false);

  const postLoginData = getPostLoginData();

  const { parsedData, isLoading, error } = useGetReleaseOnboardingDetails();

  const {
    release_heading: heading = "",
    features = [],
    show_splash_in_case_of_skip: showSplashInCaseOfSkip,
    show_splash_on_first_login: showSplashOnFirstLogin,
    most_recent_updates_date: mostRecentUpdatesDate,
  } = parsedData || {};

  const { onExecuteRequest } = useRequestWithMethod(
    "user_onboarding_info",
    undefined,
    true
  );

  const onReview = useCallback(() => {
    const payload = {
      user_last_rev_onb_date: mostRecentUpdatesDate,
      user_last_skip_onb_date: "",
      user_last_dnrm_onb_date: "",
    };

    const updatedUserInfo: PostLoginFormType["user_info"] = {
      ...postLoginData?.user_info,
      ...payload,
    };

    setIsclickedOnReview(true);

    onExecuteRequest(payload);

    localStorage.setItem(
      LOCAL_STORAGE_CONSTANTS.user_clicked_on_skip_onb,
      "false"
    );

    updatePostLoginUserInfoData(updatedUserInfo);
  }, [postLoginData, mostRecentUpdatesDate]);

  const openTutorial = useCallback(
    (assetId: string, id: string, startUrl: string) => (): void => {
      openOnboardingTutorial({ id, startUrl, assetId });
      onReview();
    },
    [onReview]
  );

  const onSkip = useCallback(() => {
    if (isClickedOnReview) {
      setShowModal(false);
    } else {
      const payload = {
        user_last_rev_onb_date: "",
        user_last_dnrm_onb_date: "",
        user_last_skip_onb_date: mostRecentUpdatesDate,
      };

      const updatedUserInfo: PostLoginFormType["user_info"] = {
        ...postLoginData?.user_info,
        ...payload,
      };

      localStorage.setItem(
        LOCAL_STORAGE_CONSTANTS.user_clicked_on_skip_onb,
        "true"
      );

      onExecuteRequest(payload);
      updatePostLoginUserInfoData(updatedUserInfo);
      setShowModal(false);
    }
  }, [postLoginData, mostRecentUpdatesDate, isClickedOnReview]);

  const onDoNotRemind = useCallback(() => {
    const payload = {
      user_last_skip_onb_date: "",
      user_last_rev_onb_date: "",
      user_last_dnrm_onb_date: mostRecentUpdatesDate,
    };

    const updatedUserInfo: PostLoginFormType["user_info"] = {
      ...postLoginData?.user_info,
      ...payload,
    };

    onExecuteRequest(payload);

    localStorage.setItem(
      LOCAL_STORAGE_CONSTANTS.user_clicked_on_skip_onb,
      "false"
    );

    updatePostLoginUserInfoData(updatedUserInfo);
    setShowModal(false);
  }, [postLoginData, mostRecentUpdatesDate]);

  useEffect(() => {
    if (!isLoading && !error) {
      setShowModal(showSplashInCaseOfSkip || showSplashOnFirstLogin);
    }
  }, [isLoading]);

  return (
    <ModalStyled
      visible={!isLoading && showModal}
      closable={false}
      centered
      isHideHeader
    >
      <ReleaseOnboardingModalStyled>
        {giftIconWithBgSvg("80px", "80px")}
        <div className="heading">{heading}</div>

        <div className="updates-list">
          {features?.map((item) => (
            <div className="updated-feature-item" key={item?.id}>
              <div className="title-sec">
                {bulletIcon("8px", "8px")}
                <div className="title">{item?.title}</div>
              </div>
              <div className="updated-feature-btns">
                <Linkbutton
                  tooltipProps={{ title: "Open Tutorial" }}
                  onClick={openTutorial(
                    item?.asset_id,
                    item?.id,
                    item?.tutorial_start_url
                  )}
                >
                  Take the Tour
                </Linkbutton>
                <VerticalDividerStyled
                  marginLeft="8px"
                  marginRight="8px"
                  height="10px"
                />
                {item?.article_link && (
                  <Linkbutton
                    tooltipProps={{ title: "View Article" }}
                    target="_blank"
                    href={item?.article_link}
                    onClick={onReview}
                  >
                    Docs
                  </Linkbutton>
                )}
              </div>
            </div>
          ))}
        </div>

        <div className="footer-sec">
          <div className="action-sec">
            <Button id="cancel" height="27.2px" onClick={onDoNotRemind}>
              Do not remind me again
            </Button>
            <Button id="primary" height="27.2px" onClick={onSkip}>
              OK, got it!
            </Button>
          </div>

          <div className="footer-note">
            You can always review the recent updates from the{" "}
            <span className="help">Help</span> panel.
          </div>
        </div>
      </ReleaseOnboardingModalStyled>
    </ModalStyled>
  );
};

export default ReleaseOnboarding;
