import { differenceInSeconds } from "date-fns";
import { utcTOLocalTimeZone } from "./utctolocaltimezone";

const convertSecToMmss = (time: number): string => {
  const seconds = time % 60;
  const minutes = Math.floor(time / 60);

  return `${`${minutes}`.padStart(2, "0")}:${`${seconds}`.padStart(2, "0")}`;
};

export const getTimeDiffInLocalMmss = (startTime: string): string => {
  try {
    const localStartTimeString = utcTOLocalTimeZone(startTime);
    const localStartTime = new Date(localStartTimeString);

    const currTime = new Date();
    const diffInSec = differenceInSeconds(currTime, localStartTime);

    return convertSecToMmss(diffInSec);
  } catch {
    return "";
  }
};
