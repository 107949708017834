import styled from "styled-components";
import { ellipsis } from "../../styles/app.styles";

export const FileUploaderStyled = styled.div<{ hasFile?: boolean }>`
  border: dashed 1px ${({ theme }): string => theme.borderColors.BLUE_26};
  border-radius: 12px;
  height: ${(props): string => (props.hasFile ? "111px" : "331.4px")};
  padding: ${(props): any => props.hasFile && "0 47px 0 33px"};
  .ant-upload-drag {
    width: 100%;
    background: ${({ theme }): string => theme.bgColors.LIGHT_BLUE_22};
    border: none;
    border-radius: 12px;
    .ant-upload-drag-container {
      .desc-sec {
        font-size: 62px;
        display: flex;
        align-items: baseline;
        justify-content: center;
        font-family: OpenSansBold;
        color: ${({ theme }): string => theme.textColors.GRAY_70};
        margin-bottom: 5px;
        > svg {
          margin-right: 8px;
        }
        .action__text__1 {
          font-size: 44px;
          margin-left: 12px;
          font-family: OpenSans;
        }
        .action__text__2 {
          font-size: 33px;
          margin-left: 8px;
          font-family: OpenSans;
        }
      }
    }
  }

  .ant-upload-list-text {
    display: none;
  }

  .progress-sec {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 111px;
    .left {
      display: flex;
      align-items: center;
      color: ${({ theme }): string => theme.textColors.BLACK_10};
      font-size: 20px;

      .file-name {
        ${ellipsis};
        max-width: 300px;
      }

      .file-icon {
        background: #32a3ce;
        width: 49px;
        height: 49px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 14px;
      }
    }

    .right {
      .file-status {
        color: ${({ theme }): string => theme.textColors.BLACK_15};
        max-width: 200px;
        font-size: 17px;
        ${ellipsis}
      }
    }
  }

  .additional-info {
    margin-top: 15px;
    color: ${({ theme }): string => theme.textColors.BLACK_10};
    font-size: 20px;
  }
`;
