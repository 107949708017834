import styled from "styled-components";
import { ellipsis } from "../../styles/app.styles";
import { RepresentModeType } from "./represents.types";

export const RepresentsStyled = styled.div<{
  isRefView?: boolean;
  mode?: RepresentModeType;
}>`
  .represents-values {
    display: flex;
    flex-wrap: wrap;

    .represent-item {
      display: flex;
      align-items: center;
      width: 100%;
      .represent-item-value {
        cursor: pointer;
        margin-left: 4.5px;
        color: ${({ theme, mode }): string =>
          mode === "primary"
            ? theme.textColors.DARK_BLUE
            : theme?.textColors?.grey_40};
        min-width: 70px;

        :last-of-type {
          cursor: ${({ isRefView }): any => isRefView && "default"};
        }

        ${ellipsis}
      }
      .seperator-right-icon {
        margin: 0 9.6px;
      }

      .flex-1 {
        flex: 1;
      }

      svg {
        path {
          fill: ${({ theme, mode }): string =>
            mode === "primary"
              ? theme.bgColors.BLUE_116
              : theme.bgColors.GREY_18};
        }
      }

      .delete-icon {
        margin-left: 20px;
        svg {
          cursor: pointer;
          path {
            fill: ${({ theme }): string => theme.bgColors.RED_100};
          }
        }
      }
    }
  }

  .empty-represents {
    font-weight: 600;
    width: 100%;
    text-align: center;
    padding-top: 20px;
  }
`;
