import { ToggleButtonWrapper, ToggleButtonStyled } from "./togglebutton.styles";
import { ToggleButtonTypes } from "./togglebutton.types";

export const ToggleButton = (props: ToggleButtonTypes): JSX.Element => {
  const { options, onClick, selectedOption } = props;

  return (
    <ToggleButtonWrapper>
      {options?.map((option: string, index: number) => {
        const isSelected = selectedOption === option;
        return (
          <ToggleButtonStyled
            selected={isSelected}
            key={`toggle-button-option-${index}`}
            onClick={(): void => onClick(option)}
            className={`toggle-button-option${isSelected ? "-selected" : ""}`}
          >
            {option}
          </ToggleButtonStyled>
        );
      })}
    </ToggleButtonWrapper>
  );
};
