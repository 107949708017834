import {
  AddMetricFormApiDataType,
  AddMetricFormType,
} from "./addmetricform.types";

export const metricFormDataToApiData = (
  formData: AddMetricFormType
): AddMetricFormApiDataType => {
  return {
    col_id: Number(formData?.column),
    metric_type: formData?.metric_type,
  };
};
