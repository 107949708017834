import styled from "styled-components";

export const ToggleEyeIconStyled = styled.div`
  .selected {
    svg {
      > g {
        > path {
          fill: ${({ theme }): string => theme.bgColors.DARK_GREY_10};
          stroke: ${({ theme }): string => theme.bgColors.DARK_GREY_10};
        }
      }
      > g {
        > g {
          path {
            :first-of-type {
              fill: ${({ theme }): string => theme.bgColors.DARK_GREY_10};
              stroke: ${({ theme }): string => theme.bgColors.DARK_GREY_10};
            }
            :last-of-type {
              fill: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
              stroke: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
            }
          }
        }
      }
    }
  }
`;
