import { APP_ROUTES } from "../constants";
import { TablePageParamViewKeys } from "../pages/tablepage/tablepage.types";

export const checkIsTableDetailPageDataTab = (): boolean => {
  const pathname = window?.location?.pathname || "";
  const dataTabKey: TablePageParamViewKeys = "data";

  try {
    return (
      pathname?.includes(APP_ROUTES.private_routes.table_dictionary) &&
      pathname?.includes(dataTabKey)
    );
  } catch (e) {
    return false;
  }
};
