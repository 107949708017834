import { RelationshipSourceIDType } from "../../../../parsers/erddiagramparser/erddiagramparser.types";
import { bulbIcon, dataSourceIcon, userIconOutlined } from "../../../../svgs";

export const getSourceIcon = (
  source: RelationshipSourceIDType
): JSX.Element | string => {
  switch (source) {
    case "IDB":
      return dataSourceIcon("12", "", "#4f4f4f");
    case "RCM":
      return bulbIcon("14");
    case "USR":
      return userIconOutlined("14", "22");
    default:
      return "";
  }
};

export const getTooltipTitle = (source: RelationshipSourceIDType): string => {
  switch (source) {
    case "IDB":
      return "From Database";
    case "USR":
      return "User Added";
    case "RCM":
      return "Recommended by DvSum";
    default:
      return "";
  }
};
