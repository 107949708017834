import styled from "styled-components";

export const GeneralViewStyled = styled.div`
  display: flex;
  justify-content: space-between;

  .content {
    max-width: 508px;

    .checkbox-wrapper {
      margin-bottom: 12px;

      .ant-checkbox-wrapper {
        font-family: "OpenSansSemiBold";
        margin-bottom: 4px;
      }

      .desc {
        padding-left: 25px;
        font-size: 12px;
        color: ${({ theme }): string => theme?.textColors?.GREY_26};
      }
    }

    .descriptive-img-wrapper {
      padding-left: 25px;

      svg {
        max-width: 100%;
      }
    }
  }

  .action-sec {
    display: flex;
    column-gap: 9.6px;
  }
`;
