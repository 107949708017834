export const glossaryCategoriesTableBlankSlate = (
  <svg width="100%" height="305" viewBox="0 0 1614.5 305">
    <defs>
      <style>
        {`
          .cls-1{
            font-size: 14.4px;
            font-family: OpenSansSemiBold, OpenSans;
            font-weight: 600;
          }
          
          .cls-2{
            fill: none;
            stroke: #707070;
            opacity: .1;
          }
          
          .cls-3{
            fill: #f0f0f0;
          }
        `}
      </style>
    </defs>
    <g
      id="Group_2743"
      data-name="Group 2743"
      transform="translate(-259.125 -267)"
    >
      <text
        id="Modified_By"
        data-name="Modified By"
        className="cls-1"
        transform="translate(1450 286)"
      >
        <tspan x="0" y="0">
          Updated By
        </tspan>
      </text>
      <path
        id="Line_397"
        data-name="Line 397"
        className="cls-2"
        transform="translate(261.625 353.5)"
        d="M0 0h1612"
      />
      <path
        id="Line_397-2"
        data-name="Line 397"
        className="cls-2"
        transform="translate(259.125 462.5)"
        d="M0 0h1612"
      />
      <path
        id="Line_397-3"
        data-name="Line 397"
        className="cls-2"
        transform="translate(259.125 571.5)"
        d="M0 0h1612"
      />
      <path
        id="Line_397-4"
        data-name="Line 397"
        className="cls-2"
        transform="translate(259.125 516.5)"
        d="M0 0h1612"
      />
      <path
        id="Line_414"
        data-name="Line 414"
        className="cls-2"
        transform="translate(259.125 407.5)"
        d="M0 0h1612"
      />
      <text
        id="Modified_By-2"
        data-name="Modified By"
        className="cls-1"
        transform="translate(1613 286)"
      >
        <tspan x="0" y="0">
          Updated On
        </tspan>
      </text>
      <text id="Role" className="cls-1" transform="translate(274 286)">
        <tspan x="0" y="0">
          Description
        </tspan>
      </text>
      <text
        id="Created_By"
        data-name="Created By"
        className="cls-1"
        transform="translate(1268.125 286)"
      >
        <tspan x="0" y="0">
          Data Steward
        </tspan>
      </text>
      <text
        id="Created_By-2"
        data-name="Created By"
        className="cls-1"
        transform="translate(888 286)"
      >
        <tspan x="0" y="0">
          Priority
        </tspan>
      </text>
      <text
        id="Created_By-3"
        data-name="Created By"
        className="cls-1"
        transform="translate(1056 286)"
      >
        <tspan x="0" y="0">
          Data Owner
        </tspan>
      </text>
      <text id="Description" className="cls-1" transform="translate(562 286)">
        <tspan x="0" y="0">
          Terms
        </tspan>
      </text>
      <rect
        id="Rectangle_1520"
        data-name="Rectangle 1520"
        className="cls-3"
        width="133"
        height="15"
        rx="7.5"
        transform="translate(274 374)"
      />
      <g id="Group_2730" data-name="Group 2730">
        <rect
          id="Rectangle_1506"
          data-name="Rectangle 1506"
          className="cls-3"
          width="133"
          height="15"
          rx="7.5"
          transform="translate(274 319)"
        />
        <rect
          id="Rectangle_1521"
          data-name="Rectangle 1521"
          className="cls-3"
          width="133"
          height="15"
          rx="7.5"
          transform="translate(274 374)"
        />
        <rect
          id="Rectangle_1522"
          data-name="Rectangle 1522"
          className="cls-3"
          width="133"
          height="15"
          rx="7.5"
          transform="translate(274 431)"
        />
        <rect
          id="Rectangle_1523"
          data-name="Rectangle 1523"
          className="cls-3"
          width="133"
          height="15"
          rx="7.5"
          transform="translate(274 483)"
        />
        <rect
          id="Rectangle_1524"
          data-name="Rectangle 1524"
          className="cls-3"
          width="133"
          height="15"
          rx="7.5"
          transform="translate(274 537)"
        />
      </g>
      <g id="Group_2731" data-name="Group 2731" transform="translate(288)">
        <rect
          id="Rectangle_1506-2"
          data-name="Rectangle 1506"
          className="cls-3"
          width="233"
          height="15"
          rx="7.5"
          transform="translate(274 319)"
        />
        <rect
          id="Rectangle_1521-2"
          data-name="Rectangle 1521"
          className="cls-3"
          width="233"
          height="15"
          rx="7.5"
          transform="translate(274 374)"
        />
        <rect
          id="Rectangle_1522-2"
          data-name="Rectangle 1522"
          className="cls-3"
          width="233"
          height="15"
          rx="7.5"
          transform="translate(274 431)"
        />
        <rect
          id="Rectangle_1523-2"
          data-name="Rectangle 1523"
          className="cls-3"
          width="233"
          height="15"
          rx="7.5"
          transform="translate(274 483)"
        />
        <rect
          id="Rectangle_1524-2"
          data-name="Rectangle 1524"
          className="cls-3"
          width="233"
          height="15"
          rx="7.5"
          transform="translate(274 537)"
        />
      </g>
      <g id="Group_2733" data-name="Group 2733" transform="translate(625)">
        <rect
          id="Rectangle_1506-3"
          data-name="Rectangle 1506"
          className="cls-3"
          width="42"
          height="15"
          rx="7.5"
          transform="translate(274 319)"
        />
        <rect
          id="Rectangle_1521-3"
          data-name="Rectangle 1521"
          className="cls-3"
          width="42"
          height="15"
          rx="7.5"
          transform="translate(274 374)"
        />
        <rect
          id="Rectangle_1522-3"
          data-name="Rectangle 1522"
          className="cls-3"
          width="42"
          height="15"
          rx="7.5"
          transform="translate(274 431)"
        />
        <rect
          id="Rectangle_1523-3"
          data-name="Rectangle 1523"
          className="cls-3"
          width="42"
          height="15"
          rx="7.5"
          transform="translate(274 483)"
        />
        <rect
          id="Rectangle_1524-3"
          data-name="Rectangle 1524"
          className="cls-3"
          width="42"
          height="15"
          rx="7.5"
          transform="translate(274 537)"
        />
      </g>
      <g id="Group_2735" data-name="Group 2735" transform="translate(782)">
        <rect
          id="Rectangle_1506-4"
          data-name="Rectangle 1506"
          className="cls-3"
          width="133"
          height="15"
          rx="7.5"
          transform="translate(274 319)"
        />
        <rect
          id="Rectangle_1521-4"
          data-name="Rectangle 1521"
          className="cls-3"
          width="133"
          height="15"
          rx="7.5"
          transform="translate(274 374)"
        />
        <rect
          id="Rectangle_1522-4"
          data-name="Rectangle 1522"
          className="cls-3"
          width="133"
          height="15"
          rx="7.5"
          transform="translate(274 431)"
        />
        <rect
          id="Rectangle_1523-4"
          data-name="Rectangle 1523"
          className="cls-3"
          width="133"
          height="15"
          rx="7.5"
          transform="translate(274 483)"
        />
        <rect
          id="Rectangle_1524-4"
          data-name="Rectangle 1524"
          className="cls-3"
          width="133"
          height="15"
          rx="7.5"
          transform="translate(274 537)"
        />
      </g>
      <g id="Group_2736" data-name="Group 2736" transform="translate(994)">
        <rect
          id="Rectangle_1506-5"
          data-name="Rectangle 1506"
          className="cls-3"
          width="133"
          height="15"
          rx="7.5"
          transform="translate(274 319)"
        />
        <rect
          id="Rectangle_1521-5"
          data-name="Rectangle 1521"
          className="cls-3"
          width="133"
          height="15"
          rx="7.5"
          transform="translate(274 374)"
        />
        <rect
          id="Rectangle_1522-5"
          data-name="Rectangle 1522"
          className="cls-3"
          width="133"
          height="15"
          rx="7.5"
          transform="translate(274 431)"
        />
        <rect
          id="Rectangle_1523-5"
          data-name="Rectangle 1523"
          className="cls-3"
          width="133"
          height="15"
          rx="7.5"
          transform="translate(274 483)"
        />
        <rect
          id="Rectangle_1524-5"
          data-name="Rectangle 1524"
          className="cls-3"
          width="133"
          height="15"
          rx="7.5"
          transform="translate(274 537)"
        />
      </g>
      <g id="Group_2738" data-name="Group 2738" transform="translate(1177)">
        <rect
          id="Rectangle_1506-6"
          data-name="Rectangle 1506"
          className="cls-3"
          width="110"
          height="15"
          rx="7.5"
          transform="translate(274 319)"
        />
        <rect
          id="Rectangle_1521-6"
          data-name="Rectangle 1521"
          className="cls-3"
          width="110"
          height="15"
          rx="7.5"
          transform="translate(274 374)"
        />
        <rect
          id="Rectangle_1522-6"
          data-name="Rectangle 1522"
          className="cls-3"
          width="110"
          height="15"
          rx="7.5"
          transform="translate(274 431)"
        />
        <rect
          id="Rectangle_1523-6"
          data-name="Rectangle 1523"
          className="cls-3"
          width="110"
          height="15"
          rx="7.5"
          transform="translate(274 483)"
        />
        <rect
          id="Rectangle_1524-6"
          data-name="Rectangle 1524"
          className="cls-3"
          width="110"
          height="15"
          rx="7.5"
          transform="translate(274 537)"
        />
      </g>
      <g id="Group_2740" data-name="Group 2740" transform="translate(1339)">
        <rect
          id="Rectangle_1506-7"
          data-name="Rectangle 1506"
          className="cls-3"
          width="184"
          height="15"
          rx="7.5"
          transform="translate(274 319)"
        />
        <rect
          id="Rectangle_1521-7"
          data-name="Rectangle 1521"
          className="cls-3"
          width="184"
          height="15"
          rx="7.5"
          transform="translate(274 374)"
        />
        <rect
          id="Rectangle_1522-7"
          data-name="Rectangle 1522"
          className="cls-3"
          width="184"
          height="15"
          rx="7.5"
          transform="translate(274 431)"
        />
        <rect
          id="Rectangle_1523-7"
          data-name="Rectangle 1523"
          className="cls-3"
          width="184"
          height="15"
          rx="7.5"
          transform="translate(274 483)"
        />
        <rect
          id="Rectangle_1524-7"
          data-name="Rectangle 1524"
          className="cls-3"
          width="184"
          height="15"
          rx="7.5"
          transform="translate(274 537)"
        />
      </g>
      <g id="Group_2742" data-name="Group 2742" transform="translate(1555.906)">
        <rect
          id="Rectangle_1506-8"
          data-name="Rectangle 1506"
          className="cls-3"
          width="29"
          height="15"
          rx="7.5"
          transform="translate(274.094 319)"
        />
        <rect
          id="Rectangle_1521-8"
          data-name="Rectangle 1521"
          className="cls-3"
          width="29"
          height="15"
          rx="7.5"
          transform="translate(274.094 374)"
        />
        <rect
          id="Rectangle_1522-8"
          data-name="Rectangle 1522"
          className="cls-3"
          width="29"
          height="15"
          rx="7.5"
          transform="translate(274.094 431)"
        />
        <rect
          id="Rectangle_1523-8"
          data-name="Rectangle 1523"
          className="cls-3"
          width="29"
          height="15"
          rx="7.5"
          transform="translate(274.094 483)"
        />
        <rect
          id="Rectangle_1524-8"
          data-name="Rectangle 1524"
          className="cls-3"
          width="29"
          height="15"
          rx="7.5"
          transform="translate(274.094 537)"
        />
      </g>
    </g>
  </svg>
);
