import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
  checkIsChatPage,
  containsOrderByClause,
  getChatPrefrencesData,
  jsonStringify,
  transformDashedTextToCapitalizedText,
} from "../../utils";

import { TrainBotFeedbackFormProps } from "./analysisdetailpage.views/trainbotfeedbackform";
import {
  AnalysisPageQueryBox,
  QueryBlockGridFilter,
} from "./analysisdetailpage.types";

import { QueryBlocksProps } from "./analysisdetailpage.views/analysisdetailpagecontentsec";
import {
  computeColBasedOnType,
  getTransiantFilteredData,
  inferChartTypeAndData,
} from "./analysisdetailpage.views/analysisdetailpagecontentsec/analysisdetailpagecontentsec.views/queryblocklisting/queryblocklisting.comp/queryblock/queryblock.comp/queryresults/queryresults.utils";

import { SourceTypes } from "../../app.types";
import {
  useDebounce,
  useGetAppState,
  useIsFirstRender,
  useOpenModal,
} from "../../customhooks";
import {
  checkQueryType,
  filterQueryFiltersWhereFilterModelsExists,
} from "./analisisdetailpage.utils";

export const useGetCaddiNegFeedbackProps = (
  queryItem: AnalysisPageQueryBox,
  handlers: QueryBlocksProps["handlers"],
  queries?: AnalysisPageQueryBox[],
  srcTypeId?: SourceTypes,
  doChartComputing?: boolean,
  sendUpdDataToModal?: boolean,
  gatewayId?: string
): any => {
  const openModal = useOpenModal();
  const isChatPage = checkIsChatPage();

  const queryItemDebounce = useDebounce(queryItem, 500);
  const isFirstRender = useIsFirstRender();
  const { modal } = useGetAppState();

  const { updateQueryChartData, onToggleResultsViewQueryBox } = handlers || {};

  const {
    error,
    is_loading: isLoading = false,
    user_feedback: userFeedback,
    results,
    filters,
    query,
    type,
    sub_type: subType,
  } = queryItem || {};

  const { transiant_filters: transiantFilters = {} } = filters || {};

  const {
    chart_config: chartConfig,
    data = [],
    columns = [],
    last_refreshed: lastRefreshed = "",
    record_count: recordCount = 0,
    is_caddi_sample_data: isCaddiSampleData = false,
  } = results || {};

  const {
    chart_y_axises: chartYAxises = [],
    sort_info_of_y_axis: sortInfoOfYAxis,
    chart_type: chartType,
  } = chartConfig || {};

  const chartLabelsLength = chartConfig?.data?.labels?.length || 0;

  const { sql_query: sqlQuery = "" } = query || {};

  const [isShowFeedbackForm, setIsShowFeedbackForm] = useState(false);
  const [isShowFeedbackSuccessMsg, setIsShowFeedbackSuccessMsg] = useState(
    false
  );

  const [feedbackSuccessMsg, setFeedbackSuccessMsg] = useState("");

  const [showRefreshQueryTooltip, setShowRefreshQueryTooltip] = useState(false);

  // ref is only used to get updated value of isShowFeedbackForm on the same render
  const isShowFeedbackFormRef = useRef(false);

  const errorCase: boolean = useMemo(
    () => !!error?.formattedMsg && !isLoading,
    [error, isLoading]
  );

  const toggleFeedbackFormVisibility = useCallback(
    (e?: any, updState?: boolean) => {
      if (updState || !isShowFeedbackFormRef?.current) {
        setIsShowFeedbackSuccessMsg(false);
        setFeedbackSuccessMsg("");
      }

      setIsShowFeedbackForm((st) => updState ?? !st);
      isShowFeedbackFormRef.current =
        updState ?? !isShowFeedbackFormRef?.current;
    },
    [isShowFeedbackForm, setIsShowFeedbackForm]
  );

  const toggleFeedbackSuccessMsg = useCallback(
    (successMsg?: string) => {
      if (!isShowFeedbackFormRef?.current) {
        setFeedbackSuccessMsg(
          successMsg ||
            (isChatPage
              ? "Thanks for the feedback! The Bot owner will be notified."
              : "Thanks for the feedback!")
        );

        setIsShowFeedbackSuccessMsg(true);
        setTimeout(() => {
          setIsShowFeedbackSuccessMsg(false);
          setFeedbackSuccessMsg("");
        }, 5000);
      }
    },
    [isShowFeedbackForm]
  );

  const toggleRefreshQueryTooltip = useCallback((): void => {
    setShowRefreshQueryTooltip(true);
    setTimeout(() => {
      setShowRefreshQueryTooltip(false);
    }, 5000);
  }, []);

  const chartDefaultXAxis = useMemo(() => {
    const {
      attr_col: attrCol = [],
      metrics_as_attr_col: metricsAsAttrCol = [],
    } = computeColBasedOnType(columns, data);

    const isNoAttrCol = !attrCol?.length;
    return isNoAttrCol
      ? metricsAsAttrCol?.[0]?.column_name
      : attrCol?.[0]?.column_name;
  }, [columns, data]);

  const {
    defSortKey,
    defSortdirection,
    shouldDoDefaultSorting,
    gridDefaultSortModel,
    sortingOrderText,
  } = useMemo(() => {
    //to check if the sorting apllied by user or not
    const doestSortExists =
      sortInfoOfYAxis?.direction || filters?.sortModel?.[0]?.sort;

    const contsOrdByClause = containsOrderByClause(sqlQuery);

    const didIAggregatedData =
      data?.length !== chartLabelsLength && contsOrdByClause;

    //if user removes the all y-axise columns then we need to sort based on the first metric column
    const istMetricColName =
      columns?.find((col) => col?.col_type_id === "MET")?.column_name || "";

    const istYAxisMetColName = chartYAxises?.[0]?.name || "";

    const sortKeyForNonOrderByClause = istYAxisMetColName || istMetricColName;

    const hasSomeOneChangeThexAxis =
      chartDefaultXAxis !== chartConfig?.chart_x_axis;

    const commonCond =
      !contsOrdByClause || didIAggregatedData || hasSomeOneChangeThexAxis;

    const {
      default_chart_sorting: defaultChartSorting = "desc",
    } = getChatPrefrencesData();

    const defsortKeyDefaultForm = commonCond ? sortKeyForNonOrderByClause : "";

    const defSortKey = transformDashedTextToCapitalizedText(
      defsortKeyDefaultForm
    );

    const defSortdirection = commonCond ? defaultChartSorting : "asc";

    // If the query does not have order by clause and user hasn't change the default setting then sort based on the prefrence set by user
    const shouldDoDefaultSortingForChart =
      !doestSortExists && chartType === "Column" && !!defSortKey && commonCond;

    const sortDir = doestSortExists
      ? filters?.sortModel?.[0]?.sort || sortInfoOfYAxis?.direction
      : defSortdirection;

    const sortColName = doestSortExists
      ? filters?.sortModel?.[0]?.colId || sortInfoOfYAxis?.name
      : defsortKeyDefaultForm;

    const showOurOwnSortingStatement =
      commonCond &&
      chartType === "Column" &&
      sortColName &&
      sortDir &&
      !isLoading;

    const sortingOrderText = showOurOwnSortingStatement
      ? `The results are sorted in ${
          sortDir === "asc" ? "ascending" : "descending"
        } order based on the ${sortColName
          ?.replaceAll(/_/g, " ")
          ?.toLocaleLowerCase()}.`
      : "";

    return {
      sortingOrderText,
      shouldDoDefaultSorting: shouldDoDefaultSortingForChart,
      defSortKey,
      defSortdirection,
      gridDefaultSortModel:
        shouldDoDefaultSortingForChart &&
        defSortdirection &&
        defsortKeyDefaultForm
          ? [
              {
                colId: defsortKeyDefaultForm,
                sort: defSortdirection,
              },
            ]
          : undefined,
    };
  }, [
    chartType,
    chartYAxises,
    sortInfoOfYAxis,
    sqlQuery,
    filters?.sortModel,
    isLoading,
    data?.length,
    chartLabelsLength,
    chartDefaultXAxis,
    chartConfig?.chart_x_axis,
  ]);

  const alreadyPercFrmtedCols = useMemo(() => {
    return columns
      ?.filter((item) => item?.is_qry_contains_perc_fmt)
      .map((item) => transformDashedTextToCapitalizedText(item?.column_name));
  }, [columns]);

  const { itemToVisualize } = useMemo(() => {
    const childrens = queries?.filter(
      (qryItem) => qryItem?.parent_query_id === queryItem?.id
    );

    const itemToVisualize = childrens?.length
      ? childrens?.find(
          (item) =>
            item?.results?.chart_config?.chart_type !== "N/A" &&
            item?.results?.chart_config?.chart_type
        ) ||
        childrens?.find((item) => item?.results?.data?.length > 0) ||
        childrens?.[0]
      : queryItem;
    return {
      itemToVisualize,
    };
  }, [queries, queryItem]);

  const itemToVisualizeDebounce = useDebounce(itemToVisualize, 0);

  const onDislikeClick = useCallback(() => {
    if (queryItem?.user_feedback?.response === "like") {
      handlers?.onRemoveFeedback([queryItem?.id], (): void =>
        toggleFeedbackSuccessMsg?.("Feedback removed successfully!")
      );
    }

    if (isChatPage) {
      toggleFeedbackFormVisibility?.();
      return;
    }

    const modalProps: TrainBotFeedbackFormProps = {
      queryItem,
      toggleFeedbackSuccessMsg,
      toggleRefreshQueryTooltip,
      handlers,
      isEditMode: !!userFeedback?.response,
      errorCase,
      itemToVisualize,
      srcTypeId,
      gatewayId,
    };

    openModal({
      modalId: "train_bot_feedback_modal",
      visible: true,
      modalProps,
    });
  }, [
    isChatPage,
    queryItem,
    handlers,
    userFeedback,
    errorCase,
    defSortKey,
    defSortdirection,
    shouldDoDefaultSorting,
    gridDefaultSortModel,
    alreadyPercFrmtedCols,
    itemToVisualize,
    srcTypeId,
    toggleFeedbackSuccessMsg,
    toggleFeedbackFormVisibility,
    gatewayId,
  ]);

  const transiantFiltersList: QueryBlockGridFilter[] = useMemo(() => {
    return doChartComputing
      ? filterQueryFiltersWhereFilterModelsExists(transiantFilters)
      : [];
  }, [transiantFilters, doChartComputing]);

  const dataFilteredWithTransientFilters = useMemo(() => {
    if (!transiantFiltersList?.length || !doChartComputing) {
      return data;
    }

    const metricColumns =
      columns
        ?.filter((col) => col?.col_type_id === "MET")
        ?.map((col) => col?.column_name) || [];

    const alreadyPercentageFormattedCols =
      columns
        ?.filter((col) => col?.is_qry_contains_perc_fmt)
        ?.map((col) => col?.column_name) || [];

    return getTransiantFilteredData(
      data,
      transiantFiltersList,
      metricColumns,
      alreadyPercentageFormattedCols
    );
  }, [data, jsonStringify(transiantFiltersList), doChartComputing]);

  const { isAboutPrompt, isNoCodeQuery, isSummary } = checkQueryType(
    type,
    subType
  );

  const isNoRecords = useMemo(
    () =>
      type === "CCQ" &&
      subType !== "GME" &&
      subType !== "TRM" &&
      !!lastRefreshed &&
      !recordCount,
    [type, subType, recordCount, lastRefreshed, isLoading]
  );

  useEffect(() => {
    const count = computeColBasedOnType(columns, data);
    const shouldComputeOnfirstRender =
      !isFirstRender || !chartConfig || !chartConfig?.chart_type;

    if (
      !isNoCodeQuery &&
      !isSummary &&
      !isAboutPrompt &&
      !isCaddiSampleData &&
      !isLoading &&
      !error &&
      shouldComputeOnfirstRender &&
      doChartComputing
    ) {
      if (!isNoRecords && count?.metric_col?.length >= 1) {
        const chartData = inferChartTypeAndData(
          count,
          dataFilteredWithTransientFilters,
          chartConfig || { is_default_settings: true },
          !!transiantFiltersList?.length
        );

        updateQueryChartData?.(queryItem?.id, chartData, undefined, true);
      } else {
        onToggleResultsViewQueryBox?.(queryItem?.id, "grid");
      }
    }
  }, [
    columns?.length,
    data?.length,
    dataFilteredWithTransientFilters?.length,
    isLoading,
    chartConfig?.is_default_settings,
  ]);

  useEffect(() => {
    if (
      !isChatPage &&
      modal?.visible &&
      modal?.modalId === "train_bot_feedback_modal" &&
      sendUpdDataToModal &&
      (modal?.modalProps as TrainBotFeedbackFormProps)?.queryItem?.id ===
        queryItem?.id
    ) {
      onDislikeClick();
    }
  }, [
    itemToVisualizeDebounce?.filters?.sortModel?.[0]?.colId,
    itemToVisualizeDebounce?.results?.chart_config?.chart_type,
    itemToVisualizeDebounce?.results?.columns?.length,
    itemToVisualizeDebounce?.results?.data?.length,
    itemToVisualizeDebounce?.is_loading,
  ]);

  return {
    isShowFeedbackForm,
    isShowFeedbackSuccessMsg,
    feedbackSuccessMsg,
    showRefreshQueryTooltip,
    setShowRefreshQueryTooltip,
    errorCase,
    toggleFeedbackFormVisibility,
    toggleFeedbackSuccessMsg,
    defSortKey,
    defSortdirection,
    shouldDoDefaultSorting,
    gridDefaultSortModel,
    sortingOrderText,
    alreadyPercFrmtedCols,
    onDislikeClick,
    itemToVisualize,
  };
};
