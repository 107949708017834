import { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";

import Button from "../button";
import FilterCriteria from "../filtercriteria/filtercriteria";

import { ModalStyled } from "../modalwrapper/modalwrapper.styles";
import {
  FilterConditionModalFooterStyled,
  FilterConditionModalStyled,
  FilterConditionWrapperStyled,
} from "./filterconditionmodal.styles";

import { FilterConditionModalProps } from "./filterconditionmodal.types";

const FilterConditionModal = (
  props: FilterConditionModalProps
): JSX.Element => {
  const {
    isModalVisible,
    onHandleCancel,
    propsOnHandleSave,
    isEditMode,
    parsedFields,
    parsedFilters,
    existingFilters,
    nameOfFilterComp = "filter_criteria",
    onClearData,
  } = props;

  const {
    handleSubmit,
    trigger,
    formState: { isValid, errors },
  } = useFormContext();

  const saveFiltersAndPassToParent = useCallback(
    (values) => {
      propsOnHandleSave?.(values);
      onHandleCancel();
    },
    [propsOnHandleSave]
  );

  const onHandleCancelClick = useCallback(() => {
    onClearData?.();
    onHandleCancel();
  }, [onClearData, onHandleCancel]);

  const onFiltersChange = useCallback(() => {
    trigger("filter_criteria");
    trigger("reference_filter_criteria");
  }, []);

  return (
    <ModalStyled
      width="65vw"
      visible={isModalVisible}
      onCancel={onHandleCancel}
      title="Filter Condition"
    >
      <FilterConditionModalStyled>
        <FilterConditionWrapperStyled>
          <FilterCriteria
            name={nameOfFilterComp}
            parsedFields={parsedFields}
            parsedFilters={parsedFilters}
            isEditMode={isEditMode}
            existingFilters={existingFilters || []}
            onChangeFilterCriteria={onFiltersChange}
            nodeType="RLS"
          />
        </FilterConditionWrapperStyled>
        <FilterConditionModalFooterStyled className="form-actions-sec">
          <Button id="cancel" width="74px" onClick={onHandleCancelClick}>
            Cancel
          </Button>
          <Button
            id="primary"
            width="74px"
            marginLeft="8px"
            htmlType="submit"
            onClick={
              propsOnHandleSave
                ? handleSubmit(saveFiltersAndPassToParent)
                : onHandleCancel
            }
            disabled={errors?.[nameOfFilterComp]?.length}
          >
            Save
          </Button>
        </FilterConditionModalFooterStyled>
      </FilterConditionModalStyled>
    </ModalStyled>
  );
};

export default FilterConditionModal;
