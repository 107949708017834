import * as yup from "yup";
import { REQUIRED_MESSAGE } from "../../constants/formconstants";
import { CriteriaSchema } from "./modulesettingsformschema";

export const addRoleSchema = yup.object().shape({
  name: yup.string().trim().required(REQUIRED_MESSAGE),
  catalog_table_criteria_enabled: yup.bool(),
  catalog_table_criteria: yup.lazy((_p, p1) => {
    if (p1?.parent?.catalog_table_criteria_enabled) {
      return yup.array().of(CriteriaSchema);
    }
    return yup.array();
  }),
  glossary_table_criteria_enabled: yup.bool(),
  glossary_table_criteria: yup.lazy((_p, p1) => {
    if (p1?.parent?.glossary_table_criteria_enabled) {
      return yup.array().of(CriteriaSchema);
    }
    return yup.array();
  }),
});

export const deleteUserRoleSchema = yup.object().shape({
  assign_user_role_to: yup.string().required(REQUIRED_MESSAGE),
});
