import styled from "styled-components";

export const ChatAgentFieldStyled = styled.div`
  .ant-form {
    .ant-form-item {
      .ant-form-item-control-input {
        .ant-form-item-control-input-content {
          .ant-select {
            width: 365px;
          }
        }
      }
    }
  }

  .selected-chat-agent {
    display: flex;
    column-gap: 25px;

    .label {
      color: ${({ theme }): string => theme?.textColors?.GREY_24};
    }
  }
`;
