import { useCallback, useState } from "react";
import { AnalysisDetailPageSavedContextsSecStyled } from "./analysisdetailpagesavedcontextssec.styles";

import { AnalysisDetailPageSavedContextsSecProps } from "./analysisdetailpagesavedcontextssec.types";

import {
  AddContextView,
  SavedContextsListingView,
} from "./analysisdetailpagesavedcontextssec.components";

import LinkButton from "../../../../components/linkbutton/linkbutton";
import { thinCrossIcon } from "../../../../svgs";

const crossIconSvg = thinCrossIcon();

const AnalysisDetailPageSavedContextsSec = (
  props: AnalysisDetailPageSavedContextsSecProps
): JSX.Element => {
  const {
    isChatPage,
    savedContextsList,
    onUpdateContexts,
    toggleSavedContextsView,
  } = props;

  const [isShowAddContextForm, setIsShowAddContextForm] = useState(false);

  const toggleAddContextView = useCallback(() => {
    setIsShowAddContextForm((st) => !st);
  }, []);

  const toggleSavedContextsViewWrapper = useCallback(
    () => (): void => {
      toggleSavedContextsView();
    },
    []
  );

  return (
    <AnalysisDetailPageSavedContextsSecStyled isChatPage={isChatPage}>
      <div className="saved-contexts-sec-wrapper">
        <LinkButton
          className="header-cross-btn"
          onClick={toggleSavedContextsViewWrapper()}
        >
          {crossIconSvg}
        </LinkButton>

        {isShowAddContextForm ? (
          <AddContextView
            onUpdateContexts={onUpdateContexts}
            onGoBackClick={toggleAddContextView}
          />
        ) : (
          <SavedContextsListingView
            savedContextsList={savedContextsList}
            onUpdateContexts={onUpdateContexts}
            onAddContextClick={toggleAddContextView}
          />
        )}
      </div>
    </AnalysisDetailPageSavedContextsSecStyled>
  );
};

export default AnalysisDetailPageSavedContextsSec;
