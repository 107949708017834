import styled from "styled-components";

export const GuideMeSectionStyled = styled.div`
  .section-heading {
    margin: 5px 0;
    font-size: 15px;
  }
  .no-questions-heading {
    margin: 8px 0;
    font-size: 15px;
  }

  span {
    font-size: 15px;
    display: inline-block;
  }

  .caddi-guide-me-list-desc {
    margin-top: 10px;
  }
`;

export const ListItemsWrapper = styled.ul`
  margin-top: 8px;
  margin-left: 30px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 0;
`;

export const GuideMeListItemStyled = styled.li`
  color: #000;
  font-size: 14px;
  margin-top: 10px;

  .execute-span {
    padding: 0 10px;
    font-size: 12px;
    color: ${({ theme }): string => theme?.textColors?.BLUE_116};

    svg {
      margin: 0 2px 0 5px;

      path {
        fill: ${({ theme }): string => theme?.textColors?.GREY_210};
        stroke: ${({ theme }): string => theme?.textColors?.GREY_210};
      }
    }
  }
`;
