import styled from "styled-components";
import { VerticalDividerStyled } from "../../../../components/dividers/dividers.styles";

export const ErdEdgeEditWrapperStyled = styled.div`
  margin: 25px 15px;
  padding: 20px;
  display: grid;
  background-color: ${({ theme }): string => theme?.bgColors?.LIGHT_GREY_22};
  row-gap: 15px;

  .add-fields-btn {
    .text {
      margin-left: 5px;
    }
  }

  .dvsum-alert {
    svg {
      path {
        fill: ${({ theme }): string => theme.bgColors.YELLOW_223};
      }
    }
  }
`;

export const InputFieldWithLabelStyled = styled.div`
  width: 100%;

  .input-field {
    .ant-select-disabled.ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      background-color: ${({ theme }): string => theme?.bgColors?.GREY_56};
    }

    .dvsum-select-field {
      width: 100%;

      .ant-select-selector {
        height: 40px;
      }
    }
  }
`;

export const InputLabel = styled.p`
  font-family: OpenSansSemiBold;
  font-size: 13.6px;
  color: ${({ theme }): string => theme?.textColors?.GREY_24};
  margin-bottom: 5px;
  display: block;
`;

export const FieldsAndLabelStyled = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 6px;

  .lables {
    display: flex;

    p {
      margin: 0;
    }

    p:first-child {
      min-width: 262px;
    }
  }
`;

export const FieldsWrapperStyled = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 10px;

  .field {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;

    .ant-select {
      width: 233px;

      .ant-select-selector {
        height: 40px;

        .ant-select-selection-item {
          svg {
            display: none;
          }
        }
      }

      .ant-select-item-option-content,
      .ant-select-selection-item-content {
        display: flex;
        align-items: center;
        column-gap: 6px;
      }
    }

    svg {
      opacity: 0.3;
      fill: ${({ theme }): string => theme?.textColors?.GREY_210};
    }
  }
`;

export const FormButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 10px;
  margin-top: 25px;

  button {
    font-size: 14.4px;
    padding: 5px 15px;
  }
`;

export const LoadingSpinerStyled = styled.div`
  padding: 5px 5px 5px 10px;
  opacity: unset;
  color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
`;

export const DividerStyled = styled(VerticalDividerStyled)`
  color: ${({ theme }): string => theme?.bgColors?.GREY_56};
`;
