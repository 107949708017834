export const transferBlankSlate = (
  <svg
    width="402"
    height="214"
    viewBox="0 0 402 214"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="8" width="24" height="24" rx="12" fill="#F0F0F0" />
    <rect x="44" y="4" width="195" height="16" rx="8" fill="#F0F0F0" />
    <rect x="8" y="38" width="24" height="24" rx="12" fill="#F0F0F0" />
    <rect x="44" y="42" width="195" height="16" rx="8" fill="#F0F0F0" />
    <rect x="8" y="76" width="24" height="24" rx="12" fill="#F0F0F0" />
    <rect x="44" y="80" width="195" height="16" rx="8" fill="#F0F0F0" />
    <rect x="8" y="114" width="24" height="24" rx="12" fill="#F0F0F0" />
    <rect x="44" y="118" width="195" height="16" rx="8" fill="#F0F0F0" />
    <rect x="8" y="152" width="24" height="24" rx="12" fill="#F0F0F0" />
    <rect x="44" y="156" width="195" height="16" rx="8" fill="#F0F0F0" />
    <rect x="8" y="190" width="24" height="24" rx="12" fill="#F0F0F0" />
    <rect x="44" y="194" width="195" height="16" rx="8" fill="#F0F0F0" />
  </svg>
);
