export function sortObjectsArrayByKeyDescOrder<T>(
  objArray: T[],
  key: string
): T[] {
  try {
    return objArray?.sort((a: any, b: any) =>
      a[key] > b[key] ? -1 : b[key] > a[key] ? 1 : 0
    );
  } catch (e) {
    return objArray || [];
  }
}
