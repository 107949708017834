import { useCallback, useMemo, useState } from "react";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

import { GroupedVirtuoso } from "react-virtuoso";

import { QueryChartSettingsTag } from "./querychartsettings.comp";
import { QueryChartSettingsStyled } from "./querychartsettings.styles";

import {
  QueryChartSettingsProps,
  TooltipStateType,
} from "./querychartsettings.types";

import LinkButton from "../../../../../../../../../../../../components/linkbutton/linkbutton";

import {
  AnalysisPageQueryBoxChartConfig,
  SortModelType,
} from "../../../../../../../../../../analysisdetailpage.types";

import { ColumnDataType } from "../../../../../../../../../../../../parsers";
import { thinCrossIcon } from "../../../../../../../../../../../../svgs";

import { AxisNameType } from "./querychartsettings.comp/querychartsettingstag/querychartsettingstag.types";
import { computeColumnSortDirection } from "../../../../../../../../../../analisisdetailpage.utils";

const crossIconSvg = thinCrossIcon("12", "12");

const QueryChartSettings = (props: QueryChartSettingsProps): JSX.Element => {
  const [showSortTooltip, setShowSortTooltip] = useState<TooltipStateType>();

  const {
    id = "",
    isChatPage = false,
    isMaximizeQueryBlock = false,
    chartConfig,
    updateQueryChartData,
    onChangeQuerySettings,
    queryColumns,
    queryFilterSortModel,
    isChartVisible = false,
  } = props;

  const {
    chart_x_axis: chartXAxis = "",
    chart_y_axises: chartYAxises = [],
    attribute_columns: attributeColumns = [],
    datetime_columns: dateTimeColumns = [],
    metric_columns: metricColumns = [],
    chart_x_Axis_data_type: xAxisDatatype,
  } = chartConfig || {};

  const hasNoYAxes = !chartYAxises?.length;
  const hasYAxes = !!chartYAxises?.length;

  const chartYaxisColName = chartYAxises?.map((yAxisCol) => yAxisCol?.name);

  const isYAxisReachedMaxLimit = chartYAxises?.length >= 12;

  const xAxisColumnsList = [...attributeColumns, ...dateTimeColumns]?.filter(
    (column) => column?.column_name !== chartXAxis
  );

  const yAxisColumnsList = metricColumns?.filter(
    (column) =>
      ![...chartYaxisColName, chartXAxis]?.includes(column?.column_name)
  );

  const onChangeYaxisOrientation = useCallback(
    (event: CheckboxChangeEvent, name: string) => {
      updateQueryChartData(id, {
        ...chartConfig,
        chart_type: undefined,
        is_default_settings: false,
        has_no_y_axes: hasNoYAxes,
        chart_y_axises: chartYAxises?.map((yAxisItem) => {
          return {
            ...yAxisItem,
            orientation:
              yAxisItem?.name === name
                ? event?.target?.checked
                  ? "right"
                  : "left"
                : yAxisItem?.orientation,
          };
        }),
      });
    },
    [updateQueryChartData, id, hasNoYAxes, chartYAxises, chartConfig, id]
  );

  const onRemoveYAxisClick = useCallback(
    (name: string) => (): void => {
      const updatedYAxis = chartYAxises
        ?.filter((yAxis) => yAxis?.name !== name)
        ?.map((yAxisItem, index) => ({
          ...yAxisItem,
          order: index + 1,
        }));

      const isGridSortedByYAxisField =
        chartConfig?.sort_info_of_y_axis?.name ===
        queryFilterSortModel?.[0]?.colId;

      updateQueryChartData(
        id,
        {
          ...chartConfig,
          chart_type:
            chartYAxises?.length >= 2 ? undefined : chartConfig?.chart_type,
          is_default_settings: false,
          chart_y_axises: updatedYAxis,
          has_no_y_axes: updatedYAxis?.length === 0,
          ...(name === chartConfig?.sort_info_of_y_axis?.name && {
            sort_info_of_y_axis: undefined,
          }),
        },
        isGridSortedByYAxisField ? [] : queryFilterSortModel
      );
    },
    [updateQueryChartData, chartConfig, chartYAxises, id, queryFilterSortModel]
  );

  const onChangeSortDirOfXAxis = useCallback(() => {
    const sortDirection = computeColumnSortDirection(
      chartConfig?.sort_direction
    );

    const updatedSortModel: SortModelType = [
      {
        colId: chartXAxis,
        sort: sortDirection,
      },
    ];

    updateQueryChartData(
      id,
      {
        ...chartConfig,
        chart_type: undefined,
        is_default_settings: false,
        has_no_y_axes: hasNoYAxes,
        sort_info_of_y_axis: undefined,
        sort_direction: sortDirection,
      },
      updatedSortModel
    );
  }, [
    updateQueryChartData,
    chartConfig,
    hasNoYAxes,
    id,
    chartXAxis,
    queryFilterSortModel,
  ]);

  const onChangeSortDirOfYAxis = useCallback(
    (name: string) => (): void => {
      const sortDir =
        chartConfig?.sort_info_of_y_axis?.name === name
          ? computeColumnSortDirection(
              chartConfig?.sort_info_of_y_axis?.direction
            )
          : "asc";

      const updatedSortModel: SortModelType = [
        {
          colId: name,
          sort: sortDir,
        },
      ];

      updateQueryChartData(
        id,
        {
          ...chartConfig,
          chart_type: undefined,
          is_default_settings: false,
          has_no_y_axes: hasNoYAxes,
          sort_direction: undefined,
          sort_info_of_y_axis: {
            name,
            direction: sortDir,
          },
        },
        updatedSortModel
      );
    },
    [updateQueryChartData, chartConfig, hasNoYAxes, id, queryFilterSortModel]
  );

  const onChangeXAxis = useCallback(
    (name: string, dataType: ColumnDataType) => (): void => {
      const isGridSortedByExistingXAxisField =
        chartConfig?.chart_x_axis === queryFilterSortModel?.[0]?.colId;

      const isNewAddedXAxisFieldAlreadySortedonGrid =
        queryFilterSortModel?.[0]?.colId === name;

      updateQueryChartData(
        id,
        {
          ...chartConfig,
          is_default_settings: false,
          has_no_y_axes: hasNoYAxes,
          chart_type: hasYAxes ? undefined : chartConfig?.chart_type,
          chart_x_axis: name,
          chart_x_Axis_data_type: dataType,
          sort_direction: isNewAddedXAxisFieldAlreadySortedonGrid
            ? queryFilterSortModel?.[0]?.sort
            : undefined,
        },

        isGridSortedByExistingXAxisField ? [] : queryFilterSortModel
      );
    },
    [
      updateQueryChartData,
      chartConfig,
      hasNoYAxes,
      hasYAxes,
      id,
      queryFilterSortModel,
    ]
  );

  const onAddYAxis = useCallback(
    (
      name: string,
      dataType: ColumnDataType,
      displayName: string
    ) => (): void => {
      const prevYAxises =
        chartYAxises?.length === 1
          ? chartYAxises?.map((yAxis) => ({ ...yAxis, orientation: "right" }))
          : chartYAxises;

      const updatedYaxis = [
        ...prevYAxises,
        {
          name,
          data_type: dataType,
          order: prevYAxises?.length + 1,
          orientation: "left",
          hide: false,
          display_name: displayName,
        },
      ] as AnalysisPageQueryBoxChartConfig["chart_y_axises"];

      const isNewAddedYAxisFieldAlreadySortedOnGrid =
        queryFilterSortModel?.[0]?.colId === name;

      updateQueryChartData(id, {
        ...chartConfig,
        chart_type: undefined,
        is_default_settings: false,
        has_no_y_axes: false,
        chart_y_axises: updatedYaxis,

        ...(isNewAddedYAxisFieldAlreadySortedOnGrid && {
          sort_info_of_y_axis: {
            name,
            direction: isNewAddedYAxisFieldAlreadySortedOnGrid
              ? queryFilterSortModel?.[0]?.sort
              : undefined,
          },
        }),
      });
    },
    [updateQueryChartData, id, chartConfig, chartYAxises]
  );

  const onHideSortTooltip = useCallback(
    () => (): void => {
      setShowSortTooltip({});
    },
    []
  );

  const onShowSortTooltip = useCallback(
    (axis: AxisNameType = "x", index: number = 0) => (): void => {
      setShowSortTooltip({ [`${axis}${index}`]: true });
    },
    []
  );

  const onColumnNameClick = useCallback(
    (e: React.MouseEvent<HTMLElement>, columnName: string): void => {
      onChangeQuerySettings?.({
        e,
        id,
        columnName,
        type: "col_selection",
        tab: "field",
      });
    },
    [id, onChangeQuerySettings]
  );

  const updXAxisDataType = useMemo(() => {
    return (
      xAxisDatatype ||
      queryColumns?.find((column) => column?.column_name === chartXAxis)
        ?.data_type
    );
  }, [chartXAxis, queryColumns, xAxisDatatype]);

  const { groupContent, groupCounts, itemContent } = useMemo(() => {
    const groupCounts = [];
    const groupContent = [];
    const itemContent = [];

    if (chartXAxis) {
      groupCounts.push(1);
      groupContent.push(<div className="sec-title">X-axis</div>);
      itemContent.push(
        <LinkButton
          className="tag-wrapper-btn"
          tooltipProps={{
            title: "Sort field",
            visible: !!showSortTooltip?.x0,
          }}
          onMouseEnter={onShowSortTooltip()}
          onMouseLeave={onHideSortTooltip()}
          onClick={onChangeSortDirOfXAxis}
        >
          <QueryChartSettingsTag
            dataType={updXAxisDataType}
            onColumnNameClick={onColumnNameClick}
            columnIndex={0}
            axisName="x"
            columnName={chartXAxis}
            variant="BLU"
            isShowSortIcon
            sortDirection={chartConfig?.sort_direction}
            onHideSortTooltip={onHideSortTooltip}
            onShowSortTooltip={onShowSortTooltip}
          />
        </LinkButton>
      );
    }

    if (chartYAxises?.length) {
      groupCounts.push(chartYAxises?.length);
      groupContent.push(
        <div className="y-axis-sec-title sec-title">Y-axis</div>
      );
      itemContent.push(
        ...chartYAxises?.map((yAxis, yAxisIndex) => {
          return (
            <div
              className="y-axis-tag-item"
              key={`added-y-axis-${yAxis?.name}`}
            >
              <LinkButton
                tooltipProps={{
                  title: "Sort field",
                  visible: !!showSortTooltip?.[`y${yAxisIndex}`],
                }}
                onMouseEnter={onShowSortTooltip("y", yAxisIndex)}
                onMouseLeave={onHideSortTooltip()}
                onClick={onChangeSortDirOfYAxis(yAxis?.name)}
                className="tag-wrapper-btn"
              >
                <QueryChartSettingsTag
                  dataType={yAxis?.data_type}
                  onColumnNameClick={onColumnNameClick}
                  onHideSortTooltip={onHideSortTooltip}
                  onShowSortTooltip={onShowSortTooltip}
                  columnName={yAxis?.name}
                  columnIndex={yAxisIndex}
                  axisName="y"
                  variant="BLU"
                  isShowCheckbox
                  checkboxTooltip="Secondary Axis"
                  isChecked={yAxis?.orientation === "right"}
                  onCheckboxClick={(e): void =>
                    onChangeYaxisOrientation(e, yAxis?.name)
                  }
                  sortDirection={
                    chartConfig?.sort_info_of_y_axis?.name === yAxis?.name
                      ? chartConfig?.sort_info_of_y_axis?.direction
                      : undefined
                  }
                  isShowSortIcon={
                    chartConfig?.sort_info_of_y_axis?.name === yAxis?.name
                  }
                />
              </LinkButton>
              <LinkButton
                className="cross-icon"
                onClick={onRemoveYAxisClick(yAxis?.name)}
              >
                {crossIconSvg}
              </LinkButton>
            </div>
          );
        })
      );
    }

    if (xAxisColumnsList?.length || yAxisColumnsList?.length) {
      groupCounts.push(1);
      groupContent.push(
        <div className="not-visualized-sec-title sec-title">Not Visualized</div>
      );
      itemContent.push(
        <div className="not-visualized-desc">
          Click on the field name from the below categories to view its
          profiling.
        </div>
      );
    }

    if (xAxisColumnsList?.length) {
      groupCounts.push(xAxisColumnsList?.length);
      groupContent.push(<div className="sec-title">Attributes & Dates</div>);
      itemContent.push(
        ...xAxisColumnsList?.map((column, index) => (
          <LinkButton
            key={column?.column_id + index}
            className="tag-wrapper-btn"
          >
            <QueryChartSettingsTag
              axisName="x"
              columnIndex={0}
              columnName={column?.column_name}
              variant="GRY"
              dataType={column?.data_type}
              onColumnNameClick={onColumnNameClick}
              showAddAxisButton
              onAddOnXAxis={onChangeXAxis(
                column?.column_name,
                column?.data_type
              )}
            />
          </LinkButton>
        ))
      );
    }

    if (yAxisColumnsList?.length) {
      groupCounts.push(yAxisColumnsList?.length);
      groupContent.push(
        <>
          <div className="sec-title">Metric Fields</div>
          {isYAxisReachedMaxLimit && (
            <div className="validation-text">
              Remove at least 1 metric from Y-axis to add from here.
            </div>
          )}
        </>
      );
      itemContent.push(
        ...yAxisColumnsList?.map((column, index) => (
          <LinkButton
            key={column?.column_id + index}
            className="tag-wrapper-btn"
          >
            <QueryChartSettingsTag
              axisName="y"
              columnIndex={0}
              columnName={column?.column_name}
              variant="GRY"
              dataType={column?.data_type}
              onColumnNameClick={onColumnNameClick}
              showAddAxisButton
              disabledAddAxisButton={isYAxisReachedMaxLimit}
              onAddOnXAxis={
                (column?.col_dist_count || 20) < 20
                  ? onChangeXAxis(column?.column_name, column?.data_type)
                  : undefined
              }
              onAddOnYAxis={onAddYAxis(
                column?.column_name,
                column?.data_type,
                column?.column_display_name
              )}
              canAddOnBothAxes
            />
          </LinkButton>
        ))
      );
    }

    return {
      groupCounts,
      groupContent,
      itemContent,
    };
  }, [
    chartXAxis,
    chartYAxises?.length,
    xAxisColumnsList?.length,
    yAxisColumnsList?.length,
    chartConfig,
    queryColumns,
    isYAxisReachedMaxLimit,
    hasNoYAxes,
    hasYAxes,
    id,
    queryFilterSortModel,
    showSortTooltip,
    updXAxisDataType,
  ]);

  return (
    <QueryChartSettingsStyled
      isChatPage={isChatPage}
      isMaximized={isMaximizeQueryBlock}
      isChartVisible={isChartVisible}
    >
      <div className="settings-container">
        {" "}
        <div className="chat-settings-content-container">
          <GroupedVirtuoso
            groupCounts={groupCounts}
            style={{ height: isMaximizeQueryBlock ? 640 : 665 }}
            groupContent={(index): JSX.Element => {
              return groupContent[index];
            }}
            itemContent={(index): JSX.Element => {
              return itemContent[index];
            }}
          />
        </div>
      </div>
    </QueryChartSettingsStyled>
  );
};

export default QueryChartSettings;
