export const typeTuple = <T extends string[]>(...args: T): any => args;

const ids = typeTuple(
  "dashboard",
  "terms",
  "table_dictionary",
  "user_groups",
  "user_roles",
  "users",
  "glossary_terms",
  "glossary_import",
  "glossary_categories",
  "data_domains",
  "profiling",
  "search",
  "home",
  "enterprise_search",
  "sources",
  "account_settings",
  "source_scan_result",
  "product_tour",
  "tagsets",
  "data_import"
);

// type Roles = {
//   admin: "NOA" | "USR" | "ACT";
//   catalog: "VWR" | "EDT" | "ADM";
//   glossary: "VWR" | "EDT" | "ADM" | "ACT";
// };

export const PERMISSION_IDS: {
  [key in typeof ids[number]]: key;
} = {
  dashboard: "dashboard",
  terms: "terms",
  table_dictionary: "table_dictionary",
  user_groups: "user_groups",
  user_roles: "user_roles",
  users: "users",
  glossary_terms: "glossary_terms",
  glossary_import: "glossary_import",
  glossary_categories: "glossary_categories",
  data_domains: "data_domains",
  profiling: "profiling",
  search: "search",
  home: "home",
  enterprise_search: "enterprise_search",
  sources: "sources",
  account_settings: "account-settings",
  source_scan_result: "source_scan_result",
  product_tour: "product_tour",
  tagsets: "tagsets",
};

export const PERMISSION_ROLES: {
  [key in typeof ids[number]]: string[];
} = {
  dashboard: [],
  terms: [],
  table_dictionary: [],
  user_groups: ["ACT", "USR"],
  user_roles: ["ACT", "USR"],
  users: ["ACT", "USR"],
  glossary_terms: [],
  glossary_import: [],
  glossary_categories: [],
  data_domains: ["ADM"],
  sub_domains: ["ADM"],
  profiling: [],
  search: [],
  home: [],
  enterprise_search: [],
  sources: [],
  account_settings: ["ACT"],
  source_scan_result: [],
  product_tour: [],
  tagsets: ["EDT", "ADM"],
  data_import: ["EDT", "ADM"],
};
