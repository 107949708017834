import styled from "styled-components";
import { hexToRgbA } from "../../../../utils";

export const LoadingScreenStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28.8px;
  place-items: center;
  width: 640px;
  padding: 157.6px 0;

  svg {
    #Group_1348 {
      circle {
        fill: ${({ theme }): string => hexToRgbA(theme.bgColors.GREY_41, 0.6)};
        stroke: none;
      }
    }
  }

  .screen-text {
    color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
    font-family: "OpenSansSemiBold";
    font-size: 22.4px;
    text-align: center;
    line-height: 33.6px;
    margin: 0px;
  }

  .ant-btn {
    font-family: "OpenSansSemiBold";
  }
`;
