// <--- Library Imports Start --->
import styled from "styled-components";
// <--- Library Imports End --->

// <--- Styles Start --->
import { ellipsis } from "../../../../styles/app.styles";
// <--- Styles End --->

// <--- Utils Start --->
import { hexToRgbA } from "../../../../utils";
// <--- Utils End --->

export const EntityRendererStyled = styled.div`
  display: flex;
  flex-direction: column;

  .item-name {
    display: flex;
    align-items: center;
    color: ${({ theme }): string => theme.textColors.BLACK_16};

    .node-name,
    .parent-name,
    .root-parent-name {
      margin: 0 8px;
      ${ellipsis}
    }
    .right-icon {
      margin-right: 8px;
      height: 15px;
      svg {
        path {
          fill: #b8bbc3;
        }
      }
    }

    .dmn-icon,
    .cat-icon {
      svg {
        path {
          fill: ${({ theme }): string =>
            hexToRgbA(theme.bgColors.BLACK_16, 0.8)};
        }
      }
    }

    .trm-icon {
      .core-nodes-circle-icon {
        width: 16px;
        min-width: 16px;
        height: 16px;
        min-height: 16px;
        font-size: 11px;
        background: ${({ theme }): string =>
          hexToRgbA(theme.bgColors.BLACK_16, 0.8)};
      }
    }
  }

  .dmn-icon {
    svg {
      g {
        :not(:last-of-type) {
          fill: ${({ theme }): string =>
            hexToRgbA(theme.bgColors.BLACK_16, 0.8)};
          stroke: ${({ theme }): string =>
            hexToRgbA(theme.bgColors.BLACK_16, 0.8)};
        }

        :last-of-type {
          path {
            fill: ${({ theme }): string => theme.textColors.WHITE_COLOR};
          }
        }
      }
    }
  }

  .item-description {
    color: ${({ theme }): string => theme.textColors.GREY_206};
    font-size: 11px;
    ${ellipsis}
  }
`;
