import * as yup from "yup";
import { REQUIRED_MESSAGE } from "../../../constants/formconstants";
import { FilterCriteriaSchema } from "../filtercriteriaschema";
import { descriptionSchema } from "./ruledescriptionschema";

export const addCompareMetricSchema = yup.object().shape({
  rule_type: yup.string()?.trim().required(REQUIRED_MESSAGE),
  rule_description: descriptionSchema,

  metric_type: yup.string().required(REQUIRED_MESSAGE),

  columns: yup.lazy((value, originalObject) => {
    const isCount = originalObject.parent.metric_type === "CNT";
    return isCount
      ? yup.array().of(yup.string())
      : yup.array().of(yup.string()).min(1, REQUIRED_MESSAGE);
  }),

  metrics: yup.lazy((value, originalObject) => {
    const isCount = originalObject.parent.metric_type === "CNT";
    return isCount
      ? yup.array().of(yup.string())
      : yup.array().of(yup.string()).min(1, REQUIRED_MESSAGE);
  }),

  reference_source: yup.string()?.trim().required(REQUIRED_MESSAGE),
  reference_table: yup.string()?.trim().required(REQUIRED_MESSAGE),

  reference_columns: yup.lazy((value, originalObject) => {
    const isCount = originalObject.parent.metric_type === "CNT";
    return isCount
      ? yup.array().of(yup.string())
      : yup.array().of(yup.string()).min(1, REQUIRED_MESSAGE);
  }),

  variance_percent: yup.lazy((value, originalObject) => {
    const isCount = originalObject.parent.metric_type === "CNT";
    return isCount
      ? yup.number()
      : yup
          .number()
          .transform((originalValue, originalObject) =>
            Number.isNaN(originalValue) ? undefined : originalValue
          )
          .min(0, "Variance percent must be greater than or equal to 0")
          .max(100, "Variance percent must be less than or equal to 100")
          .required(REQUIRED_MESSAGE);
  }),

  comparison_type: yup.lazy((value, originalObject) => {
    const isCount = originalObject.parent.metric_type === "CNT";
    return isCount ? yup.string() : yup.string().required(REQUIRED_MESSAGE);
  }),

  reference_metric: yup.lazy((_, props2) => {
    const columns = props2?.parent?.metrics;
    const refColumns = props2?.parent?.reference_metric;

    return yup.array().when(["metric_type", "metrics"], {
      is: (metricType: string, metrics: string[]) =>
        ["SUM", "AVG", "MIN", "MAX"].includes(metricType) && metrics,
      then: yup
        .array()
        .test(
          "lengthCheck",
          "Number of fields should match with Target.",
          () => !columns || !refColumns || columns.length === refColumns.length
        )
        .of(yup.string())
        .required(REQUIRED_MESSAGE),
    });
  }),

  is_filter_criteria_active: yup.boolean(),

  filter_criteria: yup.lazy((_p, p1) => {
    if (p1?.parent?.is_filter_criteria_active) {
      return yup.array().of(
        yup.object().shape({
          condition: yup.array().of(FilterCriteriaSchema),
        })
      );
    }
    return yup.mixed().nullable();
  }),
  reference_filter_criteria: yup.lazy((_p, p1) => {
    if (p1?.parent?.is_filter_criteria_active) {
      return yup.array().of(
        yup.object().shape({
          condition: yup.array().of(FilterCriteriaSchema),
        })
      );
    }
    return yup.mixed().nullable();
  }),
});
