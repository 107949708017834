import styled from "styled-components";

export const ReviewCaddiFeedbacksModalListStyled = styled.div<{
  virtuosoHeight: number;
}>`
  width: 25%;
  height: 100%;

  .list-desc {
    padding: 19.2px 25.6px 11.2px 19.2px;
    color: ${({ theme }): string => theme?.textColors?.GREY_26};
    font-size: 12px;
    font-family: "OpenSansSemiBold";
  }

  .list-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 12.8px;
    padding-bottom: 19.2px;

    .feedbacks-virtual-list {
      height: ${({ virtuosoHeight }): string =>
        `${virtuosoHeight}px !important`};
    }
  }
`;

export const ReviewCaddiFeedbacksModalListItemStyled = styled.div<{
  isSelected: boolean;
}>`
  display: flex;
  padding: 9.6px;
  border: ${({ theme, isSelected }): string =>
    isSelected
      ? `1.5px solid ${theme?.borderColors?.BLUE_116}`
      : `1px solid ${theme?.borderColors?.GREY_56}`};
  border-radius: 6.4px;
  column-gap: 6.4px;
  cursor: pointer;
  background-color: ${({ theme, isSelected }): string =>
    isSelected ? theme?.bgColors?.LIGHT_BLUE_25 : ""};
  margin: 0 18.6px;
  margin-bottom: 12.5px;

  .content-sec {
    .question {
      font-size: 12.8px;
    }

    .time {
      font-size: 9.6px;
      color: ${({ theme }): string => theme?.textColors?.GREY_210};
    }
  }
`;
