import { ProminentTagType } from "../components/genericprominenttag/genericprominenttag.types";
import { DQRuleStatusType } from "../parsers/dataqualityparser/dataqualityparser.types";

export const dqRuleStatusMapping = (
  ruleStatusId: DQRuleStatusType,
  ruleStatus: string
): ProminentTagType => {
  const availableColors: {
    [key in DQRuleStatusType]: ProminentTagType["type"];
  } = {
    PUB: "GRN",
    INA: "GRY",
    LRN: "ORG",
  };
  return {
    id: ruleStatusId,
    name: ruleStatus || "",
    type: availableColors?.[ruleStatusId],
  };
};
