import { useForm } from "react-hook-form";
import { useCallback, useMemo, useState } from "react";

import {
  CloneRuleInputsWrapperStyled,
  CloneRuleModalStyled,
  DescriptionStyled,
  LabelAndInputWrapper,
  LabelStyled,
  MultipleSourceWrapperStyled,
} from "./clonerulemodal.styles";

import { FormItemStyled, FormStyled } from "../../../form";
import Button from "../../../button";

import {
  useCancelModal,
  useGetAppState,
  useOpenModal,
} from "../../../../customhooks";

import { InputField, SelectField } from "../../../formfields";

import {
  CloneRuleModalFormType,
  CloneRuleModalProps,
} from "./clonerulemodal.types";

import {
  selectFilterOption,
  sortListOnSpecificKeyValue,
} from "../../../../utils";
import SuccessNotificationMessage from "../../../successnotificationmessagerendrer/successnotificationmessagerendrer";

import { useGetAllDataSources } from "../../../../api/sourcesservice";
import StateHandler from "../../../statehandler/statehandler";

import { useRequestWithMethod } from "../../../../api";

import {
  CloneRuleSummaryModalPropsType,
  ClonedRulesSummaryType,
} from "../clonerulesummarymodal/clonerulesummarymodal.types";

const CloneRuleModal = (): JSX.Element => {
  const closeModal = useCancelModal();
  const onOpenModal = useOpenModal();

  const {
    modal: { modalProps = {} },
  } = useGetAppState();

  const [sourceToTargetMapping, setsourceToTargetMapping] = useState<{
    [key: string]: string;
  }>({});

  const { selectedRulesWithSourceInfo } = modalProps as CloneRuleModalProps;

  const uniqueSrcNames = new Set();

  const uniqueSources =
    selectedRulesWithSourceInfo?.filter((obj) => {
      if (!uniqueSrcNames?.has(obj?.src_name)) {
        uniqueSrcNames?.add(obj?.src_name);
        return true;
      }
      return false;
    }) || [];

  const { parsedData: sources = [], isLoading, error } = useGetAllDataSources();

  const openCloneRuleSummary = useCallback(
    (clonedRules: ClonedRulesSummaryType[]) => {
      onOpenModal({
        modalId: "clone_rule_summary_modal",
        visible: true,
        modalTitle: "Clone Summary",
        modalProps: {
          clonedRulesSummary: clonedRules,
        } as CloneRuleSummaryModalPropsType,
      });
    },
    []
  );

  const onRulesClonedSuccess = useCallback((res) => {
    closeModal();
    openCloneRuleSummary(res?.data);
  }, []);

  const {
    isLoading: isCloneRuleLoading,
    error: errorInCloningRule,
    onExecuteRequest,
  } = useRequestWithMethod("clone_rule", undefined, true, onRulesClonedSuccess);

  const sourcesSelectOptions = useMemo(() => {
    const sortedSources = sortListOnSpecificKeyValue({
      list: sources,
      key: "name",
    });

    const filteredSources = sortedSources?.filter(
      (source) => !["TBU", "PBI", "EXL", "ADL"]?.includes(source?.sourceTypeId)
    );

    return (
      filteredSources?.map((source) => ({
        label: source?.name,
        value: `${source?.id}`,
      })) || []
    );
  }, [sources]);

  const [hasAnythingChanged, setHasAnythingChanged] = useState<boolean>(false);

  const ruleName =
    selectedRulesWithSourceInfo?.length === 1
      ? selectedRulesWithSourceInfo?.[0]?.node_name
      : "";

  const rulesListingEditForm = useForm<CloneRuleModalFormType>({
    defaultValues: {
      prefix: `Clone - ${ruleName}`,
      target_sources: [],
    },
    // resolver: yupResolver(erdEdgeFormSchema),
    mode: "onChange",
  });

  const {
    control,
    setValue,
    watch,
    handleSubmit,
    formState: { isDirty, isValid },
    reset,
  } = rulesListingEditForm;

  const onTargetSourceChanged = useCallback(
    (sourceId: string, targetId: string) => {
      setsourceToTargetMapping((st) => {
        return {
          ...st,
          [`${sourceId}`]: targetId,
        };
      });
    },
    []
  );

  const onTargetSourceRemoved = useCallback(
    (sourceId: string) => {
      const {
        [sourceId]: _,
        ...newSourceToTargetMapping
      } = sourceToTargetMapping;

      setsourceToTargetMapping(newSourceToTargetMapping);
    },
    [sourceToTargetMapping]
  );

  const onSave = useCallback(
    (values: CloneRuleModalFormType) => {
      const clonedRulesPayload = selectedRulesWithSourceInfo?.map((item) => {
        const srcId = `${item?.src_id}`;
        return {
          src_name: item?.src_name,
          rule_id: item?.node_id,
          rule_name: item?.node_name,
          tgt_src_id: sourceToTargetMapping?.[srcId],
          tgt_rule_desc_prefix: values?.prefix,
        };
      });

      onExecuteRequest(clonedRulesPayload);
    },
    [selectedRulesWithSourceInfo, sourceToTargetMapping]
  );

  return (
    <StateHandler
      isFetching={isLoading || isCloneRuleLoading}
      error={error || errorInCloningRule}
      isModal
    >
      <CloneRuleModalStyled>
        <FormStyled>
          <CloneRuleInputsWrapperStyled>
            <LabelAndInputWrapper>
              <LabelStyled>Prefix</LabelStyled>
              <DescriptionStyled>
                This will be added at the beginning of the selected rule
                description.
              </DescriptionStyled>
              <InputField
                control={control}
                name="prefix"
                placeholder="Prefix"
              />
            </LabelAndInputWrapper>

            <LabelAndInputWrapper>
              <LabelStyled>Data Source</LabelStyled>

              {selectedRulesWithSourceInfo?.length === 1 ? (
                <SelectField
                  setValue={setValue}
                  isAllowClear={false}
                  control={control}
                  name="target_sources.0"
                  allowClear={false}
                  placeholder="Select"
                  options={sourcesSelectOptions}
                  showSearch
                  filterOption={selectFilterOption}
                  propOnChange={(value: string): void =>
                    onTargetSourceChanged(
                      String(selectedRulesWithSourceInfo?.[0]?.src_id),
                      value
                    )
                  }
                />
              ) : (
                <MultipleSourceWrapperStyled>
                  <div className="source-div">
                    <div className="header">From Source</div>
                    <div className="source-list">
                      {uniqueSources?.map((source) => {
                        return (
                          <div
                            className="source-list-item source-item"
                            key={`source-item-${source?.src_id}`}
                          >
                            {source?.src_name}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="target-div">
                    <div className="header">To Source</div>
                    <div className="source-list">
                      {uniqueSources?.map((source, index) => {
                        return (
                          <div
                            className="target-list-item target-item"
                            key={`target-item-${source?.src_id}`}
                          >
                            <SelectField
                              options={sourcesSelectOptions}
                              control={control}
                              name={`target_sources.${index}`}
                              setValue={setValue}
                              isAllowClear={false}
                              filterOption={selectFilterOption}
                              showSearch
                              allowClear
                              placeholder="Select"
                              onClear={(): void =>
                                onTargetSourceRemoved(String(source?.src_id))
                              }
                              propOnChange={(value: string): void =>
                                onTargetSourceChanged(
                                  String(source?.src_id),
                                  value
                                )
                              }
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </MultipleSourceWrapperStyled>
              )}
            </LabelAndInputWrapper>
          </CloneRuleInputsWrapperStyled>

          <FormItemStyled className="form-actions-sec">
            <Button id="cancel" width="74px" onClick={closeModal}>
              Cancel
            </Button>
            <Button
              id="primary"
              width="74px"
              marginLeft="8px"
              htmlType="submit"
              onClick={handleSubmit(onSave)}
              // disabled={!hasAnythingChanged}
            >
              Clone
            </Button>
          </FormItemStyled>
        </FormStyled>
      </CloneRuleModalStyled>
    </StateHandler>
  );
};

export default CloneRuleModal;
