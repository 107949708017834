import { Tooltip } from "antd";
import { useCallback, useMemo, useState } from "react";

import { chevronDown1 } from "../../svgs";
import LinkButton from "../linkbutton";

import { DropDownButtonProps, DropdownItemType } from "./dropdownbutton.types";

import {
  DropDownButtonStyled,
  MenuItemStyled,
  MenuStyled,
} from "./dropdownbutton.styles";
import { useGetAppState } from "../../customhooks";
import Button from "../button";
import { DVSUM_TOOLTIP_CLASS_NAME } from "../../constants";

const DropdownButton = (props: DropDownButtonProps): JSX.Element => {
  const {
    children,
    items,
    drpdnBtnId = "primary",
    drpdnBtnElementId = "",
    disabled = false,
    width = "",
    dropdownLabelClassName = "",
    tooltip,
    hideDropdownIcon = false,
    type = "text",
  } = props;

  const { isOnboardingMode } = useGetAppState();

  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);

  const onMenuItemClick = useCallback(
    (item: DropdownItemType) => (): void => {
      setIsMenuVisible(false);
      item?.onClick?.();
    },
    []
  );

  const ButtonComponent = type === "button" ? Button : LinkButton;

  const dropDownMenu = useMemo(() => {
    return (
      // Change MenuItemStyled to a link button
      <MenuStyled width={width}>
        {items?.map((item) => {
          const {
            key = "",
            value = "",
            customClass = "",
            id = "",
            disabled,
            customComponentAfterItemLabel,
            tooltipProps,
          } = item;
          return (
            <Tooltip
              {...tooltipProps}
              key={key}
              placement="right"
              overlayClassName={`${DVSUM_TOOLTIP_CLASS_NAME}`}
              overlay={undefined}
            >
              <MenuItemStyled
                key={key}
                role="button"
                onClick={!disabled ? onMenuItemClick?.(item) : undefined}
                id={id}
                className={`${customClass}${disabled ? " disabled" : ""}`}
              >
                {item?.icon}
                <span className="item-label">{value}</span>
                {customComponentAfterItemLabel && customComponentAfterItemLabel}
              </MenuItemStyled>
            </Tooltip>
          );
        })}
      </MenuStyled>
    );
  }, [items]);

  return (
    <DropDownButtonStyled
      overlay={dropDownMenu}
      trigger={["click"]}
      visible={isMenuVisible}
      disabled={disabled}
      onVisibleChange={(visible): void => {
        setIsMenuVisible(isOnboardingMode ? true : visible);
      }}
      getPopupContainer={(triggerNode): any =>
        triggerNode?.parentNode as HTMLElement
      }
    >
      <ButtonComponent
        tooltipProps={tooltip}
        elementId={drpdnBtnElementId}
        className={dropdownLabelClassName || "dropdown-label"}
        id={drpdnBtnId}
      >
        {children}
        {!hideDropdownIcon && (
          <span className="dropdown-icon">{chevronDown1()}</span>
        )}
      </ButtonComponent>
    </DropDownButtonStyled>
  );
};

export default DropdownButton;
