import { isDvsumPrefferedPercentageMetricColumn } from "./isdvsumprefferedpercentagemetriccolumn";
import { isUserPrefferedPercentageMetricColumn } from "./isuserprefferedpercentagemetriccolumn";

export const isPercentageMetricColumn = (
  name: string,
  listOfKeywords?: string[]
): boolean => {
  try {
    return (
      isUserPrefferedPercentageMetricColumn(name, listOfKeywords) ||
      isDvsumPrefferedPercentageMetricColumn(name)
    );
  } catch {
    return false;
  }
};
