import { useCallback, useMemo, useState } from "react";

import { Input } from "../../../components/inputs";
import { SourceTypeParserReturnType } from "../../../parsers/sources/sourcesparser.types";

import { searchIconLight } from "../../../svgs";
import { SearchableSourcesContainerStyled } from "./searchablesourcescontainer.styles";

import { SearchableSourcesProps } from "./searchablesourcescontainer.types";

const SearchableSourcesContainer = (
  props: SearchableSourcesProps
): JSX.Element => {
  const {
    sources,
    selectedSources = [],
    disableSearch,
    onClickSourceType,
    className,
    headerTitle,
    headerDesc,
    showSelectedCounter,
    isSourceSelectable = true,
  } = props;
  const [searchText, setSearchText] = useState("");

  const onChangeSearchText = useCallback(
    (event) => {
      setSearchText(event?.target?.value);
    },
    [searchText]
  );

  const filteredSources = useMemo(
    () =>
      sources?.filter(
        (sourceType: SourceTypeParserReturnType) =>
          searchText?.length === 0 ||
          sourceType?.displayName
            ?.toLowerCase()
            .indexOf(searchText?.toLowerCase()) > -1
      ),
    [searchText, sources]
  );

  return (
    <SearchableSourcesContainerStyled
      className={className}
      isSourceSelectable={isSourceSelectable}
    >
      <div className="section-header">
        <div className="left-bar">
          <div className="left-title-wrapper">
            <div className="left-title-header">
              <div>{headerTitle && headerTitle}</div>
              <div className="left-title-header-counter">
                {showSelectedCounter &&
                  `Selected Sources: ${selectedSources.length}`}
              </div>
            </div>
            <div className="left-title-desc">{headerDesc && headerDesc}</div>
          </div>
        </div>
        <div className="right-bar">
          <Input
            className="search-box"
            placeholder="Search"
            prefix={searchIconLight()}
            value={searchText}
            onChange={onChangeSearchText}
            fontSize="13px"
            width="248px"
            disabled={disableSearch}
          />
        </div>
      </div>
      <div className="section-body">
        {filteredSources?.length > 0 ? (
          <div className="sources-container">
            {filteredSources?.map((sourceType: SourceTypeParserReturnType) => {
              const isAlreadySelected = selectedSources.includes(sourceType.id);
              return (
                <div
                  key={`searchable-sources-container-source-${sourceType?.id}`}
                  role="button"
                  className={`source-tile${
                    isAlreadySelected ? " selected" : ""
                  }`}
                  data-sourceType={sourceType.id}
                  onClick={(): void =>
                    onClickSourceType &&
                    onClickSourceType(sourceType, !isAlreadySelected)
                  }
                >
                  {isAlreadySelected && (
                    <span className="ant-checkbox ant-checkbox-checked">
                      <span className="ant-checkbox-inner" />
                    </span>
                  )}
                  <img src={`${sourceType?.adhocInfo?.logoUrl}`} />
                </div>
              );
            })}
          </div>
        ) : (
          <div className="no-source-message">
            No source found with name matching '{searchText}'
          </div>
        )}
      </div>
    </SearchableSourcesContainerStyled>
  );
};

export default SearchableSourcesContainer;
