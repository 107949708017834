import styled from "styled-components";

export const RelatedButtonStyled = styled.div`
  .related-datasets-btn {
    padding: 4.8px 12.8px;
    margin-bottom: 16px;
    font-size: 12px;
    border-radius: 20px;
  }

  .no-schemas-state {
    .sec-blank-slate {
      min-height: 0px;
    }
  }

  .show-related-datasets-btn {
    background: ${({ theme }): string => theme?.bgColors?.BLUE_10};
    color: ${({ theme }): string => theme?.textColors?.GREY_24};
  }

  .hide-related-datasets-btn {
    background: ${({ theme }): string => theme?.bgColors?.BLUE_116};
    color: ${({ theme }): string => theme?.textColors?.WHITE_COLOR};
  }
`;
