import { MassEditableParsedField } from "../../../../parsers/listingpagesparser/listingpagesparser.types";
import { massUpdateItemOptionRendrer } from "./massupdateitemform.rendrer";

export function transformDataToOptions(
  data: MassEditableParsedField["field_data"]
): { value: string; label: JSX.Element; labelText: string; key: string }[] {
  return data.map((item) => ({
    key: item?.id,
    value: item?.id,
    label: massUpdateItemOptionRendrer(item),
    labelText: `${item?.root_name}${item?.parent_name}${item?.value_name}`,
  }));
}
