import { AnalysisdetailpageHeaderBulkSelectionStyled } from "./analysisdetailpageheaderbulkselection.styles";

const AnalysisdetailpageHeaderBulkSelection = (): JSX.Element => {
  return (
    <AnalysisdetailpageHeaderBulkSelectionStyled>
      BulkSelectionAnalysisdetailpageHeaderStyled
    </AnalysisdetailpageHeaderBulkSelectionStyled>
  );
};

export default AnalysisdetailpageHeaderBulkSelection;
