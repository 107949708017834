import styled from "styled-components";

export const ResolveModalStyled = styled.div`
  padding-top: 30px;

  .label {
    font-family: OpenSansSemiBold;
    font-size: 14px;
    padding-left: 80px;
  }
`;

export const ResolveInputsWrapperStyled = styled.div`
  margin: 0 auto;
  padding: 20px 0 60px;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* row-gap: 20px; */
  justify-content: center;
  width: 100%;

  .description {
    font-size: 14px;
    width: 490px;
  }
`;
