import styled from "styled-components";
import { hexToRgbA } from "../../../../utils";

export const ExcelSourceFilesStyled = styled.div<{ isEmpty: boolean }>`
  padding-top: 24px;
  padding-bottom: 24px;
  border: 1px solid
    ${({ theme }): string => hexToRgbA(theme.borderColors.LIGHT_GREY_11, 0.2)};

  .empty-sec {
    display: ${({ isEmpty }): any => !isEmpty && "none"};
  }

  .non-empty-sec {
    display: ${({ isEmpty }): any => isEmpty && "none"};
  }
`;

export const DeleteExcelSourceFilesStyled = styled.div`
  .text {
    padding: 30.4px 28px 56px 28px;
  }
`;
