import { useIsFirstRender } from "usehooks-ts";
import { yupResolver } from "@hookform/resolvers/yup";

import { isBefore, isAfter } from "date-fns";
import { CategoricalChartFunc } from "recharts/types/chart/generateCategoricalChart";

import { useForm } from "react-hook-form";
import { ReactNode, useCallback, useEffect, useMemo, useRef } from "react";

import {
  BarWithProgress,
  Slider,
} from "../../../../../../../../../../../../components";
import {
  ColumnChart,
  LineChart,
} from "../../../../../../../../../../../../components/recharts";

import Flex from "../../../../../../../../../../../../components/flex/flex";
import {
  backIcon2,
  thickTickIcon,
  thinCrossIcon,
} from "../../../../../../../../../../../../svgs";

import {
  ColProfillingSectionStyled,
  VisualDistributionForDistributionDataStyled,
  VisualDistChartItemStyled,
  VisDistSecTitleStyled,
  VisDistSecDescriptionStyled,
  MinMaxFieldLabelStyled,
} from "./queryblockcolumnprofile.styles";

import {
  calculatePercentageFromTotal,
  checkDataTypeOfColumn,
  formatPercentage,
  getObjectKeys,
  isAmountMetricColumn,
  isDateInRange,
  isNumber,
  isNumberInRange,
  isPercentageMetricColumn,
  numberFormatter,
} from "../../../../../../../../../../../../utils";

import { transformColumnToFilterModel } from "../../../queryblock.comp.utils";

import { DatePickerField } from "../../../../../../../../../../../../components/formfields";

import LinkButton from "../../../../../../../../../../../../components/linkbutton/linkbutton";
import GridInputField from "../../../../../../../../../../../tagsetsdetailpage/tagsetsdetailpage.components/gridinputfield/gridinputfield";

import { QueryBlockColumnProfileProps } from "./queryblockcolumnprofile.types";
import {
  visualDistributionForDateRangeSchema,
  visualDistributionForNumericRangeSchema,
} from "./queryblockcolumnprofile.schemas";

import { checkIsChatPage } from "../../../../../../../../../../../../utils/ischatpage";
import {
  filterQueryFiltersWhereFilterModelsExists,
  formatProfilingDate,
  splitProfilingRangeVal,
  findMinMaxValues,
  calculateStep,
  createProfilingRange,
} from "../../../../../../../../../../analisisdetailpage.utils";
import { X_AXIS_DATA_KEY } from "../../../../../../../../../../../../constants";
import { COLUMN_VISUAL_DIST_DATA_KEY } from "../../../../../../../../../../../../parsers/columnparsers";
import { useDebounce } from "../../../../../../../../../../../../customhooks";
import { queryResultsGridHumanReadableFormating } from "../../../queryresults/queryresults.utils";

const tickIcon = thickTickIcon();
const crossIconSvg = thinCrossIcon();

const FALSY_VALUES = ["null", "blanks", ""];

export const ColProfillingSection = (
  props: QueryBlockColumnProfileProps
): JSX.Element => {
  const {
    queryItem,
    columnProfile,
    handlers,
    isCellProfile,
    isFirstQueryProfiling,
  } = props;
  const { filters, id = "", header, results } = queryItem || {};
  const { is_maximize_query_block: isMaximized } = header;

  const isChatPage = checkIsChatPage();

  const { column_name: columnName = "" } = columnProfile || {};
  const { is_sample_data: isSampleData = false } = results || {};

  const {
    transiant_filters: transiantFilters = {},
    applied_filters: appliedFilters = {},
  } = filters || {};

  const isFiltersExist = !!filterQueryFiltersWhereFilterModelsExists(
    appliedFilters
  )?.length;

  const { onProfile } = handlers;

  const colTransFilter = useMemo(() => {
    return transiantFilters?.[columnName] || {};
  }, [transiantFilters?.[columnName]]);

  const { isBlankFilterApplied, isNonBlankFilterApplied } = useMemo(() => {
    return {
      isBlankFilterApplied: colTransFilter?.filter_model?.type === "blanks",
      isNonBlankFilterApplied:
        colTransFilter?.filter_model?.type === "nonBlanks",
    };
  }, [colTransFilter?.filter_model?.type]);

  return (
    <ColProfillingSectionStyled
      isChatPage={isChatPage}
      isNonBlankFilterApplied={isNonBlankFilterApplied}
      isBlankFilterApplied={isBlankFilterApplied}
      data-testid="query-block-col-profiling-section-main-wrapper"
      isFirstQueryProfiling={isFirstQueryProfiling}
      isMaximized={isMaximized}
    >
      <div
        className="back-button-wrapper"
        role="button"
        data-testid="query-block-column-profile-main-header-back-btn"
      >
        <div
          className="back-icon"
          data-testid="query-block-column-profile-back-icon"
        >
          {backIcon2()}
        </div>
        <div
          className="back-text"
          data-testid="query-block-column-profile-back-btn-text"
        >
          Back to Profiling
        </div>

        <LinkButton
          onClick={(e): void => {
            onProfile?.(id, e);
          }}
          data-testid="query-block-column-profile-cross-btn"
        >
          {crossIconSvg}
        </LinkButton>
      </div>
      {!isCellProfile && isSampleData && !isFiltersExist && (
        <div
          className="col-info"
          data-testid="query-block-column-profile-col-info-text"
        >
          This is a profile of the entire Table
        </div>
      )}
    </ColProfillingSectionStyled>
  );
};

const VisualDistSecTitle = ({
  isDistriutionData,
}: {
  isDistriutionData?: boolean;
}): JSX.Element => {
  return (
    <>
      <VisDistSecTitleStyled
        className="visual-dist-sec-title"
        data-testid="visual-dist-sec-title"
      >
        Visual Distribution
      </VisDistSecTitleStyled>
      <VisDistSecDescriptionStyled data-testid="visual-dist-sec-desc">
        {isDistriutionData
          ? `Click on the bars below to filter the data.`
          : "Drag the handles or enter value below to filter the data."}
      </VisDistSecDescriptionStyled>
    </>
  );
};

const VisualDistributionForDateRange = (
  props: QueryBlockColumnProfileProps
): JSX.Element => {
  const { isCellProfile, columnProfile, handlers, queryItem } = props;
  const { chart_data: chartData = [], column_name: columnName } =
    columnProfile || {};

  const { onClearOrAddSingleTransientFilter } = handlers || {};

  const { id = "", filters, settings } = queryItem || {};

  const { transiant_filters: transiantFilters = {} } = filters || {};
  const { date_format: dateFormat } = settings || {};

  const colTransFilters = useMemo(() => transiantFilters?.[columnName] || "", [
    transiantFilters,
  ]);

  const colTransFiltModel = useMemo(() => colTransFilters?.filter_model || {}, [
    colTransFilters,
  ]);

  const { dateFrom = "", dateTo = "" } = colTransFiltModel || {};

  const formDefaultValues = {
    max: new Date(dateTo || dateFrom),
    min: new Date(dateFrom),
  };

  const {
    control,
    formState: { isValid, errors },
    reset,
    trigger,
    watch,
    setValue,
  } = useForm<{ min: Date; max: Date }>({
    defaultValues: formDefaultValues,
    mode: "onChange",
    resolver: yupResolver(visualDistributionForDateRangeSchema),
  });

  const { max: watchMax, min: watchMin } = watch();

  const [minChartTimestamp, maxChartTimestamp] = findMinMaxValues(
    chartData,
    true
  );

  const updatedChartData = useMemo(() => {
    const updatedVal =
      isValid && watchMax && watchMin
        ? chartData?.map((item) => {
            const { min, max, isBothExists } = splitProfilingRangeVal(
              item?.[X_AXIS_DATA_KEY]
            );

            const updatedColor = isBothExists
              ? formatProfilingDate(`${watchMin}`) >=
                  formatProfilingDate(min) &&
                formatProfilingDate(`${watchMax}`) <= formatProfilingDate(max)
                ? "#428bca"
                : "#a3c6e5"
              : isDateInRange(
                  formatProfilingDate(`${watchMin}`),
                  formatProfilingDate(`${watchMax}`),
                  formatProfilingDate(min)
                )
              ? "#428bca"
              : "#a3c6e5";

            return { ...item, fill: updatedColor };
          })
        : chartData;

    return updatedVal?.map((item) => {
      const { min, max, isBothExists } = splitProfilingRangeVal(item?.name);
      const updMin = formatProfilingDate(min);
      const updMax = formatProfilingDate(max);

      return {
        ...item,
        name: isBothExists
          ? createProfilingRange(updMin, updMax)
          : `( ${updMin} )`,
        key: item?.name,
      };
    });
  }, [chartData, dateFormat]);

  const onBarClick: CategoricalChartFunc = useCallback(
    (state) => {
      const { min, max, isBothExists } = splitProfilingRangeVal(
        state?.activePayload?.[0]?.payload?.key
      );

      const commonProp = { shouldValidate: true, shouldDirty: true };

      //checking state?.activeCoordinate because onClick is being trigger even when we click in area between chart and input field
      // So if user actually click on a bar we will get state?.activeCoordinate else they will be null

      if (state?.activeCoordinate) {
        if (isCellProfile) {
          onClearOrAddSingleTransientFilter(
            id,
            transformColumnToFilterModel(
              columnProfile,
              colTransFilters,
              isBothExists
                ? `(${formatProfilingDate(min)} , ${formatProfilingDate(max)})`
                : `(${formatProfilingDate(min)})`
            ),
            isCellProfile
          );
        } else if (!watchMin && !watchMax) {
          const minDate = new Date(parseInt(min));

          setValue("min", minDate, commonProp);
        } else if (!watchMin || !watchMax) {
          const minDate = new Date(parseInt(min));
          const maxDate = new Date(parseInt(max));
          if (isBothExists) {
            setValue("max", maxDate, commonProp);
          } else {
            setValue("max", minDate, commonProp);
          }
        } else {
          setValue("max", "" as any);
          setValue("min", "" as any);
        }
      }
    },
    [watchMin, watchMax, isCellProfile, columnProfile, colTransFilters]
  );

  const onApply = useCallback(() => {
    onClearOrAddSingleTransientFilter(
      id,
      transformColumnToFilterModel(
        columnProfile,
        colTransFilters,
        createProfilingRange(
          formatProfilingDate(`${watchMin}`),
          formatProfilingDate(`${watchMax}`)
        )
      )
    );
  }, [
    columnProfile,
    colTransFilters,
    id,
    watchMin,
    watchMax,
    transformColumnToFilterModel,
    onClearOrAddSingleTransientFilter,
  ]);

  const minValueDebounce = useDebounce(watchMin, 500);
  const maxValueDebounce = useDebounce(watchMax, 500);

  useEffect(() => {
    if (isValid && watchMin && watchMax) {
      onApply();
    }
  }, [minValueDebounce, maxValueDebounce]);

  useEffect(() => {
    if (!colTransFiltModel?.type) {
      reset({ max: "", min: "" });
    }
  }, [colTransFiltModel?.type]);

  useEffect(() => {
    (watchMax || watchMin) && trigger();
  }, [watchMax, watchMin]);

  const formatDatePicketField = useCallback((val: string | Date): string => {
    return formatProfilingDate(`${val?.toString()}`);
  }, []);

  return (
    <VisualDistributionForDistributionDataStyled
      isAnyError={!!getObjectKeys(errors)?.length}
      data-testid="visual-distribution-for-distribution-data-date-range-main-wrapper"
    >
      <VisualDistSecTitle />
      {chartData?.length ? (
        <div
          className="visual-dist-chart"
          data-testid="visual-distribution-for-distribution-date-range-chart-wrapper"
        >
          <LineChart
            width="100%"
            height={170}
            data={updatedChartData}
            hideXAxis
            hideYAxis
            yAxis={[]}
            dataKeys={[
              { dataKey: COLUMN_VISUAL_DIST_DATA_KEY, fill: "#4894ca" },
            ]}
            margin={{ left: -13, right: -12, bottom: 47 }}
            data-testid="visual-distribution-for-distribution-date-range-column-chart"
            showCartesianGrid={false}
            showLedgends={false}
            onClick={onBarClick}
            footerComponent={
              <Slider
                range
                step={calculateStep(
                  minChartTimestamp,
                  maxChartTimestamp,
                  chartData?.length
                )}
                defaultValue={[minChartTimestamp, maxChartTimestamp]}
                tipFormatter={(value): ReactNode => {
                  return <div>{formatProfilingDate(`${value}`)}</div>;
                }}
                max={maxChartTimestamp}
                min={minChartTimestamp}
                value={[
                  new Date(watchMin).getTime() || minChartTimestamp,
                  new Date(watchMax).getTime() || maxChartTimestamp,
                ]}
                onChange={(value: number[]): void => {
                  setValue("max", new Date(value[1]));
                  setValue("min", new Date(value[0]));
                }}
                style={{
                  position: "absolute",
                  left: 10,
                  right: 10,
                  bottom: 22,
                  margin: "auto",
                }}
                included
              />
            }
          />

          {!isCellProfile && (
            <Flex rowGap={20} direction="column">
              <Flex columnGap={10}>
                <Flex direction="column" alignItems="flex-start">
                  <MinMaxFieldLabelStyled>Oldest</MinMaxFieldLabelStyled>
                  <DatePickerField
                    control={control}
                    name="min"
                    placeholder="Start date"
                    format={formatDatePicketField}
                    disabledDate={(currentDate): any =>
                      isAfter(currentDate, watchMax as Date)
                    }
                    showError={false}
                  />
                </Flex>
                -
                <Flex direction="column" alignItems="flex-start">
                  <MinMaxFieldLabelStyled>Newest</MinMaxFieldLabelStyled>
                  <DatePickerField
                    control={control}
                    name="max"
                    placeholder="End Date"
                    format={formatDatePicketField}
                    disabledDate={(currentDate): any =>
                      isBefore(currentDate, watchMin as Date)
                    }
                    showError={false}
                  />
                </Flex>
              </Flex>
            </Flex>
          )}
        </div>
      ) : (
        <div
          className="no-chart-data-exists"
          data-testid="visual-distribution-for-distribution-date-range-blankstate"
        >
          No data exists to show.
        </div>
      )}
    </VisualDistributionForDistributionDataStyled>
  );
};

const VisualDistributionForNumericRange = (
  props: QueryBlockColumnProfileProps
): JSX.Element => {
  const isHandleActive = useRef(false);

  const { isCellProfile, columnProfile, handlers, queryItem } = props;

  const {
    chart_data: chartData = [],
    column_name: columnName,
    data_type: dataType,
    column_display_name: columnDisplayName = "",
  } = columnProfile || {};

  const { onClearOrAddSingleTransientFilter } = handlers || {};

  const { id = "", filters, settings } = queryItem || {};

  const { transiant_filters: transiantFilters = {} } = filters || {};

  const {
    is_data_formatted: isDataFormatted,
    prefrences = {},
    metric_format: metricFormat,
    prev_metric_format: prevMetricFormat,
  } = settings || {};

  const colTransFilters = useMemo(() => transiantFilters?.[columnName] || "", [
    transiantFilters,
  ]);

  const colTransFiltModel = useMemo(() => colTransFilters?.filter_model || {}, [
    colTransFilters,
  ]);

  const { filter = "", filterTo = "" } = colTransFiltModel || {};

  const formDefaultValues = { max: `${filterTo || filter}`, min: `${filter}` };

  const {
    control,
    formState: { isValid, errors },
    reset,
    trigger,
    watch,
    setValue,
  } = useForm<{ min: string; max: string }>({
    defaultValues: formDefaultValues,
    mode: "onChange",
    resolver: yupResolver(visualDistributionForNumericRangeSchema),
  });

  const isMetricCol = useMemo(() => {
    return (
      queryItem?.results?.columns?.find(
        (item) => item?.column_name === columnName
      )?.col_type_id === "MET"
    );
  }, [queryItem?.results?.columns, columnName]);

  const isPercFormatApplied = useMemo(() => {
    return queryItem?.results?.columns?.find(
      (item) => item?.column_name === columnName
    )?.is_qry_contains_perc_fmt;
  }, [queryItem?.results?.columns, columnName]);

  const formatValueAccordingToPrecision = useCallback(
    (value: string | Number, dontUseIsDataformatted?: boolean): string => {
      return isMetricCol
        ? queryResultsGridHumanReadableFormating({
            number: Number(value),
            isDataFormatted: dontUseIsDataformatted ? false : isDataFormatted,
            dataType,
            decimals: prefrences?.[columnName]?.decimals,
          })?.replaceAll(dontUseIsDataformatted ? "," : "", "")
        : `${Math.floor(Number(value))}`;
    },
    [isDataFormatted, columnName, prefrences, dataType, isMetricCol]
  );

  const { max: watchMax, min: watchMin } = watch();

  const isPercentageCol = useMemo(
    () => isMetricCol && isPercentageMetricColumn(columnDisplayName),
    [isMetricCol, metricFormat, columnDisplayName]
  );

  const isCurrencyCol = useMemo(
    () => isMetricCol && isAmountMetricColumn(columnDisplayName),
    [isMetricCol, metricFormat, columnDisplayName]
  );

  const updatedChartData = useMemo(() => {
    const updData = isPercentageCol
      ? chartData?.map((item) => {
          const { max = 0, min = 0, isBothExists } = splitProfilingRangeVal(
            item?.[X_AXIS_DATA_KEY]
          );

          return {
            ...item,
            [X_AXIS_DATA_KEY]: isBothExists
              ? createProfilingRange(
                  formatPercentage(min, isPercFormatApplied),
                  formatPercentage(max, isPercFormatApplied)
                )
              : `( ${formatPercentage(min, isPercFormatApplied)} )`,
          };
        })
      : chartData;

    const updatedValue =
      isValid && watchMax && watchMin
        ? updData?.map((item) => {
            const { max = 0, min = 0, isBothExists } = splitProfilingRangeVal(
              item?.[X_AXIS_DATA_KEY]
            );

            const updatedColor = isBothExists
              ? +formatValueAccordingToPrecision(min, true) >=
                  Number(watchMin) &&
                +formatValueAccordingToPrecision(max, true) <= Number(watchMax)
                ? "#428bca"
                : "#a3c6e5"
              : isNumberInRange(
                  formatValueAccordingToPrecision(min, true),
                  watchMin,
                  watchMax
                )
              ? "#428bca"
              : "#a3c6e5";

            return { ...item, fill: updatedColor };
          })
        : updData;

    return updatedValue?.map((item) => {
      const { min, max, isBothExists } = splitProfilingRangeVal(item?.name);

      const updMin = formatValueAccordingToPrecision(min);
      const updMax = formatValueAccordingToPrecision(max);

      return {
        ...item,
        [X_AXIS_DATA_KEY]: isBothExists
          ? createProfilingRange(
              updMin,
              updMax,
              isCurrencyCol ? "$" : "",
              isPercentageCol ? "%" : ""
            )
          : `( ${updMin} )`,
        key: item?.name,
      };
    });
  }, [
    isValid,
    watchMax,
    watchMin,
    chartData,
    isDataFormatted,
    prefrences,
    isCurrencyCol,
    isPercentageCol,
    isMetricCol,
    isPercFormatApplied,
  ]);

  const Apply = useCallback(
    (isRemovingFilter?: boolean) => {
      onClearOrAddSingleTransientFilter(
        id,
        transformColumnToFilterModel(
          columnProfile,
          colTransFilters,
          createProfilingRange(watchMin, watchMax),
          undefined,
          isRemovingFilter
        )
      );
    },
    [
      columnProfile,
      colTransFilters,
      id,
      watchMin,
      watchMax,
      transformColumnToFilterModel,
      onClearOrAddSingleTransientFilter,
    ]
  );

  const onBarClick: CategoricalChartFunc = useCallback(
    (state) => {
      const { min, max, isBothExists } = splitProfilingRangeVal(
        state?.activePayload?.[0]?.payload?.key
      );

      const commonProp = { shouldValidate: true, shouldDirty: true };

      //checking state?.activeCoordinate because onClick is being trigger even when we click in area between chart and input field
      // So if user actually click on a line we will get state?.activeCoordinate else they will be null

      if (state?.activeCoordinate) {
        if (isCellProfile) {
          onClearOrAddSingleTransientFilter(
            id,
            transformColumnToFilterModel(
              columnProfile,
              colTransFilters,
              isBothExists ? `(${min} , ${max})` : `(${min})`
            ),
            isCellProfile
          );
        } else if (!watchMin && !watchMax) {
          setValue(
            "min",
            formatValueAccordingToPrecision(min, true),
            commonProp
          );
        } else if (!watchMin || !watchMax) {
          if (isBothExists) {
            setValue(
              "max",
              formatValueAccordingToPrecision(max, true),
              commonProp
            );
          } else {
            setValue(
              "max",
              formatValueAccordingToPrecision(min, true),
              commonProp
            );
          }
        } else {
          setValue("max", "");
          setValue("min", "");

          Apply(true);
        }
      }
    },
    [
      watchMin,
      watchMax,
      isCellProfile,
      columnProfile,
      colTransFilters,
      formatValueAccordingToPrecision,
      Apply,
    ]
  );

  const minValueDebounce = useDebounce(watchMin, 500);
  const maxValueDebounce = useDebounce(watchMax, 500);

  useEffect(() => {
    if (isValid && watchMin && watchMax) {
      Apply();
    }
  }, [minValueDebounce, maxValueDebounce]);

  useEffect(() => {
    if (!colTransFiltModel?.type) {
      reset({ max: "", min: "" });
    }
  }, [colTransFiltModel?.type]);

  useEffect(() => {
    (watchMax || watchMin) && trigger();
  }, [watchMax, watchMin]);

  const [minChartValue, maxChartValue] = findMinMaxValues(
    chartData,
    false,
    isPercentageCol,
    isPercFormatApplied
  );

  const step = calculateStep(
    +formatValueAccordingToPrecision(minChartValue, true),
    +formatValueAccordingToPrecision(maxChartValue, true),
    chartData?.length
  );

  const isHistogramdata = useMemo(() => {
    const { min = "", max = "" } = splitProfilingRangeVal(
      chartData?.[0]?.[X_AXIS_DATA_KEY]
    );

    return !!(min && max);
  }, [chartData?.[0]]);

  useEffect(() => {
    const wasPercentage = isPercentageMetricColumn(
      columnDisplayName,
      prevMetricFormat?.percentage_cols_list
    );

    const wasCurrency = isAmountMetricColumn(
      columnDisplayName,
      prevMetricFormat?.currency_cols_list
    );

    const wasDefault = !wasPercentage && !wasCurrency;

    const isCurrentDefault = !isCurrencyCol && !isPercentageCol;

    if (watchMax || watchMin) {
      const isBothExists = watchMax && watchMin;

      if ((wasCurrency && isPercentageCol) || (wasDefault && isPercentageCol)) {
        reset({
          ...(isBothExists && {
            max: `${formatValueAccordingToPrecision(
              formatPercentage(watchMax, isPercFormatApplied),
              true
            )}`,
          }),
          min: `${formatValueAccordingToPrecision(
            formatPercentage(watchMin, isPercFormatApplied),
            true
          )}`,
        });
      } else if (
        (wasPercentage && isCurrencyCol) ||
        (wasPercentage && isCurrentDefault)
      ) {
        reset({
          ...(isBothExists && {
            max: `${formatValueAccordingToPrecision(+watchMax / 100, true)}`,
          }),
          min: `${formatValueAccordingToPrecision(+watchMin / 100, true)}`,
        });
      }
    }
  }, [isCurrencyCol, isPercentageCol]);

  return (
    <VisualDistributionForDistributionDataStyled
      className="ranger-filter-sec"
      isAnyError={!!getObjectKeys(errors)?.length}
      data-testid="visual-distribution-for-distribution-numeric-range-main-wrapper"
    >
      <VisualDistSecTitle isDistriutionData={!isHistogramdata} />
      {chartData?.length ? (
        <div className="visual-dist-chart">
          <ColumnChart
            width="100%"
            height={170}
            data={updatedChartData}
            yAxis={[]}
            dataKeys={[
              { dataKey: COLUMN_VISUAL_DIST_DATA_KEY, fill: "#428bca" },
            ]}
            data-testid="visual-distribution-for-distribution-date-range-column-chart"
            hideXAxis
            hideYAxis
            showCartesianGrid={false}
            showLedgends={false}
            keepStaicBarSize={false}
            margin={{ left: 0, right: 0 }}
            onClick={onBarClick}
            {...(isHistogramdata && {
              footerComponent: (
                <div
                  tabIndex={0}
                  onMouseDown={(): void => {
                    isHandleActive.current = true;
                  }}
                  onBlur={(): void => {
                    isHandleActive.current = false;
                  }}
                  role="button"
                >
                  <Slider
                    range
                    step={step}
                    defaultValue={[
                      +formatValueAccordingToPrecision(minChartValue, true),
                      +formatValueAccordingToPrecision(maxChartValue, true),
                    ]}
                    max={+formatValueAccordingToPrecision(maxChartValue, true)}
                    min={+formatValueAccordingToPrecision(minChartValue, true)}
                    value={[
                      isNumber(watchMin)
                        ? +(watchMin ?? minChartValue)
                        : +formatValueAccordingToPrecision(minChartValue, true),
                      isNumber(watchMax)
                        ? +(watchMax ?? maxChartValue)
                        : +formatValueAccordingToPrecision(maxChartValue, true),
                    ]}
                    onChange={(value: number[]): any => {
                      if (isHandleActive.current) {
                        setValue(
                          "max",
                          `${formatValueAccordingToPrecision(
                            Number(value[1]),
                            true
                          )}`
                        );
                        setValue(
                          "min",
                          `${formatValueAccordingToPrecision(
                            Number(value[0]),
                            true
                          )}`
                        );
                      }
                    }}
                    style={{
                      position: "absolute",
                      left: 0,
                      right: 0,
                      bottom: 22,
                      margin: "auto",
                    }}
                    included
                    tipFormatter={(value): ReactNode => (
                      <div>{formatValueAccordingToPrecision(`${value}`)}</div>
                    )}
                  />
                </div>
              ),
            })}
          />
          {!isCellProfile && (
            <Flex rowGap={20} direction="column">
              <Flex columnGap={10}>
                <Flex direction="column" alignItems="flex-start">
                  <MinMaxFieldLabelStyled>Minimum</MinMaxFieldLabelStyled>
                  <GridInputField
                    control={control}
                    name="min"
                    placeholder="Min"
                    tooltipClassName="dvsum-tooltip"
                    tooltipPlacement="bottomRight"
                  />
                </Flex>
                -
                <Flex direction="column" alignItems="flex-start">
                  <MinMaxFieldLabelStyled>Maximum</MinMaxFieldLabelStyled>
                  <GridInputField
                    control={control}
                    name="max"
                    placeholder="Max"
                    tooltipClassName="dvsum-tooltip"
                    tooltipPlacement="bottomRight"
                  />
                </Flex>
              </Flex>
            </Flex>
          )}
        </div>
      ) : (
        <div className="no-chart-data-exists">No data exists to show.</div>
      )}
    </VisualDistributionForDistributionDataStyled>
  );
};

const VisualDistributionForDistributionData = (
  props: QueryBlockColumnProfileProps
): JSX.Element => {
  const isFirstRender = useIsFirstRender();

  const { columnProfile, handlers, queryItem, isCellProfile } = props;

  const { id = "", filters } = queryItem || {};

  const { chart_data: chartData = [], column_name: columnName = "" } =
    columnProfile || {};

  const { transiant_filters: transiantFilters = {} } = filters || {};

  const { onClearOrAddSingleTransientFilter } = handlers || {};

  const { colTransFilter, isFiltersExists } = useMemo(() => {
    return {
      colTransFilter: transiantFilters?.[columnName] || {},
      isFiltersExists: !!transiantFilters?.[columnName]?.filter_model?.values
        ?.length,
    };
  }, [transiantFilters?.[columnName]]);

  const maxValOfChartYAxis = useMemo(() => {
    const listOfYAxis = chartData?.map(
      (item) => item?.[COLUMN_VISUAL_DIST_DATA_KEY]
    );
    return Math.max(...listOfYAxis);
  }, [chartData]);

  const initialSortedChartData = useMemo(() => {
    const chartDataWithSelected = chartData?.map((item) => {
      return {
        ...item,
        is_selected: !!colTransFilter?.filter_model?.values?.includes(
          item?.[X_AXIS_DATA_KEY]
        ),
      };
    });

    return isFiltersExists
      ? chartDataWithSelected?.sort((a, b) =>
          a?.is_selected === b?.is_selected ? 0 : a?.is_selected ? -1 : 1
        )
      : chartDataWithSelected?.sort(
          (a, b) =>
            Number(b?.[COLUMN_VISUAL_DIST_DATA_KEY]) -
            Number(a?.[COLUMN_VISUAL_DIST_DATA_KEY])
        );
  }, [chartData]);

  const sortedChartData = useMemo(() => {
    const chartDataWithSelected = initialSortedChartData?.map((item) => {
      return {
        ...item,
        is_selected: !!colTransFilter?.filter_model?.values?.includes(
          item?.[X_AXIS_DATA_KEY]
        ),
      };
    });

    const sortedData =
      isFiltersExists && isFirstRender
        ? chartDataWithSelected?.sort((a, b) =>
            a?.is_selected === b?.is_selected ? 0 : a?.is_selected ? -1 : 1
          )
        : chartDataWithSelected;

    return sortedData?.filter(
      (sortItem) =>
        !FALSY_VALUES?.includes(
          sortItem?.[X_AXIS_DATA_KEY]?.toLocaleLowerCase()
        )
    );
  }, [
    chartData,
    colTransFilter?.filter_model?.values,
    initialSortedChartData,
    isFiltersExists,
  ]);

  return (
    <VisualDistributionForDistributionDataStyled className="visual-dist-sec">
      <VisualDistSecTitle isDistriutionData />
      {sortedChartData?.length ? (
        <div className="visual-dist-chart">
          {sortedChartData?.map((item, index) => {
            const isCustomObj = item?.isCustomData;

            return (
              <VisualDistChartItemStyled
                data-testid="visual-dist-chart-for-distribution-chart"
                key={`query-block-col-profile-${columnName}-${index}`}
                role="button"
                onClick={(): void => {
                  onClearOrAddSingleTransientFilter?.(
                    id,
                    transformColumnToFilterModel(
                      columnProfile,
                      colTransFilter,
                      item?.[X_AXIS_DATA_KEY]
                    ),
                    isCellProfile
                  );
                }}
                isSelected={item?.is_selected}
              >
                <div
                  className="item-value"
                  data-testid="visual-dist-chart-for-distribution-chart-item-value"
                  title={item?.[X_AXIS_DATA_KEY]}
                >
                  {item?.is_selected ? tickIcon : ""} {item?.[X_AXIS_DATA_KEY]}
                </div>
                <BarWithProgress
                  showValue={!isCustomObj}
                  value={item?.[COLUMN_VISUAL_DIST_DATA_KEY]}
                  maxValue={maxValOfChartYAxis}
                  checkForSelected={isFiltersExists}
                  isSelected={item?.is_selected}
                />
              </VisualDistChartItemStyled>
            );
          })}
        </div>
      ) : (
        <div
          className="no-chart-data-exists"
          data-testid="visual-dist-chart-for-distribution-chart-blankstate"
        >
          No data exists to show.
        </div>
      )}
    </VisualDistributionForDistributionDataStyled>
  );
};

export const ColVisualDistributionSec = (
  props: QueryBlockColumnProfileProps
): JSX.Element => {
  const { data_type: dataType } = props?.columnProfile || {};

  const { isDateColumn, isNumberCol } = checkDataTypeOfColumn(dataType);

  return isDateColumn ? (
    <VisualDistributionForDateRange {...props} />
  ) : isNumberCol ? (
    <VisualDistributionForNumericRange {...props} />
  ) : (
    <VisualDistributionForDistributionData {...props} />
  );
};
