import { useCallback } from "react";

import {
  CancelProfilingModalStyled,
  CancelProfilingModalStyledFooterStyled,
} from "./cancelprofilingmodal.styles";

import Button from "../../../button";
import { useCancelModal, useGetAppState } from "../../../../customhooks";

import { CancelProfilingModalProps } from "./cancelprofilingmodal.types";
import StateHandler from "../../../statehandler/statehandler";

import { useRequestWithMethod } from "../../../../api";
import SuccessNotificationMessage from "../../../successnotificationmessagerendrer/successnotificationmessagerendrer";

import { openNotification } from "../../../../utils";

const CancelProfilingModal = (): JSX.Element => {
  const onCancel = useCancelModal();
  const {
    modal: { modalProps = {} },
  } = useGetAppState();

  const {
    type,
    numberOfNodesSelected,
    jobId,
    onCancelNodeProfilingSuccess,
  } = modalProps as CancelProfilingModalProps;

  const nodeType =
    type === "RLS" ? "rule" : type === "TBL" ? "table" : "column";

  const onSuccess = useCallback((res) => {
    onCancelNodeProfilingSuccess?.();
    openNotification(
      <SuccessNotificationMessage message="Job cancelled successfully." />
    );
  }, []);

  const {
    onExecuteRequest: onCancelNodeProfiling,
    isLoading: isLoadingCancelNodeProfiling,
    error: errorInCancelNodeProfiling,
  } = useRequestWithMethod("cancel_run_profiling", undefined, true, onSuccess);

  const onCancelOnlineProfiling = useCallback(() => {
    const jobConfig = {
      sch_ids: [jobId],
      sch_status: "ABORT",
    };

    onCancelNodeProfiling([jobConfig]);
  }, [jobId]);

  return (
    <StateHandler
      isFetching={isLoadingCancelNodeProfiling}
      error={errorInCancelNodeProfiling}
      isModal
    >
      <CancelProfilingModalStyled>
        <div className="label">
          {type === "RLS"
            ? `${numberOfNodesSelected} ${nodeType}(s) ${
                numberOfNodesSelected > 1 ? "are" : "is"
              } running. Are you sure you want to cancel it?`
            : ` Profiling is in progress on ${numberOfNodesSelected} ${nodeType}(s). Are
        you sure you want to cancel it?`}
        </div>

        <CancelProfilingModalStyledFooterStyled className="form-actions-sec">
          <Button id="cancel" width="74px" onClick={onCancel}>
            Never Mind
          </Button>
          <Button
            id="primary"
            width="74px"
            marginLeft="8px"
            htmlType="submit"
            onClick={onCancelOnlineProfiling}
          >
            Cancel This
          </Button>
        </CancelProfilingModalStyledFooterStyled>
      </CancelProfilingModalStyled>
    </StateHandler>
  );
};

export default CancelProfilingModal;
