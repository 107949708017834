import { useCallback, useState } from "react";
import {
  AddWidgetPickFromGalleryFooterStyled,
  AddWidgetPickFromGalleryMainStyled,
  AddWidgetPickFromGalleryStyled,
  CatergoryTile,
  CatergoryTileSection,
  EmptyWidgetContainerStyled,
  WidgetGroup,
  WidgetsSections,
  WidgetStyled,
  WidgetTile,
} from "./addwidgetpickfromgallery.styles";

import Flex from "../../../../../flex";
import { Input } from "../../../../../inputs";

import {
  boldPlusIcon,
  crossIcon,
  filledWidgetIcon,
  searchIconLight,
  widgetIcon,
} from "../../../../../../svgs";

import {
  isJsonString,
  jsonParse,
  sortListOnSpecificKeyValue,
} from "../../../../../../utils";
import { AddWidgetPickFromGalleryPropsType } from "./addwidgetpickfromgallery.types";

import StateHandler from "../../../../../statehandler";
import { useWidgetCreationPageContext } from "../../../../../../contexts/widgetcreationcontext/widgetcreationcontext";

import {
  GovernanceViewCategoryType,
  WidgetCreationCurrentViewId,
  WidgetTemplateType,
} from "../../../../../../contexts/widgetcreationcontext/widgetcreationcontext.types";

import Button from "../../../../../button";
import { useCancelModal, useDebounce } from "../../../../../../customhooks";

import { useGetRefByType } from "../../../../../../api/refservice";
import { getChartAdhocInfo } from "../../widgetcreationwizard.util";

import { selectedWidgetTemplateTransformer } from "../addwidgetform";
import { NodeType } from "../../../../../../app.types";
import { getChartImage } from "../../../../../../utils/getcharticon";
import { ELEMENT_IDS } from "../../../../../../constants";

const crossIconSvg = crossIcon("12", "12");
const serachIcon = searchIconLight();
const createYourOwnIcon = filledWidgetIcon("50", "50");

const {
  dash_add_wgt_all_wgts_list: DASH_ADD_WGT_ALL_WGTS_LIST,
  dash_add_wgt_pck_frm_gallery_wgt: DASH_ADD_WGT_PCK_FRM_GALLERY_WGT,
} = ELEMENT_IDS;

const AddWidgetPickFromGallery = (
  props: AddWidgetPickFromGalleryPropsType
): JSX.Element => {
  const onCancel = useCancelModal();

  const { state, updateState } = useWidgetCreationPageContext();
  const {
    governanceViewCategories,
    selectedGovernanceCategory,
    governanceViewCategoriesLoading,
    governanceViewCategoriesError,
    selectedWidgetConfig: widgetConfig,
  } = state;

  const {
    parsedData: chartTypeRefs,
    isLoading: isLoadingChartTypeRefs,
    error: errorChartTypeRefs,
  } = useGetRefByType("WIDGET_CHART_TYPE");

  const [searchText, setSearchText] = useState("");

  const onChangeSearchText = useCallback((event) => {
    setSearchText(event?.target?.value);
  }, []);

  const searchDebounce = useDebounce(searchText, 500);

  const debouncedSearchText = searchDebounce && searchText?.toLowerCase();

  const onChangeCategory = (
    selectedGovernanceCategory: GovernanceViewCategoryType
  ) => (): void => {
    updateState({ ...state, selectedGovernanceCategory });
  };

  const onSelectWidget = ({
    selectedWidget,
    selectedCategory,
  }: {
    selectedWidget?: WidgetTemplateType;
    selectedCategory?: NodeType;
  }) => (): void => {
    const orderOfNewlyAddedWidget = widgetConfig?.widgetOrder || 0;

    const selectedWidgetConfigurations = selectedWidget
      ? selectedWidgetTemplateTransformer(
          selectedWidget,
          orderOfNewlyAddedWidget
        )
      : undefined;

    updateState({
      ...state,
      selectedWidget,
      ...(selectedWidget || selectedCategory
        ? selectedWidget
          ? { selectedWidgetConfig: selectedWidgetConfigurations }
          : { selectedWidgetConfig: { govViewCategory: selectedCategory } }
        : undefined),
      currentViewId: "WF",
    });
  };

  const filteredGovernanceViewCategories = debouncedSearchText
    ? governanceViewCategories?.map((item) => {
        if (
          item?.name?.toLowerCase()?.includes(debouncedSearchText) ||
          item?.widgets?.some((widget) =>
            widget?.name?.toLowerCase()?.includes(debouncedSearchText)
          )
        ) {
          return item;
        }

        return {
          ...item,
          widgets: [],
        };
      }) || []
    : governanceViewCategories;

  const sortedFilteredGovernanceViewCategories = sortListOnSpecificKeyValue({
    key: "displayOrder",
    list: filteredGovernanceViewCategories,
    type: "number",
    sortAscending: false,
  });

  const isSelectedFilterIsAll = selectedGovernanceCategory?.name === "ALL";

  const onChangeView = (
    selectedViewId: WidgetCreationCurrentViewId
  ) => (): void => {
    updateState({
      ...state,
      currentViewId: selectedViewId,
      selectedGovernanceCategory: {
        name: "ALL",
        id: "ALL",
        widgets: [],
      },
    });
  };

  return (
    <StateHandler
      isFetching={governanceViewCategoriesLoading || isLoadingChartTypeRefs}
      error={governanceViewCategoriesError || errorChartTypeRefs}
      isModal
    >
      <AddWidgetPickFromGalleryStyled>
        <div className="content">
          <Flex justifyContent="space-between" customClass="heading">
            <div>Choose a widget type to proceed with configuration</div>
            <div className="right-bar">
              <Input
                suffix={
                  searchText ? (
                    <span
                      role="button"
                      onClick={onChangeSearchText}
                      className="clear-search-text-icon"
                    >
                      {crossIconSvg}
                    </span>
                  ) : (
                    <span />
                  )
                }
                className="search-box"
                placeholder="Search"
                prefix={serachIcon}
                value={searchText}
                onChange={onChangeSearchText}
                width="310px"
                disabled={
                  governanceViewCategoriesLoading ||
                  !!governanceViewCategoriesError
                }
                fontSize="14px"
              />
            </div>
          </Flex>

          <AddWidgetPickFromGalleryMainStyled className="AddWidgetPickFromGalleryMainStyled">
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <Flex
                direction="column"
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                <CatergoryTileSection id={DASH_ADD_WGT_ALL_WGTS_LIST}>
                  <CatergoryTile
                    selectedTile={selectedGovernanceCategory?.id === "ALL"}
                    onClick={onChangeCategory({
                      name: "ALL",
                      id: "ALL",
                      widgets: [],
                    })}
                  >
                    All{" "}
                    {`(${
                      sortedFilteredGovernanceViewCategories?.flatMap(
                        (wid) => wid?.widgets
                      )?.length
                    })`}
                  </CatergoryTile>
                  {sortedFilteredGovernanceViewCategories?.map((val) => (
                    <CatergoryTile
                      selectedTile={selectedGovernanceCategory?.id === val?.id}
                      key={val?.id}
                      onClick={onChangeCategory(val)}
                    >
                      {val?.name}{" "}
                      <span className="widget-total">{`(${val?.widgets?.length})`}</span>
                    </CatergoryTile>
                  ))}
                </CatergoryTileSection>
              </Flex>

              <Flex
                direction="column"
                alignItems="flex-start"
                justifyContent="flex-start"
                customClass="widget-section-wrapper"
              >
                <WidgetsSections>
                  {sortedFilteredGovernanceViewCategories?.filter(
                    (govCat) => govCat?.widgets?.length > 0
                  )?.length ? (
                    (isSelectedFilterIsAll
                      ? sortedFilteredGovernanceViewCategories
                      : sortedFilteredGovernanceViewCategories?.filter(
                          (wid) => wid?.id === selectedGovernanceCategory?.id
                        )
                    )?.map((wid) => {
                      const isWidgetsExists = wid?.widgets?.length || 0;
                      return isWidgetsExists ? (
                        <WidgetGroup key={wid?.id}>
                          <div className="widget-section-title">
                            {wid?.name}
                          </div>
                          <Flex
                            justifyContent="flex-start"
                            columnGap={16}
                            flexWrap="wrap"
                            rowGap={25}
                          >
                            {wid?.widgets?.map((widDetail) => {
                              const { name: titleofWidget = "", adhocInfo } =
                                widDetail || {};

                              const parsedSelectedWidgetAdhocInfo = isJsonString(
                                adhocInfo || ""
                              )
                                ? jsonParse(adhocInfo || "")
                                : {};

                              const {
                                chart_type: selectedWidgetChartType = "",
                              } = parsedSelectedWidgetAdhocInfo || {};

                              const chartLogo = getChartImage(
                                selectedWidgetChartType
                              );

                              return (
                                <WidgetTile
                                  id={`${DASH_ADD_WGT_PCK_FRM_GALLERY_WGT}${widDetail?.name
                                    ?.toLowerCase()
                                    ?.replaceAll(" ", "-")}`}
                                  key={widDetail?.id}
                                  onClick={onSelectWidget({
                                    selectedWidget: widDetail,
                                  })}
                                >
                                  <WidgetStyled>
                                    <div className="add-widget-media add-widget-card-img">
                                      <div className="add-widget-card-img">
                                        {chartLogo}
                                      </div>

                                      <div className="plus-icon-div">
                                        {boldPlusIcon()}
                                      </div>
                                    </div>
                                    <div
                                      className="add-widget-card-title"
                                      title={titleofWidget}
                                    >
                                      {titleofWidget}
                                    </div>
                                  </WidgetStyled>
                                </WidgetTile>
                              );
                            })}

                            <WidgetTile
                              key="create-ur-own"
                              createYourOwnWidget
                              onClick={onSelectWidget({
                                selectedCategory: wid?.id as NodeType,
                              })}
                            >
                              <WidgetStyled>
                                <div className="add-widget-media add-widget-card-svg">
                                  {createYourOwnIcon}
                                </div>
                                <div className="add-widget-card-title">
                                  Create your Own
                                </div>
                              </WidgetStyled>
                            </WidgetTile>
                          </Flex>
                        </WidgetGroup>
                      ) : !isSelectedFilterIsAll ? (
                        <EmptyWidgetContainerStyled>
                          {widgetIcon("30", "30")}
                          <span className="no-widget-desc">
                            No widgets available at the moment.
                          </span>
                        </EmptyWidgetContainerStyled>
                      ) : (
                        <span />
                      );
                    })
                  ) : (
                    <EmptyWidgetContainerStyled>
                      {widgetIcon("30", "30")}
                      <span className="no-widget-desc">
                        No widgets available for the searched query.
                      </span>
                    </EmptyWidgetContainerStyled>
                  )}
                </WidgetsSections>
              </Flex>
            </Flex>
          </AddWidgetPickFromGalleryMainStyled>
        </div>

        <AddWidgetPickFromGalleryFooterStyled>
          <Button id="cancel" width="74px" onClick={onCancel}>
            Cancel
          </Button>
          <Button id="primary" width="74px" onClick={onChangeView("COR")}>
            Back
          </Button>
        </AddWidgetPickFromGalleryFooterStyled>
      </AddWidgetPickFromGalleryStyled>
    </StateHandler>
  );
};

export default AddWidgetPickFromGallery;
