import { SelectFieldOption } from "../../../formfields/selectfield/selectfield.types";
import {
  OptionsInfoType,
  SubOptionsMappingType,
  getBaseValue,
  getChildValue,
} from "../../../modalwrapper/modals/widgetcreationwizard";

import { TagStyled } from "./tagrenderer.styles";

const TagRenderer = ({
  tagRendererProps,
  subOptionsMap,
}: {
  tagRendererProps: SelectFieldOption;
  subOptionsMap: SubOptionsMappingType;
}): JSX.Element => {
  const selectedSubMenuItemValue = tagRendererProps?.value?.toString() || "";

  const parentId = getBaseValue(selectedSubMenuItemValue);
  const tagId = getChildValue(selectedSubMenuItemValue);

  const metaInfo = subOptionsMap?.[parentId]?.optionsInfo?.find(
    (item) => item?.optionId === tagId
  );

  const isMenuItemHasSubpanel = metaInfo?.hasSubPanel || false;

  if (!isMenuItemHasSubpanel) {
    if (metaInfo) {
      // this if is to render hourly with last updated on
      return (
        <TagStyled {...tagRendererProps}>
          {(metaInfo as OptionsInfoType)?.optionDesc}
        </TagStyled>
      );
    }
    return (
      <TagStyled {...tagRendererProps}>
        {tagRendererProps?.label?.toString()}
      </TagStyled>
    );
  }

  return <span />;
};

export default TagRenderer;
