import { BackTop } from "antd";
import { arrowUpIcon } from "../../svgs";
import { BackToTopButtonStyled } from "./backtotopbtn.styles";

const BackToTopButtton = (): JSX.Element => {
  return (
    <BackToTopButtonStyled>
      <BackTop>
        <div className="back-to-top">{arrowUpIcon}</div>
      </BackTop>
    </BackToTopButtonStyled>
  );
};

export default BackToTopButtton;
