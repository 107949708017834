export const removeIndex = (array: any[], index: number): any[] => {
  let updatedArray: any[] = [];

  try {
    updatedArray = [...array?.slice(0, index), ...array?.slice(index + 1)];
    updatedArray;
  } catch (e) {
    if (array !== undefined) updatedArray = [...array];
  }

  return updatedArray;
};
