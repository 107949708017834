import styled from "styled-components";

export const TextEditorStyled = styled.div<{ width: number }>`
  .quill {
    border: 1px solid ${({ theme }): string => theme.borderColors.GREY_18};
    border-radius: 4px;
    width: ${({ width }): string => `${width}px`};

    .ql-toolbar {
      border: none;

      .ql-formats {
        position: relative;

        &:not(:last-of-type) {
          &::after {
            content: "";
            position: absolute;
            height: 9px;
            width: 1px;
            background: ${({ theme }): string => theme.bgColors.GREY_210};
            right: -10px;
            transform: translate(0, calc(50% + 3px));
          }
        }

        svg {
          .ql-fill {
            fill: ${({ theme }): string => theme.textColors.GREY_210};
          }

          .ql-stroke {
            stroke: ${({ theme }): string => theme.textColors.GREY_210};
          }
        }

        .ql-picker-label {
          &::before {
            color: ${({ theme }): string => theme.textColors.GREY_210};
          }
        }

        button {
          &:hover,
          &.ql-active {
            color: ${({ theme }): string => theme.textColors.BLUE_116};

            .ql-stroke {
              stroke: ${({ theme }): string => theme.textColors.BLUE_116};
            }

            .ql-fill {
              fill: ${({ theme }): string => theme.textColors.BLUE_116};
            }
          }
        }

        .ql-picker-options {
          .ql-picker-item {
            &:hover {
              &::before {
                color: ${({ theme }): string => theme.textColors.BLUE_116};
              }
            }
          }
        }
      }
    }

    .ql-container {
      border: none;
      background: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;

      .ql-editor {
        height: 155px;
      }

      .ql-tooltip.ql-flip {
        z-index: 10;
      }
    }
  }
`;
