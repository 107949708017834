// <--- Components Start --->
import LinkButton from "../linkbutton";
// <--- Components End --->

// <--- Styles Start --->
import { ConfirmationMessageStyled } from "./confirmationmessage.styles";
// <--- Styles End --->

// <--- SVGs Start --->
import { crossIconInCircle, tickIconInCircle } from "../../svgs";
// <--- SVGs End --->

// <--- Types Start --->
import { ConfirmationMessageProps } from "./confirmationmessage.types";
// <--- Types End --->

const ConfirmationMessage = (props: ConfirmationMessageProps): JSX.Element => {
  const { message = "", onOk, onCancel } = props;

  return (
    <ConfirmationMessageStyled data-testid="confirmation-message-wrapper">
      <p className="action-text" data-testid="confirmation-message-text">
        {message}
      </p>

      <div
        className="actions-wrapper"
        data-testid="confirmation-message-actions-sec-wrapper"
      >
        <LinkButton
          className="tick-icon"
          role="button"
          onClick={onOk}
          data-testid="confirmation-message-tick-icon"
        >
          {tickIconInCircle()}
        </LinkButton>
        <LinkButton
          role="button"
          className="cross-icon"
          onClick={onCancel}
          data-testid="confirmation-message-cross-icon"
        >
          {crossIconInCircle()}
        </LinkButton>
      </div>
    </ConfirmationMessageStyled>
  );
};

export default ConfirmationMessage;
