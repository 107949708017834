// <--- Library Imports Start --->
import styled, { createGlobalStyle } from "styled-components";

// <--- Library Imports End --->

export const AnalysisDetailPageHeaderRightSecStyled = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  background-image: linear-gradient(
    to left,
    #fff 32%,
    rgba(255, 255, 255, 0) -21%
  );

  .menu-item,
  .menu-item > button {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    cursor: pointer;
    font-size: 15px;
    color: ${({ theme }): string => theme?.textColors?.GREY_24};
    width: 100%;

    span {
      display: flex;
      align-items: center;
      height: 32px;
      font-size: 14px;
      color: ${({ theme }): string => theme.textColors.DARK_GREY};
      font-size: 14px;
      :hover {
        color: ${({ theme }): string => theme.textColors.WHITE_COLOR};
      }
    }
  }
  .menu-item {
    :hover {
      background-color: ${({ theme }): string => theme.bgColors.BLUE_116};
    }

    :nth-child(3) {
      border-top: 1px solid
        ${({ theme }): string => theme?.borderColors?.GREY_18};
    }

    button {
      width: 100%;
    }

    span {
      line-height: 14px;
    }

    svg {
      path {
        opacity: 1;
        fill: ${({ theme }): string => theme?.textColors?.GREY_24};
      }
    }
  }

  .action-items-container {
    display: flex;
    flex-direction: row;

    .action-items-wrapper {
      display: flex;
    }
  }

  .btns-wrapper {
    display: flex;
    gap: 5px;

    .edit-btn,
    .manage-access-btn {
      display: flex;
      align-items: center;
      column-gap: 8px;

      svg {
        path {
          fill: ${({ theme }): string => theme?.textColors?.WHITE_COLOR};
        }
      }
    }
    .dot-menu-btn {
      background-color: ${({ theme }): string =>
        theme?.textColors?.WHITE_COLOR};
      border: 1px solid ${({ theme }): string => theme?.textColors?.BLUE_116};

      svg {
        g {
          circle {
            fill: ${({ theme }): string => theme?.textColors?.BLUE_116};
          }
        }
      }
    }

    .save-btns-wrapper {
      display: flex;
    }
  }
`;

export const MenuItemsContainerTooltip = createGlobalStyle<{
  theme: any;
}>`
.analysis-right-section-actions-tooltip{
  padding-top:0;
    .ant-tooltip-content{
      width: max-content; 

    .ant-tooltip-arrow{
      display: none;
    }

    .ant-tooltip-inner{
      box-shadow: unset;
       background-color:${({ theme }): string => theme.bgColors.WHITE_COLOR}; 
       padding: unset;
       height: 100%;
       width: unset;
       
       
    }
  }
}
`;

export const ItemStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12.8px;
  flex-direction: column;
  cursor: pointer;

  p {
    margin-top: 5px;
    margin-bottom: 0;
  }
`;

export const MenuItemswrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  .item {
    display: flex;
    justify-content: center;
    font-size: 12.8px;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    row-gap: 5px;
  }

  .menu-item-name {
    color: ${({ theme }): string => theme?.textColors?.BLACK_COLOR};
  }
`;
