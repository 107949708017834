import { API_CONFIG } from "../../constants/apiconfig";
import { useGetAll } from "../baseservice";

import {
  getDomainSubdomainForTermSelect,
  getParsedGlossaryCategories,
  getParsedGlossaryCategoriesForDomains,
  getParsedGlossaryCategoriesForSelect,
  getParsedGlossaryCategoriesForTerm,
  getParsedTermsForCategory,
  getSubdomainOfUser,
  getUniqueDomainOfLoggedInUser,
} from "../../parsers/glossarycategories/glossarycategoriesparser";

import { CustomReactQueryHookReturnType } from "../services.types";
import { GlossaryCategoriesParserReturnType } from "../../parsers";
import {
  GlossaryCategoriesForDomainsParserReturnType,
  GlossaryCategoriesForSelectParserReturnType,
  SubDomainForSelectReturnType,
  TermsParserResponseType,
} from "../../parsers/glossarycategories/glossarycategoriesparser.types";
import { GlossaryCategoriesResponse } from "../../parsers/termsparser/termsparser.types";
import { getFiltersfromLocalStorage } from "../../utils";

const {
  get_all_glossary_categories: getAllGlossaryCategories,
  get_all_terms: getAllTerms,
  get_loggedin_user_domain_and_subdomain: getLoggedinUserDomainAndSubdomainList,
} = API_CONFIG;

export const useGetAllGlossaryCategories = (): CustomReactQueryHookReturnType<
  GlossaryCategoriesParserReturnType[]
> => {
  return useGetAll({
    apiConfig: getAllGlossaryCategories,
    parser: getParsedGlossaryCategories,
  });
};

export const useGetAllGlossaryCategoriesForDomains = (): CustomReactQueryHookReturnType<
  GlossaryCategoriesForDomainsParserReturnType[]
> => {
  return useGetAll({
    apiConfig: getAllGlossaryCategories,
    parser: getParsedGlossaryCategoriesForDomains,
  });
};

export const useGetAllGlossaryCategoriesForSelect = (): CustomReactQueryHookReturnType<
  GlossaryCategoriesForSelectParserReturnType[]
> => {
  return useGetAll({
    apiConfig: getAllGlossaryCategories,
    parser: getParsedGlossaryCategoriesForSelect,
  });
};

export const useGetAllTermsForCategory = (): CustomReactQueryHookReturnType<
  TermsParserResponseType[]
> => {
  return useGetAll({
    apiConfig: getAllTerms,
    parser: getParsedTermsForCategory,
  });
};

export const useGetAllGlossaryCategoriesForTerm = (): CustomReactQueryHookReturnType<
  GlossaryCategoriesForSelectParserReturnType[]
> => {
  return useGetAll({
    apiConfig: getAllGlossaryCategories,
    parser: getParsedGlossaryCategoriesForTerm,
  });
};

export const useGetLoggedInUserDomainAndSubdomainForTerm = (): CustomReactQueryHookReturnType<
  SubDomainForSelectReturnType[]
> => {
  const parsedNodeFilters = getFiltersfromLocalStorage("TRM");
  return useGetAll({
    apiConfig: getLoggedinUserDomainAndSubdomainList,
    params: [parsedNodeFilters],
    parser: getDomainSubdomainForTermSelect,
  });
};

export const useGetDomainAndSubdomainForDeleteTerm = (): CustomReactQueryHookReturnType<
  string[]
> => {
  const parsedNodeFilters = getFiltersfromLocalStorage("TRM");
  return useGetAll({
    apiConfig: getLoggedinUserDomainAndSubdomainList,
    params: [parsedNodeFilters],
    parser: getUniqueDomainOfLoggedInUser,
  });
};

export const useGetSubdomainForMassUpdate = (): CustomReactQueryHookReturnType<
  GlossaryCategoriesResponse[]
> => {
  const parsedNodeFilters = getFiltersfromLocalStorage("TRM");
  return useGetAll({
    apiConfig: getLoggedinUserDomainAndSubdomainList,
    params: [parsedNodeFilters],
    parser: getSubdomainOfUser,
  });
};
