// <--- Constants Start --->
import { APP_ROUTES } from "../constants";
// <--- Constants End --->

export function getViewOnlyChatDetailPageUrl(
  chatId: string,
  queryId?: string
): string {
  return `${APP_ROUTES.private_routes.read_only_chat}/${chatId}${
    queryId ? `/query-id=${queryId}` : ""
  }`;
}

export function getViewOnlyChatQuestionPageUrl(): string {
  return `${APP_ROUTES.private_routes.view_only_chat_question}`;
}
