import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import {
  FieldItemStyled,
  TrainBotFeedbackTrainMeSecStyled,
} from "./trainbotfeedbacktrainmesec.styles";

import { TrainBotFeedbackTrainMeSecProps } from "./trainbotfeedbacktrainmesec.types";
import TrainBotFeedbackFormInputSec from "../trainbotfeedbackforminputsec/trainbotfeedbackforminputsec";

import TrainbotFeedbackFormResponseSec from "../trainbotfeedbackformresponsesec/trainbotfeedbackformresponsesec";
import Flex from "../../../../../../components/flex";

import { TextAreaField } from "../../../../../../components/formfields";
import { checkIsChatPage } from "../../../../../../utils";

import { TrainbotFeedbackFormResponseForm } from "../../trainbotfeedbackform";

const isChatPage = checkIsChatPage();

const TrainBotFeedbackTrainMeSec = (
  props: TrainBotFeedbackTrainMeSecProps
): JSX.Element => {
  const {
    queryItem,
    handlers,
    itemToVisualize,
    isAuthor,
    srcTypeId,
    gatewayId = "",
  } = props;

  const {
    control,
    trigger,
    watch,
  } = useFormContext<TrainbotFeedbackFormResponseForm>();

  const { reason: watchedReason } = watch();

  const trainMeDesc = useMemo(
    () =>
      watchedReason === "WD" || watchedReason === "WQ" || watchedReason === "WC"
        ? "Explain how the generated response could be improved. Share the correct definition of a metric that I calculated wrong or the field(s) I should use for querying or a join that I missed or SQL."
        : watchedReason === "WV"
        ? "Explain how could the generated response be improved. Share the correct metric that I used wrong or the correct field I should use for visualizing chart."
        : "",
    [watchedReason]
  );

  const trainMePlaceholder = useMemo(
    () =>
      watchedReason === "WD" || watchedReason === "WQ" || watchedReason === "WC"
        ? "Explain how I should get the data"
        : watchedReason === "WV"
        ? "What would be a better way to visualize"
        : "Feel free to add specific details",
    [watchedReason]
  );

  const trainMeFieldSecConfig = useMemo(
    () => ({
      name: "train_me",
      label:
        !isChatPage && watchedReason === "OTH"
          ? "Share feedback in natural language"
          : "",
      placeholder: `${isChatPage ? "(Optional) " : ""}${trainMePlaceholder}`,
    }),
    [isChatPage, watchedReason, trainMePlaceholder]
  );

  const trainSqlOnChange = useCallback(() => {
    trigger(["train_me"]);
  }, []);

  const isReadOnlyMode = isChatPage && !isAuthor;

  return (
    <TrainBotFeedbackTrainMeSecStyled>
      {!isChatPage && watchedReason !== "OTH" && (
        <div className="train-me-detail-sec">
          <div className="train-me-left">
            <div className="train-me-sec-heading">Train Me</div>
            <div className="train-me-sec-info">{trainMeDesc}</div>
            <TrainBotFeedbackFormInputSec
              queryItem={queryItem}
              handlers={handlers}
            />
            <TrainbotFeedbackFormResponseSec
              queryItem={queryItem}
              handlers={handlers}
              itemToVisualize={itemToVisualize}
              srcTypeId={srcTypeId}
              gatewayId={gatewayId}
            />
          </div>
          <div className="train-me-right">
            <Flex rowGap={19} direction="column" alignItems="flex-start">
              <FieldItemStyled>
                <div className="train-me-field-label">
                  Share feedback in natural language
                </div>
                <TextAreaField
                  name="train_me"
                  control={control}
                  width="100%"
                  height="140px"
                  placeholder={trainMePlaceholder}
                />
              </FieldItemStyled>
              {watchedReason !== "WV" && queryItem?.query?.sql_query && (
                <FieldItemStyled>
                  <div className="train-me-field-label">
                    Share feedback as a SQL query
                  </div>
                  <TextAreaField
                    name="train_sql"
                    control={control}
                    width="100%"
                    height="241px"
                    placeholder="Type your SQL query here.."
                    showError={false}
                    propOnChange={trainSqlOnChange}
                  />
                </FieldItemStyled>
              )}
            </Flex>
          </div>
        </div>
      )}

      {(isChatPage || watchedReason === "OTH") && (
        <FieldItemStyled>
          <div className="train-me-field-label">
            {trainMeFieldSecConfig?.label}
          </div>
          <TextAreaField
            name={trainMeFieldSecConfig?.name}
            control={control}
            width="100%"
            height="187px"
            placeholder={trainMeFieldSecConfig?.placeholder}
            disabled={isReadOnlyMode}
          />
        </FieldItemStyled>
      )}
    </TrainBotFeedbackTrainMeSecStyled>
  );
};

export default TrainBotFeedbackTrainMeSec;
