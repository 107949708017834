import { useCallback } from "react";
import { Breadcrumb } from "antd";
import { singleArrowLeft } from "../../../svgs";
import { ExtBreadCrumbStyled } from "./extbreadcrumb.styles";
import LinkButton from "../../linkbutton/linkbutton";
import { ExtBreadCrumProps } from "./extbreadcrum.types";

const ExtBreadCrumb = (props: ExtBreadCrumProps): JSX.Element => {
  const { setCurrentPage } = props;

  const onClickButton = useCallback(() => {
    if (props?.onBackClick) {
      props?.onBackClick();
    } else if (setCurrentPage) {
      setCurrentPage((currentPage) => {
        if (props?.isDirectGoBackToHome) {
          return "home";
        }
        if (currentPage === "extRef") {
          return "results";
        }

        return "home";
      });
    }
  }, [props]);

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <LinkButton
        className="link-button"
        icon={singleArrowLeft()}
        onClick={(): void =>
          props?.onBackClick ? props?.onBackClick() : onClickButton()
        }
      >
        Go Back
      </LinkButton>
    </Breadcrumb.Item>,
  ];

  return <ExtBreadCrumbStyled>{breadcrumbItems}</ExtBreadCrumbStyled>;
};

export default ExtBreadCrumb;
