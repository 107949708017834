import { AxiosResponse } from "axios";
import { SelectFieldOption } from "../../components/formfields/selectfield/selectfield.types";

import {
  GetAllRolesParserReturnType,
  GetAllUsersParserReturnType,
  UserGroupResponse,
  UserGroupsParserReturnType,
  UserRolesResponseType,
  UsersResponseType,
} from "./usergroupsparser.types";

export function getParsedUserGroups({
  data,
}: AxiosResponse<UserGroupResponse[]>): UserGroupsParserReturnType[] {
  return data?.map((item) => ({
    id: item?.USER_GROUP_ID || 0,
    group_name: item?.USER_GROUP_NAME || "",
    role: item?.ROLE_NAME || "",
    users: item?.USERS?.split(",").join(", ") || "",
    users_count: item?.USERS_CNT || 0,
    created_by: item?.CREATED_BY || "",
    role_id: item?.ROLE_ID || 0,
    user_ids: item?.USER_IDS?.length ? item?.USER_IDS?.split(",") : [],
    action: "",
    is_Predefined_Group: item?.IS_PREDEFINED,
    modified_by: item?.UPDATED_BY || "",
    modified_on: item?.UPDATED_ON || "",
    admin_access_type: item?.ADMIN_ACCESS_TYPE || "",
  }));
}

export function getParsedUsers({
  data,
}: AxiosResponse<UsersResponseType[]>): GetAllUsersParserReturnType[] {
  return data?.map((item) => ({
    key: `${item?.USER_ID}`,
    name: `${item?.USER_FIRST_NAME} ${item?.USER_LAST_NAME}`,
    description: item?.USER_GROUP_NAME || "",
    statusId: item?.USER_STATUS,
    adminAccessType: item?.ADMIN_ACCESS_TYPE,
    isOwnerAccount: item?.IS_OWNER_ACCOUNT,
    email: item?.USER_EMAIL || "",
  }));
}

export function getParsedUserRoles({
  data,
}: AxiosResponse<UserRolesResponseType[]>): GetAllRolesParserReturnType[] {
  return data?.map((item) => ({
    value: item?.ROLE_ID,
    label: item?.ROLE_NAME || "",
  }));
}

export function getParsedUserGroupsForSelect({
  data,
}: AxiosResponse<UserGroupResponse[]>): SelectFieldOption[] {
  return data?.map((item) => ({
    label: item?.USER_GROUP_NAME || "",
    value: `${item?.USER_GROUP_ID}`,
  }));
}
