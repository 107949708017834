import styled from "styled-components";

import { hexToRgbA } from "../../../../../../utils";

export const ApplicableToStyled = styled.div`
  display: flex;
  flex-direction: column;

  .applicable-to-row {
    display: grid;
    grid-template-columns: 0.35fr 1fr;
    gap: 10px;

    .applicable-to-name {
      display: flex;
      align-items: center;
      gap: 7px;

      .datasets,
      .terms,
      .fields,
      .rules {
        svg {
          width: 14px;
          height: 12px;
        }
      }

      .fields {
        svg {
          height: 10.5px;
        }
      }
    }

    .applicable-to-description {
      font-size: 14px;
      color: ${({ theme }): string =>
        hexToRgbA(theme.textColors.DARK_GREY_10, 0.7)};
    }
  }
`;
