import moment, { Moment } from "moment";
import { sortBy as sortByLodash } from "lodash";

import { KeyValuePairType } from "../../../../../../../../../../app.types";
import { ColumnDataType } from "../../../../../../../../../../parsers";

import {
  calculateMedian,
  checkDataTypeOfColumn,
  computeMagnitude,
  formatPercentage,
  getEnvVariables,
  getObjectKeys,
  getObjectValues,
  getUniqueList,
  isDateInRange,
  isNumber,
  isNumberInRange,
  isPercentageMetricColumn,
  minMaxDates,
} from "../../../../../../../../../../utils";

import {
  AnalysisPageQueryBoxResultsColumns,
  AnalysisPageQueryBoxChartConfig,
  QueryBoxLoaderType,
  AnalysisPageQueryBox,
  QueryBoxResultDataType,
  QueryBlockGridFilter,
} from "../../../../../../../../analysisdetailpage.types";

const findMaxValMetricName = (
  data: KeyValuePairType<number>[] = [],
  metrics: AnalysisPageQueryBoxChartConfig["chart_y_axises"] = []
): string => {
  const sortedMaxValues = metrics
    ?.map((metric) => ({
      name: metric?.name,
      maxVal: Math.max(...data?.map((item) => item?.[metric?.name])),
    }))
    ?.sort((a, b) => b?.maxVal - a?.maxVal);
  return sortedMaxValues?.[0]?.name;
};

const sumValuesByCategory = (
  data: {
    category: string;
    val: number;
  }[] = [],
  sortedLables: string[],
  sortDirection?: "asc" | "desc",
  isDateType?: boolean
): number[] => {
  const seasonSums = data?.reduce((acc: { [key: string]: number }, curr) => {
    const { val, category } = curr;
    return { ...acc, [category]: Number(acc?.[category] || 0) + Number(val) };
  }, {});

  let sortedSeasonSums: number[] = [];

  if (isDateType && isNumber(data?.[0]?.category) && sortDirection === "desc") {
    sortedSeasonSums = getObjectValues(seasonSums)?.reverse() as number[];
  } else if (!isDateType) {
    sortedSeasonSums = sortedLables?.map((label) => seasonSums?.[label]);
  } else {
    sortedSeasonSums = getObjectValues(seasonSums);
  }

  return sortedSeasonSums;
};

function getMonthName(monthIndex: number): string {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return monthNames[monthIndex];
}

function aggregateDatesByMonthRange(
  data: KeyValuePairType<any>[],
  xColumn: string,
  yColumns: string[]
): KeyValuePairType<any>[] {
  const monthRangeToValueMap = new Map<string, Record<string, number>>();

  for (let item = 0; item < data?.length; item++) {
    const xValue: Moment = data?.[item]?.[xColumn];
    const year = xValue?.year();
    const month = xValue?.month();
    const monthRange = `${getMonthName(month)} ${year}`;

    const monthRangeValue = monthRangeToValueMap.get(monthRange) ?? {};

    yColumns?.forEach((yCol) => {
      const yValue = Number(data?.[item]?.[yCol]) || 0;
      monthRangeValue[yCol] = (monthRangeValue?.[yCol] ?? 0) + yValue;
    });

    monthRangeToValueMap.set(monthRange, monthRangeValue);
  }

  return Array.from(monthRangeToValueMap?.keys())?.map((item) => ({
    [xColumn]: item,
    ...monthRangeToValueMap?.get(item),
  }));
}

function aggregateDatesByDayRange(
  data: KeyValuePairType<any>[],
  xColumn: string,
  yColumns: string[]
): KeyValuePairType<any>[] {
  const dayRangeToValueMap = new Map<string, Record<string, number>>();

  for (let item = 0; item < data?.length; item++) {
    const xValue: Moment = data?.[item]?.[xColumn];
    const dayRange = xValue?.format("MM/DD/YY");

    const dayRangeValue = dayRangeToValueMap.get(dayRange) ?? {};

    yColumns?.forEach((yCol) => {
      const yValue = Number(data?.[item]?.[yCol]) || 0;
      dayRangeValue[yCol] = (dayRangeValue?.[yCol] ?? 0) + yValue;
    });

    dayRangeToValueMap.set(dayRange, dayRangeValue);
  }

  return Array.from(dayRangeToValueMap?.keys())?.map((item) => ({
    [xColumn]: item,
    ...dayRangeToValueMap?.get(item),
  }));
}

function aggregateDatesByYearRange(
  data: KeyValuePairType<any>[],
  xColumn: string,
  yColumns: string[]
): KeyValuePairType<any>[] {
  const yearRangeToValueMap = new Map<string, Record<string, any>>();

  for (let item = 0; item < data?.length; item++) {
    const xValue: Moment = data?.[item]?.[xColumn];
    const year = `${xValue?.year()}`;

    const yearRangeValue = yearRangeToValueMap.get(year) ?? {};

    yColumns?.forEach((yCol) => {
      const yValue = Number(data?.[item]?.[yCol]) || 0;
      yearRangeValue[yCol] = (yearRangeValue?.[yCol] ?? 0) + yValue;
    });

    yearRangeToValueMap.set(year, yearRangeValue);
  }

  return Array.from(yearRangeToValueMap?.keys())?.map((item) => ({
    [xColumn]: item,
    ...yearRangeToValueMap?.get(item),
  }));
}

function aggregateDatesByHourRange(
  data: KeyValuePairType<any>[],
  xColumn: string,
  yColumns: string[]
): KeyValuePairType<any>[] {
  const hourRangeToValueMap = new Map<string, Record<string, number>>();

  for (let item = 0; item < data?.length; item++) {
    const xValue: Moment = data?.[item]?.[xColumn];
    const hourRange = xValue?.format("MM/DD/YY HH:00:00");

    const hourRangeValues = hourRangeToValueMap.get(hourRange) ?? {};

    yColumns?.forEach((yCol) => {
      const yValue = Number(data?.[item]?.[yCol]) || 0;
      hourRangeValues[yCol] = (hourRangeValues?.[yCol] ?? 0) + yValue;
    });

    hourRangeToValueMap.set(hourRange, hourRangeValues);
  }

  return Array.from(hourRangeToValueMap?.keys())?.map((item) => ({
    [xColumn]: item,
    ...hourRangeToValueMap?.get(item),
  }));
}

const aggregateDatesData = (
  data: KeyValuePairType<any>[] = [],
  xColumn: string,
  yColumns: string[],
  chartConfig?: AnalysisPageQueryBoxChartConfig
): {
  aggregated_data: KeyValuePairType<any>[];
  aggregated_by: AnalysisPageQueryBoxChartConfig["date_aggregated_by"];
} => {
  const sortedData = data?.sort((a, b) =>
    (a?.[xColumn] as Moment)?.isBefore(b?.[xColumn] as Moment)
      ? -1
      : (a?.[xColumn] as Moment)?.isAfter(b?.[xColumn] as Moment)
      ? 1
      : 0
  );

  const chartConfigSortedData =
    chartConfig?.sort_direction === "desc" ? sortedData?.reverse() : sortedData;

  const dates = getUniqueList(
    chartConfigSortedData?.map((row) => row?.[xColumn])
  );

  const { max_date: maxDate, min_date: minDate } = minMaxDates(dates);

  const diffInDays = moment.utc(maxDate).diff(minDate, "days");
  const diffInMonths = moment.utc(maxDate).diff(minDate, "months");
  const diffInYears = moment.utc(maxDate).diff(minDate, "years");

  if (diffInDays <= 1) {
    return {
      aggregated_data: aggregateDatesByHourRange(
        chartConfigSortedData,
        xColumn,
        yColumns
      ),
      aggregated_by: "Aggregated to Hour",
    };
  }
  if (diffInDays > 1 && diffInMonths <= 1) {
    return {
      aggregated_data: aggregateDatesByDayRange(
        chartConfigSortedData,
        xColumn,
        yColumns
      ),
      aggregated_by: "Aggregated to day",
    };
  }
  if (diffInMonths > 1 && diffInYears <= 2) {
    return {
      aggregated_data: aggregateDatesByMonthRange(
        chartConfigSortedData,
        xColumn,
        yColumns
      ),
      aggregated_by: "Aggregated to month",
    };
  }

  return {
    aggregated_data: aggregateDatesByYearRange(
      chartConfigSortedData,
      xColumn,
      yColumns
    ),
    aggregated_by: "Aggregated to year",
  };
};

function convertTabularDataToChartData(
  tabularData: any[] = [],
  xColumn: string = "",
  xColumnDataType: ColumnDataType,
  yColumns: string[] = [],
  sortDirection?: "asc" | "desc"
): { labels: string[]; datasets: { label: string; data: number[] }[] } {
  const datasets = yColumns?.map((column) => {
    return {
      label: column,
      data: tabularData?.map((row) => ({
        val: row?.[column],
        category: row?.[xColumn],
      })),
    };
  });

  const { isDateColumn, isNumberCol, isDecimalColumn } = checkDataTypeOfColumn(
    xColumnDataType
  );

  const labels = getUniqueList(
    tabularData?.map(
      (row) =>
        //earlier it was being converted to zero if row?.[xColumn] is null in case of number and decimal column
        row?.[xColumn]
    )
  );
  const assendingSortedLabels = [...labels]?.sort(
    isNumberCol || isDecimalColumn ? (a, b): number => a - b : undefined
  );

  const sortedLables =
    !isDateColumn && sortDirection === "asc"
      ? assendingSortedLabels
      : !isDateColumn && sortDirection === "desc"
      ? assendingSortedLabels?.reverse()
      : labels;

  const chartData = {
    labels: sortedLables,
    datasets: datasets?.map((dataset) => ({
      ...dataset,
      data: sumValuesByCategory(
        dataset?.data,
        sortedLables,
        sortDirection,
        isDateColumn
      ),
    })),
  };

  return chartData;
}

export const computeColBasedOnType = (
  columns: AnalysisPageQueryBoxResultsColumns[] = [],
  data: any[]
): {
  row_cnt: number;
  col_cnt_all: number;
  attr_col: AnalysisPageQueryBoxResultsColumns[];
  metric_col: AnalysisPageQueryBoxResultsColumns[];
  datetime_col: AnalysisPageQueryBoxResultsColumns[];
  metrics_as_attr_col: AnalysisPageQueryBoxResultsColumns[];
  metrics_not_as_attr_col: AnalysisPageQueryBoxResultsColumns[];
} => {
  const {
    REACT_APP_DISTINCT_COUNT_FOR_METRIC_AS_ATTRIBUTE,
    REACT_APP_DISTINCT_COUNT_FOR_ATTRIBUTE,
  } = getEnvVariables();

  const attrCols = columns?.filter((item) => item?.col_type_id === "ATR");
  const metricCols = columns?.filter((item) => item?.col_type_id === "MET");

  return {
    col_cnt_all: columns?.length,
    attr_col: attrCols?.filter(
      (item) =>
        (item?.col_dist_count ?? 0) < +REACT_APP_DISTINCT_COUNT_FOR_ATTRIBUTE
    ),
    datetime_col: columns?.filter((item) => item?.col_type_id === "TIM"),
    metric_col: columns?.filter((item) => item?.col_type_id === "MET"),
    metrics_as_attr_col: metricCols?.filter(
      (item) =>
        (item?.col_dist_count ?? 0) <
        +REACT_APP_DISTINCT_COUNT_FOR_METRIC_AS_ATTRIBUTE
    ),
    metrics_not_as_attr_col: metricCols?.filter(
      (item) =>
        (item?.col_dist_count ?? 0) >
        +REACT_APP_DISTINCT_COUNT_FOR_METRIC_AS_ATTRIBUTE
    ),
    row_cnt: data?.length,
  };
};

const dualYAxisLineChart = (
  columns: ReturnType<typeof computeColBasedOnType>,
  data: any[],
  commonPropOfChartDebugConfig: AnalysisPageQueryBoxChartConfig,
  chartConfig?: AnalysisPageQueryBoxChartConfig
): AnalysisPageQueryBoxChartConfig => {
  let xAxis = "";
  let xAxisDataType: ColumnDataType = "DTE";
  let istMetricMedian;
  let secondMetricMedian;
  let ratio = 0;
  let isOneMetricPercentageCol = false;
  let yAxis: AnalysisPageQueryBoxChartConfig["chart_y_axises"] = [];

  const { chart_y_axises: chartYAxises = [] } = chartConfig || {};

  if (chartConfig) {
    xAxis = chartConfig?.chart_x_axis || "";
    xAxisDataType = chartConfig?.chart_x_Axis_data_type || "DTE";
    // y1Axis = chartConfig?.chart_y_axis || "";
    // y2Axis = chartConfig?.chart_y2_axis || "";

    // y1AxisDataType = chartConfig?.chart_y_axis_data_type;
    // y2AxisDataType = chartConfig?.chart_y2_axis_data_type;
    const maxRightOrnMetricName = findMaxValMetricName(
      data,
      chartYAxises?.filter((item) => item?.orientation === "right")
    );
    const maxLeftOrnMetricName = findMaxValMetricName(
      data,
      chartYAxises?.filter((item) => item?.orientation === "left")
    );

    yAxis = chartYAxises?.map((item) => ({
      ...item,
      hide:
        item?.orientation === "left"
          ? item?.name !== maxLeftOrnMetricName && !!maxLeftOrnMetricName
          : item?.name !== maxRightOrnMetricName && !!maxRightOrnMetricName,
    }));

    // istMetricMedian = calculateMedian(data?.map((item) => item?.[y1Axis]));
    // secondMetricMedian = calculateMedian(data?.map((item) => item?.[y2Axis]));

    // ratio = istMetricMedian / secondMetricMedian;
  } else {
    xAxis = columns?.datetime_col?.[0]?.column_name;
    xAxisDataType = columns?.datetime_col?.[0]?.data_type || "DTE";
    let y1Axis = "";
    let y2Axis = "";

    let y1AxisDataType;
    let y2AxisDataType;

    const y3Axis = columns?.metric_col?.[2]?.column_name;
    const y3AxisDataType = columns?.metric_col?.[2]?.data_type;

    const istMetricCol = columns?.metric_col?.[0]?.column_name;
    const secondMetricCol = columns?.metric_col?.[1]?.column_name;

    const istMetricColDataType = columns?.metric_col?.[0]?.data_type;
    const secondMetricColDataType = columns?.metric_col?.[1]?.data_type;

    const isIstMetricPercentageCol = isPercentageMetricColumn(istMetricCol);
    const isSecondMetricPercentageCol = isPercentageMetricColumn(
      secondMetricCol
    );

    isOneMetricPercentageCol =
      (isIstMetricPercentageCol && !isSecondMetricPercentageCol) ||
      (!isIstMetricPercentageCol && isSecondMetricPercentageCol);

    if (isOneMetricPercentageCol) {
      y1Axis = isSecondMetricPercentageCol ? istMetricCol : secondMetricCol;
      y2Axis = isSecondMetricPercentageCol ? secondMetricCol : istMetricCol;

      y1AxisDataType = isSecondMetricPercentageCol
        ? istMetricColDataType
        : secondMetricColDataType;
      y2AxisDataType = isSecondMetricPercentageCol
        ? secondMetricColDataType
        : istMetricColDataType;

      istMetricMedian = calculateMedian(data?.map((item) => item?.[y1Axis]));
      secondMetricMedian = calculateMedian(data?.map((item) => item?.[y2Axis]));
    } else {
      istMetricMedian = calculateMedian(
        data?.map((item) => item?.[istMetricCol])
      );
      secondMetricMedian = calculateMedian(
        data?.map((item) => item?.[secondMetricCol])
      );

      ratio =
        Math.max(istMetricMedian, secondMetricMedian) /
        Math.min(istMetricMedian, secondMetricMedian);

      y2Axis = ratio > 1.5 ? secondMetricCol : istMetricCol;
      y1Axis = ratio <= 1.5 ? secondMetricCol : istMetricCol;

      y2AxisDataType =
        ratio > 1.5 ? secondMetricColDataType : istMetricColDataType;
      y1AxisDataType =
        ratio <= 1.5 ? secondMetricColDataType : istMetricColDataType;
    }

    const istMetricMaxNumber = Math.max(...data?.map((item) => item?.[y1Axis]));
    const secondMetricMaxNumber = Math.max(
      ...data?.map((item) => item?.[y2Axis])
    );

    const isY1HasHigherMaxValue = istMetricMaxNumber > secondMetricMaxNumber;

    yAxis = [
      {
        name: y1Axis,
        data_type: y1AxisDataType,
        order: 1,
        orientation: "left",
        hide:
          !isOneMetricPercentageCol && !(ratio > 1.5) && !isY1HasHigherMaxValue,
        display_name:
          columns?.metric_col?.find((item) => item?.column_name === y1Axis)
            ?.column_display_name || "",
      },
      {
        name: y2Axis,
        data_type: y2AxisDataType,
        order: 2,
        orientation: ratio > 1.5 || isOneMetricPercentageCol ? "right" : "left",
        hide:
          !isOneMetricPercentageCol && !(ratio > 1.5) && isY1HasHigherMaxValue,
        display_name:
          columns?.metric_col?.find((item) => item?.column_name === y2Axis)
            ?.column_display_name || "",
      },
    ];

    const bothYAxisHasTheSameOrientation =
      yAxis?.[0]?.orientation === yAxis?.[1]?.orientation;

    if (bothYAxisHasTheSameOrientation && y3Axis) {
      yAxis = [
        ...yAxis,
        {
          name: y3Axis,
          data_type: y3AxisDataType,
          order: 3,
          orientation: "right",
          hide: false,
          display_name:
            columns?.metric_col?.find((item) => item?.column_name === y3Axis)
              ?.column_display_name || "",
        },
      ];
    } else if (y3Axis) {
      const y1AxisMedian = calculateMedian(data?.map((item) => item?.[y1Axis]));

      const y2AxisMedian = calculateMedian(data?.map((item) => item?.[y2Axis]));

      const y3AxisMedian = calculateMedian(data?.map((item) => item?.[y3Axis]));

      const y3RatioWithY1 =
        Math.max(y3AxisMedian, y1AxisMedian) /
        Math.min(y3AxisMedian, y1AxisMedian);

      const y3RatioWithY2 =
        Math.max(y3AxisMedian, y2AxisMedian) /
        Math.min(y3AxisMedian, y2AxisMedian);

      const minRatio = Math.min(y3RatioWithY1, y3RatioWithY2);

      const y3AxisOrientation: "left" | "right" | "" =
        minRatio === y3RatioWithY1 && minRatio < 1.5
          ? "left"
          : minRatio === y3RatioWithY2 && minRatio < 1.5
          ? "right"
          : "";

      if (y3AxisOrientation) {
        const thirdMetricMaxNumber = Math.max(
          ...data?.map((item) => item?.[y3Axis])
        );

        const otherYAxisHasSameOrientation =
          yAxis?.find((item) => item?.orientation === y3AxisOrientation)
            ?.name || "";

        const maxNumberOfOtherProperty = Math.max(
          ...data?.map((item) => item?.[otherYAxisHasSameOrientation])
        );

        const y3HasHigherMaxValue =
          thirdMetricMaxNumber > maxNumberOfOtherProperty;

        yAxis = [
          ...yAxis,
          {
            name: y3Axis,
            data_type: y3AxisDataType,
            order: 3,
            orientation: y3AxisOrientation,
            hide: false,
            display_name:
              columns?.metric_col?.find((item) => item?.column_name === y3Axis)
                ?.column_display_name || "",
          },
        ]?.map((item) => ({
          ...item,
          hide:
            item?.name === y3Axis
              ? !y3HasHigherMaxValue
              : item?.name === otherYAxisHasSameOrientation
              ? y3HasHigherMaxValue
              : item?.hide,
        }));
      }
    }
  }

  const yAxisColumns = yAxis?.map((item) => item?.name);

  const dates = getUniqueList(data?.map((row) => row?.[xAxis]));

  const { max_date: maxDate, min_date: minDate } = minMaxDates(dates);

  const {
    aggregated_by: aggregatedBy,
    aggregated_data: aggregateData = [],
  } = aggregateDatesData(data, xAxis, yAxisColumns, chartConfig);

  return {
    ...commonPropOfChartDebugConfig,
    chart_type: "Line",
    data: convertTabularDataToChartData(
      aggregateData,
      xAxis,
      xAxisDataType,
      yAxisColumns,
      chartConfig?.sort_direction
    ),
    chart_x_axis: xAxis,
    chart_x_Axis_data_type: xAxisDataType,
    chart_y_axises: yAxis,
    ist_metric_median: istMetricMedian,
    second_metric_median: secondMetricMedian,
    ratio_of_ist_second_metric: ratio,
    max_date: maxDate,
    min_date: minDate,
    date_aggregated_by: aggregatedBy,
    has_no_y_axes: !yAxis?.length,
  };
};

const simpleLineChart = (
  columns: ReturnType<typeof computeColBasedOnType>,
  data: any[],
  commonPropOfChartDebugConfig: AnalysisPageQueryBoxChartConfig,
  chartConfig?: AnalysisPageQueryBoxChartConfig
): AnalysisPageQueryBoxChartConfig => {
  const xAxis =
    chartConfig?.chart_x_axis || columns?.datetime_col?.[0]?.column_name;

  const xAxisDataType: ColumnDataType =
    chartConfig?.chart_x_Axis_data_type ||
    columns?.datetime_col?.[0]?.data_type ||
    "DTE";

  const yAxis = chartConfig?.has_no_y_axes
    ? ""
    : chartConfig?.chart_y_axises?.[0]?.name ||
      columns?.metric_col?.[0]?.column_name;

  const yAxisDataType = chartConfig?.has_no_y_axes
    ? undefined
    : chartConfig?.chart_y_axises?.[0]?.data_type ||
      columns?.metric_col?.[0]?.data_type;

  const yAxisColumns = [yAxis];
  const dates = getUniqueList(data?.map((row) => row?.[xAxis]));

  const { max_date: maxDate, min_date: minDate } = minMaxDates(dates);

  const {
    aggregated_by: aggregatedBy,
    aggregated_data: aggregateData = [],
  } = aggregateDatesData(data, xAxis, yAxisColumns, chartConfig);

  const useYaxisList = chartConfig
    ? !!chartConfig?.chart_y_axises?.length
    : true;

  const chartYAxis = useYaxisList
    ? [
        {
          name: yAxis,
          data_type: yAxisDataType || "NUM",
          order: 1,
          orientation: chartConfig
            ? chartConfig?.chart_y_axises?.[0]?.orientation
            : "left",
          hide: false,
          display_name:
            columns?.metric_col?.find((item) => item?.column_name === yAxis)
              ?.column_display_name || "",
        },
      ]?.filter((item) => item?.name)
    : [];
  return {
    ...commonPropOfChartDebugConfig,
    // incase of default chart setting, chart congig will be undefined
    chart_type: !chartYAxis?.length && !chartConfig ? "N/A" : "Line",
    chart_x_axis: xAxis,
    chart_x_Axis_data_type: xAxisDataType,
    chart_y_axises: chartYAxis,
    max_date: maxDate,
    min_date: minDate,
    date_aggregated_by: aggregatedBy,
    data: convertTabularDataToChartData(
      aggregateData,
      xAxis,
      xAxisDataType,
      yAxisColumns,
      chartConfig?.sort_direction
    ),
    has_no_y_axes: !chartYAxis?.length,
  };
};

const simpleBarChart = (
  columns: ReturnType<typeof computeColBasedOnType>,
  data: any[],
  commonPropOfChartDebugConfig: AnalysisPageQueryBoxChartConfig,
  chartConfig?: AnalysisPageQueryBoxChartConfig
): AnalysisPageQueryBoxChartConfig => {
  const attrColList = columns?.attr_col || [];
  const metricColList = columns?.metric_col || [];

  const metricAsAttrColList = columns?.metrics_as_attr_col || [];
  const metricNotAsAttrColList = columns?.metrics_not_as_attr_col || [];

  const isNoAttrCol = !attrColList?.length;

  const xAxis =
    chartConfig?.chart_x_axis ||
    (isNoAttrCol
      ? metricAsAttrColList?.[0]?.column_name
      : attrColList?.[0]?.column_name);

  const xAxisDataType =
    chartConfig?.chart_x_Axis_data_type ||
    (isNoAttrCol
      ? metricAsAttrColList?.[0]?.data_type
      : attrColList?.[0]?.data_type);

  const yAxis = chartConfig?.has_no_y_axes
    ? ""
    : chartConfig?.chart_y_axises?.[0]?.name ||
      (isNoAttrCol
        ? metricNotAsAttrColList?.[0] || metricAsAttrColList[1]
        : metricColList?.[0]
      )?.column_name;

  const yAxisDataType = chartConfig?.has_no_y_axes
    ? undefined
    : chartConfig?.chart_y_axises?.[0]?.data_type ||
      (isNoAttrCol
        ? metricNotAsAttrColList?.[0] || metricAsAttrColList[1]
        : metricColList?.[0]
      )?.data_type;

  const useYaxisList = chartConfig
    ? !!chartConfig?.chart_y_axises?.length
    : true;

  const chartYAxises = useYaxisList
    ? [
        {
          name: yAxis,
          data_type: yAxisDataType || "NUM",
          order: 1,
          orientation: chartConfig
            ? chartConfig?.chart_y_axises?.[0]?.orientation
            : "left",
          hide: false,
          display_name:
            metricColList?.find((item) => item?.column_name === yAxis)
              ?.column_display_name || "",
        },
      ]?.filter((item) => item?.name)
    : [];

  return {
    ...commonPropOfChartDebugConfig,
    // incase of default chart setting, chart congig will be undefined
    chart_type: !chartYAxises?.length && !chartConfig ? "N/A" : "Column",
    data: convertTabularDataToChartData(
      data,
      xAxis,
      xAxisDataType,
      [yAxis],
      chartConfig?.sort_direction
    ),
    chart_x_axis: xAxis,
    chart_x_Axis_data_type: xAxisDataType,
    chart_y_axises: chartYAxises,
    has_no_y_axes: !chartYAxises?.length,
  };
};

const dualYAxisBarChart = (
  columns: ReturnType<typeof computeColBasedOnType>,
  data: any[],
  commonPropOfChartDebugConfig: AnalysisPageQueryBoxChartConfig,
  chartConfig?: AnalysisPageQueryBoxChartConfig
): AnalysisPageQueryBoxChartConfig => {
  const attrColList = columns?.attr_col || [];
  const metricColList = columns?.metric_col || [];

  const metricAsAttrColList = columns?.metrics_as_attr_col || [];
  const metricNotAsAttrColList = columns?.metrics_not_as_attr_col || [];

  const isNoAttrCol = !attrColList?.length;

  let xAxis = "";
  let xAxisDataType: ColumnDataType = "STR";
  let istMetricMedian;
  let secondMetricMedian;
  let ratio = 0;
  let isOneMetricPercentageCol = false;
  let yAxis: AnalysisPageQueryBoxChartConfig["chart_y_axises"] = [];

  const { chart_y_axises: chartYAxises = [] } = chartConfig || {};

  if (chartConfig) {
    xAxis = chartConfig?.chart_x_axis || "";
    xAxisDataType = chartConfig?.chart_x_Axis_data_type || "STR";
    // yAxis = chartConfig?.chart_y_axis || "";
    // y2Axis = chartConfig?.chart_y2_axis || "";

    // yAxisDataType = chartConfig?.chart_y_axis_data_type;
    // y2AxisDataType = chartConfig?.chart_y2_axis_data_type;

    // istMetricMedian = calculateMedian(data?.map((item) => item?.[yAxis]));
    // secondMetricMedian = calculateMedian(data?.map((item) => item?.[y2Axis]));

    // ratio =
    //   Math.max(istMetricMedian, secondMetricMedian) /
    //   Math.min(istMetricMedian, secondMetricMedian);

    // const istMetricMaxNumber = Math.max(...data?.map((item) => item?.[yAxis]));
    // const secondMetricMaxNumber = Math.max(
    //   ...data?.map((item) => item?.[y2Axis])
    // );

    // isY1HasHigherMaxValue = istMetricMaxNumber > secondMetricMaxNumber;

    // const maxRightOrnMetricName = findMaxValMetricName(
    //   data,
    //   chartYAxises?.filter((item) => item?.orientation === "right")
    // );
    // const maxLeftOrnMetricName = findMaxValMetricName(
    //   data,
    //   chartYAxises?.filter((item) => item?.orientation === "left")
    // );

    yAxis = chartYAxises?.map((item) => ({
      ...item,
      // hide:
      //   item?.orientation === "left"
      //     ? item?.name !== maxLeftOrnMetricName && !!maxLeftOrnMetricName
      //     : item?.name !== maxRightOrnMetricName && !!maxRightOrnMetricName,
    }));
  } else {
    let y1Axis = "";
    let y2Axis = "";
    let y1AxisDataType;
    let y2AxisDataType;

    xAxis = isNoAttrCol
      ? metricAsAttrColList?.[0]?.column_name
      : attrColList?.[0]?.column_name;

    xAxisDataType = isNoAttrCol
      ? metricAsAttrColList?.[0]?.data_type
      : attrColList?.[0]?.data_type;

    const istMetricCol = (isNoAttrCol
      ? metricNotAsAttrColList?.[0] || metricAsAttrColList[1]
      : metricColList?.[0]
    )?.column_name;

    const secondMetricCol = (isNoAttrCol
      ? metricNotAsAttrColList?.[1] ||
        (metricAsAttrColList[1]?.column_name === istMetricCol
          ? metricAsAttrColList[2]
          : metricAsAttrColList[1])
      : metricColList?.[1]
    )?.column_name;

    const istMetricColDataType = (isNoAttrCol
      ? metricNotAsAttrColList?.[0] || metricAsAttrColList[1]
      : metricColList?.[0]
    )?.data_type;

    const secondMetricColDataType = (isNoAttrCol
      ? metricNotAsAttrColList?.[0] || metricAsAttrColList[1]
      : metricColList?.[0]
    )?.data_type;

    const isIstMetricPercentageCol = isPercentageMetricColumn(istMetricCol);
    const isSecondMetricPercentageCol = isPercentageMetricColumn(
      secondMetricCol
    );

    isOneMetricPercentageCol =
      (isIstMetricPercentageCol && !isSecondMetricPercentageCol) ||
      (!isIstMetricPercentageCol && isSecondMetricPercentageCol);

    if (isOneMetricPercentageCol) {
      y1Axis = isSecondMetricPercentageCol ? istMetricCol : secondMetricCol;
      y2Axis = isSecondMetricPercentageCol ? secondMetricCol : istMetricCol;

      y1AxisDataType = isSecondMetricPercentageCol
        ? istMetricColDataType
        : secondMetricColDataType;
      y2AxisDataType = isSecondMetricPercentageCol
        ? secondMetricColDataType
        : istMetricColDataType;

      istMetricMedian = calculateMedian(data?.map((item) => item?.[y1Axis]));
      secondMetricMedian = calculateMedian(data?.map((item) => item?.[y2Axis]));
    } else {
      istMetricMedian = calculateMedian(
        data?.map((item) => item?.[istMetricCol])
      );
      secondMetricMedian = calculateMedian(
        data?.map((item) => item?.[secondMetricCol])
      );

      y1Axis = secondMetricCol;
      y2Axis = istMetricCol;

      y1AxisDataType = secondMetricColDataType;
      y2AxisDataType = istMetricColDataType;
    }

    ratio =
      Math.max(istMetricMedian, secondMetricMedian) /
      Math.min(istMetricMedian, secondMetricMedian);

    const istMetricMaxNumber = Math.max(...data?.map((item) => item?.[y1Axis]));
    const secondMetricMaxNumber = Math.max(
      ...data?.map((item) => item?.[y2Axis])
    );

    const isY1HasHigherMaxValue = istMetricMaxNumber > secondMetricMaxNumber;

    yAxis = [
      {
        name: y1Axis,
        data_type: y1AxisDataType,
        order: 1,
        orientation: "left",
        hide:
          !isOneMetricPercentageCol && !(ratio > 1.5) && !isY1HasHigherMaxValue,
        display_name:
          metricColList?.find((item) => item?.column_name === y1Axis)
            ?.column_display_name || "",
      },
      {
        name: y2Axis,
        data_type: y2AxisDataType,
        order: 2,
        orientation: ratio > 1.5 || isOneMetricPercentageCol ? "right" : "left",
        hide:
          !isOneMetricPercentageCol && !(ratio > 1.5) && isY1HasHigherMaxValue,
        display_name:
          metricColList?.find((item) => item?.column_name === y2Axis)
            ?.column_display_name || "",
      },
    ];
  }

  const filteredYaxis = yAxis?.filter((item) => !!item?.name); //Empty axis will be removed if metric count <= 2 in case of isNoAttrCol

  return {
    ...commonPropOfChartDebugConfig,
    data: convertTabularDataToChartData(
      data,
      xAxis,
      xAxisDataType,
      filteredYaxis?.map((item) => item?.name),
      chartConfig?.sort_direction
    ),
    chart_x_axis: xAxis,
    chart_x_Axis_data_type: xAxisDataType,
    chart_y_axises: filteredYaxis,
    is_stacked_chart: chartConfig
      ? false
      : !(ratio > 1.5) && filteredYaxis?.length > 1,
    ist_metric_median: istMetricMedian,
    second_metric_median: secondMetricMedian,
    ratio_of_ist_second_metric: ratio,
    chart_type: "Column",
    has_no_y_axes: !filteredYaxis?.length,
  };
};

const tileView = (
  columns: ReturnType<typeof computeColBasedOnType>,
  data: any[],
  commonPropOfChartDebugConfig: AnalysisPageQueryBoxChartConfig
): AnalysisPageQueryBoxChartConfig => {
  const title = (
    columns?.attr_col?.[0] ||
    columns?.datetime_col?.[0] ||
    columns?.metric_col?.[0]
  )?.column_name;

  const dataType = (
    columns?.attr_col?.[0] ||
    columns?.datetime_col?.[0] ||
    columns?.metric_col?.[0]
  )?.data_type;

  return {
    ...commonPropOfChartDebugConfig,
    chart_type: "Tile",
    tile_data: {
      title,
      count: data?.[0]?.[title],
      data_type: dataType,
    },
  };
};

const defaultChartDebugConfig = (
  commonPropOfChartDebugConfig: AnalysisPageQueryBoxChartConfig
): AnalysisPageQueryBoxChartConfig => {
  return {
    ...commonPropOfChartDebugConfig,
    chart_type: "N/A",
  };
};

export const inferChartTypeAndData = (
  columns: ReturnType<typeof computeColBasedOnType>,
  data: any[],
  chartConfig?: AnalysisPageQueryBoxChartConfig,
  isFilterApplied?: boolean
): AnalysisPageQueryBoxChartConfig => {
  const isDefaultSettings =
    chartConfig?.is_default_settings !== false && !isFilterApplied;

  const metricsCount = columns?.metric_col?.length;
  const attributeCount = columns?.attr_col?.length;
  const dateTimeCount = columns?.datetime_col?.length;
  const metricsAsAttrCount = columns?.metrics_as_attr_col?.length;
  const metricsNotAsAttrCount = columns?.metrics_not_as_attr_col?.length;
  const colmnCount = columns?.col_cnt_all;
  const rowCount = columns?.row_cnt;

  const isNoAttrCol = !attributeCount;

  const commonPropOfChartDebugConfig = {
    column_count: colmnCount,
    metric_count: metricsCount,
    attribute_count: attributeCount,
    datetime_count: dateTimeCount,
    metrics_as_attr_count: metricsAsAttrCount,
    metrics_not_as_attr_count: metricsNotAsAttrCount,
    row_count: rowCount,
    metric_columns: columns?.metric_col,
    attribute_columns: columns?.attr_col,
    metrics_as_attr_columns: columns?.metrics_as_attr_col,
    metrics_not_as_attr_columns: columns?.metrics_not_as_attr_col,
    datetime_columns: columns?.datetime_col,
    sort_direction: chartConfig?.sort_direction,
    sort_info_of_y_axis: chartConfig?.sort_info_of_y_axis,
    is_default_settings: chartConfig?.is_default_settings,
    chart_x_Axis_data_type: chartConfig?.chart_x_Axis_data_type,
    has_no_y_axes: !chartConfig?.chart_y_axises?.length,
  };

  try {
    // if query generated empty data or single object with empty values
    if (data?.length === 1) {
      const objectValues = getObjectValues(data?.[0]);
      const isObjectContainsEmptyValue = objectValues?.every(
        (val) => val === ""
      );

      if (isObjectContainsEmptyValue && !!objectValues?.length) {
        throw new Error("Empty data");
      }
    }

    if (colmnCount === 1 && rowCount === 1) {
      //tile view
      return tileView(columns, data, commonPropOfChartDebugConfig);
    }

    if (isDefaultSettings) {
      if (dateTimeCount >= 1 && metricsCount >= 1) {
        //line chart

        const xAxis = columns?.datetime_col?.[0]?.column_name;

        const dataWithFormattedXAxisDate = data
          ?.filter((item) => item?.[xAxis])
          ?.map((item) => {
            return {
              ...item,
              [xAxis]: moment.utc(item?.[xAxis]),
            };
          });

        if (metricsCount > 1) {
          return dualYAxisLineChart(
            columns,
            dataWithFormattedXAxisDate,
            commonPropOfChartDebugConfig
          );
        }

        return simpleLineChart(
          columns,
          dataWithFormattedXAxisDate,
          commonPropOfChartDebugConfig
        );
      }

      // bar chart
      const isBarChartDrawable = isNoAttrCol
        ? metricsCount >= 1 && metricsAsAttrCount >= 1 && dateTimeCount === 0
        : metricsCount >= 1 && attributeCount >= 1 && dateTimeCount === 0;

      if (isBarChartDrawable) {
        // simple bar chart
        const isSimpleBarChartDrawable = isNoAttrCol
          ? metricsCount === 1 && metricsAsAttrCount >= 1
          : metricsCount === 1 && attributeCount >= 1;

        if (isSimpleBarChartDrawable) {
          return simpleBarChart(columns, data, commonPropOfChartDebugConfig);
        }

        // dual y-axis bar chart
        const isDualBarChartDrawable = isNoAttrCol
          ? metricsCount > 1 && metricsAsAttrCount >= 1
          : metricsCount > 1 && attributeCount >= 1;

        if (isDualBarChartDrawable) {
          return dualYAxisBarChart(columns, data, commonPropOfChartDebugConfig);
        }
      }

      return defaultChartDebugConfig(commonPropOfChartDebugConfig);
    }

    const {
      isDateColumn,
      isStringColumn,
      isNumberCol,
      isDecimalColumn,
    } = checkDataTypeOfColumn(chartConfig?.chart_x_Axis_data_type || "NUM");

    if (
      isDateColumn ||
      (!chartConfig?.chart_x_Axis_data_type && dateTimeCount >= 1)
    ) {
      // line chart

      const xAxis = chartConfig?.chart_x_axis || "";
      const dataWithFormattedXAxisDate = data
        ?.filter((item) => item?.[xAxis])
        ?.map((item) => {
          return {
            ...item,
            [xAxis]: moment.utc(item?.[xAxis]),
          };
        });

      if ((chartConfig?.chart_y_axises?.length || 0) > 1) {
        return dualYAxisLineChart(
          columns,
          dataWithFormattedXAxisDate,
          commonPropOfChartDebugConfig,
          chartConfig
        );
      }

      return simpleLineChart(
        columns,
        dataWithFormattedXAxisDate,
        commonPropOfChartDebugConfig,
        chartConfig
      );
    }

    if (
      isStringColumn ||
      isNumberCol ||
      isDecimalColumn ||
      (chartConfig?.chart_x_Axis_data_type &&
        (attributeCount >= 1 || metricsAsAttrCount >= 1) &&
        dateTimeCount === 0)
    ) {
      // bar chart

      if ((chartConfig?.chart_y_axises?.length || 0) > 1) {
        return dualYAxisBarChart(
          columns,
          data,
          commonPropOfChartDebugConfig,
          chartConfig
        );
      }

      return simpleBarChart(
        columns,
        data,
        commonPropOfChartDebugConfig,
        chartConfig
      );
    }

    return defaultChartDebugConfig(commonPropOfChartDebugConfig);
  } catch (err) {
    return defaultChartDebugConfig(commonPropOfChartDebugConfig);
  }
};

export const queryResultsGridHumanReadableFormating = ({
  number = 0,
  minNum,
  dataType = "INT",
  isDataFormatted = true,
  decimals,
}: {
  number: number;
  isDataFormatted: boolean;
  dataType?: ColumnDataType;
  minNum?: number;
  decimals?: number;
}): string => {
  const strNum = `${number}`;
  if (strNum === "") return "";

  const updatedNumber = !isNumber(number) ? 0 : Number(number);

  const { isDecimalColumn } = checkDataTypeOfColumn(dataType);

  const rawprecision = isDecimalColumn ? decimals ?? 2 : 0;

  const magnitudeOfVal = computeMagnitude(minNum ?? updatedNumber);

  const lookup: { [key: number]: string } = {
    0: "",
    3: "K",
    6: "M",
    9: "B",
    12: "T",
  };

  const divisor = 10 ** magnitudeOfVal;
  const shortNum = updatedNumber / divisor;

  const updatedRawPrecision = divisor > 1 ? decimals ?? 1 : rawprecision;

  const suffix = lookup?.[magnitudeOfVal] || "";

  return isDataFormatted
    ? `${shortNum?.toLocaleString(undefined, {
        minimumFractionDigits: updatedRawPrecision,
        maximumFractionDigits: updatedRawPrecision,
      })}${suffix}`
    : `${updatedNumber?.toLocaleString(undefined, {
        minimumFractionDigits: rawprecision,
        maximumFractionDigits: rawprecision,
      })}`;
};

export const isQuestionRefreshing = (
  isLoading: boolean,
  loaderType: QueryBoxLoaderType,
  loadingStep: AnalysisPageQueryBox["loading_step"]
): boolean => {
  return !!(
    isLoading &&
    (loaderType === "fetching_results_after_refresh" ||
      loaderType === "running_analysis" ||
      loaderType === "newly_added_question") &&
    loadingStep &&
    loadingStep <= 3
  );
};

export const showTypingAnimation = (
  loaderType: QueryBoxLoaderType,
  loadingStep: AnalysisPageQueryBox["loading_step"]
): boolean => {
  return !!(loadingStep && loadingStep < 3);
};

export const getTransiantFilteredData = (
  data: QueryBoxResultDataType,
  transiantFiltersList: QueryBlockGridFilter[],
  metricColList: string[],
  alreadyPercFormattedCols: string[]
): QueryBoxResultDataType => {
  const applyFilter = (
    currentIndex: number,
    filteredData: QueryBoxResultDataType
  ): QueryBoxResultDataType => {
    if (currentIndex >= transiantFiltersList?.length) {
      return filteredData;
    }

    const currentFilter = transiantFiltersList?.[currentIndex];

    const {
      col_data_type: colDataType,
      filter_model: filterModel,
      colm_name: colName = "",
      col_display_name: colDisplayName = "",
    } = currentFilter ?? {};

    const isPercentageCol =
      isPercentageMetricColumn(colDisplayName) &&
      metricColList?.includes(colName);

    const {
      filter = "",
      filterTo = "",
      dateFrom = "",
      dateTo = "",
      values = [],
      type,
    } = filterModel ?? {};

    const isEquals = type === "equals";

    const { isNumberCol, isDateColumn, isStringColumn } = checkDataTypeOfColumn(
      colDataType
    );

    const updatedData = filteredData?.filter((item) => {
      if (filterModel) {
        if (isNumberCol) {
          const convertedNumber = Number(
            isNumber(item?.[colName]) ? item?.[colName] : 0
          );

          const formattedconvertedNum = isPercentageCol
            ? formatPercentage(
                convertedNumber,
                alreadyPercFormattedCols?.includes(colName)
              )
            : convertedNumber;
          if (isEquals) {
            return isNumberInRange(formattedconvertedNum, filter, filter, true);
          }

          return isNumberInRange(formattedconvertedNum, filter, filterTo);
        }

        if (isDateColumn) {
          if (isEquals) {
            return isDateInRange(
              dateFrom,
              dateFrom || "",
              item?.[colName],
              true
            );
          }

          return isDateInRange(dateFrom, dateTo || "", item?.[colName]);
        }

        if (isStringColumn) {
          return values?.includes(item?.[colName]);
        }
      }

      return true;
    });

    return applyFilter(currentIndex + 1, updatedData);
  };

  // Start with the first filter at index 0
  return applyFilter(0, data);
};
