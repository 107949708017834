import {
  useCallback,
  useMemo,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";

import { chevronDown } from "../../svgs";
import Dvsumpopover from "../dvsumpopover";

import { SingleSelectionPopupStyled } from "./singleselectionpopup.styles";
import { SingleSelectionPopupProps } from "./singleselectionpopup.types";

import { SingleSelectionPopupContent } from "./components";

const SingleSelectionPopup = forwardRef(function SingleSelectionPopup(
  props: SingleSelectionPopupProps,
  ref
): JSX.Element {
  const {
    title = "",
    options = [],
    selectedOption,
    onChange,
    titleId = "",
  } = props;

  const [isVisible, setIsVisible] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      isVisible,
      setIsVisible(visible: boolean): void {
        setIsVisible(visible);
      },
    }),
    [isVisible]
  );

  const updatedTitle = useMemo(
    () =>
      selectedOption ? (
        <div className="single-selection-popup-title">
          {title}:
          <div className="seleted-options-labels">
            {options?.find((item) => item?.value === selectedOption)?.label ||
              ""}
          </div>
        </div>
      ) : (
        `${title}`
      ),
    [title, selectedOption, options]
  );

  const onVisibleChange = useCallback((visibility: boolean) => {
    setIsVisible(visibility);
  }, []);

  return (
    <Dvsumpopover
      content={
        <SingleSelectionPopupContent
          {...props}
          onChange={onChange}
          onVisibleChange={onVisibleChange}
        />
      }
      placement="bottomRight"
      trigger={["click"]}
      onVisibleChange={onVisibleChange}
      visible={isVisible}
      getPopupContainer={(trigger): HTMLElement =>
        trigger?.parentNode as HTMLElement
      }
      zIndex={1}
    >
      <SingleSelectionPopupStyled
        isVisible={isVisible}
        className="single-selection-popup"
        id={titleId}
      >
        {updatedTitle} {chevronDown()}
      </SingleSelectionPopupStyled>
    </Dvsumpopover>
  );
});

export default SingleSelectionPopup;
