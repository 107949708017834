import { FilterCriteriaParsedType } from "../../../../parsers/tablepage/tablepageparser.types";
import { getDvSumInformativeText } from "../../../../utils";
import { SchemaType, SectionField } from "../../dynamicform/dynamicform.types";

export const addCountForm = (
  isEdit: boolean,
  existingFilters?: FilterCriteriaParsedType | undefined
): SchemaType => {
  const {
    dat_dict_dat_qual_add_new_rle_pipln_flt: {
      description: datDictDatQualAddNewRlePiplnFlt = "",
    } = {},
  } = getDvSumInformativeText();

  return [
    ...((!isEdit && [
      {
        id: "basic",
        type: "section",
        fields: [
          {
            id: "rule_type",
            type: "select",
            name: "rule_type",
            label: "Rule Type",
            disabled: true,
            required: true,
          },
          {
            id: "rule_description",
            type: "text_area",
            name: "rule_description",
            label: "Rule Description",
            placeholder: "Description",
            description: "",
          },
          {
            id: "filter_criteria",
            type: "filter",
            name: "filter_criteria",
            label: "Filter Condition",
            description: datDictDatQualAddNewRlePiplnFlt,
            existingFilters,
          },
        ],
      } as SectionField,
    ]) ||
      []),

    ...((isEdit && [
      {
        id: "filter_criteria_section",
        type: "section",
        fields: [
          {
            id: "filter_criteria",
            type: "filter",
            name: "filter_criteria",
            label: "Filter Condition",
            description: datDictDatQualAddNewRlePiplnFlt,
            existingFilters,
          },
        ],
      } as SectionField,
    ]) ||
      []),
  ];
};
