import { Tooltip } from "antd";

// <--- Components Start --->
import Flex from "../flex";
// <--- Components End --->

// <--- Constants Start --->
import { DVSUM_TOOLTIP_CLASS_NAME } from "../../constants";
// <--- Constants End --->

// <--- Styles Start --->
import {
  RemainingProminentTagsSecStyled as RemainingTagsSecStyled,
  RemainingTagStyled,
} from "../prominenttagslist/prominenttagslist.styles";

import {
  GenericProminentTagStyled,
  RemainingTagsCountStyled,
} from "./genericprominenttag.styles";
// <--- Styles End --->

// <--- Types Start --->
import {
  GenericProminentTagProps,
  GenericProminentTagsListProps,
} from "./genericprominenttag.types";
// <--- Types End --->

const GenericProminentTag = (props: GenericProminentTagProps): JSX.Element => {
  const { tag, type, isBoldView = false } = props;

  return (
    <>
      {tag?.name && (
        <GenericProminentTagStyled
          type={tag?.type || type}
          isBoldView={isBoldView}
          className="generic-prominent-tag-container"
        >
          <span title={tag?.name || ""} className="generic-prominent-tag-name">
            {tag?.name}
          </span>
        </GenericProminentTagStyled>
      )}
    </>
  );
};

export const GenericProminentTagsList = (
  props: GenericProminentTagsListProps
): JSX.Element => {
  const { tags, type, isBoldView } = props;
  const firstTag = tags?.[0];

  return tags?.length >= 1 ? (
    <Flex columnGap={3}>
      <GenericProminentTag tag={firstTag} type={type} isBoldView={isBoldView} />
      {tags?.length > 1 && (
        <Tooltip
          overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
          placement="bottomLeft"
          title={
            <RemainingTagsSecStyled>
              {tags?.slice(1)?.map((tag) => (
                <RemainingTagStyled
                  title={tag?.name || ""}
                  key={tag?.name}
                  type={tag?.type}
                  isClickable={false}
                >
                  {tag?.name || ""}
                </RemainingTagStyled>
              ))}
            </RemainingTagsSecStyled>
          }
        >
          <RemainingTagsCountStyled type={firstTag?.type}>
            +{tags.length - 1}
          </RemainingTagsCountStyled>
        </Tooltip>
      )}
    </Flex>
  ) : (
    <div />
  );
};

export default GenericProminentTag;
