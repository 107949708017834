import * as yup from "yup";

import { CHARACTERS_LIMIT_EXCEEDED } from "../../constants/formconstants";

export const commentInputFieldFormSchema = yup.object().shape({
  text: yup
    .string()
    ?.trim()
    ?.required("")
    ?.max(1000, CHARACTERS_LIMIT_EXCEEDED(1000)),
});
