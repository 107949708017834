import styled from "styled-components";
import { hexToRgbA } from "../../utils";
import linkbutton from "../linkbutton";

export const AdditionalInfoStyled = styled.div<{ isEditable: boolean }>`
  min-height: 153px;
  padding: ${({ isEditable }): any => isEditable && "19px 31px 30px 32px"};
  background-color: ${({ isEditable, theme }): any =>
    isEditable && theme.bgColors.BLUE_11};

  .action-elm {
    margin-bottom: 10px;
    .hide-border {
      border-color: ${({ theme }): string => theme.borderColors.BLUE_11};
      :hover,
      :focus {
        border-color: ${({ theme }): string => theme.borderColors.BLUE_11};
      }
    }

    .error-message {
      text-align: right;
      font-size: 13px;
      color: red;
    }
  }

  .actions-sec {
    display: flex;
    div:first-of-type {
      flex: 1;
    }
  }
`;

export const ActionButtonStyled = styled(linkbutton)<{
  isSelected?: boolean;
}>`
  width: 46px;
  height: 46px;
  border-radius: 50%;
  margin-left: 0px;
  background: ${({ theme, isSelected }): any =>
    isSelected && hexToRgbA(theme.bgColors.DARK_BLUE, 0.2)};
  margin-left: 2px;

  :hover,
  :focus,
  :active {
    background: ${({ theme, isSelected }): any =>
      isSelected && hexToRgbA(theme.bgColors.DARK_BLUE, 0.2)};
  }

  :nth-of-type(1),
  :nth-of-type(2),
  :nth-of-type(3),
  :nth-of-type(5) {
    path {
      stroke: ${({ theme, isSelected }): any =>
        isSelected && theme.bgColors.DARK_BLUE};
    }
  }

  :nth-of-type(4) {
    path {
      fill: ${({ theme, isSelected }): any =>
        isSelected && theme.bgColors.DARK_BLUE};
      stroke: ${({ theme, isSelected }): any =>
        isSelected && theme.bgColors.DARK_BLUE};
    }
  }

  :nth-of-type(6),
  :nth-of-type(7) {
    path {
      fill: ${({ theme, isSelected }): any =>
        isSelected && theme.bgColors.DARK_BLUE};
    }
    .cls-inner-g {
      stroke: ${({ theme, isSelected }): any =>
        isSelected && theme.bgColors.DARK_BLUE};
    }
  }
`;

export const TextPreviewerStyled = styled.div`
  background: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
  padding: 30px 12px 12px 12px;
  border: 1px solid ${({ theme }): string => theme.borderColors.GREY_56};

  .text-preview-container {
    color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
    /* width: fit-content; */
    word-break: break-all;

    .ql-editor {
      padding: 0px;
    }

    .action-sec {
      position: absolute;
      right: 12px;
      top: 8px;
      display: flex;
      align-items: center;
      gap: 12px;

      .img-cross-icon {
        height: 18px;
        cursor: pointer;
      }
    }

    img {
      max-width: 100%;
    }
  }
`;

export const AddedItemStyled = styled.div`
  margin-bottom: 12px;
  .additional-info-added-item-title {
    color: ${({ theme }): string => theme.textColors.DARK_GREY_10};
  }

  .text-preview-wrapper {
    position: relative;
  }
`;
