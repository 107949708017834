function initLandbot(s: any, myLandbot: any): void {
  if (!myLandbot) {
    s.type = "text/javascript";
    s.async = true;
    s.addEventListener("load", () => {
      //@ts-ignore
      // eslint-disable-next-line no-undef
      myLandbot = new Landbot.Popup({
        configUrl:
          "https://storage.googleapis.com/chats.landbot.io/v3/H-1167948-1Q8GSMC1UIV1PWR0/index.json",
      });
    });
    s.src = "/assets/landbot.js";
    const x = document.getElementsByTagName("script")[0];
    x?.parentNode?.insertBefore(s, x);
  }
}

export const onMessageSupportClick = (): void => {
  try {
    const alreadyChatBotExists = !!document?.querySelector(".LandbotPopup");

    if (!alreadyChatBotExists) {
      const myLandbot: any = null;
      const s = document.createElement("script");
      s.id = "dvsum-chat-bot";

      initLandbot?.(s, myLandbot);
    }
  } catch (error) {
    console.log("error", error);
  }
};
