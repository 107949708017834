import styled from "styled-components";

export const ColumnSequenceModalStyled = styled.div`
  padding-top: 30px;

  .label {
    font-family: OpenSansSemiBold;
    font-size: 14px;
    padding-left: 80px;
  }
`;
