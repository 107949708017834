// <--- Constants Start --->
import { APP_ROUTES } from "../../constants";
import { DATA_IMPORT_PARAMS } from "./dataimportpage.constants";
// <--- Constants End --->

// <--- Types Start --->
import { NodeType } from "../../app.types";
import {
  FieldInDvsumType,
  FieldsInFileType,
} from "../../parsers/dataimportparsers/dataimportparsers.types";

import {
  FieldInDvSumSelectType,
  MapFieldsGridColumnType,
} from "./views/mapfieldsview/mapfieldsview.types";
// <--- Types End --->

// <--- Utils Start --->
import { getObjectKeys, sortObjectsArrayByKey } from "../../utils";
import { AutoMappingActionType } from "./dataimportpage.types";
// <--- Utils End --->

export const getDataImportURL = (
  step: number,
  assetType?: NodeType
): string => {
  return `${APP_ROUTES.private_routes.data_import}?${
    DATA_IMPORT_PARAMS?.ACTIVE_STEP
  }=${step}${
    assetType ? `&${DATA_IMPORT_PARAMS?.SELECTED_ASSET_TYPE}=${assetType}` : ""
  }`;
};

export const autoMapping = (
  fieldsInFile: FieldsInFileType,
  fieldsInDvSum: Array<FieldInDvsumType>,
  action: AutoMappingActionType,
  fieldsList: Array<FieldInDvSumSelectType>
): {
  fieldsList: Array<FieldInDvSumSelectType>;
  gridData: Array<MapFieldsGridColumnType>;
} => {
  const sortedFieldsInDvSum = sortObjectsArrayByKey(
    fieldsInDvSum,
    "display_order"
  );

  const updatedFields = [...(fieldsList || [])]?.map((field) => ({
    ...field,
    is_mapped: false,
  }));

  const gridMappedData: MapFieldsGridColumnType[] = getObjectKeys(
    fieldsInFile
  )?.map((field, index) => {
    const fieldInDvsum:
      | FieldInDvsumType
      | undefined = sortedFieldsInDvSum?.find((dvsumField) => {
      const dvsumFieldLowerCase = dvsumField?.name?.toLowerCase()?.trim();
      const fieldLowerCase = field?.toLowerCase()?.trim();

      return dvsumFieldLowerCase === fieldLowerCase;
    });
    const fieldIndex = updatedFields?.findIndex((item) => {
      const fieldLowerCase = item?.value?.toLowerCase()?.trim();
      const dvsumFieldLowerCase = fieldInDvsum?.name?.toLowerCase()?.trim();

      return fieldLowerCase === dvsumFieldLowerCase;
    });

    if (fieldIndex >= 0) {
      if (action === "applyAutoMapping") {
        if (fieldsInDvSum) {
          updatedFields[fieldIndex].is_mapped = true;
        } else {
          updatedFields[fieldIndex].is_mapped = false;
        }
      }
    }

    return {
      id: index,
      fields_in_dvsum:
        action === "applyAutoMapping"
          ? fieldInDvsum
            ? `${fieldInDvsum?.name?.trim()}${
                updatedFields?.[fieldIndex]?.is_required ? "*" : ""
              }`
            : ""
          : "",
      fields_in_file: field?.trim(),
      sample_data: fieldsInFile?.[field]?.[0],
      sample_data1: fieldsInFile?.[field]?.[1],
      sample_data2: fieldsInFile?.[field]?.[2],
      filter:
        action === "resetAutoMapping"
          ? "unmapped"
          : fieldInDvsum
          ? "mapped"
          : "unmapped",
    };
  });

  return {
    fieldsList: [...(updatedFields || [])],
    gridData: [...(gridMappedData || [])],
  };
};
