// <--- Library Imports Start --->
import { useFormContext } from "react-hook-form";
// <--- Library Imports End --->

// <--- Components Start --->
import {
  InputField,
  CheckboxField,
  TextAreaField,
  SelectField,
} from "../../../../components/formfields";

import { FormItemStyled, FormStyled } from "../../../../components/form";
// <--- Components End --->

// <--- Constants Start --->
import {
  is_policy_linked,
  tagsetLabels,
} from "../../tagsetsdetailpage.constants";
// <--- Constants End --->

// <--- Styles Start --->
import {
  EditableInformationSectionStyled,
  VerticalBarStyled,
} from "./editableinformationsection.styles";
// <--- Styles End --->

const EditableInformationSection = (): JSX.Element => {
  const labels = tagsetLabels.tagsetdetail.information;

  const { control, watch, setValue } = useFormContext();

  const { type, is_policy_linked: isPolicyLinked } = watch();

  const isTypeClassification = type === "CLS";

  return (
    <EditableInformationSectionStyled>
      <FormStyled isItemColumnLayout>
        <FormItemStyled label={labels.name} required>
          <InputField
            control={control}
            name="name"
            placeholder="Tagset Name"
            width="351px"
          />
        </FormItemStyled>

        <FormItemStyled label={labels.description}>
          <TextAreaField
            control={control}
            name="description"
            placeholder="Description"
            width="673px"
          />
        </FormItemStyled>

        {isTypeClassification && (
          <FormItemStyled label={labels.type}>
            <SelectField
              name="type"
              control={control}
              setValue={setValue}
              placeholder="Type"
              width="351px"
              options={[
                {
                  label: "Classification",
                  value: "CLS",
                },
              ]}
              value="CLS"
              disabled
            />
          </FormItemStyled>
        )}

        <FormItemStyled label={labels.applicable_to}>
          <>
            <CheckboxField name="datasets" control={control} width="unset">
              {labels.datasets}
            </CheckboxField>
            <CheckboxField name="fields" control={control} width="unset">
              {labels.fields}
            </CheckboxField>
            <CheckboxField name="terms" control={control} width="unset">
              {labels.terms}
            </CheckboxField>
            <CheckboxField
              name="models_reports"
              control={control}
              width="unset"
            >
              {labels.models_reports}
            </CheckboxField>
            <CheckboxField name="rules" control={control} width="unset">
              {labels.rules}
            </CheckboxField>
          </>
        </FormItemStyled>

        <FormItemStyled>
          <CheckboxField
            name="single_value"
            control={control}
            width="unset"
            disabled={isTypeClassification}
            tooltipProps={{
              title: isTypeClassification
                ? "This field can't be modified in classification type."
                : "",
              placement: "topLeft",
            }}
          >
            {labels.single_value}
          </CheckboxField>
        </FormItemStyled>

        {!isTypeClassification && (
          <FormItemStyled>
            <CheckboxField
              name="allow_suggestions"
              control={control}
              width="unset"
            >
              {labels.allow_suggestions}
            </CheckboxField>
          </FormItemStyled>
        )}

        {!isTypeClassification && (
          <FormItemStyled>
            <CheckboxField
              name="is_note_required"
              control={control}
              width="unset"
            >
              {labels.require_note}
            </CheckboxField>
          </FormItemStyled>
        )}

        {isTypeClassification && (
          <>
            <FormItemStyled>
              <CheckboxField
                name={is_policy_linked}
                control={control}
                width="unset"
              >
                {labels.tagset_policy_link}
              </CheckboxField>
            </FormItemStyled>

            {isPolicyLinked && (
              <div className="tagset-policy-link-sec">
                <VerticalBarStyled />

                <div className="link-field-wrapper">
                  <InputField
                    control={control}
                    name="tagset_policy_link"
                    placeholder="Add a hyperlink to Policy or Standard document"
                    width="642px"
                  />
                </div>
              </div>
            )}
          </>
        )}
      </FormStyled>
    </EditableInformationSectionStyled>
  );
};

export default EditableInformationSection;
