import { SelectFieldOption } from "../../../../components/formfields/selectfield/selectfield.types";
import { ReturnTypeOfRefParser } from "../../../../parsers";
import {
  getDvSumInformativeText,
  sortObjectsArrayByKey,
} from "../../../../utils";

import {
  FieldBase,
  SchemaType,
  SectionField,
} from "../../dynamicform/dynamicform.types";

export const addCompareTableFormConfig = ({
  sourceSchemaSelect,
  targetSchemaSelect,
  colDataTypeCheck,
  isSourceDisabled,
  compareSchemaTypes = [],
  isEdit,
}: {
  sourceSchemaSelect: SelectFieldOption[];
  targetSchemaSelect: SelectFieldOption[];
  colDataTypeCheck: boolean;
  isSourceDisabled: boolean;
  compareSchemaTypes?: ReturnTypeOfRefParser[];
  isEdit: boolean;
}): SchemaType => {
  const {
    dat_dict_dat_qual_add_new_rle_fnd_com_sch_dta_dif_bas: {
      description: datDictDatQualAddNewRleFndComSchDtaDifBas = "",
    } = {},
    dat_dict_dat_qual_add_new_rle_fnd_com_sch_dta_dif_str: {
      description: datDictDatQualAddNewRleFndComSchDtaDifStr = "",
    } = {},
  } = getDvSumInformativeText();

  const SOURCE_SCHEMA_SELECT: FieldBase = {
    id: "src_schema",
    type: "select",
    name: "src_schema",
    label: "Source Schema",
    placeholder: "Select",
    required: true,
    options: sourceSchemaSelect,
    showSearch: true,
    disabled: isSourceDisabled,
  };

  const TARGET_SCHEMA_SELECT: FieldBase = {
    id: "target_schema",
    type: "select",
    name: "target_schema",
    label: "Target Schema",
    placeholder: "Select",
    required: true,
    options: targetSchemaSelect,
    showSearch: true,
  };

  const CBDD_DATATYPE_CHECK: FieldBase = {
    id: "type_check",
    type: "radio_group",
    name: "type_check",
    label: "",
    description: "",
    options: [
      {
        label: "Basic datatype check",
        value: "basic",
        labelDesc: datDictDatQualAddNewRleFndComSchDtaDifBas,
      },
      {
        label: "Strict datatype check",
        value: "strict",
        labelDesc: datDictDatQualAddNewRleFndComSchDtaDifStr,
      },
    ],
    child: true,
  };

  const sortedCompareSchemaType = sortObjectsArrayByKey(
    compareSchemaTypes,
    "displayOrder"
  );

  const nameOfFields: Record<string, string> = {
    TES: "tbl_in_src_not_in_tgt",
    TET: "tbl_in_tgt_not_in_src",
    CES: "col_in_src_not_in_tgt",
    CET: "col_in_tgt_not_in_src",
    DTD: "col_data_type_check",
  };

  return [
    ...((!isEdit && [
      {
        id: "basic",
        type: "section",
        fields: [
          {
            id: "rule_type",
            type: "select",
            name: "rule_type",
            label: "Rule Type",
            required: true,
            disabled: true,
          },
          {
            id: "rule_description",
            type: "text_area",
            name: "rule_description",
            label: "Rule Description",
            placeholder: "Description",
            description: "",
          },
        ],
      } as SectionField,
    ]) ||
      []),
    {
      id: "basic_input",
      type: "section",
      title: "Basic Input",
      fields: [SOURCE_SCHEMA_SELECT, TARGET_SCHEMA_SELECT],
    },

    {
      id: "data_diff_checks",
      type: "section",
      title: "Data Diff Checks",
      fields:
        sortedCompareSchemaType?.map((schema) => {
          const { name = "", description = "", id = "tes" } = schema;
          const isCbdd = name === "CBDD";

          const nameOfInput = nameOfFields[id];

          return {
            id: nameOfInput,
            type: "checkbox",
            name: nameOfInput,
            label: `${name} - ${description}`,
            ...(isCbdd &&
              colDataTypeCheck && {
                children: [CBDD_DATATYPE_CHECK],
              }),
          };
        }) || [],
    },
  ];
};
