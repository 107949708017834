import styled from "styled-components";

export const GritterNotificationStyled = styled.div<{ type?: string }>`
  width: 100%;
  height: max-content;
  padding: 8px 16px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 5px;
  position: absolute;
  top: 0;
  left: 0;

  //added background for warning, error as per current usecase
  background: ${({ theme, type }): string =>
    type === "warning"
      ? theme.bgColors.YELLOW_12
      : type === "error"
      ? theme.bgColors.RED_101
      : theme.bgColors.LIGHT_BLUE_25};

  .notification-icon {
    height: 20px;
    margin-top: 1px;

    svg {
      path {
        fill: ${({ theme }): string => theme.bgColors.YELLOW_223};
      }
    }
  }

  .notification-link {
    display: inline-block;
    a {
      color: ${({ theme }): string => theme.bgColors.BLUE_116};
      font-size: 14.4px;
    }
  }

  .notification-message {
    color: ${({ theme }): string => theme.bgColors.GREY_40};
    font-size: 14.4px;
    display: flex;
  }
  .notification-cancel-icon {
    position: absolute;
    right: 15px;

    svg {
      path {
        fill: ${({ theme }): string => theme.textColors.GREY_210};
      }
    }
  }
`;
