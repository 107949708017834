import styled from "styled-components";

export const GatewayStatusCheckingWrapperStyled = styled.div<{
  height?: string;
}>`
  width: 100%;
  height: ${({ height }): string => height || ""};
  display: flex;
  align-items: center;
  justify-content: center;

  .loading-text {
    color: ${({ theme }): string => theme?.textColors?.BLACK_COLOR};
  }

  .failure-text {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    padding: 24px;
    text-align: center;
    color: ${({ theme }): string => theme?.textColors?.GREY_24};
    background: #fefae8;
  }

  .retry-btn {
    margin-top: 5px;
    display: flex;
    align-items: center;
    font-family: OpenSansSemiBold;
  }
`;

export const GatewayStatusCheckingWrapperWithChildren = styled.div`
  position: absolute;
  bottom: 12%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .gateway-status-checking-wrapper {
    width: 900px;
  }
`;

export const GatewayStatusCheckingWrapperBannerWithChildren = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .failure-text {
    flex-direction: row;
    width: 100%;
    text-align: center;
    padding: 8px 16px;
    font-size: 13.6px;
    justify-content: center;

    .contact-wrapper {
      display: inline-block;
    }

    a {
      line-height: 25px;
    }
  }

  .retry-btn {
    margin-top: unset;
    margin-left: 8px;
    justify-content: center;
    font-size: 13.6px;

    svg {
      display: none;
    }
  }
`;

export const GatewayLoadinBannerWithChildren = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #fefae8;

  .flex-wrapper {
    flex-direction: row;
    column-gap: 10px;
    margin: 10px 0;
    align-items: center;
  }
`;
