import styled from "styled-components";

export const DashboardGovViewFormStyled = styled.div``;

export const AddDashboardGovernanceViewFormStyled = styled.div`
  margin-top: 30px;

  .textarea-field-container > textarea {
    min-width: 400px;
    margin-bottom: 30px;
  }
`;
