import { AxiosResponse } from "axios";

import {
  ShareListAnalysisParserType,
  ShareListAnalysisResponse,
  ShareListParserType,
  ShareListResponse,
  SocialNodeActivitiesParserType,
  SocialNodeResponse,
} from "./socialnodeparser.types";

export function getSocialNodeActivities({
  data,
}: AxiosResponse<SocialNodeResponse[]>): SocialNodeActivitiesParserType {
  return {
    is_endorsed: data?.some((item) => item?.NODE_REL_TYPE === "SOCIAL_ENDORSE"),
    is_followed: data?.some((item) => item?.NODE_REL_TYPE === "SOCIAL_FOLLOW"),
    is_shared: data?.some((item) => item?.NODE_REL_TYPE === "GOV_VIEWER"),
  };
}

export function getSocialShareList({
  data,
}: AxiosResponse<ShareListResponse[]>): ShareListParserType[] {
  return data?.map((item) => ({
    node_id: item?.NODE_ID || 0,
    node_type: item?.NODE_TYPE || "SRH",
    access_type: item?.NODE_REL_TYPE,
    id: item?.REF_NODE_ID || 0,
    type: item?.REF_NODE_TYPE || "USR",
    name: item?.REF_NODE_NAME || "",
    email: item?.USER_EMAIL || "",
    status: item?.USER_STATUS || "",
    created_by: item?.CREATED_BY || 0,
    created_on: item?.CREATED_ON || "",
    comments: item?.COMMENTS || "",
  }));
}

export function getSocialShareListForAnalysis({
  data,
}: AxiosResponse<ShareListAnalysisResponse[]>): ShareListAnalysisParserType[] {
  return data?.map(
    (item) =>
      ({
        node_id: item?.USER_ID || 0,
        id: item?.USER_ID || 0,
        node_type: "ANL",
        access_type: item?.NODE_REL_TYPE || "USER_VIEWER",
        email: item?.USER_EMAIL || "",
        status: item?.USER_STATUS || "",
        type: item?.USER_TYPE || "USR",
        name: item?.USER_NAME || "",
      } || [])
  );
}
