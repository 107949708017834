import { UploadChangeParam } from "antd/lib/upload";
import { RcFile, UploadFile } from "antd/lib/upload/interface";

import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ConfigurationViewStyled } from "./configurationview.styles";

import { ConfigurationViewProps } from "./configurationview.types";

import FormItemLabel from "../../../../../../components/form/formitemlabel";
import { FormStyled } from "../../../../../../components/form";

import {
  InputFieldContainerStyled,
  TextAreaFieldStyled,
} from "../../../../../../components/formfields/textareafield/textareafield.styles";
import { AnalaysisPageState } from "../../../../analysisdetailpage.types";

import { getDvSumInformativeText, jsonParse } from "../../../../../../utils";
import SingleFileUploaderWithProgressBar from "../../../../../../components/singlefileuploaderwithprogressbar";

import { FileStateType } from "../../../../../dataimportpage/dataimportpage.types";
import dvSumAxios from "../../../../../../api";

import {
  useGetDataAnalysisConfUploadedFileSignedURL,
  useGetDataAnalysisUploadSignedUrl,
  useGetDownloadConfSignedUrl,
} from "../../../../../../api/analysisservice";

import {
  analysisWorkFlowConfigSVG,
  downloadIcon,
} from "../../../../../../svgs";

import LinkButton from "../../../../../../components/linkbutton/linkbutton";
import { validateWorkflowJson } from "./configurationview.utils";

import { ChatAgentField } from "../../definitiontab.components";
import { AnalysisConfigurationType } from "../../../../../../parsers";

import { REQUIRED_MESSAGE } from "../../../../../../constants/formconstants";
import { HeadingStyled } from "../../definitiontab.styles";
import ShowMoreOrLessText from "../../../../../../components/showmoreorlesstext/showmoreorlesstext";

const { CancelToken } = dvSumAxios;

const SAMPLE_FILE_LINK = `https://dvsum-app-data-dev.s3.amazonaws.com/workflow/docs/sample_workflow.json?response-content-disposition=attachment%3B%20filename%3D%22sample_workflow.json%22&AWSAccessKeyId=AKIA5TTPV3WAWJTSSTOB&Signature=tDSmcocJ1Dsjwutm%2BF2blJmQM8k%3D&Expires=1871186414`;

const ConfigurationView = (props: ConfigurationViewProps): JSX.Element => {
  const {
    isEditMode = false,
    configuration,
    updateConfiguration,
    aiChatModel = "",
    aiChatModelName = "",
    onChangeAIChatModel,
  } = props;

  const [fileState, setFileState] = useState<FileStateType>({});
  const [confgContent, setConfContent] = useState<string>("");

  const [dirtyFields, setDirtyFields] = useState<Record<string, boolean>>({});

  const cancelSource = useRef<any>(null);

  const {
    workflow_json_s3_pre_signed_get_url: workflowJsonS3PreSignedGetUrl = "",
    prompt_inputs: promptInputs,
  } = configuration || {};

  const {
    ssa_prmpt_tmp_ai_assistant_type: ssaPrmptTmpAiAssistantType,
    ssa_prmpt_tmp_guide: ssaPrmptTmpGuide,
    ssa_prmpt_tmp_logic: ssaPrmptTmpLogic,
    ssa_prmpt_tmp_dat_desc: ssaPrmptTmpDatDesc,
    ssa_prmpt_tmp_res_frmt: ssaPrmptTmpResFrmt,
    ssa_prmpt_tmp_int_lgc: ssaPrmptTmpIntLgc,
  } = getDvSumInformativeText();

  const {
    parsedData: parsedUploadSignedUrl,
  } = useGetDataAnalysisUploadSignedUrl();

  const { data, refetch } = useGetDataAnalysisConfUploadedFileSignedURL(
    fileState?.file?.name || ""
  );

  const downloadLink = data?.data || workflowJsonS3PreSignedGetUrl;

  const { data: confData, isLoading } = useGetDownloadConfSignedUrl(
    data?.data || workflowJsonS3PreSignedGetUrl
  );

  const onFieldChange = useCallback(
    (key: keyof AnalaysisPageState["configuration"]["prompt_inputs"]) => (
      e: ChangeEvent<HTMLTextAreaElement>
    ): void => {
      setDirtyFields({ ...dirtyFields, [key]: true });
      updateConfiguration(e?.target?.value, key);
    },
    [updateConfiguration]
  );

  const onChangeFile = useCallback(
    (info: UploadChangeParam<UploadFile<RcFile>>) => {
      setFileState((s) => ({
        ...s,
        status: undefined,
        errorMessage: undefined,
      }));

      if (!["application/json"]?.includes(info?.file?.type || "")) {
        setFileState((s) => ({
          ...s,
          isUploading: false,
          status: "error",
          errorMessage: "Unsupported file type. Please upload a JSON file.",
        }));
      } else {
        const fileReader = new FileReader();

        fileReader.onload = (e: ProgressEvent<FileReader>): void => {
          const isValidWorkflowJson = validateWorkflowJson(
            jsonParse(e?.target?.result as string)
          );

          if (isValidWorkflowJson) {
            cancelSource.current = CancelToken.source();

            const formData = new FormData();
            const fileUploadKey = parsedUploadSignedUrl?.fields?.key || "";
            const newFileUploadKey = fileUploadKey?.replace(
              `\${filename}`,
              info?.file?.name || ""
            );

            Object.entries(parsedUploadSignedUrl?.fields || {}).forEach(
              ([key, value]) => {
                formData.append(
                  key,
                  key !== "key" ? (value as string) : newFileUploadKey
                );
              }
            );

            formData.append("file", info?.file as any);

            setFileState((st) => ({
              ...st,
              file: info?.file,
              isUploading: true,
              signedUrlKey: newFileUploadKey,
            }));

            dvSumAxios({
              method: "POST",
              url: parsedUploadSignedUrl?.url,
              data: formData,
              onUploadProgress: (e) => {
                const progress = Math.round((e.loaded / e.total) * 100);
                setFileState((s) => ({ ...s, progress }));
              },
              cancelToken: cancelSource.current.token,
            })
              .then(() => {
                refetch().then((res: any) => {
                  updateConfiguration(
                    res?.data?.data,
                    "workflow_json_s3_pre_signed_get_url"
                  );
                });
                setFileState((s) => ({
                  ...s,
                  isUploading: false,
                  status: undefined,
                  errorMessage: undefined,
                }));
              })
              .catch(() => {
                setFileState((s) => ({
                  ...s,
                  isUploading: false,
                  status: "error",
                  errorMessage: "File uploading failed",
                }));
              });
          } else {
            setFileState((s) => ({
              ...s,
              isUploading: false,
              status: "error",
              errorMessage: "Unsupported format. Please upload a valid file.",
            }));
          }
        };

        fileReader.readAsText(info.file as RcFile);
      }
    },
    [fileState, parsedUploadSignedUrl, updateConfiguration]
  );

  useEffect(() => {
    if (confData?.data) {
      setConfContent(JSON.stringify(confData?.data, null, 2));
    }
  }, [confData]);

  const promptInputsConfig: Array<{
    label: string;
    key: keyof AnalysisConfigurationType["prompt_inputs"];
    height: string;
    description: string;
    required?: boolean;
    placeholder?: string;
  }> = useMemo(
    () => [
      {
        label: ssaPrmptTmpAiAssistantType?.field_name,
        key: "ai_assistant_type",
        height: "90px",
        description: ssaPrmptTmpAiAssistantType?.description,
        required: true,
        placeholder: ssaPrmptTmpAiAssistantType?.placeholder,
      },
      {
        label: ssaPrmptTmpIntLgc?.field_name,
        required: true,
        key: "intent_logic",
        height: "250px",
        description: ssaPrmptTmpIntLgc?.description,
        placeholder: ssaPrmptTmpIntLgc?.placeholder,
      },
      {
        label: ssaPrmptTmpDatDesc?.field_name,
        key: "data_description",
        height: "150px",
        description: ssaPrmptTmpDatDesc?.description,
        placeholder: ssaPrmptTmpDatDesc?.placeholder,
      },
      {
        label: ssaPrmptTmpLogic?.field_name,
        key: "logic",
        height: "300px",
        description: ssaPrmptTmpLogic?.description,
        required: true,
        placeholder: ssaPrmptTmpLogic?.placeholder,
      },
      {
        label: ssaPrmptTmpGuide?.field_name,
        key: "guidelines",
        height: "300px",
        description: ssaPrmptTmpGuide?.description,
        required: true,
        placeholder: ssaPrmptTmpGuide?.placeholder,
      },
      {
        label: ssaPrmptTmpResFrmt?.field_name,
        key: "response_format",
        height: "150px",
        description: ssaPrmptTmpResFrmt?.description,
        placeholder: ssaPrmptTmpResFrmt?.placeholder,
      },
    ],
    []
  );
  return (
    <ConfigurationViewStyled isEditMode={isEditMode}>
      <FormStyled isItemColumnLayout className="configuration-form">
        <div>
          <HeadingStyled className="config-title">
            Prompt Configurations
          </HeadingStyled>

          {isEditMode ? (
            promptInputsConfig.map((item) => (
              <FormItemLabel
                label={item?.label}
                paddingLeft="0"
                required={item?.required}
                key={`prompt-form-field-${item?.key}`}
                description={item?.description}
              >
                <InputFieldContainerStyled>
                  <TextAreaFieldStyled
                    value={promptInputs?.[item?.key]}
                    onChange={onFieldChange(item?.key)}
                    name={item?.key}
                    width="100%"
                    height={item?.height}
                    placeholder={item?.placeholder}
                  />
                  {dirtyFields?.[item?.key] &&
                    !promptInputs?.[item?.key] &&
                    item?.required && (
                      <div className="error">{REQUIRED_MESSAGE}</div>
                    )}
                </InputFieldContainerStyled>
              </FormItemLabel>
            ))
          ) : (
            <div className="readonly-config readonly-config-margin-top">
              {promptInputsConfig.map((item) => (
                <div
                  className="config-item-wrapper"
                  key={`prompt-readonly-field-${item?.key}`}
                >
                  <div className="config-label">{item?.label}:</div>
                  <div className="config-value formatted-val">
                    <ShowMoreOrLessText
                      text={promptInputs?.[item?.key] || "--"}
                      textLimit={350}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div>
          <HeadingStyled className="config-title">
            Workflow & LLM Configuration
          </HeadingStyled>

          {isEditMode ? (
            <>
              <FormItemLabel marginBottom="25px" paddingLeft="0" width="712px">
                <div
                  data-testid="data-import-upload-file-view-sec-title"
                  className="sec-title"
                >
                  Workflow JSON
                </div>
                <div
                  data-testid="data-import-upload-file-view-sec-subtitle"
                  className="subtitle"
                >
                  You can import your Workflow JSON file here.{" "}
                  <LinkButton href={SAMPLE_FILE_LINK} marginLeft="4px">
                    Download sample file
                  </LinkButton>
                </div>
                <SingleFileUploaderWithProgressBar
                  file={fileState?.file}
                  status={fileState?.status}
                  errorMessage={fileState?.errorMessage}
                  uploadProgress={fileState?.progress}
                  isLoading={fileState?.isUploading}
                  accept=".json"
                  onChange={onChangeFile}
                  showCancelIcon={false}
                />
              </FormItemLabel>

              {confgContent && (
                <div className="readonly-config">
                  <div className="config-item-wrapper">
                    <div className="config-json-value">
                      <LinkButton
                        className="cong-down-icon"
                        href={downloadLink}
                      >
                        Download: {downloadIcon}
                      </LinkButton>
                      <pre>{confgContent}</pre>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="readonly-config">
              <div className="config-item-wrapper">
                {isLoading ? (
                  analysisWorkFlowConfigSVG
                ) : (
                  <>
                    <div className="config-label">Workflow JSON:</div>
                    <div className="config-json-value">
                      <LinkButton
                        className="cong-down-icon"
                        href={downloadLink}
                      >
                        Download: {downloadIcon}
                      </LinkButton>
                      <pre>{confgContent}</pre>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
          <ChatAgentField
            isEditMode={isEditMode}
            aiChatModel={aiChatModel}
            aiChatModelName={aiChatModelName}
            onChangeAIChatModel={onChangeAIChatModel}
          />
        </div>
      </FormStyled>
    </ConfigurationViewStyled>
  );
};

export default ConfigurationView;
