import * as yup from "yup";
import { formatDate } from "../../../../../../../../../../../../utils";

export const visualDistributionForNumericRangeSchema = yup.object().shape({
  min: yup.lazy((_, prop1) => {
    const minValue = Number(prop1?.parent?.min);
    const maxValue = Number(prop1?.parent?.max);

    if (maxValue)
      return yup
        .string()
        .test(
          "minResolver",
          "Min value should be less than Max value",
          (): boolean => {
            return minValue <= maxValue;
          }
        );
    return yup.string();
  }),
  max: yup.lazy((_, prop1) => {
    const minValue = Number(prop1?.parent?.min);
    const maxValue = Number(prop1?.parent?.max);

    if (minValue >= 0 && !maxValue)
      return yup.string().required("Click the bar again to select Max value");
    return yup
      .string()
      .test(
        "maxResolver",
        "Max value should be greater than Min value",
        (): boolean => {
          return maxValue >= minValue;
        }
      );
  }),
});

export const visualDistributionForDateRangeSchema = yup.object().shape({
  min: yup.lazy((_, prop1) => {
    const minValue = prop1?.parent?.min
      ? new Date(formatDate(prop1?.parent?.min))
      : "";
    const maxValue = prop1?.parent?.max
      ? new Date(formatDate(prop1?.parent?.max))
      : "";

    if (!minValue && maxValue) return yup.string().required();

    return yup.string();
  }),
  max: yup.lazy((_, prop1) => {
    const minValue = prop1?.parent?.min
      ? new Date(formatDate(prop1?.parent?.min))
      : "";
    const maxValue = prop1?.parent?.max
      ? new Date(formatDate(prop1?.parent?.max))
      : "";

    if (minValue && !maxValue) return yup.string().required();
    return yup.string();
  }),
});
