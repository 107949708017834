import { Col, Row } from "antd";
import { useCallback, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";

import {
  AnalysisDetailPageContentSecProps,
  VisibleBottomSecType,
} from "./analysisdetailpagecontentsec.types";

import { checkIsChatPage } from "../../../../utils/ischatpage";
import { messageInputFieldFormSchema } from "../../../../utils/schemas/messageinputfieldformschema";

import { MessageInputFieldFormType } from "../../../../components/formfields/messageinputfield";
import { useGetCurrentGateway } from "../../../../customhooks";

import LinkButton from "../../../../components/linkbutton";
import StateHandler from "../../../../components/statehandler";

import {
  CollapseButtonStyled,
  SavedContextsButtonStyled,
  SelectedToolButtonStyled,
} from "./analysisdetailpagecontentsec.styles";
import {
  angleDown,
  angleUp,
  dataUnderstandingToolIcon,
  generalknowledgeToolIcon,
  guideMeIcon,
  sqlQueryToolIcon,
} from "../../../../svgs";

import {
  AnalysisDetailPageTopicSection,
  GatewayStatusCheckingWrapper,
} from "./analysisdetailpagecontentsec.components";

import MessageInputField from "../../../../components/formfields/messageinputfield/messageinputfield";
import { SkeltonButtonBlankSlate } from "../../../../components/skeltonblankslate";

import { ELEMENT_IDS } from "../../../../constants";
import { QueryBlockListing } from "./analysisdetailpagecontentsec.views";

import Flex from "../../../../components/flex/flex";
import { VerticalDividerStyled } from "../../../../components/dividers/dividers.styles";

import { checkSourceType } from "../../../../utils";

const { caddi_gid_me_btn: CADDI_GID_ME_BTN } = ELEMENT_IDS;

const sqlToolIcon = sqlQueryToolIcon();
const dataToolIcon = dataUnderstandingToolIcon();
const generalKnowledgeToolIcon = generalknowledgeToolIcon();

const AnalysisDetailPageQueriesSec = (
  props: AnalysisDetailPageContentSecProps
): JSX.Element => {
  const {
    queriesSec,
    analysisId = "",
    analysisTitle = "",
    selectedContexts = [],
    selectedTopicId = 0,
    topicsList = [],
    isTopicsListLoading = false,
    topicsListError,
    onTopicSelection,
    // onSummarizeChatClick,
    onGuideMeClick,
    trainBotFilter = "all",
    askedByFilter = ["all"],
    isSavedContextsSecVisible = false,
    toggleSavedContextsView,
    gatewayId = "",
    analysisSrcTypeId,
    isHiddenMode,
    timeFrameFilter = "all",
    isAuthor = false,
  } = props;

  const { handlers } = queriesSec;

  const {
    onAddingANewQuestion,
    onChangeTrainBotFilter,
    onChangeTrainBotAskedByFilter,
  } = handlers;

  const isChatPage = checkIsChatPage();

  const [
    visibleBottomSec,
    setVisibleBottomSec,
  ] = useState<VisibleBottomSecType>("message_field");

  const [selectedTool, setSelectedTool] = useState<
    MessageInputFieldFormType["selectedTool"]
  >("SQL");

  const { isRestAPISource } = checkSourceType(analysisSrcTypeId);

  const selectedTopicTitle = isChatPage
    ? topicsList?.find((item) => item?.id === selectedTopicId)?.title
    : analysisTitle;

  const onChangeSelectedTopic = useCallback(
    (topic: number, name: string) => {
      onTopicSelection(topic, name);
      setVisibleBottomSec("message_field");
    },
    [onTopicSelection]
  );

  const onChangeVisibleBottomSec = useCallback(
    (key: VisibleBottomSecType) => {
      if (key === visibleBottomSec) {
        setVisibleBottomSec("message_field");
      } else {
        setVisibleBottomSec(key);
      }
    },
    [visibleBottomSec]
  );

  const formProvider = useForm({
    defaultValues: {
      text: "",
    },
    mode: "onChange",
    resolver: yupResolver(messageInputFieldFormSchema),
  });
  const { setValue } = formProvider;

  const onUseSampleQuestionClick = useCallback((quesText: string) => {
    setValue("text", quesText);
  }, []);

  const onSubmitWrapper = useCallback(
    (formData: MessageInputFieldFormType): void => {
      setValue("text", "");
      onAddingANewQuestion({ ...formData, selectedTool });
    },
    [onAddingANewQuestion, selectedTool]
  );

  const onClickGenerateQuestion = useCallback(() => {
    onGuideMeClick?.();
    onChangeTrainBotFilter?.("all");
    onChangeTrainBotAskedByFilter?.(["all"]);
  }, [onGuideMeClick, onChangeTrainBotFilter, onChangeTrainBotAskedByFilter]);

  const onChangeTool = useCallback(
    (tool: "SQL" | "GEN" | "DATA") => (): void => {
      setSelectedTool(tool);
    },
    []
  );

  const onSavedContextsButtonClick = useCallback(
    () => (): void => {
      toggleSavedContextsView();
    },
    [toggleSavedContextsView]
  );

  const { isGatewayRunning } = useGetCurrentGateway(gatewayId);

  const showBottomSec =
    !isHiddenMode &&
    (!(isChatPage && !isAuthor) ||
      (isChatPage && !isAuthor && !isGatewayRunning));

  return (
    <Row className="content-sec">
      <Col span={24}>
        <Row className="explorer-query-sec">
          <Col span={24} className="query-block-listing-col">
            <QueryBlockListing
              {...queriesSec}
              onUseSampleQuestionClick={onUseSampleQuestionClick}
              trainBotFilter={trainBotFilter}
              askedByFilter={askedByFilter}
              onGuideMeClick={onGuideMeClick}
              isSavedContextsSecVisible={isSavedContextsSecVisible}
              gatewayId={gatewayId}
              analysisSrcTypeId={analysisSrcTypeId}
              isHiddenMode={isHiddenMode}
              timeFrameFilter={timeFrameFilter}
              isAuthor={isAuthor}
            />
          </Col>
        </Row>

        {showBottomSec && (
          <Row className="bottom-sec">
            <div className="bottom-sec-wrapper">
              <GatewayStatusCheckingWrapper gatewayId={gatewayId} type="error">
                <>
                  {visibleBottomSec === "message_field" && (
                    <FormProvider {...formProvider}>
                      <MessageInputField
                        name="text"
                        placeholder={
                          isChatPage
                            ? "Ask  me a question about this data"
                            : "Ask me anything about this data and I will try to find the answer. You can give feedback so I can improve"
                        }
                        onSubmit={onSubmitWrapper}
                      />
                    </FormProvider>
                  )}

                  <div className="bottom-sec-actions-sec">
                    <div className="left-action-buttons">
                      {isChatPage && (
                        <LinkButton
                          tooltipProps={{
                            title: !isChatPage
                              ? "You can't change the topic on SSA."
                              : "",
                            placement: "topLeft",
                          }}
                          disabled={!isChatPage}
                          data-testid="analysis-detail-page-content-sec-topic-btn-wrapper"
                        >
                          <StateHandler
                            isFetching={isTopicsListLoading}
                            error={topicsListError}
                            blankSlate={<SkeltonButtonBlankSlate />}
                          >
                            <CollapseButtonStyled
                              isActive={visibleBottomSec === "topic_sec"}
                              onClick={(): void =>
                                onChangeVisibleBottomSec("topic_sec")
                              }
                              data-testid="analysis-detail-page-content-sec-topic-collapse-btn"
                            >
                              Using{" "}
                              <span className="highlighted-text">
                                {selectedTopicTitle}
                              </span>{" "}
                              {visibleBottomSec === "topic_sec"
                                ? angleDown("11.2", "11.2")
                                : angleUp("11.2", "11.2")}
                            </CollapseButtonStyled>
                          </StateHandler>
                        </LinkButton>
                      )}

                      {!isRestAPISource && (
                        <SavedContextsButtonStyled
                          isActive={isSavedContextsSecVisible}
                          onClick={onSavedContextsButtonClick()}
                          data-testid="analysis-detail-page-content-sec-context-collapse-btn"
                        >
                          {`Saved Contexts (${selectedContexts.length})`}
                        </SavedContextsButtonStyled>
                      )}

                      {/* {queries?.length > 1 && isChatPage && (
                  <LinkButton
                    className="summarize-btn"
                    onClick={onSummarizeChatClick}
                    data-testid="analysis-detail-page-content-sec-summarize-btn"
                  >
                    Summarize Chat
                  </LinkButton>
                )} */}
                    </div>
                    {!isRestAPISource && (
                      <div className="right-action-buttons">
                        <LinkButton
                          disabled={!analysisId}
                          onClick={onClickGenerateQuestion}
                          tooltipProps={{
                            title:
                              "Get a guided set of follow up questions to ask",
                            placement: "top",
                            overlayClassName: "guide-me-tooltip",
                          }}
                          elementId={CADDI_GID_ME_BTN}
                          className="guide-me-action-btn"
                        >
                          <span>{guideMeIcon("19", "19")}</span>
                        </LinkButton>

                        <VerticalDividerStyled
                          height="9px"
                          width="1px"
                          marginLeft="12px"
                          marginRight="12px"
                        />

                        <div className="tool-sec-heading">Using Tool:</div>

                        <Flex
                          alignItems="center"
                          customClass="tools-sec-container"
                        >
                          <SelectedToolButtonStyled
                            onClick={onChangeTool("SQL")}
                            isSelected={selectedTool === "SQL"}
                            tooltipProps={{ title: "SQL Query Tool" }}
                          >
                            {sqlToolIcon}
                          </SelectedToolButtonStyled>

                          <SelectedToolButtonStyled
                            onClick={onChangeTool("DATA")}
                            isSelected={selectedTool === "DATA"}
                            tooltipProps={{
                              title: "Data Understanding Tool",
                            }}
                          >
                            {dataToolIcon}
                          </SelectedToolButtonStyled>

                          <SelectedToolButtonStyled
                            onClick={onChangeTool("GEN")}
                            isSelected={selectedTool === "GEN"}
                            tooltipProps={{ title: "General Knowledge Tool" }}
                          >
                            {generalKnowledgeToolIcon}
                          </SelectedToolButtonStyled>
                        </Flex>
                      </div>
                    )}
                  </div>

                  <AnalysisDetailPageTopicSection
                    isVisible={visibleBottomSec === "topic_sec"}
                    selectedTopicId={selectedTopicId}
                    topicsList={topicsList}
                    onChangeSelectedTopic={onChangeSelectedTopic}
                  />
                </>
              </GatewayStatusCheckingWrapper>
            </div>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default AnalysisDetailPageQueriesSec;
