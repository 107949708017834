import { useCallback, useMemo, useRef } from "react";

import { useGatewayStatusContext } from "../contexts";
import { usePingCheckRetry } from "../api/accountsettingsservice/sawsservice";

import { openNotification } from "../utils";
import SuccessNotificationMessage from "../components/successnotificationmessagerendrer";

export const useGatewayConRetry = (
  gatewayId: string
): { onRetryGatewayCon: () => void; onRetryInBackground: () => void } => {
  const isClickedOnRetry = useRef<boolean>(false);

  const {
    state: { listOfGateways = [] },
    dispatch,
  } = useGatewayStatusContext();

  const currentGateway = useMemo(
    () => listOfGateways?.find((item) => item?.id === gatewayId),
    [gatewayId, listOfGateways]
  );

  const onSuccess = useCallback(() => {
    dispatch({
      type: "UPDATE_GATEWAY_PING_CHECK_STATUS",
      id: gatewayId,
      status: true,
      isLoading: false,
    });

    if (isClickedOnRetry.current) {
      openNotification(
        <SuccessNotificationMessage
          message="Connected to the Gateway."
          showSuccessText={false}
        />,
        undefined,
        { key: gatewayId }
      );

      isClickedOnRetry.current = false;
    }
  }, [gatewayId]);

  const onError = useCallback(() => {
    dispatch({
      type: "UPDATE_GATEWAY_PING_CHECK_STATUS",
      id: gatewayId,
      status: false,
      isLoading: false,
    });

    if (isClickedOnRetry.current) {
      openNotification(
        <SuccessNotificationMessage
          message="Unable to connect to the Gateway"
          type="error"
          showErrorText={false}
        />,
        undefined,
        { key: gatewayId },
        "error"
      );
      isClickedOnRetry.current = false;
    }
  }, [gatewayId]);

  const { refetch } = usePingCheckRetry(
    currentGateway?.host || "",
    currentGateway?.port || "",
    gatewayId || "",
    onSuccess,
    onError
  );

  const onRetry = useCallback(() => {
    dispatch({
      type: "UPDATE_GATEWAY_PING_CHECK_LOADING",
      id: gatewayId,
      isLoading: true,
    });
    refetch();
    isClickedOnRetry.current = true;
  }, [gatewayId]);

  //If gateway gets down then use this callback to update the status
  //of that of gateway in react query state otherwise it shows old state first time
  const onRetryInBackground = useCallback(() => {
    refetch();
  }, [refetch]);

  return { onRetryGatewayCon: onRetry, onRetryInBackground };
};
