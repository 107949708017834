import { useCallback } from "react";

import StateHandler from "../../components/statehandler/statehandler";
import { useCancelModal, useGetAppState } from "../../customhooks";
import { SaveSourceFormStyled } from "./savesourceform.styles";
import ConfirmationFormModal from "../../components/confirmationformmodal/confirmationformmodal";
import { GeneralSettingsTabFormType } from "../../pages/sourcedetailpage/tabs/settingstab/tabs/generalsettingstab/generalsettingstab.types";
import { useSaveSourceSettings } from "../../pages/sourcedetailpage/tabs/settingstab/settingstab.hooks";

function SaveSourceForm(): JSX.Element {
  const {
    modal: { modalProps = {} },
  } = useGetAppState();

  const {
    id = "",
    currentSettingsTab,
    getPostData,
    onSuccessSave,
    testConnectionPassed,
  } = modalProps as GeneralSettingsTabFormType & {
    getPostData?: () => any;
    currentSettingsTab?: string;
    onSuccessSave: (_response: any) => void;
    testConnectionPassed?: boolean;
  };

  const onCancel = useCancelModal();

  const onSuccess = useCallback(
    (response) => {
      onCancel();
      onSuccessSave(response);
    },
    [modalProps]
  );

  const { isLoading, error, onExecuteRequest } = useSaveSourceSettings(
    currentSettingsTab === "general"
      ? "update_source_general_settings"
      : "update_source_connection_settings",
    id,
    onSuccess
  );

  const onSubmit = useCallback(() => {
    getPostData && onExecuteRequest(getPostData(), undefined, onSuccess);
  }, [currentSettingsTab]);

  return (
    <StateHandler isModal isFetching={isLoading} error={error}>
      <SaveSourceFormStyled>
        <ConfirmationFormModal
          okText="Save"
          onOk={onSubmit}
          onCancel={onCancel}
        >
          {currentSettingsTab === "general"
            ? "Do you want to save the general settings?"
            : testConnectionPassed
            ? "Do you want to save the connection settings?"
            : `Test connection is not verified. Do you still want to save the source.
          You will not be able to run scanner.`}
        </ConfirmationFormModal>
      </SaveSourceFormStyled>
    </StateHandler>
  );
}

export default SaveSourceForm;
