import { AxiosResponse } from "axios";

import {
  DomainsListResponseType,
  ReturnTypeOfDomainsList,
  ReturnTypeOfSourcesList,
  SourcesListResponseType,
} from "./treetransferparser.types";

import { getDataSourceLogoIconByType } from "../../utils";
import CoreNodesCircleIcon from "../../components/corenodetypescircleicon/corenodetypescircleicon";

export const getParsedSourcesList = ({
  data = [],
}: AxiosResponse<SourcesListResponseType>): ReturnTypeOfSourcesList => {
  const uniqueSources = [
    ...new Map(data?.map((source) => [source?.ROOT_ID, source])).values(),
  ];

  const sourcesTreeData = uniqueSources?.map((unqSource) => {
    const sourcesSchemas = data?.filter(
      (dataItem) =>
        dataItem?.ROOT_ID === unqSource?.ROOT_ID && dataItem?.PARENT_NAME
    );

    const uniqueSourceSchemas = [
      ...new Map(
        sourcesSchemas?.map((sourcesSchemasItem) => [
          sourcesSchemasItem?.PARENT_NAME,
          sourcesSchemasItem,
        ])
      ).values(),
    ];

    const sourcesSchemasWithTable = uniqueSourceSchemas?.map(
      (srcSchemaItem) => {
        const schemasTables = sourcesSchemas
          ?.filter(
            (schemaTable) =>
              schemaTable?.ROOT_ID === srcSchemaItem?.ROOT_ID &&
              schemaTable?.PARENT_NAME === srcSchemaItem?.PARENT_NAME
          )
          ?.map((filteredSchemasTable) => {
            return {
              id: `${filteredSchemasTable?.NODE_ID || 0}`,
              title: filteredSchemasTable?.NODE_NAME || "",
              nodeType: filteredSchemasTable?.NODE_TYPE,
              nodeSubType: filteredSchemasTable?.NODE_SUB_TYPE,
              key: `${unqSource?.ROOT_ID || 0}-${
                srcSchemaItem?.PARENT_NAME || ""
              }-${filteredSchemasTable?.NODE_ID}`,
              icon: (
                <CoreNodesCircleIcon
                  nodeType={filteredSchemasTable?.NODE_TYPE}
                  height="16px"
                  width="16px"
                  fontSize="11px"
                  nodeSubType={filteredSchemasTable?.NODE_SUB_TYPE}
                />
              ),
              isLeaf: true,
            };
          });
        return {
          title: srcSchemaItem?.PARENT_NAME || "",
          key: `${unqSource?.ROOT_ID || 0}-${srcSchemaItem?.PARENT_NAME || ""}`,
          sourceId: `${unqSource?.ROOT_ID || 0}`,
          children: schemasTables?.length ? schemasTables : undefined,
        };
      }
    );

    return {
      id: `${unqSource?.ROOT_ID || 0}`,
      title: unqSource?.ROOT_NAME || "",
      key: `${unqSource?.ROOT_ID || 0}`,
      sourceType: unqSource?.SRC_TYPE,
      nodeType: "SRC",
      icon:
        unqSource?.SRC_TYPE && getDataSourceLogoIconByType(unqSource?.SRC_TYPE),
      children: sourcesSchemasWithTable?.length
        ? sourcesSchemasWithTable
        : undefined,
      isLeaf: !sourcesSchemasWithTable?.length,
    };
  });

  return sourcesTreeData || [];
};

export const getParsedDomainsList = ({
  data,
}: AxiosResponse<DomainsListResponseType>): ReturnTypeOfDomainsList => {
  const uniqueDomains = [
    ...new Map(data?.map((item) => [item?.PARENT_ID, item])).values(),
  ];

  const domainsTreeData = uniqueDomains?.map((uniqueDomain) => {
    const subDomainsOfdomain = data?.filter(
      (dataItem) =>
        dataItem?.PARENT_ID === uniqueDomain?.PARENT_ID && dataItem?.NODE_NAME
    );

    const uniqueSubDomainsOfDomain = [
      ...new Map(
        subDomainsOfdomain?.map((domainOfSubDomainsItem) => [
          domainOfSubDomainsItem?.NODE_ID,
          domainOfSubDomainsItem,
        ])
      ).values(),
    ];

    const childrens = uniqueSubDomainsOfDomain?.map(
      (uniqueSubDomainOfDomain) => {
        return {
          id: `${uniqueSubDomainOfDomain?.NODE_ID || 0}`,
          title: uniqueSubDomainOfDomain?.NODE_NAME || "",
          nodeType: uniqueSubDomainOfDomain?.NODE_TYPE,
          key: `${uniqueDomain?.PARENT_ID || 0}-${
            uniqueSubDomainOfDomain?.NODE_ID || 0
          }`,
        };
      }
    );

    return {
      id: `${uniqueDomain?.PARENT_ID || 0}`,
      title: uniqueDomain?.PARENT_NAME || "",
      key: `${uniqueDomain?.PARENT_ID || 0}`,
      nodeType: uniqueDomain?.PARENT_NODE_TYPE,
      subDomainsCount: childrens?.length || 0,
      children: childrens?.length ? childrens : undefined,
      isLeaf: !childrens?.length,
      childrenCount: childrens?.length || 0,
    };
  });
  return domainsTreeData;
};
