// <--- Library Imports Start --->
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
// <--- Library Imports End --->

// <--- Components Start --->
import { Button } from "../../components";
import AutoComplete from "../../components/autocomplete";

import { FormStyled, FormItemStyled } from "../../components/form";
import LinkButton from "../../components/linkbutton";

import { termTitleRenderForSelect } from "../../components/rendrer";
import StateHandler from "../../components/statehandler/statehandler";

import {
  BreadcrumSection,
  entityRenderer,
  MatchingEntitiesSection,
} from "./revierecomendedentitymodal.components";
// <--- Components End --->

// <--- Constants Start --->
import { noEntitiesText } from "./reviewrecomendedentitymodal.constants";
// <--- Constants End --->

// <--- Custom Hooks Start --->
import { useCancelModal, useDebounce, useGetAppState } from "../../customhooks";
// <--- Custom Hooks End --->

// <--- Services Start --->
import { useGetListOfTerms } from "../../api/termservice/termservice";
import { useRequestWithMethod } from "../../api";
// <--- Services End --->

// <--- Styles Start --->
import {
  EntityDropdownStyle,
  ReviewRecomendedEntityFormStyled,
} from "./reviewrecomendedentitymodal.styles";
// <--- Styles End --->

// <--- SVGs Start --->
import { crossIconInCircle, plusIcon } from "../../svgs";
// <--- SVGs End --->

// <--- Types Start --->
import { MatchingStaus } from "../../parsers/columnparsers/columnparsers.types";
import {
  MatchingEntitiesForm,
  ReviewRecEntityFormProps,
} from "./reviewrecomendedentitymodal.types";
import {
  checkIsAnalyticsDictionaryPage,
  checkIsDataDictionaryPage,
} from "../../utils";
// <--- Types End --->

function ReviewRecomendedEntityForm({
  isAddMode,
}: {
  isAddMode?: boolean;
}): JSX.Element {
  const entityFieldRef = useRef<any>();
  // Custom Hooks
  const cancelModal = useCancelModal();
  const {
    modal: { modalProps = {} },
  } = useGetAppState();

  // Modal Props
  const {
    onDone,
    matchingStatus,
    matchingEntities,
    breadcrumbs,
    api,
  } = modalProps as ReviewRecEntityFormProps;

  // States
  const [searchText, setText] = useState<string>("");
  const [isAddEntity, setIsAddEntity] = useState(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);

  const isDataDictionaryPage = useMemo(() => checkIsDataDictionaryPage(), []);

  const isAnalyticsDictionaryPage = useMemo(
    () => checkIsAnalyticsDictionaryPage(),
    []
  );

  // API Integration
  const { parsedData, refetch, status } = useGetListOfTerms(
    searchText,
    isDataDictionaryPage || isAnalyticsDictionaryPage ? "DST" : undefined
  );

  const { isLoading, error, onExecuteRequest } = useRequestWithMethod(
    "update_field_data",
    api?.urlParams || [],
    false,
    api?.onSuccess
  );

  // Callback: Sorting matching entities when form is populated
  const sortedEntities = useMemo(() => {
    const selectedEntity =
      [...(matchingEntities || [])]?.filter((entity) => entity?.selected) || [];

    const nonSelectedEntities =
      [...(matchingEntities || [])]
        ?.filter((entity) => !entity?.selected)
        ?.sort((a, b) =>
          a?.matching_entity > b?.matching_entity
            ? 1
            : a?.matching_entity < b?.matching_entity
            ? -1
            : 0
        ) || [];

    return [...selectedEntity, ...nonSelectedEntities];
  }, [matchingEntities]);

  // Form
  const formProvider = useForm<MatchingEntitiesForm>({
    defaultValues: {
      matching_entities: [...sortedEntities],
    },
  });

  const { control } = formProvider;

  const { fields, update, insert } = useFieldArray<MatchingEntitiesForm>({
    control,
    name: "matching_entities",
  });

  // Variables
  const isUnlinkDisabled =
    !fields?.length ||
    !fields?.filter((field) => field?.selected)?.length ||
    false;
  const isApproveDisabled =
    !fields?.length ||
    !fields?.filter((field) => field?.selected)?.length ||
    ((matchingStatus === "" || matchingStatus === "UNL") && !isChanged) ||
    false;
  const isReviewDisabled =
    !fields?.length ||
    !fields?.filter((field) => field?.selected)?.length ||
    (matchingStatus === "REW" && !isChanged) ||
    false;

  const onSearch = useCallback(
    (val) => {
      setText(val || "");
    },
    [searchText]
  );

  const searchDebounce = useDebounce(searchText, 500);

  // Memoized Variable: Ids of the entities that are present in the grid. To be used to filter search results.
  const selectedEntitiesIds = useMemo(() => {
    return fields?.map((field) => field?.matching_entity_id) || [];
  }, [fields]);

  // Memoized Variable: Filtered search results.
  const filterdParsedData = useMemo(() => {
    return (
      parsedData?.filter(
        (item) => !selectedEntitiesIds?.includes(item?.nodeId)
      ) || []
    );
  }, [parsedData, selectedEntitiesIds]);

  // Options for search field
  const updatedData = filterdParsedData?.length
    ? [
        {
          label: termTitleRenderForSelect("Select an entity"),
          options:
            filterdParsedData?.map((item) => ({
              ...entityRenderer(item),
            })) ?? [],
        },
      ]
    : [];

  // Callbacks
  // When user selects an entity from dropdown
  const onSelect = useCallback(
    (item) => {
      const selectedItem = parsedData?.find(
        (entity) => entity?.nodeId === item
      );
      fields.forEach((field, index) => {
        update(index, { ...field, selected: false });
      });
      insert(
        0,
        {
          match_source: "USR",
          matching_entity: selectedItem?.nodeName,
          matching_entity_id: item,
          matching_score: 100,
          samples: selectedItem?.desc,
          selected: true,
          entity_status: selectedItem?.published_status,
          entity_status_id: selectedItem?.published_status_id,
        },
        {}
      );
      setIsChanged(true);
      setText("");
      entityFieldRef?.current?.blur();
    },
    [fields, parsedData, entityFieldRef]
  );

  // When user clicks on approve/flags for review
  const onSubmit = useCallback(
    (type: MatchingStaus) => {
      const selectedEntity = fields?.find((field) => field?.selected);

      if (api) {
        const isUnlink = type === "UNL";
        const entities = isUnlink
          ? fields?.map((item) => ({ ...item, selected: false }))
          : fields;
        const payload = {
          ...api?.payload,
          col_entity_id: !isUnlink
            ? Number.parseInt(selectedEntity?.matching_entity_id || "0")
            : null,
          entity_action: type || "UNL",
          col_entity_json: entities?.map((entity) => ({
            entity_id: entity?.matching_entity_id || "",
            entity_name: entity?.matching_entity || "",
            entity_desc: entity?.samples || "",
            entity_match_source: entity?.match_source || "",
            entity_matching_score: entity?.matching_score || 100,
            entity_selection_status: entity?.selected ? "SEL" : "NSL" || "NSL",
          })),
        };

        onExecuteRequest([payload]);
      } else {
        onDone?.({
          isUnlink: type === "UNL",
          status: type,
          matchingEntities:
            type === "UNL"
              ? fields?.map((item) => ({ ...item, selected: false }))
              : fields,
          selectedEntity: type === "UNL" ? undefined : selectedEntity,
        });
        cancelModal();
      }
    },
    [onDone, fields]
  );

  // Select entity
  const onUpdate = useCallback(
    (entityId: string, selected: boolean) => {
      fields?.forEach((field, index: number) =>
        update(index, {
          ...field,
          selected: field?.matching_entity_id === entityId && selected,
        })
      );
      setIsChanged(true);
    },
    [fields]
  );

  // Show search bar
  const onClickAdd = useCallback(() => {
    setIsAddEntity(true);
  }, []);

  // Hide search bar
  const onClickCancel = useCallback(() => {
    setIsAddEntity(false);
    setText("");
  }, []);

  // Use Effects
  useEffect(() => {
    searchDebounce && refetch();
  }, [searchDebounce]);

  // Callback: Rendering auto complete component
  const renderAutoComplete = useCallback(
    (width: string) => {
      return (
        <AutoComplete
          placeholder="Search ..."
          dropdownClassName="entity-search-results-dropdown"
          options={updatedData}
          listHeight={250}
          width={width || "238px"}
          virtual
          status={status}
          onSelect={onSelect}
          onSearch={onSearch}
          searchValue={searchText}
          value={searchText}
          data={filterdParsedData}
          isMultiple={false}
          isUseGlobalStyle
          allowClear
          onClear={(): void => onSearch("")}
          isShowIcon
          autoRef={(ref: any): void => {
            entityFieldRef.current = ref;
          }}
        />
      );
    },
    [
      updatedData,
      status,
      searchText,
      filterdParsedData,
      entityFieldRef,
      onSelect,
      onSearch,
    ]
  );

  return (
    <StateHandler isFetching={isLoading} error={error} isModal>
      <>
        <EntityDropdownStyle />
        <FormProvider {...formProvider}>
          <ReviewRecomendedEntityFormStyled>
            <BreadcrumSection
              databaseName={breadcrumbs?.sourceName}
              tableName={breadcrumbs?.tableName}
              columnName={breadcrumbs?.columnName}
            />

            {isAddMode ? (
              !fields?.length ? (
                <div className="no-entities-section">
                  {renderAutoComplete("492px")}
                  <div className="no-entities-info-text">{noEntitiesText}</div>
                </div>
              ) : (
                <div className="scrollable-sec">
                  {renderAutoComplete("238px")}
                  <MatchingEntitiesSection
                    fields={fields}
                    onUpdate={onUpdate}
                  />
                </div>
              )
            ) : (
              <div className="scrollable-sec">
                <MatchingEntitiesSection fields={fields} onUpdate={onUpdate} />
                {isAddEntity ? (
                  <div className="search-entity-section">
                    {renderAutoComplete("238px")}
                    <LinkButton onClick={onClickCancel} className="cross-btn">
                      {crossIconInCircle()}
                    </LinkButton>
                  </div>
                ) : (
                  <LinkButton onClick={onClickAdd} className="add-btn">
                    {plusIcon("11", "11")} Add
                  </LinkButton>
                )}
              </div>
            )}

            <FormStyled>
              <FormItemStyled className="form-actions-sec">
                <Button
                  id="danger"
                  onClick={(): void => onSubmit("UNL")}
                  disabled={isUnlinkDisabled}
                >
                  Unlink
                </Button>

                <div className="actions-sec-right">
                  <Button marginLeft="8px" id="cancel" onClick={cancelModal}>
                    Cancel
                  </Button>
                  <Button
                    marginLeft="8px"
                    id="primary"
                    onClick={(): void => onSubmit("REW")}
                    disabled={isReviewDisabled}
                  >
                    Save
                  </Button>
                  <Button
                    marginLeft="8px"
                    id="primary"
                    onClick={(): void =>
                      onSubmit(
                        matchingStatus === "APR" && !isChanged ? "REW" : "APR"
                      )
                    }
                    disabled={isApproveDisabled}
                  >
                    {matchingStatus === "APR" && !isChanged
                      ? "Unapprove"
                      : "Approve"}
                  </Button>
                </div>
              </FormItemStyled>
            </FormStyled>
          </ReviewRecomendedEntityFormStyled>
        </FormProvider>
      </>
    </StateHandler>
  );
}

export default ReviewRecomendedEntityForm;
