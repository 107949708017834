import { useCallback, useState } from "react";
import { useHistory, useParams } from "react-router";
import BreadCrum from "../../components/breadcrum";
import LeftTabs from "../../components/tabs/lefttabs";
import { APP_ROUTES, ELEMENT_IDS } from "../../constants";
import { settingIconDynamicColor } from "../../svgs";
import { MySettingsPageStyled } from "./mysettingspage.styles";
import {
  GeneralView,
  GlobalFiltersView,
  ChatPreferencesView,
} from "./mysettingspage.views";

const { userjrn_per_glblflt_ttl: USERJRN_PER_GLBLFLT_TTL } = ELEMENT_IDS;

const MySettingsPage = (): JSX.Element => {
  const history = useHistory();
  const { tab = "general" } = useParams() as any;

  const [lastPage, setLastPage] = useState(-1);

  const onTabChange = useCallback(
    (tabName) => {
      history.push(`${APP_ROUTES.private_routes.my_settings}/${tabName}`);
      setLastPage(lastPage - 1);
    },
    [lastPage]
  );

  return (
    <>
      <BreadCrum currentPage={{ label: "My Settings" }} />
      <MySettingsPageStyled>
        <div className="header">
          <div className="heading-sec">
            <div className="icon">{settingIconDynamicColor()}</div>
            <div className="heading">My Settings</div>
          </div>
          <div className="page-desc">
            Use this page to update your preferences
          </div>
        </div>

        <div className="content-sec">
          <LeftTabs
            id={USERJRN_PER_GLBLFLT_TTL}
            activeKey={tab}
            onChange={onTabChange}
          >
            <LeftTabs.LeftTabPane tab="General" key="general">
              <GeneralView lastPage={lastPage} />
            </LeftTabs.LeftTabPane>
            <LeftTabs.LeftTabPane tab="Global Filters" key="global-filters">
              <GlobalFiltersView lastPage={lastPage} />
            </LeftTabs.LeftTabPane>
            <LeftTabs.LeftTabPane tab="Chat Preferences" key="chat-preferences">
              <ChatPreferencesView lastPage={lastPage} />
            </LeftTabs.LeftTabPane>
          </LeftTabs>
        </div>
      </MySettingsPageStyled>
    </>
  );
};

export default MySettingsPage;
