import styled from "styled-components";

export const CheckboxRendererStyled = styled.span`
  .readonly-checkbox {
    cursor: default;

    .ant-checkbox {
      .ant-checkbox-input {
        cursor: default;
      }
    }
  }
`;
