import styled from "styled-components";

export const DQRuleActionsWrapperStyled = styled.div<{
  actionsFormVisible: boolean;
}>`
  display: flex;
  column-gap: 8px;

  ${({ theme, actionsFormVisible }): string =>
    actionsFormVisible
      ? `
    .ant-tooltip-open {
      z-index: 1;
      border-radius: 50%;
      background: ${theme.bgColors.LIGHT_BLUE_25};
      pointer-events:none;

      .actions-container{
        color: ${theme.textColors.BLUE_116};
      }
    }
  `
      : ""}

  .dvsum-tooltip {
    max-width: 413px;
    .ant-tooltip-content {
      .ant-tooltip-inner {
        max-width: 413px;
      }
    }
  }

  .static-text-rendrer {
    color: ${({ theme }): string => theme?.textColors?.LIGHT_GREY_50};
  }
`;

export const DQRuleActionsFormOverlayStyled = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: ${({ theme }): string => theme?.bgColors?.BLACK_COLOR};
  opacity: 0.4;
`;
