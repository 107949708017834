import * as yup from "yup";
import { REQUIRED_MESSAGE } from "../../../constants/formconstants";
import { descriptionSchema } from "./ruledescriptionschema";

export const addCustomQuerySchema = yup.object().shape({
  rule_type: yup.string()?.trim().required(REQUIRED_MESSAGE),
  rule_description: descriptionSchema,
  stored_procedure: yup.boolean(),
  custom_query: yup.string()?.trim().required(REQUIRED_MESSAGE),
});
