import styled from "styled-components";

export const PasswordPolicyPoints = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .point {
    display: grid;
    grid-template-columns: 13px auto;
    gap: 5px;

    svg {
      margin-top: 5px;
      width: 11px;
    }

    span {
      line-height: 1.5;
    }
  }
`;

export const PasswordPolicyTooltipStyled = styled.div`
  .ant-tooltip {
    top: 0px !important;

    .ant-tooltip-content {
      .ant-tooltip-arrow {
        top: 33px !important;

        .ant-tooltip-arrow-content {
          box-shadow: 0 0 7px 0 rgba(211, 211, 211, 0.85);
          background-color: ${({ theme }): string =>
            theme.bgColors.WHITE_COLOR};
          width: 8px;
          height: 8px;
        }
      }
      .ant-tooltip-inner {
        width: 300;
        min-height: 82px;
        padding: 16px 15px 18px 15px;
        box-shadow: 0 0 7px 0 rgba(211, 211, 211, 0.85);
        background-color: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
        font-size: 13px;
        color: ${({ theme }): string => theme.textColors.GREY_26};
      }
    }
  }
`;
