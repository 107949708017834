import { useCallback } from "react";
import { useHistory } from "react-router";
import { FormProvider, useForm } from "react-hook-form";

import { GeneralViewStyled } from "./generalview.styles";
import RouterPrompt from "../../../../components/routerprompt";

import { Button } from "../../../../components";

import {
  getChatPrefrencesData,
  openNotification,
  updateChatPrefrencesData,
} from "../../../../utils";

import { useRequestWithMethod } from "../../../../api";
import SuccessNotificationMessage from "../../../../components/successnotificationmessagerendrer";

import StateHandler from "../../../../components/statehandler/statehandler";

import {
  DESCRIPTIVE_MODE_EXAMPLE_SVG,
  NON_DESCRIPTIVE_MODE_EXAMPLE_SVG,
} from "./generalview.constants";

import { CheckboxField } from "../../../../components/formfields";
import { GeneralViewProps } from "./generalview.types";

const GeneralView = (props: GeneralViewProps): JSX.Element => {
  const { lastPage } = props;

  const history = useHistory();
  const chatPreferences = getChatPrefrencesData();

  const {
    is_descriptive_mode_enabled: isDescriptiveModeEnabled = false,
  } = chatPreferences;

  const descriptiveModeForm = useForm({
    defaultValues: {
      isDescriptiveModeEnabled,
    },
    mode: "onChange",
  });

  const {
    formState: { isDirty },
    watch,
    handleSubmit,
    reset,
    control,
  } = descriptiveModeForm;

  const { isDescriptiveModeEnabled: watchedIsDescriptiveModeEnabled } = watch();

  const onPreferencesUpdateSuccess = useCallback(
    (res): void => {
      const { is_descriptive_mode_enabled: isDescriptiveModeEnabled } =
        res?.data || {};

      updateChatPrefrencesData({
        is_descriptive_mode_enabled: isDescriptiveModeEnabled,
      });

      reset({ isDescriptiveModeEnabled });

      openNotification(
        <SuccessNotificationMessage
          message={`Description mode has been turned ${
            isDescriptiveModeEnabled ? "on" : "off"
          }.`}
          showSuccessText={false}
        />
      );

      if (lastPage) {
        history.go(lastPage);
      }
    },
    [lastPage]
  );

  const {
    onExecuteRequest: updateUserPreferences,
    isLoading,
    error,
  } = useRequestWithMethod(
    "update_user_preferences",
    [],
    false,
    onPreferencesUpdateSuccess
  );

  const onSubmit = useCallback(
    ({ isDescriptiveModeEnabled }) => {
      updateUserPreferences({
        ...chatPreferences,
        is_descriptive_mode_enabled: isDescriptiveModeEnabled,
      });
    },
    [chatPreferences]
  );

  const onCancel = useCallback(() => {
    reset();
  }, []);

  return (
    <StateHandler isFetching={isLoading} error={error} isModal>
      <GeneralViewStyled>
        <RouterPrompt
          when={isDirty}
          title="Discard Changes"
          desc="You have some unsaved changes. These changes will be lost if you leave this page."
        />

        <FormProvider {...descriptiveModeForm}>
          <div className="content">
            <div className="checkbox-wrapper">
              <CheckboxField name="isDescriptiveModeEnabled" control={control}>
                Enable Description
              </CheckboxField>
              <div className="desc">
                When enabled, helpful descriptions will be displayed alongside
                all form elements (Incl. input fields, radio button...). You can
                turn it off to streamline the interface by removing extra
                information.
              </div>
            </div>

            <div className="descriptive-img-wrapper">
              {watchedIsDescriptiveModeEnabled
                ? DESCRIPTIVE_MODE_EXAMPLE_SVG
                : NON_DESCRIPTIVE_MODE_EXAMPLE_SVG}
            </div>
          </div>
        </FormProvider>

        <div className="action-sec">
          <Button id="cancel" onClick={onCancel} disabled={!isDirty}>
            Cancel
          </Button>
          <Button
            disabled={!isDirty}
            onClick={handleSubmit(onSubmit)}
            tooltipProps={{
              title: !isDirty ? "You haven't changed anything" : "",
              placement: "topRight",
            }}
          >
            Save
          </Button>
        </div>
      </GeneralViewStyled>
    </StateHandler>
  );
};

export default GeneralView;
