import { useHistory, useParams } from "react-router";
import { useCallback, useEffect, useMemo, useState } from "react";

import { LinkTabs } from "../../../../../../components/tabs";

import {
  ChatBotSectionStyled,
  PageContentStyled,
  PageMenuStyled,
} from "./chatbotsection.styles";

import DefinitionActionBar from "../definitionactionbar/definitionactionbar";
import {
  BotGuidelinesTypeFilterType,
  ChatBotSectionType,
} from "./chatbotsection.types";

import { DefinitionTab, GuidelinesTab } from "../../../../tabs";
import AnalysisDetailPageQueriesSec from "../../anaysisdetailpagequeriessec";

import {
  checkSourceType,
  getAnalysisDetailPageUrl,
  openNotification,
} from "../../../../../../utils";

import TrainBotActionBar from "../trainbotactionbar";
import StateHandler from "../../../../../../components/statehandler/statehandler";

import {
  chatBotDefinitionBlankSlate,
  chatBotTrainSectionBlankSlate,
} from "../../../../../../blankslates/analysisblankslate";

import {
  ChatBotPageTabsType,
  ChatbotPageParamViewKeys,
  PinnedTableType,
} from "../../../../analysisdetailpage.types";

import { AnalysisDetailPageHeaderParamsState } from "../../../analysisdetailpageheader/analysisdetailpageheader.types";
import {
  ANALYSIS_BOT_TABS,
  DEFAULT_IS_FROM_PAGE,
} from "../../../../analysisdetalpage.constants";

import { APP_ROUTES, ELEMENT_IDS } from "../../../../../../constants";
import GatewayStatusCheckingWrapper from "../gatewaystatuscheckingwrapper/gatewaystatuscheckingwrapper";

import {
  useGetAppState,
  useOpenModal,
  useScrollingUp,
} from "../../../../../../customhooks";

import GuidelinesActionBar from "../guidelinesactionbar/guidelinesactionbar";
import { useGetBotConcepts } from "../../../../../../api/analysisservice";

import ConditionalDisplay from "../../../../../../components/conditionaldisplay/conditionaldisplay";
import { TrainbotShareConceptFormProps } from "../../../../../../forms/trainbotshareconceptform";

import { botGuidelinesTabBlankslate } from "../../../../../../blankslates";
import AnalyticsTab from "../../../../tabs/analyticstab";
import { BotGuidelineType } from "../../../../../../parsers";

const ChatBotSection = (props: ChatBotSectionType): JSX.Element => {
  const { tab = "definition" } = useParams<{ tab: ChatbotPageParamViewKeys }>();

  const history = useHistory();
  const onOpenModal = useOpenModal();

  const locationState = (history?.location?.state ||
    {}) as AnalysisDetailPageHeaderParamsState;

  const previousPage = locationState?.isFromPage || DEFAULT_IS_FROM_PAGE;
  const previousRoute = locationState?.isFromRoute;

  const {
    isAuthor,
    shareType = "NON",
    isAnalysisTitleExists,
    analysisQueryBlocksInfo,
    isEditMode,
    handlers,
    pinnedTablesList = [],
    tableIds,
    sourceId,
    analysisSrcTypeId,
    analysisId,
    queries,
    trainbotSecProps,
    isAnalysisLoading,
    isScopeEnabled,
    hasUserAccessToEdit,
    hasManageFieldsChanges,
    analysisError,
    isDataSavedIntoState,
    configuration,
    aiChatModel,
    aiChatModelName,
    dataSourceUsed,
    trainBotFilteredQueries,
    gatewayId = "",
  } = props;

  const {
    queriesSec,
    toggleSavedContextsView,
    trainBotFilter,
    askedByFilter,
    timeFrameFilter,
  } = trainbotSecProps || {};

  const {
    ai_assistant_type: aiAssistantType = "",
    logic: troubleShootingGuide = "",
    guidelines = "",
    intent_logic: intentLogic = "",
  } = configuration?.prompt_inputs || {};

  const hasPromptExists = !!(
    aiAssistantType &&
    troubleShootingGuide &&
    guidelines &&
    intentLogic
  );

  const { handlers: trainBotHandlers } = queriesSec || {};

  const {
    onChangeTrainBotFilter,
    onChangeTrainBotAskedByFilter,
    onRemoveFeedback,
    onRefreshAllClick,
    onExpandCollapseClickForTrainBot,
    onChangeTrainBotTimeFrameFilter,
  } = trainBotHandlers || {};

  const {
    onEditClick,
    onDiscardClick,
    onSaveDoneClick,
    onLeaveTabInEditMode,
    onScopeEnabledClick,
    updatePinnedTablesList,
    onAddQuery,
    onManageFieldsChange,
    onSaveAnalysisButtonClicked,
    updateConfiguration,
    onChangeAIChatModel,
  } = handlers;

  const [isSaveDisable, setIsSaveDisabled] = useState(false);

  const [
    selectedTypeFilter,
    setSelectedTypeFilter,
  ] = useState<BotGuidelinesTypeFilterType>("all");

  const { isRestAPISource } = checkSourceType(analysisSrcTypeId);
  const isScrolled = useScrollingUp(ANALYSIS_BOT_TABS);

  const { sideBarPosition } = useGetAppState();
  const isCollapsed = sideBarPosition === "collapsed";

  const {
    isLoading: isGuidelinesLoading,
    error: guidelinesError,
    parsedData: guidelinesParsedData = [],
  } = useGetBotConcepts(analysisId, sourceId);

  const guidelinesDataLists = useMemo(() => {
    //Adding this return statement to improve the perfomance, unnecessary execution if user is not in training tab
    if (tab !== "training") {
      return {
        concepts: guidelinesParsedData,
        feedbacks: [],
        allGuidelines: [],
        hiddenQrys: [],
      };
    }

    const negativeFeedbackQueries = queries?.filter(
      (qry) => qry?.user_feedback?.response === "dislike"
    );

    const negativeFeedbackQueriesIds = negativeFeedbackQueries?.map(
      (qry) => qry?.id
    );

    const childQueries = queries
      ?.filter((qry) =>
        negativeFeedbackQueriesIds?.includes(qry?.parent_query_id || "")
      )
      ?.filter((qry) => !!qry?.results?.columns?.length);

    const childQrysIds = childQueries?.map((qry) => qry?.id);

    const combinedNegativeQueries = [
      ...negativeFeedbackQueries,
      ...childQueries,
    ];

    const mappedQueriesToGuidelines: BotGuidelineType[] = combinedNegativeQueries?.map(
      (qry) => ({
        id: qry?.id,
        updated_by: qry?.qry_updated_by,
        updated_on: qry?.qry_updated_on,
        type: "feedback",
        action_rend: "",
        sql_query: qry?.user_feedback?.train_sql || "",
        explanation: qry?.user_feedback?.train_me || "",
        question: qry?.header?.title || "",
        query_data: qry,
        handlers: trainBotHandlers,
        queries: queries?.filter(
          (qryItem) => qryItem?.parent_query_id === qry?.id
        ),
        src_type_id: analysisSrcTypeId,
        hidden: childQrysIds?.includes(qry?.id || ""),
      })
    );

    const hiddenQrys = mappedQueriesToGuidelines?.filter((qry) => qry?.hidden);
    const qrysToShow = mappedQueriesToGuidelines?.filter((qry) => !qry?.hidden);

    return {
      concepts: guidelinesParsedData,
      feedbacks: qrysToShow,
      allGuidelines: [...guidelinesParsedData, ...qrysToShow],
      hiddenQrys,
    };
  }, [guidelinesParsedData, queries, analysisSrcTypeId, tab]);

  const typeFilteredGuidelinesList = useMemo(
    () =>
      selectedTypeFilter === "concept"
        ? guidelinesDataLists?.concepts
        : selectedTypeFilter === "feedback"
        ? guidelinesDataLists?.feedbacks
        : guidelinesDataLists?.allGuidelines,
    [selectedTypeFilter, guidelinesDataLists]
  );

  const enableSaveButton = useCallback(() => {
    setIsSaveDisabled(false);
  }, []);

  const disableSaveButton = useCallback(() => {
    setIsSaveDisabled(true);
  }, []);

  const onChangeTypeFilter = useCallback(
    (filter) => setSelectedTypeFilter(filter as BotGuidelinesTypeFilterType),
    []
  );

  const onAddGuidelineClick = useCallback(() => {
    const modalProps: TrainbotShareConceptFormProps = {
      description: "",
      analysisId,
      sourceId,
      sourceName: dataSourceUsed?.name,
      currentBotId: analysisId,
      currentBotSrcId: sourceId,
    };

    onOpenModal({
      modalId: "train_bot_share_concept_modal",
      visible: true,
      modalProps,
    });
  }, [onOpenModal, analysisId, sourceId, dataSourceUsed?.name]);

  const pageTabs: ChatBotPageTabsType[] = useMemo(
    () => [
      { key: "definition", value: "Definition", id: "definition" },

      ...((!!analysisId &&
        hasUserAccessToEdit && [
          {
            key: "train-bot" as ChatbotPageParamViewKeys,
            value: "Train Bot",
            id: ELEMENT_IDS.chatbot_train_bot_tab,
          },
          {
            key: "training" as ChatbotPageParamViewKeys,
            value: "Training",
            id: ELEMENT_IDS.cht_bot_trng_tab,
          },
          ...((shareType !== "NON" && [
            {
              key: "analytics" as ChatbotPageParamViewKeys,
              value: "Analytics",
              id: ELEMENT_IDS.cht_bot_anal_tab,
            },
          ]) ||
            []),
        ]) ||
        []),
    ],
    [analysisId, hasUserAccessToEdit, shareType]
  );

  const onChangeTab = useCallback(
    (newTab: ChatbotPageParamViewKeys) => {
      onLeaveTabInEditMode();
      onChangeTrainBotFilter?.("all");
      onChangeTrainBotAskedByFilter?.(["all"]);
      toggleSavedContextsView("close");

      history.push(
        getAnalysisDetailPageUrl(
          analysisId,
          newTab,
          newTab === "analytics" ? "chats-listing" : undefined
        ),
        {
          sourceId,
          isFromPage: previousPage,
          isFromRoute: previousRoute,
        }
      );
    },
    [
      history,
      analysisId,
      sourceId,
      onLeaveTabInEditMode,
      previousPage,
      onChangeTrainBotFilter,
      onChangeTrainBotAskedByFilter,
      toggleSavedContextsView,
    ]
  );

  useEffect(() => {
    if (
      !hasUserAccessToEdit &&
      !analysisError &&
      !isAnalysisLoading &&
      isDataSavedIntoState
    ) {
      openNotification(
        "You don't have Access to this bot",
        undefined,
        undefined,
        "error"
      );
      history.push(APP_ROUTES.private_routes.home);
    }
  }, [isAnalysisLoading, isDataSavedIntoState]);

  const updatePinnedTablesListAndSaveButtonState = useCallback(
    (newTable: PinnedTableType, isAdded?: boolean) => {
      isAdded && setIsSaveDisabled(false);
      if (!isAdded && pinnedTablesList?.length === 1) setIsSaveDisabled(true);
      updatePinnedTablesList(newTable);
    },
    [updatePinnedTablesList, pinnedTablesList]
  );

  const setSaveButtonState = useCallback(() => {
    setIsSaveDisabled(false);
    onDiscardClick();
  }, [setIsSaveDisabled, onDiscardClick]);

  const pageMenuRightSection = (): JSX.Element => {
    switch (tab) {
      case "definition":
        return (
          <DefinitionActionBar
            hasManageFieldsChanges={hasManageFieldsChanges}
            isAuthor={isAuthor}
            isEditMode={isEditMode}
            isAnalysisTitleExists={isAnalysisTitleExists}
            handlers={{
              onSaveAnalysisButtonClicked,
              onEditClick,
              onSaveDoneClick,
              onDiscardClick: setSaveButtonState,
            }}
            analysisQueryBlocksInfo={analysisQueryBlocksInfo}
            isSaveButtonDisable={
              isSaveDisable ||
              (analysisSrcTypeId === "RST"
                ? !configuration?.workflow_json_s3_pre_signed_get_url ||
                  !hasPromptExists
                : false)
            }
            hasUserAccessToEdit={hasUserAccessToEdit}
            analysisSrcTypeId={analysisSrcTypeId}
            hasConfgUrlExists={
              !!configuration?.workflow_json_s3_pre_signed_get_url
            }
            hasPromptExists={hasPromptExists}
          />
        );
      case "training":
        return (
          <GuidelinesActionBar
            allGuidelinesLength={
              guidelinesDataLists?.allGuidelines?.length || 0
            }
            conceptsLength={guidelinesDataLists?.concepts?.length || 0}
            feedbacksLength={guidelinesDataLists?.feedbacks?.length || 0}
            selectedTypeFilter={selectedTypeFilter}
            onChangeTypeFilter={onChangeTypeFilter}
            onAddGuidelineClick={onAddGuidelineClick}
          />
        );
      case "train-bot":
        return (
          <TrainBotActionBar
            onChangeTrainBotFilter={onChangeTrainBotFilter}
            onChangeTrainBotAskedByFilter={onChangeTrainBotAskedByFilter}
            onRefreshAllClick={onRefreshAllClick}
            onExpandCollapseClickForTrainBot={onExpandCollapseClickForTrainBot}
            onChangeTrainBotTimeFrameFilter={onChangeTrainBotTimeFrameFilter}
            queries={queries}
            trainBotFilteredQueries={trainBotFilteredQueries}
            gatewayId={gatewayId}
            askedByFilter={askedByFilter}
            trainBotFilter={trainBotFilter}
            timeFrameFilter={timeFrameFilter}
          />
        );

      default:
        return <div />;
    }
  };

  const pageContent = (): JSX.Element => {
    switch (tab) {
      case "definition":
        return (
          <StateHandler
            isFetching={isAnalysisLoading}
            blankSlate={chatBotDefinitionBlankSlate}
            error={false}
          >
            <DefinitionTab
              isScopeEnabled={isScopeEnabled}
              pinnedTablesList={pinnedTablesList}
              updatePinnedTablesList={updatePinnedTablesListAndSaveButtonState}
              onAddQuery={onAddQuery}
              tableIds={tableIds}
              sourceId={sourceId}
              analysisSrcTypeId={analysisSrcTypeId}
              queries={queries}
              analysisId={analysisId}
              onSaveDoneClick={onSaveDoneClick}
              isEditMode={isEditMode}
              onScopeEnabledClick={onScopeEnabledClick}
              enableSaveButton={enableSaveButton}
              disableSaveButton={disableSaveButton}
              onManageFieldsChange={onManageFieldsChange}
              hasManageFieldsChanges={hasManageFieldsChanges}
              configuration={configuration}
              updateConfiguration={updateConfiguration}
              aiChatModel={aiChatModel}
              aiChatModelName={aiChatModelName}
              onChangeAIChatModel={onChangeAIChatModel}
            />
          </StateHandler>
        );
      case "training":
        return <div />;
      case "train-bot":
        return (
          <StateHandler
            isFetching={isAnalysisLoading}
            blankSlate={chatBotTrainSectionBlankSlate}
            error={false}
          >
            <GatewayStatusCheckingWrapper
              gatewayId={gatewayId}
              type="loading"
              height="400px"
            >
              <AnalysisDetailPageQueriesSec {...trainbotSecProps} />
            </GatewayStatusCheckingWrapper>
          </StateHandler>
        );

      case "analytics":
        return <AnalyticsTab analysisId={analysisId} />;

      default:
        return <div />;
    }
  };

  return (
    <ChatBotSectionStyled isCollapsed={isCollapsed}>
      <div
        className={
          isScrolled ? "fixed-tabs-sec fixed-tabs-sec-trans" : "tabs-sec"
        }
      >
        <PageMenuStyled isScrolled={isScrolled}>
          <LinkTabs tabs={pageTabs} selectedTab={tab} onChange={onChangeTab} />
          <div className="action-bar">
            {isAnalysisLoading ? undefined : pageMenuRightSection()}
          </div>
        </PageMenuStyled>
      </div>
      <div id={ANALYSIS_BOT_TABS} />
      <PageContentStyled>
        {pageContent()}

        <StateHandler
          isFetching={
            (isGuidelinesLoading || isAnalysisLoading) && tab === "training"
          }
          error={guidelinesError}
          isShowBlankSlate={tab === "training"}
          blankSlate={botGuidelinesTabBlankslate}
        >
          <ConditionalDisplay condition={tab === "training"}>
            <GuidelinesTab
              allGuidelinesLength={
                guidelinesDataLists?.allGuidelines?.length || 0
              }
              guidelines={typeFilteredGuidelinesList}
              onAddGuidelineClick={onAddGuidelineClick}
              onRemoveFeedback={onRemoveFeedback}
              analysisId={analysisId}
              sourceId={sourceId}
              hiddenQrys={guidelinesDataLists?.hiddenQrys}
              gatewayId={gatewayId}
            />
          </ConditionalDisplay>
        </StateHandler>
      </PageContentStyled>
    </ChatBotSectionStyled>
  );
};

export default ChatBotSection;
