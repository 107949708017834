import { useMemo } from "react";
import {
  GatewayStatusContextState,
  useGatewayStatusContext,
} from "../contexts";

export const useGetCurrentGateway = (
  gatewayId: string
): {
  isGatewayRunning: boolean;
  currentGateway?: GatewayStatusContextState["listOfGateways"][number];
  isFetchingGatewaysList: boolean;
} => {
  const {
    state: { listOfGateways = [], isFetchingGatewaysList = false },
  } = useGatewayStatusContext();

  const currentGateway = useMemo(
    () => listOfGateways?.find((item) => item?.id === gatewayId),
    [gatewayId, listOfGateways]
  );

  return {
    isGatewayRunning: !!currentGateway?.isGatewayRunning,
    currentGateway,
    isFetchingGatewaysList,
  };
};
