// <--- Components Start --->
import CoreNodesCircleIcon from "../../../../components/corenodetypescircleicon";
import { ListOfAllTermsReturnType } from "../../../../parsers/termsparser";
// <--- Components End --->

// <--- SVGs Start --->
import { categoryIcon, doomianIcon, rightAngleIcon } from "../../../../svgs";
// <--- SVGs End --->

// <--- Styles Start --->
import { EntityRendererStyled } from "./entityrenderer.styles";
// <--- Styles End --->

const entityRenderer = (item: ListOfAllTermsReturnType[number]): any => {
  return {
    value: item?.nodeId,
    label: (
      <EntityRendererStyled>
        <div className="item-name">
          <div className="dmn-icon">{doomianIcon()}</div>
          <div className="root-parent-name" title={item?.rootParentName}>
            {item?.rootParentName}
          </div>
          <div className="right-icon">{rightAngleIcon}</div>{" "}
          <div className="cat-icon">{categoryIcon()}</div>
          <div className="parent-name" title={item?.parentName}>
            {item?.parentName}
          </div>
          <div className="right-icon">{rightAngleIcon}</div>{" "}
          <div className="trm-icon">
            <CoreNodesCircleIcon nodeType="TRM" />
          </div>
          <div className="node-name" title={item?.nodeName}>
            {item?.nodeName}
          </div>
        </div>
        <div className="item-description" title={item?.desc}>
          {item?.desc || "--"}
        </div>
      </EntityRendererStyled>
    ),
  };
};

export default entityRenderer;
