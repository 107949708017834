import { Spin } from "antd";

import { QueryBlocksLoadingSlateWrapperStyled } from "./queryblocksloadingstates.styles";

import InlineLoader from "../../../../../../../../components/inlineloader";

import { checkIsChatPage } from "../../../../../../../../utils/ischatpage";
import Flex from "../../../../../../../../components/flex/flex";
import { TimeCounter } from "../../../../../../../../components";

const QueryBlocksLoadingSlateWrapper = (props: {
  isLoading: boolean;
  children: JSX.Element;
  text?: string;
  isPaginatedResultsLoading?: boolean;
  qryCreatedOn?: string;
}): JSX.Element => {
  const isChatPage = checkIsChatPage();

  const {
    children,
    isLoading,
    text = "Generating Insights",
    isPaginatedResultsLoading,
    qryCreatedOn = "",
  } = props;

  return (
    <QueryBlocksLoadingSlateWrapperStyled
      isChatPage={isChatPage}
      className="query-blocks-loading-slate-main-wrapper"
      data-testid="analysis-detail-page-query-blocks-loading-slate-main-wrapper"
    >
      {isLoading && !isPaginatedResultsLoading && (
        <div
          className="inline-loader-wrapper"
          data-testid="analysis-detail-page-query-blocks-loading-slate-inline-loader-wrapper"
        >
          <div className="inline-loader-left-sec">
            <div>{text}</div>
            <InlineLoader />
          </div>

          {qryCreatedOn && (
            <TimeCounter stopCount={!isLoading} startingPoint={qryCreatedOn} />
          )}
        </div>
      )}
      {isPaginatedResultsLoading && isLoading && (
        <div
          className="inline-loader-wrapper paginated-results-wrapper"
          data-testid="analysis-detail-page-query-blocks-loading-slate-inline-loader-wrapper"
        >
          <Flex customClass="paginated-results">
            <Spin />
            <div>{text}</div>
          </Flex>
        </div>
      )}
      {!isLoading && children}
    </QueryBlocksLoadingSlateWrapperStyled>
  );
};

export default QueryBlocksLoadingSlateWrapper;
