import styled from "styled-components";
import LinkButton from "../../../linkbutton/linkbutton";

export const SubPanelWrapperStyled = styled.div<{ isSubPanelOpen?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 5px;
  width: ${({ isSubPanelOpen }): string => (isSubPanelOpen ? "50%" : "0")};
  border-left: 1px solid ${({ theme }): string => theme.borderColors.GREY_56};

  .sub-panel-heading {
    margin: 0 5px 5px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
  }
`;

export const SubPanelOptionWrapperStyled = styled.div`
  width: 100%;
`;

export const SubPanelOptionStyled = styled(LinkButton)<{ isSelected: boolean }>`
  font-size: 12px;
  padding: 5px 5px;
  text-align: left;
  width: 100%;

  color: ${({ theme, isSelected }): string =>
    isSelected ? theme.textColors.BLUE_116 : theme.textColors.GREY_40};

  background-color: ${({ theme, isSelected }): string =>
    isSelected ? theme.bgColors.LIGHT_BLUE_25 : ""};

  :first-child {
    padding-top: 0;
  }

  :active {
    color: ${({ theme }): string => theme.textColors.BLUE_116};
  }
  :hover {
    color: ${({ theme }): string => theme.textColors.BLUE_116};
    background-color: ${({ theme }): string => theme.bgColors.LIGHT_BLUE_25};
  }
`;
