import * as yup from "yup";
import {
  INVALID_FIRST_NAME,
  INVALID_LAST_NAME,
  INVALID_PASSWORD,
  NAME_REGEX,
  PASSWORD_REGEX,
  REQUIRED_MESSAGE,
} from "../../constants/formconstants";

export const registrationSchema = yup.object().shape({
  first_name: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .matches(NAME_REGEX, INVALID_FIRST_NAME),
  last_name: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .matches(NAME_REGEX, INVALID_LAST_NAME),
  company_name: yup.string().required(REQUIRED_MESSAGE),
  password: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .matches(PASSWORD_REGEX, INVALID_PASSWORD),
  accept_terms_and_conditions: yup.boolean().isTrue(REQUIRED_MESSAGE),
});
