import { useMemo } from "react";
import { useHistory } from "react-router";
// <--- Components Start --->
import {
  AnalysisDetailPageHeaderWithNoSelection,
  AnalysisdetailpageHeaderBulkSelection,
} from "./analysisdetailpageheader.views";
// <--- Components End --->

// <--- Custom Hooks Start --->
import { useGetAppState } from "../../../../customhooks";
// <--- Custom Hooks End --->

// <--- Styles Start --->
import { AnalysisDetailPageHeaderStyled } from "./analysisdetailpageheader.styles";
// <--- Styles End --->

// <--- Types Start --->
import {
  AnalysisDetailPageHeaderParamsState,
  AnalysisDetailPageHeaderProps,
} from "./analysisdetailpageheader.types";
import BreadCrum from "../../../../components/breadcrum";
import { APP_ROUTES } from "../../../../constants";
import { sourceDetailPageTabsValues } from "../../../sourcedetailpage/sourcedetailpage.constants";
import { DEFAULT_IS_FROM_PAGE } from "../../analysisdetalpage.constants";
// <--- Types End --->

const ANALYSIS_DETAIL_PAGE_HEADER_ID = "analysis_detail_page_header_id";

const AnalysisDetailPageHeader = (
  props: AnalysisDetailPageHeaderProps
): JSX.Element => {
  const { sideBarPosition } = useGetAppState();

  const { sourceId, analysisInfo } = props?.withOutSelectionProps;

  const history = useHistory();

  const SOURCE_BREAD_CRUM = [
    {
      label: "Data Sources",
      link: APP_ROUTES.private_routes.sources,
    },
    {
      label: analysisInfo?.datasourceUsed?.name || "Source Detail",
      link: `${APP_ROUTES.private_routes.sources}/${sourceId}/${sourceDetailPageTabsValues?.["chat-bots"]}`,
    },
    {
      label: "Chatbot",
    },
  ];

  const locationState = (history?.location?.state ||
    {}) as AnalysisDetailPageHeaderParamsState;

  const previousPage = locationState?.isFromPage || DEFAULT_IS_FROM_PAGE;
  const previousRoute = locationState?.isFromRoute;

  const breadCrumbBasisOnPreviousRoute = useMemo(() => {
    if (previousPage === "home" || previousPage === "search")
      return [
        ...((previousPage === "search" && [
          { label: "Search", link: previousRoute },
        ]) ||
          []),
        {
          label: "Chatbot",
        },
      ];

    return SOURCE_BREAD_CRUM;
  }, [SOURCE_BREAD_CRUM, previousPage, previousRoute]);

  return (
    <>
      <div id={ANALYSIS_DETAIL_PAGE_HEADER_ID} />

      <AnalysisDetailPageHeaderStyled sideBarPosition={sideBarPosition}>
        <BreadCrum currentPages={breadCrumbBasisOnPreviousRoute} />
        <div className="header-content-sec">
          {props?.isAnyQuerySelected ? (
            <AnalysisdetailpageHeaderBulkSelection />
          ) : (
            <AnalysisDetailPageHeaderWithNoSelection
              {...props?.withOutSelectionProps}
            />
          )}
        </div>
      </AnalysisDetailPageHeaderStyled>
    </>
  );
};

export default AnalysisDetailPageHeader;
