import { useCallback } from "react";
import LinkButton from "../../components/linkbutton";
import { useGetAppState, useOpenModal } from "../../customhooks";
import { useCancelModal } from "../../customhooks/useCancelModal";

import {
  crossIcon,
  editPencilIcon,
  secondaryDeleteIcon,
  singleArrowRight,
} from "../../svgs";

import { OverviewSuggestAnEditStyled } from "./overviewsuggestanedit.styles";

function OverviewSuggestAnEdit(props: {
  isRefPage?: boolean;
  onSelectOption?: (_option: "suggest" | "deprecate") => void;
}): JSX.Element {
  const { isRefPage = false, onSelectOption } = props;

  const onOpenModal = useOpenModal();
  const { modal } = useGetAppState();

  const onCancel = useCancelModal();

  const onEdit = useCallback(
    (id: "suggest" | "deprecate") => {
      if (onSelectOption) {
        onSelectOption(id);
      } else {
        onOpenModal({
          modalId:
            id === "suggest"
              ? "create_suggestion_form"
              : "mark_as_deprecate_suggestion",
          visible: true,
          modalTitle: !isRefPage
            ? id === "suggest"
              ? "Suggest an Edit"
              : "Suggest an Deprecate"
            : undefined,
          modalProps: { ...modal?.modalProps, isRefPage },
        });
      }
    },
    [modal]
  );

  return (
    <OverviewSuggestAnEditStyled isRefPage={isRefPage}>
      <div className="suggest-header">
        Suggest an Edit
        {!isRefPage && (
          <LinkButton onClick={onCancel}>
            {crossIcon("16px", "16px")}
          </LinkButton>
        )}
      </div>
      <div
        className="suggest-item-1"
        role="button"
        onClick={(): void => onEdit("suggest")}
      >
        <div className="inner-box">
          <div>{editPencilIcon("27", "27")}</div>
          <div className="suggest-item-1-right">
            <div className="suggest-item-1-right-title">Change Definition</div>
            <div className="suggest-item-1-right-desc">
              Edit Business Name, Definition or other details
            </div>
          </div>
        </div>
        {isRefPage && (
          <div className="suggestion-right-arrow">
            {singleArrowRight("11", "17")}
          </div>
        )}
      </div>
      <div
        className="suggest-item-2"
        role="button"
        onClick={(): void => onEdit("deprecate")}
      >
        <div className="inner-box">
          <div>{secondaryDeleteIcon()}</div>
          <div className="suggest-item-2-right">
            <div className="suggest-item-2-right-title">Remove</div>
            <div className="suggest-item-2-right-desc">
              Mark as Duplicate or Deprecated
            </div>
          </div>
        </div>
        {isRefPage && (
          <div className="suggestion-right-arrow">
            {singleArrowRight("11", "17")}
          </div>
        )}
      </div>
    </OverviewSuggestAnEditStyled>
  );
}

export default OverviewSuggestAnEdit;
