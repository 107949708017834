import { useState } from "react";

// <--- Components Start --->
import DQScore from "../../../../../../../dqscore";
import { DataQualityRuleDetailsBarChart } from "../../..";

import GenericProminentTag from "../../../../../../../genericprominenttag";
import IconWithSelectedState from "../../../../../../../iconselectedstate";

import SectionRow from "../../../../../../../sectionrow";
import SectionBlankSlateText from "../../../../../../../sectionblankslatetext";

import TableRecordCountHistoryChart from "../../../../../../../tablerecordcounthistorychart";
// <--- Components End --->

// <--- Styles Start --->
import { LinkCollapseStyled } from "../../dqruledetailsmodaldetailssec.styles";
// <--- Styles End --->

// <--- SVGs Start --->
import { antiClockIcon } from "../../../../../../../../svgs";
// <--- SVGs End --->

// <--- Types Start --->
import { DQRuleDetailsSummarySecProps } from "./dqruledetailssummarysec.types";
// <--- Types End --->

const { Panel } = LinkCollapseStyled;

const DQRuleDetailsSummarySec = (
  props: DQRuleDetailsSummarySecProps
): JSX.Element => {
  const {
    nodeId = "",
    dqScore,
    ruleAlert = false,
    ruleHistoryId = "",
    ruleTypeId,
    onNextClick,
    onPrevClick,
    parsedDataBarChart,
    isLoadingBarChart = false,
    errorBarChart,
  } = props;

  const [showChart, setShowChart] = useState(true);

  return (
    <>
      <SectionRow
        data-testid={`rule-type-detail-modal-details-sec-current-dq-score-${nodeId}`}
        title="Current DQ Score"
        customJSX={dqScore && <DQScore {...dqScore} />}
      />
      <SectionRow
        data-testid={`rule-type-detail-modal-details-sec-current-dq-score-${nodeId}`}
        title="Alert Type"
        customJSX={
          <>
            {ruleAlert ? (
              <GenericProminentTag
                type="RED"
                isBoldView
                tag={{ name: ruleAlert, type: "RED" }}
              />
            ) : (
              "--"
            )}
          </>
        }
      />
      <SectionRow
        data-testid={`rule-type-detail-modal-details-sec-dq-history-${nodeId}`}
        title="DQ History"
        customJSX={
          <LinkCollapseStyled activeKey={showChart ? ["chart"] : []}>
            <Panel
              key="chart"
              header={
                <>
                  <IconWithSelectedState
                    selected={showChart}
                    onClick={(): void => {
                      setShowChart(!showChart);
                    }}
                    tooltipProps={{
                      title: `${showChart ? "Hide" : "Show"} chart`,
                      placement: "right",
                    }}
                  >
                    {antiClockIcon()}
                  </IconWithSelectedState>
                </>
              }
              showArrow={false}
            >
              <div className="rule-history-chart-sec">
                {ruleTypeId === "TDD" ? (
                  <TableRecordCountHistoryChart
                    tableId={nodeId}
                    height="380px"
                    width="100%"
                  />
                ) : (
                  <SectionBlankSlateText isEmpty={!ruleHistoryId}>
                    <DataQualityRuleDetailsBarChart
                      data-testid={`rule-type-detail-modal-details-sec-data-quality-rule-details-bar-chart-${nodeId}`}
                      nodeId={nodeId}
                      ruleHistoryId={ruleHistoryId}
                      onPrevClick={onPrevClick}
                      onNextClick={onNextClick}
                      parsedData={parsedDataBarChart}
                      isLoading={isLoadingBarChart}
                      error={errorBarChart}
                    />
                  </SectionBlankSlateText>
                )}
              </div>
            </Panel>
          </LinkCollapseStyled>
        }
      />
    </>
  );
};

export default DQRuleDetailsSummarySec;
