import { useCallback, useEffect, useMemo, useState } from "react";
import { RadioChangeEvent } from "antd";

import RadioGroupButtons from "../radiobuttons";

import { PermissionSelectorStyled } from "./permissionselector.styles";
import { PermissionSelectorProps } from "./permissionselector.types";

const PermissionSelector = (props: PermissionSelectorProps): JSX.Element => {
  const {
    title = "",
    options = [],
    list = [],
    onChange,
    fineGrainSelectedValues,
    setfineGrainSelectedValues,
  } = props;

  const onChangeSelection = useCallback(
    (listItemValue: string) => (e: RadioChangeEvent): void => {
      const updatedValues = {
        ...fineGrainSelectedValues,
        [listItemValue]: e?.target?.value,
      };

      setfineGrainSelectedValues(updatedValues);
      onChange(updatedValues);
    },
    [fineGrainSelectedValues, onChange]
  );

  const radioOptions = useMemo(
    () =>
      options?.map((option) => ({
        label: "",
        value: option?.value,
      })),
    [options]
  );

  return (
    <PermissionSelectorStyled className="permission-selector">
      <div className="header">
        <div className="title">{title}</div>
        <div className="options">
          {options?.map((option) => (
            <div className="option" key={option?.value}>
              {option?.name}
            </div>
          ))}
        </div>
      </div>

      <div className="content">
        {list?.map((listItem) => (
          <div className="item" key={listItem?.value}>
            <div className="name">{listItem?.name}</div>
            <RadioGroupButtons
              onChange={onChangeSelection?.(listItem?.value)}
              options={radioOptions}
              value={fineGrainSelectedValues[listItem?.value]}
              direction="row"
            />
          </div>
        ))}
      </div>
    </PermissionSelectorStyled>
  );
};

export default PermissionSelector;
