import { useCallback, useEffect, useMemo, useState } from "react";

import { Input } from "../inputs";
import LinkButton from "../linkbutton";
import { SelectDropdown } from "../selectdropdowns";

import {
  doubleArrowLeft,
  doubleArrowRight,
  singleArrowLeft,
  singleArrowRight,
} from "../../svgs";

import { AgGridPaginationStyled } from "../aggridtable/aggridpagination/aggridpagination.styles";

import { CustomPaginationProps } from "./custompagination.types";
import { numberFormatter } from "../../utils";

const CustomPagination = (props: CustomPaginationProps): JSX.Element => {
  const {
    currentPage = 1,
    pageSize = 10,
    totalCount,
    onChangePagination,
  } = props;
  const [changePage, setChangePage] = useState(currentPage);

  const totalPages = Math.ceil(totalCount / pageSize);

  const onPageSizeChanged = useCallback(
    (pageSize) => {
      onChangePagination?.({
        page_size: Number(pageSize),
        current_page: 1,
      });
    },
    [props]
  );

  const onChangePage = useCallback(
    (page) => {
      if (!Number.isNaN(Number(page))) {
        setChangePage(Number(page));
      } else {
        setChangePage(currentPage);
      }
    },
    [props, changePage]
  );

  const onEnter = useCallback((): void => {
    const isValidPage = changePage < totalPages && changePage > 0;

    onChangePagination?.({
      page_size: pageSize,
      current_page: isValidPage
        ? changePage
        : changePage === 0
        ? 1
        : totalPages,
    });

    setChangePage(isValidPage ? changePage : changePage === 0 ? 1 : totalPages);
  }, [props, changePage]);

  const onPageNavBtnHandler = useCallback(
    (pageNavType) => {
      switch (pageNavType) {
        case "first":
          onChangePagination({ page_size: pageSize, current_page: 1 });
          break;
        case "last":
          onChangePagination?.({
            page_size: pageSize,
            current_page: totalPages,
          });
          break;
        case "next":
          onChangePagination?.({
            page_size: pageSize,
            current_page: currentPage + 1,
          });
          break;
        case "prev":
          onChangePagination?.({
            page_size: pageSize,
            current_page: currentPage - 1,
          });
          break;
        default:
          break;
      }
    },
    [props, totalPages]
  );

  const startsFrom = useMemo(() => {
    if (currentPage === 1 && !totalCount) {
      return 0;
    }

    return (currentPage - 1) * pageSize + 1;
  }, [currentPage, pageSize, totalCount]);

  const isFirstPage = useMemo(() => {
    return currentPage <= 1;
  }, [currentPage]);

  const isLastPage = useMemo(() => {
    return currentPage >= totalPages;
  }, [currentPage, totalPages]);

  const endFrom = useMemo(() => {
    if (currentPage === 1 && !totalCount) {
      return 0;
    }

    const endLimit = startsFrom + (pageSize - 1);
    return endLimit > totalCount ? totalCount : endLimit;
  }, [pageSize, currentPage, startsFrom, totalCount]);

  useEffect(() => {
    setChangePage(totalCount ? currentPage : 0);
  }, [currentPage, totalCount]);

  return (
    <AgGridPaginationStyled className="ag-pagination-panel">
      <div className="ag-paging-row-summary-panel" aria-hidden="true">
        <div>View</div>
        <div className="ag-paging-row-summary-panel-number">{startsFrom}</div>
        <div>-</div>
        <div className="ag-paging-row-summary-panel-number">{endFrom}</div>
        <div>of</div>
        <div className="ag-paging-row-summary-panel-number">
          {numberFormatter(`${totalCount}`)}
        </div>
      </div>
      <div className="ag-paging-page-summary-panel">
        <LinkButton
          aria-label="First Page"
          className={`ag-paging-button first-btn${
            isFirstPage ? " ag-disabled" : ""
          }`}
          onClick={(): void => onPageNavBtnHandler("first")}
        >
          {doubleArrowLeft("11px", "12px")}
        </LinkButton>
        <LinkButton
          className={`ag-paging-button prev-btn${
            isFirstPage ? " ag-disabled" : ""
          }`}
          aria-label="Previous Page"
          onClick={(): void => onPageNavBtnHandler("prev")}
        >
          {singleArrowLeft("7px", "12px")}
        </LinkButton>

        <div className="paging-divider" />

        <div className="ag-paging-description" aria-hidden="true">
          <div>Page</div>
          <div className="ag-paging-number">
            <Input
              type="text"
              value={changePage}
              onChange={(e): void => onChangePage(e?.target?.value)}
              onPressEnter={onEnter}
            />
          </div>
          <div>of</div>
          <div>{numberFormatter(`${totalPages}`)}</div>
        </div>

        <div className="paging-divider" />
        <LinkButton
          className={`ag-paging-button next-btn${
            isLastPage ? " ag-disabled" : ""
          }`}
          aria-label="Next Page"
          onClick={(): void => onPageNavBtnHandler("next")}
        >
          {singleArrowRight("7px", "12px")}
        </LinkButton>
        <LinkButton
          className={`ag-paging-button last-btn${
            isLastPage ? " ag-disabled" : ""
          }`}
          aria-label="Last Page"
          onClick={(): void => onPageNavBtnHandler("last")}
        >
          {doubleArrowRight("11px", "12px")}
        </LinkButton>

        <div className="ag-paging-number">
          <SelectDropdown
            value={pageSize}
            onChange={onPageSizeChanged}
            height="25px"
            options={["5", "10", "20", "50"].map((op: string): any => ({
              label: op,
              value: op,
            }))}
          />
        </div>
      </div>
    </AgGridPaginationStyled>
  );
};

export default CustomPagination;
