import { useCallback } from "react";

import LinkButton from "../../../linkbutton";

import { useOpenModal } from "../../../../customhooks";

import { AddEntityButtonProps } from "./addentitybutton.types";
import { ReviewRecEntityFormProps } from "../../../../forms/reviewrecomendedentitymodal";

import { AddEntityButtonStyled } from "./addentitybutton.styles";

const AddEntityButton = (props: AddEntityButtonProps): JSX.Element => {
  const { recEntityModalProps, onUnSelectRow } = props;

  const openModal = useOpenModal();

  const onAdd = useCallback(
    (e) => {
      e?.stopPropagation();
      onUnSelectRow && onUnSelectRow();
      const isUnlinkedRecord = !!recEntityModalProps?.matchingEntities?.length;

      openModal({
        modalId: isUnlinkedRecord
          ? "review_recomended_entity_modal"
          : "add_recomended_entity_modal",
        visible: true,
        modalTitle: isUnlinkedRecord ? "Review Recommended Term" : "Add Entity",
        modalProps: recEntityModalProps as ReviewRecEntityFormProps,
      });
    },
    [recEntityModalProps]
  );

  return (
    <AddEntityButtonStyled>
      <LinkButton onClick={onAdd}>
        <span className="plus-icon">+</span> Add
      </LinkButton>
    </AddEntityButtonStyled>
  );
};

export default AddEntityButton;
