import { NodeType } from "../app.types";
import { API_CONFIG } from "../constants/apiconfig";

import { APIKeys } from "../constants/constant.types";
import { useGetAll } from "./baseservice";

import { CustomReactQueryHookReturnType } from "./services.types";

export function useGetDropdownOptios(
  key: APIKeys,
  params: string[] = [],
  searchText: string,
  onlyAdminAndEditorRole?: boolean,
  nodeType?: NodeType
): CustomReactQueryHookReturnType {
  const apiConfig = API_CONFIG[key];
  return useGetAll({
    apiConfig,
    params: onlyAdminAndEditorRole
      ? [nodeType || "true", ...params, searchText]
      : ["", ...params, searchText],
    options: { enabled: false },
  });
}
