// <--- Library Imports Start --->
import { Tooltip } from "antd";
import { useState } from "react";
// <--- Library Imports End --->

// <--- Components Start --->
import LinkButton from "../linkbutton";
// <--- Components End --->

// <--- Styles Start --->
import {
  TooltipGlobalStyle,
  TableActionsSecStyled,
} from "./tableactionssec.styles";
// <--- Styles End --->

// <--- Types Start --->
import { TableActionsSecProps } from "./tableactionssec.types";
// <--- Types End --->

function TableActionsSec(props: TableActionsSecProps): JSX.Element {
  const [visible, setVisible] = useState(false);

  const {
    buttonTitle,
    data = [],
    hideDots = false,
    children,
    itemClassName = "",
    isEnableGetPopUpCont,
    isSvgNoHighlight = false,
    id = "",
  } = props;

  return (
    <TableActionsSecStyled isVisible={visible} className="table-actions-sec">
      <TooltipGlobalStyle isSvgNoHighlight={isSvgNoHighlight} />
      <Tooltip
        title={
          <div className="tooltip-custom-content">
            {data?.map((item) => (
              <LinkButton
                role="button"
                onClick={(e): void => {
                  item?.onClick && item.onClick(e);
                  setVisible(false);
                }}
                className={`item ${itemClassName}`}
                disabled={item?.disabled}
                key={`tableactionssec-${item?.name}`}
                elementId={item?.id}
              >
                {item?.icon && item?.icon} {item?.name}
              </LinkButton>
            ))}
          </div>
        }
        trigger={["click"]}
        placement="bottomRight"
        onVisibleChange={(visiblility): void => setVisible(visiblility)}
        visible={visible}
        overlayClassName="tables-action-section-tooltip"
        {...(isEnableGetPopUpCont && {
          getPopupContainer: (trigger): HTMLElement =>
            trigger?.parentNode as HTMLElement,
        })}
      >
        <div className="actions-container" title={buttonTitle} id={id}>
          {!hideDots && "..."}
          {children}
        </div>
      </Tooltip>
    </TableActionsSecStyled>
  );
}

export default TableActionsSec;
