import { APP_ROUTES } from "../constants";

import {
  JobDetailPageTabType,
  jobDetailPageTabsValues,
} from "../pages/jobdetailpage/jobdetailpage.types";
import { jobScheduleDetailPageTabsValues } from "../pages/jobscheduledetailpage/jobscheduledetailpage.types";

export const getJobDetailPageUrl = (
  id: string,
  tab?: JobDetailPageTabType
): string => {
  const newTab = tab || jobDetailPageTabsValues?.details;

  return `${APP_ROUTES.private_routes.job_detail}/${id}/${newTab}`;
};

export const getJobScheduledDetailPageUrl = (
  jobId: string,
  scheduleId: string,
  tab?: string
): string => {
  const newTab = tab || jobScheduleDetailPageTabsValues?.summary;

  return `${APP_ROUTES.private_routes.job_detail}/${jobId}/details/${scheduleId}/${newTab}`;
};
