import { Card } from "antd";
import { queryResultsGridHumanReadableFormating } from "../../../pages/analysisdetailpage/analysisdetailpage.views/analysisdetailpagecontentsec/analysisdetailpagecontentsec.views/queryblocklisting/queryblocklisting.comp/queryblock/queryblock.comp/queryresults/queryresults.utils";

import { BoxStyled, TileViewStyled } from "./tileview.styles";
import { TileViewProps } from "./tileview.types";

const TileView = (props: TileViewProps): JSX.Element => {
  const {
    labelText = "",
    count = 0,
    prefix = "",
    dataType,
    isDataFormatted = false,
    suffix = "",
    isBorderedChart = true,
    width,
    height,
  } = props;
  return (
    <Card>
      <TileViewStyled height={height}>
        <BoxStyled isBordered={isBorderedChart} width={width} height={height}>
          <p className="count-text">
            {prefix}
            {queryResultsGridHumanReadableFormating({
              number: count || 0,
              isDataFormatted,
              dataType,
            })}
            {suffix}
          </p>
          <p className="caption-text">{labelText}</p>
        </BoxStyled>
      </TileViewStyled>
    </Card>
  );
};

export default TileView;
