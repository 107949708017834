import styled from "styled-components";

export const ConfirmationMessageStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  .action-text {
    margin: 0;
  }

  .actions-wrapper {
    display: flex;
    gap: 5px;
    align-items: center;
    line-height: 15px;

    svg {
      width: 25px;
      height: 24px;
    }
  }
`;
