// <--- Components Start --->
import StaticTextRendrer from "../../statictextrendrer";
// <--- Components End --->

// <--- Styles Start --->
import {
  CardContentStyled,
  CardHeadingStyled,
  CardWrapperStyled,
  CardLinkButtonStyled,
  CardTextWrapperStyled,
  CardContentWrapperStyled,
} from "../dqruleactionstatus.styles";
// <--- Styles End --->

// <--- SVGs Start --->
import { notesIcon, TickInFilledRectangleIcon } from "../../../svgs";
// <--- SVGs End --->

// <--- Types Start --->
import { DQRuleActionStatusProps } from "../dqruleactionstatus.types";
// <--- Types End --->

// <--- Utils Start --->
import { dqRuleActionStatusCardIconMapping } from "../../modalwrapper/modals/dqruledetailsmodal/dqruledetailsmodal.components/dqruleactions/dqruleactions.utils";
// <--- Utils End --->

const DQRuleActionStatusCard = (
  props: DQRuleActionStatusProps
): JSX.Element => {
  const { ruleActionDetails = [] } = props;

  return (
    <CardWrapperStyled data-testid="dq-rule-action-status-card-component">
      <CardHeadingStyled>Action Details</CardHeadingStyled>

      <CardContentWrapperStyled data-testid="dq-rule-action-status-card-content">
        {ruleActionDetails?.length ? (
          ruleActionDetails?.map((action) => (
            <div
              key={`dq-rule-action-details-${action?.time}-${action?.id}`}
              data-testid={`dq-rule-action-details-${action?.time}-${action?.id}`}
            >
              <CardContentStyled
                title={`${action?.title} ${action?.action_by} on ${action?.time}`}
                data-testid={`dq-rule-action-details-heading-${action?.time}-${action?.id}`}
              >
                {dqRuleActionStatusCardIconMapping(action?.id) || <div />}
                <CardTextWrapperStyled>
                  {`${action?.title} `}
                  <CardLinkButtonStyled>
                    {action?.action_by?.split("(")[0]}
                  </CardLinkButtonStyled>{" "}
                  on{" "}
                  <span
                    data-testid={`dq-rule-action-details-time-${action?.time}-${action?.id}`}
                  >
                    {" "}
                    {action?.time}
                  </span>
                </CardTextWrapperStyled>
              </CardContentStyled>

              {action?.resolution_action && (
                <CardContentStyled className="list-item">
                  {TickInFilledRectangleIcon()}
                  <CardTextWrapperStyled
                    title={`Resolution action is ${action?.resolution_action}`}
                  >
                    Resolution action is{" "}
                    <CardLinkButtonStyled
                      data-testid={`dq-rule-action-details-resolution-action-${action?.time}-${action?.id}`}
                    >
                      {action?.resolution_action}
                    </CardLinkButtonStyled>
                  </CardTextWrapperStyled>
                </CardContentStyled>
              )}

              {action?.id !== "OPN" && (
                <CardContentStyled
                  className="list-item"
                  data-testid={`dq-rule-action-details-comment-${action?.time}-${action?.id}`}
                >
                  {notesIcon()}
                  <CardTextWrapperStyled
                    title={action?.comment}
                    data-testid={`dq-rule-action-details-resolution-action-${action?.time}-${action?.id}`}
                  >
                    {!action?.comment ? (
                      <StaticTextRendrer text="No comment added" />
                    ) : (
                      action?.comment
                    )}
                  </CardTextWrapperStyled>
                </CardContentStyled>
              )}
            </div>
          ))
        ) : (
          <StaticTextRendrer
            text="No details exists"
            data-testid="dq-rule-action-details-blank-state"
          />
        )}
      </CardContentWrapperStyled>
    </CardWrapperStyled>
  );
};

export default DQRuleActionStatusCard;
