import styled from "styled-components";

export const QueryResultsStyled = styled.div<{
  isChatPage: boolean;
  isMaximizeQueryBlock: boolean;
  isChartVisible: boolean;
  isQuerySetingsVisible: boolean;
  isProfiled?: boolean;
  isMaximizedQuery?: boolean;
  isSavedContextsSecVisible: boolean;
  isFocused: boolean;
  errorCase: boolean;
  isExpanded: boolean;
  isConceptQuery: boolean;
  isAuthor: boolean;
  isViewOnlyChatQuestionPage: boolean;
}>`
  position: relative;
  display: ${({ isChatPage }): string => (isChatPage ? "flex" : "")};
  flex-direction: ${({ isChatPage }): string => (isChatPage ? "column" : "")};
  align-items: ${({ isChatPage, isMaximizeQueryBlock }): string =>
    isChatPage && isMaximizeQueryBlock ? "start" : "center"};
  justify-content: center;

  .query-results-collapse {
    padding: ${({ isChatPage }): string =>
      isChatPage ? "12px 0" : "12px 17px 0px 15.2px"};
    ${({ isMaximizeQueryBlock, isChatPage }): string =>
      isMaximizeQueryBlock && !isChatPage ? "padding-right:34px" : ""};
    display: flex;
    width: 100%;
    margin-bottom: ${({ isChatPage }): string => (isChatPage ? "0" : "10px")};

    .query-results-collapse-header {
      display: flex;
      align-items: flex-start;
      margin-left: ${({
        isChatPage,
        isMaximizeQueryBlock,
        isProfiled,
        isQuerySetingsVisible,
        isViewOnlyChatQuestionPage,
      }): string =>
        isViewOnlyChatQuestionPage
          ? "18px"
          : isChatPage && !isMaximizeQueryBlock
          ? "11rem"
          : isChatPage &&
            isMaximizeQueryBlock &&
            (isProfiled || isQuerySetingsVisible)
          ? "2rem"
          : isChatPage &&
            isMaximizeQueryBlock &&
            (!isProfiled || !isQuerySetingsVisible)
          ? "11rem"
          : "1rem"};

      max-width: ${({
        isChatPage,
        isProfiled,
        isQuerySetingsVisible,
        isMaximizeQueryBlock,
        isSavedContextsSecVisible,
        isViewOnlyChatQuestionPage,
      }): string =>
        isViewOnlyChatQuestionPage
          ? "97%"
          : (isProfiled ||
              isQuerySetingsVisible ||
              isSavedContextsSecVisible) &&
            isChatPage &&
            !isMaximizeQueryBlock
          ? "80%"
          : (isProfiled || isQuerySetingsVisible) &&
            isChatPage &&
            isMaximizeQueryBlock
          ? "100%"
          : (!isProfiled || !isQuerySetingsVisible) &&
            isChatPage &&
            isMaximizeQueryBlock
          ? "78%"
          : (!isProfiled || !isQuerySetingsVisible) && isChatPage
          ? "70%"
          : "100%"};

      gap: 0.7rem;
      width: 100%;

      .title {
        color: ${({ theme }): string => theme?.textColors?.BLACK_COLOR};
      }

      .circle {
        width: 6px;
        height: 6px;
        background: ${({ theme }): string => theme?.bgColors?.LIGHT_GREY_10};
        border-radius: 50%;
        margin: 0 9px;
      }

      .rows-count,
      .last-refreshed {
        font-size: 12px;
        color: ${({ theme }): string => theme?.textColors?.GREY_24};
      }

      .error-state-msg {
        height: 100%;
        display: flex;
        align-items: center;
        flex: 1;
        padding-top: 5px;
        line-height: 30px;
      }

      .query-results-flex {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
      }

      .query-results-error-container {
        width: 100%;
      }
    }

    .query-blocks-loading-slate-main-wrapper {
      max-width: ${({
        isChatPage,
        isQuerySetingsVisible,
        isChartVisible,
      }): string =>
        !isChatPage && isChartVisible && isQuerySetingsVisible
          ? "97%"
          : "100%"};
    }

    .query-results-collapse-icon {
      svg {
        margin-top: 3px;
        margin-right: 4px;
        cursor: pointer;
      }
    }
  }

  .queryresult-header-section {
    width: 100%;
    position: relative;

    .multi-results-sec-cont-border {
      border: ${({ isChatPage, theme }): string =>
        isChatPage ? " " : `1px solid ${theme.borderColors.LIGHT_GREY_51}`};
    }

    .workflow-msg-sec {
      .label {
        font-family: OpenSansSemiBold;
        color: ${({ theme }): string => theme?.textColors?.BLUE_116};
        margin-right: 5px;
        margin-top: 6px;
        font-size: 20px;
        margin-bottom: 15px;
      }

      .spacing-after-workflow-mesage {
        margin-bottom: 20px;
      }
    }

    .results-action-wrapper {
      display: flex;
      justify-content: space-between;
      .query-chart-header {
        display: flex;
        align-items: center;
        height: 40px;
        font-size: 16px;
      }
    }

    .msg-box {
      padding: 12.8px;
      margin: 17.6px 0;

      .error-type {
        font-weight: bold;
      }

      &.query-block-error-box {
        background: ${({ theme }): string => theme?.bgColors?.RED_101};
        color: ${({ theme }): string => theme?.textColors?.RED_100};
      }

      &.no-records-box {
        background: ${({ theme }): string => theme?.bgColors?.LIGHT_GREY_22};
        color: ${({ theme }): string => theme?.textColors?.GREY_24};
      }
    }

    .ag-root-wrapper {
      .ag-root-wrapper-body {
        .ag-body-viewport {
          height: 400px;
        }
      }
    }

    .query-results-bottom-sec {
      display: flex;
      justify-content: space-between;
      align-items: self-start;
      margin: ${({
        isChatPage,
        errorCase,
        isFocused,
        isConceptQuery,
      }): string =>
        !isChatPage && !isFocused
          ? "10px 0 0"
          : isChatPage || errorCase || isConceptQuery
          ? "19.2px 0 10px"
          : "36px 0 10px"};
      margin-top: ${({ errorCase, isExpanded }): string =>
        errorCase || !isExpanded ? "12px" : ""};

      .created-at {
        line-height: 32.8px;
        font-size: 12px;
        color: ${({ theme }): string => theme?.textColors?.GREY_210};
      }
    }

    .caddi-feedback-form {
      background: ${({ theme }): string => theme?.textColors?.WHITE_COLOR};
      border-radius: 4.8px;
      box-shadow: 0px 0px 4px 0px #00000026;
      position: relative;

      .arrow {
        position: absolute;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid
          ${({ theme }): string => theme?.textColors?.WHITE_COLOR};
        top: -7px;
        left: ${({ isChatPage, isAuthor }): string =>
          isChatPage && !isAuthor ? "6px" : "45px"};
      }
    }
  }

  .notification-div {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 9px 20px;
    margin: ${({
      isChatPage,
      isProfiled,
      isQuerySetingsVisible,
      isMaximizeQueryBlock,
    }): string =>
      isChatPage
        ? (isProfiled || isQuerySetingsVisible) && !isMaximizeQueryBlock
          ? "0.5rem 0 0 9.5rem"
          : (isProfiled || isQuerySetingsVisible) && isMaximizeQueryBlock
          ? "0.5rem 0 0 2.2rem"
          : (!isProfiled || !isQuerySetingsVisible) && !isMaximizeQueryBlock
          ? "0.5rem 3rem 0 0"
          : "0.5rem 3.3rem 0 11rem"
        : "0.5rem 1.2rem 0 2rem"};

    width: ${({
      isChatPage,
      isProfiled,
      isQuerySetingsVisible,
      isMaximizeQueryBlock,
    }): string =>
      isChatPage
        ? (isProfiled || isQuerySetingsVisible) && !isMaximizeQueryBlock
          ? "80%"
          : (isProfiled || isQuerySetingsVisible) && isMaximizeQueryBlock
          ? "91%"
          : isMaximizeQueryBlock && (!isProfiled || !isQuerySetingsVisible)
          ? "75%"
          : "70%"
        : ""};

    border: solid 1px ${({ theme }): string => theme.borderColors.BLUE_150};
    background-color: ${({ theme }): string => theme.bgColors.LIGHT_BLUE_25};

    .icon {
      line-height: 15px;
    }

    .text {
      color: ${({ theme }): string => theme.bgColors.GREY_40};
      font-size: 14px;
    }
  }

  .loader-wrapper {
    scroll-behavior: smooth;
  }

  .inline-loader-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .inline-loader-left-sec {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      line-height: 34px;
      gap: 10px;
      height: 40px;

      .inline-loader {
        margin-top: 0;
      }
    }
  }

  .concept-query {
    min-height: 40px;
    display: flex;
    align-items: center;
  }

  .query-retry-overlay {
    position: absolute;
    width: ${({ isChatPage }): string =>
      isChatPage ? "100%" : "calc(100% - 84px)"};
    max-width: ${({ isChatPage }): string => (isChatPage ? "67.5%" : "100%")};
    height: 100%;
    background: rgba(255, 255, 255, 0.94);
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    left: ${({ isChatPage }): string => (isChatPage ? "14.2rem" : "82px")};
    color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
    font-size: 15px;
  }
`;

export const LoaderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

export const QueryActionAndSettingsWrapperStyled = styled.div<{
  isChartView?: boolean;
}>`
  position: absolute;
  display: flex;
  align-items: center;
  column-gap: 10px;
  right: ${({ isChartView }): string => (isChartView ? "8px" : "8px")};
  top: -43px;

  .chart-actions-wrapper {
    display: flex;
    align-items: center;
  }
  .chart-settings {
    height: 18px;
    svg {
      path {
        fill: ${({ theme }): string => theme?.textColors?.BLUE_116};
      }
    }
  }
`;

export const QueryFiltersWrapperStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const QueryResultsAndActionWrapperStyled = styled.div<{
  isChatPage?: boolean;
  showBorder?: boolean;
}>`
  padding: 50px 18px 15px;
  background-color: ${({ isChatPage, theme }): string =>
    isChatPage ? theme.borderColors.WHITE_COLOR : ""};

  border: ${({ isChatPage, theme, showBorder }): string =>
    !showBorder
      ? ""
      : isChatPage
      ? " "
      : `1px solid ${theme.borderColors.LIGHT_GREY_51}`};
  position: relative;

  .query-view-actions-filter-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .results-keyword {
      position: absolute;
      top: 10px;
      left: 20px;
      font-family: "OpenSansSemiBold";
      font-size: 16px;
      margin-bottom: 10px;
    }
  }

  .bottom-spacing {
    margin-bottom: 20px;
  }
`;

export const QueryResultsFiltersStyled = styled.div<{ isChatPage?: boolean }>`
  padding-left: ${({ isChatPage }): string => (isChatPage ? "20px" : "0")};
`;

export const QueryExplanationStyled = styled.div<{ isChatPage: boolean }>`
  padding: 12px 18px;
  border: 1px solid ${({ theme }): string => theme?.borderColors?.LIGHT_GREY_51};
  margin: 16px 0;
  background: ${({ theme, isChatPage }): string =>
    isChatPage ? theme?.bgColors?.WHITE_COLOR : ""};

  .sec-head {
    color: ${({ theme }): string => theme?.textColors?.BLACK_COLOR};
    font-family: OpenSansSemiBold;
    margin-bottom: 12px;
    font-size: 16px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .sec-heading {
      font-size: 17px;
    }
  }

  .query-expl-sec {
    .query-expl-item-wrapper {
      margin-bottom: 7px;
    }

    .query-expl-title {
      color: ${({ theme }): string => theme?.textColors?.BLACK_COLOR};
      font-family: OpenSansSemiBold;
      padding-right: 5px;
    }

    .query-expl-item-wrapper:last-child {
      margin: 0;
    }

    ul {
      margin: 8px 0 8px 30px;
      padding: 0;

      .tbl-name {
        color: ${({ theme }): string => theme?.textColors?.BLUE_116};
        cursor: pointer;
        width: fit-content;
      }
    }
  }
`;
