// <--- Library Imports Start --->
import { useCallback, useMemo } from "react";
// <--- Library Imports End --->

// <--- Components Start --->
import LinkButton from "../../../../../../../../../../components/linkbutton/linkbutton";
// <--- Components End --->

// <--- Styles Start --->
import {
  ChartTabsSectionStyled,
  QueryBlockSettingsStyled,
  TabStyled,
} from "./queryblocksettings.styles";
// <--- Styles End --->

// <--- SVGs Start --->
import { thinCrossIcon } from "../../../../../../../../../../svgs";
// <--- SVGs End --->

import { checkIsChatPage } from "../../../../../../../../../../utils/ischatpage";

import {
  QueryChartSettings,
  QueryFieldSettings,
  QueryGeneralSettings,
} from "./views";

import { QueryBlockSettingsProps } from "./queryblocksettings.types";
import { QuerySettingsTabType } from "../../../../../../../../analysisdetailpage.types";

import { filterQueryFiltersWhereFilterModelsExists } from "../../../../../../../../analisisdetailpage.utils";
// <--- Utils End --->

const TABS_LIST = [
  { title: "General", id: "general" },
  { title: "Chart", id: "chart" },
  { title: "Field", id: "field" },
];

const QueryBlockSettings = (props: QueryBlockSettingsProps): JSX.Element => {
  const isChatPage = checkIsChatPage();

  const { queryItem, handlers, activeTab } = props;

  const {
    updateQueryChartData,
    onChangeQuerySettings,
    onResetChartSettingsClick,
  } = handlers || {};

  const { header, id = "", settings, results, filters } = queryItem || {};

  const { chart_config: chartConfig = {}, columns } = results || {};

  const { is_data_formatted: isDataFormatted } = settings || {};

  const {
    is_maximize_query_block: isMaximizeQueryBlock = false,
    result_type: resultType = "chart",
  } = header || {};

  const isChartVisible = resultType === "chart";

  const { chart_type: chartType, is_default_settings: isDefaultSettings } =
    chartConfig || {};

  const isChartExists = chartType && chartType !== "N/A";

  const onChangeActiveTab = useCallback(
    (selectedTab: QuerySettingsTabType) => (): void => {
      onChangeQuerySettings?.({ id, tab: selectedTab });
    },
    [id, onChangeQuerySettings]
  );

  const onCancelSettings = useCallback((): void => {
    onChangeQuerySettings?.({ id, type: "settings_toggle" });
  }, [id, onChangeQuerySettings]);

  const onResetChartSettings = useCallback((): void => {
    onResetChartSettingsClick(id);
  }, [id, onResetChartSettingsClick]);

  const selectedColumn = useMemo(() => {
    const findSelectColumn =
      columns?.filter((column) => column?.is_selected) || [];
    return findSelectColumn?.[0];
  }, [columns]);

  const isAnyfilterApplied = useMemo(() => {
    return !!filterQueryFiltersWhereFilterModelsExists(
      filters?.transiant_filters
    )?.length;
  }, [filters?.transiant_filters]);

  const tabContent = (): JSX.Element => {
    switch (activeTab) {
      case "general":
        return (
          <QueryGeneralSettings
            onChangeQuerySettings={onChangeQuerySettings}
            isDataFormatted={isDataFormatted}
            id={id}
          />
        );
      case "chart":
        return (
          <QueryChartSettings
            disabled={!chartConfig}
            id={id}
            isChatPage={isChatPage}
            isMaximizeQueryBlock={isMaximizeQueryBlock}
            chartConfig={chartConfig}
            updateQueryChartData={updateQueryChartData}
            onChangeQuerySettings={onChangeQuerySettings}
            queryColumns={queryItem?.results?.columns}
            queryFilterSortModel={filters?.sortModel}
            isChartVisible={isChartVisible}
          />
        );
      case "field":
        return (
          <QueryFieldSettings
            key={selectedColumn?.column_id}
            queryItem={queryItem}
            handlers={handlers}
            selectedColumn={selectedColumn}
          />
        );
      default:
        return <div />;
    }
  };

  return (
    <QueryBlockSettingsStyled
      isChatPage={isChatPage}
      isMaximized={isMaximizeQueryBlock}
    >
      <div className="chart-settings-header">
        <div className="header-left-sec">
          <div className="heading"> Settings</div>
        </div>
        <div className="header-right-sec">
          <LinkButton onClick={onCancelSettings}>
            {thinCrossIcon("10", "10")}
          </LinkButton>
        </div>
      </div>
      <ChartTabsSectionStyled>
        <div className="tabs-list">
          {TABS_LIST?.map((tab) => {
            const isTabDataNotExist = !isChartExists && tab?.id === "chart";
            return (
              <TabStyled
                className="tab-btn"
                key={tab?.id}
                onClick={onChangeActiveTab(tab?.id as QuerySettingsTabType)}
                isSelected={activeTab === tab?.id}
                isTabDataNotExist={isTabDataNotExist}
                tooltipProps={{
                  title: isTabDataNotExist
                    ? "No chart setting exists"
                    : undefined,
                }}
                disabled={isTabDataNotExist}
                data-testid={`query-block-field-settings-${tab?.id}-tab${
                  activeTab === tab?.id ? "-selected" : ""
                }`}
              >
                {tab?.title}
              </TabStyled>
            );
          })}
        </div>

        {activeTab === "chart" && (!isDefaultSettings || isAnyfilterApplied) && (
          <LinkButton className="reset-btn" onClick={onResetChartSettings}>
            Reset to default
          </LinkButton>
        )}
      </ChartTabsSectionStyled>

      {tabContent()}
    </QueryBlockSettingsStyled>
  );
};

export default QueryBlockSettings;
