//       <--Components Start-->
import BoldText from "../../../../../boldtext";
import GenericProminentTag from "../../../../../genericprominenttag";

import TagList from "../../../../../tags/taglist/taglist";
//       <--Components Start-->

//       <--SVGS Start-->
import {
  driftDirectionBothIcon,
  driftDirectionDownIcon,
  driftDirectionUpIcon,
} from "../../../../../../svgs";
//       <--SVGS Start-->

//       <--Types Start-->
import { SectionRowProps } from "../../../../../sectionrow/sectionrow.types";
import { DQAlertDriftDir } from "../../../../../../parsers/dataqualityparser/dataqualityparser.types";
//       <--Types End-->

export const ruleTypeDetailModalRenderers: {
  [key: string]: (_item: SectionRowProps) => JSX.Element;
} = {
  "Drift Calculation": (item) => (
    <BoldText text={item?.value || ""} fontSize="14px" isSemiBold />
  ),
  "Drift Direction": (item) => {
    const dir = item?.value as DQAlertDriftDir;
    return dir === "BID" ? (
      driftDirectionBothIcon
    ) : dir === "UP" ? (
      driftDirectionUpIcon
    ) : dir === "DWN" ? (
      driftDirectionDownIcon
    ) : (
      <div />
    );
  },
  Categories: (item) => (
    <TagList tags={item?.tags || []} type="secondory" tagstoShowUpfront={5} />
  ),
  "DQ Alert": (item) => (
    <GenericProminentTag
      type="RED"
      tag={{ name: item?.value || "", type: "RED" }}
    />
  ),
  "Drift Alert": (item) => (
    <GenericProminentTag
      type="RED"
      tag={{ name: item?.value || "", type: "RED" }}
    />
  ),
};
