import { useMemo } from "react";

import { SummarizeSecProps } from "./summarizesec.types";
import { parseStringToHtml } from "../../../../../../../../../../../../utils";

import { SummarizeSecStyled } from "./summarizesec.styles";

const SummarizeSec = ({
  isExpanded = false,
  summary = "",
  summaryResponseKeys = [],
}: SummarizeSecProps): JSX.Element => {
  const summaryConvertedInHtmlString = useMemo(() => {
    const updatedKeys = !isExpanded
      ? summaryResponseKeys?.slice(0, 1)
      : summaryResponseKeys;

    return updatedKeys?.reduce((updatedSummary, key, i) => {
      const pattern = new RegExp(
        key?.includes(":") ? `${key}` : `${key}:`,
        "g"
      );
      const title = `<span class='summary-sec-title'>${key}</span>`;
      const separator = i > 0 ? "<br/><br/>" : "";

      const summaryToBechanged = updatedSummary.replace(
        pattern,
        `${separator}${title}`
      );

      return !isExpanded
        ? summaryToBechanged?.slice(0, 180)
        : summaryToBechanged;
    }, summary);
  }, [summary, summaryResponseKeys, isExpanded]);
  return (
    <SummarizeSecStyled>
      {parseStringToHtml(
        summaryConvertedInHtmlString || "Sorry, not able to summarize the chat"
      )}
      {!isExpanded && "..."}
    </SummarizeSecStyled>
  );
};

export default SummarizeSec;
