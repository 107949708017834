export function toFixed(val: number | string, decimals = 1): string {
  try {
    const fixedVal = Number(val)?.toFixed(decimals);
    return Number(fixedVal) % 1 !== 0
      ? fixedVal
      : `${Math.round(Number(fixedVal))}`;
  } catch {
    return "";
  }
}
