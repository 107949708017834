import styled from "styled-components";

export const ErdEdgeRefViewDrawerStyled = styled.div``;

export const ErdEdgeHeaderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 15px;
  border-bottom: 1px solid
    ${({ theme }): string => theme?.borderColors?.LIGHT_GREY_51};

  .actions-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 15px;

    .edit-action-item,
    .delete-action-item {
      display: flex;
      align-items: center;
      column-gap: 5px;
      font-family: OpenSansSemiBold;
    }

    .delete-action-item {
      color: ${({ theme }): string => theme?.textColors?.RED_100};
    }

    .edit-action-item {
      color: ${({ theme }): string => theme?.textColors?.BLUE_116};
    }
  }
`;

export const TextWithIconStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;

  .text {
    font-size: 19.2px;
    font-family: OpenSansSemiBold;
    color: ${({ theme }): string => theme?.textColors?.BLUE_116};
  }

  .icon {
    height: 20px;
    margin-right: 3px;
  }
`;
