// <--- Import Statements Start --->
import { useMemo } from "react";
// <--- Import Statements End --->

// <--- Components Start --->
import Flex from "../flex";
import LinkButton from "../linkbutton";

import SectionTitleWithIcon from "../sectiontitlewithicon";
// <--- Components End --->

// <--- Styles Start --->
import { StyledSectionWithBody } from "./sectionwithbody.styles";
// <--- Styles End --->

// <--- Types Start --->
import { SectionWithBodyProps } from "./sectionwithbody.types";
// <--- Types End --->

const SectionWithBody = (props: SectionWithBodyProps): JSX.Element => {
  const {
    customClass,
    children,
    headingProps: {
      title,
      isDisabledLeftIcon,
      leftIcon,
      leftIconTooltip,
      leftSec,
      onClickLeftIcon,
      leftSecGap,
    },
  } = props;

  const titleSection = useMemo(() => {
    return (
      <Flex columnGap={leftSecGap || 5.6} alignItems="center">
        <div className="title-text">{title || ""}</div>
        {leftIcon && (
          <LinkButton
            type="link"
            onClick={onClickLeftIcon}
            height="13px"
            disabled={isDisabledLeftIcon}
            tooltipProps={leftIconTooltip}
            className="left-icon"
          >
            {leftIcon}
          </LinkButton>
        )}
        {!leftIcon && leftSec && leftSec}
      </Flex>
    );
  }, [
    title,
    isDisabledLeftIcon,
    leftIconTooltip,
    leftSec,
    leftIcon,
    onClickLeftIcon,
  ]);

  return (
    <StyledSectionWithBody className={customClass}>
      <SectionTitleWithIcon
        className="content-header"
        {...props.headingProps}
        title={titleSection}
      />

      <div className="section-body">{children}</div>
    </StyledSectionWithBody>
  );
};

export default SectionWithBody;
