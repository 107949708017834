import { Spin } from "antd";
import { useCallback, useMemo } from "react";

import { checkSourceType, parseStringToHtml } from "../../utils";

import {
  VerboseLogsFormStyled,
  EmptyVerboseLogState,
} from "./verboselogsform.styles";
import { VerboseLogsFormProps } from "./verboselogsform.types";

import { copyTextIcon, crossIcon } from "../../svgs";
import LinkButton from "../../components/linkbutton/linkbutton";
import Flex from "../../components/flex/flex";
import { removeTimestampFromLogs } from "../../pages/analysisdetailpage/analisisdetailpage.utils";
import { useCopyToClipboard } from "../../customhooks";

const VerboseLogsForm = (props: VerboseLogsFormProps): JSX.Element => {
  const copyLogs = useCopyToClipboard();

  const {
    verboseResponse = [],
    isChatPage = false,
    onShowVerboseIconClick,
    isLoading,
    analysisSrcTypeId,
  } = props;

  const formattedJsx = useMemo(() => {
    return verboseResponse
      ?.map((item) => removeTimestampFromLogs(item))
      ?.map((verboseItem, index) => {
        const formattedString = verboseItem?.replace(
          /(\n.*:)/g,
          (match) => `<span className="bold">${match}</span>`
        );
        return (
          <div key={`${index}-${verboseItem}-verbose-logs`}>
            {parseStringToHtml(formattedString)}
          </div>
        );
      });
  }, [verboseResponse]);

  const { isRestAPISource } = checkSourceType(analysisSrcTypeId);

  const onCopy = useCallback(() => {
    copyLogs(verboseResponse?.join("\n"));
  }, [verboseResponse?.length]);

  return (
    <VerboseLogsFormStyled isChatPage={isChatPage}>
      <div className="title-section">
        <span className="title">
          {isRestAPISource ? "Workflow Messages" : "Log Details"}
        </span>

        <div className="title-sec-right-sec">
          <LinkButton className="copy-icon" onClick={onCopy}>
            {copyTextIcon("15", "15")} Copy
          </LinkButton>

          <LinkButton
            className="cross-icon-container"
            onClick={onShowVerboseIconClick}
            data-testid="analysis-detail-page-overview-sec-cross-btn"
          >
            {crossIcon("12", "12")}
          </LinkButton>
        </div>
      </div>
      <div className="content-section">
        <div className="logs-sec">
          {verboseResponse?.length ? (
            formattedJsx
          ) : (
            <EmptyVerboseLogState>
              {isLoading ? (
                <Flex direction="column">
                  <Spin size="small" />
                  <span> Fetching Logs...</span>
                </Flex>
              ) : (
                <span>No Logs Exist</span>
              )}
            </EmptyVerboseLogState>
          )}
        </div>
      </div>
    </VerboseLogsFormStyled>
  );
};

export default VerboseLogsForm;
