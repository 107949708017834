import styled from "styled-components";

export const ReviewCaddiFeedbackContentHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid
    ${({ theme }): string => theme?.borderColors?.GREY_56};
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);

  .header-left-sec {
    .heading-sec {
      display: flex;
      column-gap: 9.6px;
      margin-bottom: 6.4px;
      align-items: center;

      .review-feedback-heading-tooltip {
        max-width: 270px;
      }

      .heading {
        font-size: 16px;
        font-family: "OpenSansSemiBold";
      }
    }

    .desc-sec {
      font-size: 12px;
      color: ${({ theme }): string => theme?.textColors?.GREY_26};

      .ant-btn-link {
        font-size: 12px;
      }
    }
  }

  .header-right-sec {
    display: flex;
    column-gap: 10.8px;
    align-items: center;

    .ant-btn-link {
      display: flex;

      svg {
        path {
          fill: ${({ theme }): string => theme?.textColors?.BLUE_116};
        }
      }

      .update-status-btn {
        background: ${({ theme }): string => theme?.bgColors?.BLUE_116};
        color: ${({ theme }): string => theme?.textColors?.WHITE_COLOR};
        padding: 4.8px 12.8px;
        display: flex;
        align-items: center;
        column-gap: 6.4px;
        border-radius: 2px;
        font-family: "OpenSansSemiBold";

        svg {
          path {
            stroke: ${({ theme }): string => theme?.textColors?.WHITE_COLOR};
          }
        }
      }
    }

    .ant-dropdown {
      .ant-btn-link {
        color: ${({ theme }): string => theme?.textColors?.GREY_40};

        &:disabled {
          opacity: 1;
          background: ${({ theme }): string => theme?.bgColors?.WHITE_COLOR};
          color: ${({ theme }): string => theme?.textColors?.GREY_210};

          &:hover {
            span {
              color: ${({ theme }): string => theme?.textColors?.GREY_210};
            }
          }
        }
      }
    }
  }
`;

export const HeadingTooltipBlankState = styled.div`
  color: ${({ theme }): string => theme?.textColors?.GREY_210};
`;
