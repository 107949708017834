import { toFixed } from "./tofixed";

export function numberFormatter(
  val: string,
  checkDecimal?: boolean,
  isRound = true
): string {
  try {
    const finalVal =
      checkDecimal && !Number.isInteger(Number(val)) && isRound
        ? toFixed(val, 3)
        : Number(val);

    const [integerPart, fractionalPart] = finalVal.toString().split(".");

    const formattedIntegerPart = integerPart.replace(
      /(\d)(?=(\d{3})+(?!\d))/g,
      "$1,"
    );

    return fractionalPart !== undefined
      ? `${formattedIntegerPart}.${fractionalPart}`
      : formattedIntegerPart;

    // return finalVal.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  } catch {
    return "";
  }
}
