import { getItemKeyAndSymbolOfPattern } from "./getItemKeyAndSymbolOfPattern.util";
import { getPatternedDataByType } from "./getPatternedDataByType.util";

export function getFilterDataByPattern(
  responseData: any,
  readPattern: string,
  filterValue: string
): any {
  const [itemKeys, symbols] = getItemKeyAndSymbolOfPattern(readPattern);

  return getPatternedDataByType(
    responseData,
    itemKeys,
    symbols,
    "filter",
    filterValue
  );
}
