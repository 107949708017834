import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useCallback } from "react";
import LinkButton from "../../../linkbutton";
import { moveDown, moveUP } from "../../transfer.utils";
import { TransferUpDownSecProps } from "./transferupdownsec.types";

export function TransferUpDownSection(
  props: TransferUpDownSecProps
): JSX.Element {
  const { selectedTargetKeys, setState, state } = props;

  const onMoveUpDown = useCallback(
    (isMovingUp?: boolean) => {
      const updatedOrderOfTargetKeys = (isMovingUp ? moveUP : moveDown)(
        state?.targetKeys,
        selectedTargetKeys
      );
      setState((st) => ({ ...st, targetKeys: updatedOrderOfTargetKeys }));
    },
    [state, selectedTargetKeys, moveDown, moveUP]
  );

  const isDisabledUpButton =
    state?.targetKeys?.indexOf(selectedTargetKeys?.[0]) === 0;
  const isDisabledDownButton =
    state?.targetKeys?.indexOf(selectedTargetKeys?.[0]) ===
    state?.targetKeys?.length - 1;

  const isMultipleSelected = selectedTargetKeys?.length > 1;
  const isNothingSelected = selectedTargetKeys?.length === 0;

  return (
    <div className="up-down-icons-sec">
      <LinkButton
        onClick={(): void => onMoveUpDown(true)}
        disabled={isNothingSelected || isDisabledUpButton}
        className="up-down-icon"
        tooltipProps={{
          title: isNothingSelected
            ? "Please select something to change order"
            : "",
          placement: "right",
        }}
      >
        <UpOutlined />
      </LinkButton>
      <LinkButton
        onClick={(): void => onMoveUpDown(false)}
        disabled={isNothingSelected || isDisabledDownButton}
        className="up-down-icon"
        tooltipProps={{
          title: isNothingSelected
            ? "Please select something to change order"
            : "",
          placement: "right",
        }}
      >
        <DownOutlined />
      </LinkButton>
    </div>
  );
}

export default TransferUpDownSection;
