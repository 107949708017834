import styled from "styled-components";

export const TabsWrapperStyled = styled.div`
  padding-bottom: 25px;

  .module-settings-heading {
    font-size: 25px;
    text-align: left;
    color: ${({ theme }): string => theme.textColors.BLUE_116};
    margin-bottom: 8px;
    font-family: OpenSansLight;
  }

  .form-wrapper {
    border: solid 1px ${({ theme }): string => theme.borderColors.LIGHT_GREY_51};
    background-color: #fff;
    display: grid;
    grid-template-columns: 15% 85%;
    height: 100%;
    min-height: 70vh;

    .tabs-ul {
      display: flex;
      gap: 5px;
      flex-direction: column;
      padding: 20px 0;
      border-right: solid 1px
        ${({ theme }): string => theme.borderColors.LIGHT_GREY_51};
    }

    .tabs-panel {
      padding: 15px 0;
    }
  }
`;

export const TabItemStyled = styled.div<{ isSelected: boolean }>`
  cursor: pointer;
  font-size: 14.4px;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 15px;
  font-family: ${({ isSelected }): string =>
    isSelected ? "OpenSansSemiBold" : ""};
  color: ${({ isSelected, theme }): string =>
    isSelected ? theme.textColors.BLUE_116 : theme.textColors.BLACK_COLOR};
  border-left: 5px solid
    ${({ isSelected, theme }): string =>
      isSelected ? theme.textColors.BLUE_116 : theme.textColors.WHITE_COLOR};
  background-color: ${({ isSelected, theme }): string =>
    isSelected ? theme.bgColors.LIGHT_BLUE_25 : "transparent"};
`;
