import { capitalize } from "lodash";
import { BarWithProgress, UserNameInitials } from "../../../../components";

import { LineChart } from "../../../../components/recharts";
import { DVSUM_COLOR_PALLETE } from "../../../../components/recharts/dvsumcolorpallete";

import { ELEMENT_IDS, X_AXIS_DATA_KEY } from "../../../../constants";
import { getNameInitials, getObjectKeys } from "../../../../utils";

import { ChartSecStyled, TopUserItemStyled } from "./chartsec.styles";
import { ChartSecProps, TopUserItemProps } from "./chartsec.types";

import { BOT_ENGAGEMENTS_Y_AXIS } from "./chartsec.constants";
import StateHandler from "../../../../components/statehandler/statehandler";

import {
  botEngagementsBlankSlate,
  topUsersBlankSlate,
} from "../../../../blankslates/listingpagesblankslate";

const TopUserItem = (props: TopUserItemProps): JSX.Element => {
  const { name = "", value = 0, maxValue = 0 } = props;

  const nameInitals = getNameInitials(
    name?.split(" ")?.[0],
    name?.split(" ")?.[1]
  );

  return (
    <TopUserItemStyled>
      <UserNameInitials name={nameInitals} />

      <div className="bar-with-name-sec">
        <div className="name" title={name}>
          {name}
        </div>
        <BarWithProgress value={value} maxValue={maxValue} showValue />
      </div>
    </TopUserItemStyled>
  );
};

const ChartSec = (props: ChartSecProps): JSX.Element => {
  const {
    botEngagementsData = [],
    botEngagementsIsLoading = false,
    botEngagementsError = {},
    topUsersData = [],
    topUsersIsLoading = false,
    topUsersError = {},
  } = props;

  const dataKeys = getObjectKeys(botEngagementsData?.[0])
    ?.filter((item) => item !== X_AXIS_DATA_KEY)
    ?.map((nestItem, index) => ({
      dataKey: capitalize(nestItem || ""),
      fill: DVSUM_COLOR_PALLETE[index] || "",
    }));

  const topUserMaxValue =
    topUsersData?.reduce((max, obj) => {
      return obj.value > max.value ? obj : max;
    }, topUsersData[0])?.value || 0;

  const isRotateXAxis = botEngagementsData?.length > 6;

  return (
    <ChartSecStyled isRotateXAxis={isRotateXAxis}>
      <StateHandler
        isFetching={botEngagementsIsLoading}
        error={botEngagementsError}
        blankSlate={botEngagementsBlankSlate}
      >
        <div className="line-chart-sec" id={ELEMENT_IDS.cht_bot_anal_eng_sec}>
          <div className="line-chart-title">Bot Engagement</div>
          {botEngagementsData?.length ? (
            <LineChart
              data={botEngagementsData}
              dataKeys={dataKeys}
              yAxis={BOT_ENGAGEMENTS_Y_AXIS}
              width="100%"
              height={isRotateXAxis ? 370 : 350}
              margin={{
                left: isRotateXAxis ? 30 : 10,
                right: 10,
                bottom: isRotateXAxis ? 10 : 0,
                top: 20,
              }}
              isTiltedXAxisTicks={isRotateXAxis}
            />
          ) : (
            <div className="line-chart-blankstate">No data to display.</div>
          )}
        </div>
      </StateHandler>
      <StateHandler
        isFetching={topUsersIsLoading}
        error={topUsersError}
        blankSlate={topUsersBlankSlate}
      >
        <div
          className="bar-chart-sec"
          id={ELEMENT_IDS.cht_bot_anal_top_usr_sec}
        >
          <div className="heading">Top Users</div>
          {topUsersData?.length ? (
            <>
              <div className="desc">
                Results are based on number of questions asked by each user.
              </div>

              <div className="users-list">
                {topUsersData?.map((user, index) => (
                  <TopUserItem
                    key={index + user?.name}
                    {...user}
                    maxValue={topUserMaxValue}
                  />
                ))}
              </div>
            </>
          ) : (
            <div className="bar-chart-blankstate">No data to display.</div>
          )}
        </div>
      </StateHandler>
    </ChartSecStyled>
  );
};

export default ChartSec;
