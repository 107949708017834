import { ICellRendererParams } from "ag-grid-community";

import ElementWithDefaultTooltip from "../elementwithdefaulttooltip";

import { utcTOLocalTimeZone } from "../../utils";
import { MONTH_DAY_YEAR_HOUR_MINUTE } from "../../constants";

export const DefaultRendrerForEllipsis = (
  props: ICellRendererParams
): JSX.Element => {
  return (
    <ElementWithDefaultTooltip
      title={props?.value}
      value={props?.value}
      showEllipsis
    />
  );
};

export const DateTimeRendrer = (props: ICellRendererParams): string => {
  return utcTOLocalTimeZone(props?.value, MONTH_DAY_YEAR_HOUR_MINUTE);
};
