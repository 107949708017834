import { AxiosResponse } from "axios";
import { getLineagePreviewData } from "../parserutils";
import { dataSourceFieldNamesConfig } from "./datasourcefieldparser.config";

import {
  DataSourceFieldNodeDoc,
  ReturnTypeOfDataSourceFieledRefDocParser,
} from "./datasourcefieldparser.types";

const { overview } = dataSourceFieldNamesConfig;

export function getParsedDataSourceFieldDoc({
  data,
}: AxiosResponse<DataSourceFieldNodeDoc>): ReturnTypeOfDataSourceFieledRefDocParser {
  return {
    node_id: `${data?.node_id || ""}`,
    node_desc: data?.description?.description?.value || "",
    is_editable: true,
    node_name: data?.node_name || "",
    node_title: data?.title?.value,
    publish_status: data?.published_status || "",
    publish_status_id: data?.published_status_id,
    source_id: `${data?.source_id || ""}`,
    source_name: data?.source_name || "",
    table_id: `${data?.parent_id || ""}`,
    table_name: data?.parent_name || "",
    overview: [
      {
        title: overview.description,
        value: data?.description?.description?.value || "",
      },
      {
        title: overview.data_type,
        value: data?.description?.data_type_id,
        additionalInfo: {
          data_type_title: data?.description?.data_type || "",
        },
      },
    ],
    lineage: getLineagePreviewData(data?.lineage),
  };
}
