import { useCallback } from "react";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import { TextAreaField } from "../../../../../../components/formfields";
import LinkButton from "../../../../../../components/linkbutton";

import { arrowBackIcon } from "../../../../../../svgs";
import { AddContextViewStyled } from "./addcontextview.styles";

import { AddContextViewProps } from "./addcontextview.types";
import { Button } from "../../../../../../components";
import { addContextsSchema } from "../../../../../../utils/schemas/addcontextschema";

const backIconSvg = arrowBackIcon("14px", "14px");

const AddContextView = (props: AddContextViewProps): JSX.Element => {
  const { onGoBackClick, onUpdateContexts } = props;

  const {
    control,
    reset,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      context: "",
    },
    resolver: yupResolver(addContextsSchema),
  });

  const onSubmit = useCallback(({ context }) => {
    onUpdateContexts({
      context,
      type: "add",
    });
    onGoBackClick();
  }, []);

  const onCancel = useCallback(() => {
    reset();
    onGoBackClick();
  }, []);

  return (
    <AddContextViewStyled>
      <div className="header">
        <LinkButton
          className="back-btn"
          icon={backIconSvg}
          onClick={onGoBackClick}
        >
          Go Back
        </LinkButton>
      </div>

      <div className="content">
        <div className="title-sec">
          <div className="heading">Context</div>
          <div className="desc">You can add your custom context below.</div>
        </div>

        <TextAreaField
          name="context"
          control={control}
          height="228px"
          placeholder="Add your context here"
        />

        <div className="add-content-view-action-sec">
          <Button id="cancel" height="34.4px" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            id="primary"
            height="34.4px"
            disabled={!isValid}
            onClick={handleSubmit(onSubmit)}
            tooltipProps={{
              title: !isValid ? "Please add some context." : "",
              placement: "bottomRight",
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </AddContextViewStyled>
  );
};

export default AddContextView;
