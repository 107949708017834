import { useCallback, useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import { SharedWithItem } from "..";

import { ShareOwnerFormType } from "../../shareownerform.types";
import { SharedWithListProps } from "./sharedwithlist.types";

import { SharedWithListStyled } from "./sharedwithlist.styles";

const SharedWithList = (props: SharedWithListProps): JSX.Element => {
  const { parsedSharedWith, isLoading, isShowShareForm, accessOptions } = props;

  const { control, setValue } = useFormContext<ShareOwnerFormType>();

  const { fields, update } = useFieldArray<ShareOwnerFormType>({
    control,
    name: "shared_with_list",
  });

  const handleChangeAccessType = useCallback(
    (accessType: string, index: number) => {
      update(index, { ...fields?.[index], access_type: accessType });
    },
    [fields]
  );
  useEffect(() => {
    if (!isLoading) {
      setValue("shared_with_list", parsedSharedWith);
    }
  }, [isLoading]);
  return (
    <>
      {!isShowShareForm && (
        <SharedWithListStyled>
          {fields?.map((field, index: number) => (
            <SharedWithItem
              {...field}
              index={index}
              handleChangeAccessType={handleChangeAccessType}
              key={field?.id}
              accessOptions={accessOptions}
            />
          ))}
        </SharedWithListStyled>
      )}
    </>
  );
};

export default SharedWithList;
