import { useCallback, useEffect, useMemo } from "react";
import { useForm, FormProvider } from "react-hook-form";

import { Button } from "../../components";
import { FormItemStyled, FormStyled } from "../../components/form";
import StateHandler from "../../components/statehandler/statehandler";
import SuccessNotificationMessage from "../../components/successnotificationmessagerendrer/successnotificationmessagerendrer";
import { MassUpdateFormItem } from "./massupdateform.components";

import { useRequestWithMethod } from "../../api";
import { useGetMassEditableFields } from "../../api/listingsservice";

import { useCancelModal, useGetAppState } from "../../customhooks";

import { MassUpdateModalProps } from "./massupdateform.types";

import { isEqual, jsonParse, openNotification } from "../../utils";

import { statusId } from "./massupdateform.constants";

import { MassUpdateFormStyled } from "./massupdateform.styles";
import { ELEMENT_IDS } from "../../constants";
import { useGetSubdomainForMassUpdate } from "../../api/glossarycategoriesservice/glossarycategoriesservice";
import { MassEditableParsedField } from "../../parsers/listingpagesparser/listingpagesparser.types";
import { SelectField } from "../../components/formfields";
import { DvSumSelectFieldDropdownGlobalStyles } from "../../components/selectdropdowns";

const {
  allcat_lst_lst_mass_upd_apply_btn: ALLCAT_LST_LST_MASS_UPD_APPLY_BTN,
} = ELEMENT_IDS;

const TERM_STATUS_FILED = "trm_status";

function MassUpdateForm(): JSX.Element {
  const { modal = {} } = useGetAppState();
  const { modalProps = {} } = modal;
  const {
    nodeType,
    nodeData,
    isSelectAll,
    selectedNodesIds,
    countFilter,
    filterModel,
    searchId,
    selectedRowsCount,
    populateData,
  } = modalProps as MassUpdateModalProps;

  // Get API
  const { parsedData, isLoading, error } = useGetMassEditableFields(nodeType);

  const {
    parsedData: termCategories,
    isLoading: isLoadingCategories,
    error: errorCategories,
  } = useGetSubdomainForMassUpdate();

  const availableStatusIds = useMemo(() => {
    return parsedData?.fields
      ?.find((item) => item?.field_display_name === "Status")
      ?.field_data?.map((item) => item?.id);
  }, [parsedData]);

  const isCategoryAccessible =
    termCategories?.find((item) => item?.CAT_ID === nodeData?.cat_id) || false;

  const defaultValues: any =
    nodeType === "TRM"
      ? {
          cat_name:
            nodeData?.cat_id && isCategoryAccessible
              ? `${nodeData?.cat_id}`
              : undefined,
          trm_status:
            nodeData?.status_id &&
            availableStatusIds?.includes(nodeData?.status_id)
              ? nodeData?.status_id
              : undefined,
        }
      : nodeType === "TBL"
      ? {
          dmn_name: nodeData?.dmn_id ? `${nodeData?.dmn_id}` : undefined,
          trm_name: nodeData?.trm_id ? `${nodeData?.trm_id}` : undefined,
          tbl_status:
            nodeData?.status_id &&
            availableStatusIds?.includes(nodeData?.status_id)
              ? nodeData?.status_id
              : undefined,
        }
      : nodeType === "COL"
      ? {
          trm_name: nodeData?.trm_id ? `${nodeData?.trm_id}` : undefined,
          col_status:
            nodeData?.status_id &&
            availableStatusIds?.includes(nodeData?.status_id)
              ? nodeData?.status_id
              : undefined,
          col_entity_match_status:
            nodeData?.col_entity_match_status_id === ""
              ? undefined
              : nodeData?.col_entity_match_status_id,
          is_col_cde:
            nodeData?.is_cde !== undefined
              ? nodeData?.is_cde === "Yes"
                ? "true"
                : ""
              : undefined,
        }
      : nodeType === "RLS"
      ? {
          rule_action_status: nodeData?.rule_action_status_id
            ? `${nodeData?.rule_action_status_id}`
            : undefined,
          rule_priority: nodeData?.rule_priority
            ? `${nodeData?.rule_priority}`
            : undefined,
        }
      : {
          bo_status:
            nodeData?.status_id &&
            availableStatusIds?.includes(nodeData?.status_id)
              ? nodeData?.status_id
              : undefined,
          bo_func_area_name: nodeData?.bo_functional_area_id
            ? `${nodeData?.bo_functional_area_id}`
            : undefined,
        };

  const formProvider = useForm({ defaultValues });
  const { handleSubmit, setValue, watch, control } = formProvider;

  const watchFields = watch();
  const { trm_status: termStatus, field = "" } = watchFields;

  const memoizedSelectedTermStatus = useMemo(() => {
    const termStatusFields = parsedData?.fields?.find(
      (item) => item?.field_name === TERM_STATUS_FILED
    );
    const selectedStatus = termStatusFields?.field_data?.find(
      (item) => item?.id === termStatus
    );
    return selectedStatus;
  }, [termStatus, parsedData?.fields]);

  const isAnythingChangedInForm = useMemo(
    () =>
      !isEqual({ ...defaultValues, field: "" }, { ...watchFields, field: "" }),
    [defaultValues, watchFields]
  );

  const onCancel = useCancelModal();

  // API Integration
  const onSuccessMassUpdate = useCallback(() => {
    populateData({});

    onCancel();

    openNotification(
      <SuccessNotificationMessage
        message={`${selectedRowsCount} ${
          nodeType === "TRM"
            ? "term"
            : nodeType === "TBL"
            ? "table"
            : nodeType === "COL"
            ? "column"
            : nodeType === "RLS"
            ? "rule"
            : "dataset"
        }${
          selectedRowsCount > 1 ? "s have" : " has"
        } been ${"submitted for mass update."}  `}
      />
    );
  }, [nodeType, selectedRowsCount, memoizedSelectedTermStatus]);

  const {
    onExecuteRequest: onExecuteRequestMassUpdate,
    isLoading: isLoadingMassUpdate,
    error: errorMassUpdate,
  } = useRequestWithMethod(
    "update_field_data",
    [nodeType, searchId],
    false,
    onSuccessMassUpdate
  );

  // Callbacks
  const onSubmit = useCallback(
    (values) => {
      const updatedFields = jsonParse(JSON.stringify(values));
      const isMassUpdate: boolean =
        isSelectAll || (!isSelectAll && selectedNodesIds?.length > 1);

      if (!isMassUpdate && `${nodeData?.trm_id}` === updatedFields?.trm_name) {
        delete updatedFields.trm_name;
      }

      onExecuteRequestMassUpdate([
        {
          ...updatedFields,
          is_col_cde: updatedFields?.is_col_cde === "true",
          [nodeType === "TRM"
            ? "trm_ids"
            : nodeType === "TBL"
            ? "tbl_ids"
            : nodeType === "COL"
            ? "col_ids"
            : nodeType === "RLS"
            ? "rule_ids"
            : "bo_ids"]: [...(selectedNodesIds || [])],
          is_mass_update: isMassUpdate,
          isSelectAll: isSelectAll || false,
          filterModel: filterModel || {},
          countFilter: countFilter || "all",
        },
      ]);
    },
    [
      nodeType,
      isSelectAll,
      selectedNodesIds,
      nodeData,
      filterModel,
      countFilter,
      memoizedSelectedTermStatus,
    ]
  );

  useEffect(() => {
    if (
      !isLoading &&
      nodeData?.status_id &&
      availableStatusIds?.includes(nodeData?.status_id) &&
      parsedData
    )
      setValue(statusId[nodeType], nodeData?.status_id);
  }, [isLoading]);

  const onClear = (field: MassEditableParsedField): void => {
    setValue(field?.field_name, undefined);
  };

  const fields = useMemo(() => {
    return parsedData?.fields?.map((field) => ({
      labelText: field?.field_display_name || "",
      label: field?.field_display_name || "",
      value: field?.field_name || "",
    }));
  }, [parsedData?.fields]);

  const selectedField = useMemo(() => {
    const findedField = parsedData?.fields?.find(
      (formField) => formField?.field_name === field
    );
    return findedField ? (
      <MassUpdateFormItem
        {...findedField}
        key={findedField?.field_name}
        onClear={onClear}
      />
    ) : (
      <SelectField
        name=""
        width="348px"
        control={control}
        options={[]}
        setValue={setValue}
        disabled
      />
    );
  }, [field]);

  useEffect(() => {
    if (!isLoading && fields?.[0]?.value) {
      setValue("field", fields?.[0]?.value);
    }
  }, [isLoading]);

  return (
    <StateHandler
      isFetching={isLoading || isLoadingMassUpdate || isLoadingCategories}
      error={error || errorMassUpdate || errorCategories}
      isModal
    >
      <MassUpdateFormStyled type={nodeType}>
        <DvSumSelectFieldDropdownGlobalStyles />
        <FormProvider {...formProvider}>
          <FormStyled
            isItemColumnLayout
            paddingLeft="100px"
            onFinish={handleSubmit(onSubmit) as any}
          >
            <div className="content-sec">
              <div className="field-label">Field</div>
              <div className="field-label-desc">
                Select some field to add the Value
              </div>
              <div className="field-content">
                <SelectField
                  options={fields}
                  name="field"
                  setValue={setValue}
                  control={control}
                  isAllowClear={false}
                  placeholder="Select"
                  width="194px"
                  isUseGlobalStyle
                />
                {selectedField}
              </div>
            </div>

            {memoizedSelectedTermStatus && (
              <div className="description-sec">
                <span className="desc">
                  {memoizedSelectedTermStatus?.desc || ""}
                </span>
              </div>
            )}

            <FormItemStyled
              label=""
              className="form-actions-sec"
              marginBottom="0px"
            >
              <Button id="cancel" width="74px" height="39px" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                id="primary"
                width="74px"
                height="39px"
                marginLeft="8px"
                htmlType="submit"
                disabled={!isAnythingChangedInForm}
                tooltipProps={{
                  title: !isAnythingChangedInForm
                    ? "You haven't made any change in the value field yet."
                    : "",
                  placement: "right",
                }}
                elementId={ALLCAT_LST_LST_MASS_UPD_APPLY_BTN}
              >
                Apply
              </Button>
            </FormItemStyled>
          </FormStyled>
        </FormProvider>
      </MassUpdateFormStyled>
    </StateHandler>
  );
}

export default MassUpdateForm;
