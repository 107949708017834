import { useFormContext } from "react-hook-form";
import { useCallback } from "react";

import { leftPrecision, rightPrecision } from "../../svgs";
import LinkButton from "../linkbutton/linkbutton";

import { RangeInputStyled } from "./rangeinput.styles";
import GridInputField from "../../pages/tagsetsdetailpage/tagsetsdetailpage.components/gridinputfield/gridinputfield";

const rightPrecisionSvg = rightPrecision();
const leftPrecisionSvg = leftPrecision();

const RangeInput = (): JSX.Element => {
  const { control, setValue, watch } = useFormContext();

  const currentPrecisionValue = watch("precision");

  const validateUserPrecisionValue = useCallback((inputValue: number) => {
    if (inputValue >= 2 && inputValue <= 100) {
      setValue("precision", inputValue, {
        shouldValidate: true,
      });
    } else {
      const updatedValue = inputValue < 2 ? 2 : 100;
      setValue("precision", updatedValue, {
        shouldValidate: true,
      });
    }
  }, []);

  const onChangePrecisionButtonClick = useCallback(
    (operation: "add" | "reduce") => (): void => {
      const updatedPrecisionValue =
        parseInt(currentPrecisionValue) +
        (operation === "add" ? 1 : currentPrecisionValue === 0 ? 0 : -1);

      validateUserPrecisionValue(updatedPrecisionValue);
    },
    [currentPrecisionValue]
  );

  const onChangePrecisionInput = useCallback((precisionValue: number) => {
    validateUserPrecisionValue(precisionValue);
  }, []);

  return (
    <RangeInputStyled>
      <LinkButton
        className="left-sec"
        onClick={onChangePrecisionButtonClick("reduce")}
        tooltipProps={{
          title: (
            <div className="tooltip-title">
              <b>Decrease Decimal:</b> Show less decimal places for a more
              general value
            </div>
          ),
          placement: "topRight",
          overlayStyle: {
            width: 270,
            minWidth: 270,
          },
        }}
      >
        {leftPrecisionSvg}
      </LinkButton>
      <div className="input-sec">
        <GridInputField
          type="number"
          control={control}
          className="precision-input"
          name="precision"
          propOnChange={(e): void => onChangePrecisionInput(e?.target?.value)}
          min={2}
          tooltipClassName="dvsum-tooltip"
          tooltipPlacement="bottomRight"
        />
      </div>
      <LinkButton
        className="right-sec"
        onClick={onChangePrecisionButtonClick("add")}
        tooltipProps={{
          title: (
            <div className="tooltip-title">
              <b>Increase Decimal:</b> Show more decimal places for a more
              precise value
            </div>
          ),
          placement: "topLeft",
          overlayStyle: {
            width: 280,
            minWidth: 280,
          },
        }}
      >
        {rightPrecisionSvg}
      </LinkButton>
    </RangeInputStyled>
  );
};

export default RangeInput;
