import { APP_ROUTES } from "../constants";

export const checkIsReadOnlyChatPage = (): boolean => {
  const pathname = window?.location?.pathname || "";

  try {
    return pathname?.includes(APP_ROUTES.private_routes.read_only_chat);
  } catch (e) {
    return false;
  }
};
