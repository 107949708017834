import styled from "styled-components";

export const ReleaseOnboardingModalStyled = styled.div`
  padding: 60.6px 35.2px 48px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .heading {
    font-size: 24px;
    font-family: "OpenSansSemiBold";
    margin: 16px 0 22.4px;
  }

  .updates-list {
    display: flex;
    flex-direction: column;
    row-gap: 12.8px;
    width: 100%;
    overflow: auto;
    max-height: 290px;

    .updated-feature-item {
      background: ${({ theme }): string => theme?.bgColors?.LIGHT_GREY_22};
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12.8px;

      .title-sec {
        display: flex;
        align-items: center;
        column-gap: 6.4px;
      }

      .updated-feature-btns {
        display: flex;
        align-items: center;

        .ant-btn {
          font-family: OpenSansSemiBold;
        }
      }
    }
  }

  .footer-sec {
    margin-top: 38.4px;
    width: 266px;

    .action-sec {
      display: flex;
      justify-content: center;
      column-gap: 9.6px;
    }

    .footer-note {
      font-size: 10.4px;
      margin-top: 12.8px;
      text-align: center;

      .help {
        font-family: OpenSansSemiBold;
      }
    }
  }
`;
