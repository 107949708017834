import { UseQueryResult } from "react-query";

// <--- Constants Start --->
import { API_CONFIG } from "../../constants/apiconfig";
// <--- Constants End --->

// <--- Parsers Start --->
import {
  getParsedDetailsGridData,
  getParsedSummaryTiles,
  parsedDataOfRuleDetails,
  parsedDataQualityHistoryChartData,
  ReturnTypeOfDataQualityHistoryChartParser,
  ReturnTypeOfDetailsGridParser,
  ReturnTypeOfDQRuleDetailsParser,
  ReturnTypeOfSummaryTilesParser,
  getParsedRuleDetailsBarChartData,
  ReturnTypeOfRuleHistoryBarChartParser,
  DQRuleCategories,
  getParsedDownloadRuleLink,
} from "../../parsers";

// <--- Parsers End --->

// <--- Services Start --->
import { useGetAll } from "../baseservice";
// <--- Services End --->

// <--- Types Start --->
import { CustomReactQueryHookReturnType } from "../services.types";
import { NodeType } from "../../app.types";
// <--- Types End --->

const {
  get_summary_tiles: getSummaryTiles,
  get_details_grid_data: getDetailsGridData,
  download_dq_rules: downloadDQRules,
} = API_CONFIG;

export function useGetSummaryTiles(
  nodeId: string
): CustomReactQueryHookReturnType<ReturnTypeOfSummaryTilesParser[]> {
  return useGetAll({
    apiConfig: getSummaryTiles,
    params: [nodeId],
    parser: getParsedSummaryTiles,
  });
}

export function useGetDetailsData(
  id: string
): CustomReactQueryHookReturnType<ReturnTypeOfDetailsGridParser[]> {
  return useGetAll({
    apiConfig: getDetailsGridData,
    params: [id],
    parser: getParsedDetailsGridData,
  });
}

export function useGetDataQualityHistoryChartData(
  nodeId: string,
  nodeType: NodeType,
  category: DQRuleCategories
): CustomReactQueryHookReturnType<ReturnTypeOfDataQualityHistoryChartParser> {
  return useGetAll({
    apiConfig: API_CONFIG.get_data_quality_category_history,
    params: [nodeId, nodeType, category],
    parser: parsedDataQualityHistoryChartData,
  });
}

export function useGetDQRuleDetails(
  id: string,
  nodeType: NodeType
): CustomReactQueryHookReturnType<ReturnTypeOfDQRuleDetailsParser> {
  return useGetAll({
    apiConfig: API_CONFIG.get_dq_rule_details,
    params: [id, nodeType],
    parser: parsedDataOfRuleDetails,
  });
}

export function useGetDQRuleDetailsBarChartData(
  nodeId: string,
  ruleHistoryId: string
): CustomReactQueryHookReturnType<ReturnTypeOfRuleHistoryBarChartParser> {
  return useGetAll({
    apiConfig: API_CONFIG.get_data_quality_rule_history,
    parser: getParsedRuleDetailsBarChartData,
    params: [nodeId, ruleHistoryId],
  });
}

export function useGetDQRuleExport(
  nodeId: string,
  isAlert: boolean,
  onSuccess: (_response: any) => void
): CustomReactQueryHookReturnType<string> {
  return useGetAll({
    apiConfig: downloadDQRules,
    params: [nodeId, isAlert ? "true" : "false"],
    parser: getParsedDownloadRuleLink,
    options: { onSuccess, enabled: false },
  });
}

export function useGetDQRuleDetailCSV(
  sampleDataUrl: string,
  enabled?: boolean
): UseQueryResult {
  return useGetAll({
    apiConfig: { url: sampleDataUrl, method: "GET" },
    options: { enabled: !!enabled },
    isReqWithoutHeader: true,
  });
}
