import { Tooltip } from "antd";
import { useCallback } from "react";

import { useController } from "react-hook-form";

import { DatePickerFieldContainerStyled } from "./datepickerfield.styles";
import { DatePickerFieldType } from "./datepickerfield.types";

import DatePicker from "../../datepicker/datepicker";

function DatePickerField({
  width,
  name = "",
  control,
  suffixIcon = null,
  propOnChange,
  errorInTooltip,
  tooltipClassName,
  showError = true,
  ...props
}: DatePickerFieldType<Date>): JSX.Element {
  const {
    field: { ref, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const handleChange = useCallback(
    (event) => {
      inputProps?.onChange(event);
      propOnChange && propOnChange(event);
    },
    [inputProps]
  );

  return (
    <DatePickerFieldContainerStyled
      isError={!!error?.message}
      className="input-field-container"
      data-testid="date-picker-field-container"
    >
      <Tooltip
        placement="bottomRight"
        title={errorInTooltip ? error?.message : undefined}
        overlayClassName={tooltipClassName || "dvsum-error-tooltip"}
      >
        <DatePicker
          {...props}
          {...inputProps}
          onChange={handleChange}
          ref={ref}
          name={name}
          width={width}
          suffixIcon={suffixIcon}
          allowClear={false}
          data-testid="date-picker-field"
        />
      </Tooltip>

      {!errorInTooltip && showError && (
        <div className="error" data-testid="date-picker-error-msg">
          {error?.message}
        </div>
      )}
    </DatePickerFieldContainerStyled>
  );
}

export default DatePickerField;
