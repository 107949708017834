import { useMemo } from "react";

import { tagsIcon } from "../../../../svgs";

import { TagsGridProps } from "./tagsgrid.types";

import { tagsetLabels } from "../../tagsetsdetailpage.constants";

import { TagsGridStyled } from "./tagsgrid.styles";
import { tagPrivacyMapping } from "../../../../utils/tagprivacymapping";
import GenericProminentTag from "../../../../components/genericprominenttag";
import { CheckboxStyled } from "../../../../components/checkbox/checkbox.styles";

const TagsGrid = (props: TagsGridProps): JSX.Element => {
  const { tags, isClassificationTagset = false, isShowBorder = true } = props;

  const labels = tagsetLabels.tagsetdetail.tags;

  const renderTags = useMemo(() => {
    return [...tags]
      ?.sort((a, b) =>
        isClassificationTagset
          ? 0
          : a?.tag > b?.tag
          ? 1
          : b?.tag > a?.tag
          ? -1
          : 0
      )
      ?.map((tag, index) => (
        <div key={index} className="grid-row">
          <div title={tag?.tag}>{tag?.tag}</div>
          <div title={tag?.description}>{tag?.description}</div>
          <div>
            {tag?.prominent_tag_id !== "GRY" && (
              <div className="tags-grid-prominent-tag-container">
                <CheckboxStyled checked />
                <GenericProminentTag
                  tag={{ name: "Tag", type: tag?.prominent_tag_id }}
                  type={tag?.prominent_tag_id}
                  isBoldView
                />
              </div>
            )}
          </div>
          {isClassificationTagset && (
            <div title={tagPrivacyMapping(tag?.privacy || "NON")}>
              {tagPrivacyMapping(tag?.privacy || "NON")}
            </div>
          )}
          <div title={`${tag?.usage_count}`}>{tag?.usage_count}</div>
        </div>
      ));
  }, [tags]);

  return (
    <TagsGridStyled
      isShowBorder={isShowBorder}
      isClassificationTagset={isClassificationTagset}
    >
      <div className="grid-row grid-header">
        <div>{labels.name}</div>
        <div>{labels.description}</div>
        <div>{labels.prominent_tag}</div>
        {isClassificationTagset && <div>{labels.privacy}</div>}
        <div>{labels.usage_count}</div>
      </div>

      {tags?.length ? (
        renderTags
      ) : (
        <div className="no-tags-section">
          <div className="no-tags-icon">{tagsIcon("18", "18")}</div>
          <div className="no-tags-text">{labels.no_tags}</div>
        </div>
      )}
    </TagsGridStyled>
  );
};

export default TagsGrid;
