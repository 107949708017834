import { SelectFieldOption } from "../formfields/selectfield/selectfield.types";
import { OptionRendrerStyled } from "./managedbysectionrow.styles";

export const optionRendrer = (item: SelectFieldOption): JSX.Element => (
  <OptionRendrerStyled
    className="custom-option"
    key={item?.value}
    title={item?.labelText}
  >
    {item?.icon}
    {item?.labelText}
  </OptionRendrerStyled>
);
