import { Form } from "antd";
import styled from "styled-components";

import { FormItemLabelStyledProps } from "./formitemlabel/formitemlabel.types";

const FormStyled = styled(Form)<{
  isItemColumnLayout?: boolean;
  paddingLeft?: string;
  paddingRight?: string;
  height?: string;
}>`
  &.ant-form {
    ${({ height }): any => height && `height: ${height};`};

    padding-top: 0px;

    .ant-form-item {
      ${(props): any =>
        props.isItemColumnLayout &&
        `
          display:flex;
          flex-direction: column;
          align-items: baseline;
        `}
      padding-left: ${({ paddingLeft }): any => paddingLeft};
      ${({ paddingRight }): any =>
        paddingRight && `padding-right: ${paddingRight};`};

      &:not(.form-actions-sec) {
        margin-bottom: 18px;
      }

      &.sec-header {
        border-bottom: none;
      }

      .ant-form-item-label {
        .ant-form-item-required {
          color: ${(props): string => props.theme.textColors.BLACK_10};
          font-family: OpenSansSemiBold;
          ::after {
            display: inline-block;
            margin-right: 4px;
            color: ${({ theme }): string => theme.textColors.RED_11};
            font-family: OpenSansSemiBold;
            line-height: 1;
            content: "*";
          }
          ::before {
            display: none;
          }
        }

        label {
          color: ${(props): string => props.theme.textColors.BLACK_10};
          font-family: OpenSansSemiBold;
          ::after {
            display: none;
          }
        }
      }

      .ant-form-item-control {
        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            .input-field-container > input,
            .textarea-field-container > textarea {
            }

            .textarea-field-container > textarea {
              height: 120px !important;
            }

            .ant-radio-wrapper {
              margin-right: 0px;
              .ant-radio {
                top: 5px;
              }
            }

            .ant-radio-wrapper,
            .ant-checkbox-wrapper {
              /* width: 170px; */
              //Needs to test across the application

              &.ant-radio-wrapper-disabled {
                .ant-radio-disabled {
                  .ant-radio-inner {
                    &:after {
                      background-color: ${({ theme }): string =>
                        theme.bgColors.GREY_14};
                    }
                  }
                }
                span:nth-of-type(2) {
                  color: ${({ theme }): string => theme.textColors.BLACK_17};
                }
              }

              span:nth-of-type(2) {
                color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
                font-family: OpenSans;
                padding-left: 12px;
              }

              .ant-checkbox-inner,
              .ant-radio-inner,
              input[type="checkbox"],
              input[type="radio"] {
                width: 19px;
                height: 19px;
              }

              .ant-checkbox-inner {
                background-color: ${({ theme }): string =>
                  theme.bgColors.WHITE_COLOR};
                border-color: ${({ theme }): string =>
                  theme.borderColors.LIGHT_GREY_10};

                &:after {
                  border-color: ${({ theme }): string =>
                    theme.bgColors.BLUE_116};
                  border-width: 3px;
                  width: 6.714286px;
                  height: 10.742857px;
                }
              }

              .ant-radio-inner {
                background-color: ${({ theme }): string =>
                  theme.bgColors.LIGHT_BLUE_5};
                border-color: ${({ theme }): string =>
                  theme.borderColors.GREY_18};

                &:after {
                  background: ${({ theme }): string => theme.bgColors.BLUE_116};
                  width: 12px;
                  height: 12.2px;
                  top: 2.5px;
                  left: 3px;
                }
              }
            }

            .app-transfer {
              margin-top: 13px;
              .transfer-left-title {
                display: flex;
                align-items: center;

                svg {
                  width: 6.3px;
                  height: 13.8px;
                }
              }
            }
          }
        }
      }
    }

    .form-actions-sec {
      background-color: ${(props): string =>
        props.theme.bgColors.LIGHT_BLUE_25};
      border-top: 1px solid
        ${(props): string => props.theme.borderColors.LIGHT_BLUE_45};
      padding: 8px 18px 8px 18px;

      .ant-form-item-control-input-content {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
`;

export const FormItemStyled = styled(Form.Item)<FormItemLabelStyledProps>`
  margin-bottom: ${({ marginBottom }): string => marginBottom || "15px"};
  margin-top: ${({ marginTop }): any => marginTop};
  padding-left: ${({ paddingLeft }): string => paddingLeft || "20px"};
  .label-desc {
    font-size: 12px;
    color: ${({ theme }): string => theme.textColors.GREY_24};
    width: 70%;
  }
`;

export const FormHeaderStyled = styled(Form.Item)<{
  fontSize?: string;
  paddingLeft?: string;
  marginBottom?: string;
}>`
  color: ${({ theme }): string => theme.textColors.DARK_BLUE};
  font-family: OpenSansSemiBold;
  font-size: ${({ fontSize }): string => fontSize || "20px"};
  padding-left: ${({ paddingLeft }): string => paddingLeft || "20px"};
  margin-bottom: ${({ marginBottom }): string => marginBottom || "14px"};
`;

export const CheckboxWrapperStyled = styled.div<{
  paddingLeft?: string;
  marginBottom?: string;
  marginTop?: string;
  width?: string;
}>`
  margin-top: ${({ marginTop }): string => marginTop || "0"};
  margin-bottom: ${({ marginBottom }): string => marginBottom || "15px"};
  padding-left: ${({ paddingLeft }): string => paddingLeft || "334px"};
  display: flex;
  align-items: flex-end;

  .info-icon {
    svg {
      padding-top: 5px;
    }
  }

  .ant-checkbox-wrapper {
    justify-content: center;
    align-items: flex-start;
  }

  .ant-checkbox + span {
    // padding-top: 8px;
    padding-left: 8px;
    font-size: 14px;

    svg {
      margin-left: 8px;
      padding-top: 4px;
    }
  }

  .checkbox-tooltip {
    .ant-tooltip-content {
      .ant-tooltip-arrow {
        .ant-tooltip-arrow-content {
          box-shadow: 0 0 7px 0 rgba(211, 211, 211, 0.85);
          background-color: ${({ theme }): string =>
            theme.bgColors.WHITE_COLOR};
          width: 8px;
          height: 8px;
        }
      }
      .ant-tooltip-inner {
        width: 400px;
        min-height: 82px;
        padding: 16px 9.2px 18px 15px;
        box-shadow: 0 0 7px 0 rgba(211, 211, 211, 0.85);
        background-color: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
        font-size: 13px;
        color: ${({ theme }): string => theme.textColors.GREY_26};
      }
    }
  }
`;

export default FormStyled;
