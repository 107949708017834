import styled from "styled-components";
import { TextStyledProps } from "./text.types";

export const TextStyled = styled.div<TextStyledProps>`
  color: ${({ theme, isPrimary }): string =>
    isPrimary ? theme?.textColors?.BLUE_116 : theme?.textColors?.GREY_24};
  font-size: ${({ fontSize }): number => fontSize}px;
  font-family: ${({ isBold }): string => (isBold ? "OpenSansSemiBold" : "")};
  margin-top: ${({ spacing }): number => spacing?.top}px;
  margin-bottom: ${({ spacing }): number => spacing?.bottom}px;
  margin-right: ${({ spacing }): number => spacing?.right}px;
  margin-left: ${({ spacing }): number => spacing?.left}px;
`;
