import { useCallback } from "react";

import { Button } from "..";

import { useCancelModal, useGetAppState } from "../../customhooks";

import { DialogBoxProps } from "./dialogbox.types";

import { DialogBoxStyled } from "./dialogbox.styles";
import { useRequestWithMethod } from "../../api";
import StateHandler from "../statehandler/statehandler";

const DialogBox = (): JSX.Element => {
  const {
    modal: { modalProps = {} },
  } = useGetAppState();

  const {
    content,
    okText,
    api,
    onSuccess,
    cancelOnSuccess,
  } = modalProps as DialogBoxProps;

  const { isLoading, error, onExecuteRequest } = useRequestWithMethod(
    api?.apiKey || "delete_tagset",
    api?.urlParams,
    false,
    api?.successFn
  );

  const handleCancel = useCancelModal();
  const handleSuccess = useCallback(() => {
    const apiBody = api?.body;

    if (api) {
      onExecuteRequest(apiBody);
    } else {
      onSuccess?.();
      cancelOnSuccess && handleCancel?.();
    }
  }, [api]);

  return (
    <StateHandler isFetching={isLoading} error={error} isModal>
      <DialogBoxStyled>
        <div className="content-sec">
          <div className="static-text">{content || ""}</div>
        </div>
        <div className="modal-actions-sec">
          <Button
            id="cancel"
            data-testid="dialogbox-cancel-btn"
            width="78px"
            onClick={handleCancel}
          >
            Cancel
          </Button>

          <Button
            id="primary"
            data-testid="dialogbox-ok-btn"
            width="78px"
            onClick={handleSuccess}
          >
            {okText || "OK"}
          </Button>
        </div>
      </DialogBoxStyled>
    </StateHandler>
  );
};

export default DialogBox;
