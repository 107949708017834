// <--- Library Imports Start --->
import { AxiosResponse } from "axios";
// <--- Library Imports End --->

// <--- Constants Start --->
import { COLUMN_SECTION_TITLES } from "../../constants/labelconstants";
// <--- Constants End --->

// <--- Types Start --->
import { DQScoreProps } from "../../components/dqscore/dqscore.types";
import { SectionRowProps } from "../../components/sectionrow/sectionrow.types";

import {
  ColTypeIds,
  ColRefClassEntityAddInfoType,
  ColumnDataType,
  ColumnDocResponse,
  ColumnRefDataQualityRulesAdditionalInfo,
  ColumnRefDataTypeAdditionalInfo,
  ColumnRefProfillingInfoAdditionalInfo,
  ColumnRefValiditityAdditionalInfo,
  ColumnRefVisDisAdditionalInfo,
  ColumnResponse,
  DataConnectionFields,
  DQSummaryType,
  MatchingEntityResponseType,
  MatchingEntityType,
  MatchingStaus,
  ReturnTypeOfColumnDoc,
  ReturnTypeOfColumnsParser,
  ReturnTypeOfDataSourceFieldDetailsParser,
  SampleDataHeaderChart,
} from "./columnparsers.types";
// <--- Types End --->

// <--- Utils Start --->
import {
  checkDataTypeOfColumn,
  convertNoHyphenDateToValidDate,
  formatDate,
  getObjectKeys,
  getProminentTags,
  getTimeDifference,
  jsonParse,
  maskString,
  sumTupleElement,
} from "../../utils";

import { getProfilingInfo } from "../../utils/getProfilingInfo";
import { utcTOLocalTimeZone } from "../../utils/utctolocaltimezone";

import {
  getClassificationCustomTags,
  getLineagePreviewData,
} from "../parserutils";

import { computeColTypeID } from "./columnparser.utils";
import { X_AXIS_DATA_KEY, YEAR_MONTH_DATE } from "../../constants";

import { COLUMN_VISUAL_DIST_DATA_KEY } from "./columnparser.constants";
import { momentFormatDateNoTimezone } from "../../utils/momentFormatDateNoTimezone";
// <--- Utils End --->

function getColumnChartData(
  data: SampleDataHeaderChart,
  colDataType: ColumnDataType,
  colTypeId: ColTypeIds
): { name: string; Count: number; fill: string }[] {
  const { isNumberCol, isDateColumn } = checkDataTypeOfColumn(colDataType);

  const distCount = getObjectKeys(data?.DIST)?.length || 0;
  const histCount = getObjectKeys(data?.HIST)?.length || 0;

  const distData = isNumberCol
    ? distCount > 20 || !distCount
      ? data?.HIST
      : data?.DIST
    : isDateColumn
    ? histCount
      ? data?.HIST
      : data?.DIST
    : data?.DIST;

  const chartData = getObjectKeys(distData)?.map((item) => ({
    [X_AXIS_DATA_KEY]: isDateColumn
      ? momentFormatDateNoTimezone(
          convertNoHyphenDateToValidDate(item),
          "MM/DD/YY"
        )
      : item,

    [COLUMN_VISUAL_DIST_DATA_KEY]: Number(distData?.[item]),
    fill: "#4894ca",
  }));

  const sortedChartData = isNumberCol
    ? chartData?.sort(
        (a, b) =>
          sumTupleElement(a?.[X_AXIS_DATA_KEY]) -
          sumTupleElement(b?.[X_AXIS_DATA_KEY])
      )
    : isDateColumn
    ? chartData
    : chartData?.sort(
        (a, b) =>
          b?.[COLUMN_VISUAL_DIST_DATA_KEY] - a?.[COLUMN_VISUAL_DIST_DATA_KEY]
      );

  return sortedChartData;
}

function getMatchingEntities(entitiesJson: string): MatchingEntityType[] {
  const matchingEntities: MatchingEntityResponseType[] = jsonParse(
    entitiesJson
  );

  return (
    matchingEntities?.map((entity) => ({
      entity_id: Number.parseInt(entity?.entity_id) || 0,
      entity_name: entity?.entity_name || "",
      description: entity?.entity_desc || "",
      entity_status: entity?.entity_status || "",
      entity_status_id: entity?.entity_status_id || "",
      matching_score: entity?.entity_matching_score || 0,
      matching_source: entity?.entity_match_source || "AAI",
      selected: entity?.entity_selection_status === "SEL",
    })) || []
  );
}

function getDQScore(dqScoreJSON: string): DQScoreProps {
  const { DQ_STATUS_ID, DQ_STATUS = "", SCORE, DRIFT_VAR } = jsonParse(
    dqScoreJSON,
    true
  ) as DQSummaryType;

  return {
    score: `${SCORE}`,
    trend: `${DRIFT_VAR}`,
    status: DQ_STATUS,
    statusId: DQ_STATUS_ID,
  };
}

function getColumnSummary(
  data: ColumnResponse[],
  isFieldView = false
): SectionRowProps[] {
  const filterSameGroupOfColumnType = (
    colType: ColumnResponse["COL_TYPE"]
  ): string[] =>
    data
      ?.filter((item) => item?.COL_TYPE === colType)
      ?.map((nestItem) => nestItem?.COL_TITLE || nestItem?.COL_NAME)
      ?.sort((a, b) => a?.toLowerCase()?.localeCompare(b?.toLowerCase()));
  return [
    {
      title: isFieldView ? "Total fields" : "Total Columns",
      value: `${data?.length || 0}`,
    },
    {
      title: "Granularity",
      stringValues: filterSameGroupOfColumnType("Identifier"),
    },
    {
      title: "Attributes",
      stringValues: filterSameGroupOfColumnType("Attribute"),
    },
    {
      title: "Measures",
      stringValues: filterSameGroupOfColumnType("Measure"),
    },
    {
      title: "Time Series",
      stringValues: filterSameGroupOfColumnType("Timeseries"),
    },
  ];
}

export function getTableColumsDetails({
  data,
}: AxiosResponse<ColumnResponse[]>): ReturnTypeOfColumnsParser {
  const maskDefinition = (str: string): string => {
    const strToBeMasked = str?.includes("e.g.")
      ? str?.split("e.g.")?.slice(-1)?.[0]
      : "";

    const maskedStr = maskString(strToBeMasked);
    return str?.replace(strToBeMasked, maskedStr);
  };

  return {
    column_details:
      data?.map((column) => {
        const minMax = `${column?.COL_MIN_VALUE || ""} - ${
          column?.COL_MAX_VALUE || ""
        }`;
        return {
          column_name: column?.COL_NAME || "",
          description: column?.IS_COL_MASKED
            ? maskDefinition(column?.COL_USER_DESC || "")
            : column?.COL_USER_DESC || "",
          data_type: column?.COL_DATA_TYPE_ID,
          data_type_info: column?.COL_DATA_TYPE || "",
          column_type: column?.COL_TYPE || "",
          column_type_id: column?.COL_TYPE_ID || "",
          entity: column?.COL_ENTITY_NAME || "",
          col_status: column?.COL_STATUS || "",
          col_status_id: column?.COL_STATUS_ID,
          profiling: getProfilingInfo(
            column?.COL_RECORD_CNT,
            column?.COL_DIST_CNT,
            column?.COL_BLANKS_CNT
          ),
          min_max: column?.IS_COL_MASKED ? maskString(minMax) : minMax,
          is_endorsed: column?.COL_ENDORSED_STATUS,
          endorsed_by: column?.COL_ENDORSED_BY || "",
          updated_by: column?.COL_UPDATED_BY || "",
          endorsed_on: getTimeDifference(
            utcTOLocalTimeZone(column?.COL_ENDORSED_ON) || ""
          ),
          updated_on: getTimeDifference(
            utcTOLocalTimeZone(column?.COL_UPDATED_ON) || ""
          ),
          is_recomended_by_ai: column?.IS_ENTITY_AUTO_ASSIGNED,
          column_id: column?.COL_ID,
          entity_id: column?.COL_ENTITY_ID,
          is_desc_inherited: column?.IS_DESC_INHERITED,
          entity_status: column?.TRM_STATUS || "",
          entity_status_id: column?.TRM_STATUS_ID,
          match_info: {
            matching_status: (column?.COL_ENTITY_MATCH_STATUS_ID ||
              "") as MatchingStaus,
            confidence_score: Math.round(
              Number.parseFloat(column?.COL_ENTITY_MATCH_SCORE)
            ),
          },
          matching_entities: getMatchingEntities(column?.COL_ENTITY_JSON),
          column_display_name: column?.COL_TITLE || column?.COL_NAME || "",
          dq_score: getDQScore(column?.COL_DQ_SUMMARY),
          alert: column?.IS_COL_HAS_ALERTS || false,
          table_display_name: column?.TBL_NAME || "",
          source_display_name: column?.SRC_NAME || "",
          is_cde_field: column?.IS_COL_CDE ? "Yes" : "",
          buiseness_rule: column?.COL_BIZ_RULES || "",
          col_type_id: computeColTypeID(column),
          col_dist_count: getObjectKeys(
            (jsonParse(column?.COL_DISTRIBUTION, true) as SampleDataHeaderChart)
              ?.DIST
          )?.length,
          is_col_masked: !!column?.IS_COL_MASKED,
        };
      }) || [],
    column_summary: getColumnSummary(data),
    sample_data_header_rendrer:
      data?.map((column) => ({
        column_id: `${column?.COL_ID || ""}`,
        column_name: column?.COL_NAME || "",
        column_display_name: column?.COL_TITLE || column?.COL_NAME || "",
        description: column?.IS_COL_MASKED
          ? maskDefinition(column?.COL_USER_DESC || "")
          : column?.COL_USER_DESC || "",
        data_type: column?.COL_DATA_TYPE_ID,
        data_type_info: column?.COL_DATA_TYPE || "",
        profiling: getProfilingInfo(
          column?.COL_RECORD_CNT,
          column?.COL_DIST_CNT,
          column?.COL_BLANKS_CNT
        ),
        is_endorsed: column?.COL_ENDORSED_STATUS,
        endorsed_by: column?.COL_ENDORSED_BY || "",
        col_status: column?.COL_STATUS || "",
        col_status_id: column?.COL_STATUS_ID,
        chart_data: column?.IS_COL_MASKED
          ? []
          : getColumnChartData(
              jsonParse(
                column?.COL_DISTRIBUTION,
                true
              ) as SampleDataHeaderChart,
              column?.COL_DATA_TYPE_ID,
              column?.COL_TYPE_ID
            ),
        is_col_masked: !!column?.IS_COL_MASKED,
      })) || [],
  };
}

export function getColumnParsedDoc({
  data,
}: AxiosResponse<ColumnDocResponse>): ReturnTypeOfColumnDoc {
  const classificationData =
    getClassificationCustomTags(data?.classifications || {}) || [];

  const {
    dq_status_id: dqStatusId,
    dq_status: dqStatus = "",
    dq_score: dqScore,
    dq_variance: dqVariance,
  } = data?.quality || {};

  const { is_autoset: isAutoSet, value } = data?.data_quality_rules || {};
  const { CMP, SHP, UNQ, VLD } = value || {};

  return {
    publish_status: data?.published_status,
    publish_status_id: data?.published_status_id,
    node_title: data?.title?.value || "",
    node_name: data?.physical_name || "",
    source_name: data?.source_name || "",
    source_id: `${data?.source_id || ""}`,
    parent_id: `${data?.parent_id || ""}`,
    parent_name: data?.parent_name || "",
    lineage: getLineagePreviewData(data?.lineage),
    node_id: `${data?.node_id || ""}`,
    is_rule_auto_set: isAutoSet,
    entity_id: data?.classifications?.entity?.value?.entity_id || "",
    is_col_desc_inherited: !!data?.description?.description?.is_inherited,
    is_dq_enabled: data?.is_dq_enabled || false,
    information: [
      {
        title: COLUMN_SECTION_TITLES?.information?.meta_score,
        value: data?.description?.metadata_score?.value || "",
      },
      {
        title: COLUMN_SECTION_TITLES?.information?.description,
        value: data?.description?.description?.value || "",
      },
      {
        title: COLUMN_SECTION_TITLES?.information?.data_type,
        value: data?.description?.data_type_id || "",
        additionalInfo: {
          data_type_info: data?.description?.data_type || "",
        } as ColumnRefDataTypeAdditionalInfo,
      },
      {
        title: COLUMN_SECTION_TITLES?.information?.example,
        value: data?.description?.example || "",
      },
    ],

    classification: [
      {
        title: COLUMN_SECTION_TITLES?.classification?.entity,
        value: data?.classifications?.entity?.value?.entity_name || "",
        additionalInfo: {
          entity_matching_status:
            data?.classifications?.entity?.value?.entity_match_status,
        } as ColRefClassEntityAddInfoType,
        selectedValueOfTags: data?.classifications?.entity?.value?.entity_name
          ? [
              {
                label: data?.classifications?.entity?.value?.entity_name || "",
                value: data?.classifications?.entity?.value?.entity_id || "",
              },
            ]
          : undefined,
      },
      {
        title: COLUMN_SECTION_TITLES?.classification?.column_type,
        value: data?.classifications?.col_type || "",
      },
      {
        title: COLUMN_SECTION_TITLES?.classification?.column_sub_type,
        value: data?.classifications?.col_subtype || "",
      },
      {
        title: COLUMN_SECTION_TITLES?.classification?.is_cde,
        value: data?.classifications?.is_col_cde ? "Yes" : "",
      },
      {
        title: COLUMN_SECTION_TITLES?.classification?.functional_area,
        value: data?.functional_area_name || "",
      },
      ...classificationData,
    ],
    data_quality_rules: [
      {
        title: SHP?.rule_cat_name || "",
        id: "data_format",
        additionalInfo: {
          checked: SHP?.rules?.CDF?.dq_rule_status_id !== "INA",
        } as ColumnRefDataQualityRulesAdditionalInfo,
      },
      {
        title: CMP?.rule_cat_name || "",
        id: "completeness",
        additionalInfo: {
          checked: CMP?.rules?.CCP?.dq_rule_status_id !== "INA",
        } as ColumnRefDataQualityRulesAdditionalInfo,
      },
      {
        title: COLUMN_SECTION_TITLES?.data_quality_rules?.validity,
        additionalInfo: {
          completenes_value:
            VLD?.rules?.CCV?.dq_rule_status_id !== "INA" ||
            VLD?.rules?.CPV?.dq_rule_status_id !== "INA" ||
            VLD?.rules?.CVR?.dq_rule_status_id !== "INA",
        } as ColumnRefValiditityAdditionalInfo,
        subList: [
          {
            title: VLD?.rules?.CCV?.dq_rule_name || "",
            stringValues: getObjectKeys(
              VLD?.rules?.CCV?.Values || {
                None: "",
              }
            ),
          },
          {
            title: VLD?.rules?.CVR?.dq_rule_name,
            value: `${VLD?.rules?.CVR?.Values?.MIN || ""} - ${
              VLD?.rules?.CVR?.Values?.MAX || ""
            }`,
          },
          {
            title: VLD?.rules?.CPV?.dq_rule_name || "",
            stringValues: getObjectKeys(
              VLD?.rules?.CPV?.Values || {
                None: "",
              }
            ),
          },
        ],
      },
      {
        title: UNQ?.rule_cat_name || "",
        id: "uniqueness",
        additionalInfo: {
          checked: UNQ?.rules?.CUQ?.dq_rule_status_id !== "INA",
        } as ColumnRefDataQualityRulesAdditionalInfo,
      },
    ],
    profillingInfo: [
      {
        title: COLUMN_SECTION_TITLES?.profiling_information?.count,
        additionalInfo: {
          profillingInfo: getProfilingInfo(
            data?.profiling_info?.count?.record_count,
            data?.profiling_info?.count?.distinct_count,
            data?.profiling_info?.count?.blanks_count
          ),
        } as ColumnRefProfillingInfoAdditionalInfo,
      },
      {
        title: COLUMN_SECTION_TITLES?.profiling_information?.min_max,
        value: data?.profiling_info?.min_max || "",
      },
      {
        title:
          COLUMN_SECTION_TITLES?.profiling_information?.visual_distribution,
        additionalInfo: {
          visual_distribution_chart_data:
            getColumnChartData(
              data?.profiling_info?.distribution,
              data?.description?.data_type_id,
              data?.node_sub_type
            ) || [],
          col_data_type_id: data?.description?.data_type_id,
        } as ColumnRefVisDisAdditionalInfo,
      },
    ],
    quality: [
      {
        title: COLUMN_SECTION_TITLES?.quality?.overall_score,
        value: dqScore || "",
        additionalInfo: {
          status: dqStatus,
          statusId: dqStatusId,
          score: dqScore,
          trend: dqVariance,
        },
      },
      {
        title: COLUMN_SECTION_TITLES?.quality?.last_updated,
        value: `Last updated ${getTimeDifference(
          utcTOLocalTimeZone(data?.last_refreshed_on || "")
        )}`,
      },
    ],
    is_editable: !!data?.is_editable,

    prominent_tags: {
      tags: getProminentTags(classificationData),
      isBoldView: true,
    },
  };
}

export function getDataSourceFieldsDetails({
  data = [],
}: AxiosResponse<ColumnResponse[]>): ReturnTypeOfDataSourceFieldDetailsParser {
  return {
    column_summary: getColumnSummary(data, true),
    field_details: data?.map((item) => ({
      id: `${item?.COL_ID || ""}`,
      data_connection_fields: (jsonParse(
        item?.DATA_CONNECTION_FIELDS,
        false
      ) as DataConnectionFields)?.map((conectionField) => ({
        name: conectionField?.col_name || "",
        id: `${conectionField?.col_id || ""}`,
      })),
      data_source_field: item?.COL_TITLE || item?.COL_NAME || "",
      data_type: item?.COL_DATA_TYPE_ID,
      entity: item?.COL_ENTITY_NAME || "",
      entity_id: `${item?.COL_ENTITY_ID || ""}`,
      field_desc: item?.COL_USER_DESC || "",
      field_type: item?.COL_TYPE,
      field_status_id: item?.COL_STATUS_ID,
      field_status: item?.COL_STATUS || "",
      data_type_title: item?.COL_DATA_TYPE || "",
    })),
  };
}
