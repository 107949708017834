import { useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { SelectField } from "../formfields";
import { DateFormatInputStyled } from "./dateformatinput.styles";
import { DateFormatInputType } from "./dateformatinput.types";
import { DateFormat, getCurrentFormattedDate } from "./dataformat.util";
import { getObjectKeys, selectFilterOption } from "../../utils";
import { MOMENT_DATE_FORMATS } from "../../constants";

const DateFormatInput = (props: DateFormatInputType): JSX.Element => {
  const { control, setValue } = useFormContext();
  const { onChange, disabled, width } = props;

  const DATE_FORMAT_CONFIG_OPTIONS = useMemo(() => {
    const momentDateFormats = getObjectKeys(MOMENT_DATE_FORMATS);

    return momentDateFormats?.map((formatItem) => {
      const dateFormatWithExample = getCurrentFormattedDate(
        formatItem as DateFormat
      );
      return {
        labelText: formatItem,
        value: formatItem,
        label: (
          <div className="date-format-wrapper" title={dateFormatWithExample}>
            <div className="date-format-label">{formatItem}</div>
            <div className="date-format-example">({dateFormatWithExample})</div>
          </div>
        ),
      };
    });
  }, []);

  return (
    <DateFormatInputStyled width={width}>
      <SelectField
        className="date-format-select"
        options={DATE_FORMAT_CONFIG_OPTIONS}
        control={control}
        name="date_format"
        setValue={setValue}
        isAllowClear={false}
        propOnChange={onChange}
        filterOption={selectFilterOption}
        showSearch
        disabled={disabled}
      />
    </DateFormatInputStyled>
  );
};

export default DateFormatInput;
