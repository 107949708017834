import { Tooltip } from "antd";
import { useCallback } from "react";

//              <--Constants Start-->
import { DVSUM_TOOLTIP_CLASS_NAME } from "../../../constants";
//              <--Constants End-->

//              <--Styles Start-->
import { TagListStyled, TagsRemainingSecStyled } from "./taglist.styles";
//              <--Styles End-->

//              <--Types Start-->
import { TagListProps } from "./taglist.types";
//              <--Types End-->

const TagList = (props: TagListProps): JSX.Element => {
  const { tags: allTags, type = "primary", tagstoShowUpfront = 1 } = props;
  const tags = allTags?.filter((tag) => tag !== "");

  const upfrontTags = tags?.slice(0, tagstoShowUpfront);
  const remainingTags = tags?.slice(tagstoShowUpfront, tags?.length);

  const handleClick = useCallback((event: any) => {
    event?.stopPropagation();
  }, []);

  return (
    <TagListStyled
      type={type}
      className="tags-list"
      data-testid="tag-list-wrapper"
    >
      {tags && tags.length >= 1 && (
        <>
          {upfrontTags?.map((item, index) => (
            <div
              title={item}
              className="tag-name"
              onClick={handleClick}
              role="button"
              key={`upfront-tags-tag-${item}-${index}`}
              data-testid="tag-list-upfront-tag"
            >
              <span className="tag-name-text">{item}</span>
            </div>
          ))}

          {!!remainingTags.length && (
            <Tooltip
              overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
              placement="bottomLeft"
              title={
                <TagsRemainingSecStyled>
                  {remainingTags?.map((tag = "", index) => (
                    <div
                      className="remaining-tags-tag"
                      title={tag}
                      key={`remaining-tags-tag-${tag}-${index}`}
                      data-testid="tag-list-remaining-tag"
                    >
                      {tag}
                    </div>
                  ))}
                </TagsRemainingSecStyled>
              }
            >
              <div
                className="count"
                role="button"
                onClick={handleClick}
                data-testid="tag-list-count"
              >
                +{remainingTags.length || 1}
              </div>
            </Tooltip>
          )}
        </>
      )}
    </TagListStyled>
  );
};

export default TagList;
