import { useMemo } from "react";

import {
  QueryBlockColumnProfile,
  QueryBlockProfile,
} from "./queryblockprofiling.comp";

import { QueryBlockProfilingStyled } from "./queryblockprofiling.styles";

import { QueryBlockProfilingProps } from "./queryblockprofiling.types";

import { checkDataTypeOfColumn } from "../../../../../../../../../../utils";

import { getProfilingChartData } from "../../../../../../../../../../utils/getprofilingchartdata";
import ConditionalDisplay from "../../../../../../../../../../components/conditionaldisplay/conditionaldisplay";
import { checkIsChatPage } from "../../../../../../../../../../utils/ischatpage";

const QueryBlockProfiling = (props: QueryBlockProfilingProps): JSX.Element => {
  const { queryItem, handlers, isFirstQueryProfiling = false } = props;

  const { results } = queryItem || {};

  const {
    columns_profilig: columnsProfilig = [],
    columns = [],
    selected_cell: selectedCell,
  } = results || {};

  const isChatPage = checkIsChatPage();

  const selectedColProf = useMemo(() => {
    const selCol = columns?.find((col) => col?.is_selected);
    const selColProf = columnsProfilig?.find(
      (colProf) => colProf?.column_id === selCol?.column_id
    );
    return selColProf;
  }, [columns, columnsProfilig]);

  const selectedColValProf = useMemo(() => {
    const selColValProf = columnsProfilig?.find(
      (colProf) => colProf?.column_id === selectedCell?.col_id
    );

    const { chart_data: chartData = [], data_type: dataType } =
      selColValProf || {};

    const { isDateColumn, isNumberCol } = checkDataTypeOfColumn(
      dataType || "BLB"
    );

    const {
      numberChartDataProf,
      dateChartProf,
      stringChartDataProf,
    } = getProfilingChartData(dataType || "BLB", chartData, selectedCell);

    return selColValProf
      ? isNumberCol
        ? {
            ...selColValProf,
            chart_data: numberChartDataProf,
          }
        : isDateColumn
        ? {
            ...selColValProf,
            chart_data: dateChartProf,
          }
        : {
            ...selColValProf,
            chart_data: stringChartDataProf,
          }
      : undefined;
  }, [selectedCell, columnsProfilig]);

  const columnProfile = columns?.map((item) => (
    <>
      {selectedColValProf &&
        item?.column_id === selectedColValProf?.column_id && (
          <QueryBlockColumnProfile
            isFirstQueryProfiling={isFirstQueryProfiling}
            columnProfile={selectedColValProf}
            queryItem={queryItem}
            handlers={handlers}
            isCellProfile
          />
        )}
      {selectedColProf && item?.column_id === selectedColProf?.column_id && (
        <QueryBlockColumnProfile
          isFirstQueryProfiling={isFirstQueryProfiling}
          columnProfile={selectedColProf}
          queryItem={queryItem}
          handlers={handlers}
        />
      )}
    </>
  ));

  return (
    <QueryBlockProfilingStyled isChatPage={isChatPage}>
      {columnProfile}
      <ConditionalDisplay condition={!selectedColProf && !selectedColValProf}>
        <QueryBlockProfile
          isFirstQueryProfiling={isFirstQueryProfiling}
          queryItem={queryItem}
          handlers={handlers}
        />
      </ConditionalDisplay>
    </QueryBlockProfilingStyled>
  );
};

export default QueryBlockProfiling;
