import { useCallback } from "react";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

import { useController } from "react-hook-form";
import { InputFieldType } from "./inputfield.types";

import {
  InputFieldContainerStyled,
  InputFieldStyled,
  PasswordInputFieldStyled,
} from "./inputfield.styles";

function InputField({
  isPasswordField,
  width,
  name = "",
  control,
  isMessagePositionAbsolute = false,
  propOnChange,
  isMinMaxButtonHidden = true,
  ...props
}: InputFieldType): JSX.Element {
  const {
    field: { ref, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const handleChange = useCallback(
    (event) => {
      inputProps?.onChange(event);
      propOnChange && propOnChange(event);
    },
    [inputProps]
  );

  return isPasswordField ? (
    <InputFieldContainerStyled
      isMessagePositionAbsolute={isMessagePositionAbsolute}
      className="input-field-container"
    >
      <PasswordInputFieldStyled
        {...props}
        {...inputProps}
        ref={ref}
        type="password"
        width={width}
        autoComplete="off"
        iconRender={(visible): JSX.Element =>
          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
        }
      />

      <div className="error">{error?.message}</div>
    </InputFieldContainerStyled>
  ) : (
    <InputFieldContainerStyled
      isMessagePositionAbsolute={isMessagePositionAbsolute}
      className="input-field-container"
    >
      <InputFieldStyled
        {...props}
        {...inputProps}
        onChange={handleChange}
        ref={ref}
        width={width}
        isMinMaxButtonHidden={isMinMaxButtonHidden}
      />
      <div className="error">{error?.message}</div>
    </InputFieldContainerStyled>
  );
}

export default InputField;
