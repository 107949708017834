import styled from "styled-components";
import { ellipsis } from "../../styles/app.styles";

export const ManagedBySectionRowStyled = styled.div<{
  marginBottom?: string;
  isEditable?: boolean;
}>`
  margin-bottom: ${(props): string => props?.marginBottom || "17px"};
  .title {
    color: ${({ theme }): string => theme.textColors.BLUE_116};
    font-family: OpenSansSemiBold;
    margin-bottom: 11px;
    > svg {
      margin-left: 9px;
    }
  }
  .value-sec {
    display: flex;
    flex-wrap: wrap;
    .value {
      color: ${({ theme }): string => theme.textColors.DARK_GREY_10};

      margin-right: 10px;
      ${ellipsis}
      > svg {
        margin-right: 10px;
      }
    }
  }

  .empty-value {
    color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
    align-items: center;
    > svg {
      margin-right: 10px;
    }
  }

  ${({ isEditable, theme }): any =>
    !isEditable &&
    `
  display:flex;
  .title{
    width:33%;
    color: ${theme.textColors.DARK_GREY_10};
    font-family:OpenSans;
    margin-bottom:0px;
  }
  .value-sec,.value-sec-custom-jsx{
    width:66%;
    display:flex;
    gap:5px;
    button{
      height:max-content;
      svg{
        margin-top:2px;
        cursor:pointer;
        path{
          fill : #428BCA;
          
        }
      }
    }
   
  }
  .value-sec {
    svg{
      path{
        fill:${theme.bgColors.GREY_COLOR_12}
      }
    }
  }
  `}
  .section-vertical-column-edit-mode {
    .title {
      width: 100% !important;
    }
  }
`;

export const OptionRendrerStyled = styled.div`
  &.custom-option {
    > svg {
      margin-right: 12px;
      min-width: 20px;
      path {
        fill: ${({ theme }): string => theme.bgColors.BLUE_105};
      }
    }
    ${ellipsis}
  }
`;
