import { useCallback, useState } from "react";

import Button from "../button";
import DvSumPopover from "../dvsumpopover";

import {
  ConfirmationPopoverContentStyled,
  ConfirmationPopoverStyled,
} from "./confirmationpopover.styles";

import {
  ConfirmationPopoverContentProps,
  ConfirmationPopoverProps,
} from "./confirmationpopover.types";

import { ELEMENT_IDS } from "../../constants";
import { useGetAppState } from "../../customhooks";

const ConfirmationPopoverContent = (
  props: ConfirmationPopoverContentProps
): JSX.Element => {
  const {
    onCancel,
    onOk,
    okText,
    cancelText,
    heading,
    desc,
    width = "",
  } = props;
  return (
    <ConfirmationPopoverContentStyled width={width}>
      <div className="heading">{heading}</div>
      <div className="desc">{desc}</div>
      <div className="action-sec">
        <Button
          id="cancel"
          height="27.2px"
          onClick={onCancel}
          elementId={ELEMENT_IDS.chat_bot_refr_all_conf_popovr_cancel_btn}
          data-testid="confirmation-popover-cancel-btn"
        >
          {cancelText}
        </Button>
        <Button
          id="primary"
          height="27.2px"
          onClick={onOk}
          elementId={ELEMENT_IDS.chat_bot_refr_all_conf_popovr_ok_btn}
          data-testid="confirmation-popover-ok-btn"
        >
          {okText}
        </Button>
      </div>
    </ConfirmationPopoverContentStyled>
  );
};
const ConfirmationPopover = (props: ConfirmationPopoverProps): JSX.Element => {
  const {
    children,
    placement = "bottomRight",
    arrowPointAtCenter = false,
    onCancel,
    onOk,
    visible,
    zIndex,
    propOnVisibleChange,
    ...resProps
  } = props;

  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const { isOnboardingMode } = useGetAppState();

  const onVisibleChange = useCallback(
    (visibility: boolean) => {
      setIsPopoverVisible(visibility);
      propOnVisibleChange?.(visibility);
    },
    [propOnVisibleChange]
  );

  const onCancelClick = useCallback(() => {
    onCancel?.();
    setIsPopoverVisible(false);
    propOnVisibleChange?.(false);
  }, [onCancel]);

  const onOkClick = useCallback(() => {
    onOk?.();
    setIsPopoverVisible(false);
    propOnVisibleChange?.(false);
  }, [onOk]);

  return (
    <ConfirmationPopoverStyled>
      <DvSumPopover
        content={
          <ConfirmationPopoverContent
            {...resProps}
            onCancel={onCancelClick}
            onOk={onOkClick}
          />
        }
        placement={placement}
        trigger="click"
        {...(isOnboardingMode && !isPopoverVisible && { onVisibleChange })}
        {...(!isOnboardingMode ? { onVisibleChange } : {})}
        visible={visible || isPopoverVisible}
        arrowPointAtCenter={arrowPointAtCenter}
        zIndex={zIndex}
        onVisibleChange={onVisibleChange}
        showArrow
      >
        {children}
      </DvSumPopover>
    </ConfirmationPopoverStyled>
  );
};

export default ConfirmationPopover;
