import { Spin } from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

//          <--Components Start-->
import { SelectField } from "..";
//          <--Components End-->

//          <--Custom Hooks Start-->
import { useDebounce } from "../../../customhooks";
//          <--Custom Hooks End-->

//          <--Renderers start-->
import { optionRendrer } from "../../managedbysectionrow/rendrer";
//          <--Renderers End-->

//          <--Service Start-->
import { useGetDropdownOptios } from "../../../api/sectionwithverticalcolumnservice";
//          <--Service End-->

//          <--Styles Start-->
import { TagStyled } from "./multiselectusersfield.styles";
import { LoadingSpinerStyled } from "../../../styles/app.styles";
//          <--Styles End-->

//          <--Types Start-->
import { UserOrUsersGroupParsedType } from "../../../parsers/tablepage/tablepageparser.types";
import { SelectFieldOption } from "../selectfield/selectfield.types";

import { MultiSelectUsersFieldTypes } from "./multiselectusersfield.types";
//          <--Types End-->

//          <--Utils Start-->
import { getPostLoginData } from "../../../utils/getpostlogindata";
import { useOutsideClick } from "../../../utils/useoutsideclick";
//          <--Utils End-->

const SELF_SEARCH_KEYWORDS = ["me", "mine", "self"];

const MultiSelectUsersField = ({
  control,
  name,
  defaultValue,
  setValue,
  height = "32px",
  width = "416px",
  isShareForm = false,
  excludeIdsList = [],
  excludeUsers = [],
  isLineageShareForm = false,
  onlyUsersWithAdminAndEditorRole = false,
  onlyUsers = false,
  nodeType,
  ...props
}: MultiSelectUsersFieldTypes): JSX.Element => {
  const [visible, setVisible] = useState(false);
  const [searchText, setText] = useState<string>("");
  const selectRef: any = useRef();

  const { user_info: userInfo = {} } = getPostLoginData();

  const { user_name: userName = "" } = userInfo;

  const {
    parsedData: parsedSortedUsers = [],
    status,
    refetch,
  } = useGetDropdownOptios(
    "get_user_users_groups",
    onlyUsers ? ["USR"] : ["USR,UGP"],
    isLineageShareForm
      ? SELF_SEARCH_KEYWORDS?.includes(searchText?.toLowerCase())
        ? encodeURIComponent(userName)
        : searchText || ""
      : searchText || "",
    onlyUsersWithAdminAndEditorRole,
    nodeType
  );

  const onSearch = useCallback(
    (val) => {
      setText(val || "");
    },
    [searchText]
  );

  const searchDebounce = useDebounce(searchText, 500);

  useEffect(() => {
    searchDebounce && refetch();
  }, [searchDebounce]);

  const sortedUsers = useMemo(() => {
    return isShareForm
      ? isLineageShareForm
        ? parsedSortedUsers
        : parsedSortedUsers?.filter(
            (user: UserOrUsersGroupParsedType) =>
              user?.value !== userInfo?.user_name &&
              !excludeUsers?.includes(user?.value) &&
              !excludeIdsList?.includes(user?.id || "")
          )
      : parsedSortedUsers?.filter(
          (user: UserOrUsersGroupParsedType) =>
            !excludeUsers?.includes(user?.value) &&
            !excludeIdsList?.includes(user?.id || "")
        );
  }, [
    parsedSortedUsers,
    userInfo,
    isShareForm,
    excludeIdsList,
    excludeUsers,
    isLineageShareForm,
  ]);

  useOutsideClick(selectRef, () => setVisible(false), [visible]);

  return (
    <div ref={selectRef}>
      <SelectField
        width={width}
        allowClear
        {...props}
        mode="multiple"
        control={control}
        setValue={setValue}
        height={height}
        maxHeight="165px"
        name={name}
        tagRender={(tagRendererProps): JSX.Element => (
          <TagStyled {...tagRendererProps}>
            {tagRendererProps?.value?.toString()?.split("(")[0]}
          </TagStyled>
        )}
        options={
          searchText && sortedUsers?.length
            ? [
                {
                  label: "Users",
                  value: "",
                  options:
                    sortedUsers
                      ?.filter((parsedItem: any) => parsedItem?.type === "USR")
                      ?.map((item: SelectFieldOption) => ({
                        ...item,
                        label: optionRendrer(item),
                      })) || [],
                },
                {
                  label: "Groups",
                  value: "",
                  options:
                    sortedUsers
                      ?.filter((parsedItem: any) => parsedItem?.type === "UGP")
                      ?.map((item: SelectFieldOption) => ({
                        ...item,
                        label: optionRendrer(item),
                      })) || [],
                },
              ]
            : []
        }
        defaultValue={defaultValue}
        onSearch={onSearch}
        searchValue={searchText}
        notFoundContent={
          status === "loading" ? (
            <LoadingSpinerStyled>
              Data is being fetched <Spin size="small" />
            </LoadingSpinerStyled>
          ) : sortedUsers?.length === 0 &&
            searchText &&
            (status === "success" || status === "idle") ? (
            <LoadingSpinerStyled>No results found</LoadingSpinerStyled>
          ) : null
        }
        filterOption={false}
        autoClearSearchValue={false}
        onClick={(): void => setVisible(true)}
        open={visible}
        onClear={(): void => {
          setText("");
        }}
      />
    </div>
  );
};

export default MultiSelectUsersField;
