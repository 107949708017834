import { useCallback, useMemo } from "react";
import { Button } from "../../../../../../../../components";

import { emptyChatIcon, emptyChatIcon1 } from "../../../../../../../../svgs";
import {
  TrainBotTimeframeFilters,
  TrainbotFilters,
} from "../../../../../../analysisdetailpage.types";

import { QueryBlockListingBlankSlateStyled } from "./queryblocklistingblankslate.styles";
import { SourceTypes } from "../../../../../../../../app.types";

import {
  checkIsChatPage,
  checkSourceType,
} from "../../../../../../../../utils";
import { useSearchFieldContext } from "../../../../../../../../contexts";

const isChatPage = checkIsChatPage();

const QueryBlockListingBlankSlate = ({
  filter = "all",
  onGuideMeClick,
  askedByFilter = ["all"],
  analysisSrcTypeId,
  timeFrameFilter = "all",
}: {
  filter: TrainbotFilters;
  onGuideMeClick: () => void;
  askedByFilter: string[];
  analysisSrcTypeId: SourceTypes;
  timeFrameFilter?: TrainBotTimeframeFilters;
}): JSX.Element => {
  const { debouncedSearchText } = useSearchFieldContext();

  const { isRestAPISource } = checkSourceType(analysisSrcTypeId);

  const onGuideMeClickWrapper = useCallback(() => {
    onGuideMeClick?.();
  }, [onGuideMeClick]);

  const isAskedByFilterEmpty = useMemo(() => !askedByFilter?.length, [
    askedByFilter,
  ]);

  const isAnyfilterApplied = useMemo(
    () =>
      timeFrameFilter !== "all" ||
      filter !== "all" ||
      !askedByFilter?.includes("all") ||
      !!debouncedSearchText,
    [timeFrameFilter, filter, askedByFilter, debouncedSearchText]
  );

  return (
    <QueryBlockListingBlankSlateStyled isChatPage={isChatPage}>
      {!isAnyfilterApplied ? emptyChatIcon : emptyChatIcon1}
      <div className="blank-slate-msg">
        {!isAnyfilterApplied
          ? isRestAPISource
            ? "Utilize this to effortlessly gather insights into customer issues"
            : "Use this to test the queries and also give feedback so the bot can become better. To get started, you can click on the generate questions button to get some idea of what questions you can ask to warm up the bot."
          : isAskedByFilterEmpty
          ? "Nothing to display, no user has been selected."
          : filter === "CNP"
          ? "Nothing to display, no concept has been added so far."
          : filter === "APR"
          ? "Nothing to display, no question has been approved so far."
          : filter === "REJ"
          ? "Nothing to display, no question has been rejected so far."
          : filter === "INC"
          ? "Nothing to display, all questions are succeeded or approved!"
          : filter === "SVD"
          ? "Nothing to display, no question has been bookmarked so far."
          : debouncedSearchText
          ? "Nothing to display, no question matched your searched keyword."
          : timeFrameFilter !== "all"
          ? "Nothing to display; no question matched your timeframe filter."
          : "Nothing to display, all questions have either failed or not received a response!"}
      </div>
      {!isAnyfilterApplied && !isRestAPISource && (
        <Button onClick={onGuideMeClickWrapper}>Generate Questions</Button>
      )}
    </QueryBlockListingBlankSlateStyled>
  );
};

export default QueryBlockListingBlankSlate;
