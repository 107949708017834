import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useGetAppState } from ".";
import { setModal } from "../reducers/appreducer/appreducer";

export function useCancelModal(): () => void {
  const {
    modal: { modalId, modalProps, modalTitle },
  } = useGetAppState();
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(setModal({ modalId, modalProps, modalTitle }));
  }, [modalId]);
}
