import { useCallback, useMemo, useState } from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

import { ColumnType, SequenceProps } from "./sequence.types";

import {
  ColumnItemStyled,
  SequenceStyled,
  UpDownButtonStyled,
} from "./sequence.styles";

import { getDataTypeIcon } from "../../utils";
import { moveDown, moveUP } from "../transfer/transfer.utils";

import { ColumnDataType } from "../../parsers";

const Sequence = (props: SequenceProps): JSX.Element => {
  const {
    onColumnSeqChange,
    sortedColumns,
    columnsOrder,
    onSetColumnsOrder,
  } = props;
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const isMultipleSelected = selectedKeys?.length > 1;
  const isNothingSelected = selectedKeys?.length === 0;

  const isDisabledUpButton = useMemo(() => {
    return columnsOrder?.indexOf(selectedKeys?.[0]) === 0;
  }, [columnsOrder, selectedKeys]);

  const isDisabledDownButton = useMemo(() => {
    return (
      columnsOrder?.indexOf(selectedKeys?.[0]) === columnsOrder?.length - 1
    );
  }, [columnsOrder, selectedKeys]);

  const onColumnSelect = useCallback(
    (columnId: string) => (): void => {
      selectedKeys?.includes(columnId)
        ? setSelectedKeys((st) => st?.filter((item) => item !== columnId))
        : setSelectedKeys((st) => [...st, columnId]);
    },
    [selectedKeys]
  );

  const onMoveUpDown = useCallback(
    (isMovingUp?: boolean) => (): void => {
      const updatedOrderOfKeys = (isMovingUp ? moveUP : moveDown)(
        columnsOrder,
        selectedKeys
      );

      onSetColumnsOrder(updatedOrderOfKeys);
      onColumnSeqChange?.();
    },
    [moveDown, moveUP, columnsOrder, selectedKeys, onColumnSeqChange]
  );

  return (
    <SequenceStyled>
      <div className="listing-container">
        {sortedColumns?.map((column, columnIndex) => {
          return (
            <ColumnItemStyled
              key={`sequence-column-${column?.field_id}`}
              onClick={onColumnSelect(`${column?.field_id}`)}
              isSelected={selectedKeys?.indexOf(column?.field_id) >= 0}
            >
              <span className="column-type">
                {getDataTypeIcon(column?.field_datatype as ColumnDataType)}
              </span>
              <span className="column-title">{column?.field_display_name}</span>
            </ColumnItemStyled>
          );
        })}
      </div>
      <div className="up-down-container">
        <UpDownButtonStyled
          tooltipProps={{
            title: isNothingSelected ? "Select column to change order" : "",
            placement: "right",
          }}
          onClick={onMoveUpDown(true)}
          disabled={isNothingSelected || isDisabledUpButton}
        >
          <UpOutlined />
        </UpDownButtonStyled>
        <UpDownButtonStyled
          tooltipProps={{
            title: isNothingSelected ? "Select column to change order" : "",
            placement: "right",
          }}
          onClick={onMoveUpDown(false)}
          disabled={isNothingSelected || isDisabledDownButton}
        >
          <DownOutlined />
        </UpDownButtonStyled>
      </div>
    </SequenceStyled>
  );
};

export default Sequence;
