import styled from "styled-components";
import { ellipsis } from "../../../../styles/app.styles";

export const PatternSelectStyled = styled.div`
  .create-new-job-btn {
    font-size: 12px;
    color: ${({ theme }): string => theme.textColors.BLUE_116};
  }
`;

export const PatternOptionStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .name-desc-wrapper {
    column-gap: 5px;
    display: flex;
    align-items: center;
    max-width: 450px;
    overflow: hidden;
  }

  .pattern-desc {
    color: ${({ theme }): string => theme.textColors.GREY_210};
    ${ellipsis}
  }

  .pattern-edit-icon {
    align-items: flex-end;
    display: none;
  }

  :hover {
    .pattern-edit-icon {
      display: inline-block;
    }
  }
`;

export const ColumnDetailWrapper = styled.div<{ isHidden: boolean }>`
  width: 500px;
  height: 320px;
  padding: 20px;
  max-width: 500px;
  margin: -13px -9.2px -14px -21px;
  box-shadow: 0 0 7px 0 rgba(211, 211, 211, 0.85);
  background-color: #fff;
  overflow-y: auto;

  border-radius: 8px;

  border: var(--Flat, 1px) solid var(--blues-primary-500-main, #428bca);

  background: var(--Neutrals-100, #fff);

  .title {
    width: 160px;
  }

  .value {
    width: 280px;
    color: black;
  }

  .row {
    width: 500px;
  }

  .title-main {
    color: ${({ theme }): string => theme.textColors.BLUE_116};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .group {
    margin-bottom: 10px;
  }

  .main-group {
    width: 100%;
  }

  .icon-datatype {
    cursor: pointer;
  }
`;
