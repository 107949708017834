import { useMemo } from "react";
import { VerticalDividerStyled } from "../../../../components/dividers/dividers.styles";
import LinkButton from "../../../../components/linkbutton/linkbutton";
import {
  getSourceIcon,
  getTooltipTitle,
} from "../../../../pages/tablepage/views/datamodeltab/datamodeltab.utils";
import {
  FieldStyled,
  FieldWrapperStyled,
  KeyStyled,
  KeyValueWrapper,
  MappedFieldsWrapperStyled,
  NodeEdgeRelationStyled,
  ValueStyled,
} from "./viewerdedgedetails.styles";

import {
  RelationshipSourceIDType,
  TableFieldsInfoType,
  TableInfo,
} from "../../../../parsers/erddiagramparser/erddiagramparser.types";

import ShowMoreOrLessText from "../../../../components/showmoreorlesstext/showmoreorlesstext";
import { getDataTypeIcon } from "../../../../utils";

import StateHandler from "../../../../components/statehandler/statehandler";
import { erdEdgeBlankSlate } from "../../../../blankslates/erdedgerefblankslate";

export const ViewErdEdgeDetails = ({
  sourceTable,
  referenceTable,
  relationShipSource,
  cardinality,
  fields,
  sourceTableDesc,
  referenceTableDesc,
  isLoadingDesc,
}: {
  sourceTable: TableInfo;
  referenceTable: TableInfo;
  relationShipSource: RelationshipSourceIDType;
  cardinality: string;
  fields: TableFieldsInfoType;
  sourceTableDesc: string;
  referenceTableDesc: string;
  isLoadingDesc: boolean;
}): JSX.Element => {
  const tooltipTitle = useMemo(() => getTooltipTitle(relationShipSource), [
    relationShipSource,
  ]);
  const sourceIcon = useMemo(() => getSourceIcon(relationShipSource), [
    relationShipSource,
  ]);

  return (
    <>
      <NodeEdgeRelationStyled>
        <KeyStyled> Relationship Source:</KeyStyled>
        <LinkButton
          tooltipProps={{
            title: tooltipTitle,
            placement: "bottomLeft",
          }}
        >
          <ValueStyled
            isBold
            className={`erd-edge-source-icon-${relationShipSource?.toLocaleLowerCase()}`}
          >
            {sourceIcon}
          </ValueStyled>
        </LinkButton>
      </NodeEdgeRelationStyled>

      <KeyValueWrapper>
        <KeyStyled>Source Table:</KeyStyled>
        <ValueStyled isBold>{sourceTable?.table_name}</ValueStyled>
      </KeyValueWrapper>

      <KeyValueWrapper>
        <KeyStyled>Description:</KeyStyled>
        <StateHandler
          error={false}
          isFetching={isLoadingDesc}
          blankSlate={erdEdgeBlankSlate}
        >
          <ValueStyled>
            <ShowMoreOrLessText text={sourceTableDesc} textLimit={250} />
          </ValueStyled>
        </StateHandler>
      </KeyValueWrapper>

      <KeyValueWrapper>
        <KeyStyled>Reference Table:</KeyStyled>
        <ValueStyled isBold>{referenceTable?.table_name}</ValueStyled>
      </KeyValueWrapper>

      <KeyValueWrapper lastChild>
        <KeyStyled>Description:</KeyStyled>
        <StateHandler
          error={false}
          isFetching={isLoadingDesc}
          blankSlate={erdEdgeBlankSlate}
        >
          <ValueStyled>
            <ShowMoreOrLessText text={referenceTableDesc} textLimit={250} />
          </ValueStyled>
        </StateHandler>
      </KeyValueWrapper>

      <NodeEdgeRelationStyled>
        <KeyStyled>Cardinality:</KeyStyled>
        <ValueStyled>{cardinality}</ValueStyled>
      </NodeEdgeRelationStyled>

      <NodeEdgeRelationStyled lastChild>
        <KeyStyled> Mapped Fields </KeyStyled>

        <MappedFieldsWrapperStyled>
          <FieldStyled>
            <ValueStyled isBold>Source Fields</ValueStyled>
            <VerticalDividerStyled
              width="10px"
              height="2px"
              className="vertical-divider"
            />
            <ValueStyled isBold>Reference Fields</ValueStyled>
          </FieldStyled>

          <FieldWrapperStyled>
            {fields?.map((column, index) => {
              return (
                <FieldStyled key={`source-target-key-${index}`}>
                  <ValueStyled>
                    {column?.source_field_name}{" "}
                    <span className="col-type">
                      {getDataTypeIcon(column?.source_col_type)}
                    </span>
                  </ValueStyled>
                  <VerticalDividerStyled
                    width="10px"
                    height="2px"
                    className="vertical-divider"
                  />
                  <ValueStyled title={column?.target_field_name}>
                    {column?.target_field_name}
                    <span className="col-type">
                      {getDataTypeIcon(column?.target_col_type)}
                    </span>
                  </ValueStyled>
                </FieldStyled>
              );
            })}
          </FieldWrapperStyled>
        </MappedFieldsWrapperStyled>
      </NodeEdgeRelationStyled>
    </>
  );
};

export default ViewErdEdgeDetails;
