import { useCallback, useMemo, useState } from "react";
import LinkButton from "../linkbutton/linkbutton";
import { ShowMoreStyled } from "./showmore.styles";

const ShowMore = (props: { data: string[]; limit: number }): JSX.Element => {
  const { data, limit } = props;

  const [allShowing, setAllShowing] = useState<boolean>(false);

  const dataToView = useMemo(() => {
    const showCount = allShowing ? data?.length : limit;
    return data?.slice(0, showCount)?.join(", ");
  }, [allShowing, data]);

  const showAllBtnHandler = useCallback((): void => {
    setAllShowing(!allShowing);
  }, [allShowing]);

  return (
    <ShowMoreStyled>
      <div className="data-view">
        {data?.length ? (
          <>
            {dataToView}
            {data?.length > limit && (
              <LinkButton
                type="link"
                onClick={showAllBtnHandler}
                marginLeft="5px"
              >
                {allShowing ? "Show Less" : `(+${data?.length - limit} more)`}
              </LinkButton>
            )}
          </>
        ) : (
          "--"
        )}
      </div>
    </ShowMoreStyled>
  );
};

export default ShowMore;
