import { useGetAll } from "../baseservice";
import { getParsedSubscriptions } from "../../parsers/accountsettings/accountsettingsparser";

import { API_CONFIG } from "../../constants/apiconfig";

import { CustomReactQueryHookReturnType } from "../services.types";

const { get_subscriptions: getSubscriptions } = API_CONFIG;

export function useGetSubscriptions(): CustomReactQueryHookReturnType {
  return useGetAll({
    apiConfig: getSubscriptions,
    parser: getParsedSubscriptions,
  });
}
