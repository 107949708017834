export const getCurrentRuleData = (
  zendeskRulesArticle: string,
  currentRuleId: string,
  dataToExtract: "Desc" | "Title" | "Example"
): string => {
  const regex = new RegExp(
    `<!-- ${currentRuleId} ${dataToExtract} Starts\\s*-->(.*?)<!-- ${currentRuleId} ${dataToExtract} Ends\\s*-->`,
    "s"
  );

  const match = zendeskRulesArticle.match(regex);

  const extractedContent = (match && match?.[1]?.trim()) || "";
  return extractedContent;
};
