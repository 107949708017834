import { tagsetLabels } from "../../../../tagsetsdetailpage.constants";

import { ApplicableToRendererProps } from "./applicabletorenderer.types";

import { ApplicableToStyled } from "./applicabletorenderer.styles";
import CoreNodesCircleIcon from "../../../../../../components/corenodetypescircleicon";

const nodeIconProps = { width: "15px", height: "15px", fontSize: "12px" };

const ApplicableToRenderer = (
  props: ApplicableToRendererProps
): JSX.Element => {
  const { applicableTo } = props;

  const {
    datasets,
    fields,
    terms,
    models_reports: modelAndReports,
    rules,
  } = tagsetLabels?.tagsetdetail?.information;

  return (
    <ApplicableToStyled>
      {applicableTo?.datasets !== undefined && (
        <div className="applicable-to-row">
          <span className="applicable-to-name">
            <span className="datasets">
              <CoreNodesCircleIcon
                nodeType="TBL"
                {...nodeIconProps}
                mode="tertiary"
              />
            </span>
            {datasets}
          </span>
          <span className="applicable-to-description">
            Used {applicableTo?.datasets} times on {datasets?.toLowerCase()}
          </span>
        </div>
      )}

      {applicableTo?.fields !== undefined && (
        <div className="applicable-to-row">
          <span className="applicable-to-name">
            <span className="fields">
              <CoreNodesCircleIcon
                nodeType="COL"
                {...nodeIconProps}
                mode="tertiary"
              />
            </span>
            {fields}
          </span>
          <span className="applicable-to-description">
            Used {applicableTo?.fields} times on {fields?.toLowerCase()}
          </span>
        </div>
      )}

      {applicableTo?.terms !== undefined && (
        <div className="applicable-to-row">
          <span className="applicable-to-name">
            <span className="terms">
              <CoreNodesCircleIcon
                nodeType="TRM"
                {...nodeIconProps}
                mode="tertiary"
              />
            </span>
            {terms}
          </span>
          <span className="applicable-to-description">
            Used {applicableTo?.terms} times on {terms?.toLowerCase()}
          </span>
        </div>
      )}

      {applicableTo?.models_reports !== undefined && (
        <div className="applicable-to-row">
          <span className="applicable-to-name">
            <span className="terms">
              <CoreNodesCircleIcon
                nodeType="DSR"
                {...nodeIconProps}
                mode="tertiary"
                fontSize="9px"
              />
            </span>
            {modelAndReports}
          </span>
          <span className="applicable-to-description">
            Used {applicableTo?.models_reports} times on{" "}
            {modelAndReports?.toLowerCase()}
          </span>
        </div>
      )}

      {applicableTo?.rules !== undefined && (
        <div className="applicable-to-row">
          <span className="applicable-to-name">
            <span className="rules">
              <CoreNodesCircleIcon
                nodeType="RLS"
                {...nodeIconProps}
                mode="tertiary"
                fontSize="9px"
              />
            </span>
            {rules}
          </span>
          <span className="applicable-to-description">
            Used {applicableTo?.rules} times on {rules?.toLowerCase()}
          </span>
        </div>
      )}

      {applicableTo?.datasets === undefined &&
        applicableTo?.fields === undefined &&
        applicableTo?.terms === undefined &&
        "--"}
    </ApplicableToStyled>
  );
};

export default ApplicableToRenderer;
