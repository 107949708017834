import moment from "moment";

export const momentFormatDateNoTimezone = (
  date: string | number,
  format?: string
): string => {
  try {
    if (date?.toString()?.toLocaleLowerCase() === "null") return "";

    const formattedDate = moment(date).format(format || "YYYY-MM-DD");
    return formattedDate === "Invalid date" ? "" : formattedDate;
  } catch {
    return "";
  }
};
