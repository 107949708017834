import styled from "styled-components";

export const TileViewStyled = styled.div<{
  height: number | string | undefined;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ height }): any => height || "300px"};
`;

export const BoxStyled = styled.div<{
  isBordered?: boolean;
  width: number | string | undefined;
  height: number | string | undefined;
}>`
  width: ${({ width }): any => width || "327px"};
  width: ${({ height }): any => height || "231px"};
  border: ${({ theme, isBordered }): string =>
    isBordered ? `solid 1px ${theme?.bgColors?.GREY_56}` : ""};
  background-color: ${({ theme }): string => theme?.bgColors?.WHITE_COLOR};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .count-text {
    font-family: "OpenSansBold";
    font-size: 28px;
    color: ${({ theme }): string => theme?.textColors?.GREY_40};
    margin: 0;
  }
  .caption-text {
    font-size: 16px;
    color: ${({ theme }): string => theme?.textColors?.GREY_24};
    margin: 0;
  }
`;
