import styled from "styled-components";

export const CriteriaContainerStyled = styled.div<{ paddingLeft?: string }>`
  padding-left: ${({ paddingLeft }): string => paddingLeft || "0"};
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  align-items: flex-start;

  .specify-criteria-desc {
    color: ${({ theme }): string => theme.textColors.GREY_24};
    font-size: 13.8px;
    margin: 5px 0 8px 0;
    max-width: 50%;
  }

  .specify-criteria-fields {
    .field {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 10px;

      .ant-select-multiple {
        width: 500px;

        .ant-select-selector {
          max-height: 34px;

          .ant-select-selection-item {
            height: 26px;
          }
        }
      }

      .ant-select-single {
        width: 270px;
      }

      .input-field-container > input {
        width: 270px;
      }

      .filters-container {
        display: grid;
        grid-template-columns: repeat(2, 270px);
        gap: 15px;
      }

      .values-container {
        display: grid;
        grid-template-columns: repeat(2, 270px);
        gap: 15px;

        .ant-select-multiple,
        .ant-select-multiple ~ .error {
          width: 560px;

          .ant-select-selector {
            max-height: 34px;

            .ant-select-selection-item {
              height: 26px;
            }
          }
        }

        &.date-type {
          grid-template-columns: 186px repeat(2, 167px);

          .dvsum-select-field {
            width: 186px;
          }

          .input-field-container > input {
            width: 167px;
          }
        }
      }
    }
  }

  .add-criteria-btn {
    svg {
      margin-right: 5px;
    }
  }

  .add-condition-button {
    text-align: left;
    svg {
      margin-right: 2px;
    }
  }

  .text-condition {
    width: 85px;
    color: ${({ theme }): string => theme.textColors.GREY_26};
  }
`;

export const CriteriaAndRemoveButtonWrapperStyled = styled.div`
  width: 100%;
  display: flex;
  column-gap: 10px;
  align-items: flex-start;
`;

export const CriteriaAndConditionWrapperStyled = styled.div`
  width: 100%;
  display: flex;
  row-gap: 10px;
  flex-direction: column;
`;

export const SpecifyCriteriaFieldsContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 10px;
  width: 100%;
`;

export const FilterCriteriaStyled = styled.div`
  background: ${({ theme }): string => theme.textColors.LIGHT_GREY_22};
  display: flex;
  align-items: flex-start;
  padding: 20px;
  flex-direction: column;
  width: 100%;
  row-gap: 8px;
`;

export const EmptyFilterCriteriaContainerStyled = styled.div`
  background: ${({ theme }): string => theme.textColors.LIGHT_GREY_22};
  display: flex;
  gap: 10px;
  flex-direction: column;
  width: 100%;
  padding: 20px;

  .no-filters {
    color: ${({ theme }): string => theme.textColors.GREY_210};
    font-size: 13.8px;
    margin-bottom: 15px;
    text-align: center;
  }
`;
