import styled from "styled-components";

export const GlossaryPageHeaderStyled = styled.div<{
  isProfilingInProgress?: boolean;
  isDqTabOutSideListing?: boolean;
}>`
  .left {
    align-items: center;
    .gov-view-static-text {
      display: flex;
      align-items: center;
      font-size: 24px;
      font-family: OpenSans;
      color: ${({ theme }): string => theme.textColors.BLUE_116};
      margin-right: 14px;
      > svg {
        margin-left: 8.8px;
      }
      padding: 4px 9.6px;
    }

    .node-static-text {
      display: flex;
      align-items: center;
      font-size: 15px;
      font-family: OpenSansSemiBold;
      color: ${({ theme }): string => theme.textColors.BLUE_116};
    }

    .add-rule-btn {
      display: flex;
      align-items: center;
      column-gap: 5px;
      font-size: 13.6px;
      font-family: OpenSansSemiBold;
      color: ${({ theme }): string => theme.textColors.BLUE_116};
    }

    .gph-action-buttons {
      border-left: 1px solid
        ${({ theme }): string => theme.borderColors.GREY_10};

      padding-left: ${({ isDqTabOutSideListing }): string =>
        !isDqTabOutSideListing ? "16px" : ""};

      .refresh-btn,
      .edit-btn {
        display: flex;
        align-items: center;
      }

      .edit-btn {
        svg {
          width: 14.4px;
          height: 14.4px;
        }
      }

      .add-term-btn {
        display: flex;
        align-items: baseline;
        gap: 5px;
        font-family: OpenSansSemiBold;
        padding: 0px 10px;
        border-left: 1px solid
          ${({ theme }): string => theme.borderColors.GREY_10};
        line-height: 13px;
        border-radius: 0px;
      }
    }

    .list-view-popover .ant-popover-content {
      width: 263px;
    }

    .ant-popover-open {
      background: ${({ theme }): string => theme.bgColors.LIGHT_BLUE_25};
      border-radius: 3px;
    }
  }

  .right {
    display: flex;
    gap: 20.8px;

    .download-icon,
    .import-icon,
    .save-icon,
    .share-icon,
    .delete-icon,
    .add-icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: end;
      color: ${({ theme }): string => theme.textColors.DARK_GREY_10};
      font-size: 13px;

      svg {
        path {
          stroke: ${({ theme }): string => theme.borderColors.DARK_GREY_10};
        }
      }

      .actions-container {
        display: flex;
        flex-direction: column;
        width: unset;
        height: 37.1333px;
        background: none;
        color: ${({ theme }): string => theme.textColors.DARK_GREY_10};
        font-size: 13px;
        font-family: OpenSans;
        padding-bottom: 0px;
        font-weight: 400;
      }

      &.download-icon {
        line-height: 20px;
      }

      &.delete-icon,
      &.add-icon {
        svg {
          path {
            fill: ${({ theme }): string => theme.borderColors.DARK_GREY_10};
          }
        }
      }
    }

    .chats-listing,
    .feedback-analytics {
      svg {
        path {
          stroke: none;
        }
      }
    }
  }
`;

export const RulePageLeftSectionStyled = styled.div`
  display: flex;
  align-items: center;
  column-gap: 15px;
  width: 100%;

  .page-name {
    color: ${({ theme }): string => theme.textColors.BLUE_116};
    font-size: 24px;
    font-family: OpenSansSemiBold;
  }
`;

export const ViewNameStyled = styled.div<{ isSelected?: boolean }>`
  color: ${({ isSelected, theme }): string =>
    isSelected ? theme.textColors.BLUE_116 : theme.textColors.GREY_24};
  font-size: 13.6px;
`;

export const BoldTextStyled = styled.div<{ isSelected?: boolean }>`
  font-family: OpenSansSemiBold;
  display: inline-flex;
  margin: 2px 5px;
  justify-content: baseline;

  svg {
    width: 18px;
    height: 13px;
  }
`;
