import { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { setDrawer } from "../../reducers/appreducer/appreducer";
import {
  backIcon,
  crossIconSolid,
  plusIconInCircle,
  searchIcon,
} from "../../svgs";
import { InputStyled } from "../inputs/inputs.styles";
import LinkButton from "../linkbutton";
import { FindOrAddDatasetStyled } from "./findoradddataset.styles";

function FindOrAddDataset(): JSX.Element {
  const [state, setState] = useState<{ searchVal?: string }>();
  const dispatch = useDispatch();

  const onChange = useCallback((e) => {
    setState((st) => ({ ...st, searchVal: e?.target?.value }));
  }, []);

  const onClear = useCallback(() => {
    setState((st) => ({ ...st, searchVal: "" }));
  }, []);

  const onBack = useCallback(() => {
    dispatch(setDrawer({}));
  }, []);

  return (
    <FindOrAddDatasetStyled>
      <div className="header">
        <LinkButton onClick={onBack} height="20px">
          {backIcon()}
        </LinkButton>
        Back to Discovery
      </div>
      <div className="body">
        <InputStyled
          width="100%"
          height="48px"
          prefix={searchIcon("18px")}
          fontSize="16px"
          suffix={
            state?.searchVal && (
              <LinkButton onClick={onClear} height="18px">
                {crossIconSolid()}
              </LinkButton>
            )
          }
          value={state?.searchVal}
          onChange={onChange}
        />
        {!state?.searchVal && (
          <LinkButton className="add-btn">{plusIconInCircle()}</LinkButton>
        )}
      </div>
    </FindOrAddDatasetStyled>
  );
}

export default FindOrAddDataset;
