import { Virtuoso } from "react-virtuoso";

import { CustomDynamicProgressBar } from "../../../../../../../../../../../../components";

import LinkButton from "../../../../../../../../../../../../components/linkbutton/linkbutton";
import { thinCrossIcon } from "../../../../../../../../../../../../svgs";

import {
  checkDataTypeOfColumn,
  getDataTypeIcon,
} from "../../../../../../../../../../../../utils";
import { QueryBlockProfileStyled } from "./queryblockprofile.styles";

import { queryblockprofileProps } from "./queryblockprofile.types";
import { getProfilingDetails } from "../queryblockprofiling.comp.utils";

import { checkIsChatPage } from "../../../../../../../../../../../../utils/ischatpage";
import {
  LineChart,
  BarChart,
  ColumnChart,
} from "../../../../../../../../../../../../components/recharts";
import { Y_AXIS_DATA_KEY } from "../../../../../../../../../../../../constants";
import { COLUMN_VISUAL_DIST_DATA_KEY } from "../../../../../../../../../../../../parsers/columnparsers";

const crossIconSvg = thinCrossIcon();

const QueryBlockProfile = (props: queryblockprofileProps): JSX.Element => {
  const { handlers, queryItem, isFirstQueryProfiling = false } = props;

  const { id = "", results, header } = queryItem || {};
  const { is_maximize_query_block: isMaximizeQueryBlock } = header || {};
  const { columns_profilig: columnsProfilig = [] } = results || {};
  const { onProfile } = handlers || {};

  const isChatPage = checkIsChatPage();

  return (
    <QueryBlockProfileStyled
      data-testid="query-block-profiling"
      isChatPage={isChatPage}
      isFirstQueryProfiling={isFirstQueryProfiling}
      isMaximized={isMaximizeQueryBlock}
    >
      <div className="query-profilt-header">
        <div className="query-profilt-header-title">Profiling</div>
        <LinkButton
          onClick={(e): void => {
            onProfile?.(id, e);
          }}
        >
          {crossIconSvg}
        </LinkButton>
      </div>

      <Virtuoso
        style={{ height: 550 }}
        data={columnsProfilig}
        totalCount={columnsProfilig?.length}
        itemContent={(index, item): JSX.Element => {
          const {
            profiling = [],
            column_display_name: columnDisplayName = "",
            column_id: columnId = "",
            description = "",
            data_type: dataType,
            chart_data: chartData = [],
          } = item || {};

          const { non_blanks: nonBlanks = 0, blanks = 0 } = getProfilingDetails(
            profiling
          );

          const { isDateColumn, isNumberCol } = checkDataTypeOfColumn(dataType);

          const ChartComp = isDateColumn
            ? LineChart
            : isNumberCol
            ? ColumnChart
            : BarChart;

          return (
            <div
              className="query-profile-single-block"
              role="button"
              key={`query-block-profile-${columnId}-${index}`}
            >
              <div className="query-profile-single-block-header">
                <div className="col-name">
                  {getDataTypeIcon(dataType)}
                  <div>{columnDisplayName}</div>
                </div>
                <div className="col-desc" title={description}>
                  {description}
                </div>
              </div>
              <CustomDynamicProgressBar
                value={nonBlanks}
                width="100%"
                fillText={`${nonBlanks}% filled`}
                emptyText={`${blanks}% blank`}
              />

              <div className="divider-text-wrapper">
                <p className="text-heading">Visual distribution</p>
              </div>
              <div className="query-profile-single-block-chart">
                <ChartComp
                  height={135}
                  width="100%"
                  data={chartData}
                  dataKeys={[
                    { dataKey: COLUMN_VISUAL_DIST_DATA_KEY, fill: "#4894ca" },
                  ]}
                  yAxis={[{ yAxisId: Y_AXIS_DATA_KEY, label: { value: "" } }]}
                  showLedgends={false}
                  hideXAxis
                  showCartesianGrid={false}
                  margin={{ left: 0 }}
                  hideYAxis
                  keepStaicBarSize={false}
                />
              </div>
            </div>
          );
        }}
      />
    </QueryBlockProfileStyled>
  );
};

export default QueryBlockProfile;
