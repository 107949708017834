import styled from "styled-components";
import { ellipsis } from "../../../../styles/app.styles";

export const MultiSelectioncheckBoxPopupContentStyled = styled.div`
  background: ${({ theme }): string => theme?.bgColors?.WHITE_COLOR};
  border: 1px solid ${({ theme }): string => theme?.borderColors?.GREY_56};
  width: max-content;
  min-width: 225px;
  border-radius: 4px;
  box-shadow: 0px 4px 12px 0px rgb(0 0 0 / 10%);
  max-height: 275px;
  overflow: auto;

  .multi-selection-checkboxes-item {
    cursor: pointer;
    padding: 8px 12px;
    color: ${({ theme }): string => theme?.textColors?.GREY_40};

    :hover {
      background: ${({ theme }): string => theme?.bgColors?.LIGHT_BLUE_25};
    }

    .ant-checkbox-wrapper {
      width: 100%;

      .ant-checkbox + span {
        width: 100%;

        .option-label-container {
          display: flex;
          justify-content: space-between;
          column-gap: 8px;

          .label {
            ${ellipsis}

            .label-suffix {
              margin-left: 4px;
              color: ${({ theme }): string => theme?.textColors?.GREY_210};
            }
          }

          .count {
            color: ${({ theme }): string => theme?.textColors?.GREY_210};
          }
        }
      }
    }
  }

  .horizontal-divider {
    height: 1px;
    width: 90%;
    background: ${({ theme }): string => theme.borderColors.LIGHT_GREY_15};
    margin: 2px auto;
  }
`;
