import { Alert, Spin } from "antd";
import { useState, useEffect } from "react";
import { API_CONFIG } from "../../constants/apiconfig";
import { crossIcon, dvSumnLogo } from "../../svgs";
import EmptyTableSlate from "../emptytableslate";
import Flex from "../flex";
import LinkButton from "../linkbutton";
import {
  AlertContainerStyled,
  BlankSlateLoading,
  EmptyTableContainerStyled,
  LoadingStyledForModal,
  PageLoadingStyled,
  SpinnerLoading,
} from "./statehandler.styles";
import { ComponentProps, StateHandlerProps } from "./statehandler.types";
import { useGetAppState } from "../../customhooks";
import { checkIsChatPage } from "../../utils";

function Modal({
  isFetching,
  error,
  children,
  setEnableError,
  errorMsg,
  isFullScreenLoading,
}: ComponentProps): JSX.Element {
  const { sideBarPosition, caddiSideBarPosition } = useGetAppState();
  const isChatPage = checkIsChatPage();

  const isSideBarCollapsed = isChatPage
    ? caddiSideBarPosition === "collapsed"
    : sideBarPosition === "collapsed";

  return (
    <>
      {(errorMsg || error instanceof Error) && (
        <AlertContainerStyled>
          <Alert
            message={(error as any)?.formattedMsg || errorMsg}
            type="error"
            onClose={(): void => {
              setEnableError(false);
            }}
          />
        </AlertContainerStyled>
      )}
      {isFetching && (
        <LoadingStyledForModal
          data-testid="test-modal-loading-state"
          className="modal-loading-state"
          isFullScreenLoading={isFullScreenLoading}
          isSideBarCollapsed={isSideBarCollapsed}
          isChatPage={isChatPage}
        >
          <Flex rowGap={18} direction="column">
            {dvSumnLogo}
            Hang on! Loading...
            <Spin size="large" />
          </Flex>
        </LoadingStyledForModal>
      )}
      <div className="state-handler-children-wrapper">{children}</div>
    </>
  );
}

function SimpleComponent(props: ComponentProps): JSX.Element {
  const {
    error,
    isEmpty,
    children,
    enableError,
    setEnableError,
    isFetching,
    emptyTableProps = {},
    hideError = false,
    apiKey = "get_all_user_groups",
    blankSlate,
    isShowBlankSlate = true,
    mode,
    showChildrenInCaseOfError = true,
    showAnimation = true,
    showSpinner,
    spinnerHeight = "",
  } = props;

  useEffect(() => {
    if (error instanceof Error && hideError) {
      setTimeout(() => setEnableError(false), 5000);
    }
  }, [error]);

  if (mode === "section_handler") {
    return (
      <>
        {error instanceof Error && enableError && (
          <PageLoadingStyled className="simple-component-error-alert">
            <span>{(error as any)?.formattedMsg}</span>
            <LinkButton type="link" onClick={(): void => setEnableError(false)}>
              {crossIcon()}
            </LinkButton>
          </PageLoadingStyled>
        )}
        {isFetching && (
          <LoadingStyledForModal data-testid="test-modal-loading-state">
            <Flex rowGap={18} direction="column">
              {dvSumnLogo}
              Hang on! Loading...
              <Spin size="large" />
            </Flex>
          </LoadingStyledForModal>
        )}
        <div>{children}</div>
      </>
    );
  }

  if (error instanceof Error) {
    return (
      <>
        {enableError && (
          <PageLoadingStyled className="simple-component-error-alert">
            <span>{(error as any)?.formattedMsg}</span>
            <LinkButton type="link" onClick={(): void => setEnableError(false)}>
              {crossIcon()}
            </LinkButton>
          </PageLoadingStyled>
        )}

        {isShowBlankSlate && (blankSlate || API_CONFIG[apiKey]?.blankSlate)}
        {!isShowBlankSlate && showChildrenInCaseOfError && children}
      </>
    );
  }

  if (isFetching || showSpinner) {
    return showSpinner ? (
      <SpinnerLoading className="spinner-wrapper" spinnerHeight={spinnerHeight}>
        <Flex rowGap={18} direction="column">
          {dvSumnLogo}
          Hang on! Loading...
          <Spin size="large" />
        </Flex>
      </SpinnerLoading>
    ) : (
      <BlankSlateLoading
        showAnimation={showAnimation}
        className="blankslate-loading"
      >
        {blankSlate || (isShowBlankSlate && API_CONFIG[apiKey]?.blankSlate)}
      </BlankSlateLoading>
    );
  }
  return !isFetching && isEmpty && !(error instanceof Error) ? (
    <EmptyTableContainerStyled>
      <EmptyTableSlate {...emptyTableProps} />
    </EmptyTableContainerStyled>
  ) : (
    children ?? <div />
  );
}

function StateHandler(props: StateHandlerProps): JSX.Element {
  const { isModal, error } = props;
  const [enableError, setEnableError] = useState(true);
  useEffect(() => {
    setEnableError(true);
  }, [error]);

  return isModal ? (
    <Modal
      {...props}
      enableError={enableError}
      setEnableError={setEnableError}
    />
  ) : (
    <SimpleComponent
      {...props}
      enableError={enableError}
      setEnableError={setEnableError}
    />
  );
}

export default StateHandler;
