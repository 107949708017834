// <--- Types Start --->
import { DQRuleActionDetailsType } from "..";

import { DQActionStatusType } from "../../app.types";
import { DQRuleActionDetailsResponseType } from "./dataqualityparser.types";
// <--- Types End --->

// <--- Utils Start --->
import { jsonParse } from "../../utils";

import { utcTOLocalTimeZone } from "../../utils/utctolocaltimezone";
import { dqRuleActionStatusCardTitleMapping } from "../../components/modalwrapper/modals/dqruledetailsmodal/dqruledetailsmodal.components/dqruleactions/dqruleactions.utils";
// <--- Utils End --->

export const parseDQActionDetails = (
  data: string
): DQRuleActionDetailsType[] => {
  const parsedRuleActionDetails = jsonParse(
    data
  ) as DQRuleActionDetailsResponseType[];

  const ruleActionDetailsRefinedData: DQRuleActionDetailsType[] =
    parsedRuleActionDetails
      ?.filter((tempAction) => tempAction?.action_on) // this filter will be removed
      ?.sort(
        (objA, objB) =>
          new Date(objB?.action_on)?.getTime() -
          new Date(objA?.action_on)?.getTime()
      )
      ?.map((action) => {
        const isShowActionByName = ([
          "INP",
          "RSL",
          "OPN",
        ] as Array<DQActionStatusType>)?.includes(action?.action_status);

        return {
          id: action?.action_status,
          title:
            dqRuleActionStatusCardTitleMapping(action?.action_status) || "",
          action_by:
            (isShowActionByName
              ? action?.action_by
              : action?.assigned_to_name) || "",
          time: utcTOLocalTimeZone(action?.action_on?.slice(0, -1), "Pp") || "",
          resolution_action: action?.resolution_action_name || "",
          resolution_action_id: action?.resolution_action_id,
          comment: action?.comments || "",
        };
      }) || [];

  return ruleActionDetailsRefinedData;
};
