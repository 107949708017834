import { useLayoutEffect, useState } from "react";
import { useDebounce } from "./useDebounce";

export function useWindowSize(debounce = 150): number[] {
  const [size, setSize] = useState([0, 0]);
  const debounceSize = useDebounce(size, debounce);
  useLayoutEffect(() => {
    function updateSize(): void {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    // return (): void => window.removeEventListener('resize', updateSize);
  }, []);
  return debounceSize;
}
