import styled from "styled-components";
import { ellipsis } from "../../../../../../../../../../../../styles/app.styles";

export const QueryBlockColumnProfileStyled = styled.div<{
  isCellProfile?: boolean;
}>`
  .column-profile-header {
    padding: ${({ isCellProfile }): string =>
      isCellProfile ? "10px 20px  " : "0 20px 10px"};
    border-top: ${({ isCellProfile }): string =>
      isCellProfile ? " 1px solid #e8e8e8" : ""};
    .row-1 {
      display: flex;
      align-items: center;

      svg {
        margin-left: 8px;
        margin-right: 7px;
        max-width: 13px;
        path {
          fill: ${({ theme }): string => theme?.bgColors?.GREY_25};
        }

        circle {
          fill: ${({ theme }): string => theme?.bgColors?.GREY_25};
        }
      }

      .col-profile-data-type-icon {
        display: flex;
      }

      .column-name {
        color: ${({ theme }): string => theme?.textColors?.BLUE_116};
        font-family: OpenSansSemiBold;
        flex: 1;
        ${ellipsis}
      }
    }

    .row-2 {
      color: ${({ theme }): string => theme?.textColors?.GREY_24};
      font-size: 12px;
    }
  }
  .column-count-wrapper {
    padding: 18.8px 20px;
    border-top: 1px solid #e8e8e8;
    .col-count-title {
      font-family: OpenSansSemiBold;
      font-size: 13px;
      color: #4f4f4f;
      margin-bottom: 5px;
    }
  }
`;

export const VisualDistChartItemStyled = styled.div<{
  isSelected: boolean;
}>`
  margin-bottom: 20px;
  cursor: pointer;
  .item-value {
    color: ${({ theme, isSelected }): string =>
      isSelected
        ? theme?.textColors?.BLUE_116
        : theme?.textColors?.BLACK_COLOR};
    font-family: OpenSansSemiBold;
    margin-bottom: 4px;
    ${ellipsis}
  }
`;

export const ColProfillingSectionStyled = styled.div<{
  isBlankFilterApplied: boolean;
  isNonBlankFilterApplied: boolean;
  isChatPage?: boolean;
  isFirstQueryProfiling?: boolean;
  isMaximized?: boolean;
}>`
  border-top: ${({
    theme,
    isChatPage,
    isFirstQueryProfiling,
    isMaximized,
  }): string =>
    isChatPage
      ? isMaximized
        ? `1px solid ${theme?.borderColors?.GREY_56}`
        : isFirstQueryProfiling
        ? ""
        : `1px solid ${theme?.borderColors?.GREY_56}`
      : ""};

  .back-button-wrapper {
    padding: ${({ isChatPage }): string => (isChatPage ? "18px 20px" : "10px")};
    display: flex;
    gap: 7px;
    align-items: center;
    cursor: pointer;
    .back-icon svg {
      width: 10px;
      height: 10px;
    }
    .back-text {
      flex-grow: 1;
      font-family: OpenSansSemiBold;
      font-size: 13px;
      color: #428bca;
    }
  }
  .col-info {
    width: 100%;
    margin: 0 0 10px 0;
    padding: 9px 9px 9px 20px;
    background-color: ${({ theme }): string => theme?.bgColors?.GREY_19};
    color: ${({ theme }): string => theme?.textColors?.GREY_24};
    font-style: italic;
  }

  .col-count {
    padding: 0px 20px;
    margin-bottom: 18.8px;
    .col-count-title {
      color: ${({ theme }): string => theme?.textColors?.GREY_24};
      font-size: 12px;
      font-family: OpenSansSemiBold;
      line-height: 12px;
    }

    .progress {
      .progress-inner {
        .progress-inner-fill {
          opacity: ${({ isBlankFilterApplied }): string =>
            isBlankFilterApplied ? "0.5" : ""};
        }

        .progress-inner-empty {
          opacity: ${({ isNonBlankFilterApplied }): string =>
            isNonBlankFilterApplied ? "0.5" : ""};
        }
      }

      .progress-text {
        display: flex;
        svg {
          width: 10px;
          height: 6px;
          margin-right: 2px;
        }
        .progress-text-fill {
          color: ${({ theme, isNonBlankFilterApplied }): string =>
            isNonBlankFilterApplied ? theme?.textColors?.BLUE_116 : ""};
        }

        .progress-text-empty {
          color: ${({ theme, isBlankFilterApplied }): string =>
            isBlankFilterApplied ? theme?.textColors?.BLUE_116 : ""};
        }
      }
    }
  }
`;

export const VisDistSecTitleStyled = styled.div`
  color: ${({ theme }): string => theme?.textColors?.GREY_40};
  font-family: OpenSansSemiBold;
  font-size: 14px;
`;
export const VisDistSecDescriptionStyled = styled.div`
  font-size: 12.5px;
  color: #4f4f4f;
  margin: 0 0 0.5rem;
`;
export const MinMaxFieldLabelStyled = styled.div`
  color: ${({ theme }): string => theme?.textColors?.GREY_24};
  font-family: OpenSansSemiBold;
  font-size: 12px;
`;
export const VisualDistributionForDistributionDataStyled = styled.div<{
  isAnyError?: boolean;
}>`
  padding: 10px 0;
  max-height: 425px;
  overflow: auto;
  overflow-x: hidden;

  .visual-dist-chart {
    padding: 15.2px 15.2px 24px 15.2px;
    color: ${({ theme }): string => theme?.textColors?.GREY_18};

    .ant-card {
      padding: 0;

      .ant-card-body {
        padding: 0;
      }
    }

    .ant-card-bordered {
      border: none;
    }

    .actions-buttons-sec {
      width: 100%;
    }
  }

  .no-chart-data-exists {
    padding: 20px;
    background-color: ${({ theme }): string => theme?.bgColors?.LIGHT_BLUE_5};
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }): string => theme?.textColors?.GREY_24};
    font-family: OpenSansSemiBold;
  }
`;
