import parse from "html-react-parser";
import { domSanitization } from "./domsanitization";

export const parseStringToHtml = (
  text?: string,
  doDOMSanitization?: boolean
): JSX.Element | JSX.Element[] | undefined | string => {
  try {
    const textWithDefaultValue = text || "";

    return parse(
      doDOMSanitization
        ? domSanitization(textWithDefaultValue)
        : textWithDefaultValue
    );
  } catch (error) {
    return text || "";
  }
};
