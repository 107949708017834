import styled from "styled-components";

export const AboutThePromptSecStyled = styled.div`
  .heading {
    margin: 4px 0;
    font-family: "OpenSansSemiBold";
  }

  .tables-info-wrapper {
    .tables-list {
      padding-left: 24px;
      margin: 6.4px 0;

      .table-name {
        color: ${({ theme }): string => theme?.textColors?.BLUE_116};
        display: flex;
        column-gap: 8px;

        .sample-data-btn {
          display: flex;
          column-gap: 8px;
          align-items: center;

          svg {
            path {
              fill: ${({ theme }): string => theme?.textColors?.GREY_210};
            }
          }
        }
      }
    }
  }

  .stewards-info {
    margin-top: 8px;

    .stewards-list {
      padding-left: 24px;
      margin: 6.4px 0;

      .steward-email {
        color: ${({ theme }): string => theme?.textColors?.BLUE_116};
      }
    }
  }

  .no-summary-state {
    padding-top: 10px;
  }
`;
