import { useCallback, useMemo } from "react";
import LinkButton from "../../../../../../components/linkbutton";

import { brushIcon } from "../../../../../../svgs";

import {
  ContextsListSecStyled,
  SavedContextsListingViewStyled,
} from "./savedcontextslistingview.styles";

import {
  ContextsListSecProps,
  SavedContextsListingViewProps,
} from "./savedcontextslistingview.types";

import { ContextTag } from "./savedcontextslistingview.components";
import ConfirmationPopover from "../../../../../../components/confirmationpopover/confirmationpopover";

const brushIconSvg = brushIcon("16px", "16px");

const ContextsListSec = (props: ContextsListSecProps): JSX.Element => {
  const {
    type,
    heading = "",
    description = "",
    contextsList = [],
    onUpdateContexts,
  } = props;

  return (
    <ContextsListSecStyled>
      {contextsList?.length ? (
        <div className="content-sec">
          <div className="heading">{heading}</div>
          <div className="desc">{description}</div>
          <div className="tags-list">
            {contextsList?.map((item) => (
              <ContextTag
                key={item?.id}
                type={type}
                item={item}
                onUpdateContexts={onUpdateContexts}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="blankstate">
          <div className="content">
            {type === "in-use" ? "Active contexts" : "Saved contexts"}
          </div>
        </div>
      )}
    </ContextsListSecStyled>
  );
};

const SavedContextsListingView = (
  props: SavedContextsListingViewProps
): JSX.Element => {
  const { savedContextsList = [], onUpdateContexts, onAddContextClick } = props;

  const { contextsInUseList, contextsNotInUseList } = useMemo(
    () => ({
      contextsInUseList: savedContextsList?.filter((item) => item?.selected),
      contextsNotInUseList: savedContextsList?.filter(
        (item) => !item?.selected
      ),
    }),
    [savedContextsList]
  );

  const onClearContextClick = useCallback(
    () => (): void => {
      onUpdateContexts({
        type: "clear_all_selected_contexts",
      });
    },
    [onUpdateContexts]
  );

  return (
    <SavedContextsListingViewStyled>
      <div className="header">Saved Contexts</div>

      <div className="context-listing-view-action-sec">
        <LinkButton onClick={onAddContextClick}>
          <span className="add-icon">+</span> Add Context
        </LinkButton>

        <ConfirmationPopover
          onOk={onClearContextClick()}
          okText="Clear"
          cancelText="Cancel"
          heading="Clear Contexts"
          desc="This will clear all the contexts in use. Are you sure you want to clear it? This cannot be undone."
        >
          <LinkButton icon={brushIconSvg} disabled={!contextsInUseList?.length}>
            Clear Context
          </LinkButton>
        </ConfirmationPopover>
      </div>

      <div className="content-sec">
        <ContextsListSec
          type="in-use"
          heading="Contexts In Use"
          description="I will use these to answer follow-up questions."
          contextsList={contextsInUseList}
          onUpdateContexts={onUpdateContexts}
        />
        <ContextsListSec
          type="saved"
          heading="Available Saved Contexts"
          description="Click on the + icon below to use any context"
          contextsList={contextsNotInUseList}
          onUpdateContexts={onUpdateContexts}
        />
      </div>
    </SavedContextsListingViewStyled>
  );
};

export default SavedContextsListingView;
