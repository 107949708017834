import styled from "styled-components";
import { ellipsis } from "../../../../../../../../styles/app.styles";

export const ManageFieldsViewStyled = styled.div`
  background: ${({ theme }): string => theme?.bgColors?.WHITE_COLOR};

  .collapse-header-content-manage-fields {
    width: 100%;
    padding: 16px 16px 14px 16px;
    display: flex;
    align-items: center;

    .manage-fields-sec-title {
      flex: 1;
      font-family: OpenSansSemiBold;
      color: ${({ theme }): string => theme?.textColors?.GREY_40};
    }

    .manage-fields-right-sec {
      display: flex;
      align-items: center;
      column-gap: 8px;
    }
  }

  .manage-input-search {
    padding: 0 16px 14px 16px;
  }

  .ant-spin-nested-loading {
    min-height: 200px;
    /* max-height: 400px;
    overflow-y: auto; */
    > div {
      .ant-spin {
        min-height: 150px;
      }
    }
  }

  .manage-fileds-collapse {
    border: none;
    background: none;
    padding-left: 18px;
    .ant-collapse-item {
      border: none;
      margin-bottom: 12px;

      .ant-collapse-header {
        padding: 0;

        .core-nodes-circle-icon {
          margin-right: 8px;
        }
      }

      .ant-collapse-content {
        border-top: none;
        .ant-collapse-content-box {
          display: flex;
          flex-direction: column;
          row-gap: 12.8px;
          margin-left: 5.5px;
          border-left: 1px solid
            ${({ theme }): string => theme?.borderColors?.BLUE_116};
          padding-top: 15px;
          padding-left: 17.4px;
          .table-columns-list {
            display: flex;
            align-items: center;

            svg {
              max-width: 12px;
              margin: 0 3px 0 8px;

              path {
                fill: ${({ theme }): string => theme?.bgColors?.GREY_210};
              }
            }

            .ant-btn-link {
              display: flex;
              align-items: center;
              width: 100%;

              .col-name-container {
                display: flex;
                gap: 10px;
                width: 100%;
                text-align: start;
                justify-content: space-between;
                color: ${({ theme }): string => theme?.textColors?.GREY_24};

                .name {
                  display: flex;

                  .col-display-name {
                    color: ${({ theme }): string =>
                      theme?.textColors?.BLUE_105};
                    max-width: 140px;
                    ${ellipsis}
                  }
                }

                .col-ref-profiling-renderer {
                  width: 250px;
                  margin-top: 6px;
                }
              }
            }
          }
        }
      }
    }
  }
`;
