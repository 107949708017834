import { Button } from "..";
import { FormItemStyled, FormStyled } from "../form";
import { ConfirmationFormModalStyled } from "./confirmationformmodal.styles";
import { ConfirmationFormModalProps } from "./confirmationformmodal.types";

const ConfirmationFormModal = (
  props: ConfirmationFormModalProps
): JSX.Element => {
  const { onCancel, onOk, children, okText, cancelText } = props;

  return (
    <ConfirmationFormModalStyled>
      <div className="modal-form-content-container">{children}</div>
      <FormStyled>
        <FormItemStyled label="" className="form-actions-sec">
          <Button id="cancel" width="82px" onClick={onCancel}>
            {cancelText || "Cancel"}
          </Button>
          <Button id="primary" width="93px" marginLeft="8px" onClick={onOk}>
            {okText || "Ok"}
          </Button>
        </FormItemStyled>
      </FormStyled>
    </ConfirmationFormModalStyled>
  );
};

export default ConfirmationFormModal;
