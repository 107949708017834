import styled from "styled-components";
import { ellipsis } from "../../styles/app.styles";
import { hexToRgbA } from "../../utils";

export const DataSourceFieldRefViewStyled = styled.div`
  padding-bottom: 2px;

  .datasource-field-ref-header {
    padding: 32px 44px 22px 31px;

    .header_row_2 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-wrap: wrap;

      .suggest-edit-btn {
        margin-top: 5px;
      }

      .node-title {
        font-size: 15px;
        color: ${({ theme }): string => theme.textColors.DARK_GREY_10};
        width: 100%;
        ${ellipsis}
        opacity: 0.8;
        padding-left: 39px;
      }

      .prominent-tags-sec {
        padding-left: 39px;
      }

      .section-header {
        flex: 1;

        .info {
          margin: 0;
          margin-right: 0px;
          display: flex;
          flex-flow: column;

          .info-title {
            ${ellipsis}
            max-width: 400px;
            line-height: unset;
          }
        }

        .deprecate-icon {
          display: flex;
          svg {
            margin-right: 0px;
            margin-left: 12.5px;
          }
        }

        .datasource-field-redirect-icon {
          svg {
            margin-right: 0px;
            margin-left: 12.5px;
          }
        }
      }
    }

    .header_row_3 {
      color: ${({ theme }): string => theme.textColors.DARK_GREY_10};
      font-size: 15px;

      > span {
        color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
      }
    }

    .datasource-field-header-breadcrums {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .header-item {
        display: flex;
        align-items: center;
      }

      .item-icon {
        margin-right: 6px;
        display: flex;

        svg {
          path {
            fill: ${({ theme }): string => theme.bgColors.BLUE_116};
          }
        }
      }

      .item-title {
        cursor: pointer;
        font-size: 16px;
        color: ${({ theme }): string => theme.textColors.BLUE_116};
        ${ellipsis}
      }

      .right-icon {
        margin: 0px 11px;
        display: flex;
      }
    }
  }

  .datasource-field-ref-section {
    padding: 20px 29px 10px 29px;
    border-top: 1px solid
      ${({ theme }): string => hexToRgbA(theme.bgColors.LIGHT_GREY_11, 0.1)};

    .datasource-field-ref-sec-titile {
      min-height: unset;
      .title {
        font-size: 17px;
      }
    }

    .lineage-rendrer {
      margin-top: 7px;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      column-gap: 5px;

      .cursor-default {
        cursor: default;
      }
    }

    .sec-row {
      margin-bottom: 9px;
      .main-row {
        .row-title {
          min-width: 217px;
        }
      }
    }
  }
`;
