import { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Dynamicform from "../../dynamicform";
import { AddFreshnessFormType } from "./addfreshnessform.types";

import { addFreshnessForm } from "./addfreshnessform.config";

import { addFreshnessSchema } from "../../../../utils/schemas";
import { GetAddRuleFormContentProps } from "../../addruleform.types";

import { filterCriteriaApiData } from "../../../addgovernanceviewform/addgovernanceviewform.utils";

const AddFreshnessForm = (props: GetAddRuleFormContentProps): JSX.Element => {
  const {
    columnsData,
    sourceId,
    tableId,
    onAddRule,
    ruleCategoryId,
    formId,
    columnId,
    propsOnCancel,
    ruleConfig,
    onUpdateRule,
    isEdit = false,
  } = props;

  const initialFilters = ruleConfig?.filter_cond;

  const { threshold_hrs: threshHrs, rule_desc: desc = "" } = ruleConfig || {};

  const methods = useForm<AddFreshnessFormType>({
    defaultValues: {
      id: "freshness_check",
      rule_type: "Freshness",
      rule_description: desc,
      threshold: threshHrs,

      is_filter_criteria_active: isEdit,
      filter_criteria: [],
    },
    resolver: yupResolver(addFreshnessSchema),
    mode: "onChange",
  });

  const onSubmit = useCallback(
    (values: AddFreshnessFormType) => {
      const desc = values?.rule_description;

      const rule_config = {
        threshold_hrs: values?.threshold,
        col_id: isEdit ? ruleConfig?.col_id : columnId,
        rule_type: formId,
        rule_desc: desc,
        //NEEDED COLUMN IDS HERE
        col_ids: isEdit ? ruleConfig?.col_ids : columnId ? [columnId] : [],
      };

      const formFilterCondition = values?.filter_criteria || [];

      const filterCondition = filterCriteriaApiData(
        formFilterCondition,
        columnsData
      );

      const modifyRulePayload = {
        ...rule_config,
        filter_cond: filterCondition,
      };

      const dataToSubmit = {
        rule_cat: ruleCategoryId,
        rule_type: formId,
        rule_desc: desc,
        src_id: sourceId,
        tbl_id: tableId,
        rule_priority: "NRM",
        rule_def_config: rule_config,
        filter: filterCondition,
        col_ids: [columnId],
      };

      isEdit ? onUpdateRule?.(modifyRulePayload) : onAddRule(dataToSubmit);
    },
    [tableId, sourceId, onAddRule, columnsData, columnId, ruleConfig]
  );

  const addFreshnessFormConfig = addFreshnessForm({
    existingFilters: initialFilters,
    isEdit,
  });

  return (
    <FormProvider {...methods}>
      <Dynamicform
        schema={addFreshnessFormConfig}
        onSubmit={onSubmit}
        columnsData={columnsData}
        propsOnCancel={propsOnCancel}
        isEdit={isEdit}
      />
    </FormProvider>
  );
};

export default AddFreshnessForm;
