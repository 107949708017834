// <--- Styles Start --->
import styled from "styled-components";
// <--- Styles End --->

export const ExclusionCriteriaSectionStyled = styled.div`
  .criteria-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    .label {
      font-size: 12px;
      color: ${({ theme }): string => theme?.textColors?.GREY_40};
    }
  }
`;

export const ListingStyled = styled.div`
  display: grid;
  grid-template-columns: 15% 85%;
  align-items: flex-start;

  .name {
    font-size: 13.6px;
    color: ${({ theme }): string => theme?.textColors?.GREY_24};
  }

  .filters-values {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    flex-wrap: wrap;

    .value {
      padding: 2px 10px;
      border-radius: 4px;
      border: solid 1px ${({ theme }): string => theme?.borderColors?.GREY_18};
      background-color: ${({ theme }): string =>
        theme?.bgColors?.LIGHT_GREY_22};
      font-size: 12px;
      color: ${({ theme }): string => theme?.textColors?.GREY_24};
    }
  }
`;
