import styled from "styled-components";

//        <---Styles--->
import { ellipsis } from "../../styles/app.styles";
//        <---Styles--->

//        <---Utils--->
import { hexToRgbA } from "../../utils";
//        <---Utils--->

export const TableRefDrawerStyled = styled.div<{ hasPromintntTags: boolean }>`
  padding-bottom: 2px;

  .table-ref-header {
    padding: 32px 44px 22px 31px;

    .header_row_2 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-wrap: wrap;

      .suggest-edit-btn {
        margin-top: 5px;
      }

      .node-title {
        font-size: 15px;
        color: ${({ theme }): string => theme.textColors.DARK_GREY_10};
        width: 100%;
        ${ellipsis}
        opacity: 0.8;
        margin-bottom: ${({ hasPromintntTags }): string =>
          hasPromintntTags ? "16px" : ""};
        padding-left: 39px;
      }

      .tags-popularity-sec {
        padding-left: 39px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 10.4px;
      }

      .section-header {
        flex: 1;

        .info {
          margin: 0;
          margin-right: 0px;
          display: flex;
          flex-flow: column;

          .info-title {
            ${ellipsis}
            max-width: 400px;
            line-height: unset;
          }
        }

        .deprecate-icon {
          display: flex;
          svg {
            margin-right: 0px;
            margin-left: 12.5px;
          }
        }

        .table-redirect-icon {
          svg {
            margin-right: 0px;
            margin-left: 12.5px;
          }
        }
      }
    }

    .header_row_3 {
      color: ${({ theme }): string => theme.textColors.DARK_GREY_10};
      font-size: 15px;

      > span {
        color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
      }
    }

    .table-header-breadcrums {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .item-icon {
        margin-right: 6px;
        display: flex;

        svg {
          path {
            fill: ${({ theme }): string => theme.bgColors.BLUE_116};
          }
        }
      }

      .item-title {
        cursor: pointer;
        font-size: 16px;
        color: ${({ theme }): string => theme.textColors.BLUE_116};
        ${ellipsis}
      }

      .right-icon {
        margin: 0px 11px;
        display: flex;
      }
    }
  }

  .table-ref-section {
    &.section-represents {
      display: flex;
      flex-flow: column;
      row-gap: 1em;
    }
  }
  .table-ref-section,
  .first-type {
    padding: 29px 29px 10px 29px;
    border-top: 1px solid
      ${({ theme }): string => hexToRgbA(theme.bgColors.LIGHT_GREY_11, 0.1)};

    .col-ref-sec-titile {
      min-height: unset;

      .title {
        font-size: 17px;
      }
    }

    .sec-row {
      margin-bottom: 9px;

      .main-row {
        .row-title {
          min-width: 217px;
        }

        .row-value {
          .replace-rendrer {
            color: ${({ theme }): string => theme.textColors.DARK_BLUE};

            > svg {
              margin-right: 10px;

              path {
                fill: ${({ theme }): string => theme.bgColors.BLUE_116};
              }
            }
          }

          .tags-wrapper {
            row-gap: 10px;
          }
        }
      }
    }
  }

  .managedby-section {
    > div {
      .section-vertical {
        .title {
          width: 217px;
        }

        .value-sec {
          width: 59%;
        }
      }
    }
  }

  .first-type {
    margin-top: 0px;
  }

  .vertical-columns-container {
    display: flex;

    .section-vertical {
      display: flex;
      flex-flow: column;

      .title {
        color: #428bca;
      }
    }
  }

  .flex-col {
    display: flex;
    flex-flow: column;
  }

  .flex {
    display: flex;
  }

  .flex-1 {
    flex: 1;
  }

  .flex-2 {
    flex: 2;
  }

  .flex-start {
    justify-content: flex-start;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .items-center {
    align-items: center;
  }

  .gap-1 {
    gap: 1em;
  }

  .mt-1 {
    margin-top: 1em;
  }

  .text-sm {
    font-family: OpenSans;
    font-size: 0.875em;
    color: #4c4c4c;
  }

  .text-gray {
    color: #4c4c4c;
  }

  .excerpt-link {
    color: #428bca;
    margin-left: 5px;
    cursor: pointer;
  }

  .last-updated-static-text {
    font-size: 13px;
    color: ${({ theme }): string => theme.textColors.DARK_GREY_10};
    opacity: 0.6;
    margin-bottom: 23px;
  }
`;
