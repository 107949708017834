import { SetStateAction, useMemo } from "react";
import StateHandler from "../../components/statehandler/statehandler";

import SectionHeader from "../../components/sectionheader";
import { redirectCircledIcon } from "../../svgs";

import { useGetAppState, useGetAppType } from "../../customhooks";
import LinkButton from "../../components/linkbutton";

import { ELEMENT_IDS } from "../../constants";
import NodeRefPageHOC from "../../components/noderefpagehoc/noderefpagehoc";

import CoreNodesCircleIcon from "../../components/corenodetypescircleicon";
import { NodeRefPageHOCEmbededProps } from "../../components/noderefpagehoc/noderefpagehoc.types";

import StyledLink from "../../components/styledlink/styledlink";

import { useGetJobDocForRefPage } from "../../api/jobservice/jobservice";

import {
  JobRefDrawerStyled,
  JobRefSectionStyled,
  MetaDataInfoStyled,
} from "./jobrefdrawer.styles";

import { jobRefDrawer } from "./jobrefdrawer.renderer";
import { getJobDetailPageUrl } from "../../utils/getjobdetailpageurl";

import { jobRefBlankSlate } from "../../blankslates/jobrefblankslate";
import { JobItemType } from "./jobrefdrawer.types";
import { getScanSummary } from "../../pages/sourcedetailpage/tabs/settingstab/tabs/generalsettingstab/generalsettingstabform/generalsettingstabform.util";

const {
  busgls_detpg_ref_ttl: BUSGLS_DETPG_REF_TTL,
  busgls_detpg_ref_goto_btn: BUSGLS_DETPG_REF_GOTO_BTN,
} = ELEMENT_IDS;

function DrawerContent(
  props: NodeRefPageHOCEmbededProps & {
    id?: string;
  }
): JSX.Element {
  const { drawer } = useGetAppState();

  const { isExtOrDesktop } = useGetAppType();

  const { id, isDrawer, editViewComp, onClickEdit } = props;

  const { drawerProps = {} } = drawer || {};

  const { id: drawerPropsId } = drawerProps || {};

  const nodeId = id || drawerPropsId;

  const { parsedData, isLoading, error } = useGetJobDocForRefPage(
    nodeId,
    "JOB",
    "PUB"
  );

  const {
    jobCategory,
    jobCreatedOnNodes = [],
    internalRecipients = [],
    externalRecipients = [],
    lastExecutionOn = "",
    nextExecutionOn = "",
    description = "",
    jobName,
    jobScheduleInfo,
    jobCreatedOnTags = [],
  } = parsedData || {};

  const scheduleSummary = useMemo(() => {
    return getScanSummary({
      ...jobScheduleInfo,
    });
  }, [jobScheduleInfo]);

  const summary = scheduleSummary || "";

  const toUrl = useMemo(() => {
    const url = getJobDetailPageUrl(nodeId);

    return url;
  }, [drawerProps, nodeId]);

  const jobCreatedOn = jobCategory === "RLS" ? "Rule(s)" : "Table(s)";

  const isJobCreatedOnRule = jobCategory === "RLS";

  const metaDataInfo = [
    {
      key: "Job Category",
      value: isJobCreatedOnRule ? "Execute Rules" : "Profile Tables",
    },

    ...((jobCreatedOnNodes?.length && [
      {
        key: jobCreatedOn,
        ids: jobCreatedOnNodes,
        nodeType: isJobCreatedOnRule ? "RLS" : "TBL",
      },
    ]) ||
      []),

    ...((jobCreatedOnTags?.length && [
      {
        key: "Attribute(s)",
        stringValues: jobCreatedOnTags,
      },
    ]) ||
      []),
    {
      key: "Description",
      value: description,
    },

    {
      key: "Internal Recipients",
      value: internalRecipients?.map((item) => item?.name)?.join(", "),
      ids: internalRecipients,
    },
    {
      key: "External Recipients",
      value: externalRecipients?.map((item) => item)?.join(", "),
    },
    {
      key: "Schedule Summary",
      value: summary,
    },
    {
      key: "Last Execution",
      value: lastExecutionOn,
    },
    {
      key: "Next Execution",
      value: nextExecutionOn,
    },
  ];

  const jobRefDrawerRenderer = useMemo(() => {
    return jobRefDrawer;
  }, [jobRefDrawer]);

  return (
    <StateHandler
      isFetching={isLoading}
      error={error}
      blankSlate={jobRefBlankSlate}
    >
      <JobRefDrawerStyled>
        <div className="job-ref-header">
          <div className="header_row_2" id={BUSGLS_DETPG_REF_TTL}>
            <SectionHeader
              icon={<CoreNodesCircleIcon nodeType="JOB" />}
              title={jobName}
              subTitle=""
              rightIcon={
                <>
                  <LinkButton height="33px" disabled={!!editViewComp}>
                    <StyledLink
                      to={toUrl}
                      target={isExtOrDesktop ? "_blank" : "_self"}
                      cutomClassName="job-redirect-icon"
                      elementId={BUSGLS_DETPG_REF_GOTO_BTN}
                    >
                      {redirectCircledIcon("28px", "28px")}
                    </StyledLink>
                  </LinkButton>
                </>
              }
            />
          </div>
        </div>
        {editViewComp || (
          <>
            <JobRefSectionStyled>
              {metaDataInfo?.map((data, index) => {
                return (
                  <MetaDataInfoStyled key={index}>
                    <div className="key">{data?.key}:</div>
                    <div className="value">
                      {jobRefDrawerRenderer?.[data?.key]?.(
                        data as JobItemType
                      ) || data?.value}
                    </div>
                  </MetaDataInfoStyled>
                );
              })}
            </JobRefSectionStyled>
          </>
        )}
      </JobRefDrawerStyled>
    </StateHandler>
  );
}

const JobRefDrawer = (props: {
  id?: string;
  isDrawer?: boolean;
  setShowParentGoBack?: React.Dispatch<SetStateAction<boolean>>;
  onClickSuggest?: () => void;
}): JSX.Element => {
  return (
    <NodeRefPageHOC
      isDrawer={props?.isDrawer}
      setShowParentGoBack={props?.setShowParentGoBack}
    >
      <DrawerContent {...props} />
    </NodeRefPageHOC>
  );
};

export default JobRefDrawer;
