import { useCallback } from "react";
import { Tooltip } from "antd";

import { ContextTagStyled } from "./contexttag.styles";
import { ContextTagProps } from "./contexttag.types";

import {
  deleteIcon1,
  plusIcon,
  thinCrossIcon,
} from "../../../../../../../../svgs";

import { DVSUM_TOOLTIP_CLASS_NAME } from "../../../../../../../../constants";
import LinkButton from "../../../../../../../../components/linkbutton";

const crossIconSvg = thinCrossIcon("10px", "10px");
const plusIconSvg = plusIcon("10px", "10px");
const deleteIconSvg = deleteIcon1("17px", "16px");

const ContextTag = (props: ContextTagProps): JSX.Element => {
  const { type, item, onUpdateContexts } = props;

  const { id = "", title = "", parent_id: parentId = "" } = item;

  const toggleSelectedState = useCallback(
    () => (): void => {
      onUpdateContexts({
        contextId: id,
        parentId,
        type: "use_context_toggle",
      });
    },
    [id, parentId]
  );

  const onDeleteClick = useCallback(
    () => (): void => {
      onUpdateContexts({
        contextId: id,
        parentId,
        type: "delete",
      });
    },
    [id, parentId]
  );

  return (
    <ContextTagStyled isContextInUse={type === "in-use"}>
      <div className="tag">
        <Tooltip
          title={title}
          placement="left"
          overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
        >
          <div className="content">{title}</div>
        </Tooltip>

        {type === "in-use" ? (
          <LinkButton
            tooltipProps={{
              title:
                "This will remove the context but you can always add it back from below.",
              placement: "bottomRight",
              align: { offset: [15, 0] },
            }}
            onClick={toggleSelectedState()}
            marginLeft="8px"
          >
            {crossIconSvg}
          </LinkButton>
        ) : (
          <LinkButton
            className="add-btn"
            onClick={toggleSelectedState()}
            marginLeft="8px"
          >
            {plusIconSvg}
          </LinkButton>
        )}
      </div>

      {type === "saved" && (
        <LinkButton onClick={onDeleteClick()}>{deleteIconSvg}</LinkButton>
      )}
    </ContextTagStyled>
  );
};

export default ContextTag;
