import { toCapitalize } from "./tocapitalize";

export const transformDashedTextToCapitalizedText = (text: string): string => {
  try {
    const removeDashes = text?.replace(/[\-_]/g, " ");

    return toCapitalize(removeDashes?.toLocaleLowerCase());
  } catch {
    return "";
  }
};
