import {
  AddCustomQueryFormApiDataType,
  AddCustomQueryFormType,
} from "./addcustomqueryform.types";

export const customQueryFormDataToApiData = (
  formData: AddCustomQueryFormType
): AddCustomQueryFormApiDataType => {
  return {
    is_stored_proc: formData?.stored_procedure,
    custom_query: formData?.custom_query,
  };
};
