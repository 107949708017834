import { CheckboxRendererProps } from "./checkboxrenderer.types";

import { CheckboxStyled } from "../../../../../../components/checkbox/checkbox.styles";
import { CheckboxRendererStyled } from "./checkboxrenderer.styles";

const CheckboxRenderer = (props: CheckboxRendererProps): JSX.Element => {
  const { checked } = props;

  return (
    <CheckboxRendererStyled>
      <CheckboxStyled
        checked={checked}
        width="14px"
        height="14px"
        onChange={undefined}
        className="readonly-checkbox"
      />
    </CheckboxRendererStyled>
  );
};

export default CheckboxRenderer;
