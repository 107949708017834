// <--- Components Start --->
import LinkButton from "../../../../components/linkbutton";
import SectionWithBody from "../../../../components/sectionwithbody";

// <--- Components End --->

// <--- Services Start --->
import { useGetSPMetadataUrl } from "../../../../api/accountsettingsservice/ssoservice";
// <--- Services End --->

// <--- Styles Start --->
import { ServiceProviderConfigsStyled } from "./serviceproviderconfigurations.styles";
import { FormItemStyled } from "../../../../components/form";
import CopyToClipboard from "../../../../components/copytoclipboard";
// <--- Styles End --->

const ServiceProviderConfigurations = (): JSX.Element => {
  const { parsedData: spMetadataUrl, isLoading } = useGetSPMetadataUrl();

  // To be changed
  const downloadFileWithName = (urlToSend: string, fileName: string): void => {
    const req = new XMLHttpRequest();
    req.open("GET", urlToSend, true);

    const downloadFileName = fileName;

    req.responseType = "blob";
    req.onload = (): void => {
      const blob = req.response;
      const fileName = downloadFileName; //if you have the fileName header available
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    };

    req.send();
  };

  return (
    <ServiceProviderConfigsStyled>
      <SectionWithBody
        headingProps={{
          title: "Service Provider Configurations",
          isShowIcon: false,
          className: "section-with-body",
        }}
      >
        <div className="sp-metadata-row">
          <FormItemStyled label="SP Metadata">
            <CopyToClipboard
              text={spMetadataUrl?.spMetadataKey}
              isLoading={isLoading}
            />
          </FormItemStyled>

          <LinkButton
            id="secondary"
            isTextUnderLine
            height="32px"
            fontSize="14px"
            className="download-metadata-btn"
            onClick={(): void =>
              downloadFileWithName(
                spMetadataUrl?.metadataDownloadLink,
                "dvsum-sp-configurations.xml"
              )
            }
            disabled={isLoading}
          >
            Download SP metadata file
          </LinkButton>
        </div>
      </SectionWithBody>
    </ServiceProviderConfigsStyled>
  );
};

export default ServiceProviderConfigurations;
