import { useCallback, useRef, useState } from "react";
import { Spin } from "antd";

import LinkButton from "../../../../../../../../../../../../components/linkbutton";
import { HighlightIcon, QueryMenuSecStyled } from "./querymenusec.styles";

import { QueryMenuSecProps } from "./querymenusec.types";

import {
  codeViewWithCircle,
  contextIcon,
  filledCodeViewWithCircle,
  filledContextIcon,
  filledRomanInfoIconInCircle,
  filledVerboseIcon,
  queryDeleteIcon,
  refreshIconOutlined,
  romanInfoIconInCircle,
  saveIcon,
  saveIconFilled,
  thumbsDownSvg,
  thumbsUpSvg,
  tickIconGreen,
  verboseIcon,
} from "../../../../../../../../../../../../svgs";

import { ELEMENT_IDS } from "../../../../../../../../../../../../constants";
import { LOADING_TEXT } from "../../../../../../../../../../analysisdetalpage.constants";

import {
  checkIsChatPage,
  checkSourceType,
} from "../../../../../../../../../../../../utils";

import ConfirmationPopover from "../../../../../../../../../../../../components/confirmationpopover";
import ConditionalWrapper from "../../../../../../../../../../../../components/conditionalwrapper";

import {
  checkQueryType,
  filterQueryFiltersWhereFilterModelsExists,
  mergeTransientFiltersAndAppliedFilters,
} from "../../../../../../../../../../analisisdetailpage.utils";

const thumbsUpIcon = thumbsUpSvg("20", "20");
const thumbsDownIcon = thumbsDownSvg("20", "20");
const tickIcon = tickIconGreen();
const refreshIcon = refreshIconOutlined("16", "16");
const codeViewIcon = codeViewWithCircle("18", "18");
const filledCodeViewIcon = filledCodeViewWithCircle("18", "18");
const explanationIcon = romanInfoIconInCircle("20", "18");
const filledExplanationIcon = filledRomanInfoIconInCircle("20", "18");
const logIcon = verboseIcon("15", "18");
const filledLogIcon = filledVerboseIcon("15", "18");
const activeContextIcon = filledContextIcon("20", "20");
const outlinedContextIcon = contextIcon("20", "20");
const deleteIcon = queryDeleteIcon("16", "18");
const filledSaveIcon = saveIconFilled("16", "17");
const saveIconOutlined = saveIcon("16", "17");

const {
  caddi_qry_rslt_lst_fedbk_sec: CADDI_QRY_RSLT_LST_FEDBK_SEC,
  caddi_qry_rslt_lst_fedbk_sec_thms_up_btn: CADDI_QRY_RSLT_LST_FEDBK_SEC_THMS_UP_BTN,
  caddi_qry_rslt_lst_fedbk_sec_thms_dn_btn: CADDI_QRY_RSLT_LST_FEDBK_SEC_THMS_DN_BTN,
  caddi_qry_menu_sec: CADDI_QRY_MENU_SEC,
  caddi_qry_rslt_lst_bokm_sec: CADDI_QRY_RSLT_LST_BOKM_SEC,
} = ELEMENT_IDS;

const isChatPage = checkIsChatPage();

const scrollToElement = (
  ref: React.RefObject<HTMLDivElement>,
  topDiff: number,
  isMaximizeQueryBlock = false
): void => {
  if (isMaximizeQueryBlock) {
    ref?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
  } else {
    const rect = ref?.current?.getBoundingClientRect() ?? 0;
    const top = rect ? window.scrollY + rect.top : 0;

    window.scrollTo({
      top: top - topDiff,
      left: 0,
      behavior: "smooth",
    });
  }
};

const QueryMenuSec = (props: QueryMenuSecProps): JSX.Element => {
  const queryMenuSecRef = useRef<HTMLDivElement>(null);

  const {
    queryItem,
    handlers,
    showFeedbackSec = false,
    index = 0,
    totalItems = 0,
    isShowFeedbackSuccessMsg = false,
    feedbackSuccessMsg = "",
    isShowFeedbackForm = false,
    toggleFeedbackSuccessMsg,
    toggleFeedbackFormVisibility,
    errorCase = false,
    callOnRefreshSingleAnalysisWithRef,
    queryContext = [],
    isAppliedfiltersChanged = false,
    hasUserAccessToEdit,
    onDislikeClick,
    showRefreshQueryTooltip = false,
    setShowRefreshQueryTooltip,
    analysisSrcTypeId,
    codeViewRef,
    explanationRef,
    logRef,
    isAuthor = false,
    isGatewayRunning,
  } = props;

  const {
    id = "",
    user_feedback: { response },
    is_loading: isLoading,
    query,
    header,
    results,
    filters,
    type,
    sub_type: subType,
    is_focused: isFocused,
  } = queryItem || {};

  const { sql_query: sqlQuery = "" } = query || {};

  const {
    is_sql_query_visible: isSqlQueryVisible = false,
    is_explanation_visible: isExplanationVisible = false,
    is_verbose_visible: isVerboseVisible = false,
    is_use_context: isUseContext = false,
    is_maximize_query_block: isMaximizeQueryBlock = false,
    is_question_saved: isQuestionSaved = false,
  } = header || {};

  const {
    is_caddi_sample_data: isCaddiSampleData = false,
    query_explanation: queryExplanation = "",
    verbose_response: verboseResponse = [],
  } = results || {};

  const {
    applied_filters: appliedFilters,
    transiant_filters: transiantFilters,
  } = filters || {};

  const { isRestAPISource } = checkSourceType(analysisSrcTypeId);

  const {
    onSaveFeedback,
    onToggleCodeView,
    onToggleQueryExplanation,
    onShowVerboseIconClick,
    onDelete,
    onUseContext,
    onRemoveFeedback,
    onToggleSavedQuestion,
  } = handlers || {};

  const [isShowSpinner, setIsShowSpinner] = useState(false);
  const [showContextTooltip, setShowContextTooltip] = useState(false);

  const [isDeletePopoverVisible, setIsDeletePopoverVisible] = useState(false);
  const [isDeleteTooltipVisible, setIsDeleteTooltipVisible] = useState(false);

  const {
    isSummary,
    isAboutPrompt,
    isConceptQuery,
    isGuideMeSec,
    isTermQuery,
  } = checkQueryType(type, subType);

  const showMenu =
    !isSummary &&
    // !isGuideMeSec &&
    !isConceptQuery &&
    !isAboutPrompt &&
    (isFocused || isChatPage);

  const isReadOnlyMode = isChatPage && !isAuthor;

  const onLikeClick = useCallback(() => {
    if (response === "like") {
      setIsShowSpinner(true);

      onRemoveFeedback?.([id], (): void => {
        toggleFeedbackSuccessMsg?.("Feedback removed successfully!");
        setIsShowSpinner(false);
      });
    } else {
      if (isShowFeedbackForm) {
        toggleFeedbackFormVisibility?.();
      }

      setIsShowSpinner(true);
      onSaveFeedback?.(
        id,
        {
          response: "like",
        },
        (): void => {
          setIsShowSpinner(false);
          toggleFeedbackSuccessMsg?.();
        }
      );
    }
  }, [
    id,
    isChatPage,
    response,
    isShowFeedbackForm,
    onSaveFeedback,
    toggleFeedbackFormVisibility,
    onRemoveFeedback,
    toggleFeedbackSuccessMsg,
  ]);

  const isLastItem = index === totalItems - 1;

  const deletePopoverVisibleChange = useCallback((visible: boolean) => {
    setIsDeleteTooltipVisible(false);
    setIsDeletePopoverVisible(visible);
  }, []);

  const deleteTooltipVisibleChange = useCallback(
    (visible: boolean) => (): void => {
      setIsDeleteTooltipVisible(visible);
    },
    []
  );

  const onCodeViewClick = useCallback(
    () => (e: React.MouseEvent<HTMLElement>): void => {
      onToggleCodeView?.(id, e);

      if (isSqlQueryVisible && !isMaximizeQueryBlock) {
        scrollToElement(queryMenuSecRef, 500, isMaximizeQueryBlock);
      }

      if (!isSqlQueryVisible) {
        scrollToElement(codeViewRef, 100, isMaximizeQueryBlock);
      }
    },
    [
      id,
      isSqlQueryVisible,
      isMaximizeQueryBlock,
      codeViewRef,
      queryMenuSecRef,
      onToggleCodeView,
      scrollToElement,
    ]
  );

  const onQueryExplanationClick = useCallback(
    () => (e: React.MouseEvent<HTMLElement>): void => {
      onToggleQueryExplanation?.(id, e);

      if (isExplanationVisible && !isMaximizeQueryBlock) {
        scrollToElement(queryMenuSecRef, 500, isMaximizeQueryBlock);
      }

      if (!isExplanationVisible) {
        scrollToElement(explanationRef, 100, isMaximizeQueryBlock);
      }
    },
    [
      id,
      isExplanationVisible,
      isMaximizeQueryBlock,
      explanationRef,
      queryMenuSecRef,
      onToggleQueryExplanation,
      scrollToElement,
    ]
  );

  const onLogClick = useCallback(
    () => (): void => {
      onShowVerboseIconClick?.(id);

      if (isVerboseVisible && !isMaximizeQueryBlock) {
        scrollToElement(queryMenuSecRef, 700, isMaximizeQueryBlock);
      }

      if (!isVerboseVisible) {
        scrollToElement(logRef, 100, isMaximizeQueryBlock);
      }
    },
    [
      id,
      isVerboseVisible,
      isMaximizeQueryBlock,
      logRef,
      queryMenuSecRef,
      onShowVerboseIconClick,
      scrollToElement,
    ]
  );

  const onDeleteConfirmation = useCallback(
    () => (): void => {
      onDelete?.(id);
    },
    [id, onDelete]
  );

  const onContextClick = useCallback(
    () => (e: React.MouseEvent<HTMLElement>): void => {
      setShowContextTooltip(false);
      onUseContext?.(id, queryContext, e);
    },
    [id, queryContext, onUseContext]
  );

  const onSavedQuestionClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      onToggleSavedQuestion?.(id, e);
    },
    [id, onToggleSavedQuestion]
  );

  const isFiltersExists = !!filterQueryFiltersWhereFilterModelsExists(
    mergeTransientFiltersAndAppliedFilters(appliedFilters, transiantFilters)
  )?.length;

  return (
    <QueryMenuSecStyled
      id={`${CADDI_QRY_MENU_SEC}-${id}`}
      ref={queryMenuSecRef}
    >
      {(isFocused || isChatPage) && (
        <>
          <div
            className="menu-action-sec"
            id={isLastItem ? CADDI_QRY_RSLT_LST_FEDBK_SEC : ""}
          >
            {showMenu && !(errorCase && !showFeedbackSec) && (
              <>
                {isShowSpinner ? (
                  <Spin size="small" />
                ) : (
                  !(
                    isReadOnlyMode &&
                    (!response || response === "dislike")
                  ) && (
                    <ConditionalWrapper
                      condition={response === "dislike"}
                      wrapper={(child): JSX.Element => (
                        <ConfirmationPopover
                          heading="Change Feedback"
                          desc="You already submitted feedback. Are you sure you want to change it?"
                          placement="topLeft"
                          arrowPointAtCenter
                          okText="Yes"
                          cancelText="No"
                          onOk={onLikeClick}
                          width="309px"
                        >
                          {child || <></>}
                        </ConfirmationPopover>
                      )}
                    >
                      <LinkButton
                        {...(response !== "dislike" && {
                          onClick: onLikeClick,
                        })}
                        disabled={isLoading || errorCase || isReadOnlyMode}
                        tooltipProps={{
                          title:
                            isReadOnlyMode && response !== "like"
                              ? ""
                              : isLoading
                              ? LOADING_TEXT
                              : errorCase
                              ? ""
                              : response === "like"
                              ? "Approved"
                              : "Approve",
                        }}
                        data-testid="analysis-detail-page-query-block-header-right-sec-thumbs-up-btn"
                        className={response === "like" ? "active" : ""}
                        elementId={
                          isLastItem
                            ? CADDI_QRY_RSLT_LST_FEDBK_SEC_THMS_UP_BTN
                            : ""
                        }
                      >
                        {thumbsUpIcon}
                      </LinkButton>
                    </ConditionalWrapper>
                  )
                )}
                {!(isReadOnlyMode && (!response || response === "like")) && (
                  <ConditionalWrapper
                    condition={response === "like"}
                    wrapper={(child): JSX.Element => (
                      <ConfirmationPopover
                        heading="Change Feedback"
                        desc="You already submitted feedback. Are you sure you want to change it?"
                        placement="topLeft"
                        arrowPointAtCenter
                        okText="Yes"
                        cancelText="No"
                        onOk={onDislikeClick}
                      >
                        {child || <></>}
                      </ConfirmationPopover>
                    )}
                  >
                    <LinkButton
                      {...(response !== "like" && { onClick: onDislikeClick })}
                      disabled={
                        isLoading ||
                        (isReadOnlyMode && (response === "like" || !response))
                      }
                      tooltipProps={{
                        title:
                          isReadOnlyMode && response !== "dislike"
                            ? ""
                            : isLoading
                            ? LOADING_TEXT
                            : response === "dislike"
                            ? "View Feedback"
                            : "Reject",
                      }}
                      data-testid="analysis-detail-page-query-block-header-right-sec-thumbs-down-btn"
                      className={response === "dislike" ? "active" : ""}
                      elementId={
                        isLastItem
                          ? CADDI_QRY_RSLT_LST_FEDBK_SEC_THMS_DN_BTN
                          : ""
                      }
                    >
                      {thumbsDownIcon}
                    </LinkButton>
                  </ConditionalWrapper>
                )}
              </>
            )}

            {(isChatPage || hasUserAccessToEdit) && (
              <>
                {!isConceptQuery &&
                  !isAboutPrompt &&
                  !isReadOnlyMode &&
                  isGatewayRunning && (
                    <LinkButton
                      onClick={callOnRefreshSingleAnalysisWithRef}
                      disabled={isLoading}
                      data-testid="analysis-detail-page-query-block-header-right-sec-refresh-btn"
                      className="refresh-btn"
                      tooltipProps={
                        showRefreshQueryTooltip
                          ? {
                              title: showRefreshQueryTooltip
                                ? "Run this query again to check updated response"
                                : undefined,
                              overlayClassName: "query-refresh-tooltip",
                              visible: showRefreshQueryTooltip,
                              onVisibleChange: (visible): void => {
                                setShowRefreshQueryTooltip(visible);
                              },
                              placement: "topLeft",
                            }
                          : {
                              title: isLoading ? LOADING_TEXT : "Refresh Data",
                            }
                      }
                    >
                      {showRefreshQueryTooltip ? (
                        <HighlightIcon>{refreshIcon}</HighlightIcon>
                      ) : (
                        refreshIcon
                      )}
                    </LinkButton>
                  )}

                {showMenu && (
                  <>
                    {sqlQuery && (
                      <LinkButton
                        onClick={onCodeViewClick()}
                        disabled={!sqlQuery}
                        tooltipProps={{
                          title: sqlQuery
                            ? `${isSqlQueryVisible ? "Hide" : "Show"} Code`
                            : "Code view does not exist for this question",
                        }}
                        data-testid={`query-menu-${
                          isSqlQueryVisible ? "active" : "inactive"
                        }-code-view-btn-${id}`}
                        className={isSqlQueryVisible ? "active" : ""}
                      >
                        {isSqlQueryVisible ? filledCodeViewIcon : codeViewIcon}
                      </LinkButton>
                    )}

                    {!!queryExplanation?.length && (
                      <LinkButton
                        onClick={onQueryExplanationClick()}
                        tooltipProps={{
                          title: queryExplanation?.length
                            ? `${
                                isExplanationVisible ? "Hide" : "Show"
                              } Explanation`
                            : "Explanation does not exist for this question",
                        }}
                        className={isExplanationVisible ? "active" : ""}
                        disabled={!queryExplanation?.length}
                        data-testid={`query-menu-${
                          isExplanationVisible ? "active" : "inactive"
                        }-explanation-btn-${id}`}
                      >
                        {isExplanationVisible
                          ? filledExplanationIcon
                          : explanationIcon}
                      </LinkButton>
                    )}

                    {!!verboseResponse?.length && (
                      <LinkButton
                        onClick={onLogClick()}
                        tooltipProps={{
                          title: verboseResponse?.length
                            ? `${isVerboseVisible ? "Hide" : "Show"} ${
                                isRestAPISource ? "workflow messages" : "Log"
                              }`
                            : "Log does not exist for this question",
                        }}
                        className="log-btn"
                        disabled={!verboseResponse?.length}
                        data-testid={`query-menu-${
                          isVerboseVisible ? "active" : "inactive"
                        }-logs-btn-${id}`}
                      >
                        {isVerboseVisible ? filledLogIcon : logIcon}
                      </LinkButton>
                    )}
                  </>
                )}

                {!isAboutPrompt && !isReadOnlyMode && (
                  <LinkButton
                    className="menu-save-icon"
                    onClick={onSavedQuestionClick}
                    tooltipProps={{
                      title: isQuestionSaved ? "Bookmarked" : "Bookmark",
                    }}
                    elementId={isLastItem ? CADDI_QRY_RSLT_LST_BOKM_SEC : ""}
                  >
                    {isQuestionSaved ? filledSaveIcon : saveIconOutlined}
                  </LinkButton>
                )}

                {showMenu &&
                  !isTermQuery &&
                  !isRestAPISource &&
                  !isGuideMeSec &&
                  !isReadOnlyMode && (
                    <LinkButton
                      onClick={onContextClick()}
                      disabled={
                        isUseContext ||
                        isAppliedfiltersChanged ||
                        (isCaddiSampleData && !isFiltersExists) ||
                        isLoading ||
                        errorCase
                      }
                      tooltipProps={{
                        title: isLoading
                          ? LOADING_TEXT
                          : errorCase
                          ? ""
                          : isUseContext
                          ? "Using this question as context for subsequent questions"
                          : isAppliedfiltersChanged
                          ? "First refresh the query to save context"
                          : isCaddiSampleData && !isFiltersExists
                          ? "Context can't be saved for the sample data without filters"
                          : "Save this question as context for subsequent questions",
                        visible: showContextTooltip,
                        onVisibleChange: (visible): void => {
                          setShowContextTooltip(visible);
                        },
                      }}
                      data-testid="analysis-detail-page-query-block-header-right-sec-context-btn"
                      className="qry-context-icon"
                    >
                      {isUseContext ? activeContextIcon : outlinedContextIcon}
                    </LinkButton>
                  )}

                {!isAboutPrompt && !isReadOnlyMode && (
                  <ConfirmationPopover
                    heading={`Delete ${
                      isConceptQuery ? "Concept" : "Question"
                    }`}
                    desc="This action cannot be reversed. Are you sure?"
                    arrowPointAtCenter
                    okText="Delete"
                    cancelText="Cancel"
                    onOk={onDeleteConfirmation()}
                    placement="top"
                    // zIndex={0} // TODO: Will discuss with bader about this
                    visible={isDeletePopoverVisible}
                    propOnVisibleChange={deletePopoverVisibleChange}
                    {...(isLoading && { visible: false })}
                  >
                    <LinkButton
                      disabled={isLoading}
                      onMouseEnter={(): void => setIsDeleteTooltipVisible(true)}
                      onMouseLeave={(): void =>
                        setIsDeleteTooltipVisible(false)
                      }
                      tooltipProps={{
                        title: isLoading
                          ? LOADING_TEXT
                          : `Delete ${
                              isConceptQuery
                                ? "Concept"
                                : isAboutPrompt
                                ? "info"
                                : isGuideMeSec
                                ? "Generated Questions"
                                : "Question"
                            }`,
                        trigger: "hover",
                        visible: isDeleteTooltipVisible,
                        onVisibleChange: deleteTooltipVisibleChange,
                      }}
                      data-testid={`query-menu-delete-btn-${id}`}
                    >
                      {deleteIcon}
                    </LinkButton>
                  </ConfirmationPopover>
                )}
              </>
            )}
          </div>

          {isShowFeedbackSuccessMsg && !isShowSpinner && (
            <div className="feedback-success-sec">
              <div className="feedback-success-msg">
                {tickIcon}
                {feedbackSuccessMsg}
              </div>
            </div>
          )}
        </>
      )}
    </QueryMenuSecStyled>
  );
};

export default QueryMenuSec;
