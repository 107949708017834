import { Tooltip, Upload } from "antd";
import { RcFile } from "antd/lib/upload";
import { useCallback, useState } from "react";
import { ExcelSourceEmptyState } from "..";
import { DVSUM_TOOLTIP_CLASS_NAME } from "../../../../../../constants";
import { fileIcon } from "../../../../../../svgs";
import { ExcelSourceUploadSecStyled } from "./excelsourcefilesuploadsec.styles";
import { ExcelSourceFileUploadSecProps } from "./excelsourcefileuploadsec.types";

const { Dragger } = Upload;
const acceptFilesTypes = ".xlsx,.xls,.xlsm,.xlt,.xlr,.csv,.CSV";

function ExcelSourceFileUploadSec(
  props: ExcelSourceFileUploadSecProps
): JSX.Element {
  const { isEmpty, onUpload, isDisabled = false, isUploadingFiles } = props;
  const [hasError, setError] = useState<boolean>();

  const onBeforeUpload = useCallback(
    (file: RcFile, files: RcFile[]) => {
      const validFilesTypesArr = acceptFilesTypes?.split(",");
      const hasValidFilesSelected = files?.every((file) => {
        const splittedFileName = file?.name?.split(".");
        const fileExt = splittedFileName?.[splittedFileName?.length - 1] || "";
        return validFilesTypesArr?.includes(`.${fileExt}`);
      });

      if (hasValidFilesSelected) {
        onUpload(file, files);
        setError(false);
      } else {
        setError(true);
      }
      return false;
    },
    [props]
  );

  const commonConfig = {
    accept: acceptFilesTypes,
    beforeUpload: onBeforeUpload,
    fileList: [],
    multiple: true,
  };

  return (
    <ExcelSourceUploadSecStyled isEmpty={isEmpty}>
      {isEmpty ? (
        <div className="file-upload-empty-sec">
          <Dragger className="empty-state-upload-file" {...commonConfig}>
            <ExcelSourceEmptyState />
          </Dragger>
          {hasError && (
            <div className="empty-state-error-message">
              Invalid file(s) selected. Selected file(s) must be type of (
              {acceptFilesTypes})
            </div>
          )}
        </div>
      ) : (
        <>
          <Tooltip
            title={
              isUploadingFiles ? "Some operations are already in progress." : ""
            }
            overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
            placement="top"
          >
            <div>
              <Dragger
                className="upload-files"
                {...commonConfig}
                disabled={isDisabled}
              >
                {fileIcon()}
                <div className="static-text">Upload files</div>
              </Dragger>
            </div>
          </Tooltip>
          {hasError && (
            <div className="error-message">
              Invalid file(s) selected. Selected file(s) must be type of (
              {acceptFilesTypes})
            </div>
          )}
        </>
      )}
    </ExcelSourceUploadSecStyled>
  );
}

export default ExcelSourceFileUploadSec;
