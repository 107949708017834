//   <--Library Imports Start-->
import { cloneElement, useCallback, useState } from "react";
//   <--Library Imports End-->

//   <--Components Start-->
import ExtBreadCrumb from "../breadcrum/extbreadcrumb/extbreadcrumb";
import { NodeRefQuickEditEditView } from "./noderefpagehoc.components";

import {
  OverviewSuggestAnEdit,
  MarkAsDeprecateSuggestionForm,
  OverviewCreateSuggestion,
} from "../../forms";
//   <--Components End-->

//   <--Types Start-->
import {
  NodeQuickEditClickParams,
  NodeRefPageHOCEmbededProps,
  NodeRefPageHOCProps,
  OnNodeQuickEditClick,
} from "./noderefpagehoc.types";

import { NodeType } from "../../app.types";
//   <--Types End-->

type ScreenTypes = "REF" | "OPT" | "SUG" | "DEP";

const NodeRefPageHOC = ({
  children,
  ...props
}: NodeRefPageHOCProps): JSX.Element => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [screenType, setScreenType] = useState<ScreenTypes>("REF");

  const [isEdited, setIsEdited] = useState(false);

  const [formProps, setFormProps] = useState<NodeQuickEditClickParams>({});

  const [isWfEnabled, setIsWfEnabled] = useState(false);

  const switchScreen = useCallback(
    (sType?: ScreenTypes) => {
      if (!sType && screenType === "REF") {
        props?.setShowParentGoBack && props?.setShowParentGoBack(false);
      } else {
        if (sType === "REF") {
          props?.setShowParentGoBack && props?.setShowParentGoBack(true);
        } else if (!sType) {
          props?.setShowParentGoBack &&
            props?.setShowParentGoBack(
              screenType !== "SUG" && screenType !== "DEP"
            );
        }
        setScreenType(
          sType ||
            (screenType === "SUG" || screenType === "DEP" ? "OPT" : "REF")
        );
      }
    },
    [screenType]
  );

  const onClickSuggest = useCallback(
    (
      nodeType?: NodeType,
      nodeId?: string,
      nodeTitle?: string,
      nodeDesc?: string
    ) => {
      props?.setShowParentGoBack && props.setShowParentGoBack(false);
      switchScreen("OPT");
      setFormProps({ nodeId, nodeType, nodeTitle, nodeDesc });
    },
    [screenType, formProps]
  );

  const onClickEdit: OnNodeQuickEditClick = useCallback(
    (params = {}) => {
      setIsWfEnabled(!!params?.wfEnabled);
      setIsEditMode(true);
      setFormProps({
        ...params,
      });

      setIsEdited(false);
    },
    [isEditMode, formProps, isEdited]
  );

  const onCancelEditMode = useCallback(() => {
    setIsEditMode(false);
  }, [isEditMode]);

  const onSuccessUpdateIsEdited = useCallback(() => {
    setIsEdited(true);
  }, [isEdited]);

  return (
    <div>
      {screenType !== "REF" && <ExtBreadCrumb onBackClick={switchScreen} />}
      {screenType === "SUG" ? (
        <OverviewCreateSuggestion
          {...formProps}
          isRefPage
          onCancel={(): void => switchScreen("REF")}
        />
      ) : screenType === "DEP" ? (
        <MarkAsDeprecateSuggestionForm
          {...formProps}
          isRefPage
          onCancel={(): void => switchScreen("REF")}
        />
      ) : screenType === "OPT" ? (
        <OverviewSuggestAnEdit
          {...formProps}
          isRefPage
          onSelectOption={(option): void =>
            switchScreen(option === "suggest" ? "SUG" : "DEP")
          }
        />
      ) : (
        cloneElement(
          children as JSX.Element,
          {
            ...props,
            onClickSuggest,
            onClickEdit,
            editViewComp: isEditMode ? (
              <NodeRefQuickEditEditView
                {...formProps}
                onCancel={onCancelEditMode}
                onSuccessUpdateIsEdited={onSuccessUpdateIsEdited}
                isWfEnable={isWfEnabled}
              />
            ) : undefined,
            isEdited,
          } as NodeRefPageHOCEmbededProps
        )
      )}
    </div>
  );
};

export default NodeRefPageHOC;
