import styled from "styled-components";

export const TypingAnimationStyled = styled.span`
  .typing-animation {
    color: #000000;
  }

  .typing-animation::after {
    content: "|";
    color: transparent;
    opacity: 0.9;
    display: inline-block;
    width: 0;
    border-right: 2px solid #000; /* Adjust the thickness and color as needed */
    animation: blink-caret 0.75s step-end infinite;
    margin-left: 2px; /* Adjust the distance between the text and the cursor */
  }

  @keyframes blink-caret {
    from,
    to {
      border-color: transparent;
    }
    50% {
      border-color: #000;
    } /* Adjust the color to match your text color */
  }
`;
