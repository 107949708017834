import styled from "styled-components";

const flexCenter = `
  display: flex;
  align-items: center;
`;

export const QueryErrorSectionStyled = styled.div<{ isBlackColor?: boolean }>`
  width: 100%;

  .error-state-msg {
    color: ${({ theme, isBlackColor }): string =>
      isBlackColor
        ? theme?.textColors?.BLACK_COLOR
        : theme?.textColors?.RED_100};

    flex-wrap: wrap; //to make content wrap when feedback shown alnog with error
    position: relative; //to check height of error message when feedback is shown
    .feedback-success-wrapper {
      ${flexCenter}

      .feedback-success-msg {
        ${flexCenter}
        column-gap: 3px;
        font-size: 13.6px;
        color: ${({ theme }): string => theme?.textColors?.GREEN_111};
      }
    }
  }

  .query-results-flex {
    .created-at {
      font-size: 12px;
      color: ${({ theme }): string => theme?.textColors?.GREY_210};
    }
  }
`;
