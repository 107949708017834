import styled from "styled-components";

export const ScanningInfoFormStyled = styled.div`
  .weekly-field-item-container {
    .ant-form-item .ant-form-item-control {
      display: none;
    }
  }
  .ant-row.ant-form-item {
    padding-left: 0px;
  }
  .inline-fields {
    display: flex;
    align-items: baseline;
    column-gap: 10px;

    label {
      font-size: 14px;
    }
  }
  .scan-summary {
    font-size: 14px;
    font-family: OpenSansBold;
    color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
  }

  .is-repeat-cb {
    font-size: 14px;
    font-family: OpenSans;
    margin-bottom: 25px;
  }

  .ends-on-options-container {
    display: grid;
    grid-template-columns: 113.33px 113.33px 140px;

    > div {
      display: flex;
      align-items: center;
      column-gap: 4px;
      margin-top: 8px;
    }
  }

  .select-field-container {
    .ant-select-item {
      .ant-select-item-option-content {
      }
    }
    .ant-select {
      .ant-select-selector {
        .ant-select-selection-placeholder {
        }
        .ant-select-selection-item {
          .custom-option {
            svg {
              display: none;
            }
          }
        }
      }
    }
  }
`;

export const InlineFieldsStyled = styled.div`
  display: flex;
  align-items: baseline;
  column-gap: 10px;

  label {
    font-size: 14px;
  }
`;

export const AfterFieldContainerStyled = styled.div<{
  calledFromSourcePage?: boolean;
}>`
  grid-column-start: ${({ calledFromSourcePage }): string =>
    calledFromSourcePage ? "2" : ""};

  margin-top: 4px;
  display: flex;
  align-items: center;
  column-gap: 8px;

  & {
    padding-left: ${({ calledFromSourcePage }): string =>
      calledFromSourcePage ? "" : "32px"};
  }

  .text {
    font-size: 14px;
    font-family: OpenSans;
    color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
    position: absolute;
    left: 100px;
    top: 10px;
  }
`;

export const OnFieldContainerStyled = styled.div<{
  calledFromSourcePage?: boolean;
}>`
  grid-column-start: ${({ calledFromSourcePage }): string =>
    calledFromSourcePage ? "3" : ""};

  margin-top: 4px;

  & {
    padding-left: ${({ calledFromSourcePage }): string =>
      calledFromSourcePage ? "" : "32px"};
  }
`;
