import styled from "styled-components";
import { ellipsis } from "../../../styles/app.styles";

export const ListItemStyled = styled.li<{
  inactive?: boolean;
  color: string;
  legendsCount: number;
}>`
  .list-item-icon {
    width: 8px;
    height: 8px;
    opacity: ${({ inactive }): string => (inactive ? "0.6" : "1")};
    background-color: ${({ color }): string => color};
  }

  opacity: ${({ inactive }): string => (inactive ? "0.6" : "1")};
  .pie-chart-legend {
    letter-spacing: 0.1ch;
  }
  .chart-legend {
    min-width: 135px;
    max-width: ${({ legendsCount }): string =>
      legendsCount > 3 ? "135px" : "100%"};
    color: ${({ theme }): string => theme?.textColors?.DARK_GREY_120};
    ${ellipsis}
  }
`;

export const LegendWrapperStyled = styled.ul`
  display: flex;
  justify-content: flex-start;
  list-style-type: square;
  margin-left: -100px;
  flex-wrap: wrap;
  max-width: 90%;
  padding: 0 0 0 20px;
  column-gap: 15px;

  li {
    display: flex;
    align-items: center;
    gap: 5px;
  }
`;
