import styled from "styled-components";

export const TagsBasedGridFiltersStyled = styled.div<{
  mode: "primary" | "secondary";
}>`
  display: flex;
  column-gap: 8px;
  row-gap: 15px;

  .filters-list-label {
    min-width: fit-content;
  }

  .filters-list-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 8px;

    .filter-tag {
      min-height: 25px;
      border-radius: ${({ mode }): string =>
        mode === "primary" ? "37px" : ""};
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 13px;
      text-transform: capitalize;
      cursor: default;
      color: ${({ theme }): string => theme.textColors.GREY_24};
      background: ${({ theme }): string => theme.bgColors.LIGHT_GREY_22};

      .filter-count {
        font-family: OpenSansSemiBold;
        margin-right: 3px;
      }
      .filter-operator {
        margin-left: 2px;
      }

      .filter-val {
        font-family: OpenSansSemiBold;
        margin-left: 2px;
        word-break: break-word;
        white-space: pre-line;
        padding-right: 8px;
      }

      .remaining-tags {
        padding: 0 5px;
        border-radius: 4px;
        background: ${({ theme }): string => theme.bgColors.GREY_56};
        font-family: OpenSansSemiBold;
        color: ${({ theme }): string => theme.textColors.GREY_24};
        font-size: 12px;
        margin: 0 5px 0 8px;
      }

      .ant-tag-close-icon {
        margin-top: 1px;
      }
    }

    .clear-all-btn {
      color: ${({ theme }): string => theme.textColors.BLUE_116};
      cursor: pointer;
    }
  }
`;

export const RemainingTagListItem = styled.div`
  padding-right: 52px;
`;
