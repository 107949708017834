import {
  ExclusionCriteriaSectionStyled,
  ListingStyled,
} from "./exclusioncriteriasection.styles";
import {
  ExclusionCriteriaFilterType,
  ExclusionCriteriaSectionType,
} from "./exclusioncriteriasection.types";
import { getTransformedFilter } from "./exclusioncriteriasection.util";

const ListingWrapper = ({
  title = "",
  exclusionCriteria,
}: {
  title: string;
  exclusionCriteria: ExclusionCriteriaFilterType;
}): JSX.Element => {
  return (
    <ListingStyled>
      <div className="name">{title}:</div>
      <div className="filters-values">
        {!!exclusionCriteria?.length &&
          exclusionCriteria?.map((criteria) => {
            return (
              <div className="value" key={`column-criteria-${criteria}`}>
                {criteria?.column}: {`${criteria?.filter}  ${criteria?.value}`}
              </div>
            );
          })}
      </div>
    </ListingStyled>
  );
};

const ExclusionCriteriaSection = (
  props: ExclusionCriteriaSectionType
): JSX.Element => {
  const { columnFilters, tableFilters } = props;

  return (
    <ExclusionCriteriaSectionStyled>
      {" "}
      <div
        className="criteria-container"
        data-testid="analysis-detail-page-overview-sec-criteria-container"
      >
        <div
          className="label"
          data-testid="analysis-detail-page-overview-shared-with-text"
        >
          Following criterion as defined in Module Settings is used to exclude
          tables and columns from the chat.
        </div>

        {!!tableFilters?.length && (
          <ListingWrapper
            title="Table Exclusion Criteria "
            exclusionCriteria={getTransformedFilter(tableFilters)}
          />
        )}
        {!!columnFilters?.length && (
          <ListingWrapper
            title="Column Exclusion Criteria "
            exclusionCriteria={getTransformedFilter(columnFilters)}
          />
        )}
      </div>
    </ExclusionCriteriaSectionStyled>
  );
};

export default ExclusionCriteriaSection;
