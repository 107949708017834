import { Tooltip } from "antd";
import { useCallback, useState } from "react";

import {
  ActionItemStyled,
  DropDownButtonStyled,
  DropDownWrapperStyled,
  ButtonActionMenuStyled,
  ThreeDotMenuButton,
} from "./buttonactionmenu.styles";

import { DVSUM_TOOLTIP_CLASS_NAME } from "../../constants";
import { actionsIcon } from "../../svgs";

import { ButtonActionMenuProps } from "./buttonactionmenu.types";
import LinkButton from "../linkbutton/linkbutton";

const threeDotActionIcon = actionsIcon();

const ButtonActionMenu = (props: ButtonActionMenuProps): JSX.Element => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const { overLayItems, customButton } = props;

  const onVisibleChange = useCallback((visibility) => {
    setIsMenuVisible(visibility);
  }, []);

  return (
    <ButtonActionMenuStyled>
      <DropDownWrapperStyled isVisible={isMenuVisible}>
        <DropDownButtonStyled
          overlay={
            <>
              {overLayItems?.map((item) => {
                const { name, icon, onClick, customClass = "", disable } = item;

                const onMenuItemClick = (): void => {
                  onClick();
                  setIsMenuVisible(false);
                };

                return (
                  <Tooltip
                    key={`overlay-item-${name}`}
                    title={item?.desc}
                    placement="left"
                    overlayClassName={`${DVSUM_TOOLTIP_CLASS_NAME}`}
                  >
                    <ActionItemStyled
                      onClick={onMenuItemClick}
                      key={`overlay-item-${name}`}
                      className={`action-item ${customClass}`}
                      disabled={disable}
                    >
                      {icon} {name}
                    </ActionItemStyled>
                  </Tooltip>
                );
              })}
            </>
          }
          trigger={["click"]}
          placement="bottomRight"
          onVisibleChange={onVisibleChange}
          visible={isMenuVisible}
          getPopupContainer={(trigger): HTMLElement =>
            trigger.parentNode as HTMLElement
          }
        >
          {customButton ? (
            <LinkButton
              onClick={(): void => {
                setIsMenuVisible(!isMenuVisible);
              }}
            >
              {customButton}
            </LinkButton>
          ) : (
            <ThreeDotMenuButton
              className="actions-container"
              onClick={(): void => {
                setIsMenuVisible(!isMenuVisible);
              }}
              hasCommonStyle={false}
            >
              <div className="action-icon"> {threeDotActionIcon}</div>
            </ThreeDotMenuButton>
          )}
        </DropDownButtonStyled>
      </DropDownWrapperStyled>
    </ButtonActionMenuStyled>
  );
};

export default ButtonActionMenu;
