import styled from "styled-components";

export const CancelScheduleModalStyled = styled.div`
  .description {
    padding: 32px 53px 51px 23px;
  }

  .action-sec {
    background-color: ${({ theme }): string => theme.bgColors.LIGHT_BLUE_25};
    padding-bottom: 8px;
    padding-top: 8px;
    padding-right: 18px;
    padding-left: 23px;
  }
`;
