import * as yup from "yup";
import { REQUIRED_MESSAGE } from "../../constants/formconstants";

export const addTermSchema = yup.object().shape({
  name: yup.string().required(REQUIRED_MESSAGE),
  title: yup.string(),
  definition: yup.string().required(REQUIRED_MESSAGE),
  category: yup.string().required(REQUIRED_MESSAGE),
  type: yup.string().required(REQUIRED_MESSAGE),
});
