import styled from "styled-components";

export const SavedContextsListingViewStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    padding: 18px 16px;
    font-family: "OpenSansSemiBold";
    border-bottom: 1px solid
      ${({ theme }): string => theme?.borderColors?.GREY_56};
  }

  .context-listing-view-action-sec {
    padding: 9.6px 16px;
    border-bottom: 1px solid
      ${({ theme }): string => theme?.borderColors?.GREY_56};
    display: flex;
    align-items: center;
    column-gap: 19.6px;

    .add-icon {
      font-size: 19px;
    }

    .ant-btn {
      display: flex;
      align-items: center;
      column-gap: 4px;
    }

    .dvsum-popover {
      top: 95px !important;

      .ant-popover-content {
        .ant-popover-arrow {
          display: block;
        }
      }
    }
  }

  .content-sec {
    flex: 1;
  }
`;

export const ContextsListSecStyled = styled.div`
  height: 50%;
  overflow-y: auto;
  padding: 16px;
  border-bottom: 1px solid
    ${({ theme }): string => theme?.borderColors?.GREY_56};

  .content-sec {
    .heading {
      font-family: "OpenSansSemiBold";
    }

    .desc {
      font-size: 12px;
      color: ${({ theme }): string => theme?.borderColors?.GREY_24};
      margin-bottom: 12px;
    }

    .tags-list {
      display: flex;
      flex-direction: column;
      row-gap: 12px;
    }
  }

  .blankstate {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .content {
      color: ${({ theme }): string => theme?.borderColors?.GREY_210};
      width: 240px;
      text-align: center;
    }
  }
`;
