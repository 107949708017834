// <--Components Start-->
import CoreNodesCircleIcon from "../../../../../../../../components/corenodetypescircleicon";
import TableItem from "../tableitem/tableitem";
// <--Components End-->

// <--Styles Start-->
import { PinnedTablesViewStyled } from "./pinnedtablesview.styles";
// <--Styles End-->

// <--Types Start-->
import { PinnedTablesViewProps } from "./pinnedtablesview.types";
// <--Types End-->

const PinnedTablesView = (props: PinnedTablesViewProps): JSX.Element => {
  const {
    onAddQuery,
    pinnedTablesList,
    queries,
    updatePinnedTablesList,
  } = props;

  return (
    <PinnedTablesViewStyled>
      <div
        className="explorer-collapse-item-content-container"
        data-testid="analysis-detail-page-explorer-sec-pinned-sec-collapse-item-content-container"
      >
        {pinnedTablesList?.length ? (
          pinnedTablesList?.map((pinnedTable, index) => (
            <div
              key={index + pinnedTable?.title}
              className="pinned-item"
              data-testid="analysis-detail-page-explorer-sec-pinned-sec-pinned-item"
            >
              <CoreNodesCircleIcon
                nodeType="TBL"
                height="16px"
                width="16px"
                fontSize="11px"
              />

              <TableItem
                id={pinnedTable?.id}
                title={pinnedTable?.title}
                isPinned
                queries={queries}
                onAddQuery={onAddQuery}
                updatePinnedTablesList={updatePinnedTablesList}
                isCmgFrmPinned
                name={pinnedTable?.name}
              />
            </div>
          ))
        ) : (
          <div
            className="pinned-tables-blankstate"
            data-testid="analysis-detail-page-explorer-sec-pinned-sec-blankstate"
          >
            No table is available for chat yet
          </div>
        )}
      </div>
    </PinnedTablesViewStyled>
  );
};

export default PinnedTablesView;
