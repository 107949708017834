// <--- Library Imports Start --->
import styled from "styled-components";
// <--- Library Imports End --->

export const QueryBlockListingStyled = styled.div<{
  height: number;
  isChatPage?: boolean;
}>`
  background: ${({ theme, isChatPage }): string =>
    isChatPage ? "transparent" : theme?.bgColors?.LIGHT_GREY_22};
  width: 100%;
  height: ${({ height }): string => `${height}px`};
  padding: ${({ isChatPage }): string =>
    isChatPage ? "" : "19.2px 19.2px 10.4px 19.2px"};
  .virtuoso {
    height: ${({ height }): string => `${height}px`} !important;
  }

  .min-height-of-query-block {
    min-height: 20px;
  }
`;
