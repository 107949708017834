import styled from "styled-components";

export const EndOnboardingTutorialModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 62.4px 132px 62.4px 132px;
  color: ${({ theme }): string => theme?.textColors?.BLACK_COLOR};

  .congrats-txt {
    font-size: 20px;
    color: ${({ theme }): string => theme?.textColors?.BLACK_COLOR};
    font-family: OpenSansSemiBold;
    margin-top: 25px;
    margin-bottom: 8px;
  }

  .end-tutorial-modal-cont {
    text-align: center;
    margin-bottom: 27px;
  }

  .end-tutorial-modalfooter-info {
    margin-top: 10px;
    font-size: 12px;
    color: ${({ theme }): string => theme?.textColors?.GREY_24};
  }

  button {
    pointer-events: all;
  }

  .exit-btn {
    margin-top: 25px;
  }
`;
