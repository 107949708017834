import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import Timeline from "antd/lib/timeline";

import { useCancelDrawer, useGetAppState } from "../../customhooks";

import { FormStyled } from "../../components/form";
import { TextAreaField } from "../../components/formfields";

import LinkButton from "../../components/linkbutton/linkbutton";
import StateHandler from "../../components/statehandler/statehandler";

import {
  CommentHistoryDrawerWrapperStyled,
  CommentSection,
  FlexGrow,
  TimelineItemDescStyled,
  TimelineItemStyled,
} from "./activityhistorydrawer.styles";

import {
  CommentHistoryDrawerTypes,
  CommentInputFieldType,
} from "./activityhistorydrawer.types";

import { commentInputFieldFormSchema } from "../../utils/schemas/commenthistorydrawerschema";

import { sendMessageIcon } from "../../svgs";
import { useGetUserActivities } from "../../api/termservice/termservice";

import {
  activityBlankSlate,
  loadMoreSlate,
} from "../../blankslates/activityhistoryblankslate";
import {
  analyticsStatusTagMapping,
  getTimeDifference,
  utcTOLocalTimeZone,
} from "../../utils";

import { getNodeNameBasedOnType } from "./activityhistorydrawer.util";

import { HorizontalDividerStyled } from "../../components/dividers/dividers.styles";
import { EventType, UserActivityType } from "../../parsers/termsparser";

import { SingleTagRendrer } from "../../components/prominenttagslist/prominenttagslist";
import Flex from "../../components/flex";

export const FEEDBACK_STATUS_IDS: Array<EventType> = [
  "ACP",
  "CLS",
  "FIX",
  "IGN",
  "INP",
  "NEW",
];

const ActivityHistoryDrawer = (): JSX.Element => {
  const { drawer } = useGetAppState();
  const closeDrawer = useCancelDrawer();

  const [numOfRecordsToFetch, setNumOfRecordsToFetch] = useState(10);
  const [fetchingOlderActivities, setFetchingOlderActivities] = useState(false);
  const historyDivRef = useRef<HTMLDivElement>(null);

  const {
    activityRequest,
    nodeType = "TRM",
    nodeId = "",
    actionType = "CMT",
    isReportPage = false,
  } = (drawer?.drawerProps || {}) as CommentHistoryDrawerTypes;

  const {
    parsedData: userActivities,
    isLoading: isUserActivitiesLoading,
    isFetching: isUserActivitiesFetching,
    error: errorInUserActivities,
  } = useGetUserActivities(nodeId, nodeType, `${numOfRecordsToFetch}`);

  const { activities, hasMoreRecords } = userActivities || {};

  const onShowOlderActivities = useCallback(() => {
    setNumOfRecordsToFetch((st) => st + 10);
    setFetchingOlderActivities(true);
  }, []);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isValid },
  } = useForm<CommentInputFieldType>({
    defaultValues: {
      text: "",
    },
    mode: "onChange",
    resolver: yupResolver(commentInputFieldFormSchema),
  });

  const onSubmitWrapper = (formData: CommentInputFieldType): void => {
    actionType === "REJ" && closeDrawer();
    activityRequest?.(formData?.text, actionType, numOfRecordsToFetch);
    setValue("text", "");
    historyDivRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const nodeName = useMemo(
    () => getNodeNameBasedOnType(nodeType, isReportPage),
    [nodeType, isReportPage]
  );

  const TimeLineItemJsx = ({
    activity,
  }: {
    activity: UserActivityType;
  }): JSX.Element => {
    const {
      action_type: actionType,
      node_alert_status: nodeAlertStatus,
      action_title: title = "",
      performed_by: performedBy,
      action_desc: desc = "",
      show_description: showDescription = false,
      performed_on: performedOn,
    } = activity || {};

    const isResolvedOrAcknowledged =
      actionType === "ACK" || actionType === "RSL";

    const isAcknowledged = actionType === "ACK";

    const isAlerting = nodeAlertStatus === "ALE";
    const isFeedbackStatus = FEEDBACK_STATUS_IDS?.includes(actionType);

    const renderTags = (): JSX.Element =>
      isFeedbackStatus ? (
        //TODO: Will use the same type on activity and feedback status;
        <SingleTagRendrer tag={analyticsStatusTagMapping(actionType as any)} />
      ) : isResolvedOrAcknowledged ? (
        <SingleTagRendrer
          tag={{
            name: isAcknowledged ? "Acknowledged" : "Resolved",
            type: isAcknowledged ? "LBL" : "LGR",
          }}
          isBordered
        />
      ) : (
        <Flex alignItems="center" columnGap={5}>
          <SingleTagRendrer
            tag={{
              name: "Alerting",
              type: "LRD",
            }}
            isBordered
          />
          Executed
        </Flex>
      );

    const showTag = isAlerting || isResolvedOrAcknowledged || isFeedbackStatus;

    return (
      <TimelineItemStyled color="#069c56">
        <div className="timeline-item">
          <div className="activity-details">
            <span>{showTag ? renderTags() : title}</span>
            <span> by {performedBy}</span>
            <span>{getTimeDifference(utcTOLocalTimeZone(performedOn))}</span>
          </div>

          {desc && (
            <TimelineItemDescStyled isComment>
              <span className="desc">{desc}</span>
            </TimelineItemDescStyled>
          )}
        </div>
      </TimelineItemStyled>
    );
  };

  const groupedDataActivities = activities ? Object.keys(activities) : [];

  const groupedDataActivitiesLength = groupedDataActivities?.length;

  const activitiesHistoryExists = groupedDataActivitiesLength > 0;

  useEffect(() => {
    !isUserActivitiesFetching && setFetchingOlderActivities(false);
  }, [isUserActivitiesFetching]);

  return (
    <CommentHistoryDrawerWrapperStyled>
      <StateHandler
        isFetching={isUserActivitiesLoading && !fetchingOlderActivities}
        error={errorInUserActivities}
        blankSlate={activityBlankSlate}
        isShowBlankSlate={!fetchingOlderActivities}
      >
        <>
          <div className="drawer-content">
            <div className="drawer-title-wrapper">
              <p className="drawer-title">Activities</p>
            </div>
            <div className="activity-history-wrapper">
              <div
                className="top-of-activity-history-wrapper"
                ref={historyDivRef}
              />
              {activitiesHistoryExists ? (
                Object.entries(activities)?.map(([date, activities], index) => {
                  return (
                    <div key={`activity-history-${date}-${index}`}>
                      <p className="date">{date}</p>
                      <Timeline>
                        {activities?.map((activity) => {
                          return (
                            <TimeLineItemJsx
                              activity={activity}
                              key={`activitiy-history-item-${activity?.performed_on}-${activity?.action_title}`}
                            />
                          );
                        })}
                      </Timeline>
                    </div>
                  );
                })
              ) : (
                <div className="no-activities">
                  <span>No activity is available at the moment</span>
                </div>
              )}
            </div>
          </div>
          <FlexGrow />
          {hasMoreRecords && (
            <>
              <LinkButton
                className="show-older-activities"
                onClick={onShowOlderActivities}
              >
                <StateHandler
                  isFetching={fetchingOlderActivities}
                  error={errorInUserActivities}
                  blankSlate={loadMoreSlate}
                >
                  <span> Show Older</span>
                </StateHandler>
              </LinkButton>
              <HorizontalDividerStyled />
            </>
          )}

          <CommentSection className="comment-section">
            <FormStyled
              layout="vertical"
              onFinish={handleSubmit(onSubmitWrapper) as any}
              className="form-style"
              data-testid="message-input-field-form-wrapper"
            >
              <p className="form-label">
                {actionType === "REJ"
                  ? `Leave a reason for rejecting the ${nodeName}`
                  : `Leave a comment for ${nodeName}`}
              </p>
              <div className="form-components">
                <TextAreaField
                  name="text"
                  control={control}
                  autoSize
                  autoFocus
                  height="60.8"
                  placeholder={
                    actionType === "REJ"
                      ? "Give reason of Rejection"
                      : "Add a Comment"
                  }
                  onPressEnter={
                    isValid && (handleSubmit(onSubmitWrapper) as any)
                  }
                  data-testid="message-input-field-text-area-field"
                  disabled={
                    !!isUserActivitiesFetching || fetchingOlderActivities
                  }
                />

                <LinkButton
                  className="send-comment"
                  htmlType="submit"
                  disabled={!isValid}
                  data-testid="message-input-field-send-btn"
                >
                  {sendMessageIcon("18", "18")}
                </LinkButton>
              </div>
            </FormStyled>
          </CommentSection>
        </>
      </StateHandler>
    </CommentHistoryDrawerWrapperStyled>
  );
};
export default ActivityHistoryDrawer;
