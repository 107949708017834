import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Button } from "../../components";
import { FormItemStyled, FormStyled } from "../../components/form";
import { SelectField } from "../../components/formfields";
import { TagsGrid } from "../../pages/tagsetsdetailpage/tagsetsdetailpage.components";

import { useCancelModal, useGetAppState } from "../../customhooks";

import { mergeTagsSchema } from "../../utils/schemas/tagsetsschemas";

import { MergeTagsFormType, MergeTagsModalProps } from "./mergetagsform.types";
import { HandleSubmitType } from "../../app.types";

import { mergeTags } from "./mergetagsform.utils";

import { MergeTagsFormStyled } from "./mergetagsform.styles";

const MergeTagsForm = (): JSX.Element => {
  const onCancel = useCancelModal();
  const {
    modal: { modalProps = {} },
  } = useGetAppState();

  const {
    allTags,
    selected_tags,
    selectedTagsId,
    setState,
    setSelectedTags,
    remove,
    update,
  } = modalProps as MergeTagsModalProps;

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isValid },
  } = useForm<MergeTagsFormType>({
    defaultValues: { target_tag: undefined },
    resolver: yupResolver(mergeTagsSchema),
    mode: "onChange",
  });

  const onSubmit = useCallback(
    (values: MergeTagsFormType) => {
      const targetTagId = Number.parseInt(`${values?.target_tag}`);

      // Target tag with updated usage count
      const mergedTags = { ...mergeTags([...selected_tags], `${targetTagId}`) };

      // Updating the usage count of target tag
      const tagToUpdateIndex = allTags?.findIndex(
        (tag) => tag?.tag_id === targetTagId
      );

      update(tagToUpdateIndex, mergedTags);

      // Removing selected tags from fields list.
      const tagsToDeleteIndexes: number[] = allTags
        ?.map((tag, index) =>
          selectedTagsId?.includes(tag?.tag_id) && tag?.tag_id !== targetTagId
            ? index
            : -1
        )
        ?.filter((tagIndex) => tagIndex !== -1);

      remove([...tagsToDeleteIndexes]);

      // Adding in merged_tags array for using when sending data to BE
      setState((s) => ({
        ...s,
        merged_tags: [
          ...s?.merged_tags,
          {
            source_tags: selectedTagsId,
            target_tag: targetTagId,
          },
        ],
      }));
      setSelectedTags([]);
      onCancel();
    },
    [selected_tags, selectedTagsId, allTags, modalProps]
  );

  const optionsForSelect = useMemo(() => {
    return selected_tags?.map((tag) => ({
      label: tag?.tag || "",
      value: `${tag?.tag_id || 0}`,
      key: `${tag?.tag_id || 0}`,
    }));
  }, [selected_tags]);

  return (
    <MergeTagsFormStyled>
      <FormStyled
        onFinish={handleSubmit(onSubmit) as HandleSubmitType}
        isItemColumnLayout
      >
        <div className="form-content">
          <FormItemStyled label="Target Tag" required>
            <SelectField
              control={control}
              name="target_tag"
              options={optionsForSelect}
              setValue={setValue}
              placeholder="Select"
              isAllowClear={false}
            />
          </FormItemStyled>

          <TagsGrid tags={selected_tags} isShowBorder={false} />
        </div>

        <FormItemStyled
          label=""
          className="form-actions-sec"
          marginBottom="0px"
        >
          <Button id="cancel" width="74px" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            id="primary"
            width="74px"
            marginLeft="8px"
            htmlType="submit"
            disabled={!isValid}
          >
            Merge With Target
          </Button>
        </FormItemStyled>
      </FormStyled>
    </MergeTagsFormStyled>
  );
};

export default MergeTagsForm;
