// <--- Import Statements Start --->
import { useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
// <--- Import Statements End --->

// <--- Components Start --->
import SectionWithBody from "../../../../components/sectionwithbody";
import EditableGrid from "../editablegrid";

import TagsGrid from "../tagsgrid";
// <--- Components End --->

// <--- Constants Start --->
import { tagsetLabels } from "../../tagsetsdetailpage.constants";
// <--- Constants End --->

// <--- Schemas Start --->
import { tagsSectionSchema } from "../../../../utils/schemas/tagsetsschemas";
// <--- Schemas End --->

// <--- SVGs Start --->
import { editPencilIcon } from "../../../../svgs";
// <--- SVGs End --->

// <--- Types Start --->
import { TagConfigType, TagType } from "../../tagsetsdetailpage.types";
import { TagGridFormType, TagsSectionProps } from "./tagssection.types";
import { ELEMENT_IDS } from "../../../../constants";
// <--- Types End --->

const {
  dataorg_tagset_dtl_pg_tags_edt_btn: DATAORG_TAGSET_DTL_PG_TAGS_EDT_BTN,
  dataorg_tagset_dtl_pg_tags_save_btn: DATAORG_TAGSET_DTL_PG_TAGS_SAVE_BTN,
} = ELEMENT_IDS;

const TagsSection = (props: TagsSectionProps): JSX.Element => {
  const { state, actionsState, isLoading, setState, setActionsState } = props;

  const isClassificationTagset = state?.information?.type === "CLS";

  // States
  const [tagsState, setTagsState] = useState<TagConfigType>({ ...state?.tags });

  // Form
  const formProvider = useForm<TagGridFormType>({
    defaultValues: {
      allTags: [...tagsState?.all_tags],
    },
    resolver: yupResolver(tagsSectionSchema),
    mode: "onChange",
  });
  const {
    getValues,
    reset,
    formState: { isValid },
  } = formProvider;

  // Callbacks
  // Edit tags section
  const handleEdit = useCallback(() => {
    if (!actionsState?.tags?.isChanged) {
      setTagsState({ ...state?.tags });
      reset({ allTags: [...state?.tags?.all_tags] });
    }
    setActionsState((s) => ({
      ...s,
      tags: { ...s?.tags, isEdit: true },
    }));
  }, [state, actionsState]);

  // Discard changes
  const handleCancel = useCallback(() => {
    setActionsState((s) => ({
      ...s,
      tags: { ...s?.tags, isEdit: false },
    }));
    setTagsState({ ...state?.tags });
    reset({ allTags: [...state?.tags?.all_tags] });
  }, [state]);

  // Update changes
  const handleDone = useCallback(() => {
    const allTags =
      getValues("allTags")?.map(
        (tag): TagType => ({
          tag_id: tag?.tag_id || -1,
          tag: tag?.tag || "",
          description: tag?.description || "",
          prominent_tag_id: tag?.prominent_tag_id || "GRY",
          privacy: tag?.privacy,
          usage_count: tag?.usage_count || 0,
        })
      ) || [];

    setActionsState((s) => ({
      ...s,
      tags: { isEdit: false, isChanged: true },
    }));
    setState((s) => ({ ...s, tags: { ...tagsState, all_tags: [...allTags] } }));
  }, [tagsState]);

  return (
    <SectionWithBody
      headingProps={{
        title: tagsetLabels.tagsetdetail.tags.title,
        isShowIcon: actionsState?.isEdit || false,
        icon: editPencilIcon("15", "15"),
        className: "section-with-body",
        isEditable: actionsState?.tags?.isEdit || false,
        onEdit: handleEdit,
        onSave: handleDone,
        onCancel: handleCancel,
        isDisabledSaveButton: !isValid,
        isDisabledEditButton: isLoading,
        elemIdOfEdit: DATAORG_TAGSET_DTL_PG_TAGS_EDT_BTN,
        elemIdOfSave: DATAORG_TAGSET_DTL_PG_TAGS_SAVE_BTN,
      }}
    >
      {!actionsState?.tags?.isEdit ? (
        <TagsGrid
          isClassificationTagset={isClassificationTagset}
          tags={state?.tags?.all_tags}
        />
      ) : (
        <FormProvider {...formProvider}>
          <EditableGrid
            isClassificationTagset={isClassificationTagset}
            setState={setTagsState}
          />
        </FormProvider>
      )}
    </SectionWithBody>
  );
};

export default TagsSection;
