import { ImageStyled } from "./image.styles";
import { ImageProps } from "./image.types";

function Image(props: ImageProps): JSX.Element {
  return (
    <ImageStyled {...props}>
      <img src={`/assets/icons/${props.src}`} />
    </ImageStyled>
  );
}

export default Image;
