import * as yup from "yup";

import { DESC_MAX_RANGE_MSG } from "../../constants/formconstants";

import { TABLE_SECTION_TITLES } from "../../constants";

const { overview: OVERVIEW } = TABLE_SECTION_TITLES;
const { definition: DEFINITION, title: TITLE } = OVERVIEW;

export const dataSetDescriptionSchema = yup.object().shape({
  [DEFINITION]: yup.string().max(500, DESC_MAX_RANGE_MSG),
  [TITLE]: yup.string(),
});
