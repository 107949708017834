import styled from "styled-components";

export const ConnectionSettingsTabStyled = styled.div<{
  isADLSSource?: boolean;
}>`
  .settings-tab-detail-section {
    margin-bottom: 31px;

    .section-title {
      margin-bottom: 8px;
    }
    .sec-row {
      .main-row {
        .row-title {
          width: 171px;
        }

        .row-value {
          .read-only-password-container {
            position: relative;
            top: 4px;
          }

          .info-icon-container {
            display: flex;
            align-items: baseline;
            column-gap: 8px;

            svg {
              width: 7px;
              height: 14px;
              position: relative;
              top: 1px;
            }
          }
        }
      }
    }
  }
`;

export const ConnectionInfoTooltipContentStyled = styled.div`
  color: ${({ theme }): string => theme.textColors.BLACK_COLOR};

  .folder-structure-tooltip {
    display: flex;
    flex-direction: column;

    .fs-list-item {
      .heading {
        font-family: "OpenSansSemiBold";
        margin-right: 5px;

        span {
          font-family: "OpenSans";
          font-style: italic;
        }
      }
    }
  }

  .bold {
    font-family: OpenSansBold;
  }

  ol {
    margin-left: 15px;
    margin-bottom: 0px;
    padding-left: 0px;
  }

  ul {
    list-style-type: none;

    li {
      &:before {
        background-color: ${({ theme }): string => theme.bgColors.GREY_201};
        border-radius: 50%;
        content: "";
        position: absolute;
        left: -16px;
        top: 6px;
        height: 7px;
        width: 7px;
      }
    }
  }

  ul,
  ol {
    li {
      position: relative;

      .filter-desc {
        color: ${({ theme }): string => theme.textColors.GREY_26};
      }

      .filter-syntax {
        margin-left: 1px;
      }
    }
  }
`;

export const SchemasNamesStyled = styled.div`
  .schemas-count {
    font-family: "OpenSansSemiBold";
    font-size: 13px;
  }

  .schemas-bullets {
    div {
      width: 6.4px;
      height: 25px;
      border-left: 1px solid
        ${({ theme }): string => theme.borderColors.LIGHT_GREY_61};
      border-bottom: 1px solid
        ${({ theme }): string => theme.borderColors.LIGHT_GREY_61};

      &:first-child {
        height: 14px;
      }
    }
  }

  .schemas-list {
    font-size: 13px;
    position: relative;
    top: 7px;

    div {
      height: 25px;
    }
  }
`;
