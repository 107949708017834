import styled from "styled-components";

export const FormItemLabelStyled = styled.div<{
  width: string;
  isColumnLayout: boolean;
}>`
  /* width: ${({ width }): string => width || "100%"}; */

  .ant-form-item-label {
    padding: 0;
    padding-bottom: 3px;
  }

  .ant-form-item-control-input-content {
    ${({ isColumnLayout }): string =>
      isColumnLayout ? "flex-direction: column" : ""};
  }
`;
