import { DataNode } from "antd/lib/tree";
import React from "react";

import {
  DataNodeWithCustomProps,
  LevelOfAccessOptionType,
  OnSelectInfoType,
} from "./treetransfer.types";

export const getUpdatedTreeData = (
  list: DataNode[],
  key: React.Key,
  children: DataNode[]
): DataNode[] =>
  list?.map((node) => {
    if (node?.key === key) {
      return {
        ...node,
        children,
      };
    }
    if (node?.children) {
      return {
        ...node,
        children: getUpdatedTreeData(node?.children, key, children),
      };
    }
    return node;
  });

export const getNodeByKey = (
  key: React.Key,
  tree: DataNode[]
): DataNode | undefined => {
  let node: DataNode | undefined;

  for (let i = 0; i < tree?.length; i++) {
    const item = tree[i];

    if (item?.key === key) {
      node = item;
      break;
    } else if (item?.children) {
      node = getNodeByKey(key, item?.children);

      if (node) {
        break;
      }
    }
  }

  return node;
};

export const getAllTreeKeys = (data: DataNode[]): React.Key[] => {
  const traverseNode = (node: DataNode): React.Key[] => {
    const { key, children } = node;
    const keys: React.Key[] = [key];

    if (children) {
      children?.forEach((child) => {
        keys.push(...traverseNode(child));
      });
    }

    return keys;
  };

  const allKeys: React.Key[] = [];

  data?.forEach((node) => {
    allKeys.push(...traverseNode(node));
  });

  return allKeys;
};

export const getParentKey = (key: React.Key, tree: DataNode[]): React.Key => {
  let parentKey: React.Key;
  for (let i = 0; i < tree?.length; i++) {
    const node = tree?.[i];
    if (node?.children) {
      if (node?.children?.some((item) => item?.key === key)) {
        parentKey = node?.key;
      } else if (getParentKey(key, node?.children)) {
        parentKey = getParentKey(key, node?.children);
      }
    }
  }
  return parentKey!;
};

export const getAllParentKeys = (
  key: React.Key,
  tree: DataNode[]
): React.Key[] => {
  const parentKeysList: React.Key[] = [];
  let currentKey: React.Key = key;

  while (getParentKey(currentKey, tree)) {
    const currentParentKey = getParentKey(currentKey, tree);
    currentKey = currentParentKey;
    parentKeysList.push(currentParentKey);
  }

  return parentKeysList!;
};

export const getChildrenKeys = (nodes: DataNode[]): React.Key[] => {
  const childrenKeys: React.Key[] = [];

  nodes?.forEach((node) => {
    childrenKeys.push(node?.key);

    if (node?.children) {
      const nodeChildren = getChildrenKeys(node?.children);
      childrenKeys?.push(...nodeChildren);
    }
  });

  return childrenKeys || [];
};

export const getChildren = (nodes: DataNode[]): DataNode[] => {
  const children: DataNode[] = [];

  nodes?.forEach((node) => {
    children.push(node);

    if (node?.children) {
      const nodeChildren = getChildren(node?.children);
      children?.push(...nodeChildren);
    }
  });

  return children || [];
};

export const anySiblingSelected = (
  key: React.Key,
  selectedKeys: React.Key[],
  tree: DataNode[]
): boolean => {
  const topLevelKeys: React.Key[] = tree?.map((node) => node?.key);
  const isNodeTopLevel = topLevelKeys?.some((currentKey) => key === currentKey);

  const parentKey = getParentKey(key, tree) || "";
  const parentNode = getNodeByKey(parentKey, tree);

  const keysList = isNodeTopLevel
    ? topLevelKeys
    : parentNode?.children?.map((child) => child?.key) || [];

  const siblingKeys = keysList?.filter((currentKey) => currentKey !== key);

  for (let i = 0; i < siblingKeys?.length; i++) {
    const siblingKey = siblingKeys?.[i];

    if (selectedKeys?.some((selectedKey) => selectedKey === siblingKey)) {
      return true;
    }
  }

  return false;
};

export const getSelectableKeys = (
  info: OnSelectInfoType,
  treeData: DataNode[],
  selectedKeys: React.Key[]
): React.Key[] => {
  const allSelectedKeysParentsList: React.Key[] = [];
  const selectedNodeChildrenList: React.Key[] = [];

  selectedKeys.forEach((key) => {
    const parentKeys = getAllParentKeys(key, treeData!);
    allSelectedKeysParentsList?.push(...parentKeys);
  });

  if (info?.node?.children) {
    const childrenKeysList = getChildrenKeys(info?.node?.children);
    selectedNodeChildrenList?.push(...childrenKeysList);
  }

  const selectedKeysWithParentKeys = [
    ...new Set([
      ...selectedKeys,
      ...allSelectedKeysParentsList,
      ...selectedNodeChildrenList,
    ]),
  ];

  return selectedKeysWithParentKeys;
};

export const getUnselectableKeys = (
  info: OnSelectInfoType,
  treeData: DataNode[],
  selectedKeys: React.Key[]
): React.Key[] => {
  const selectedNodeChildrenList: React.Key[] = [];
  const selectedNodeParentsList: React.Key[] = [];

  const parentKeysList = getAllParentKeys(info?.node?.key, treeData);
  const keysToCheckForSelectedSiblings = [info?.node?.key, ...parentKeysList];

  for (let i = 0; i < keysToCheckForSelectedSiblings?.length; i++) {
    const item = keysToCheckForSelectedSiblings?.[i];

    const isAnySiblingSelected = anySiblingSelected(
      item,
      selectedKeys,
      treeData
    );

    if (!isAnySiblingSelected) {
      selectedNodeParentsList?.push(getParentKey(item, treeData));
    } else {
      break;
    }
  }

  if (info?.node?.children) {
    const childrenKeysList = getChildrenKeys(info?.node?.children);
    selectedNodeChildrenList?.push(...childrenKeysList);
  }

  return selectedKeys?.filter(
    (key) =>
      key !== info?.node?.key &&
      !selectedNodeChildrenList?.some((childKey) => key === childKey) &&
      !selectedNodeParentsList?.some((childKey) => key === childKey)
  );
};

export const constructTreeWithKeys = (
  keys: React.Key[],
  data: DataNode[],
  selectedSourceKeys?: React.Key[],
  levelOfAccess?: string
): DataNode[] => {
  const traverseNode = (node: DataNode): DataNode | null => {
    if (!keys?.includes(node?.key)) {
      return null;
    }

    const filteredChildren: DataNode[] = node?.children
      ? node?.children
          ?.map(traverseNode)
          ?.filter((child): child is DataNode => child !== null)
      : [];

    return {
      ...node,
      levelOfAccess: selectedSourceKeys?.includes(node?.key)
        ? levelOfAccess
        : (node as DataNodeWithCustomProps)?.levelOfAccess,
      children: filteredChildren?.length > 0 ? filteredChildren : [],
    } as DataNode;
  };

  return data
    ?.map(traverseNode)
    ?.filter((node): node is DataNode => node !== null);
};

export const constructTreeAfterTransfer = (
  destinationKeys: React.Key[],
  data: DataNode[]
): DataNode[] => {
  const traverseNode = (node: DataNode): DataNode | null => {
    const { key, children } = node;

    if (!destinationKeys?.includes(key)) {
      return { ...node };
    }

    if (!children) {
      return null;
    }

    const filteredChildren = children
      .map(traverseNode)
      .filter((child) => child !== null) as DataNode[];

    const allChildrenKeysInArray = getChildrenKeys(
      filteredChildren
    ).every((childKey) => destinationKeys.includes(childKey));

    if (allChildrenKeysInArray) {
      return null;
    }

    return {
      ...node,
      children: filteredChildren.length > 0 ? filteredChildren : undefined,
    };
  };

  const filteredTree = data
    ?.map(traverseNode)
    ?.filter((node): node is DataNode => node !== null);

  return filteredTree;
};

export const filterTreeWithSearchText = (
  searchText: string,
  treeData: DataNode[],
  expandedKeys: React.Key[]
): { data: DataNode[]; keysToExpand: React.Key[] } => {
  const selectedKeys: React.Key[] = [];
  const keysToExpand: React.Key[] = [];

  const traverseNode = (node: DataNode): void => {
    const { title, children } = node;

    if (
      ((title as React.ReactElement)?.props?.title || title)
        ?.toString()
        ?.toLocaleLowerCase()
        ?.includes(searchText?.toLocaleLowerCase())
    ) {
      const parentKeys = getAllParentKeys(node?.key, treeData);
      const childrenKeys = node?.children
        ? getChildrenKeys(node?.children)
        : [];

      keysToExpand?.push(...parentKeys);
      selectedKeys?.push(node?.key, ...parentKeys, ...childrenKeys);
    }

    if (children) {
      children?.forEach(traverseNode);
    }
  };

  if (searchText) {
    treeData.forEach(traverseNode);

    const searchTree = constructTreeWithKeys(
      [...new Set(selectedKeys)],
      treeData
    );

    return {
      data: searchTree,
      keysToExpand: [...new Set([...keysToExpand, ...expandedKeys])],
    };
  }

  return { data: treeData, keysToExpand: expandedKeys };
};

export const getLevelofAccess = (
  selectedLevelOfAccess: string,
  options: Array<LevelOfAccessOptionType>
): string => {
  const selectedOption = options?.find(
    (option) => option?.value === selectedLevelOfAccess
  );

  return selectedOption?.label ? `(${selectedOption?.label})` : "";
};
