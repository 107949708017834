import styled from "styled-components";

export const AgGridPaginationStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  background: #eff3f8;
  color: ${({ theme }): string => theme.textColors.GREY_90};
  border: 1px solid ${({ theme }): string => theme.borderColors.BLUE_10};
  height: 42px;
  font-size: 13px;
  font-family: OpenSans;

  > * {
    margin: 0 18px;
  }

  .ag-paging-row-summary-panel {
    display: flex;
    column-gap: 5px;

    .ag-paging-row-summary-panel-number {
      font-weight: normal;
    }
  }

  .ag-paging-page-summary-panel {
    flex: 1;
    display: flex;
    align-items: center;
    column-gap: 10px;
    justify-content: center;

    .paging-divider {
      height: 18px;
      border-left: 2px solid
        ${({ theme }): string => theme.borderColors.GREY_16};
    }

    .ag-paging-button {
      border: 1px solid
        ${({ theme }): string => theme.borderColors.LIGHT_GREY_13};
      border-radius: 50%;
      margin: 0px;

      padding-top: 1px;
      padding-bottom: 2px;

      &.first-btn,
      &.prev-btn,
      &.last-btn,
      &.next-btn {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      svg path {
        fill: ${({ theme }): string => theme.textColors.DARK_GREY_1};
      }

      &.ag-disabled {
        pointer-events: none;
        svg path {
          fill: ${({ theme }): string => theme.textColors.GREY_34};
        }
      }
    }

    .ag-paging-description {
      display: flex;
      align-items: center;
      column-gap: 5px;
      margin: 0px;
    }

    .ag-paging-number {
      color: ${({ theme }): string => theme.textColors.DARK_GREY_100};

      input {
        width: 42px;
        text-align: center;
        padding: 4px 2px;
      }

      input,
      .ant-select-selector {
        height: 25px;
        font-size: 13px;
        border-radius: 0;
        border: 1px solid
          ${({ theme }): string => theme.borderColors.LIGHT_GREY_40};
      }

      .ant-select-selector {
        overflow: hidden;
      }

      .ant-select {
        width: 74px;

        .ant-select-arrow {
          color: ${({ theme }): string => theme.textColors.DARK_GREY_100};
        }
      }

      .ant-select-item,
      .ant-select-selection-item {
        font-weight: normal;
        color: ${({ theme }): string => theme.textColors.DARK_GREY_100};
        font-size: 13px;
        font-family: OpenSans;
      }

      .ant-select-item {
        min-height: unset;
        padding: 0px 18px;
      }
    }
  }
`;
