import { useDebounce } from "usehooks-ts";
import { createContext, useCallback, useContext, useState } from "react";

import { SearcFieldContextType } from "./searchfieldcontext.types";

const SearchFieldContext = createContext<SearcFieldContextType>({
  onChangeSearchText: () => {},
  onClearSearchText: () => {},
  searchText: "",
  debouncedSearchText: "",
});

export const useSearchFieldContext = (): SearcFieldContextType => {
  return useContext(SearchFieldContext);
};

export const SearchFieldContextProvider = (props: any): JSX.Element => {
  const { children, defaultSearchText = "" } = props;

  const [searchText, setSearchText] = useState(defaultSearchText);

  const debouncedSearchText = useDebounce(searchText, 500);

  const onChangeSearchText = useCallback((text: string) => {
    setSearchText(text);
  }, []);

  const onClearSearchText = useCallback(() => {
    setSearchText("");
  }, []);

  return (
    <SearchFieldContext.Provider
      value={{
        onChangeSearchText,
        onClearSearchText,
        searchText,
        debouncedSearchText,
      }}
    >
      {children}
    </SearchFieldContext.Provider>
  );
};
