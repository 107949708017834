import styled from "styled-components";

export const ProductTourFormStyled = styled.div`
  .ant-card {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    height: 388px;
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-card-body {
      width: 470px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0px;
      .product-tour {
        color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
        font-size: 20px;
        font-family: OpenSansBold;
        margin-top: 25px;
      }

      .product-tour-desc {
        color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
        text-align: center;
        margin-bottom: 44px;
      }

      .product-tour-act {
        display: flex;
        .ant-btn {
          font-family: OpenSansSemiBold;
        }

        .take-tour-btn {
          padding-top: 5px !important;
        }
      }
    }
  }
`;
