// <--Library Imports Start-->
import { useCallback, useEffect, useMemo, useState } from "react";
import { Collapse, Spin, Tooltip } from "antd";
// <--Library Imports End-->

// <--Components Start-->
import LinkButtonToOpenRefView from "../../../../../../../../components/linkbuttontoopenrefview/linkbuttontoopenrefview";
import CoreNodesCircleIcon from "../../../../../../../../components/corenodetypescircleicon/corenodetypescircleicon";

import BoldText from "../../../../../../../../components/boldtext/boldtext";
import LinkButton from "../../../../../../../../components/linkbutton";

import { VerticalDividerStyled } from "../../../../../../../../components/dividers/dividers.styles";
// <--Components End-->

// <--Service Start-->
import { useRequestWithMethod } from "../../../../../../../../api";
import { useGetFieldsThatPartOfPrompt } from "../../../../../../../../api/analysisservice";
// <--Service End-->

// <--Styles Start-->
import { ManageFieldsViewStyled } from "./managefieldsview.styles";
import { CheckboxStyled } from "../../../../../../../../components/checkbox/checkbox.styles";
// <--Styles End-->

// <--Utils Start-->
import {
  getDataTypeIcon,
  isEqual,
  jsonStringify,
} from "../../../../../../../../utils";
// <--Utils End-->

// <--Types Start-->
import { ManageFieldsViewProps } from "./managefieldsview.types";
import { DVSUM_TOOLTIP_CLASS_NAME } from "../../../../../../../../constants";
import StateHandler from "../../../../../../../../components/statehandler";
import { useDebounce, useSetData } from "../../../../../../../../customhooks";
import { API_CONFIG } from "../../../../../../../../constants/apiconfig";
import ProfilingCountProgressbar from "../../../../../../../../components/profilingcountprogressbar/profilingcountprogressbar";
import { ProgressBarItem } from "../../../../../../../../components";
import { Input } from "../../../../../../../../components/inputs";
import { lightCrossIcon, searchIcon } from "../../../../../../../../svgs";
// <--Types End-->

const ManageFieldsView = (props: ManageFieldsViewProps): JSX.Element => {
  const {
    onClickManageFields,
    analysisId = "",
    pinnedTableIds = "",
    onManageFieldsChange,
    hasManageFieldsChanges,
  } = props;

  const { isLoading, parsedData = [], error } = useGetFieldsThatPartOfPrompt(
    analysisId,
    pinnedTableIds
  );

  const [savedRes, setSavedRes] = useState<typeof parsedData>([]);
  const [activeKey, setActiveKey] = useState<string | string[]>(
    "manage-fields-tables-0"
  );

  const [searchText, setSearchText] = useState("");

  const searchDebounce = useDebounce(searchText, 500);

  const debouncedSearchText = searchDebounce && searchText?.toLowerCase();

  const isSearchTextEmpty = debouncedSearchText === "";

  const onSetData = useSetData();

  const onSaveSucess = useCallback(
    (res) => {
      onSetData(API_CONFIG.get_pinned_tbls_manage_fields, res?.data, [
        analysisId,
        pinnedTableIds,
      ]);
      onClickManageFields?.();
    },
    [analysisId, pinnedTableIds]
  );

  const {
    isLoading: savingPromptIsLoading = false,
    onExecuteRequest: updatePrompt,
  } = useRequestWithMethod("update_prompt", undefined, true, onSaveSucess);

  const onChangeSelection = useCallback(
    (colId, tblId) => {
      const updatedState = savedRes?.map((item) => ({
        ...item,
        columns: item?.columns?.map((col) => ({
          ...col,
          is_selected:
            item?.table_id === tblId && col?.col_id === colId
              ? !col?.is_selected
              : col?.is_selected,
        })),
      }));

      const isSavedAndApiDataChanged = !isEqual(updatedState, parsedData);

      if (
        (isSavedAndApiDataChanged && !hasManageFieldsChanges) ||
        (!isSavedAndApiDataChanged && hasManageFieldsChanges)
      )
        onManageFieldsChange?.();

      setSavedRes(updatedState);
    },
    [savedRes]
  );

  const isAnythingChanged = useMemo(() => {
    return !isEqual(savedRes, parsedData);
  }, [savedRes, parsedData]);

  const isSaveEnable = useMemo(() => {
    return savedRes?.every((item) =>
      item?.columns?.some((col) => col?.is_selected)
    );
  }, [savedRes]);

  const onSaveAndReset = useCallback(
    (isCmgFrmSave: boolean) => {
      updatePrompt(
        {
          pin_tables: jsonStringify(
            savedRes?.map((table) => ({
              pin_tbl_id: table?.table_id,
              pin_tbl_name: table?.table_name,
              pin_tbl_type: "TBL",
              cols_cnt: table?.cols_to_select,
              cols: isCmgFrmSave
                ? table?.columns?.map((col) => ({
                    col_id: col?.col_id,
                    col_name: col?.col_name,
                    is_selected: col?.is_selected,
                    is_default: col?.is_default,
                    is_key_field: col?.is_key_col,
                  }))
                : [],
            }))
          ),
        },
        [analysisId, isCmgFrmSave ? "false" : "true", pinnedTableIds]
      );
      hasManageFieldsChanges && onManageFieldsChange?.();
    },
    [analysisId, savedRes, pinnedTableIds]
  );

  useEffect(() => {
    if (!isLoading) {
      const sortedData = pinnedTableIds
        ?.split(",")
        ?.map((item) =>
          parsedData?.filter((parsedItem) => parsedItem?.table_id === item)
        )
        ?.flat();
      setSavedRes(sortedData);
    }
  }, [isLoading]);

  const filteredTables = useMemo(() => {
    !activeKey && setActiveKey("manage-fields-tables-0");
    return isSearchTextEmpty
      ? savedRes
      : savedRes
          ?.filter((table) => {
            const isColumnMatch = table?.columns?.find((column) =>
              column?.col_key
                ?.toLowerCase()
                ?.includes(debouncedSearchText?.toLowerCase())
            );

            return isColumnMatch;
          })
          ?.map((table) => {
            const matchedColumns = table?.columns?.filter((column) =>
              column?.col_key
                ?.toLowerCase()
                ?.includes(debouncedSearchText?.toLowerCase())
            );

            return { ...table, columns: matchedColumns };
          });
  }, [debouncedSearchText, isSearchTextEmpty, savedRes]);

  const selectAll = useCallback((tableId: string, isChecked: boolean) => {
    setSavedRes((oldState) =>
      oldState?.map((table) => ({
        ...table,
        ...(tableId === table?.table_id && {
          columns: table?.columns?.map((col) => ({
            ...col,
            is_selected: isChecked,
          })),
        }),
      }))
    );
  }, []);

  const getManageFieldColName = (
    colDisplayName: string,
    colName: string
  ): string => {
    const trimmedColName = colName?.replaceAll("_", " ");

    if (
      colDisplayName?.toLocaleLowerCase() !==
      trimmedColName?.toLocaleLowerCase()
    ) {
      return `(${colName})`;
    }

    return "";
  };

  const onSearchTextChange = useCallback((e) => {
    setSearchText(e?.target?.value || "");
  }, []);

  return (
    <StateHandler isFetching={false} error={error} isModal>
      <ManageFieldsViewStyled>
        <div className="collapse-header-content-manage-fields">
          <div className="manage-fields-sec-title">Select Fields</div>
          <div className="manage-fields-right-sec">
            <LinkButton
              onClick={(): void => onSaveAndReset(false)}
              disabled={isLoading || savingPromptIsLoading}
            >
              Auto Select
            </LinkButton>
            <VerticalDividerStyled height="8px" />
            <LinkButton
              onClick={(): void => {
                hasManageFieldsChanges && onManageFieldsChange?.();
                onClickManageFields?.();
              }}
              disabled={isLoading || savingPromptIsLoading}
            >
              Cancel
            </LinkButton>
            <LinkButton
              disabled={
                !isAnythingChanged ||
                isLoading ||
                savingPromptIsLoading ||
                !isSaveEnable
              }
              tooltipProps={{
                title: !isAnythingChanged
                  ? "You haven't change any thing to save."
                  : !isSaveEnable
                  ? "Please select atleast one field from each table to save."
                  : "",
              }}
              onClick={(): void => onSaveAndReset(true)}
            >
              Save
            </LinkButton>
          </div>
        </div>
        <div className="manage-input-search">
          <Input
            className="table-browser-search"
            prefix={searchIcon("12.48px")}
            suffix={
              searchText ? (
                <LinkButton onClick={onSearchTextChange}>
                  {lightCrossIcon("9.6px", "9.6px")}
                </LinkButton>
              ) : (
                <div />
              )
            }
            onChange={onSearchTextChange}
            value={searchText}
            placeholder="Search"
            data-testid="analysis-detail-page-explorer-sec-table-browser-sec-search-field"
          />
        </div>

        <Spin spinning={!analysisId || isLoading || savingPromptIsLoading}>
          <Collapse
            className="manage-fileds-collapse"
            defaultActiveKey="manage-fields-tables-0"
            activeKey={activeKey}
            onChange={(key): void => setActiveKey(key)}
          >
            {filteredTables?.map((tbl, index) => {
              return (
                <Collapse.Panel
                  key={`manage-fields-tables-${index}`}
                  className="collapse-panel"
                  header={
                    <>
                      <CoreNodesCircleIcon
                        nodeType="TBL"
                        width="16px"
                        height="16px"
                        fontSize="9px"
                      />
                      <BoldText
                        fontSize="14px"
                        isSemiBold
                        type="secondary"
                        text={tbl?.table_title}
                      />
                    </>
                  }
                >
                  <div className="table-columns-list">
                    <CheckboxStyled
                      onChange={(e): void =>
                        selectAll(tbl?.table_id, e?.target?.checked)
                      }
                      indeterminate={
                        tbl?.columns?.some((col) => col?.is_selected) &&
                        !tbl?.columns?.every((col) => col?.is_selected)
                      }
                      checked={tbl?.columns?.some((col) => col?.is_selected)}
                    >
                      Select All
                    </CheckboxStyled>
                  </div>
                  {tbl?.columns?.map((col) => (
                    <div
                      className="table-columns-list"
                      key={`${tbl?.table_id}-${col?.col_id}-manage-fields-field`}
                    >
                      <Tooltip
                        overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
                        title={
                          col?.is_chat_disabled
                            ? "This Column is excluded from chat. Check Module Settings or contact your administrator."
                            : col?.is_privacy_applied
                            ? "You can't change it, Field contains privacy info."
                            : col?.is_key_col
                            ? "You can't change predefined field"
                            : ""
                        }
                        placement="topLeft"
                      >
                        <CheckboxStyled
                          checked={col?.is_selected}
                          onChange={(): void =>
                            onChangeSelection(col?.col_id, tbl?.table_id)
                          }
                          disabled={
                            col?.is_key_col ||
                            col?.is_privacy_applied ||
                            col?.is_chat_disabled
                          }
                        />
                      </Tooltip>
                      <LinkButtonToOpenRefView
                        nodeId={`${col?.col_id}`}
                        nodeType="COL"
                        className=""
                      >
                        {getDataTypeIcon(col?.data_type_id)}
                        <span
                          className="col-name-container"
                          title={`${
                            col?.col_display_name
                          } ${getManageFieldColName(
                            col?.col_display_name,
                            col?.col_name
                          )}`}
                        >
                          <div className="name">
                            <div
                              className="col-display-name"
                              title={col?.col_display_name}
                            >
                              {col?.col_display_name}
                            </div>
                            <span className="col-name">
                              &nbsp;
                              {getManageFieldColName(
                                col?.col_display_name,
                                col?.col_name
                              )}
                            </span>
                          </div>
                          <div className="col-ref-profiling-renderer">
                            <ProfilingCountProgressbar
                              data={col?.profilingInfo as ProgressBarItem[]}
                              width="100%"
                              minThreshhold={10}
                            />
                          </div>
                        </span>
                      </LinkButtonToOpenRefView>
                    </div>
                  ))}
                </Collapse.Panel>
              );
            })}
          </Collapse>
        </Spin>
      </ManageFieldsViewStyled>
    </StateHandler>
  );
};

export default ManageFieldsView;
