import { databaseTableIcon } from "../../svgs";
import Flex from "../flex";

import {
  RulePriorityIconDivStyled,
  SectionHeaderStyled,
} from "./sectionheader.styles";
import { SectionHeaderProps } from "./sectionheader.types";

function SectionHeader(props: SectionHeaderProps): JSX.Element {
  const { title, subTitle, icon, rightIcon, nodePriority } = props;

  const isNormalPriority = nodePriority === "NRM";

  return (
    <SectionHeaderStyled {...props} className="section-header" title="">
      {icon || <Flex>{databaseTableIcon("23px", "20px")}</Flex>}
      {nodePriority && !isNormalPriority && (
        <RulePriorityIconDivStyled priority={nodePriority} />
      )}
      <div className="info">
        <div className="info-title" title={title}>
          {title}
        </div>
        <div className="info-subtitle">{subTitle}</div>
      </div>
      {rightIcon && rightIcon}
    </SectionHeaderStyled>
  );
}

export default SectionHeader;
