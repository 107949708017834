import { Collapse } from "antd";
import Flex from "../../../../components/flex";
import LinkButton from "../../../../components/linkbutton";
import SectionBlankSlateText from "../../../../components/sectionblankslatetext";
import StateHandler from "../../../../components/statehandler";
import { redirectIcon } from "../../../../svgs";
import DvsumHelpItemCard from "../dvsumhelpitemcard";
import { ResultsSecStyled } from "./resultssec.styles";
import { ResultsSecProps } from "./resultssec.types";

const ResultsSec = (props: ResultsSecProps): JSX.Element => {
  const {
    isFetching = false,
    error,
    data,
    onResultItemClick,
    isSearching,
  } = props;
  const {
    articles = [],
    quick_tips: quickTips = [],
    videos = [],
    tutorial = [],
  } = data || {};

  const defaultKey = quickTips?.length
    ? "Quick Tips"
    : tutorial?.length
    ? "Tutorials"
    : videos?.length
    ? "Videos"
    : articles?.length
    ? "Support Articles"
    : undefined;

  return (
    <ResultsSecStyled>
      <StateHandler isFetching={isFetching} error={error} isModal>
        <SectionBlankSlateText
          isEmpty={!isFetching && !defaultKey && !isSearching}
          text="No data exists against your searched keyword"
        >
          {defaultKey ? (
            <Collapse
              accordion
              defaultActiveKey={defaultKey}
              expandIconPosition="right"
            >
              {quickTips?.length && (
                <Collapse.Panel
                  header={
                    <div>
                      Quick Tips
                      <span className="count-bubble">{quickTips?.length}</span>
                    </div>
                  }
                  key="Quick Tips"
                >
                  <Flex
                    columnGap={15}
                    flexWrap="wrap"
                    rowGap={20}
                    alignItems="flex-start"
                  >
                    {quickTips?.map((item) => (
                      <DvsumHelpItemCard
                        type="gif"
                        item={item}
                        onClick={onResultItemClick}
                        key={`${item?.id}-quick-tips`}
                      />
                    ))}
                  </Flex>
                </Collapse.Panel>
              )}

              {tutorial?.length && (
                <Collapse.Panel
                  header={
                    <div>
                      Tutorials
                      <span className="count-bubble">{tutorial?.length}</span>
                    </div>
                  }
                  key="Tutorials"
                >
                  <Flex
                    columnGap={15}
                    flexWrap="wrap"
                    rowGap={20}
                    alignItems="flex-start"
                  >
                    {tutorial?.map((item) => (
                      <DvsumHelpItemCard
                        type="tutorial"
                        item={item}
                        onClick={onResultItemClick}
                        key={`${item?.id}-tutorials`}
                      />
                    ))}
                  </Flex>
                </Collapse.Panel>
              )}

              {videos?.length && (
                <Collapse.Panel
                  header={
                    <div>
                      Videos
                      <span className="count-bubble">{videos?.length}</span>
                    </div>
                  }
                  key="Videos"
                >
                  <Flex
                    columnGap={15}
                    flexWrap="wrap"
                    rowGap={20}
                    alignItems="flex-start"
                  >
                    {videos?.map((item) => (
                      <DvsumHelpItemCard
                        type="video"
                        item={item}
                        onClick={onResultItemClick}
                        key={`${item?.id}-videos`}
                      />
                    ))}
                  </Flex>
                </Collapse.Panel>
              )}

              {articles?.length && (
                <Collapse.Panel
                  header={
                    <div>
                      Support Articles
                      <span className="count-bubble">{articles?.length}</span>
                    </div>
                  }
                  key="Support Articles"
                >
                  <Flex direction="column" alignItems="flex-start">
                    {articles?.map((item) => (
                      <LinkButton
                        className="article-item"
                        onClick={(): void => {
                          window.open(item?.url);
                        }}
                        title={item?.title}
                        key={`${item?.id}-articles`}
                      >
                        {item?.title}
                        {redirectIcon("10", "10")}
                      </LinkButton>
                    ))}
                  </Flex>
                </Collapse.Panel>
              )}
            </Collapse>
          ) : (
            <div />
          )}
        </SectionBlankSlateText>
      </StateHandler>
    </ResultsSecStyled>
  );
};

export default ResultsSec;
