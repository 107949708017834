import styled from "styled-components";

export const AddUserRoleFormStyled = styled.div<{ maxHeight?: string }>`
  .ant-form {
    .form-wrapper {
      margin: 42.4px;
      margin-top: 25.6px;

      .tabs-panel {
        height: calc(100vh - 185px);
        overflow: auto;
        padding-left: 116px;
        padding-top: 25.6px;

        p.desc {
          max-width: 601px;
        }
      }
    }

    .ant-form-item {
      .ant-form-item-control {
        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            .ant-radio-wrapper {
              width: 100%;
            }
            .input-field-container > input,
            .textarea-field-container > textarea {
              width: 512px;
            }

            .ant-radio-group {
              .ant-radio {
                padding-left: 0;
              }
            }

            .permission-selector {
              width: 490px;
            }
          }
        }
      }
    }

    .form-item-with-inline-info {
      .ant-form-item-control-input-content {
        display: flex;
        align-items: center;
        column-gap: 8px;
      }
    }

    .alert-user-notify {
      svg {
        margin-bottom: 2px;
        margin-right: 16px;

        path {
          fill: ${({ theme }): string => theme.textColors.DARK_BLUE};
        }
      }
    }

    .form-actions-sec {
      position: absolute;
      width: 100vw;
      bottom: 0px;
      margin-bottom: 0px;
    }
  }
`;

export const AddRoleFormViewStyled = styled.div`
  display: flex;
  flex-direction: column;

  .dvsum-tooltip,
  .desc {
    padding-left: 24px;
  }

  .dvsum-tooltip {
    padding-left: 0;

    .ant-checkbox-wrapper {
      justify-content: start;

      .label {
        font-size: 14px;
      }
    }
  }

  .criteria-field {
    .specify-criteria-fields {
      .remove-criteria-btn {
        display: flex;
        align-items: center;
        position: unset;
        right: 0px;
        top: 7px;
        margin-left: 21.6px;
      }
    }
  }
  .required-star {
    color: red;
    margin-left: 4px;
  }
`;
