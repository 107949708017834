import { useMemo } from "react";
import "react-quill/dist/quill.snow.css";

import { closeIconInCircle, editPencilIcon } from "../../svgs";
import { parseStringToHtml } from "../../utils";

import FilePreviewer from "../filepreviewer";
// import UrlPreviewer from "../urlpreviewer";

import { AddedItemStyled, TextPreviewerStyled } from "./additionalinfo.styles";
import { AdditionalInfoState } from "./additionalinfo.types";

import LinkButton from "../linkbutton";

function AddedItem({
  item,
  onRemove,
  onEdit,
  isEditable,
  isInEditMode,
}: {
  item: AdditionalInfoState["addedItems"][number];
  onRemove?: (_id: any) => void;
  onEdit?: (_id: string) => void;
  isEditable: boolean;
  isInEditMode?: boolean;
}): JSX.Element {
  const previewElement = useMemo(() => {
    switch (item?.actionId) {
      // case "embed_link":
      //   return (
      //     <div>
      //       {!isEditable && (
      //         <div className="additional-info-added-item-title">Link</div>
      //       )}
      //       <UrlPreviewer
      //         url={item.value}
      //         isAdded
      //         onRemove={onRemove}
      //         id={item.id}
      //       />
      //     </div>
      //   );
      case "edit":
        return (
          <div className="text-preview-wrapper">
            {/* {!isEditable && (
              <div className="additional-info-added-item-title">Text</div>
            )} */}
            <TextPreviewerStyled>
              <div className="text-preview-container">
                <div className="ql-editor">
                  {parseStringToHtml(item?.value)}
                </div>

                <div className="action-sec">
                  {onEdit && (
                    <LinkButton
                      tooltipProps={{
                        title: isInEditMode
                          ? "Item already in edit mode."
                          : "Edit Item",
                      }}
                      disabled={isInEditMode}
                      onClick={(): void => onEdit(item?.id)}
                    >
                      {editPencilIcon("12px", "12px")}
                    </LinkButton>
                  )}

                  {onRemove && (
                    <LinkButton
                      tooltipProps={{
                        title: isInEditMode
                          ? "You cannot remove the item in edit mode."
                          : "Remove Item",
                      }}
                      className="img-cross-icon"
                      disabled={isInEditMode}
                      onClick={(): void => {
                        onRemove(item?.id);
                      }}
                    >
                      {closeIconInCircle()}
                    </LinkButton>
                  )}
                </div>
              </div>
            </TextPreviewerStyled>
          </div>
        );
      default:
        return (
          <div>
            {!isEditable && (
              <div className="additional-info-added-item-title">Image</div>
            )}
            <FilePreviewer
              isAdded
              file={item.value}
              id={item.id}
              onRemove={onRemove}
            />
          </div>
        );
    }
  }, [item, isEditable, isInEditMode]);
  return <AddedItemStyled>{previewElement}</AddedItemStyled>;
}

export default AddedItem;
