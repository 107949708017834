import styled from "styled-components";

export const AddFilterCriteriaFormStyled = styled.div`
  .ant-form-item-control {
    width: 95%;
  }

  .input-field-container {
    width: max-content;
  }
`;

export const FilterCriteriaFormStyled = styled.div`
  padding: 25px;
`;
