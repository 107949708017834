import styled from "styled-components";

export const StyledInputField = styled.div<{
  isFocus: boolean;
  hasValue: boolean;
}>`
  position: relative;

  .input-field-container > .ant-input,
  .ant-input-password {
    height: 63.2px;
  }

  .ant-input,
  .ant-input-password > .ant-input {
    color: ${({ theme }): string => theme.textColors.DARK_GREY_106};
    border: 1px solid ${({ theme }): string => theme.borderColors.LIGHT_GREY_55};
    padding: 33.6px 18.4px 10.4px;
    font-size: 13px;
    outline: none;
  }

  .ant-input-password > .ant-input {
    height: 13px;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 6px;
    border: none;
  }

  .ant-input-password {
    border: 1px solid
      ${({ isFocus, theme }): string =>
        isFocus
          ? theme.borderColors.BLUE_56
          : theme.borderColors.LIGHT_GREY_55} !important;

    .ant-input-suffix {
      svg {
        width: 24px;
        height: 16.6px;
      }
    }
  }

  .input-field-container > .ant-input:not(:disabled):focus {
    border: 1px solid ${({ theme }): string => theme.borderColors.BLUE_56};
    color: ${({ theme }): string => theme.textColors.DARK_GREY_106};
  }

  .floating-label {
    position: absolute;
    color: ${({ theme }): string => theme.textColors.LIGHT_GREY_11};
    font-size: 14.4px;
    font-family: "OpenSansSemiBold";
    pointer-events: none;
    transition: 0.2s ease all;
    transform: translate(18.4px, 20px);
    left: 0;
    top: 0;
    z-index: 1;

    ${({ isFocus, hasValue, theme }): string =>
      isFocus || hasValue
        ? `
    color: ${isFocus ? theme.textColors.BLUE_56 : theme.textColors.BLACK_COLOR};
    transform: translate(18.4px, 10.4px);
    font-size: 13px;
    `
        : ""}
  }
`;
