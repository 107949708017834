import { AxiosResponse } from "axios";
import {
  ColSeqTypeResponse,
  DictionaryListParsedType,
  DictionaryListResponse,
  DictMappingResponseType,
  GetPatternsParsedDataType,
  GetPatternsResponseType,
  MetricType,
  RecommendedRulesParsedType,
  RecommendedRulesResponse,
  ReturnTypeOfRuleDocParser,
  ReturnTypeOfRuleFilterParser,
  ReturnTypeOfRuleRefDocParser,
  RuleDefConfigType,
  RuleDocResponse,
  RuleExecutionHistoryDataParsedType,
  RuleExecutionHistoryDataResponse,
  RulePatterGroupedData,
  ZendeskArticleRuleParsedType,
  ZendeskArticleRuleResponse,
} from "./ruleparser.types";

import {
  csvToJSON,
  isJsonString,
  jsonParse,
  sortListOnSpecificKeyValue,
} from "../../utils";

import {
  RULE_SECTION_SEQUENCE,
  RULE_SECTION_TITLES,
} from "../../constants/labelconstants/ruleconfig";

import { getClassificationCustomTags } from "../parserutils";
import { API_CONFIG } from "../../constants/apiconfig";

import {
  extractClassificationTagsFromApiData,
  getFiltersAndFilterTextFormApi,
  getWorkflow,
  getWorkflowStats,
} from "./ruleparser.util";

import { useGetAll } from "../../api/baseservice";
import { CustomReactQueryHookReturnType } from "../../api/services.types";

import { TagsData } from "../../components/tagseditor/tagseditor.types";
import { NodeTypeTAGContextResponse } from "../tablepage/tablepageparser.types";

import { commonItemParser } from "../tablepage/tablepageparser";
import { getMetricType } from "../../pages/ruledetailpage/ruledetailpage.views/ruledetailpagecontent/ruledetailpagecontent.tabs/definitiontab/definitiontab.utilis";

import { useGatewayDownPrompt } from "../../customhooks";
import { CustomException } from "../../customexception";

export const DATE_FORMAT = "DD/MM/YYYY hh:mm A";

export const getParsedRecommendRulesData = ({
  data,
}: AxiosResponse<RecommendedRulesResponse[]>): RecommendedRulesParsedType[] => {
  return data?.map((item) => {
    const valueRangeRuleConfig: RuleDefConfigType = isJsonString(
      item?.RULE_DEF_CONFIG || ""
    )
      ? JSON.parse(item?.RULE_DEF_CONFIG)
      : [];

    return {
      rule_id: item?.RULE_ID || 0,
      rule_name: item?.RULE_NAME || "",
      rule_desc: item?.RULE_DESC || "",
      id: item?.RULE_ID || 0,
      rule_type_id: item?.RULE_TYPE_ID || "",
      value_range_rule_config: valueRangeRuleConfig || {},
    };
  });
};

export const getParsedColSeq = ({
  data,
}: AxiosResponse<ColSeqTypeResponse>): {
  columns: string[];
  seqType: string;
  customColumns: string[];
} => {
  const customColumns: string[] = data?.custom_columns
    ? data?.custom_columns
        .slice(1, -1)
        .split(", ")
        .map((item) => item.trim())
    : [];

  return {
    columns: data?.columns?.map((col) => String(col?.COL_ID)),
    seqType: data?.seq_type,
    customColumns: customColumns?.map((val) => val?.toLowerCase()),
  };
};

export const getParsedRuleSpecificColSeq = ({
  data,
}: AxiosResponse): string[] => {
  const item = data?.[0];

  const ruleColSeq = isJsonString(item?.RULE_COL_SEQ || "")
    ? JSON.parse(item?.RULE_COL_SEQ)
    : [];
  return ruleColSeq;
};

export const getParsedRefDictionariesList = ({
  data,
}: AxiosResponse<DictionaryListResponse[]>): DictionaryListParsedType[] => {
  return (
    data?.map((item) => {
      const dictMapping: DictMappingResponseType = isJsonString(
        item?.DICT_MAPPING_CONFIG || ""
      )
        ? JSON.parse(item?.DICT_MAPPING_CONFIG)
        : [];

      return {
        dict_id: item?.DICT_ID || 0,
        dict_name: item?.DICT_NAME || "",
        is_hierarchical: item?.IS_HIERARCHICAL || false,
        dict_mapping_config: {
          data_headers: dictMapping?.data_headers || false,
          mapping: dictMapping?.dict_mapping_config || [],
        },
      };
    }) || []
  );
};

export const getrulesListFromZendeskArticles = ({
  data,
}: AxiosResponse<ZendeskArticleRuleResponse>): ZendeskArticleRuleParsedType => {
  return {
    htmlContent: data?.article?.body || "",
  };
};

export function getParsedRuleDocForRefPage({
  data,
}: AxiosResponse<RuleDocResponse>): ReturnTypeOfRuleRefDocParser {
  const isRuleScheduled = !!data?.scheduled_jobs?.value?.length;

  const { rule_def_config } = data || {};
  const { value } = rule_def_config || {};
  const {
    min_value: minValue = "",
    max_value: maxValue = "",
    is_single_value: isSingleValue,
  } = value || {};

  const workflow = data?.wf_info || [];

  const { currentWorkflowStatus, actionPerformedBy } = getWorkflowStats(
    workflow
  );

  const userAddedDesc = data?.overview?.description?.value || "";
  const systemGeneratedDesc = data?.overview?.system_description || "";

  const desc = userAddedDesc
    ? `${userAddedDesc}. ${systemGeneratedDesc}`
    : systemGeneratedDesc;

  return {
    definition: [
      {
        title: RULE_SECTION_TITLES?.DEFINITION?.DATA_SOURCE,
        value: data?.src_name,
      },

      {
        title: RULE_SECTION_TITLES?.DEFINITION?.TABLE_NAME,
        value: data?.tbl_name,
        id: `${data?.tbl_id}`,
      },

      {
        title: RULE_SECTION_TITLES?.DEFINITION?.FIELD_NAME,
        value: data?.cols_info?.map((item) => item?.col_name).join(", "),
      },

      ...((minValue && [
        {
          title: isSingleValue ? "Absolute Value" : "Min Value",
          value: String(minValue),
        },
      ]) ||
        []),

      ...((maxValue && [
        {
          title: "Max Value",
          value: String(maxValue),
        },
      ]) ||
        []),

      {
        title: RULE_SECTION_TITLES?.DEFINITION?.FILTER,
        value: data?.filter_info?.filter_text,
      },
    ],
    status: [
      {
        title: RULE_SECTION_TITLES?.STATUS?.RUN_RESULT,
        value: data?.rule_status_id,
        additionalInfo: {
          exceptionCount: data?.rule_exec_info?.rule_exec_result,
        },
      },
      {
        title: RULE_SECTION_TITLES?.STATUS?.LAST_REFRESHED,
        value: data?.rule_exec_info?.rule_exec_on,
      },
      {
        title: RULE_SECTION_TITLES?.STATUS?.DQ_SCORE,
        value: data?.rule_exec_info?.rule_score
          ? `${data?.rule_exec_info?.rule_score}%`
          : "",
      },
      {
        title: RULE_SECTION_TITLES?.STATUS?.WORKFLOW_STATUS,
        value: currentWorkflowStatus || "",
        additionalInfo: {
          actionPerformedBy,
        },
      },
      {
        title: RULE_SECTION_TITLES?.STATUS?.REMARKS,
        value: data?.rule_exec_info?.rule_remarks,
      },
    ],
    description: [
      {
        title: RULE_SECTION_TITLES?.DESCRIPTION,
        value: desc,
      },
    ],

    tableName: data?.tbl_name,
    sourceId: data?.src_id,
    sourceName: data?.src_name,
    tableId: data?.tbl_id,
    ruleName: data?.node_name,
    isEditable: data?.is_editable,
    ruleAlertStatus: data?.rule_exec_info?.alert_status_id || undefined,
    rulePriority: data?.overview?.rule_priority?.value?.rule_priority_id || "",
    isRuleScheduled,
  };
}

export function getParsedRuleFilters({
  data,
}: AxiosResponse<any>): ReturnTypeOfRuleFilterParser {
  const item = data?.[0];

  const { parsedFilters, filterText } = getFiltersAndFilterTextFormApi(item);

  return { filterCondition: parsedFilters, filterText };
}

export function getParsedRuleDocForDetailPage({
  data,
}: AxiosResponse<RuleDocResponse>): ReturnTypeOfRuleDocParser {
  const workflow = data?.wf_info || [];

  const ruleTypeId = data?.rule_type_id;

  const isCompareSchemaRule = ruleTypeId === "CMS";

  const tagNamesArray =
    extractClassificationTagsFromApiData(data?.classifications || {}) || [];

  const workflowSteps = getWorkflow(workflow);

  const attributesData = [
    {
      dbId: `${data?.src_id}`,
      dbName: data?.src_name || "",
      tblId: isCompareSchemaRule ? "" : `${data?.tbl_id}`,
      tblName: isCompareSchemaRule ? "" : data?.tbl_name || "",
      schemaName: data?.rule_def_config?.value?.src_schema || "",
      colId: `${data?.cols_info?.[0]?.col_id || 0}`,
      colName: data?.cols_info?.[0]?.col_name || "",
      isTblSelection: false,
      isRefView: false,
    },
  ];

  const classificationData =
    getClassificationCustomTags(data?.classifications || {}) || [];

  const { currentWorkflowStatus, actionPerformedBy } = getWorkflowStats(
    workflow
  );

  const getAllappliedSchedulers =
    data?.scheduled_jobs?.value?.map((item) => ({
      jobId: item?.job_id,
      jodDefId: item?.job_def_id,
      jobDesc: item?.job_desc,
    })) || [];

  const appliedSchedulers = getAllappliedSchedulers?.map(
    (item) => `${item?.jobId} - ${item?.jobDesc}`
  );

  const appliedSchedulersIds = getAllappliedSchedulers?.map((item) =>
    String(item?.jodDefId)
  );
  const metricType = data?.rule_def_config?.value?.metric_type?.trim() as MetricType;
  const metricName = metricType ? getMetricType[metricType] : "";

  const minNextExecDate =
    data?.scheduled_jobs?.value?.reduce((min, job) => {
      const jobDate = new Date(job?.next_job_exec_on);
      return jobDate < new Date(min) ? job?.next_job_exec_on : min;
    }, data?.scheduled_jobs?.value[0]?.next_job_exec_on) || "";

  return {
    definition: [
      {
        title: RULE_SECTION_TITLES?.DEFINITION?.RULE_TYPE,
        value: `${data?.rule_type || ""} ${
          metricType ? `(${metricName})` : ""
        }`,
        sequence: RULE_SECTION_SEQUENCE?.DEFINITION?.RULE_TYPE,
      },

      {
        title: RULE_SECTION_TITLES?.DEFINITION?.APPLIED_TO,
        representsData: attributesData,
        sequence: RULE_SECTION_SEQUENCE?.DEFINITION?.APPLIED_TO,
        additionalInfo: {
          colsInfo: data?.cols_info || [],
        },
      },

      {
        title: RULE_SECTION_TITLES?.DEFINITION?.ATTRIBUTE,
        value: tagNamesArray.join(", "),
        sequence: RULE_SECTION_SEQUENCE?.DEFINITION?.ATTRIBUTE,
      },
      {
        title: RULE_SECTION_TITLES?.DEFINITION?.SCHEDULED_JOB,
        stringValues: appliedSchedulers || [],
        sequence: RULE_SECTION_SEQUENCE?.DEFINITION?.SCHEDULED_JOB,
        ids: appliedSchedulersIds || [],
      },

      {
        title: RULE_SECTION_TITLES?.DEFINITION?.SCOPE,
        value: data?.rule_thresh_config?.value?.scope_remarks || "",
        sequence: RULE_SECTION_SEQUENCE?.DEFINITION?.SCOPE,
      },
      {
        title: RULE_SECTION_TITLES?.DEFINITION?.THRESHOLD,
        value: data?.rule_thresh_config?.value?.thresh_remarks || "",
        sequence: RULE_SECTION_SEQUENCE?.DEFINITION?.THRESHOLD,
      },
    ],
    status: [
      {
        title: RULE_SECTION_TITLES?.STATUS?.RUN_RESULT,
        value: data?.rule_status_id || "",
        isShowProgress: false,
        sequence: RULE_SECTION_SEQUENCE?.STATUS?.RUN_RESULT,
        additionalInfo: {
          exceptionCount: data?.rule_exec_info?.rule_exec_result,
        },
      },
      {
        title: RULE_SECTION_TITLES?.STATUS?.LAST_REFRESHED,
        value: data?.rule_exec_info?.rule_exec_on,
        sequence: RULE_SECTION_SEQUENCE?.STATUS?.LAST_REFRESHED,
      },
      {
        title: RULE_SECTION_TITLES?.STATUS?.NEXT_REFRESH_ON,
        value: minNextExecDate,
        sequence: RULE_SECTION_SEQUENCE?.STATUS?.NEXT_REFRESH_ON,
      },
      {
        title: RULE_SECTION_TITLES?.STATUS?.DQ_SCORE,
        value:
          data?.rule_exec_info?.rule_score !== null
            ? `${data?.rule_exec_info?.rule_score}%`
            : "",
        sequence: RULE_SECTION_SEQUENCE?.STATUS?.DQ_SCORE,
      },
      {
        title: RULE_SECTION_TITLES?.STATUS?.WORKFLOW_STATUS,
        value: currentWorkflowStatus || "",
        additionalInfo: {
          actionPerformedBy,
        },
        sequence: RULE_SECTION_SEQUENCE?.STATUS?.WORKFLOW_STATUS,
      },
      {
        title: RULE_SECTION_TITLES?.STATUS?.REMARKS,
        value: data?.rule_exec_info?.rule_remarks || "",
        sequence: RULE_SECTION_SEQUENCE?.STATUS?.REMARKS,
        additionalInfo: {
          ruleTypeId: data?.rule_type_id,
        },
      },
    ],
    compareSchemaRef: [],

    instructions: data?.additional_info?.value || "",

    isWorkFlowEnabled: data?.is_dq_wf_enabled || false,
    sourceId: data?.src_id || 0,
    sourceName: data?.src_name || "",
    tableId: data?.tbl_id || 0,
    tableName: data?.tbl_name || "",
    ruleAppliedOnColumns: data?.col_ids,
    ruleName: data?.node_name || "",
    isEditable: data?.is_editable || false,
    isRuleAlertStatusExists: !!data?.rule_exec_info?.alert_status_id,
    ruleAlertStatus: data?.rule_exec_info?.alert_status_id,
    ruleId: data?.node_id || 0,
    ruleAlertStatusId: data?.rule_status_id,
    exceptionsCount: data?.rule_exec_info?.rule_exec_result,
    ruleTypeId: data?.rule_type_id,
    ruleCatId: data?.rule_cat_id,
    sourceTypeId: data?.src_type_id,
    ruleColumnIds: data?.col_ids,
    tableFilter: data?.filter_info?.tbl_filter_text,
    colsInfo: data?.cols_info,
    refSourceInfo: data?.ref_source_info,
    ruleRunStatusId: data?.rule_status_id,
    userAddedDesc: data?.overview?.description?.value || "",
    systemGeneratedDesc: data?.overview?.system_description || "",
    workflow: workflowSteps || [],
    isRuleResolved: !!workflow?.rule_rsld_by_id,
    ruleResolvedReason: workflow?.rule_rsld_type_id,
    ruleResolvedComment: workflow?.rule_rsld_type_id,
    ruleAssignedTo: {
      name:
        data?.classifications?.rule_assigned_to?.value?.rule_assigned_to || "",
      id: data?.classifications?.rule_assigned_to?.value?.rule_assigned_to_id,
    },
    thresholdConfig: {
      lowerBound: data?.rule_thresh_config?.value?.thresh_lb,
      upperBound: data?.rule_thresh_config?.value?.thresh_ub,
      threshType: data?.rule_thresh_config?.value?.thresh_type || "",
      windowType: data?.rule_thresh_config?.value?.window_type || "",
      bucketSize: data?.rule_thresh_config?.value?.bucket_size || "NB",
      lookBackDays: data?.rule_thresh_config?.value?.lookback_days || 0,
      threshBound: data?.rule_thresh_config?.value?.adp_thresh_bound || "BT",
      threshFormat: data?.rule_thresh_config?.value?.thresh_format || "",
      threshValue: data?.rule_thresh_config?.value?.thresh_value || 0,
      slicerColumnId: data?.rule_thresh_config?.value?.slicer_col_id,
      metricTimecolumnId: data?.rule_thresh_config?.value?.audit_ts_col_id,
    },
    rulePriority: data?.overview?.rule_priority?.value?.rule_priority_id || "",
    classifications: {
      data: classificationData,
    },

    jobSchedulers: getAllappliedSchedulers,
    ruleExpUrls: data?.rule_exp_urls,
    nodeId: data?.node_id,
    colSeqType: data?.col_seq_type,
    isTblSeqSet: data?.is_tbl_seq_set,
    sawsId: data?.saws_id,

    // STARTING DEFINITION TAB FROM HERE
    defintionConfig: data?.rule_def_config?.value,
    filterConfig: {
      filter_text: data?.filter_info?.filter_text,
      ref_filter_text: data?.filter_info?.ref_filter_text,
    },
  };
}

export function getRuleExecutionHistoryParsedData({
  data,
}: AxiosResponse<
  RuleExecutionHistoryDataResponse[]
>): RuleExecutionHistoryDataParsedType[] {
  return (
    data?.map((execHistoy) => {
      return {
        runDate: execHistoy?.RULE_EXEC_ON || "",
        actionAt: execHistoy?.ACTION_AT || "",
        actionBy: execHistoy?.ACTION_BY || "",
        isAlerting: execHistoy?.IS_ALERTING,
        slicer: execHistoy?.SLICER_VALUE || "",
        lowerBound: execHistoy?.THRESH_LB || "",
        upperBound: execHistoy?.THRESH_UB || "",
        bucketResult: execHistoy?.BUCKET_RESULT || "",
        bucketTime: execHistoy?.BUCKET_TS || "",
        workflowStatus: execHistoy?.RULE_ACTION_STATUS_ID,
      };
    }) || []
  );
}

export const useGetResultsOfDataTab = (
  url: string,
  isOnpremiseGateway: boolean,
  sawsCommunicationKey: string,
  sawsId: string,
  isGatewayRunning: boolean
): CustomReactQueryHookReturnType<any> => {
  const { triggerPrompt } = useGatewayDownPrompt(sawsId);

  return useGetAll<any>({
    apiConfig: isOnpremiseGateway
      ? { url, method: "GET" }
      : API_CONFIG?.get_presigned_url,
    options: {
      enabled: !!url && !!sawsCommunicationKey && isGatewayRunning,
      refetchOnMount: false,
      ...(isOnpremiseGateway && {
        onError: (err: any): void => triggerPrompt?.(err),
      }),
    },
    params: isOnpremiseGateway ? [] : [url],
    parser: isOnpremiseGateway ? csvToJSON : (data): void => data?.data,
    // isReqWithoutHeader: !isOnpremiseGateway,
    ...(isOnpremiseGateway && {
      isReqWithCustomHeader: isOnpremiseGateway,
      headers: {
        "X-Api-Key": btoa(sawsCommunicationKey),
      },
    }),
  });
};

export const useGetResultsOfDataTabOnline = (
  url: string
  // isOnpremiseGateway: boolean,
  // sawsCommunicationKey: string
): CustomReactQueryHookReturnType<any> => {
  return useGetAll<any>({
    apiConfig: { url, method: "GET" },
    options: {
      enabled: !!url,
      // refetchOnMount: false,
    },
    isReqWithoutHeader: true,
    // params: isOnpremiseGateway ? [] : [url],
    parser: csvToJSON,
    // isReqWithoutHeader: !!isOnpremiseGateway,
    // ...(isOnpremiseGateway && {
    //   isReqWithCustomHeader: isOnpremiseGateway,
    //   headers: {
    //     "X-Api-Key": btoa(sawsCommunicationKey),
    //   },
    // }),
  });
};

export function useGetNodeTagsParsedData({
  data,
}: AxiosResponse<NodeTypeTAGContextResponse[]>): TagsData {
  const sortedData =
    sortListOnSpecificKeyValue({
      list: data,
      key: "PARENT_NAME",
    }) || [];

  const parentIds = sortedData?.map((item) => item?.PARENT_ID || "") || [];
  const uniqueIds = [...new Set(parentIds)];

  const tags = uniqueIds?.reduce((prev, parentId) => {
    const currItem = data?.find((item) => item?.PARENT_ID === parentId);
    const parentName = currItem?.PARENT_NAME || "";
    return {
      ...prev,
      [parentName]: {
        tagsData: commonItemParser(
          data?.filter((item) => item?.PARENT_ID === parentId) || []
        ),
        isMultiple: currItem?.IS_MULTI_VALUE_ALLOWED,
        isNewValueAllowed: false,
        isNoteRequired: false,
      },
    };
  }, {});
  return tags;
}

export function useGetPatternsParsedData({
  data,
}: AxiosResponse<GetPatternsResponseType[]>): RulePatterGroupedData {
  const getParsedItem = (
    item: GetPatternsResponseType
  ): GetPatternsParsedDataType => {
    const regexValue = jsonParse(item?.REF_ADHOC_INFO);
    return {
      name: item?.REF_NAME,
      description: item?.REF_DESC || "",
      regex: regexValue?.daf_regexp,
      refId: item?.REF_ID,
      refSeqId: item?.REF_SEQ_ID,
    };
  };

  const sortedData = sortListOnSpecificKeyValue({
    list: data,
    key: "REF_NAME",
  });

  const customAndDefaultPatternsGroupedData =
    sortedData?.reduce<RulePatterGroupedData>(
      (acc, item) => {
        if (item?.REF_ID) {
          acc?.default?.push(getParsedItem(item));
        } else {
          acc?.custom?.push(getParsedItem(item));
        }
        return acc;
      },
      { default: [], custom: [] }
    ) || [];

  return customAndDefaultPatternsGroupedData;

  // return (
  //   data?.map((pattern) => {
  //     const regexValue = jsonParse(pattern?.REF_ADHOC_INFO);
  //     return {
  //       name: pattern?.REF_NAME,
  //       description: pattern?.REF_DESC || "",
  //       regex: regexValue?.daf_regexp,
  //       refId: pattern?.REF_ID,
  //       refSeqId: pattern?.REF_SEQ_ID,
  //     };
  //   }) || []
  // );
}
