import styled from "styled-components";

export const ServiceProviderConfigsStyled = styled.div`
  .section-with-body {
    margin-left: 130px;
  }

  .sp-metadata-row {
    display: flex;
    gap: 15px;

    .download-metadata-btn {
      margin-top: 32px;
    }
  }
`;
