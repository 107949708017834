import { useCallback } from "react";

import {
  ColumnChart,
  LineChart,
  BarChart,
} from "../../../../../components/recharts";
import NodeStatusRendrer from "../../../../../components/nodestatusrendrer";

import ProfilingCountProgressbar from "../../../../../components/profilingcountprogressbar";
import { useOpenDrawer } from "../../../../../customhooks";

import { NodePublishedStatusIds } from "../../../../../parsers/parser.types";
import { checkDataTypeOfColumn, getDataTypeIcon } from "../../../../../utils";

import { HeaderRenderersStyled } from "./columnsampledata.styles";
import { ColSampleDataHeaderCompParams } from "./columnsampledata.types";
import { Y_AXIS_DATA_KEY } from "../../../../../constants";
import { COLUMN_VISUAL_DIST_DATA_KEY } from "../../../../../parsers/columnparsers";

export function HeaderRenderers(props: any): JSX.Element {
  const { column = {} } = props || {};
  const params = (column?.getColDef?.()?.headerComponentParams ||
    {}) as ColSampleDataHeaderCompParams;
  const openDrawer = useOpenDrawer();

  const currentConfig = params?.headerconfig?.find(
    (item) => item?.column_name === props?.column?.colId
  );

  const {
    column_id: columnId = "",
    chart_data: chartData = [],
    col_status: colStatus = "",
    col_status_id: colStatusId,
    column_display_name: columnDisplayName = "",
    data_type: dataType = "BOL",
    data_type_info: dataTypeInfo = "",
    description = "",
    profiling = [],
  } = currentConfig || {};

  const { isDateColumn, isNumberCol } = checkDataTypeOfColumn(dataType);

  const onColumnClick = useCallback(() => {
    openDrawer({
      drawerId: "column_ref",
      visible: true,
      drawerProps: { id: columnId },
    });
  }, [props]);

  const ChartComp = isDateColumn
    ? LineChart
    : isNumberCol
    ? ColumnChart
    : BarChart;

  return (
    <HeaderRenderersStyled>
      <div className="sample-data-header">
        <div className="header-title">
          <span title={dataTypeInfo} className="data-type-icon">
            {getDataTypeIcon(dataType)}
          </span>
          <span
            className="rendrer-column-name"
            title={columnDisplayName}
            role="button"
            onClick={onColumnClick}
          >
            {columnDisplayName}
          </span>
          <NodeStatusRendrer
            status={colStatusId as NodePublishedStatusIds}
            tooltipTitle={colStatus}
            className="header-rendrer-icon"
            nodeType="COL"
          />
        </div>
        {params?.isShowChart && (
          <div className="desc" title={description}>
            {description || "--"}
          </div>
        )}
      </div>
      <ProfilingCountProgressbar
        data={profiling}
        className="column-sample-data-profiling-tooltip"
        showValue={false}
      />
      {params?.isShowChart && !!chartData?.length && (
        <div className="header-column-chart">
          <ChartComp
            height={140}
            width="95%"
            data={chartData}
            dataKeys={[
              { dataKey: COLUMN_VISUAL_DIST_DATA_KEY, fill: "#4894ca" },
            ]}
            yAxis={[{ yAxisId: Y_AXIS_DATA_KEY, label: { value: "" } }]}
            showLedgends={false}
            hideXAxis
            showCartesianGrid={false}
            margin={{ left: 20 }}
            hideYAxis
            keepStaicBarSize={false}
          />
        </div>
      )}
    </HeaderRenderersStyled>
  );
}
