import styled from "styled-components";

export const DeleteViewTooltipStyled = styled.div`
  .heading {
    color: ${({ theme }): string => theme.textColors.BLUE_116};
    font-size: 18px;
    font-family: "OpenSansSemiBold";
  }

  .body {
    color: ${({ theme }): string => theme.textColors.GREY_40};
    font-size: 14px;
    font-family: "OpenSans";
    margin: 10px 0px 25px;
  }
`;
