import { Tooltip } from "antd";
import { useCallback, useMemo } from "react";

import { APP_ROUTES, DVSUM_TOOLTIP_CLASS_NAME } from "../../constants";
import { useGetAppType, useOpenDrawer } from "../../customhooks";
import { useOpenLinkInNewTab } from "../../customhooks/useopenlinlinnewtab";

import { chevronRight, databaseIcon, deleteIcon } from "../../svgs";
import { getTableDetailPageUrl } from "../../utils";
import CoreNodesCircleIcon from "../corenodetypescircleicon";

import { RepresentsStyled } from "./represents.styles";
import {
  Represents as RepresentsType,
  RepresentsProps,
} from "./represents.types";

import StyledLink from "../styledlink/styledlink";

const nodeIconProps = { width: "16px", height: "16px", fontSize: "11px" };

function Represents(props: RepresentsProps): JSX.Element {
  const openDrawer = useOpenDrawer();
  const openLinkInNewTab = useOpenLinkInNewTab();
  const { isExtOrDesktop } = useGetAppType();

  const { moreItemsCount, moreItemsTitle = "", mode = "primary" } = props;

  const openLink = useCallback((e, url) => {
    isExtOrDesktop ? window.open(url) : openLinkInNewTab(e, url);
  }, []);

  const onClick = useCallback(
    (e, id: "col" | "tbl" | "src", item: RepresentsType) => {
      if (id === "col") {
        item?.isRefView
          ? console.log("not implemented")
          : openDrawer({
              drawerId: "column_ref",
              visible: true,
              drawerProps: { id: item?.colId },
            });
      } else if (id === "tbl") {
        item?.isRefView
          ? openLink(e, getTableDetailPageUrl(`${item?.tblId}`))
          : openDrawer({
              drawerId: "table_ref",
              visible: true,
              drawerProps: { id: item?.tblId },
            });
      } else {
        openLink(e, `${APP_ROUTES.private_routes.sources}/${item?.dbId}`);
      }
    },
    [props]
  );

  const isRefView = useMemo(
    () => props?.represents?.some((item) => item?.isRefView),
    [props?.represents]
  );

  const isViewMode = mode === "secondary";

  return (
    <RepresentsStyled
      isRefView={isRefView}
      className="represents-container"
      mode={mode}
    >
      <RepresentsStyled
        isRefView={isRefView}
        className="represents-container"
        mode={mode}
      >
        <div className="represents-values">
          {props?.represents?.map((represent, index) => {
            const { dbName, schemaName } = represent || {};
            const isDbOrSchemaExists = dbName || schemaName;

            return (
              <div
                className="represent-item"
                key={`key-${represent.dbName}-${index}`}
              >
                {represent?.dbName && (
                  <StyledLink
                    to={`${APP_ROUTES.private_routes.sources}/${represent?.dbId}`}
                  >
                    <div>{represent?.dbIcon || databaseIcon}</div>
                    <div
                      className="represent-item-value"
                      title={represent?.dbName}
                    >
                      {represent?.dbName}
                    </div>
                  </StyledLink>
                )}

                {represent?.schemaName && (
                  <>
                    {dbName && (
                      <div className="seperator-right-icon">{chevronRight}</div>
                    )}
                    <div title={represent?.schemaName}>
                      {represent?.schemaName}
                    </div>
                  </>
                )}

                {represent?.tblName && (
                  <>
                    {isDbOrSchemaExists && (
                      <div className="seperator-right-icon">{chevronRight}</div>
                    )}
                    <div>
                      {represent?.tblIcon || (
                        <CoreNodesCircleIcon
                          nodeType="TBL"
                          {...nodeIconProps}
                        />
                      )}
                    </div>
                    <div
                      className="represent-item-value"
                      role="button"
                      onClick={(e): void =>
                        isViewMode ? undefined : onClick(e, "tbl", represent)
                      }
                      title={represent?.tblName}
                    >
                      {represent?.tblName}
                    </div>
                  </>
                )}

                {represent?.colName && (
                  <>
                    <div className="seperator-right-icon">{chevronRight}</div>
                    <div>
                      {represent?.colIcon || (
                        <CoreNodesCircleIcon
                          nodeType="COL"
                          {...nodeIconProps}
                        />
                      )}
                    </div>
                    <div
                      className="represent-item-value"
                      role="button"
                      onClick={(e): void =>
                        isViewMode ? undefined : onClick(e, "col", represent)
                      }
                      title={represent?.colName}
                    >
                      {represent?.colName}
                    </div>
                  </>
                )}
                {props?.onRemove && <div className="flex-1" />}
                {props?.onRemove && (
                  <div
                    className="delete-icon"
                    role="button"
                    onClick={(): void => {
                      props?.onRemove &&
                        props?.onRemove(
                          represent?.isTblSelection
                            ? represent?.tblId
                            : represent?.colId
                        );
                    }}
                  >
                    {deleteIcon}
                  </div>
                )}
                {!!moreItemsCount && (
                  <span className="more-items-count" title={moreItemsTitle}>
                    ,+{moreItemsCount}
                  </span>
                )}
              </div>
            );
          })}
        </div>
      </RepresentsStyled>
    </RepresentsStyled>
  );
}

export default Represents;
