import styled from "styled-components";

export const FindOrAddDatasetStyled = styled.div`
  width: 375px;
  height: 100%;
  padding: 24px 16px 24px 24px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fafafa;
  .header {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: ${({ theme }): string => theme.textColors.BLACK_10};
    margin-bottom: 23px;
    > button {
      margin-right: 8px;
    }
  }

  .body {
    display: flex;
    align-items: center;

    .ant-input-affix-wrapper {
      .ant-input-prefix {
        margin-right: 8px;
        svg {
          path {
            fill: ${({ theme }): string => theme.textColors.BLUE_116};
          }
        }
      }
      .ant-input-suffix {
        svg {
          path {
            fill: #c2c2c2;
          }
        }
      }
    }

    .add-btn {
      margin-left: 9px;
      height: 48px;
      border-radius: 50%;
    }
  }
`;
