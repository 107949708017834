import styled from "styled-components";

export const QueryBlocksLoadingSlateWrapperStyled = styled.div<{
  isChatPage?: boolean;
}>`
  width: 100%;
  .query-blocks-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ isChatPage }): string => (isChatPage ? "70%" : "100%")};
    margin: ${({ isChatPage }): string => (isChatPage ? "0 auto" : "unset")};
    height: "fit-content";
    background-color: #ffffff;
    z-index: 1;
    display: flex;
    flex-direction: column;
    row-gap: 18px;
    justify-content: center;
    align-items: center;
    font-family: OpenSansSemiBold;
    font-size: 16px;
    color: ${({ theme }): string => theme?.textColors?.BLACK_COLOR};

    .loading-info {
      font-family: OpenSans;
      font-size: 14px;
      color: ${({ theme }): string => theme?.textColors?.GREY_24};
      width: 500px;
      text-align: center;
    }

    .inline-loader-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .inline-loader-left-sec {
        display: flex;
        column-gap: 0.7rem;
        width: 100%;
        padding: 12px 30px;
        font-size: 14px;
        font-family: OpenSans;
        align-items: center;

        img {
          margin-bottom: 7px;
        }
      }
    }
  }

  .paginated-results-wrapper {
    min-height: 420px;
  }

  .paginated-results {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
