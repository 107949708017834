import { NodeType } from "../../app.types";
import { SectionRowProps } from "../../components/sectionrow/sectionrow.types";
import { TagsData } from "../../components/tagseditor/tagseditor.types";

import { API_CONFIG } from "../../constants/apiconfig";
import { TERM_SECTION_TITLES } from "../../constants/labelconstants";

import { getDomainSubdomainForTermSelect } from "../../parsers/glossarycategories/glossarycategoriesparser";
import { SubDomainForSelectReturnType } from "../../parsers/glossarycategories/glossarycategoriesparser.types";

import { GetClassificationCustomTagsAddInfo } from "../../parsers/parserutils/getclassificationcustomtags";
import { getNodeTypeTAGContextParser } from "../../parsers/tablepage/tablepageparser";

import {
  getParsedListOfTerm,
  getParsedTermDocForDetailPage,
  getParsedTermDocForRef,
} from "../../parsers/termsparser/termsparser";

import {
  ActivitiesParsedDataType,
  ListOfAllTermsReturnType,
  ParsedTermDocForDetailPage,
  ParsedTermDocForRef,
  ReviewChangesParsedType,
  TermType,
  WorkflowStepsParsedType,
  WorkflowTemplateParsedType,
} from "../../parsers/termsparser/termsparser.types";
import { getFiltersfromLocalStorage } from "../../utils";

import { useGetAll } from "../baseservice";
import { useGetRefByType } from "../refservice";

import { CustomReactQueryHookReturnType } from "../services.types";

const { OVERVIEW } = TERM_SECTION_TITLES;
const { TERM_TYPE, TERM_CATEGORY } = OVERVIEW;

export function useGetGlossaryCategoriesForSelection(): CustomReactQueryHookReturnType<SubDomainForSelectReturnType> {
  const parsedNodeFilters = getFiltersfromLocalStorage("TRM");
  return useGetAll({
    apiConfig: API_CONFIG.get_loggedin_user_domain_and_subdomain,
    params: [parsedNodeFilters],
    parser: getDomainSubdomainForTermSelect,
  });
}

export function useGetTermRefDoc(
  nodeID: string,
  nodeType: NodeType,
  nodeStatus: string
): CustomReactQueryHookReturnType<ParsedTermDocForRef> {
  const parsedNodeFilter = getFiltersfromLocalStorage(nodeType);

  return useGetAll({
    apiConfig: API_CONFIG.get_node,
    params: [nodeID, nodeType, nodeStatus, "true", "", parsedNodeFilter],
    parser: getParsedTermDocForRef,
  });
}

export function useGetListOfTerms(
  searchText: string,
  trmType?: TermType,
  enabled?: boolean
): CustomReactQueryHookReturnType<ListOfAllTermsReturnType> {
  return useGetAll({
    apiConfig: API_CONFIG.get_searched_entities,
    params: [searchText, trmType || ""],
    parser: getParsedListOfTerm,
    options: { enabled: enabled || false },
  });
}

export function useGetTermDocForDetailPage(
  nodeID: string,
  nodeType: NodeType,
  nodeStatus: "PUB" | "DFT",
  enabled?: boolean
): CustomReactQueryHookReturnType<ParsedTermDocForDetailPage> {
  const parsedNodeFilter = getFiltersfromLocalStorage(nodeType);

  const config = useGetAll<ParsedTermDocForDetailPage>({
    apiConfig: API_CONFIG.get_node,
    params: [nodeID, nodeType, nodeStatus, "", "", parsedNodeFilter],
    parser: getParsedTermDocForDetailPage,
    options: {
      enabled: nodeStatus === "PUB" ? true : !!enabled,
    },
  });

  const config1 = useGetRefByType("TERM_SUBTYPE");
  const config2 = useGetGlossaryCategoriesForSelection();

  const isAnyLoading =
    config?.isLoading || config1?.isLoading || config2?.isLoading;

  const isAnyError = config?.error || config1?.error || config2?.error;

  const updatedTermDocResponse = !isAnyLoading && {
    ...config?.parsedData,
    description: {
      ...config?.parsedData?.description,
      data: config?.parsedData?.description?.data?.map((item) => ({
        ...item,
        options:
          item?.title === TERM_TYPE
            ? (config1?.parsedData || [])?.map((item) => ({
                key: item?.id || "",
                value: item?.id || "",
                label: item?.name || "",
                labelText: item?.name || "",
              }))
            : item?.title === TERM_CATEGORY
            ? config2?.parsedData
            : undefined,
      })),
    },
  };

  return {
    ...config,
    parsedData: updatedTermDocResponse as ParsedTermDocForDetailPage,
    isLoading: isAnyLoading as any,
    error: isAnyError as any,
  };
}

export function useGetClassificationDataForTerm(
  id: string,
  isDraftExist: boolean,
  enabled: boolean
): CustomReactQueryHookReturnType<TagsData> {
  const { parsedData } = useGetTermDocForDetailPage(
    id,
    "TRM",
    isDraftExist ? "DFT" : "PUB"
  );

  const getClassificationRow = (tagsetId: string): SectionRowProps =>
    parsedData?.classification?.data?.find(
      (item) =>
        (item?.additionalInfo as GetClassificationCustomTagsAddInfo)
          ?.tagsetId === tagsetId
    ) || { title: "" };

  const tagsConfig = useGetAll<TagsData>({
    apiConfig: API_CONFIG.get_node_tags,
    params: ["TRM"],
    options: { enabled },
    parser: getNodeTypeTAGContextParser,
  });

  const { parsedData: tagsData = {} } = tagsConfig;
  return {
    ...tagsConfig,
    parsedData: {
      ...Object.keys(tagsData)?.reduce((prev, item) => {
        const row = getClassificationRow(item);
        const { tagsetConfig = {} } = (row?.additionalInfo ||
          {}) as GetClassificationCustomTagsAddInfo;

        const {
          tagset_name: tagsetName,
          is_comment_required: isCommentRequired,
          is_multi_value_allowed: isMultiValueAllowed,
          is_new_value_allowed: isNewValueAllowed,
          tagset_type: tagsetType,
        } = tagsetConfig?.[item] || {};

        const isSelected = (val: string): boolean => {
          const combinedTags = [
            ...(row?.tags || []),
            ...(row?.prominentTags?.tags?.map((promTag) => promTag?.name) ||
              []),
          ];
          return combinedTags?.length
            ? combinedTags?.includes(val)
            : val === row?.value;
        };
        return {
          ...prev,
          [tagsetName]: {
            ...tagsData?.[item],
            isMultiple: !!isMultiValueAllowed,
            isNewValueAllowed: !!isNewValueAllowed,
            isNoteRequired: !!isCommentRequired,
            tagsetType,
            tagsData: tagsData?.[item]?.tagsData?.map((nestItem) => ({
              ...nestItem,
              selected: isSelected(nestItem?.label),
              isAdded: isSelected(nestItem?.label),
              comment: row?.prominentTags?.tags?.find(
                (item) => item?.id === nestItem?.value
              )?.tagDetails?.tagNote,
            })),
          },
        };
      }, {}),
    },
  };
}

export function useGetWorkflowTemplates(): CustomReactQueryHookReturnType<
  WorkflowTemplateParsedType[]
> {
  return useGetAll({
    apiConfig: API_CONFIG?.get_workflow_template,
    params: ["TRM"],
  });
}

export function useGetWorkflowSteps(
  nodeID: string,
  nodeType: string
): CustomReactQueryHookReturnType<WorkflowStepsParsedType> {
  return useGetAll({
    apiConfig: API_CONFIG?.get_workflow_steps,
    params: [nodeType, nodeID],
  });
}

export function useGetTermChangesForReview(
  nodeID: string,
  nodeType: NodeType
): CustomReactQueryHookReturnType<ReviewChangesParsedType> {
  return useGetAll({
    apiConfig: API_CONFIG?.review_term_changes,
    params: [nodeID, nodeType],
  });
}

export function useGetUserActivities(
  nodeID: string,
  nodeType: string,
  numOfRecordsToFetch: string
): CustomReactQueryHookReturnType<ActivitiesParsedDataType> {
  return useGetAll({
    apiConfig: API_CONFIG?.user_activities,
    params: [nodeID, nodeType, numOfRecordsToFetch],
    options: { enabled: !!nodeID, keepPreviousData: true },
  });
}
