import LinkButton from "../../../../../../components/linkbutton/linkbutton";
import { crossIcon } from "../../../../../../svgs";
import { AnalysisOverviewSecStyled } from "./analysisoverviewsec.styles";
import { AnalysisOverviewSecProps } from "./analysisoverviewsec.types";

const AnalysisOverviewSec = (props: AnalysisOverviewSecProps): JSX.Element => {
  const { children, handleClose } = props;

  return (
    <AnalysisOverviewSecStyled data-testid="analysis-detail-page-overview-sec-main-wrapper">
      <div
        className="overview-content"
        data-testid="analysis-detail-page-overview-sec-overview-content-sec"
      >
        {" "}
        {children}
      </div>

      <LinkButton
        className="cross-icon-container"
        onClick={handleClose}
        data-testid="analysis-detail-page-overview-sec-cross-btn"
      >
        {crossIcon("9.6", "9.6")}
      </LinkButton>
    </AnalysisOverviewSecStyled>
  );
};

export default AnalysisOverviewSec;
