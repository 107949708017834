export const JOB_SECTION_TITLES = {
  RULES: "Rule(s)",
  TABLES: "Table(s)",
  LAST_EXECUTION_ON: "Last Execution",
  NEXT_EXECUTION_ON: "Next Execution",
  INTERNAL_RECIPIENTS: "Internal Recipients",
  EXTERNAL_RECIPIENTS: "External Recipients",
  DESCRIPTION: "Description",
  ATTRIBUTES: "Attribute(s)",
};
