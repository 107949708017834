import moment from "moment";

import { FilterResponse } from "../../parsers/modulesettingparser/modulesettingparser.types";

import {
  SpecifyCriteriaReadModeType,
  SpecifyCriteriaType,
} from "../../components/criteriaselection/criteriaselection.types";

import { FiltersColumnParsedConfigurationType } from "../../parsers/tablepage/tablepageparser.types";
import { FilterCriteriaType } from "../../components/filtercriteria/filtercriteria.types";

import { ColumnDataType } from "../../parsers";
import { checkDataTypeOfColumn } from "../../utils";

export const getValue = (
  value: string,
  dateType: string | Date,
  dataType?: ColumnDataType,
  dateValue?: string | Date
): string => {
  const { isDateColumn } = checkDataTypeOfColumn(dataType || "STR");

  if (isDateColumn && dateType && dateType === "ABS") {
    return moment.utc(dateValue).local().format("YYYY-MM-DD HH:MM:SS");
  }
  return value;
};

export const getSecondValue = (
  value: string,
  selectedFilter: string,
  dateType: string | Date,
  dataType?: ColumnDataType,
  dateValue?: string | Date
): string => {
  const { isDateColumn } = checkDataTypeOfColumn(dataType || "STR");

  if (
    isDateColumn &&
    dateType &&
    dateType === "ABS" &&
    ["BTW", "NBT"].includes(selectedFilter)
  ) {
    return moment.utc(dateValue).local().format("YYYY-MM-DD HH:MM:SS");
  }
  return value || "";
};

export const apiToFormCriteria = (
  parsedCurrentNode: FilterResponse[],
  filters?: SpecifyCriteriaReadModeType[]
): SpecifyCriteriaType[] => {
  return parsedCurrentNode?.map((auroraFilter: FilterResponse, filterIndex) => {
    const withinConditionGrpLogicalOperator =
      parsedCurrentNode?.[0]?.next_operation;

    const { isDateColumn } = checkDataTypeOfColumn(
      auroraFilter?.field_type || "STR"
    );

    return {
      columnName: `${filters?.[filterIndex]?.columnName || ""}`,
      valuesList: filters?.[filterIndex]?.valuesList || [],
      column: `${auroraFilter?.field_id || ""}`,
      data_type: auroraFilter?.field_type,
      filter: auroraFilter?.filter_oper,
      value:
        auroraFilter?.datestype !== "ABS" ? auroraFilter?.limit1_value : "",
      second_value:
        auroraFilter?.datestype !== "ABS" ? auroraFilter?.limit2_value : "",
      date_type: auroraFilter?.datestype || "REL",
      date_value:
        isDateColumn &&
        auroraFilter?.datestype === "ABS" &&
        auroraFilter?.limit1_value
          ? new Date(auroraFilter?.limit1_value)
          : undefined,
      second_date_value:
        isDateColumn && auroraFilter?.datestype === "ABS"
          ? auroraFilter?.limit2_value !== "" && auroraFilter?.limit2_value
            ? new Date(auroraFilter?.limit2_value)
            : undefined
          : undefined,

      values_list: ["INC", "NIN"].includes(auroraFilter?.filter_oper || "")
        ? auroraFilter?.values_list
        : undefined,

      filter_type: auroraFilter?.filter_type || "CND",
      next_operation: withinConditionGrpLogicalOperator || "AND",
      sql_expression: auroraFilter?.sql_expression || "",
    };
  });
};

export const formCriteriaToApiData = (
  criteriaFormValues: SpecifyCriteriaType[],
  parsedFields: FiltersColumnParsedConfigurationType[]
): FilterResponse[] => {
  return criteriaFormValues?.map(
    (criteria: SpecifyCriteriaType, criteriaIndex: number) => {
      const fieldId = Number.parseInt(criteria?.column?.split("+")?.[0] || "");

      const field = parsedFields?.find(
        (parsedField) => Number(parsedField?.field_id) === fieldId
      );

      const valuesListIds =
        criteria?.values_list?.map(
          (valueListItem: string) => valueListItem?.split("+")?.[0]
        ) || [];

      const { isDateColumn } = checkDataTypeOfColumn(
        field?.field_datatype || "STR"
      );

      return {
        field_id: Number(field?.field_id) || 0,
        field_type: field?.field_datatype,
        filter_oper: criteria?.filter || "",

        filter_type: criteria?.filter_type || "",
        sql_expression: criteria?.sql_expression || "",
        next_operation:
          criteriaIndex < criteriaFormValues?.length - 1
            ? criteria?.next_operation
            : undefined,

        datestype: isDateColumn ? criteria?.date_type : "",

        limit1_value:
          getValue(
            criteria?.value || "",
            criteria?.date_type || "",
            field?.field_datatype,
            String(criteria?.date_value)
          ) || "",
        limit2_value:
          getSecondValue(
            criteria?.second_value || "",
            criteria?.filter || "",
            criteria?.date_type || "",
            field?.field_datatype,
            String(criteria?.second_date_value)
          ) || "",
        values_list: ["INC", "NIN"].includes(criteria?.filter || "")
          ? valuesListIds
          : [],

        condition_order: criteriaIndex + 1,
      };
    }
  );
};

export const filterCriteriaApiData = (
  formFilterCondition: FilterCriteriaType,
  columnsData: FiltersColumnParsedConfigurationType[]
): FilterCriteriaType | undefined => {
  const isfilterConditionEmpty = !formFilterCondition?.every((filterCriteria) =>
    filterCriteria?.condition?.every(
      (condition) => condition?.sql_expression || condition?.column
    )
  );

  const filterCondition = (formFilterCondition || [])?.map(
    (filterCriteriaRow, filterCriteriaIndex: number) => {
      const formCriteriaToApiParsedData = formCriteriaToApiData(
        filterCriteriaRow?.condition,
        columnsData
      );

      return {
        condition: formCriteriaToApiParsedData,
        criteria_id: filterCriteriaIndex + 1,
        next_operator:
          filterCriteriaIndex < formFilterCondition?.length - 1
            ? filterCriteriaRow?.next_operator
            : undefined,
      };
    }
  );
  return isfilterConditionEmpty ? undefined : filterCondition;
};

export const formCriteriaToExclusionCriteriaReadableFormat = (
  criteriaFormValues: SpecifyCriteriaType[]
): SpecifyCriteriaReadModeType[] => {
  return criteriaFormValues?.map((criteria: SpecifyCriteriaType) => {
    return {
      columnName: criteria?.columnName,
      filter: criteria?.filter,
      value1: criteria?.value,
      value2: criteria?.second_value,
      valuesList: criteria?.valuesList || [],
    };
  });
};
