import { useCallback } from "react";
import { useHistory } from "react-router";

//        <---Components-->
import Flex from "../flex";
import LinkButton from "../linkbutton";

import CoreNodesCircleIcon from "../corenodetypescircleicon";
//        <---Components-->

//        <---SVGS--->
import { redirectCircledIcon } from "../../svgs";
import { useOpenModal } from "../../customhooks";
import { DQRuleDetailsModalProps } from "../modalwrapper/modals/dqruledetailsmodal/dqruledetailsmodal.types";
import { QualitySectionTitleWithIconProps } from "./qualitysectitlewithicon.types";
import { getTableDetailPageUrl } from "../../utils";
import { tableDetailPageTabsValues } from "../../pages/tablepage/tablepage.constants";
//        <---SVGS--->

function QualitySectionTitleWithIcon(
  props: QualitySectionTitleWithIconProps
): JSX.Element {
  const onOpenModal = useOpenModal();
  const history = useHistory();

  const {
    title = "",
    nodeId = "",
    isColRef = false,
    isOverview = false,
    parentId = "",
  } = props;

  const onClick = useCallback((): void => {
    if (isColRef) {
      onOpenModal({
        modalId: "rule_type_detail_modal",
        visible: true,
        modalTitle: (
          <Flex columnGap={5}>
            <CoreNodesCircleIcon nodeType="COL" /> {title}
          </Flex>
        ),
        modalProps: {
          nodeId,
          ruleTypeId: "ALL",
          nodeName: title || "",
          nodeType: "COL",
          dqRulesgridNodeId: parentId,
          chartProps: {
            title: "Overall DQ trend for column",
            nodeId,
            nodeType: "COL",
            ruleCategoryId: "ALL",
          },
        } as DQRuleDetailsModalProps,
      });
    } else {
      const url = getTableDetailPageUrl(
        nodeId,
        tableDetailPageTabsValues?.data_quality
      );
      history.push(url);
    }
  }, [props]);

  return (
    <Flex>
      {isOverview && "Data"} Quality
      <LinkButton marginLeft="8px" height="20px" onClick={onClick}>
        {isOverview
          ? redirectCircledIcon("25", "25")
          : redirectCircledIcon("20", "20")}
      </LinkButton>
    </Flex>
  );
}

export default QualitySectionTitleWithIcon;
