// <---  Library Imports Start --->
import { useCallback } from "react";
import { useHistory } from "react-router";

// <---  Library Imports Start --->

// <---  Components Start --->
import { Button } from "../../../../components";
import IconWithText from "../../../../components/iconwithtext";

import StateHandler from "../../../../components/statehandler/statehandler";
import SuccessNotificationMessage from "../../../../components/successnotificationmessagerendrer/successnotificationmessagerendrer";
// <---  Components End --->

// <---  Constants Start --->
import {
  deleteListingTermsConfirmationText,
  deleteOverviewTermConfirmationText,
} from "./deleteterm.constants";

import { APP_ROUTES } from "../../../../constants";
// <---  Constants End --->

// <---  Custom Hooks Start --->
import { useCancelModal, useGetAppState } from "../../../../customhooks";
import { useRequestWithMethod } from "../../../../api";
// <---  Custom Hooks End --->

// <---  Styles Start --->
import { DeleteTermStyled } from "./deleteterm.styles";
// <---  Styles End --->

// <---  Svgs Start --->
import { infoIcon } from "../../../../svgs";
// <--- Svgs End --->

// <---  Types Start --->
import { DeleteTermModalProps } from "./deleteterm.types";
// <---  Types End --->

// <---  Utils Start --->
import {
  getUserPermissions,
  jsonParse,
  openNotification,
} from "../../../../utils";
import { useGetImportOwners } from "../../../../api/dataimportservice";
import { DetailDomainInfo } from "../../../../components/detaildomaininfo/detaildomaininfo";

// <---  Utils End --->

const DeleteTerm = (): JSX.Element => {
  const history = useHistory();

  const {
    modal: { modalProps = {} },
  } = useGetAppState();
  const handleCancel = useCancelModal();

  const {
    nodeId = "",
    nodeType,
    selectedNodesIds = [],
    countFilter = "",
    filterModel,
    isSelectAll = false,
    isTermOverviewPage = false,
    searchId = "",
    populateData,
  } = modalProps as DeleteTermModalProps;

  const { is_glossary_admin: isGlossaryAdmin = false } = getUserPermissions();

  const {
    parsedData: importDomains,
    isLoading: isLoadingImportDomains,
    error: errorLoadingImportDomains,
  } = useGetImportOwners();

  const onSuccessDeleteTerms = useCallback(
    (res) => {
      const parsedRes = jsonParse(res?.data?.FN_UPDATE_GOV_TRMS_DATA);
      populateData?.({});
      handleCancel?.();

      if (isTermOverviewPage) {
        openNotification(
          <SuccessNotificationMessage message="Term has been deleted." />
        );
      } else if (parsedRes?.deleted_cnt) {
        openNotification(
          <SuccessNotificationMessage
            message={`${parsedRes?.deleted_cnt} Term${
              parsedRes?.deleted_cnt > 1 && !nodeId ? "s have" : " has"
            } been deleted.`}
          />
        );
      } else {
        openNotification("No Term(s) has been deleted.", undefined, {}, "info");
      }

      if (isTermOverviewPage) {
        const url = APP_ROUTES?.private_routes?.terms;
        history.push(url);
      }
    },
    [selectedNodesIds]
  );

  const {
    onExecuteRequest: onExecuteRequestDeleteTerms,
    isLoading: isLoadingDeleteTerms,
    error: errorDeleteTerms,
  } = useRequestWithMethod(
    isTermOverviewPage ? "delete_term" : "update_field_data",
    isTermOverviewPage ? [nodeId, nodeType] : [nodeType, searchId],
    false,
    onSuccessDeleteTerms
  );

  const handleSuccess = useCallback(() => {
    onExecuteRequestDeleteTerms([
      {
        trm_ids: [...(selectedNodesIds || [])],
        trm_status: "DEL",
        is_mass_update:
          isSelectAll || (!isSelectAll && selectedNodesIds?.length > 1),
        isSelectAll: isSelectAll || false,
        filterModel: filterModel || {},
        countFilter: countFilter || "all",
      },
    ]);
  }, [selectedNodesIds, filterModel, countFilter, isSelectAll]);

  return (
    <StateHandler
      isFetching={isLoadingDeleteTerms || isLoadingImportDomains}
      error={errorDeleteTerms || errorLoadingImportDomains}
      isModal
    >
      <DeleteTermStyled>
        <div className="content-sec">
          <div className="static-text">
            <span className="bold">
              {isTermOverviewPage
                ? deleteOverviewTermConfirmationText
                : deleteListingTermsConfirmationText}
            </span>

            {!isTermOverviewPage && !isGlossaryAdmin && (
              <>
                <div className="note">
                  <IconWithText
                    icon={infoIcon}
                    text={`${
                      importDomains?.length === 1
                        ? `You can delete terms from these sub-domains of ${
                            importDomains?.[0]?.DMN_NAME || ""
                          }`
                        : "You can delete terms from these domains/sub-domains"
                    }  `}
                    textFlexDirection="column"
                  />
                </div>
                <div className="domains-info">
                  <DetailDomainInfo importDomains={importDomains} />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="modal-actions-sec">
          <Button id="cancel" width="78px" onClick={handleCancel}>
            Cancel
          </Button>

          <Button
            id="primary"
            width="78px"
            onClick={handleSuccess}
            disabled={!nodeId && !selectedNodesIds?.length && !isSelectAll}
          >
            Delete
          </Button>
        </div>
      </DeleteTermStyled>
    </StateHandler>
  );
};

export default DeleteTerm;
