import { useCallback } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  AddFilterCriteriaFormStyled,
  FilterCriteriaFormStyled,
} from "./addfiltercriteriaform.styles";

import {
  AddFilterCriteriaFormModalPropsType,
  AddWidgetFilterCriteriaFormType,
} from "./addfiltercriteriaform.types";

import { FormStyled } from "../../../form";
import { CheckboxField, InputField } from "../../../formfields";

import {
  CheckboxWrapperStyled,
  FormItemStyled,
} from "../../../form/form.styles";

import Button from "../../../button";
import {
  useCancelSecondaryModal,
  useGetAppState,
} from "../../../../customhooks";

import FormItemLabel from "../../../form/formitemlabel/formitemlabel";
import FilterCriteria from "../../../filtercriteria/filtercriteria";

import {
  useGetGovernanceViewFields,
  useGetGovernanceViewFilters,
} from "../../../../api/listingsservice";

import StateHandler from "../../../statehandler/statehandler";
import { useRequestWithMethod } from "../../../../api";
import { addWidgetFilterSchema } from "../../../../utils/schemas/addwidgetfilterschema";
import InformativeText from "../../../informativetext";
import { FilterCriteriaType } from "../../../filtercriteria/filtercriteria.types";

const AddFilterCriteriaForm = (): JSX.Element => {
  const onCancel = useCancelSecondaryModal();

  const {
    secondaryModal: { modalProps = {} },
  } = useGetAppState();

  const {
    propsOnSave,
    formData,
    isReadMode,
  } = modalProps as AddFilterCriteriaFormModalPropsType;

  const {
    widgetFilterCriteriaName = "",
    isTemplateFilter = false,
    widgetFilterCriteria = [],
    isEditMode = false,
    selectedModule,
  } = formData || {};

  const addWidgetFilterCriteriaForm = useForm<AddWidgetFilterCriteriaFormType>({
    defaultValues: {
      name: widgetFilterCriteriaName,
      save_as_template: isTemplateFilter,
      filter_criteria: [],
    },
    resolver: yupResolver(addWidgetFilterSchema),
    mode: "onChange",
  });

  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty },
    watch,
  } = addWidgetFilterCriteriaForm || {};

  const { filter_criteria: filterCriteriaWatch } = watch();

  const {
    parsedData: parsedFields,
    isLoading: isLoadingFields,
    error: errorFields,
  } = useGetGovernanceViewFields(selectedModule, "", !!selectedModule);

  const {
    parsedData: parsedFilters,
    isLoading: isLoadingFilters,
    error: errorInFilters,
  } = useGetGovernanceViewFilters("");

  const buildSqlStatement = (conditionsArray: FilterCriteriaType): string => {
    let sqlStatement = "";

    conditionsArray.forEach((group, groupIndex) => {
      if (groupIndex > 0) {
        sqlStatement += ` ${group.next_operator} `;
      }

      const groupConditions = group.condition
        .map((condition) => {
          let filter;
          switch (condition.filter) {
            case "EQL":
              filter = "=";
              break;
            case "NEQ":
              filter = "!=";
              break;
            case "LTH":
              filter = "<";
              break;
            case "LTE":
              filter = "<=";
              break;
            case "GTH":
              filter = ">";
              break;
            case "GTE":
              filter = ">=";
              break;
            case "BTW":
              filter = "BETWEEN";
              break;
            case "NBT":
              filter = "NOT BETWEEN";
              break;
            case "BLK":
              filter = "IS NULL";
              break;
            case "NBL":
              filter = "IS NOT NULL";
              break;
            case "WCM":
              filter = "LIKE";
              break;
            case "INC":
              filter = "IN";
              break;
            case "NIN":
              filter = "NOT IN";
              break;
            default:
              filter = "=";
          }

          return `${condition.columnName} ${filter} ${
            condition.value
              ? `${condition.value}`
              : `${
                  condition?.valuesList?.length
                    ? condition?.valuesList?.toString()
                    : ""
                }`
          }`;
        })
        .join(` ${group.condition[0].next_operation} `);

      sqlStatement += `(${groupConditions})`;
    });

    return sqlStatement;
  };

  const onSubmit = useCallback((values: AddWidgetFilterCriteriaFormType) => {
    propsOnSave?.({ ...values });
    onCancel();
  }, []);

  const onCancelModal = useCallback(() => {
    const origValues = {
      name: widgetFilterCriteriaName,
      save_as_template: isTemplateFilter,
      filter_criteria: widgetFilterCriteria,
    };
    isEditMode && propsOnSave?.(origValues);
    onCancel();
  }, [widgetFilterCriteriaName, isTemplateFilter, widgetFilterCriteria]);

  return (
    <StateHandler
      isFetching={isLoadingFields || isLoadingFilters}
      error={errorFields || errorInFilters}
      isModal
    >
      <AddFilterCriteriaFormStyled>
        <FormProvider {...addWidgetFilterCriteriaForm}>
          <FormStyled isItemColumnLayout>
            <FilterCriteriaFormStyled>
              <FormItemLabel label="Name" key="name" width="450px" required>
                <InputField
                  control={control}
                  name="name"
                  width="450px"
                  type="text"
                  disabled={isReadMode}
                />
              </FormItemLabel>

              <CheckboxWrapperStyled
                marginBottom="15px"
                paddingLeft="20px"
                marginTop="20px"
              >
                <CheckboxField
                  control={control}
                  name="save_as_template"
                  mode="secondary"
                  description="Save filter criteria as a template to use on other widgets."
                  disabled={isReadMode}
                >
                  Save as Template
                </CheckboxField>
              </CheckboxWrapperStyled>

              <FormItemLabel
                label="Specify Criteria"
                key="name"
                width="450px"
                required
              >
                <FilterCriteria
                  name="filter_criteria"
                  parsedFields={parsedFields}
                  parsedFilters={parsedFilters}
                  isEditMode={isEditMode}
                  existingFilters={widgetFilterCriteria}
                  nodeType={selectedModule}
                  isFromGovViews
                  isReadMode={isReadMode}
                />
              </FormItemLabel>
            </FilterCriteriaFormStyled>

            <FormItemStyled className="form-actions-sec">
              <Button id="cancel" width="74px" onClick={onCancelModal}>
                Cancel
              </Button>
              {!isReadMode && (
                <Button
                  id="primary"
                  width="74px"
                  marginLeft="8px"
                  htmlType="submit"
                  onClick={handleSubmit(onSubmit)}
                  disabled={
                    !isValid || !isDirty || !(filterCriteriaWatch?.length > 0)
                  }
                >
                  Save
                </Button>
              )}
            </FormItemStyled>
          </FormStyled>
        </FormProvider>
      </AddFilterCriteriaFormStyled>
    </StateHandler>
  );
};

export default AddFilterCriteriaForm;
