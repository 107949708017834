// <--- Types Start --->
import {
  PopularityScoreProps,
  ScoreType,
} from "../../components/popularityscore/popularityscore.types";

import { TablePopularityInfoType } from "../tablepage/tablepageparser.types";
// <--- Types End --->

export const getPopularityScoreDetailsData = (
  data: TablePopularityInfoType,
  popularityId: ScoreType
): PopularityScoreProps["popularityScoreDetails"] => {
  return {
    topUsers: data?.Usernames || [],
    used: {
      usageCount: data?.NoOfTimeUsed || 0,
      usedByCount: data?.NoOfUsers || 0,
      usedInCount: `${data?.NoOfDays || 0} days`,
    },
    isNeverUsed: popularityId === "NOU",
  };
};

export const getPopularityScoreData = (
  popularityId: ScoreType,
  popularityTitle: string,
  data: TablePopularityInfoType
): PopularityScoreProps => {
  return {
    score: popularityId || "NOU",
    title: popularityTitle || "",
    popularityScoreDetails: {
      ...getPopularityScoreDetailsData(data, popularityId),
    },
    isShow: !!popularityId,
  };
};
