// <---  Library Imports Start --->
import { useCallback } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// <---  Library Imports Start --->

// <---  Components Start --->
import { Button } from "../../components";
import LinkButton from "../../components/linkbutton";

import { FormItemStyled, FormStyled } from "../../components/form";
import { TextAreaField } from "../../components/formfields";

import StateHandler from "../../components/statehandler/statehandler";
import MultiSelectUsersField from "../../components/formfields/multiselectusersfield";

import SuccessNotificationMessage from "../../components/successnotificationmessagerendrer/successnotificationmessagerendrer";
// <---  Components End --->

// <---  Custom Hooks Start --->
import { useGetUserAndGroups } from "../../api/tablesservice";
import { useRequestWithMethod } from "../../api";

import {
  useCancelModal,
  useCopyToClipboard,
  useGetAppState,
} from "../../customhooks";
// <---  Custom Hooks End --->

// <---  Styles Start --->
import { ShareViewerFormStyled } from "./shareviewerform.styles";
// <---  Styles End --->

// <---  Svgs Start --->
import { chainIconInCircle, crossIcon } from "../../svgs";
// <--- Svgs End --->

// <---  Types Start --->
import { ShareViewerFormType } from "./shareviewerform.types";
// <---  Types End --->

// <---  Utils Start --->
import { openNotification } from "../../utils";
import { shareViewerSchema } from "../../utils/schemas/shareschemas";
// <---  Utils End --->

const ShareViewerForm = ({
  isLineageShare = false,
}: {
  isLineageShare?: boolean;
}): JSX.Element => {
  const {
    modal: { modalProps = {}, modalTitle = "" },
  } = useGetAppState();

  const {
    showCopyLink = false,
    nodeId = "",
    successMessage = "",
    createdById = "",
  } = modalProps;

  const onCopyClipboard = useCopyToClipboard();
  const onCancel = useCancelModal();

  const {
    control,
    setValue,
    handleSubmit,
    formState: { isValid },
  } = useForm<ShareViewerFormType>({
    defaultValues: {
      share_with: undefined,
      message: undefined,
    },
    resolver: yupResolver(shareViewerSchema),
    mode: "onChange",
  });

  const {
    parsedData: parsedUsers,
    isLoading: isLoadingUsers,
    error: errorUsers,
  } = useGetUserAndGroups(true);

  const onSuccessShare = useCallback(() => {
    openNotification(<SuccessNotificationMessage message={successMessage} />);
    onCancel();
  }, []);

  const { onExecuteRequest, isLoading, error } = useRequestWithMethod(
    isLineageShare ? "lineage_share_action" : "add_share_action",
    isLineageShare ? [nodeId] : [nodeId, "SRH"],
    false,
    onSuccessShare
  );

  const handleCopyLink = useCallback(() => {
    if (isLineageShare) {
      onCopyClipboard(window.location.href);
    } else {
      onCopyClipboard("");
    }
  }, []);

  const onSubmit = useCallback(
    (values) => {
      const shareWith =
        parsedUsers
          ?.filter((user) => values?.share_with?.includes(user?.value))
          ?.map((user) => ({
            node_id: Number.parseInt(user?.id || ""),
            node_type: user?.type,
            access_type: "GOV_VIEWER",
          })) || [];

      onExecuteRequest({
        refNodes: shareWith,
        description: values?.message || "",
        url: window.location.href,
      });
    },
    [parsedUsers]
  );

  return (
    <StateHandler
      isFetching={isLoadingUsers || isLoading}
      error={errorUsers || error}
      isModal
    >
      <ShareViewerFormStyled>
        {modalTitle === "" && (
          <div className="modal-heading-sec">
            <div className="heading">Share</div>
            <div className="cross-modal-icon" role="button" onClick={onCancel}>
              {crossIcon("16px", "16px")}
            </div>
          </div>
        )}

        <FormStyled
          isItemColumnLayout
          onFinish={
            handleSubmit(onSubmit) as ((_values: unknown) => void) | undefined
          }
        >
          <FormItemStyled>
            <MultiSelectUsersField
              control={control}
              setValue={setValue}
              name="share_with"
              mode="multiple"
              allowClear
              isShareForm
              placeholder="Share with: People or group"
              excludeIdsList={[createdById]}
              isLineageShareForm={isLineageShare}
              width="360px"
            />
          </FormItemStyled>

          <FormItemStyled>
            <TextAreaField
              control={control}
              name="message"
              placeholder="(Optional) Add message here..."
              width="360px"
              id="share-textarea"
            />
          </FormItemStyled>

          {showCopyLink && (
            <FormItemStyled className="copy-link-icon">
              <LinkButton className="chain-icon" onClick={handleCopyLink}>
                {chainIconInCircle("48px", "48px")} Copy Link
              </LinkButton>
            </FormItemStyled>
          )}

          <FormItemStyled label="" className="send-btn-sec" marginBottom="0px">
            <Button
              id="primary"
              width="74px"
              height="39px"
              htmlType="submit"
              disabled={!isValid}
            >
              Send
            </Button>
          </FormItemStyled>
        </FormStyled>
      </ShareViewerFormStyled>
    </StateHandler>
  );
};

export default ShareViewerForm;
