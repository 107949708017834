import { Dropdown, Menu } from "antd";
import styled, { FlattenSimpleInterpolation } from "styled-components";
import LinkButton from "../linkbutton/linkbutton";
import { disabledContent } from "../../styles/app.styles";

const { SubMenu } = Menu;

export const DropDownWrapperStyled = styled.div``;

export const RuleTypesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
`;

export const SubmenuStyled = styled(SubMenu)`
  .ant-menu-submenu-title {
    font-size: 13.6px;
    color: ${(props): string => props.theme.textColors.GREY_24};
    min-width: 200px;
    font-family: OpenSans;
    margin: 0;

    .ant-menu-submenu-arrow::before,
    .ant-menu-submenu-arrow::after {
      height: 1.2px;
    }

    :hover {
      background-color: ${({ theme }): string => theme.bgColors.BLUE_116};
      color: ${({ theme }): string => theme.textColors.WHITE_COLOR};

      .ant-menu-submenu-arrow {
        color: ${({ theme }): string => theme.textColors.WHITE_COLOR};
      }
    }
  }
  .ant-menu-submenu-popup {
    left: 195px !important;
  }
`;

export const SubmenuItemStyled = styled(Menu.Item)<{ isDisabled?: boolean }>`
  font-size: 13.6px;
  color: ${(props): string => props.theme.textColors.GREY_24} !important;
  min-width: 150px;
  margin-top: 0px !important;
  margin-bottom: 0 !important;
  margin-left: 0px;
  background-color: transparent !important;
  padding: 0;

  ${({ isDisabled }): FlattenSimpleInterpolation | string =>
    isDisabled ? disabledContent : ""}

  :last-child {
    border-bottom: unset;
  }
  &.ant-menu-item-disabled {
    opacity: 0.5;
  }

  :hover {
    background-color: ${({ theme }): string =>
      theme.bgColors.BLUE_116} !important;
    .ant-menu-title-content {
      color: ${({ theme }): string => theme.textColors.WHITE_COLOR};
    }
  }
`;

export const DropDownButtonStyled = styled(Dropdown)`
  border: unset;

  .action-icon {
    font-family: OpenSansSemiBold;
    font-size: 13.6px;

    &,
    .ant-btn {
      display: flex;
      align-items: center;
      column-gap: 3px;
    }

    svg {
      path {
        fill: ${({ theme }): string => theme.textColors.DARK_BLUE};
      }
    }
  }
`;

export const RuleTypeTextStyled = styled(LinkButton)`
  border: solid 1px ${({ theme }): string => theme.borderColors.GREY_56} !important;

  .MenuStyled {
    float: right;
  }

  button {
    height: 40px;
    padding: 8px 14px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    color: ${(props): string => props.theme.textColors.GREY_24};
    width: 100%;

    :active,
    :focus {
      color: ${(props): string => props.theme.textColors.GREY_24};
    }

    :hover {
      color: ${(props): string => props.theme.textColors.WHITE_COLOR};
      background-color: ${(props): string => props.theme.bgColors.BLUE_116};

      svg {
        path {
          fill: ${(props): string => props.theme.textColors.WHITE_COLOR};
          stroke: ${(props): string => props.theme.textColors.WHITE_COLOR};
          stroke-width: 0.3px;
        }
      }
    }
  }
`;

export const IconTextStyled = styled(LinkButton)`
  display: flex;
  align-items: center;
`;
