import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRequestWithMethod } from "../../api";

import { Button } from "../../components";
import { FormItemStyled, FormStyled } from "../../components/form";
import { SelectField } from "../../components/formfields";
import StateHandler from "../../components/statehandler/statehandler";
import { API_CONFIG } from "../../constants/apiconfig";
import { UserGroupsGridColumnsType } from "../../pages/usergroupspage/usergroupspage.types";
import { DeleteUserGroupFormStyled } from "./deleteusergroupsform.styles";
import { deleteUserGroupSchema } from "../../utils/schemas/usergroupschemas";
import { useGetAllUserGroups } from "../../api/userservice";
import { useCancelModal, useGetAppState } from "../../customhooks";
import { UserGroupResponse } from "../../parsers";

const api = API_CONFIG?.get_all_user_groups?.url;

function DeleteUserGroupForm(): JSX.Element {
  const {
    control,
    setValue,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    defaultValues: { assign_user_group_to: undefined },
    mode: "onChange",
    resolver: yupResolver(deleteUserGroupSchema),
  });
  const {
    modal: { modalProps = {} },
  } = useGetAppState();

  const {
    id = "",
    group_name: groupName,
  } = modalProps as UserGroupsGridColumnsType;

  const queryClient = useQueryClient();
  const {
    data = [],
    isLoading: isLoading1,
    error: error1,
  } = useGetAllUserGroups();

  const assignableGroups = useMemo(
    () =>
      data?.data?.filter(
        (group: UserGroupResponse) => group?.USER_GROUP_ID !== id
      ),
    [data]
  );

  const onCancel = useCancelModal();

  const onSuccess = useCallback(() => {
    queryClient.invalidateQueries(api, { fetching: false });
    onCancel();
  }, [modalProps]);

  const { isLoading, error, onExecuteRequest } = useRequestWithMethod(
    "delete_user_group",
    [id],
    true,
    onSuccess
  );

  const onSubmit = useCallback((values) => {
    onExecuteRequest({ new_group_id: values?.assign_user_group_to });
  }, []);

  return (
    <StateHandler
      isModal
      isFetching={isLoading || isLoading1}
      error={error || error1}
    >
      <DeleteUserGroupFormStyled>
        <FormStyled
          isItemColumnLayout
          paddingLeft="110px"
          onFinish={handleSubmit(onSubmit) as any}
        >
          <div className="content-sec">
            <div className="static-text">
              You must assign responsibilities of
              <span className="bold"> {groupName}</span> to another group.
            </div>
            <FormItemStyled
              label="Assign User Group To"
              required
              marginBottom="60px"
            >
              <SelectField
                control={control}
                setValue={setValue}
                options={assignableGroups?.map((item: any) => ({
                  label: item?.USER_GROUP_NAME,
                  value: item?.USER_GROUP_ID,
                }))}
                name="assign_user_group_to"
                width="396px"
                height="45.36px"
                placeholder="Select Group"
                isAllowClear={false}
              />
            </FormItemStyled>
          </div>
          <FormItemStyled
            label=""
            className="form-actions-sec"
            marginBottom="0px"
          >
            <Button
              id="primary"
              width="78px"
              height="39px"
              htmlType="submit"
              disabled={!isValid}
            >
              Delete
            </Button>
            <Button
              id="primary"
              width="78px"
              height="39px"
              marginLeft="5px"
              onClick={onCancel}
            >
              Cancel
            </Button>
          </FormItemStyled>
        </FormStyled>
      </DeleteUserGroupFormStyled>
    </StateHandler>
  );
}

export default DeleteUserGroupForm;
