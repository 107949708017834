import { useFormContext } from "react-hook-form";
import { FormItemStyled } from "../../../../components/form";
import { SelectField, TextAreaField } from "../../../../components/formfields";
import MultiSelectUsersField from "../../../../components/formfields/multiselectusersfield";
import { ShareFormStyled } from "./shareform.styles";
import { ShareFormProps } from "./shareform.types";

const ShareForm = (props: ShareFormProps): JSX.Element => {
  const { isShowShareForm, createdById = "", accessOptions } = props;

  const { control, setValue } = useFormContext();

  return (
    <ShareFormStyled isShowShareForm={isShowShareForm}>
      <FormItemStyled
        className="share-with-section"
        label="Share with people or groups"
      >
        <MultiSelectUsersField
          control={control}
          setValue={setValue}
          name="share_with"
          mode="multiple"
          className="share-with-select"
          placeholder="Add one or more people or groups"
          allowClear
          isShareForm
          excludeIdsList={[createdById]}
          width={isShowShareForm ? "531px" : "656px"}
        />

        {isShowShareForm && (
          <SelectField
            control={control}
            options={accessOptions}
            setValue={setValue}
            name="share_with_role"
            width="114.4px"
            height="39px"
            placeholder="Select"
            isAllowClear={false}
          />
        )}
      </FormItemStyled>

      {isShowShareForm && (
        <FormItemStyled>
          <TextAreaField
            control={control}
            name="message"
            placeholder="Enter message"
            rows={6}
            width="656.8px"
          />
        </FormItemStyled>
      )}
    </ShareFormStyled>
  );
};

export default ShareForm;
