import { useMemo } from "react";
import { arrowRightSecondary } from "../../../../svgs";

import { ExploreSecStyled } from "./exploresec.styles";
import { ExploreSecProps } from "./exploresec.types";

const ExploreSec = (props: ExploreSecProps): JSX.Element => {
  const { data = [], handleItemClick } = props;

  const sortedFeatures = useMemo(
    () => data?.sort((a, b) => a?.feature_set_order - b?.feature_set_order),
    [data?.length]
  );

  return (
    <ExploreSecStyled>
      <div className="title">Explore topics</div>
      <div className="explore-btns-sec">
        {sortedFeatures?.map((featureSet) => {
          const {
            id = "",
            title = "",
            is_recent_update_feaure: isRecentUpdateFeaure,
          } = featureSet || {};

          return (
            <div
              className={`explore-btn ${
                isRecentUpdateFeaure ? "recent-update-btn" : ""
              }`}
              key={id}
              role="button"
              onClick={(): void => handleItemClick(title, isRecentUpdateFeaure)}
            >
              {title} {arrowRightSecondary("10", "10")}
            </div>
          );
        })}
      </div>
    </ExploreSecStyled>
  );
};

export default ExploreSec;
