import { RcFile } from "antd/lib/upload";

// <--- Components Start --->
import LinkButton from "../linkbutton/linkbutton";
import CustomProgressBar from "../customprogresbar";
// <--- Components End --->

// <--- Styles Start --->
import {
  SingleFileDraggerStyled,
  SingleFileUploaderWithProgressBarStyled,
} from "./singlefileuploaderwithprogressbar.styles";
// <--- Styles End --->

// <--- SVGs Start --->
import {
  cloudyUploadIcon,
  crossIcon,
  filledCrossIcon,
  filledSuccessTickInCircle,
} from "../../svgs";
// <--- SVGs End --->

// <--- Types Start --->
import { SingleFileUploaderWithProgressBarProps } from "./singlefileuploaderwithprogressbar.types";
// <--- Types End --->

const SingleFileUploaderWithProgressBar = (
  props: SingleFileUploaderWithProgressBarProps
): JSX.Element => {
  const {
    file,
    status,
    additionalInfo,
    errorMessage,
    isLoading = false,
    uploadProgress = 0,
    onChange,
    onCancelFile,
    showCancelIcon = true,
  } = props;

  return (
    <SingleFileUploaderWithProgressBarStyled>
      <SingleFileDraggerStyled
        {...props}
        data-testid="single-file-uploader-with-progress-bar-dragger"
        className={`${file ? "file-added" : ""}${
          file && status === "error" ? " upload-error" : ""
        }`}
        maxCount={1}
        beforeUpload={(file: RcFile, FileList: RcFile[]): any =>
          props?.beforeUpload ? props?.beforeUpload(file, FileList) : false
        }
        onChange={onChange}
        showUploadList={false}
        disabled={isLoading}
      >
        {isLoading && (
          <div className="loading-wrapper">
            <div className="loading-text">Uploading...</div>
            <CustomProgressBar
              data-testid="single-file-uploader-progress-bar-wrapper"
              value={uploadProgress}
              height="18.8px"
            />
          </div>
        )}
        {!isLoading &&
          status !== "error" &&
          (!file ? (
            <>
              <p className="ant-upload-text">
                Drop file here or <span className="blue-text">browse</span>
              </p>
              {cloudyUploadIcon("54.4", "38.4")}
            </>
          ) : (
            <div className="additional-file-info">
              <div className="icon-wrapper">
                {filledSuccessTickInCircle("35.2px", "35.2px")}
              </div>
              <div className="file-info">
                File has been uploaded successfully:{" "}
                <span
                  data-testid="single-file-uploader-with-progress-bar-file-name"
                  className="file-name"
                >
                  {file?.name}
                </span>
              </div>
              <div className="parse-desc">
                The file will be parsed using UTF-8 encoding.
              </div>
            </div>
          ))}
        {!isLoading && status === "error" && (
          <div className="additional-file-info">
            <div className="icon-wrapper">
              {filledCrossIcon("35.2px", "35.2px")}
            </div>
            <div
              data-testid="single-file-uploader-with-progress-bar-error-message"
              className="file-info"
            >
              {errorMessage}
            </div>
          </div>
        )}
      </SingleFileDraggerStyled>
      {file && showCancelIcon && (
        <LinkButton
          data-testid="single-file-uploader-with-progress-bar-cross-icon"
          className="close-icon"
          icon={crossIcon("7", "11")}
          onClick={onCancelFile}
        />
      )}
      {additionalInfo && (
        <div
          data-testid="single-file-uploader-with-progress-bar-additional-info"
          className="additional-info"
        >
          {additionalInfo}
        </div>
      )}
    </SingleFileUploaderWithProgressBarStyled>
  );
};

export default SingleFileUploaderWithProgressBar;
