// <--- Library Imports Start --->
import { useHistory } from "react-router";
import { SetStateAction, useMemo } from "react";
// <--- Library Imports End --->

// <--- BlankSlates Start --->
import { tableRefBlankSlate } from "../../blankslates/tablerefblankslate";
// <--- BlankSlates End--->

// <--- Components Start--->
import StateHandler from "../../components/statehandler/statehandler";
import SectionHeader from "../../components/sectionheader";

import { Section } from "../../components/section";
import LinkButton from "../../components/linkbutton";

import SuggestAnEditButton from "../../components/suggestaneditbutton/suggestaneditbutton";
import NodeRefPageHOC from "../../components/noderefpagehoc/noderefpagehoc";

import CoreNodesCircleIcon from "../../components/corenodetypescircleicon";
import { SingleTagRendrer } from "../../components/prominenttagslist/prominenttagslist";

import LineageSectionTitleWithIcon from "../../components/lineagesectitlewithicon";
import QualitySectionTitleWithIcon from "../../components/qualitysectitlewithicon";

import NodeRefQuickEditComp from "../../components/noderefquickedit";
import PopularityScore from "../../components/popularityscore";
// <--- Components End --->

// <--- Constants Start --->
import { APP_ROUTES, ELEMENT_IDS } from "../../constants";
import { TABLE_SECTIONS } from "../../constants/labelconstants";
// <--- Constants End --->

// <--- Custom Hooks Start --->
import { useGetAppState, useGetAppType } from "../../customhooks";
// <--- Custom Hooks End --->

// <--- Renderers Start --->
import {
  tableRefCustomCompRendrer,
  tableRefRenderer as tableRefRendererConfig,
} from "./tableref.renderer";

import NodeStatusRendrer from "../../components/nodestatusrendrer";
// <--- Renderers End --->

// <--- Services Start --->
import { useGetTableDoc } from "../../api/tablesservice";
// <--- Services End --->

// <--- Styles Start --->
import { TableRefDrawerStyled } from "./tablerefdrawer.styles";
// <--- Styles End --->

// <--- SVGs Start --->
import { databaseIcon, redirectCircledIcon } from "../../svgs";
// <--- SVGs End --->

// <--- Types Start --->
import { SectionRowProps } from "../../components/sectionrow/sectionrow.types";
import { NodeRefPageHOCEmbededProps } from "../../components/noderefpagehoc/noderefpagehoc.types";
// <--- Types End --->

// <--- Utils Start --->
import { getSourceDetailPageUrl, getTableDetailPageUrl } from "../../utils";
// <--- Utils End --->

import StyledLink from "../../components/styledlink/styledlink";

const titleSecProps = {
  className: "col-ref-sec-titile",
  showBorderBottom: false,
  isShowIcon: false,
};

const {
  additional_info: ADDITIONAL_INFO,
  classification: CLASSIFICATION,
  get_access_info: GET_ACCESS_INFO,
  linked_columns: LINKED_COLUMN,
  managed_by: MANAGED_BY,
} = TABLE_SECTIONS;

const {
  datdict_detpg_info_ref_goto: DATDICT_DETPG_INFO_REF_GOTO,
  datdict_detpg_info_ref_ttl: DATDICT_DETPG_INFO_REF_TTL,
} = ELEMENT_IDS;

function DrawerContent(
  props: NodeRefPageHOCEmbededProps & { id?: string }
): JSX.Element {
  const history = useHistory();

  const { drawer } = useGetAppState();
  const { isExtOrDesktop } = useGetAppType();

  const { drawerProps = {} } = drawer || {};

  const { isDrawer, onClickEdit, editViewComp } = props;

  const nodeId = props?.id || drawerProps?.id;

  const { parsedData, isLoading, error } = useGetTableDoc(nodeId, "TBL", "PUB");

  const {
    nodeDesc = "",
    nodeTitle = "",
    physicalName = "",
    isDqEnabled = false,
    isWfEnabled = false,
  } = parsedData || {};

  const tableRefRenderer = useMemo(() => {
    return tableRefRendererConfig;
  }, []);

  const { isOpeningInNewTab, toUrl } = useMemo(() => {
    const isOpeningInNewTab =
      history?.location?.pathname?.includes(
        APP_ROUTES.private_routes.chat_bot
      ) ||
      history?.location?.pathname?.includes(APP_ROUTES.private_routes.chat);

    const url = getTableDetailPageUrl(nodeId);

    return {
      isOpeningInNewTab: isExtOrDesktop || isOpeningInNewTab,
      toUrl: url,
    };
  }, [drawerProps, isExtOrDesktop, nodeId]);

  const breadCrumUrl = useMemo(() => {
    return getSourceDetailPageUrl(parsedData?.parentId || "");
  }, [parsedData]);

  return (
    <StateHandler
      isFetching={isLoading}
      error={error}
      blankSlate={tableRefBlankSlate}
    >
      <TableRefDrawerStyled
        hasPromintntTags={!!parsedData?.prominentTags?.tags?.length}
      >
        <div className="table-ref-header">
          <div className="table-header-breadcrums">
            <div className="item-icon">{databaseIcon}</div>

            <StyledLink
              to={breadCrumUrl}
              target={isExtOrDesktop ? "_blank" : "_self"}
              title={parsedData?.parentName}
            >
              <div className="item-title" title={parsedData?.parentName}>
                {parsedData?.parentName}
              </div>
            </StyledLink>
          </div>
          <div className="header_row_2" id={DATDICT_DETPG_INFO_REF_TTL}>
            <SectionHeader
              icon={<CoreNodesCircleIcon nodeType="TBL" marginRight="12px" />}
              title={nodeTitle}
              subTitle=""
              rightIcon={
                <>
                  <NodeStatusRendrer
                    status={parsedData?.publishStatusId}
                    className="deprecate-icon"
                    tooltipTitle={parsedData?.publishStatus}
                    nodeType="TBL"
                  />

                  <LinkButton height="33px" disabled={!!editViewComp}>
                    <StyledLink
                      to={editViewComp ? "" : toUrl}
                      cutomClassName="table-redirect-icon"
                      target={isOpeningInNewTab ? "_blank" : "_self"}
                      id={DATDICT_DETPG_INFO_REF_GOTO}
                    >
                      {redirectCircledIcon("28px", "28px")}
                    </StyledLink>
                  </LinkButton>
                </>
              }
            />
            {!parsedData?.isEditable && (
              <SuggestAnEditButton
                nodeType="TBL"
                nodeId={props?.id || drawerProps?.id}
                isDrawer={isDrawer}
                onClick={props?.onClickSuggest}
                nodeDesc={parsedData?.nodeDesc}
                nodeTitle={nodeTitle}
                marginLeft="39px"
              />
            )}
            <div className="node-title" title={physicalName}>
              {physicalName}
            </div>
            <div className="tags-popularity-sec">
              {parsedData?.prominentTags?.tags?.map((tag) => (
                <SingleTagRendrer
                  tag={tag}
                  key={`prominent-tags-${tag?.name}`}
                  isBoldView
                />
              ))}
              <PopularityScore
                {...parsedData?.popularityScore}
                isShowBorderLeft={!!parsedData?.prominentTags?.tags?.length}
              />
            </div>
          </div>
        </div>
        {editViewComp || (
          <>
            {parsedData?.isEditable && (
              <NodeRefQuickEditComp
                nodeDesc={nodeDesc}
                nodeId={nodeId}
                nodeTitle={nodeTitle}
                nodeType="TBL"
                onClickEdit={(props): void => onClickEdit?.({ ...props })}
                top="18px"
                right="29px"
                nodeName={physicalName}
                isDisabled={isWfEnabled}
              />
            )}
            <div className="table-ref-section">
              <Section
                secTitle={{ title: "" }}
                secRowData={parsedData?.overview_info?.map(
                  (item: SectionRowProps) => ({
                    ...item,
                    customJSX: tableRefRenderer?.[item?.title]?.(item),
                  })
                )}
              />
            </div>
            <div className="table-ref-section managedby-section">
              <Section
                secTitle={{
                  title: MANAGED_BY,
                  ...titleSecProps,
                }}
                secRowData={parsedData?.managed_by?.map(
                  (item: SectionRowProps) => ({
                    ...item,
                    customJSX: tableRefRenderer?.[item?.title]?.(item),
                  })
                )}
                mode="section_column"
              />
            </div>
            <div className="table-ref-section">
              <Section
                secTitle={{
                  title: CLASSIFICATION,
                  ...titleSecProps,
                }}
                secRowData={parsedData?.classification?.map(
                  (item: SectionRowProps) => ({
                    ...item,
                    customJSX: tableRefRenderer?.[item?.title]?.(item),
                  })
                )}
              />
            </div>
            <div className="table-ref-section">
              <Section
                secTitle={{
                  title: (
                    <LineageSectionTitleWithIcon
                      isRefView
                      queryParams={{
                        nodeId,
                        nodeName: nodeTitle,
                        mode: "none",
                        nodeType: "TBL",
                      }}
                      lineageExists={parsedData?.lineage?.lineageExists}
                    />
                  ),
                  ...titleSecProps,
                }}
                lineageData={{ ...parsedData?.lineage, isRefView: true }}
                secRowData={[]}
                mode="lineage"
              />
            </div>
            {isDqEnabled && (
              <div className="table-ref-section data-quality-section">
                <Section
                  secTitle={{
                    ...titleSecProps,
                    title: (
                      <QualitySectionTitleWithIcon
                        nodeId={props?.id || drawerProps?.id}
                      />
                    ),
                    marginBottom: "15px",
                  }}
                  secRowData={parsedData?.quality?.map(
                    (item: SectionRowProps) => ({
                      ...item,
                      customJSX: tableRefRenderer?.[item?.title]?.(item),
                      customRowComp: tableRefCustomCompRendrer?.[item?.title]?.(
                        item
                      ),
                    })
                  )}
                />
              </div>
            )}

            <div className="table-ref-section section-represents">
              <Section
                secTitle={{
                  title: LINKED_COLUMN,
                  ...titleSecProps,
                }}
                secRowData={[]}
                representsData={parsedData?.represents}
                mode="represents"
              />
              <Section
                secTitle={{
                  title: "",
                  ...titleSecProps,
                }}
                secRowData={parsedData?.provenance?.map(
                  (item: SectionRowProps) => ({
                    ...item,
                    customJSX: tableRefRenderer?.[item?.title]?.(item),
                  })
                )}
              />
            </div>
            <div className="table-ref-section">
              <Section
                secTitle={{
                  title: ADDITIONAL_INFO,
                  ...titleSecProps,
                }}
                additionalInfoData={parsedData?.additionalInfo}
                mode="additional_info"
                secRowData={[]}
              />
            </div>
            <div className="table-ref-section">
              <Section
                secTitle={{
                  ...titleSecProps,
                  title: GET_ACCESS_INFO,
                }}
                mode="access_info"
                additionalInfoData={parsedData?.getAccessInfo}
                secRowData={[]}
              />
            </div>
          </>
        )}
      </TableRefDrawerStyled>
    </StateHandler>
  );
}

const TableRefDrawer = (props: {
  id?: string;
  isDrawer?: boolean;
  setCurrentPage?: React.Dispatch<
    SetStateAction<"home" | "results" | "extRef" | "other">
  >;
  onClickSuggest?: () => void;
  setShowParentGoBack?: React.Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
  return (
    <NodeRefPageHOC
      isDrawer={props?.isDrawer}
      setShowParentGoBack={props?.setShowParentGoBack}
    >
      <DrawerContent {...props} />
    </NodeRefPageHOC>
  );
};

export default TableRefDrawer;
