// <--- Library Imports Start --->
import styled, { createGlobalStyle } from "styled-components";
// <--- Library Imports End --->

// <--- Utils Start --->
import { hexToRgbA } from "../../utils";
// <--- Utils End --->

export const ReviewRecomendedEntityFormStyled = styled.div`
  .scrollable-sec {
    min-height: 353px;
    max-height: 370px;
    overflow: auto;
    padding: 20px;

    .search-entity-section {
      display: flex;
      gap: 6px;
      align-items: center;

      .cross-btn {
        height: 30px;
      }
    }

    .add-btn {
      display: flex;
      gap: 5px;
      align-items: center;
      height: 32px;
    }
  }

  .no-entities-section {
    height: 353px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 13.6px;

    .no-entities-info-text {
      font-size: 11px;
      color: ${({ theme }): string => theme.textColors.GREY_211};
    }
  }

  .form-actions-sec {
    .ant-form-item-control-input-content {
      display: flex;
      justify-content: flex-start !important;
    }

    .actions-sec-right {
      width: 100%;
      display: flex;
      justify-content: flex-end !important;
    }
  }
`;

export const EntityDropdownStyle = createGlobalStyle<{ theme: any }>`
  .entity-search-results-dropdown {
    padding: 0px;
    padding-bottom: 6px;
    padding-top: 6px;
    width: 490px !important;

    .rc-virtual-list {
      .rc-virtual-list-holder {
        .rc-virtual-list-holder-inner {
          .ant-select-item-group {
            padding: 0;
          }

          .ant-select-item-option {
            padding: 14px 15px;
          }

          .ant-select-item-option-active,
          .ant-select-item-option-selected {
            background: ${({ theme }): string => theme.bgColors.BLUE_118};
            .ant-select-item-option-content {
              .item-name {
                .trm-icon {
                  .core-nodes-circle-icon {
                    background: ${({ theme }): string =>
                      theme.bgColors.WHITE_COLOR};
                    color: ${({ theme }): string => theme.textColors.BLUE_56};
                  }
                }
              }
              .render-item {
                color: ${({ theme }): string => theme.textColors.WHITE_COLOR};
                svg {
                  path {
                    fill: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
                  }
                }
              }
            }
          }

          .ant-select-item-option-active{
            .ant-select-item-option-content {
                .item-name,
                .item-description {
                  color: ${({ theme }): string => theme.textColors.WHITE_COLOR};
                }

                .right-icon {
                  svg {
                    path {
                      fill: ${({ theme }): string =>
                        theme.textColors.WHITE_COLOR};
                    }
                  }
                }

                .dmn-icon,
                .trm-icon,
                .cat-icon {
                  svg {
                    path {
                      fill: ${({ theme }): string =>
                        theme.textColors.WHITE_COLOR};
                    }
                  }
                }

                .dmn-icon {
                  svg {
                    g {
                      :not(:last-of-type) {
                        fill: ${({ theme }): string =>
                          theme.bgColors.WHITE_COLOR};
                        stroke: ${({ theme }): string =>
                          theme.bgColors.WHITE_COLOR};
                      }

                      :last-of-type {
                        path {
                          fill: ${({ theme }): string =>
                            theme.bgColors.BLUE_118};
                        }
                      }
                    }
                  }
                }
              }
          }
          .ant-select-item-option-disabled {
            opacity: 0.5;
          }
        }
      }
    }

    .ant-select-item-empty {
      padding: 0px 12px;
      display: flex;
      align-items: center;
      > div {
        padding: 0px;
      }
    }
    
    .ant-select-item-group {
      font-size: 14px;
      div {
        color: ${({ theme }): string =>
          hexToRgbA(theme.textColors.DARK_GREY, 0.8)};
      }
    }
  }
`;
