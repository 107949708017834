import { useCallback } from "react";
import { useQueryClient } from "react-query";
import { Button } from "../../..";
import { useRequestWithMethod } from "../../../../api";
import { API_CONFIG } from "../../../../constants/apiconfig";
import { useCancelModal, useGetAppState } from "../../../../customhooks";
import { updateArrayWithSameSequence } from "../../../../utils";
import StateHandler from "../../../statehandler";
import { ResendInviteUserModalStyled } from "./resendinviteusermodalstyles";

const api = API_CONFIG?.get_all_users?.url;

function ResendInviteUserModal(): JSX.Element {
  const onCancel = useCancelModal();
  const { modal = {} } = useGetAppState();
  const { firstName = "", lastName = "", id = "" } = modal?.modalProps || {};

  const queryClient = useQueryClient();

  const onSuccess = useCallback((res) => {
    if (res?.data) {
      queryClient?.setQueryData(api, (oldData: any) => {
        return {
          ...oldData,
          data: updateArrayWithSameSequence(
            oldData.data,
            "USER_ID",
            res?.data?.USER_ID,
            res?.data
          ),
        };
      });
    }

    onCancel();
  }, []);

  const { isLoading, error, onExecuteRequest } = useRequestWithMethod(
    "resend_invite_user",
    [id],
    true,
    onSuccess
  );

  return (
    <StateHandler isModal isFetching={isLoading} error={error}>
      <ResendInviteUserModalStyled>
        <div className="content-sec">
          <div className="static-text">
            Are you sure you want to Re-send Invite to{" "}
            <span className="bold">
              {firstName} {lastName}
            </span>
            ?
          </div>
        </div>
        <div className="modal-actions-sec">
          <Button id="cancel" width="78px" onClick={onCancel}>
            Cancel
          </Button>

          <Button
            id="primary"
            width="78px"
            onClick={(): void => onExecuteRequest({})}
          >
            Re-send
          </Button>
        </div>
      </ResendInviteUserModalStyled>
    </StateHandler>
  );
}

export default ResendInviteUserModal;
