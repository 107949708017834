import * as yup from "yup";

export const queryFieldSettingsSchema: any = yup.object().shape({
  precision: yup
    .string()
    .required("Please specify the decimal precision value")
    .test(
      "minResolver",
      "Precision value must be greater than or equal to 2 or less than or equal to 100",
      (currentValue): boolean => {
        return Number(currentValue) >= 2 && Number(currentValue) <= 100;
      }
    ),
});
