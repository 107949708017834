import { GridApi, ICellRendererParams } from "ag-grid-community";
import { Spin, Tooltip } from "antd";
import { useCallback, useEffect } from "react";
import { useRequestWithMethod } from "../../../../../../api";
import { useGetCurrentJobStatusOfExcelSource } from "../../../../../../api/sourcesservice/sourceservice";
import {
  TableColumns,
  CellRenderers,
} from "../../../../../../components/aggridtable/aggridtable.types";
import { SkeltonButtonBlankSlate } from "../../../../../../components/skeltonblankslate";
import SuccessNotificationMessage from "../../../../../../components/successnotificationmessagerendrer/successnotificationmessagerendrer";
import TableActionsSec from "../../../../../../components/tableactionssec";
import { DVSUM_TOOLTIP_CLASS_NAME } from "../../../../../../constants";
import { useOpenModal } from "../../../../../../customhooks";
import {
  agGridSortDateComparator,
  agGridStringComparator,
  openNotification,
} from "../../../../../../utils";
import {
  ActionRendrerStyled,
  FileNameRendrerStyled,
  UpdatedOnRendrersStyled,
} from "./excelsourcefilesgridview.styles";
import { ExcelSourceFilesGridViewType } from "./excelsourcefilesgridview.types";

type ExcelSourceFileGridViewType = ExcelSourceFilesGridViewType[number];

const columnsToUpdated: (keyof ExcelSourceFileGridViewType)[] = [
  "file_name",
  "delete_file",
  "updated_by",
  "size",
];

export function ExcelSourceFilesActionRendrer(
  props: ICellRendererParams
): JSX.Element {
  const openModal = useOpenModal();
  const typedData = props?.data as ExcelSourceFileGridViewType;

  const onSuccessOfDownload = useCallback(
    (res) => {
      window.location.href = res?.data?.download_link;
      openNotification(
        <SuccessNotificationMessage message="Download is in progress" />
      );
    },
    [props]
  );

  const { onExecuteRequest } = useRequestWithMethod(
    "download_excel_source_file",
    [typedData?.file_id],
    true,
    onSuccessOfDownload
  );

  const onSuccess = useCallback(() => {
    (props?.api as GridApi)?.applyTransaction({ remove: [props?.data] });
  }, [props]);

  const onClick = useCallback(() => {
    const nodes: ExcelSourceFilesGridViewType = [];

    (props?.api as GridApi)?.forEachNode((val) => {
      nodes.push(val?.data);
    });

    openModal({
      modalId: "delete_excel_source_file",
      modalTitle: "Delete File",
      visible: true,
      modalProps: {
        fileName: typedData?.file_name?.file_name,
        fileId: typedData?.file_id,
        folderName: typedData?.folder_name,
        onSuccess,
        isAnyFileUploading: nodes?.some(
          (item: ExcelSourceFileGridViewType) => item?.is_newly_added
        ),
      },
    });
  }, [props, onSuccess]);

  const onDownload = useCallback(() => {
    onExecuteRequest({});
  }, [props]);

  const isScanRunning =
    typedData?.delete_file?.is_loading ||
    typedData?.delete_file?.is_newly_added;

  return (
    <ActionRendrerStyled isDisabled={!!isScanRunning}>
      <TableActionsSec
        data={[
          {
            name: "Delete",
            onClick,
          },
          {
            name: "Download",
            onClick: onDownload,
          },
        ]}
      />
    </ActionRendrerStyled>
  );
}

export function UpdatedOnRendrers(props: ICellRendererParams): JSX.Element {
  const typedData = props?.data as ExcelSourceFileGridViewType;
  const {
    isLoading,
    parsedData: jobResult,
  } = useGetCurrentJobStatusOfExcelSource(typedData?.Job_id);
  const { is_newly_added: isNewlyAdded, updated_on: updatedOn } =
    typedData || {};

  const isScanRunning = jobResult?.status === "RUN" && !isLoading;

  const {
    progress = 0,
    updated_time: updatedTime = "",
    is_uploading: isUploading,
  } = updatedOn || {};

  const isUploadingFiles = isNewlyAdded && isUploading && progress < 100;
  const isStartingScan = progress === 100;

  useEffect(() => {
    if (typedData?.Job_id) {
      const node = props?.api?.getRowNode(typedData?.id);

      columnsToUpdated.forEach((item) => {
        const restValues: Object =
          typeof typedData?.[item] === "object" ? typedData?.[item] : {};
        node?.setDataValue(item, {
          ...restValues,
          is_loading: isLoading,
          is_newly_added: jobResult?.status === "RUN",
        });
      });
    }
  }, [jobResult?.status]);

  return (
    <UpdatedOnRendrersStyled
      progress={jobResult?.status === "RUN" ? 100 : progress}
      isNewlyAdded={isUploadingFiles}
    >
      {isLoading ? (
        <SkeltonButtonBlankSlate active />
      ) : isUploadingFiles ? (
        <div className="newly-added-sec">
          Uploading...
          <Tooltip
            title={`${progress}% uploaded`}
            overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
            placement="right"
          >
            <div className="progress">
              <div className="inner">j</div>
            </div>
          </Tooltip>
        </div>
      ) : isStartingScan ? (
        <div className="newly-added-sec">
          Starting scan <Spin size="small" />
        </div>
      ) : isScanRunning ? (
        <div className="newly-added-sec">
          Scanning <Spin size="small" />
        </div>
      ) : (
        updatedTime
      )}
    </UpdatedOnRendrersStyled>
  );
}

export function FileNameRendrer(props: ICellRendererParams): JSX.Element {
  const typedData = props?.data as ExcelSourceFileGridViewType;
  const isScanRunning =
    typedData?.file_name?.is_newly_added || typedData?.file_name?.is_loading;

  return (
    <FileNameRendrerStyled
      isNewlyAdded={!!isScanRunning}
      title={typedData?.file_name?.file_name || ""}
    >
      {typedData?.file_name?.file_name}
    </FileNameRendrerStyled>
  );
}

export function UpdatedByRendrer(props: ICellRendererParams): JSX.Element {
  const typedData = (props?.data || {}) as ExcelSourceFileGridViewType;
  const { updated_by } = typedData;
  const {
    is_loading: isLoading = false,
    is_newly_added: isNewLyAdded = false,
    updated_by: updatedBy = "",
  } = updated_by || {};

  return isLoading ? (
    <SkeltonButtonBlankSlate active />
  ) : isNewLyAdded ? (
    <div />
  ) : (
    <div>{updatedBy}</div>
  );
}

export function SizeRendrer(props: ICellRendererParams): JSX.Element {
  const typedData = (props?.data || {}) as ExcelSourceFileGridViewType;
  const { size } = typedData;
  const {
    is_loading: isLoading = false,
    is_newly_added: isNewLyAdded = false,
    size: fileSze = "",
  } = size || {};

  return isLoading ? (
    <SkeltonButtonBlankSlate active />
  ) : isNewLyAdded ? (
    <div />
  ) : (
    <div>{fileSze}</div>
  );
}

export const excelSourceFileGridViewColumns: TableColumns<ExcelSourceFileGridViewType> = [
  {
    field: "file_name",
    headerName: "File Name",
    minWidth: 400,
    flex: 1,
    comparator: (a: any, b: any): number =>
      agGridStringComparator(a?.file_name, b?.file_name),
  },
  {
    field: "updated_on",
    headerName: "Updated On",
    minWidth: 311,
    comparator: (p: any, p1: any): number =>
      agGridSortDateComparator(p?.updated_time, p1?.updated_time),
  },
  {
    field: "updated_by",
    headerName: "Updated By",
    minWidth: 311,
    comparator: (a: any, b: any): number =>
      agGridStringComparator(a?.updated_by, b?.updated_by),
  },
  {
    field: "size",
    headerName: "Size",
    minWidth: 168,
    comparator: (a: any, b: any): number =>
      agGridStringComparator(a?.size, b?.size),
  },
  { field: "delete_file", headerName: "", sortable: false },
];

export const excelSourceFileGridViewRendrers: CellRenderers<ExcelSourceFileGridViewType> = {
  delete_file: {
    render: "ExcelSourceFilesActionRendrer",
    align: "right",
  },
  updated_on: {
    render: "UpdatedOnRendrers",
  },
  file_name: { render: "FileNameRendrer" },
  updated_by: { render: "UpdatedByRendrer" },
  size: { render: "SizeRendrer" },
};
