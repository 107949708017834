import { NodeType } from "../app.types";
import { encodeParams } from "./encodeparams";
import { getPostLoginData } from "./getpostlogindata";
import { jsonParse } from "./jsonparse";

export const getFiltersfromLocalStorage = (nodeType: NodeType): string => {
  try {
    const { user_info: userInfo = {} } = getPostLoginData();

    const {
      catalog_access_filter: catalogAccessFilter = "",
      glossary_access_filter: glossaryAccessFilter = "",
    } = userInfo;

    const catalogAccess = jsonParse(catalogAccessFilter);
    const glossaryAccess = jsonParse(glossaryAccessFilter);

    const nodeFilter =
      nodeType === "TRM"
        ? glossaryAccess?.TRM?.filter_sql_stmt || ""
        : catalogAccess?.[nodeType]?.filter_sql_stmt || "";

    const parsedNodeFilter = encodeParams(nodeFilter || "");
    return parsedNodeFilter;
  } catch (error) {
    return "";
  }
};
