import { Tooltip } from "antd";
import { DVSUM_TOOLTIP_CLASS_NAME } from "../../constants";
import {
  MiltiProgressBarStyled,
  ProgressItemStyled,
} from "./multistatusprogressbar.styles";
import { MultiProgressBarProps } from "./multistatusprogressbar.types";

function MultiStatusProgressbar(props: MultiProgressBarProps): JSX.Element {
  const {
    data = [],
    minThreshhold = 0,
    className,
    tooltipProps = { title: "" },
    showValue = true,
  } = props;
  const max = Math.max(...data?.map((item) => item?.value));
  const ValuesThatLessThan1 = data
    ?.filter((item) => item?.value < 1 && item?.value > 0)
    ?.map((item) => item?.value);
  const min = ValuesThatLessThan1?.length
    ? Math.min(...ValuesThatLessThan1)
    : 0;

  return (
    <Tooltip {...tooltipProps} overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}>
      <MiltiProgressBarStyled className={className} width={props?.width}>
        {data?.map((item, index) => (
          <ProgressItemStyled
            {...item}
            className="bar-item"
            title={`${item?.valueDetail}`}
            key={`${item?.value}-${item?.type}-${index}`}
            width={
              max === item?.value
                ? max - min - ValuesThatLessThan1?.length * 2
                : item?.value
            }
          >
            {!!(minThreshhold && item?.value > minThreshhold && showValue) && (
              <p>
                {Number.isInteger(item?.value)
                  ? item?.value
                  : Number(item?.value)?.toFixed(1)}
              </p>
            )}
          </ProgressItemStyled>
        ))}
      </MiltiProgressBarStyled>
    </Tooltip>
  );
}

export default MultiStatusProgressbar;
