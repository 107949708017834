import styled from "styled-components";
import { hexToRgbA } from "../../../../utils";

export const CloneRuleSummaryModalStyled = styled.div`
  padding: 30px 45px;
  .label {
    padding: 30px 80px 70px 30px;
    font-size: 14px;
    text-align: flex-start;
    color: ${({ theme }): string => theme.textColors.GREY_40};
  }

  .link-tabs-download-btn-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
`;

export const DeleteRuleModalFooterStyled = styled.div`
  background-color: ${({ theme }): string => theme.bgColors.LIGHT_BLUE_25};
  border-top: 1px solid
    ${({ theme }): string => theme.borderColors.LIGHT_BLUE_45};
  padding: 8px 18px 8px 18px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
